import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import { TextBox } from "devextreme-react";
import { faQuestion, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ManageShiftsDesignProps extends RouteComponentProps {}

class ManageShiftsDesign extends Component<ManageShiftsDesignProps> {
    constructor(props: ManageShiftsDesignProps) {
        super(props);
    }
    handleChange(e: { value: any }) {
        this.setState({
            type: e.value,
        });
    }

    render() {
        return (
            <LightModePageTemplate>
                <div className="page-content">
                    <div className="col-12">
                        <h3 className="card-form__page-heading">Manage Shifts - View/Edit/Master Edit</h3>
                        <section className="card-form__button-container px-2">
                            <div className="col-12 col-lg-4 col-xl-2">
                                <button className="btn btn-primary btn--large">Edit</button>
                            </div>
                            <div className="col-12 col-lg-4 col-xl-6"></div>
                            <div className="col-12 col-lg-4">
                                <div className="row p-3 p-xl-0">
                                    <label className="card-form__label col-12 col-lg-4 text-left text-xl-right">
                                        Field Label
                                    </label>
                                    <div className="col-12 col-lg-8 pl-0">
                                        <TextBox />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="card card-form my-5">
                            <div className="card-body">
                                <h3 className="card-form__section-heading">Shift</h3>
                                <hr className="horizontal_border_style"></hr>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0"></div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pr-0">
                                        <div className="row">
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Small Field Label
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    End
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                </div>
                                <hr className="horizontal_border_style"></hr>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 col-lg-4 pr-0">
                                        <div className="row">
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Mon
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Tue
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pr-0">
                                        <div className="row">
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Wed
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Thu
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pr-0">
                                        <div className="row">
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Fri
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Sat
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 col-lg-4 pr-0"> </div>
                                    <div className="col-12 col-lg-4 pr-0">
                                        <div className="row">
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Sun
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Weekly Hours
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pr-0"></div>
                                </div>
                                <hr className="horizontal_border_style"></hr>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-1 text-left text-xl-right">
                                            External Comments (Public)
                                        </label>
                                        <div className="col-12 col-xl-11 pl-0">
                                            <TextBox height="100" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-1 text-left text-xl-right">
                                            Internal Comments (Private)
                                        </label>
                                        <div className="col-12 col-xl-11 pl-0">
                                            <TextBox height="100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="card card-form my-5">
                            <div className="card-body">
                                <h3 className="card-form__section-heading">Adjustment</h3>
                                <hr className="horizontal_border_style"></hr>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pr-0">
                                        <div className="row">
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Small Field Label
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Small Field Label
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pr-0">
                                        <div className="row">
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Small Field Label
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 row mx-0 mt-3">
                                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                    Small Field Label
                                                </label>
                                                <div className="col-12 col-xl-6 pl-0">
                                                    <TextBox />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 row mx-0 pr-0 mt-3">
                                        <p className="text-danger font-weight-bold mx-auto">
                                            This change will be subject to client approval for the new cost of £90834.09
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="card card-form my-5">
                            <div className="card-body">
                                <h3 className="card-form__section-heading">Financials</h3>
                                <hr className="horizontal_border_style"></hr>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox className="card-form__field--narrow" value="Short Field" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox className="card-form__field--narrow" value="Short Field" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox className="card-form__field--narrow" value="Short Field" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-5 pl-0">
                                            <TextBox />
                                        </div>
                                        <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                            <button className="btn btn-primary btn--small">Cancel</button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Field Label
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="card-form__button-container">
                            <div className="col-12 col-lg-4 col-xl-2">
                                <button className="btn btn-primary btn--large mt-3">
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                    Apply
                                </button>
                            </div>
                            <div className="col-12 col-lg-4 col-xl-2">
                                <button className="btn btn--ghost btn--ghost--teal btn--large mt-3">Close</button>
                            </div>
                            <div className="col-12 col-lg-4 col-xl-2">
                                <button className="btn btn--ghost btn--ghost--teal btn--large mt-3">
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faQuestion} />
                                    </span>
                                    Add Shift
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ManageShiftsDesign);
