import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import HighLevelGigDetailedCard from "../GigListHighLevel/HighLevelGigDetailedCard";
import DarkModePageTemplate from "./Templates/DarkModePageTemplate";

interface HighLevelDetailedGigPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

class HighLevelDetailedGigPage extends Component<HighLevelDetailedGigPageComponentProps> {
    render() {
        return (
            <DarkModePageTemplate>
                <div className="page-content">
                    <section className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-5 mb-3">
                                <HighLevelGigDetailedCard location={this.props.location} history={this.props.history} />
                            </div>
                        </div>
                    </section>
                </div>
            </DarkModePageTemplate>
        );
    }
}

export default withRouter(HighLevelDetailedGigPage);
