import React, { Component, MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import DataGrid, { Paging, HeaderFilter,Search, FilterRow, Column, Export, FilterPanel, GroupPanel, Pager, SearchPanel, Format, StateStoring, Scrolling } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import PaymentService from "../../services/PaymentService";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import MatchIdActionCell from "./MatchIdActionCell";
import PaymentQueryActionCell from "./PaymentQueryActionCell";
import PayRequestViewActionCell from "./PayRequestViewActionCell";
import PayRequestEditActionCell from "./PayRequestEditActionCell";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { paymentRequestGridTypes } from "../../services/SimpleGridService";
import sharedUtils from "../grid/sharedUtilities";
import { PaymentTypes } from "../../services/FindAnArtistService";
import SimpleShiftGlobalFilterService from "../../services/SimpleShiftGlobalFilterService";
import TagCell from "../GigRequest/TagCell";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import { Link } from "react-router-dom";
import { confirm } from "devextreme/ui/dialog";
import { Popup, ScrollView } from "devextreme-react";
import SupplierInformationMainContent from "../SupplierInformation/SupplierInformationMainContent";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";

let isExport: boolean = false;

interface PaymentRequestGridProps extends WithTranslation {
    serviceTypeId: string,
    startDate: string,
    endDate: string,
    venueId: string,
    providerId: string,
    navigateFrom?: string,
    setPaymentGridRecordDataSource: (count: number, dataSource: any[]) => void;
    getBuild: (flag: boolean) => void;  
    resetRefreshGrid?: (flag: boolean) => void;
    gridType: string;
    isHide: boolean;
    providerPayAmount: number | undefined;
    paymentRequestId: string;
    paymentTypeId: string;
    location: any;
    history: any;
    paymentRequestType: string;
    isRefreshButtonClicked?: boolean;     
}

interface PaymentRequestGridState {
    dataSource: [];
    totalCount: number;
    showButton: boolean;
    expanded: boolean;
    expandedText: string;
    collapsedText: string;
    buttonText: string;
    parameterVal: string;
    promiseResponse: any;
    loadIndicatorForGrid: boolean;
    providerPopupVisibleEntertainment?: boolean;
    providerPopupVisibleOther?: boolean;
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    businessEntityId: string;
}

class PaymentRequestGrid extends Component<PaymentRequestGridProps> {
    state: PaymentRequestGridState;
    paymentService: PaymentService;
    sharedUtils: sharedUtils;
    remoteOperations: {
        sorting: boolean,
        paging: boolean,
        filtering: boolean,
        grouping: boolean,
        summary: boolean
    }
    isEditMode: string;
    dataGridRef: any;
    clearRef:any;
    constructor(props: PaymentRequestGridProps) {
        super(props);
        this.isEditMode = "";
        this.state = {
            dataSource: [],
            totalCount: 0,
            showButton: false,
            expanded: false,
            expandedText: "Close",
            collapsedText: "View more",
            buttonText: "",
            parameterVal: "",
            promiseResponse: {},
            loadIndicatorForGrid: false,
            providerPopupVisibleEntertainment: false,
            providerPopupVisibleOther: false,
            clientId: "",
            venueId: "",
            serviceTypeId: "",
            businessEntityId: "",
        }
        this.paymentService = new PaymentService();
        this.sharedUtils = new sharedUtils();
        this.remoteOperations = {
            sorting: true,
            paging: true,
            filtering: true,
            grouping: true,
            summary: false
        }
        //this.dataGridRef = React.createRef();
        //this.dataGridRef =React.useRef<DataGrid>(null);
        this.dataGridRef = null;
        this.clearRef = React.createRef;
        this.clearRef.current = false;

    }

    clearFiltersOnLoad_DrillDown = () =>{
        //this.clearRef.current = true;
        let navigateFrom = this.props.location.state && this.props.location.state.navigateFrom || '';
        if(navigateFrom && this.clearRef?.current){
            //if(navigateFrom == "AddJobPending" || navigateFrom == "AddJobQueried" || navigateFrom=='PaymentRequestQueried'){
                this.dataGridRef?.instance()?.clearFilter();
                this.clearRef.current = false;
            //}
        }
    }

    getResults = (loadOptions: any) => {
        this.clearFiltersOnLoad_DrillDown()
        let params = "";
        let paymentTypeId: string = "";
        if (this.props.paymentTypeId && this.props.paymentTypeId != "0") {
            paymentTypeId = this.props.paymentTypeId;
        }
        
        if (this.props.location.state && this.props.location.state.navigateFrom == "PaymentRequestPending") {
            let filters = [["paymentRequestStatusLookUp.value","=","Not Matched"],"or",["paymentRequestStatusLookUp.value","=","Draft"],"or",["paymentRequestStatusLookUp.value","=","Pending"]];
            params = buildRequestParameters(loadOptions,'filter',filters);
            params += `&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;

            //if(buildRequestParameters(loadOptions) !== "") params = buildRequestParameters(loadOptions) + `&filter=[["paymentRequestStatusLookUp.value","=","Not Matched"],"or",["paymentRequestStatusLookUp.value","=","Draft"],"or",["paymentRequestStatusLookUp.value","=","Pending"]]&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;
            //else params = buildRequestParameters(loadOptions) + `?filter=[["paymentRequestStatusLookUp.value","=","Not Matched"],"or",["paymentRequestStatusLookUp.value","=","Draft"],"or",["paymentRequestStatusLookUp.value","=","Pending"]]&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;
        }
        else if (this.props.location.state && this.props.location.state.navigateFrom == "AddJobPending") {
            let filters = [["paymentRequestStatusLookUp.value","=","Add Job Draft"],"or",["paymentRequestStatusLookUp.value","=","Add Job Pending"]];
            params = buildRequestParameters(loadOptions,'filter',filters);
            params += `&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;
           // if(buildRequestParameters(loadOptions) !== "") params = buildRequestParameters(loadOptions) + `&filter=[["paymentRequestStatusLookUp.value","=","Add Job Draft"],"or",["paymentRequestStatusLookUp.value","=","Add Job Pending"]]&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;
            //else params = buildRequestParameters(loadOptions) + `?filter=[["paymentRequestStatusLookUp.value","=","Add Job Draft"],"or",["paymentRequestStatusLookUp.value","=","Add Job Pending"]]&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;
        }
        else if (this.props.location.state && this.props.location.state.navigateFrom == "PaymentRequestQueried") {
            let filters = ["paymentRequestStatusLookUp.value","=","Queried"]
            params = buildRequestParameters(loadOptions,'filter',filters);
            params += `&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`
            
            //if(buildRequestParameters(loadOptions) !== "") params = buildRequestParameters(loadOptions) + `&filter=["paymentRequestStatusLookUp.value","=","Queried"]&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;
            //else params = buildRequestParameters(loadOptions) + `?filter=["paymentRequestStatusLookUp.value","=","Queried"]&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;
        }
        else if (this.props.location.state && this.props.location.state.navigateFrom == "AddJobQueried") {
            let filters = ["paymentRequestStatusLookUp.value","=","Add Job Queried"];
            params = buildRequestParameters(loadOptions,'filter',filters);
            params += `&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`
            //if(buildRequestParameters(loadOptions) !== "") params = buildRequestParameters(loadOptions) + `&filter=["paymentRequestStatusLookUp.value","=","Add Job Queried"]&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;
            //else params = buildRequestParameters(loadOptions) + `?filter=["paymentRequestStatusLookUp.value","=","Add Job Queried"]&dateRangeFrom=&dateRangeTo=&serviceTypeId=${this.props.location.state.serviceType}&unionVenueAndProviders=false&venueId=&providerId=&typeId=`;
        }
        else if (this.props.navigateFrom == "matchedPopUp" && this.props.paymentRequestId) {
            if(buildRequestParameters(loadOptions) !== "") params = buildRequestParameters(loadOptions) + `&dateRangeFrom=${this.props.startDate}&dateRangeTo=${this.props.endDate}&serviceTypeId=${this.props.serviceTypeId}&unionVenueAndProviders=${"true"}&venueId=${this.props.venueId}&providerId=${this.props.providerId}&hiddenPaymentRequestId=${this.props.paymentRequestId}&filterBy=${this.props.paymentTypeId}`;
            else params = buildRequestParameters(loadOptions) + `?dateRangeFrom=${this.props.startDate}&dateRangeTo=${this.props.endDate}&serviceTypeId=${this.props.serviceTypeId}&unionVenueAndProviders=${"true"}&venueId=${this.props.venueId}&providerId=${this.props.providerId}&hiddenPaymentRequestId=${this.props.paymentRequestId}&filterBy=${this.props.paymentTypeId}`;
        }
        else if (this.props.navigateFrom != "matchedPopUp") {
            if(buildRequestParameters(loadOptions) !== "") params = buildRequestParameters(loadOptions) + `&dateRangeFrom=${this.props.startDate}&dateRangeTo=${this.props.endDate}&serviceTypeId=${this.props.serviceTypeId}&unionVenueAndProviders=${"false"}&venueId=${this.props.venueId}&providerId=${this.props.providerId}&typeId=${paymentTypeId}`;
            else params = buildRequestParameters(loadOptions) + `?dateRangeFrom=${this.props.startDate}&dateRangeTo=${this.props.endDate}&serviceTypeId=${this.props.serviceTypeId}&unionVenueAndProviders=${"false"}&venueId=${this.props.venueId}&providerId=${this.props.providerId}&typeId=${paymentTypeId}`;
        }
        else if (isExport) {            
            params = `?dateRangeFrom=${this.props.startDate}&dateRangeTo=${this.props.endDate}&serviceTypeId=${this.props.serviceTypeId}&unionVenueAndProviders=${"false"}&venueId=${this.props.venueId}&providerId=${this.props.providerId}&typeId=${paymentTypeId}`;
        }
        if (params) {
            return this.paymentService.getPaymentGridRowsWithinRemoteOperations(params)
                .then(this.handleSuccessLoadDataSource)
                .catch(() => { throw 'Data Loading Error'; });
       }
        else {
            return this.paymentService.getPaymentGridRowsWithinRemoteOperations("")
            .then(this.handleSuccessLoadDataSource)
            .catch(() => { throw 'Data Loading Error'; });
       }
    }

    loadDataSource = () => {
        if(this.props.isRefreshButtonClicked == true) {
            this.props.resetRefreshGrid ? this.props.resetRefreshGrid(false) : null;
        }
        let dataSource = new CustomStore({
            key: "id",
            load: this.getResults,
        });

        this.setState({
            dataSource: dataSource
        });
    }

    handleSuccessLoadDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });
        // show notification for new build            
        let version = localStorage.getItem("BuildVersion");
        let headerVersion = response.headers["x-application-version"];
        // if(version == "" || version == null){
            //     version = getHeaderVersion;
            //     localStorage.setItem("BuildVersion", getHeaderVersion);
            // }        
            if(version !== headerVersion){
                localStorage.setItem("BuildVersion", headerVersion);
                this.props.getBuild(true);              
            }
        // end
        return {
            data: response.data.data ? response.data.data : [],
            totalCount: response.data.totalCount ? response.data.totalCount : 0
        };
    }

    componentDidMount() {
        if (this.props.gridType == paymentRequestGridTypes.matchedPopUp) {
            this.loadDataSource();
        }
        else {
            this.setState({
                loadIndicatorForGrid: true
            });
            SimpleShiftGlobalFilterService.removeDateFromvalue();
            SimpleShiftGlobalFilterService.removeDateToValue();
        }

        if(this.props?.location?.state?.pointOfNavigation == 'home')
        {
            this.clearRef.current = true;
        }
    }
    componentDidUpdate(prevProps: PaymentRequestGridProps) {
        if (prevProps.venueId != this.props.venueId || prevProps.providerId != this.props.providerId || prevProps.serviceTypeId != this.props.serviceTypeId || prevProps.startDate != this.props.startDate 
            || prevProps.endDate != this.props.endDate || prevProps.paymentTypeId != this.props.paymentTypeId || (this.props.isRefreshButtonClicked != prevProps.isRefreshButtonClicked && this.props.isRefreshButtonClicked == true)) {
            this.loadDataSource();
        }
    }

    venueCellDisplayContent = (cellInfo: any) => {
        var isShowInColour: boolean = false;
        var x = this.props.venueId;
        if (this.props.gridType == paymentRequestGridTypes.matchedPopUp && (cellInfo.data.venue?.id.toString() == this.props.venueId)) {
            {
                isShowInColour = true;
            }
        }
        return (
            <div className="container-fluid">
                <div className="row">
                    {isShowInColour ? <span className="grid-info__text_color_green">{cellInfo.data.venue?.venueName}</span> : cellInfo.data.venue?.venueName}
                </div>
            </div>
        );
    };

    typeCellDisplayContent = (cellInfo: any) => {
        var type: string = "";
        if (cellInfo && cellInfo.data.paymentRequestType && cellInfo.data.paymentRequestType.id == PaymentTypes.PaymentRequest) {
            type = "Payment";
        }
        else if (cellInfo && cellInfo.data.paymentRequestType && cellInfo.data.paymentRequestType.id == PaymentTypes.AddJob) {
            type = "Job";
        }

        return (
            <div className="container-fluid">
                <span>{type}</span>
            </div>
        );
    }

    showInfoProvider = (clientId: string, venueId: string, serviceTypeId: string, businessEntityId: string) => {
        this.setState({
            clientId: clientId,
            venueId: venueId,
            serviceTypeId: serviceTypeId,
            businessEntityId: businessEntityId,

        });
        if (serviceTypeId == "30") {
            this.setState({
                providerPopupVisibleOther: false,
                providerPopupVisibleEntertainment: true,
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
                providerPopupVisibleOther: true,
            });
        }
    };   

    providerCellDisplayContent = (cellInfo: any) => {
        var provider: string = cellInfo.data.providerBusinessEntity.shortName;
        var isShowInColour: boolean = false;
        if (this.props.gridType == paymentRequestGridTypes.matchedPopUp && (cellInfo.data.providerBusinessEntity.id.toString() == this.props.providerId)) {
            isShowInColour = true;
        }
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.clientBusinessEntity.id, cellInfo.data.venue.id, cellInfo.data.serviceType.id, cellInfo.data.providerBusinessEntity.id);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {isShowInColour ? <span className="grid-info__text_color_green">{provider}</span> : provider}
                </Link>
            </div>
            
        );
        
    };

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
            });
        }
    };

    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
        });
    }
    hideInfoProvider = (e: any) => {
        this.setState({
            providerPopupVisibleOther: false,
        });
    };

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    displayBooleanContent = (Val:any)=>{
        let valToShow: string = "";
        if (Val) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                <span>{valToShow}</span>
            </div>
        );
    }

    dateCellDisplayContent = (cellInfo: any) => {
        let date: string = cellInfo.text;
        let isShowInColour: boolean = false;
        if ((this.props.gridType == paymentRequestGridTypes.matchedPopUp) && (cellInfo.text && cellInfo.text == this.props.startDate)) {
            isShowInColour = true;
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{date}</span> : date}
            </div>
        );
    };

    providerNetCellDisplayContent = (cellInfo: any) => {
        var providerAmount: number = cellInfo.data.providerPayAmountLocal;
        var providerPayAmount: string = providerAmount.toString().slice(1);
        var providerAmountVal: number = parseInt(providerPayAmount);
        var isShowInColour: boolean = false;
        if ((this.props.gridType == paymentRequestGridTypes.matchedPopUp) && (providerAmountVal && providerAmountVal == this.props.providerPayAmount)) {
            isShowInColour = true;
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{this.sharedUtils.thousandsSeparator(providerAmount.toString())}</span> : this.sharedUtils.thousandsSeparator(providerAmount.toString())}
            </div>
        );
    };
    fasterPaymentCellDisplayContent = (cellInfo: any) => {
        let fasterpayVal = cellInfo.data.fasterPayment;
        let isShowInColour: boolean = false;
        let valToShow: string = "";
        if (fasterpayVal) {
            isShowInColour = true;
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{valToShow}</span> : valToShow}
            </div>
        );

    }

    onboardedCellDisplayContent = (cellInfo: any) => {
        let onboardedVal = cellInfo.data.venue?.onboarded;
        let valToShow: string = "";
        if (onboardedVal) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {valToShow}
            </div>
        );

    }

    onContentReady = (e: any) => {
        var totalCount: number = 0;
        var items: any[] = [];
        if (e.component.getDataSource().totalCount()) {
            totalCount = e.component.getDataSource().totalCount();
            items = e.component.getDataSource().items();
        }
        this.props.setPaymentGridRecordDataSource(totalCount, items);
    }

    setCollapsibleSection = (totalCount: number) => {
        if (totalCount > 0) {
            this.setState({
                showButton: true,
                expanded: false,
                buttonText: this.state.collapsedText
            });
        }
    }

    renderRequestDateCellDisplayContent = (cellInfo: any) => {
        var requestDate: string = cellInfo.data.requestDate.toString();
        var requestDateParts: string[] = requestDate.split(" ");
        var part1 = requestDateParts[0];
        var part2 = requestDateParts[1];
        var valueToShow: string = part1 + " " + part2;
        var isShowInColour: boolean = false;
        if (this.props.gridType == paymentRequestGridTypes.matchedPopUp) {
            isShowInColour = true;
        }
        return (
            <div className="container-fluid">
                {<span>{valueToShow}</span>}
            </div>
        );
    }

    onCustomGridLoad = () => {
        if (this.props.gridType == paymentRequestGridTypes.matchedPopUp) {
            return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.paymentRequestGridMatched);
        }
        else {
            return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.paymentRequestGridForm);
        }
    }

    onCustomGridSave = (gridState: Object) => {
        if (this.props.gridType == paymentRequestGridTypes.matchedPopUp) {
            CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.paymentRequestGridMatched, gridState);
        }
        else {
            CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.paymentRequestGridForm, gridState);
        }
    }

    renderPaymentGrid = () => {
        var isProvider = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler);
        return (

            <DataGrid
                elementAttr={{
                    id: "gridContainer"
                }}
                ref={(ref) => { this.dataGridRef = ref; }}
                dataSource={this.state.dataSource}
                showBorders={false}
                showColumnLines={false}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                columnResizingMode={"widget"}
                hoverStateEnabled={true}
                wordWrapEnabled={true}
                remoteOperations={this.remoteOperations}
                noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                onContentReady={this.onContentReady}
                onExporting={this.onExporting}
            >
                <Paging defaultPageSize={this.props.navigateFrom == "matchedPopUp" ? 5 : 10} />
                <Scrolling useNative={true} showScrollbar={"always"} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                <HeaderFilter visible={true}>
                        <Search enabled={true}/> 
                </HeaderFilter>
                <Export enabled={true} />
                <FilterRow visible={true} applyFilter="auto" />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <StateStoring
                    enabled={true}
                    type="custom"
                    customLoad={this.onCustomGridLoad}
                    customSave={this.onCustomGridSave}
                />
                <SearchPanel visible={true} placeholder={"Search"} />
                <Column
                    width="120"
                    caption="MATCHED"
                    cellComponent={MatchIdActionCell}
                    allowExporting={false}
                />
                <Column
                    width="120"
                    caption="QUERY"
                    cellComponent={PaymentQueryActionCell}
                    allowExporting={false}
                />
                {isProvider ? null :
                    <Column
                        width="120"
                        caption="CHANGE"
                        cellComponent={PayRequestEditActionCell}
                        allowExporting={false}
                    />}
                <Column
                    width="120"
                    caption="VIEW"
                    cellComponent={PayRequestViewActionCell}
                    allowExporting={false}
                />
                <Column
                    caption="TAG ME"
                    cellComponent={TagCell}

                ></Column>
                <Column
                    dataField="taggedUser.name"
                    caption="TAGGED"
                    width="120"
                />
                <Column
                    dataField="id"
                    caption="ID"
                />
                <Column
                    dataField="paymentRequestType.value"
                    caption="TYPE"
                    cellRender={this.typeCellDisplayContent}
                />
                <Column
                    dataField="paymentRequestStatusLookUp.value"
                    caption="STATUS"
                />
                <Column
                    dataField="providerBusinessEntity.shortName"
                    caption="PROVIDER/ARTIST"
                    cellRender={this.providerCellDisplayContent}
                />
                <Column
                    dataField="providerBusinessEntity.id"
                    caption="PROVIDER ID"
                />
                <Column
                    dataField="providerBusinessEntity.professionalName"
                    caption="ACT NAME"
                />
                <Column
                        dataField="providerBusinessEntity.verified"
                        caption="BUSINESS SET-UP VERIFIED"
                        // cellRender={this.feedbackCellDisplayContent}
                        cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.providerBusinessEntity?.verified) }}
                        dataType="boolean"
                    />
                    <Column
                        dataField="providerBusinessEntity.vatVerified"
                        caption="VAT VERIFIED"
                        cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.providerBusinessEntity?.vatVerified) }}
                        dataType="boolean"
                    //cellRender={this.feedbackCellDisplayContent}
                    />
                    <Column
                        dataField="bankAccountVerified"
                        caption="BANK ACCOUNT VERIFIED"
                        cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.bankAccountVerified) }}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        // dataType="boolean"
                    //cellRender={this.feedbackCellDisplayContent}
                    />
                <Column
                    caption="DATE"
                    dataField="dateFrom"
                    cellRender={this.dateCellDisplayContent}
                    format="dd/MM/yyyy"
                    dataType="date"
                />
                <Column
                    caption="PROVIDER NET"
                    dataField="providerPayAmountLocal"
                    cellRender={this.providerNetCellDisplayContent}
                ><Format type="currency" currency='GBP' precision={2}></Format>
                </Column>
                <Column
                    dataField="providerPayAmountLocalExtras"
                    caption="EXTRAS"
                    dataType="number"
                >
                    <Format type="currency" currency='GBP' precision={2}></Format>
                </Column>
                <Column
                    caption="FASTER PAY"
                    dataField="fasterPayment"
                    cellRender={this.fasterPaymentCellDisplayContent}
                />
                <Column
                    dataField="clientBusinessEntity.shortName"
                    caption="CLIENT"
                />
                <Column
                    dataField="venue.venueName"
                    caption="VENUE"
                    cellRender={this.venueCellDisplayContent}
                />
                <Column
                    dataField="venue.houseNumber"
                    caption="VENUE NO"
                />
                <Column 
                    dataField="venue.onboarded" 
                    caption="ONBOARDED" 
                    cellRender={this.onboardedCellDisplayContent}
                />
                <Column
                    dataField="serviceType.value"
                    caption="SERVICE"
                />
                <Column
                    dataField="requestDate"
                    caption="REQUEST DATE"
                    format="dd/MM/yyyy"
                    dataType="date"
                // cellRender={this.renderRequestDateCellDisplayContent}
                />
                <Column
                    dataField="description"
                    caption="DESCRIPTION"
                />
                <Column
                    dataField="comments"
                    caption="COMMENTS"
                />
                <Column
                    dataField="internalComments"
                    caption="INTERNAL COMMENTS"
                />
            </DataGrid>
        );
    }


    render() {
        return (
            <>
                {this.props.navigateFrom == "matchedPopUp" ?
                    <>
                        <section className="card card-form my-5" hidden={this.props.isHide}>
                            <div className="card-body">
                                {this.renderPaymentGrid()}
                            </div></section>
                    </>

                    :
                    <LightModePageTemplate>
                        {this.renderPaymentGrid()}
                    </LightModePageTemplate>
                }
                <Popup
                    visible={this.state.providerPopupVisibleOther}
                    onHiding={this.hideInfoProvider}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Supplier Information & Venue Conditions"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <SupplierInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            serviceTypeId={this.state.serviceTypeId}
                            navigationFromShift="SimpleShiftGrid"
                        ></SupplierInformationMainContent>
                    </ScrollView>
                </Popup>
                {
                    this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            hideOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title="Artist Details"
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <ArtistProfileComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    businessEntityId={this.state.businessEntityId}
                                    handleFeedbackUpdate={() => { return null }}
                                    handleAddArtist={() => { return null }}
                                    serviceType={"Entertainment"}
                                ></ArtistProfileComponent>
                            </ScrollView>
                        </Popup>
                    ) : (null)
                }
            </>
        );
    }
}

export default withTranslation()(PaymentRequestGrid);