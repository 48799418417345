import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import { DateBox, LoadPanel, TextBox, CheckBox, NumberBox, TextArea } from "devextreme-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import {
    DateBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
    NumberBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import SubstituteFormUtil, { AddEditSubstituteFormData } from "./SubstituteFormUtils";
import EntertainmentSubstituteService from "../../services/EntertainmentSubstituteService";

type NullableDate = Date | null | undefined;
//#region props
interface EntertainmentSubstituteFormProps extends WithTranslation {
    location: any;
    history: any;
    onApplySuccess: (applySignal: string) => void;
    isEditForm: any;
    subRowId: string;
    resId: string;
    isActive: string;
    dateFrom: string;
    dateTo: string;
    day: string;
    handleFormOpenClose: (applySignal: string, id: string) => void;
}

// State
interface EntertainmentSubstituteFormState {
    //TODO : Define the form state here.
    editPeriodFormData: AddEditSubstituteFormData;
    loadPanelVisible: boolean;
    disableDayTextbox: boolean;
    filterId: string;
    errorMessage: [];
    showError: boolean;
    //weekLookUp: LookupTypeItem[];
}

class EntertainmentSubstituteForm extends React.Component<EntertainmentSubstituteFormProps> {
    lookupService: LookupService;
    state: EntertainmentSubstituteFormState;
    utils: SubstituteFormUtil;
    service: EntertainmentSubstituteService;

    constructor(props: EntertainmentSubstituteFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.utils = new SubstituteFormUtil();
        this.service = new EntertainmentSubstituteService();
        var convertedEditPeriodData = this.utils.initializePeriodFormItem(
            props && props.subRowId ? props.subRowId : ""
        );

        this.state = {
            editPeriodFormData: convertedEditPeriodData,
            loadPanelVisible: false,
            disableDayTextbox: false,
            filterId: "20",
            errorMessage: [],
            showError: false,
        };
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        this.getEditViewItemDataSource();
    }

    getEditViewItemDataSource = () => {
        if (this.props && this.props.subRowId) {
            this.service
                .getSubstituteDataItem(this.props.subRowId)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleError);
        }
    };

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        var viewDataSource = this.utils.convertSubstituteItemResponeToFormData(response.data.data);
        this.setState({
            editPeriodFormData: viewDataSource,
        });
    };

    handleError = (err: any) => {
        this.setState({
            errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
            showError: true,
            loadPanelVisible: false,
        });
        var respMessage: string = "Get Substitute data item service failed with response: " + JSON.stringify(err);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    onCancelClick() {
        // this.props.onApplySuccess("CANCEL");
        this.props.handleFormOpenClose("CLOSE", "");
    }
    onApplyClick() {
        this.setState({
            loadPanelVisible: true,
        });
        var periodSubmitRequest = this.utils.convertFormDataToSubstituteItemRequest(
            this.state.editPeriodFormData,
            this.props.resId,
            this.props.isActive,
            this.props.dateFrom,
            this.props.dateTo,
            this.props.day
        );

        this.service
            .saveSubstituteDataItem(periodSubmitRequest)
            .then(this.handleSuccessDataSubmission)
            .catch(this.handleError);
    }

    handleChangeRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                rate: dxValueChange.value,
            },
        });
    };

    handleChangeDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                date: dxValueChange.value,
            },
        });
    };

    handleChangeActive = (dxValueChange: CheckBoxOnValueChanged) => {
        var activeFlag = dxValueChange.value;
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                isActive: activeFlag ? "True" : "False",
            },
        });
    };

    handleChangeProvider = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                businessEntityId: dxValueChange.value,
            },
        });
    };

    handleChangeFee = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                feeOverride: dxValueChange.value,
            },
        });
    };

    handleChangeInternalComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                internalComments: dxValueChange.value,
            },
        });
    };
    handleChangeOverrideFlag = (dxValueChange: CheckBoxOnValueChanged) => {
        var overRideFlag = dxValueChange.value;
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                feeOverrideFlag: overRideFlag ? "True" : "False",
            },
        });
    };

    handleSuccessDataSubmission = () => {
        this.setState({
            loadPanelVisible: false,
        });

        if (this.props && this.props.subRowId) {
            this.props.onApplySuccess("EDIT");
        } else if (this.state.editPeriodFormData.id == "0") {
            this.props.onApplySuccess("ADD");
        }
    };

    filterDataSource = (data: LookupTypeItem[], filterId: string): LookupTypeItem[] => {
        return !this.isBlank(filterId)
            ? data.filter((item: LookupTypeItem) => {
                  return item.id === filterId;
              })
            : data;
    };

    isBlank(filterId: string) {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    }

    render() {
        return (
            <form data-testid="addEditShift-form" className="edit-form my-5 form_border">
                <div className="container mb-6 edit-form__column">
                    <div className="row pt-3">
                        <h2 className="billingForm-heading">Substitute Form</h2>
                    </div>
                    <br></br>
                    <div className="row justify-content-left pt-3" hidden={!this.state.showError}>
                        <span className="text-danger font-weight-bold text-left">
                            <ul>
                                {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                    return (
                                        <li key={uniqueKey}>
                                            {item.columnName}: {item.errorMessage}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    </div>
                    <div className="row" hidden={!this.props.isEditForm}>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">ID</div>
                        <div className="mt-3 col-10 col-lg-2">
                            <TextBox value={this.state.editPeriodFormData.id} readOnly={true} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Artist</div>
                        <div className="mt-3 col-10 col-lg-2">
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.providerType}
                                onValueChanged={this.handleChangeProvider}
                                value={this.state.editPeriodFormData.businessEntityId}
                            />
                        </div>

                        <div className="mt-3 col-2 col-lg-2">
                            <CheckBox
                                value={this.state.editPeriodFormData.isActive != "False" ? true : false}
                                onValueChanged={this.handleChangeActive}
                                width={130}
                                text="Is Active?"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Date</div>
                        <div className="mt-3 col-10 col-lg-2">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeDate}
                                value={
                                    this.state.editPeriodFormData.date ? this.state.editPeriodFormData.date : undefined
                                }
                                useMaskBehavior={true}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Rate</div>
                        <div className="mt-3 col-10 col-lg-2">
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                onValueChanged={this.handleChangeRate}
                                valueChangeEvent="keyup"
                                value={this.state.editPeriodFormData.rate}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Override Fee?</div>
                        <div className="mt-3 residency_form_active_checkbox">
                            <CheckBox
                                value={this.state.editPeriodFormData.feeOverrideFlag != "False" ? true : false}
                                onValueChanged={this.handleChangeOverrideFlag}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Override Fee</div>
                        <div className="mt-3 col-10 col-lg-2">
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                onValueChanged={this.handleChangeFee}
                                valueChangeEvent="keyup"
                                value={this.state.editPeriodFormData.feeOverride}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Internal comments</div>
                        <div className="mt-3 col-10 col-lg-11">
                            <TextArea
                                height={50}
                                value={this.state.editPeriodFormData.internalComments}
                                onValueChanged={this.handleChangeInternalComments}
                                valueChangeEvent="keyup"
                            />
                        </div>
                    </div>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                            Last Updated On
                        </div>
                        <div className="mt-3 col-10 col-lg-2" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editPeriodFormData.lastUpdatedOn} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                            Last Updated By
                        </div>
                        <div className="mt-3 col-10 col-lg-3" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editPeriodFormData.lastUpdatedBy} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size"></div>
                        <div
                            className="mt-3 col-2 col-lg-7 font-weight-bold font_size"
                            hidden={this.props.isEditForm}
                        ></div>
                        <div className="col-12 col-lg-2 mt-3">
                            <Link
                                to={{ pathname: "" }}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onCancelClick();
                                }}
                                className="btn btn--ghost btn--large"
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                                {this.props.t("addEditShiftRow.financeSection.cancelButton.text")}
                            </Link>
                        </div>
                        <div className="col-12 col-lg-2 mt-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="button"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onApplyClick();
                                }}
                            >
                                <span className="btn__icon"></span>
                                {this.props.t("addEditShiftRow.financeSection.applyButton.text")}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default withTranslation()(EntertainmentSubstituteForm);
