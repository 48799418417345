import React from "react";
import { Popup } from "devextreme-react";

interface FinancialAdjustmentCardHeaderSectionProps {
    title?: string;
    venue: string;
    postcode?: string;
    date: string;
    message: string;
    adjustment?: boolean;
    errorMessage?: string;
    clearErrorMessage?: any; //to allow the closing of the error popup
    calculationErrorMessage?: string[]; //Only for Security personnel cards.
    licenseNumber?: string; // for security personnel cards
    clientOrProviderName: string;
}

class FinancialAdjustmentCardHeaderSection extends React.Component<FinancialAdjustmentCardHeaderSectionProps> {
    constructor(props: FinancialAdjustmentCardHeaderSectionProps) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="text-center">
                    {this.props.calculationErrorMessage ? (
                        <span className="text-danger font-weight-bold">
                            <ul>
                                {this.props.calculationErrorMessage.map((item: any, uniqueKey: number) => {
                                    return (
                                        <li key={uniqueKey}>
                                            {item.columnName}: {item.errorMessage}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    ) : null}
                    <header>
                        {this.props.adjustment ? null : <div className="euk-card__adjustment-label">Adjustment</div>}
                        <div className="euk-card__title">{this.props.title ? this.props.title : ""}</div>
                        <div className="euk-card__header-venue">{this.props.clientOrProviderName ? this.props.clientOrProviderName : ""}</div>
                        <div className="euk-card__header-venue">{this.props.venue ? this.props.venue : ""}</div>
                        <div className="euk-card__header-date">{this.props.date}</div>
                        <div className="euk-card__message">{this.props.message ? this.props.message : ""}</div>
                    </header>
                </div>
                <Popup
                    className={"pop-up--small"}
                    visible={this.props.errorMessage !== undefined && this.props.errorMessage !== ""}
                    onHiding={this.props.clearErrorMessage}
                    title="Error"
                >
                    <span className="my-2 d-block text-center">{this.props.errorMessage}</span>
                </Popup>
            </>
        );
    }
}
export default FinancialAdjustmentCardHeaderSection;