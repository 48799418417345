import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError} from "./BaseApiService";

const PROVIDER_PAGE_API = "BusinessEntity/Profile";
const PROVIDER_PAGE_API_SAVE = "BusinessEntity/Profile/TermsAgreed"

export interface providerProfileResponse {
    businessEntityID: string;
    businessEntityTypeLookUpId: string;
    businessEntityType: string;
    legalName: string;
    tradingName: string;
    shortName: string;
    businessEntityLegalStatusLookUpId: string;
    businessEntityLegalStatus: string;
    companyNumber: string;
    tax_Applicable: string;
    openingHours: string;
    webSite: string;
    officePhone: string;
    email: string;
    provider_Payment_Offset_Days: string;
    taxRegistrationNumber: string;
    addressStreet1: string;
    addressStreet2: string;
    addressStreet3: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
    professionalName: string;
    score: string;
    bio: string;
    shortDescription: string;
    coverageMiles: string;
    termsAgreed: string;
    businessEntityStatusId: string;
    businessEntityStatus: string;
    termsAgreedDateTime: string;
    venueFavourite: string;
    lastPlayedVenue: string;
    isActive: string;
    contactInformation: {
        contactId: string;
        title: string;
        name: string;
        firstName: string;
        lastName: string;
        mobileTelephone: string;
        businessTelephone: string;
        otherTelephone: string;
        email: string;
        addressStreet1: string;
        addressStreet2: string;
        city: string;
        state: string;
        postCode: string;
        emailNotification: string;
        dateOfBirth: string;
        utr: string;
        ni: string;
    };
    providerSiteManagerInformation: {
        contactId: string;
        title: string;
        firstName: string;
        lastName: string;
        name: string;
        mobileTelephone: string;
        businessTelephone: string;
        otherTelephone: string;
        email: string;
        addressStreet1: string;
        addressStreet2: string;
        city: string;
        state: string;
        postCode: string;
        emailNotification: string;
        dateOfBirth: string;
        utr: string;
        ni: string;
    };
    bankInformation: {
        id: string;
        businessEntityID: string;
        contactID: string;
        bankName: string;
        branchName: string;
        accountNumber: string;
        sortCode: string;
        buildingSociety: string;
        nameOnAccount: string;
        active: string;
        serviceTypeLookUpId: string;
        serviceSubTypeLookUpId: string;
    };
    requirePayment: string;
    requirePaymentComment: string;
    artistApart: string;
    linkInformation: [
        {
            id: string;
            businessEntityId: string;
            linkTypeId: string;
            linkTypeName: string;
            linkTypeOther: string;
            link: string;
            displayLink: string;
            active: string;
            lastUpdatedOn: string;
            lastUpdatedBy: string;
        }
    ];
    typeList: [
        {
            id: number;
            providerBusinessEntityId: number;
            serviceTypeLookUpId: number;
            serviceSubTypeLookUpId: number;
            typeLookUpId: number;
            lastUpdatedOn: string;
            lastUpdatedBy: number;
            providerBusinessEntity: string;
            serviceTypeLookUp: string;
            serviceSubTypeLookUp: string;
            typeLookUp: string;
            user: string;
        }
    ];
    businessEntityDocument: [
        {
            id: string;
            name: string;
            link: string;
            displayLink: string;
            formatType: string;
            documentType: string;
            dateFrom: string;
            dateTo: string;
            updatedBy: string;
            updatedOn: string;
        }
    ];
    validationErrors: string;
    postSignUpMessages: string | null;
}

class ProviderProfileService {
    getProviderPageData(): Promise<AxiosResponse<any>> {
        return _get(PROVIDER_PAGE_API);
    }

    getProviderPageDataById(businessEntityId: number): Promise<AxiosResponse<any>> {
        return _get(`${PROVIDER_PAGE_API}/${businessEntityId}`);
    }

    saveProfilePageData(profileData: object): Promise<AxiosResponse<any>> {
        return _post(PROVIDER_PAGE_API_SAVE, profileData);
    }
    //#endregion
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}
export default ProviderProfileService;
