import React from "react";
import DataGrid, { Pager, Paging, Export, FilterRow, Column, Scrolling, SearchPanel } from "devextreme-react/data-grid";
import { DataGridOnInitializedEvent } from "../../types/DevExtremeTypes";
import BillableItemHeaderService, { BudgetUploadChildGridRowItem } from "../../services/BillableItemHeaderService";
import gridUtils from "../grid/GridUtilities";
import { AxiosResponse } from "axios";
import StatusColour from "../grid/StatusColour";
import ErrorMessage from "../grid/ErrorMessage";
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
// Props
//  - refreshSignal will indicate that a grid refresh is required.
//  - gridVisibilitySignal is going to signal when the grid will be shown.
interface BudgetUploadFileContentsGridProps {
    isChildGridVisible: boolean;
    headerId: string;
    gridType: string;
}

// State
interface BudgetUploadFileContentsGridState {
    budgetUploadChildGridDataSource: BudgetUploadChildGridRowItem[];
}

// Component - displays both the header and child grids
class BudgetUploadFileContentsGrid extends React.Component<BudgetUploadFileContentsGridProps> {
    state: BudgetUploadFileContentsGridState;
    gridUtils: gridUtils;
    dataGridElement: any;
    service: BillableItemHeaderService;

    constructor(props: BudgetUploadFileContentsGridProps) {
        super(props);
        this.state = {
            budgetUploadChildGridDataSource: [],
        };

        this.gridUtils = new gridUtils();
        this.service = new BillableItemHeaderService();
        this.dataGridElement = undefined;
        this.handleFileUploadOnInitialize = this.handleFileUploadOnInitialize.bind(this);
    }

    // Initialize state from server
    componentDidMount() {
        this.updateChildGridDataSource(this.props.headerId);
    }

    updateChildGridDataSource = (headerId: string) => {
        if(this.props.gridType == "19"){
            if (headerId) {
                this.service
                    .getChildGridDataForPeriodMatrixUpload(headerId)
                    .then(this.handleFileUploadSuccess)
                    .catch(this.handleFailure);
            }
        }
        else{
            if (headerId) {
                this.service
                    .getChildGridDataForBudgetUpload(headerId)
                    .then(this.handleFileUploadSuccess)
                    .catch(this.handleFailure);
            }
        }        
    };

    handleFileUploadSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            budgetUploadChildGridDataSource: response.data.data,
        });
    };

    handleFailure = (error: any) => {
        var respMessage: string = "getChildGridDataForVenueHierarchy failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    componentDidUpdate(prevProps: BudgetUploadFileContentsGridProps) {
        if (this.props.headerId !== prevProps.headerId || this.props.gridType !== prevProps.gridType) {
            // refresh the grid
            this.updateChildGridDataSource(this.props.headerId);
        }
    }

    // Set the file uploader component
    handleFileUploadOnInitialize(e: DataGridOnInitializedEvent) {
        this.dataGridElement = e.component;
    }
    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    render() {
        return (
            <div>
                <div className="childGrid mt-5">
                    <h4 className="font-weight-bold mb-3" hidden={!this.props.isChildGridVisible}>
                        View Selected File Contents
                    </h4>
                    { this.props.gridType == "19" ? 
                        <DataGrid
                            dataSource={this.state.budgetUploadChildGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            onExporting={this.onExporting}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <Paging defaultPageSize={1} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[1, 5, 10, 20]} showInfo={true} />
                            <Column dataField="id" caption="ID" />
                            <Column dataField="fileUploadHeaderId" caption="HEADER ID" />
                            <Column dataField="business_Entity" caption="CLIENT SHORT NAME" />
                            <Column dataField="yearNumber" caption="YEAR" />
                            <Column dataField="periodNumber" caption="PERIOD" />
                            <Column dataField="periodWeek" caption="WEEK" />
                            <Column dataField="dayOfWeek" caption="WEEK START DAY" />
                            <Column dataField="dateFrom" caption="START" />
                            <Column dataField="dateTo" caption="END" />
                            <Column dataField="active" caption="Active" />
                            <Column dataField="fileContentUploadStatus" cellComponent={StatusColour} />
                            <Column
                                dataField="errorMessage"
                                caption="Error Message"
                                cellComponent={ErrorMessage}
                            />
                            <Column dataField="lastUpdatedByUser" caption="UPDATED BY" />
                            <Column dataField="lastUpdatedDateTime" caption="UPDATED ON" />
                            <FilterRow visible={true} applyFilter="auto" />
                            <Scrolling mode="standard" useNative={true} scrollByThumb={true} />
                        </DataGrid>
                        : <DataGrid
                            dataSource={this.state.budgetUploadChildGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            onExporting={this.onExporting}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <Paging defaultPageSize={1} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[1, 5, 10, 20]} showInfo={true} />
                            <Column dataField="id" caption="ID" />
                            <Column dataField="fileUploadHeaderId" caption="HEADER ID" />
                            <Column dataField="clientBusinessEntityName" caption="CLIENT SHORT NAME" />
                            <Column dataField="houseNumber" caption="HOUSE NUMBER" />
                            <Column dataField="venueName" caption="VENUE NAME" />
                            <Column dataField="serviceType" caption="SERVICE" />
                            <Column dataField="periodYear" caption="YEAR" />
                            <Column dataField="periodNumber" caption="PERIOD" />
                            <Column dataField="periodWeek" caption="WEEK" />
                            <Column dataField="periodStart" caption="START" />
                            <Column dataField="periodEnd" caption="END" />
                            <Column dataField="amountValue" caption="AMOUNT" />
                            <Column dataField="fileContentUploadStatus" cellComponent={StatusColour} />
                            <Column
                                dataField="errorWarningMessage"
                                caption="Error Message"
                                cellComponent={ErrorMessage}
                            />
                            <Column dataField="lastUpdatedBy" caption="UPDATED BY" />
                            <Column dataField="lastUpdatedOn" caption="UPDATED ON" />
                            <FilterRow visible={true} applyFilter="auto" />
                            <Scrolling mode="standard" useNative={true} scrollByThumb={true} />
                        </DataGrid>
                    }
                </div>
            </div>
        );
    }
}

export default BudgetUploadFileContentsGrid;
