import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError } from "./BaseApiService";

const COST_REPORT_DATES_API = "PeriodMatrix/CostReport/Dates";
const COST_REPORT_GRID_API = "CostReport";
const RAISE_QUERY_API = "Query";
const SIMPLE_SHIFTS_GRID_API = "BillableItem/simpleshift";
const INCIDENTS_GRID_API = "Incident";
const Residency_SHIFT_API = "Residencies/residencyshifts";
const ContractCleaning_SHIFT_API = "ContractHour/contractHourshifts";

export interface costReportDates {
    periodMatrixId: string;
    clientId: string;
    businessEntityId: string;
    periodNumber: string;
    periodWeek: string;
    dateFrom: string;
    dateTo: string;
    displayPeriod: string;
    dayRange: string;
}

export interface postApprovalDates {
    periodMatrixId: string;
    businessEntityId: string;
    periodNumber: string;
    periodWeek: string;
    dateFrom: string;
    dateTo: string;
    displayPeriod: string;
    dayOfWeekId : string;
    dayOfWeekValue : string;
    dayRange: string;
}

export interface queryItem {
    case_ID: string;
    billableItemId: string;
    d365_Link_ID: string;
    d365_Case_Incident_Id: string;
    date_Created: string;
    query_Title: string;
    query_Description: string;
    external_Comments: string;
    linkedId: string;
    venueId: string;
    last_Update: string;
    updated_By: string;
    query_Status: string;
    resolution_reason: string;
    lastUpdated_DateTime: string;
    lastUpdated_By_User_ID: string;
    businessEntityId: string;
    queryTypeLookUpId: string;
    requestId: string;
    queryPriorityId: string;
    user: string;
    billableItem: string;
    caseStatusLU: string;
    contact: string;
    businessEntity: string;
    venue: string;
    queryTypeLookUp: string;
    request: string;
    queryPriorityLookUp: string;
}

export interface costReportGridRowItem {
    billableItemId: string;
    venue: string;
    number: string;
    client: string;
    service: string;
    subType: string;
    role: string;
    type: string;
    provider: string;
    artistName: string;
    actName: string;
    date: string;
    name: string;
    expectedShift: string;
    performanceTime: string;
    billableTime: string;
    contractHours: string;
    rate: string;
    total: string;
    billPeriod: string;
    billDate: string;
    payDate: string;
    queries: queryItem[] | [];
    itemType: string;
    itemTypeId: string;
    onboarded: string;
    approvalDate: string;
    shiftAcknowledgmentDate: string;
}

export interface simpleShiftsGridRowItem {
    billableItemId: string;
    venueId: string;
    venue: string;
    number: string;
    client: string;
    service: string;
    subType: string;
    role: string;
    type: string;
    comments: string;
    provider: string;
    artistName: string;
    actName: string;
    date: string;
    dateTo: string;
    name: string;
    identificationNumber: string;
    checkInCheckOut: string;
    checkIn: string;
    checkOut: string;
    checkInFont: string;
    checkOutFont: string;
    checkInColor: string;
    checkOutColor: string;
    expectedShift: string;
    performanceTime: string;
    performanceDetails: string;
    billableTime: string;
    contractHours: string;
    rate: string;
    total: string;
    billPeriod: string;
    billDate: string;
    payDate: string;
    statusValue: string;
    statusId: string;
    tenseFlag: string;
    queries: queryItem[] | [];
    itemType: string;
    itemTypeId: string;
    clientBillableAmountLocal: string;
    onboarded: string;
    approvalDate: string;
    shiftAcknowledgmentDate: string;
}

export interface IncidentListGridRowItem {
    incidentId: string;
    houseNo: string;
    clientBusinessEntityId: string;
    clientBusinessEntityName: string;
    providerBusinessEntityId: string;
    providerBusinessEntityName: string;
    venueId: string;
    houseNoVenueName: string;
    venueNamePostCode: string;
    venueName: string;
    postCode: string;
    description: string;
    severity: string;
    injuries: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    ambulanceAttended: string;
    date: string;
    documentId: string;
    documentLink: string;
    emergenecyServicesAttended: string;
    fireAttended: string;
    policeAttended: string;
    reportedByName: string;
    reportedByRole: string;
    time: string;
    customDateTime: string;
    title: string;
    incidentStatusId: string;
    incidentStatus: string;
    customEmergencyServices: string;
    validationErrors: string;
}

export const queryStatusType = {
    Pending: "10",
    Assigned: "20",
    Resolved: "30",
    Closed: "40",
};

export const configurableTextForIncidentsPage = {
    Web_IncidentList_Intro1: 157,
    Web_IncidentList_Intro2: 158,
};

class CostReportService {
    getPeriodWeekLookup(): Promise<AxiosResponse<any>> {
        return _get(`${COST_REPORT_DATES_API}`);
    }

    getCostReportGridRows(
        clientId: string,
        venueId: string,
        periodStart: string,
        periodEnd: string,
        serviceId: string
    ): Promise<AxiosResponse<any>> {
        return _get(
            `${COST_REPORT_GRID_API}?clientId=${clientId}&venueId=${venueId}&periodStart=${periodStart}&periodEnd=${periodEnd}&serviceId=${serviceId}`
        );
    }

    //Post the query to the server.
    uploadQueryFormData(data: object): Promise<AxiosResponse<any>> {
        return _post(RAISE_QUERY_API, data);
    }

    //Service functions for the Simple shifts View starting below.
    getSimpleShiftGridRows(
        clientId: string,
        venueId: string,
        startDate: string,
        endDate: string,
        serviceId: string
    ): Promise<AxiosResponse<any>> {
        return _get(
            `${SIMPLE_SHIFTS_GRID_API}?dateRangeFrom=${startDate}&dateRangeTo=${endDate}&clientId=${clientId}&venueId=${venueId}&serviceTypeId=${serviceId}`
        );
    }

    //Service functions for the Incidents View.
    getIncidentGridRows(dateRangeFrom: string, dateRangeTo: string): Promise<AxiosResponse<any>> {
        return _get(`${INCIDENTS_GRID_API}?dateRangeFrom=${dateRangeFrom}&dateRangeTo=${dateRangeTo}`);
    }

    getLicenseChildGridDataSource(
        serviceTypeId: string,
        licenseNumber: string,
        orderByDesc: string
    ): Promise<AxiosResponse<any>> {
        return _get(
            `${SIMPLE_SHIFTS_GRID_API}?serviceTypeId=${serviceTypeId}&LicenseNumber=${licenseNumber}&orderByDesc=${orderByDesc}`
        );
    }

    //get unbilled date items

    getUnbilledBillableItems(serviceTypeId: string, isShowUnbilled: boolean, endDate: string):Promise<AxiosResponse<any>> {
        return _get(
            `${SIMPLE_SHIFTS_GRID_API}?serviceTypeId=${serviceTypeId}&showUnbilledItems=${isShowUnbilled}&dateRangeTo=${endDate}`
        );
    }

    //get unpaid date  items

    getUnpaidBillableItems(serviceTypeId: string, isShowUnpaid: boolean, endDate: string):Promise<AxiosResponse<any>> {
        return _get(
            `${SIMPLE_SHIFTS_GRID_API}?serviceTypeId=${serviceTypeId}&showUnpaidItems=${isShowUnpaid}&dateRangeTo=${endDate}`
        );
    }

    //get unpaid date items

    getUnconfirmedBillableItems(serviceTypeId: string, isShowUnconfirmed: boolean, endDate: string):Promise<AxiosResponse<any>> {
        return _get(
            `${SIMPLE_SHIFTS_GRID_API}?serviceTypeId=${serviceTypeId}&showUnconfirmedItems=${isShowUnconfirmed}&dateRangeTo=${endDate}`
        );
    }

    getUnapprovedBillableItems(serviceTypeId: string, isShowUnapproved: boolean, endDate: string):Promise<AxiosResponse<any>> {
        return _get(
            `${SIMPLE_SHIFTS_GRID_API}?serviceTypeId=${serviceTypeId}&showUnapprovedItems=${isShowUnapproved}&dateRangeTo=${endDate}`
        );
    }
    //get query linked date items

    getQueryRelatedData(id: string):Promise<AxiosResponse<any>> {
        return _get(
            `${SIMPLE_SHIFTS_GRID_API}?id=${id}`
        );
    }

    //Post the query to the server.
    uploadQueryData(data: object): Promise<AxiosResponse<any>> {
        return _post(RAISE_QUERY_API, data);
    }

    //Payment child grid
    getPaymentDetailsById(paymentId: string): Promise<AxiosResponse<any>> {
        return _get(`${SIMPLE_SHIFTS_GRID_API}?paymentId=${paymentId}`);
    }

    //get simple shift grid by billable item id
    getSimpleShiftGridById(billableItemId: string): Promise<AxiosResponse<any>> {
        return _get(`${SIMPLE_SHIFTS_GRID_API}/${billableItemId}/shifts`);
    }
    
    // get shifts data on the basis of selected residency
    getShiftDataItemByResidency(shiftId: any): Promise<AxiosResponse<any>> {
        return _get(Residency_SHIFT_API + `?shiftBatchMainId=${shiftId}`);
    }

    // get shifts data on the basis of selected contract cleaning id
    getShiftDataItemByContractCleaning(shiftId: any): Promise<AxiosResponse<any>> {
        return _get(ContractCleaning_SHIFT_API + `?contractHourId=${shiftId}`);
    }

    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default CostReportService;
