import React from "react";
import gridUtils from "./GridUtilities";

interface AllUserGridServiceProps {
    data: any;
}
interface AllUserGridServiceState {
    Service: string;
}
class AllUserGridService extends React.Component<AllUserGridServiceProps> {
    state: AllUserGridServiceState;
    gridUtils: gridUtils;
    constructor(props: AllUserGridServiceProps) {
        super(props);
        this.state = {
            Service: "",
        };
        this.gridUtils = new gridUtils();
    }
    componentDidMount() {
        if(this.props.data.data.service){
            this.setState({
                Service: this.props.data.data.service,
            });
        }
        else{
            this.setState({
                Service: this.props.data && this.props.data.data && this.props.data.data.serviceTypeLookUp && this.props.data.data.serviceTypeLookUp.value,
            });
        }
    }
    render() {
        const { Service } = this.state;
        var service: string = "";
        var serviceParts: string[] = [];
        if (Service && Service.length > 0) {
            serviceParts = this.gridUtils.convertServiceType(this.state.Service);
            service = serviceParts[0];
        }
        let serviceColour = "";
        if (service) {
            if (service.toLowerCase() == "security") {
                serviceColour = "bg-security";
            } else if (service.toLowerCase() == "entertainment") {
                serviceColour = "bg-entertainer";
            } else if (service.toLowerCase() == "contract cleaning") {
                serviceColour = "bg-cleaner";
            }
        }
        return (
            <div className="status-message-color d-flex align-center">
                <span className={`mr-2 status__circle ${serviceColour}`}></span>
                {
                    <>
                        <ul className="list-style-type pl-0">
                            <li> {serviceParts[0]} </li>
                            <li> {serviceParts[1]} </li>
                        </ul>
                    </>
                }
            </div>
        );
    }
}

export default AllUserGridService;
