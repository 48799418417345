import React, { MouseEvent, Component, ReactNode } from "react";

interface CollapsibleCardGridProps {
    title: string;
    numItems: number;

    children: React.ReactNode;
}

interface CollapsibleCardGridState {
    showButton: boolean;
    expanded: boolean;
    expandedText: string;
    collapsedText: string;
    buttonText: string;
}

class CollapsibleCardGrid extends React.Component<CollapsibleCardGridProps> {
    state: CollapsibleCardGridState;
    gridRef: React.RefObject<any>;

    constructor(props: CollapsibleCardGridProps) {
        super(props);
        this.gridRef = React.createRef();
        this.state = {
            showButton: false,
            expanded: false,
            expandedText: "Close",
            collapsedText: "View more",
            buttonText: "View more",
        };
    }

    toggleExpand = (): void => {
        if (this.state.expanded) {
            // collapse
            this.setState({
                expanded: false,
                buttonText: this.state.collapsedText,
            });
        } else {
            // expand
            this.setState({
                expanded: true,
                buttonText: this.state.expandedText,
            });
        }
    };

    componentDidMount() {
        var parentNode = this.gridRef.current;

        if (parentNode != null) {
            var parentWidth = parentNode.offsetWidth;
            var children = [].slice.call(parentNode.children);
            var innerWidth = 0;
            children.forEach((element: any) => {
                innerWidth += element.offsetWidth;
            });
            if (innerWidth > parentWidth) {
                this.setState({ showButton: true });
            } else {
                this.setState({ showButton: false });
            }
        }

        // TODO also do this check on resize
    }

    render() {
        return (
            <>
                {/* this should be wrapped in a "collapsible-grid__container" in the parent page*/}
                <header className="collapsible-grid__header">
                    {/* TODO check if this will always be H3 */}
                    <h3 className="collapsible-grid__title">
                        {this.props.title} ({this.props.numItems})
                    </h3>
                    {this.state.showButton ? (
                        <button className="btn btn-link btn-link--inline" onClick={this.toggleExpand}>
                            <span
                                className={`btn-link--collapsible-content ${
                                    this.state.expanded ? "btn-link--collapsible-content--expanded" : ""
                                }`}
                            >
                                {this.state.buttonText}
                            </span>
                        </button>
                    ) : null}
                </header>
                <div
                    ref={this.gridRef}
                    className={`row collapsible-grid mt-3 ${this.state.expanded ? "collapsible-grid--expanded" : ""}`}
                >
                    {this.props.children}
                </div>
            </>
        );
    }
}

export default CollapsibleCardGrid;
