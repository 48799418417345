import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/pro-regular-svg-icons";
import BillableItemHeaderService from "../../services/BillableItemHeaderService";
import { AxiosResponse } from "axios";
import { LoadPanel } from "devextreme-react";

interface AcknowledgedCellProps {
    data: any;
}

interface AcknowledgedCellState {
    checkSquareIconVisible: boolean;
    loadPanelVisible: boolean;
}

class AcknowledgedCell extends React.Component<AcknowledgedCellProps> {
    state: AcknowledgedCellState;
    billableItemUploadService: BillableItemHeaderService;

    constructor(props: AcknowledgedCellProps) {
        super(props);
        this.state = {
            checkSquareIconVisible:
                this.props.data.data &&
                this.props.data.data.warningAcknowledge &&
                this.props.data.data.warningAcknowledge.toLowerCase() == "true"
                    ? true
                    : false,
            loadPanelVisible: false,
        };
        this.billableItemUploadService = new BillableItemHeaderService();
    }

    //Whenever the state of the checkbox is altered, trigger the API call.
    onClickCheckbox = (event: any) => {
        var postObject = {
            billableItemUpload: [
                {
                    id: this.props.data.data.billableItemUploadId,
                },
            ],
        };
        this.setState((prevState: AcknowledgedCellState) => ({
            checkSquareIconVisible: !prevState.checkSquareIconVisible,
            loadPanelVisible: true,
        }));
        this.billableItemUploadService
            .updateWarningGridRow(postObject)
            .then(this.handleSuccessAcknowledge)
            .catch(this.handleDeleteFailure);
    };

    handleSuccessAcknowledge = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleDeleteFailure = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "submitDeleteShift service failed with response: " + JSON.stringify(error);

        if (!this.billableItemUploadService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    message="Please wait..."
                    visible={this.state.loadPanelVisible}
                />
                <button className={"btn icon-btn"} onClick={this.onClickCheckbox}>
                    {this.state.checkSquareIconVisible ? (
                        <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                        <FontAwesomeIcon icon={faSquare} />
                    )}
                </button>
            </div>
        );
    }
}

export default AcknowledgedCell;
