import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CheckinCard from "../check-in/CheckinCard";
import CheckinCardNew from "../check-in/CheckinCardNew";
import DarkModePageTemplate from "./Templates/DarkModePageTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";

interface CheckInOutPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

class CheckInOutPage extends Component<CheckInOutPageComponentProps> {
    render() {
        let id = this.props.location.state.id;
        let header = "Door Supervision - Add New Shift";
        let manual = this.props.location.state.manual;

        return (
            <DarkModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                </SideBarTemplate>
                <div id="checkInOut" className="page-content--with-sidebar-hidden-mobile">
                    <section className="container--restricted-desktop-width">
                        <header>
                            <h2 className="page-title__lightgrey pb-2">{header}</h2>
                        </header>
                        <div className="accent-container">
                            {id == 0 ? (
                                <CheckinCardNew location = {this.props.location } history={this.props.history}></CheckinCardNew>
                            ) : manual == false ? (
                                <CheckinCard cardUIType="scan" checkinCard={this.props.location.state.card} />
                            ) : (
                                <CheckinCard
                                    cardUIType="manual"
                                    checkinCard={this.props.location.state.card}
                                ></CheckinCard>
                            )}
                        </div>
                    </section>
                </div>
            </DarkModePageTemplate>
        );
    }
}

export default withRouter(CheckInOutPage);
