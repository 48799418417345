import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError, _put } from "./BaseApiService";
import { LookupTypeItem } from "./LookupService";
import { ContactType } from "../components/Outstanding-Actions/OutstandingActionsUtilities";

// Endpoints
const LIST_OF_PENDING_REQUESTS_API = "Request";
const STATUS = "status";
const RAISE_QUERY_API = "Query";

// Interfaces
export interface PendingRequestItemResponse {
    id: string;
    startTime: string;
    finishTime: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    providerBusinessEntityId: string;
    clientBusinessEntityId: string;
    venueId: string;
    townCity: string;
    country: string;
    venueName: string;
    venuePostCode: string;
    dateFrom: string;
    dateTo: string;
    providerPayAmountLocal: string;
    providerPayAmountLocalCurrency: string;
    configurableText: string;
    entertainmentDescription: string;
    equipmentRequired: string;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
    additionalInformation: string;
    budget: string;
    requestStatusId: string;
    requestStatusValue: string;
    requestTypeId: string;
    requestType: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    workerName: string;
    workerEmail: string;
    workerPhone: string;
    internalComments: string;
    clientBillableAmountLocal: string;
    clientBillableAmountLocalCurrency: string;
    genre: string;
    grossProfitAmountLocal: string;
    grossProfitAmountLocalCurrency: string;
    grossProfitPercentageLocal: string;
    requestApprover: string;
    requestDate: string;
    typeLookUpId: string;
    typeLookUp: string;
    messages: string;
    showInterest: string;
    validationErrors: string;
    clientShortName: string;
}

export interface RaiseQueryObject {
    id: string;
    queryTitle: string;
    queryDescription: string;
    queryTypeLookupID: string;
    contactId: string;
    contactName: string;
    companyId: string;
    company: string;
    d365LinkID: string;
    resolutionReason: string;
    billableItemId: string;
    queryStatusId: string;
    queryStatus: string;
    dateCreated: string;
    clientId: string;
    client: string;
    venueId: string;
    venue: string;
    comments: string;
    lastUpdate: string;
    assignedTo: string;
    serviceTypeId: string;
    serviceTypeValue: string;
    serviceSubTypeId: string;
    serviceSubTypeValue: string;
    entertainmentDateInfo: string;
    venueName: string;
    city: string;
    requestDateFrom: string;
    requestId: string;
    requestTypeId: string;
    requestAdditionalInfo: string;
    queryTypeText: string;
}

export const requestTypes = {
    IndependentBooking: "independent booking",
    FindAnArtist: "find an artist",
};

export interface updateRequest {
    requestStatusValue: string;
    cancellationreasonId: string;
    rePublishGigRequest: boolean | null;
}

export const statusTypes = {
    Pending: "Pending",
    Published: "Published",
    Booked: "Booked",
    Cancelled: "Cancelled",
};

const delimeter = ",";
const status = `${statusTypes.Booked}${delimeter}${statusTypes.Cancelled}`;

/**
 * REST services for the BillableItem resource.
 */
class PendingRequestService {
    // Retrieve the list of Artists from the Db.
    getListOfPendingRequests(clientId: string, venueId: string): Promise<AxiosResponse<any>> {
        return _get(`${LIST_OF_PENDING_REQUESTS_API}?clientId=${clientId}&venueId=${venueId}&statusNotEq=${status}`);
    }

    // Retrieve a single card based on the search query param.
    getIndividualRequestItem(filterById: string): Promise<AxiosResponse<any>> {
        return _get(`${LIST_OF_PENDING_REQUESTS_API}?filterBy=${filterById}`);
    }

    //Post the query to the server.
    uploadQueryData(data: object): Promise<AxiosResponse<any>> {
        return _post(RAISE_QUERY_API, data);
    }

    //Function to Book/Cancel the Request.
    updateRequestData(id: string, data: object): Promise<AxiosResponse<any>> {
        return _put(`${LIST_OF_PENDING_REQUESTS_API}/${id}/${STATUS}`, data);
    }
    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default PendingRequestService;
