import React from "react";
import { PaymentTypes } from "../../services/FindAnArtistService";
import { paymentRequestGridTypes } from "../../services/SimpleGridService";
import PaymentRequestGrid from "./PaymentRequestGrid";

interface CollapsibleSectionProps {
    title: string;
    serviceTypeId: string;
    venueId: string;
    providerId: string;
    dateFrom: string;
    requestId: string;
    location: any;
    history: any;
    paymentRequestType: string;
    disable: boolean;
}

interface CollapsibleSectionState {
    showButton: boolean;
    expanded: boolean;
    expandedText: string;
    collapsedText: string;
    buttonText: string;
    count: string;
    isHide: boolean;
}

class CollapsibleSection extends React.Component<CollapsibleSectionProps> {
    state: CollapsibleSectionState;
    gridRef: React.RefObject<any>;

    constructor(props: CollapsibleSectionProps) {
        super(props);
        this.gridRef = React.createRef();
        this.state = {
            showButton: false,
            expanded: false,
            expandedText: "Close",
            collapsedText: "View more",
            buttonText: "View more",
            count: "",
            isHide: true
        };
    }

    toggleExpand = (): void => {
        if (this.state.expanded) {
            // collapse
            this.setState({
                expanded: false,
                buttonText: this.state.collapsedText,
                isHide: true
            });
        } else {
            // expand
            this.setState({
                expanded: true,
                buttonText: this.state.expandedText,
                isHide: false
            });
        }
    };

    componentDidMount() {
        this.setState({ showButton: true , isHide: true});
    }
    setPaymentGridRecordDataSource = (count: number) => {
        this.setState({
            count: count
        });
        if(count > 0){
            this.setState({ 
                expanded: true,
                isHide: false,
                buttonText: this.state.expandedText
            });
        }
    }
    render() {
        let heading : string = "";
        if(this.props.paymentRequestType == PaymentTypes.AddJob){
            heading = "Artist Job Notification";
        }
        else {
            heading = "Payment Request";
        }
        return (
            <section className="card card-form my-5" hidden={this.props.disable}>
                <div className="card-body">
                    <h3 className="card-form__section-heading">
                        <b>Similar</b> Venue or Provider <b>{heading}</b> For Awareness<b>({this.state.count})</b>
                        {this.state.showButton ? (
                            <button className="btn btn-link btn-link--inline float-right" onClick={this.toggleExpand}>
                                <span
                                    className={`btn-link--collapsible-content ${this.state.expanded ? "btn-link--collapsible-content--expanded" : ""
                                        }`}
                                >
                                    {this.state.buttonText}
                                </span>
                            </button>
                        ) : null}
                    </h3>
                    <div
                        ref={this.gridRef}
                        className={`row collapsible-grid mt-3 ${this.state.expanded ? "collapsible-grid--expanded" : ""}`}
                    >
                            <PaymentRequestGrid serviceTypeId={this.props.serviceTypeId} startDate={this.props.dateFrom} endDate="" venueId={this.props.venueId} providerId={this.props.providerId} navigateFrom="matchedPopUp" setPaymentGridRecordDataSource={this.setPaymentGridRecordDataSource} gridType={paymentRequestGridTypes.matchedPopUp} isHide = {this.state.isHide} providerPayAmount={undefined} paymentRequestId = {this.props.requestId} paymentTypeId = {""} paymentRequestType = {this.props.paymentRequestType} location = {this.props.location} history = {this.props.history} 
                                getBuild={() => { return null }}></PaymentRequestGrid> 
                    </div>
                </div>
            </section>
        );
    }
}

export default CollapsibleSection;
