import React from "react";
import { queryCard } from "../../services/QueryService";
import LargeLightCardWrapper from "../Cards/LargeLightCardWrapper";
import GeneralQueryCardComponent from "./GeneralQueryCardComponent";
import DoorSupervisionCardComponent from "./DoorSupervisionCardComponent";
import ContractCleaningCardComponent from "./ContractCleaningCardComponent";
import EntertainmentCardComponent from "./EntertainmentCardComponent";
import { ServiceType } from "../../common/ServiceUtilities";

interface QueryCardProps {
    querycard: queryCard;
    serialNumber: number;
    totalNumber: number;
    serviceType: string;
}

interface QueryCardState {
    queryIsOpen: boolean;
}

class QueryCard extends React.Component<QueryCardProps> {
    state: QueryCardState;
    constructor(props: QueryCardProps) {
        super(props);
        this.state = {
            queryIsOpen: false,
        };
    }

    render() {
        const serviceTypeId = this.props.querycard.serviceTypeId;
        const { serviceTypeValue, serviceSubTypeValue } = this.props.querycard;
        var titleOfCard = "";
        if (serviceTypeValue && serviceSubTypeValue) {
            titleOfCard = serviceTypeValue + " - " + serviceSubTypeValue;
        }
        var cardHeaderType: string = "";
        if (serviceTypeId) {
            if (serviceTypeId.trim() == ServiceType.Security) {
                cardHeaderType = "security";
            } else if (serviceTypeId.trim() == ServiceType.Entertainment) {
                cardHeaderType = "entertainer";
            } else {
                cardHeaderType = "cleaner";
            }
        } else {
            cardHeaderType = "general";
        }

        return (
            <LargeLightCardWrapper
                typeOfCard="query"
                hasHeader={true}
                type={cardHeaderType ? cardHeaderType : ""}
                typeTitle={titleOfCard ? titleOfCard : "General"}
                enumeration={true}
                cardIndex={this.props.serialNumber}
                cardsTotal={this.props.totalNumber}
                leftSection={
                    this.props.serviceType.toLowerCase() == "general" ? (
                        <GeneralQueryCardComponent
                            querycard={this.props.querycard}
                            serialNumber={this.props.serialNumber}
                            totalNumber={this.props.totalNumber}
                            serviceType={this.props.serviceType}
                        ></GeneralQueryCardComponent>
                    ) : this.props.serviceType.toLowerCase() == "security" ? (
                        <DoorSupervisionCardComponent
                            querycard={this.props.querycard}
                            serialNumber={this.props.serialNumber}
                            totalNumber={this.props.totalNumber}
                            serviceType={this.props.serviceType}
                        >
                            {" "}
                        </DoorSupervisionCardComponent>
                    ) : this.props.serviceType.toLowerCase() == "cleaning" ? (
                        <ContractCleaningCardComponent
                            querycard={this.props.querycard}
                            serialNumber={this.props.serialNumber}
                            totalNumber={this.props.totalNumber}
                            serviceType={this.props.serviceType}
                        ></ContractCleaningCardComponent>
                    ) : this.props.serviceType.toLowerCase() == "entertainment" ? (
                        <EntertainmentCardComponent
                            querycard={this.props.querycard}
                            serialNumber={this.props.serialNumber}
                            totalNumber={this.props.totalNumber}
                            serviceType={this.props.serviceType}
                        ></EntertainmentCardComponent>
                    ) : null
                }
            />
        );
    }
}
export default QueryCard;
