import React from "react";
import { Link } from "react-router-dom";
import { confirm } from "devextreme/ui/dialog";
import checkInCardService, { checkinCardData } from "../../services/CheckInCardService";
import { TextBox, DateBox, LoadPanel, SelectBox, LoadIndicator } from "devextreme-react";
import {
    TextBoxOnValueChangedEvent,
    DateBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import PhotoCapture from "../Camera/PhotoCaptureClass";
import sharedUtils from "../grid/sharedUtilities";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import LookupService, { LookupTypeIndexes, VenueLookupTypeItem } from "../../services/LookupService";
import outstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import { AxiosResponse } from "axios";
import checkInCardUtils, { checkInCardData, checkInCardSubmitRequest } from "./CheckinCardUtils";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import { outstandingActions } from "../../services/OutstandingApprovalService";

interface CheckinProps {
    checkinCard?: checkinCardData;
    history: any;
    location: any;
}

interface RecentWorkerLookupTypeItem {
    index: string;
    name: string;
    idValue: string;
    nameWithLicense: string;
    venueId: string;
}
interface CheckinCardNewState {
    isDate: string | boolean;
    currentCardState: checkInCardData;
    errorMessage: [];
    IsShowSuccess: boolean;
    successMessage: [];
    closeButton: boolean;
    isStartTime: boolean;
    isFinishTime: boolean;
    checkInStatus: string;
    checkOutStatus: string;
    isLoad: boolean;
    venueLoaded: boolean;
    venueTypeDataSource: VenueLookupTypeItem[];
    isCameraEnabled: boolean;
    updatedDate: string;
    isDisableRecentWorkerDropdown: boolean;
    recentWorkerLookupDataSource: RecentWorkerLookupTypeItem[];
    recentWorkerId: string;
    recentWorkerProgressIndicator: boolean;
    singleVenue: boolean;
}

class CheckinCardNew extends React.Component<CheckinProps> {
    lookupService: LookupService;
    state: CheckinCardNewState;
    cardService: checkInCardService;
    Utils: sharedUtils;
    checkinUtils: checkInCardUtils;
    dateFormatUtils: outstandingActionsUtilities;
    constructor(props: CheckinProps) {
        super(props);
        this.lookupService = new LookupService();
        this.cardService = new checkInCardService();
        this.Utils = new sharedUtils();
        this.checkinUtils = new checkInCardUtils();
        this.dateFormatUtils = new outstandingActionsUtilities();
        var convertedCheckInCardData = this.checkinUtils.initializeCheckinCardData();
        this.state = {
            currentCardState: convertedCheckInCardData,
            errorMessage: [],
            IsShowSuccess: false,
            successMessage: [],
            closeButton: false,
            isStartTime: false,
            isFinishTime: false,
            checkInStatus: "",
            checkOutStatus: "",
            isLoad: false,
            venueLoaded: false,
            isDate: this.props.history.location.state.approvals ? false : true,
            venueTypeDataSource: [],
            isCameraEnabled: this.props.history.location.state.approvals ? false : true,
            updatedDate: "",
            isDisableRecentWorkerDropdown: true,
            recentWorkerLookupDataSource: [],
            recentWorkerId: "",
            recentWorkerProgressIndicator: false,
            singleVenue: false,
        };
    }

    //Call the Venue API lookup here.
    componentDidMount() {
        this.retrieveLookupValues();
    }

    // Helper function for fetching data
    retrieveLookupValues = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLookupSuccess)
            .catch((error) =>
                this.setState({
                    venueLoaded: true,
                    venueTypeDataSource: [],
                })
            );
    };

    retrieveRecentWorkerLookUp = (venueId: string) => {
        this.setState({
            recentWorkerProgressIndicator: true,
        });
        this.cardService
            .getRecentWorkerData(venueId)
            .then(this.handleRecentWorkerLookUpSucess)
            .catch(this.handleStandardError);
    };
    handleRecentWorkerLookUpSucess = (response: AxiosResponse<any>) => {
        this.setState({
            recentWorkerLookupDataSource: response.data.data.filter((item: any) => {
                return item.nameWithLicense !== null;
            }),
            recentWorkerProgressIndicator: false,
            isDisableRecentWorkerDropdown: false,
        });
    };
    handleStandardError = (error: any) => {
        var respMessage: string = "recent worker lookup load failed with response" + JSON.stringify(error);

        if (!this.cardService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    handleVenueLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            var venues: any[] = response.data.data;
            var singleVenue: boolean = response.data.data.length == 1 ? true : false;
            var clientId: string = this.checkinUtils.extractClientInformation(venues, venues[0].id);
            this.setState({
                currentCardState: {
                    ...this.state.currentCardState,
                    venueId: singleVenue ? venues[0].id : "",
                    clientId: clientId,
                },
                venueTypeDataSource: venues,
                singleVenue: singleVenue,
            });
            if (singleVenue) {
                this.retrieveRecentWorkerLookUp(this.state.currentCardState.venueId);
            }
        }
        this.setState({
            venueLoaded: true,
        });
    };

    validate = () => {
        var errmsg = [];
        if (
            this.state.currentCardState.name &&
            (this.state.currentCardState.name.trim().includes(" ") ||
                this.state.currentCardState.name.trim().includes(","))
        ) {
            return true;
        } else {
            errmsg.push({
                columnName: "Name",
                errorType: "Error",
                errorMessage:
                    "Only one name has been provided - please provide both first and surname unless there is a valid reason not to.",
            });
            this.setState({
                errorMessage: errmsg,
                IsShowSuccess: false,
                closeButton: true,
                isLoad: false,
            });
            return false;
        }
    };

    handleSubmit = () => {
        let submitRequest: checkInCardSubmitRequest = this.checkinUtils.convertFormDataToBillableItemRequest(
            this.state.currentCardState
        );
        var date = new Date(this.state.updatedDate ? this.state.updatedDate : "");
        var months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var year = date.getFullYear();
        var month = months[date.getMonth()];
        var day = date.getDate();
        var formatted = this.state.updatedDate ? " on " + day + "th" + " " + month + " " + year : "";
        let duration = this.checkinUtils.getShiftDuration(submitRequest);
        var isValid = this.validate();
        if (isValid) {
            confirm(
                "<div class='text-center'>" + duration + formatted + "<br />Is this correct?</div>",
                "Confirm Shift"
            ).then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        isLoad: true,
                    });

                    this.cardService
                        .submitShiftData(submitRequest) //TODO : replace the object type with a concrete type.
                        .then(this.handleSubmitSuccess)
                        .catch((err: any) => {
                            this.setState({
                                errorMessage:
                                    err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                                IsShowSuccess: false,
                                closeButton: true,
                                isLoad: false,
                            });
                        });
                }
            });
        }
    };

    handleSubmitSuccess = () => {
        this.setState(
            {
                closeButton: true,
                IsShowSuccess: true,
                isLoad: false,
            },
            () => {
                this.renderRedirect();
            }
        );
    };

    handleChangeName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            currentCardState: {
                ...this.state.currentCardState,
                name: dxValueChange.value,
            },
        });
    };

    handleChangeLicence = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            currentCardState: {
                ...this.state.currentCardState,
                idvalue: dxValueChange.value,
            },
        });
    };

    renderRedirect = () => {
        let approvalNewAddShift: boolean = this.props.history.location.state.approvals;
        this.props.history.push(
            approvalNewAddShift
                ? {
                      pathname: "/outstandingActions",
                      state: {
                          status: outstandingActions.approvals,
                          serviceTypeId: "",
                          isHeader: true,
                      },
                  }
                : {
                      pathname: "/todaysViewCheckinOut",
                      state: { id: "" },
                  }
        );
    };

    handleChangeShiftStart = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            currentCardState: {
                ...this.state.currentCardState,
                startTime: dxValueChange.value,
            },
            isStartTime: true,
        });
    };
    
    handleChangeDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        let time = "";
        let dateAndTime = "";
        let today = dxValueChange.value;
        let mins = new Date().getMinutes().toString();
        if (new Date().getMinutes() < 10) {
            mins = "0" + today.getMinutes();
        }
        time = new Date().getHours() + ":" + mins; // 04/06/2021 17:27
        dateAndTime = this.Utils.convertDateToString(today) + " " + time;
        this.setState({
            currentCardState: {
                ...this.state.currentCardState,
                date: dateAndTime,
                IsAddShiftApproval: true,
            },
            isDate: true,
            updatedDate: dxValueChange.value,
        });
    };

    handleChangeShiftEnd = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            currentCardState: {
                ...this.state.currentCardState,
                finishTime: dxValueChange.value,
            },
            isFinishTime: true,
        });
    };

    handleChangeRecentWorkerChange = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var selectedWorker = this.state.recentWorkerLookupDataSource.filter((item: RecentWorkerLookupTypeItem) => {
            return item.index === dxValueChange.value;
        });
        this.setState({
            recentWorkerId: dxValueChange.value,
            currentCardState: {
                ...this.state.currentCardState,
                name: selectedWorker.length > 0 ? selectedWorker[0].name : "",
                idvalue: selectedWorker.length > 0 ? selectedWorker[0].idValue : "",
            },
        });
    };

    handleChangeRole = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            currentCardState: {
                ...this.state.currentCardState,
                roleId: dxValueChange.value,
            },
        });
    };

    handleChangeVenueSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.retrieveRecentWorkerLookUp(dxValueChange.value);
        var clientId: string = this.checkinUtils.extractClientInformation(
            this.state.venueTypeDataSource,
            dxValueChange.value
        );
        this.setState({
            currentCardState: {
                ...this.state.currentCardState,
                venueId: dxValueChange.value,
                clientId: clientId,
            },
        });
    };

    imageCapture = (imageLink: any) => {
        if (imageLink) {
            imageLink = imageLink.replace("data:image/jpeg;base64,", "");
            console.log("image link : " + imageLink);
            this.setState({
                currentCardState: {
                    ...this.state.currentCardState,
                    image: imageLink,
                },
            });
        }
    };

    cameraEnabled = (isCameraEnabled: any) => {
        this.setState({
            isCameraEnabled: isCameraEnabled,
        });
    };
    render() {
        const approvalNewCheckInOut: boolean = this.props.history.location.state.approvals;
        var isSubmitDisabled: boolean = true;
        if (
            this.state.currentCardState.name &&
            this.state.currentCardState.idvalue.length == 16 &&
            this.state.currentCardState.venueId &&
            this.state.currentCardState.roleId &&
            !this.state.isLoad &&
            this.state.isStartTime &&
            this.state.isFinishTime &&
            this.state.isDate &&
            (this.state.currentCardState.image || !this.state.isCameraEnabled)
        ) {
            isSubmitDisabled = false;
        }
        return (
            <div className={"checkin-card checkin-card--security mt-3"}>
                <div className="checkin-card__category">
                    Security - Door Supervision Personnel
                    {approvalNewCheckInOut ? (
                        <></>
                    ) : (
                        <span className="ml-md-2 float-md-right d-block d-md-inline">
                            {this.dateFormatUtils.convertddmmyyyyStringToCustomizedFormat("")}
                        </span>
                    )}
                </div>
                <div className="checkin-card__content">
                    {!this.state.singleVenue ? (
                        <div className="row mt-3 align-items-center">
                            <div className="col-md-2 nameText">Venue</div>
                            <div className="col-md-10">
                                {this.state.venueLoaded ? (
                                    <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.venueType}
                                        onValueChanged={this.handleChangeVenueSelect}
                                        isRoot={true}
                                        parentMappingId=""
                                        value={this.state.currentCardState.venueId}
                                        displayExpression={"value"}
                                    />
                                ) : (
                                    <span> Loading... </span>
                                )}{" "}
                            </div>
                        </div>
                    ) : null}
                    <div className="row mt-3 align-items-center">
                        <div className="col-12 col-md-12 nameText"> {"Choose recent worker from the below list"}</div>
                    </div>
                    <div className="row mt-3 align-items-center">
                        <div className="col-12 col-md-2 nameText">Recent Workers</div>
                        <div className="col-md-10">
                            {this.state.recentWorkerProgressIndicator ? (
                                <LoadIndicator
                                    id="simple-grid-indicator"
                                    height={50}
                                    width={50}
                                    visible={this.state.recentWorkerProgressIndicator}
                                />
                            ) : (
                                <SelectBox
                                    dataSource={this.state.recentWorkerLookupDataSource}
                                    displayExpr={"nameWithLicense"}
                                    valueExpr={"index"}
                                    value={this.state.recentWorkerId}
                                    onValueChanged={this.handleChangeRecentWorkerChange}
                                    disabled={this.state.isDisableRecentWorkerDropdown}
                                />
                            )}
                        </div>
                    </div>
                    <div className="row mt-3 align-items-center">
                        <div className="col-12 col-md-12 nameText"> {"Or enter full name and licence"}</div>
                    </div>

                    <div className="checkin-card__name-field">
                        <div className="row mt-3 align-items-center">
                            <div className="col-12 col-md-2 nameText">Full Name</div>
                            <div className="col-md-10">
                                <TextBox
                                    placeholder="Full name as appearing on SIA Badge"
                                    onValueChanged={this.handleChangeName}
                                    value={this.state.currentCardState.name}
                                ></TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="checkin-card__licence-field mt-3">
                        <div className="row align-items-center">
                            <div className="col-md-2 nameText">Licence No</div>
                            <div className="col-md-10">
                                <TextBox
                                    mask="0000  -  0000  -  0000  -  0000"
                                    placeholder="Please Enter Licence No"
                                    onValueChanged={this.handleChangeLicence}
                                    value={this.state.currentCardState.idvalue}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 align-items-center">
                        <div className="col-md-2">Role</div>
                        <div className="col-md-10">
                            <DropDownFromLookupTableDynamic
                                lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                isRoot={false}
                                parentMappingId={"11"}
                                onValueChanged={this.handleChangeRole}
                                value={this.state.currentCardState.roleId}
                            />
                        </div>
                    </div>

                    {approvalNewCheckInOut ? (
                        <div className="row mt-3 align-items-center">
                            <div className="col-md-2 nameText">Date</div>
                            <div className="col-md-4">
                                <DateBox
                                    type="date"
                                    placeholder="Select date"
                                    useMaskBehavior={true}
                                    displayFormat={"dd/MM/yyyy"}
                                    onValueChanged={this.handleChangeDate}
                                    pickerType={"calendar"}
                                />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="row mt-3 align-items-center">
                        <div className="col-md-2 nameText">Shift Start</div>
                        <div className="col-md-4">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="Select time"
                                acceptCustomValue={false}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.handleChangeShiftStart}
                                pickerType={"list"}
                            />
                        </div>
                        <div className="col-md-2 nameText">Shift End</div>
                        <div className="col-md-4">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="Select time"
                                acceptCustomValue={false}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.handleChangeShiftEnd}
                                pickerType={"list"}
                            />
                        </div>
                    </div>

                    {!this.state.closeButton ? (
                        <></>
                    ) : (
                        <span className="text-danger font-weight-bold text-center">
                            <ul className="mt-3">
                                {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                    return (
                                        <li key={uniqueKey}>
                                            {item.columnName}: {item.errorMessage}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    )}
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.isLoad} />
                    {approvalNewCheckInOut ? (
                        <></>
                    ) : (
                        <div className="checkin-card__scan mt-3 text-center">
                            <div className="my-3">
                                <div className="my-2 text-left">SIA Licence</div>
                                <div className="mx-auto my-auto pb-5 checkin-card__photo-capture-container">
                                    <PhotoCapture imageCapture={this.imageCapture} cameraEnabled={this.cameraEnabled} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row my-3">
                        <div className="col-12 col-lg-6 mb-3">
                            <div>
                                <Link
                                    to={
                                        approvalNewCheckInOut
                                            ? {
                                                  pathname: "/outstandingActions",
                                                  state: {
                                                      status: outstandingActions.approvals,
                                                      serviceTypeId: this.props.location.state && this.props.location.state.serviceTypeId ? this.props.location.state.serviceTypeId : "",
                                                      isHeader: true,
                                                  },
                                              }
                                            : {
                                                  pathname: "/todaysViewCheckinOut",
                                                  state: {
                                                      id: "",
                                                  },
                                              }
                                    }
                                    className={"btn btn--ghost btn--large"}
                                >
                                    CLOSE
                                </Link>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                            <button
                                className="btn btn-primary btn--large"
                                disabled={isSubmitDisabled}
                                onClick={this.handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CheckinCardNew;
