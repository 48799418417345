import React from "react";
import { AxiosResponse } from "axios";
import QueryService, { queryCard, cardSections } from "../../services/QueryService";
import QueryCard from "./QueryCard";
import { LoadIndicator } from "devextreme-react";
import queryUtils from "./QueryUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { queryCardUIType } from "../../services/QueryService";
import CardListUtilities from "../../common/CardListUtilities";
import { ServiceNames } from "../../common/ServiceUtilities";

interface QueryCardListProps {
    clientId: string;
    venueId: string;
    filterId: string;
    queryCardType: string; //This prop determines which button is selected in the main page i.e Open/Resolved and the UI will update based on that.
    serviceTypeId: string;
    setQueryCardCount: (count: number, cardsLoaded: boolean) => void;
    location: any;
}

interface QueryCardListState {
    clientId: string;
    venueId: string;
    querycards: queryCard[];
    showProgressIndicator: boolean;
    accordionCardSection: cardSections;
}

class QueryCardList extends React.Component<QueryCardListProps> {
    //State object to manage the state of the List of cards.
    state: QueryCardListState;
    //Service that fetches the data for the individual cards.
    queryService: QueryService;
    utils: queryUtils;
    cardListUtilities: CardListUtilities;

    constructor(props: QueryCardListProps) {
        super(props);
        //Initializing the service.
        this.queryService = new QueryService();
        this.utils = new queryUtils();
        var accordionSectionCardData: cardSections = this.utils.initializeCardSections();
        this.cardListUtilities = new CardListUtilities();
        this.state = {
            clientId: this.props.clientId ? this.props.clientId : "",
            venueId: this.props.venueId ? this.props.venueId : "",
            querycards: [],
            showProgressIndicator: false,
            accordionCardSection: accordionSectionCardData,
        };
    }

    isBlank(filterparam: string) {
        return !filterparam || /^\s*$/.test(filterparam) || 0 === filterparam.length;
    }

    //Please Note: For venue managers, this component will receive clientId and venueId as props.
    //Therefore, call the API endpoint is to be made at the componentDidUpdate for the Venue Managers.
    //For all other users this component will receive ClientId and VenueId as blank on Initial Load.
    //Therefore call the API only on mount for every other user except VM.
    componentDidMount() {
        var { filterId } = this.props;
        var queryId: string = "";
        if (this.props.location.state) {
            queryId = this.props.location.state.queryId;
        }
        if (this.isBlank(filterId) && this.isBlank(queryId)) {
            this.setState({
                showProgressIndicator: true,
            });
        } else {
            this.fetchQueryDetailsbyId();
        }
    }

    //To fetch in the Query card details from the server.
    fetchQueryDetails = () => {
        this.props.setQueryCardCount(0, false);
        var { clientId, venueId, queryCardType } = this.props;
        var status: string = "";
        if (queryCardType == queryCardUIType.Open) {
            status = "pending";
        } else {
            status = "resolved";
        }
        this.setState({
            showProgressIndicator: true,
        });
        if (this.props.serviceTypeId) {
            this.queryService
                .getQueryCardList(
                    clientId && clientId == "0" ? "" : clientId,
                    venueId && venueId == "0" ? "" : venueId,
                    status,
                    this.props.serviceTypeId
                )
                .then(this.handleSuccess)
                .catch(this.handleFailure);
        } else if (!this.props.serviceTypeId) {
            this.queryService
                .getQueryCardList(clientId, venueId, status)
                .then(this.handleSuccess)
                .catch(this.handleFailure);
        }
    };

    fetchQueryDetailsbyId = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.queryService
            .getQueryCardItem(this.props.filterId || this.props.location.state.queryId)
            .then(this.handleQueryItemSuccess)
            .catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        var accordionSectionData: cardSections = this.utils.groupCardListByService(response.data.data);
        this.setState({
            showProgressIndicator: false,
            querycards: response.data.data,
            accordionCardSection: accordionSectionData,
        });
        if (response.data && response.data.data) {
            this.props.setQueryCardCount(response.data.data.length, true);
        }
    };

    handleQueryItemSuccess = (response: AxiosResponse<any>) => {
        var accordionPanelData: cardSections = this.utils.groupCardListByService(
            response.data.data ? [response.data.data] : []
        );
        this.setState({
            querycards: [response.data.data],
            accordionCardSection: accordionPanelData,
            showProgressIndicator: false,
        });
    };

    handleFailure = (error: any) => {
        var respMessage: string = "Query service failed with response: " + JSON.stringify(error);

        if (!this.queryService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            showProgressIndicator: false,
        });
    };

    //Only applicable to venue Managers when the clientId and venueId are supplied.
    componentDidUpdate = (prevprops: QueryCardListProps, prevState: QueryCardListState) => {
        var queryId: string = "";
        if (this.props.location.state) {
            queryId = this.props.location.state.queryId;
        }
        if (
            (this.props.clientId != prevprops.clientId ||
                this.props.venueId != prevprops.venueId ||
                this.props.queryCardType != prevprops.queryCardType) &&
            !this.props.filterId &&
            !queryId
        ) {
            this.fetchQueryDetails();
        } else if (this.props.serviceTypeId != prevprops.serviceTypeId) {
            this.fetchQueryDetails();
        }
    };

    render() {
        var { queryCardType } = this.props;
        var securityCardSerialNo: number = 1;
        var cleaningCardSerialNo: number = 1;
        var entertainmentCardSerialNo: number = 1;
        var generalCardSerialNo: number = 1;
        var { accordionCardSection } = this.state;
        var securityCardLength: number =
            accordionCardSection && accordionCardSection.security ? accordionCardSection.security.length : 0;
        var cleaningCardLength: number =
            accordionCardSection && accordionCardSection.cleaning ? accordionCardSection.cleaning.length : 0;
        var entertainmentCardLength: number =
            accordionCardSection && accordionCardSection.entertainment ? accordionCardSection.entertainment.length : 0;
        var generalCardLength: number =
            accordionCardSection && accordionCardSection.general ? accordionCardSection.general.length : 0;

        var doorSupervisionCssClass = this.cardListUtilities.getAccordionCssClass(
            ServiceNames.Security,
            securityCardLength,
            cleaningCardLength,
            entertainmentCardLength,
            generalCardLength,
            0
        );
        var contractCleaningCssClass = this.cardListUtilities.getAccordionCssClass(
            ServiceNames.ContractCleaing,
            securityCardLength,
            cleaningCardLength,
            entertainmentCardLength,
            generalCardLength,
            0
        );
        var entertainmentCssClass = this.cardListUtilities.getAccordionCssClass(
            ServiceNames.Entertainment,
            securityCardLength,
            cleaningCardLength,
            entertainmentCardLength,
            generalCardLength,
            0
        );
        var generalCssClass = this.cardListUtilities.getAccordionCssClass(
            ServiceNames.General,
            securityCardLength,
            cleaningCardLength,
            entertainmentCardLength,
            generalCardLength,
            0
        );
        return this.state.showProgressIndicator ? (
            <div className="row col-lg-12">
                <span className="col-lg-12 text-center">
                    <LoadIndicator
                        id="simple-grid-indicator"
                        height={100}
                        width={100}
                        visible={this.state.showProgressIndicator}
                    />
                </span>
            </div>
        ) : this.state.querycards && this.state.querycards.length > 0 ? (
            <div className="accordion" id="queriesAccordion">
                {accordionCardSection && accordionCardSection.security.length > 0 ? (
                    <div className="card-accordion__section--security">
                        <h4 id="queriesHeadingSecurity">
                            <button
                                className={doorSupervisionCssClass.buttonClass}
                                type="button"
                                data-toggle="collapse"
                                data-target="#queries-security-section"
                                aria-expanded="false"
                                aria-controls="queries-security-section"
                            >
                                <b>{`Door Supervision (${securityCardLength})`}</b>
                                <span className="card-accordion__icon">
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </span>
                            </button>
                        </h4>

                        <div
                            id="queries-security-section"
                            className={doorSupervisionCssClass.sectionClass}
                            aria-labelledby="queriesHeadingSecurity"
                            data-parent="#queriesAccordion"
                        >
                            {accordionCardSection.security.map((cardItem: queryCard) => {
                                return (
                                    <div className="mb-3" key={cardItem.id}>
                                        <QueryCard
                                            querycard={cardItem}
                                            totalNumber={accordionCardSection.security.length}
                                            serialNumber={securityCardSerialNo++}
                                            serviceType="Security"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {accordionCardSection && accordionCardSection.cleaning.length > 0 ? (
                    <div className="card-accordion__section--cleaning">
                        <h4 id="queriesHeadingCleaning">
                            <button
                                className={contractCleaningCssClass.buttonClass}
                                type="button"
                                data-toggle="collapse"
                                data-target="#queries-cleaning-section"
                                aria-expanded="false"
                                aria-controls="queries-cleaning-sectiong"
                            >
                                <b>{`Contract Cleaning (${cleaningCardLength})`}</b>
                                <span className="card-accordion__icon">
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </span>
                            </button>
                        </h4>

                        <div
                            id="queries-cleaning-section"
                            className={contractCleaningCssClass.sectionClass}
                            aria-labelledby="queriesHeadingCleaning"
                            data-parent="#queriesAccordion"
                        >
                            {accordionCardSection.cleaning.map((cardItem: queryCard) => {
                                return (
                                    <div className="mb-3" key={cardItem.id}>
                                        <QueryCard
                                            querycard={cardItem}
                                            totalNumber={accordionCardSection.cleaning.length}
                                            serialNumber={cleaningCardSerialNo++}
                                            serviceType="Cleaning"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {accordionCardSection && accordionCardSection.entertainment.length > 0 ? (
                    <div className="card-accordion__section--entertainment">
                        <h4 id="queriesHeadingEntertainment">
                            <button
                                className={entertainmentCssClass.buttonClass}
                                type="button"
                                data-toggle="collapse"
                                data-target="#queries-entertainment-section"
                                aria-expanded="false"
                                aria-controls="queries-entertainment-section"
                            >
                                <b>{`Entertainment (${entertainmentCardLength})`}</b>
                                <span className="card-accordion__icon">
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </span>
                            </button>
                        </h4>

                        <div
                            id="queries-entertainment-section"
                            className={entertainmentCssClass.sectionClass}
                            aria-labelledby="queriesHeadingEntertainment"
                            data-parent="#queriesAccordion"
                        >
                            {accordionCardSection.entertainment.map((cardItem: queryCard) => {
                                return (
                                    <div className="mb-3" key={cardItem.id}>
                                        <QueryCard
                                            querycard={cardItem}
                                            totalNumber={accordionCardSection.entertainment.length}
                                            serialNumber={entertainmentCardSerialNo++}
                                            serviceType="Entertainment"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {accordionCardSection && accordionCardSection.general.length > 0 ? (
                    <div className="card-accordion__section--general">
                        <h4 id="queriesHeadingGeneral">
                            <button
                                className={generalCssClass.buttonClass}
                                type="button"
                                data-toggle="collapse"
                                data-target="#queries-general-section"
                                aria-expanded="false"
                                aria-controls="queries-general-section"
                            >
                                <b>{`General (${generalCardLength})`}</b>
                                <span className="card-accordion__icon">
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </span>
                            </button>
                        </h4>

                        <div
                            id="queries-general-section"
                            className={generalCssClass.sectionClass}
                            aria-labelledby="queriesHeadingGeneral"
                            data-parent="#queriesAccordion"
                        >
                            {accordionCardSection.general.map((cardItem: queryCard) => {
                                return (
                                    <div className="mb-3" key={cardItem.id}>
                                        <QueryCard
                                            querycard={cardItem}
                                            totalNumber={accordionCardSection.general.length}
                                            serialNumber={generalCardSerialNo++}
                                            serviceType="General"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        ) : (
            <div className="row col-lg-12">
                <span className="col-lg-12 text-center">
                    {queryCardType == queryCardUIType.Open ? "No open queries" : "No previous queries"}
                </span>
            </div>
        );
    }
}

export default QueryCardList;
