import { AxiosResponse } from "axios";
import { _get, _delete, _setURL, _aiTraceAsError,_postBatchRequest,_post } from "./BaseApiService";
import {paymentTypeIndex} from "./PaymentService";

const DOCUMENTS_GRID_API = "Document/";
const DOWNLOAD_DOCUMENT_API = "/download";
const DOCUMENT_INVOICE = "invoice/";
const DOCUMENT_PAYMENT = "payment/";
const DOCUMENT_REGENERATE_BILLING = "Invoice/Regenerate/";
const FILE_DOWNLOAD = "File/Download";

export interface logicAppRegeneratePostRequest {
    PaymentID: string;
}

export interface DocumentsGridRowITem {
    id: string;
    link: string;
    formatType: string;
    documentType: string;
    dateFrom: string;
    dateTo: string;
    updatedBy: string;
    updatedOn: string;
}

class ManageDocumentsGridService {
    getDocumentsGridRowITem(): Promise<AxiosResponse<any>> {
        return _get(DOCUMENTS_GRID_API);
    }

    getDocumentById(id: string): Promise<AxiosResponse<any>> {
        return _setURL(DOCUMENTS_GRID_API + id + DOWNLOAD_DOCUMENT_API);
    }
    getDocumentByURL(url: string): Promise<AxiosResponse<any>> {
        return _setURL(`${FILE_DOWNLOAD}?fileUrl=${url}`);
    }

    deleteDocumentById(id: string): Promise<AxiosResponse<any>> {
        return _delete(DOCUMENTS_GRID_API + id);
    }
    getDocumentByInvoiceId(docId: string): Promise<AxiosResponse<any>> {
        return _get(DOCUMENTS_GRID_API + DOCUMENT_INVOICE + docId);
    }
    getDocumentByPaymentId(paymentId: string): Promise<AxiosResponse<any>> {
        return _get(DOCUMENTS_GRID_API + DOCUMENT_PAYMENT + paymentId);
    }
    regenerateDocument(invoiceId: string): Promise<AxiosResponse<any>> {
        return _post(DOCUMENT_REGENERATE_BILLING + invoiceId);
    }
    triggerLogicAppForRegenerateDocument(formData: object): Promise<AxiosResponse<any>> {
        return _postBatchRequest(paymentTypeIndex.RegenerateSelfbillinvoice, formData);
    }
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default ManageDocumentsGridService;
