import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import OurDetailsCard from "./OurDetailsCard";
import SocialMediaCard from "./SocialMediaCard";
import GeneralQueryCard from "./GeneralQueryCard";
import QueryService, { companyInformationData, teamData } from "../../services/QueryService";
import helpScreenUtils from "./HelpScreenUtils";
import { AxiosResponse } from "axios";

interface GeneralQueryPageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}
// State
interface GeneralQueryPageState {
    clientId: string;
    venueId: string;
    companyInformation: companyInformationData;
    dataLoaded: boolean;
}

class GeneralQueryPage extends Component<GeneralQueryPageProps> {
    state: GeneralQueryPageState;
    service: QueryService;
    utils: helpScreenUtils;
    constructor(props: GeneralQueryPageProps) {
        super(props);
        this.service = new QueryService();
        this.utils = new helpScreenUtils();
        // Initialize state
        var convertedCompanyInformationData = this.utils.initializeCompanyInformationData();
        this.state = {
            clientId: "",
            venueId: "",
            companyInformation: convertedCompanyInformationData,
            dataLoaded: false,
        };
    }

    //Get the page data on the initial screen render.
    componentDidMount() {
        this.fetchHelpScreenData();
    }

    fetchHelpScreenData = () => {
        this.service.getHelpScreenData().then(this.handleSuccess).catch(this.handleStandardFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
            dataLoaded: true,
            companyInformation: response.data.data.companyInformation,
        });
    };

    handleStandardFailure = (response: AxiosResponse<any>) => {
        this.setState({
            dataLoaded: true,
        });
    };

    render() {
        const { companyInformation, dataLoaded } = this.state;
        return (
            <>
                <LightModePageTemplate>
                    <SideBarTemplate isFixed={true}>
                    </SideBarTemplate>
                    <div className="page-content--with-sidebar-hidden-mobile">
                        <section className="col">
                            <header>
                                <h4 className="font-weight-bold ml-2">Contact</h4>
                            </header>
                            <div className="row mb-lg-5">
                                <div className="col-12 col-lg-7 mb-4 mb-lg-0">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <OurDetailsCard
                                                companyData={companyInformation}
                                                dataLoaded={dataLoaded}
                                            ></OurDetailsCard>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5 mb-4 mb-lg-0">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <SocialMediaCard
                                                socialMediaData={companyInformation}
                                                dataLoaded={dataLoaded}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-lg-5">
                                <div className="col-12 mb-4 mb-lg-0">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <GeneralQueryCard />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </LightModePageTemplate>
            </>
        );
    }
}

export default withRouter(GeneralQueryPage);
