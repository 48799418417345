import React, { MouseEvent, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/pro-regular-svg-icons";

interface FavouriteButtonProps {
    favourited: boolean; // not sure if this is needed?
    selectFavorite: () => void;
}

interface FavouriteButtonState {
    favourited: boolean;
}

class FavouriteButton extends React.Component<FavouriteButtonProps> {
    state: FavouriteButtonState;

    constructor(props: FavouriteButtonProps) {
        super(props);

        this.state = {
            favourited: this.props.favourited,
        };
    }

    toggleFavourite = (): void => {
        // this.setState({ favourited: !this.state.favourited }
        this.props.selectFavorite();
    };

    componentDidUpdate = (prevprops: FavouriteButtonProps, prevState: FavouriteButtonState) => {
        if (this.props.favourited != prevprops.favourited) {
            this.changeIcon();
        }
    };

    changeIcon = () => {
        this.setState({
            favourited: !this.state.favourited,
        });
    };

    render() {
        return (
            <button className="artist-card__favourite-button btn icon-btn" onClick={this.toggleFavourite}>
                {this.state.favourited ? <FontAwesomeIcon icon={faHeart} /> : <FontAwesomeIcon icon={faHeartOutline} />}
            </button>
        );
    }
}

export default FavouriteButton;
