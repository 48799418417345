import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError } from "./BaseApiService";
import { ContactType, feedbackRequestData } from "../components/Outstanding-Actions/OutstandingActionsUtilities";

// Endpoints
const EDIT_VIEW_API_URL = "BillableItem";
const CALCULATION_API = "BillableItem/Calculate";
const SUBMIT_FEEDBACK = "BillableItem/entertainment/feedback";
//EndPoints for v2 
const EDIT_VIEW_API_URL_V2 = "BillableItem/V2";

export const TaxPercentage = {
    Percentage: 0.2,
};

export interface ConfigurableTextType {
    id: string;
    parentMappingId: string;
    value: string;
}

export interface OutstandingCardSections {
    security: BillableItemResponse[];
    cleaning: BillableItemResponse[];
    entertainment: BillableItemResponse[];
    securityLazyLoaded: BillableItemResponse[];
    cleaningLazyLoaded: BillableItemResponse[];
    entertainmentLazyLoaded: BillableItemResponse[];
}

export const Status = {
    Pending: "121",
    Approved: "122",
    ReadyForBilling: "123",
    Billed: "124",
    BillPaid: "125",
    ReadyForPayment: "126",
    NettedForPayment: "127",
    PaymentValidationFailed: "128",
    PaymentPaid: "129",
    PaymentSettled: "130",
    PaymentBounced: "131",
    Queried: "132",
    Cancelled: "133",
    Accepted: "134",
    PendingAcceptance: "135",
};

export const outstandingActions = {
    approvals: "Approvals",
    acceptances: "Acceptances",
    checkInOut: "CheckInOut",
    queries: "Queries",
    NoAction: "",
};

export interface ContractHourResponse {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    totalHours: string;
    variableHours: string;
}

// Interfaces
export interface BillableItemSubmitRequest {
    absent: string;
    accept_Date: string;
    approval_Date: string;
    bill_Date: string;
    billable_Finish: string;
    billable_Hours_Units: string;
    billable_Start: string;
    billable_Start_Finish: string;
    billableItemId: string;
    billableStatusLookupId: string;
    check_In: string;
    check_In_Out: string;
    check_Out: string;
    checkin_Color: string;
    checkin_Font: string;
    checkOut_Color: string;
    checkOut_Font: string;
    client: string;
    client_Billable_Amount_Base_Ccy: string;
    client_Billable_Amount_Local_Ccy: string;
    client_Billable_Currency_Local_Ccy: string;
    client_Billable_Original_Amount_Local_Ccy: string;
    client_Billable_Tax_Base_Ccy: string;
    client_Billable_Tax_Local_Ccy: string;
    client_Billable_Total_Base_Ccy: string;
    client_Billable_Total_Local_Ccy: string;
    client_Tax_Applicable: string;
    clientId: string;
    comments: string;
    cost: string;
    cover: string;
    date: string;
    dateTo: string;
    equipmentQuantity: string;
    fee_Amount_Base_Ccy: string;
    fee_Amount_Local_Ccy: string;
    fee_Currency_Local_Ccy: string;
    fee_Percentage_Base_Ccy: string;
    fee_Percentage_Local_Ccy: string;
    fee_Tax_Base_Ccy: string;
    fee_Tax_Local_Ccy: string;
    fee_Total_Base_Ccy: string;
    fee_Total_Local_Ccy: string;
    fee_Tax_Applicable: string;
    identification_Number: string;
    identificationTypeId: string;
    name: string;
    non_Standard: string;
    pay_Date: string;
    provider: string;
    provider_Pay_Amount_Base_Ccy: string;
    provider_Pay_Amount_Local_Ccy: string;
    provider_Pay_Currency_Local_Ccy: string;
    provider_Pay_Original_Amount_Local_Ccy: string;
    provider_Pay_Tax_Base_Ccy: string;
    provider_Pay_Tax_Local_Ccy: string;
    provider_Pay_Total_Base_Ccy: string;
    provider_Pay_Total_Local_Ccy: string;
    provider_Tax_Applicable: string;
    providerId: string;
    rate: string;
    role: string;
    service: string;
    serviceSubTypeId: string;
    serviceTypeId: string;
    shift: string;
    shift_Color: string;
    shift_End: string;
    shift_Font: string;
    shift_Start: string;
    status: string;
    status_BackGroundColor: string;
    status_Color: string;
    tenseFlag: string;
    typeId: string;
    unscheduled: string;
    validationErrors: string | null;
    venueId: string;
    venue: string;
    isAmend: string;
    invoiceId: string;
    paymentId: string;
    paymentCycleId: string;
    itemTypeId: string;
    itemTypeValue: string;
    originalBillableStartTime: string;
    originalBillableFinishTime: string;
    originalBillableMinutes: string;
    originalRate: string;
    equipmentOriginalQuantity: string;
    noCharge: string;
    childRecordId: string;
    parentRecordId: string;
    internalComments: string;
    otherDescription: string;
    requestApprover: string;
    requestDate: string;
    contractHours: ContractHourResponse | null;
    equipmentBillableQuantity: string;
    equipmentScheduleQuantity: string;
    scheduleRate: string;
    venueName: string;
    venuePostCode: string;
    venueTownCity: string;
    venue_Phone: string;
    billableRate: string;
    shiftCompleteAcknowledgment: boolean;
    shiftCompleteAcknowledgmentDateTime : string;
    genre: string;
    equipmentRequired: string;
    entertainmentDateInfo: string;
    performanceInformation: string;
    reoccurance: string;
    contacts: ContactType[] | null;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
    additionalInformation: string;
    navigateFrom: string;
    hardApproval: string;
    holdPayment : string;
    isAccrual : boolean;
    accrual: {

        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null,
    scheduled_Hours_Units: string;
    scheduledMinutes: string;
}

export interface BillableItemResponse {
    billableItemId: string;
    childRecordId: string;
    parentRecordId: string;
    date: string;
    dateTo: string;
    client: string;
    provider: string;
    venue: string;
    service: string;
    role: string;
    name: string;
    identification_Number: string;
    cover: string;
    check_In_Out: string;
    shift: string;
    billable_Hours_Units: string;
    billable_Minutes: string;
    originalBillableMinutes: string;
    rate: string;
    rate_Decimal: string;
    originalRate: string;
    cost: string;
    status: string;
    itemTypeId: string;
    itemTypeValue: string;
    invoiceId: string;
    paymentId: string;
    paymentCycleId: string;
    comments: string;
    billable_Start_Finish: string;
    provider_Tax_Applicable: string;
    client_Tax_Applicable: string;
    fee_Tax_Applicable: string;
    pay_Date: string;
    bill_Date: string;
    provider_Pay_Currency_Local_Ccy: string;
    provider_Pay_Amount_Local_Ccy: string;
    provider_Pay_Tax_Local_Ccy: string;
    provider_Pay_Total_Local_Ccy: string;
    provider_Pay_Original_Amount_Local_Ccy: string;
    fee_Currency_Local_Ccy: string;
    fee_Amount_Local_Ccy: string;
    fee_Tax_Local_Ccy: string;
    fee_Total_Local_Ccy: string;
    fee_Percentage_Local_Ccy: string;
    client_Billable_Currency_Local_Ccy: string;
    client_Billable_Amount_Local_Ccy: string;
    client_Billable_Tax_Local_Ccy: string;
    client_Billable_Total_Local_Ccy: string;
    client_Billable_Original_Amount_Local_Ccy: string;
    provider_Pay_Amount_Base_Ccy: string;
    provider_Pay_Tax_Base_Ccy: string;
    provider_Pay_Total_Base_Ccy: string;
    fee_Amount_Base_Ccy: string;
    fee_Tax_Base_Ccy: string;
    fee_Total_Base_Ccy: string;
    fee_Percentage_Base_Ccy: string;
    client_Billable_Amount_Base_Ccy: string;
    client_Billable_Tax_Base_Ccy: string;
    client_Billable_Total_Base_Ccy: string;
    accept_Date: string;
    approval_Date: string;
    non_Standard: string;
    checkin_Color: string;
    checkin_Font: string;
    checkOut_Color: string;
    checkOut_Font: string;
    status_Color: string;
    status_BackGroundColor: string;
    shift_Color: string;
    shift_Font: string;
    tenseFlag: string;
    billableStatusLookupId: string;
    absent: string;
    unscheduled: string;
    check_In: string;
    check_Out: string;
    shift_Start: string;
    shift_End: string;
    billable_Start: string;
    billable_Finish: string;
    originalBillableStartTime: string;
    originalBillableFinishTime: string;
    typeId: string;
    identificationTypeId: string;
    equipmentQuantity: string;
    equipmentOriginalQuantity: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    clientId: string;
    providerId: string;
    venueId: string;
    isAmend: string;
    noCharge: string;
    scheduled_Hours_Units: string;
    scheduledMinutes: string;
    validationErrors: string;
    internalComments: string;
    otherDescription: string;
    requestApprover: string;
    requestDate: string;
    contractHours: ContractHourResponse | null;
    previousHoursRunningTotal: string;
    previousQuantityRunningTotal: string;
    equipmentBillableQuantity: string;
    equipmentScheduleQuantity: string;
    scheduleRate: string;
    venueName: string;
    venuePostCode: string;
    venueTownCity: string;
    venue_Phone: string;
    billableRate: string;
    shiftCompleteAcknowledgment: boolean;
    shiftCompleteAcknowledgmentDateTime: string;
    genre: string;
    equipmentRequired: string;
    entertainmentDateInfo: string;
    performanceInformation: string;
    reoccurance: string;
    contacts: ContactType[] | null;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
    additionalInformation: string;
    queries: [];
    feedback: feedbackRequestData | null;
    isAccrual: boolean;
    accrual: {

        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null
}

// Calculation response
export interface CalculationResponse {
    billableStartTime: string;
    billableFinishTime: string;
    billableMinutes: string;
    providerPayAmount: string;
    providerPayAmountLocal: string;
    providerPayAmountLocalCurrency: string;
    providerOriginalPayAmount: string;
    grossProfitAmount: string;
    grossProfitAmountLocalCurrency: string;
    grossProfitAmountLocal: string;
    clientBillableAmount: string;
    clientBillableAmountLocal: string;
    clientBillableAmountLocalCurrency: string;
    clientOriginalBillableAmount: string;
    accrual: {
        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null,
    validationErrors: [];
}

export interface ApprovalCardFeedbackRequest {
    billableItemId: string;
    feedbackScore: string;
    feedbackComments: string;
    businessEntityId: string;
    feedbackType: string;
}


/**
 * REST services for the BillableItem resource.
 */
class OutstandingApprovalService {
    // Retrieve all rows for the grid based on Id of row clicked on parent grid
    getEditViewData(
        type: string,
        serviceTypeId?: string,
        clientId?: string,
        venueId?: string
    ): Promise<AxiosResponse<any>> {
        if ((clientId || venueId) && serviceTypeId) {
            return _get(
                EDIT_VIEW_API_URL +
                    `?outStandingActionStatus=${type}&servicetypeid=${serviceTypeId}&clientId=${clientId}&venueId=${venueId}`
            );
        } else if (serviceTypeId) {
            return _get(EDIT_VIEW_API_URL + `?outStandingActionStatus=${type}&servicetypeid=${serviceTypeId}`);
        } else if (clientId || venueId) {
            return _get(EDIT_VIEW_API_URL + `?outStandingActionStatus=${type}&clientId=${clientId}&venueId=${venueId}`);
        } else {
            return _get(EDIT_VIEW_API_URL + `?outStandingActionStatus=${type}`);
        }
    }
    // Enrichment field calculations.
    uploadFormData(data: object): Promise<AxiosResponse<any>> {
        return _post(CALCULATION_API, data);
    }
    // Billable Item update request //sangeeta
    uploadEditAddData(editAddData: object[]): Promise<AxiosResponse<any>> {
        return _post(EDIT_VIEW_API_URL_V2, editAddData);
    }
    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }

    //To submit the feedback for an artist.
    submitFeedBack(data: object): Promise<AxiosResponse<any>> {
        return _post(SUBMIT_FEEDBACK, data);
    }
}

export default OutstandingApprovalService;
