import React from "react";
import DataGrid, { Pager, Paging, Export, FilterRow, Column, SearchPanel, Scrolling } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import gridUtils from "../../grid/GridUtilities";
import ReconciliationGridService, { TransactionGridRowItem } from "../../../services/ReconciliationGridService";
import { AxiosResponse } from "axios";
import GridToggleTab from "../../BillingV2/GridToggleTab";
import sharedUtils from "../../grid/sharedUtilities";
import { onExportingEvent } from "../../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
//props
interface TransactionReconcilliationGridProps {
    tabVisible: boolean;
    id: string;
    refreshGridData: boolean;
}

//State
interface TransactionReconcilliationGridState {
    showProgressIndicator: boolean;
    gridDataSource: TransactionGridRowItem[];
    primaryDataSource: TransactionGridRowItem[];
    secondaryDataSource: TransactionGridRowItem[];
    primaryDataSourceCount: number;
    secondaryDataSourceCount: number;
}
class TransactionReconcilliationGrid extends React.Component<TransactionReconcilliationGridProps> {
    state: TransactionReconcilliationGridState;
    service: ReconciliationGridService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    constructor(props: TransactionReconcilliationGridProps) {
        super(props);
        // Initialize state and services/utils
        this.service = new ReconciliationGridService();
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            showProgressIndicator: false,
            gridDataSource: [],
            primaryDataSource: [],
            secondaryDataSource: [],
            primaryDataSourceCount: 0,
            secondaryDataSourceCount: 0,
        };
    }

    componentDidMount() {
        // Function to call API
        this.updateGridDataSource();
    }

    updateGridDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.service.getTransactionGridRows(this.props.id).then(this.handleSuccess).catch(this.handleError);
    };

    //This function will change the contents of the Grid, depending on the Tab button that is clicked.
    swapGridDataSource = (isToggle: boolean) => {
        var { primaryDataSource, secondaryDataSource } = this.state;
        if (isToggle) {
            this.setState({
                gridDataSource: primaryDataSource,
            });
        } else {
            this.setState({
                gridDataSource: secondaryDataSource,
            });
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        var primaryDataSource: TransactionGridRowItem[] = this.gridUtils.splitTransactionReportData(response.data.data);
        this.setState({
            primaryDataSource: primaryDataSource,
            secondaryDataSource: response.data.data,
            gridDataSource: this.props.tabVisible ? primaryDataSource : response.data.data,
            showProgressIndicator: false,
            primaryDataSourceCount: primaryDataSource && primaryDataSource.length > 0 ? primaryDataSource.length : 0,
            secondaryDataSourceCount: response.data.data.length,
        });
    };

    handleError = (errorResponse: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string =
            "Get Transaction Grid rows service failed with response: " + JSON.stringify(errorResponse.status);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    setRateColour = (cellElement: any, cellInfo: any) => {
        var cellRateDiffWithCurrencySymbol = this.sharedUtils.thousandsSeparator(cellInfo.data.rateDiff);
        if (cellInfo.data.rateDiffColor == "Green" && cellInfo.data.rateDiff) {
            cellElement.classList.add("text-success");
        } else if (cellInfo.data.rateDiffColor == "Amber" && cellInfo.data.rateDiff) {
            cellElement.classList.add("text-warning");
        }
        if (cellInfo.data.rateDiff) {
            cellElement.append(cellRateDiffWithCurrencySymbol);
        }
    };

    setFeeColour = (cellElement: any, cellInfo: any) => {
        var cellFeeDiffWithCurrencySymbol = this.sharedUtils.thousandsSeparator(cellInfo.data.feeDiff);
        if (cellInfo.data.feeDiffColor == "Green") {
            cellElement.classList.add("text-success");
        } else {
            cellElement.classList.add("text-warning");
        }
        if (cellInfo.data.feeDiff) {
            cellElement.append(cellFeeDiffWithCurrencySymbol);
        }
    };

    componentDidUpdate = (prevProps: TransactionReconcilliationGridProps) => {
        if (this.props.refreshGridData != prevProps.refreshGridData) {
            this.setState({
                primaryDataSourceCount: 0,
                secondaryDataSourceCount: 0,
            });
            this.updateGridDataSource();
        }
    };

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    render() {
        var { primaryDataSourceCount, secondaryDataSourceCount } = this.state;
        return (
            <div>
                <h4 className="font-weight-bold ml-2">Transaction</h4>
                {this.props.tabVisible && (
                    <GridToggleTab
                        primaryTabText={`Different (${primaryDataSourceCount.toString()})`}
                        secondaryTabText={`View All (${secondaryDataSourceCount.toString()})`}
                        className={"Yellow"}
                        onToggle={this.swapGridDataSource}
                    />
                )}
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={50}
                            width={50}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        columnResizingMode={"widget"}
                        allowColumnResizing={true}
                        onExporting={this.onExporting}
                    >
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="client" caption="CLIENT" />
                        <Column dataField="provider" caption="PROVIDER" />
                        <Column dataField="venue" caption="VENUE" />
                        <Column dataField="house" caption="HOUSE" />
                        <Column dataField="service" caption="Service" />
                        <Column dataField="serviceSubType" caption="SERVICE SUB TYPE" />
                        <Column dataField="type" caption="Type" />
                        <Column dataField="name" caption="Name" />
                        <Column
                            dataField="dateFrom"
                            caption="DATE FROM"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateFrom);
                            }}
                        />
                        <Column
                            dataField="dateTo"
                            caption="DATE TO"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                            }}
                        />
                        <Column
                            dataField="rate"
                            caption="RATE"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.rate);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate);
                            }}
                        />
                        <Column
                            dataField="expectedRateLower"
                            caption="EXPECTED RATE LOWER"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.expectedRateLower);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.expectedRateLower);
                            }}
                        />
                        <Column
                            dataField="expectedRateHigher"
                            caption="EXPECTED RATE HIGHER"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.expectedRateHigher);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.expectedRateHigher);
                            }}
                        />
                        <Column
                            dataField="rateDiff"
                            caption="RATE DIFF"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.rateDiff);
                            }}
                            cellTemplate={this.setRateColour}
                        />
                        <Column
                            dataField="minutes"
                            caption="MINUTES"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.minutes);
                            }}
                        />
                        <Column dataField="quantity" caption="QUANTITY" />
                        <Column
                            dataField="pay"
                            caption="PAY"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.pay);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.pay);
                            }}
                        />
                        <Column
                            dataField="fee"
                            caption="FEE"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.fee);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.fee);
                            }}
                        />
                        <Column
                            dataField="bill"
                            caption="BILL"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.bill);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.bill);
                            }}
                        />
                        <Column
                            dataField="expectedFee"
                            caption="EXPECTED FEE"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.expectedFee);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.expectedFee);
                            }}
                        />
                        <Column
                            dataField="feeDiff"
                            caption="FEE DIFF"
                            cellTemplate={this.setFeeColour}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.feeDiff);
                            }}
                        />
                        <Column dataField="currecy" caption="Currency" visible={false} />
                        <Column dataField="feeDiffColor" caption="FeeDiffColor" visible={false} />
                        <Column dataField="rateDiffColor" caption="RateDiffColor" visible={false} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default TransactionReconcilliationGrid;
