import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import { AxiosResponse } from "axios";
import { LoadPanel } from "devextreme-react";
import sharedUtils from "../grid/sharedUtilities";
import { faTh, faRotateExclamation } from "@fortawesome/pro-light-svg-icons";
import billingFormUtils, { documentObject } from "../Billing/BillingFormUtils";
import notify from 'devextreme/ui/notify';

interface AgreementExpiryActionCellProps {
    data: any;
}

interface AgreementExpiryActionCellState {
    //filetype: string;
    loadPanelVisible: boolean;
    downloadLink: string;
}

class AgreementExpiryActionCell extends React.Component<AgreementExpiryActionCellProps> {
    state: AgreementExpiryActionCellState;
    service: ManageDocumentsGridService;
    sharedUtils: sharedUtils;
    billingFormUtils: billingFormUtils;
    constructor(props: AgreementExpiryActionCellProps) {
        super(props);
        this.state = {
            //filetype: this.props.data.data.formatType,
            loadPanelVisible: false,
            downloadLink: ""
        };
        this.service = new ManageDocumentsGridService();
        this.sharedUtils = new sharedUtils();
        this.billingFormUtils = new billingFormUtils();
    }

    onDownloadClick = () => {
        let link = this.props.data.data.selfBillingAgreementLink;
        //let url = link;
        let url = '';
        if(link){
            let splitUrl = link.split('?');
            if(splitUrl && splitUrl.length){
                url = splitUrl[0];
            }
        }
        this.setState({
            loadPanelVisible: true,
            downloadLink: link
        }, () => {
    //         url = this.props.data.data.selfBillingAgreementLink || 'https://stgeventukstoragedev01.blob.core.windows.net/self-bill-agreements/self-bill-agreement_6_2024_12_29_05_12.pdf?sv=2018-03-28&sr=b&sig=eGvYmmOZgCRGkMsTxyHAdLn%2BsLN%2BV1Gz0ZCJrRHvy%2F0%3D&st=2024-11-04T06%3A19%3A00Z&se=2024-11-05T06%3A24%3A00Z&sp=r'
    //         const linkEle = document.createElement('a');
    //         //link.target = '_blank';
    //         linkEle.href = url;
    //         linkEle.download = 'preferred-filename.pdf';
    //         document.body.appendChild(linkEle);
    //         linkEle.click();
    // document.body.removeChild(linkEle);

            this.service
                .getDocumentByURL(url)
                .then(this.handleDownloadSuccess)
                .catch(this.handleDownloadError);
        });

    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        let link = this.state.downloadLink.split("/");
        let fileName = 'self-bill-agreement.pdf';
        let splittedName = link[link.length-1]?.split('?');
        if(splittedName && splittedName.length){
            fileName = splittedName[0];
        }
        var FileSaver = require("file-saver");
        FileSaver.saveAs(response.data, fileName);
        this.setState({
            loadPanelVisible: false,
            downloadLink:""
        });
    };

    handleDownloadError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
            downloadLink:""
        });
        var respMessage: string = "getDocumentByURL failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    renderDownloadButton = () => {
        if (this.props.data.data.selfBillingAgreementLink) {
        return (
            <>
            <div className="icon-btn">
                <FontAwesomeIcon icon={faDownload} onClick={() => this.onDownloadClick()} />
            </div>
            
                {/* <a className="d-inline-flex py-1" download='test.pdf' href={'https://stgeventukstoragedev01.blob.core.windows.net/self-bill-agreements/self-bill-agreement_6_2024_12_29_05_12.pdf?sv=2018-03-28&sr=b&sig=eGvYmmOZgCRGkMsTxyHAdLn%2BsLN%2BV1Gz0ZCJrRHvy%2F0%3D&st=2024-11-04T06%3A19%3A00Z&se=2024-11-05T06%3A24%3A00Z&sp=r'}>
                    <div>
                        <FontAwesomeIcon icon={faDownload} />
                    </div>
                </a> */}
            </>
        );
        }
    }

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                {this.sharedUtils.convertTZDateToDDMMYYY(this.props.data.data.selfBillingAgreementExpiryDate)}
                {/* {this.props.data.data.selfBillingAgreementExpiryDate} */}
                {this.renderDownloadButton()}
            </div>
        );
    }
}

export default AgreementExpiryActionCell;
