import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import CostReportPanel from "./CostReportPanel";
import CostReportService, { costReportDates } from "../../services/CostReportService";
import CostReportGrid from "./CostReportGrid";
import LookupService from "../../services/LookupService";
import CostReportUtils from "./CostReportUtils";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import { ServiceType } from "../../common/ServiceUtilities";

interface CostReportPageProps extends RouteComponentProps, WithTranslation {
    title: string;
    category: string;
    location: any;
}

interface CostReportPageState {
    dateList: costReportDates[];
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    startDateId: string;
    startDateDisplayPeriod: string;
    startDatePeriodNumber: string;
    startDatePeriodWeek: string;
    endDateId: string;
    endDateDisplayPeriod: string;
    endDatePeriodNumber: string;
    endDatePeriodWeek: string;
    sumTotal: string;
    gridLoaded: boolean;
    onUpdate: boolean;
    dateFromDayRange: string;
    dateToDayRange: string;
}

class CostReportPage extends Component<CostReportPageProps> {
    costReportUtils: CostReportUtils;
    state: CostReportPageState;
    Service: CostReportService;
    lookupService: LookupService;
    constructor(props: CostReportPageProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.costReportUtils = new CostReportUtils();
        // Initialize button states to false as a user selection of a grid row is required
        this.state = {
            dateList: [],
            clientId:
                this.props.location.state && this.props.location.state.clientId
                    ? this.props.location.state.clientId
                    : "",
            venueId:
                this.props.location.state && this.props.location.state.venueId ? this.props.location.state.venueId : "",
            serviceTypeId: "",
            startDateId:
                this.props.location.state && this.props.location.state.startDateId
                    ? this.props.location.state.startDateId
                    : "",
            startDateDisplayPeriod: "",
            startDatePeriodNumber: "",
            startDatePeriodWeek: "",
            endDateId:
                this.props.location.state && this.props.location.state.endDateId
                    ? this.props.location.state.endDateId
                    : "",
            endDateDisplayPeriod: "",
            endDatePeriodNumber: "",
            endDatePeriodWeek: "",
            sumTotal: "",
            gridLoaded: false,
            onUpdate: false,
            dateFromDayRange: "",
            dateToDayRange: "",
        };
    }

    parentIdSet = (
        venueId: string,
        startDateId: string,
        endDateId: string,
        clientId: string,
        serviceTypeId: string,
        startDateDisplayPeriod: string,
        startDatePeriodNumber: string,
        startDatePeriodWeek: string,
        endDateDisplayPeriod: string,
        endDatePeriodNumber: string,
        endDatePeriodWeek: string,
        dateFromDayRange: string,
        dateToDayRange: string,
        onUpdate?: boolean
    ) => {
        this.setState({
            clientId: clientId ? clientId : "",
            venueId: venueId ? venueId : "",
            serviceTypeId: serviceTypeId ? serviceTypeId : "",
            startDateId: startDateId ? startDateId : "",
            endDateId: endDateId ? endDateId : "",
            startDateDisplayPeriod: startDateDisplayPeriod ? startDateDisplayPeriod : "",
            startDatePeriodNumber: startDatePeriodNumber ? startDatePeriodNumber : "",
            startDatePeriodWeek: startDatePeriodWeek ? startDatePeriodWeek : "",
            endDateDisplayPeriod: endDateDisplayPeriod ? endDateDisplayPeriod : "",
            endDatePeriodNumber: endDatePeriodNumber ? endDatePeriodNumber : "",
            endDatePeriodWeek: endDatePeriodWeek ? endDatePeriodWeek : "",
            dateFromDayRange: dateFromDayRange ? dateFromDayRange : "",
            dateToDayRange: dateToDayRange ? dateToDayRange : "",
            onUpdate: onUpdate ? onUpdate : "",
        });
    };

    setCalculatedValue = (totalValue: number, loaded: boolean) => {
        this.setState({
            sumTotal: totalValue,
            gridLoaded: loaded,
        });
    };

    render() {
        var serviceTypeId: string | null = sessionStorage.getItem("serviceTypeFilter");
        var periodWeekText: string = "";
        var {
            startDateDisplayPeriod,
            startDatePeriodNumber,
            startDatePeriodWeek,
            startDateId,
            endDateId,
            endDateDisplayPeriod,
            endDatePeriodNumber,
            endDatePeriodWeek,
            dateFromDayRange,
            dateToDayRange,
            gridLoaded,
        } = this.state;
        if (startDateId.localeCompare(endDateId) == 0 && gridLoaded == true) {
            var startDatePeriodText: string[] = startDateDisplayPeriod ? startDateDisplayPeriod.split("(") : [];
            var modifiedStartDateText: string = this.costReportUtils.constructHeaderText(
                startDatePeriodText[0],
                dateFromDayRange
            );
            var weekText: string = `Period ${startDatePeriodNumber}, Week ${startDatePeriodWeek}`;
            periodWeekText = `${modifiedStartDateText ? modifiedStartDateText : ""} (${weekText ? weekText : ""})`;
        } else {
            if (gridLoaded == true && startDateId.localeCompare(endDateId) != 0) {
                var startDay: string[] = dateFromDayRange.split("-");
                var endDay: string[] = dateToDayRange.split("-");
                var startDateText: string[] = startDateDisplayPeriod ? startDateDisplayPeriod.split("-") : [];
                var endDateText: string[] = endDateDisplayPeriod ? endDateDisplayPeriod.split("(") : [];
                var endDatePeriodText: string[] = endDateText[0] ? endDateText[0].split("-") : [];
                var startDateWeekText: string = `Period ${startDatePeriodNumber}, Week ${startDatePeriodWeek}`;
                var endDateWeekText: string = `Period ${endDatePeriodNumber}, Week ${endDatePeriodWeek}`;
                periodWeekText = `${startDay.length > 0 ? startDay[0] : ""} ${
                    startDateText[0] ? startDateText[0] : ""
                } - ${endDay.length > 0 ? endDay[1] : ""}${endDatePeriodText[1] ? endDatePeriodText[1] : ""} (${
                    startDateWeekText ? startDateWeekText : ""
                } - ${endDateWeekText ? endDateWeekText : ""})`;
            }
        }
        var headerText: string = "";
        if (serviceTypeId == ServiceType.Security) {
            headerText = "Door Supervision";
        } else if (serviceTypeId == ServiceType.Entertainment) {
            headerText = "Entertainment";
        } else {
            headerText = "Contract Cleaning";
        }
        var header: string = `${headerText} - ${"Cost Report"}`;
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <CostReportPanel
                        serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                        onApplyButtonClick={this.parentIdSet}
                        clientId={this.state.clientId}
                        venueId={this.state.venueId}
                        startDateId={this.state.startDateId}
                        endDateId={this.state.endDateId}
                    />
                    <BulletinPanel />
                </SideBarTemplate>

                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mt-3 mb-3">
                        <h2 className="page-title__black">{header}</h2>
                        <h5 className="font-weight-bold text-center text-lg-left">{periodWeekText ? periodWeekText : ""}</h5>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            {
                                <CostReportGrid
                                    clientId={this.state.clientId}
                                    venueId={this.state.venueId}
                                    serviceTypeId={this.state.serviceTypeId}
                                    startDateId={this.state.startDateId}
                                    endDateId={this.state.endDateId}
                                    setCalculations={this.setCalculatedValue}
                                />
                            }
                        </div>
                        <div></div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(withTranslation()(CostReportPage));
