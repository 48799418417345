import React, { Component } from "react";
import TransactionReconcilliationGrid from "../Billing/Reconciliation/TransactionReconcilliationGrid";
import WeekOnWeekReconciliationGrid from "../Billing/Reconciliation/WeekOnWeekReconciliationGrid";
import { LoadPanel } from "devextreme-react";
import { BillingCyclesGridServiceInvoiceItem } from "../../services/BillingCyclesGridService";
import BillingService, { billingCycleStatus, billingStatus } from "../../services/BillingService";
import BillingFormUtils, { BillingFormData, BillingStatus } from "../Billing/BillingFormUtils";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import ShiftMatrixUnbilledGrid from "../ShiftMatrix/ShiftMatrixUnbilledGrid";

interface ReportsGridComponentProps {
    invoiceId: number;
    billingData: BillingFormData;
    onShiftsChecked: (status: BillingStatus, statusId: string) => void;
    setTaxValues: (CalculationsObject: any) => void;
    statusId: string;
    calculationsObject: any;
    viewDisable: boolean;
    previewGrid?: boolean;
    isPreviewClicked?: boolean;
}

interface ReportsGridComponentState {
    billingItemList: string;
    gridData: BillingCyclesGridServiceInvoiceItem[];
    calculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    loadPanelVisible: boolean;
    refreshReports: boolean;
    disableCheckShifts: boolean;
}

class ReportsGridComponent extends Component<ReportsGridComponentProps> {
    state: ReportsGridComponentState;
    BillingService: BillingService;
    billingFormUtils: BillingFormUtils;
    sharedUtils: sharedUtils;
    constructor(props: ReportsGridComponentProps) {
        super(props);
        this.BillingService = new BillingService();
        this.billingFormUtils = new BillingFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            gridData: [],
            billingItemList: "",
            calculationsObject: {
                subTotal: "",
                total: "",
                tax: "",
            },
            loadPanelVisible: false,
            refreshReports: false,
            disableCheckShifts: this.props.viewDisable ? true : false,
        };
    }

    //Function which is invoked when the "Check" button is clicked.
    onShiftsChecked = () => {
        let data: BillingCyclesGridServiceInvoiceItem = this.billingFormUtils.initializeBillingCyclesGridServiceInvoiceItem();
        data.invoiceId = this.props.invoiceId ? this.props.invoiceId.toString() : "";
        data.clientId = this.props.billingData.clientId;
        data.client = this.props.billingData.client;
        data.statusId = billingCycleStatus.BillChecked;
        data.status = billingStatus.BillChecked;
        data.periodMatrixId = this.props.billingData.periodMatrixId;
        data.period = this.props.billingData.period;
        data.week = this.props.billingData.week;
        data.dateRangeFrom = this.props.billingData.dateRangeFrom
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeFrom)
            : "";
        data.dateRangeTo = this.props.billingData.dateRangeTo
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeTo)
            : "";
        data.tax =
            this.props.calculationsObject && this.props.calculationsObject.tax
                ? this.props.calculationsObject.tax.toString()
                : "";
        data.subTotal =
            this.props.calculationsObject && this.props.calculationsObject.subTotal
                ? this.props.calculationsObject.subTotal.toString()
                : "";
        data.total =
            this.props.calculationsObject && this.props.calculationsObject.total
                ? this.props.calculationsObject.total.toString()
                : "";
        data.invoiceDate = this.props.billingData.invoiceDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDate)
            : "";
        data.invoiceReference = this.props.billingData.invoiceReference
            ? this.props.billingData.invoiceReference.toString()
            : "";
        data.internalReference = this.props.billingData.internalReference
            ? this.props.billingData.internalReference.toString()
            : "";
        data.invoiceSentDate = this.props.billingData.invoiceSentDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceSentDate)
            : "";
        data.invoiceDueDate = this.props.billingData.invoiceDueDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDueDate)
            : "";

        data.invoicePaidDate = this.props.billingData.invoicePaidDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoicePaidDate)
            : "";
        data.invoiceAmount = this.props.billingData.invoiceAmount
            ? this.props.billingData.invoiceAmount.toString()
            : "";
        data.description = this.props.billingData.description ? this.props.billingData.description.toString() : "";
        data.actionType = "Save";
        data.billableItemIds = "";
        data.invoiceServiceMappingList = this.props.billingData.invoiceServiceMappingList;

        this.setState({
            gridData: data,
            loadPanelVisible: true,
        });
        this.onSubmit(data);
    };

    onSubmit = (billingRowData: BillingCyclesGridServiceInvoiceItem) => {
        this.BillingService.billingDataService(billingRowData).then(this.handleSuccess).catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState((prevState: ReportsGridComponentState) => ({
            loadPanelVisible: false,
            disableCheckShifts: true,
        }));
        this.props.onShiftsChecked(BillingStatus.BillChecked, billingCycleStatus.BillChecked);
    };

    handleFailure = (error: any) => {
        var respMessage: string = "postBillingCycleInvoice failed with response: " + JSON.stringify(error);

        if (!this.BillingService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            loadPanelVisible: false,
        });
    };

    //A function which when triggered would refresh the Reports.
    refreshReports = () => {
        this.setState((prevState: ReportsGridComponentState) => ({
            refreshReports: !prevState.refreshReports,
        }));
    };

    render() {
        var { invoiceId, statusId } = this.props;
        var { disableCheckShifts } = this.state;
        var checkButtonCssClass: string =
            disableCheckShifts ||
                (statusId != billingCycleStatus.Pending && statusId != billingCycleStatus.ReadyForBilling)
                ? "btn disabledCycleButtonColor btn--large"
                : "btn saveCycleButtonColor btn--large";
        return (
            <div className="card mt-2">
                <div className="card-body">
                    { this.props.previewGrid ? ( 
                            <></>
                         )
                        : (
                            <>
                            <div className="col col-lg-3">
                                <button
                                    className="btn btn--ghost--teal status-flow_restart-button"
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.refreshReports();
                                    }}
                                >
                                    Refresh Reports
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md">
                                    <TransactionReconcilliationGrid
                                        tabVisible={true}
                                        id={invoiceId ? invoiceId.toString() : ""}
                                        refreshGridData={this.state.refreshReports}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md">
                                    <WeekOnWeekReconciliationGrid
                                        tabVisible={true}
                                        id={invoiceId ? invoiceId.toString() : ""}
                                        refreshGridData={this.state.refreshReports}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="row mt-3">
                        <div className="col-md">
                            <h4 className="font-weight-bold">Unbilled Items</h4>
                            <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                            <ShiftMatrixUnbilledGrid
                                id={this.props.invoiceId.toString()}
                                previewGrid={this.props.previewGrid}
                                billingData={this.props.billingData}
                                isPreviewClicked={this.props.isPreviewClicked}
                            />
                            <div className="row mt-3" hidden={this.props.previewGrid}>
                                <div className="col-12 col-lg-2 mb-3 mr-auto">
                                    <button
                                        className={checkButtonCssClass}
                                        type="button"
                                        onClick={(e: React.MouseEvent) => {
                                            e.preventDefault();
                                            this.onShiftsChecked();
                                        }}
                                        disabled={
                                            this.props.viewDisable
                                                ? true
                                                : disableCheckShifts ||
                                                (statusId != billingCycleStatus.Pending &&
                                                    statusId != billingCycleStatus.ReadyForBilling)
                                        }
                                    >
                                        CHECK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportsGridComponent;
