import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";

class DesignSystemLandingPage extends Component<RouteComponentProps> {
    constructor(props: RouteComponentProps) {
        super(props);
    }

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <p>Please use the links on the left to navigate through the design system for Event UK.</p>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(DesignSystemLandingPage);
