import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { WithTranslation } from "react-i18next";
import ServiceFeeService from "../../services/ServiceFeesService";
import UserService, { RoleGroupNames } from "../../services/UserService";

interface ServiceFeeCellComponentProps extends WithTranslation {
    data: any;
}

interface ServiceFeeCellComponentState {
    eventUKSMIndicator: boolean;
}

class ServiceFeeCellComponent extends React.Component<ServiceFeeCellComponentProps> {
    state: ServiceFeeCellComponentState;
    service: ServiceFeeService;

    constructor(props: ServiceFeeCellComponentProps) {
        super(props);
        this.service = new ServiceFeeService();
        this.state = {
            eventUKSMIndicator: UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ? true : false,
        };
    }

    //This is needed here to check for some conditions when the component mounts.
    componentDidMount() {}

    render() {
        return (
            <div hidden={!this.state.eventUKSMIndicator}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <Link
                            title="Edit"
                            className="icon-btn"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    id: this.props.data.data.id,
                                    isEdit: true,
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceFeeCellComponent;
