import sharedUtils from "../grid/sharedUtilities";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import { VenueLookupTypeItem } from "../../services/LookupService";
import moment from "moment";

export interface checkInCardData {
    billableitemid: string;
    name: string;
    idvalue: string;
    checkInTime: string;
    checkOutTime: string;
    roleId: string;
    venueId: string;
    clientId: string;
    startTime: NullableDate;
    finishTime: NullableDate;
    IsRefresh: string;
    image: string;
    date: string;
    IsAddShiftApproval:boolean
}

export interface checkInCardSubmitRequest {
    billableitemid: string;
    name: string;
    idvalue: string;
    checkInTime: string;
    checkOutTime: string;
    roleId: string;
    venueId: string;
    clientId: string;
    startTime: string;
    finishTime: string;
    IsRefresh: string;
    image: string;
    date: string;
    IsAddShiftApproval:boolean
}

export default class checkInCardUtils {
    //Declare the shared utils function here.
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.convertFormDataToBillableItemRequest = this.convertFormDataToBillableItemRequest.bind(this);
    }

    //Initialize the Raise query form data.
    initializeCheckinCardData = (): checkInCardData => {
        var formData: checkInCardData = {
            billableitemid: "",
            name: "",
            idvalue: "",
            checkInTime: "",
            checkOutTime: "",
            roleId: "",
            venueId: "",
            clientId: "",
            startTime: this.utils.convertStringToTime(""),
            finishTime: this.utils.convertStringToTime(""),
            IsRefresh: "",
            image: "",
            date: "",
            IsAddShiftApproval:false,
        };
        return formData;
    };

    checkinLocalMachineDateFormat() {
        let time = "";
        let dateAndTime = "";
        let today = new Date();
        let mins = today.getMinutes().toString();
        if (today.getMinutes() < 10) {
            mins = "0" + today.getMinutes();
        }
        time = today.getHours() + ":" + mins; // 04/06/2021 17:27
        dateAndTime = this.utils.convertDateToString(new Date()) + " " + time; 
        return dateAndTime;
    }

    // Convert the form's data into a proper billable item submit request
    convertFormDataToBillableItemRequest = (formData: checkInCardData): checkInCardSubmitRequest => {
        var submitRequest: checkInCardSubmitRequest = {
            billableitemid: "",
            name: formData.name,
            idvalue: formData.idvalue,
            checkInTime: "",
            checkOutTime: "",
            roleId: formData.roleId ? formData.roleId : "",
            venueId: formData.venueId ? formData.venueId : "",
            clientId: formData.clientId ? formData.clientId : "",
            startTime: this.utils.convertTimeToString(formData.startTime),
            finishTime: this.utils.convertTimeToString(formData.finishTime),
            IsRefresh: "",
            image: formData.image ? formData.image : "",
            date: formData.date ? formData.date : this.checkinLocalMachineDateFormat(),
            IsAddShiftApproval:formData.IsAddShiftApproval? true:false,
        };
        return submitRequest;
    };

    //Helper function that returns the clientId when a venue is selected from the dropdown.
    //The ParentMappingId of the selected venue is the clientId.
    extractClientInformation = (venueDataSource: VenueLookupTypeItem[], venueId: string): string => {
        var clientIdObject: VenueLookupTypeItem[];
        clientIdObject = venueDataSource.filter((item: VenueLookupTypeItem) => {
            return item.id == venueId;
        });
        return clientIdObject[0].parentMappingId;
    };

    //Helper function for providing a string referring to the length of a shift.
    //Uses Moment.js
    getShiftDuration = (checkInCardSubmitRequest: checkInCardSubmitRequest) => {
        let startTime: any = moment(checkInCardSubmitRequest.startTime, "HH:mm");
        let finishTime: any = moment(checkInCardSubmitRequest.finishTime, "HH:mm");

        if (finishTime.isBefore(startTime)) {
            //if the finish time is before the start time then we assume the shift ends tommorrow
            finishTime.add(1, "days");
        }

        let duration = moment.duration(finishTime.diff(startTime, "hours", true), "hours");
        let hours = duration.hours();
        let minutes = duration.minutes();
        let durationString = "";

        if (hours > 0 && minutes > 0) {
            durationString = `${hours} Hr ${minutes} Min`;
        } else if (hours > 0) {
            durationString = `${hours} Hr`;
        } else {
            durationString = `${minutes} Min`;
        }

        return `You are adding a ${durationString} shift for ${startTime._i} to ${finishTime._i}`;
    };
}
