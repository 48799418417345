import React from "react";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import paymentFormUtils, { ActionType, PaymentFormData } from "../Payments/PaymentFormUtils";
import PaymentService, { logicAppPostRequest, paymentCycleStatus, paymentStatus } from "../../services/PaymentService";
import { LoadPanel } from "devextreme-react";

interface PaymentDocumentSectionProps {
    viewDisabled: boolean;
    paymentCycleId: number;
    paymentData: PaymentFormData;
    onPaymentProduced: (status: string, statusId: string, isRestartDisable: boolean) => void;
    statusId: string;
    invoiceSentDate: NullableDate;
    syncDateFields: (date: NullableDate, type: string) => void;
    calculationsObject: any;
}

interface PaymentDocumentSectionState {
    loadPanelVisible: boolean;
    disableProduceButton: boolean;
    disableSentButton: boolean;
    statusIdOnClick: string;
    downloadLink: string;
    downloadLinkCss: string;
    paymentFormData: PaymentFormData;
    errorMessage: [];
}

class PaymentDocumentSection extends React.Component<PaymentDocumentSectionProps> {
    state: PaymentDocumentSectionState;
    PaymentService: PaymentService;
    paymentFormUtils: paymentFormUtils;
    sharedUtils: sharedUtils;
    documentService: ManageDocumentsGridService;

    constructor(props: PaymentDocumentSectionProps) {
        super(props);
        this.PaymentService = new PaymentService();
        this.paymentFormUtils = new paymentFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            loadPanelVisible: false,
            disableProduceButton: this.props.viewDisabled ? true : false,
            disableSentButton: this.props.viewDisabled ? true : false,
            statusIdOnClick: "",
            downloadLink: "",
            downloadLinkCss: "icon-btn",
            paymentFormData: this.props.paymentData,
            errorMessage: [],
        };
        this.documentService = new ManageDocumentsGridService();
    }

    //Function which is invoked when the "Produce" as well as "Sent" button is clicked.
    onPaymentProduced = (statusId: string, status: string) => {
        let data: any = this.paymentFormUtils.paymentDataInitializePaymentItems(this.props, statusId, status);
        this.setState({
            gridData: data,
            loadPanelVisible: true,
            statusIdOnClick: statusId,
        });
        this.onSubmit(data);
    };

    onSubmit = (paymentData: PaymentFormData) => {
        var paymentCycleSubmitRequest = this.paymentFormUtils.convertFormDataToPaymentCycleRequest(
            paymentData,
            ActionType.Save,
            "",
            ""
        );
        this.PaymentService.postPaymentCycleDataV2(paymentCycleSubmitRequest)
            .then(this.handleSuccess)
            .catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        const { paymentCycleId } = this.props;
        const { statusIdOnClick } = this.state;

        this.setState(
            (prevState: PaymentDocumentSectionState) => ({
                loadPanelVisible: false,
                disableProduceButton: true,
                downloadLinkCss: "icon-btn",
                errorMessage: [],
            }),
            () => this.props.onPaymentProduced(paymentStatus.PaymentPackRequested, statusIdOnClick, false)
        );
        if (statusIdOnClick == paymentStatus.PaymentPackRequested) {
            let postObject: logicAppPostRequest = {
                PaymentCycleId: paymentCycleId.toString(),
            };
            this.PaymentService.triggerLogicAppForPayPackRequested(postObject);
        }
    };

    handleFailure = (error: any) => {
        var { statusIdOnClick } = this.state;
        var respMessage: string = "postPaymentCycleInvoice failed with response: " + JSON.stringify(error);

        if (!this.PaymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            errorMessage: error.response !== null ? JSON.parse(JSON.stringify(error.response.data.error)) : null,
            loadPanelVisible: false,
            disableProduceButton: false,
            downloadLinkCss:
                statusIdOnClick == paymentCycleStatus.PaymentPackRequested ? "disabled icon-btn" : "icon-btn",
        });
    };

    //Inside the update function, when the Sent date is changed in the form, it would reflect here and vice versa.
    componentDidUpdate = (prevProps: PaymentDocumentSectionProps) => {
        if (this.props.invoiceSentDate != prevProps.invoiceSentDate) {
            this.setState({
                billingFormData: {
                    ...this.state.paymentFormData,
                    invoiceSentDate: this.props.invoiceSentDate,
                },
            });
        }
    };

    render() {
        var produceButtonText: string = "PRODUCE";
        var { statusId } = this.props;
        var { disableProduceButton } = this.state;
        if (statusId && statusId == paymentStatus.PaymentPackRequested) {
            produceButtonText = "Documents Requested";
        } else if (
            (statusId && statusId == paymentStatus.PaymentPackReady) ||
            statusId == paymentStatus.PaymentPaid ||
            statusId == paymentStatus.PaymentAdviseRequested ||
            statusId == paymentStatus.PaymentAdvised ||
            statusId == paymentStatus.PaymentSettled ||
            statusId == paymentStatus.PaymentRemittanceRequested ||
            statusId == paymentStatus.PaymentRemitted
        ) {
            produceButtonText = "Documents Ready";
        } else if (statusId && statusId == paymentStatus.PaymentPackFailed) {
            produceButtonText = "Documents Failed";
        }
        var produceButtonCssClass: string =
            disableProduceButton ||
            (statusId != paymentStatus.Pending &&
                statusId != paymentStatus.PaymentNetted &&
                statusId != paymentStatus.PaymentChecked)
                ? "btn disabledCycleButtonColor btn--large"
                : "btn saveCycleButtonColor btn--large";
        var disablePaymentProduceButton: boolean =
            disableProduceButton ||
            (statusId != paymentStatus.Pending &&
                statusId != paymentStatus.PaymentNetted &&
                statusId != paymentStatus.PaymentChecked)
                ? true
                : false;
        return (
            <div className="card my-3">
                <div className="card-body">
                    <h4>
                        <b>Documents</b>
                    </h4>
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        {this.state.errorMessage ? (
                            <span className="unscheduled-shift">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className="row align-items-center">
                            <div className="col-2">
                                <button
                                    className={produceButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onPaymentProduced(
                                            paymentStatus.PaymentPackRequested,
                                            paymentCycleStatus.PaymentPackRequested
                                        );
                                    }}
                                    disabled={this.props.viewDisabled ? true : disablePaymentProduceButton}
                                >
                                    {produceButtonText}
                                </button>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        );
    }
}
export default PaymentDocumentSection;
