import React, { Component } from "react";
import DevExFileUploader from "../file/DevExFileUploader";
import UploadFileListGrid from "../grid/UploadFileListGrid";
import { alert, confirm } from "devextreme/ui/dialog";
import { Button, LoadPanel } from "devextreme-react";
import BillableItemHeaderService, {
    fileListGridType,
    BillableItemHeaderServiceRowItem,
} from "../../services/BillableItemHeaderService";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";
import GlobalService from "../../services/GlobalService";
import gridUtils from "../grid/GridUtilities";
import { withTranslation, WithTranslation } from "react-i18next";

// Props
interface NewFileSubmissionComponentProps extends WithTranslation {
    title: string;
    category: string;
    landingPage: string;
    typeId?: string; // TypeId props specifies which value the type dropdown should hold.
    landingPageTab: string;
    shiftType?: string;
}

// State - refreshSignal used to determine when a grid refresh is required
//State property - gridVisibilitySignal used to determine when to display the Upload file list grid.
interface NewFileSubmissionComponentState {
    refreshSignal: boolean;
    gridVisibilitySignal: boolean;
    hideHeader: boolean;
    serviceType: string;
    subServiceType: string;
    landingPage: string;
    selectedPendingButton: boolean;
    selectedSubmittedButton: boolean;
    parentGridType: string;
    billableItemHeaderId: string;
    statusOfUploadedFile: string;
    loadPanelVisible: boolean;
}

// Component - displays the file upload component and the two grids that show file data
class NewFileSubmissionComponent extends Component<NewFileSubmissionComponentProps> {
    state: NewFileSubmissionComponentState;
    service: BillableItemHeaderService;
    gridUtils: gridUtils;
    constructor(props: NewFileSubmissionComponentProps) {
        super(props);
        this.service = new BillableItemHeaderService();
        this.gridUtils = new gridUtils();
        this.onFileUploaded = this.onFileUploaded.bind(this);
        this.onDropdownSelection = this.onDropdownSelection.bind(this);
        this.state = {
            refreshSignal: false,
            gridVisibilitySignal: false,
            hideHeader: true,
            serviceType: "",
            subServiceType: "",
            landingPage: "",
            selectedPendingButton: true,
            selectedSubmittedButton: false,
            parentGridType: fileListGridType.Pending,
            billableItemHeaderId: "",
            statusOfUploadedFile: "",
            loadPanelVisible: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<NewFileSubmissionComponentProps>): void {
        if (this.props.shiftType != prevProps.shiftType) {
            this.setState({
                refreshSignal: false,
                gridVisibilitySignal: false,
                serviceType: "",
                subServiceType: "",                       
                parentGridType:  fileListGridType.Pending,
                billableItemHeaderId: "",
            })
        }
    }

    // DevExFileUploader child component will signal via this callback when a file has been uploaded
    onFileUploaded() {
        // This refresh signal will toggle between true and false to indicate a refresh is required.
        this.setState({
            refreshSignal: !this.state.refreshSignal,
            parentGridType: fileListGridType.Pending,
            selectedPendingButton: true,
            selectedSubmittedButton: false,
        });
    }

    //The gridVisibilitySignal will be set to true when the values in the dropdowns are populated.
    onDropdownSelection(serviceTypeId: string, serviceSubTypeId: string) {
        this.setState({
            serviceType: serviceTypeId,
            subServiceType: serviceSubTypeId,
        });
    }

    onFileUploadBillableItemHeaderId = (billableItemHeaderId: string) => {
        this.setState({
            billableItemHeaderId: billableItemHeaderId,
            gridVisibilitySignal: true,
        });
    };

    //A helper function that would toggle the state of the button to refresh the contents of the Parent Grid.
    toggleButtonStates = (toggleButtonState: boolean) => {
        this.setState({
            selectedPendingButton: toggleButtonState,
            selectedSubmittedButton: !toggleButtonState,
            parentGridType: toggleButtonState ? fileListGridType.Pending : fileListGridType.Submitted,
        });
    };

    setFilter = () => {
        return "";
    };
    onShowStatusOfFile = (newUploaded: BillableItemHeaderServiceRowItem[]) => {
        var statusOfFile: string = "";
        if (newUploaded.length > 0) {
            newUploaded.forEach((shiftImportDataItem: BillableItemHeaderServiceRowItem) => {
                statusOfFile = shiftImportDataItem.status;
            });
        }
        this.setState({
            statusOfUploadedFile: statusOfFile,
        });
    };

    onSubmitFile = () => {
        confirm(this.props.t("shiftUpload.uploadScheduleGridRow.submitConfirmation.text"), "Submit").then(
            (hasConfirmed) => {
                if (hasConfirmed) {
                    //call the API for posting the object and handle the response.
                    this.onSubmitConfirmation();
                }
            }
        );
    };

    onSubmitConfirmation = () => {
        this.setState({
            loadPanelVisible: true,
        });
        this.service
            .submitRowData(this.state.billableItemHeaderId)
            .then(this.handleSubmissionSuccess)
            .catch((error) => {
                var respMessage: string = "submitRowData failed with response: " + JSON.stringify(error);

                if (!this.service.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
            });
    };

    handleSubmissionSuccess = () => {
        this.setState({
            loadPanelVisible: false,
            refreshSignal: false,
            gridVisibilitySignal: false,
            hideHeader: true,
            serviceType: "",
            subServiceType: "",
            landingPage: "",
            selectedPendingButton: true,
            selectedSubmittedButton: false,
            parentGridType: fileListGridType.Pending,
            billableItemHeaderId: "",
            statusOfUploadedFile: "",
        });
        alert(
            "Thank you for your submission - in the event of any problems Event Uk will be in touch",
            "File Submitted"
        );
    };

    render() {
        var { landingPage } = this.props;
        var shiftImportIntro: string = "";
        var shiftImportStep1Intro: string = "";
        var shiftImportStep2Intro: string = "";
        var shiftImportStep3IntroReady: string = "";
        var shiftImportStep3IntroFailed: string = "";
        var dataSourceOfConfgurationValues = GlobalService.configurationValue();
        var isSubmitFileBtnDisabled: boolean = false;
        var refreshBtnClass: string = "";
        var submitBtnClass: string = "";
        if (this.state.statusOfUploadedFile) {
            isSubmitFileBtnDisabled = this.gridUtils.getStatusButtonDisabledState(this.state.statusOfUploadedFile);
            if (!isSubmitFileBtnDisabled) {
                submitBtnClass = "btn btn--small-inline saveCycleButtonColor";
            }
            else {
                submitBtnClass = "btn btn--small-inline";
            }
        }
        else {
            isSubmitFileBtnDisabled = true;
            submitBtnClass = "btn btn--small-inline";
        }
        if (
            !this.state.gridVisibilitySignal ||
            this.state.statusOfUploadedFile.toLowerCase() == "not accepted" ||
            this.state.statusOfUploadedFile.toLowerCase() == "failed" || this.state.statusOfUploadedFile.toLowerCase() == "ready for submission (warnings)" || submitBtnClass == "btn btn--small-inline saveCycleButtonColor"
        ) {
            refreshBtnClass = "btn btn--small-inline mt-3";
        } else if (this.state.gridVisibilitySignal) {
            refreshBtnClass = "btn btn--small-inline mt-3 saveCycleButtonColor";
        }

        if (dataSourceOfConfgurationValues.length > 0) {
            shiftImportIntro = dataSourceOfConfgurationValues.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_ShiftImport_Intro;
            })[0].value;

            shiftImportStep1Intro = this.props.shiftType !== "Security" ? "Please select the file type to upload." : dataSourceOfConfgurationValues.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_ShiftImport_Step1_Intro;
            })[0].value;

            shiftImportStep2Intro = dataSourceOfConfgurationValues.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_ShiftImport_Step2_Intro;
            })[0].value;

            shiftImportStep3IntroReady = dataSourceOfConfgurationValues.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_ShiftImport_Step3_Intro_Ready;
            })[0].value;

            shiftImportStep3IntroFailed = dataSourceOfConfgurationValues.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_ShiftImport_Step3_Intro_Failed;
            })[0].value;

        }
        return (
            <>
                <hr className="horizontal_border_style"></hr>
                <span>
                    {shiftImportIntro} <a href="https://stgeventukstorageprod01.blob.core.windows.net/supporting-documents/eventuk-shiftimportguidance.pdf" target="_blank">For guidance on importing shifts, please download the guide here.</a>
                </span>
                <br></br>
                <br></br>
                <span>
                    <b>Step 1 - Present File</b>
                </span>
                <div className="row">
                    <div className="col-md">
                        <DevExFileUploader
                            onFileUploaded={this.onFileUploaded}
                            onDropdownSelection={this.onDropdownSelection}
                            landingPage={landingPage}
                            typeId={this.props.typeId}
                            configtext={shiftImportStep1Intro}
                            onFileUploadBillableItemHeaderId={this.onFileUploadBillableItemHeaderId}
                            shiftType={this.props.shiftType}
                        />
                    </div>
                </div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                <hr className="horizontal_border_style"></hr>
                <span>
                    <b>Step 2 - Review File</b>
                </span>
                <br></br>
                <span>{shiftImportStep2Intro}</span>
                <br></br>
                <Button
                    className={refreshBtnClass}
                    onClick={this.onFileUploaded}
                    disabled={!this.state.gridVisibilitySignal}
                >
                    <span className="dx-button-text">Refresh</span>
                </Button>
                <div>
                    <br></br>
                    <br></br>
                    <UploadFileListGrid
                        refreshSignal={this.state.refreshSignal}
                        gridVisibilitySignal={this.state.gridVisibilitySignal}
                        service={this.state.serviceType}
                        subService={this.state.subServiceType}
                        landingPage={this.props.landingPage}
                        gridType={this.state.parentGridType}
                        billableItemHeaderId={this.state.billableItemHeaderId}
                        onShowStatusOfFile={this.onShowStatusOfFile}
                        pointOfNavigation="New File Submission"
                    />
                </div>
                <hr className="horizontal_border_style"></hr>
                <span>
                    <b>Step 3 - Submit File</b>
                </span>
                <br></br>
                <br></br>
                {this.state.statusOfUploadedFile.toLowerCase() == "not accepted" ||
                    this.state.statusOfUploadedFile.toLowerCase() == "failed" ? (
                    <span className="unscheduled-shift">
                        <b>{shiftImportStep3IntroFailed}</b>
                    </span>
                ) : this.state.statusOfUploadedFile.toLowerCase() == "ready for submission (warnings)" ? (
                    <span className="configurationText_Color">
                        {" "}
                        <b>{shiftImportStep3IntroReady}</b>
                    </span>
                ) : (
                    ""
                )}
                <br></br>
                <br></br>
                <Button
                    className={submitBtnClass}
                    disabled={isSubmitFileBtnDisabled}
                    onClick={this.onSubmitFile}
                >
                    <span className="dx-button-text">Submit File</span>
                </Button>
            </>
        );
    }
}

export default withTranslation()(NewFileSubmissionComponent);
