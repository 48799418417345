import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutline } from "@fortawesome/pro-regular-svg-icons";

interface StarRatingProps {
    score: number;
    distance?: string;
}

interface StarRatingState {
    wholeStars: number;
    halfStar: boolean;
}

class StarRating extends React.Component<StarRatingProps> {
    state: StarRatingState;

    constructor(props: StarRatingProps) {
        super(props);
        const roundedScore = Math.ceil((this.props.score > 5 ? 5 : this.props.score) * 2) / 2; // always rounds up to nearest 0.5

        this.state = {
            wholeStars: Math.floor(roundedScore),
            halfStar: roundedScore % 1 == 0.5 ? true : false,
        };
    }

    render() {
        let icons = [];
        let totalStars = this.state.wholeStars;
        let remainingStars = 5 - totalStars;
        for (let star = 0; star < totalStars; star++) {
            icons.push(
                <span key={`star_${star}`} className="star-rating__full-star">
                    <FontAwesomeIcon icon={faStar} />
                </span>
            );
        }
        if (this.state.halfStar) {
            icons.push(
                <span key={"onlyValue"} className="star-rating__half-star">
                    <FontAwesomeIcon icon={faStarHalfAlt} />
                </span>
            );
            remainingStars -= 1;
        }
        for (var emptyStar = 0; emptyStar < remainingStars; emptyStar++) {
            icons.push(
                <span key={`emptyStar_${emptyStar}`} className="star-rating__empty-star">
                    <FontAwesomeIcon icon={faStarOutline} />
                </span>
            );
        }
        return (
            <div className="star-rating__container">
                {this.props.distance && this.props.distance != "N/A" ? (
                    <span className="float-left artist-card__miles">
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> {this.props.distance} Miles
                    </span>
                ) : null}
                {icons}
            </div>
        );
    }
}

export default StarRating;
