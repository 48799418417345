import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LightModePageTemplate from "./Templates/LightModeTemplate";

class VersionPage extends Component<RouteComponentProps> {
    render() {
        var nextVersion= '[AIV]{version}[/AIV]';
        //console.log(autoVersion);
        return (
            <LightModePageTemplate>
                <dl className="ml-5">
                    <dt>Version:</dt>
                    {/* <dd>[AIV]{`{version}`}[/AIV]</dd> */}
                    <dd>{nextVersion}</dd>
                </dl>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(VersionPage);
