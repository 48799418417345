import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateBox, CheckBox } from "devextreme-react";
import React from "react";
import { DateBoxOnValueChangedEvent, CheckBoxOnValueChanged } from "../../../types/DevExtremeTypes";
import outstandingActionsUtilities, { NullableDate } from "../../Outstanding-Actions/OutstandingActionsUtilities";
import { itemTypes } from "../../grid/AddEditPopUpUtilities";
import sharedUtils from "../../grid/sharedUtilities";
import { faThumbsUp } from "@fortawesome/pro-regular-svg-icons";

interface CardPersonnelSectionProps {
    previousHoursRunningTotal: string;
    shiftCss: string;
    scheduled_Hours_Units: string;
    shift_Start: string;
    shift_End: string;
    check_In: string;
    check_Out: string;
    billable_Start: NullableDate;
    billable_Finish: NullableDate;
    disableBilableStartFinishFields: boolean;
    checkInStatus: string;
    checkOutStatus: string;
    billableHoursAdjustment: string;
    billableHours: string;
    originalBillableStartTime: string;
    originalBillableFinishTime: string;
    checkBoxType: boolean;
    checkBoxValue: boolean;
    handleChangeBillableStart: (dxValueChange: DateBoxOnValueChangedEvent) => void;
    handleChangeBillableFinish: (dxValueChange: DateBoxOnValueChangedEvent) => void;
    checkBoxValueChanged: (dxValueChange: CheckBoxOnValueChanged) => void;
    handleThumbsUpIconClick: (event: any) => void;
    itemTypeId: string;
    calculationText: string;
    providerPayAmountLocal: string;
    isThumbsUpClicked?: boolean;
}

class CardPersonnelSection extends React.Component<CardPersonnelSectionProps> {
    utils: outstandingActionsUtilities;
    sharedUtils: sharedUtils;
    constructor(props: CardPersonnelSectionProps) {
        super(props);
        this.utils = new outstandingActionsUtilities();
        this.sharedUtils = new sharedUtils();
    }

    render() {
        var {
            scheduled_Hours_Units,
            shift_Start,
            shift_End,
            billable_Start,
            billable_Finish,
            disableBilableStartFinishFields,
            billableHours,
            billableHoursAdjustment,
            originalBillableStartTime,
            originalBillableFinishTime,
            checkBoxType,
            checkBoxValue,
            shiftCss,
            previousHoursRunningTotal,
            itemTypeId,
            calculationText,
        } = this.props;
        var shiftText: string = "Scheduled Shift:";
        var shiftTimings: string = scheduled_Hours_Units;
        var shiftStart: string = shift_Start;
        var shiftEnd: string = shift_End;
        var billableHoursText: string = "Approved Shift:";
        var billedHours: string = billableHours;
        var billedHoursAdjusted: string = ` (${billableHoursAdjustment ? billableHoursAdjustment : ""})`;
        if (itemTypeId == itemTypes.AdjustmentClientDebit || itemTypeId == itemTypes.Adjustment) {
            shiftText = "Previously Approved Shift:";
            shiftTimings = this.utils.convertBillableMinutesToHoursFormat(
                this.sharedUtils.convertStringToFloat(previousHoursRunningTotal)
            );
            shiftStart = originalBillableStartTime;
            shiftEnd = originalBillableFinishTime;
            billableHoursText = "Adjusted Shift - New Hours to Approve:";
            billedHoursAdjusted = "(" + billedHours + ")";
            billedHours = billableHoursAdjustment;

        } else if (itemTypeId == itemTypes.AdjustmentProviderDebit) {
            shiftText = "Previously Approved Shift:";
            shiftTimings = this.utils.convertBillableMinutesToHoursFormat(
                this.sharedUtils.convertStringToFloat(previousHoursRunningTotal)
            );
            shiftStart = originalBillableStartTime;
            shiftEnd = originalBillableFinishTime;
            billableHoursText = "Amended Shift - New Hours to Accept:";
            billedHoursAdjusted = "(" + billedHours + ")";
            billedHours = billableHoursAdjustment;

        }

        return (
            <div className="text-center">
                <div>{`${shiftText} ${shiftTimings}`}</div>
                <div className="row mt-3">
                    <div className="col-6">
                        <div className="time-indicator time-indicator--checkin">{shiftStart}</div>
                    </div>
                    <div className="col-6">
                        <div className="time-indicator time-indicator--checkout">{shiftEnd}</div>
                    </div>
                </div>
                <div className="row my-3">
                    <div className={`col-12 text-center`}>
                        {calculationText ? (
                            calculationText
                        ) : (
                            <>
                                {`${billableHoursText} `}
                                <span className={`${shiftCss}`}>
                                    {`${billedHours
                                        ? billedHours.trim()
                                        : billableHoursText.toLowerCase() == "approved shift:" && !billedHours
                                            ? "0 Hrs"
                                            : ""
                                        }`}
                                    {!billableHoursAdjustment ? "" : billedHoursAdjusted}
                                </span>
                            </>
                        )}
                    </div>
                </div>

                <div className="row my-3">
                    <div className="col-6">
                        <div className="py-0">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="Select time"
                                acceptCustomValue={false}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.props.handleChangeBillableStart}
                                value={billable_Start ? billable_Start : undefined}
                                valueChangeEvent="keyup"
                                disabled={disableBilableStartFinishFields}
                                pickerType={"list"}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="py-0">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="Select time"
                                acceptCustomValue={false}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.props.handleChangeBillableFinish}
                                value={billable_Finish ? billable_Finish : undefined}
                                valueChangeEvent="keyup"
                                disabled={disableBilableStartFinishFields}
                                pickerType={"list"}

                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-2 align-items-center">
                    {checkBoxType ? (
                        <>
                            <div className="col-6 text-left">
                                <CheckBox
                                    value={checkBoxValue}
                                    onValueChanged={this.props.checkBoxValueChanged}
                                    width={80}
                                    text="Absent"
                                    disabled ={disableBilableStartFinishFields}
                                />
                            </div>
                           
                            <div className="col-6 text-right">
                            <a
                                href="#"
                                className={`faThumbsUp-btn ${this.props.isThumbsUpClicked ? "text-success" : ""}`}
                                onClick={this.props.handleThumbsUpIconClick}
                            >
                                <FontAwesomeIcon icon={faThumbsUp} />
                            </a>
                                {this.props.providerPayAmountLocal}

                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-6 text-left">
                                <CheckBox
                                    value={checkBoxValue}
                                    onValueChanged={this.props.checkBoxValueChanged}
                                    width={80}
                                    text="No Charge"
                                    disabled={!disableBilableStartFinishFields}
                                />
                            </div>
                           
                            <div className="col-6 text-right">
                            <a
                                href="#"
                                className={`faThumbsUp-btn ${this.props.isThumbsUpClicked ? "text-success" : ""}`}
                                onClick={this.props.handleThumbsUpIconClick}
                            >
                                <FontAwesomeIcon icon={faThumbsUp} />
                            </a>
                                {this.props.providerPayAmountLocal}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
export default CardPersonnelSection;
