import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError } from "./BaseApiService";

// Endpoints
const ADD_SHIFT_URL = "BillableItem/AddShift";
const CURRENT_DAY_SHIFT_URL = "BillableItem/Todayshift";
const CHECKIN_API_URL = "BillableItem";
const RECENT_WORKER_DROPDOWN = "BillableItem/RecentWorker";

export interface checkinCardData {
    billableItemId: string;
    name: string;
    idValue: string;
    scheduledStartTime: string;
    scheduledFinishTime: string;
    serviceTypeLookUpID: string;
    serviceTypeLookUpName: string;
    serviceSubTypeLookUpID: string;
    serviceSubTypeLookUpName: string;
    checkInTime: string;
    checkOutTime: string;
    venueName: string;
    jobRole: string;
    dateFrom: string;
}

export interface successMessageResponse {
    sucessMessage: string;
    messageColor: string;
}


/// REST service for the check in/out card component.
class CheckInCardService {
    // Method to post the data to the server for validation.
    submitShiftData(data: object) {
        return _post(ADD_SHIFT_URL, data);
    }

    //To fetch the array of objects from the server wherein each object maps onto a particular card component.
    getCurrentDayShiftData(clientId : string , venueId : string) {
        if(clientId || venueId) {
            return _get(CURRENT_DAY_SHIFT_URL + `?clientId=${clientId}&venueId=${venueId}`);
        }
        else {
            return _get(CURRENT_DAY_SHIFT_URL);
        }       
    }

    //Retrieves the data for a single card based upon the BillableItemID for a particular row.
    getCheckinCardData(id: string) {
        return _get(`${CHECKIN_API_URL}/${id}`);
    }

    //Retrieve look up data for recent worker 
    getRecentWorkerData(venueId : string) {
        return _get(RECENT_WORKER_DROPDOWN + `?venueId=${venueId}`);
    }
    //#endregion
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default CheckInCardService;
