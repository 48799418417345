import { AxiosResponse } from "axios";
import { _get, _aiTraceAsError, _post } from "./BaseApiService";

// Endpoints
const SUPPLIER_INFORMATION_API = "Dashboard/entity";
const OPERATIONAL_CONTACT_LU_API = "lookup/venue";
const APPROVED_RATING_INFO_API = "Dashboard/entity/rating";
const SAVE_SECURITY_CONTACT_API = "contact";

export interface RateResponse {
    typeLookValue: string;
    rate_Higher: number;
    rateText: string;
}

export interface EnhancementResponse {
    date: string;
    multiplier: string;
    enhancementTextPart1: string;
    enhancementTextPart2: string;
}

export interface ExceptionalRatesResponse {
    typeLookValue: string;
    name: string;
    idValue: string;
    rate_Higher: number;
    rateText: string;
}

export interface PrimarySecurityContact {
    id: number;
    verified: string;
    contactTypeLookUpId: string;
    contactTypeOther: string;
    name: string;
    jobTitle: string;
    email: string;
    businessTelphone: string;
    mobileTelephone: string;
    otherTelephone: string;
    addressStreet1: string;
    addressStreet2: string;
    addressStreet3: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
    dynamicsContactID: string;
    preferredContact: boolean;
    emailNotification: boolean;
    emailRemittance: boolean;
    emailInvoice: boolean;
    emailSelfInvoice: boolean;
    phone: boolean;
    text: boolean;
    fax: boolean;
    mail: boolean;
    gdprOptout: boolean;
    dateOfBirth: string;
    utr: string;
    ni: string;
    active: string;
    title: string;
    firstName: string;
    lastName: string;
    primaryCleaningContactId: string;
    primarySecurityContactId: string;
    cleaningProviderBusinessEntityID: string;
    securityProviderBusinessEntityID: string;
    addressLongitude: string;
    addressLatitude: string;
    lastUpdatedBy: number;
    lastUpdatedOn: string;
    contactTypeLookUp: string;
    billableItem: [];
    payment: [];
    contractCleaningBusinessEntity: string;
    securityProviderBusinessEntity: string;
    primarySecurityContact: string;
    primaryCleaningContact: string;
    user: string;
}

export interface clientVenueAddEditObject {
    contactId: string;
    primarySecurityContactId: string;
}

/// Service
class SupplierInformationService {
    getSupplierInformation(clientId: number, venueId: number): Promise<AxiosResponse<any>> {
        return _get(`${SUPPLIER_INFORMATION_API}?venueId=${venueId}&clientId=${clientId}`);
    }
    getAllVenueInformation(clientId: string): Promise<AxiosResponse<any>> {
        return _get(`${SUPPLIER_INFORMATION_API}?clientId=${clientId}`);
    }
    getOperationalContactLookup(venueId: string): Promise<AxiosResponse<any>> {
        return _get(`${OPERATIONAL_CONTACT_LU_API}/${venueId}/contacts`);
    }
    getApprovedRatingInformation(clientId: number, venueId: number): Promise<AxiosResponse<any>> {
        return _get(`${APPROVED_RATING_INFO_API}?venueId=${venueId}&clientId=${clientId}`);
    }

    savePrimarySecurityContactRowData(securityContactObject : clientVenueAddEditObject): Promise<AxiosResponse<any>> {
        return _post(SAVE_SECURITY_CONTACT_API, securityContactObject);
    }

    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default SupplierInformationService;
