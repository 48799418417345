import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SideBarTemplate from "../../side-bar/SideBarTemplate";
import DropDownFromLookupTable from "../../select/SelectBoxFromLookupTable";
import lookupService, { LookupTypeIndexes } from "../../../services/LookupService";
import PendingRequestCard from "./PendingRequestCard";
import { AxiosResponse } from "axios";
import { LookUpOnValueChangedEvent, SelectBoxOnValueChangedEvent } from "../../../types/DevExtremeTypes";
import PendingRequestService, { PendingRequestItemResponse } from "../../../services/PendingRequestService";
import pendingRequestUtilities from "./PendingRequestUtilities";
import PendingRequestDateList from "./PendingRequestDates";
import { LoadIndicator } from "devextreme-react";
import { alert } from "devextreme/ui/dialog";
import LightModePageTemplate from "../../page/Templates/LightModeTemplate";
import UserService, { RoleGroupNames } from "../../../services/UserService";
import LookupTypeListDynamic from "../../select/LookupTypeListDynamic";

interface PendingRequestCardListProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
    queryRequestId: string;
}

interface PendingRequestCardListState {
    raisingQuery: boolean;
    clientId: string;
    venueId: string;
    cards: PendingRequestItemResponse[];
    filteredCards: PendingRequestItemResponse[];
    dateList: string[];
    datePicklist: string[];
    showProgressIndicator: boolean;
    queryRequestId: string;
}

class PendingRequestCardListPage extends Component<PendingRequestCardListProps> {
    utils: pendingRequestUtilities;
    lookUp: lookupService;
    service: PendingRequestService;
    state: PendingRequestCardListState;

    constructor(props: PendingRequestCardListProps) {
        super(props);
        this.utils = new pendingRequestUtilities();
        this.lookUp = new lookupService();
        this.service = new PendingRequestService();
        //Initialize the State
        this.state = {
            raisingQuery: false,
            clientId: "",
            venueId: "",
            cards: [],
            filteredCards: [],
            dateList: [],
            datePicklist: [],
            showProgressIndicator: true,
            queryRequestId: this.props.queryRequestId ? this.props.queryRequestId : "",
        };
    }

    componentDidMount = () => {
        this.fetchPendingRequestCardDetails();
    };

    fetchPendingRequestCardDetails = () => {
        if (this.props.queryRequestId) {
            this.service
                .getIndividualRequestItem(this.props.queryRequestId)
                .then(this.handleDataRetrievalSuccess)
                .catch(this.handleStandardError);
        } else {
            this.lookUp
                .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                .then(this.handleClientLookUpSuccess)
                .catch(this.handleStandardError);
        }
    };

    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };

    //Single card for the matched filterBy query param.
    handleDataRetrievalSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data) {
            this.setState({
                showProgressIndicator: false,
                cards: [response.data.data],
                filteredCards: [response.data.data],
                datePicklist: [response.data.data.dateFrom],
                dateList: [response.data.data.dateFrom],
                clientId: response.data.data.clientBusinessEntityId ? response.data.data.clientBusinessEntityId : "",
                venueId: response.data.data.venueId ? response.data.data.venueId : "",
            });
        }
    };
    //List of all the pending request cards.
    handleRetrieveSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            this.setState(
                {
                    cards: response.data.data,
                    showProgressIndicator: false,
                    datePicklist: [],
                    dateList: [],
                },
                () => this.groupByDates()
            );
        } else {
            this.setState(
                {
                    cards: response.data.data,
                    showProgressIndicator: false,
                },
                () => this.groupByDates()
            );
            alert("No data found for the above selected client and venue", "Sorry!");
        }
    };

    //Helper function that would group the response by distinct dates.
    groupByDates = () => {
        var uniqueDates: string[] = this.state.cards ? this.utils.uniqueByDate(this.state.cards, "dateFrom") : [];
        var listOfFilteredRequestedCards = this.utils.filterRequestCards(this.state.cards, [uniqueDates[0]]);
        this.setState({
            datePicklist: uniqueDates,
            dateList: [uniqueDates[0]],
            filteredCards: listOfFilteredRequestedCards,
        });
    };

    handleClientLookUpSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            clientId: response.data && response.data.data ? response.data.data[0].id : "",
        });

        this.lookUp
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLookUpSuccess)
            .catch(this.handleStandardError);
    };

    handleVenueLookUpSuccess = (response: AxiosResponse<any>) => {
        var displayVenue: string = "";
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            !this.isBlank(this.state.clientId)
        ) {
            var venues: any[] = response.data.data;
            venues = venues.filter((item) => {
                return item.parentMappingId == this.state.clientId;
            });
            if (
                UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
            ) {
                displayVenue = "";
            } else {
                displayVenue = venues[0].id;
            }
            this.setState(
                {
                    venueId: displayVenue,
                },
                () => this.getListofPendingRequestCards()
            );
        }
    };

    getListofPendingRequestCards = () => {
        const { clientId, venueId } = this.state;
        this.setState({
            showProgressIndicator: true,
        });
        this.service
            .getListOfPendingRequests(clientId, venueId)
            .then(this.handleRetrieveSuccess)
            .catch(this.handleStandardError);
    };

    //Helper function that would filter the Card List based on the selected dates.
    buildListOfFilteredCards = (date: string, selection: boolean) => {
        var listOfDates: string[] = [];
        listOfDates = this.state.dateList;
        if (selection == true) {
            //Push the date in the array if the date is selected.
            listOfDates.push(date);
        } else {
            const index = listOfDates.indexOf(date);
            if (index > -1) {
                listOfDates.splice(index, 1);
            }
        }
        var listOfFilteredRequestedCards = this.utils.filterRequestCards(this.state.cards, listOfDates);
        this.setState({
            dateList: listOfDates,
            count: listOfDates.length,
            filteredCards: listOfFilteredRequestedCards,
        });
    };

    //Helper function that would update the panel and remove the cards from the view once a Book/Cancel Button is clicked.
    //FindIndex metnod used below returns the index of the first occurrence of a given key.
    removeCardsFromtheView = (id: string, dateFrom: string) => {
        var dates: string[] = this.state.datePicklist;
        var datesFromList: string[] = this.state.dateList;
        var filteredRequestedCards: PendingRequestItemResponse[] = this.state.filteredCards;
        var index = filteredRequestedCards.findIndex((item) => {
            return item.id.trim() == id.trim();
        });
        var cardCount = this.utils.countCardsForSelectedDate(filteredRequestedCards, dateFrom);
        if (cardCount == 1) {
            var position = dates.findIndex((item) => {
                return item.trim().localeCompare(dateFrom.trim()) == 0;
            });
            var positionOfDateinDateList = datesFromList.findIndex((item) => {
                return item.trim().localeCompare(dateFrom.trim()) == 0;
            });
            dates.splice(position, 1);
            datesFromList.splice(positionOfDateinDateList, 1);
        }
        if (index > -1) {
            filteredRequestedCards.splice(index, 1);
        }
        this.setState({
            filteredCards: filteredRequestedCards,
            datePicklist: dates,
        });
    };

    handleStandardError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState(
            {
                venueId: dxValueChange.value,
            },
            () => this.getListofPendingRequestCards()
        );
    };

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState(
            {
                clientId: dxValueChange.value,
                venueId: "",
            },
            () => this.getListofPendingRequestCards()
        ); //When a client dropdonw is changed, initiate the API call, and default the VenueID to "", because the State is still holding the previously selected VenueId.
    };

    componentDidUpdate = (prevprops: PendingRequestCardListProps, prevState: PendingRequestCardListState) => {
        if (this.props.queryRequestId != prevprops.queryRequestId) {
            this.fetchPendingRequestCardDetails();
        }
    };

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate>
                    <div className="filter-box mt-3">
                        <h4 className="filter-box__section-header">Pending Requests</h4>
                        <ul className="pending-request-list">
                            {this.state.showProgressIndicator ? (
                                <div className="row col-lg-12">
                                    <span className="col-lg-12 text-center">
                                        <LoadIndicator
                                            id="simple-grid-indicator"
                                            height={100}
                                            width={100}
                                            visible={this.state.showProgressIndicator}
                                        />
                                    </span>
                                </div>
                            ) : this.state.datePicklist ? (
                                this.state.datePicklist.map((date: string, index: number) => {
                                    return (
                                        <PendingRequestDateList
                                            key={date}
                                            date={date}
                                            selectDate={this.buildListOfFilteredCards}
                                            serialNo={index}
                                        />
                                    );
                                })
                            ) : null}
                        </ul>
                    </div>
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    <div className="container">
                        <div className="row pb-2">
                            <div className="mt-3 col-3 col-lg-1 font-weight-bold">Client</div>
                            <div className="mt-3 col-8 col-lg-4">
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                    onValueChanged={this.handleChangeClientTypeSelect}
                                    value={this.state.clientId}
                                    disableSignal={this.props.queryRequestId ? true : false}
                                />
                                <input
                                    data-testid={LookupTypeIndexes.clientType}
                                    type="hidden"
                                    name={LookupTypeIndexes.clientType}
                                ></input>
                            </div>
                            <div className="mt-3 col-3 col-lg-1 font-weight-bold">Venue</div>
                            <div className="mt-3 col-8 col-lg-4">
                                <LookupTypeListDynamic
                                    isDisabled={this.props.queryRequestId ? true : false}
                                    lookupTypeIndex={LookupTypeIndexes.venueType}
                                    onValueChanged={this.handleChangeVenueTypeSelect}
                                    isRoot={false}
                                    parentMappingId={this.state.clientId}
                                    value={this.state.venueId}
                                    displayExpression={"value"}
                                />
                            </div>
                        </div>
                        {this.state.showProgressIndicator ? (
                            <div className="row col-lg-12">
                                <span className="col-lg-12 text-center">
                                    <LoadIndicator
                                        id="simple-grid-indicator"
                                        height={100}
                                        width={100}
                                        visible={this.state.showProgressIndicator}
                                    />
                                </span>
                            </div>
                        ) : this.state.filteredCards ? (
                            this.state.filteredCards.map((cardItem: PendingRequestItemResponse, index: number) => {
                                return (
                                    <div className="mb-3" key={cardItem.id}>
                                        <PendingRequestCard
                                            renderCards={this.removeCardsFromtheView}
                                            cardData={cardItem}
                                        />
                                    </div>
                                );
                            })
                        ) : null}
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}
export default withRouter(PendingRequestCardListPage);
