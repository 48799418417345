import React from "react";

export interface ErrorMessageProps {
    data: any;
}

export interface ErrorMessageState {
    errMsgArr: [];
}

class ErrorMessage extends React.Component<ErrorMessageProps> {
    state: ErrorMessageState;
    constructor(props: ErrorMessageProps) {
        super(props);
        this.state = {
            errMsgArr: this.props.data.data.errorMessage !== "" ? JSON.parse(this.props.data.data.errorMessage) : [],
        };
    }

    render() {
        return (
            <span>
                <ul className="mb-0">
                    {this.state.errMsgArr
                        ? this.state.errMsgArr.map((item: any, uniqueKey: number) => {
                              return (
                                  <li key={uniqueKey}>
                                      <b className={item.ErrorType == "Warning" ? `text-warning` : `text-danger`}>
                                          {item.ColumnName}
                                      </b>
                                      : "{item.ErrorType} - {item.ErrorMessage}"
                                  </li>
                              );
                          })
                        : null}
                </ul>
            </span>
        );
    }
}

export default ErrorMessage;
