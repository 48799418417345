import React from "react";
import GigHighLevelService, { GigHighLevelData, RequestType } from "../../services/GigHighLevelService";
import GigHighLevelCard from "./GigHighLevelCard";
import { AxiosResponse } from "axios";
import { LoadIndicator } from "devextreme-react";
import CollapsibleCardGrid from "../Cards/CollapsibleCardGrid";
import LazyLoad from "react-lazyload";
import { LazyLoadConfig } from "../grid/sharedUtilities";
import HeaderService, { HeaderResponse } from "../../services/HeaderService";

interface GigHighLevelListProps {
    location: any;
    history: any;
}

interface GigHighLevelListState {
    gigHighLevelList: GigHighLevelData[];
    gigCardList: RequestType[];
    totalNumberGig: string;
    totalNumberAction: string;
    totalNumberQuery: string;
    showProgressIndicator: boolean;
    serviceTypeId: string;
    serviceTypeName: string;
    expandedForGig: boolean;
    expandedForAction: boolean;
    expandedForQuery: boolean;
    headerResponse: HeaderResponse;
}

class GigHighLevelList extends React.Component<GigHighLevelListProps> {
    //State object to manage the state of the List of cards.
    state: GigHighLevelListState;
    //Service that fetches the data for the individual cards.
    gigHighLevelService: GigHighLevelService;
    //Service to fetch the header response.
    service: HeaderService;

    constructor(props: GigHighLevelListProps) {
        super(props);
        //Initializing the service.
        this.gigHighLevelService = new GigHighLevelService();
        this.service = new HeaderService();

        this.state = {
            gigHighLevelList: [],
            gigCardList: [],
            totalNumberGig: "0",
            totalNumberAction: "0",
            totalNumberQuery: "0",
            showProgressIndicator: false,
            serviceTypeId: "30",
            serviceTypeName: "Entertainment",
            expandedForGig: false,
            expandedForAction: false,
            expandedForQuery: false,
            headerResponse: {
                title: "",
                description: "",
                businessEntityId: "",
            },
        };
    }

    componentDidMount() {
        //Call the header API to fetch the details of the Business Entity Id.
        this.service
            .getMainHeaderByRole()
            .then((response) =>
                this.setState({
                    headerResponse: response.data.data,
                })
            )
            .catch((error) => console.log(error));
        this.fetchGigHighLevelCardDetails();
    }

    fetchGigHighLevelCardDetails = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.gigHighLevelService.getGigHighLevelCardList().then(this.handleSuccess).catch(this.handleFailure);
    };

    //Arrow functions bind the context of 'this' inside the function itself, so no need to bind inside of the constructor.
    //To fetch in the card details from the server.
    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        if (response && response.data && response.data.data) {
            this.setState({
                gigHighLevelList: response.data.data,
            });
        }
    };

    handleFailure = (response: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    uniqueBy = (arr: any, prop: any) => {
        return arr.reduce((a: any, d: any) => {
            if (!a.includes(d[prop])) {
                a.push(d[prop]);
            }
            return a;
        }, []);
    };

    render() {
        var { headerResponse } = this.state;
        return (
            <LazyLoad height={LazyLoadConfig.height} offset={LazyLoadConfig.offset}>
                <div className="row justify-content-left pt-4">
                    <div className="col-12 col-lg-12">
                        <section className="collapsible-grid__container mb-4">
                            <h2 className="page-title__lightgrey">Gig List</h2>
                        </section>
                        <br></br>
                    </div>
                </div>
                {this.state.gigHighLevelList.length > 0 ? (
                    this.state.gigHighLevelList.map((gigHighLevelItem: GigHighLevelData, index: any) => {
                        let serialNoGig = 1;

                        var dates =
                            gigHighLevelItem.requestItems && gigHighLevelItem.requestItems.length > 0
                                ? this.uniqueBy(gigHighLevelItem.requestItems, "dateFrom")
                                : null;
                        return (
                            <div key={index}>
                                <section className="collapsible-grid__container mb-4" key={index}>
                                    {gigHighLevelItem.requestItems && gigHighLevelItem.requestItems.length > 0 ? (
                                        <>
                                            <CollapsibleCardGrid
                                                title={gigHighLevelItem.typeValue}
                                                numItems={parseInt(gigHighLevelItem.typeCount)}
                                            >
                                                {dates
                                                    ? dates.map((date: any, index: any) => {
                                                          var cardDetailsAsPerDate = gigHighLevelItem.requestItems
                                                              ? gigHighLevelItem.requestItems.filter(
                                                                    (item: RequestType) => item.dateFrom === date
                                                                )
                                                              : null;

                                                          return (
                                                              <div className="col-12" key={index}>
                                                                  <div className="row justify-content-left mt-2">
                                                                      <div className="col-12">
                                                                          <h5 className="date-separator_border text-left">
                                                                              <span className="font-weight-bold d-block text-left">
                                                                                  {date}
                                                                              </span>
                                                                          </h5>
                                                                      </div>
                                                                  </div>
                                                                  {cardDetailsAsPerDate ? (
                                                                      <div className="row justify-content-left mt-2">
                                                                          {cardDetailsAsPerDate.map(
                                                                              (gigCard: RequestType, index: any) => (
                                                                                  <div
                                                                                      className="col col-12 col-lg-4"
                                                                                      key={index}
                                                                                  >
                                                                                      <GigHighLevelCard
                                                                                          gigCardItem={gigCard}
                                                                                          totalNumber={parseInt(
                                                                                              gigHighLevelItem.typeCount
                                                                                          )}
                                                                                          serialNumber={serialNoGig++}
                                                                                          location={this.props.location}
                                                                                          history={this.props.history}
                                                                                          headerResponse={
                                                                                              headerResponse
                                                                                          }
                                                                                      />
                                                                                  </div>
                                                                              )
                                                                          )}
                                                                      </div>
                                                                  ) : null}
                                                              </div>
                                                          );
                                                      })
                                                    : null}
                                            </CollapsibleCardGrid>
                                        </>
                                    ) : null}
                                </section>
                            </div>
                        );
                    })
                ) : this.state.showProgressIndicator ? (
                    <div className="row">
                        <span className="col-lg-12 text-center">
                            <LoadIndicator
                                id="simple-grid-indicator"
                                height={100}
                                width={100}
                                visible={this.state.showProgressIndicator}
                            />
                        </span>
                    </div>
                ) : (
                    <>
                        <header className="collapsible-grid__header">
                            <h3 className="collapsible-grid__title">No data found</h3>
                        </header>
                    </>
                )}
            </LazyLoad>
        );
    }
}

export default GigHighLevelList;
