import "./polyfill"; // for polyfills as IE11 support is required
import React from "react";
import ReactDOM from "react-dom";
import AppContainer from "./AppContainer";
import { BrowserRouter } from "react-router-dom";

//for devextreme license (error introduces from version 23.2)
import config from 'devextreme/core/config'; 
// import { licenseKey } from './devextreme-license'; 
// config({ licenseKey }); 

let licenseKey=  process.env.DEVEXTREME_KEY ? process.env.DEVEXTREME_KEY : '';
//console.log(licenseKey,'license key');
config({ licenseKey}); 

const wrapper = document.getElementById("root");
const app = (
    <BrowserRouter>
        <AppContainer />
    </BrowserRouter>
);

if(wrapper) ReactDOM.render(app, wrapper);
