import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faComment } from "@fortawesome/pro-regular-svg-icons";
import { queryCardData, actionItemData, gigItemData, ServiceSubTypeId } from "../../services/ArtistService";
import EntLandUtilities from "./EntLandingUtilities";
import sharedUtils from "../grid/sharedUtilities";
import CardWrapper from "../Cards/CardWrapper";

// all of this info will be needed to populate a card
// not sure if status (late/on time) will come from db or be dervied here
interface GigsCardProps {
    cardUIType: string;
    gigCardItem?: gigItemData;
    actionCardItem?: actionItemData;
    queryCardItem?: queryCardData;
    totalNumber: number;
    serialNumber: number;
    location: any;
    history: any;
}
interface GigsCardState {
    showButton: boolean;
    showDetailLink: boolean;
    showViewButton: boolean;
    showDescText: boolean;
    showPaymentStatus: boolean;
}

class GigsCard extends React.Component<GigsCardProps> {
    state: GigsCardState;
    //service that would post the data back to the server.

    utils: EntLandUtilities;
    sharedUtils: sharedUtils;
    constructor(props: GigsCardProps) {
        super(props);
        this.utils = new EntLandUtilities();
        this.sharedUtils = new sharedUtils();
        this.state = {
            showButton: this.props.cardUIType == "outstandingAction" ? true : false,
            showDetailLink: this.props.cardUIType == "gigs" ? true : false,
            showViewButton: this.props.cardUIType == "query" ? true : false,
            showDescText: this.props.cardUIType == "query" ? true : false,
            showPaymentStatus: this.props.cardUIType == "outstandingAction" ? true : false,
        };
        this.onConfirmClick = this.onConfirmClick.bind(this);
    }

    onConfirmClick = (billableItemId: string) => {
        this.props.history.push({
            pathname: "/EntertainmentCompletionCard",
            state: { id: billableItemId, FeedBack: false },
        });
    };

    onFeedbackClick = (billableItemId: string) => {
        this.props.history.push({
            pathname: "/EntertainmentCompletionCard",
            state: { id: billableItemId, FeedBack: true },
        });
    };

    onViewClick = (id: string) => {};
    isPerfInfoRequired = (data: any): boolean => {
        var showPerInfo = true;
        if (
            data.serviceSubTypeId == ServiceSubTypeId.DJ ||
            data.serviceSubTypeId == ServiceSubTypeId.Karaoke ||
            data.serviceSubTypeId == ServiceSubTypeId.PubQuiz
        ) {
            showPerInfo = false;
        }
        return showPerInfo;
    };
    render() {
        var delimeter: string = "-";
        var maxWidthQueryDesc = 25;
        var gigData = this.props.gigCardItem;
        var actionData = this.props.actionCardItem;
        var queryData = this.props.queryCardItem;

        var serviceName =
            gigData && gigData.service
                ? gigData.service
                : actionData && actionData.service
                ? actionData.service
                : queryData && queryData.serviceTypeValue && queryData.serviceSubTypeValue
                ? `${queryData.serviceTypeValue} ${delimeter} ${queryData.serviceSubTypeValue}`
                : "";

        var venueName =
            gigData && gigData.venueName
                ? gigData.venueName
                : actionData && actionData.venueName
                ? actionData.venueName
                : queryData && queryData.venueName
                ? queryData.venueName
                : "";

        var city =
            gigData && gigData.venueTownCity
                ? gigData.venueTownCity
                : actionData && actionData.venueTownCity
                ? actionData.venueTownCity
                : queryData && queryData.city
                ? queryData.city
                : "";

        var dateInfo =
            gigData && gigData.entertainmentDateInfo
                ? this.utils.getDateInfo(gigData.entertainmentDateInfo)
                : actionData && actionData.entertainmentDateInfo
                ? this.utils.getDateInfo(actionData.entertainmentDateInfo)
                : queryData && queryData.entertainmentDateInfo
                ? this.utils.getDateInfo(queryData.entertainmentDateInfo)
                : "";
        var startTimeInfo =
            gigData && gigData.entertainmentDateInfo
                ? this.utils.getStartTimeInfo(gigData.entertainmentDateInfo)
                : actionData && actionData.entertainmentDateInfo
                ? this.utils.getStartTimeInfo(actionData.entertainmentDateInfo)
                : queryData && queryData.entertainmentDateInfo
                ? this.utils.getStartTimeInfo(queryData.entertainmentDateInfo)
                : "";
        var endTimeInfo =
            gigData && gigData.entertainmentDateInfo
                ? this.utils.getEndTimeInfo(gigData.entertainmentDateInfo)
                : actionData && actionData.entertainmentDateInfo
                ? this.utils.getEndTimeInfo(actionData.entertainmentDateInfo)
                : queryData && queryData.entertainmentDateInfo
                ? this.utils.getEndTimeInfo(queryData.entertainmentDateInfo)
                : "";
        var totalTimeInfo =
            !this.utils.isBlank(startTimeInfo) && !this.utils.isBlank(endTimeInfo)
                ? `${startTimeInfo} - ${endTimeInfo}`
                : startTimeInfo;
        var gigPerfInfo =
            gigData &&
            !this.sharedUtils.isBlank(gigData.performanceInformationSetsNumber) &&
            !this.sharedUtils.isBlank(gigData.performanceInformationSetsDuration)
                ? `${gigData.performanceInformationSetsNumber} X ${gigData.performanceInformationSetsDuration}`
                : null;

        var actionPerfInfo =
            actionData &&
            !this.sharedUtils.isBlank(actionData.performanceInformationSetsNumber) &&
            !this.sharedUtils.isBlank(actionData.performanceInformationSetsDuration)
                ? `${actionData.performanceInformationSetsNumber} X ${actionData.performanceInformationSetsDuration}`
                : null;

        var performanceInfo =
            !this.sharedUtils.isBlank(gigPerfInfo) && this.isPerfInfoRequired(gigData)
                ? `${gigPerfInfo} minutes sets`
                : !this.sharedUtils.isBlank(actionPerfInfo) && this.isPerfInfoRequired(actionData)
                ? `${actionPerfInfo} minutes sets`
                : "";

        var finalTimePerfInfo =
            !this.utils.isBlank(performanceInfo) && !this.utils.isBlank(totalTimeInfo)
                ? `${totalTimeInfo}, ${performanceInfo}`
                : !this.utils.isBlank(totalTimeInfo) && this.utils.isBlank(performanceInfo)
                ? totalTimeInfo
                : this.utils.isBlank(totalTimeInfo) && !this.utils.isBlank(performanceInfo)
                ? performanceInfo
                : null;

        var serviceSubType =
            gigData && gigData.role
                ? gigData.role
                : actionData && actionData.service
                ? actionData.service.split("-")[1]
                : "";

        var payAmount =
            gigData && gigData.provider_Pay_Amount_Local_Ccy
                ? gigData.provider_Pay_Amount_Local_Ccy
                : actionData && actionData.provider_Pay_Amount_Local_Ccy
                ? actionData.provider_Pay_Amount_Local_Ccy
                : "";

        var payDate =
            actionData && actionData.pay_Date
                ? this.utils.convertddmmyyyyStringToCustomizedFormat(actionData.pay_Date)
                : "";
        var payDateFieldInfo = payDate ? `${"due "}${payDate}` : " pending";
        var finalPayDisplayText = this.state.showPaymentStatus ? payDateFieldInfo : null;

        var querydescription = queryData && queryData.queryDescription ? queryData.queryDescription : "";
        var querystatus = queryData && queryData.queryStatus ? queryData.queryStatus : "";

        var statusMessageClass = querystatus && querystatus.toUpperCase() == "RESOLVED" ? "text-info" : "text-warning";
        var showConfirmButton = actionData && actionData.shiftCompleteAcknowledgment ? false : true;

        var outstandingItemId = actionData && actionData.billableItemId ? actionData.billableItemId : "";
        var gigItemId = gigData && gigData.billableItemId ? gigData.billableItemId : "";
        var queryId = queryData && queryData.id ? queryData.id : "";
        var separator: string = ",";
        return (
            <CardWrapper
                type="entertainer"
                typeTitle={serviceName}
                enumeration={true}
                cardIndex={this.props.serialNumber}
                cardsTotal={this.props.totalNumber}
                fullHeight={true}
            >
                <div className="euk-card__content-section">
                    <div className="entertainer-card-layout">
                        <div className="euk-card__title">
                            {venueName}
                            {separator} {city}
                        </div>
                        <div className="entertainer-card-layout__date-info gig-text-size">
                            <time dateTime="" className="d-inline">
                                {/* TODO populate the datetime with machine-readable format */}
                                <span className="font-weight-bold">{dateInfo}&nbsp;&nbsp;</span>
                                <span>{finalTimePerfInfo}</span>
                            </time>
                        </div>
                        <div className="entertainer-card-layout__payment-info" hidden={this.state.showDescText}>
                            <div>{serviceSubType}</div>
                            <span>{this.sharedUtils.thousandsSeparator(payAmount)}</span>
                            <span>{finalPayDisplayText}</span>
                            <div className="entertainer-card-layout float-right" hidden={!this.state.showDetailLink}>
                                <Link
                                    to={{
                                        pathname: "/upcomingGig",
                                        state: {
                                            id: gigItemId,
                                            serialNo: this.props.serialNumber,
                                            totalNo: this.props.totalNumber,
                                        },
                                    }}
                                    className="btn btn-link btn-link--inline"
                                >
                                    View Detail
                                </Link>
                            </div>
                        </div>

                        <div className="entertainer-card-layout__query-info" hidden={!this.state.showDescText}>
                            <span>
                                {querydescription.length > maxWidthQueryDesc
                                    ? querydescription.substring(0, maxWidthQueryDesc) + "..."
                                    : querydescription}
                            </span>
                        </div>
                        <div
                            className={"entertainer-card-layout__query-status " + statusMessageClass}
                            hidden={!this.state.showDescText}
                        >
                            <span>{querystatus}</span>
                        </div>
                        <div className="entertainer-card-layout__controls">
                            <div hidden={!this.state.showButton}>
                                <div hidden={!showConfirmButton}>
                                    <button
                                        className="btn btn-primary btn--with-icon entertainer-card-layout__button"
                                        type="button"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onConfirmClick(outstandingItemId);
                                        }}
                                    >
                                        <span className="btn__icon">
                                            <FontAwesomeIcon icon={faCheckCircle} />
                                        </span>
                                        <span>Confirm</span>
                                    </button>
                                </div>
                                <div hidden={showConfirmButton}>
                                    <button
                                        className="btn btn-primary btn--with-icon entertainer-card-layout__button"
                                        type="button"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onFeedbackClick(outstandingItemId);
                                        }}
                                    >
                                        <span className="btn__icon">
                                            <FontAwesomeIcon icon={faComment} />
                                        </span>
                                        <span>Feedback</span>
                                    </button>
                                </div>
                            </div>

                            <div className="float-right" hidden={!this.state.showViewButton}>
                                <Link
                                    to={{
                                        pathname: "#",
                                        state: {},
                                    }}
                                    className="btn btn-link btn-link--inline"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onViewClick(queryId);
                                    }}
                                >
                                    View Detail
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </CardWrapper>
        );
    }
}

export default GigsCard;
