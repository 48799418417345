import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import UploadFileListGrid from "../grid/UploadFileListGrid";
import { Button } from "devextreme-react";
import BillableItemHeaderService, {
    TypeOfFileUploaded,
    fileListGridType,
} from "../../services/BillableItemHeaderService";

// Props
interface RecentFileSubmissionComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    landingPage: string;
    typeId?: string; // TypeId props specifies which value the type dropdown should hold.
    landingPageTab: string;
}

// State - refreshSignal used to determine when a grid refresh is required
//State property - gridVisibilitySignal used to determine when to display the Upload file list grid.
interface RecentFileSubmissionComponentState {
    refreshSignal: boolean;
    gridVisibilitySignal: boolean;
    hideHeader: boolean;
    serviceType: string;
    subServiceType: string;
    landingPage: string;
    selectedPendingButton: boolean;
    selectedSubmittedButton: boolean;
    parentGridType: string;
}

// Component - displays the file upload component and the two grids that show file data
class RecentFileSubmissionComponent extends Component<RecentFileSubmissionComponentProps> {
    state: RecentFileSubmissionComponentState;
    service: BillableItemHeaderService;
    constructor(props: RecentFileSubmissionComponentProps) {
        super(props);
        this.service = new BillableItemHeaderService();
        this.onFileUploaded = this.onFileUploaded.bind(this);
        this.onDropdownSelection = this.onDropdownSelection.bind(this);
        this.state = {
            refreshSignal: false,
            gridVisibilitySignal: false,
            hideHeader: true,
            serviceType: "",
            subServiceType: "",
            landingPage: "",
            selectedPendingButton: true,
            selectedSubmittedButton: false,
            parentGridType: fileListGridType.Pending,
        };
    }

    // DevExFileUploader child component will signal via this callback when a file has been uploaded
    onFileUploaded() {
        // This refresh signal will toggle between true and false to indicate a refresh is required.
        this.setState({
            refreshSignal: !this.state.refreshSignal,
            parentGridType: fileListGridType.Pending,
            selectedPendingButton: true,
            selectedSubmittedButton: false,
        });
    }

    //The gridVisibilitySignal will be set to true when the values in the dropdowns are populated.
    onDropdownSelection(serviceId: string, serviceSubTypeId: string) {
        if (serviceId && serviceSubTypeId) {
            this.setState({
                gridVisibilitySignal: true,
                hideHeader: false,
                serviceType: serviceId,
                subServiceType: serviceSubTypeId,
            });
        }
    }

    //A helper function that would toggle the state of the button to refresh the contents of the Parent Grid.
    toggleButtonStates = (toggleButtonState: boolean) => {
        this.setState({
            selectedPendingButton: toggleButtonState,
            selectedSubmittedButton: !toggleButtonState,
            parentGridType: toggleButtonState ? fileListGridType.Pending : fileListGridType.Submitted,
        });
    };

    setFilter = () => {
        return "";
    };
    onShowStatusOfFile = () => {
        return "";
    };

    render() {
        var { landingPage } = this.props;
        return (
            <div>
                <hr className="horizontal_border_style"></hr>
                <div>
                    {landingPage == TypeOfFileUploaded.Shifts ? (
                        <div className="view-queries-header__controls">
                            <Button className="btn btn-primary" onClick={this.onFileUploaded}>
                                <span className="dx-button-text">Refresh</span>
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div>
                    <br></br>
                    <br></br>
                    <UploadFileListGrid
                        refreshSignal={this.state.refreshSignal}
                        gridVisibilitySignal={true}
                        service={this.state.serviceType}
                        subService={this.state.subServiceType}
                        landingPage={this.props.landingPage}
                        gridType={this.state.parentGridType}
                        onShowStatusOfFile={this.onShowStatusOfFile}
                        pointOfNavigation = "Recent File Submission"
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(RecentFileSubmissionComponent);
