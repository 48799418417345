import sharedUtils from "../../grid/sharedUtilities";
import { PendingRequestItemResponse, RaiseQueryObject } from "../../../services/PendingRequestService";

type NullableDate = Date | null | undefined;

interface ConfigurationLookupValues {
    id: string[];
    value: string[];
}

export const months = {
    Jan: "jan",
    Feb: "feb",
    Mar: "mar",
    Apr: "apr",
    May: "may",
    Jun: "jun",
    Jul: "jul",
    Aug: "aug",
    Sep: "sep",
    Oct: "oct",
    Nov: "nov",
    Dec: "dec",
};

interface MonthDictionary {
    [key: string]: string;
}

export const monthNameToNumberMatrix: MonthDictionary = {
    [months.Jan]: "01",
    [months.Feb]: "02",
    [months.Mar]: "03",
    [months.Apr]: "04",
    [months.May]: "05",
    [months.Jun]: "06",
    [months.Jul]: "07",
    [months.Aug]: "08",
    [months.Sep]: "09",
    [months.Oct]: "10",
    [months.Nov]: "11",
    [months.Dec]: "12",
};

export interface feedbackRequestData {
    billableItemId: string;
    feedbackScore: string;
    feedbackComments: string;
    businessEntityId: string;
    feedbackType: string;
}

export default class pendingRequestUtilities {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
    }

    //Initialize the Raise query form data to post the data back to the server.
    initializeQueryFormData = (cardData: PendingRequestItemResponse, queryTypeLookupId: string): RaiseQueryObject => {
        var formData: RaiseQueryObject = {
            id: "",
            queryTitle: "",
            queryDescription: "",
            queryTypeLookupID: queryTypeLookupId ? queryTypeLookupId : "",
            contactId: "",
            contactName: "",
            companyId: "",
            company: "",
            d365LinkID: "",
            resolutionReason: "",
            billableItemId: "",
            queryStatusId: "",
            queryStatus: "",
            dateCreated: "",
            clientId: cardData.clientBusinessEntityId ? cardData.clientBusinessEntityId : "",
            client: cardData.clientShortName ? cardData.clientShortName : "",
            venueId: cardData.venueId ? cardData.venueId : "",
            venue: "",
            comments: "",
            lastUpdate: "",
            assignedTo: "",
            serviceTypeId: cardData.serviceTypeId ? cardData.serviceTypeId : "",
            serviceTypeValue: "",
            serviceSubTypeId: "",
            serviceSubTypeValue: cardData.serviceSubType ? cardData.serviceSubType : "",
            entertainmentDateInfo: "",
            venueName: cardData.venueName ? cardData.venueName : "",
            city: "",
            requestDateFrom: cardData.dateFrom ? cardData.dateFrom : "",
            requestId: cardData.id ? cardData.id : "",
            requestTypeId: cardData.requestTypeId ? cardData.requestTypeId : "",
            requestAdditionalInfo: cardData.additionalInformation ? cardData.additionalInformation : "",
            queryTypeText: "external",
        };
        return formData;
    };


    //Function that will reduce the original card response array and return a unique set of Dates for the side panel.
    uniqueByDate = (arr: PendingRequestItemResponse[], prop: string): string[] => {
        return arr.reduce((a: any, d: any) => {
            if (!a.includes(d[prop])) {
                a.push(d[prop]);
            }
            return a;
        }, []);
    };

    //Helper function that takes in the list of all the cards and returns the ones filtered by a single date.
    filterRequestedCardsBasedOnDate = (
        cardList: PendingRequestItemResponse[],
        date: string
    ): PendingRequestItemResponse[] => {
        var resultArray: PendingRequestItemResponse[] = [];
        resultArray =
            cardList && cardList.length > 0
                ? cardList.filter((item: PendingRequestItemResponse) => {
                      return item.dateFrom.trim().search(date.trim()) !== -1;
                  })
                : [];
        return resultArray;
    };

    //Helper function that takes in the list of all the Pending request cards and returns the list based on date selection.
    filterRequestCards = (cardList: PendingRequestItemResponse[], dateList: string[]): PendingRequestItemResponse[] => {
        var resultArray: PendingRequestItemResponse[] = [];
        resultArray =
            dateList && dateList.length > 0
                ? cardList.filter((item: PendingRequestItemResponse) => {
                      return dateList.indexOf(item.dateFrom) !== -1;
                  })
                : [];
        return resultArray;
    };
    //Helper function that would take the CardList array and return the number of count of the cards for for which the datFrom matches.
    //If it returns 1, splice the datePickLit.
    countCardsForSelectedDate = (filteredRequestedCards: PendingRequestItemResponse[], date: string): number => {
        var count: number = 0;
        for (var item = 0; item < filteredRequestedCards.length; item++) {
            if (filteredRequestedCards[item].dateFrom.trim().localeCompare(date.trim()) == 0) {
                count += 1;
            }
        }
        return count;
    };

    // Convert a string formated 17th July,2020 into dd/mmmm/yyyy format.
    convertStringAsDateIntoddmmyyyyFormat = (rowValue: string): string => {
        var convertedDate: string = "";

        if (rowValue && rowValue.length > 0) {
            var dateParts: string[] = rowValue.split(" ");
            convertedDate = `${dateParts[1]}/${monthNameToNumberMatrix[dateParts[2].toLowerCase()]}/${dateParts[3]}`;
        }

        return convertedDate;
    };
}
