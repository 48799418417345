import React from "react";

interface CardConfirmCostSectionProps {
    isItemType: boolean;
    shift?: string;
    clientBillableAmount: string;
    confirmationText: string;
    acceptDate: string;
    currencySymbol: string;
    originalRate: number;
    displayRate: string;
    billableRate: number;
    role: string;
}

class CardConfirmCostSection extends React.Component<CardConfirmCostSectionProps> {
    constructor(props: CardConfirmCostSectionProps) {
        super(props);
    }

    render() {
        return (
            <>
                {this.props.isItemType ? (
                    <div className="text-center">
                        <div className="font-weight-bold">{this.props.clientBillableAmount}</div>
                        <div>
                            {
                                this.props.role && this.props.shift ? (
                                    <span>
                                        {this.props.role}, {this.props.shift}
                                    </span>
                                ) : (
                                    <span>
                                        {this.props.role}
                                        {this.props.shift}
                                    </span>
                                )
                                // if one is missing, no comma
                            }
                        </div>
                        <div className="mt-3">{`${this.props.confirmationText}${this.props.acceptDate}`}</div>
                    </div>
                ) : (
                    <>
                        <div className="px-3">
                            <div className="row mb-2">
                                <div className="col-9 text-right">Previously billed amount</div>
                                <div className="col-3">
                                    {this.props.currencySymbol}
                                    {this.props.originalRate}
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-9 text-right euk-card__adjustment-text">Amount adjustment</div>
                                <div className="col-3 euk-card__adjustment-text">{this.props.displayRate}</div>
                            </div>

                            <div className="row dashed-divider--top pt-2">
                                <div className="col-9 text-right">Total amount to approve</div>
                                <div className="col-3">
                                    {this.props.currencySymbol}
                                    {this.props.billableRate}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
}
export default CardConfirmCostSection;
