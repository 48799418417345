import React from "react";
import SimpleGridService from "../../services/SimpleGridService";
import { LoadIndicator } from "devextreme-react";
import { WithTranslation } from "react-i18next";

interface AllUserGridStatusForGigListProps extends WithTranslation {
    data: any;
}

interface AllUserGridStatusForGigListState {
    Status: string;
    StatusColor: string;
    StatusBackGroundColor: string;
    billableItemId: number;
    loadIndicatorVisible: boolean;
    serviceValue: string;
}

class AllUserGridStatusForGigList extends React.Component<AllUserGridStatusForGigListProps> {
    state: AllUserGridStatusForGigListState;
    Service: SimpleGridService;
    constructor(props: AllUserGridStatusForGigListProps) {
        super(props);
        this.setLoadIndicatorVisibility = this.setLoadIndicatorVisibility.bind(this);
        this.Service = new SimpleGridService();
        this.state = {
            loadIndicatorVisible: false,
            Status: this.props.data.data.status,
            StatusColor: this.props.data.data.status_Color,
            StatusBackGroundColor: this.props.data.data.status_BackGroundColor,
            billableItemId: this.props.data.data.billableItemId,
            serviceValue: this.props.data.data.service,
        };
    }

    setLoadIndicatorVisibility(isVisible: boolean): void {
        this.setState({
            loadIndicatorVisible: isVisible,
        });
    }

    render() {
        let statusBackGroundColour = "";
        let tenseIdentifier = this.props.data.data.gigTenseFlag;
        if (this.state.StatusBackGroundColor != null && tenseIdentifier.toUpperCase() == "GIGCURRENT") {
            if (this.state.StatusBackGroundColor.toUpperCase() == "BLUE") {
                statusBackGroundColour = "bg-info";
            } else if (this.state.StatusBackGroundColor.toUpperCase() == "GREEN") {
                statusBackGroundColour = "bg-success";
            } else if (this.state.StatusBackGroundColor.toUpperCase() == "RED") {
                statusBackGroundColour = "bg-danger";
            } else if (
                this.state.StatusBackGroundColor.toUpperCase() == "AMBER" &&
                this.state.Status.toUpperCase() == "PENDING"
            ) {
                statusBackGroundColour = "bg-warning";
            }
        }

        let statusColour = "";
        if (this.state.StatusColor != null) {
            if (this.state.StatusColor.toUpperCase() == "BLUE") {
                statusColour = "bg-info";
            } else if (this.state.StatusColor.toUpperCase() == "GREEN") {
                statusColour = "bg-success";
            } else if (this.state.StatusColor.toUpperCase() == "RED") {
                statusColour = "bg-danger";
            }
        }

        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <div
                            className={
                                statusBackGroundColour != ""
                                    ? `mr-2 status__circle ${statusBackGroundColour}`
                                    : `mr-2 status__circle ${statusColour}`
                            }
                        ></div>
                        <span className="status-message-color">{this.state.Status}</span>
                        <LoadIndicator
                            className="ml-3"
                            id="button-indicator"
                            height={20}
                            width={20}
                            visible={this.state.loadIndicatorVisible}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default AllUserGridStatusForGigList;
