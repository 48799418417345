import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Schedule from "../schedule/Schedule";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import DatePanel from "../side-bar/DatePanel";
import VenueCard from "../Venue/VenueCard";
import ScheduleService from "../../services/ScheduleService";
import dashBoardUtils, { DashBoardData } from "../Today/DashBoardUtils";
import { AxiosResponse } from "axios";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import NewRegistrationCard from "./NewRegistrationCard";
import BookingsOverview from "./BookingsOverview";
import BookingsByWeek from "./BookingsByWeek";
import DashBoardSidePanel from "./DashBoardSidePanel";
import { ServiceType } from "../../common/ServiceUtilities";
import DashBoardService, { DashboardResponseCount } from "../../services/DashBoardService";
import { serviceTypes } from "../../services/FileService";
import DashBoardCountUtils from "./DashBoardCountUtils";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import sharedUtils from "../grid/sharedUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import { LoadPanel } from "devextreme-react";

interface HomePageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
    getBuild: (flag: boolean) => void;
}
// State
interface HomePageComponentState {
    clientId: string;
    venueId: string;
    providerId: string;
    isClientOrVenueFound: boolean;
    dashBoardData: DashBoardData;
    isDashBoardDataLoaded: boolean;
    dataNotFoundMsg: string;
    startDate: string;
    endDate: string;
    serviceTypeId: string;
    isPaymentGridRefresh: boolean;
    paymentId: string;
    dashBoardCount: DashboardResponseCount;
    isGridDataLoaded: boolean;
    isActionsDataLoaded: boolean;
    datasource: [];
    dateFrom: NullableDate;
    dateTo: NullableDate;
    loadPanelVisible: boolean;
}

class HomePage extends Component<HomePageComponentProps> {
    state: HomePageComponentState;
    dashBoardService: ScheduleService;
    dashBoardCountService: DashBoardService;
    utils: dashBoardUtils;
    sharedUtils: sharedUtils;
    dashBoardCountUtils: DashBoardCountUtils;
    constructor(props: HomePageComponentProps) {
        super(props);
        this.dashBoardService = new ScheduleService();
        this.dashBoardCountService = new DashBoardService();
        this.utils = new dashBoardUtils();
        this.sharedUtils = new sharedUtils();
        this.dashBoardCountUtils = new DashBoardCountUtils();
        // Initialize state
        let convertedDashBoardDataItem = this.utils.initializeDashBoardItems();
        let initializeDashBoardCount = this.dashBoardCountUtils.initializeDashBoardCount();
        this.state = {
            clientId: "",
            venueId: "",
            providerId: "",
            isClientOrVenueFound: false,
            dashBoardData: convertedDashBoardDataItem,
            isDashBoardDataLoaded: false,
            dataNotFoundMsg: "",
            startDate:
                this.props.location.state && this.props.location.state.startDate
                    ? this.props.location.state.startDate
                    : "",
            endDate:
                this.props.location.state && this.props.location.state.endDate ? this.props.location.state.endDate : "",
            serviceTypeId:
                this.props.location.state && this.props.location.state.serviceTypeId
                    ? this.props.location.state.serviceTypeId
                    : serviceTypes.Entertainment,
            isPaymentGridRefresh: false,
            paymentId: "",
            dashBoardCount: initializeDashBoardCount,
            isGridDataLoaded: false,
            isActionsDataLoaded: false,
            datasource: [],
            dateFrom: null,
            dateTo: null,
            loadPanelVisible: false,
        };
    }

    componentDidMount(): void {
        this.onPageLoad();
    }

    componentDidUpdate = (prevProps: HomePageComponentProps, prevState: HomePageComponentState) => {
        if(this.state.serviceTypeId != prevState.serviceTypeId){
            this.setState({
                loadPanelVisible: true
            });
            this.getDashBoardCount();
        }        
    }

    setClientOrVenueId = (venueIdValue: string, clientIdValue: string, isClientOrVenueValue: boolean) => {
        this.setState(
            {
                clientId: clientIdValue,
                venueId: venueIdValue,
                isClientOrVenueFound: isClientOrVenueValue,
                isDashBoardDataLoaded: false,
            },
            () => {
                this.getDashBoardData();
                this.getDashBoardCount();
            }
        );
    };

    getDashBoardCount = () => {
        this.dashBoardCountService.getDashboardCount(this.state.serviceTypeId, this.sharedUtils.convertDateToString(this.state.dateFrom), this.sharedUtils.convertDateToString(this.state.dateTo))
            .then(this.handleSuccessDataSource)
            .catch(this.handleStandardError)
    }

    handleSuccessDataSource = (response: AxiosResponse<any>) => {
        // show notification for new build            
        let version = localStorage.getItem("BuildVersion");
        let headerVersion = response.headers["x-application-version"];
        // if(version == "" || version == null){
            //     version = getHeaderVersion;
            //     localStorage.setItem("BuildVersion", getHeaderVersion);
            // }        
            if(version !== headerVersion){
                localStorage.setItem("BuildVersion", headerVersion);
                this.props.getBuild(true);              
            }
        // end
        if (response && response.data.data) {            
            this.setState({
                dashBoardCount: response.data.data,
                loadPanelVisible: false,
                isActionsDataLoaded: true,
            }, () => { this.setUpGridDataSource(this.state.dashBoardCount) });            
        }
    }

    setUpGridDataSource = (gridData: DashboardResponseCount) => {
        this.setState({
            datasource: [
                {
                    "date": gridData.requestByDate[0].date,
                    "newRequest": gridData.requestByDate[0].newRequest,
                    "advertised": gridData.requestByDate[0].advertised,
                    "optionSent": gridData.requestByDate[0].optionsSent,
                    "shortlisted": gridData.requestByDate[0].shortlisted,
                    "offered": gridData.requestByDate[0].offered,
                    "confirming": gridData.requestByDate[0].confirming,
                    "booked": gridData.requestByDate[0].booked,
                    "reConfirm": gridData.requestByDate[0].reConfirm,                    
                    "day": "Monday"
                },
                {
                    "date": gridData.requestByDate[1].date,
                    "newRequest": gridData.requestByDate[1].newRequest,
                    "advertised": gridData.requestByDate[1].advertised,
                    "optionSent": gridData.requestByDate[1].optionsSent,
                    "shortlisted": gridData.requestByDate[1].shortlisted,
                    "offered": gridData.requestByDate[1].offered,
                    "confirming": gridData.requestByDate[1].confirming,
                    "booked": gridData.requestByDate[1].booked,
                    "reConfirm": gridData.requestByDate[1].reConfirm,                    
                    "day": "Tuesday"
                },
                {
                    "date": gridData.requestByDate[2].date,
                    "newRequest": gridData.requestByDate[2].newRequest,
                    "advertised": gridData.requestByDate[2].advertised,
                    "optionSent": gridData.requestByDate[2].optionsSent,
                    "shortlisted": gridData.requestByDate[2].shortlisted,
                    "offered": gridData.requestByDate[2].offered,
                    "confirming": gridData.requestByDate[2].confirming,
                    "booked": gridData.requestByDate[2].booked,
                    "reConfirm": gridData.requestByDate[2].reConfirm,                    
                    "day": "Wednesday"
                },
                {
                    "date": gridData.requestByDate[3].date,
                    "newRequest": gridData.requestByDate[3].newRequest,
                    "advertised": gridData.requestByDate[3].advertised,
                    "optionSent": gridData.requestByDate[3].optionsSent,
                    "shortlisted": gridData.requestByDate[3].shortlisted,
                    "offered": gridData.requestByDate[3].offered,
                    "confirming": gridData.requestByDate[3].confirming,
                    "booked": gridData.requestByDate[3].booked,
                    "reConfirm": gridData.requestByDate[3].reConfirm,                    
                    "day": "Thursday"
                },
                {
                    "date": gridData.requestByDate[4].date,
                    "newRequest": gridData.requestByDate[4].newRequest,
                    "advertised": gridData.requestByDate[4].advertised,
                    "optionSent": gridData.requestByDate[4].optionsSent,
                    "shortlisted": gridData.requestByDate[4].shortlisted,
                    "offered": gridData.requestByDate[4].offered,
                    "confirming": gridData.requestByDate[4].confirming,
                    "booked": gridData.requestByDate[4].booked,
                    "reConfirm": gridData.requestByDate[4].reConfirm,                    
                    "day": "Friday"
                },
                {
                    "date": gridData.requestByDate[5].date,
                    "newRequest": gridData.requestByDate[5].newRequest,
                    "advertised": gridData.requestByDate[5].advertised,
                    "optionSent": gridData.requestByDate[5].optionsSent,
                    "shortlisted": gridData.requestByDate[5].shortlisted,
                    "offered": gridData.requestByDate[5].offered,
                    "confirming": gridData.requestByDate[5].confirming,
                    "booked": gridData.requestByDate[5].booked,
                    "reConfirm": gridData.requestByDate[5].reConfirm,                    
                    "day": "Saturday"
                },
                {
                    "date": gridData.requestByDate[6].date, 
                    "newRequest": gridData.requestByDate[6].newRequest,
                    "advertised": gridData.requestByDate[6].advertised,
                    "optionSent": gridData.requestByDate[6].optionsSent,
                    "shortlisted": gridData.requestByDate[6].shortlisted,
                    "offered": gridData.requestByDate[6].offered,
                    "confirming": gridData.requestByDate[6].confirming,
                    "booked": gridData.requestByDate[6].booked,
                    "reConfirm": gridData.requestByDate[6].reConfirm,                    
                    "day": "Sunday"
                }
            ],
            isGridDataLoaded: true
        }, () => { this.setUpBookingsByWeekArrDataSource(this.state.datasource) })
    }

    setUpBookingsByWeekArrDataSource = (dataSource: any[]) => {
        let dataSourceForBookingByWeek = [];
        dataSourceForBookingByWeek.push({
            values: dataSource
        });
        this.setState({
            datasource: dataSourceForBookingByWeek && dataSourceForBookingByWeek.length > 0 ? dataSourceForBookingByWeek : []
        });
    }

    handleStandardError = (error: any) => {
        var respMessage: string = "New Registration Card Data load failed with response: " + JSON.stringify(error);
        this.setState({
            loadPanelVisible: false
        });
        if (!this.dashBoardCountService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    getDashBoardData = () => {
        if (this.state.clientId && this.state.venueId) {
            this.dashBoardService
                .getFinancialDashBoardData(this.state.clientId, this.state.venueId)
                .then(this.handleSuccess)
                .catch((err) => {
                    let respMessage: string = "getDashBoardData failed with response: " + JSON.stringify(err);

                    if (!this.dashBoardService.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                    this.setState({
                        isDashBoardDataLoaded: false,
                    });
                });
        } else {
            this.setState({
                dataNotFoundMsg: "No data found",
                isDashBoardDataLoaded: true,
            });
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            dashBoardData: response.data.data,
            isDashBoardDataLoaded: true,
            dataNotFoundMsg: "",
        });
    };

    onApplyButtonClick = (serviceTypeId: string) => {
        this.setState({
            serviceTypeId: serviceTypeId
        });
    }

    onPageLoad = () => {
        let dateFromValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        let dateToValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        this.setState(
            {
                dateFrom: dateFromValue,
                dateTo: dateToValue
            }, () => { this.getDashBoardCount() }
        );
    }

    onClickNextWeek = () => {
        let nextWeekStartDay: NullableDate = null;
        let nextWeekEndDay: NullableDate = null;
        if (this.state.dateFrom) {
            nextWeekStartDay = new Date(this.state.dateFrom);
        }
        if (this.state.dateTo) {
            nextWeekEndDay = new Date(this.state.dateTo);
        }
        if (nextWeekStartDay) {
            nextWeekStartDay.setDate(nextWeekStartDay.getDate() + 7);
        }
        if (nextWeekEndDay) {
            nextWeekEndDay.setDate(nextWeekEndDay.getDate() + 7)
        }
        this.setState({
            dateFrom: nextWeekStartDay,
            dateTo: nextWeekEndDay,
            loadPanelVisible: true
        }, () => { this.getDashBoardCount() });
    }

    onClickPreviousWeek = () => {
        let nextWeekStartDay: NullableDate = null;
        let nextWeekEndDay: NullableDate = null;
        if (this.state.dateFrom) {
            nextWeekStartDay = new Date(this.state.dateFrom);
        }
        if (this.state.dateTo) {
            nextWeekEndDay = new Date(this.state.dateTo);
        }
        if (nextWeekStartDay) {
            nextWeekStartDay.setDate(nextWeekStartDay.getDate() - 7);
        }
        if (nextWeekEndDay) {
            nextWeekEndDay.setDate(nextWeekEndDay.getDate() - 7)
        }
        this.setState({
            dateFrom: nextWeekStartDay,
            dateTo: nextWeekEndDay,
            loadPanelVisible: true
        }, () => { this.getDashBoardCount() });
    }

    render() {
        let heading: string = "";
        if (this.state.dateFrom && this.state.dateTo) {
            let convertedStartDate: string = moment(this.state.dateFrom).format("Do MMM YYYY");
            let convertedEndDate: string = moment(this.state.dateTo).format("Do MMM YYYY");
            heading = `${convertedStartDate} - ${convertedEndDate}`;
        }
        return (
            <>
                <LightModePageTemplate>
                    <SideBarTemplate isFixed={true}>
                        <DatePanel />
                        <DashBoardSidePanel onApplyButtonClick={this.onApplyButtonClick} serviceTypeId={ServiceType.Entertainment} />
                        <BulletinPanel />
                    </SideBarTemplate>
                    <div className="page-content--with-sidebar-hidden-mobile">
                        <div className="col mt-4">
                            {/* should be main above but main is used elsewhere */}
                            <section className="mb-2 mb-lg-4">
                                <h3 className="font-size-xxxxl">Outstanding Actions</h3>
                                <NewRegistrationCard serviceTypeId={this.state.serviceTypeId} dashBoardCount={this.state.dashBoardCount} isDashBoardDataLoaded={this.state.isActionsDataLoaded}/>
                            </section>
                            <section className="mb-2 mb-lg-4">
                                <h3 className="font-size-xxxxl">Bookings Overview</h3>
                                <BookingsOverview serviceTypeId={this.state.serviceTypeId} dashBoardCount={this.state.dashBoardCount} isDashBoardDataLoaded={this.state.isActionsDataLoaded}/>
                            </section>
                            {this.state.isGridDataLoaded ?
                                <section className="card mb-4">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap justify-content-between align-items-baseline mb-4">
                                            <div>
                                                <h3 className="font-size-xxxxl">Bookings By Week</h3>
                                            </div>
                                            <div>
                                                <div className="d-flex align-items-center">
                                                    <button
                                                        className="btn btn--arrow-in-square"
                                                        onClick={this.onClickPreviousWeek}
                                                    >
                                                        <FontAwesomeIcon icon={faAngleLeft} />
                                                        <span className="sr-only">Go to previous week</span>
                                                    </button>
                                                        <h4 className="mb-0 mx-3">{heading}</h4>
                                                        <button
                                                            className="btn btn--arrow-in-square"
                                                            onClick={this.onClickNextWeek}
                                                        >
                                                            <FontAwesomeIcon icon={faAngleRight} />
                                                            <span className="sr-only">Go to next week</span>
                                                        </button>
                                                </div>
                                            </div>
                                        </div>
                                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                                        <BookingsByWeek dataSource={this.state.datasource} serviceTypeId={this.state.serviceTypeId} isDashBoardDataLoaded={this.state.isGridDataLoaded} dateFrom={this.sharedUtils.convertDateToString(this.state.dateFrom)} dateTo={this.sharedUtils.convertDateToString(this.state.dateTo)}></BookingsByWeek>
                                    </div>
                                </section> : null
                            }
                            <section className="row mb-xl-5">
                                <div className="col-12 col-xl-12 mb-4 mb-xl-0">
                                    <div className="card h-100 home-page-card--venue-information">
                                        <div className="card-body">
                                            <h3 className="font-size-xxxxl">Venue Information</h3>
                                            <div className="row mt-3">
                                                <div className="col-12 col-lg-6 pr-lg-5 mb-lg-0 mb-4">
                                                    <Schedule venueAll={true} setParentFilterValuesCallback={this.setClientOrVenueId} navigateFrom="eventukhomepage" />
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <VenueCard
                                                        dashBoardData={this.state.dashBoardData}
                                                        isDashBoardDataLoaded={this.state.isDashBoardDataLoaded}
                                                        clientId={this.state.clientId}
                                                        venueId={this.state.venueId}
                                                        dataNotFoundMsg={this.state.dataNotFoundMsg}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </LightModePageTemplate>

            </>
        );
    }
}

export default withRouter(HomePage);
