import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import ArtistProfile from "./ArtistProfileComponent";

interface ArtistProfilePageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
    businessEntityId: string;
}

class ArtistProfilePage extends Component<ArtistProfilePageComponentProps> {
    render() {
        return (
            <LightModePageTemplate>
                <section className="page-content">
                    <ArtistProfile
                        history={this.props.history}
                        location={this.props.location}
                        businessEntityId={this.props.businessEntityId}
                        handlePopUpClose = {()=>{return null}}
                        handleFeedbackUpdate = {()=>{return null}}
                        handleAddArtist = {()=>{return null}}
                    />
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ArtistProfilePage);
