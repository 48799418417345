import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CustomStore from "devextreme/data/custom_store";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import { LoadPanel } from "devextreme-react";
import SimpleGridService, { GridTypes } from "../../services/SimpleGridService";
import ShiftMatrixGridContents from "./ShiftMatrixGridContents";
import { BillingFormData } from "../Billing/BillingFormUtils";



interface ShiftMatrixUnbilledGridProps extends WithTranslation {
    id: string;
    previewGrid?: boolean;
    billingData?: BillingFormData;
    isPreviewClicked?: boolean;
}

interface ShiftMatrixUnbilledGridState {
    dataSource: [],
    loadIndicatorForGrid: boolean
}

class ShiftMatrixUnbilledGrid extends Component<ShiftMatrixUnbilledGridProps> {
    state: ShiftMatrixUnbilledGridState;
    service: SimpleGridService;
    constructor(props: ShiftMatrixUnbilledGridProps) {
        super(props);
        this.state = {
            dataSource: [],
            loadIndicatorForGrid: false
        }
        this.service = new SimpleGridService();
    }

    getResults = (loadOptions: any) => {
        let params = buildRequestParameters(loadOptions);
        if(this.props.previewGrid){
            params = params + `&clientId=${this.props.billingData?.clientId}&date=${this.props.billingData?.dateRangeTo}`;
        }
        let id = this.props.previewGrid ? "0" : this.props.id;
        return this.service.getUnbilledGridRowsWithinRemoteOperations(params, id)
            .then(this.handleSuccessLoadDataSource)
            .catch(() => { throw 'Data Loading Error'; })
            .finally(() => {
                this.setState({
                    loadIndicatorForGrid: false
                });
            }
            )
    }

    handleSuccessLoadDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });
        if (response.data.data && response.data.data.data) {
            return {
                data: response.data.data ? response.data.data : [],
                totalCount: response.data.data.totalCount
            };
        }
        else {
            return {
                data: response.data.data.billableItemRemoteGrid ? response.data.data.billableItemRemoteGrid : [],
                totalCount: response.data.data.totalCount
            };
        }
    }

    componentDidMount() {
        this.setState({
            loadIndicatorForGrid: true
        });
        this.loadDataSource();
    }

    componentDidUpdate(prevProps: ShiftMatrixUnbilledGridProps) {
        if (this.props.id != prevProps.id || this.props.isPreviewClicked !== prevProps.isPreviewClicked) {
            this.loadDataSource();
        }

    }

    loadDataSource = () => {
        let dataSource = new CustomStore({
            key: "id",
            load: this.getResults,
        });

        this.setState({
            dataSource: dataSource
        });
    }


    render() {
        return (
            <div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadIndicatorForGrid} />
                <ShiftMatrixGridContents dataSource={this.state.dataSource} navigateFrom={GridTypes.invoiceReport} />
            </div>
        );
    }
}

export default withTranslation()(ShiftMatrixUnbilledGrid);