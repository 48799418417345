type NullableDate = Date | null | undefined;

interface ConfigurationLookupValues {
    id: string[];
    value: string[];
}

interface minuteSelectBoxLookup {
    id: string;
    value: string;
}

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export interface contractHourLookup {
    day: string;
    count: string;
}

export const checkInStatus = {
    green: "Green",
    red: "Red",
};

export default class EntLandingUtilities {
    constructor() {
        this.convertddmmyyyyStringToCustomizedFormat = this.convertddmmyyyyStringToCustomizedFormat.bind(this);
    }
    isBlank = (filterId: string | null | undefined) => {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    };
    // Convert a string formated "10/07/2020" into a suitable date format i.e 17th July,2020.
    convertddmmyyyyStringToCustomizedFormat(rowValue: string): string {
        var convertedDate: string = "";
        var monthName: string = "";

        if (rowValue && rowValue.length > 0) {
            if (rowValue.includes("/")) {
                var dateParts = rowValue.split("/");
                var day = parseInt(dateParts[0]);
                var month = parseInt(dateParts[1]);
                var year = parseInt(dateParts[2]);
                var date = new Date(year, month - 1, day, 0, 0, 0, 0);
                var dayIndex = date.getDay();
                var dayName = days[dayIndex];
                monthName = months[month - 1].substring(0, 3); //Takes in the month number and converts it into the Month Name.
                var convertedDate = `${day} ${monthName} ${year}`;
            } else {
                var parts = rowValue.split(" ");
                var convertedDate =
                    parts[0].substring(0, 3) + " " + parts[1] + " " + parts[2].substring(0, 3) + " " + parts[3];

                //var timepart= parts[parts.length-1];

                //var day = parts && parts.length > 0 && parts[0];

                // var convertedDate = `${dayName} ${day} ${monthName} ${year}`;
            }
        }

        return convertedDate;
    }
    getDateInfo = (entDateTimeInfo: string): string => {
        var dateText = "";
        if (entDateTimeInfo.includes(",")) {
            var dateParts = entDateTimeInfo.split(",");
            dateText = dateParts && dateParts.length > 0 ? dateParts[0] : "";
        } else {
            dateText = entDateTimeInfo;
        }
        return dateText;
    };
    getStartTimeInfo = (entDateTimeInfo: string): string => {
        var timeText = "";
        var startTimeText = "";
        if (entDateTimeInfo.includes(",")) {
            var dateParts = entDateTimeInfo.split(",");
            timeText = dateParts && dateParts.length > 0 ? dateParts[1] : "";
            if (!this.isBlank(timeText) && timeText.includes("-")) {
                var timeParts = timeText.split("-");
                startTimeText = timeParts && timeParts.length > 0 ? timeParts[0] : "";
            } else {
                startTimeText = timeText;
            }
        }
        return startTimeText;
    };
    getEndTimeInfo = (entDateTimeInfo: string): string => {
        var timeText = "";
        var endTimeText = "";
        if (entDateTimeInfo.includes(",")) {
            var dateParts = entDateTimeInfo.split(",");
            timeText = dateParts && dateParts.length > 0 ? dateParts[1] : "";
            if (!this.isBlank(timeText) && timeText.includes("-")) {
                var timeParts = timeText.split("-");
                endTimeText = timeParts && timeParts.length > 0 ? timeParts[1] : "";
            }
        }
        return endTimeText;
    };
}
