import React, { MouseEvent, Component } from "react";
import CardWrapper from "./CardWrapper";

interface EntertainmentCardProps {
    type: string;
    typeTitle: string;
    enumeration: boolean;
    cardIndex?: number;
    cardsTotal?: number;
    cardType: string; // upcoming, pending, outstanding, query

    children: React.ReactNode;
}

interface EntertainmentCardState {
    button: boolean;
    buttonText: string;
    showMoney: boolean;
    showPaymentText: boolean;
    paymentText: React.ReactNode;
}

class EntertainmentCard extends React.Component<EntertainmentCardProps> {
    state: EntertainmentCardState;

    constructor(props: EntertainmentCardProps) {
        super(props);

        this.state = {
            button: true,
            buttonText: "",
            showMoney: true,
            showPaymentText: true,
            paymentText: "",
        };
    }

    render() {
        // set up state depending on card type
        if (this.props.cardType == "upcoming") {
            this.state.button = false;
            this.state.showPaymentText = false;
        } else if (this.props.cardType == "query") {
            this.state.buttonText = "View";
            this.state.showMoney = false;
            this.state.showPaymentText = false;
        } else if (this.props.cardType == "outstanding") {
            this.state.buttonText = "Feedback";
            this.state.paymentText = (
                <span>
                    {" due "}
                    <time dateTime="2020-08-22">22/08/2020</time>
                </span>
            );
        } else if (this.props.cardType == "pending") {
            this.state.buttonText = "Confirm";
            this.state.paymentText = <span>{" pending"}</span>;
        }

        return (
            <CardWrapper
                type="entertainer"
                typeTitle="entertainment"
                enumeration={true}
                cardIndex={this.props.cardIndex}
                cardsTotal={this.props.cardsTotal}
                fullHeight={true}
            >
                <div className="euk-card__content-section">
                    <div className="entertainer-card-layout">
                        <div className="euk-card__title">The Red Lion, London</div>
                        <div className="entertainer-card-layout__date-info">
                            <time dateTime="2020-08-13T21:00">Thu 13 Aug 2020, 21:00</time>
                            <time dateTime="PT1H">1hr</time>
                        </div>
                        <div className="entertainer-card-layout__payment-info">
                            <div>DJ</div>
                            {this.state.showMoney ? <span>£150</span> : null}
                            {this.state.showPaymentText ? <span>{this.state.paymentText}</span> : null}
                        </div>
                        {this.state.button ? (
                            <button className="btn btn-primary entertainer-card-layout__button">
                                {this.state.buttonText}
                            </button>
                        ) : null}
                    </div>
                </div>
            </CardWrapper>
        );
    }
}
export default EntertainmentCard;
