import { AxiosResponse } from "axios";
import { _get, _delete, _setURL, _post, _aiTraceAsError } from "./BaseApiService";

const CONTRACTCLEANING_API = "ContractHour";
const CONTRACT_CHECK_DATA_API = "ContractHour/Validate"

type NullableBool = boolean | null | undefined;
export interface ContractCleaningGridRowItem {
    contractCleaningId: string;
    clientId: string;
    clientName: string;
    serviceTypeId: string;
    serviceTypeName: string;
    serviceSubTypeId: string;
    serviceSubTypeName: string;
    providerId: string;
    providerName: string;
    venuId: string;
    venuName: string;
    isActive: string;
    variableHours: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    rate: string;
    total: string;
    frequencyId: string;
    frequencyName: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    typeLookUpId: string;
    otherDescription: string;
    providerPayAmountLocal: string;
}

export interface ContractFormResponse {
    contractCleaningId: string;
    clientId: string;
    clientName: string;
    serviceTypeId: string;
    serviceTypeName: string;
    serviceSubTypeId: string;
    serviceSubTypeName: string;
    providerId: string;
    providerName: string;
    venuId: string;
    venuName: string;
    isActive: string;
    variableHours: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    rate: string;
    total: string;
    frequencyId: string;
    feeOverride: string;
    feeOverrideFlag: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    typeLookUpId: string;
    otherDescription: string;
    providerPayAmountLocal: string;
}

export interface ContractFormRequest {
    contractCleaningId: string;
    clientId: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    providerId: string;
    venuId: string;
    isActive: string;
    variableHours: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    rate: string;
    total: string;
    frequencyId: string;
    feeOverride: string;
    feeOverrideFlag: string;
    typeLookUpId: string;
    otherDescription: string;
    providerPayAmountLocal: string;
}
export const services = {
    contractCleaning: "ContractCleaning",
};
class ContractCleaningService {
    //#region Contract Cleaning Service Call
    getContractCleaningData(id: any): Promise<AxiosResponse<any>> {
        return _get(CONTRACTCLEANING_API + `?serviceSubType=${id}`);
    }
    getContractCleaningDataItem(contractCleaningId: any): Promise<AxiosResponse<any>> {
        return _get(`${CONTRACTCLEANING_API}/${contractCleaningId}`);
    }
    saveContractCleaningDataItem(contractCleaningObject: object) {
        return _post(CONTRACTCLEANING_API, contractCleaningObject);
    }
    deleteContractCleaningDataItem(contractCleaningId: any) {
        return _delete(`${CONTRACTCLEANING_API}/${contractCleaningId}`);
    }
    //#endregion

    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }

    getContractsDataItemByVenue(venueId: any): Promise<AxiosResponse<any>> {
        return _get(CONTRACTCLEANING_API + `?venue=${venueId}`);
    } 

    // validate duplicate data before save
    validateContractDataItem(contractObject: object) {
        return _post(CONTRACT_CHECK_DATA_API, contractObject);
    }  
}
export default ContractCleaningService;
