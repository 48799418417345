import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    DateBox,
    SelectBox,
    TextBox,
    NumberBox,
    TextArea,
    Switch,
    Button,
    LoadIndicator,
    Validator,
    ValidationSummary,
    LoadPanel,
} from "devextreme-react";
import {
    DateBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import UserService, { RoleGroupNames } from "../../services/UserService";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import PaymentService, { paymentStatus, logicAppPostRequest } from "../../services/PaymentService";
import { AxiosResponse, AxiosError } from "axios";
import paymentFormUtils, { PaymentFormData, ActionType } from "./PaymentFormUtils";
import { Link } from "react-router-dom";
import { RequiredRule } from "devextreme-react/data-grid";

//#region props
interface PaymentCycleFormProps extends WithTranslation {
    location: any;
    history: any;
}
//#endregion
//#region State

// State
interface PaymentCycleFormState {
    editPaymentFormData: PaymentFormData;
    statusLookUp: LookupTypeItem[];
    nextStepStatusId: number;
    nextStepStatusValue: string;
    isStatusChanged: boolean;
    IsShowSuccess: boolean;
    restartIndicator: boolean;
    disableRestartButton: boolean;
    isErrorMessageCriteriaOne: boolean;
    loadPanelVisible: boolean;
    IsShowErrorFromAPI: boolean;
    errorMessage: [];
}
//#endregion

// Component
class PaymentCycleForm extends React.Component<PaymentCycleFormProps> {
    lookupService: LookupService;
    state: PaymentCycleFormState;
    util: paymentFormUtils;
    paymentService: PaymentService;
    userHasAccess: boolean;

    constructor(props: PaymentCycleFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.util = new paymentFormUtils();
        this.paymentService = new PaymentService();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        // Initialize state
        var convertedEditPaymentData = this.util.initializePaymentItems(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        this.state = {
            //Initialize the State here.
            editPaymentFormData: convertedEditPaymentData,
            statusLookUp: [],
            nextStepStatusId: 0,
            nextStepStatusValue: "",
            isStatusChanged: false,
            IsShowSuccess: false,
            restartIndicator: false,
            disableRestartButton: false,
            isErrorMessageCriteriaOne: true,
            loadPanelVisible: false,
            IsShowErrorFromAPI: false,
            errorMessage: [],
        };

        //#region 'binding events'
        this.nextStepValue = this.nextStepValue.bind(this);
        this.handleSuccessStatusLookUp = this.handleSuccessStatusLookUp.bind(this);
        this.handleStandardError = this.handleStandardError.bind(this);
        this.handleSetStateError = this.handleSetStateError.bind(this);
        this.handleChangePayDate = this.handleChangePayDate.bind(this);
        this.handleChangeStatusTypeSelect = this.handleChangeStatusTypeSelect.bind(this);
        this.handleChangePaySentDate = this.handleChangePaySentDate.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.onClickRestart = this.onClickRestart.bind(this);
        this.onClickSaveViewItems = this.onClickSaveViewItems.bind(this);
        this.onClickApplyNextStep = this.onClickApplyNextStep.bind(this);
        this.getEditViewDataSource = this.getEditViewDataSource.bind(this);
        this.handleSuccessViewDataSource = this.handleSuccessViewDataSource.bind(this);
        this.handlePaymentSaveSuccess = this.handlePaymentSaveSuccess.bind(this);
        this.hideLoadpanel = this.hideLoadpanel.bind(this);
        this.handleRestartSuccess = this.handleRestartSuccess.bind(this);
        this.statusSelection = this.statusSelection.bind(this);
        //#endregion
    }
    //#region 'handleChangeEvents'
    handleChangePayDate(dxValueChange: DateBoxOnValueChangedEvent) {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                payDate: dxValueChange.value,
            },
        });
    }

    handleChangeStatusTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            isStatusChanged: true,
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                statusId: dxValueChange.value,
            },
        });
        this.nextStepValue();
    }

    handleChangePaySentDate(dxValueChange: DateBoxOnValueChangedEvent) {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                paySentDate: dxValueChange.value,
            },
        });
    }

    handleChangeDescription(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                description: dxValueChange.value,
            },
        });
    }
    //#region 'On Billing Save Success functions'
    renderRedirect = (paymentCycleId: string) => {
        if (this.state.IsShowSuccess) {
            this.props.history.push({
                pathname: "/managePaymentCycleItem",
                state: { id: paymentCycleId },
            });
        }
    };

    handlePaymentSaveSuccess = (response: AxiosResponse<any>) => {
        var paymentCycleId = response.data;
        this.setState({
            IsShowSuccess: true,
        });
        this.renderRedirect(paymentCycleId);
    };

    handleApplyNextSuccess = (response: AxiosResponse<any>) => {
        const { paymentCycleId } = this.state.editPaymentFormData;
        let postObject: logicAppPostRequest = {
            PaymentCycleId: paymentCycleId,
        };
        if (this.state.nextStepStatusId.toString() == paymentStatus.PaymentPackRequested) {
            this.paymentService.triggerLogicAppForPayPackRequested(postObject);
        } else if (this.state.nextStepStatusId.toString() == paymentStatus.PaymentRemittanceRequested) {
            this.paymentService.triggerLogicAppForRemittanceRequest(postObject);
        } else if (this.state.nextStepStatusId.toString() == paymentStatus.PaymentAdviseRequested) {
            this.paymentService.triggerLogicAppForPaymentAdviceRequest(postObject);
        }
        this.setState({
            IsShowSuccess: true,
        });
        this.renderRedirect(paymentCycleId);
    };

    errorMessageDisplay = () => {
        if (
            this.state.editPaymentFormData.statusId == this.state.statusLookUp[0].id ||
            this.state.editPaymentFormData.statusId == this.state.statusLookUp[1].id ||
            this.state.editPaymentFormData.statusId == this.state.statusLookUp[3].id ||
            this.state.editPaymentFormData.statusId == this.state.statusLookUp[4].id
        ) {
            if (this.state.editPaymentFormData.payDate == undefined) {
                this.setState({
                    isErrorMessageCriteriaOne: false,
                });
            } else {
                this.setState({
                    isErrorMessageCriteriaOne: true,
                });
            }
        }
    };

    onClickRestart() {
        if (this.userHasAccess) {
            this.setState({
                restartIndicator: true,
            });
            var paymentCycleSubmitRequest = this.util.convertFormDataToPaymentCycleRequest(
                this.state.editPaymentFormData,
                ActionType.Restart,
                "",
                ""
            );
            //passing object data to the server

            this.paymentService
                .postPaymentCycleData(paymentCycleSubmitRequest)
                .then(this.handleRestartSuccess)
                .catch((err) => {
                    this.setState({
                        errorMessage:
                            err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null,
                        IsShowSuccess: false,
                    });
                    this.failureMessageVisible();
                    this.hideRestartIndicator();
                })
                .finally(() => {
                    this.hideRestartIndicator();
                });
        }
    }

    hideRestartIndicator = () => {
        this.setState({
            restartIndicator: false,
        });
    };

    handleRestartSuccess() {
        if (this.userHasAccess) {
            this.setState({
                restartIndicator: false,
                IsShowErrorFromAPI: false,
            });

            this.paymentService
                .getPaymentCycleViewData(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
        }
    }

    statusSelection(): string {
        var item: number ;
        var paymentStatusId = "";
        if (this.state.isStatusChanged == false) {
            if (this.state.editPaymentFormData.status) {
                for (item = 0 ; item < this.state.statusLookUp.length; item++) {
                    if (this.state.editPaymentFormData.status == this.state.statusLookUp[item].value) {
                        paymentStatusId = this.state.statusLookUp[item].id;
                        break;
                    }
                }
            } else {
                paymentStatusId = this.state.statusLookUp[0].id;
            }
        }
        return paymentStatusId;
    }

    onClickSaveViewItems() {
        if (this.userHasAccess) {
            this.setState({
                loadPanelVisible: true,
            });
            //Service call for saving the data
            var paymentStatusId = this.statusSelection();
            var paymentCycleSubmitRequest = this.util.convertFormDataToPaymentCycleRequest(
                this.state.editPaymentFormData,
                ActionType.Save,
                "",
                paymentStatusId
            );
            this.paymentService
                .postPaymentCycleData(paymentCycleSubmitRequest)
                .then(this.handlePaymentSaveSuccess)
                .catch(this.handleSetStateError)
                .finally(() => {
                    this.hideLoadpanel();
                });
        }
    }

    failureMessageVisible = () => {
        this.setState({
            IsShowErrorFromAPI: true,
        });
    };

    hideLoadpanel() {
        this.setState({ loadPanelVisible: false });
    }

    onClickApplyNextStep() {
        if (this.userHasAccess) {
            this.setState({
                loadPanelVisible: true,
            });
            //Service call for saving the data
            var paymentStatusId = this.statusSelection();
            var paymentCycleSubmitRequest = this.util.convertFormDataToPaymentCycleRequest(
                this.state.editPaymentFormData,
                ActionType.Save,
                this.state.nextStepStatusId.toString(),
                paymentStatusId
            );
            this.paymentService
                .postPaymentCycleData(paymentCycleSubmitRequest)
                .then(this.handleApplyNextSuccess)
                .catch(this.handleSetStateError)
                .finally(() => {
                    this.hideLoadpanel();
                });
        }
    }
    //#endregion

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.paymentStatus)
            .then(this.handleSuccessStatusLookUp)
            .catch(this.handleStandardError);
    }

    getEditViewDataSource() {
        if (this.props.location.state.id != 0 && this.userHasAccess) {
            this.paymentService
                .getPaymentCycleViewData(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
        }
    }

    handleSuccessViewDataSource(response: AxiosResponse<any>) {
        // Convert to proper data types
        var viewDataSource = this.util.convertPaymentCycleResponseToFormData(response.data.data);
        this.setState({
            editPaymentFormData: viewDataSource,
        });
        this.nextStepValue();
    }

    nextStepValue() {
        var item: number;
        var nextStepStatusId = "";
        var nextStepStatusValue = "";
        var statusFromDropDown = this.state.editPaymentFormData.statusId; // need to change after object creation
        if (this.state.statusLookUp.length > 0 && statusFromDropDown != "") {
            for (item = 0; item < this.state.statusLookUp.length; item++) {
                if (statusFromDropDown == this.state.statusLookUp[item].id) {
                    if (statusFromDropDown == this.state.statusLookUp[this.state.statusLookUp.length - 1].id) {
                        this.setState({
                            nextStepStatusValue: "",
                        });
                    } else {
                        if (statusFromDropDown == paymentStatus.PaymentPackReady) {
                            nextStepStatusId = this.state.statusLookUp[item + 2].id;
                            nextStepStatusValue = this.state.statusLookUp[item + 2].value;
                        } else {
                            nextStepStatusId = this.state.statusLookUp[item + 1].id;
                            nextStepStatusValue = this.state.statusLookUp[item + 1].value;
                        }
                        break;
                    }
                }
            }
        } else {
            nextStepStatusId = this.state.statusLookUp[1].id;
            nextStepStatusValue = this.state.statusLookUp[1].value;
        }

        this.setState({
            nextStepStatusId: nextStepStatusId,
            nextStepStatusValue: nextStepStatusValue,
        });
    }

    handleSuccessStatusLookUp(response: AxiosResponse<any>) {
        var statusLUItem: LookupTypeItem;
        var statusLUItemArray: LookupTypeItem[];
        statusLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            statusLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            statusLUItemArray.push(statusLUItem);
        });
        this.setState({
            statusLookUp: statusLUItemArray,
        });
        this.getEditViewDataSource();
        this.nextStepValue();
    }

    handleStandardError(error: AxiosError<any>) {
        // Redirect back to home page on a 401 unauth error
        if (error.response != null && error.response.status === 401) {
            this.props.history.push({
                pathname: "/",
            });
        } else {
            // TODO: use appInsights logging here
            var respMessage: string = "Payment Cycle Form service failed with response: " + JSON.stringify(error);

            if (!this.paymentService.traceAsErrorToAppInsights(respMessage)) {
                // AppInsights is not available
                console.error(respMessage);
            }
        }
    }

    handleSetStateError(error: AxiosError<any>) {
        // Redirect back to home page on a 401 unauth error
        if (error.response != null && error.response.status === 401) {
            this.props.history.push({
                pathname: "/",
            });
        } else {
            this.setState({
                errorMessage:
                    error.response != null && error.response.data != null
                        ? JSON.parse(JSON.stringify(error.response.data.error))
                        : null,
                IsShowSuccess: false,
            });
            this.failureMessageVisible();
            this.hideLoadpanel();
        }
    }

    //#region render Component
    render() {
        const { statusId } = this.state.editPaymentFormData;
        let disableForm = true;
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            disableForm = false;
        }
        var disableApplyNextField: boolean =
            statusId == paymentStatus.PaymentRemitted ||
            statusId == paymentStatus.PaymentRemittanceRequested ||
            statusId == paymentStatus.PaymentPackRequested ||
            statusId == paymentStatus.PaymentAdviseRequested
                ? true
                : false;
        var disableSaveButton: boolean =
            statusId == paymentStatus.PaymentRemittanceRequested ||
            statusId == paymentStatus.PaymentPackRequested ||
            statusId == paymentStatus.PaymentAdviseRequested
                ? true
                : false;
        // var disableApplyNextAndSaveFields: boolean = ((statusId == paymentStatus.PaymentPackReady || statusId == paymentStatus.PaymentRemitted) ? true : false);

        return (
            <form data-testid="addEditShift-form" className="edit-form my-5">
                <div className="container mb-6 edit-form__column">
                    <div className="row">
                        <h1 className="billingForm-heading">
                            <u>Payment Cycle - Add/Edit/View</u>
                        </h1>
                    </div>

                    <div>
                        {this.state.IsShowErrorFromAPI && this.state.IsShowErrorFromAPI != null ? (
                            <span className="unscheduled-shift">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return <li key={uniqueKey}>{item.errorMessage}</li>;
                                    })}
                                </ul>
                            </span>
                        ) : null}
                    </div>
                    <div>
                        <ValidationSummary className="billingForm-heading"></ValidationSummary>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-10 col-lg-10"></div>
                        <div className="mt-3 col-2 col-lg-2">
                            <button
                                className="btn btn-primary btn--large"
                                type="button"
                                disabled={disableForm || this.state.disableRestartButton}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onClickRestart();
                                }}
                            >
                                <span className="btn__icon">
                                    <LoadIndicator
                                        id="button-indicator"
                                        height={20}
                                        width={20}
                                        visible={this.state.restartIndicator}
                                    />
                                </span>
                                ReStart{" "}
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Pay Date</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangePayDate}
                                disabled={disableForm}
                                useMaskBehavior={true}
                                value={
                                    this.state.editPaymentFormData.payDate
                                        ? this.state.editPaymentFormData.payDate
                                        : undefined
                                }
                            >
                                <Validator>
                                    {this.state.isErrorMessageCriteriaOne == false ? (
                                        <RequiredRule message="Pay Date is required - please select carefully  - this will be shown on the providers self bill invoice" />
                                    ) : (
                                        ""
                                    )}
                                </Validator>
                            </DateBox>
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Status</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <SelectBox
                                dataSource={this.state.statusLookUp}
                                displayExpr="value"
                                valueExpr="id"
                                disabled={disableForm}
                                onValueChanged={this.handleChangeStatusTypeSelect}
                                value={
                                    this.props.location.state.id == 0 &&
                                    this.state.statusLookUp.length > 0 &&
                                    this.state.isStatusChanged == false
                                        ? this.state.statusLookUp[0].id
                                        : this.state.editPaymentFormData.statusId
                                }
                            ></SelectBox>
                        </div>

                        <div className="mt-3 pr-0 col-10 col-lg-1 font-weight-bold font_size d-flex justify-content-center">
                            Pay Sent Date
                        </div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangePaySentDate}
                                disabled={disableForm}
                                useMaskBehavior={true}
                                value={
                                    this.state.editPaymentFormData.paySentDate
                                        ? this.state.editPaymentFormData.paySentDate
                                        : undefined
                                }
                            ></DateBox>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Sub Total</div>
                        <div className="mt-3 col-10 col-lg-11">
                            <div className="edit-form__to-from-field">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    disabled={true}
                                    value={this.state.editPaymentFormData.subTotal}
                                />
                                <div className="font-weight-bold font_size edit-form__to-from-field-text">Tax</div>
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    disabled={true}
                                    value={this.state.editPaymentFormData.tax}
                                />
                                <div className="font-weight-bold font_size edit-form__to-from-field-text">Total</div>
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    disabled={true}
                                    value={this.state.editPaymentFormData.total}
                                />
                            </div>
                            <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        </div>
                    </div>
                    <br></br>
                    <div className="row"></div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Description</div>
                        <div className="mt-3 col-10 col-lg-11">
                            <TextArea
                                height={90}
                                onValueChanged={this.handleChangeDescription}
                                disabled={disableForm}
                                value={this.state.editPaymentFormData.description}
                            />
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="ml-auto col-10 col-lg-2">
                            <Link to={"/managePaymentCycle"}>
                                <button className="btn btn-primary btn--large" disabled={disableForm}>
                                    <span className="btn__icon"></span>
                                    Close{" "}
                                </button>
                            </Link>
                        </div>
                        <div className="col-10 col-lg-2">
                            <Link
                                className="btn btn-primary btn--large"
                                to={{
                                    pathname: "/paymentCycleBacs",
                                    state: {
                                        id: this.state.editPaymentFormData.paymentCycleId,
                                    },
                                }}
                            >
                                BACS
                            </Link>
                        </div>

                        <div className="col-10 col-lg-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="submit"
                                disabled={disableForm || disableSaveButton}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onClickSaveViewItems();
                                }}
                            >
                                <span className="btn__icon"></span>
                                Save & View Items{" "}
                            </button>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="ml-auto mt-3 col-2 col-lg-1 font-weight-bold font_size">Next Step?</div>
                        <div className="mt-3 col-10 col-lg-2">
                            <TextBox value={this.state.nextStepStatusValue} disabled={disableForm}></TextBox>
                        </div>
                        <div className="mr-auto mt-3 col-10 col-lg-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="submit"
                                disabled={disableForm || disableApplyNextField}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onClickApplyNextStep();
                                }}
                            >
                                <span className="btn__icon"></span>
                                Apply Next Step and View Items
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

//#endregion

export default withTranslation()(PaymentCycleForm);
