import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccountID } from "./AuthService";

const API_CACHE_LENGTH_SECONDS = 14400; // 4 hours
const API_CACHE_DATE_LABEL = "__date";
const ACCOUNT_ID = getAccountID();
const APIM_BASE_URL = process.env.APIM_BASE_URL;
// only these responses will be cached:
const CACHEABLE_ENDPOINTS = [
    APIM_BASE_URL + "lookup?type=client",
    APIM_BASE_URL + "lookup?type=requeststatuslookup",
    APIM_BASE_URL + "lookup?type=client&filterBy=all",
    APIM_BASE_URL + "lookup?type=venue",
    APIM_BASE_URL + "lookup?type=venue&filterBy=all",
    APIM_BASE_URL + "lookup?type=periodMatrix",
    APIM_BASE_URL + "lookup?type=configuration",
    APIM_BASE_URL + "lookup?type=subservice",
    APIM_BASE_URL + "lookup?type=provider",
    APIM_BASE_URL + "lookup?type=provider&filterBy=all",
    APIM_BASE_URL + "header",
];

export function HandleRequestForCaching(request: AxiosRequestConfig) {
    let url = request.url;
    if (url) {
        let cacheKey = ACCOUNT_ID + "_" + url;
        let cachedResponse = localStorage.getItem(cacheKey);
        if (cachedResponse) {
            //check if expired and remove if stale
            if (shouldClearItem(cacheKey)) {
                removeItemFromCache(cacheKey);
            } else {
                let parsedResponse = JSON.parse(cachedResponse);
                request.data = parsedResponse;
                // Set the request adapter to send the cached response and prevent the request from actually running
                request.adapter = () => {
                    return Promise.resolve({
                        data: parsedResponse,
                        status: 200,
                        statusText: "OK",
                        headers: request.headers,
                        config: request,
                        request: request,
                    });
                };
            }
        }
    }

    return request;
}

export function HandleResponseForCaching(response: AxiosResponse) {
    let url = response.config.url;
    let now = new Date(new Date().toUTCString()).getTime();

    // Check that this response should be cached.
    if (url && CACHEABLE_ENDPOINTS.includes(url)) {
        // On response, store the response in the cache (unless its already there)
        let cacheKey = ACCOUNT_ID + "_" + url;
        if (!localStorage.getItem(cacheKey + API_CACHE_DATE_LABEL)) {
            localStorage.setItem(cacheKey, JSON.stringify(response.data)); //store the response in local storage
            localStorage.setItem(cacheKey + API_CACHE_DATE_LABEL, now.toString()); //stores the expiry date as a seperate entity
        }
    }

    return response;
}

function removeItemFromCache(url: string) {
    localStorage.removeItem(url);
    localStorage.removeItem(url + API_CACHE_DATE_LABEL);
}

function shouldClearItem(url: string) {
    let now = new Date(new Date().toUTCString()).getTime();
    let cachedResponseDateFromStorage = localStorage.getItem(url + API_CACHE_DATE_LABEL);

    if (cachedResponseDateFromStorage) {
        let cachedResponseDate = parseInt(cachedResponseDateFromStorage);
        if (!isNaN(cachedResponseDate)) {
            let ageInSeconds = (now - cachedResponseDate) / 1000;

            return ageInSeconds > API_CACHE_LENGTH_SECONDS;
        }
    }

    //if we cant successfully retrieve and calculate the age then expire the item
    return true;
}
