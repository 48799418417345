
import React, { useEffect, useState } from "react";
import { Lookup, SelectBox } from "devextreme-react";
import { BaseApiService } from "../../services/BaseApiService";
import LookupService, { LookupTypeIndexes, LookupTypeItem} from "../../services/LookupService";
import { LookUpOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { DropDownOptions } from "devextreme-react/autocomplete";
import SelectAndLookUpBoxUtils from "./SelectAndLookUpBoxUtils";
import { AxiosResponse } from "axios";
/**
 * Props 
 */
interface LookupTypeListDynamicProps {
    /** The data this lookup should display */
    lookupTypeIndex: string;
    /** Callback to fire on user selection */
    onValueChanged: (e: LookUpOnValueChangedEvent) => void;
    /** Regardless of internal rules, disable the control */
    isDisabled?: boolean;
    /** The root lookup will not have a parentMappingId but should still be enabled (unlike child lookups) */
    isRoot: boolean;
    /** Filter by this Id (props.parentMappingId === lookup.parentMappingId) */
    parentMappingId: string;
    /** Set as the selected value */
    value?: string;
    /** Set as the selected text */
    displayExpression?: string;
    /** Showing active and inactive records in dropdowns */ 
    recordType?: string;
    itemAll?:boolean;
    isReadOnly? : boolean;
}

/**
 * Component - Localizes state for a lookup-enable drop-down control.
 */
const LookupTypeListDynamic: React.FC<LookupTypeListDynamicProps> = (props) => {
    // Initialize state
    const [state, setResult] = useState<BaseApiService<LookupTypeItem>>({
        status: "loading",
    });

    const selectAndLookUpBoxUtils = new SelectAndLookUpBoxUtils();
    const service = new LookupService();

    const allType: LookupTypeItem = {
        id: "",
        value: "ALL",
        parentMappingId: "0",
    };
    /**
     * Load data on component load
     */
    useEffect(() => {
        if(props.recordType && props.recordType == "all" ) { 
            selectAndLookUpBoxUtils.executeLookup(service.getLookupByLookupTypeIndexAll, props.lookupTypeIndex, 3, handleSuccess, handleError);
            }
         else {
               selectAndLookUpBoxUtils.executeLookup(service.getLookupByLookupTypeIndex, props.lookupTypeIndex, 3, handleSuccess, handleError);
            }
    }, [props.parentMappingId]);

    /**
     * Update state with data source from server
     *
     * @param response {AxiosResponse<any>} - server response
     * @returns {void}
     */
     const handleSuccess = (response: AxiosResponse<any>): void => {
        setResult({
            status: "loaded",
            payload:props.lookupTypeIndex =="provider"? response.data.data : selectAndLookUpBoxUtils.filterDataSource(response.data.data, props.parentMappingId),
        });
    };

    /**
     * Capture error from server
     *
     * @param error {any} - error resolved by promise
     * @returns {void}
     */
    const handleError = (error: any): void => {
        setResult({
            status: "error",
            error,
        });

        var respMessage: string =
            `Lookup Type ${props.lookupTypeIndex} data load failed with response: ` + JSON.stringify(error);

        if (!service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    return (
        <div id="lookupTypeListComponentId">
            {state.status === "loading" && <span>Loading...</span>}
            {state.status === "loaded" && (
                <Lookup
                    dataSource={props.itemAll ? [allType].concat(selectAndLookUpBoxUtils.filterDataSource(state.payload, props.parentMappingId)) : selectAndLookUpBoxUtils.filterDataSource(state.payload, props.parentMappingId)}
                    displayExpr={props.displayExpression}
                    valueExpr={"id"}
                    onValueChanged={props.onValueChanged}
                    // disabled={
                    //     props.isDisabled ||props.lookupTypeIndex =="provider"||props.lookupTypeIndex =="client" || props.lookupTypeIndex == LookupTypeIndexes.userCompanyType
                    //         ? props.isDisabled
                    //         : selectAndLookUpBoxUtils.isDisabledRules(state.status, props.parentMappingId, props.isRoot)
                    // }
                    disabled={props.isDisabled}
                    value={props.value}
                    readOnly ={props.isReadOnly}
                >
                    <DropDownOptions showTitle={false} closeOnOutsideClick={true} height="300px" />
                </Lookup>
            )}
            {state.status === "error" && <SelectBox dataSource={[]} />}
        </div>
    );
};

export default LookupTypeListDynamic;
