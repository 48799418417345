import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Editing,
    Texts,
    ColumnChooser,
    SearchPanel,
    GroupPanel,
    Summary,
    TotalItem,
    ValueFormat,
    Format,
    Scrolling,
} from "devextreme-react/data-grid";
import { LoadIndicator, Popup, ScrollView } from "devextreme-react";
import AllUserGridService from "../grid/AllUserGridService";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import gridUtils from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";
import CostReportService, { costReportGridRowItem } from "../../services/CostReportService";
import RaiseQueryActionCell from "./RaiseQueryActionCell";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { Link } from "react-router-dom";
import outstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import CostReportUtils from "./CostReportUtils";
import ClientInformationMainContent from "../ClientInformation/ClientInformationMainContent";
import SupplierInformationMainContent from "../SupplierInformation/SupplierInformationMainContent";
import { ServiceType, GetServiceName } from "../../common/ServiceUtilities";
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
//props
interface CostReportGridProps {
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    startDateId: string;
    endDateId: string;
    setCalculations: (totalValue: number, loaded: boolean) => void;
}

// State
interface CostReportGridState {
    costReportGridDataSource: costReportGridRowItem[];
    showProgressIndicator: boolean;
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    startDateId: string;
    endDateId: string;
    venuePopupVisible?: boolean;
    providerPopupVisible?: boolean;
}

// Component - displays both the header and child grids
class CostReportGrid extends React.Component<CostReportGridProps> {
    state: CostReportGridState;
    service: CostReportService;
    gridUtils: gridUtils;
    utils: outstandingActionsUtilities;
    costReportUtils: CostReportUtils;
    constructor(props: CostReportGridProps) {
        super(props);
        // Initialize state and services/utils
        this.state = {
            costReportGridDataSource: [],
            showProgressIndicator: false,
            clientId: this.props.clientId,
            venueId: this.props.venueId,
            serviceTypeId: this.props.serviceTypeId,
            startDateId: this.props.startDateId,
            endDateId: this.props.endDateId,
            venuePopupVisible: false,
            providerPopupVisible: false,
        };
        this.gridUtils = new gridUtils();
        this.costReportUtils = new CostReportUtils();
        this.utils = new outstandingActionsUtilities();
        // Functions
        this.service = new CostReportService();
        this.onCustomGridLoad = this.onCustomGridLoad.bind(this);
        this.onCustomGridSave = this.onCustomGridSave.bind(this);
    }

    // Initialize state from server
    componentDidMount() {
        this.setState({
            showProgressIndicator: true,
        });
    }

    costReportGridDataSource = () => {
        this.props.setCalculations(0, false); //This will set the Period week and Total texts on the main page to be invisible.
        this.setState({
            showProgressIndicator: true,
        });
        this.service
            .getCostReportGridRows(
                this.props.clientId,
                this.props.venueId,
                this.props.startDateId,
                this.props.endDateId,
                this.props.serviceTypeId
            )
            .then(this.handleSuccess)
            .catch(this.handleError);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        var eventUser: boolean =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        var total: number = 0;
        if (response.status == 204) {
            this.props.setCalculations(total, false);
            this.setState({
                costReportGridDataSource: response.data, // data will be an empty string ("")
                showProgressIndicator: false,
            });
        } else {
            if (eventUser) {
                total = this.costReportUtils.returnCalculationsExcludingItemType(response.data.data, [], true);
            } else {
                total = this.costReportUtils.returnCalculationsExcludingItemType(response.data.data, [], false);
            }
            this.props.setCalculations(total, true);
            this.setState({
                costReportGridDataSource: response.data.data,
                showProgressIndicator: false,
            });
        }
    };

    handleError = () => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    componentDidUpdate = (prevprops: CostReportGridProps) => {
        if (
            this.props.clientId != prevprops.clientId ||
            this.props.venueId != prevprops.venueId ||
            this.props.serviceTypeId != prevprops.serviceTypeId ||
            this.props.startDateId != prevprops.startDateId ||
            this.props.endDateId != prevprops.endDateId
        ) {
            this.setState({
                serviceTypeId: this.props.serviceTypeId,
            });
            this.costReportGridDataSource();
        }
    };

    isBlank(billableItemId: string) {
        return !billableItemId || /^\s*$/.test(billableItemId) || 0 === billableItemId.length;
    }

    onCustomGridLoad() {
        return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid);
    }

    onCustomGridSave(gridState: Object) {
        CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid, gridState);
    }

    //Helper function that sets the serviceTypeId in the session.
    linkToSimpleShifts = (serviceId: string) => {
        sessionStorage.setItem("serviceTypeFilter", serviceId);
    };

    ////Custom cell rendering function that links to the cost report page.
    dateCustomCellContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={() => this.linkToSimpleShifts(cellInfo.data.serviceTypeId)}
                    className="approval-query-column"
                    to={{
                        pathname: "/simpleShifts",
                        state: {
                            clientId: cellInfo.data.clientId,
                            venueId: cellInfo.data.venueId,
                            startDate: cellInfo.data.date,
                            endDate: cellInfo.data.date,
                        },
                    }}
                >
                    {cellInfo.data.date}
                </Link>
            </div>
        );
    };

    approvedShiftCellDisplayContent = (cellInfo: any) => {
        var timeDiffIndicator: string = "";
        var approvedShift: string = "";
        if (cellInfo.data && cellInfo.data.timeDiffIndicator) {
            timeDiffIndicator = cellInfo.data.timeDiffIndicator;
        }
        if (cellInfo.data && cellInfo.data.approvedShift) {
            approvedShift = cellInfo.data.approvedShift;
        }

        var isBlue: boolean = false;
        var isRed: boolean = false;
        var classname: string = "";
        var isEventOrClient =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.VenueManager);

        if (timeDiffIndicator && timeDiffIndicator.toLowerCase() == "no change") {
            isBlue = false;
            isRed = false;
        } else if (isEventOrClient && timeDiffIndicator && timeDiffIndicator.toLowerCase() == "less hours") {
            isBlue = true;
            isRed = false;
        } else if (
            UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) &&
            timeDiffIndicator &&
            timeDiffIndicator.toLowerCase() == "less hours"
        ) {
            isRed = true;
            isBlue = false;
        } else if (isEventOrClient && timeDiffIndicator && timeDiffIndicator.toLowerCase() == "more hours") {
            isRed = true;
            isBlue = false;
        } else if (
            UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) &&
            timeDiffIndicator &&
            timeDiffIndicator.toLowerCase() == "more hours"
        ) {
            isBlue = true;
            isRed = false;
        } else {
            isBlue = true;
            isRed = false;
        }

        if (isBlue) {
            classname = "check-blue";
        }
        if (isRed) {
            classname = "check-red";
        }

        return (
            <div>
                <span className={classname}>{`${approvedShift}`}</span>
            </div>
        );
    };

    billableTimeCellDisplayContent = (cellInfo: any) => {
        var billedTime: string[] = this.costReportUtils.billableTimeDisplayContent(
            cellInfo.data ? cellInfo.data : "",
            "costreport"
        );
        return (
            <div>
                <span className={billedTime[0]}>{`${billedTime[1]}`}</span>
            </div>
        );
    };

    venueCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoVenue(cellInfo.data.clientId, cellInfo.data.venueId);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.venue}
                </Link>
            </div>
        );
    };

    providerCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.clientId, cellInfo.data.venueId);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.provider}
                </Link>
            </div>
        );
    };

    showInfoVenue = (clientId: string, venueId: string) => {
        this.setState({
            venuePopupVisible: true,
            clientId: clientId,
            venueId: venueId,
        });
    };
    showInfoProvider = (clientId: string, venueId: string) => {
        this.setState({
            providerPopupVisible: true,
            clientId: clientId,
            venueId: venueId,
        });
    };
    hideInfoVenue = () => {
        this.setState({
            venuePopupVisible: false,
        });
    };
    hideInfoProvider = () => {
        this.setState({
            providerPopupVisible: false,
        });
    };

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell && gridCell.rowType === "totalFooter") {
                    if (gridCell.value && gridCell.totalSummaryItemName == "providerPayAmountLocal") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "grossProfitAmountLocal") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "total") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }

                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    };


    render() {
        const isEventUk =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.costReportGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        onExporting={this.onExporting}
                        noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                    >
                        <ColumnChooser enabled={true} />
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <GroupPanel visible={true} />
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column caption="QUERY" cellComponent={RaiseQueryActionCell} />
                        <Column dataField="venue" caption="VENUE" cellRender={this.venueCellDisplayContent} />
                        <Column dataField="number" caption="NUMBER" />
                        <Column dataField="client" caption="CLIENT" />
                        <Column dataField="service" caption="SERVICE" cellComponent={AllUserGridService} />
                        <Column dataField="subType" caption="SUB-TYPE" />
                        <Column
                            dataField={this.state.serviceTypeId == ServiceType.Security ? "role" : "type"}
                            caption={this.state.serviceTypeId == ServiceType.Security ? "ROLE" : "TYPE"}
                        />
                        <Column
                            dataField={
                                this.state.serviceTypeId == ServiceType.Entertainment ? "artistName" : "provider"
                            }
                            caption={this.state.serviceTypeId == ServiceType.Entertainment ? "ARTIST NAME" : "PROVIDER"}
                            cellRender={this.providerCellDisplayContent}
                        />
                        <Column
                            dataField="date"
                            caption="DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                            cellRender={this.dateCustomCellContent}
                        />

                        {this.state.serviceTypeId == ServiceType.Security ? (
                            <Column dataField="name" caption="NAME" />
                        ) : null}

                        {this.state.serviceTypeId == ServiceType.ContractCleaning ? null : (
                            <Column
                                dataField={
                                    this.state.serviceTypeId == ServiceType.Security
                                        ? "expectedShift"
                                        : "performanceTime"
                                }
                                caption={
                                    this.state.serviceTypeId == ServiceType.Security
                                        ? "SCHEDULED SHIFT"
                                        : "PERFORMANCE TIME"
                                }
                            />
                        )}

                        {this.state.serviceTypeId == ServiceType.Security ? (
                            <Column
                                dataField="approvedShift"
                                caption="APPROVED SHIFT"
                                cellRender={this.approvedShiftCellDisplayContent}
                            />
                        ) : null}

                        {this.state.serviceTypeId == ServiceType.Entertainment ? null : (
                            <Column
                                dataField={
                                    this.state.serviceTypeId == ServiceType.Security ? "billableTime" : "contractHours"
                                }
                                caption={
                                    this.state.serviceTypeId == ServiceType.Security
                                        ? "TIME / QUANTITY"
                                        : "CONTRACT HOURS"
                                }
                                cellRender={this.billableTimeCellDisplayContent}
                            />
                        )}

                        {this.state.serviceTypeId == ServiceType.Entertainment ? null : (
                            <Column
                                dataField="rate"
                                caption="RATE"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        {isEventUk && (
                            <Column
                                dataField="providerPayAmountLocal"
                                caption="PROVIDER NET"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        {isEventUk && (
                            <Column
                                dataField="grossProfitAmountLocal"
                                caption="EVENT FEE NET"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        <Column
                            dataField="total"
                            caption="TOTAL"
                            dataType="number"
                        >
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column>

                        <Column dataField="billPeriod" caption="BILLED" />

                        {isEventUk && (
                            <Column
                                dataField="payDate"
                                caption="PAY DATE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertddmmyyyyStringToDate(rowData.payDate);
                                }}
                            />
                        )}
                        <Summary>
                            <TotalItem
                                column="providerPayAmountLocal"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="providerPayAmountLocal">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="grossProfitAmountLocal"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="grossProfitAmountLocal">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="total"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="total">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                        </Summary>
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
                <Popup
                    visible={this.state.venuePopupVisible}
                    onHiding={this.hideInfoVenue}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={GetServiceName(this.state.serviceTypeId) + " - Client Information & Schedule"}
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <ClientInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            isShowByVenue={true}
                            serviceTypeId={this.state.serviceTypeId}
                        ></ClientInformationMainContent>
                    </ScrollView>
                </Popup>

                <Popup
                    visible={this.state.providerPopupVisible}
                    onHiding={this.hideInfoProvider}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={GetServiceName(this.state.serviceTypeId) + " - Supplier Information & Venue Conditions"}
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <SupplierInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            navigationFromShift="SimpleShiftGrid"
                        ></SupplierInformationMainContent>
                    </ScrollView>
                </Popup>
            </div>
        );
    }
}

export default CostReportGrid;
