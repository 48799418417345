import React, { Component } from "react";
import { Link } from "react-router-dom";
import CookieConsent from "./CookieConsent";

class Footer extends Component {
    onChange = () => {
        return;
    };
    //Add the Javascript's default function to the href attribute in order to prevent any redirects.
    render() {
        return (
            <>
                <footer className="site-footer">
                    <ul>
                        <li>
                            <Link to="/terms">Terms & Conditions</Link>
                        </li>
                        <li>
                            <Link to="/acceptableUse">Acceptable Use</Link>
                        </li>
                        <li>
                            <Link to="/privacy">Privacy</Link>
                        </li>
                        <li>
                            <Link to="/cookiePolicy">Cookie</Link>
                        </li>
                        <li>
                            <Link to="/raiseQuery">Contact Us</Link>
                        </li>
                        <li>
                            <Link to="/FAQs">FAQs</Link>
                        </li>
                    </ul>
                </footer>
                <CookieConsent />
            </>
        );
    }
}
export default Footer;
