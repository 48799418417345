import { LoadIndicator } from "devextreme-react";
import React from "react";
import { companyInformationData } from "../../services/QueryService";

interface OurDetailsCardProps {
    companyData: companyInformationData;
    dataLoaded: boolean;
}

interface OurDetailsCardState {}

class OurDetailsCard extends React.Component<OurDetailsCardProps> {
    state: OurDetailsCardState;

    constructor(props: OurDetailsCardProps) {
        super(props);
        this.state = {};
    }

    renderShortName = () => {
        if(this.props.companyData.shortName) {
            return (
                <>
                    {this.props.companyData.shortName}
                </>
            )
        }
    }

    renderOpeningHours = () => {
        if(this.props.companyData.openingHours) {
            return (
                <>
                    <br />
                    <span style={{ color: `#27bcbd` }}>Office Hours: </span>
                    {this.props.companyData.openingHours}
                </>
            )
        }
    }

    renderAddress = () => {
        const { addressStreet1, addressStreet2, addressStreet3, city, state, postCode } = this.props.companyData;
        
        var fullAddress = "";

        if(addressStreet1) {
            fullAddress += addressStreet1 + ", ";
        }
        if(addressStreet2) {
            fullAddress += addressStreet2 + ", ";
        }
        if(addressStreet3) {
            fullAddress += addressStreet3 + ", ";
        }
        if(city) {
            fullAddress += city + ", ";
        }
        if(state) {
            fullAddress += state + ", ";
        }
        if(postCode) {
            fullAddress += postCode;
        }
        
        return (
            <>
                <br />
                {fullAddress}
            </>
        )
    }

    renderWebSiteLink = () => {
        if(this.props.companyData.webSite) {
            return (
                <a href={this.props.companyData.webSite}>{this.props.companyData.webSite}</a>
            )
        }
    }

    renderOfficePhone = () => {
        if(this.props.companyData.officePhone) {
            return (
                <>
                    <br />
                    {this.props.companyData.officePhone}
                </>
            )
        }
    }

    renderEmail = () => {
        if(this.props.companyData.email) {
            return (
                <>
                    <br />
                    {this.props.companyData.email}
                </>
            )
        }
    }

    render() {
        const { dataLoaded } = this.props;
        
        return (
            <>
                <h4>
                    <b>Our Details</b>
                </h4>
                {dataLoaded ? (
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <strong>
                                {this.renderShortName()}
                            </strong>
                            {this.renderOpeningHours()}
                            {this.renderAddress()}
                        </div>
                        <div className="col-12 col-xl-6 text-xl-right">
                            {this.renderWebSiteLink()}
                            {this.renderOfficePhone()}
                            {this.renderEmail()}
                        </div>
                    </div>
                ) : (
                    <LoadIndicator
                        id="button-indicator"
                        height={60}
                        width={60}
                        className="home-page__todayloader"
                        visible={true}
                    />
                )}
            </>
        );
    }
}

export default OurDetailsCard;
