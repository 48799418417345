import { AxiosResponse } from "axios";
import { AddEditFormData } from "../components/Outstanding-Actions/OutstandingActionsUtilities";
import PaymentRequestUtils from "../components/PaymentRequests/PaymentRequestUtils";
import { PaymentRequestMatchedPopUpFormData } from "../components/PaymentRequests/PaymentRequestUtils";
import { _aiTraceAsError, _get } from "./BaseApiService";
import { ConfigurableTextType } from "./OutstandingApprovalService";
import  { HeaderResponse } from "../services/HeaderService";

// Endpoints
const SERVICE_FEE_MATRIX_API = "ServiceFeeMatrix";
const LOOKUP_BASE_URL = "lookup";
type NullableDate = Date | null | undefined;
export interface gridFilter {
    serviceType: string;
    dateFrom: NullableDate;
    dateTo: NullableDate;
}

export interface GigreqGridFilter {
    status: string;
    dateFrom: NullableDate;
    dateTo: NullableDate;
}

export interface QueryReqGridFilter {
    clientId: string;
    venueId: string;
    serviceId: string;
    statusId: string;
    raisedById:string;
}

export interface SignupGridFilter {
    statusId: string;
}

// Global Service - To retrieve lookup values and use that globally throughout the application
const GlobalService = (function () {
    let utils: PaymentRequestUtils = new PaymentRequestUtils();
    var serviceFeeMatrixDetails: any[] = [];
    let configurationValueDetails: ConfigurableTextType[] = [];
    var currentPeriod: string = "";
    var currentWeek: string = "";
    var paymentCycleIds: string[] = [];
    var billableItemIds: string[] = [];
    var commaSeparatedPaymentCycleIds: string = "";
    var commanSeparatedBillableItemIds: string = "";
    var editedApprovalItems: AddEditFormData[] = [];
    var deletedItemId: string = "";
    var billableItem: any;
    var gridType: string;
    let popupType: string;
    let artistTypeSubTypeVal: string[] = [];
    let removedElementArr: string[] = [];
    let editState: boolean = false;
    let isApply: boolean = false;
    let isClose: boolean = false;
    let newShift: boolean = false;
    let billableItemId: string = "";
    var currentFilterValues: gridFilter = {
        serviceType: "",
        dateFrom: null,
        dateTo: null,
    };
    var currentGigFilterValues: GigreqGridFilter = {
        status: "",
        dateFrom: null,
        dateTo: null,
    };
    var paymentGridParamVal: string;
    let gigFrom: string;
    let artistGridFrom: string;
    var paymentRequestDataSource: PaymentRequestMatchedPopUpFormData;

    var currentQueryFilterValues: QueryReqGridFilter = {
        clientId: "",
        venueId: "",
        serviceId: "",
        statusId: "",
        raisedById:""
    };
    var currentSignUpProfileUpdateFilterVal: SignupGridFilter = {
        statusId: ""
    }
    let userHeader: HeaderResponse = {
        title: '',
        description: '',
        businessEntityId: ''
    }
    const setDataSource = function (response: any) {
        paymentRequestDataSource = response;
    }

    const getDataSource = function () {
        return paymentRequestDataSource;
    }

    const removeDataSourceVal = function (response: any) {
        paymentRequestDataSource = utils.initializePaymentRequestMatchedPopUpItems();
    }


    const setParamVal = function (param: string) {
        paymentGridParamVal = param;
    }

    const getParamVal = function () {
        return paymentGridParamVal;
    }

    const removeParamVal = function () {
        paymentGridParamVal = "";
    }

    const setDeletedItemId = function (itemId: string) {
        deletedItemId = itemId;
    }

    const getDeletedItemId = function () {
        return deletedItemId;
    }

    const removeDeletedItemId = function () {
        deletedItemId = "";
    }
    const setGridType = function (gridTypeVal: string) {
        gridType = gridTypeVal;
    }

    const getGridType = function () {
        return gridType;
    }

    const setPopupType = function (gridTypeVal: string) {
        popupType = gridTypeVal;
    }

    const getPopupType = function () {
        return popupType;
    }

    const setArtistTypeSubType = function (typeSubTypeVal: string) {
        let partsOfTypeSubTypeVal = typeSubTypeVal.split(",");
        let lengthOfParts = partsOfTypeSubTypeVal.length;
        let itemId = lengthOfParts - 1;
        let lastVal = partsOfTypeSubTypeVal[itemId];
        let isMatch: boolean = false;
        removedElementArr && removedElementArr.length > 0 && removedElementArr.forEach((item: any) => {
            if (item == lastVal) {
                isMatch = true;
                const index = removedElementArr.indexOf(item);
                if (index > -1) {
                    removedElementArr.splice(index, 1);
                }
            }
        });
        if (isMatch) {
            artistTypeSubTypeVal.push(lastVal);
        }
        else {
            artistTypeSubTypeVal.push(typeSubTypeVal);
        }

    }
    const getArtistTypeSubType = function () {
        return artistTypeSubTypeVal.toString();
    }
    const removeArtistTypeSubType = function (subServiceTypeId: string, typeId: string) {
        let removedItem: string = "";
        if (subServiceTypeId && typeId) {
            removedItem = `${subServiceTypeId}-${typeId}`;
        }
        else {
            removedItem = `${subServiceTypeId}`;
        }
        let parts = artistTypeSubTypeVal.toString().split(",");
        let copyParts = JSON.parse(JSON.stringify(parts));
        parts.length > 0 && parts.forEach((item: any) => {
            if ((subServiceTypeId && typeId) && (item === removedItem)) {
                let index = copyParts.indexOf(item);
                if (index >= 0) {
                    copyParts.splice(index, 1);
                }
                removedElementArr.push(removedItem);
            }
            else if (subServiceTypeId) {
                if (item.includes(`${removedItem}-`)) {
                    let index = copyParts.indexOf(item);
                    if (index >= 0) {
                        copyParts.splice(index, 1);
                    }
                    removedElementArr.length > 0 && removedElementArr.forEach((item: any) => {
                        if (item.includes(`${removedItem}-`)) {
                            let index = removedElementArr.indexOf(item);
                            if (index >= 0) {
                                removedElementArr.splice(index, 1);
                            }
                        }
                    });
                }
                else if (item === removedItem) {
                    let index = copyParts.indexOf(item);
                    if (index >= 0) {
                        copyParts.splice(index, 1);
                    }
                    removedElementArr.push(removedItem);
                }
            }
        });
        artistTypeSubTypeVal = [];
        artistTypeSubTypeVal.push(copyParts.toString());
        return artistTypeSubTypeVal.toString();
    }

    const setBillableItem = function (billableItemObj: AddEditFormData) {
        if (billableItemObj) {
            billableItem = billableItemObj;
        }
    }

    const getBillableItem = function () {
        return billableItem;
    }

    const removeGridType = function () {
        gridType = "";
    }

    const removeBillableItem = function () {
        billableItem = {}
    }
    const setEditedApprovalItems = function (items: AddEditFormData) {
        var updatedBillableItem: any;
        if (editedApprovalItems.length > 0) {
            editedApprovalItems.forEach((billableitem: AddEditFormData, key: number) => {
                if (billableitem.billableItemId == items.billableItemId) {
                    editedApprovalItems.splice(key, 1, items);
                    updatedBillableItem = billableitem;
                }
            });
        }

        if (!updatedBillableItem) {
            editedApprovalItems.push(items);
        }
    };

    const getEditedApprovalItems = function () {
        return editedApprovalItems;
    };

    const removeEditapprovalItems = function () {
        editedApprovalItems = [];
    };

    const serviceFeeMatrixValue = function () {
        var serviceFeeMatrixLookUp = [];
        if (serviceFeeMatrixDetails && serviceFeeMatrixDetails.length > 0) {
            serviceFeeMatrixLookUp = serviceFeeMatrixDetails;
        }
        return serviceFeeMatrixLookUp;
    };
    const configurationValue = function () {
        let configurationLookUp: ConfigurableTextType[] = [];
        if (configurationValueDetails && configurationValueDetails.length > 0) {
            configurationLookUp = configurationValueDetails;
        }
        return configurationLookUp;
    };

    // get dateFrom and dateTo for payment request form
    const getFilterValues = function (): gridFilter {
        return {
            serviceType: currentFilterValues.serviceType,
            dateFrom: currentFilterValues.dateFrom,
            dateTo: currentFilterValues.dateTo,
        };
    };

    // get dateFrom and dateTo for payment request form
    const getGigFilterValues = function (): GigreqGridFilter {
        return {
            status: currentGigFilterValues.status,
            dateFrom: currentGigFilterValues.dateFrom,
            dateTo: currentGigFilterValues.dateTo,
        };
    };

    // get dateFrom and dateTo for payment request form
    const getQueryFilterValues = function (): QueryReqGridFilter {
        return {
            clientId: currentQueryFilterValues.clientId,
            venueId: currentQueryFilterValues.venueId,
            serviceId: currentQueryFilterValues.serviceId,
            statusId: currentQueryFilterValues.statusId,
            raisedById:currentQueryFilterValues.raisedById
        };
    };

    // get status of signup/ profile update filter value
    const getSignUpFilterValues = function (): SignupGridFilter {
        return {
            statusId: currentSignUpProfileUpdateFilterVal.statusId
        };
    };


    const removegigFilterValues = function () {
        currentGigFilterValues.dateFrom = null;
        currentGigFilterValues.dateTo = null;
        currentGigFilterValues.status = "";
    };

    // to identify user coming from entertainment or contractCleaning
    const setGigComingFrom = function ( from: string) {
        gigFrom = from;
    };

    const getGigComingFrom = function () {
        return gigFrom;
    };

    // to identify user coming from entertainment or contractCleaning
    const setArtistGridComingFrom = function ( from: string) {
        artistGridFrom = from;
    };

    const getArtistGridComingFrom = function () {
        return artistGridFrom;
    };

    const removequeryFilterValues = function () {
        currentQueryFilterValues.clientId = "";
        currentQueryFilterValues.venueId = "";
        currentQueryFilterValues.serviceId = "";
        currentQueryFilterValues.statusId = "";
        currentQueryFilterValues.raisedById = "";
    };

    const removeSignUpFilterValues = function () {
        currentSignUpProfileUpdateFilterVal.statusId = ""
    };


    const setGigFilterValues = function (dateFrom: NullableDate, dateTo: NullableDate): void {
        // Set new service type filter
        //currentFilterValues.serviceType = serviceFilterType;
        // Use dates provided
        currentGigFilterValues.dateFrom = dateFrom;
        currentGigFilterValues.dateTo = dateTo;
    };

    const setQueryFilterValues = function (clientId: string, venueId: string, serviceId: string, statusId: string,raisedById:string): void {
        // Set new service type filter
        //currentFilterValues.serviceType = serviceFilterType;
        // Use dates provided
        currentQueryFilterValues.clientId = clientId;
        currentQueryFilterValues.venueId = venueId;
        currentQueryFilterValues.serviceId = serviceId;
        currentQueryFilterValues.statusId = statusId;
        currentQueryFilterValues.raisedById = raisedById;
    };

    const setFilterValues = function (dateFrom: NullableDate, dateTo: NullableDate): void {
        // Set new service type filter
        //currentFilterValues.serviceType = serviceFilterType;
        // Use dates provided
        currentFilterValues.dateFrom = dateFrom;
        currentFilterValues.dateTo = dateTo;
    };

    const setSignUpGridFilterValues = function (statusId: string): void {
        // Set new service type filter
        //currentFilterValues.serviceType = serviceFilterType;
        // Use dates provided
        currentSignUpProfileUpdateFilterVal.statusId = statusId;
    };

    // Set datefrom and dateto from Query String (D365)
    const setDateFromValue = function (dateFrom: NullableDate): void {
        currentFilterValues.dateFrom = dateFrom;
    };
    const setDateToValue = function (dateTo: NullableDate): void {
        currentFilterValues.dateTo = dateTo;
    };

    //Set PaymentCycle Id on row selection
    const setPaymentCycleIds = function (paymentCycleId: string) {
        paymentCycleIds.push(paymentCycleId);
    };

    //Set BillableItem Id on row selection
    const setBillableItemIds = function (billableItemId: string) {
        billableItemIds.push(billableItemId);
    };

    //Retrieve PaymentCycle Id on button click
    const getPaymentCycleIds = function () {
        commaSeparatedPaymentCycleIds = paymentCycleIds.join(",");
        return commaSeparatedPaymentCycleIds;
    };

    //Retrieve PaymentCycle Id on button click
    const getBillableItemIds = function () {
        commanSeparatedBillableItemIds = billableItemIds.join(",");
        return commanSeparatedBillableItemIds;
    };

    //Clear the paymentCycleIds value array and string
    const removePaymentCycleIds = function () {
        paymentCycleIds = [];
        commaSeparatedPaymentCycleIds = "";
    };

    //Clear the paymentCycleIds value array and string
    const removeBillableItemIds = function () {
        billableItemIds = [];
        commanSeparatedBillableItemIds = "";
    };

    //Clear the paymentCycleIds value array and string
    const removePaymentCycleParticularIds = function (particularPaymentId: string) {
        var arrPaymentCycleId: string[] = [];
        if (paymentCycleIds.length > 0) {
            arrPaymentCycleId = paymentCycleIds.filter((item) => {
                return item != particularPaymentId;
            });
        }
        paymentCycleIds = arrPaymentCycleId;
        commaSeparatedPaymentCycleIds = paymentCycleIds.join(",");
    };

    //Clear the billableItemIds value array and string
    const removeBillableItemParticularIds = function (particularBillableItemId: string) {
        var arrBillableItemId: string[] = [];
        if (billableItemIds.length > 0) {
            arrBillableItemId = billableItemIds.filter((item) => {
                return item != particularBillableItemId;
            });
        }
        billableItemIds = arrBillableItemId;
        commanSeparatedBillableItemIds = billableItemIds.join(",");
    };

    //Retrieve the service fee matrix details from server
    const getServiceFeeMatrixDetails = function (): Promise<AxiosResponse<any>> {
        return _get(SERVICE_FEE_MATRIX_API);
    };

    // Set the service fee matrix data
    const setServiceFeeMatrixDetails = function () {
        getServiceFeeMatrixDetails().then(handleSuccessServiceFeeMatrixValue).catch(handleStandardError);
    };

    const handleSuccessServiceFeeMatrixValue = function (response: AxiosResponse<any>): string[] {
        serviceFeeMatrixDetails = response.data.data;
        return serviceFeeMatrixDetails;
    };

    const handleStandardError = function (error: any) {
        var respMessage: string = "Global Service load failed with response: " + JSON.stringify(error);
        if (traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    const getConfigurationValueDetails = function (): Promise<AxiosResponse<any>> {
        return _get(LOOKUP_BASE_URL + `?type=${"configuration"}`);
    };

    const setConfigurationDetails = function () {
        getConfigurationValueDetails().then(handleSuccessConfigurationValue).catch(handleStandardError);
    };

    const handleSuccessConfigurationValue = function (response: AxiosResponse<any>): ConfigurableTextType[] {
        configurationValueDetails = response.data.data;
        return configurationValueDetails;
    };

    const setCurrentPeriod = function (period: string) {
        currentPeriod = period;
    };

    const getCurrentPeriod = function () {
        return currentPeriod;
    };

    const setCurrentWeek = function (week: string) {
        currentWeek = week;
    };

    const getCurrentWeek = function () {
        return currentWeek;
    };

    const setApplyState = function (apply: boolean) {
        isApply = apply;
    };

    const getApplyState = function () {
        return isApply;
    };
    const removeApplyState = function () {
        isApply = false;
    }

    const setCloseState = function (close: boolean) {
        isClose = close;
    };

    const getCloseState = function () {
        return isClose;
    };

    const removeCloseState = function () {
        isClose = false;
    }

    const setNewShift = function (close: boolean) {
        newShift = close;
    };

    const getNewShift = function () {
        return newShift;
    };

    const removeNewShift = function () {
        newShift = false;
    }

    const setBillableItemId = function (itemId: string) {
        billableItemId = itemId;
    }

    const getBillableItemId = function () {
        return billableItemId;
    }

    const removeBillableItemId = function () {
        billableItemId = "";
    }

    const traceAsErrorToAppInsights = function (message: string): boolean {
        return _aiTraceAsError(message);
    };

    const setHeaderResopnse = function(headerObject:HeaderResponse){
        userHeader = headerObject;
    }

    const getHeaderResopnse = function () {
        return userHeader;
    };


    return {
        setServiceFeeMatrixDetails: setServiceFeeMatrixDetails,
        serviceFeeMatrixDetails: serviceFeeMatrixDetails,
        serviceFeeMatrixValue: serviceFeeMatrixValue,
        setConfigurationDetails: setConfigurationDetails,
        configurationValue: configurationValue,
        configurationValueDetails: configurationValueDetails,
        getConfigurationValueDetails: getConfigurationValueDetails,
        setCurrentWeek: setCurrentWeek,
        setCurrentPeriod: setCurrentPeriod,
        getCurrentPeriod: getCurrentPeriod,
        getCurrentWeek: getCurrentWeek,
        setPaymentCycleIds: setPaymentCycleIds,
        getPaymentCycleIds: getPaymentCycleIds,
        removePaymentCycleIds: removePaymentCycleIds,
        removePaymentCycleParticularIds: removePaymentCycleParticularIds,
        setBillableItemIds: setBillableItemIds,
        removeBillableItemIds: removeBillableItemIds,
        removeBillableItemParticularIds: removeBillableItemParticularIds,
        getBillableItemIds: getBillableItemIds,
        setEditedApprovalItems: setEditedApprovalItems,
        getEditedApprovalItems: getEditedApprovalItems,
        removeEditapprovalItems: removeEditapprovalItems,
        getFilterValues: getFilterValues,
        setFilterValues: setFilterValues,
        setDateFromValue: setDateFromValue,
        setDateToValue: setDateToValue,
        setBillableItem: setBillableItem,
        getBillableItem: getBillableItem,
        removeBillableItem: removeBillableItem,
        setGridType: setGridType,
        getGridType: getGridType,
        setPopupType: setPopupType,
        getPopupType: getPopupType,
        removeGridType: removeGridType,
        setDeletedItemId: setDeletedItemId,
        getDeletedItemId: getDeletedItemId,
        removeDeletedItemId: removeDeletedItemId,
        getGigFilterValues: getGigFilterValues,
        setGigFilterValues: setGigFilterValues,
        removegigFilterValues: removegigFilterValues,
        setGigComingFrom: setGigComingFrom,
        getGigComingFrom: getGigComingFrom,
        setArtistGridComingFrom: setArtistGridComingFrom,
        getArtistGridComingFrom: getArtistGridComingFrom,
        setQueryFilterValues: setQueryFilterValues,
        getQueryFilterValues: getQueryFilterValues,
        removequeryFilterValues: removequeryFilterValues,
        setParamVal: setParamVal,
        getParamVal: getParamVal,
        removeParamVal: removeParamVal,
        setDataSource: setDataSource,
        getDataSource: getDataSource,
        getSignUpFilterValues: getSignUpFilterValues,
        setSignUpGridFilterValues: setSignUpGridFilterValues,
        removeSignUpFilterValues: removeSignUpFilterValues,
        removeDataSourceVal: removeDataSourceVal,
        setArtistTypeSubType: setArtistTypeSubType,
        getArtistTypeSubType: getArtistTypeSubType,
        removeArtistTypeSubType: removeArtistTypeSubType,
        setApplyState: setApplyState,
        getApplyState: getApplyState,
        setCloseState: setCloseState,
        getCloseState: getCloseState,
        setBillableItemId: setBillableItemId,
        getBillableItemId: getBillableItemId,
        removeBillableItemId: removeBillableItemId,
        removeApplyState: removeApplyState,
        removeCloseState: removeCloseState,
        setNewShift: setNewShift,
        getNewShift: getNewShift,
        removeNewShift: removeNewShift,
        setHeaderResopnse:setHeaderResopnse,
        getHeaderResopnse:getHeaderResopnse
    };
})();

export default GlobalService;
