import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SimpleGrid from "../grid/SimpleGrid";
import LightModePageTemplate from "./Templates/LightModeTemplate";

interface GigListPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}
interface GigListPageComponentState {
    refreshGrid: boolean;
    gigCount: string;
}
class GigListPage extends Component<GigListPageComponentProps> {
    state: GigListPageComponentState;
    constructor(props: GigListPageComponentProps) {
        super(props);
        this.state = {
            refreshGrid: false,
            gigCount: "0",
        };
    }

    setSummary = () => {};
    setButtonStates = () => {};
    onRefreshClick = () => {
        this.setState({
            refreshGrid: !this.state.refreshGrid,
        });
    };
    setTotalGigsCounter = (totalCount: number | undefined) => {
        this.setState({ gigCount: totalCount && totalCount.toString() });
    };
    setIndicatorStates = () => {};
    render() {
        // let id = this.props.location.state ? this.props.location.state.id : "";
        let header = "My Gigs";

        return (
            <LightModePageTemplate>
                <div id="myGigs" className="page-content container">
                    <br></br>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="row mygig">
                                <div className="col">
                                    <h3 className="text-left font-weight-bold mt-4">
                                        <span>{`${header} (${this.state.gigCount})`}</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 col-lg-2 mb-4">
                                    <button className="btn btn-primary btn--large" onClick={this.onRefreshClick}>
                                        Refresh
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <SimpleGrid
                                        setSummary={this.setSummary}
                                        setParentButtonStatesSignal={this.setButtonStates}
                                        refreshSignal={this.state.refreshGrid}
                                        location={this.props.location}
                                        history={this.props.history}
                                        setGigCount={this.setTotalGigsCounter}
                                        gridType={"Gig"}
                                        setIndicatorStates={this.setIndicatorStates}
                                        paymentId=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(GigListPage);
