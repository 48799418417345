import { faSolarSystem } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RaiseQueryFormData } from "../../services/AddEditPopUpService";
import CostReportService from "../../services/CostReportService";
import { ArtistConfirmRequest, ReConfirmRequest, TaggedRequest, VenueConfirmRequest } from "../../services/FindAnArtistService";
import QueryService from "../../services/QueryService";
import SimpleGridService from "../../services/SimpleGridService";
import CostReportUtils from "../CostReports/CostReportUtils";
import FindAnArtistUtils from "../FindAnArtist/FindAnArtistUtils";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import sharedUtils from "../grid/sharedUtilities";
import { NullableDate } from "../PostApproval/PostApprovalUtils";
import queryUtils, { QueryFormObject } from "../Query/QueryUtils";

export const FadSquare = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" {...props}>
        <path
            d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-16 368a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V112a16 16 0 0 1 16-16h288a16 16 0 0 1 16 16z"
            fill="rgb(110, 188, 189)"
        />
        <path
            d="M64 400V112a16 16 0 0 1 16-16h288a16 16 0 0 1 16 16v288a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16z"
            fill="rgba(200, 200, 200, 0.4)"
        />
    </svg>
);

export interface BaseActionCellProps extends WithTranslation {
    data: any;
    approvalSuccess?: boolean;
    approvalResponse?: any;
    dataId?: any;
    getRequestGridStatusUpdate?: (flag: boolean) => void; 
    getUpdate?: (flag: boolean) => void;  
    onRefresh?: (flag:boolean) => void;
}

export interface BaseActionCellState {
    deletableShift: boolean;
    queryItem: RaiseQueryFormData;
    pendingIconVisible: boolean;
    loadPanelVisible: boolean;
    iconDisabled: boolean;
    futureShift: boolean;
    noIconToDisplay: boolean;
    disableQueryIcon: boolean;
    onQueryRaising: boolean;
    isBilledItem: boolean;
    isFinancialAdjustmentView: boolean;
    isAdjustmentChange: boolean;
    isEditIconDisable: boolean;
    statusColumnIndexVal: number;
    queryStatusIndexVal: number;
    taggedColumnIndexVal: number;
    status: string;
    queryId: number;
    showDoubleTick: boolean;
    showSingleTick: boolean;
    showFindIcon: boolean;
    showDisableIcon: boolean;
    isItemQueried: boolean;
    isShowCircle: boolean;
    isShowFilledCircle: boolean;
    rowIndex: string | null;
    prevRowIndex: string | null;
    billableItemId: string;
    onReConfirm: boolean;
    onVenueConfirm: boolean,
    onArtistConfirm: boolean;
    reConfirmRequest: ReConfirmRequest;
    artistConfirmRequest: ArtistConfirmRequest;
    venueConfirmRequest: VenueConfirmRequest;
    tagRequest: TaggedRequest;
    internalComments: string;
    aristConfirmationDate: NullableDate,
    venueConfirmationDate: NullableDate,
    reConfirmationDate: NullableDate,
    gridArtistConfirmationDate: string;
    gridArtistConfirmationStatus: boolean;
    gridVenueConfirmationDate: string;
    gridVenueConfirmationStatus: boolean;
    gridReConfirmDate: string;
    gridReConfirmStatus: boolean;
    gridTaggedTo: string;
    gridTaggedStatus: boolean;
    errorMessage: [];
    onOptionsSentConfirm: boolean;
    optionsSentConfirmDate: NullableDate;
    gridOptionsSentDate: string;
    gridOptionsConfirmStatus: boolean;
    isQueriedDisable: boolean;
    afterQueryRaised: boolean;
    paymentQueryDescription: string;
    paymentResolveQueryDescription: string;
    queryFormData: QueryFormObject;
    queryDateCreated: string;
    isToastConfigVisible: boolean;
    toastConfigMessage: string;
    payDateStatusColumnIndexVal: number;
    shiftMatrixStatusVal: string;
}

class BaseActionCell extends React.Component<BaseActionCellProps> {
    status: string;
    state: BaseActionCellState;
    Service: SimpleGridService;
    queryService: CostReportService;
    utils: addEditPopupUtils;
    costReportUtils: CostReportUtils;
    sharedUtils: sharedUtils;
    findAnArtistUtils: FindAnArtistUtils;
    resolvedQueryService: QueryService;
    queryUtils: queryUtils;
    constructor(props: BaseActionCellProps) {
        super(props);
        this.Service = new SimpleGridService();
        this.resolvedQueryService = new QueryService();
        this.queryService = new CostReportService();
        this.utils = new addEditPopupUtils();
        this.costReportUtils = new CostReportUtils();
        this.sharedUtils = new sharedUtils();
        this.findAnArtistUtils = new FindAnArtistUtils();

        this.status = this.props.data.data.statusValue;
        this.queryUtils = new queryUtils();
        var convertedQueryFormData = this.utils.initializeQueryFormData(
            props.data.data && props.data.data.billableItemId ? props.data.data.billableItemId.toString() : "",
            "",
            "",
            "Shift / Item Status Query"
        );
        let convertedQueryViewDataItem = this.queryUtils.initializeQueryItem(
            ""
        );
        let convertedArtistRequestData = this.findAnArtistUtils.initializeArtistConfirmData();
        let convertedVenueConfirmRequestData = this.findAnArtistUtils.initializeVenueConfirmData();
        let convertedReConfirmRequestData = this.findAnArtistUtils.initializeReConfirmData();
        let convertedTaggedRequestData = this.findAnArtistUtils.initializeTaggedData();
        this.state = {
            deletableShift: false,
            queryItem: convertedQueryFormData,
            pendingIconVisible: false,
            loadPanelVisible: false,
            iconDisabled: false,
            futureShift: false,
            noIconToDisplay: false,
            disableQueryIcon: false,
            onQueryRaising: false,
            isBilledItem: false,
            isFinancialAdjustmentView: false,
            isAdjustmentChange: false,
            isEditIconDisable: false,
            status: this.props.data.data.statusValue,
            statusColumnIndexVal: 0,
            payDateStatusColumnIndexVal: 0,
            queryStatusIndexVal: 0,
            taggedColumnIndexVal: 0,
            queryId: 0,
            showDoubleTick: false,
            showSingleTick: false,
            showFindIcon: false,
            showDisableIcon: false,
            isItemQueried: false,
            isShowCircle: false,
            isShowFilledCircle: false,
            rowIndex: null,
            prevRowIndex: null,
            billableItemId: "",
            onReConfirm: false,
            onVenueConfirm: false,
            onArtistConfirm: false,
            reConfirmRequest: convertedReConfirmRequestData,
            artistConfirmRequest: convertedArtistRequestData,
            venueConfirmRequest: convertedVenueConfirmRequestData,
            tagRequest: convertedTaggedRequestData,
            internalComments: this.props.data.data.internalComments,
            aristConfirmationDate: this.props.data.data.artistConfirmDateTime ? this.sharedUtils.convertStringToDate(this.props.data.data.artistConfirmDateTime) : new Date(),
            venueConfirmationDate: this.props.data.data.venueConfirmDateTime ? this.sharedUtils.convertStringToDate(this.props.data.data.venueConfirmDateTime) : new Date(),
            reConfirmationDate: this.props.data.data.reConfirmDateTime ? this.sharedUtils.convertStringToDate(this.props.data.data.reConfirmDateTime) : new Date(),
            gridArtistConfirmationDate: this.props.data.data.artistConfirmDateTime,
            gridArtistConfirmationStatus: this.props.data.data.artistConfirm,
            gridVenueConfirmationDate: this.props.data.data.venueConfirmDateTime,
            gridVenueConfirmationStatus: this.props.data.data.venueConfirm,
            gridReConfirmDate: this.props.data.data.reConfirmDateTime,
            gridReConfirmStatus: this.props.data.data.reConfirm,
            gridTaggedTo: this.props.data.data.taggedUser ? this.props.data.data.taggedUser.name : "",
            gridTaggedStatus: this.props.data.data.taggedUser && this.props.data.data.taggedUser.name ? true : false,
            errorMessage: [],
            onOptionsSentConfirm: false,
            optionsSentConfirmDate: this.props.data.data.optionsSentDateTime ? this.sharedUtils.convertStringToDate(this.props.data.data.optionsSentDateTime) : new Date(),
            gridOptionsSentDate: this.props.data.data.optionsSentDateTime,
            gridOptionsConfirmStatus: this.props.data.data.optionsSent,
            isQueriedDisable: false,
            afterQueryRaised: false,
            paymentQueryDescription: "",
            paymentResolveQueryDescription: "",
            queryFormData: convertedQueryViewDataItem,
            queryDateCreated: "",
            isToastConfigVisible: false,
            toastConfigMessage: "",
            shiftMatrixStatusVal: this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value,
        };
    }

    showLoadPanel = () => {
        this.setState({
            loadPanelVisible: true,
        });
    };

    hideLoadPanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    setLoadIndicatorVisibility(isVisible: boolean): void {
        this.setState({
            loadPanelVisible: isVisible,
        });
    }

}

export default BaseActionCell;