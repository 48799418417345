import axios, { AxiosResponse } from "axios";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getAppInsights } from "../components/AppInsights/TelemetryService";
import { HandleRequestForCaching, HandleResponseForCaching } from "./CacheAPIInterceptor";
import { HandleResponseForError } from "./APIErrorInterceptor";
import { getAccessToken } from "./AuthService";

const SUBSCRIPTION_KEY_VALUE = process.env.SUBSCRIPTION_KEY_VALUE;
const APIM_BASE_URL = process.env.APIM_BASE_URL;
const APIM_BATCH_BASE_URL = process.env.APIM_BATCH_BASE_URL;
const ENABLE_CLIENT_CACHING = process.env.ENABLE_CLIENT_CACHING;

interface ServiceLoaded<T> {
    status: "loaded";
    payload: T[];
}
interface ServiceError {
    status: "error";
    error: Error;
}
interface ServiceInit {
    status: "init";
}
interface ServiceLoading {
    status: "loading";
}
export type BaseApiService<T> = ServiceInit | ServiceLoading | ServiceLoaded<T> | ServiceError;

const HeaderTypes = {
    Standard: 0,
    FormData: 1,
};

if (ENABLE_CLIENT_CACHING === "true") {
    // For any GET request, return the cached version from local storage
    // This approach is used as we cannot set expiry headers on the responses
    // that contain auth headers (see https://docs.microsoft.com/en-us/aspnet/core/performance/caching/response?view=aspnetcore-5.0)
    axios.interceptors.request.use(
        (request) => HandleRequestForCaching(request),
        (error) => Promise.reject(error)
    );

    // For any GET response store a cached version in local storage
    // Expiry data is configured in seconds using
    // This approach is used as we cannot set expiry headers on the responses
    // that contain auth headers (see https://docs.microsoft.com/en-us/aspnet/core/performance/caching/response?view=aspnetcore-5.0)
    axios.interceptors.response.use(
        (response) => HandleResponseForCaching(response),
        (error) => Promise.reject(error)
    );
}

axios.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => HandleResponseForError(error)
);


// Get the access token and build the header needed for every API call
const buildHeader = async (headerType: number) => {
    let header = {
        Authorization: "",
        "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY_VALUE,
        enctype: "",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        "x-custom-correlation-id": uuidv4(),
    };

    try {
        // Critical that this function returns the header before making the api call
        const tokenResponse = await getAccessToken();
        // If a token was found, tokenResponse will be the token as a string
        if (tokenResponse && typeof tokenResponse === "string" && tokenResponse.length > 0) {
            // Set the token
            header.Authorization = `Bearer ${tokenResponse}`;
            // Set the content type
            switch (headerType) {
                case HeaderTypes.Standard:
                    header.enctype = "application/x-www-form-urlencoded";
                    break;
                case HeaderTypes.FormData:
                    header.enctype = "multipart/form-data";
                    break;
            }
        }
    } catch (err) {
        console.warn(`getAccessToken failed: ${JSON.stringify(err)}`);
        throw err;
    }

    return header;
};

////////////////////////////////////////////////////////
// Generalized REST functions
//      - Intended to return a promise, only async in order to await headers before makeing server request.
//      - Will automatically provide the required headers
//
// GET - will perform a get request with the exact URI provided, without baseURI
export async function _getAbsolute(endpointURL: string): Promise<AxiosResponse<any>> {
    return axios.get(endpointURL);
}
// GET - will perform a get request with the exact URI provided
export async function _get(endpointURL: string): Promise<AxiosResponse<any>> {
    const headers = await buildHeader(HeaderTypes.Standard);
    return axios.get(APIM_BASE_URL + endpointURL, { headers: headers });
}

// POST - will perform a post request with the provided URI and data object
export async function _post(endpointURL: string, data?: object): Promise<AxiosResponse<any>> {
    const headers = await buildHeader(HeaderTypes.Standard);
    return axios.post(APIM_BASE_URL + endpointURL, data, { headers: headers });
}

// PUT - will perform a put request with the provided URI and data object
export async function _put(endpointURL: string, data?: object): Promise<AxiosResponse<any>> {
    const headers = await buildHeader(HeaderTypes.Standard);
    return axios.put(APIM_BASE_URL + endpointURL, data, { headers: headers });
}
// POST - will perform a post request to trigger the Logic app at certain points of the Billing/Payment cycle.
export async function _postBatchRequest(endpointURL: string, data?: object): Promise<AxiosResponse<any>> {
    const headers = await buildHeader(HeaderTypes.Standard);
    return axios.post(APIM_BATCH_BASE_URL + endpointURL, data, {
        headers: headers,
    });
}
// POST with FormData - will perform a post request with the form data provided and attached the multipart/form-data header
export async function _postFormData(endpointURL: string, formData: FormData): Promise<AxiosResponse<any>> {
    const headers = await buildHeader(HeaderTypes.FormData);
    return axios.post(APIM_BASE_URL + endpointURL, formData, {
        headers: headers,
    });
}
// Delete - will perform a Delete request with the exact URI provided
export async function _delete(endpointURL: string): Promise<AxiosResponse<any>> {
    const headers = await buildHeader(HeaderTypes.Standard);
    return axios.delete(APIM_BASE_URL + endpointURL, { headers: headers });
}
//Download Functionality
export async function _setURL(endpointURL: string): Promise<AxiosResponse<any>> {
    const headers = await buildHeader(HeaderTypes.Standard);
    return axios.get(APIM_BASE_URL + endpointURL, {
        headers: headers,
        responseType: "blob",
    });
}
/**
 * POST - will perform a post request with the provided URI and data object
 *
 * @param endpointURL {string} - endpoint
 * @param data {number[]} - data to include in POST body
 * @returns {Promise<AxiosResponse<any>>} - server response
 */
export async function _postArray(endpointURL: string, data?: number[]): Promise<AxiosResponse<any>> {
    const headers = await buildHeader(HeaderTypes.Standard);
    return axios.post(APIM_BASE_URL + endpointURL, data, { headers: headers });
}
/**
 * Helper function to prevent every component from including this standard trace log when a promise returns an error.
 *
 * @param message {string}
 * @returns {boolean} - if appInsights failed to load, allow app to do something else, like log to console.
 */
export function _aiTraceAsError(message: string): boolean {
    var appInsights = getAppInsights();
    var success = false;

    if (appInsights) {
        appInsights.trackTrace({
            message: message,
            severityLevel: SeverityLevel.Error,
        });

        success = true;
    }

    return success;
}
function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
