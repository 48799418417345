import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Lookup,
    PatternRule,
    SearchPanel,
    StateStoring,
    Export
} from "devextreme-react/data-grid";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import MatrixService, { RateMatrixGridRowItem } from "../../services/MatrixService";
import lookupService, { LookupTypeIndexes } from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import gridUtils from "../grid/GridUtilities";
import {
    OnRowUpdatingEvent,
    OnRowInsertingEvent,
    OnRowRemovingEvent,
    onEditCanceledEvent,
} from "../../types/DevExtremeTypes";
import periodMatrixFormUtil from "./PeriodMatrixFormUtil";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import CustomStore from 'devextreme/data/custom_store';
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";

//props
interface RateMatrixGridProps {}

export interface RateMatrixAddEditObjectItem {
    rateMatrixId: number;
    providerBusinessEntityId: number;
    clientBusinessEntityId: number;
    serviceTypeLookUpId: number;
    serviceSubTypeLookUpId: number;
    typeLookUpId: number;
    venueId: string;
    idTypeLookUpId: string;
    idValue: string;
    rate_Lower: number;
    rate_Higher: number;
    comment: string;
    name: string;
}
export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}
// State
interface RateMatrixGridState {
    rateGridDataSource: RateMatrixGridRowItem[];
    showProgressIndicator: boolean;
    clientLookUp: LookupTypeItemGrid[];
    serviceLookUp: LookupTypeItemGrid[];
    subServiceLookUp: LookupTypeItemGrid[];
    roleLookUp: LookupTypeItemGrid[];
    venueLookUp: LookupTypeItemGrid[];
    licenseTypeLookUp: LookupTypeItemGrid[];
    providerLookUp: LookupTypeItemGrid[];
    rateMatrixAddEditObject: RateMatrixAddEditObjectItem;
    errorMessage: any[];
    loadPanelVisible: boolean;
    clientLookUpAll: LookupTypeItemGrid[];
    venueLookUpAll: LookupTypeItemGrid[];
    providerLookUpAll: LookupTypeItemGrid[];
    active: boolean;
}

// Component - displays the Rate Matrix Grid
class RateMatrixGrid extends React.Component<RateMatrixGridProps> {
    //Initialize the component's state.
    state: RateMatrixGridState;
    rateService: MatrixService;
    sharedUtils: sharedUtils;
    dropdownService: lookupService;
    utils: periodMatrixFormUtil;
    gridUtils: gridUtils;
    constructor(props: RateMatrixGridProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.rateService = new MatrixService();
        this.dropdownService = new lookupService();
        this.utils = new periodMatrixFormUtil();
        this.gridUtils = new gridUtils();
        // Initialize state and services/utils
        this.state = {
            rateGridDataSource: [],
            showProgressIndicator: false,
            clientLookUp: [],
            clientLookUpAll: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            roleLookUp: [],
            venueLookUp: [],
            venueLookUpAll: [],
            licenseTypeLookUp: [],
            providerLookUp: [],
            providerLookUpAll: [],
            rateMatrixAddEditObject: {
                rateMatrixId: 0,
                providerBusinessEntityId: 0,
                clientBusinessEntityId: 0,
                serviceTypeLookUpId: 0,
                serviceSubTypeLookUpId: 0,
                typeLookUpId: 0,
                venueId: "",
                idTypeLookUpId: "",
                idValue: "",
                rate_Lower: 0,
                rate_Higher: 0,
                comment: "",
                name: "",
            },
            errorMessage: [],
            loadPanelVisible: false,
            active: true,
        };
    }

    componentDidMount() {
        this.dropDownDataSource();
        this.rateMatrixDataSource();
    }

    dropDownDataSource = () => {
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
            .then(this.handleClientLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndexAll(LookupTypeIndexes.clientType)
            .then(this.handleClientLUAllSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
            .then(this.handleServiceLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.subServiceType)
            .then(this.handleSubServiceLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.offeringsType)
            .then(this.handleRoleLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndexAll(LookupTypeIndexes.venueType)
            .then(this.handleVenueLUAllSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.identificationType)
            .then(this.handleLicenseLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.providerType)
            .then(this.handleProviderLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndexAll(LookupTypeIndexes.providerType)
            .then(this.handleProviderLUAllSuccess)
            .catch(this.handleError);
    };

    rateMatrixDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.rateService.getRateMatrixDataItem().then(this.handleSuccess).catch(this.handleError);
    };

    lookUpCreationSelect = (response: AxiosResponse<any>): LookupTypeItemGrid[] => {
        // var itemLUItem: LookupTypeItemGrid;
        // var itemLUItemArray: LookupTypeItemGrid[];
        // itemLUItemArray = [];
        // response.data.data.map((item: any, uniqueKey: number) => {
        //     itemLUItem = {
        //         id: parseInt(item.id),
        //         value: item.value,
        //         parentMappingId: item.parentMappingId,
        //     };
        //     itemLUItemArray.push(itemLUItem);
        // });
        // return itemLUItemArray;
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        var select: LookupTypeItemGrid = {
            id: 0,
            value: "select",
            parentMappingId: "",
        };
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: parseInt(item.id),
                value: item.value,
                parentMappingId: item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return [select].concat(itemLUItemArray);
    };

    lookUpCreation = (response: AxiosResponse<any>): LookupTypeItemGrid[] => {
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: parseInt(item.id),
                value: item.value,
                parentMappingId: item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };

    handleProviderLUSuccess = (response: AxiosResponse<any>) => {
        var providerDropDown = this.lookUpCreationSelect(response);
        this.setState({
            providerLookUp: providerDropDown,
        });
    };

    handleProviderLUAllSuccess = (response: AxiosResponse<any>) => {
        var providerDropDown = this.lookUpCreationSelect(response);
        this.setState({
            providerLookUpAll: providerDropDown,
        });
    };

    handleClientLUSuccess = (response: AxiosResponse<any>) => {
        var clientDropDown = this.lookUpCreationSelect(response);
        this.setState({
            clientLookUp: clientDropDown,
        });
    };

    handleClientLUAllSuccess = (response: AxiosResponse<any>) => {
        var clientDropDown = this.lookUpCreationSelect(response);
        this.setState({
            clientLookUpAll: clientDropDown,
        });
    };

    handleServiceLUSuccess = (response: AxiosResponse<any>) => {
        var serviceDropDown = this.lookUpCreation(response);
        this.setState({
            serviceLookUp: serviceDropDown,
        });
    };

    handleSubServiceLUSuccess = (response: AxiosResponse<any>) => {
        var subServiceDropDown = this.lookUpCreation(response);
        this.setState({
            subServiceLookUp: subServiceDropDown,
        });
    };

    handleRoleLUSuccess = (response: AxiosResponse<any>) => {
        var roleDropDown = this.lookUpCreation(response);
        this.setState({
            roleLookUp: roleDropDown,
        });
    };

    handleVenueLUSuccess = (response: AxiosResponse<any>) => {
        var venueDropDown = this.lookUpCreation(response);
        this.setState({
            venueLookUp: venueDropDown,
        });
    };

    handleVenueLUAllSuccess = (response: AxiosResponse<any>) => {
        var venueDropDown = this.lookUpCreation(response);
        this.setState({
            venueLookUpAll: venueDropDown,
        });
    };

    handleLicenseLUSuccess = (response: AxiosResponse<any>) => {
        var licenseTypeDropDown = this.lookUpCreationSelect(response);
        this.setState({
            licenseTypeLookUp: licenseTypeDropDown,
        });
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            rateGridDataSource: response.data.data,
            showProgressIndicator: false,
            loadPanelVisible: false,
        });
    };

    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
            loadPanelVisible: false,
        });
        var respMessage: string = "Rate service failed with response: " + JSON.stringify(error);

        if (!this.rateService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    onRowUpadating = (e: OnRowUpdatingEvent) => {
        var typeId: string =
            e.newData.idTypeLookUpId == undefined ? e.oldData.idTypeLookUpId : e.newData.idTypeLookUpId;
        var typeValue: string = e.newData.idValue == undefined ? e.oldData.idValue : e.newData.idValue;
        var name: string = e.newData.name == undefined ? e.oldData.name : e.newData.name;
        this.setState({
            loadPanelVisible: true,
            rateMatrixAddEditObject: {
                ...this.state.rateMatrixAddEditObject,
                rateMatrixId: e.oldData.rateMatrixId,
                providerBusinessEntityId:
                    e.newData.providerBusinessEntityId ||
                    e.newData.providerBusinessEntityId === null ||
                    e.newData.providerBusinessEntityId === 0 ||
                    e.newData.providerBusinessEntityId === ""
                        ? e.newData.providerBusinessEntityId !== 0
                            ? e.newData.providerBusinessEntityId
                            : null
                        : e.oldData.providerBusinessEntityId !== 0
                        ? e.oldData.providerBusinessEntityId
                        : null,
                clientBusinessEntityId:
                    e.newData.clientBusinessEntityId ||
                    e.newData.clientBusinessEntityId === null ||
                    e.newData.clientBusinessEntityId === 0 ||
                    e.newData.clientBusinessEntityId === ""
                        ? e.newData.clientBusinessEntityId !== 0
                            ? e.newData.clientBusinessEntityId
                            : null
                        : e.oldData.clientBusinessEntityId !== 0
                        ? e.oldData.clientBusinessEntityId
                        : null,
                serviceTypeLookUpId:
                    e.newData.serviceTypeLookUpId ||
                    e.newData.serviceTypeLookUpId === null ||
                    e.newData.serviceTypeLookUpId === ""
                        ? e.newData.serviceTypeLookUpId
                        : e.oldData.serviceTypeLookUpId,
                serviceSubTypeLookUpId:
                    e.newData.serviceSubTypeLookUpId ||
                    e.newData.serviceSubTypeLookUpId === null ||
                    e.newData.serviceSubTypeLookUpId === ""
                        ? e.newData.serviceSubTypeLookUpId
                        : e.oldData.serviceSubTypeLookUpId,
                typeLookUpId:
                    e.newData.typeLookUpId || e.newData.typeLookUpId === null || e.newData.typeLookUpId === ""
                        ? e.newData.typeLookUpId
                        : e.oldData.typeLookUpId,
                venueId:
                    e.newData.venueId ||
                    e.newData.venueId === null ||
                    e.newData.venueId === 0 ||
                    e.newData.venueId === ""
                        ? e.newData.venueId !== 0
                            ? e.newData.venueId
                            : null
                        : e.oldData.venueId !== 0
                        ? e.oldData.venueId
                        : null,
                idTypeLookUpId:
                    e.newData.idTypeLookUpId ||
                    e.newData.idTypeLookUpId === null ||
                    e.newData.idTypeLookUpId === 0 ||
                    e.newData.idTypeLookUpId === ""
                        ? e.newData.idTypeLookUpId !== 0
                            ? e.newData.idTypeLookUpId
                            : null
                        : e.oldData.idTypeLookUpId !== 0
                        ? e.oldData.idTypeLookUpId
                        : null,
                idValue:
                    e.newData.idValue || e.newData.idValue === null || e.newData.idValue === ""
                        ? e.newData.idValue
                        : e.oldData.idValue,
                rate_Lower:
                    e.newData.rate_Lower || e.newData.rate_Lower === null || e.newData.rate_Lower === ""
                        ? e.newData.rate_Lower
                        : e.oldData.rate_Lower,
                rate_Higher:
                    e.newData.rate_Higher || e.newData.rate_Higher === null || e.newData.rate_Higher === ""
                        ? e.newData.rate_Higher
                        : e.oldData.rate_Higher,
                comment:
                    e.newData.comment || e.newData.comment === null || e.newData.comment === ""
                        ? e.newData.comment
                        : e.oldData.comment,
                name:
                    e.newData.name || e.newData.name === null || e.newData.name === ""
                        ? e.newData.name
                        : e.oldData.name,
            },
        });

        this.rateService
            .saveRateMatrixDataItem(this.state.rateMatrixAddEditObject)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch((err) => {
                this.setState({
                    loadPanelVisible: false,
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                });
            });

        e.cancel = this.addEditCancel(e, typeId, typeValue, name);
    };

    addEditCancel = (e: any, typeId: any, typeValue: any, name: any) => {
        var cancel: boolean = false;
        var addEditCancelled: boolean = this.utils.nameValidation(typeId, typeValue, name);
        if (addEditCancelled && this.state.errorMessage.length > 0) {
            cancel = false;
            this.setState({
                errorMessage: [],
            });
        } else {
            cancel = true;
            this.setState({
                errorMessage: [],
            });
        }
        return cancel;
    };

    onRowUpdatedInsertedDeleted = () => {
        this.setState({
            // loadPanelVisible: false,
            errorMessage: [],
        });
        this.rateService.getRateMatrixDataItem().then(this.handleSuccess).catch(this.handleError);
    };

    onRowInserting = (e: OnRowInsertingEvent) => {
        this.setState({
            active: true,
            loadPanelVisible: true,
            rateMatrixAddEditObject: {
                ...this.state.rateMatrixAddEditObject,
                rateMatrixId: 0,
                providerBusinessEntityId:
                    e.data.providerBusinessEntityId !== 0 ? e.data.providerBusinessEntityId : null,
                clientBusinessEntityId: e.data.clientBusinessEntityId !== 0 ? e.data.clientBusinessEntityId : null,
                serviceTypeLookUpId: e.data.serviceTypeLookUpId ? e.data.serviceTypeLookUpId : null,
                serviceSubTypeLookUpId: e.data.serviceSubTypeLookUpId ? e.data.serviceSubTypeLookUpId : null,
                typeLookUpId: e.data.typeLookUpId ? e.data.typeLookUpId : null,
                venueId: e.data.venueId !== 0 ? e.data.venueId : null,
                idTypeLookUpId: e.data.idTypeLookUpId !== 0 ? e.data.idTypeLookUpId : null,
                idValue: e.data.idValue ? e.data.idValue : "",
                rate_Lower: e.data.rate_Lower ? parseFloat(e.data.rate_Lower) : 0.0,
                rate_Higher: e.data.rate_Higher ? parseFloat(e.data.rate_Higher) : 0.0,
                comment: e.data.comment ? e.data.comment : "",
                name: e.data.name ? e.data.name : "",
            },
        });

        this.rateService
            .saveRateMatrixDataItem(this.state.rateMatrixAddEditObject)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch((err) => {
                this.setState({
                    loadPanelVisible: false,
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                });
            });
        // e.cancel = this.addEditCancel(
        //     e,
        //     this.state.rateMatrixAddEditObject.idTypeLookUpId,
        //     this.state.rateMatrixAddEditObject.idValue,
        //     this.state.rateMatrixAddEditObject.name
        // );
    };

    onRowRemoving = (e: OnRowRemovingEvent) => {
        this.rateService
            .deleteRateMatrixDataItem(e.data.rateMatrixId)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch(this.handleError);
    };

    getFilteredVenueType = (options: any) => {
        var itemLUItemSelect = {
            id: 0,
            value: "select",
            parentMappingId: "",
        };
        var itemLUItem: LookupTypeItemGrid[];
        var data = this.state.venueLookUpAll;
        itemLUItem = options.data
            ? data.filter((item) => item.parentMappingId == options.data.clientBusinessEntityId)
            : data;
        //return [itemLUItemSelect].concat(itemLUItem);

        // let obj = {
        //     store: {
        //         type: 'array',
        //         data: [itemLUItemSelect].concat(itemLUItem)
        //     },
        //     pageSize: 10,
        //     paginate: true
        // }
        let obj = {
            store: new CustomStore({
                //key: "id",
                loadMode: "raw",
                load: () => {
                    return [itemLUItemSelect].concat(itemLUItem);
                }
            }),
            // sort: "name",
            pageSize: 10,
            paginate: true
        };
        return obj;

    };

    getFilteredSubService = (options: any) => {
        return {
            store: this.state.subServiceLookUp,
            filter: options.data ? ["parentMappingId", "=", options.data.serviceTypeLookUpId] : null,
        };
    };

    getFilteredRoleType = (options: any) => {
        return {
            store: this.state.roleLookUp,
            filter: options.data ? ["parentMappingId", "=", options.data.serviceSubTypeLookUpId] : null,
        };
    };

    setClientCellValue = (rowData: any, value: any) => {
        rowData.venueId = null;
        rowData.clientBusinessEntityId = value === 0 ? null : value;
    };

    setServiceCellValue = (rowData: any, value: any) => {
        rowData.serviceSubTypeLookUpId = null;
        rowData.typeLookUpId = null;
        rowData.serviceTypeLookUpId = value;
    };

    setSubServiceCellValue = (rowData: any, value: any) => {
        rowData.typeLookUpId = null;
        rowData.serviceSubTypeLookUpId = value;
    };

    onEditCancelled = (e: onEditCanceledEvent) => {
        this.setState({
            errorMessage: [],
        });
    };

    renderErrorMessage = (errorMessage: any[]) => {
        let errorData: React.ReactNode = <></>;
        if (errorMessage) {
            errorData = (
                <span className="unscheduled-shift">
                    <ul>
                        {errorMessage.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    {item.columnName}: {item.errorMessage}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            );
        }
        return errorData;
    };
    onRowEditting = () => {
        this.setState({
            active: false,
        });
    };
    onRowAdding = () => {
        this.setState({
            active: true,
        });
    };
    setProviderCellValue = (rowData: any, value: any) => {
        rowData.providerBusinessEntityId = value === 0 ? null : value;
    };
    setVenueCellValue = (rowData: any, value: any) => {
        rowData.venueId = value === 0 ? null : value;
    };
    setIdTypeCellValue = (rowData: any, value: any) => {
        rowData.idTypeLookUpId = value === 0 ? null : value;
    };
    setProviderCellComponent = (cellInfo: any) => {
        return (
            <div>
                {cellInfo.data.providerBusinessEntity}
                {"  "}
                {cellInfo.data.isValidateProvider !== null ? (
                    cellInfo.data.isValidateProvider === true ? (
                        <span className="fa-lg">
                            <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
                        </span>
                    ) : (
                        <span className="fa-lg">
                            <FontAwesomeIcon style={{ color: "red" }} icon={faTimes} />
                        </span>
                    )
                ) : null}
            </div>
        );
    };
    onCustomGridLoad() {
        return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.rateMatrixGrid);
    }

    onCustomGridSave(gridState: Object) {
        CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.rateMatrixGrid, gridState);
    }

    // getlookupDataSourceConfig1 = () => {
    //     let obj = {
    //         store: {
    //             type: 'array',
    //             data: this.state.active ? this.state.providerLookUp : this.state.providerLookUpAll
    //         },
    //         pageSize: 10,
    //         paginate: true
    //     }
    //     return obj;
    // }
    getlookupDataSourceConfig = () =>{
        let config = {
            store: new CustomStore({
                //key: "id",
                loadMode: "raw",
                load: () => {
                    return this.state.active ? this.state.providerLookUp : this.state.providerLookUpAll;
                }
            }),
            // sort: "name",
            pageSize: 10,
            paginate: true
        };
        return config;
    }

    onEditorPreparing = (editEvent: any) => {
        if (editEvent.parentType === "filterRow" && 
        (editEvent.dataField === "providerBusinessEntityId" || 
        editEvent.dataField === "clientBusinessEntityId" ||
        editEvent.dataField === "serviceTypeLookUpId" ||
        editEvent.dataField === "serviceSubTypeLookUpId" ||
        editEvent.dataField === "typeLookUpId" ||
        editEvent.dataField === "venueId" ||
        editEvent.dataField === "idTypeLookUpId")
        ) {
            editEvent.editorName = "dxTextBox"
            editEvent.editorOptions.valueChangeEvent = "keyup";
        }
    }

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    calculateSortValue(this:any,data:any) {
        if(this){
            if(this.dataField =='providerBusinessEntityId'){
                return data.providerBusinessEntity;
            } else if(this.dataField =='clientBusinessEntityId'){
                return data.clientBusinessEntity;
            } else if(this.dataField =='serviceTypeLookUpId'){
                return data.serviceTypeLookUp;
            } else if(this.dataField =='serviceSubTypeLookUpId'){
                return data.serviceSubTypeLookUp;
            } else if(this.dataField =='typeLookUpId'){
                return data.typeLookUp;
            } else if(this.dataField =='venueId'){
                return data.venue;
            } else if(this.dataField =='idTypeLookUpId'){
                return data.idTypeLookUp;
            }
        }
        return;
    }

    calculateFilterExpression(this:any,filterValue:any, selectedFilterOperation:any, target:any){

        if (target == "filterRow") {
            if(selectedFilterOperation){
                //let filterExpression = ['legalName',selectedFilterOperation,filterValue]
                if(this.dataField =='providerBusinessEntityId'){
                   return[['providerBusinessEntity',selectedFilterOperation,filterValue]];
                } else if(this.dataField == 'clientBusinessEntityId'){
                    return[['clientBusinessEntity',selectedFilterOperation,filterValue]]
                 } else if(this.dataField == 'serviceTypeLookUpId'){
                    return[['serviceTypeLookUp',selectedFilterOperation,filterValue]]
                 } else if(this.dataField =='serviceSubTypeLookUpId'){
                    return[['serviceSubTypeLookUp',selectedFilterOperation,filterValue]]
                } else if(this.dataField =='typeLookUpId'){
                    return[['typeLookUp',selectedFilterOperation,filterValue]]
                } else if(this.dataField =='venueId'){
                    return[['venue',selectedFilterOperation,filterValue]]
                } else if(this.dataField =='idTypeLookUpId'){
                    return[['idTypeLookUp',selectedFilterOperation,filterValue]]
                }
                //return [filterExpression];
            }
            return this.defaultCalculateFilterExpression?.apply(this, arguments);
        }
        return this.defaultCalculateFilterExpression?.apply(this, arguments);
    }
    
    
    render() {
        var { errorMessage, loadPanelVisible } = this.state;
        return (
            <div>
                {this.renderErrorMessage(errorMessage)}
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={loadPanelVisible} />
                        <DataGrid
                            dataSource={this.state.rateGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            onRowUpdating={this.onRowUpadating}
                            onRowInserting={this.onRowInserting}
                            onRowRemoving={this.onRowRemoving}
                            onEditCanceling={this.onEditCancelled}
                            onEditingStart={this.onRowEditting}
                            onInitNewRow={this.onRowAdding}
                            onEditorPreparing={this.onEditorPreparing}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            onExporting={this.onExporting}
                        >
                            <Export enabled={true} />
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Sorting mode="single" />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <Editing
                                mode="row"
                                useIcons={true}
                                allowAdding={true}
                                allowUpdating={true}
                                allowDeleting={true}
                            />
                            <StateStoring
                                enabled={true}
                                type="custom"
                                customLoad={this.onCustomGridLoad}
                                customSave={this.onCustomGridSave}
                                savingTimeout={0}
                            />
                            <Column type="buttons" buttons={["edit", "delete"]} />
                            <Column dataField="rateMatrixId" allowSorting={true} caption="ID" allowEditing={false} calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.rateMatrixId);
                            }} cssClass='grid-column-padding'/>
                            <Column
                                dataField="providerBusinessEntityId"
                                allowSorting={true}
                                caption="PROVIDER"
                                setCellValue={this.setProviderCellValue}
                                cellRender={this.setProviderCellComponent}
                                filterOperations={[ "contains", "notcontains", "startswith", "endswith", "=", "<>" ]}
                                calculateFilterExpression={this.calculateFilterExpression}
                                calculateSortValue={this.calculateSortValue}
                                dataType="string"
                            >
                                <Lookup
                                    // dataSource={
                                    //     this.state.active ? this.state.providerLookUp : this.state.providerLookUpAll
                                    // }
                                    dataSource={this.getlookupDataSourceConfig}
                                    displayExpr="value"
                                    valueExpr="id"
                                />
                            </Column>
                            <Column
                                dataField="clientBusinessEntityId"
                                allowSorting={true}
                                caption="CLIENT"
                                setCellValue={this.setClientCellValue}
                                filterOperations={[ "contains", "notcontains", "startswith", "endswith", "=", "<>" ]}
                                calculateFilterExpression={this.calculateFilterExpression}
                                calculateSortValue={this.calculateSortValue}
                                dataType="string"
                            >
                                <Lookup
                                    dataSource={
                                        this.state.active ? this.state.clientLookUp : this.state.clientLookUpAll
                                    }
                                    displayExpr="value"
                                    valueExpr="id"
                                />
                            </Column>
                            <Column
                                dataField="serviceTypeLookUpId"
                                allowSorting={true}
                                caption="SERVICE"
                                setCellValue={this.setServiceCellValue}
                                filterOperations={[ "contains", "notcontains", "startswith", "endswith", "=", "<>" ]}
                                calculateFilterExpression={this.calculateFilterExpression}
                                calculateSortValue={this.calculateSortValue}
                                dataType="string"
                            >
                                <Lookup dataSource={this.state.serviceLookUp} displayExpr="value" valueExpr="id" />
                            </Column>
                            <Column
                                dataField="serviceSubTypeLookUpId"
                                allowSorting={true}
                                caption="SERVICE SUB TYPE"
                                setCellValue={this.setSubServiceCellValue}
                                filterOperations={[ "contains", "notcontains", "startswith", "endswith", "=", "<>" ]}
                                calculateFilterExpression={this.calculateFilterExpression}
                                calculateSortValue={this.calculateSortValue}
                                dataType="string"
                            >
                                <Lookup dataSource={this.getFilteredSubService} displayExpr="value" valueExpr="id" />
                            </Column>
                            <Column dataField="typeLookUpId" allowSorting={true} caption="TYPE"
                            filterOperations={[ "contains", "notcontains", "startswith", "endswith", "=", "<>" ]}
                            calculateFilterExpression={this.calculateFilterExpression}
                            calculateSortValue={this.calculateSortValue}
                            dataType="string">
                                <Lookup dataSource={this.getFilteredRoleType} displayExpr="value" valueExpr="id" />
                            </Column>
                            <Column
                                dataField="venueId"
                                allowSorting={true}
                                caption="VENUE NAME"
                                setCellValue={this.setVenueCellValue}
                                filterOperations={[ "contains", "notcontains", "startswith", "endswith", "=", "<>" ]}
                                calculateFilterExpression={this.calculateFilterExpression}
                                calculateSortValue={this.calculateSortValue}
                                dataType="string"
                            >
                                <Lookup dataSource={this.getFilteredVenueType} displayExpr="value" valueExpr="id" />
                            </Column>
                            <Column
                                dataField="idTypeLookUpId"
                                allowSorting={true}
                                caption="ID TYPE"
                                setCellValue={this.setIdTypeCellValue}
                                filterOperations={[ "contains", "notcontains", "startswith", "endswith", "=", "<>" ]}
                                calculateFilterExpression={this.calculateFilterExpression}
                                calculateSortValue={this.calculateSortValue}
                                dataType="string"
                            >
                                <Lookup dataSource={this.state.licenseTypeLookUp} displayExpr="value" valueExpr="id" />
                            </Column>
                            <Column dataField="idValue" allowSorting={true} caption="ID NO." />
                            <Column dataField="name" allowSorting={true} caption="NAME" />
                            <Column
                                dataField="rate_Lower"
                                allowSorting={true}
                                caption="LOWER LIMIT"
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                            >
                                <PatternRule
                                    message={"Lower Limit should be in two decimal place"}
                                    pattern={/^[0-9]+(\.[0-9][0-9]?)?$/}
                                />
                            </Column>
                            <Column
                                dataField="rate_Higher"
                                allowSorting={true}
                                caption="UPPER LIMIT"
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                            >
                                <PatternRule
                                    message={"Upper Limit should be in two decimal place"}
                                    pattern={/^[0-9]+(\.[0-9][0-9]?)?$/}
                                />
                            </Column>
                            <Column dataField="comment" allowSorting={true} caption="COMMENT" />
                            <Column
                                dataField="lastUpdatedOn"
                                allowSorting={true}
                                caption="LAST UPDATED ON"
                                allowEditing={false}
                            />
                            <Column
                                dataField="lastUpdatedBy"
                                allowSorting={true}
                                caption="LAST UPDATED BY"
                                allowEditing={false}
                            />
                            <FilterRow visible={true} applyFilter="auto" />
                        </DataGrid>
                    </>
                )}
            </div>
        );
    }
}

export default RateMatrixGrid;
