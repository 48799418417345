import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
//import OutstandingApprovalsCardList from "./OutstandingApprovalsCardList";
import DarkModePageTemplate from "../page/Templates/DarkModePageTemplate";
import UserService, { RoleGroupNames } from "../../services/UserService";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import PostApprovalPanel from "./PostApprovalPanel";
import PostApprovalCardList from "./PostApprovalCardList";

interface PostApprovalPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
}

interface PostApprovalPageComponentState {
    refreshCount: boolean;
    cardCount: number;
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    startDateId: string;
    endDateId: string;
    statusId: string;
}

class PostApprovalPage extends Component<PostApprovalPageComponentProps> {
    state: PostApprovalPageComponentState;
    constructor(props: PostApprovalPageComponentProps) {
        super(props);
        this.state = {
            refreshCount: false,
            cardCount: 0,
            clientId: "",
            venueId: "",
            serviceTypeId: "",
            startDateId: "",
            endDateId: "",
            statusId: ""
        };
    }


    //A parent callback function that will update the card count on the page header.
    setCardCount = (cardCount: number) => {
        this.setState({
            cardCount: cardCount,
        });
    };

    handleApplyButtonClick = (clientId: string,
        venueId: string,
        serviceId: string,
        startDateId: string,
        endDateId: string,
        statusId: string) => {
        this.setState({
            clientId: clientId ? clientId : "",
            venueId: venueId ? venueId : "",
            serviceTypeId: serviceId,
            startDateId: startDateId,
            endDateId: endDateId,
            statusId: statusId,
            cardCount: ""
        });
    };

    renderPostApprovalCardList = () => {
        return (
            <PostApprovalCardList
                serviceTypeId={this.state.serviceTypeId}
                clientId={this.state.clientId}
                venueId={this.state.venueId}
                dateRangeFrom={this.state.startDateId}
                dateRangeTo={this.state.endDateId}
                statusId={this.state.statusId}
                updateCardCount={this.setCardCount}
            />
        );
    };

    render() {
        var serviceTypeId: string | null = sessionStorage.getItem("serviceTypeFilter");
        var { cardCount } = this.state;
        //var outstandingCardsTotal: string = cardCount > -1 ? `(${cardCount})` : "";
        var header = "";
        if (cardCount) {
            header = "Approvals" + " " + "(" + cardCount + ")";
        }
        else {
            header = "Approvals";
        }
        return (
            <DarkModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <PostApprovalPanel
                        serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                        onApplyButtonClick={this.handleApplyButtonClick}
                        clientId={this.state.clientId}
                        venueId={this.state.venueId}
                        startDateId={""}
                        endDateId={""} />
                    <BulletinPanel />
                </SideBarTemplate>
                <div id="todaysView" className="page-content--with-sidebar-hidden-mobile">
                    <section className="container--no-padding">
                        <header>
                            <h2 className="page-title__lightgrey pb-2">{header}</h2>
                        </header>
                        {this.renderPostApprovalCardList()}
                    </section>
                </div>
            </DarkModePageTemplate>
        );
    }
}

export default withRouter(PostApprovalPage);
