import { AxiosResponse } from "axios";
import { _post, _get, _aiTraceAsError, _delete, _put } from "./BaseApiService";

const BILLABLE_ITEM_HEADER_API = "BillableItemHeader";
const SIA_ITEM_HEADER_API = "FileUploadHeader";
const SUBMIT_SHIFT_SCHEDULE_API = "billableitemupload/submit";
const SUBMIT_SIA_UPLOADED_FILE_API = "IdCheck/Submit";
const DELETE_FILE_UPLOAD_API = "billableitemheader";
const CHILD_GRID_ITEM_API = "billableitemupload";
const BUDGET_CHILD_GRID_ITEM_API = "BudgetUpload";
const PERIOD_MATRIX_CHILD_GRID_ITEM_API = "PeriodMatrixUpload"
const SIA_CHILD_GRID_ITEM_API = "IdCheckUpload";
const VENUE_CHILD_GRID_ITEM_API = "VenueHierarchyUpload";
const RATE_CHILD_GRID_ITEM_API = "RateMatrixUpload";
const SUBMIT_VENUE_UPLOADER_FILE_API = "VenueHierarchyUpload/Submit";
const SUBMIT_BUDGET_UPLOADER_FILE_API = "BudgetUpload/Submit";
const SUBMIT_PERIOD_MATRIX_UPLOADER_FILE_API = "PeriodMatrixUpload/Submit";
const SUBMIT_RATEMATRIX_UPLOADER_FILE_API = "RateMatrixUpload/Submit";
const MANAGE_OUR_WARNINGS_API = "BillableItemUpload/Warnings";
const UPDATE_WARNING_ROW = "BillableItemUpload/Update";
const REMOVE_PENDING_SHIFTS = "BillableItemUpload/Update/Shifts";
const BUDGET_API = "Budget";
const SAVE_BUDGET_DATA = "budget";

export const BillableItemHeaderStatuses = {
    Pending: "Pending",
    Uploaded: "Uploaded",
    InProgress: "In Progress",
    NotAccepted: "Not Accepted",
    ReadyForSubmission: "Ready For Submission",
    ReadyForSubmissionWarnings: "Ready For Submission (Warnings)",
    Failed: "Failed",
    Submitted: "Submitted",
    SubmissionInProgress: "Submission In Progress",
    postRemovalInProgress: "Post Removal In Progress",
    postRemovalFailed: "Post Removal Failed",
    postRemovalComplete: "Post Removal Complete",
    postRemovalArchived: "Archived"
};

export const TypeOfFileUploaded = {
    SIA: "SIAWatchlist",
    Shifts: "RegularShifts",
    Venue: "VenueUpload",
    BudgetUpload: "BudgetUpload",
    PeriodMatrixUpload: "PeriodMatrixUpload",
    RateMatrixUpload: "RateMatrixUpload"
};

export const fileListGridType = {
    Pending: "Pending",
    Submitted: "Submitted",
    All: "All",
    Warnings: "Warnings",
    ExcludeRate:"ExcludeRate",

};

export const fileTypeId = {
    SIA: "13",
    personnel: "11",
    equipment: "12",
    Venue: "14",
    Budget: "15",
    RateMatrix: "16",
    VenueV2: "17",
    ServiceUploader: "18",
    PeriodMatrix: "19"
};

export interface UploadFileListGridSections {
    all: BillableItemHeaderServiceRowItem[];
    newUploaded: BillableItemHeaderServiceRowItem[];
}

export interface UploadedFileListGridSections {
    All: ShiftImportChildGridRowItem[];
    Warnings: ShiftImportChildGridRowItem[];
}
export interface BillableItemHeaderServiceRowItem {
    id: string;
    fileFormatTypeLookUpId: string;
    fileFormatText: string;
    fileTypeText: string;
    fileName: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    status: string;
    errorMessage: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
}

export interface ShiftImportChildGridRowItem {
    billableItemId: string;
    billableItemUploadHdrId: string;
    billableItemUploadId: string;
    billableItemUploadStatus: string;
    client: string;
    clientBusinessEntityId: string;
    cover: string;
    dateFrom: string;
    dateTo: string;
    equipmentType: string;
    errorMessage: string;
    finish: string;
    houseName: string;
    houseNumber: string;
    idValue: string;
    lastUpdatedBy: string;
    lastUpdatedOn: string;
    licenseNumber: string;
    name: string;
    personnelType: string;
    position: string;
    provider: string;
    providerBusinessEntityId: string;
    quantity: string;
    rate: string;
    serviceSubTypeId: string;
    serviceTypeId: string;
    start: string;
}


export interface SIAChildGridRowItem {
    id: string;
    fileUploadHeaderId: string;
    idTypeLookUpId: string;
    idType: string;
    idValue: string;
    idName: string;
    expiryDate: string;
    asAtDate: string;
    errorMessageLookUpId: string;
    errorMessage: string;
    idCheckUploadStatusLookUpId: string;
    idCheckUploadStatus: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
}

export interface VenueHierarchyChildGridRowItem {
    id: string;
    fileUploadHeaderId: string;
    clientBusinessEntityId: string;
    clientBusinessEntityName: string;
    venueId: string;
    houseNumber: string;
    venueName: string;
    contactId: string;
    contactName: string;
    userId: string;
    userName: string;
    roleId: string;
    roleName: string;
    managerContactId: string;
    managerContactName: string;
    fileContentUploadStatusId: string;
    fileContentUploadStatus: string;
    errorWarningMessage: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
}

export interface BudgetUploadChildGridRowItem {
    id: string;
    fileUploadHeaderId: string;
    clientBusinessEntityId: string;
    clientBusinessEntityName: string;
    venueId: string;
    houseNumber: string;
    venueName: string;
    serviceTypeId: string;
    serviceType: string;
    periodId: string;
    periodYear: string;
    periodNumber: string;
    periodWeek: string;
    periodStart: string;
    periodEnd: string;
    amountValue: string;
    budgetAmount: string;
    fileContentUploadStatusId: string;
    fileContentUploadStatus: string;
    errorWarningMessageId: string;
    errorWarningMessage: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
}

/// REST service for the file resource.
class BillableItemHeaderService {
    submitRowData(id: string): Promise<AxiosResponse<any>> {
        return _post(SUBMIT_SHIFT_SCHEDULE_API + "?billableItemHeaderId=" + id, {});
    }

    removeRowData(submittedBillableItemIds: any[]): Promise<AxiosResponse<any>> {
        return _put(REMOVE_PENDING_SHIFTS , submittedBillableItemIds);
    }

    submitSIARowData(id: string): Promise<AxiosResponse<any>> {
        return _post(SUBMIT_SIA_UPLOADED_FILE_API + "?fileUploadHeaderId=" + id, {});
    }

    submitVenueUploaderRowData(id: string): Promise<AxiosResponse<any>> {
        return _post(SUBMIT_VENUE_UPLOADER_FILE_API + "?fileUploadHeaderId=" + id, {});
    }

    submitBudgetUploaderRowData(id: string): Promise<AxiosResponse<any>> {
        return _post(SUBMIT_BUDGET_UPLOADER_FILE_API + "?fileUploadHeaderId=" + id, {});
    }

    submitPeriodMatrixUploaderRowData(id: string): Promise<AxiosResponse<any>> {
        return _post(SUBMIT_PERIOD_MATRIX_UPLOADER_FILE_API + "?fileUploadHeaderId=" + id, {});
    }

    submitRateMatrixUploaderRowData(id: string): Promise<AxiosResponse<any>> {
        return _post(SUBMIT_RATEMATRIX_UPLOADER_FILE_API + "?fileUploadHeaderId=" + id, {});
    }

    getBillableItemHeaderRows(serviceId: string, subServiceId: string): Promise<AxiosResponse<any>> {
        return _get(BILLABLE_ITEM_HEADER_API + "?serviceId=" + serviceId + "&serviceSubTypeId=" + subServiceId);
    }

    getBillableItemHeaderRowsServiceUploader(filetypeid: string): Promise<AxiosResponse<any>> {
        return _get(BILLABLE_ITEM_HEADER_API + "?filetypeid=" + filetypeid);
    }

    getSIAWatchListHeaderRows(serviceId: string, subServiceId: string): Promise<AxiosResponse<any>> {
        return _get(SIA_ITEM_HEADER_API + "?serviceId=" + serviceId + "&fileTypeId=" + subServiceId);
    }

    getHeaderRows(serviceId: string, subServiceId: string): Promise<AxiosResponse<any>> {
        return _get(SIA_ITEM_HEADER_API + "?clientId=" + serviceId + "&fileTypeId=" + subServiceId);
    }

    deleteFileUploadRowItem(deleteData: string): Promise<AxiosResponse<any>> {
        return _delete(DELETE_FILE_UPLOAD_API + `/${deleteData}`);
    }

    deleteSIAUploadRowItem(deleteData: string): Promise<AxiosResponse<any>> {
        return _delete(SIA_ITEM_HEADER_API + `/${deleteData}`);
    }

    // Retrieve all rows for the grid based on Id of row clicked on parent grid
    getChildGridData(id: string): Promise<AxiosResponse<any>> {
        return _get(CHILD_GRID_ITEM_API + "?headerId=" + id);
    }

    //This function will fetch the Child Grid data for Manage our warnings page.
    //Constructs the URL dynamically based on the values available
    getManageOurWarningsGridData(
        clientId: string,
        providerId: string,
        subServiceId: string,
        dateRangeFrom: string,
        dateRangeTo: string,
        warningAcknowledged: boolean
    ): Promise<AxiosResponse<any>> {
        var queryParam = [clientId, providerId, subServiceId, dateRangeFrom, dateRangeTo];
        var queryParamKeys = ["clientId", "providerId", "serviceSubTypeId", "dateRangeFrom", "dateRangeTo"];
        var query = `?warningAcknowledged=${warningAcknowledged}&`;
        if (clientId || providerId || subServiceId || dateRangeFrom || dateRangeTo) {
            for (var queryItem = 0; queryItem < queryParam.length; queryItem++) {
                if (queryParam[queryItem]) {
                    query += queryParamKeys[queryItem] + "=" + queryParam[queryItem] + "&";
                } else {
                    query += "";
                }
            }
            if (query && query.length > 0) {
                var queryString: string = query.charAt(query.length - 1);
                if (queryString == "&") {
                    query = query.substring(0, query.length - 1);
                }
            }
        }
        return _get(`${MANAGE_OUR_WARNINGS_API}${query}`);
    }

    updateWarningGridRow(data: object): Promise<AxiosResponse<any>> {
        return _put(UPDATE_WARNING_ROW, data);
    }

    getChildGridDataForSIA(id: string): Promise<AxiosResponse<any>> {
        return _get(SIA_CHILD_GRID_ITEM_API + "?headerId=" + id);
    }

    getChildGridDataForVenueHierarchy(id: string): Promise<AxiosResponse<any>> {
        return _get(VENUE_CHILD_GRID_ITEM_API + "?headerId=" + id);
    }

    getChildGridDataForBudgetUpload(id: string): Promise<AxiosResponse<any>> {
        return _get(BUDGET_CHILD_GRID_ITEM_API + "?headerId=" + id);
    }

    getChildGridDataForPeriodMatrixUpload(id: string): Promise<AxiosResponse<any>> {
        return _get(PERIOD_MATRIX_CHILD_GRID_ITEM_API + "?headerId=" + id);
    }

    getChildGridDataForRateMatrixUpload(id: string): Promise<AxiosResponse<any>> {
        return _get(RATE_CHILD_GRID_ITEM_API + "?headerId=" + id);
    }

    //This function will fetch the Data for the Budget table in the MOS Budget page.
    //Constructs the URL dynamically based on the values available
    getBudgetData(
        clientId: string,
        venueId: string,
        serviceId: string,
        dateRangeFrom: string,
        dateRangeTo: string
    ): Promise<AxiosResponse<any>> {
        var queryParam = [clientId, venueId, serviceId, dateRangeFrom, dateRangeTo];
        var queryParamKeys = ["clientId", "venueId", "serviceTypeId", "dateFrom", "dateTo"];
        var query = "?";
        if (clientId || venueId || serviceId || dateRangeFrom || dateRangeTo) {
            for (var queryItem = 0; queryItem < queryParam.length; queryItem++) {
                if (queryParam[queryItem]) {
                    query += queryParamKeys[queryItem] + "=" + queryParam[queryItem] + "&";
                } else {
                    query += "";
                }
            }
            if (query && query.length > 0) {
                var queryString: string = query.charAt(query.length - 1);
                if (queryString == "&") {
                    query = query.substring(0, query.length - 1);
                }
            }
        } else {
            query = "";
        }
        return _get(`${BUDGET_API}${query}`);
    }

    saveBudgetDataItem(object: object) {
        return _post(SAVE_BUDGET_DATA, object);
    }

    deleteBudgetDataItem(id: number) {
        return _delete(`${BUDGET_API}?id=${id}`);
    }
    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }

    /**
     * Validate status
     *
     * @param status {string} - current status of grid row
     * @param equalTo {string} - the status to check against
     */
    isStatusEqualTo(status: string, equalTo: string) {
        return status && status.length > 0 ? status.toUpperCase() === equalTo.toUpperCase() : false;
    }
}

export default BillableItemHeaderService;
