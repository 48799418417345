import React, { Component, MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import DataGrid, { Paging, HeaderFilter, Search,FilterRow, Column, Export, FilterPanel, GroupPanel, Pager, SearchPanel, StateStoring, Scrolling} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import ArtistService from "../../services/ArtistService";
import PerformanceTimeCell from "./PerformanceTimeCell";
import GigRequestChangeCell from "./GigRequestChangeCell";
import { Popup, ScrollView } from "devextreme-react";
import { Format } from "devextreme-react/bar-gauge";
import { Link } from "react-router-dom";
import AppliedArtistGrid from "./AppliedArtistGrid";
import GigRequestArtistConfirmedCell from "./GigRequestArtistConfirmedCell";
import GigRequestVenueConfirmedCell from "./GigRequestVenueConfirmedCell";
import GigRequestReConfirmedCell from "./GigRequestReConfirmedCell";
import TagCell from "./TagCell";
import ClientInformationMainContent from "../ClientInformation/ClientInformationMainContent";
import { serviceTypes } from "../../services/FileService";
import FindAnArtistService, { RequestStatus, ShowInterestOptionsSentRequest } from "../../services/FindAnArtistService";
import GigRequestOptionsSentCell from "./GigRequestOptionsSentCell";
import sharedUtils from "../grid/sharedUtilities";
import { alert, confirm } from "devextreme/ui/dialog";
import GlobalService from "../../services/GlobalService";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import SupplierInformationMainContent from "../SupplierInformation/SupplierInformationMainContent";
import GardeningRequestGrid from "./GardeningRequestGrid";
import WindowCleaningRequestGrid from "./WindowCleaningRequestGrid";
import MaintenanceRequestGrid from "./MaintenanceRequestGrid";
import MiscelleanousRequestGrid from "./MiscelleanousRequestGrid";
import SecurityRequestGrid from "./SecurityRequestGrid";
import { ServiceType } from "../../common/ServiceUtilities";
import moment from "moment";
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";

let storageVal: string | null = "";

interface GigRequestGridProps extends WithTranslation {
    statusId: string,
    startDate: string,
    endDate: string
    location: any,
    gridType?: string,
    isRefreshButtonClicked?: boolean;
    getBuild: (flag: boolean) => void;  
    resetRefreshGrid?: (flag: boolean) => void;
}

interface GigRequestGridState {
    dataSource: [],
    loadIndicatorForGrid: boolean,
    appliedPopupVisible: boolean,
    requestId: string,
    venuePopupVisible?: boolean;
    clientId: string;
    venueId: string;
    internalComments: string;
    loadPanelVisible: boolean;
    errorMessage: [];
    storageVal: boolean;
    serviceTypeId: string;
    serviceSubTypeId: string;
    providerPopupVisibleEntertainment?: boolean;
    providerPopupVisibleOther?: boolean;
    businessEntityId: string;
    entertainmentDescPopupVisible: boolean;
    entdescription: string;
    otherDescPopupVisible: boolean;
    otherDescription: string;
    requestStatusId: string;
}

class GigRequestGrid extends Component<GigRequestGridProps> {
    state: GigRequestGridState;
    artistService: ArtistService;
    dataGrid: any;
    sharedUtils: sharedUtils;
    findAnArtistService: FindAnArtistService;
    remoteOperations: {
        sorting: boolean,
        paging: boolean,
        filtering: boolean,
        grouping: boolean,
        summary: boolean
    }
    isEditMode: string;
    clearRef:any;
    constructor(props: GigRequestGridProps) {
        super(props);
        this.isEditMode = "";
        this.state = {
            dataSource: [],
            loadIndicatorForGrid: false,
            appliedPopupVisible: false,
            requestId: "0",
            venuePopupVisible: false,
            clientId: "",
            venueId: "",
            internalComments: "",
            loadPanelVisible: false,
            errorMessage: [],
            storageVal: false,
            serviceTypeId: "",
            serviceSubTypeId: "",
            providerPopupVisibleEntertainment: false,
            providerPopupVisibleOther: false,
            businessEntityId: "",
            entertainmentDescPopupVisible: false,
            entdescription: "",
            otherDescPopupVisible: false,
            otherDescription: "",
            requestStatusId: "",
        }
        this.artistService = new ArtistService();
        this.dataGrid = React.createRef();
        this.sharedUtils = new sharedUtils();
        this.findAnArtistService = new FindAnArtistService();
        this.remoteOperations = {
            sorting: true,
            paging: true,
            filtering: true,
            grouping: true,
            summary: false
        }
        this.clearRef = React.createRef();
        this.clearRef.current = false;
    }

    clearFiltersOnLoad_DrillDown = () =>{
        let navigateFrom = this.props.location?.state && this.props.location?.state?.navigateFrom || this.props.location?.state?.navFrom || '';
        if(navigateFrom && this.clearRef?.current){
            if(navigateFrom == "bookingsOverview" || navigateFrom=='BookingsByWeek'){
                this.dataGrid?.current?.instance()?.clearFilter();
                this.clearRef.current = false;
            }
        }
    }

    getResults = (loadOptions: any) => {
        //let params = buildRequestParameters(loadOptions);
        this.clearFiltersOnLoad_DrillDown();
        let params = '';
        let dateto = this.sharedUtils.convertDateToString(this.sharedUtils.fetchMaxValueOfCurrentWeek());
        let gigType = GlobalService.getGigComingFrom();
        let serviceTypeId = "";
        if(gigType){
            if(gigType == "Entertainment"){
                serviceTypeId = ServiceType.Entertainment;
            }
            else if(gigType == "ContractCleaning"){
                serviceTypeId = ServiceType.ContractCleaning;
            }
            else if(gigType == "Gardening"){
                serviceTypeId = ServiceType.Gardening;
            }
            else if(gigType == "WindowCleaning"){
                serviceTypeId = ServiceType.WindowCleaning;
            }
            else if(gigType == "Maintenance"){
                serviceTypeId = ServiceType.Maintenance;
            }
            else if(gigType == "Miscelleanous"){
                serviceTypeId = ServiceType.Miscelleanous;
            }
            else if(gigType == "Security"){
                serviceTypeId = ServiceType.Security;
            }
        }
        if(this.props.location?.state?.navFrom == "bookingsOverview" || this.props.location?.state?.navigateFrom == "BookingsByWeek"){
            serviceTypeId = this.props.location?.state?.serviceType ;            
        }
        if (this.props.location && this.props.location.state && this.props.location.state.navigateFrom == "BookingsByWeek") {
            if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.Published) {
                let filters = ['requestStatus.value','=','Published'];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;

                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Published%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                //else params = params + `?filter=[%22requestStatus.value%22,%22=%22,%22Published%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.Pending) {

                let filters = ['requestStatus.value','=','Pending'];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;

                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Pending%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                //else params = params + `?filter=[%22requestStatus.value%22,%22=%22,%22Pending%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == "Confirm") {
                let filters =[[["artistConfirm","=",null],"or",["artistConfirm","=",false],"or",["venueConfirm","=",null],"or",["venueConfirm","=",false]],"and",[["requestStatus.value","=","Artist Selected"],"or",["requestStatus.value","=","Artist Confirmation"]]];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;

                //if(params !== "") params = params + `&filter=[[["artistConfirm","=",null],"or",["artistConfirm","=",false],"or",["venueConfirm","=",null],"or",["venueConfirm","=",false]],"and",[["requestStatus.value","=","Artist Selected"],"or",["requestStatus.value","=","Artist Confirmation"]]]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                //else params = params + `?filter=[[["artistConfirm","=",null],"or",["artistConfirm","=",false],"or",["venueConfirm","=",null],"or",["venueConfirm","=",false]],"and",[["requestStatus.value","=","Artist Selected"],"or",["requestStatus.value","=","Artist Confirmation"]]]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == "reConfirm") {
                let filters =[["requestStatus.value","=","Booked"],"and",[["reConfirm","=",null],"or",["reConfirm","=",false]]];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                
                //if(params !== "") params = params + `&filter=[["requestStatus.value","=","Booked"],"and",[["reConfirm","=",null],"or",["reConfirm","=",false]]]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                //else params = params + `?filter=[["requestStatus.value","=","Booked"],"and",[["reConfirm","=",null],"or",["reConfirm","=",false]]]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.Booked) {

                let filters =[["requestStatus.value","=","Booked"],"or",["reConfirm","=",true]];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;

                //if(params !== "") params = params +`&filter=["reConfirm","=",true]&filter=["requestStatus.value","=","Booked"]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                //else params = params +`?filter=["reConfirm","=",true]&filter=["requestStatus.value","=","Booked"]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.OptionsSent) {
                let filters =["requestStatus.value","=","Options Sent"];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                
                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Options Sent%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                //else params = params + `?filter=[%22requestStatus.value%22,%22=%22,%22Options Sent%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.Shortlisted) {
                let filters =["requestStatus.value","=","Shortlisted"];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;

                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Shortlisted%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                //else params = params + `?filter=[%22requestStatus.value%22,%22=%22,%22Shortlisted%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.Offered) {
                let filters =["requestStatus.value","=","Offered"];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;

                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Offered%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
                //else params = params + `?filter=[%22requestStatus.value%22,%22=%22,%22Offered%22]&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            }
            // else if (params) {
            //     if(params !== "") params = params + `&dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            //     else params = params + `?dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            // }
            else {
                params = buildRequestParameters(loadOptions);
                if(params){
                    params += '&'
                } else {
                    params += '?'
                }
                params += `dateRangeFrom=${this.props.location.state.dateFrom}&serviceTypeId=${serviceTypeId}&dateRangeTo=${this.props.location.state.dateTo}`;
            }
        }
        else {
            if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.Published) {
                let filters = ['requestStatus.value','=','Published'];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Published%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
                //else params = params +`?filter=[%22requestStatus.value%22,%22=%22,%22Published%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.Pending) {
                let filters = ['requestStatus.value','=','Pending'];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
                
                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Pending%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
                //else params = params + `?filter=[%22requestStatus.value%22,%22=%22,%22Pending%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == "Confirm") {

                let filters =[[["artistConfirm","=",null],"or",["artistConfirm","=",false],"or",["venueConfirm","=",null],"or",["venueConfirm","=",false]],"and",[["requestStatus.value","=","Artist Selected"],"or",["requestStatus.value","=","Artist Confirmation"]]];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;

                //if(params !== "") params = params + `&filter=[[["artistConfirm","=",null],"or",["artistConfirm","=",false],"or",["venueConfirm","=",null],"or",["venueConfirm","=",false]],"and",[["requestStatus.value","=","Artist Selected"],"or",["requestStatus.value","=","Artist Confirmation"]]]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
                //else params = params + `?filter=[[["artistConfirm","=",null],"or",["artistConfirm","=",false],"or",["venueConfirm","=",null],"or",["venueConfirm","=",false]],"and",[["requestStatus.value","=","Artist Selected"],"or",["requestStatus.value","=","Artist Confirmation"]]]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == "reConfirm") {
                let filters =[["requestStatus.value","=","Booked"],"and",[["reConfirm","=",null],"or",["reConfirm","=",false]]];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.startDate}&dateRangeTo=${dateto}&serviceTypeId=${serviceTypeId}`;
                
                //if(params !== "") params = params + `&filter=[["requestStatus.value","=","Booked"],"and",[["reConfirm","=",null],"or",["reConfirm","=",false]]]&dateRangeFrom=${this.props.startDate}&dateRangeTo=${dateto}&serviceTypeId=${serviceTypeId}`;
                //else params = params + `?filter=[["requestStatus.value","=","Booked"],"and",[["reConfirm","=",null],"or",["reConfirm","=",false]]]&dateRangeFrom=${this.props.startDate}&dateRangeTo=${dateto}&serviceTypeId=${serviceTypeId}`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.OptionsSent) {
                let filters =["requestStatus.value","=","Options Sent"];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;

                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Options Sent%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
                //else params = params +`?filter=[%22requestStatus.value%22,%22=%22,%22Options Sent%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.Shortlisted) {
                let filters =["requestStatus.value","=","Shortlisted"];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;


                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Shortlisted%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
                //else params = params +`?filter=[%22requestStatus.value%22,%22=%22,%22Shortlisted%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.statusToShow == RequestStatus.Offered) {
                let filters =["requestStatus.value","=","Offered"];
                params = buildRequestParameters(loadOptions,'filter',filters);
                params +=`&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;

                //if(params !== "") params = params + `&filter=[%22requestStatus.value%22,%22=%22,%22Offered%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
                //else params = params +`?filter=[%22requestStatus.value%22,%22=%22,%22Offered%22]&dateRangeFrom=${this.props.startDate}&serviceTypeId=${serviceTypeId}&dateRangeTo=`;
            }
            // else if (params) {                
            //     if(params !== "") params = params + `&dateRangeFrom=${this.props.startDate}&dateRangeTo=${this.props.endDate}&serviceTypeId=${serviceTypeId}`;
            //     else params = params + `?dateRangeFrom=${this.props.startDate}&dateRangeTo=${this.props.endDate}&serviceTypeId=${serviceTypeId}`;
            // }
            else {
                params = buildRequestParameters(loadOptions);
                if(params){
                    params += '&'
                } else {
                    params += '?'
                }
                params += `dateRangeFrom=${this.props.startDate}&dateRangeTo=${this.props.endDate}&serviceTypeId=${serviceTypeId}`;
            }
        }
        return this.artistService.getGigGridRowsWithinRemoteOperations(params)
            .then(this.handleSuccessLoadDataSource)
            .catch(() => { throw 'Data Loading Error'; });
    }

    handleSuccessLoadDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });
        // show notification for new build            
        let version = localStorage.getItem("BuildVersion");
        let headerVersion = response.headers["x-application-version"];
        // if(version == "" || version == null){
            //     version = getHeaderVersion;
            //     localStorage.setItem("BuildVersion", getHeaderVersion);
            // }        
            if(version !== headerVersion){
                localStorage.setItem("BuildVersion", headerVersion);
                this.props.getBuild(true);              
            }
        // end
        return {
            data: response.data.data ? response.data.data : [],
            totalCount: response.data.totalCount
        };
    }

    componentDidMount() {
        this.setState({
            loadIndicatorForGrid: true
        });
        let navigateFrom = this.props.location?.state && this.props.location?.state?.navigateFrom || this.props.location?.state?.navFrom || '';
        if (navigateFrom == "bookingsOverview" || navigateFrom == 'BookingsByWeek') {
            this.clearRef.current = true;
        }
        this.loadDataSource();
    }

    componentDidUpdate(prevProps: GigRequestGridProps) {
        if(prevProps.gridType != this.props.gridType){
            // const dataGrid = this.dataGrid?.current?.instance;
            // dataGrid.clearFilter();
            this.dataGrid?.current?.instance()?.clearFilter();
        }
        if (this.props.startDate != prevProps.startDate || this.props.endDate != prevProps.endDate || this.props.statusId != prevProps.statusId 
                || prevProps.gridType != this.props.gridType || (this.props.isRefreshButtonClicked != prevProps.isRefreshButtonClicked && this.props.isRefreshButtonClicked == true)) {            
            this.loadDataSource();
        }       

    }

    loadDataSource = () => {
        if(this.props.isRefreshButtonClicked == true) {
            this.props.resetRefreshGrid ? this.props.resetRefreshGrid(false) : null;
        }
        let dataSource = new CustomStore({
            key: "id",
            load: this.getResults,
        });

        this.setState({
            dataSource: dataSource
        });
    }

    featuredCellDisplayContent = (cellInfo: any) => {
        let featuredVal = cellInfo.data.data.featured;
        let isShowInColour: boolean = false;
        let valToShow: string = "";
        if (featuredVal) {
            isShowInColour = true;
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{valToShow}</span> : valToShow}
            </div>
        );

    }

    equipmentCellDisplayContent = (cellInfo: any) => {
        let equipmentVal = cellInfo.data.data.isEquipment;
        let valToShow: string = "";
        if (equipmentVal) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                <span>{valToShow}</span>
            </div>
        );

    }

    featureHeaderFilter = (options: any) => {
        options.dataSource.postProcess = (results: any) => {
            results.forEach((item: any) => {
                item.text = item.value ? "Yes" : "No"
            });
            return results;
        };
    }

    showInfoShowInterest = (gigRequestId: string, internalComments: string, serviceTypeId: string, serviceSubTypeId: string, clientBusinessEntityId: string, requestStatusId: string) => {
        this.setState({
            appliedPopupVisible: true,
            requestId: gigRequestId,
            internalComments: internalComments,
            serviceTypeId: serviceTypeId,
            serviceSubTypeId: serviceSubTypeId,
            clientId: clientBusinessEntityId,
            requestStatusId: requestStatusId,
            errorMessage: []
        });
    };

    venueCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoVenue(cellInfo.data.clientBusinessEntity.id, cellInfo.data.venue.id);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.venue.venueName}
                </Link>
            </div>
        );
    };

    showInfoVenue = (clientId: string, venueId: string) => {
        this.setState({
            venuePopupVisible: true,
            clientId: clientId,
            venueId: venueId,
        });
    };
    hideInfoVenue = () => {
        this.setState({
            venuePopupVisible: false,
        });
    };


    hideInfoShowInterest = () => {
        this.setState({
            appliedPopupVisible: false,
            requestId: 0,
        });
    };

    showInfoCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                {cellInfo.data.showInterestCount ?
                    <Link
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.showInfoShowInterest(cellInfo.data.id, cellInfo.data.internalComments, cellInfo.data.serviceTypeLookUp.id.toString(), cellInfo.data.serviceSubTypeLookUp.id.toString(), cellInfo.data.clientBusinessEntity.id.toString(), cellInfo.data.requestStatus?.id.toString());
                        }}
                        className="approval-query-column"
                        to="#"
                    >
                        {cellInfo.data.showInterestCount}
                    </Link> :
                    <span>
                        {cellInfo.data.showInterestCount}
                    </span>}
            </div>
        );
    };

    onConfirm = (submitRequest: ShowInterestOptionsSentRequest) => {
        this.setState({
            loadPanelVisible: true
        });
        this.findAnArtistService.updateArtistRequest(submitRequest.id, submitRequest)
            .then(this.handleSuccessConfirmOptionsSent)
            .catch((error) => {
                if (error.response && error.response.data.error) {
                    this.setState({
                        errorMessage: JSON.parse(JSON.stringify(error.response.data.error))
                    });
                }
                else {
                    let respMessage: string = "Re Confirmed failed with response: " + JSON.stringify(error);
                    alert("Sorry! Options Sent Confirmation failed", "Error!");
                    if (!this.findAnArtistService.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                }
                this.setState({
                    loadPanelVisible: false
                });

            });
    }


    handleSuccessConfirmOptionsSent = () => {
        this.setState({
            loadPanelVisible: false
        }, () => { this.hideInfoShowInterest() });
        this.loadDataSource();
    }
    onOptionChanged = () => {
        storageVal = localStorage.getItem('storageGigReqGrid');
        if (storageVal) {
            this.setState({
                storageVal: true
            });
        }
    }
    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    onCleaningOptionChanged = () => {
        storageVal = localStorage.getItem('storageCleaningReqGrid');
        if (storageVal) {
            this.setState({
                storageVal: true
            });
        }
    }

    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
        });
    }
    hideInfoProvider = (e: any) => {
        this.setState({
            providerPopupVisibleOther: false,
        });
    };

    showInfoProvider = (clientId: string, venueId: string, serviceTypeId: string, businessEntityId: string) => {
        this.setState({
            clientId: clientId,
            venueId: venueId,
            serviceTypeId: serviceTypeId,
            businessEntityId: businessEntityId,

        });
        if (serviceTypeId == "30") {
            this.setState({
                providerPopupVisibleOther: false,
                providerPopupVisibleEntertainment: true,
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
                providerPopupVisibleOther: true,
            });
        }
    };

    providerCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.clientBusinessEntity.id, cellInfo.data.venue.id, cellInfo.data.serviceTypeLookUp.id, cellInfo.data.providerBusinessEntity?.id);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.providerBusinessEntity?.shortName}
                </Link>
            </div>
        );
    };

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
            });
        }
    };

    reoccurrenceCellDisplayContent = (cellInfo: any) => {
        let reoccurrenceVal = cellInfo.data.data.reoccurrence;
        let isShowInColour: boolean = false;
        let valToShow: string = "";
        if (reoccurrenceVal) {
            isShowInColour = true;
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{valToShow}</span> : valToShow}
            </div>
        );

    }

    onboardedCellDisplayContent = (cellInfo: any) => {
        let onboardedVal = cellInfo.data.data.venue?.onboarded;
        let isShowInColour: boolean = false;
        let valToShow: string = "";
        if (onboardedVal) {
            isShowInColour = true;
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{valToShow}</span> : valToShow}
            </div>
        );

    }

    entertainmentDescCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showEntDecriptionPopup(cellInfo.data.entertainmentDescription);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.entertainmentDescription
                        ? cellInfo.data.entertainmentDescription.length > 20
                            ? `${cellInfo.data.entertainmentDescription.substring(0, 20)}...`
                            : cellInfo.data.entertainmentDescription
                        : ""}
                </Link>
            </div>
        );
    };

    otherDescCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showOtherDecriptionPopup(cellInfo.data.otherDescription);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.otherDescription
                        ? cellInfo.data.otherDescription.length > 20
                            ? `${cellInfo.data.otherDescription.substring(0, 20)}...`
                            : cellInfo.data.otherDescription
                        : ""}
                </Link>
            </div>
        );
    };

    showEntDecriptionPopup = (desc: string) => {
        this.setState({
            entertainmentDescPopupVisible: true,
            entdescription: desc,
        });
    };

    showOtherDecriptionPopup = (desc: string) => {
        this.setState({
            otherDescPopupVisible: true,
            otherDescription: desc,
        });
    };

    hideDecriptionPopup = () => {
        this.setState({
            entertainmentDescPopupVisible: false,
            otherDescPopupVisible: false,
        });
    };

    getRequestGridStatusUpdate = (flag: boolean) => {
        if(flag == true){
            this.loadDataSource();
        }
    };
    gigRequestOptionsSent = (cellInfo: any) => {
        return(
            <div>
                <GigRequestOptionsSentCell data={cellInfo} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate} i18n={cellInfo} tReady={false} t={cellInfo}/>
            </div>        
        );
    }
    gigRequestArtistConfirmed = (cellInfo: any) => {
        return(
            <div>
                <GigRequestArtistConfirmedCell data={cellInfo} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate} i18n={cellInfo} tReady={false} t={cellInfo}/>
            </div>        
        );
    }
    gigRequestVenueConfirmed = (cellInfo: any) => {
        return(
            <div>
                <GigRequestVenueConfirmedCell data={cellInfo} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate} i18n={cellInfo} tReady={false} t={cellInfo}/>
            </div>        
        );
    }

    dateFromDisplayContent = (cellInfo: any) => {
        let dateFrom = cellInfo.data?.dateFrom;

        let dateString = new Date(dateFrom).toDateString();
        let formattedDate = new Date(dateFrom).toLocaleDateString('en-GB'); //'dd/mm/yyyy'
        return (
            <div>
                {/* {!this.sharedUtils.isDefaultDate(dateString) ? moment(dateString).format("DD/MM/YYYY"): ''} */}
                {!this.sharedUtils.isDefaultDate(dateString) ? formattedDate: ''}
            </div>
        );

    }

    renderServiceGrids = () => {
        let isEventUk = UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        if(this.props.gridType == "ContractCleaning"){
            return (
                <DataGrid
                    elementAttr={{
                        id: "gridContainer"
                    }}
                    ref={this.dataGrid}
                    dataSource={this.state.dataSource}
                    showBorders={false}
                    showColumnLines={false}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnResizingMode={"widget"}
                    hoverStateEnabled={true}
                    wordWrapEnabled={false}
                    remoteOperations={this.remoteOperations}
                    onExporting={this.onExporting}
                    onContentReady={this.onCleaningOptionChanged}
                    noDataText={"No requests found for the filters used"}
                    columnMinWidth={150}
                >
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                    <HeaderFilter visible={true}>
                        <Search enabled={true}/> 
                    </HeaderFilter>
                    <Scrolling useNative={true} showScrollbar={"always"} />
                    <Export enabled={true} />
                    <FilterRow visible={true} applyFilter="auto" />
                    <GroupPanel visible={true} />
                    <FilterPanel visible={true} />
                    <SearchPanel visible={true} placeholder={"Search"} />
                    <StateStoring
                        enabled={true}
                        type="localStorage" storageKey="storageCleaningReqGrid"
                    />
                    <Column
                        caption="CHANGE"
                        cellComponent={GigRequestChangeCell}
                        allowExporting={false}
                        allowSearch={false}
                    />
                    <Column
                        caption="TAG?"
                        cellComponent={TagCell}

                    ></Column>
                    <Column
                        dataField="taggedUser.name"
                        caption="TAGGED TO"
                        dataType="string"
                    />
                    <Column
                        dataField="requestStatus.value"
                        caption="STATUS"
                        dataType="string"
                    />
                    <Column
                        dataField="venue.venueName"
                        caption="VENUE"
                        cellRender={this.venueCellDisplayContent}
                        dataType="string"
                    />
                    <Column
                        dataField="venue.houseNumber"
                        caption="VENUE NO"
                        dataType="string"
                    />
                    <Column 
                        dataField="venue.onboarded" 
                        caption="ONBOARDED" 
                        cellComponent={this.onboardedCellDisplayContent}
                        dataType="boolean"
                    />
                    <Column
                        dataField="clientBusinessEntity.shortName"
                        caption="CLIENT"
                        dataType="string"
                    />
                    <Column
                        dataField="optionsSent"
                        caption="OPTIONS SENT"
                        cellRender={this.gigRequestOptionsSent}
                        dataType="boolean"
                        allowExporting={false}
                        allowSearch={false}
                    />
                    <Column
                        dataField="artistConfirm"
                        caption="PROVIDER CONFIRMED"
                        
                        cellRender={this.gigRequestArtistConfirmed}
                        dataType="boolean"
                        allowExporting={false}
                        allowSearch={false}
                    />
                    <Column
                        dataField="venueConfirm"
                        caption="VENUE CONFIRMED"
                        cellRender={this.gigRequestVenueConfirmed}
                        dataType="boolean"
                        allowExporting={false}
                        allowSearch={false}
                    />
                    <Column
                        caption="SUB TYPE"
                        dataField="serviceSubTypeLookUp.value"
                        dataType="string"
                    /> 
                    <Column
                        dataField="typeLookUp.value"
                        caption="TYPE"
                        dataType="string"
                    />
                    <Column
                        dataField="otherDescription"
                        caption="OTHER DESCRIPTION"
                        dataType="string"
                    /> 
                    <Column
                        dataField="dateFrom"
                        caption="WORK DATE"
                        format="dd/MM/yyyy"
                        dataType="date"
                        cellRender={this.dateFromDisplayContent}
                    />
                    <Column
                        dataField="hoursMinutes"
                        caption="CONTRACT HOURS"
                        dataType="string"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    /> 
                    <Column
                        dataField="rate"
                        caption="RATE"
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                        dataField="budget"
                        caption="PROVIDER FEE"
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                            dataField="marketPlaceBudget"
                            caption="Market Place Budget"
                            dataType="number">
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column>
                    <Column
                        dataField="maxBudget"
                        caption="VENUE BUDGET"
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>    
                    <Column
                        dataField="requestDate"
                        caption="REQUEST DATE"
                        format="dd/MM/yyyy"
                        dataType="date"
                    /> 
                    <Column
                        dataField="bookedDate"
                        caption="Booked DATE"
                        format="dd/MM/yyyy"
                        dataType="date"
                    />
                    {isEventUk ?
                        <Column
                            dataField="internalComments"
                            caption="INTERNAL COMMENTS"
                            dataType="string"
                        /> : null}
                    <Column
                        dataField="providerBusinessEntity.shortName"
                        caption="PROVIDER"
                        dataType="string"
                        cellRender={this.providerCellDisplayContent}
                    />
                    <Column
                        dataField="providerBusinessEntity.id"
                        caption="PROVIDER ID"
                        dataType="string"
                    /> 
                    <Column
                        dataField="providerPayAmountLocal"
                        caption="PROVIDER NET"
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    {isEventUk ?
                        <Column
                            dataField="grossProfitAmountLocal"
                            caption="EVENT FEE NET"
                            dataType="number"
                        >
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column> : null}
                    {isEventUk ?
                        <Column
                            dataField="clientBillableAmountLocal"
                            caption="TOTAL"
                            dataType="number"
                        >
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column> : null}   
                        <Column dataField='id' caption='ID'/>                  
                </DataGrid>
            );
        }
        else if(this.props.gridType == "Gardening"){
            return (
                <GardeningRequestGrid  dataSource={this.state.dataSource} datagridIns={this.dataGrid} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate}/>
            );
        }
        else if(this.props.gridType == "WindowCleaning"){
            return (
                <WindowCleaningRequestGrid  dataSource={this.state.dataSource} datagridIns={this.dataGrid} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate}/>
            );
        }
        else if(this.props.gridType == "Maintenance"){
            return (
                <MaintenanceRequestGrid  dataSource={this.state.dataSource} datagridIns={this.dataGrid} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate}/>
            );
        }
        else if(this.props.gridType == "Miscelleanous"){
            return (
                <MiscelleanousRequestGrid  dataSource={this.state.dataSource} datagridIns={this.dataGrid} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate}/>
            );
        }
        else if(this.props.gridType == "Security"){
            return (
                <SecurityRequestGrid  dataSource={this.state.dataSource} datagridIns={this.dataGrid} getRequestGridStatusUpdate={this.getRequestGridStatusUpdate}/>
            );
        }
    }

    checkService = () => {
        if(this.props.location?.state?.serviceType == "10"){
           return "Security"
        }  
        else if(this.props.location?.state?.serviceType == "20"){
            return "ContractCleaning"
        } 
        else if(this.props.location?.state?.serviceType == "30"){
            return "Entertainment"
        }
        else if(this.props.location?.state?.serviceType == "40"){
            return "Gardening"
        } 
        else if(this.props.location?.state?.serviceType == "50"){
            return "WindowCleaning"
        } 
        else if(this.props.location?.state?.serviceType == "60"){
            return "Maintenance"
        } 
        else{
            return "Miscelleanous"
        }  
    }

    render() {
        var isEventUk = UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        let gigType = GlobalService.getGigComingFrom();
        if(this.props.location?.state?.navFrom == "bookingsOverview" || this.props.location?.state?.navigateFrom == "BookingsByWeek"){
            gigType = this.checkService();           
        }
        return (
            <div>
                { this.props.gridType == "Entertainment" ?
                    <DataGrid
                        elementAttr={{
                            id: "gridContainer"
                        }}
                        ref={this.dataGrid}
                        dataSource={this.state.dataSource}
                        showBorders={false}
                        showColumnLines={false}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        columnResizingMode={"widget"}
                        hoverStateEnabled={true}
                        wordWrapEnabled={false}
                        remoteOperations={this.remoteOperations}
                        onExporting={this.onExporting}
                        onContentReady={this.onOptionChanged}
                        noDataText={"No requests found for the filters used"}
                        columnMinWidth={150}
                    >
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <HeaderFilter visible={true}>
                        <Search enabled={true}/> 
                        </HeaderFilter>
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Export enabled={true} />
                        <FilterRow visible={true} applyFilter="auto" />
                        <GroupPanel visible={true} />
                        <FilterPanel visible={true} />
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <StateStoring
                            enabled={true}
                            type="localStorage" storageKey="storageGigReqGrid"
                        />
                        <Column
                            caption="CHANGE"
                            cellComponent={GigRequestChangeCell}
                            allowExporting={false}
                            allowSearch={false}
                        />
                        <Column
                            caption="TAG?"
                            cellComponent={TagCell}

                        ></Column>
                        <Column
                            dataField="taggedUser.name"
                            caption="TAGGED TO"
                            dataType="string"
                        />
                        <Column
                            dataField="requestStatus.value"
                            caption="STATUS"
                            dataType="string"
                        />
                        <Column
                            dataField="venue.venueName"
                            caption="VENUE"
                            dataType="string"
                            cellRender={this.venueCellDisplayContent}
                        />
                        <Column
                            dataField="venue.houseNumber"
                            caption="VENUE NO"
                            dataType="string"
                        />
                        <Column 
                            dataField="venue.onboarded" 
                            caption="ONBOARDED" 
                            cellComponent={this.onboardedCellDisplayContent}
                            dataType="boolean"
                        />
                        <Column
                            dataField="clientBusinessEntity.shortName"
                            caption="CLIENT"
                            dataType="string"
                        />
                        <Column
                            dataField="optionsSent"
                            caption="OPTIONS SENT"
                            cellRender={this.gigRequestOptionsSent}
                            dataType="boolean"
                            allowExporting={false}
                            allowSearch={false}
                        />
                        <Column
                            dataField="artistConfirm"
                            caption="ARTIST CONFIRMED"
                            cellRender={this.gigRequestArtistConfirmed}
                            dataType="boolean"
                            allowExporting={false}
                            allowSearch={false}
                        />
                        <Column
                            dataField="venueConfirm"
                            caption="VENUE CONFIRMED"
                            cellRender={this.gigRequestVenueConfirmed}
                            dataType="boolean"
                            allowExporting={false}
                            allowSearch={false}
                        />
                         <Column
                            dataField="reConfirm"
                            caption="RE-CONFIRMED"
                            cellComponent={GigRequestReConfirmedCell}
                            dataType="boolean"
                            allowExporting={false}
                            allowSearch={false}
                        />
                        <Column
                            caption="SUB TYPE"
                            dataField="serviceSubTypeLookUp.value"
                            dataType="string"
                        />                
                        <Column
                            dataField="typeLookUp.value"
                            caption="TYPE"
                            dataType="string"
                        />
                        <Column
                            dataField="dateFrom"
                            caption="GIG DATE"
                            format="dd/MM/yyyy"
                            dataType="date"
                        /> 
                        <Column
                            caption="GIG DAY"
                            dataField="gigDay"
                            allowGrouping={false}
                            allowFiltering={false}
                            allowSearch={false}
                            allowSorting={false}
                            dataType="string"
                        />
                         <Column
                            dataField="startTime"
                            caption="PERFORMANCE TIME"
                            allowGrouping={false}
                            allowFiltering={false}
                            allowSearch={false}
                            allowSorting={false}
                            dataType="string"
                            cellComponent={PerformanceTimeCell}
                        />
                         <Column
                            dataField="showInterestCount"
                            caption="# APPLIED"
                            allowGrouping={false}
                            allowFiltering={false}
                            allowSearch={false}
                            allowSorting={false}
                            cellRender={this.showInfoCellDisplayContent}
                            dataType="number"
                        /> 
                        <Column
                            dataField="budget"
                            caption="GIG LIST FEE"
                            dataType="number">
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column>
                        <Column
                            dataField="marketPlaceBudget"
                            caption="Market Place Budget"
                            dataType="number">
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column>
                        
                        <Column
                            dataField="maxBudget"
                            caption="VENUE BUDGET"
                            dataType="number"
                        >
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column>
                        <Column
                            dataField="isEquipment"
                            caption="Equipment"
                            cellComponent={this.equipmentCellDisplayContent}
                            allowGrouping={false}
                            allowFiltering={false}
                            allowSearch={false}
                            allowSorting={false}
                            dataType="boolean"
                        /> 
                        <Column
                            dataField="entertainmentDescription"
                            caption="ENTERTAINMENT DESCRIPTION"
                            cellRender={this.entertainmentDescCellDisplayContent}
                        />
                        <Column
                            dataField="otherDescription"
                            caption="OTHER DESCRIPTION"
                            cellRender={this.otherDescCellDisplayContent}
                        />
                        <Column
                            dataField="featured"
                            caption="FEATURED"
                            cellComponent={this.featuredCellDisplayContent}
                            dataType="boolean"
                        >
                            <HeaderFilter
                                dataSource={this.featureHeaderFilter}
                            />
                        </Column>
                        <Column
                            dataField="requestDate"
                            caption="REQUEST DATE"
                            format="dd/MM/yyyy"
                            dataType="date"
                        />
                        <Column
                            dataField="publishedDate"
                            caption="Published DATE"
                            format="dd/MM/yyyy"
                            dataType="date"
                        />
                        <Column
                            dataField="bookedDate"
                            caption="Booked DATE"
                            format="dd/MM/yyyy"
                            dataType="date"
                        />
                        {isEventUk ?
                            <Column
                                dataField="internalComments"
                                caption="INTERNAL COMMENTS"
                                dataType="string"
                            /> : null}
                        <Column
                            dataField="reoccurrence"
                            caption="RECURRING"
                            cellComponent={this.reoccurrenceCellDisplayContent}
                            dataType="boolean"
                        >
                            <HeaderFilter
                                dataSource={this.featureHeaderFilter}
                            />
                         </Column>
                        <Column
                            dataField="providerBusinessEntity.shortName"
                            caption="ARTIST NAME"
                            dataType="string"
                            cellRender={this.providerCellDisplayContent}
                        />
                        <Column
                            dataField="mobile"
                            caption="MOBILE"
                            dataType="string"
                        />
                        <Column
                            dataField="providerBusinessEntity.id"
                            caption="ARTIST ID"
                            dataType="string"
                        />
                        <Column
                            dataField="providerBusinessEntity.professionalName"
                            caption="ACT NAME"
                            dataType="string"
                        /> 
                        <Column
                            dataField="providerPayAmountLocal"
                            caption="PROVIDER NET"
                            dataType="number"
                        >
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column>
                        {isEventUk ?
                            <Column
                                dataField="grossProfitAmountLocal"
                                caption="EVENT FEE NET"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column> : null}
                        {isEventUk ?
                            <Column
                                dataField="clientBillableAmountLocal"
                                caption="TOTAL"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column> : null}
                        <Column
                            dataField="artistEmail"
                            caption="Artist Email"
                            dataType="string"
                            allowGrouping={false}
                            allowFiltering={false}
                            allowSearch={false}
                            allowSorting={false}
                        />  
                        <Column dataField='id' caption='ID'/>                  
                    </DataGrid> 
                    : this.renderServiceGrids()
                }
                <Popup
                    visible={this.state.venuePopupVisible}
                    onHiding={this.hideInfoVenue}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"Entertainment - Client Information & Schedule"}
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <ClientInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            isShowByVenue={true}
                            serviceTypeId={serviceTypes.Entertainment}
                        ></ClientInformationMainContent>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.appliedPopupVisible}
                    onHiding={this.hideInfoShowInterest}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"Applied Artists"}
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <AppliedArtistGrid requestId={this.state.requestId} internalComments={this.state.internalComments} onConfirm={this.onConfirm} errorMessage={this.state.errorMessage} serviceTypeId={this.state.serviceTypeId} serviceSubTypeId={this.state.serviceSubTypeId} clientBusinessEntityId={this.state.clientId} requestStatusId={this.state.requestStatusId}/>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.providerPopupVisibleOther}
                    onHiding={this.hideInfoProvider}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Supplier Information & Venue Conditions"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <SupplierInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            serviceTypeId={this.state.serviceTypeId}
                            navigationFromShift="SimpleShiftGrid"
                        ></SupplierInformationMainContent>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.entertainmentDescPopupVisible}
                    onHiding={this.hideDecriptionPopup}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"ENTERTAINMENT DESCRIPTION"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.entdescription}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.otherDescPopupVisible}
                    onHiding={this.hideDecriptionPopup}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"OTHER DESCRIPTION"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.otherDescription}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                {
                    this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            hideOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title="Artist Details"
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <ArtistProfileComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    businessEntityId={this.state.businessEntityId}
                                    handleFeedbackUpdate={() => { return null }}
                                    handleAddArtist={() => { return null }}
                                    serviceType={"Entertainment"}
                                ></ArtistProfileComponent>
                            </ScrollView>
                        </Popup>
                    ) : (null)
                }
            </div>
        );
    }
}

export default withTranslation()(GigRequestGrid);