import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import Schedule from "../schedule/Schedule";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import SupplierInformationMainContent from "./SupplierInformationMainContent";

interface SupplierInformationPageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

interface SupplierInformationPageState {
    clientId: string;
    venueId: string;
    isClientOrVenueFound: boolean;
}

class SupplierInformationPage extends Component<SupplierInformationPageProps> {
    //Initialize the component's state.
    state: SupplierInformationPageState;

    constructor(props: SupplierInformationPageProps) {
        super(props);
        this.state = {
            clientId: "",
            venueId: "",
            isClientOrVenueFound: false,
        };
    }
    setClientOrVenueId = (venueIdValue: string, clientIdValue: string, isClientOrVenueValue: boolean) => {
        this.setState({
            clientId: clientIdValue,
            venueId: venueIdValue,
            isClientOrVenueFound: isClientOrVenueValue,
            isDashBoardDataLoaded: false,
        });
    };
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <BulletinPanel />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <h2 className="page-title__black">
                            {/* Door Supervision - Supplier Information & Venue Conditions */}
                            {`${this.props.location.state.Header} - Supplier Information & Venue Conditions`}
                        </h2>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            <Schedule venueAll ={false} setParentFilterValuesCallback={this.setClientOrVenueId} navigateFrom = "supplierinformation"/>
                            <SupplierInformationMainContent
                                clientId={this.state.clientId}
                                venueId={this.state.venueId}
                            ></SupplierInformationMainContent>
                        </div>
                        <div />
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(SupplierInformationPage);
