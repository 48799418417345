import React from "react";
import "../../../assets/images/event-UK-logo-vecture.svg";
import { LoadIndicator } from "devextreme-react";
import { LookupTypeItem } from "../../services/LookupService";

interface WelcomePageComponentProps {
    configValueText1: string;
    configValueText2: string;
    title: string;
}
// State
interface WelcomePageComponentState {
    configurationValue: LookupTypeItem[];
}

class WelcomePageComponent extends React.Component<WelcomePageComponentProps> {
    state: WelcomePageComponentState;
    constructor(props: WelcomePageComponentProps) {
        super(props);
        this.state = {
            configurationValue: [],
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {}

    render() {
        const { configValueText1, configValueText2 } = this.props;
        return (
            <>
                <form data-testid="addEditPayment-form" className="card card-form my-5">
                    <div className="card-body">
                        {configValueText1 && configValueText2 && configValueText1.length > 0 && configValueText2.length > 0 ? (
                            <section className="incidents-footer-text">
                                <div>
                                    <img
                                        width="50%"
                                        height="50%"
                                        src="/assets/images/event-UK-logo-vecture.svg"
                                        alt="Event UK Logo"
                                    ></img>
                                </div>
                                <div className="mt-5">
                                    <h4>
                                        Welcome <span className="configurationText_Color">{this.props.title}</span> to the
                                        Event UK Portal
                                    </h4>
                                </div>
                                <div className="mt-5">
                                    <h4>{this.props.configValueText1} <a href = "/raiseQuery">{this.props.configValueText2}</a></h4>
                                </div>
                            </section>
                        ) : (
                            <div className="row col-lg-12">
                                <span className="col-lg-12 text-center">
                                    <LoadIndicator
                                        id="simple-grid-indicator"
                                        height={100}
                                        width={100}
                                        visible={!configValueText1 && !configValueText2}
                                    />
                                </span>
                            </div>
                        )}
                    </div>
                </form>
            </>
        );
    }
}

export default WelcomePageComponent;
