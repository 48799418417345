import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DateBox, SelectBox, TextBox, TextArea, LoadPanel, RadioGroup, FileUploader, CheckBox } from "devextreme-react";
import {
    DateBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
    RadioButtonOnSelectionChanged,
    CheckBoxOnValueChanged,
    FileUploadOnInitializedEvent,
    FileUploaderOnValueChangedEvent,
    LookUpOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";
import RaiseIncidentService from "../../services/RaiseIncidentService";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import RaiseIncidentUtils, { RaiseIncidentObject } from "./RaiseIncidentUtils";
import sharedUtils from "../grid/sharedUtilities";
import outstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import UserService, { RoleGroupNames } from "../../services/UserService";
import GlobalService from "../../services/GlobalService";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
interface RaiseIncidentFormProps extends WithTranslation {
    location: any;
    history: any;
    incidentId: string;
}

interface RaiseIncidentFormState {
    loadPanelVisible: boolean;
    raiseIncidentFormData: RaiseIncidentObject;
    errorMessage: [];
    IsSuccessResponse: string;
    isEnableUpload: boolean;
    selectedFiles: File[];
    fileUploadList: File[];
    loadIndicatorVisible: boolean;
    isEmergencyServicesShow: boolean;
    incidentStatusDataSource: LookupTypeItem[];
    multiple: boolean;
    isInjuriesDescriptionToShow: boolean;
    comment: string;
    commentReportedBy: string;
    commentRolePosition: string;
    isTimeChange: boolean;
    isDateChange: boolean;
}

// Component
class RaiseIncidentForm extends React.Component<RaiseIncidentFormProps> {
    outStandingActionUtils: outstandingActionsUtilities;
    utils: RaiseIncidentUtils;
    sharedUtils: sharedUtils;
    state: RaiseIncidentFormState;
    incidentService: RaiseIncidentService;
    lookUpService: LookupService;
    taxApplicable: LookupTypeItem[];
    severityLookUp: LookupTypeItem[];
    fileUploadElement: any;
    constructor(props: RaiseIncidentFormProps) {
        super(props);
        this.fileUploadElement = undefined;
        this.incidentService = new RaiseIncidentService();
        this.lookUpService = new LookupService();
        this.utils = new RaiseIncidentUtils();
        this.sharedUtils = new sharedUtils();
        this.outStandingActionUtils = new outstandingActionsUtilities();
        this.taxApplicable = this.lookUpService.getDecisionTypes();
        var convertedRaiseIncidentFormData = this.utils.initializeRequestItem();
        this.severityLookUp = this.lookUpService.getSeverity();
        // Initialize state
        this.state = {
            loadPanelVisible: false,
            raiseIncidentFormData: convertedRaiseIncidentFormData,
            errorMessage: [],
            IsSuccessResponse: "",
            isEnableUpload: false,
            selectedFiles: [],
            fileUploadList: [],
            loadIndicatorVisible: false,
            isEmergencyServicesShow: false,
            incidentStatusDataSource: [],
            multiple: false,
            isInjuriesDescriptionToShow: false,
            comment: "",
            commentReportedBy: "",
            commentRolePosition: "",
            isTimeChange: false,
            isDateChange: false,
        };
    }

    componentDidMount() {
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
            .then(this.handleClientLookUpSuccess)
            .catch(this.handleError);
        this.getIncidentDetails();
    }

    getIncidentDetails = () => {
        if (this.props.location.state && this.props.location.state.id != "0") {
            this.incidentService
                .getIncidentDetailsById(this.props.location.state.id)
                .then(this.handleSuccessGetIncidentById)
                .catch(this.handleError);
        } else if (this.props.incidentId) {
            this.incidentService
                .getIncidentDetailsById(parseInt(this.props.incidentId))
                .then(this.handleSuccessGetIncidentById)
                .catch(this.handleError);
        }
    };

    handleSuccessGetIncidentById = (response: AxiosResponse<any>) => {
        var convertServerResponseToFormData = this.utils.convertIncidentResponeToFormData(response.data.data);
        var isInjuries: boolean = false;
        var isEmergenecyServicesAttended: boolean = false;
        if (
            convertServerResponseToFormData.injuries &&
            convertServerResponseToFormData.injuries.toLowerCase() == "yes" &&
            convertServerResponseToFormData.injuries.toLowerCase() == "true"
        ) {
            isInjuries = true;
        }
        if (
            convertServerResponseToFormData.emergenecyServicesAttended &&
            convertServerResponseToFormData.emergenecyServicesAttended.toLowerCase() == "yes" &&
            convertServerResponseToFormData.emergenecyServicesAttended.toLowerCase() == "true"
        ) {
            isEmergenecyServicesAttended = true;
        }
        this.setState({
            raiseIncidentFormData: convertServerResponseToFormData,
            isInjuriesDescriptionToShow: isInjuries ? true : false,
            isEmergencyServicesShow: isEmergenecyServicesAttended ? true : false,
        });
    };
    handleClientLookUpSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data && response.data.data && response.data.data.length > 0) {
            this.setState(
                {
                    raiseIncidentFormData: {
                        ...this.state.raiseIncidentFormData,
                        clientBusinessEntityId: response.data.data[0].id,
                    },
                },
                () => {
                    this.lookUpService
                        .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                        .then(this.handleVenueLookUpSuccess)
                        .catch(this.handleError);
                }
            );
        }
    };

    handleVenueLookUpSuccess = (response: AxiosResponse<any>) => {
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            !this.utils.isBlank(this.state.raiseIncidentFormData.clientBusinessEntityId)
        ) {
            var venues: any[] = response.data.data;
            venues = venues.filter((item) => {
                return item.parentMappingId == this.state.raiseIncidentFormData.clientBusinessEntityId;
            });

            this.setState({
                raiseIncidentFormData: {
                    ...this.state.raiseIncidentFormData,
                    venueId: venues[0].id,
                },
            });
        }
    };
    handleError = (err: any) => {
        this.setState({
            errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
            showError: true,
            loadPanelVisible: false,
        });
    };
    handleChangeClientSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                clientBusinessEntityId: dxValueChange.value,
            },
        });
    };

    handleChangeProviderTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                providerBusinessEntityId: dxValueChange.value,
            },
        });
    };
    handleChangeVenueSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                venueId: dxValueChange.value,
            },
        });
    };
    handleChangeReportedByRole = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                reportedByRole: dxValueChange.value,
            },
        });
    };
    handleChangeReportedByName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                reportedByName: dxValueChange.value,
            },
        });
    };
    handleChangeInjuries = (dxValueChange: RadioButtonOnSelectionChanged) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                injuries: dxValueChange.value,
            },
        });
        if (dxValueChange.value.toLowerCase() == "yes") {
            this.setState({
                isInjuriesDescriptionToShow: true,
            });
        } else if (dxValueChange.value.toLowerCase() == "no") {
            this.setState({
                isInjuriesDescriptionToShow: false,
                raiseIncidentFormData: {
                    ...this.state.raiseIncidentFormData,
                    injuriesDescription: "",
                },
            });
        }
    };
    handleChangePoliceService = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                policeAttended: dxValueChange.value,
            },
        });
    };
    handleChangeAmbulanceService = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                ambulanceAttended: dxValueChange.value,
            },
        });
    };
    handleChangeFireService = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                fireAttended: dxValueChange.value,
            },
        });
    };
    handleChangeEmergencyService = (dxValueChange: RadioButtonOnSelectionChanged) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                emergenecyServicesAttended: dxValueChange.value,
            },
        });
        if (dxValueChange.value.toLowerCase() == "yes") {
            this.setState({
                isEmergencyServicesShow: true,
            });
        } else if (dxValueChange.value.toLowerCase() == "no") {
            this.setState({
                isEmergencyServicesShow: false,
                raiseIncidentFormData: {
                    ...this.state.raiseIncidentFormData,
                    policeAttended: false,
                    ambulanceAttended: false,
                    fireAttended: false,
                },
            });
        }
    };
    handleChangeSeverity = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                severity: dxValueChange.value,
            },
        });
    };
    handleChangeIncidentStatus = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                incidentStatusId: dxValueChange.value,
            },
        });
    };
    handleChangeDescriptionOfIncident = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                description: dxValueChange.value,
            },
        });
    };
    handleChangeInjuriesDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                injuriesDescription: dxValueChange.value,
            },
        });
    };
    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            comment: dxValueChange.value,
        });
    };
    handleChangeCommentReportedBy = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            commentReportedBy: dxValueChange.value,
        });
    };
    handleChangeCommentRolePosition = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            commentRolePosition: dxValueChange.value,
        });
    };
    handlechangeIncidentTitle = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                title: dxValueChange.value,
            },
        });
    };
    handleChangeDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.previousValue || this.props.location.state && this.props.location.state.id == "0") {
            this.setState({
                raiseIncidentFormData: {
                    ...this.state.raiseIncidentFormData,
                    date: dxValueChange.value,
                },
                isDateChange: true,
            });
        }
    };
    handleChangeTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            raiseIncidentFormData: {
                ...this.state.raiseIncidentFormData,
                time: dxValueChange.value,
            },
            isTimeChange: true,
        });
    };

    onSubmit = () => {
        this.setState({
            loadPanelVisible: true,
        });
        this.setLoadIndicator(true);
        var formData = new FormData();
        formData.append("incidentId", this.state.raiseIncidentFormData.incidentId);
        formData.append("clientBusinessEntityId", this.state.raiseIncidentFormData.clientBusinessEntityId);
        formData.append("providerBusinessEntityId", this.state.raiseIncidentFormData.providerBusinessEntityId);
        formData.append("venueId", this.state.raiseIncidentFormData.venueId);
        formData.append("description", this.state.raiseIncidentFormData.description);
        formData.append("severity", this.state.raiseIncidentFormData.severity);
        formData.append("injuries", this.state.raiseIncidentFormData.injuries);
        formData.append("injuriesDescription", this.state.raiseIncidentFormData.injuriesDescription);
        formData.append("ambulanceAttended", this.state.raiseIncidentFormData.ambulanceAttended.toString());
        formData.append(
            "date",
            this.state.isDateChange
                ? this.sharedUtils.convertDateToString(this.state.raiseIncidentFormData.date)
                : this.state.raiseIncidentFormData.date
                    ? this.state.raiseIncidentFormData.date.toString()
                    : ""
        );
        formData.append("emergenecyServicesAttended", this.state.raiseIncidentFormData.emergenecyServicesAttended);
        formData.append("fireAttended", this.state.raiseIncidentFormData.fireAttended.toString());
        formData.append("policeAttended", this.state.raiseIncidentFormData.policeAttended.toString());
        formData.append("reportedByName", this.state.raiseIncidentFormData.reportedByName);
        formData.append("reportedByRole", this.state.raiseIncidentFormData.reportedByRole);
        formData.append("time", this.sharedUtils.convertTimeToString(this.state.raiseIncidentFormData.time));
        formData.append("title", this.state.raiseIncidentFormData.title);
        if (this.state.selectedFiles.length > 0) {
            this.state.selectedFiles.forEach((data: any) => {
                formData.append("files[]", data, data.name);
            });
        }
        formData.append("comment", this.state.comment);
        formData.append("commentsReportedBy", this.state.commentReportedBy);
        formData.append("commentsRolePosition", this.state.commentRolePosition);
        this.incidentService.postIncidentRequest(formData).then(this.handleSuccess).catch(this.handleFailure);

        //passing object data to the server
    };

    handleFormDataSubmissionSuccess = (response: AxiosResponse<any>) => {
        this.hideLoadpanel();
        this.setState({
            errorMessage: [],
        });
        this.renderRedirect();
    };

    hideLoadpanel = () => {
        this.setState({ loadPanelVisible: false });
    };
    showLoadpanel = () => {
        this.setState({
            loadPanelVisible: true,
        });
    };
    handleFormSubmissionFailure = (err: any) => {
        this.hideLoadpanel();
        this.setState({
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null,
        });
    };

    renderRedirect = () => {
        this.props.history.goBack();
    };
    // Set the file uploader component
    handleFileUploadOnInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElement = e.component;
    };
    enableUploadButton = (e: FileUploaderOnValueChangedEvent) => {
        this.setState({
            isEnableUpload: true,
            selectedFiles: e.value,
        });
    };
    setLoadIndicator = (isVisible: boolean) => {
        this.setState({ loadIndicatorVisible: isVisible });
    };
    handleSuccess = (response: AxiosResponse<any>) => {
        //handle success
        this.fileUploadElement.reset();
        this.setLoadIndicator(false);
        this.hideLoadpanel();
        // Reset the file uploader array
        this.setState({
            isEnableUpload: false,
            selectedFiles: [],
            IsSuccessResponse: true,
        });
        this.topFunction();
        setTimeout(this.renderRedirect, 500);
    };
    handleFailure = (err: any) => {
        this.setLoadIndicator(false);
        this.hideLoadpanel();
        this.setState({
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null,
            IsSuccessResponse: false,
        });
        this.topFunction();
    };
    topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    onMultipleChanged = (e: CheckBoxOnValueChanged) => {
        this.setState({ multiple: e.value });
    };
    //#region render Component
    render() {
        var configurableData = GlobalService.configurationValue();
        var incidentTitle = "";
        var incidentDate = "";
        var incidentReportedBy = "";
        var incidentReportedRolePosition = "";
        var incidentDesc = "";
        var injuriesDesc = "";
        var incidentTime = "";
        if (configurableData && configurableData.length > 0) {
            incidentTitle = configurableData.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_AddIncident_Title;
            })[0].value;
            incidentDate = configurableData.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_AddIncident_Date;
            })[0].value;
            incidentReportedBy = configurableData.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_AddIncident_ReportedBy;
            })[0].value;
            incidentReportedRolePosition = configurableData.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_AddIncident_ReportedRolePosition;
            })[0].value;
            incidentDesc = configurableData.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_AddIncident_Description;
            })[0].value;
            injuriesDesc = configurableData.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_AddIncident_InjuriesDescription;
            })[0].value;
            incidentTime = configurableData.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_AddIncident_Time;
            })[0].value;
        }
        var isVenueManager = false;
        var isProvider = false;
        if (UserService.isUserInGroup(RoleGroupNames.VenueManager) && this.props.location.state.id != "0") {
            isVenueManager = true;
        }
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) && this.props.location.state.id != "0") {
            isProvider = true;
        }
        return (
            <form data-testid="addEditPayment-form" className="card card-form my-5">
                <div className="card-body">
                    <section>
                        {this.state.IsSuccessResponse ? (
                            <span className="text-success">
                                {this.props.location.state.id == "0" ? (
                                    <b>Incident Raised Successfully!</b>
                                ) : (
                                    <b> Incident Updated Successfully </b>
                                )}
                            </span>
                        ) : null}
                        {this.state.errorMessage &&
                            this.state.errorMessage.length > 0 &&
                            !this.state.IsSuccessResponse ? (
                            <div className="row justify-content-left pt-3">
                                <span className="unscheduled-shift">
                                    <ul>
                                        {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {item.columnName} : {item.errorMessage}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </span>
                            </div>
                        ) : null}
                        <div className="row">
                            <div className="mt-3 col-10 col-lg-5">
                                <h6>
                                    Incident Title
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <TextBox
                                    onValueChanged={this.handlechangeIncidentTitle}
                                    value={this.state.raiseIncidentFormData.title}
                                    placeholder={incidentTitle}
                                    disabled={isVenueManager || isProvider}
                                ></TextBox>
                            </div>
                            <div className="mt-3 col-10 col-lg-5">
                                <h6>
                                    Date
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <DateBox
                                    onValueChanged={this.handleChangeDate}
                                    value={
                                        this.state.raiseIncidentFormData.date
                                            ? this.sharedUtils.convertStringToDate(
                                                this.state.raiseIncidentFormData.date.toString()
                                            )
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                    placeholder={incidentDate}
                                    disabled={isVenueManager || isProvider}
                                    valueChangeEvent="keyup"
                                ></DateBox>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-10 col-lg-5">
                                <h6>
                                    Time
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>

                                <DateBox
                                    type="time"
                                    interval={15}
                                    placeholder={incidentTime}
                                    useMaskBehavior={true}
                                    displayFormat={"HH:mm"}
                                    onValueChanged={this.handleChangeTime}
                                    value={
                                        this.state.isTimeChange && this.state.raiseIncidentFormData.time
                                            ? this.state.raiseIncidentFormData.time
                                            : this.state.raiseIncidentFormData.time
                                                ? this.sharedUtils.convertStringToTime(
                                                    this.state.raiseIncidentFormData.time.toString()
                                                )
                                                : undefined
                                    }
                                    disabled={isVenueManager || isProvider}
                                />
                            </div>
                            <div className="mt-3 col-10 col-lg-5">
                                <h6>
                                    Provider <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.providerType}
                                    onValueChanged={this.handleChangeProviderTypeSelect}
                                    value={this.state.raiseIncidentFormData.providerBusinessEntityId}
                                    signal={isVenueManager || isProvider}
                                    recordType={
                                        this.props.location.state && this.props.location.state.id != "0"
                                            ? "all"
                                            : "active"
                                    }
                                />
                                <input
                                    data-testid={LookupTypeIndexes.providerType}
                                    type="hidden"
                                    name={LookupTypeIndexes.providerType}
                                    value={
                                        this.state.raiseIncidentFormData.providerBusinessEntityId
                                            ? this.state.raiseIncidentFormData.providerBusinessEntityId
                                            : 0
                                    }
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-10 col-lg-5">
                                <h6>
                                    Client <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                    onValueChanged={this.handleChangeClientSelect}
                                    value={this.state.raiseIncidentFormData.clientBusinessEntityId}
                                    signal={isVenueManager || isProvider}
                                    recordType={
                                        this.props.location.state && this.props.location.state.id != "0"
                                            ? "all"
                                            : "active"
                                    }
                                />
                                <input
                                    data-testid={LookupTypeIndexes.clientType}
                                    type="hidden"
                                    name={LookupTypeIndexes.clientType}
                                ></input>
                            </div>
                            <div className="mt-3 col-10 col-lg-5">
                                <h6>
                                    Venue
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <LookupTypeListDynamic
                                    isDisabled={isVenueManager || isProvider}
                                    lookupTypeIndex={LookupTypeIndexes.venueType}
                                    onValueChanged={this.handleChangeVenueSelect}
                                    isRoot={false}
                                    parentMappingId={this.state.raiseIncidentFormData.clientBusinessEntityId}
                                    value={this.state.raiseIncidentFormData.venueId}
                                    displayExpression={"value"}
                                    recordType={
                                        this.props.location.state && this.props.location.state.id != "0"
                                            ? "all"
                                            : "active"
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-10 col-lg-5">
                                <h6>
                                    Reported By
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <TextBox
                                    onValueChanged={this.handleChangeReportedByName}
                                    value={this.state.raiseIncidentFormData.reportedByName}
                                    placeholder={incidentReportedBy}
                                    disabled={isVenueManager || isProvider}
                                ></TextBox>
                            </div>
                            <div className="mt-3 col-10 col-lg-5">
                                <h6>
                                    Role/Position
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <TextBox
                                    onValueChanged={this.handleChangeReportedByRole}
                                    value={this.state.raiseIncidentFormData.reportedByRole}
                                    placeholder={incidentReportedRolePosition}
                                    disabled={isVenueManager || isProvider}
                                ></TextBox>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="mt-3 col-12 col-lg-12">
                                <h6>
                                    Description
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <TextArea
                                    onValueChanged={this.handleChangeDescriptionOfIncident}
                                    value={this.state.raiseIncidentFormData.description}
                                    placeholder={incidentDesc}
                                    height={120}
                                    disabled={isVenueManager || isProvider}
                                ></TextArea>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="mt-3 col-lg-12">
                                <h6>
                                    Injuries<sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <RadioGroup
                                    items={this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                    displayExpr="value"
                                    valueExpr="value"
                                    value={
                                        this.state.raiseIncidentFormData.injuries
                                            ? this.state.raiseIncidentFormData.injuries.toLowerCase() == "yes" ||
                                                this.state.raiseIncidentFormData.injuries.toLowerCase() == "true"
                                                ? "Yes"
                                                : "No"
                                            : ""
                                    }
                                    onValueChanged={this.handleChangeInjuries}
                                    layout="horizontal"
                                    disabled={isVenueManager || isProvider}
                                />
                            </div>
                        </div>
                        {this.state.isInjuriesDescriptionToShow ? (
                            <div className="row">
                                <div className="mt-3 col-12 col-lg-12">
                                    <h6>
                                        <sup className="card-form__mandatory-symbol"></sup>
                                    </h6>
                                    <TextArea
                                        onValueChanged={this.handleChangeInjuriesDescription}
                                        value={this.state.raiseIncidentFormData.injuriesDescription}
                                        placeholder={injuriesDesc}
                                        height={60}
                                        maxLength={100}
                                        disabled={isVenueManager || isProvider}
                                    ></TextArea>
                                </div>
                            </div>
                        ) : null}
                        <hr></hr>
                        <div className="row">
                            <div className="mt-3 col">
                                <h6>
                                    Emergency Services
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-lg-6">
                                <h6>Emergency Services attended:</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-lg-6">
                                <RadioGroup
                                    items={this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                    displayExpr="value"
                                    valueExpr="value"
                                    value={
                                        this.state.raiseIncidentFormData.emergenecyServicesAttended
                                            ? this.state.raiseIncidentFormData.emergenecyServicesAttended.toLowerCase() ==
                                                "yes" ||
                                                this.state.raiseIncidentFormData.emergenecyServicesAttended.toLowerCase() ==
                                                "true"
                                                ? "Yes"
                                                : "No"
                                            : ""
                                    }
                                    onValueChanged={this.handleChangeEmergencyService}
                                    layout="horizontal"
                                    disabled={isVenueManager || isProvider}
                                />
                            </div>
                        </div>
                        {this.state.isEmergencyServicesShow ? (
                            <div className="row">
                                <div className="mt-3 col-4 col-lg-3 square-dx-checkbox">
                                    <CheckBox
                                        text="Police"
                                        onValueChanged={this.handleChangePoliceService}
                                        value={
                                            this.state.raiseIncidentFormData.policeAttended == "true"
                                                ? true
                                                : Boolean(this.state.raiseIncidentFormData.policeAttended)
                                        }
                                        disabled={isVenueManager || isProvider}
                                    />
                                </div>
                                <div className="mt-3 col-4 col-lg-3 square-dx-checkbox">
                                    <CheckBox
                                        text="Ambulance"
                                        onValueChanged={this.handleChangeAmbulanceService}
                                        value={
                                            this.state.raiseIncidentFormData.ambulanceAttended == "true"
                                                ? true
                                                : Boolean(this.state.raiseIncidentFormData.ambulanceAttended)
                                        }
                                        disabled={isVenueManager || isProvider}
                                    />
                                </div>
                                <div className="mt-3 col-4 col-lg-3 square-dx-checkbox">
                                    <CheckBox
                                        text="Fire"
                                        onValueChanged={this.handleChangeFireService}
                                        value={
                                            this.state.raiseIncidentFormData.fireAttended == "true"
                                                ? true
                                                : Boolean(this.state.raiseIncidentFormData.fireAttended)
                                        }
                                        disabled={isVenueManager || isProvider}
                                    />
                                </div>{" "}
                            </div>
                        ) : null}
                        <hr></hr>
                        <div className="row">
                            <div className="mt-3 col-lg-3">
                                <h6>
                                    Incident Severity
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </h6>
                                <SelectBox
                                    dataSource={this.severityLookUp}
                                    displayExpr={"value"}
                                    valueExpr={"value"}
                                    onValueChanged={this.handleChangeSeverity}
                                    value={this.state.raiseIncidentFormData.severity}
                                    disabled={isVenueManager || isProvider}
                                />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="mt-3 col-12 col-lg-12">
                                <h6>Attachments</h6>
                                <p className="mt-3">
                                    <span>
                                        Please attach any supportive documentation, including statements, photographs
                                        and videos.
                                    </span>
                                </p>
                                {this.props.location.state && this.props.location.state.id != "0" ? (
                                    <div className="incidents-document-display-div mt-4">
                                        {this.state.raiseIncidentFormData.incidentDocument.length > 0
                                            ? this.state.raiseIncidentFormData.incidentDocument.map(
                                                (document: any, uniqueKey: number) => {
                                                    return (
                                                        <>
                                                            <span className="mr-2 ml-3">
                                                                <FontAwesomeIcon
                                                                    icon={faFileAlt}
                                                                    className="text-dark"
                                                                />
                                                            </span>
                                                            <a
                                                                className="text-dark"
                                                                href={document.displayLink}
                                                                download={document.name}
                                                            >
                                                                {document.name}&nbsp;
                                                            </a>
                                                        </>
                                                    );
                                                }
                                            )
                                            : null}
                                    </div>
                                ) : null}
                                <div className="mt-3 col-12 col-lg-12">
                                    <FileUploader
                                        multiple={true}
                                        accept={"*"}
                                        selectButtonText={this.props.t("shiftUpload.fileUploader.browseButton.text")}
                                        labelText="or drop files here"
                                        uploadMode="useForm"
                                        onInitialized={this.handleFileUploadOnInitialize}
                                        onUploadAborted={(e: any) => {
                                            this.setLoadIndicator(false);
                                        }}
                                        onUploadError={(e: any) => {
                                            this.setLoadIndicator(false);
                                        }}
                                        readyToUploadMessage={this.props.t(
                                            "shiftUpload.fileUploader.uploadMessage.text"
                                        )}
                                        onValueChanged={this.enableUploadButton}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.props.location.state && this.props.location.state.id != "0" ? (
                            <>
                                <hr></hr>
                                <div className="row">
                                    <div className="mt-3 col-7 col-lg-7">
                                        <h6>Comments</h6>
                                    </div>
                                </div>
                                <hr className="incidents-comments-below-line"></hr>
                                {this.state.raiseIncidentFormData.incidentComment.length > 0
                                    ? this.state.raiseIncidentFormData.incidentComment.map(
                                        (item: any, uniqueKey: number) => {
                                            return (
                                                <>
                                                    <div className="row">
                                                        <div className="col-lg-7">{item.comment}</div>
                                                        <div className="col-lg-5">
                                                            <div className="row">
                                                                <div className="col-lg-6 mt-3 mt-lg-0 text-lg-right configurationText_Color">
                                                                    Added By:
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    {item.reportedByName}
                                                                    <br></br>
                                                                    {item.reportedByRole}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6 text-lg-right configurationText_Color">
                                                                    Date Added:
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    {this.utils.convertdataBaseDateToCustomizedFormat(
                                                                        item.lastUpdatedOn
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="incidents-comments-below-line"></hr>
                                                </>
                                            );
                                        }
                                    )
                                    : null}

                                <div className="row">
                                    <div className="mt-3 col-12 col-xl-8">
                                        <TextArea
                                            onValueChanged={this.handleChangeComments}
                                            placeholder="Please add comment here and then select Submit.."
                                            height={100}
                                        ></TextArea>
                                    </div>
                                    <div className="mt-3 col-12 col-xl-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h6>Reported By:</h6>
                                            </div>
                                            <div className="col-md-8">
                                                <TextBox
                                                    onValueChanged={this.handleChangeCommentReportedBy}
                                                    placeholder="Please add name.."
                                                ></TextBox>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-4">
                                                <h6>Role/Position:</h6>
                                            </div>
                                            <div className="col-md-8">
                                                <TextBox
                                                    onValueChanged={this.handleChangeCommentRolePosition}
                                                    placeholder="Please add role.."
                                                ></TextBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </section>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="row mt-5">
                        <div className="col-0 col-lg-4 col-xl-8"></div>
                        <div className="col-12 col-lg-4 col-xl-2">
                            <Link to="/DoorSupervision/Incidents">
                                <button className="btn btn--ghost btn--ghost--teal btn--large mt-3">Cancel</button>
                            </Link>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-2">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onSubmit();
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    <br></br>
                    <p className="incidents-footer-text">
                        Please ensure that the information provided is as accurate as possible- no changes to text can
                        be made once submitted,<br></br>
                        however attachments and comments can still be added.
                    </p>
                </div>
            </form>
        );
    }
}

export default withTranslation()(RaiseIncidentForm);
