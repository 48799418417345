import React, { Component } from "react";
import BillableItemHeaderService from "../../services/BillableItemHeaderService";
import LookupService, { LookupTypeIndexes, LookupTypeItem, PeriodMatrixLookupItem } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import { SelectBox, DateBox } from "devextreme-react";
import { SelectBoxOnValueChangedEvent, DateBoxOnValueChangedEvent, LookUpOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import sharedUtils from "../grid/sharedUtilities";
import BudgetGrid from "./BudgetGrid";
import BillingFormUtils from "../Billing/BillingFormUtils";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";

// Props
interface BudgetProps {}

interface BudgetState {
    clientLoaded: boolean;
    clientDataSource: LookupTypeItem[];
    clientDataSourceAll:LookupTypeItem[];
    venueLoaded: boolean;
    venueDataSource: LookupTypeItem[];
    venueNameDataSourceAll:LookupTypeItem[];
    clientId: string;
    venueId: string;
    startDate: NullableDate;
    endDate: NullableDate;
    serviceLoaded: boolean;
    serviceDataSource: LookupTypeItem[];
    serviceId: string;
    dateFrom: string;
    dateTo: string;
    refreshSignal: boolean;
    periodMatrixLoaded: boolean;
    periodMatrixDataSource: PeriodMatrixLookupItem[];
    houseNoDataSource: LookupTypeItem[];
    venueNameDataSource: LookupTypeItem[];
    venueListByClient: any[];
}

class Budget extends Component<BudgetProps> {
    state: BudgetState;
    service: BillableItemHeaderService;
    lookupService: LookupService;
    sharedUtils: sharedUtils;
    utils: BillingFormUtils;
    constructor(props: BudgetProps) {
        super(props);
        this.service = new BillableItemHeaderService();
        this.lookupService = new LookupService();
        this.sharedUtils = new sharedUtils();
        this.utils = new BillingFormUtils();
        this.state = {
            clientLoaded: false,
            clientDataSource: [],
            clientDataSourceAll:[],
            venueLoaded: false,
            venueDataSource: [],
            venueNameDataSourceAll:[],
            serviceLoaded: false,
            serviceDataSource: [],
            serviceId: "",
            clientId: "",
            venueId: "",
            startDate: null,
            endDate: null,
            dateFrom: "",
            dateTo: "",
            refreshSignal: false,
            periodMatrixLoaded: false,
            periodMatrixDataSource: [],
            houseNoDataSource: [],
            venueNameDataSource: [],
            venueListByClient: [],
        };
    }

    componentDidMount() {
        this.retrieveLookupValues(LookupTypeIndexes.clientType);
        this.retrieveLookupValues(LookupTypeIndexes.venueType);
        this.retrieveLookupValues(LookupTypeIndexes.serviceType);
        this.retrieveLookupValues(LookupTypeIndexes.periodMatrix);
        this.retrieveLookupValuesAll(LookupTypeIndexes.clientType);
        this.retrieveLookupValuesAll(LookupTypeIndexes.venueType);
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleLookupSuccessForClient)
                    .catch((error) => {
                        this.setState({
                            clientLoaded: true,
                            clientDataSource: [],
                        });
                    });
                break;
            case LookupTypeIndexes.serviceType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
                    .then(this.handleLookupSuccessForService)
                    .catch((error) => {
                        this.setState({
                            serviceLoaded: true,
                            serviceDataSource: [],
                        });
                    });
                break;
            case LookupTypeIndexes.venueType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                    .then(this.handleVenueTypeLookupSuccess)
                    .catch((error) => {
                        this.setState({
                            venueLoaded: true,
                            venueDataSource: [],
                        });
                    });
                break;
            case LookupTypeIndexes.periodMatrix:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.periodMatrix)
                    .then(this.handlePeriodMatrixLookupSuccess)
                    .catch((error) => {
                        this.setState({
                            periodMatrixLoaded: true,
                            periodMatrixDataSource: [],
                        });
                    });
                break;
        }
    };
    retrieveLookupValuesAll = (lookupTypeIndex: string) => {
        switch(lookupTypeIndex){
            case LookupTypeIndexes.clientType:
                this.lookupService
                    .getLookupByLookupTypeIndexAll(LookupTypeIndexes.clientType)
                    .then(this.handleLookupSuccessForClientAll)
                    .catch((error) => {
                        this.setState({
                            clientLoaded: true,
                            clientDataSourceAll: [],
                        });
                    });
                break;
                case LookupTypeIndexes.venueType:
                    this.lookupService
                        .getLookupByLookupTypeIndexAll(LookupTypeIndexes.venueType)
                        .then(this.handleVenueTypeLookupAllSuccess)
                        .catch((error) => {
                            this.setState({
                                venueLoaded: true,
                                venueDataSourceAll: [],
                            });
                        });
                    break;
        }
    }

    handlePeriodMatrixLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            this.setState({
                periodMatrixLoaded: true,
                periodMatrixDataSource: response.data.data,
            });
        }
    };

    handleLookupSuccessForService = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            this.setState({
                serviceLoaded: true,
                serviceDataSource: response.data.data,
            });
        }
    };

    handleLookupSuccessForClient = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            this.setState({
                clientLoaded: true,
                clientDataSource: response.data.data,
            });
        }
    };
    handleLookupSuccessForClientAll = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            this.setState({
                clientLoaded: true,
                clientDataSourceAll: response.data.data,
            });
        }
    }

    handleVenueTypeLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            var venueData: any[] = [];
            this.setState({
                venueLoaded: true,
                venueDataSource: response.data.data,
                venueListByClient: venueData,
                houseNoDataSource: this.utils.filterVenueNoValues(response.data.data),
                venueNameDataSource: this.utils.filterVenueNameValues(response.data.data),
            });
        }
    };
    
    handleVenueTypeLookupAllSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            var venueData: any[] = [];
            this.setState({
                venueLoaded: true,
                venueDataSource: response.data.data,
                venueListByClient: venueData,
                houseNoDataSource: this.utils.filterVenueNoValues(response.data.data),
                venueNameDataSourceAll: this.utils.filterVenueNameValues(response.data.data),
            });
        }
    };

    //Handle change functions
    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            serviceId: dxValueChange.value,
        });
    };

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var venueList: any[] = this.state.venueDataSource;
        var venueDataByClientId: any[] = this.sharedUtils.extractVenueListPerClient(venueList, dxValueChange.value);
        this.setState({
            clientId: dxValueChange.value,
            venueId: "",
            venueListByClient: venueDataByClientId,
        });
    };

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            venueId: dxValueChange.value,
        });
    };

    handleChangeStartDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            startDate: dxValueChange.value,
            dateFrom: this.sharedUtils.convertDateToString(dxValueChange.value),
        });
    };

    handleChangeEndDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            endDate: dxValueChange.value,
            dateTo: this.sharedUtils.convertDateToString(dxValueChange.value),
        });
    };

    //A Helper function that refreshes the Grid based on the filters.
    refreshGrid = () => {
        this.setState((prevState: BudgetState) => ({
            refreshSignal: !prevState.refreshSignal,
        }));
    };

    render() {
        const {
            clientLoaded,
            clientDataSource,
            clientDataSourceAll,
            venueLoaded,
            venueDataSource,
            serviceLoaded,
            serviceDataSource,
            serviceId,
            clientId,
            venueId,
            dateFrom,
            dateTo,
            refreshSignal,
            periodMatrixLoaded,
            periodMatrixDataSource,
            houseNoDataSource,
            venueNameDataSource,
            venueNameDataSourceAll
        } = this.state;
        return (
            <div>
                <h3>
                    <strong>Budgets</strong>
                </h3>
                <hr className="horizontal_border_style"></hr>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Client</div>
                    <div className="mt-3 col-10 col-lg-3">
                        {clientLoaded ? (
                            <SelectBox
                                dataSource={clientDataSource}
                                displayExpr={"value"}
                                valueExpr={"id"}
                                value={this.state.clientId}
                                onValueChanged={this.handleChangeClientTypeSelect}
                            />
                        ) : (
                            <span> Loading... </span>
                        )}{" "}
                    </div>
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Venue</div>
                    <div className="mt-3 col-10 col-lg-3">
                        {venueLoaded ? (
                            <LookupTypeListDynamic
                                lookupTypeIndex={LookupTypeIndexes.venueType}
                                onValueChanged={this.handleChangeVenueTypeSelect}
                                isRoot={false}
                                parentMappingId={this.state.clientId}
                                value={this.state.venueId}
                                displayExpression={"value"}
                                
                            />
                        ) : (
                            <span> Loading... </span>
                        )}{" "}
                    </div>
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Service</div>
                    <div className="mt-3 col-10 col-lg-3">
                        {serviceLoaded ? (
                            <SelectBox
                                dataSource={serviceDataSource}
                                displayExpr={"value"}
                                valueExpr={"id"}
                                value={this.state.serviceId}
                                onValueChanged={this.handleChangeServiceTypeSelect}
                            />
                        ) : (
                            <span> Loading... </span>
                        )}{" "}
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Date From</div>
                    <div className="mt-3 col-10 col-lg-3">
                        <DateBox
                            onValueChanged={this.handleChangeStartDate}
                            value={this.state.startDate ? this.state.startDate : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Date To</div>
                    <div className="mt-3 col-10 col-lg-3">
                        <DateBox
                            onValueChanged={this.handleChangeEndDate}
                            value={this.state.endDate ? this.state.endDate : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-2">
                        <button className="btn btn-primary btn--large" type="submit" onClick={this.refreshGrid}>
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            Apply
                        </button>
                    </div>
                </div>
                <div></div>
                <div>
                    <br></br>
                    <br></br>
                    {clientLoaded && periodMatrixLoaded && serviceDataSource && venueDataSource && (
                        <BudgetGrid
                            clientDataSource={clientDataSource}
                            clientDataSourceAll={clientDataSourceAll}
                            venueNameDataSourceAll={venueNameDataSourceAll}
                            periodMatrixDataSource={periodMatrixDataSource}
                            serviceTypeDataSource={serviceDataSource}
                            venueDataSource={venueDataSource}
                            houseNoDataSource={houseNoDataSource}
                            venueNameDataSource={venueNameDataSource}
                            clientId={clientId}
                            venueId={venueId}
                            serviceId={serviceId}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            refreshSignal={refreshSignal}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default Budget;
