import { PeriodFormResponse, PeriodFormRequest } from "../../services/MatrixService";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;

export interface AddEditPeriodMatrixFormData {
    id: string;
    businessEntityId: string;
    daysOfWeekId: string;
    periodNumber: string;
    periodWeek: string;
    dateFrom: NullableDate;
    dateTo: NullableDate;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    isActive: boolean;
    yearNumber: string;
}

export default class periodMatrixFormUtil {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializePeriodFormItem = this.initializePeriodFormItem.bind(this);
        this.convertPeriodMatrixItemResponeToFormData = this.convertPeriodMatrixItemResponeToFormData.bind(this);
    }

    initializePeriodFormItem(idFromGrid: string): AddEditPeriodMatrixFormData {
        var formData: AddEditPeriodMatrixFormData = {
            id: "0",
            businessEntityId: "",
            daysOfWeekId: "",
            periodNumber: "",
            periodWeek: "",
            dateFrom: undefined,
            dateTo: undefined,
            lastUpdatedOn: "",
            lastUpdatedBy: "",
            isActive: true,
            yearNumber: "",
        };

        return formData;
    }

    // Convert the server response into the component's form state
    convertPeriodMatrixItemResponeToFormData(serverResponse: PeriodFormResponse): AddEditPeriodMatrixFormData {
        var formData: AddEditPeriodMatrixFormData = {
            id: serverResponse.id,
            businessEntityId: serverResponse.businessEntityId,
            daysOfWeekId: serverResponse.daysOfWeekId,
            periodNumber: serverResponse.periodNumber,
            periodWeek: serverResponse.periodWeek,
            dateFrom: this.utils.convertStringToDate(serverResponse.dateFrom),
            dateTo: this.utils.convertStringToDate(serverResponse.dateTo),
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            lastUpdatedBy: serverResponse.lastUpdatedBy,
            isActive: serverResponse.isActive,
            yearNumber: serverResponse.yearNumber,
        };

        return formData;
    }

    // Convert the form's data into a proper item submit request
    convertFormDataToPeriodMatrixItemRequest(formData: AddEditPeriodMatrixFormData): PeriodFormRequest {
        var submitRequest: PeriodFormRequest = {
            id: formData.id,
            businessEntityId: formData.businessEntityId,
            daysOfWeekId: formData.daysOfWeekId,
            periodNumber: formData.periodNumber,
            periodWeek: formData.periodWeek,
            dateFrom: this.utils.convertDateToString(formData.dateFrom),
            dateTo: this.utils.convertDateToString(formData.dateTo),
            isActive: formData.isActive,
            yearNumber: formData.yearNumber,
        };

        return submitRequest;
    }

    //A helper function that would check the Validation for "Name in case of Rate Matrix Grid.
    nameValidation = (typeId: string, typeValue: string, name: string): boolean => {
        var validationSuccess: boolean = true;
        var nameField = name ? name.trim() : "";
        if (typeId && typeValue) {
            if (!nameField || (nameField && nameField.split(" ").length != 2)) {
                validationSuccess = false;
            }
        }
        return validationSuccess;
    };

    shortNameValidation = (typeId: string, typeValue: string, name: string): boolean => {
        var isValid: boolean = false;
        var nameField = name ? name.trim() : "";
        if (typeId && typeValue) {
            if (nameField) {
                isValid = true;
            }
        }
        return isValid;
    };
}
