import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError, _delete } from "./BaseApiService";

const VENUE_PROVIDER_API = "VenueProviderService";

type NullableBool = boolean | null | undefined;
export interface VenueProviderGridRowItem {
    id: string;
    venueId: string;
    venueName: string;
    houseNo: string;
    providerBusinessEntityId: string;
    providerBusinessEntity: string;
    clientBusinessEntityId: string;
    clientBusinessEntity: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    isActive: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    validationErrors: string;
}

export interface VenueProviderFormResponse {
    id: string;
    venueId: string;
    venueName: string;
    houseNo: string;
    providerBusinessEntityId: string;
    providerBusinessEntity: string;
    clientBusinessEntityId: string;
    clientBusinessEntity: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    isActive: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    validationErrors: string;
    city: string;
    county: string;
    postCode: string;
}

export interface VenueProviderFormRequest {
    id: string;
    venueId: string;
    venueName: string;
    houseNo: string;
    providerBusinessEntityId: string;
    providerBusinessEntity: string;
    clientBusinessEntityId: string;
    clientBusinessEntity: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    isActive: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
}
export const providerservice = {
    venueProvider: "venueProvider",
};
class VenueProviderService {
    //#region VenueServiceProvider Service Call
    getVenueProviderData(id: string): Promise<AxiosResponse<any>> {
        return _get(`${VENUE_PROVIDER_API}/${id}`);
    }
    deleteVenueProviderDataItem(id: number): Promise<AxiosResponse<any>> {
        return _delete(`${VENUE_PROVIDER_API}/${id}`);
    }
    getVenueProviderDataItem(): Promise<AxiosResponse<any>> {
        return _get(VENUE_PROVIDER_API);
    }
    saveVenueHierarchyDataItem(venueProviderObject: object) {
        return _post(VENUE_PROVIDER_API, venueProviderObject);
    }
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }

    //#endregion
}

export default VenueProviderService;
