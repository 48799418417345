import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import DropDownFromLookupTable from "../components/select/SelectBoxFromLookupTable";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import { LookupTypeIndexes } from "../services/LookupService";
import { LookUpOnValueChangedEvent, SelectBoxOnValueChangedEvent } from "../types/DevExtremeTypes";
import DesignSystemNavigation from "./DesignSystemNavigation";
import LookupTypeListDynamic from "../components/select/LookupTypeListDynamic";

interface VenueLookUpDesignProps extends WithTranslation {
    
}

interface VenueLookUpDesignState {
    clientId: string;
    venueId: string;
}

class VenueLookUpDesign extends Component<VenueLookUpDesignProps> {
    state: VenueLookUpDesignState;
    constructor(props: VenueLookUpDesignProps) {
        super(props);
        this.state = {
            clientId: "",
            venueId: "",
        }
    }

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            clientId: dxValueChange.value,
            venueId: ""
        });
    };

    handleChangeVenueSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            venueId: dxValueChange.value,
        });
    };

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-4 pt-3 pt-md-0">
                                    <div className="row">
                                        <label className="col-12 col-xl-4 mb-0 pt-1">
                                        {this.props.t("addEditShiftRow.summarySection.clientDropdown.label")}
                                        </label>
                                        <div className="col-12 col-xl-8">
                                            <DropDownFromLookupTable
                                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                                    onValueChanged={this.handleChangeClientTypeSelect}
                                                    value={this.state.clientId}
                                                />
                                                <input
                                                    data-testid={LookupTypeIndexes.clientType}
                                                    type="hidden"
                                                    name={LookupTypeIndexes.clientType}
                                                ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 pt-3 pt-md-0">
                                    <div className="row">
                                        <label className="col-12 col-xl-4 mb-0 pt-1">
                                            {this.props.t("addEditShiftRow.summarySection.venueDropdown.label")}
                                        </label>
                                        <div className="col-12 col-xl-8">
                                            <LookupTypeListDynamic
                                                lookupTypeIndex={LookupTypeIndexes.venueType}
                                                onValueChanged={this.handleChangeVenueSelect}
                                                isRoot={false}
                                                parentMappingId={this.state.clientId}
                                                value={this.state.venueId}
                                                displayExpression={"value"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withTranslation()(VenueLookUpDesign);