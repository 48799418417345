import React from "react";
import { AxiosResponse } from "axios";
import SupplierInformationPageGrid from "../SupplierInformation/SupplierInformationPageGrid";
import SupplierInformationService, {
    RateResponse,
    EnhancementResponse,
    ExceptionalRatesResponse,
} from "../../services/SupplierInformationService";
import SupplierInformationUtils, { SupplierInformationData } from "../SupplierInformation/SupplierInformationUtils";
import { LoadIndicator } from "devextreme-react";

interface ApprovedRatingComponentProps {
    clientId: string;
    venueId: string;
    dataSource: SupplierInformationData | null;
}
// State
interface ClientInformationMainContentState {
    supplierInformationDataSource: SupplierInformationData;
    rateDataSource: RateResponse[];
    exceptionalRateGridDataSource: ExceptionalRatesResponse[];
    showLoadIndicator: boolean;
}

class ApprovedRatingComponent extends React.Component<ApprovedRatingComponentProps> {
    state: ClientInformationMainContentState;
    service: SupplierInformationService;
    utils: SupplierInformationUtils;
    constructor(props: ApprovedRatingComponentProps) {
        super(props);
        this.service = new SupplierInformationService();
        this.utils = new SupplierInformationUtils();
        var convertedSupplierInformationData = this.utils.initializeSupplierInformationItem();
        this.state = {
            supplierInformationDataSource: convertedSupplierInformationData,
            rateDataSource: [],
            exceptionalRateGridDataSource: [],
            showLoadIndicator: false,
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        if (this.props.dataSource) {
            this.setState({
                supplierInformationDataSource: this.props.dataSource,
                showLoadIndicator: false,
            });
            this.dataPreparation(
                this.props.dataSource.personnelRates,
                this.props.dataSource.equipmentRates,
                this.props.dataSource.exceptionalRates
            );
        } else if (this.props.clientId && this.props.venueId) {
            this.getSupplierInfoDataSource();
        } else {
            this.setState({
                supplierInformationDataSource: null,
                rateDataSource: null,
                exceptionalRateGridDataSource: null,
                showLoadIndicator: true,
            });
        }
    }

    getSupplierInfoDataSource() {
        if (this.props.clientId && this.props.venueId) {
            this.setState({
                showLoadIndicator: true,
            });
            this.service
                .getApprovedRatingInformation(parseInt(this.props.clientId), parseInt(this.props.venueId))
                .then(this.handleSuccessSupplierInformationData)
                .catch((error) => {
                    this.setState({
                        showLoadIndicator: false,
                    });
                });
        } else {
            this.setState({
                supplierInformationDataSource: null,
                rateDataSource: null,
                exceptionalRateGridDataSource: null,
            });
        }
    }

    componentDidUpdate(prevProps: ApprovedRatingComponentProps) {
        if (this.props.clientId != prevProps.clientId || this.props.venueId != prevProps.venueId) {
            this.getSupplierInfoDataSource();
        }
    }
    handleSuccessSupplierInformationData = (response: AxiosResponse<any>) => {
        this.setState(
            {
                supplierInformationDataSource: response.data.data,
                showLoadIndicator: false,
            },
            () =>
                this.dataPreparation(
                    this.state.supplierInformationDataSource.personnelRates,
                    this.state.supplierInformationDataSource.equipmentRates,
                    this.state.supplierInformationDataSource.exceptionalRates
                )
        );
    };

    dataPreparation = (
        personnelRates: RateResponse[],
        equipmentRates: RateResponse[],
        exceptionalRates: ExceptionalRatesResponse[]
    ) => {
        var enhancementArr: RateResponse[] = [];
        if (personnelRates && personnelRates.length > 0) {
            personnelRates.forEach((item: any, uniqueKey: number) => {
                enhancementArr.push(item);
            });
        }
        if (equipmentRates && equipmentRates.length > 0) {
            equipmentRates.forEach((item: any, uniqueKey: number) => {
                enhancementArr.push(item);
            });
        }
        this.setState({
            rateDataSource: enhancementArr,
        });

        if (exceptionalRates && exceptionalRates.length > 0) {
            exceptionalRates.forEach((item: any, uniqueKey: number) => {
                this.setState({
                    exceptionalRateGridDataSource: exceptionalRates,
                });
            });
        }
    };

    render() {
        var enhancementData: EnhancementResponse;
        var enhancementDataArr: EnhancementResponse[] = [];
        var enhancementDataPart1: string = "";
        var enhancementDataPart2: string = "";
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.enhancements.length > 0
        ) {
            this.state.supplierInformationDataSource.enhancements.forEach((item: any, uniqueKey: number) => {
                var parts = item.enhancementText.split("x");
                enhancementDataPart1 = parts[0];
                enhancementDataPart2 = parts[1];
                enhancementData = {
                    date: item.date,
                    multiplier: item.multiplier,
                    enhancementTextPart1: enhancementDataPart1,
                    enhancementTextPart2: enhancementDataPart2,
                };
                enhancementDataArr.push(enhancementData);
            });
        }
        var heading: string = "";
        if (this.props.clientId && this.props.venueId) {
            heading = "Approved Rating for Selected Venue";
        } else {
            heading = "Approved Rating";
        }
        return this.state.showLoadIndicator && this.props.clientId && this.props.venueId ? (
            <LoadIndicator
                visible={this.state.showLoadIndicator}
                id="button-indicator"
                height={60}
                width={60}
                className="home-page__todayloader"
            ></LoadIndicator>
        ) : (
            <>
                <hr className="horizontal_border_style"></hr>
                <h3 className="page-title--medium">{heading}</h3>
                <div className="row">
                    <div className="col-12 col-md-4 vertical-line">
                        <h6>
                            <b>Rates</b>
                        </h6>
                        {this.state.rateDataSource && this.state.rateDataSource.length > 0
                            ? this.state.rateDataSource.map((item: any, uniqueKey: number) => {
                                  return (
                                      <span key={uniqueKey}>
                                          <strong key={item.typeLookValue}>{item.typeLookValue}: </strong>
                                          {item.rateText}
                                          <br />
                                      </span>
                                  );
                              })
                            : null}
                    </div>
                    <div className="col-12 col-md-4 vertical-line">
                        <h6>
                            <b>Enhancements</b>
                        </h6>
                        {enhancementDataArr && enhancementDataArr.length > 0
                            ? enhancementDataArr.map((item: any, uniqueKey: number) => {
                                  return (
                                    <span key={uniqueKey}>
                                        <strong key={item.date}>{item.enhancementTextPart1}</strong>{" "}
                                        {this.props.clientId && this.props.venueId ? "x " : ""}{" "}
                                        {item.enhancementTextPart2}
                                        <br />
                                    </span>
                                  );
                              })
                            : null}
                    </div>
                    <div className="col-12 col-md-4">
                        <h6>
                            <b>Understandings</b>
                        </h6>
                        {this.state.supplierInformationDataSource && this.state.supplierInformationDataSource.venue
                            ? this.state.supplierInformationDataSource.venue.contact
                                ? this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity
                                    ? this.state.supplierInformationDataSource.venue.contact
                                          .securityProviderBusinessEntity.dsProviderInformation
                                    : null
                                : null
                            : null}
                    </div>
                </div>
                <hr className="horizontal_border_style"></hr>
                <h3 className="page-title--medium">Exceptional Rating (if applicable)</h3>
                <SupplierInformationPageGrid
                    exceptionalRateGridDataSource={this.state.exceptionalRateGridDataSource}
                ></SupplierInformationPageGrid>
            </>
        );
    }
}

export default ApprovedRatingComponent;
