import React, { Component } from "react";
import BillableItemHeaderService from "../../services/BillableItemHeaderService";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import { SelectBox, DateBox, CheckBox } from "devextreme-react";
import {
    SelectBoxOnValueChangedEvent,
    DateBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
} from "../../types/DevExtremeTypes";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import UploadedFileContentsGrid from "../grid/UploadedFileContentsGrid";
import { uploadedFileType } from "../../services/FileService";
import sharedUtils from "../grid/sharedUtilities";

// Props
interface ShiftImportWarningsProps {}

interface ShiftImportWarningsState {
    clientLoaded: boolean;
    clientDataSource: LookupTypeItem[];
    providerLoaded: boolean;
    providerDataSource: LookupTypeItem[];
    clientId: string;
    providerId: string;
    startDate: NullableDate;
    endDate: NullableDate;
    acknowledged: boolean;
    subServiceLoaded: boolean;
    subServiceDataSource: LookupTypeItem[];
    subServiceId: string;
    dateFrom: string;
    dateTo: string;
    refreshSignal: boolean;
}

class ShiftImportWarnings extends Component<ShiftImportWarningsProps> {
    state: ShiftImportWarningsState;
    service: BillableItemHeaderService;
    lookupService: LookupService;
    sharedUtils: sharedUtils;
    constructor(props: ShiftImportWarningsProps) {
        super(props);
        this.service = new BillableItemHeaderService();
        this.lookupService = new LookupService();
        this.sharedUtils = new sharedUtils();
        this.state = {
            clientLoaded: false,
            clientDataSource: [],
            providerLoaded: false,
            providerDataSource: [],
            subServiceLoaded: false,
            subServiceDataSource: [],
            subServiceId: SubServiceType.DoorSupervisionPersonnel,
            clientId: "",
            providerId: "",
            startDate: null,
            endDate: null,
            acknowledged: false,
            dateFrom: "",
            dateTo: "",
            refreshSignal: false,
        };
    }

    componentDidMount() {
        this.retrieveLookupValues(LookupTypeIndexes.clientType);
        this.retrieveLookupValues(LookupTypeIndexes.providerType);
        this.retrieveLookupValues(LookupTypeIndexes.subServiceType);
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleLookupSuccessForClient)
                    .catch((error) => {
                        this.setState({
                            clientLoaded: true,
                            clientDataSource: [],
                        });
                    });
                break;
            case LookupTypeIndexes.subServiceType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.subServiceType)
                    .then(this.handleLookupSuccessForSubService)
                    .catch((error) => {
                        this.setState({
                            subServiceLoaded: true,
                            subServiceDataSource: [],
                        });
                    });
                break;
            case LookupTypeIndexes.providerType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.providerType)
                    .then(this.handleProviderTypeLookupSuccess)
                    .catch((error) => {
                        this.setState({
                            providerLoaded: true,
                            providerDataSource: [],
                        });
                    });
                break;
        }
    };

    handleLookupSuccessForSubService = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            this.setState({
                subServiceLoaded: true,
                subServiceDataSource: response.data.data.filter(
                    (item: LookupTypeItem) => item.parentMappingId === ServiceType.Security
                ),
            });
        }
    };

    handleLookupSuccessForClient = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            this.setState({
                clientLoaded: true,
                clientDataSource: response.data.data,
            });
        }
    };

    handleProviderTypeLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            this.setState({
                providerLoaded: true,
                providerDataSource: response.data.data,
            });
        }
    };

    //Handle change functions
    handleChangeSubServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            subServiceId: dxValueChange.value,
        });
    };

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            clientId: dxValueChange.value,
        });
    };

    handleChangeProviderTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            providerId: dxValueChange.value,
        });
    };

    handleChangeStartDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            startDate: dxValueChange.value,
            dateFrom: this.sharedUtils.convertDateToString(dxValueChange.value),
        });
    };

    handleChangeEndDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            endDate: dxValueChange.value,
            dateTo: this.sharedUtils.convertDateToString(dxValueChange.value),
        });
    };

    checkBoxValueChanged = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            acknowledged: dxValueChange.value,
        });
    };

    //A Helper function that refreshes the Grid based on the filters.
    refreshGrid = () => {
        this.setState((prevState: ShiftImportWarningsState) => ({
            refreshSignal: !prevState.refreshSignal,
        }));
    };

    render() {
        const {
            clientLoaded,
            clientDataSource,
            providerLoaded,
            providerDataSource,
            acknowledged,
            subServiceLoaded,
            subServiceDataSource,
            subServiceId,
            clientId,
            providerId,
            dateFrom,
            dateTo,
            refreshSignal,
        } = this.state;
        return (
            <div>
                <h3>
                    <strong>Shift Import Warnings</strong>
                </h3>
                <hr className="horizontal_border_style"></hr>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Type</div>
                    <div className="mt-3 col-10 col-lg-3">
                        {subServiceLoaded ? (
                            <SelectBox
                                dataSource={subServiceDataSource}
                                displayExpr={"value"}
                                valueExpr={"id"}
                                value={this.state.subServiceId}
                                onValueChanged={this.handleChangeSubServiceTypeSelect}
                            />
                        ) : (
                            <span> Loading... </span>
                        )}{" "}
                    </div>
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Provider</div>
                    <div className="mt-3 col-10 col-lg-3">
                        {providerLoaded ? (
                            <SelectBox
                                dataSource={providerDataSource}
                                displayExpr={"value"}
                                valueExpr={"id"}
                                value={this.state.providerId}
                                onValueChanged={this.handleChangeProviderTypeSelect}
                            />
                        ) : (
                            <span> Loading... </span>
                        )}{" "}
                    </div>
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Client</div>
                    <div className="mt-3 col-10 col-lg-3">
                        {clientLoaded ? (
                            <SelectBox
                                dataSource={clientDataSource}
                                displayExpr={"value"}
                                valueExpr={"id"}
                                value={this.state.clientId}
                                onValueChanged={this.handleChangeClientTypeSelect}
                            />
                        ) : (
                            <span> Loading... </span>
                        )}{" "}
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Acknowledged</div>
                    <div className="mt-3 col-10 col-lg-3 align-self-center">
                        <CheckBox value={acknowledged} onValueChanged={this.checkBoxValueChanged} width={100} />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Date From</div>
                    <div className="mt-3 col-10 col-lg-3">
                        <DateBox
                            onValueChanged={this.handleChangeStartDate}
                            value={this.state.startDate ? this.state.startDate : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 align-self-center font-weight-bold">Date To</div>
                    <div className="mt-3 col-10 col-lg-3">
                        <DateBox
                            onValueChanged={this.handleChangeEndDate}
                            value={this.state.endDate ? this.state.endDate : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-2">
                        <button className="btn btn-primary btn--large" type="submit" onClick={this.refreshGrid}>
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            Apply
                        </button>
                    </div>
                </div>
                <div></div>
                <div>
                    <br></br>
                    <br></br>
                    <UploadedFileContentsGrid
                        IsShowColumn={subServiceId}
                        isChildGridVisible={true}
                        headerId={""}
                        pointOfNavigation={""}
                        clientId={clientId}
                        providerId={providerId}
                        typeId={subServiceId}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        isAcknowledged={acknowledged}
                        hideTab={true}
                        refreshSignal={refreshSignal}
                    />
                </div>
            </div>
        );
    }
}

export default ShiftImportWarnings;
