import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { faPen as faPenSolid } from "@fortawesome/pro-solid-svg-icons";
import { Status } from "../../services/AddEditPopUpService";
import { LoadPanel } from "devextreme-react";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { Link } from "react-router-dom";
import BaseActionCell, { BaseActionCellProps } from "./BaseActionCell";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import GlobalService from "../../services/GlobalService";
import { GridTypes } from "../../services/SimpleGridService";
class ChangeActionCell extends BaseActionCell {
    constructor(props: BaseActionCellProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.data && this.props.data.data) {
            var statusColumnIndex: number = this.props.data.component.getVisibleColumnIndex("statusValue");
            var disableQueryIcon: boolean = false;
            var noIcon: boolean = false;
            const isEventUk =
                UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
            const isProvider = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler);
            const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
            var pendingIconVisible = this.state.pendingIconVisible;
            var futureShift: boolean = false;
            var tenseIdentifier: string = this.props.data.data.tenseFlag;
            var statusId: string = this.props.data.data.statusId;
            var approvalDate: string = this.props.data.data.approvalDate;
            var acceptanceDate: string = this.props.data.data.acceptDate;
            var itemTypeId: string =
                this.props && this.props.data && this.props.data.data ? this.props.data.data.itemTypeId : "";
            var iconDisabled: boolean = false;
            var iconDisable: boolean = false;
            var isBilledItem: boolean = false;
            if (tenseIdentifier && tenseIdentifier.trim().toLowerCase() == "future") {
                iconDisabled = true;
                futureShift = true;
            } else {
                if (
                    (tenseIdentifier && tenseIdentifier.trim().toLowerCase() == "past" &&
                        isEventUk &&
                        statusId &&
                        statusId == Status.Pending &&
                        this.props.data.data.statusValue == "Pending") ||
                    (isEventUk &&
                        statusId &&
                        statusId == Status.PendingAcceptance &&
                        this.props.data.data.statusValue == "Pending Acceptance")
                ) {
                    iconDisabled = iconDisable;
                    pendingIconVisible = true;
                } else if (
                    (statusId && statusId == Status.Approved) ||
                    (statusId && statusId == Status.Accepted) ||
                    this.props.data.data.statusValue == "Approved" ||
                    this.props.data.data.statusValue == "Accepted" ||
                    approvalDate ||
                    acceptanceDate
                ) {
                    iconDisabled = true;
                    pendingIconVisible = false;
                } else if (this.props.data.data.invoiceId || this.props.data.data.paymentId) {
                    iconDisabled = true;
                    pendingIconVisible = false;
                    isBilledItem = true;
                } else if (
                    (isVenueManager && itemTypeId && itemTypeId == itemTypes.AdjustmentProviderDebit) ||
                    (isProvider &&
                        itemTypeId &&
                        itemTypeId == itemTypes.AdjustmentClientDebit &&
                        tenseIdentifier.trim().toLowerCase() == "past")
                ) {
                    iconDisabled = true;
                    pendingIconVisible = true;
                } else {
                    noIcon = true;
                }
            }
            if (this.props.data && this.props.data.data.queries && this.props.data.data.queries.length > 0) {
                disableQueryIcon = this.costReportUtils.disableRaiseQueryIcon(this.props.data.data.queries);
            }
            this.setState({
                disableQueryIcon: disableQueryIcon,
                futureShift: futureShift,
                pendingIconVisible: pendingIconVisible,
                iconDisabled: iconDisabled,
                noIconToDisplay: noIcon,
                isBilledItem: isBilledItem,
            });
            if (
                this.props.data &&
                (this.props.data.data.itemTypeId == itemTypes.ClientCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderDebit ||
                    this.props.data.data.itemTypeId == itemTypes.Receivable ||
                    this.props.data.data.itemTypeId == itemTypes.Payable ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit)
            ) {
                this.setState({
                    isFinancialAdjustmentView: true,
                });
            }
            if (
                (this.props.data && this.props.data.data && this.props.data.data.editAllowed && this.props.data.data.editAllowed.toLowerCase() == "false") ||
                (UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) &&
                    (this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
                        this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit))
            ) {
                this.setState({
                    isEditIconDisable: true,
                });
            }
            if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
                if (
                    ((this.props.data.data.itemTypeId == itemTypes.Original ||
                        this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebit ||
                        this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebit) &&
                        (this.props.data.data.approvalDate ||
                            this.props.data.data.acceptDate ||
                            this.props.data.data.invoiceId)) ||
                    this.props.data.data.statusValue == "Approved" ||
                    this.props.data.data.statusValue == "Accepted"
                ) {                    
                    if (!this.props.data.data.childRecordId && this.props.data.data.isAccrual && !this.props.data.data.paymentId) {
                        this.setState({
                            isAdjustmentChange: false, 
                        });
                    }
                    else {
                        this.setState({
                            isAdjustmentChange: true,
                        });
                        if (this.props.data.data.subTypeId == SubServiceType.AdditionalCleaning ||
                            this.props.data.data.serviceTypeId == ServiceType.Gardening ||
                            this.props.data.data.serviceTypeId == ServiceType.WindowCleaning ||
                            this.props.data.data.serviceTypeId == ServiceType.Maintenance ||
                            this.props.data.data.serviceTypeId == ServiceType.Miscelleanous) {
                            this.setState({                  
                                isEditIconDisable: true
                            });
                        }
                    }
                }
            }

            if (this.props.data.data.subTypeId == SubServiceType.AdditionalCleaning ||
                this.props.data.data.serviceTypeId == ServiceType.Gardening ||
                this.props.data.data.serviceTypeId == ServiceType.WindowCleaning ||
                this.props.data.data.serviceTypeId == ServiceType.Maintenance ||
                this.props.data.data.serviceTypeId == ServiceType.Miscelleanous) {
                this.setState({
                    isAdjustmentChange: false,
                });
            }

            this.setState({
                statusColumnIndexVal: statusColumnIndex,
            });
        }
    }

    render() {
        var isEventUser =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        let billableItemId: string = "";
        if (this.props.data.data && this.props.data.data.billableItemId) {
            billableItemId = this.props.data.data.billableItemId.toString();
        }
        let gridType = GlobalService.getGridType();
        let pointOfNav: string = "";
        if (gridType && gridType == GridTypes.residencyShifts) {
            pointOfNav = "Residencies";
        }
        else if (gridType && gridType == GridTypes.queryForm) {
            pointOfNav = "QueryManageForm";
        }
        else if (gridType && gridType == GridTypes.contractCleaningShifts) {
            pointOfNav = "ContractCleaning";
        }
        else {
            pointOfNav = "SimpleShifts";
        }
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        message="Please wait..."
                        visible={this.state.loadPanelVisible}
                    />
                    {isEventUser ? (
                        <div>
                            {this.state.isEditIconDisable ? (
                                <Link title="Edit" className={"disabled icon-btn"} to="#">
                                    <FontAwesomeIcon icon={faPenSolid} />
                                </Link>
                            ) : (
                                <Link
                                    title="Edit"
                                    className={"icon-btn"}
                                    to={
                                        this.state.isFinancialAdjustmentView
                                            ? {
                                                pathname: "/FinanceAdjustmentPopUp",
                                                state: {
                                                    id: billableItemId,
                                                    adjustment: false,
                                                    isEdit: true,
                                                    pointOfNavigation: pointOfNav,
                                                    filterStartDate: this.props.data.data.filterStartDate,
                                                    filterEndDate: this.props.data.data.filterEndDate
                                                }
                                            }
                                            : this.state.isAdjustmentChange ?
                                                {
                                                    pathname: "/AddEditFormV2",
                                                    state: {
                                                        id: billableItemId,
                                                        isAdjust: this.state.isAdjustmentChange,
                                                        isEdit: true,
                                                        pointOfNavigation: pointOfNav,
                                                        filterStartDate: this.props.data.data.filterStartDate,
                                                        filterEndDate: this.props.data.data.filterEndDate
                                                    }
                                                } : {
                                                    pathname: "/QuickAddShiftForm",
                                                    state: {
                                                        id: billableItemId,
                                                        pointOfNavigation: pointOfNav,
                                                        filterStartDate: this.props.data.data.filterStartDate,
                                                        filterEndDate: this.props.data.data.filterEndDate
                                                    }
                                                }
                                    }
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </Link>
                            )}
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

export default ChangeActionCell;
