import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError } from "./BaseApiService";

const HIGHLEVEL_REQUEST_API = "Request";
const HIGH_LEVEL = "GetHighLevelGigs";
const SHOW_INTEREST = "Artist/ShowInterest";
const UNDO_INTEREST = "request/artist/undointerest";
const RANDOM_IMAGE = "Request/LinkImage";

export interface GigHighLevelData {
    typeId: string;
    typeValue: string;
    typeCount: string;
    requestItems: RequestType[] | null;
    validationErrors: ValidationErrorType[] | null;
}
export interface ValidationErrorType {
    columnName: string;
    errorType: string;
    errorMessage: string;
}
export interface GigMessageType {
    keyName: string;
    keyValue: string;
}
export interface ShowInterestType {
    id: string;
    providerBusinessEntityId: string;
    providerBusinessEntity: string;
    requestId: string;
    showInterestDateTime: string;
    showInterestStatusId: string;
    showInterestStatus: string;
    selectedOrderNumber: string;
}
export interface RequestType {
    id: string;
    startTime: string;
    finishTime: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    providerBusinessEntityId: string;
    clientBusinessEntityId: string;
    venueId: string;
    townCity: string;
    country: string;
    dateFrom: string;
    providerPayAmountLocal: string;
    configurableText: string;
    entertainmentDescription: string;
    additionalInformation: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    requestStatusId: string;
    requestStatusValue: string;
    budget?: string;
    equipmentRequired?: string;
    performanceInformationSetsNumber?: string;
    performanceInformationSetsDuration?: string;
    internalComments?: string;
    typeLookUpId?: string;
    typeLookUp?: string;
    messages: GigMessageType[] | null;
    showInterest: ShowInterestType[] | null;
    validationErrors: ValidationErrorType[] | null;
}

class GigHighLevelService {
    getGigHighLevelCardList(): Promise<AxiosResponse<any>> {
        return _get(`${HIGHLEVEL_REQUEST_API}/${HIGH_LEVEL}`);
    }

    getHighLevelCardData(id: string): Promise<AxiosResponse<any>> {
        return _get(`${HIGHLEVEL_REQUEST_API}/${id}`);
    }

    saveShowInterestStatus(id: string): Promise<AxiosResponse<any>> {
        return _post(`${HIGHLEVEL_REQUEST_API}/${SHOW_INTEREST}/${id}`);
    }

    undoInterested(id: string): Promise<AxiosResponse<any>> {
        return _post(`${UNDO_INTEREST}/${id}`);
    }

    getRandomImage(serviceTypeId: string, serviceSubTypeId: string, typeLookupId: string): Promise<AxiosResponse<any>> {
        return _get(`${RANDOM_IMAGE}?serviceId=${serviceTypeId}&serviceSubtypeId=${serviceSubTypeId}&typeLookupId=${typeLookupId}`);
    }

    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}
export default GigHighLevelService;
