import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { paymentStatus } from "../../services/PaymentService";

//#region props
interface PaymentStatusFlowProps extends WithTranslation {
    statusId: string;
    onRestartClicked: (isRestartClicked: boolean) => void;
    isRestartDisable: boolean;
}
//#endregion

//#region State
interface PaymentStatusFlowState {
    statusId: string;
    prepareStateCssClass: string;
    lockPaymentsStateCssClass: string;
    checkStateCssClass: string;
    produceStateCssClass: string;
    sentStateCssClass: string;
    notificationStateCssClass: string;
    emailNotificationStateCssClass:string;
    invoiceStateCssClass: string;
    paidStateCssClass: string;
    remittedStateCssClass: string;
    isRestartClicked: boolean;
    isRestartButtonDisable: boolean;
    loadPanelVisible: boolean;
}
//#endregion

// Component
class PaymentStatusFlow extends React.Component<PaymentStatusFlowProps> {
    state: PaymentStatusFlowState;
    constructor(props: PaymentStatusFlowProps) {
        super(props);

        this.state = {
            statusId: this.props.statusId,
            prepareStateCssClass: "",
            lockPaymentsStateCssClass: "",
            checkStateCssClass: "",
            produceStateCssClass: "",
            sentStateCssClass: "",
            notificationStateCssClass: "",
            emailNotificationStateCssClass:"",
            invoiceStateCssClass: "",
            paidStateCssClass: "",
            remittedStateCssClass: "",
            isRestartClicked: false,
            isRestartButtonDisable: false,
            loadPanelVisible: false,
        };
    }

    updateCssClasses = () => {
        const standardItemCssClass = "status-flow__progress-bar__item";
        const highlightItemCssClass = "status-flow__progress-bar__item--highlighted";
        const status = this.state.statusId;
        this.setState({
            prepareStateCssClass:
                status == paymentStatus.PaymentNotification ||
                status == paymentStatus.PaymentNotificationRequested ||
                status == paymentStatus.Pending ||
                status == paymentStatus.ReadyForPayment ||
                status == paymentStatus.PaymentNetted ||
                status == paymentStatus.PaymentChecked ||
                status == paymentStatus.PaymentPackRequested ||
                status == paymentStatus.PaymentPackReady ||
                status == paymentStatus.PaymentPackFailed ||
                status == paymentStatus.PaymentPaid ||
                status == paymentStatus.PaymentEmailNotificationRequested ||
                status == paymentStatus.PaymentEmailNotification || 
                status == paymentStatus.PaymentAdviseRequested ||
                status == paymentStatus.PaymentAdvised ||
                status == paymentStatus.PaymentSettled ||
                status == paymentStatus.PaymentRemittanceRequested ||
                status == paymentStatus.PaymentRemitted
                    ? highlightItemCssClass
                    : standardItemCssClass,
            lockPaymentsStateCssClass:
                status == paymentStatus.PaymentNotification ||
                status == paymentStatus.PaymentNotificationRequested ||
                status == paymentStatus.PaymentNetted ||
                status == paymentStatus.PaymentChecked ||
                status == paymentStatus.PaymentPackRequested ||
                status == paymentStatus.PaymentPackReady ||
                status == paymentStatus.PaymentPackFailed ||
                status == paymentStatus.PaymentPaid ||
                status == paymentStatus.PaymentEmailNotificationRequested ||
                status == paymentStatus.PaymentEmailNotification || 
                status == paymentStatus.PaymentAdviseRequested ||
                status == paymentStatus.PaymentAdvised ||
                status == paymentStatus.PaymentSettled ||
                status == paymentStatus.PaymentRemittanceRequested ||
                status == paymentStatus.PaymentRemitted
                    ? highlightItemCssClass
                    : standardItemCssClass,
            checkStateCssClass:
                status == paymentStatus.PaymentNotification ||
                status == paymentStatus.PaymentNotificationRequested ||
                status == paymentStatus.PaymentChecked ||
                status == paymentStatus.PaymentPackRequested ||
                status == paymentStatus.PaymentPackReady ||
                status == paymentStatus.PaymentPackFailed ||
                status == paymentStatus.PaymentPaid ||
                status == paymentStatus.PaymentEmailNotificationRequested ||
                status == paymentStatus.PaymentEmailNotification || 
                status == paymentStatus.PaymentAdviseRequested ||
                status == paymentStatus.PaymentAdvised ||
                status == paymentStatus.PaymentSettled ||
                status == paymentStatus.PaymentRemittanceRequested ||
                status == paymentStatus.PaymentRemitted
                    ? highlightItemCssClass
                    : standardItemCssClass,
            produceStateCssClass:
                status == paymentStatus.PaymentNotification ||
                status == paymentStatus.PaymentNotificationRequested ||
                status == paymentStatus.PaymentPackReady ||
                status == paymentStatus.PaymentPaid ||
                status == paymentStatus.PaymentEmailNotificationRequested ||
                status == paymentStatus.PaymentEmailNotification || 
                status == paymentStatus.PaymentAdviseRequested ||
                status == paymentStatus.PaymentAdvised ||
                status == paymentStatus.PaymentSettled ||
                status == paymentStatus.PaymentRemittanceRequested ||
                status == paymentStatus.PaymentRemitted
                    ? highlightItemCssClass
                    : standardItemCssClass,
            sentStateCssClass:
                status == paymentStatus.PaymentNotification ||
                status == paymentStatus.PaymentNotificationRequested ||
                status == paymentStatus.PaymentPaid ||
                status == paymentStatus.PaymentEmailNotificationRequested ||
                status == paymentStatus.PaymentEmailNotification || 
                status == paymentStatus.PaymentAdviseRequested ||
                status == paymentStatus.PaymentAdvised ||
                status == paymentStatus.PaymentSettled ||
                status == paymentStatus.PaymentRemittanceRequested ||
                status == paymentStatus.PaymentRemitted
                    ? highlightItemCssClass
                    : standardItemCssClass,
            notificationStateCssClass:
                status == paymentStatus.PaymentNotification ||
                status == paymentStatus.PaymentEmailNotificationRequested ||
                status == paymentStatus.PaymentEmailNotification || 
                status == paymentStatus.PaymentAdviseRequested ||
                status == paymentStatus.PaymentAdvised ||
                status == paymentStatus.PaymentSettled ||
                status == paymentStatus.PaymentRemittanceRequested ||
                status == paymentStatus.PaymentRemitted
                    ? highlightItemCssClass
                    : standardItemCssClass,
            emailNotificationStateCssClass:
                    status == paymentStatus.PaymentEmailNotification || 
                    status == paymentStatus.PaymentAdviseRequested ||
                    status == paymentStatus.PaymentAdvised ||
                    status == paymentStatus.PaymentSettled ||
                    status == paymentStatus.PaymentRemittanceRequested ||
                    status == paymentStatus.PaymentRemitted
                        ? highlightItemCssClass
                        : standardItemCssClass,
            
            invoiceStateCssClass:
                status == paymentStatus.PaymentAdvised ||
                status == paymentStatus.PaymentSettled ||
                status == paymentStatus.PaymentRemittanceRequested ||
                status == paymentStatus.PaymentRemitted
                    ? highlightItemCssClass
                    : standardItemCssClass,
            paidStateCssClass:
                status == paymentStatus.PaymentSettled ||
                status == paymentStatus.PaymentRemittanceRequested ||
                status == paymentStatus.PaymentRemitted
                    ? highlightItemCssClass
                    : standardItemCssClass,
            remittedStateCssClass:
                status == paymentStatus.PaymentRemitted ? highlightItemCssClass : standardItemCssClass,
            isRestartButtonDisable:
                status == paymentStatus.PaymentAdviseRequested ||
                status == paymentStatus.PaymentAdvised ||
                status == paymentStatus.PaymentSettled ||
                status == paymentStatus.PaymentRemittanceRequested ||
                status == paymentStatus.PaymentRemitted
                    ? true
                    : false,
        });
        if (status == paymentStatus.Pending) {
            this.setState({
                loadPanelVisible: false,
            });
        }
    };

    componentDidUpdate(prevProps: PaymentStatusFlowProps) {
        if (prevProps.statusId != this.props.statusId) {
            this.setState(
                {
                    statusId: this.props.statusId,
                },
                this.updateCssClasses
            );
        }
    }

    componentDidMount() {
        this.updateCssClasses();
    }

    onClickRestart = () => {
        this.setState(
            (prevState: PaymentStatusFlowState) => ({
                isRestartClicked: !prevState.isRestartClicked,
                loadPanelVisible: true,
            }),
            () => {
                this.props.onRestartClicked(this.state.isRestartClicked);
            }
        );
    };

    //#region render Component
    render() {
        return (
            <section className="status-flow">
                <ul className="status-flow__progress-bar">
                    <li className={this.state.prepareStateCssClass}>
                        <label>Prepare</label>
                    </li>
                    <li className={this.state.lockPaymentsStateCssClass}>
                        <label>Lock Payments</label>
                    </li>
                    <li className={this.state.checkStateCssClass}>
                        <label>Check</label>
                    </li>
                    <li className={this.state.produceStateCssClass}>
                        <label>Produce</label>
                    </li>
                    <li className={this.state.sentStateCssClass}>
                        <label>Sent</label>
                    </li>
                    <li className={this.state.notificationStateCssClass}>
                        <label>Notifications</label>
                    </li>
                    <li className={this.state.emailNotificationStateCssClass}>
                        <label>Email Notifications</label>
                    </li>
                    <li className={this.state.invoiceStateCssClass}>
                        <label>Invoice</label>
                    </li>
                    <li className={this.state.paidStateCssClass}>
                        <label>Paid</label>
                    </li>
                    <li className={this.state.remittedStateCssClass}>
                        <label>Remitted</label>
                    </li>
                </ul>
                {/* <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} /> */}
                <button
                    className="btn btn--ghost--teal status-flow__restart-button"
                    type="button"
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.onClickRestart();
                    }}
                    disabled={this.state.isRestartButtonDisable}
                >
                    <span className="btn__icon"></span>
                    Restart
                </button>
            </section>
        );
    }
}

//#endregion

export default withTranslation()(PaymentStatusFlow);
