import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import SelectBoxFromLookupTable from "../select/SelectBoxFromLookupTable";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { SelectBoxOnValueChangedEvent, DateBoxOnValueChangedEvent, LookUpOnValueChangedEvent } from "../../types/DevExtremeTypes";
import ScheduleService, { SchedulerItem } from "../../services/ScheduleService";
import { AxiosResponse } from "axios";
import BillingFormUtils from "../Billing/BillingFormUtils";
import sharedUtils from "../grid/sharedUtilities";
import UserService, { RoleGroupNames } from "../../services/UserService";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";

interface ScheduleProps extends WithTranslation {
    venueAll: boolean;
    navigateFrom: string;
    setParentFilterValuesCallback: (venueId: string, clientId: string, isClientVenueFound: boolean) => void;
}
interface ScheduleState {
    clientId: string;
    venueId: string;
    minDateValue: string;
    maxDateValue: string;
    schedulerData: SchedulerItem[];
    currentDate: Date;
    isLoad: boolean;
    periodMatrixData: any;
    period: string;
    week: string;
    dateFrom: Date;
    dateTo: Date;
    isShowDropDown: boolean;
}
class Schedule extends React.Component<ScheduleProps> {
    state: ScheduleState;
    utils: sharedUtils;
    billingUtils: BillingFormUtils;
    service: ScheduleService;
    lookUp: lookupService;
    constructor(props: ScheduleProps) {
        super(props);
        this.utils = new sharedUtils();
        this.service = new ScheduleService();
        this.lookUp = new lookupService();
        this.billingUtils = new BillingFormUtils();
        this.state = {
            clientId: "",
            venueId: "",
            minDateValue: "",
            maxDateValue: "",
            schedulerData: [],
            currentDate: new Date(),
            isLoad: true,
            periodMatrixData: {},
            period: "",
            week: "",
            dateFrom: new Date(),
            dateTo: new Date(),
            isShowDropDown: false,
        };
    }

    componentDidMount() {
        this.retrieveLookup(LookupTypeIndexes.clientType);
        this.retrieveLookup(LookupTypeIndexes.periodMatrix);
        // show dropdowns
        if (
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)
        ) {
            this.setState({
                isShowDropDown: true,
            });
        }
    }

    retrieveLookup = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookUp
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleClientLookUpSuccess)
                break;
            case LookupTypeIndexes.periodMatrix:
                this.lookUp
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.periodMatrix)
                    .then(this.handleSuccesPeriodMatrixLookUp)
                break;
            case LookupTypeIndexes.venueType:
                this.lookUp
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                    .then(this.handleVenueLookUpSuccess)
                    .catch(this.handleStandardError);
        }
    };

    handleClientLookUpSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            if (response.data.data.length == 0) {
                this.props.setParentFilterValuesCallback("", "", false);
            } else {
                this.setState(
                    {
                        clientId: response.data.data[0].id,
                    },
                    () => {
                        this.retrieveLookup(LookupTypeIndexes.venueType);
                    }
                );
            }
        }
    };

    handleStandardError = (response: AxiosResponse<any>) => {
        this.setState({
            isLoad: false,
        });
    };

    handleVenueLookUpSuccess = (response: AxiosResponse<any>) => {
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            !this.isBlank(this.state.clientId)
        ) {
            var venues: any[] = response.data.data;
            venues = venues.filter((item) => {
                return item.parentMappingId == this.state.clientId;
            });

            this.setState(
                {
                    venueId: venues[0].id,
                    isLoad: false,
                },
                () => {
                    this.props.setParentFilterValuesCallback(venues[0].id, this.state.clientId, true);
                }
            );
            this.dropDownToShow(response.data.data);
        }
    };

    dropDownToShow = (venueDataSource: LookupTypeItem[]) => {        
        if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            if (venueDataSource.length > 1) {
                this.setState({
                    isShowDropDown: true,
                });
            }
        }
    };
    handleSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data) {
            let data: SchedulerItem[] = response.data.data;
            let convertedDataPoints: SchedulerItem[] = [];
            // Build the data points
            data.forEach((item) => {
                convertedDataPoints.push({
                    count: item.count,
                    serviceType: item.serviceType,
                    serviceSubType: item.serviceSubType,
                    shiftStart: this.billingUtils.parseTimestamp(Date.parse(item.shiftStart.toString())),
                    shiftEnd: this.billingUtils.parseTimestamp(Date.parse(item.shiftEnd.toString())),
                    clientId: this.state.clientId,
                    venueId: this.state.venueId,
                });
            });
            // Save data points to state
            this.setState({
                schedulerData: convertedDataPoints,
                isLoad: false,
            });
        } else {
            this.setState({
                schedulerData: [],
                isLoad: false,
            });
        }
    };

    handleSuccesPeriodMatrixLookUp = (response: AxiosResponse<any>) => {
        if (response != null && response.data != null && response.data.data && response.data.data.length > 0) {
            this.setState({
                periodMatrixData: response.data.data ? response.data.data : [],
            });
            if (!this.isBlank(this.state.clientId)) {
                var currentPeriodData = this.billingUtils.getTodaysPeriodWeekAndDatesByClient(
                    this.state.clientId,
                    this.state.periodMatrixData,
                    this.state.currentDate
                );
                if (
                    currentPeriodData &&
                    currentPeriodData.dateFrom != undefined &&
                    currentPeriodData.dateTo != undefined
                ) {
                    this.setState({
                        period: currentPeriodData.period,
                        week: currentPeriodData.week,
                        dateFrom: currentPeriodData.dateFrom,
                        dateTo: currentPeriodData.dateTo,
                    });
                }
            }
        }
        this.setState({
            isLoad: false,
        });
    };

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var currentPeriodData = this.billingUtils.getTodaysPeriodWeekAndDatesByClient(
            dxValueChange.value,
            this.state.periodMatrixData,
            this.state.currentDate
        );
        this.setState({
            clientId: dxValueChange.value,
            venueId: "",
            period: currentPeriodData.period,
            week: currentPeriodData.week,
            dateFrom: currentPeriodData.dateFrom,
            dateTo: currentPeriodData.dateTo,
        });
        this.props.setParentFilterValuesCallback("", dxValueChange.value, true);
    };

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            venueId: dxValueChange.value,
        });
        this.props.setParentFilterValuesCallback(dxValueChange.value, this.state.clientId, true);
    };

    handleFailure = (error: any) => {
        var respMessage: string = "getScheduleData failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            schedulerData: "",
            isLoad: false,
        });
    };
    handleChangePeriod = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var firstWeekValue = "1";
        this.setState({
            period: dxValueChange.value,
            week: firstWeekValue,
            dateFrom: this.billingUtils.getDateFromPeriodMatrixTable(
                true,
                this.state.periodMatrixData,
                this.state.clientId,
                dxValueChange.value,
                firstWeekValue
            ),
            dateTo: this.billingUtils.getDateFromPeriodMatrixTable(
                false,
                this.state.periodMatrixData,
                this.state.clientId,
                dxValueChange.value,
                firstWeekValue
            ),
        });
    };

    handleChangeWeek = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            week: dxValueChange.value,
            dateFrom: this.billingUtils.getDateFromPeriodMatrixTable(
                true,
                this.state.periodMatrixData,
                this.state.clientId,
                this.state.period,
                dxValueChange.value
            ),
            dateTo: this.billingUtils.getDateFromPeriodMatrixTable(
                false,
                this.state.periodMatrixData,
                this.state.clientId,
                this.state.period,
                dxValueChange.value
            ),
        });
    };

    handleStartDateChange = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            dateFrom: dxValueChange.value,
        });
        this.handleChangeCalFromSelect(this.state.dateFrom);
    };
    handleChangeCalFromSelect = (date: Date) => {
        var currentPeriodData = this.billingUtils.getTodaysPeriodWeekAndDatesByClient(
            this.state.clientId,
            this.state.periodMatrixData,
            date
        );
        this.setState({
            period: currentPeriodData.period,
            week: currentPeriodData.week,
            dateFrom: currentPeriodData.dateFrom,
            dateTo: currentPeriodData.dateTo,
        });
    };
    handleFinishDateChange = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            dateTo: dxValueChange.value,
        });
    };
    isBlank(param: string) {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    }
    onAppointmentFormOpening(data: any) {
        let form = data.form;
        form.option("items", [{}]);
        let shiftStart = data.appointmentData.shiftStart;
        let shiftEnd = data.appointmentData.shiftEnd;

        var formattedStartTime =
            [shiftStart.getMonth() + 1, shiftStart.getDate(), shiftStart.getFullYear()].join("-") +
            " " +
            [shiftStart.getHours(), shiftStart.getMinutes()].join(":");
        var formattedEndTime =
            [shiftEnd.getMonth() + 1, shiftEnd.getDate(), shiftEnd.getFullYear()].join("-") +
            " " +
            [shiftEnd.getHours(), shiftEnd.getMinutes()].join(":");

        window.location.href =
            "/manageviewshift/?clientId=" +
            data.appointmentData.clientId +
            "&venueId=" +
            data.appointmentData.venueId +
            "&shiftStart=" +
            formattedStartTime +
            "&shiftEnd=" +
            formattedEndTime +
            "&isSchedulePage=" +
            true;
    }

    renderInsideVenueCard = () => {
        if (this.state.schedulerData && this.props.navigateFrom == "eventukhomepage") {
            return (
                <>
                    <div className="mb-3">
                        <label className="mb-0">
                            Client
                        </label>
                        <div>
                            <SelectBoxFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.clientType}
                                onValueChanged={this.handleChangeClientTypeSelect}
                                value={this.state.clientId}
                            />
                            <input
                                data-testid={LookupTypeIndexes.clientType}
                                type="hidden"
                                name={LookupTypeIndexes.clientType}
                            ></input>
                        </div>
                    </div>
                    <div>
                        <label className="mb-0">
                            Venue
                        </label>
                        <LookupTypeListDynamic
                            lookupTypeIndex={LookupTypeIndexes.venueType}
                            onValueChanged={this.handleChangeVenueTypeSelect}
                            isRoot={false}
                            parentMappingId={this.state.clientId}
                            value={this.state.venueId}
                            displayExpression={"value"}
                            itemAll={false}
                        />
                    </div>
                </>
            )
        }
    }

    render() {
        return (
            <>
                {this.renderInsideVenueCard()}
                {this.state.schedulerData && (this.props.navigateFrom == "homepage" || this.props.navigateFrom == "supplierinformation" || this.props.navigateFrom == "clientinformation") ? (
                    <div className="card mb-4" hidden={!this.state.isShowDropDown}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="row">
                                        <label className="col-12 col-xl-2 mb-0 pt-1">
                                            {this.props.t("addEditShiftRow.summarySection.clientDropdown.label")}
                                        </label>
                                        <div className="col-12 col-xl-10">
                                            <SelectBoxFromLookupTable
                                                lookupTypeIndex={LookupTypeIndexes.clientType}
                                                onValueChanged={this.handleChangeClientTypeSelect}
                                                value={this.state.clientId}
                                            />
                                            <input
                                                data-testid={LookupTypeIndexes.clientType}
                                                type="hidden"
                                                name={LookupTypeIndexes.clientType}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 pt-3 pt-md-0">
                                    <div className="row">
                                        <label className="col-12 col-xl-2 mb-0 pt-1">
                                            {this.props.t("addEditShiftRow.summarySection.venueDropdown.label")}
                                        </label>
                                        <div className="col-12 col-xl-10">
                                            <LookupTypeListDynamic
                                                lookupTypeIndex={LookupTypeIndexes.venueType}
                                                onValueChanged={this.handleChangeVenueTypeSelect}
                                                isRoot={false}
                                                parentMappingId={this.state.clientId}
                                                value={this.state.venueId}
                                                displayExpression={"value"}
                                                itemAll={this.props.venueAll}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

export default withTranslation()(Schedule);
