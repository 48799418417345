import { AxiosError } from "axios";
import { alert, custom } from "devextreme/ui/dialog";
import { msalInstance } from "./AuthService";

export function HandleResponseForError(error: AxiosError) {
    if(error && error.isAxiosError) {
        //The initial group call response is handled within the App.tsx file
        if(!error.request.responseURL.endsWith("group")) {
            //if user is unauthorised/forbidden get them logged out and send to the log in screen
            if(error.response && error.response.status == 401) {
                displayAuthErrorMessage("Your session has expired", "Your session has expired, please re-login.");
            }
            else if (error.response && error.response.status == 403) {
                displayAuthErrorMessage("Issue with your user", "There may be an issue with your user, please re-login.");
            }
            //or, display generic error to user
            else if (error.response && error.response.status == 500) {
                alert("There has been an issue with this page. If the problem persists please <a href='/raiseQuery'>contact Event UK</a>.", "Unexpected System Error");
            }
        }
    }
    throw error;
}

const onClickLink = () => {
    sessionStorage.clear();
    msalInstance.logoutRedirect({
        account: msalInstance.getActiveAccount(),
        authority: 'login.microsoftonline.com'
    })
}

const displayAuthErrorMessage = (title: string, message: string) => {
    let customAlert = custom({
        title: title, 
        messageHtml: "<div class='text-center'>" + message + "</div>",
        buttons: [{
            text: "Switch User",
            onClick: onClickLink
        }]
    });

    customAlert.show();
}