import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import PaymentsGridProvider from "../PaymentsGridProvider";
import { paymentGridTypes } from "../../../services/PaymentService";
import LightModePageTemplate from "../../page/Templates/LightModeTemplate";
import SideBarTemplate from "../../side-bar/SideBarTemplate";
import PaymentSidebarPanel from "../ProviderPayment/PaymentSidebarPanel";
import BulletinPanel from "../../BulletinPanel/BulletinPanel";
import SimpleShiftsByService from "../../SimpleShiftsByService/SimpleShiftsByService";
import { ServiceType } from "../../../common/ServiceUtilities";

// import PaymentCycleGrid from "../Payments/PaymentCyclesGrid";

interface ArtistPaymentsPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

interface ArtistPaymentsPageState {
    gridRefreshSignal: boolean;
    paymentId: string;
    gigsGridVisible: boolean;
    providerId: string;
    startDate: string;
    endDate: string;
    serviceTypeId: string;
}

class ArtistPaymentsPage extends Component<ArtistPaymentsPageComponentProps> {
    //Initialize the component's state.
    state: ArtistPaymentsPageState;

    constructor(props: ArtistPaymentsPageComponentProps) {
        super(props);

        this.state = {
            gridRefreshSignal: false,
            paymentId: "",
            gigsGridVisible: false,
            providerId: "",
            startDate: "",
            endDate: "",
            serviceTypeId: "",
        };
    }
    //function that would indicate that a Grid refresh is required on click of the button.
    signalGridRefresh = () => {
        this.setState({ gridRefreshSignal: !this.state.gridRefreshSignal });
    };

    setPaymentId = (paymentId: string) => {
        if (paymentId) {
            this.setState({
                paymentId: paymentId,
                gigsGridVisible: true,
            });
        }
    };

    setSummary = () => {};
    setButtonStates = () => {};
    setTotalGigsCounter = () => {};
    setIndicatorStates = () => {};
    setCalculatedValue = () => {};
    parentIdSet = (
        providerId: string,
        startDate: string,
        endDate: string,
        serviceTypeId: string,
        paymentId: string
    ) => {
        this.setState({
            providerId: providerId ? providerId : "",
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
            serviceTypeId: serviceTypeId,
            paymentId: paymentId,
        });
    };
    render() {
        var serviceTypeId: string | null = sessionStorage.getItem("serviceTypeFilter");
        var serviceType: string = "";

        if (
            this.props.location.state &&
            this.props.location.state.burgerMenuName &&
            this.props.location.state.burgerMenuName == "ArtistPayment"
        ) {
            serviceType = " ";
        } else if (serviceTypeId == ServiceType.Entertainment) {
            serviceType = "Entertainment";
        }

        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    {/* <PaymentSidebarPanel
                        onApplyButtonClick={this.parentIdSet}
                        serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                        location={this.props.location}
                    ></PaymentSidebarPanel> */}
                    <BulletinPanel />
                </SideBarTemplate>
                <section className="page-content--with-sidebar-hidden-mobile">
                    <h2 className="page-title__black-table">{serviceType ? serviceType : null} Payments</h2>
                    <header className="grid-info mb-3">
                        <div className="grid-info__row mt-3 mb-3">
                            <div className="grid-info__button-container">
                                <button
                                    className={(true ? "" : "disabled") + "btn btn-primary btn--large"}
                                    onClick={this.signalGridRefresh}
                                >
                                    Refresh
                                </button>
                            </div>
                        </div>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            <PaymentsGridProvider
                                location={this.props.location}
                                history={this.props.history}
                                paymentsRefresh={this.state.gridRefreshSignal}
                                gridType={paymentGridTypes.artistPayment}
                                setPaymentIdOnParent={this.setPaymentId}
                                providerId={this.state.providerId}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                            />
                        </div>
                        <div />
                    </div>
                    <h5 className="font-weight-bold ml-3 mt-4">View Gigs Included In the Selected Payment</h5>
                    {
                        <>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <SimpleShiftsByService
                                        clientId={""}
                                        venueId={""}
                                        serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        setCalculations={this.setCalculatedValue}
                                        paymentId={this.state.paymentId}
                                        burgerMenuName={
                                            this.props.location.state && this.props.location.state.burgerMenuName
                                                ? this.props.location.state.burgerMenuName
                                                : ""
                                        }
                                        getBuild={() => { return null }}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ArtistPaymentsPage);
