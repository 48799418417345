import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CollapsibleCardGrid from "../components/Cards/CollapsibleCardGrid";
import EntertainmentCard from "../components/Cards/EntertainmentCard";
import DarkModePageTemplate from "../components/page/Templates/DarkModePageTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface EntertainerLandingPageDesignComponentProps extends RouteComponentProps {}

class EntertainerLandingPageDesign extends Component<EntertainerLandingPageDesignComponentProps> {
    render() {
        const elements6 = [1, 2, 3, 4, 5, 6];
        const elements4 = [1, 2, 3, 4];
        const elements2 = [1, 2];

        return (
            <DarkModePageTemplate>
                <SideBarTemplate>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    <div className="row">
                        <section className="col">
                            <section className="collapsible-grid__container">
                                <CollapsibleCardGrid title="My Upcoming Gigs" numItems={elements6.length}>
                                    {elements6.map((value) => {
                                        return (
                                            <div key={value} className="col col-6 col-lg-4">
                                                <EntertainmentCard
                                                    type="entertainer"
                                                    typeTitle="Entertainment"
                                                    enumeration={true}
                                                    cardIndex={value}
                                                    cardsTotal={elements6.length}
                                                    cardType="upcoming"
                                                >
                                                    stuff
                                                </EntertainmentCard>
                                            </div>
                                        );
                                    })}
                                </CollapsibleCardGrid>
                            </section>
                            <section className="collapsible-grid__container mt-5">
                                <CollapsibleCardGrid title="My Outstanding Actions" numItems={3}>
                                    <div key={1} className="col col-6 col-lg-4">
                                        <EntertainmentCard
                                            type="entertainer"
                                            typeTitle="Entertainment"
                                            enumeration={true}
                                            cardIndex={1}
                                            cardsTotal={3}
                                            cardType="pending"
                                        >
                                            stuff
                                        </EntertainmentCard>
                                    </div>
                                    <div key={2} className="col col-6 col-lg-4">
                                        <EntertainmentCard
                                            type="entertainer"
                                            typeTitle="Entertainment"
                                            enumeration={true}
                                            cardIndex={2}
                                            cardsTotal={3}
                                            cardType="outstanding"
                                        >
                                            stuff
                                        </EntertainmentCard>
                                    </div>
                                    <div key={3} className="col col-6 col-lg-4">
                                        <EntertainmentCard
                                            type="entertainer"
                                            typeTitle="Entertainment"
                                            enumeration={true}
                                            cardIndex={3}
                                            cardsTotal={3}
                                            cardType="outstanding"
                                        >
                                            stuff
                                        </EntertainmentCard>
                                    </div>
                                </CollapsibleCardGrid>
                            </section>
                            <section className="collapsible-grid__container mt-5">
                                <CollapsibleCardGrid title="My Queries" numItems={elements4.length}>
                                    {elements4.map((value) => {
                                        return (
                                            <div key={value} className="col col-6 col-lg-4">
                                                <EntertainmentCard
                                                    type="entertainer"
                                                    typeTitle="Entertainment"
                                                    enumeration={true}
                                                    cardIndex={value}
                                                    cardsTotal={elements4.length}
                                                    cardType="query"
                                                >
                                                    stuff
                                                </EntertainmentCard>
                                            </div>
                                        );
                                    })}
                                </CollapsibleCardGrid>
                            </section>
                        </section>
                    </div>
                </div>
            </DarkModePageTemplate>
        );
    }
}

export default withRouter(EntertainerLandingPageDesign);
