import {
    PaymentFormResponse,
    PaymentFormSubmitRequest,
    AddEditPaymentFormResponse,
    AddEditPaymentSubmitRequest,
    paymentsServices,
} from "../../services/PaymentService";
import sharedUtils from "../grid/sharedUtilities";
import { ExcludedEntityList } from "../../services/FindAnArtistService";

type NullableDate = Date | null | undefined;

export const ActionType = {
    Restart: "Reset",
    Apply: "Apply",
    Save: "Save",
    NotificationRequest: "PaymentNotificationRequested"
};

export enum PaymentStatus {
    Draft,
    Pending,
    ReadyForPayment,
    PaymentNetted,
    PaymentChecked,
    PackRequested,
    PaymentPackReady,
    PaymentPackFailed,
    PaymentPaid,
    AdviseRequested,
    PaymentAdvised,
    PaymentSettled,
    RemittanceRequested,
    PaymentRemitted,
}

//Interface type for the payment cycle form fields.
export interface PaymentFormData {
    paymentCycleId: string;
    statusId: string;
    status: string;
    payDate: NullableDate;
    paySentDate: NullableDate;
    paidDate: NullableDate;
    description: string;
    subTotal: number;
    tax: number;
    total: number;
    actionType: string;
    excludedPaymentIds: string;
    bouncedPaymentIds: string;
    serviceMappingList: paymentsServices[];
    internalReference: string;
    paymentCycleTypeId: string;
    paymentCycleType: string;
    paymentCycleExcludedEntities: ExcludedEntityList[];
}

//Interface type for the Payment form fields.
export interface AddEditPaymentFormData {
    id: string;
    paymentCycleId: string;
    businessEntityId: string;
    businessEntity: string;
    contactId: string;
    contact: string;
    reference: string;
    subAmount: number;
    tax: number;
    amount: number;
    bankAccountId: string;
    sortCode: string;
    bankAccountNumber: string;
    paymentStatusId: string;
    paymentStatus: string;
    payDate: NullableDate;
    advisedDate: NullableDate;
    settleDate: NullableDate;
    remittanceDate: NullableDate;
    bounceDate: NullableDate;
    comments: string;
    validationErrors: string;
    immediatePayment: string;
    immediatePaymentId: string;
    orignatingBillableItemId: string;
}

export default class paymentFormUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializePaymentItems = this.initializePaymentItems.bind(this);
        this.convertPaymentCycleResponseToFormData = this.convertPaymentCycleResponseToFormData.bind(this);
        this.convertFormDataToPaymentCycleRequest = this.convertFormDataToPaymentCycleRequest.bind(this);
        this.paymentDataInitializePaymentItems = this.paymentDataInitializePaymentItems.bind(this);
    }

    //Initializes the items for the payment cycle form
    initializePaymentItems(idFromGrid?: string): PaymentFormData {
        var formData: PaymentFormData = {
            paymentCycleId: "",
            statusId: "",
            status: "",
            payDate: undefined,
            paySentDate: undefined,
            paidDate: undefined,
            description: "",
            subTotal: 0,
            tax: 0,
            total: 0,
            actionType: "",
            excludedPaymentIds: "",
            bouncedPaymentIds: "",
            serviceMappingList: [],
            internalReference: "",
            paymentCycleTypeId: "",
            paymentCycleType: "",
            paymentCycleExcludedEntities: [],
        };

        return formData;
    }
    //Initializes the data for the Payment form
    initializeAddEditPaymentItems(idFromGrid: string): AddEditPaymentFormData {
        var formData: AddEditPaymentFormData = {
            id: idFromGrid,
            paymentCycleId: "",
            businessEntityId: "",
            businessEntity: "",
            contactId: "",
            contact: "",
            reference: "",
            subAmount: 0,
            tax: 0,
            amount: 0,
            bankAccountId: "",
            sortCode: "",
            bankAccountNumber: "",
            paymentStatusId: "",
            paymentStatus: "",
            payDate: undefined,
            advisedDate: undefined,
            settleDate: undefined,
            remittanceDate: undefined,
            bounceDate: undefined,
            comments: "",
            validationErrors: "",
            immediatePayment: "",
            immediatePaymentId: "",
            orignatingBillableItemId: "",
        };

        return formData;
    }

    // Convert the server response into the component's form state
    convertPaymentResponseToFormData(serverResponse: AddEditPaymentFormResponse): AddEditPaymentFormData {
        var formData: AddEditPaymentFormData = {
            id: serverResponse.id,
            paymentCycleId: serverResponse.paymentCycleId,
            businessEntityId: serverResponse.businessEntityId,
            businessEntity: serverResponse.businessEntity,
            contactId: serverResponse.contactId,
            contact: serverResponse.contact,
            reference: serverResponse.reference,
            subAmount: this.utils.convertStringToFloat(serverResponse.subAmount),
            tax: this.utils.convertStringToFloat(serverResponse.tax),
            amount: this.utils.convertStringToFloat(serverResponse.amount),
            bankAccountId: serverResponse.bankAccountId,
            sortCode: serverResponse.sortCode,
            bankAccountNumber: serverResponse.bankAccountNumber,
            paymentStatusId: serverResponse.paymentStatusId,
            paymentStatus: serverResponse.paymentStatus,
            payDate: this.utils.convertStringToDate(serverResponse.payDate),
            advisedDate: this.utils.convertStringToDate(serverResponse.advisedDate),
            settleDate: this.utils.convertStringToDate(serverResponse.settleDate),
            remittanceDate: this.utils.convertStringToDate(serverResponse.remittanceDate),
            bounceDate: this.utils.convertStringToDate(serverResponse.bounceDate),
            comments: serverResponse.comments,
            validationErrors: serverResponse.validationErrors,
            immediatePayment: serverResponse.immediatePayment,
            immediatePaymentId: serverResponse.immediatePaymentId,
            orignatingBillableItemId: serverResponse.orignatingBillableItemId,
        };

        return formData;
    }

    paymentDataInitializePaymentItems = (props: any, statusId: string, status: string , paySentDate? : NullableDate) => {
        let data: PaymentFormData = this.initializePaymentItems();
        data.paymentCycleId = props.paymentCycleId ? props.paymentCycleId.toString() : "";
        data.statusId = statusId;
        data.status = status;
        data.tax =
            props.calculationsObject && props.calculationsObject.tax ? props.calculationsObject.tax.toString() : "";
        data.subTotal =
            props.calculationsObject && props.calculationsObject.subTotal
                ? props.calculationsObject.subTotal.toString()
                : "";
        data.total =
            props.calculationsObject && props.calculationsObject.total ? props.calculationsObject.total.toString() : "";
        data.internalReference = props.paymentData.internalReference
            ? props.paymentData.internalReference.toString()
            : "";
        data.payDate = props.paymentData.payDate ? props.paymentData.payDate : null;
        data.description = props.paymentData.description ? props.paymentData.description.toString() : "";
        data.actionType = "Save";
        data.serviceMappingList = props.paymentData.serviceMappingList;
        data.paySentDate = paySentDate? paySentDate : null;
        data.paymentCycleTypeId = props.paymentData.paymentCycleTypeId ? props.paymentData.paymentCycleTypeId.toString() : "";
        data.paymentCycleType = props.paymentData.paymentCycleType ? props.paymentData.paymentCycleType : "";
        data.paymentCycleExcludedEntities = props.paymentData.paymentCycleExcludedEntities ? props.paymentData.paymentCycleExcludedEntities : [];
        return data;
    };
    // Convert the form's data into a proper billable item submit request
    convertFormDataToPaymentCycleRequest(
        formData: PaymentFormData,
        actionTypeParam: string,
        statusIdForNextStep: string,
        paymentStatusId: string
    ): PaymentFormSubmitRequest {
        var submitRequest: PaymentFormSubmitRequest = {
            paymentCycleId: formData.paymentCycleId.toString(),
            statusId:
                statusIdForNextStep != ""
                    ? statusIdForNextStep
                    : paymentStatusId != ""
                    ? paymentStatusId
                    : formData.statusId.toString(),
            status: formData.status,
            payDate: this.utils.convertDateToString(formData.payDate),
            paySentDate: this.utils.convertDateToString(formData.paySentDate),
            paidDate: this.utils.convertDateToString(formData.paidDate),
            description: formData.description,
            subTotal: this.utils.convertNumberToString(formData.subTotal),
            tax: this.utils.convertNumberToString(formData.tax),
            total: this.utils.convertNumberToString(formData.total),
            actionType: actionTypeParam,
            excludedPaymentIds: formData.excludedPaymentIds,
            bouncedPaymentIds: formData.bouncedPaymentIds,
            serviceMappingList: formData.serviceMappingList,
            internalReference: formData.internalReference,
            paymentCycleTypeId: formData.paymentCycleTypeId,
            paymentCycleType: formData.paymentCycleType,
            paymentCycleExcludedEntities: formData.paymentCycleExcludedEntities,
            
        };

        return submitRequest;
    }

    // Convert the server response into the component's form state
    convertPaymentCycleResponseToFormData(serverResponse: PaymentFormResponse): PaymentFormData {
        var formData: PaymentFormData = {
            paymentCycleId: serverResponse.paymentCycleId,
            statusId: serverResponse.statusId,
            status: serverResponse.status,
            payDate: this.utils.convertStringToDate(serverResponse.payDate),
            paySentDate: this.utils.convertStringToDate(serverResponse.paySentDate),
            paidDate: this.utils.convertStringToDate(serverResponse.paidDate),
            description: serverResponse.description,
            subTotal: this.utils.convertStringToFloat(serverResponse.subTotal),
            tax: this.utils.convertStringToFloat(serverResponse.tax),
            total: this.utils.convertStringToFloat(serverResponse.total),
            actionType: serverResponse.actionType,
            excludedPaymentIds: serverResponse.excludedPaymentIds,
            bouncedPaymentIds: serverResponse.bouncedPaymentIds,
            serviceMappingList: serverResponse.serviceMappingList,
            internalReference: serverResponse.internalReference,
            paymentCycleTypeId: serverResponse.paymentCycleTypeId,
            paymentCycleType: serverResponse.paymentCycleType,
            paymentCycleExcludedEntities: serverResponse.paymentCycleExcludedEntities,
        };
        return formData;
    }

    // Convert the form's data into a proper submit request
    convertFormDataToPaymentRequest(formData: AddEditPaymentFormData): AddEditPaymentSubmitRequest {
        var submitRequest: AddEditPaymentSubmitRequest = {
            id: formData.id,
            paymentCycleId: formData.paymentCycleId,
            businessEntityId: formData.businessEntityId,
            businessEntity: formData.businessEntity,
            contactId: formData.contactId,
            contact: formData.contact,
            reference: formData.reference,
            subAmount: this.utils.convertNumberToString(formData.subAmount),
            tax: this.utils.convertFloatToString(formData.tax),
            amount: this.utils.convertNumberToString(formData.amount),
            bankAccountId: formData.bankAccountId,
            sortCode: formData.sortCode,
            bankAccountNumber: formData.bankAccountNumber,
            paymentStatusId: formData.paymentStatusId,
            paymentStatus: formData.paymentStatus,
            payDate: this.utils.convertDateToString(formData.payDate),
            advisedDate: this.utils.convertDateToString(formData.advisedDate),
            settleDate: this.utils.convertDateToString(formData.settleDate),
            remittanceDate: this.utils.convertDateToString(formData.remittanceDate),
            bounceDate: this.utils.convertDateToString(formData.bounceDate),
            comments: formData.comments,
            immediatePayment: formData.immediatePayment,
            immediatePaymentId: formData.immediatePaymentId,
            orignatingBillableItemId: formData.orignatingBillableItemId,
        };

        return submitRequest;
    }
}
