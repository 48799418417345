import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import DesignSystemNavigation from "./DesignSystemNavigation";
import { Button } from "devextreme-react";

class FormDesign extends Component<RouteComponentProps> {
    constructor(props: RouteComponentProps) {
        super(props);
    }

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <dl className="row">
                        <dt className="col-3">Primary</dt>
                        <dd className="col-3">
                            <button className="btn btn-primary">Cancel</button>
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-3">Primary with icon</dt>
                        <dd className="col-3">
                            <button className="btn btn-primary btn--large btn--with-icon">
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                                Add Shift
                            </button>
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-3">Primary Large</dt>
                        <dd className="col-3">
                            <button className="btn btn-primary btn--large">Cancel</button>
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-3">Primary Mid-Large</dt>
                        <dd className="col-3">
                            <button className="btn btn-primary btn--midlarge">Cancel</button>
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-3">Primary Small</dt>
                        <dd className="col-3">
                            <button className="btn btn-primary btn--small">Cancel</button>
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-3">Primary Large (Disabled)</dt>
                        <dd className="col-3">
                            <button className="btn btn-primary btn--large disabled">Cancel</button>
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-3">Small Inline (DX Button)</dt>
                        <dd className="col-3">
                            <Button
                                className="btn btn--small-inline"
                                onClick={() => {console.log("Clicked")}}
                            >
                                <span className="dx-button-text">Upload</span>
                            </Button>
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-3">Ghost</dt>
                        <dd className="col-3">
                            <button className="btn btn--ghost">Cancel</button>
                        </dd>
                    </dl>

                    <dl className="row">
                        <dt className="col-3">Ghost (Disabled)</dt>
                        <dd className="col-3">
                            <button className="btn btn--ghost disabled">Cancel</button>
                        </dd>
                    </dl>

                    <dl className="row">
                        <dt className="col-3">Ghost Light</dt>
                        <dd className="col-3">
                            <button className="btn btn--ghost btn--ghost--light">Cancel</button>
                        </dd>
                    </dl>

                    <dl className="row">
                        <dt className="col-3">Ghost Teal</dt>
                        <dd className="col-3">
                            <button className="btn btn--ghost btn--ghost--teal">Cancel</button>
                        </dd>
                    </dl>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(FormDesign);
