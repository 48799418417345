import React from "react";
import ArtistPaymentsPage from "./ArtistPaymentsPage";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../../Auth/ProtectedPage";

interface ArtistPaymentsPageContainerProps extends RouteProps, withValidUserRoleProps {}

const ArtistPaymentsPageContainer = (props: ArtistPaymentsPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return <ArtistPaymentsPage title={getTitle()} category={getType()} />;
};

export default withValidUserRole(ArtistPaymentsPageContainer);
