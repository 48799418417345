import React from "react";
import { DateBox, NumberBox, LoadPanel } from "devextreme-react";
import BillingFormUtils, { BillingFormData, BillingStatus } from "../Billing/BillingFormUtils";
import { BillingCyclesGridServiceInvoiceItem } from "../../services/BillingCyclesGridService";
import BillingService, { billingCycleStatus, billingStatus } from "../../services/BillingService";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import { DateBoxOnValueChangedEvent, NumberBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";

interface InvoicePaymentSectionProps {
    invoiceId: number;
    billingData: BillingFormData;
    onBillPaid: (status: BillingStatus, statusId: string) => void;
    statusId: string;
    syncDateFields: (date: NullableDate, type: string) => void;
    syncAmountFields: (data: number) => void;
    invoiceTotal: string;
    invoicePaidDate: NullableDate;
    invoiceAmount: number;
    calculationsObject: any;
    viewDisable: boolean;
}

interface InvoicePaymentSectionState {
    disablePaidButton: boolean;
    loadPanelVisible: boolean;
    billingFormData: BillingFormData;
    errorMessage: [];
}

class InvoicePaymentSection extends React.Component<InvoicePaymentSectionProps> {
    state: InvoicePaymentSectionState;
    BillingService: BillingService;
    billingFormUtils: BillingFormUtils;
    sharedUtils: sharedUtils;

    constructor(props: InvoicePaymentSectionProps) {
        super(props);
        this.BillingService = new BillingService();
        this.billingFormUtils = new BillingFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            disablePaidButton: this.props.viewDisable ? true : false,
            loadPanelVisible: false,
            billingFormData: this.props.billingData,
            errorMessage: [],
        };
    }

    //Function which is invoked when the "Paid" button is clicked.
    onBillPaid = () => {
        let data: BillingCyclesGridServiceInvoiceItem = this.billingFormUtils.initializeBillingCyclesGridServiceInvoiceItem();
        data.invoiceId = this.props.invoiceId ? this.props.invoiceId.toString() : "";
        data.clientId = this.props.billingData.clientId;
        data.client = this.props.billingData.client;
        data.statusId = billingCycleStatus.BillPaid;
        data.status = billingStatus.BillPaid;
        data.periodMatrixId = this.props.billingData.periodMatrixId;
        data.period = this.props.billingData.period;
        data.week = this.props.billingData.week;
        data.dateRangeFrom = this.props.billingData.dateRangeFrom
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeFrom)
            : "";
        data.dateRangeTo = this.props.billingData.dateRangeTo
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeTo)
            : "";
        data.tax =
            this.props.calculationsObject && this.props.calculationsObject.tax
                ? this.props.calculationsObject.tax.toString()
                : "";
        data.subTotal =
            this.props.calculationsObject && this.props.calculationsObject.subTotal
                ? this.props.calculationsObject.subTotal.toString()
                : "";
        data.total =
            this.props.calculationsObject && this.props.calculationsObject.total
                ? this.props.calculationsObject.total.toString()
                : "";
        data.invoiceDate = this.props.billingData.invoiceDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDate)
            : "";
        data.invoiceReference = this.props.billingData.invoiceReference
            ? this.props.billingData.invoiceReference.toString()
            : "";
        data.internalReference = this.props.billingData.internalReference
            ? this.props.billingData.internalReference.toString()
            : "";
        data.invoiceSentDate = this.props.billingData.invoiceSentDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceSentDate)
            : "";
        data.invoiceDueDate = this.props.billingData.invoiceDueDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDueDate)
            : "";

        data.invoicePaidDate = this.state.billingFormData.invoicePaidDate
            ? this.sharedUtils.convertDateToString(this.state.billingFormData.invoicePaidDate)
            : "";
        data.invoiceAmount = this.state.billingFormData.invoiceAmount
            ? this.state.billingFormData.invoiceAmount.toString()
            : "";
        data.description = this.props.billingData.description ? this.props.billingData.description.toString() : "";
        data.actionType = "Save";
        data.invoiceServiceMappingList = this.props.billingData.invoiceServiceMappingList;

        this.setState({
            gridData: data,
            loadPanelVisible: true,
        });
        this.onSubmit(data);
    };

    onSubmit = (billingRowData: BillingCyclesGridServiceInvoiceItem) => {
        this.BillingService.billingDataService(billingRowData).then(this.handleSuccess).catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            (prevState: InvoicePaymentSectionState) => ({
                loadPanelVisible: false,
                disablePaidButton: true,
                errorMessage: [],
            }),
            () => this.props.onBillPaid(BillingStatus.BillPaid, billingCycleStatus.BillPaid)
        );
    };

    handleFailure = (error: any) => {
        var respMessage: string = "postBillingCycleInvoice failed with response: " + JSON.stringify(error);

        if (!this.BillingService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            errorMessage: error.response !== null ? JSON.parse(JSON.stringify(error.response.data.error)) : null,
            loadPanelVisible: false,
            disablePaidButton: false,
        });
    };

    //Handle change functions.
    handleChangePaidDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            billingFormData: {
                ...this.state.billingFormData,
                invoicePaidDate: dxValueChange.value,
            },
        });
        this.props.syncDateFields(dxValueChange.value, "Paid");
    };

    handleChangeInvoiceAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            billingFormData: {
                ...this.state.billingFormData,
                invoiceAmount: dxValueChange.value,
            },
        });
        this.props.syncAmountFields(dxValueChange.value);
    };

    //Inside the update function, when the Paid date or Amount paid fields are changed in the form, it would reflect here and vice versa.
    componentDidUpdate = (prevProps: InvoicePaymentSectionProps) => {
        if (this.props.invoicePaidDate != prevProps.invoicePaidDate) {
            this.setState({
                billingFormData: {
                    ...this.state.billingFormData,
                    invoicePaidDate: this.props.invoicePaidDate,
                },
            });
        }
        if (this.props.invoiceAmount != prevProps.invoiceAmount) {
            this.setState({
                billingFormData: {
                    ...this.state.billingFormData,
                    invoiceAmount: this.props.invoiceAmount,
                },
            });
        }
    };

    render() {
        var { statusId } = this.props;
        var { disablePaidButton } = this.state;
        var disableBillPaidButton: boolean = statusId != billingCycleStatus.BillPackSent ? false : true;
        var paidButtonCssClass: string = "btn disabledCycleButtonColor btn--large";
        var paidButtonDisabled: boolean = true;
        if (disableBillPaidButton && disablePaidButton == false) {
            paidButtonDisabled = false;
            paidButtonCssClass = "btn saveCycleButtonColor btn--large";
        }
        return (
            <div className="card my-3">
                <div className="card-body">
                    <h4>
                        <b>Invoice Payment</b>
                    </h4>
                    <>
                        <div className="row mt-3 mb-5 align-items-center">
                            <div className="col-2 col-lg-1">Amount Due</div>
                            <div className="col-10 col-lg-3">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    disabled={true}
                                    value={this.props.invoiceTotal ? parseFloat(this.props.invoiceTotal) : undefined}
                                />
                            </div>
                            <div className="col-2 col-lg-1">Paid Date</div>
                            <div className="col-10 col-lg-3">
                                <DateBox
                                    displayFormat="dd/MM/yyyy"
                                    onValueChanged={this.handleChangePaidDate}
                                    value={
                                        this.state.billingFormData.invoicePaidDate
                                            ? this.state.billingFormData.invoicePaidDate
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    disabled={this.props.viewDisable ? true : false}
                                />
                            </div>
                            <div className="col-2 col-lg-1">Amount Paid</div>
                            <div className="col-10 col-lg-3">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeInvoiceAmount}
                                    value={
                                        this.state.billingFormData.invoiceAmount
                                            ? this.state.billingFormData.invoiceAmount
                                            : 0
                                    }
                                    disabled={this.props.viewDisable ? true : false}
                                />
                            </div>
                        </div>
                        {this.state.errorMessage ? (
                            <span className="unscheduled-shift">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        ) : (
                            <></>
                        )}
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <div className="row">
                            <div className="col-2">
                                <button
                                    className={paidButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onBillPaid();
                                    }}
                                    disabled={paidButtonDisabled}
                                >
                                    PAID
                                </button>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        );
    }
}
export default InvoicePaymentSection;
