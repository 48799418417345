import React from "react";
import { queryCard } from "../../services/QueryService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import gridUtils from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";

interface GeneralQueryCardProps {
    querycard: queryCard;
    serialNumber: number;
    totalNumber: number;
    serviceType: string;
}

interface GeneralQueryCardState {
    queryIsOpen: boolean;
}

class GeneralQueryCardComponent extends React.Component<GeneralQueryCardProps> {
    state: GeneralQueryCardState;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    constructor(props: GeneralQueryCardProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.gridUtils = new gridUtils();
        this.state = {
            queryIsOpen: false,
        };
    }
    render() {
        const {
            queryTitle,
            queryStatus,
            assignedTo,
            contactJobTitle,
            queryDescription,
            raisedByContactName,
            d365LinkID,
            priority,
            comments,
            resolutionReason,
            queryDateCreated
        } = this.props.querycard;
        var formattedDate: NullableDate = this.gridUtils.convertUTCStringToDate(queryDateCreated);
        var formattedDatePart2 = this.sharedUtils.formattedDateValue(formattedDate);
        var raisedByContact: string = raisedByContactName
            ? contactJobTitle ? `${raisedByContactName} (${contactJobTitle}) on ${formattedDatePart2}`
                : `${raisedByContactName} on ${formattedDatePart2}`
            : contactJobTitle ? `(${contactJobTitle}) on ${formattedDatePart2}` : `${formattedDatePart2}`;
        const isEventUk =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        return (
            <>
                <div className="row">
                    <div className="col-12 col-xl-7">
                        <span className="large-light-card-layout__title">
                            {queryTitle ? queryTitle : "General Query"}
                        </span>
                        <span className="large-light-card-layout__normal">Raised by: </span>
                        {raisedByContact}
                    </div>
                    <div className="col-12 col-xl-5 large-light-card-layout__status">
                        <div className="divider-narrow--top pt-4 pt-xl-0">
                            <span className="large-light-card-layout__normal">Status: </span>
                            {queryStatus}
                            <br></br>
                            {assignedTo ? (
                                <>
                                    <span className="large-light-card-layout__normal">Assigned To: </span>
                                    {assignedTo}{" "}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pt-4 text-center text-xl-left">
                            <div className="divider--top py-4 mb-10">
                                <span className="large-light-card-layout__labelbold">Query Description</span>
                                <span className="d-flex justify-content-center">
                                    {queryDescription ? queryDescription : ""}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pt-4 text-center text-xl-left">
                            <div className="divider--top py-4 mb-10">
                                <span className="large-light-card-layout__labelbold">Resolution Reason</span>
                                <span className="d-flex justify-content-center">
                                    {resolutionReason ? resolutionReason : "Not yet resolved"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {isEventUk ? (
                    <div className="row">
                        <div className="col-12">
                            <div className="pt-4 text-center text-xl-left">
                                <div className="divider--top py-4 mb-10">
                                    <span className="large-light-card-layout__labelbold">Event UK Only</span>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">
                                        Link to Dynamics:{" "}
                                    </label>
                                    <div className="col-5 col-xl-2 text-left"><a href={d365LinkID} target="_blank">{d365LinkID}</a></div>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">
                                        Linked Id:{" "}
                                    </label>
                                    <div className="col-5 col-xl-2 text-left">{"D1234567"}</div>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">Priority: </label>
                                    <div className="col-5 col-xl-2 text-left">{priority}</div>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">
                                        Internal Comments:{" "}
                                    </label>
                                    <div className="col-5 col-xl-2 text-left">{comments}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}
export default GeneralQueryCardComponent;
