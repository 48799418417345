import { EntertainmentCardResponse, EntertainMentCardRequest } from "../../services/EntertainmentCompletionCardService";
import { RaiseQueryFormData } from "../../services/AddEditPopUpService";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;

export interface EntertainmentCardFormData {
    billableItemId: string;
    providerId: string;
    date: string;
    dateTo: NullableDate;
    provider_Pay_Amount_Local_Ccy: number;
    service: string;
    shift_End: string;
    shift_Start: string;
    status: string;
    venueName: string;
    venuePostCode: string;
    venueTownCity: string;
    address: string;
    venue_Phone: string;
    performanceInformation: string;
    genre: string;
    reoccurance: string;
    feedbackScore: string;
    feedbackComments: string;
    businessEntityId: string;
    feedbackType: string;
}

export default class EntertainmentCompletionCardUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializeEntertainmentCardItem = this.initializeEntertainmentCardItem.bind(this);
        this.convertEntertainmentCardResponeToFormData = this.convertEntertainmentCardResponeToFormData.bind(this);
        this.initializeQueryFormData = this.initializeQueryFormData.bind(this);
    }

    initializeEntertainmentCardItem(idFromLandingPage: string): EntertainmentCardFormData {
        var formData: EntertainmentCardFormData = {
            billableItemId: idFromLandingPage, // INIT via param idFromLandingPage
            providerId: "",
            date: "",
            dateTo: undefined,
            provider_Pay_Amount_Local_Ccy: 0,
            service: "",
            shift_End: "",
            shift_Start: "",
            status: "",
            venueName: "",
            venuePostCode: "",
            venueTownCity: "",
            address: "",
            venue_Phone: "",
            performanceInformation: "",
            genre: "",
            reoccurance: "",
            feedbackScore: "",
            feedbackComments: "",
            businessEntityId: "",
            feedbackType: "",
        };

        return formData;
    }

    //Initialize the Raise query form data.
    initializeQueryFormData = (idFromGrid: string): RaiseQueryFormData => {
        var formData: RaiseQueryFormData = {
            id: "",
            queryTitle: "",
            queryDescription: "",
            queryTypeLookupID: "",
            contactId: "",
            contactName: "",
            companyId: "",
            company: "",
            d365LinkID: "",
            resolutionReason: "",
            billableItemId: idFromGrid,
            queryStatusId: "",
            queryStatus: "",
            dateCreated: "",
            clientId: "",
            client: "",
            venueId: "",
            venue: "",
            venueName: "",
            comments: "",
            lastUpdate: "",
            assignedTo: "",
            raisedByContactName: "",
            raisedByContactTelNumber: "",
            serviceTypeId: "",
            linkedId: "",
            navigateFrom: "webApp"
        };
        return formData;
    };

    // Convert the server response into the component's form state
    convertEntertainmentCardResponeToFormData(serverResponse: EntertainmentCardResponse): EntertainmentCardFormData {
        var formData: EntertainmentCardFormData = {
            billableItemId: serverResponse.billableItemId,
            providerId: serverResponse.providerId,
            date: serverResponse.date,
            dateTo: this.utils.convertStringToDate(serverResponse.dateTo),
            provider_Pay_Amount_Local_Ccy: this.utils.convertStringToFloat(
                serverResponse.provider_Pay_Amount_Local_Ccy
            ),
            service: serverResponse.service,
            shift_End: serverResponse.shift_End,
            shift_Start: serverResponse.shift_Start,
            status: serverResponse.status,
            venueName: serverResponse.venueName,
            venuePostCode: serverResponse.venuePostCode,
            venueTownCity: serverResponse.venueTownCity,
            address: serverResponse.address,
            venue_Phone: serverResponse.venue_Phone,
            performanceInformation: serverResponse.performanceInformation,
            genre: serverResponse.genre,
            reoccurance: serverResponse.reoccurance,
            feedbackScore: "",
            feedbackComments: "",
            businessEntityId: "",
            feedbackType: "",
        };

        return formData;
    }
    convertFormDataToBillableItemRequest(
        formData: EntertainmentCardFormData,
        providerId: string,
        feedbackBy: string
    ): EntertainMentCardRequest {
        var submitRequest: EntertainMentCardRequest = {
            billableItemId: formData.billableItemId,
            feedbackScore: formData.feedbackScore.toString(),
            feedbackComments: formData.feedbackComments,
            businessEntityId: providerId,
            feedbackType: feedbackBy,
        };

        return submitRequest;
    }
}
