import React from "react";

interface MaskedStatusForAccrualsProps {
    data: any;
}

interface MaskedStatusForAccrualsState {
    accrualStatus: string;
}

class MaskedStatusForAccruals extends React.Component<MaskedStatusForAccrualsProps> {
    state: MaskedStatusForAccrualsState;
    constructor(props: MaskedStatusForAccrualsProps) {
        super(props);
        this.state = {
            accrualStatus: this.props.data.data.isAccruals ? "Yes" : "No",
        };
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <span className="status-message-color">{this.state.accrualStatus}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default MaskedStatusForAccruals;
