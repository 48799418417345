import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sharedUtils from "../components/grid/sharedUtilities";
import LookupService, { LookupTypeItem } from "../services/LookupService";
import UserService, { RoleGroupNames } from "../services/UserService";
import { PeriodMatrixTableLookup } from "../components/Billing/BillingFormUtils";
import CostReportUtils from "../components/CostReports/CostReportUtils";
import SimpleShiftGlobalFilterService from "../services/SimpleShiftGlobalFilterService";
import ManageShiftGlobalFilterService from "../services/ManageShiftGlobalFilterService";

type NullableDate = Date | null | undefined;
interface DayMonthWeekPanelProps extends WithTranslation {
    ondateTodateFromSelection: (dateFromValue: string, dateToValue: string) => void;
    dateToValue: NullableDate;
    dateFromValue: NullableDate;
    serviceTypeId: string;
    periodLookUpItems: PeriodMatrixTableLookup[];
    clientId: string;
    pointOfNavigation?: string;
    billableItemId: string;
}

interface DayMonthWeekPanelState {
    dateFrom: NullableDate;
    dateTo: NullableDate;
    monthList: LookupTypeItem[];
    monthId: number | null;
    yearId: number | 0;
    isSelectionToday: boolean;
    isSelectionWeek: boolean;
    isSelectionMonth: boolean;
    currentWkStartDt: NullableDate;
    currentWkEndDt: NullableDate;
}

class DayMonthWeekPanel extends Component<DayMonthWeekPanelProps> {
    lookupService: LookupService;
    state: DayMonthWeekPanelState;
    sharedUtils: sharedUtils;
    utils: CostReportUtils;
    constructor(props: DayMonthWeekPanelProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.lookupService = new LookupService();
        this.utils = new CostReportUtils();
        this.state = {
            dateFrom: null,
            dateTo: null,
            monthList: [],
            monthId: null,
            yearId: 0,
            isSelectionToday: false,
            isSelectionWeek: this.props.billableItemId ? false : true,
            isSelectionMonth: false,
            currentWkStartDt: null,
            currentWkEndDt: null,
        };
    }

    componentDidMount() {
        if(this.props.pointOfNavigation == "popUp" || 
        this.props.pointOfNavigation?.toLocaleLowerCase() == 'burgermenupayments' ||
        this.props.pointOfNavigation == 'home') {
            this.setState({
                isSelectionWeek: false,
            });
        }
    }

    componentDidUpdate(prevProps: DayMonthWeekPanelProps) {
        if ((this.props.serviceTypeId != prevProps.serviceTypeId) && this.props.pointOfNavigation == "burgerMenu") {
            this.setState({
                isSelectionWeek: true,
            });  
        }
        // if(this.props.dateFromValue && !this.props.dateToValue && this.props.pointOfNavigation =='burgerMenuPayments' && (this.state.isSelectionWeek || this.state.isSelectionToday || this.state.isSelectionMonth)){
        //     this.setState({
        //         isSelectionWeek: false,
        //         isSelectionToday: false,
        //         isSelectionMonth: false
        //     })
        // }
    }

    //Store the Date to and from values in both the Global services.
    handleClickApplyButton = () => {
        SimpleShiftGlobalFilterService.setDateFromValue(this.state.dateFrom);
        SimpleShiftGlobalFilterService.setDateToValue(this.state.dateTo);
        ManageShiftGlobalFilterService.setDateFromValue(this.state.dateFrom);
        ManageShiftGlobalFilterService.setDateToValue(this.state.dateTo);
        let dateFromValue = this.sharedUtils.convertDateToString(this.state.dateFrom);
        let dateTovalue = this.sharedUtils.convertDateToString(this.state.dateTo);
        this.props.ondateTodateFromSelection(dateFromValue, dateTovalue);
    };

    onClickToday = () => {
        this.setState(
            {
                dateFrom: new Date(),
                dateTo: new Date(),
                isSelectionToday: true,
                isSelectionWeek: false,
                isSelectionMonth: false,
            },
            () => {
                this.handleClickApplyButton();
            }
        );
    };

    onClickTodayRightArrow = () => {
        var tomorrow: Date = new Date();
        if (this.props.dateFromValue) {
            tomorrow = new Date(this.props.dateFromValue.getTime() + 24 * 60 * 60 * 1000);
        }
        this.setState(
            {
                dateFrom: tomorrow,
                dateTo: tomorrow,
                isSelectionToday: false,
                isSelectionWeek: false,
                isSelectionMonth: false,
            },
            () => {
                this.handleClickApplyButton();
            }
        );
    };

    onClickTodayLeftArrow = () => {
        var previousDay: Date = new Date();
        if (this.props.dateFromValue) {
            previousDay = new Date(this.props.dateFromValue.getTime() - 24 * 60 * 60 * 1000);
        }
        this.setState(
            {
                dateFrom: previousDay,
                dateTo: previousDay,
                isSelectionToday: false,
                isSelectionWeek: false,
                isSelectionMonth: false,
            },
            () => {
                this.handleClickApplyButton();
            }
        );
    };

    onClickWeek = () => {
        var dateFromValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        var dateToValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();

        if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            var filteredLookupList: PeriodMatrixTableLookup[] = this.utils.buildPeriodListBasedOnClient(
                this.props.clientId,
                this.props.periodLookUpItems
            );
            var dateArray: string[] = this.utils.setDateRangeForVenueManagers(filteredLookupList);
            this.setState(
                {
                    dateFrom: this.sharedUtils.convertStringToDate(dateArray[0]),
                    dateTo: this.sharedUtils.convertStringToDate(dateArray[1]),
                    isSelectionToday: false,
                    isSelectionWeek: true,
                    isSelectionMonth: false,
                },
                () => {
                    this.handleClickApplyButton();
                }
            );
        } else {
            this.setState(
                {
                    dateFrom: dateFromValue,
                    dateTo: dateToValue,
                    isSelectionToday: false,
                    isSelectionWeek: true,
                    isSelectionMonth: false,
                },
                () => {
                    this.handleClickApplyButton();
                }
            );
        }
    };

    onClickWeekRightArrow = () => {
        // var nextWeekStartDay: NullableDate = null;
        // var nextWeekEndDay: NullableDate = null;
        var nextWeekStartDay: NullableDate = this.sharedUtils.fetchMinValueOfCurrentWeek();
        var nextWeekEndDay: NullableDate = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        if (this.props.dateFromValue) {
            nextWeekStartDay = new Date(this.props.dateFromValue);
        }
        if (this.props.dateToValue) {
            nextWeekEndDay = new Date(this.props.dateToValue);
        }
        if (nextWeekStartDay) {
            if (
                this.sharedUtils.convertDateToString(nextWeekStartDay) ==
                this.sharedUtils.convertDateToString(nextWeekEndDay)
            ) {
                nextWeekStartDay.setDate(nextWeekStartDay.getDate() + 1);
            } else if (this.state.isSelectionMonth) {
                nextWeekStartDay.setDate(nextWeekStartDay.getDate() + 1);
            } else {
                nextWeekStartDay.setDate(nextWeekStartDay.getDate() + 7);
            }
        }
        if (nextWeekEndDay) {
            if (this.state.isSelectionMonth && nextWeekStartDay) {
                nextWeekEndDay.setDate(nextWeekStartDay.getDate() + 6);
            } else {
                nextWeekEndDay.setDate(nextWeekEndDay.getDate() + 7);
            }
        }
        this.setState(
            {
                dateFrom: nextWeekStartDay,
                dateTo: nextWeekEndDay,
                isSelectionToday: false,
                isSelectionWeek: false,
                isSelectionMonth: false,
            },
            () => {
                this.handleClickApplyButton();
            }
        );
    };

    onClickWeekLeftArrow = () => {
        // var nextWeekStartDay: NullableDate = null;
        // var nextWeekEndDay: NullableDate = null;
        var nextWeekStartDay: NullableDate = this.sharedUtils.fetchMinValueOfCurrentWeek();
        var nextWeekEndDay: NullableDate = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        if (this.props.dateFromValue) {
            nextWeekStartDay = new Date(this.props.dateFromValue);
        }
        if (this.props.dateToValue) {
            nextWeekEndDay = new Date(this.props.dateToValue);
        }
        if (nextWeekEndDay) {
            if (
                this.sharedUtils.convertDateToString(nextWeekStartDay) ==
                this.sharedUtils.convertDateToString(nextWeekEndDay)
            ) {
                nextWeekEndDay.setDate(nextWeekEndDay.getDate() - 1);
            } else {
                nextWeekEndDay.setDate(nextWeekEndDay.getDate() - 7);
            }
        }
        if (nextWeekStartDay) {
            nextWeekStartDay.setDate(nextWeekStartDay.getDate() - 7);
        }

        this.setState(
            {
                dateFrom: nextWeekStartDay,
                dateTo: nextWeekEndDay,
                isSelectionToday: false,
                isSelectionWeek: false,
                isSelectionMonth: false,
            },
            () => {
                this.handleClickApplyButton();
            }
        );
    };

    onClickMonth = () => {
        var date = new Date();
        var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.setState(
            {
                dateFrom: firstDayOfMonth,
                dateTo: lastDayOfMonth,
                monthId: date.getMonth(),
                yearId: date.getFullYear(),
                isSelectionToday: false,
                isSelectionWeek: false,
                isSelectionMonth: true,
            },
            () => {
                this.handleClickApplyButton();
            }
        );
    };

    getYearsID = ()=>{
        let year = null;
        let date = new Date();
        let firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        year = firstDayOfMonth.getFullYear();
        return year;
    }

    getMonthsID = (type:any)=>{
        let monthsID = null;
        let date = new Date();
        let firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        if(type){
            if(type =='previous'){
                monthsID = firstDayOfMonth.getMonth() - 1;
            } else if(type =='next'){
                monthsID = firstDayOfMonth.getMonth() + 1;
            }
        }
        return monthsID;
    }

    onClickMonthLeftArrow = () => {
        this.setState(
            {
                // monthId: this.props.dateFromValue ? this.props.dateFromValue.getMonth() - 1 : null,
                // yearId: this.props.dateFromValue ? this.props.dateFromValue.getFullYear() : null,
                monthId: this.props.dateFromValue ? this.props.dateFromValue.getMonth() - 1 : this.getMonthsID("previous"),
                yearId: this.props.dateFromValue ? this.props.dateFromValue.getFullYear() : this.getYearsID(),
            },
            () => {
                this.onClickMonthArrowChangeDate();
            }
        );
    };
    onClickMonthRightArrow = () => {
        this.setState(
            {
                // monthId: this.props.dateFromValue ? this.props.dateFromValue.getMonth() + 1 : null,
                // yearId: this.props.dateFromValue ? this.props.dateFromValue.getFullYear() : null,
                monthId: this.props.dateFromValue ? this.props.dateFromValue.getMonth() + 1 : this.getMonthsID("next"),
                yearId: this.props.dateFromValue ? this.props.dateFromValue.getFullYear() : this.getYearsID()
            },
            () => {
                this.onClickMonthArrowChangeDate();
            }
        );
    };

    onClickMonthArrowChangeDate = () => {
        var date = new Date();
        var monthFirstDay = new Date();
        var monthLastDay = new Date();
        if (this.state.monthId || this.state.monthId == 0) {
            if(this.state.yearId !== 0 && this.state.yearId !== date.getFullYear()) {
                monthFirstDay = new Date(this.state.yearId, this.state.monthId, 1);
                monthLastDay = new Date(this.state.yearId, this.state.monthId + 1, 0);
            }
            else{
                monthFirstDay = new Date(date.getFullYear(), this.state.monthId, 1);
                monthLastDay = new Date(date.getFullYear(), this.state.monthId + 1, 0);
            }
        }
        this.setState(
            {
                dateFrom: monthFirstDay,
                dateTo: monthLastDay,
                isSelectionToday: false,
                isSelectionWeek: false,
                isSelectionMonth: false,
            },
            () => {
                this.handleClickApplyButton();
            }
        );
    };

    render() {
        const isSelectionWeek: boolean = this.props.dateFromValue == null ? false : this.state.isSelectionWeek;
        return (
            <div>
                <div className="sidebar__view-selector-container_update">
                    <div className="sidebar__view-selector-card_update">
                        <div className="sidebar__view-selector-arrow-buttons_update">
                            <button className="sidebar__view-selector-button" onClick={this.onClickTodayLeftArrow}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                            <button
                                className={
                                    this.state.isSelectionToday
                                        ? "sidebar__view-selector-card-on-select"
                                        : "sidebar__view-selector-button"
                                }
                                onClick={this.onClickToday}
                            >
                                <span className="sidebar__view-selector-label">DAY</span>
                            </button>
                            <button className="sidebar__view-selector-button" onClick={this.onClickTodayRightArrow}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    </div>
                    <div className="sidebar__view-selector-card_update">
                        <div className="sidebar__view-selector-arrow-buttons_update">
                            <button className="sidebar__view-selector-button" onClick={this.onClickWeekLeftArrow}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                            <button
                                className={
                                    isSelectionWeek
                                        ? "sidebar__view-selector-card-on-select"
                                        : "sidebar__view-selector-button"
                                }
                                onClick={this.onClickWeek}
                            >
                                <span className="sidebar__view-selector-label">WEEK</span>
                            </button>
                            <button className="sidebar__view-selector-button" onClick={this.onClickWeekRightArrow}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    </div>
                    <div className="sidebar__view-selector-card_update">
                        <div className="sidebar__view-selector-arrow-buttons_update">
                            <button className="sidebar__view-selector-button" onClick={this.onClickMonthLeftArrow}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                            <button
                                className={
                                    this.state.isSelectionMonth
                                        ? "sidebar__view-selector-card-on-select"
                                        : "sidebar__view-selector-button"
                                }
                                onClick={this.onClickMonth}
                            >
                                <span className="sidebar__view-selector-label">MONTH</span>
                            </button>
                            <button className="sidebar__view-selector-button" onClick={this.onClickMonthRightArrow}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DayMonthWeekPanel);
