import React, { Component } from "react";
import Header from "./Header";

interface HeaderContainerProps {
    getBuild: (flag: boolean) => void;
    isNewBuild: boolean;     
}

class HeaderContainer extends Component<HeaderContainerProps> {
    constructor(props: HeaderContainerProps) {
        super(props);
    }

    getBuild = (flag: boolean) => {
        this.props.getBuild(flag);
    };

    render() {
        return <Header title="containerTitle" initialState={false} getBuild={this.getBuild} isNewBuild={this.props.isNewBuild}/>;
    }
}

export default HeaderContainer;
