import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button,DateBox,TagBox,CheckBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import UserService, { RoleGroupNames } from "../../../services/UserService";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../../services/LookupService";
import { NullableDate } from "../../Outstanding-Actions/OutstandingActionsUtilities";
import { DateBoxOnValueChangedEvent, LookUpOnValueChangedEvent, SelectBoxOnValueChangedEvent, TagBoxOnValueChangeEvent, CheckBoxOnValueChanged } from "../../../types/DevExtremeTypes";
import sharedUtils from "../../grid/sharedUtilities";
import LookupTypeListDynamic from "../../select/LookupTypeListDynamic";
import DropDownFromLookupTableDynamic from "../../select/SelectBoxFromLookupTableDynamic";
import PaymentService from "../../../services/PaymentService";
import { ServiceType } from "../../../common/ServiceUtilities";

export interface LookUpTypeItemForPaymentStatusType {
    paymentStatusTypeLookupId: number;
    value: string;
    parentMappingId: string;
}

interface PaymentSidebarPanelProps extends WithTranslation {
    onApplyButtonClick: (providerId: string, startDate: string, endDate: string, serviceTypeId: string, paymentId: string, status: [], isShowFasterPayments: boolean) => void;
    location: any;
    serviceTypeId: string;
    getServiceTypeId: (serviceTypeId: string) => void;
}

interface PaymentSidebarPanelState {
    providerId: string;
    serviceId: string;
    startDate: string;
    endDate: string;
    paymentStatusId: [];
    paymentStatusTypeLookUp: LookUpTypeItemForPaymentStatusType[];
    singleVenue: boolean;
    serviceTypeloaded: false;
    minDateValue: NullableDate;
    maxDateValue: NullableDate;
    isVenueManager: boolean;
    isProvider: boolean;
    isEventUk: boolean;
    venueLoaded: boolean;
    venueListByClient: any[];
    entireVenueList: any[];
    isFasterPayments: boolean;
}

class PaymentSidebarPanel extends Component<PaymentSidebarPanelProps> {
    lookupService: LookupService;
    paymentService: PaymentService;
    state: PaymentSidebarPanelState;
    sharedUtils: sharedUtils;
    //_isMounted:boolean;
    constructor(props: PaymentSidebarPanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.paymentService = new PaymentService();
        this.sharedUtils = new sharedUtils();
        this.state = {
            providerId: "",
            serviceId: "",
            startDate: "",
            endDate: "",
            paymentStatusId: [],
            paymentStatusTypeLookUp: [],
            singleVenue: UserService.isUserInGroup(RoleGroupNames.VenueManager) ? true : false,
            serviceTypeloaded: false,
            minDateValue: null,
            maxDateValue: null,
            isVenueManager: UserService.isUserInGroup(RoleGroupNames.VenueManager) ? true : false,
            isProvider: UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ? true : false,
            isEventUk:
                UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
                    ? true
                    : false,
            venueLoaded: false,
            venueListByClient: [],
            entireVenueList: [],
            isFasterPayments: false,
        };
        //this._isMounted = false;
    }

    //Retrieve the lookup values inside the mount
    //If a venue manager logs in Call the client and venue dropdowns and all the required API calls.
    //Else bypaas all the unnecessary API calls and Populate the Start and End dates.
    componentDidMount() {
        //this._isMounted =true;
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.paymentStatusType)
            .then(this.handleSuccessPaymentStatusTypeLookUp)
            .catch(this.handleStandardError);
            
        if(this.props.serviceTypeId !== ""){
            this.setState({
                serviceId: this.props.serviceTypeId,
            });
        } 
        if(this.props.location?.state?.navigateFrom == "PaymentRequestFailed"){
            this.setState({
                paymentStatusId: [90, 40]
            }, () => { 
                this.props.onApplyButtonClick(
                    this.state.providerId,
                    this.state.startDate,
                    this.state.endDate,
                    this.state.serviceId,
                    "",
                    this.state.paymentStatusId,
                    this.state.isFasterPayments
                ); 
            });            
        } 
        else if(this.props.location?.state?.navigateFrom == "PaymentWithFasterPayments"){
            let dateFrom = new Date();
            dateFrom.setDate(dateFrom.getDate() - 15);
            let dateTo = new Date();
            dateTo.setDate(dateTo.getDate() + 1);
            this.setState({
                paymentStatusId: [70],
                isFasterPayments: true,
                minDateValue: dateFrom,
                startDate: this.sharedUtils.convertDateToString(dateFrom),
                maxDateValue: dateTo,
                endDate: this.sharedUtils.convertDateToString(dateTo),
            }, () => { 
                this.props.onApplyButtonClick(
                    this.state.providerId,
                    this.state.startDate,
                    this.state.endDate,
                    this.state.serviceId,
                    "",
                    this.state.paymentStatusId,
                    this.state.isFasterPayments
                ); 
            });            
        } 
        else {       
            this.defaultDateSelectionOnLoad();
        }
    }

    // componentWillUnmount() {
    //     // fix Warning: Can't perform a React state update on an unmounted component
    //     this.setState = (state,callback)=>{
    //         return;
    //     };
    // }

    // componentWillUnmount() {
    //     this._isMounted = false;
    //  }
    //  setState = (state:any, callback?:any) => {
    //     if (this._isMounted) {
    //       super.setState(state, callback);
    //     }
    //  }

    handleSuccessPaymentStatusTypeLookUp = (response: AxiosResponse<any>) => {
        var statusTypeLUItem: LookUpTypeItemForPaymentStatusType;
        var statusTypeLUItemArray: LookUpTypeItemForPaymentStatusType[];
        statusTypeLUItemArray = [];
        response.data.data.map((item: any) => {
            statusTypeLUItem = {
                paymentStatusTypeLookupId: parseInt(item.id),
                value: item.value,
                parentMappingId: "0",
            };
            statusTypeLUItemArray.push(statusTypeLUItem);
        });
        this.setState({
            paymentStatusTypeLookUp: statusTypeLUItemArray,
        });
    };

    handleStandardError = (error: any) => {
        // TODO: use appInsights logging here
        var respMessage: string = "Add/Edit shift form load failed with response: " + JSON.stringify(error);

        if (!this.paymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            serviceId: dxValueChange.value,
        });
        this.props.getServiceTypeId(dxValueChange.value);
    }
    
    handleChangeProviderTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.value) {
            this.setState({
                providerId: dxValueChange.value,
                isFasterPayments: false
            });
        }
    };

    handleChangeStartDateSelect = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.value) {
            this.setState({
                minDateValue: dxValueChange.value,
                startDate: this.sharedUtils.convertDateToString(dxValueChange.value),
                isFasterPayments: false
            });
        }
    };

    handleChangeEndDateSelect = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.value) {
            this.setState({
                maxDateValue: dxValueChange.value,
                endDate: this.sharedUtils.convertDateToString(dxValueChange.value),
                isFasterPayments: false
            });
        }
    };

    handleChangePaymentStatusSelect = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setState({
            paymentStatusId: dxValue.value,
        });
    }; 

    componentDidUpdate = (prevprops: PaymentSidebarPanelProps, prevState: PaymentSidebarPanelState) => {        
        if (this.props.serviceTypeId != prevprops.serviceTypeId) {
            this.setState({
                serviceId: this.props.serviceTypeId,
            })
            if(this.props.location.state?.navigateFrom !== "PaymentRequestFailed"){       
                this.defaultDateSelection();
            }
        }
        if (this.props.location.state?.burgerMenuName != prevprops.location.state?.burgerMenuName) {
            this.setState({
                providerId: "0",
                paymentStatusId: [],
            })
        }
    };

    getBurgerMenuName = () => {
        let menuName = '';
        let serviceId = this.state.serviceId || '';
        if (serviceId == ServiceType.ContractCleaning)
            menuName = "ContractCleaningPayment"
        else if (serviceId == ServiceType.Security)
            menuName = "SecurityPayment"
        else if (serviceId == ServiceType.Entertainment)
            menuName = "EntertainmentPayment"
        else if (serviceId == ServiceType.Gardening)
            menuName = "GardeningPayment"
        else if (serviceId == ServiceType.Maintenance)
            menuName = "MaintenancePayment"
        else if (serviceId == ServiceType.Miscelleanous)
            menuName = "MiscelleanousPayment"
        else if (serviceId == ServiceType.WindowCleaning)
            menuName = 'WindowCleaningPayment'
        return menuName;
    }
       
            

    //Fire the parent component's callback on an Apply click.
    onApply = () => {
        if (this.props?.location?.state?.pointOfNavigation == 'home') {
            let menuName = this.getBurgerMenuName();
            if(menuName){
                let stateObj = {
                    burgerMenuName:menuName
                }
                this.props.location.state = stateObj;
            } else {
                this.props.location.state = {};
            }
        }
        this.props.onApplyButtonClick(
            this.state.providerId,
            this.state.startDate,
            this.state.endDate,
            this.state.serviceId,
            "",
            this.state.paymentStatusId,
            this.state.isFasterPayments,
        );
    };

    defaultDateSelection = () => {
        var dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - 15);
        var dateTo = new Date();
        dateTo.setDate(dateTo.getDate() + 1);
        this.setState({
            minDateValue: dateFrom,
            startDate: this.sharedUtils.convertDateToString(dateFrom),
            maxDateValue: dateTo,
            endDate: this.sharedUtils.convertDateToString(dateTo),
        });
    };

    defaultDateSelectionOnLoad = () => {
        var dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - 15);
        var dateTo = new Date();
        dateTo.setDate(dateTo.getDate() + 1);
        this.setState({
            minDateValue: dateFrom,
            startDate: this.sharedUtils.convertDateToString(dateFrom),
            maxDateValue: dateTo,
            endDate: this.sharedUtils.convertDateToString(dateTo),
        },() => { 
            this.props.onApplyButtonClick(
                this.state.providerId,
                this.state.startDate,
                this.state.endDate,
                this.state.serviceId,
                "",
                this.state.paymentStatusId,
                this.state.isFasterPayments
            ); 
        });
    };

    handleChangeFasterPayments = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isFasterPayments: dxValueChange.value,
        });
        if (dxValueChange.value == true && this.props.location.state?.navigateFrom !== "PaymentWithFasterPayments") {
            this.setState({
                startDate: "",
                endDate: "",
                minDateValue: undefined,
                maxDateValue: undefined,
                providerId: "",

            });
        }
        if(dxValueChange.value == false){
            this.defaultDateSelection();
        }
    }

    render() {
        var isShowProviderDropDown : boolean = true;
        if(UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) || UserService.isUserInGroup(RoleGroupNames.Artist)){
            isShowProviderDropDown = false;
        }
        return (
            <div>
                <div className="row mt-4">
                    <div className="col-10">
                        <h4 className="sidebar__heading font-weight-bold">Filter Payments</h4>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col sidebar__heading">Service</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DropDownFromLookupTableDynamic
                            lookupTypeIndex={LookupTypeIndexes.serviceType}
                            isRoot={true}
                            parentMappingId={"0"}
                            onValueChanged={this.handleChangeServiceTypeSelect}
                            value={this.state.serviceId}
                        />
                        <input
                            data-testid={LookupTypeIndexes.serviceType}
                            type="hidden"
                            name={LookupTypeIndexes.serviceType}
                            value={
                                this.state.serviceId
                                    ? this.state.serviceId
                                    : ""
                            }
                        ></input>
                    </div>
                </div>
                {isShowProviderDropDown ?
                <>
                    {" "}
                    <div className="row mt-3">
                        <div className="col sidebar__heading">Provider</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <>
                                {" "}
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.providerType}
                                    onValueChanged={this.handleChangeProviderTypeSelect}
                                    isRoot={false}
                                    parentMappingId={""}
                                    displayExpression={"value"}
                                    value={this.state.providerId}
                                />
                                <input
                                    data-testid={LookupTypeIndexes.providerType}
                                    type="hidden"
                                    name={LookupTypeIndexes.providerType}
                                ></input>{" "}
                            </>
                        </div>
                    </div>
                </> : null }                
                <div className="row mt-3">
                    <div className="col sidebar__heading">Date from</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeStartDateSelect}
                            value={this.state.minDateValue ? this.state.minDateValue : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col sidebar__heading">Date to</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeEndDateSelect}
                            value={this.state.maxDateValue ? this.state.maxDateValue : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col sidebar__heading">Payment Status</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <TagBox
                            value={this.state.paymentStatusId}
                            dataSource={this.state.paymentStatusTypeLookUp}
                            width={"100%"}
                            valueExpr="paymentStatusTypeLookupId"
                            displayExpr="value"
                            showSelectionControls={true}
                            onValueChanged={this.handleChangePaymentStatusSelect}
                        ></TagBox>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <CheckBox
                            value={this.state.isFasterPayments}
                            onValueChanged={this.handleChangeFasterPayments}
                        />
                        &nbsp; &nbsp;
                        Only Show Faster Payments
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        <Button className="btn btn-primary btn--large" disabled={false} onClick={this.onApply}>
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PaymentSidebarPanel);
