import React from "react";

interface MaskedStatusForActiveProps {
    data: any;
}

interface MaskedStatusForActiveState {
    activeStatus: string;
}

class MaskedStatusForActive extends React.Component<MaskedStatusForActiveProps> {
    state: MaskedStatusForActiveState;
    constructor(props: MaskedStatusForActiveProps) {
        super(props);
        this.state = {
            activeStatus: this.props.data.data.isActive ? "Yes" : "No",
        };
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <span className="status-message-color">{this.state.activeStatus}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default MaskedStatusForActive;
