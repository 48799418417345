import React from "react";
import ApprovedRatingComponent from "./ApprovedRatingComponent";
import ClientVenuesGrid from "./ClientVenuesGrid";

interface ClientInformationViewAllContentProps {
    clientId: string;
    venueId: string;
}
// State
interface ClientInformationViewAllContentState {
    clientId: string;
    venueId: string;
}

class ClientInformationViewAllContent extends React.Component<ClientInformationViewAllContentProps> {
    state: ClientInformationViewAllContentState;
    constructor(props: ClientInformationViewAllContentProps) {
        super(props);
        this.state = {
            clientId: "",
            venueId: "",
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {}

    componentDidUpdate(prevProps: ClientInformationViewAllContentProps) {
        if (this.props.clientId != prevProps.clientId) {
            this.setState({
                clientId: "",
                venueId: "",
            });
        }
    }

    setClientVenueId = (clientId: string, venueId: string) => {
        this.setState({
            clientId: clientId,
            venueId: venueId,
        });
    };

    render() {
        return (
            <>
                <div className="card mt-4">
                    <div className="card-body">
                        <h5>
                            <b>Client Venues</b>
                        </h5>
                        <br></br>
                        <ClientVenuesGrid
                            clientId={this.props.clientId}
                            setClientVenueId={this.setClientVenueId}
                        ></ClientVenuesGrid>
                        {this.state.clientId && this.state.venueId ? (
                            <div>
                                <ApprovedRatingComponent
                                    clientId={this.state.clientId}
                                    venueId={this.state.venueId}
                                    dataSource={null}
                                ></ApprovedRatingComponent>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default ClientInformationViewAllContent;
