import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, DateBox, CheckBox } from "devextreme-react";
import LookupService from "../../services/LookupService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import sharedUtils from "../grid/sharedUtilities";
import { DateBoxOnValueChangedEvent, CheckBoxOnValueChanged } from "../../types/DevExtremeTypes";
import CostReportService from "../../services/CostReportService";
import CostReportUtils from "../CostReports/CostReportUtils";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import GlobalService from "../../services/GlobalService";
import { ClickEvent } from "devextreme/ui/button";

interface GigRequestSidebarPanelProps extends WithTranslation {
    onApplyButtonClick: (
        startDateId: string,
        endDateId: string,
        isApplyClicked:boolean
    ) => void;

}

interface GigRequestSidebarPanelState {
    startDate: NullableDate;
    endDate: NullableDate;
    isVenueManager: boolean;
    isProvider: boolean;
    isEventUk: boolean;
    isHistoricalData: boolean;
}

class GigRequestSidebarPanel extends Component<GigRequestSidebarPanelProps> {
    lookupService: LookupService;
    state: GigRequestSidebarPanelState;
    sharedUtils: sharedUtils;
    Service: CostReportService;
    utils: CostReportUtils;
    constructor(props: GigRequestSidebarPanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.sharedUtils = new sharedUtils();
        this.utils = new CostReportUtils();
        this.state = {
            startDate: null,
            endDate: null,
            isVenueManager: UserService.isUserInGroup(RoleGroupNames.VenueManager) ? true : false,
            isProvider: UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ? true : false,
            isEventUk:
                UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                    UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
                    ? true
                    : false,
            isHistoricalData: false
        };
    }

    //Retrieve the lookup values inside the mount
    //If a venue manager logs in Call the client and venue dropdowns and all the required API calls.
    //Else bypaas all the unnecessary API calls and Populate the Start and End dates.
    componentDidMount() {
        this.setIntialValueForDates();
    }

    setIntialValueForDates = () => {
        this.setState({
            startDate: new Date(),
            endDate: null
        }, () => { this.onApply() });
    }


    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };


    handleChangeStartDateSelect = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            startDate: dxValueChange.value,
        });
    };

    handleChangeEndDateSelect = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            endDate: dxValueChange.value,
        });
    };

    handleChangeHistorical = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isHistoricalData: dxValueChange.value
        }, () => {this.setDateFromDateToBasedOnHistorical(dxValueChange.value)});
    }

    setDateFromDateToBasedOnHistorical = (isHistorical : boolean) => {
        if (isHistorical) {
            this.setState({
                startDate: null,
                endDate: null,
            });
        }
        else {
            this.setState({
                startDate: new Date(),
                endDate: null
            });
        }
    }
    //Fire the parent component's callback on an Apply click.
    onApply = (isApplyClicked:boolean = false) => {
        this.props.onApplyButtonClick(
            this.sharedUtils.convertDateToString(this.state.startDate),
            this.sharedUtils.convertDateToString(this.state.endDate),
            isApplyClicked
        );
        GlobalService.setGigFilterValues(this.state.startDate, this.state.endDate);
    }; 


    render() {
        return (
            <div>
                <div className="row mt-4">
                    <div className="col-10">
                        <h4 className="sidebar__heading font-weight-bold">Filter shifts</h4>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col sidebar__heading">Date from</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeStartDateSelect}
                            value={this.state.startDate ? this.state.startDate : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Date to</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeEndDateSelect}
                            value={this.state.endDate ? this.state.endDate : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <CheckBox
                            value={this.state.isHistoricalData}
                            onValueChanged={this.handleChangeHistorical}
                        />
                        &nbsp; &nbsp;
                        Include Historical
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        <Button
                            className="btn btn-primary btn--large"
                            disabled={false}
                            onClick={(e: ClickEvent) => {
                                //e.preventDefault();
                                this.onApply(true)
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(GigRequestSidebarPanel);
