import {
    ChartData,
    ResponseFinancialItem,
    FinancialItem,
    ResponsePeriodWeek,
    PeriodWeek,
    BudgetSpendBarChart,
} from "../../services/ScheduleService";
import { LookupTypeItem } from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import UserService, { RoleGroupNames } from "../../services/UserService";

/**
 * Predicate used to sort lookup item array by ID
 *
 * @param a {string}
 * @param b {string}
 */
export const compareLookupIDs = (a: LookupTypeItem, b: LookupTypeItem): number => {
    let keyA: number = parseInt(a.id);
    let keyB: number = parseInt(b.id);

    let comparison = 0;
    if (keyA > keyB) {
        comparison = 1;
    } else if (keyA < keyB) {
        comparison = -1;
    }
    return comparison;
};
/**
 * Initialize the ChartData object
 *
 * @returns {ChartData}
 */
export const initialChartData = (): ChartData => {
    return {
        itemToDateSecurity: "",
        itemToDateCleaning: "",
        itemToDateEnt: "",
        itemToDateGard: "",
        itemToDateMain: "",
        itemToDateWind: "",
        itemToDateMisc: "",
        currentWeekSecurity: "",
        currentWeekCleaning: "",
        currentWeekEnt: "",
        currentWeekGard: "",
        currentWeekMain: "",
        currentWeekWind: "",
        currentWeekMisc: "",
        currentPeriodSecurity: "",
        currentPeriodCleaning: "",
        currentPeriodEnt: "",
        currentPeriodGard: "",
        currentPeriodMain: "",
        currentPeriodWind: "",
        currentPeriodMisc: "",
        periodDataSourceForSecurity: [],
        periodDataSourceForCleaning: [],
        periodDataSourceForEnt: [],
        periodDataSourceForGard: [],
        periodDataSourceForMain: [],
        periodDataSourceForWind: [],
        periodDataSourceForMisc: [],
        BudgetSpendBarChartSecurity: [],
        BudgetSpendBarChartCleaning: [],
        BudgetSpendBarChartEnt: [],
        BudgetSpendBarChartGard: [],
        BudgetSpendBarChartMain: [],
        BudgetSpendBarChartWind: [],
        BudgetSpendBarChartMisc: [],
        selectedPeriodSecurity: {
            serviceSpecificValue: {
                serviceTypeId: "",
                serviceColor: "",
                clientId: "",
                venueId: "",
            },
            period: "",
            budget: 0.0,
            spend: 0.0,
            current: "",
            weeks: [],
        },
        selectedPeriodEnt: {
            serviceSpecificValue: {
                serviceTypeId: "",
                serviceColor: "",
                clientId: "",
                venueId: "",
            },
            period: "",
            budget: 0.0,
            spend: 0.0,
            current: "",
            weeks: [],
        },
        selectedPeriodGard: {
            serviceSpecificValue: {
                serviceTypeId: "",
                serviceColor: "",
                clientId: "",
                venueId: "",
            },
            period: "",
            budget: 0.0,
            spend: 0.0,
            current: "",
            weeks: [],
        },
        selectedPeriodMain: {
            serviceSpecificValue: {
                serviceTypeId: "",
                serviceColor: "",
                clientId: "",
                venueId: "",
            },
            period: "",
            budget: 0.0,
            spend: 0.0,
            current: "",
            weeks: [],
        },
        selectedPeriodWind: {
            serviceSpecificValue: {
                serviceTypeId: "",
                serviceColor: "",
                clientId: "",
                venueId: "",
            },
            period: "",
            budget: 0.0,
            spend: 0.0,
            current: "",
            weeks: [],
        },
        selectedPeriodMisc: {
            serviceSpecificValue: {
                serviceTypeId: "",
                serviceColor: "",
                clientId: "",
                venueId: "",
            },
            period: "",
            budget: 0.0,
            spend: 0.0,
            current: "",
            weeks: [],
        },
        selectedPeriodCleaning: {
            serviceSpecificValue: {
                serviceTypeId: "",
                serviceColor: "",
                clientId: "",
                venueId: "",
            },
            period: "",
            budget: 0.0,
            spend: 0.0,
            current: "",
            weeks: [],
        },
        yearToDateSpendSecurity: 0.0,
        fullYearBudgetSecurity: 0.0,
        yearToDateSpendCleaning: 0.0,
        fullYearBudgetCleaning: 0.0,
        yearToDateSpendEnt: 0.0,
        fullYearBudgetEnt: 0.0,
        yearToDateSpendGard: 0.0,
        fullYearBudgetGard: 0.0,
        yearToDateSpendMain: 0.0,
        fullYearBudgetMain: 0.0,
        yearToDateSpendWind: 0.0,
        fullYearBudgetWind: 0.0,
        yearToDateSpendMisc: 0.0,
        fullYearBudgetMisc: 0.0,
        overspend: 0.0,
    };
};
/**
 * Shared utilities
 */
const utils = new sharedUtils();
/**
 * Calculate how many weeks into the current period are in the past.
 *
 * @param weeks {PeriodWeek[]} - Weeks in the period
 * @returns {number} - the number of weeks into the current period
 */
const numberOfWeeksIntoCurrentPeriod = (weeks: PeriodWeek[]): number => {
    let dateToCompare = new Date();
    // The weeks array will contain dates seven days from today; must adjust 'today' to match
    dateToCompare.setDate(dateToCompare.getDate() - 7);
    let weeksIntoPeriod = 0;
    let weekIndex;

    for (weekIndex = 0; weekIndex < weeks.length; weekIndex++) {
        let dateFrom = utils.convertStringToDate(weeks[weekIndex].dateFrom);
        let dateTo = utils.convertStringToDate(weeks[weekIndex].dateTo);
        if (dateFrom && dateTo) {
            if (dateFrom <= dateToCompare && dateTo >= dateToCompare) {
                // Skip the current week from the calculation
                weeksIntoPeriod++;
                break;
            } else {
                weeksIntoPeriod++;
            }
        }
    }

    return weeksIntoPeriod;
};
/**
 * Calculate budge and spend for the period based on data from the weeks in the peroid
 *
 * @param weekInPeriod {PeriodWeek[]} - The weeks within a period
 * @returns { { budget: number, spend: number } } - Total budget and spend for all weeks in period
 */
const calculateTotalBudgetAndSpend = (weekInPeriod: ResponsePeriodWeek[]): { budget: number; spend: number } => {
    let totals = {
        budget: 0.0,
        spend: 0.0,
    };

    if (weekInPeriod) {
        weekInPeriod.forEach((element) => {
            totals.budget += utils.convertStringToFloat(element.budget);
            totals.spend += utils.convertStringToFloat(element.spend);
        });
    }

    return totals;
};
/**
 * Extend data from DB to accommodate chart
 *
 * @param periodWeeks {PeriodWeek[]} - The weeks within a period
 * @returns {PeriodWeek[]} - Updated list of weeks within a period
 */
const setSubChartXAxisLable = (
    periodWeeks: ResponsePeriodWeek[],
    periodNumber: string,
    clientId: string,
    venueId: string,
    serviceTypeId: string,
    current: string
): PeriodWeek[] => {
    let modifiedWeekList: PeriodWeek[] = [];

    periodWeeks.forEach((item: ResponsePeriodWeek) => {
        // Create a new modified week item (a sub component will require additional fields for each week: serviceTypeId, clientId, venueId, periodNumber)
        let modifiedItem: PeriodWeek = {
            periodMatrixId: item.periodMatrixId,
            number: item.number,
            dateFrom: item.dateFrom,
            dateTo: item.dateTo,
            budget: utils.convertStringToFloat(item.budget),
            spend: utils.convertStringToFloat(item.spend),
            scheduleInfo: {
                count: "",
                serviceType: serviceTypeId,
                serviceSubType: "",
                shiftStart: new Date(),
                shiftEnd: new Date(),
                clientId: clientId,
                venueId: venueId,
            },
            periodNumber: periodNumber,
            current: item.current,
            invoiceId: item.invoiceId,
        };
        // Add to the list
        modifiedWeekList.push(modifiedItem);
    });

    return modifiedWeekList;
};
/**
 * Build data points from API response to display in financial chart
 *
 * @param typedResponse {FinancialItem[]} - All financial data from API
 * @returns {ChartData} - Data points for financial chart
 */
export const buildDataPoints = (
    typedResponse: ResponseFinancialItem[],
    clientId: string,
    venueId: string,
    serviceTypeId: string
): ChartData => {
    let weeksRemaining = 0;
    let dataSource: FinancialItem[] = [];
    let dataSourceForVenue: FinancialItem[] = [];
    let barChartDataSource: BudgetSpendBarChart[] = [];
    let currentPeriodIndex = 0;
    let currentPeriodFound = false;
    let currentWeekValue = "";
    let itemToDateValue = "";
    let loopIndex = 0;
    let yearToDateSpend = 0;
    let yearToDateBudget = 0;
    let fullYearBudget = 0;
    let yearToDateSpendForBarChart = 0;
    let yearToDateBudgetForBarChart = 0;
    let fullYearBudgetForBarChart = 0;
    let overspendReductionRequiredPerWeek = 0;
    let dataCount = 0;
    let lastIndexShown = 0;
    let countOfCurrentData = 0;
    let loopIndexOfWeek = 0;
    let currentWeekIndex = 0;
    // Calculate budge and spend for the period based on data from the weeks in the peroid
    typedResponse.forEach((element) => {
        // Check if this data point is the current period
        if (element.current.toLowerCase() === "true") {
            currentPeriodIndex = loopIndex;
            currentPeriodFound = true;
            element.weeks.forEach((item) => {
                if (item.current.toLowerCase() === "true") {
                    currentWeekIndex = loopIndexOfWeek;
                    currentWeekValue = item.number;
                    itemToDateValue = item.itemToDate;
                }
                loopIndexOfWeek++;
            });
            countOfCurrentData = 1;
        }
        // Calculate budget and spend for the period
        let budgetAndSpendForPeriod = calculateTotalBudgetAndSpend(element.weeks);
        if (!currentPeriodFound && !currentWeekValue && countOfCurrentData == 0) {
            // Add period's budget to running total for the year
            // Add to YTD spend and budget (future spend accumulates with budget)
            fullYearBudgetForBarChart += budgetAndSpendForPeriod.budget;
            yearToDateBudgetForBarChart += budgetAndSpendForPeriod.budget;
            yearToDateSpendForBarChart += budgetAndSpendForPeriod.spend;
        } else if (currentPeriodFound && currentWeekValue && countOfCurrentData == 1) {
            budgetAndSpendForPeriod = calculateTotalBudgetAndSpend(element.weeks.slice(0, currentWeekIndex + 1));
            // Add period's budget to running total for the year
            // Add to YTD spend and budget (future spend accumulates with budget)
            fullYearBudgetForBarChart += budgetAndSpendForPeriod.budget;
            yearToDateBudgetForBarChart += budgetAndSpendForPeriod.budget;
            yearToDateSpendForBarChart += budgetAndSpendForPeriod.spend;
            countOfCurrentData = 0;
        }

        // Add period's budget to running total for the year
        fullYearBudget += budgetAndSpendForPeriod.budget;
        // Add to YTD spend and budget (future spend accumulates with budget)
        yearToDateBudget += budgetAndSpendForPeriod.budget;
        if (currentPeriodFound) {
            yearToDateSpend += budgetAndSpendForPeriod.spend;
            // Track the number of weeks remaining in the year (weeks past the current period)
            weeksRemaining += element.weeks.length;
        } else {
            yearToDateSpend += budgetAndSpendForPeriod.spend;
            //yearToDateSpend += budgetAndSpendForPeriod.spend + 15000;
        }

        // Create a new data point
        let extendedItem: FinancialItem = {
            serviceSpecificValue: {
                serviceTypeId: "",
                serviceColor: "",
                clientId: "",
                venueId: "",
            },
            period: element.period,
            current: element.current,
            budget: fullYearBudget,
            spend: yearToDateSpend,
            weeks: setSubChartXAxisLable(
                element.weeks,
                element.period,
                clientId,
                venueId,
                serviceTypeId,
                element.current
            ),
        };

        //VenueManager DataSource Creation
        if (element.current.toLowerCase() == "true") {
            dataCount = 1;
            dataSourceForVenue.push(extendedItem);
        } else if (element.current.toLowerCase() == "false" && dataCount == 1) {
            let reshapedItem: FinancialItem = {
                serviceSpecificValue: {
                    serviceTypeId: "",
                    serviceColor: "",
                    clientId: "",
                    venueId: "",
                },
                period: element.period,
                current: element.current,
                budget: fullYearBudget,
                weeks: setSubChartXAxisLable(
                    element.weeks,
                    element.period,
                    clientId,
                    venueId,
                    serviceTypeId,
                    element.current
                ),
            };
            dataSourceForVenue.push(reshapedItem);
        } else if (element.current.toLowerCase() == "false") {
            dataSourceForVenue.push(extendedItem);
        }
        // Add new data point to the list
        dataSource.push(extendedItem);
        loopIndex++;
    });
    let budgetItems: BudgetSpendBarChart = {
        label: "BUDGET",
        value: fullYearBudgetForBarChart,
    };
    let spendItems: BudgetSpendBarChart = {
        label: "SPEND",
        value: yearToDateSpendForBarChart,
    };
    barChartDataSource.push(spendItems);
    barChartDataSource.push(budgetItems);
    // Calculate predicted spend in order to calculate overspend
    let predictedSpend = yearToDateSpend + (fullYearBudget - yearToDateBudget);
    let overspend = yearToDateBudget - predictedSpend;
    // Calculate reduction required per week
    if (overspend < 0) {
        // The weeks remaining include all the weeks in the current period; need to remove weeks in the past to get a more granular weeks remaining total
        weeksRemaining = weeksRemaining - numberOfWeeksIntoCurrentPeriod(dataSource[currentPeriodIndex].weeks);
        let validatedWeeksRemaining = weeksRemaining > 0 ? weeksRemaining : 0;
        // Convert the negative number to a positive number then divide by number of weeks remaining in the year.
        validatedWeeksRemaining > 0
            ? (overspendReductionRequiredPerWeek = (overspend * -1) / validatedWeeksRemaining)
            : (overspendReductionRequiredPerWeek = overspend * -1);
    }

    return {
        itemToDateSecurity: itemToDateValue,
        itemToDateCleaning: itemToDateValue,
        itemToDateEnt: itemToDateValue,
        itemToDateGard: itemToDateValue,
        itemToDateMain: itemToDateValue,
        itemToDateWind: itemToDateValue,
        itemToDateMisc: itemToDateValue,
        currentWeekSecurity: currentWeekValue,
        currentWeekCleaning: currentWeekValue,
        currentWeekEnt: currentWeekValue,
        currentWeekGard: currentWeekValue,
        currentWeekMain: currentWeekValue,
        currentWeekWind: currentWeekValue,
        currentWeekMisc: currentWeekValue,
        currentPeriodSecurity: dataSource[currentPeriodIndex].period,
        currentPeriodCleaning: dataSource[currentPeriodIndex].period,
        currentPeriodEnt: dataSource[currentPeriodIndex].period,
        currentPeriodGard: dataSource[currentPeriodIndex].period,
        currentPeriodMain: dataSource[currentPeriodIndex].period,
        currentPeriodWind: dataSource[currentPeriodIndex].period,
        currentPeriodMisc: dataSource[currentPeriodIndex].period,
        periodDataSourceForSecurity:
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
                ? dataSource
                : dataSourceForVenue,
        periodDataSourceForCleaning:
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
                ? dataSource
                : dataSourceForVenue,
        periodDataSourceForEnt:
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
                ? dataSource
                : dataSourceForVenue,
        periodDataSourceForGard:
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
                ? dataSource
                : dataSourceForVenue,
        periodDataSourceForMain:
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
                ? dataSource
                : dataSourceForVenue,
        periodDataSourceForWind:
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
                ? dataSource
                : dataSourceForVenue,
        periodDataSourceForMisc:
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
                ? dataSource
                : dataSourceForVenue,
        selectedPeriodSecurity: dataSource[currentPeriodIndex],
        selectedPeriodEnt: dataSource[currentPeriodIndex],
        selectedPeriodGard: dataSource[currentPeriodIndex],
        selectedPeriodMain: dataSource[currentPeriodIndex],
        selectedPeriodWind: dataSource[currentPeriodIndex],
        selectedPeriodMisc: dataSource[currentPeriodIndex],
        selectedPeriodCleaning: dataSource[currentPeriodIndex],
        yearToDateSpendSecurity: yearToDateSpendForBarChart,
        fullYearBudgetSecurity: fullYearBudgetForBarChart,
        yearToDateSpendCleaning: yearToDateSpendForBarChart,
        fullYearBudgetCleaning: fullYearBudgetForBarChart,
        yearToDateSpendEnt: yearToDateSpendForBarChart,
        fullYearBudgetEnt: fullYearBudgetForBarChart,
        yearToDateSpendGard: yearToDateSpendForBarChart,
        fullYearBudgetGard: fullYearBudgetForBarChart,
        yearToDateSpendMain: yearToDateSpendForBarChart,
        fullYearBudgetMain: fullYearBudgetForBarChart,
        yearToDateSpendWind: yearToDateSpendForBarChart,
        fullYearBudgetWind: fullYearBudgetForBarChart,
        yearToDateSpendMisc: yearToDateSpendForBarChart,
        fullYearBudgetMisc: fullYearBudgetForBarChart,
        BudgetSpendBarChartSecurity: barChartDataSource,
        BudgetSpendBarChartCleaning: barChartDataSource,
        BudgetSpendBarChartEnt: barChartDataSource,
        BudgetSpendBarChartGard: barChartDataSource,
        BudgetSpendBarChartMain: barChartDataSource,
        BudgetSpendBarChartWind: barChartDataSource,
        BudgetSpendBarChartMisc: barChartDataSource,
        overspend: overspendReductionRequiredPerWeek,
    };
};
