import { VenueProviderFormRequest, VenueProviderFormResponse } from "../../services/VenueProviderService";
import lookupService from "../../services/LookupService";
import React from "react";
import sharedUtils from "../grid/sharedUtilities";

type NullableBool = boolean | null | undefined;
export interface ProviderServiceFormUtilProps {}
export interface AddEditVenueProviderFormData {
    id: string;
    venueId: string;
    venueName: string;
    houseNo: string;
    providerBusinessEntityId: string;
    providerBusinessEntity: string;
    clientBusinessEntityId: string;
    clientBusinessEntity: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    isActive: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    validationErrors: string;
    city: string;
    county: string;
    postCode: string;
}

export interface LookUpDetailsInformation {
    showProgressIndicator: boolean;
}
export default class ProviderServiceFormUtil extends React.Component<ProviderServiceFormUtilProps> {
    utils: sharedUtils;
    dropdownService: lookupService;
    state: LookUpDetailsInformation;
    constructor(props: ProviderServiceFormUtilProps) {
        super(props);
        this.utils = new sharedUtils();
        this.dropdownService = new lookupService();
        this.state = {
            showProgressIndicator: false,
        };
    }

    initializeVenueProviderFormItem = (): AddEditVenueProviderFormData => {
        var formData: AddEditVenueProviderFormData = {
            id: "0",
            venueId: "",
            venueName: "",
            houseNo: "",
            providerBusinessEntityId: "",
            providerBusinessEntity: "",
            clientBusinessEntityId: "",
            clientBusinessEntity: "",
            serviceTypeId: "",
            serviceType: "",
            serviceSubTypeId: "",
            serviceSubType: "",
            isActive: true,
            lastUpdatedOn: "",
            lastUpdatedBy: "",
            validationErrors: "",
            city: '',
            county: '',
            postCode: ''
        };

        return formData;
    };

    // Convert the server response into the component's form state
    convertVenueProviderItemResponeToFormData = (
        serverResponse: VenueProviderFormResponse
    ): AddEditVenueProviderFormData => {
        var formData: AddEditVenueProviderFormData = {
            id: serverResponse.id,
            venueId: serverResponse.venueId,
            venueName: serverResponse.venueName,
            houseNo: serverResponse.houseNo,
            providerBusinessEntityId: serverResponse.providerBusinessEntityId,
            providerBusinessEntity: serverResponse.providerBusinessEntity,
            clientBusinessEntityId: serverResponse.clientBusinessEntityId,
            clientBusinessEntity: serverResponse.clientBusinessEntity,
            serviceTypeId: serverResponse.serviceTypeId,
            serviceType: serverResponse.serviceType,
            serviceSubTypeId: serverResponse.serviceSubTypeId,
            serviceSubType: serverResponse.serviceSubType,
            isActive: serverResponse.isActive,
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            lastUpdatedBy: serverResponse.lastUpdatedBy,
            validationErrors: serverResponse.validationErrors,
            city: serverResponse?.city,
            county: serverResponse?.county,
            postCode: serverResponse?.postCode
        };

        return formData;
    };

    // Convert the form's data into a proper item submit request
    convertFormDataToVenueProviderRequest = (formData: AddEditVenueProviderFormData): VenueProviderFormRequest => {
        var submitRequest: VenueProviderFormRequest = {
            id: formData.id,
            venueId: formData.venueId,
            venueName: formData.venueName,
            houseNo: formData.houseNo,
            providerBusinessEntityId: formData.providerBusinessEntityId,
            providerBusinessEntity: formData.providerBusinessEntity,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            clientBusinessEntity: formData.clientBusinessEntity,
            serviceTypeId: formData.serviceTypeId,
            serviceType: formData.serviceType,
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceSubType: formData.serviceSubType,
            isActive: formData.isActive,
            lastUpdatedOn: formData.lastUpdatedOn,
            lastUpdatedBy: formData.lastUpdatedBy,
        };
        return submitRequest;
    };
}
