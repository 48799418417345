import sharedUtils from "../grid/sharedUtilities";
import { IFAQFormRequest, IFAQFormResponse } from "../../services/MatrixService";

type NullableDate = Date | null | undefined;

export interface FAQFormData {
    id: string,
    categoryLookUpId: string,
    categoryLookUpValue: string,
    app: string,
    appName: string,
    categoryOrder: string,
    questionOrder: string,
    name: string,
    question: string,
    answer: string,
    isPopUpRequired: boolean,
    faqDate: NullableDate,
    validFrom: NullableDate,
    validTo: NullableDate,
    isActive: boolean,
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    validationErrors: [];
}

export default class FAQFormUtil {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
    }

    initializeFAQFormItem = (idFromGrid: string): FAQFormData => {
        var formData: FAQFormData = {
            id: "0",
            categoryLookUpId: "",
            categoryLookUpValue: "",
            app: "",
            appName: "",
            categoryOrder: "",
            questionOrder: "",
            name: "",
            question: "",
            answer: "",
            isPopUpRequired: false,
            faqDate: undefined,
            validFrom: undefined,
            validTo: undefined,
            isActive: true,
            lastUpdatedOn: "",
            lastUpdatedBy: "",
            validationErrors: []
        };
        return formData;
    };

    // Convert the server response into the component's form state
    convertFAQResponeToFormData = (serverResponse: IFAQFormResponse): FAQFormData => {
        var formData: FAQFormData = {
            id: serverResponse.id,
            categoryLookUpId: serverResponse.categoryLookUpId,
            categoryLookUpValue: serverResponse.categoryLookUpValue,
            app: serverResponse.app ? this.utils.convertNumberToString(serverResponse.app) : "",
            appName: serverResponse.appName,
            categoryOrder: serverResponse.categoryOrder,
            questionOrder: serverResponse.questionOrder,
            name: serverResponse.name,
            question: serverResponse.question,
            answer: serverResponse.answer,
            isPopUpRequired: serverResponse.isPopUpRequired,
            faqDate: serverResponse.faqDate ? this.utils.convertStringToDate(serverResponse.faqDate) : undefined,
            validFrom: serverResponse.validFrom ? this.utils.convertStringToDate(serverResponse.validFrom) : undefined,
            validTo: serverResponse.validTo ? this.utils.convertStringToDate(serverResponse.validTo) : undefined,
            isActive: serverResponse.isActive,
            lastUpdatedOn: serverResponse.lastUpdatedOn ? serverResponse.lastUpdatedOn : "",
            lastUpdatedBy: serverResponse.lastUpdatedBy ? serverResponse.lastUpdatedBy : "",
            validationErrors: serverResponse.validationErrors
        };
        return formData;
    };

    // Convert the form's data into a proper item submit request
    convertFormDataToFAQRequest = (formData: FAQFormData): IFAQFormRequest => {
        var submitRequest: IFAQFormRequest = {
            id: formData.id,
            categoryLookUpId: formData.categoryLookUpId,
            app: formData.app ? this.utils.convertStringToInt(formData.app) : 0,
            questionOrder: formData.questionOrder,
            name: formData.name,
            question: formData.question,
            answer: formData.answer,
            isPopUpRequired: formData.isPopUpRequired,
            faqDate: formData.faqDate ? this.utils.convertDateToString(formData.faqDate) : "",
            validFrom: formData.validFrom ? this.utils.convertDateToString(formData.validFrom) : "",
            validTo: formData.validTo ? this.utils.convertDateToString(formData.validTo) : "",
            isActive: formData.isActive
        };
        return submitRequest;
    };
}
