import React from "react";
import { Link } from "react-router-dom";
import ArtistService, { queryCardData, actionItemData, gigItemData } from "../../services/ArtistService";
import GigsCard from "./GigsCard";
import { AxiosResponse } from "axios";
import { LoadIndicator } from "devextreme-react";
import CollapsibleCardGrid from "../Cards/CollapsibleCardGrid";
import LazyLoad from "react-lazyload";
import { LazyLoadConfig } from "../grid/sharedUtilities";

interface EntLandPageItemListProps {
    location: any;
    history: any;
    /*  querycardList: queryCardData[];
     actioncardList: actionItemData[];
     gigcardList: gigItemData[];
     totalNumberGig: string;
     totalNumberAction: string;
     totalNumberQuery: string;
     showProgressIndicatorGig: boolean;
     showProgressIndicatorAction: boolean;
     showProgressIndicatorQuery: boolean;
     actionCardNoDataMessage: string;
     gigsCardNoDataMessage: string;
     queryCardNoDataMessage: string; */
}

interface EntLandPageItemListState {
    querycardList: queryCardData[];
    actioncardList: actionItemData[];
    gigcardList: gigItemData[];
    totalNumberGig: string;
    totalNumberAction: string;
    totalNumberQuery: string;
    showProgressIndicatorGig: boolean;
    showProgressIndicatorAction: boolean;
    showProgressIndicatorQuery: boolean;
    serviceTypeId: string;
    serviceTypeName: string;
    expandedForGig: boolean;
    expandedForAction: boolean;
    expandedForQuery: boolean;
}

class EntLandPageItemList extends React.Component<EntLandPageItemListProps> {
    //State object to manage the state of the List of cards.
    state: EntLandPageItemListState;
    //Service that fetches the data for the individual cards.
    artistService: ArtistService;
    actionCardNoDataMessage: string;
    gigsCardNoDataMessage: string;
    queryCardNoDataMessage: string;

    constructor(props: EntLandPageItemListProps) {
        super(props);
        //Initializing the service.
        this.artistService = new ArtistService();
        (this.actionCardNoDataMessage = ""), (this.gigsCardNoDataMessage = ""), (this.queryCardNoDataMessage = "");

        this.state = {
            querycardList: [],
            actioncardList: [],
            gigcardList: [],
            totalNumberGig: "0",
            totalNumberAction: "0",
            totalNumberQuery: "0",
            showProgressIndicatorGig: false,
            showProgressIndicatorAction: false,
            showProgressIndicatorQuery: false,
            serviceTypeId: "30",
            serviceTypeName: "Entertainment",
            expandedForGig: false,
            expandedForAction: false,
            expandedForQuery: false,
        };
    }

    setProgressIndicators = (index: number, value: boolean): boolean[] => {
        let spinnerValues: boolean[] = [];
        spinnerValues[index] = value;

        return spinnerValues;
    };

    componentDidMount() {
        this.fetchGigCardDetails();
        this.fetchActionCardDetails();
        this.fetchQueryCardDetails();
    }
    componentDidUpdate(prevProps: EntLandPageItemListProps, prevState: EntLandPageItemListState) {
        if (
            prevState.gigcardList.length == this.state.gigcardList.length &&
            this.state.gigcardList.length == 0 &&
            prevState.actioncardList.length == this.state.actioncardList.length &&
            this.state.actioncardList.length == 0 &&
            prevState.querycardList.length == this.state.querycardList.length &&
            this.state.querycardList.length == 0 &&
            !this.state.showProgressIndicatorAction &&
            !this.state.showProgressIndicatorGig &&
            !this.state.showProgressIndicatorQuery
        ) {
            this.navigateGigList();
        }
    }
    fetchQueryCardDetails = () => {
        this.setState({
            showProgressIndicatorQuery: true,
        });

        this.artistService
            .getQueryCardList(this.state.serviceTypeId)
            .then(this.handleQuerySuccess)
            .catch(this.handleFailure);
    };
    fetchActionCardDetails = () => {
        this.setState({
            showProgressIndicatorAction: true,
        });

        this.artistService
            .getActionCardList(this.state.serviceTypeName)
            .then(this.handleActionSuccess)
            .catch(this.handleFailure);
    };

    //Arrow functions bind the context of 'this' inside the function itself, so no need to bind inside of the constructor.
    //To fetch in the card details from the server.
    fetchGigCardDetails = () => {
        this.setState({
            showProgressIndicatorGig: true,
        });

        this.artistService
            .getGigCardList(this.state.serviceTypeId)
            .then(this.handleGigSuccess)
            .catch(this.handleFailure);
    };

    handleQuerySuccess = (response: AxiosResponse<any>) => {
        if (response.data && response.data.data.length > 0) {
            this.queryCardNoDataMessage = "";
            this.setState({
                showProgressIndicatorQuery: false,
                querycardList: response.data.data,
                totalNumberQuery: response.data.data.length,
            });
        } else {
            this.queryCardNoDataMessage = "No data found for Queries";
            this.setState({ showProgressIndicatorQuery: false });
        }
    };

    handleActionSuccess = (response: AxiosResponse<any>) => {
        if (response.status === 204) {
            this.actionCardNoDataMessage = "No data found for Outstanding Actions";
            this.setState({ showProgressIndicatorAction: false });
        } else {
            this.actionCardNoDataMessage = "";
            this.setState({
                showProgressIndicatorAction: false,
                actioncardList: response.data.data.eventUkUserGridLookUp,
                totalNumberAction: response.data.data.eventUkUserGridLookUp.length,
            });
        }
    };

    handleGigSuccess = (response: AxiosResponse<any>) => {
        if (response.status === 204) {
            this.gigsCardNoDataMessage = "No data found for Upcoming Gigs";
            this.setState({ showProgressIndicatorGig: false });
        } else {
            this.gigsCardNoDataMessage = "";
            this.setState({
                showProgressIndicatorGig: false,
                gigcardList: response.data.data.eventUkUserGridLookUp,
                totalNumberGig: response.data.data.eventUkUserGridLookUp.length,
            });
        }
    };

    handleFailure = (response: AxiosResponse<any>) => {
        this.queryCardNoDataMessage = "";
        this.gigsCardNoDataMessage = "";
        this.actionCardNoDataMessage = "";

        this.setState({
            showProgressIndicatorQuery: false,
            showProgressIndicatorAction: false,
            gigsCardNoDataMessage: false,
        });
    };
    navigateGigList = () => {
        this.props.history.push({
            pathname: "/gigCardList",
        });
    };
    render() {
        let serialNoGig = 1;
        let serialNoAction = 1;
        let serialNoQuery = 1;
        return (
            <>
                <section className="collapsible-grid__container">
                    {this.state.gigcardList.length > 0 ? (
                        <LazyLoad height={LazyLoadConfig.height} offset={LazyLoadConfig.offset}>
                            <CollapsibleCardGrid title="My Upcoming Gigs" numItems={this.state.gigcardList.length}>
                                {this.state.gigcardList.map((gigCard: gigItemData, index: any) => (
                                    <div className="col col-12 col-md-6 col-lg-4" key={index}>
                                        <GigsCard
                                            cardUIType="gigs"
                                            gigCardItem={gigCard}
                                            totalNumber={this.state.gigcardList.length}
                                            serialNumber={serialNoGig++}
                                            location={this.props.location}
                                            history={this.props.history}
                                        />
                                    </div>
                                ))}
                            </CollapsibleCardGrid>
                        </LazyLoad>
                    ) : this.state.showProgressIndicatorGig ? (
                        <div className="row">
                            <span className="col-lg-12 text-center">
                                <LoadIndicator
                                    id="simple-grid-indicator"
                                    height={100}
                                    width={100}
                                    visible={this.state.showProgressIndicatorGig}
                                />
                            </span>
                        </div>
                    ) : (
                        <>
                            <header className="collapsible-grid__header">
                                <h3 className="collapsible-grid__title">{this.gigsCardNoDataMessage}</h3>
                            </header>
                        </>
                    )}
                </section>

                <section className="collapsible-grid__container mt-5">
                    {this.state.actioncardList.length > 0 ? (
                        <>
                            <CollapsibleCardGrid
                                title="My Outstanding Actions"
                                numItems={this.state.actioncardList.length}
                            >
                                {this.state.actioncardList.map((actionCard: actionItemData, index: any) => (
                                    <div className="col col-12 col-md-6 col-lg-4" key={index}>
                                        <GigsCard
                                            cardUIType="outstandingAction"
                                            actionCardItem={actionCard}
                                            totalNumber={this.state.actioncardList.length}
                                            serialNumber={serialNoAction++}
                                            location={this.props.location}
                                            history={this.props.history}
                                        />
                                    </div>
                                ))}
                            </CollapsibleCardGrid>
                        </>
                    ) : this.state.showProgressIndicatorAction ? (
                        <div className="row">
                            <span className="col-lg-12 text-center">
                                <LoadIndicator
                                    id="simple-grid-indicator"
                                    height={100}
                                    width={100}
                                    visible={this.state.showProgressIndicatorAction}
                                />
                            </span>
                        </div>
                    ) : (
                        <>
                            <h3 className="collapsible-grid__title">
                                <header className="collapsible-grid__header">{this.actionCardNoDataMessage}</header>
                            </h3>
                        </>
                    )}
                </section>
                {/*  
                <GigsCardList
                    cardList={this.state.querycardList}
                    showProgressIndicator={this.state.showProgressIndicator[SpinnerTypes.Queries]}
                    noDataFoundMessage="No data found for Queries"
                    location={this.props.location}
                    history={this.props.history}
                />
*/}
                <section className="collapsible-grid__container mt-5">
                    {this.state.querycardList.length > 0 ? (
                        <LazyLoad height={LazyLoadConfig.height} offset={LazyLoadConfig.offset}>
                            <CollapsibleCardGrid title="My Queries" numItems={this.state.querycardList.length}>
                                {this.state.querycardList.map((queryCard: queryCardData, index: any) => (
                                    <div className="col col-12 col-md-6 col-lg-4" key={index}>
                                        <GigsCard
                                            cardUIType="query"
                                            queryCardItem={queryCard}
                                            totalNumber={this.state.querycardList.length}
                                            serialNumber={serialNoQuery++}
                                            location={this.props.location}
                                            history={this.props.history}
                                        />
                                    </div>
                                ))}
                            </CollapsibleCardGrid>
                        </LazyLoad>
                    ) : this.state.showProgressIndicatorQuery ? (
                        <div className="row">
                            <span className="col-lg-12 text-center">
                                <LoadIndicator
                                    id="simple-grid-indicator"
                                    height={100}
                                    width={100}
                                    visible={this.state.showProgressIndicatorQuery}
                                />
                            </span>
                        </div>
                    ) : (
                        <>
                            <header className="collapsible-grid__header">
                                <h3 className="collapsible-grid__title">{this.queryCardNoDataMessage}</h3>
                            </header>
                        </>
                    )}
                </section>

                <div className="mt-5">
                    <span className="font-weight-bold d-block text-left">Looking for more gigs?</span>
                    <Link title="View gig list" to="/gigCardList" className="btn btn-link btn-link--inline">
                        View gig list
                    </Link>
                </div>
            </>
        );
    }
}

export default EntLandPageItemList;
