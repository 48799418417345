import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { faTheaterMasks, faBroom, faFlowerTulip, faWrench, faSoap, faRectanglesMixed, faUserSecret, faSync } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { RequestStatus } from "../../services/FindAnArtistService";
import GridTitleCard from "../Cards/GridTitleCard";
import outstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import GigRequestGrid from "./GigRequestGrid";
import GigRequestSidebarPanel from "./GigRequestSidebarPanel";
import GlobalService from "../../services/GlobalService";

interface GigRequestPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
    getBuild: (flag: boolean) => void;  
    incidentId: string;
}

interface MatrixBACSPageComponentState {
    closeSidebar: boolean;
}

interface GigRequestPageComponentState {
    startDate: string;
    endDate: string;
    statusId: string;
    closeSidebar: boolean;
    isRefreshButtonClicked: boolean;
}
class GigRequestPage extends Component<GigRequestPageComponentProps> {
    state: GigRequestPageComponentState;
    outstandingActionsUtilities: outstandingActionsUtilities;
    constructor(props: GigRequestPageComponentProps) {
        super(props);
        this.outstandingActionsUtilities = new outstandingActionsUtilities();
        this.state = {
            startDate: "",
            endDate: "",
            statusId: "",
            closeSidebar: false,
            isRefreshButtonClicked: false,
        };
    }

    onApplyButtonClick = (dateFrom: string,dateTo: string,isApplyClicked:boolean) => {
        if (isApplyClicked && this.props?.location?.state) {
            this.props.location.state = {};
        }
        this.setState({
            startDate: dateFrom,
            endDate: dateTo
        });
        this.setState((prevState: MatrixBACSPageComponentState) => ({
            closeSidebar: !prevState.closeSidebar
        }));

    }

    renderGridTitleCard = () => {
        let gigType = GlobalService.getGigComingFrom();
        if(this.props.location?.state?.navFrom == "bookingsOverview" || this.props.location?.state?.navigateFrom == "BookingsByWeek"){  
            gigType = this.checkService();
        }
        if(gigType == "ContractCleaning"){
            return (
                <GridTitleCard title="Cleaning - Work Requests" cardStyle={"work-requests"} icon={faBroom} />
            );
        }
        else if(gigType == "Gardening"){
            return (
                <GridTitleCard title="Gardening - Work Requests" cardStyle={"work-requests"} icon={faFlowerTulip} />
            );
        }
        else if(gigType == "WindowCleaning"){
            return (
                <GridTitleCard title="Window Cleaning - Work Requests" cardStyle={"work-requests"} icon={faSoap} />
            );
        }
        else if(gigType == "Maintenance"){
            return (
                <GridTitleCard title="Maintenance - Work Requests" cardStyle={"work-requests"} icon={faWrench} />
            );
        }
        else if(gigType == "Miscelleanous"){
            return (
                <GridTitleCard title="Miscelleanous - Work Requests" cardStyle={"work-requests"} icon={faRectanglesMixed} />
            );
        }
        else if(gigType == "Security"){
            return (
                <GridTitleCard title="Door Supervision - Work Requests" cardStyle={"security-requests"} icon={faUserSecret} />
            );
        }
        else{
            return (
                <GridTitleCard title="Entertainment - Gig Requests" cardStyle={"gig-requests"} icon={faTheaterMasks} />
            );
        }
    }

    onRefreshGrid = () => {
        this.setState({isRefreshButtonClicked: true});
    }

    resetRefreshGrid = () => {
        this.setState({isRefreshButtonClicked: false});
    }

    getBuild = (flag: boolean) => {
        this.props.getBuild(flag);
    };

    checkService = () => {
        if(this.props.location?.state?.serviceType == "10"){
           return "Security"
        }  
        else if(this.props.location?.state?.serviceType == "20"){
            return "ContractCleaning"
        } 
        else if(this.props.location?.state?.serviceType == "30"){
            return "Entertainment"
        }
        else if(this.props.location?.state?.serviceType == "40"){
            return "Gardening"
        } 
        else if(this.props.location?.state?.serviceType == "50"){
            return "WindowCleaning"
        } 
        else if(this.props.location?.state?.serviceType == "60"){
            return "Maintenance"
        } 
        else{
            return "Miscelleanous"
        }  
    }

    render() {
        var dateDisplayText: string = "";
        let gigType = GlobalService.getGigComingFrom();
        let gridType = (gigType && gigType !== "Entertainment") ? gigType : "Entertainment";
        if(!gigType){
            GlobalService.setGigComingFrom(gridType);
        }
        if(this.props.location?.state?.navFrom == "bookingsOverview" || this.props.location?.state?.navigateFrom == "BookingsByWeek"){  
            gridType = this.checkService();
        }
        sessionStorage.removeItem("formdata");
         sessionStorage.removeItem("isShowReoccuranceDate");
        if (this.state.endDate && this.state.startDate) {
            dateDisplayText = this.outstandingActionsUtilities.convertddmmyyyyStringToCustomizedFormat(this.state.startDate) + " " + "-" + " " + this.outstandingActionsUtilities.convertddmmyyyyStringToCustomizedFormat(this.state.endDate);
        }
        else if (this.state.startDate) {
            dateDisplayText = this.outstandingActionsUtilities.convertddmmyyyyStringToCustomizedFormat(this.state.startDate);
        }
        else if (this.state.endDate) {
            dateDisplayText = this.outstandingActionsUtilities.convertddmmyyyyStringToCustomizedFormat(this.state.endDate);;
        }
        return (
            <LightModePageTemplate>
                <SideBarTemplate closeSidebar={this.state.closeSidebar}>
                    <GigRequestSidebarPanel
                        onApplyButtonClick={this.onApplyButtonClick}
                    />
                </SideBarTemplate>
                <div id="independentBooking" className="page-content--with-sidebar">
                    <div className="col-12">
                        {this.renderGridTitleCard()}
                        <h5 className="font-weight-bold text-center text-lg-left">
                            {dateDisplayText}
                        </h5>
                        <div className="grid-info__button-container text-center text-lg-left" >

                            <Link
                                className={"btn btn-primary btn--large btn--with-icon col-12 col-sm-6 col-xl-3 mb-3"}
                                to={{
                                    pathname: "/findAnArtistLandingPage",
                                    state: {
                                        id: "0",
                                        reqStatus: "",
                                        isPendingRequest: true,
                                        navigation: "gigRequestAdd"
                                    },
                                }}
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                                Add Request
                            </Link>
                            &nbsp;&nbsp;
                            <button
                                className="status-flow__refresh-shiftbutton btn gig-icon-btn"
                                aria-label="Refresh the current billing status"
                                onClick={this.onRefreshGrid}
                            >
                                <FontAwesomeIcon icon={faSync} />
                            </button> 
                        </div>

                        <div className="row">
                            <div className="col-md">
                                <GigRequestGrid statusId={this.state.statusId}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate} 
                                    location={this.props.location}
                                    gridType={gridType}
                                    getBuild={this.getBuild}
                                    isRefreshButtonClicked={this.state.isRefreshButtonClicked}
                                    resetRefreshGrid={this.resetRefreshGrid}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(GigRequestPage);
