import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import { LoadPanel, TextBox, CheckBox } from "devextreme-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import ProviderServiceFormUtil, { AddEditVenueProviderFormData } from "./ProviderServiceFormUtil";
import VenueProviderService from "../../services/VenueProviderService";
import BillingFormUtils from "../Billing/BillingFormUtils";
import {
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
    LookUpOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";

//#region props
interface ProviderServiceFormProps extends WithTranslation {
    location: any;
    history: any;
    onApplySuccess: (applySignal: string) => void;
    isEditForm: any;
}

// State
interface ProviderServiceFormState {
    //TODO : Define the form state here.

    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    venueLookUp: LookupTypeItem[];
    providerLookUp: LookupTypeItem[];
    frequencyLookUp: LookupTypeItem[];
    editVenueProviderFormData: AddEditVenueProviderFormData;
    //PeriodMatrix: any;
    loadPanelVisible: boolean;
    disableDayTextbox: boolean;
    filterId: string;
    errorMessage: [];
    showError: boolean;
    //weekLookUp: LookupTypeItem[];
}

class ProviderServiceForm extends React.Component<ProviderServiceFormProps> {
    lookupService: LookupService;
    state: ProviderServiceFormState;
    utils: ProviderServiceFormUtil;
    VenueProviderService: VenueProviderService;
    periodWeekUtil: BillingFormUtils;
    constructor(props: ProviderServiceFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.utils = new ProviderServiceFormUtil(props);
        this.VenueProviderService = new VenueProviderService();
        this.periodWeekUtil = new BillingFormUtils();
        var convertedEditData = this.utils.initializeVenueProviderFormItem();
        this.state = {
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            venueLookUp: [],
            providerLookUp: [],
            frequencyLookUp: [],
            editVenueProviderFormData: convertedEditData,
            loadPanelVisible: false,
            disableDayTextbox: false,
            filterId: "20",
            errorMessage: [],
            showError: false,
        };
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        this.getEditViewItemDataSource();
    }
    getEditViewItemDataSource = () => {
        if (this.props.location.state && this.props.location.state.id) {
            this.VenueProviderService.getVenueProviderData(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleError);
        }
        else 
        {
            this.setState({
                editVenueProviderFormData: {
                    ...this.state.editVenueProviderFormData,
                    isActive: true,
                },
            })
        }
    };
    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        var viewDataSource = this.utils.convertVenueProviderItemResponeToFormData(response.data.data);
        this.setState({
            editVenueProviderFormData: viewDataSource,
        });
    };

    handleError = (err: any) => {
        this.setState({
            errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
            showError: true,
            loadPanelVisible: false,
        });
    };

    handleChangeProvider = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            editVenueProviderFormData: {
                ...this.state.editVenueProviderFormData,
                providerBusinessEntityId: dxValueChange.value,
            },
        });
    };

    handleChangeClient = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editVenueProviderFormData: {
                ...this.state.editVenueProviderFormData,
                clientBusinessEntityId: dxValueChange.value,
                venueId: "",
            },
        });
    };
    handleChangeVenue = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            editVenueProviderFormData: {
                ...this.state.editVenueProviderFormData,
                venueId: dxValueChange.value,
            },
        });
    };
    handleChangeHouseNo = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editVenueProviderFormData: {
                ...this.state.editVenueProviderFormData,
                houseNo: dxValueChange.value,
            },
        });
    };
    handleChangeService = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editVenueProviderFormData: {
                ...this.state.editVenueProviderFormData,
                serviceTypeId: dxValueChange.value,
            },
        });
    };
    handleChangeServiceType = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editVenueProviderFormData: {
                ...this.state.editVenueProviderFormData,
                serviceSubTypeId: dxValueChange.value,
            },
        });
    };
    onApplyClick = () => {
        this.setState({
            loadPanelVisible: true,
        });
        var venueProviderSubmitRequest = this.utils.convertFormDataToVenueProviderRequest(
            this.state.editVenueProviderFormData
        );

        this.VenueProviderService.saveVenueHierarchyDataItem(venueProviderSubmitRequest)
            .then(this.handleSuccessDataSubmission)
            .catch(this.handleError);
    };

    handleSuccessDataSubmission = () => {
        this.setState({
            loadPanelVisible: false,
        });

        if (this.props.location.state && this.props.location.state.id) {
            this.props.onApplySuccess("EDIT");
        } else if (this.state.editVenueProviderFormData.id == "0") {
            this.props.onApplySuccess("ADD");
        }
    };

    isBlank = (filterId: string) => {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    };
    //#region render Component
    render() {
        return (
            <form data-testid="addEditShift-form" className="edit-form my-5 form_border">
                <div className="container mb-6">
                    <div className="row pt-3">
                        <h2 className="billingForm-heading aligned-labels">Venue Provider Service Form</h2>
                    </div>
                    <br></br>
                    <div className="row justify-content-left pt-3 aligned-labels" hidden={!this.state.showError}>
                        <span className="text-danger font-weight-bold text-left">
                            <ul>
                                {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                    return (
                                        <li key={uniqueKey}>
                                            {item.columnName}: {item.errorMessage}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels">Provider</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <LookupTypeListDynamic
                                lookupTypeIndex={LookupTypeIndexes.providerType}
                                onValueChanged={this.handleChangeProvider}
                                isRoot={false}
                                parentMappingId={""}
                                displayExpression={"value"}
                                value={this.state.editVenueProviderFormData.providerBusinessEntityId}
                                recordType={this.props.location.state && this.props.location.state.id? "all":"active"}
                            />                           
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels">Client</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.clientType}
                                onValueChanged={this.handleChangeClient}
                                value={this.state.editVenueProviderFormData.clientBusinessEntityId}
                                recordType={this.props.location.state && this.props.location.state.id? "all":"active"}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels">Venue </div>
                        <div className="mt-3 col-10 col-lg-3">
                            <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.venueType}
                                        onValueChanged={this.handleChangeVenue}
                                        isRoot={false}
                                        parentMappingId={this.state.editVenueProviderFormData.clientBusinessEntityId}
                                        value={this.state.editVenueProviderFormData.venueId}
                                        displayExpression={"value"}
                                        recordType={this.props.location.state && this.props.location.state.id? "all":"active"}
                             />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels">Service</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.serviceType}
                                onValueChanged={this.handleChangeService}
                                value={this.state.editVenueProviderFormData.serviceTypeId}
                            />
                        </div>
                        {/* <div className="mt-3 col-2 col-lg-3 right-aligned-text">
                            <CheckBox
                                value={this.state.editVenueProviderFormData.isActive}
                                width={130}
                                text="Active?"
                                readOnly = {true}
                            />
                        </div> */}
                    </div>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels" hidden={!this.props.isEditForm}>
                            ID
                        </div>
                        <div className="mt-3 col-10 col-lg-3" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editVenueProviderFormData.id} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels" hidden={!this.props.isEditForm}>
                            Last Updated On
                        </div>
                        <div className="mt-3 col-10 col-lg-3" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editVenueProviderFormData.lastUpdatedOn} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels" hidden={!this.props.isEditForm}>
                            Last Updated By
                        </div>
                        <div className="mt-3 col-10 col-lg-3" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editVenueProviderFormData.lastUpdatedBy} readOnly={true} />
                        </div>
                        <>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels" hidden={!this.props.isEditForm}>
                            Town/City
                        </div>
                        <div className="mt-3 col-10 col-lg-3 disabled-grey" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editVenueProviderFormData?.city} disabled={true} />
                        </div>

                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels" hidden={!this.props.isEditForm}>
                            County
                        </div>
                        <div className="mt-3 col-10 col-lg-3 disabled-grey" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editVenueProviderFormData?.county} disabled={true} />
                        </div>

                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size aligned-labels" hidden={!this.props.isEditForm}>
                            Post Code
                        </div>
                        <div className="mt-3 col-10 col-lg-3 disabled-grey" hidden={!this.props.isEditForm}>
                            <TextBox value={this.state.editVenueProviderFormData?.postCode} disabled={true} />
                        </div>
                        </>
                        
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size"></div>
                        <div className="mt-3 col-2 col-lg-7 font-weight-bold font_size"></div>
                        <div className="col-12 col-lg-2 mt-3 text-end">
                            <Link
                                to={{
                                    pathname: "/matrixView",
                                    state: {
                                        isCancel: true,
                                    },
                                }}
                                className="btn btn--ghost btn--large"
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                                {this.props.t("addEditShiftRow.financeSection.cancelButton.text")}
                            </Link>
                        </div>
                        <div className="col-12 col-lg-2 mt-3 text-end">
                            <button
                                className="btn btn-primary btn--large"
                                type="button"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onApplyClick();
                                }}
                            >
                                <span className="btn__icon"></span>
                                {this.props.t("addEditShiftRow.financeSection.applyButton.text")}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default withTranslation()(ProviderServiceForm);
