import React from "react";

interface TodayCardPopOverWrapperProps {
    title: string;
    date: string;
    officeContactPhoneNumber?: string;
    operationalContactName?: string;
    operationalContactPhoneNumber?: string;
    summaryText?: React.ReactNode;
    mainContent?: React.ReactNode;
    footerString: string;
}

const TodayCardPopOverWrapper: React.FC<TodayCardPopOverWrapperProps> = (props: TodayCardPopOverWrapperProps) => {
    let displayOfficeContact: boolean = props.officeContactPhoneNumber !== undefined;
    let displayOperationalContact: boolean =
        props.operationalContactName !== undefined && props.operationalContactPhoneNumber !== undefined;

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <label className="d-block font-weight-bold">{props.title}</label>
                    {displayOfficeContact ? (
                        <label className="d-block">
                            Office Contact:{" "}
                            {props.officeContactPhoneNumber ? props.officeContactPhoneNumber : "To Be Advised"}
                        </label>
                    ) : (
                        ""
                    )}
                </div>
                <div className="col-6">
                    <label className="d-block text-right font-weight-bold">{props.date}</label>
                    {displayOperationalContact ? (
                        <label className="d-block text-right">
                            Operational Contact:
                            <br />
                            {props.operationalContactName ? props.operationalContactName : "To Be Advised"}
                            <br />
                            {props.operationalContactPhoneNumber}
                        </label>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div>{props.summaryText}</div>
            {props.mainContent ? (
                <table className="table my-3 w-100 home-page__data-table">{props.mainContent}</table>
            ) : (
                ""
            )}
            {
                <p className="mb-0 text-center">
                    Out of hours protocol : {props.footerString ? props.footerString : "To Be Advised"}
                </p>
            }
        </div>
    );
};

export default TodayCardPopOverWrapper;
