import React, { MouseEvent, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import ChildFilterItem from "./ChildFilterItem";
import ArtistUtilities, { filterObject } from "./FindAnArtistUtils";
import GlobalService from "../../services/GlobalService";

interface FilterItemProps {
    item: any;
    sendTypeListToParent: (typeList: filterObject, selected: boolean, parentNodeClicked: boolean, deSelectType: boolean,isEnter:boolean) => void;
}

interface FilterItemState {
    selected: boolean;
    listItem: any;
    parentNodeClicked: boolean;
    IdList: string[];
    count: number;
    filterObject: filterObject;
    typeList: string[];
}

class FilterItem extends React.Component<FilterItemProps> {
    state: FilterItemState;
    utils: ArtistUtilities;

    constructor(props: FilterItemProps) {
        super(props);
        this.utils = new ArtistUtilities();
        this.state = {
            selected: false,
            listItem: this.props.item,
            parentNodeClicked: false,
            IdList: [],
            count: this.props.item.typeOptions.length,
            filterObject: this.utils.initializeFilterObject(),
            typeList: [],
        };
        this.toggleSelected = this.toggleSelected.bind(this);
    }

    componentDidMount() {
        var typeListObject: filterObject = this.utils.initializeFilterObject();
        typeListObject = this.utils.extractTypes(this.props.item);
        this.setState({
            filterObject: typeListObject,
        });
    }

    toggleParentState = (listItem: any, state: boolean,isEnter:boolean) => {
        var typeObject: filterObject = this.state.filterObject;
        let deSelectType: boolean = false;
        var typeList: string[] = this.state.typeList;
        var IdList: string[] = this.state.IdList;
        if(!isEnter){
        if (state) {
            IdList.push(listItem.id);
            typeList.push(listItem.value);
            typeObject.types = IdList;
        } else {
            const index = IdList.indexOf(listItem.id);
            const position = typeList.indexOf(listItem.value);
            if (index > -1) {
                IdList.splice(index, 1);
            }
            if (position > -1) {
                typeList.splice(position, 1);
            }
            GlobalService.removeArtistTypeSubType(typeObject.serviceSubtype, listItem.id);
            typeObject.types = IdList;
            deSelectType = true;
        }
    }

        this.setState({
            IdList: IdList,
            selected: IdList.length > 0 ? true : false,
            parentNodeClicked: false,
            typeList: typeList,
        }, () => { this.props.sendTypeListToParent(typeObject, this.state.selected, this.state.parentNodeClicked, deSelectType,isEnter) });
    };

    toggleSelected(e:any){
        e.preventDefault();
        let iskeyDown = e.type && e.type === 'keydown' ? true:false;
        let isEnter = this.checkIsEnter(e);
        if(iskeyDown && !isEnter){
            return;
        }
        this.setState(
            (prevState: FilterItemState) => ({
                // selected: !prevState.selected,
                // selected: iskeyDown || isEnter? prevState.selected : !prevState.selected,
                selected: isEnter? prevState.selected : !prevState.selected,
                parentNodeClicked: true,
            }),
            () => {
                this.createIdListOnParentClcik(isEnter)
            }
        );
    }

    checkIsEnter = (e: any) => {
        let isEnter = false;
        if (e.type && e.type === 'keydown') {
            e.preventDefault();
            if (e.key && e.key === 'Enter')
                isEnter = true;
        }
        return isEnter;
    }

    createIdListOnParentClcik = (isEnter:boolean) => {
        var typeObject: filterObject = this.state.filterObject;
        var ListItem: string[] = [];
        var typeListItem: string[] = [];
        if (this.state.parentNodeClicked) {
            if (this.state.selected) {
                ListItem = this.utils.buildIdList(this.state.listItem);
                typeListItem = this.utils.buildTypeList(this.state.listItem);
                typeObject.types = ListItem;
            } else {
                ListItem = [];
                typeListItem = [];
                typeObject.types = ListItem;
            }
        }
        this.setState({
            IdList: ListItem,
            typeList: typeListItem,
        });
        this.props.sendTypeListToParent(typeObject, this.state.selected, this.state.parentNodeClicked, false,isEnter);
    };

    render() {
        return (
            <li>
                <button type='button'
                    className={`filter-item ${this.state.selected ? "filter-item--selected" : ""}`}
                    onClick={this.toggleSelected}
                    onKeyDown={this.toggleSelected}
                >
                    <span className="filter-item__icon">
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    {this.state.listItem.value}
                </button>
                {this.state.listItem.typeOptions && this.state.listItem.typeOptions.length > 1 ? (
                    <ul className="filter-box__filter-list filter-item__children">
                        {this.state.listItem.typeOptions.map((filterItem: any, index: number) => {
                            return (
                                <li key={filterItem.id}>
                                    <ChildFilterItem
                                        item={filterItem}
                                        selectFilterType={this.toggleParentState}
                                        parentNodeselected={this.state.parentNodeClicked}
                                        selected={this.state.selected}
                                    />
                                </li>
                            );
                        })}{" "}
                    </ul>
                ) : null}
            </li>
        );
    }
}

export default FilterItem;
