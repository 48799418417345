import React from 'react'
import {Prompt} from 'react-router-dom'
import { Popup } from "devextreme-react";

interface RestrictRouterProps {
    location: any;
    history: any;  
    when:boolean;
    isNavigate: (updateFlag: boolean) => void;
}

interface RestrictRouterState {
    modalVisible: boolean,
    lastLocation: string,
    confirmedNavigation: boolean,
}

export class RestrictRouter extends React.Component<RestrictRouterProps> {
    state: RestrictRouterState;
    constructor(props: RestrictRouterProps) {
        super(props);
        this.state = {
            modalVisible: false,
            lastLocation: "",
            confirmedNavigation: false,
        }
    }

 showModal = (location:any) => this.setState({
   modalVisible: true,
   lastLocation: location,
 })

 closeModal = () => {
 this.setState({
   modalVisible: false
 })
 this.props.isNavigate(true);
}

handleBlockedNavigation = (nextLocation:any) => {
   const {confirmedNavigation} = this.state
   if (!confirmedNavigation){
       this.showModal(nextLocation)
       return false
   }
   return true
 }

 handleConfirmNavigationClick = () => {
  sessionStorage.removeItem("formdata");
  sessionStorage.removeItem("isShowReoccuranceDate");
  sessionStorage.removeItem("showEqipReqd");
  sessionStorage.removeItem("showPerfInfo");
  sessionStorage.removeItem("finishTimeIsMandatory");
  sessionStorage.removeItem("isPublished");
   if (this.state.lastLocation) {
      this.setState({
         confirmedNavigation: true
      }, () => {
         // Navigate to the previous blocked location with your navigate function
        this.props.history.push(this.state.lastLocation)
      })
   }
 }

 render() {
   const {modalVisible} = this.state
   return (
        <>
        <Prompt
           when={this.props.when}
           message={this.handleBlockedNavigation}/>
           <Popup
                wrapperAttr={{
                    class: "pending-request-panel__raise-query-pop-up",
                }}
                visible={modalVisible}
                onHiding={this.closeModal}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showTitle={true}
                showCloseButton={false}
                title="Close"
                maxWidth="300px"
                maxHeight="200px"
            >
                <div className="pending-request-panel__raise-query">
                    <div className="pending-request-panel__query-box">
                            <p className='text-center'>Close screen without saving?</p>
                            <div className='text-center'>
                              <button className="btn btn-popup mr-3" onClick={this.handleConfirmNavigationClick}> YES </button>
                              <button className="btn btn-popup" onClick={this.closeModal}> NO </button>   
                            </div>                         
                    </div>
                </div>
            </Popup>
     </>
      
   )
 }
}
export default RestrictRouter