import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import ManageDocumentsGridService from "../../../services/ManageDocumentsGridService";
import { AxiosResponse } from "axios";
import { Button, LoadPanel } from "devextreme-react";
import gridUtils from "../../grid/GridUtilities";

interface PayDateAdviceCellProps {
    data: any;
}

interface PayDateAdviceCellState {
    filetype: string;
    loadPanelVisible: boolean;
}

class PayDateAdviceCell extends React.Component<PayDateAdviceCellProps> {
    state: PayDateAdviceCellState;
    service: ManageDocumentsGridService;
    gridUtils: gridUtils;
    constructor(props: PayDateAdviceCellProps) {
        super(props);
        this.state = {
            filetype: this.props.data.data.formatType,
            loadPanelVisible: false,
        };
        this.service = new ManageDocumentsGridService();
        this.gridUtils = new gridUtils();
    }

    onDownloadClick = (event: any) => {
        var id = "";
        if (this.props.data && this.props.data.data && this.props.data.data.documents) {
            this.props.data.data.documents.map((item: any) => {
                if (item.documentType.toLowerCase() == "remittance") {
                    id = item.id;
                }
            });
        }
        this.setState({
            loadPanelVisible: true,
        });

        this.service.getDocumentById(id).then(this.handleDownloadSuccess).catch(this.handleDownloadError);
        event.event.stopPropagation();
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        var linkRaw = "";
        if (this.props.data && this.props.data.data && this.props.data.data.documents) {
            this.props.data.data.documents.map((item: any) => {
                if (item.documentType.toLowerCase() == "remittance") {
                    linkRaw = item.link;
                }
            });
        }
        let link = linkRaw.split("/");
        let filename = link[link.length - 1];
        var FileSaver = require("file-saver");
        FileSaver.saveAs(response.data, filename);
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleDownloadError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "getDocumentById failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div>{this.props.data.data.advisedDate}</div>
                {this.props.data && this.props.data.data && this.props.data.data.documents ? (
                    this.props.data.data.documents.map((item: any) => {
                        if (item.documentType.toLowerCase() == "remittance") {
                            return (
                                <Button className="icon-btn btn-file-downloader" onClick={this.onDownloadClick}>
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>
                            );
                        }
                    })
                ) : (
                    <></>
                )}
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
            </div>
        );
    }
}

export default PayDateAdviceCell;
