import React from "react";
import { AxiosResponse } from "axios";
import ScheduleService, { ChartData, FinancialItem } from "../../services/ScheduleService";
import LookupService, { LookupTypeItem } from "../../services/LookupService";
import { initialChartData, buildDataPoints } from "./VenueFinancesUtils";
import VenueFinancesCard from "./VenueFinancesCard";
import UserService, { RoleGroupNames, OperatingServiceNames } from "../../services/UserService";
import { ServiceType } from "../../common/ServiceUtilities";
// Interfaces and Constants
export interface FinancialChartColorPalette {
    [key: string]: string[];
}
const FinancialChartColorPalettesByServiceType: FinancialChartColorPalette = {
    [ServiceType.Security]: ["#39404b", "#e020ad"],
    [ServiceType.ContractCleaning]: ["#39404b", "#32c5ff"],
    [ServiceType.Entertainment]: ["#39404b", "#6236ff"],
    [ServiceType.Miscelleanous]: ["#39404b", "#c9e551"],
};
// Props
interface VenueFinancesProps {
    filterByClientId: string;
    filterByVenueId: string;
    clientOrVenueFound: boolean;
}
// State
interface VenueFinancesState {
    serviceFilterDataSource: LookupTypeItem[];
    currentFilterSelected: LookupTypeItem;
    currentChartColorPalette: string[];
    chartData: ChartData;
    chartDataPending: boolean;
    serviceTypeArray: LookupTypeItem[];
}
/**
 * Component - Spline chart to display budget and spend
 */
class VenueFinances extends React.Component<VenueFinancesProps> {
    dataService: ScheduleService;
    lookupService: LookupService;
    state: VenueFinancesState;
    constructor(props: VenueFinancesProps) {
        super(props);
        this.dataService = new ScheduleService();
        this.lookupService = new LookupService();
        this.state = {
            serviceFilterDataSource: [],
            currentFilterSelected: {
                id: ServiceType.Security,
                value: "",
                parentMappingId: "",
            },
            currentChartColorPalette: FinancialChartColorPalettesByServiceType[ServiceType.Security],
            chartData: initialChartData(),
            chartDataPending: true,
            serviceTypeArray: [],
        };
        // Methods
        this.getFinancialData = this.getFinancialData.bind(this);
        this.handleServiceSuccess = this.handleServiceSuccess.bind(this);
    }

    //On Mount of the Venue Finances chart we will capture the services that the logged in user is supposed to see the charts for.
    componentDidMount() {
        var serviceTypeArray: LookupTypeItem[] = [];
        serviceTypeArray = UserService.operatingServiceList();
        this.setState({
            serviceTypeArray: serviceTypeArray ? serviceTypeArray : [],
        });
    }

    /**
     * Retrieves financial data from API
     *
     * @param clientId {string}
     * @param venueId {string}
     * @param serviceType {string}
     */
    getFinancialData(clientId: string, venueId: string, serviceType: string): void {
        this.dataService
            .getFinancialChartData(clientId, venueId, serviceType)
            .then((response) => this.handleServiceSuccess(response, serviceType))
            .catch((err) => {
                var respMessage: string = "getFinancialChartData failed with response: " + JSON.stringify(err);

                if (!this.dataService.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
            });
    }
    /**
     * On component update, retrieve new financial chart data points if any props have been updated
     *
     * @param prevProps {SplineProps} - Previous prop values
     */
    componentDidUpdate(prevProps: VenueFinancesProps) {
        var { serviceTypeArray } = this.state;
        if (
            (this.props.filterByClientId != prevProps.filterByClientId ||
                this.props.filterByVenueId != prevProps.filterByVenueId) &&
            (UserService.isUserInGroup(RoleGroupNames.VenueManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager))
        ) {
            serviceTypeArray.forEach((serviceTypeItem: LookupTypeItem) =>
                this.getFinancialData(this.props.filterByClientId, this.props.filterByVenueId, serviceTypeItem.id)
            );
        }
    }

    periodDataSourceBasedOnService = (serviceType: string, periodDataSource: FinancialItem[]) => {
        var periodItem: FinancialItem;
        var periodItemArray: FinancialItem[];
        periodItemArray = [];
        if (periodDataSource.length > 0) {
            periodDataSource.forEach((item: FinancialItem, uniqueKey: number) => {
                periodItem = {
                    serviceSpecificValue: {
                        serviceTypeId: serviceType,
                        serviceColor:
                            serviceType == ServiceType.ContractCleaning
                                ? "#32c5ff"
                                : serviceType == ServiceType.Entertainment
                                ? "#6236ff"
                                : serviceType == ServiceType.Security
                                ? "#e020ad"
                                : serviceType == ServiceType.Gardening
                                ? "#01a65a"
                                : serviceType == ServiceType.Maintenance
                                ? "#fe9882"
                                : serviceType == ServiceType.Miscelleanous
                                ? "#fe7e00"
                                : serviceType == ServiceType.WindowCleaning
                                ? "#0140ff"
                                : "",
                        clientId: this.props.filterByClientId,
                        venueId: this.props.filterByVenueId,
                    },
                    period: item.period,
                    budget: item.budget,
                    spend: item.spend,
                    current: item.current,
                    weeks: item.weeks,
                };
                periodItemArray.push(periodItem);
            });
        }
        return periodItemArray;
    };

    periodDataSourceSetStateSync = () => {};
    /**
     * Set state with all data required to manage the finanical chart
     *
     * @param response {AxiosResponse<any>} - API response
     */
    handleServiceSuccess(response: AxiosResponse<any>, serviceTypeValue: string): void {
        this.setState({
            chartDataPending: false,
        });
        // Update data if there is any; otherwise reset the data to initial values
        if (response && response.data && response.data.data) {
            // Create modified list of data points
            let stateValues = buildDataPoints(
                response.data.data,
                this.props.filterByClientId,
                this.props.filterByVenueId,
                serviceTypeValue
            );
            this.setState({
                chartData: {
                    ...this.state.chartData,
                    itemToDateSecurity:
                        serviceTypeValue == ServiceType.Security
                            ? stateValues.itemToDateSecurity
                            : this.state.chartData.itemToDateSecurity,
                    itemToDateCleaning:
                        serviceTypeValue == ServiceType.ContractCleaning
                            ? stateValues.itemToDateCleaning
                            : this.state.chartData.itemToDateCleaning,
                    itemToDateEnt:
                        serviceTypeValue == ServiceType.Entertainment
                            ? stateValues.itemToDateEnt
                            : this.state.chartData.itemToDateEnt,
                    itemToDateGard:
                        serviceTypeValue == ServiceType.Gardening
                            ? stateValues.itemToDateGard
                            : this.state.chartData.itemToDateGard,
                    itemToDateMain:
                        serviceTypeValue == ServiceType.Maintenance
                            ? stateValues.itemToDateMain
                            : this.state.chartData.itemToDateMain,
                    itemToDateWind:
                        serviceTypeValue == ServiceType.WindowCleaning
                            ? stateValues.itemToDateWind
                            : this.state.chartData.itemToDateWind,
                    itemToDateMisc:
                        serviceTypeValue == ServiceType.Miscelleanous
                            ? stateValues.itemToDateMisc
                            : this.state.chartData.itemToDateMisc,
                    currentWeekSecurity:
                        serviceTypeValue == ServiceType.Security
                            ? stateValues.currentWeekSecurity
                            : this.state.chartData.currentWeekSecurity,
                    currentWeekCleaning:
                        serviceTypeValue == ServiceType.ContractCleaning
                            ? stateValues.currentWeekCleaning
                            : this.state.chartData.currentWeekCleaning,
                    currentWeekEnt:
                        serviceTypeValue == ServiceType.Entertainment
                            ? stateValues.currentWeekEnt
                            : this.state.chartData.currentWeekEnt,
                    currentWeekGard:
                        serviceTypeValue == ServiceType.Gardening
                            ? stateValues.currentWeekGard
                            : this.state.chartData.currentWeekGard,
                    currentWeekMain:
                        serviceTypeValue == ServiceType.Maintenance
                            ? stateValues.currentWeekMain
                            : this.state.chartData.currentWeekMain,
                    currentWeekWind:
                        serviceTypeValue == ServiceType.WindowCleaning
                            ? stateValues.currentWeekWind
                            : this.state.chartData.currentWeekWind,
                    currentWeekMisc:
                        serviceTypeValue == ServiceType.Miscelleanous
                            ? stateValues.currentWeekMisc
                            : this.state.chartData.currentWeekMisc,
                    currentPeriodSecurity:
                        serviceTypeValue == ServiceType.Security
                            ? stateValues.currentPeriodSecurity
                            : this.state.chartData.currentPeriodSecurity,
                    currentPeriodCleaning:
                        serviceTypeValue == ServiceType.ContractCleaning
                            ? stateValues.currentPeriodCleaning
                            : this.state.chartData.currentPeriodCleaning,
                    currentPeriodEnt:
                        serviceTypeValue == ServiceType.Entertainment
                            ? stateValues.currentPeriodEnt
                            : this.state.chartData.currentPeriodEnt,
                    currentPeriodGard:
                        serviceTypeValue == ServiceType.Gardening
                            ? stateValues.currentPeriodGard
                            : this.state.chartData.currentPeriodGard,
                    currentPeriodMain:
                        serviceTypeValue == ServiceType.Maintenance
                            ? stateValues.currentPeriodMain
                            : this.state.chartData.currentPeriodMain,
                    currentPeriodWind:
                        serviceTypeValue == ServiceType.WindowCleaning
                            ? stateValues.currentPeriodWind
                            : this.state.chartData.currentPeriodWind,
                    currentPeriodMisc:
                        serviceTypeValue == ServiceType.Miscelleanous
                            ? stateValues.currentPeriodMisc
                            : this.state.chartData.currentPeriodMisc,
                    periodDataSourceForSecurity:
                        serviceTypeValue == ServiceType.Security
                            ? this.periodDataSourceBasedOnService(
                                  serviceTypeValue,
                                  stateValues.periodDataSourceForSecurity
                              )
                            : this.state.chartData.periodDataSourceForSecurity,
                    periodDataSourceForCleaning:
                        serviceTypeValue == ServiceType.ContractCleaning
                            ? this.periodDataSourceBasedOnService(
                                  serviceTypeValue,
                                  stateValues.periodDataSourceForCleaning
                              )
                            : this.state.chartData.periodDataSourceForCleaning,
                    periodDataSourceForEnt:
                        serviceTypeValue == ServiceType.Entertainment
                            ? this.periodDataSourceBasedOnService(serviceTypeValue, stateValues.periodDataSourceForEnt)
                            : this.state.chartData.periodDataSourceForEnt,
                    periodDataSourceForGard:
                        serviceTypeValue == ServiceType.Gardening
                            ? this.periodDataSourceBasedOnService(serviceTypeValue, stateValues.periodDataSourceForGard)
                            : this.state.chartData.periodDataSourceForGard,
                    periodDataSourceForMain:
                        serviceTypeValue == ServiceType.Maintenance
                            ? this.periodDataSourceBasedOnService(serviceTypeValue, stateValues.periodDataSourceForMain)
                            : this.state.chartData.periodDataSourceForMain,
                    periodDataSourceForWind:
                        serviceTypeValue == ServiceType.WindowCleaning
                            ? this.periodDataSourceBasedOnService(serviceTypeValue, stateValues.periodDataSourceForWind)
                            : this.state.chartData.periodDataSourceForWind,
                    periodDataSourceForMisc:
                        serviceTypeValue == ServiceType.Miscelleanous
                            ? this.periodDataSourceBasedOnService(serviceTypeValue, stateValues.periodDataSourceForMisc)
                            : this.state.chartData.periodDataSourceForMisc,
                    selectedPeriodSecurity: ServiceType.Security
                        ? stateValues.selectedPeriodSecurity
                        : this.state.chartData.selectedPeriodSecurity,
                    selectedPeriodEnt: ServiceType.Entertainment
                        ? stateValues.selectedPeriodEnt
                        : this.state.chartData.selectedPeriodEnt,
                    selectedPeriodGard: ServiceType.Gardening
                        ? stateValues.selectedPeriodGard
                        : this.state.chartData.selectedPeriodGard,
                    selectedPeriodMain: ServiceType.Maintenance
                        ? stateValues.selectedPeriodMain
                        : this.state.chartData.selectedPeriodMain,
                    selectedPeriodWind: ServiceType.WindowCleaning
                        ? stateValues.selectedPeriodWind
                        : this.state.chartData.selectedPeriodWind,
                    selectedPeriodMisc: ServiceType.Miscelleanous
                        ? stateValues.selectedPeriodMisc
                        : this.state.chartData.selectedPeriodMisc,
                    selectedPeriodCleaning: ServiceType.ContractCleaning
                        ? stateValues.selectedPeriodCleaning
                        : this.state.chartData.selectedPeriodCleaning,
                    yearToDateSpendSecurity:
                        serviceTypeValue == ServiceType.Security
                            ? stateValues.yearToDateSpendSecurity
                            : this.state.chartData.yearToDateSpendSecurity,
                    fullYearBudgetSecurity:
                        serviceTypeValue == ServiceType.Security
                            ? stateValues.fullYearBudgetSecurity
                            : this.state.chartData.fullYearBudgetSecurity,
                    yearToDateSpendCleaning:
                        serviceTypeValue == ServiceType.ContractCleaning
                            ? stateValues.yearToDateSpendCleaning
                            : this.state.chartData.yearToDateSpendCleaning,
                    fullYearBudgetCleaning:
                        serviceTypeValue == ServiceType.ContractCleaning
                            ? stateValues.fullYearBudgetCleaning
                            : this.state.chartData.fullYearBudgetCleaning,
                    yearToDateSpendEnt:
                        serviceTypeValue == ServiceType.Entertainment
                            ? stateValues.yearToDateSpendEnt
                            : this.state.chartData.yearToDateSpendEnt,
                    fullYearBudgetEnt:
                        serviceTypeValue == ServiceType.Entertainment
                            ? stateValues.fullYearBudgetEnt
                            : this.state.chartData.fullYearBudgetEnt,
                    yearToDateSpendGard:
                        serviceTypeValue == ServiceType.Gardening
                            ? stateValues.yearToDateSpendGard
                            : this.state.chartData.yearToDateSpendGard,
                    fullYearBudgetGard:
                        serviceTypeValue == ServiceType.Gardening
                            ? stateValues.fullYearBudgetGard
                            : this.state.chartData.fullYearBudgetGard,
                    yearToDateSpendMain:
                        serviceTypeValue == ServiceType.Maintenance
                            ? stateValues.yearToDateSpendMain
                            : this.state.chartData.yearToDateSpendMain,
                    fullYearBudgetMain:
                        serviceTypeValue == ServiceType.Maintenance
                            ? stateValues.fullYearBudgetMain
                            : this.state.chartData.fullYearBudgetMain,
                    yearToDateSpendWind:
                        serviceTypeValue == ServiceType.WindowCleaning
                            ? stateValues.yearToDateSpendWind
                            : this.state.chartData.yearToDateSpendWind,
                    fullYearBudgetWind:
                        serviceTypeValue == ServiceType.WindowCleaning
                            ? stateValues.fullYearBudgetWind
                            : this.state.chartData.fullYearBudgetWind,
                    yearToDateSpendMisc:
                        serviceTypeValue == ServiceType.Miscelleanous
                            ? stateValues.yearToDateSpendMisc
                            : this.state.chartData.yearToDateSpendMisc,
                    fullYearBudgetMisc:
                        serviceTypeValue == ServiceType.Miscelleanous
                            ? stateValues.fullYearBudgetMisc
                            : this.state.chartData.fullYearBudgetMisc,
                    BudgetSpendBarChartSecurity:
                        serviceTypeValue == ServiceType.Security
                            ? stateValues.BudgetSpendBarChartSecurity
                            : this.state.chartData.BudgetSpendBarChartSecurity,
                    BudgetSpendBarChartCleaning:
                        serviceTypeValue == ServiceType.ContractCleaning
                            ? stateValues.BudgetSpendBarChartCleaning
                            : this.state.chartData.BudgetSpendBarChartCleaning,
                    BudgetSpendBarChartEnt:
                        serviceTypeValue == ServiceType.Entertainment
                            ? stateValues.BudgetSpendBarChartEnt
                            : this.state.chartData.BudgetSpendBarChartEnt,
                    BudgetSpendBarChartGard:
                        serviceTypeValue == ServiceType.Gardening
                            ? stateValues.BudgetSpendBarChartGard
                            : this.state.chartData.BudgetSpendBarChartGard,
                    BudgetSpendBarChartMain:
                        serviceTypeValue == ServiceType.Maintenance
                            ? stateValues.BudgetSpendBarChartMain
                            : this.state.chartData.BudgetSpendBarChartMain,
                    BudgetSpendBarChartWind:
                        serviceTypeValue == ServiceType.WindowCleaning
                            ? stateValues.BudgetSpendBarChartWind
                            : this.state.chartData.BudgetSpendBarChartWind,
                    BudgetSpendBarChartMisc:
                        serviceTypeValue == ServiceType.Miscelleanous
                            ? stateValues.BudgetSpendBarChartMisc
                            : this.state.chartData.BudgetSpendBarChartMisc,
                    overspend: stateValues.overspend,
                },
            });
        } else {
            this.setState({
                chartData: initialChartData(),
            });
        }
    }

    render() {
        let { serviceTypeArray } = this.state;
        let securityNodePresent: boolean = UserService.isOperatingServicePresent(OperatingServiceNames.Security);
        let cleaningNodePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.ContractCleaing
        );
        let entertainmentNodePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.Entertainment
        );
        let gardeningNodePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.Gardening
        );
        let maintenanceNodePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.Maintenance
        );
        let miscelleanousNodePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.Miscelleanous
        );
        let windowCleaningNodePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.WindowCleaning
        );
        return (
            <div className="card home-page-card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <>
                                {serviceTypeArray && cleaningNodePresent ? (
                                    <VenueFinancesCard
                                        serviceColor="#32c5ff"
                                        serviceName="Contract Cleaning"
                                        chartData={this.state.chartData}
                                        itemToDate={this.state.chartData.itemToDateCleaning}
                                        currentWeek={this.state.chartData.currentWeekCleaning}
                                        currentPeriod={this.state.chartData.currentPeriodCleaning}
                                        yearToDateSpend={this.state.chartData.yearToDateSpendCleaning}
                                        fullYearBudget={this.state.chartData.fullYearBudgetCleaning}
                                        budgetSpendBarChartData={this.state.chartData.BudgetSpendBarChartCleaning}
                                        periodGraphData={
                                            this.state.chartData.periodDataSourceForCleaning.length > 0
                                                ? this.state.chartData.periodDataSourceForCleaning
                                                : ""
                                        }
                                        chartDataPending={this.state.chartDataPending}
                                        clientOrVenueFound={this.props.clientOrVenueFound}
                                    />
                                ) : (
                                    <></>
                                )}
                                {serviceTypeArray && securityNodePresent ? (
                                    <VenueFinancesCard
                                        serviceColor="#e020ad"
                                        serviceName="Door Supervision"
                                        chartData={this.state.chartData}
                                        itemToDate={this.state.chartData.itemToDateSecurity}
                                        currentWeek={this.state.chartData.currentWeekSecurity}
                                        currentPeriod={this.state.chartData.currentPeriodSecurity}
                                        yearToDateSpend={this.state.chartData.yearToDateSpendSecurity}
                                        fullYearBudget={this.state.chartData.fullYearBudgetSecurity}
                                        budgetSpendBarChartData={this.state.chartData.BudgetSpendBarChartSecurity}
                                        periodGraphData={
                                            this.state.chartData.periodDataSourceForSecurity.length > 0
                                                ? this.state.chartData.periodDataSourceForSecurity
                                                : ""
                                        }
                                        chartDataPending={this.state.chartDataPending}
                                        clientOrVenueFound={this.props.clientOrVenueFound}
                                    />
                                ) : (
                                    <></>
                                )}
                                {serviceTypeArray && entertainmentNodePresent ? (
                                    <VenueFinancesCard
                                        serviceColor="#6236ff"
                                        serviceName="Entertainment"
                                        chartData={this.state.chartData}
                                        itemToDate={this.state.chartData.itemToDateEnt}
                                        currentWeek={this.state.chartData.currentWeekEnt}
                                        currentPeriod={this.state.chartData.currentPeriodEnt}
                                        yearToDateSpend={this.state.chartData.yearToDateSpendEnt}
                                        fullYearBudget={this.state.chartData.fullYearBudgetEnt}
                                        budgetSpendBarChartData={this.state.chartData.BudgetSpendBarChartEnt}
                                        periodGraphData={
                                            this.state.chartData.periodDataSourceForEnt.length > 0
                                                ? this.state.chartData.periodDataSourceForEnt
                                                : ""
                                        }
                                        chartDataPending={this.state.chartDataPending}
                                        clientOrVenueFound={this.props.clientOrVenueFound}
                                        className="home-page__service-panel--final"
                                    />
                                ) : (
                                    <></>
                                )}
                                {serviceTypeArray && gardeningNodePresent ? (
                                    <VenueFinancesCard
                                        serviceColor="#01a65a"
                                        serviceName="Gardening"
                                        chartData={this.state.chartData}
                                        itemToDate={this.state.chartData.itemToDateGard}
                                        currentWeek={this.state.chartData.currentWeekGard}
                                        currentPeriod={this.state.chartData.currentPeriodGard}
                                        yearToDateSpend={this.state.chartData.yearToDateSpendGard}
                                        fullYearBudget={this.state.chartData.fullYearBudgetGard}
                                        budgetSpendBarChartData={this.state.chartData.BudgetSpendBarChartGard}
                                        periodGraphData={
                                            this.state.chartData.periodDataSourceForGard.length > 0
                                                ? this.state.chartData.periodDataSourceForGard
                                                : ""
                                        }
                                        chartDataPending={this.state.chartDataPending}
                                        clientOrVenueFound={this.props.clientOrVenueFound}
                                        className="home-page__service-panel--final"
                                    />
                                ) : (
                                    <></>
                                )}
                                {serviceTypeArray && maintenanceNodePresent ? (
                                    <VenueFinancesCard
                                        serviceColor="#fe9882"
                                        serviceName="Maintenance"
                                        chartData={this.state.chartData}
                                        itemToDate={this.state.chartData.itemToDateMain}
                                        currentWeek={this.state.chartData.currentWeekMain}
                                        currentPeriod={this.state.chartData.currentPeriodMain}
                                        yearToDateSpend={this.state.chartData.yearToDateSpendMain}
                                        fullYearBudget={this.state.chartData.fullYearBudgetMain}
                                        budgetSpendBarChartData={this.state.chartData.BudgetSpendBarChartMain}
                                        periodGraphData={
                                            this.state.chartData.periodDataSourceForMain.length > 0
                                                ? this.state.chartData.periodDataSourceForMain
                                                : ""
                                        }
                                        chartDataPending={this.state.chartDataPending}
                                        clientOrVenueFound={this.props.clientOrVenueFound}
                                        className="home-page__service-panel--final"
                                    />
                                ) : (
                                    <></>
                                )}
                                {serviceTypeArray && miscelleanousNodePresent ? (
                                    <VenueFinancesCard
                                        serviceColor="#fe7e00"
                                        serviceName="Miscelleanous"
                                        chartData={this.state.chartData}
                                        itemToDate={this.state.chartData.itemToDateMisc}
                                        currentWeek={this.state.chartData.currentWeekMisc}
                                        currentPeriod={this.state.chartData.currentPeriodMisc}
                                        yearToDateSpend={this.state.chartData.yearToDateSpendMisc}
                                        fullYearBudget={this.state.chartData.fullYearBudgetMisc}
                                        budgetSpendBarChartData={this.state.chartData.BudgetSpendBarChartMisc}
                                        periodGraphData={
                                            this.state.chartData.periodDataSourceForMisc.length > 0
                                                ? this.state.chartData.periodDataSourceForMisc
                                                : ""
                                        }
                                        chartDataPending={this.state.chartDataPending}
                                        clientOrVenueFound={this.props.clientOrVenueFound}
                                        className="home-page__service-panel--final"
                                    />
                                ) : (
                                    <></>
                                )}
                                {serviceTypeArray && windowCleaningNodePresent ? (
                                    <VenueFinancesCard
                                        serviceColor="#0140ff"
                                        serviceName="Windows"
                                        chartData={this.state.chartData}
                                        itemToDate={this.state.chartData.itemToDateWind}
                                        currentWeek={this.state.chartData.currentWeekWind}
                                        currentPeriod={this.state.chartData.currentPeriodWind}
                                        yearToDateSpend={this.state.chartData.yearToDateSpendWind}
                                        fullYearBudget={this.state.chartData.fullYearBudgetWind}
                                        budgetSpendBarChartData={this.state.chartData.BudgetSpendBarChartWind}
                                        periodGraphData={
                                            this.state.chartData.periodDataSourceForWind.length > 0
                                                ? this.state.chartData.periodDataSourceForWind
                                                : ""
                                        }
                                        chartDataPending={this.state.chartDataPending}
                                        clientOrVenueFound={this.props.clientOrVenueFound}
                                        className="home-page__service-panel--final"
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VenueFinances;
