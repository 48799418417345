import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons";

interface PaymentViewActionCellProps {
    data: any;
}

interface PaymentViewActionCellState {
    paymentId: number;
    viewDisabled:boolean;
}

class  PaymentViewActionCell extends React.Component<PaymentViewActionCellProps> {
    state: PaymentViewActionCellState;

    constructor(props: PaymentViewActionCellProps) {
        super(props);
        this.state = {
            paymentId: this.props.data.data.id,
            viewDisabled:true,
        };
    }

    componentDidMount() {}

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    <Link
                        title="View"
                        className={(false ? "disabled " : "") + "icon-btn"}
                        to={{
                            pathname: "/PaymentsV2Page",
                            state: {
                                id: this.state.paymentId.toString(),
                                viewDisabled:this.state.viewDisabled,
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </Link>
                </div>
            </div>
        );
    }
}

export default PaymentViewActionCell;
