import sharedUtils from "../grid/sharedUtilities";
import { companyInformationData, teamData, linkData, socialMediaType } from "../../services/QueryService";

export default class helpScreenUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
    }

    initializeCompanyInformationData = (): companyInformationData => {
        var formData: companyInformationData = {
            shortName: "",
            openingHours: "",
            webSite: "",
            officePhone: "",
            email: "",
            addressStreet1: "",
            addressStreet2: "",
            addressStreet3: "",
            city: "",
            state: "",
            country: "",
            postCode: "",
            linkInformation: [],
        };

        return formData;
    };

    //Helper function that takes in the link information node and returns an array with the Link information Id sorted.
    returnLink = (linkInformation: linkData[]): linkData[] => {
        var navigationLinkArray: linkData[] = [];
        navigationLinkArray = linkInformation.filter((item: linkData) => {
            return (
                item.linkTypeId == socialMediaType.Facebook ||
                item.linkTypeId == socialMediaType.Instagram ||
                item.linkTypeId == socialMediaType.Twitter
            );
        });
        return navigationLinkArray;
    };
}

