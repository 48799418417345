import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError } from "./BaseApiService";

const INCIDENT_API = "incident";
class RaiseIncidentService {
    //#region Rate Matrix Service Call
    postIncidentRequest(formData: object): Promise<AxiosResponse<any>> {
        return _post(INCIDENT_API, formData);
    }

    // Get Incident By Id
    getIncidentDetailsById(incidentId: number): Promise<AxiosResponse<any>> {
        return _get(`${INCIDENT_API}/${incidentId}`);
    }
    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
    //#endregion
}

export default RaiseIncidentService;
