const isNotEmpty = (value: string): boolean => {
    return value !== undefined && value !== null && value !== '';
}

export const buildRequestParameters = (loadOptions: any, key = '', value:any = null) => {
    let params = '?';
    [
        'skip',
        'take',
        'requireTotalCount',
        'requireGroupCount',
        'sort',
        'filter',
        'totalSummary',
        'group',
        'groupSummary',
        'export'
    ].forEach((i) => {
        if (i in loadOptions) {
            if (key && value && key == i) {//implemented for filters only
                let options = loadOptions[i];
                if (options) {
                    //options.push('and', value);
                    options = [options,'and', value]
                } else {
                    options = value;
                }
                let optionJSON = encodeURIComponent(JSON.stringify(options));
                params += `${i}=${optionJSON}&`;
            }
            else if (isNotEmpty(loadOptions[i])) {
                let optionJSON = encodeURIComponent(JSON.stringify(loadOptions[i]));
                params += `${i}=${optionJSON}&`;
            }

        }
    });

    params = params.slice(0, -1);
    return params;
}
