import React, { MouseEvent, Component } from "react";

interface LargeLightCardWrapperProps {
    typeOfCard?: string;
    hasHeader: boolean;
    type?: string;
    typeTitle?: string;
    enumeration?: boolean;
    cardIndex?: number;
    cardsTotal?: number;
    leftSection: React.ReactNode;
    rightSection?: React.ReactNode;
}

class LargeLightCardWrapper extends React.Component<LargeLightCardWrapperProps> {
    classList: string;

    constructor(props: LargeLightCardWrapperProps) {
        super(props);

        this.classList = "euk-card euk-card--full-height euk-card--" + this.props.type;

        this.classList = !this.props.hasHeader ? this.classList + " euk-card--borderless" : this.classList;
    }

    render() {
        return (
            <div className={this.classList}>
                {this.props.hasHeader ? (
                    <div className="euk-card__type-header">
                        <span className="euk-card__type-title">{this.props.typeTitle}</span>
                        {this.props.enumeration ? (
                            <span className="euk-card__type-enumeration float-right">
                                {this.props.cardIndex} of {this.props.cardsTotal}
                            </span>
                        ) : null}
                    </div>
                ) : null}
                <div className="large-light-card-layout">
                    <div className="row">
                        {this.props.typeOfCard == "query" ? (
                            <div className="col-12 col-xl-12">{this.props.leftSection}</div>
                        ) : (
                            <div className="col-12 col-xl-9">{this.props.leftSection}</div>
                        )}
                        {this.props.rightSection ? (
                            <div className="col-12 col-xl-3">{this.props.rightSection}</div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
export default LargeLightCardWrapper;
