export default class CommonUIUtils {
    constructor() {
        this.setDarkMode = this.setDarkMode.bind(this);
    }

    setDarkMode(setDarkMode: boolean) {
        const body = document.getElementsByTagName("body")[0];

        if (setDarkMode) {
            body.classList.add("dark-mode");
        } else {
            body.classList.remove("dark-mode");
        }
    }
}

export const isValidImageFileType = (fileType: string) => {
    return fileType === "image/gif" || fileType === "image/jpeg" || fileType === "image/png";
}

export const isValidDocumentFileType = (fileType: string) => {
    return fileType === "application/pdf" || fileType === "application/msword";
}

export const isDesktop = () => {
    let viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    return viewportWidth >= 1024; //iPad landscape is 1024
}

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};
