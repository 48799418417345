import React from "react";

interface FinancialAdjustmentCardBodySectionProps {
    reason: string;
    billAmountLocal: string;
}

class FinancialAdjustmentCardBodySection extends React.Component<FinancialAdjustmentCardBodySectionProps> {
    constructor(props: FinancialAdjustmentCardBodySectionProps) {
        super(props);
    }

    render() {
        //Note: We won't be working with multiple Billable Items per card for the Equipment, hence a list of items is not required.
        return (
            <ul className="euk-card__equipment-list">
                <li>{this.props.reason}</li>
                <br/>
                <li>{this.props.billAmountLocal}</li>
            </ul>
        ) 
    }
}
export default FinancialAdjustmentCardBodySection;