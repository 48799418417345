import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import SimpleGridService, { PeriodGridTypeParams } from "../../services/SimpleGridService";
import sharedUtils from "../grid/sharedUtilities";
import LightModePageTemplate from "./Templates/LightModeTemplate";
import ManageShiftGlobalFilterService, { gridFilter } from "../../services/ManageShiftGlobalFilterService";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import { ServiceType } from "../../common/ServiceUtilities";
import ManageShiftsDatePanel from "../side-bar/ManageShiftsDatePanel";
import outstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import ShiftMatrixGrid from "../ShiftMatrix/ShiftMatrixGrid";

interface ManageViewShiftPageComponentProps extends RouteComponentProps, WithTranslation {
    title: string;
    category: string;
    billableItemId: string;
    location: any;
    clientId: any;
    venueId: any;
    shiftStart: any;
    shiftEnd: any;
    isSchedulePage: any;
    periodGridTypeParams: PeriodGridTypeParams;
    filterTextFromHeader: string;
    isHeader: boolean;
}

interface ManageViewShiftPageState {
    showApprovalLoadIndicator: boolean;
    refreshSignal: boolean;
    filterText: string;
    dateFrom: string;
    dateTo: string;
    SummaryObject: {
        billableHours: string;
        billableCost: string;
        equipmentCost: string;
        total: string;
        service: string;
    };
    signalForArrowClick: string;
    loadIndicatorVisibility: boolean;
    isButtonStateValueChanged: boolean;
    isShowUnbilled: boolean;
    isUnPaid: boolean;
    isUnconfirmedItems: boolean;
    isUnapprovedItems:boolean;
}
class ManageViewShiftPage extends Component<ManageViewShiftPageComponentProps> {
    globalVariable: boolean;
    utils: sharedUtils;
    state: ManageViewShiftPageState;
    Service: SimpleGridService;
    conversionUtils: outstandingActionsUtilities;
    constructor(props: ManageViewShiftPageComponentProps) {
        super(props);
        this.globalVariable = false;
        this.Service = new SimpleGridService();
        this.utils = new sharedUtils();
        this.conversionUtils = new outstandingActionsUtilities();
        this.setIndicatorStates = this.setIndicatorStates.bind(this);
        this.setFilter = this.setFilter.bind(this);
        // Initialize button states to false as a user selection of a grid row is required
        this.state = {
            showApprovalLoadIndicator: false,
            refreshSignal: false,
            filterText:
                this.props && this.props.location && this.props.location.state && this.props.location.state.status
                    ? this.props.location.state.status
                    : "",
            dateFrom:
                this.props && this.props.location && this.props.location.state && this.props.location.state.dateFrom
                    ? this.props.location.state.dateFrom
                    : "",
            dateTo:
                this.props && this.props.location && this.props.location.state && this.props.location.state.dateTo
                    ? this.props.location.state.dateTo
                    : "",
            SummaryObject: {
                billableHours: "0",
                billableCost: "0.00",
                equipmentCost: "0.00",
                total: "0.00",
                service: "",
            },
            signalForArrowClick: "",
            loadIndicatorVisibility: true,
            isButtonStateValueChanged: false,
            isShowUnbilled: false,
            isUnPaid: false,
            isUnconfirmedItems: false,
            isUnapprovedItems:false,
        };
    }

    //This function is used when we click on the outstanding actions from the side-bar in the ManageShifts page.
    //This will set the Date to the current week.
    setFilter(status: string) {
        this.setState({
            filterText: status,
            dateFrom: this.utils.convertDateToString(this.utils.fetchMinValueOfCurrentWeek()),
            dateTo: this.utils.convertDateToString(this.utils.fetchMaxValueOfCurrentWeek()),
        });
    }


    //A helper function that would refrsh the EventUk User Grid.
    refreshMatrixGrid = (dateFrom: string, dateTo: string, isShowUnbilledItems: boolean, isUnPaidItems: boolean,isUnconfirmedItems: boolean,isUnapprovedItems :boolean,onUpdate?: boolean) => {
        this.setState((prevState: ManageViewShiftPageState) => ({
            refreshSignal: onUpdate ? !prevState.refreshSignal : prevState.refreshSignal,
            filterText: "",
            dateFrom: dateFrom,
            dateTo: dateTo,
            isShowUnbilled: isShowUnbilledItems,
            isUnPaid: isUnPaidItems,
            isUnconfirmedItems: isUnconfirmedItems,
            isUnapprovedItems:isUnapprovedItems
        }));
    };

    setIndicatorStates(indicatorState: boolean) {
        this.setState({
            loadIndicatorVisibility: indicatorState,
        });
    }

    setTotalGigsCounter = (totalCount: number | undefined) => { };

    render() {
        const { filterText, dateFrom, dateTo } = this.state;
        const globalFilterDetails: gridFilter = ManageShiftGlobalFilterService.getFilterValues();
        var dateDisplayText: string = "";
        let isShowFullGrid = false;
        let disableButtonAdd = true;
        let disableButtonAddFinance = true;
        let linkTodaysView = false;
        if (
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
        ) {
            isShowFullGrid = true;
            disableButtonAdd = false;
        } else if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            disableButtonAdd = false;
        }
        if (
            UserService.isUserInGroup(RoleGroupNames.HeadDoorSupervisor) ||
            UserService.isUserInGroup(RoleGroupNames.VenueManager)
        ) {
            linkTodaysView = true;
        }
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            disableButtonAddFinance = false;
        }
        if (dateFrom.localeCompare(dateTo) == 0) {
            var startDateText: string = dateFrom
                ? this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(dateFrom)
                : "";
            dateDisplayText = `${startDateText ? startDateText : ""}`;
        } else {
            if (dateFrom.localeCompare(dateTo) != 0) {
                var minDateText: string = dateFrom
                    ? this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(dateFrom)
                    : "";
                var maxDateText: string = dateTo
                    ? this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(dateTo)
                    : "";
                dateDisplayText = `${minDateText ? minDateText : ""} - ${maxDateText ? maxDateText : ""}`;
            }
        }
        var header: string = "Shift Matrix";
        if (globalFilterDetails.serviceType != null) {
            if (globalFilterDetails.serviceType == ServiceType.Security) {
                header = "Door Supervision Shifts";
            }
            if (globalFilterDetails.serviceType == ServiceType.ContractCleaning) {
                header = "Contract Cleaning Shifts";
            }
            if (globalFilterDetails.serviceType == ServiceType.Entertainment) {
                header = "Scheduled Artists";
            }
        }
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <ManageShiftsDatePanel
                        onApplyButtonClick={this.refreshMatrixGrid}
                        clientId={""}
                        startDate={dateFrom}
                        endDate={dateTo}
                        pointOfNavigation={this.props && this.props.location && this.props.location.state && this.props.location.state.pointOfNavigation
                            ? this.props.location.state.pointOfNavigation
                            : ""}
                        filterText={filterText}
                    />
                    <BulletinPanel />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mt-3 mb-3">
                        <h2 className="page-title__black">{header}</h2>
                        <h3 className="grid-info__title">
                            {<b>{dateDisplayText ? dateDisplayText : ""}</b>}
                        </h3>
                    </header>
                    <div className="grid-info__row mt-2 mb-3">
                        <div className="grid-info__button-container">
                            <Link
                                className={
                                    (disableButtonAdd ? "disabled " : "") + "btn btn-primary btn--large btn--with-icon"
                                }
                                to={
                                    linkTodaysView
                                        ? {
                                            pathname: "/checkinOut",
                                            state: {
                                                id: 0,
                                            },
                                        }
                                        : disableButtonAdd
                                            ? "#"
                                            : {
                                                pathname: "/AddEditFormV2",
                                                state: {
                                                    id: 0,
                                                    serviceTypeId: ServiceType.Security,
                                                    pointOfNavigation: "manageshifts",
                                                    filterStartDate: dateFrom,
                                                    filterEndDate: dateTo,
                                                },
                                            }
                                }
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                                Add Shift
                            </Link>
                        </div>
                        <div className="grid-info__button-container">
                            <Link
                                className={
                                    (disableButtonAddFinance ? "disabled " : "") +
                                    "btn btn-primary btn--large btn--with-icon"
                                }
                                to={
                                    disableButtonAddFinance
                                        ? "#"
                                        : {
                                            pathname: "/FinanceAdjustmentPopUp",
                                            state: {
                                                id: 0,
                                                filterStartDate: dateFrom,
                                                filterEndDate: dateTo,
                                            },
                                        }
                                }
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                                {this.props.t("manageViewShift.pageHeader.adjustment.label")}
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            {isShowFullGrid == true ? (
                                <ShiftMatrixGrid
                                    dateRangeFrom={dateFrom}
                                    dateRangeTo={dateTo}
                                    filterText={filterText}
                                    isShowUnbilledItems={this.state.isShowUnbilled}
                                    isUnPaidItems={this.state.isUnPaid}
                                    isUnconfirmedItems={this.state.isUnconfirmedItems}
                                    isUnapprovedItems={this.state.isUnapprovedItems}
                                    
                                />
                                
                            ) : (
                                <></>
                            )} 
                        </div>
                        <div></div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(withTranslation()(ManageViewShiftPage));
