import {
    costReportDates,
    queryItem,
    queryStatusType,
    costReportGridRowItem,
    simpleShiftsGridRowItem,
} from "../../services/CostReportService";
import { PeriodMatrixTableLookup } from "../Billing/BillingFormUtils";
import sharedUtils from "../grid/sharedUtilities";
import outstandingActionsUtilities, { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import UserService, { RoleGroupNames } from "../../services/UserService";
import GridUtils from "../grid/GridUtilities";
import { itemTypes } from "../grid/AddEditPopUpUtilities";

interface ConfigurationLookupValues {
    id: string[];
    value: string[];
}

export interface periodLookupItem {
    id: string;
    value: string;
    periodNumber: string;
    periodWeek: string;
    dayRange: string;
    dateFrom: string;
    dateTo: string;
}

export default class CostReportUtils {
    //Declare the shared utils function here.
    utils: sharedUtils;
    gridUtils: GridUtils;
    outstandingActionUtils: outstandingActionsUtilities;
    constructor() {
        this.utils = new sharedUtils();
        this.gridUtils = new GridUtils();
        this.outstandingActionUtils = new outstandingActionsUtilities();
    }

    filterDatesBasedOnClient = (dateList: costReportDates[], clientId: string): periodLookupItem[] => {
        var itemLUItem: periodLookupItem;
        var dateListArray: periodLookupItem[];
        dateListArray = [];
        dateList.filter((item: costReportDates) => {
            if (item.clientId == clientId || item.businessEntityId == clientId) {
                itemLUItem = {
                    id: item.periodMatrixId,
                    value: item.displayPeriod,
                    periodNumber: item.periodNumber,
                    periodWeek: item.periodWeek,
                    dayRange: item.dayRange,
                    dateFrom: item.dateFrom,
                    dateTo: item.dateTo,
                };
                dateListArray.push(itemLUItem);
            }
        });
        return dateListArray;
    };

    displayValueForDateFrom(dateFrom: string, dateTo: string, periodNumber: string, weekNumber: string) {
        var dateFromVal = this.utils.convertStringToDate(dateFrom);
        var dateToVal = this.utils.convertStringToDate(dateTo);
        var partsOfdateFromDate: string[] = [];
        var partsOfdateToDate: string[] = [];
        var dateFromMonth: string = "";
        var dateFromDate: string = "";
        var dateFromYear: string = "";
        var dateToMonth: string = "";
        var dateToDate: string = "";
        var dateToYear: string = "";
        if (dateFromVal && dateToVal) {
            partsOfdateFromDate = dateFromVal.toString().split(" ");
            partsOfdateToDate = dateToVal.toString().split(" ");
        }
        if (partsOfdateFromDate && partsOfdateFromDate.length > 0) {
            dateFromMonth = partsOfdateFromDate[1];
            dateFromDate = partsOfdateFromDate[2];
            dateFromYear = partsOfdateFromDate[3].slice(-2);
        }
        if (partsOfdateToDate && partsOfdateToDate.length > 0) {
            dateToMonth = partsOfdateToDate[1];
            dateToDate = partsOfdateToDate[2];
            dateToYear = partsOfdateToDate[3].slice(-2);
        }

        var period = periodNumber;
        var week = weekNumber;
        var displayValue =
            dateFromDate +
            " " +
            dateFromMonth +
            " " +
            dateFromYear +
            " " +
            "-" +
            " " +
            dateToDate +
            " " +
            dateToMonth +
            " " +
            dateToYear +
            " " +
            "(" +
            "P" +
            period +
            " " +
            "Wk" +
            week +
            " " +
            ")";
        return displayValue;
    }

    filterDatesBasedOnClientPostApproval = (
        dateList: PeriodMatrixTableLookup[],
        clientId: string
    ): periodLookupItem[] => {
        var itemLUItem: periodLookupItem;
        var dateListArray: periodLookupItem[];
        dateListArray = [];
        dateList.filter((item: any) => {
            if (item.businessEntityId == clientId) {
                itemLUItem = {
                    id: item.id,
                    value: this.displayValueForDateFrom(item.dateFrom, item.dateTo, item.periodNumber, item.periodWeek),
                    periodNumber: item.periodNumber,
                    periodWeek: item.periodWeek,
                    dayRange: item.dayRange,
                    dateFrom: item.dateFrom,
                    dateTo: item.dateTo,
                };
                dateListArray.push(itemLUItem);
            }
        });
        return dateListArray;
    };

    /**
     * Provide a sort comparrison function in order to sort a PeriodMatrixTableLookup[] by periodNumber or periodWeek
     *
     * @param key {string} - Can be either 'periodNumber' or 'periodWeek'
     * @returns {function compare(a: PeriodMatrixTableLookup, b: PeriodMatrixTableLookup)} - The compare function for sorting
     */

    converPeriodStringDateToDate = (date: string) => {
        if (date && date.includes("/")) {
            var parts = date.split("/"); //"11/05/2020
            var year = parseInt(parts[2], 10);
            var mm = parseInt(parts[1], 10);
            var dd = parseInt(parts[0], 10);
            return new Date(year, mm - 1, dd);
        }
    };

    sortPeriodMatrixByDateFrom(key: string): (a: PeriodMatrixTableLookup, b: PeriodMatrixTableLookup) => number {
        return function compare(a: PeriodMatrixTableLookup, b: PeriodMatrixTableLookup) {
            let keyA: NullableDate = undefined;

            let keyB: NullableDate = undefined;
            if (a.dateFrom) {
                var parts = a.dateFrom.split("/"); //"11/05/2020
                var year = parseInt(parts[2], 10);
                var mm = parseInt(parts[1], 10);
                var dd = parseInt(parts[0], 10);
                keyA = new Date(year, mm - 1, dd);
            }
            if (b.dateFrom) {
                var parts = b.dateFrom.split("/"); //"11/05/2020
                var year = parseInt(parts[2], 10);
                var mm = parseInt(parts[1], 10);
                var dd = parseInt(parts[0], 10);
                keyB = new Date(year, mm - 1, dd);
            }

            let comparison: any;
            if (keyA && keyB) {
                comparison = keyA.valueOf() - keyB.valueOf();
            }
            return comparison;
        };
    }

    returnPeriodTextBasedOnId = (dateList: periodLookupItem[], dateId: string): periodLookupItem[] => {
        var itemLUItem: periodLookupItem;
        var perioWeekListArray: periodLookupItem[] = [];
        dateList.filter((item: periodLookupItem) => {
            if (item.id == dateId) {
                itemLUItem = {
                    id: item.id,
                    value: item.value,
                    periodNumber: item.periodNumber,
                    periodWeek: item.periodWeek,
                    dayRange: item.dayRange,
                    dateFrom: item.dateFrom,
                    dateTo: item.dateTo,
                };
                perioWeekListArray.push(itemLUItem);
            }
        });
        return perioWeekListArray;
    };

    disableRaiseQueryIcon = (queryList: queryItem[]): boolean => {
        var count: number = 0;
        var disableQueryIcon: boolean = false;
        for (var item = 0; item < queryList.length; item++) {
            if (
                queryList[item].query_Status == queryStatusType.Pending ||
                queryList[item].query_Status == queryStatusType.Assigned
            ) {
                count += 1;
                break;
            }
        }
        if (count == 1) {
            disableQueryIcon = true;
        } else {
            disableQueryIcon = false;
        }
        return disableQueryIcon;
    };

    findIndexOfDateId = (dateList: periodLookupItem[], dateId: string): number => {
        var dateIndex: number = 0;
        dateIndex = dateList.findIndex((item) => {
            return item.id.trim() == dateId.trim();
        });
        return dateIndex;
    };

    //Utility functions specific to the Shifts Screen starting below.

    //A helper function that will take a list of Period Matrix Item and return the set of Values based on ClientId
    buildPeriodListBasedOnClient = (
        clientId: string,
        periodMatrixTable: PeriodMatrixTableLookup[]
    ): PeriodMatrixTableLookup[] => {
        var clientMatrix: PeriodMatrixTableLookup[] = [];

        if (periodMatrixTable && periodMatrixTable.length > 0) {
            clientMatrix = periodMatrixTable.filter((item) => {
                return item.businessEntityId == clientId;
            });
        }
        return clientMatrix;
    };
    //Utility functions specific to the Shifts Screen starting below.

    //A helper function that will take a list of Period Matrix Item and return the set of Values based on ClientId
    buildPeriodListBasedOnDateCriteria = (periodMatrixTable: PeriodMatrixTableLookup[]): PeriodMatrixTableLookup[] => {
        var today = new Date();
        var priorDate = new Date(new Date().setDate(today.getDate() - 21));
        var clientMatrixByDateFrom: PeriodMatrixTableLookup[] = [];
        var clientMatrixByDateTo: PeriodMatrixTableLookup[] = [];
        if (periodMatrixTable && periodMatrixTable.length > 0) {
            clientMatrixByDateFrom = periodMatrixTable.filter((item) => {
                if (item.dateFrom) {
                    var dateFrom: NullableDate = undefined;
                    dateFrom = this.utils.convertStringToDate(item.dateFrom);
                }
                if (dateFrom) {
                    return dateFrom <= today;
                }
            });
            clientMatrixByDateTo = clientMatrixByDateFrom.filter((item) => {
                if (item.dateTo) {
                    var dateTo: NullableDate = undefined;
                    dateTo = this.utils.convertStringToDate(item.dateTo);
                }
                if (dateTo) {
                    return dateTo >= priorDate;
                }
            });
        }
        return clientMatrixByDateTo;
    };
    // A helper function that would set the Date range based on current date i.e; on the peiord it falls.
    setDateRangeForVenueManagers = (periodLookupList: PeriodMatrixTableLookup[]): string[] => {
        var dateFrom: NullableDate;
        var dateTo: NullableDate;
        var currentDate: Date = new Date();
        var dateArray: string[] = [];
        for (var item = 0; item < periodLookupList.length; item++) {
            dateFrom = this.utils.convertStringToDate(periodLookupList[item].dateFrom);
            dateTo = this.utils.convertStringToDate(periodLookupList[item].dateTo);
            if (dateFrom && dateTo && currentDate >= dateFrom && currentDate <= dateTo) {
                dateArray.push(periodLookupList[item].dateFrom);
                dateArray.push(periodLookupList[item].dateTo);
                break;
            }
        }
        return dateArray;
    };

    //Helper function that is used to construct the Header text displayed on the cost report page.
    constructHeaderText = (periodText: string, dayRange: string): string => {
        var periodTextParts: string[] = periodText.split("-");
        var dateRangeParts: string[] = dayRange.split("-");
        return `${dateRangeParts[0]} ${periodTextParts[0]} - ${dateRangeParts[1]} ${periodTextParts[1]}`;
    };

    //A function that returns the part enclosed within the brakcets. if 8 Hr(+ 15 mins) is passed, it would return +15 mins.
    splitBillableTimeToAjustmentType = (rowValue: string): string => {
        var extraBillableQuantity: string = "";
        if (rowValue && rowValue.length > 0) {
            if (rowValue.includes("(")) {
                extraBillableQuantity = rowValue
                    .substring(rowValue.lastIndexOf("(") + 1, rowValue.lastIndexOf(")"))
                    .trim();
            }
        }
        return extraBillableQuantity;
    };

    billableTimeDisplayContent = (cellData: any, navigation: string): string[] => {
        var billableColumnAttributes: string[] = [];
        var timeDiffIndicator: string = cellData && cellData.timeDiffIndicator ? cellData.timeDiffIndicator : "";
        var quantityDiffIndicator: string =
            cellData && cellData.quantityDiffIndicator ? cellData.quantityDiffIndicator : "";
        var billableTime: string = "";
        if (navigation == "manageshift" || navigation == "costreport") {
            billableTime = cellData && cellData.billableTime ? cellData.billableTime : "";
        } else if (navigation == "shiftmatrix") {
            billableTime = cellData && cellData.billable_Hours_Units ? cellData.billable_Hours_Units : "";
        }
        var isBlue: boolean = false;
        var isRed: boolean = false;
        var classname: string = "";
        var isEventOrClient =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.VenueManager);

        if (
            (timeDiffIndicator && timeDiffIndicator.toLowerCase() == "less hours") ||
            (quantityDiffIndicator && quantityDiffIndicator.toLowerCase() == "less quantity")
        ) {
            //Guard clause
            if (isEventOrClient) {
                isBlue = true;
            } else {
                isRed = true;
            }
        } else if (
            (timeDiffIndicator && timeDiffIndicator.toLowerCase() == "more hours") ||
            (quantityDiffIndicator && quantityDiffIndicator.toLowerCase() == "more quantity")
        ) {
            if (isEventOrClient) {
                isRed = true;
            } else {
                isBlue = true;
            }
        }

        if (isBlue) {
            classname = "check-blue";
        }
        if (isRed) {
            classname = "check-red";
        }
        billableColumnAttributes.push(classname);
        billableColumnAttributes.push(billableTime);
        return billableColumnAttributes;
    };

    billableRateDisplayContent = (cellData: any, navigation: string): string[] => {
        var billableColumnAttributes: string[] = [];
        var billableRate: string = "";
        if (navigation == "manageshift") {
            billableRate = cellData && cellData.rate ? cellData.rate : "";
        } else if (navigation == "shiftmatrix") {
            billableRate = cellData && cellData.rate_Decimal ? cellData.rate_Decimal : "";
        }
        var rateDiffIndicator: string = cellData && cellData.rateDiffIndicator ? cellData.rateDiffIndicator : "";
        var isBlue: boolean = false;
        var isRed: boolean = false;
        var classname: string = "";
        var isEventOrClient =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.VenueManager);

        if (rateDiffIndicator && rateDiffIndicator.toLowerCase() == "less rate") {
            //Guard clause
            if (isEventOrClient) {
                isBlue = true;
            } else {
                isRed = true;
            }
        } else if (rateDiffIndicator && rateDiffIndicator.toLowerCase() == "more rate") {
            if (isEventOrClient) {
                isRed = true;
            } else {
                isBlue = true;
            }
        }

        if (isBlue) {
            classname = "check-blue";
        }
        if (isRed) {
            classname = "check-red";
        }
        billableColumnAttributes.push(classname);
        billableColumnAttributes.push(billableRate);
        return billableColumnAttributes;
    };

    //A helper function that will return the totals excluding acceptances for EventUk users.
    //Works both for Cost report and simple shift screens.
    returnCalculationsExcludingItemType = (
        costReportGridItem: costReportGridRowItem[],
        simpleShiftGridItem: simpleShiftsGridRowItem[],
        isEventUser: boolean
    ): number => {
        var total: number = 0;
        if (costReportGridItem && costReportGridItem.length > 0) {
            if (isEventUser) {
                costReportGridItem.forEach((item: costReportGridRowItem) => {
                    if (item.itemTypeId != itemTypes.Acceptance) {
                        total = total + this.gridUtils.convertCurrencyAsStringToFloat(item.total);
                    }
                });
            } else {
                costReportGridItem.forEach((item: costReportGridRowItem) => {
                    total = total + this.gridUtils.convertCurrencyAsStringToFloat(item.total);
                });
            }
        } else if (simpleShiftGridItem && simpleShiftGridItem.length > 0) {
            if (isEventUser) {
                simpleShiftGridItem.forEach((item: simpleShiftsGridRowItem) => {
                    if (item.itemTypeId != itemTypes.Acceptance) {
                        total = total + this.gridUtils.convertCurrencyAsStringToFloat(item.clientBillableAmountLocal);
                    }
                });
            } else {
                simpleShiftGridItem.forEach((item: simpleShiftsGridRowItem) => {
                    total = total + this.gridUtils.convertCurrencyAsStringToFloat(item.clientBillableAmountLocal);
                });
            }
        }
        return total;
    };
}
