import { AxiosResponse } from "axios";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import GlobalService from "../../services/GlobalService";
import WelcomePageComponent from "./WelcomePageComponent";
import HeaderService, { HeaderResponse } from "../../services/HeaderService";

interface WelcomePageProps extends RouteComponentProps {}

interface WelcomePageState {
    configurationValue1: string;
    configurationValue2: string;
    titleText: string;
}

class WelcomePage extends Component<WelcomePageProps> {
    //Initialize the component's state.
    state: WelcomePageState;
    service: HeaderService;
    constructor(props: WelcomePageProps) {
        super(props);
        this.service = new HeaderService();
        this.state = {
            configurationValue1: "",
            configurationValue2: "",
            titleText: ""
        };
    }

    componentDidMount() {
        this.configurationValueDetails();
    }

    configurationValueDetails = () => {
        GlobalService.getConfigurationValueDetails().then(this.handleSuccess).catch();
        this.service.getMainHeaderByRole().then(this.handleTitleFetchSuccess).catch();
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            configurationValue1: response.data.data ? response.data.data[177].value : "",
            configurationValue2: response.data.data ? response.data.data[178].value : "",
        });
    };

    handleTitleFetchSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            titleText: response.data.data.title
        });
    }

    render() {
        return (
          <WelcomePageComponent configValueText1 = {this.state.configurationValue1} configValueText2 = {this.state.configurationValue2} title = {this.state.titleText}></WelcomePageComponent>
        );
    }
}

export default withRouter(WelcomePage);
