import React from "react";
import SimpleGridService, { GigStatusTypes } from "../../services/SimpleGridService";
import { LoadIndicator } from "devextreme-react";
import { WithTranslation } from "react-i18next";
import sharedUtils from "../grid/sharedUtilities";

interface MaskedStatusForGigProps extends WithTranslation {
    data: any;
}

interface MaskedStatusForGigState {
    Status: string;
    StatusColor: string;
    StatusBackGroundColor: string;
    billableItemId: number;
    loadIndicatorVisible: boolean;
    serviceValue: string;
    payDate?: Date | null;
    dateFrom?: Date | null;
    gigConfirmStatus: boolean;
    currentDate: Date;
}

class MaskedStatusForGig extends React.Component<MaskedStatusForGigProps> {
    state: MaskedStatusForGigState;
    Service: SimpleGridService;
    utils: sharedUtils;
    currentDate = new Date(new Date().toDateString());
    dateFrom = this.props.data.data.date ? new Date(this.props.data.data.date) : null;
    payDate = this.props.data.data.pay_Date ? new Date(this.props.data.data.pay_Date) : null;
    gigConfirmStatus = this.props.data.data.shiftCompleteAcknowledgment;

    constructor(props: MaskedStatusForGigProps) {
        super(props);
        this.setLoadIndicatorVisibility = this.setLoadIndicatorVisibility.bind(this);
        this.Service = new SimpleGridService();
        this.utils = new sharedUtils();
        this.state = {
            loadIndicatorVisible: false,
            Status: this.props.data.data.status,
            StatusColor: this.props.data.data.status_Color,
            StatusBackGroundColor: this.props.data.data.status_BackGroundColor,
            billableItemId: this.props.data.data.billableItemId,
            serviceValue: this.props.data.data.service,
            currentDate: new Date(new Date().toDateString()),
            dateFrom: this.props.data.data.date
                ? new Date(this.utils.convertddmmyyyyStringToCustomizedFormat(this.props.data.data.date))
                : null,
            payDate: this.props.data.data.pay_Date
                ? new Date(this.utils.convertddmmyyyyStringToCustomizedFormat(this.props.data.data.pay_Date))
                : null,
            gigConfirmStatus: this.props.data.data.shiftCompleteAcknowledgment
                ? this.props.data.data.shiftCompleteAcknowledgment
                : false,
        };
    }

    setLoadIndicatorVisibility(isVisible: boolean): void {
        this.setState({
            loadIndicatorVisible: isVisible,
        });
    }

    identifyStatusBackgroundColour = (StatusBackGroundColor : string, tenseIdentifier : string) => {
        let statusBackGroundColour = "";
        if (StatusBackGroundColor != null && tenseIdentifier.toUpperCase() == "GIGCURRENT") {
            if (this.state.StatusBackGroundColor.toUpperCase() == "BLUE") {
                statusBackGroundColour = "bg-info";
            } else if (StatusBackGroundColor.toUpperCase() == "GREEN") {
                statusBackGroundColour = "bg-success";
            } else if (StatusBackGroundColor.toUpperCase() == "RED") {
                statusBackGroundColour = "bg-danger";
            } else if (
                StatusBackGroundColor.toUpperCase() == "AMBER" &&
                this.state.Status.toUpperCase() == "PENDING"
            ) {
                statusBackGroundColour = "bg-warning";
            }
        }
        return statusBackGroundColour;
    }

    identifyStatusColor = (StatusColour : string) => {
        let statusColour = "";
        if (StatusColour != null) {
            if (StatusColour.toUpperCase() == "BLUE") {
                statusColour = "bg-info";
            } else if (StatusColour.toUpperCase() == "GREEN") {
                statusColour = "bg-success";
            } else if (StatusColour.toUpperCase() == "RED") {
                statusColour = "bg-danger";
            }
        }
        return statusColour;
    }

    render() {
        let statusBackGroundColour = this.identifyStatusBackgroundColour(this.state.StatusBackGroundColor , this.props.data.data.gigTenseFlag);
        let statusColour = this.identifyStatusColor(this.state.StatusColor);
        let status = "";
        if (this.state.dateFrom && this.state.dateFrom >= this.state.currentDate) {
            status = GigStatusTypes.pending;
        } else if (
            this.state.dateFrom &&
            this.state.dateFrom > this.state.currentDate &&
            !this.state.gigConfirmStatus
        ) {
            status = GigStatusTypes.awaiting_completion;
        } else if (!this.state.payDate && this.state.gigConfirmStatus) {
            status = GigStatusTypes.awaiting_authorization;
        } else if (this.state.payDate && this.state.payDate < this.state.currentDate) {
            status = GigStatusTypes.payment_scheduled;
        } else if (this.state.payDate && this.state.payDate >= this.state.currentDate) {
            status = GigStatusTypes.payment_sent;
        }
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <div
                            className={
                                statusBackGroundColour != ""
                                    ? `mr-2 status__circle ${statusBackGroundColour}`
                                    : `mr-2 status__circle ${statusColour}`
                            }
                        ></div>
                        <span className="status-message-color">{status}</span>
                        <LoadIndicator
                            className="ml-3"
                            id="button-indicator"
                            height={20}
                            width={20}
                            visible={this.state.loadIndicatorVisible}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default MaskedStatusForGig;
