import React from "react";
import { LoadIndicator } from "devextreme-react";
import { WithTranslation } from "react-i18next";

interface MaskedStatusForRefundProps extends WithTranslation {
    data: any;
}

interface MaskedStatusForRefundState {
    refundStatus: string;
}

class MaskedStatusForRefund extends React.Component<MaskedStatusForRefundProps> {
    state: MaskedStatusForRefundState;
    constructor(props: MaskedStatusForRefundProps) {
        super(props);
        this.state = {
            refundStatus: this.props.data.data.refund ? "Yes" : "No",
        };
    }

    //This is needed here to check for some conditions when the component mounts.
    componentDidMount() {}
    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <span className="status-message-color">{this.state.refundStatus}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default MaskedStatusForRefund;
