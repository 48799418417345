import React, { Component, MouseEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../Templates/LightModeTemplate";
import LookupService, { LookupTypeIndexes } from "../../../services/LookupService";
import CookiePreferencesService, { CookieSettingsType } from "../../../services/CookiePreferencesService";
import { AxiosResponse } from "axios";
import { ConfigurableTextIndexes } from "../../../services/configurationValueService";
import { RadioGroup } from "devextreme-react";
import { RadioButtonOnSelectionChanged } from "../../../types/DevExtremeTypes";
import SideBarTemplate from "../../side-bar/SideBarTemplate";
import DatePanel from "../../side-bar/DatePanel";
import BulletinPanel from "../../BulletinPanel/BulletinPanel";
import { getAccountID } from "../../../services/AuthService";

interface CookiePolicyPageProps extends RouteComponentProps { }

interface CookiePolicyPageState {
    configurationValueArray: any[];
    selectAnalytics: string;
    selectMarketing: string;
    selectSetting: string;
    confirmationPanelVisible: boolean;
    settingsHaveChanged: boolean;
    errorPanelVisible: boolean;
}

class CookiePolicyPage extends Component<CookiePolicyPageProps> {
    lookupService: LookupService;
    cookiePreferencesService: CookiePreferencesService;
    state: CookiePolicyPageState;
    constructor(props: CookiePolicyPageProps) {
        super(props);
        this.lookupService = new LookupService();
        this.cookiePreferencesService = new CookiePreferencesService();
        this.state = {
            configurationValueArray: [],
            selectAnalytics: "Accept",
            selectMarketing: "Accept",
            selectSetting: "Accept",
            confirmationPanelVisible: false,
            settingsHaveChanged: false,
            errorPanelVisible: false,
        };
    }

    componentDidMount() {
        this.getConfigurationLookup();
        this.getExistingCookieSettings();
    }

    getConfigurationLookup = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.configuration)
            .then(this.handleConfigurationLookupSuccess)
            .catch(this.handleFailure);
    };

    getExistingCookieSettings = () => {
        let accountID = getAccountID();

        if (accountID === "") {
            this.handleError();
        } else {
            let existingCookieSettings = this.cookiePreferencesService.getCookiePreferences(accountID);
            if (existingCookieSettings != null) {
                this.setState({
                    selectAnalytics: existingCookieSettings.Analytics,
                    selectMarketing: existingCookieSettings.Marketing,
                    selectSetting: existingCookieSettings.Settings,
                });
            } else {
                this.setState({
                    settingsHaveChanged: true //enable confirm button for users with no saved preferences
                });
            }
        }
    };

    handleConfigurationLookupSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            configurationValueArray: response.data.data,
        });
    };

    handleFailure = (response: AxiosResponse<any>) => {
        this.setState({
            configurationValueArray: [],
        });
    };

    //Event Handlers that will store the value of the Selected Radio button inside the state variable.
    changeAnalytics = (event: RadioButtonOnSelectionChanged) => {
        this.setState({
            selectAnalytics: event.value,
            settingsHaveChanged: true,
        });
    };

    changeMarketing = (event: RadioButtonOnSelectionChanged) => {
        this.setState({
            selectMarketing: event.value,
            settingsHaveChanged: true,
        });
    };

    changeSettings = (event: RadioButtonOnSelectionChanged) => {
        this.setState({
            selectSetting: event.value,
            settingsHaveChanged: true,
        });
    };

    onSubmit = () => {
        let accountID = getAccountID();

        if (accountID === "") {
            this.handleError();
        } else {
            let cookieSettings: CookieSettingsType = {
                AccountID: accountID,
                Analytics: this.state.selectAnalytics,
                Marketing: this.state.selectMarketing,
                Settings: this.state.selectSetting,
            };
            this.cookiePreferencesService.setCookiePreferences(cookieSettings);

            this.setState({
                confirmationPanelVisible: true,
                errorPanelVisible: false,
                settingsHaveChanged: false,
            });
        }
    };

    handleError() {
        //handle issue where we could not get account ID from JWT
        this.setState({
            settingsHaveChanged: false,
            confirmationPanelVisible: false,
            errorPanelVisible: true,
        });
        console.error("There was an issue retrieving the account ID from the JWT token.");
    }

    render() {
        let cookieIntroText1: string = "";
        let cookieIntroText2: string = "";
        let analyticsText: string = "";
        let marketingText: string = "";
        let settingsText: string = "";
        if (this.state.configurationValueArray.length > 0) {
            cookieIntroText1 = this.state.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.CookiePageIntro1;
            })[0].value;
            cookieIntroText2 = this.state.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.CookiePageIntro2;
            })[0].value;
            analyticsText = this.state.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.CookiePageAnalytics;
            })[0].value;
            marketingText = this.state.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.CookiePageCommsMarket;
            })[0].value;
            settingsText = this.state.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.CookiePageSettings;
            })[0].value;
        }

        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>
                <div id="cookiePolicyPage" className="page-content--with-sidebar-hidden-mobile">
                    <div className="col-12">
                        <form data-testid="addEditPayment-form" className="card card-form my-5">
                            <div className="card-body">
                                <section>
                                    <div className="card-form__field card-form__field--wide">
                                        <h4 className="card-form__tealheader">Cookies</h4>
                                        <p>{cookieIntroText1}</p>
                                        <p>{cookieIntroText2}</p>
                                    </div>
                                    <div className="card-form__field card-form__field--wide">
                                        <h4 className="card-form__tealheader">Cookie Settings</h4>
                                        <p>Please choose which cookies you are happy for us to use</p>
                                    </div>
                                    {this.state.errorPanelVisible ? (
                                        <p className="card-form__errors">
                                            Sorry, there was an issue accessing your settings. If the problem persists
                                            then please contact Event UK.
                                        </p>
                                    ) : (
                                        <>
                                            <div className="card-form__field card-form__field--wide">
                                                <h4 className="card-form__subheading">Analytics</h4>
                                                <p>{analyticsText}</p>
                                                <RadioGroup
                                                    items={["Accept", "Reject"]}
                                                    value={this.state.selectAnalytics}
                                                    onValueChanged={this.changeAnalytics}
                                                    layout={"horizontal"}
                                                />
                                            </div>
                                            <div className="card-form__field card-form__field--wide">
                                                <h4 className="card-form__subheading">Communications & Marketing</h4>
                                                <p>{marketingText}</p>
                                                <RadioGroup
                                                    items={["Accept", "Reject"]}
                                                    value={this.state.selectMarketing}
                                                    onValueChanged={this.changeMarketing}
                                                    layout={"horizontal"}
                                                />
                                            </div>
                                            <div className="card-form__field card-form__field--wide">
                                                <h4 className="card-form__subheading">Your Settings</h4>
                                                <p>{settingsText}</p>
                                                <RadioGroup
                                                    items={["Accept", "Reject"]}
                                                    value={this.state.selectSetting}
                                                    onValueChanged={this.changeSettings}
                                                    layout={"horizontal"}
                                                />
                                            </div>
                                            <div className="card-form__field card-form__field--wide">
                                                <h4 className="card-form__subheading">Strictly Necessary</h4>
                                                <p>These cookies are required for web site use and issue resolution</p>
                                            </div>
                                        </>
                                    )}
                                </section>
                                {this.state.confirmationPanelVisible ? (
                                    <p className="card-form__confirmation">
                                        Thank you, your settings have been captured.
                                    </p>
                                ) : (
                                    ""
                                )}
                                {!this.state.errorPanelVisible ? (
                                    <section className="card-form__button-container">
                                        <div className="col-12 col-lg-4 col-xl-2">
                                            <button
                                                className="btn btn-primary btn--large mt-3"
                                                type="submit"
                                                disabled={!this.state.settingsHaveChanged}
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.onSubmit();
                                                }}
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </section>
                                ) : (
                                    ""
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(CookiePolicyPage);
