import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface DatePanelProps extends WithTranslation {
}

interface DatePanelState {
}

class DatePanel extends Component<DatePanelProps> {
    state: DatePanelState;

    constructor(props: DatePanelProps) {
        super(props);
        this.state = {
        };
    }

    renderDate = () => {
        let dateString = new Date();
        let longMonth = dateString.toLocaleString("en-GB", { month: "long" });
        return dateString.getDate() + " " + longMonth + " " + dateString.getFullYear();
    }

    renderDay = () => {
        let dateString = new Date();
        return dateString.toLocaleString("en-GB", { weekday: "long" });
    }

    render() {
        var currentDay: string = this.renderDay();
        var currentDate: string = this.renderDate();
        
       
        return (
            <>
                <div className="sidebar__date-section mt-lg-4 row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <h3 className="text-center">
                            <span className="d-block text-center">
                                <h4>{ currentDay ? currentDay : ""}</h4>
                                <h4>{ currentDate ? currentDate : ""}</h4>
                            </span>
                        </h3>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(DatePanel);
