import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface CollapsibleSidePanelDesignProps extends RouteComponentProps {}

class CollapsibleSidePanelDesign extends Component<CollapsibleSidePanelDesignProps> {
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    test content with collapsible sidebar, should be expanded on desktop and collapsed on mobile
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(CollapsibleSidePanelDesign);
