import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import LicenseChecksComponent from "./LicenseChecksComponent";

interface LicenseChecksPageProps extends RouteComponentProps {}

interface LicenseChecksPageState {}

class LicenseChecksPage extends Component<LicenseChecksPageProps> {
    //Initialize the component's state.
    state: LicenseChecksPageState;

    constructor(props: LicenseChecksPageProps) {
        super(props);
        this.state = {
            //Define the page state here if any.
        };
    }

    render() {
        return (
            <LightModePageTemplate>
                <section className="page-content container--small-margins">
                    <header className="grid-info mb-3">
                        <h2 className="page-title__black">License Checks</h2>
                    </header>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md">
                            <LicenseChecksComponent />
                        </div>
                        <div />
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(LicenseChecksPage);
