import React, { MouseEvent, Component } from "react";
import LargeLightCardWrapper from "./LargeLightCardWrapper";

interface ExampleLargeLightCardProps {}

class ExampleLargeLightCard extends React.Component<ExampleLargeLightCardProps> {
    constructor(props: ExampleLargeLightCardProps) {
        super(props);
    }

    handleChangeComments = () => {};

    render() {
        return (
            <LargeLightCardWrapper
                hasHeader={false}
                leftSection={
                    <>
                        <div className="row pending-request-panel__details">
                            <div className="col-12 col-lg-5">
                                <span className="pending-request-panel__title">Red Lion London, London</span>
                                N1 1EV
                            </div>
                            <div className="col-12 col-lg-3 mt-3 mt-lg-0">
                                <span className="pending-request-panel__title">Sat 19 Sep 2020</span>
                                02:30-00:00
                            </div>
                            <div className="col-12 col-lg-2 mt-3 mt-lg-0">
                                <span className="pending-request-panel__title">DJ</span>
                                Commercial DJ
                            </div>
                            <div className="col-12 col-lg-2 mt-3 mt-lg-0">
                                <span className="pending-request-panel__title">£100.01</span>
                                Budget
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="pending-request-panel__description">
                                    <div>Rock music</div>
                                    <div>Party atmosphere along with crowd engagement</div>
                                    <div>Laptop along with DJ Band</div>
                                    <div>Peter Dane, peter.dane@gmail.com, +67456475643</div>
                                </div>
                                <div className="pending-request-panel__requested">
                                    Requested:{" "}
                                    <span className="pending-request-panel__request-date">Thu 24 Sep 2020</span>
                                </div>
                            </div>
                        </div>
                    </>
                }
                rightSection={
                    <>
                        <div className="pending-request-panel__controls">
                            <a className="btn btn-primary btn--full-width" href="/findAnArtistLandingPage">
                                View Request
                            </a>
                            <button className="btn btn--full-width btn--ghost--teal mt-3 pending-request-panel__raise-query-button">
                                Cancel Request
                            </button>
                            <button className="btn btn--full-width btn--ghost--teal mt-3">Raise Query</button>
                            <div className="dx-overlay dx-popup dx-widget dx-state-invisible pending-request-panel__raise-query-pop-up dx-visibility-change-handler">
                                <div className="dx-overlay-content dx-popup-normal" aria-hidden="true">
                                    <div className="dx-popup-content"></div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        );
    }
}
export default ExampleLargeLightCard;
