import React from "react";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import paymentFormUtils, { ActionType, PaymentFormData } from "../Payments/PaymentFormUtils";
import PaymentService, { logicAppPostRequest, paymentCycleStatus, paymentStatus } from "../../services/PaymentService";
import { LoadPanel } from "devextreme-react";

interface PaymentEmailNotificationSectionProps {
    paymentCycleId: number;
    paymentData: PaymentFormData;
    onPaymentProduced: (status: string, statusId: string, isRestartDisable: boolean) => void;
    statusId: string;
    notificationSentDate: NullableDate;
    syncDateFields: (date: NullableDate, type: string) => void;
    calculationsObject: any;
    viewDisabled: boolean;
}

interface PaymentEmailNotificationSectionState {
    loadPanelVisible: boolean;
    disableNotificationButton: boolean;
    disableSentButton: boolean;
    statusIdOnClick: string;
    downloadLink: string;
    downloadLinkCss: string;
    paymentFormData: PaymentFormData;
    errorMessage: [];
}

class PaymentEmailNotificationSection extends React.Component<PaymentEmailNotificationSectionProps> {
    state: PaymentEmailNotificationSectionState;
    PaymentService: PaymentService;
    paymentFormUtils: paymentFormUtils;
    sharedUtils: sharedUtils;
    documentService: ManageDocumentsGridService;

    constructor(props: PaymentEmailNotificationSectionProps) {
        super(props);
        this.PaymentService = new PaymentService();
        this.paymentFormUtils = new paymentFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            loadPanelVisible: false,
            disableNotificationButton: false,
            disableSentButton: false,
            statusIdOnClick: "",
            downloadLink: "",
            downloadLinkCss: "icon-btn",
            paymentFormData: this.props.paymentData,
            errorMessage: [],
        };
        this.documentService = new ManageDocumentsGridService();
    }

    //Function which is invoked when the "Produce" as well as "Sent" button is clicked.
    onPaymentProduced = (statusId: string, status: string) => {
        let data: PaymentFormData = this.paymentFormUtils.paymentDataInitializePaymentItems(
            this.props,
            statusId,
            status
        );
        this.setState({
            gridData: data,
            loadPanelVisible: true,
            statusIdOnClick: statusId,
        });
        this.onSubmit(data);
    };

    onSubmit = (paymentData: PaymentFormData) => {
        let paymentCycleSubmitRequest = this.paymentFormUtils.convertFormDataToPaymentCycleRequest(
            paymentData,
            ActionType.Save,
            "",
            ""
        );
        this.PaymentService.postPaymentCycleDataV2(paymentCycleSubmitRequest)
            // .then(this.handleSuccess)
            .then(res => this.handleSuccess(res,paymentData))
            .catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>,paymentData:PaymentFormData) => {
        const { statusIdOnClick } = this.state;
        const { paymentCycleId } = this.props;

        this.setState(
            (prevState: PaymentEmailNotificationSectionState) => ({
                loadPanelVisible: false,
                disableNotificationButton: true,
                downloadLinkCss: "icon-btn",
                errorMessage: [],
            }),
            () => this.props.onPaymentProduced(paymentData.status, statusIdOnClick, false)
        );
        
        if (statusIdOnClick == paymentStatus.PaymentEmailNotificationRequested) {
            let postObject: logicAppPostRequest = {
                PaymentCycleId: paymentCycleId.toString(),
            };
            this.PaymentService.triggerLogicAppForPaymentEmailNotificationRequest(postObject);
        }
    };

    handleFailure = (error: any) => {
        var { statusIdOnClick } = this.state;
        var respMessage: string = "postPaymentCycleNotification failed with response: " + JSON.stringify(error);

        if (!this.PaymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            errorMessage: error.response !== null ? JSON.parse(JSON.stringify(error.response.data.error)) : null,
            loadPanelVisible: false,
            disableNotificationButton: false,
            downloadLinkCss:
                statusIdOnClick == paymentCycleStatus.PaymentEmailNotificationRequested ? "disabled icon-btn" : "icon-btn",
        });
    };

    //Inside the update function, when the Sent date is changed in the form, it would reflect here and vice versa.
    componentDidUpdate = (prevProps: PaymentEmailNotificationSectionProps) => {
        if (this.props.notificationSentDate != prevProps.notificationSentDate) {
            this.setState({
                billingFormData: {
                    ...this.state.paymentFormData,
                    notificationSentDate: this.props.notificationSentDate,
                },
            });
        }
    };

    render() {
        var viewDisabled: boolean = this.props.viewDisabled;
        var notificationButtonText: string = "SEND EMAIL NOTIFICATIONS";
        var { statusId } = this.props;
        var { disableNotificationButton } = this.state;
        if (statusId && statusId == paymentStatus.PaymentEmailNotificationRequested) {
            notificationButtonText = "SENDING EMAIL NOTIFICATIONS";
        } else if (
            statusId &&
            (statusId == paymentStatus.PaymentEmailNotification || 
                statusId == paymentStatus.PaymentAdviseRequested ||
                statusId == paymentStatus.PaymentAdvised ||
                statusId == paymentStatus.PaymentSettled ||
                statusId == paymentStatus.PaymentRemittanceRequested ||
                statusId == paymentStatus.PaymentRemitted)
        ) {
            notificationButtonText = "EMAIL NOTIFICATION SENT";
        }
        var notificationButtonCssClass: string =
            this.props.viewDisabled ||
            disableNotificationButton ||
            statusId == paymentStatus.PaymentEmailNotificationRequested ||
            statusId == paymentStatus.PaymentEmailNotification ||
            statusId == paymentStatus.PaymentAdvised ||
            statusId == paymentStatus.PaymentAdviseRequested ||
            statusId == paymentStatus.PaymentSettled ||
            statusId == paymentStatus.PaymentRemittanceRequested ||
            statusId == paymentStatus.PaymentRemitted
                ? "btn disabledCycleButtonColor btn--large"
                : "btn saveCycleButtonColor btn--large";
        var disablePaymentNotificationButton: boolean =
        disableNotificationButton ||
            statusId == paymentStatus.PaymentEmailNotificationRequested ||
            statusId == paymentStatus.PaymentEmailNotification ||
            statusId == paymentStatus.PaymentAdvised ||
            statusId == paymentStatus.PaymentAdviseRequested ||
            statusId == paymentStatus.PaymentSettled ||
            statusId == paymentStatus.PaymentRemittanceRequested ||
            statusId == paymentStatus.PaymentRemitted
                ? true
                : false;
        return (
            <div className="card my-3">
                <div className="card-body">
                    <h4>
                        <b>Email Notifications</b>
                    </h4>
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <div className="row align-items-center">
                            <div className="col-2">
                                <button
                                    className={(viewDisabled ? "disabled " : "") + notificationButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onPaymentProduced(
                                            paymentStatus.PaymentEmailNotificationRequested,
                                            paymentCycleStatus.PaymentEmailNotificationRequested
                                        );
                                    }}
                                    disabled={viewDisabled ? true : disablePaymentNotificationButton}
                                >
                                    {notificationButtonText}
                                </button>
                            </div>

                            <div className="col-2">
                                    <button
                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                    //className={(viewDisabled ? "disabled " : "") + notificationButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onPaymentProduced(
                                            paymentStatus.PaymentEmailNotification,
                                            paymentCycleStatus.PaymentEmailNotification
                                        );
                                    }}
                                    disabled={viewDisabled ? true : disablePaymentNotificationButton}>
                                    SKIP
                                </button>
                                </div>
                        </div>
                    </>
                </div>
            </div>
        );
    }
}
export default PaymentEmailNotificationSection;
