import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Schedule from "../schedule/Schedule";
import VenueFinances from "../VenueFinances/VenueFinances";
import UserService, { RoleGroupNames } from "../../services/UserService";
import EntLandPageItemList from "../EntertainmentLanding/EntLandPageItemList";
import DarkModePageTemplate from "./Templates/DarkModePageTemplate";
import LightModePageTemplate from "./Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import DatePanel from "../side-bar/DatePanel";
import TodayCard from "../Today/TodayCard";
import VenueCard from "../Venue/VenueCard";
import ScheduleService from "../../services/ScheduleService";
import dashBoardUtils, { DashBoardData } from "../Today/DashBoardUtils";
import { AxiosResponse } from "axios";
import TodaysViewCheckInOutPageContainer from "./TodaysViewCheckInOutPageContainer";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import WelcomePage from "../Welcome/WelcomePage";
import { isIE } from "../../common/CommonUIUtils";
import HomePageContainer from "../Home/HomePageContainer";
interface PageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
    getBuild: (flag: boolean) => void; 
}
// State
interface PageComponentState {
    clientId: string;
    venueId: string;
    providerId: string;
    isClientOrVenueFound: boolean;
    dashBoardData: DashBoardData;
    isDashBoardDataLoaded: boolean;
    dataNotFoundMsg: string;
    startDate: string;
    endDate: string;
    serviceTypeId: string;
    isPaymentGridRefresh: boolean;
    paymentId: string;
}

class Page extends Component<PageComponentProps> {
    state: PageComponentState;
    dashBoardService: ScheduleService;
    utils: dashBoardUtils;
    constructor(props: PageComponentProps) {
        super(props);
        this.dashBoardService = new ScheduleService();
        this.utils = new dashBoardUtils();
        // Initialize state
        var convertedDashBoardDataItem = this.utils.initializeDashBoardItems();
        this.state = {
            clientId: "",
            venueId: "",
            providerId: "",
            isClientOrVenueFound: false,
            dashBoardData: convertedDashBoardDataItem,
            isDashBoardDataLoaded: false,
            dataNotFoundMsg: "",
            startDate:
                this.props.location.state && this.props.location.state.startDate
                    ? this.props.location.state.startDate
                    : "",
            endDate:
                this.props.location.state && this.props.location.state.endDate ? this.props.location.state.endDate : "",
            serviceTypeId:
                this.props.location.state && this.props.location.state.serviceTypeId
                    ? this.props.location.state.serviceTypeId
                    : "",
            isPaymentGridRefresh: false,
            paymentId: "",
        };
    }

    setPaymentId = (paymentId: string) => {
        if (paymentId) {
            this.setState({
                paymentId: paymentId,
                gigsGridVisible: true,
            });
        }
    };

    setClientOrVenueId = (venueIdValue: string, clientIdValue: string, isClientOrVenueValue: boolean) => {
        this.setState(
            {
                clientId: clientIdValue,
                venueId: venueIdValue,
                isClientOrVenueFound: isClientOrVenueValue,
                isDashBoardDataLoaded: false,
            },
            () => {
                this.getDashBoardData();
            }
        );
    };

    getDashBoardData = () => {
        if (this.state.clientId && this.state.venueId) {
            this.dashBoardService
                .getFinancialDashBoardData(this.state.clientId, this.state.venueId)
                .then(this.handleSuccess)
                .catch((err) => {
                    var respMessage: string = "getDashBoardData failed with response: " + JSON.stringify(err);

                    if (!this.dashBoardService.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                    this.setState({
                        isDashBoardDataLoaded: false,
                    });
                });
        } else {
            this.setState({
                dataNotFoundMsg: "No data found",
                isDashBoardDataLoaded: true,
            });
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            dashBoardData: response.data.data,
            isDashBoardDataLoaded: true,
            dataNotFoundMsg: "",
        });
    };

    setCalculatedValue = () => { };
    returnCheckInOutHomePage = () => {
        return (
            <DarkModePageTemplate>
                <TodaysViewCheckInOutPageContainer
                    authorizedUserRoles={[
                        RoleGroupNames.EventUKSeniorManager,
                        RoleGroupNames.EventUKRelationshipManager,
                        RoleGroupNames.VenueManager,
                        RoleGroupNames.HeadDoorSupervisor,
                    ]}
                />
            </DarkModePageTemplate>
        );
    }

    returnEntertainmentHomePage = () => {
        return (
            <DarkModePageTemplate>
                <div id="entLandPage" className="page-content">
                    <section className="container accent-container">
                        <div className="pb-3">
                            <EntLandPageItemList location={this.props.location} history={this.props.history} />
                        </div>
                    </section>
                </div>
            </DarkModePageTemplate>
        );
    }

    returnProviderHomePage = () => {
        return (
            <>
                <SideBarTemplate isFixed={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <section className="col">
                        <WelcomePage></WelcomePage>
                    </section>
                </div>
            </>
        );
    }

    returnVenueManagerHomePage = (isShowFinancialChart: boolean) => {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <section className="col">
                        <Schedule venueAll={true} setParentFilterValuesCallback={this.setClientOrVenueId} navigateFrom="homepage" />
                        <div className="row mb-xl-5" hidden={!isShowFinancialChart}>
                            <div className="col-12 col-xl-5 mb-4 mb-xl-0">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <TodayCard
                                            dashBoardData={this.state.dashBoardData}
                                            clientId={this.state.clientId}
                                            venueId={this.state.venueId}
                                            isDashBoardDataLoaded={this.state.isDashBoardDataLoaded}
                                            dataNotFoundMsg={this.state.dataNotFoundMsg}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-7 mb-4 mb-xl-0">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h4 className="home-page-card__header">Venue</h4>
                                        <VenueCard
                                            dashBoardData={this.state.dashBoardData}
                                            isDashBoardDataLoaded={this.state.isDashBoardDataLoaded}
                                            clientId={this.state.clientId}
                                            venueId={this.state.venueId}
                                            dataNotFoundMsg={this.state.dataNotFoundMsg}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div hidden={isIE() || !isShowFinancialChart}>
                            <VenueFinances
                                filterByClientId={this.state.clientId}
                                filterByVenueId={this.state.venueId}
                                clientOrVenueFound={this.state.isClientOrVenueFound}
                            />
                        </div>
                    </section>
                </div>
            </LightModePageTemplate>
        );
    }

    getBuild = (flag: boolean) => {
        this.props.getBuild(flag);
    };

    returnEventHomePage = () => {
        return (
            <HomePageContainer location={this.props.location} getBuild={this.getBuild}></HomePageContainer>
        );
    }
    render() {
        let isShowFinancialChart = true;
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            return this.returnProviderHomePage();
        }
        if (UserService.isUserInGroup(RoleGroupNames.Artist)) {
            return this.returnEntertainmentHomePage();
        }
        if (UserService.isUserInGroup(RoleGroupNames.HeadDoorSupervisor)) {
            return this.returnCheckInOutHomePage();
        }

        if (
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
        ) {
            
            if (window.location.pathname.includes("budgetView")) {
                return this.returnVenueManagerHomePage(isShowFinancialChart);
            }
            else if(this.props.location.state && this.props.location.state.serviceTypeId){
                isShowFinancialChart = false;
                return this.returnVenueManagerHomePage(isShowFinancialChart);
            }
            else {
                return this.returnEventHomePage();
            }
        }

        if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            return this.returnVenueManagerHomePage(isShowFinancialChart);
        }
    }
}

export default withRouter(Page);
