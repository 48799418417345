import React from "react";
import { Link } from "react-router-dom";
import { ServiceType } from "../../common/ServiceUtilities";
import { queryCard } from "../../services/QueryService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import gridUtils from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";

interface ContractCleaningCardProps {
    querycard: queryCard;
    serialNumber: number;
    totalNumber: number;
    serviceType: string;
}

interface ContractCleaningCardState {
    queryIsOpen: boolean;
}

class ContractCleaningCardComponent extends React.Component<ContractCleaningCardProps> {
    state: ContractCleaningCardState;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    constructor(props: ContractCleaningCardProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.gridUtils = new gridUtils();
        this.state = {
            queryIsOpen: false,
        };
    }

    //Helper function to navigate to the Simple shifts page by storing the appropriate service type id's into session storage.
    viewSimpleShiftsForService = (serviceTypeId: string) => {
        sessionStorage.setItem("serviceTypeFilter", serviceTypeId);
    };
    render() {
        var maxWidthQueryDesc = 25;
        const {
            serviceSubTypeValue,
            queryTitle,
            queryStatus,
            assignedTo,
            queryDescription,
            contactName,
            contactJobTitle,
            client,
            venueName,
            houseNumber,
            provider,
            dateFrom,
            rate,
            providerPayAmountLocal,
            billed,
            dateTo,
            resolutionReason,
            d365LinkID,
            linkedId,
            priority,
            comments,
            quantity,
            otherDescription,
            requestApprover,
            queryDateCreated
        } = this.props.querycard;
        let formattedDate: NullableDate = this.gridUtils.convertUTCStringToDate(queryDateCreated);
        var formattedDatePart2 = this.sharedUtils.formattedDateValue(formattedDate);
        var raisedByContact: string = contactName
            ? `${contactName} (${contactJobTitle}) on ${formattedDatePart2}`
            : `${formattedDatePart2}`;
        var isServiceTypeValueWeeklyCleaing = false;
        if (serviceSubTypeValue && serviceSubTypeValue.toLowerCase() == "weekly cleaning") {
            isServiceTypeValueWeeklyCleaing = true;
        }
        var cleaningDescription = otherDescription ? otherDescription : "";
        const isEventUk =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        return isServiceTypeValueWeeklyCleaing ? (
            <>
                <div className="row">
                    <div className="col-12 col-xl-7">
                        <span className="large-light-card-layout__title">
                            {queryTitle ? queryTitle : serviceSubTypeValue + " " + "(" + dateFrom + "-" + dateTo + ")"}
                        </span>
                        <span className="large-light-card-layout__normal">Raised by: </span>
                        {raisedByContact}
                    </div>
                    <div className="col-12 col-xl-5 large-light-card-layout__status">
                        <div className="divider-narrow--top pt-4 pt-xl-0">
                            <span className="large-light-card-layout__normal">Status: </span>
                            {queryStatus}
                            <br></br>
                            {assignedTo ? (
                                <>
                                    <span className="large-light-card-layout__normal">Assigned To: </span>
                                    {assignedTo}{" "}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pt-4 text-center text-xl-left">
                            <div className="divider--top divider--bottom py-4 mb-10">
                                <span className="large-light-card-layout__labelbold">Query Description</span>
                                <span className="d-flex justify-content-center">
                                    {queryDescription ? queryDescription : ""}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pt-1 text-center text-xl-left">
                            <div className="py-4 mb-10">
                                <span className="large-light-card-layout__labelbold">Shift Details</span>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Client: </label>
                                <div className="col-5 col-xl-2 text-left">{client}</div>
                                <label className="col-6 col-xl-6 large-light-card-layout__normal">
                                    Contract Hours:{" "}
                                </label>
                                <div className="col-5 col-xl-2 text-left">{quantity ? quantity + "/Week" : null}</div>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Venue: </label>
                                <div className="col-5 col-xl-2 text-left">{venueName}</div>
                                <label className="col-6 col-xl-6 large-light-card-layout__normal">Rate: </label>
                                <div className="col-5 col-xl-2 text-left">{rate ? rate + "/Hour" : null}</div>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Number: </label>
                                <div className="col-5 col-xl-2 text-left">{houseNumber}</div>
                                <label className="col-6 col-xl-6 large-light-card-layout__normal">Total: </label>
                                <div className="col-5 col-xl-1 text-left">{providerPayAmountLocal}</div>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Provider: </label>
                                <div className="col-5 col-xl-2 text-left">{provider}</div>
                                <label className="col-6 col-xl-6 large-light-card-layout__normal">Billed: </label>
                                <div className="col-5 col-xl-2 text-left">{billed ? billed : "N/A"}</div>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Shift Date: </label>
                                <div className="col-5 col-xl-4 text-left">
                                    {dateFrom} - {dateTo}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pt-4 text-center text-xl-left">
                            <div className="divider--top py-4 mb-10">
                                <span className="large-light-card-layout__labelbold">Resolution Reason</span>
                                <span className="d-flex justify-content-center">
                                    {resolutionReason ? resolutionReason : "Not yet resolved"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row mt-3 justify-content-center">
                            <div className="large-light-card-layout__controls">
                                <Link
                                    className="btn--link btn btn-primary btn--full-width"
                                    onClick={() => this.viewSimpleShiftsForService(ServiceType.ContractCleaning)}
                                    to={{
                                        pathname: "/simpleShifts",
                                        state: {
                                            id: this.props.querycard.billableItemId,
                                        },
                                    }}
                                >
                                    View Related shift
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {isEventUk ? (
                    <div className="row">
                        <div className="col-12">
                            <div className="pt-4 text-center text-xl-left">
                                <div className="divider--top py-4 mb-10">
                                    <span className="large-light-card-layout__labelbold">Event UK Only</span>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">
                                        Link to Dynamics:{" "}
                                    </label>
                                    <div className="col-5 col-xl-2 text-left">
                                        <a href={d365LinkID} target="_blank">
                                            {d365LinkID}
                                        </a>
                                    </div>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">
                                        Linked Id:{" "}
                                    </label>
                                    <div className="col-5 col-xl-2 text-left">{linkedId}</div>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">Priority: </label>
                                    <div className="col-5 col-xl-2 text-left">{priority}</div>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">
                                        Internal Comments:{" "}
                                    </label>
                                    <div className="col-5 col-xl-2 text-left">{comments}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        ) : (
            <>
                <div className="row">
                    <div className="col-12 col-xl-7">
                        <span className="large-light-card-layout__title">
                            {queryTitle ? queryTitle : serviceSubTypeValue + " " + "(" + dateFrom + "-" + dateTo + ")"}
                        </span>
                        <span className="large-light-card-layout__normal">Raised by: </span>
                        {raisedByContact}
                    </div>
                    <div className="col-12 col-xl-5 large-light-card-layout__status">
                        <div className="divider-narrow--top pt-4 pt-xl-0">
                            <span className="large-light-card-layout__normal">Status: </span>
                            {queryStatus}
                            <br></br>
                            {assignedTo ? (
                                <>
                                    <span className="large-light-card-layout__normal">Assigned To: </span>
                                    {assignedTo}{" "}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pt-4 text-center text-xl-left">
                            <div className="divider--top divider--bottom py-4 mb-10">
                                <span className="large-light-card-layout__labelbold">Query Description</span>
                                <span className="d-flex justify-content-center">
                                    {queryDescription ? queryDescription : ""}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pt-1 text-center text-xl-left">
                            <div className="py-4 mb-10">
                                <span className="large-light-card-layout__labelbold">Shift Details</span>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Client: </label>
                                <div className="col-5 col-xl-2 text-left">{client}</div>
                                <label className="col-6 col-xl-6 large-light-card-layout__normal">Description: </label>
                                <div className="col-5 col-xl-2 text-left">
                                    {cleaningDescription.length > maxWidthQueryDesc
                                        ? cleaningDescription.substring(0, maxWidthQueryDesc) + "..."
                                        : cleaningDescription}
                                </div>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Venue: </label>
                                <div className="col-5 col-xl-2 text-left">{venueName}</div>
                                <label className="col-6 col-xl-6 large-light-card-layout__normal">
                                    Authorised by:{" "}
                                </label>
                                <div className="col-5 col-xl-1 text-left">{requestApprover}</div>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Number: </label>
                                <div className="col-5 col-xl-2 text-left">{houseNumber}</div>
                                <label className="col-6 col-xl-6 large-light-card-layout__normal">Total: </label>
                                <div className="col-5 col-xl-1 text-left">{providerPayAmountLocal}</div>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Provider: </label>
                                <div className="col-5 col-xl-2 text-left">{provider}</div>
                                <label className="col-6 col-xl-6 large-light-card-layout__normal">Billed: </label>
                                <div className="col-5 col-xl-2 text-left">{billed ? billed : "N/A"}</div>
                            </div>
                            <div className="row mb-xl-1">
                                <label className="col-6 col-xl-2 large-light-card-layout__normal">Shift Date: </label>
                                <div className="col-5 col-xl-4 text-left">
                                    {dateFrom} - {dateTo}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pt-4 text-center text-xl-left">
                            <div className="divider--top py-4 mb-10">
                                <span className="large-light-card-layout__labelbold">Resolution Reason</span>
                                <span className="d-flex justify-content-center">
                                    {resolutionReason ? resolutionReason : "Not yet resolved"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row mt-3 justify-content-center">
                            <div className="large-light-card-layout__controls">
                                <Link
                                    className="btn--link btn btn-primary btn--full-width"
                                    onClick={() => this.viewSimpleShiftsForService(ServiceType.ContractCleaning)}
                                    to={{
                                        pathname: "/simpleShifts",
                                        state: {
                                            id: this.props.querycard.billableItemId,
                                        },
                                    }}
                                >
                                    View Related shift
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {isEventUk ? (
                    <div className="row">
                        <div className="col-12">
                            <div className="pt-4 text-center text-xl-left">
                                <div className="divider--top py-4 mb-10">
                                    <span className="large-light-card-layout__labelbold">Event UK Only</span>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">
                                        Link to Dynamics:{" "}
                                    </label>
                                    <div className="col-5 col-xl-2 text-left">
                                        <a href={d365LinkID} target="_blank">
                                            {d365LinkID}
                                        </a>
                                    </div>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">
                                        Linked Id:{" "}
                                    </label>
                                    <div className="col-5 col-xl-2 text-left">{linkedId}</div>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">Priority: </label>
                                    <div className="col-5 col-xl-2 text-left">{priority}</div>
                                </div>
                                <div className="row mb-xl-1">
                                    <label className="col-6 col-xl-2 large-light-card-layout__normal">
                                        Internal Comments:{" "}
                                    </label>
                                    <div className="col-5 col-xl-2 text-left">{comments}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}
export default ContractCleaningCardComponent;
