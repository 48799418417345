import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (event: any) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <div onChange={changeLanguage}>
            <input type="radio" value="enGB" name="language" defaultChecked /> English (GB)
            <input type="radio" value="enUS" name="language" /> English (US)
            <input type="radio" value="fr" name="language" /> French
            <input type="radio" value="de" name="language" /> German
        </div>
    );
};

export default LanguageSelector;
