import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, DateBox, CheckBox } from "devextreme-react";
import LookupService from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import { DateBoxOnValueChangedEvent, CheckBoxOnValueChanged } from "../../types/DevExtremeTypes";
import CostReportService from "../../services/CostReportService";
import CostReportUtils from "../CostReports/CostReportUtils";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { PeriodMatrixTableLookup } from "../Billing/BillingFormUtils";
import DayMonthWeekPanel from "../../DayMonthWeekPanel/DayMonthWeekPanel";
import { GridTypes } from "../../services/SimpleGridService";

interface ManageShiftsDatePanelProps extends WithTranslation {
    onApplyButtonClick: (dateFrom: string, dateTo: string, isShowUnbilledItems: boolean, isUnPaidItems: boolean,isUnconfirmedItems: boolean,isUnapprovedItems :boolean,onUpdate?: boolean) => void;
    clientId: string;
    startDate: string;
    endDate: string;
    filterText: string;
    pointOfNavigation: string;
    isAddShiftClicked?:boolean;
}

interface ManageShiftsDatePanelState {
    clientId: string;
    startDate: string;
    endDate: string;
    minDateValue: NullableDate;
    maxDateValue: NullableDate;
    dateList: PeriodMatrixTableLookup[];
    isUnbilledItems: boolean;
    isUnPaidItems: boolean;
    isUnconfirmedItems: boolean;
    isUnapprovedItems:boolean;
    onUpdate: boolean;    
}

class ManageShiftsDatePanel extends Component<ManageShiftsDatePanelProps> {
    lookupService: LookupService;
    state: ManageShiftsDatePanelState;
    sharedUtils: sharedUtils;
    Service: CostReportService;
    utils: CostReportUtils;
    constructor(props: ManageShiftsDatePanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.sharedUtils = new sharedUtils();
        this.utils = new CostReportUtils();
        this.state = {
            clientId: "",
            startDate: this.props.startDate ? this.props.startDate : "",
            endDate: this.props.endDate ? this.props.endDate : "",
            minDateValue: this.props.startDate ? this.sharedUtils.convertStringToDate(this.props.startDate) : null,
            maxDateValue: this.props.endDate ? this.sharedUtils.convertStringToDate(this.props.endDate) : null,
            dateList: [],
            isUnbilledItems: false,
            isUnPaidItems: false,
            isUnconfirmedItems: false,
            isUnapprovedItems:false,
            onUpdate: false,            
        };
    }

    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };    

    handleChangeUnbilledItems = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isUnbilledItems: dxValueChange.value,
        });
        if (dxValueChange.value) {
            this.setState({
                startDate: "",
                endDate: "",
                minDateValue: undefined,
                maxDateValue: undefined,
                isUnPaidItems: false,
                isUnconfirmedItems: false,
                isUnapprovedItems:false
            });
        }
        // if(dxValueChange.value == false && this.state.isUnPaidItems == false && this.state.isUnconfirmedItems == false && this.state.isUnapprovedItems == false){
        //     let minValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        //     let maxValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        //     this.setState({
        //         minDateValue: minValue,
        //         maxDateValue: maxValue,
        //     });
        // }
        if (dxValueChange && dxValueChange.element) {
            dxValueChange.element.onkeydown = (event: any) => {
                if (event.key && event.key === 'Enter') {
                    this.onApply("","");
                }
            }
        }
    }
    handleChangeUnPaidItems = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isUnPaidItems: dxValueChange.value,
        });
        if (dxValueChange.value) {
            this.setState({
                startDate: "",
                endDate: "",
                minDateValue: undefined,
                maxDateValue: undefined,
                isUnbilledItems: false,
                isUnconfirmedItems: false,
                isUnapprovedItems:false
            });
        }
        // if(dxValueChange.value == false && this.state.isUnbilledItems == false && this.state.isUnconfirmedItems == false && this.state.isUnapprovedItems == false){
        //     let minValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        //     let maxValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        //     this.setState({
        //         minDateValue: minValue,
        //         maxDateValue: maxValue,
        //     });
        // }
        if (dxValueChange && dxValueChange.element) {
            dxValueChange.element.onkeydown = (event: any) => {
                if (event.key && event.key === 'Enter') {
                    this.onApply("","");
                }
            }
        }
    }

    handleChangeUnconfirmedItems = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isUnconfirmedItems: dxValueChange.value,
        });
        if (dxValueChange.value) {
            this.setState({
                startDate: "",
                endDate: "",
                minDateValue: undefined,
                maxDateValue: undefined,
                isUnbilledItems: false,
                isUnPaidItems: false,
                isUnapprovedItems:false
            });
        }
        // if(dxValueChange.value == false && this.state.isUnbilledItems == false && this.state.isUnPaidItems == false && this.state.isUnapprovedItems == false){
        //     let minValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        //     let maxValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        //     this.setState({
        //         minDateValue: minValue,
        //         maxDateValue: maxValue,
        //     });
        // }
        if (dxValueChange && dxValueChange.element) {
            dxValueChange.element.onkeydown = (event: any) => {
                if (event.key && event.key === 'Enter') {
                    this.onApply("","");
                }
            }
        }
    }
    
    handleChangeUnapprovedItems = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isUnapprovedItems: dxValueChange.value,
        });
        if (dxValueChange.value) {
            this.setState({
                startDate: "",
                endDate: "",
                minDateValue: undefined,
                maxDateValue: undefined,
                isUnbilledItems: false,
                isUnPaidItems: false,
                isUnconfirmedItems: false,
            });
        }
        // if(dxValueChange.value == false && this.state.isUnbilledItems == false && this.state.isUnPaidItems == false && this.state.isUnconfirmedItems == false){
        //     let minValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        //     let maxValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        //     this.setState({
        //         minDateValue: minValue,
        //         maxDateValue: maxValue,
        //     });
        // }
        if (dxValueChange && dxValueChange.element) {
            dxValueChange.element.onkeydown = (event: any) => {
                if (event.key && event.key === 'Enter') {
                    this.onApply("","");
                }
            }
        }
    }

    handleChangeStartDateSelect = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.value) {
            this.setState({
                minDateValue: dxValueChange.value,
                startDate: this.sharedUtils.convertDateToString(dxValueChange.value),
                isUnbilledItems: false,
                isUnPaidItems: false,
                isUnconfirmedItems: false,
                isUnapprovedItems:false
            });
        }
    };

    handleChangeEndDateSelect = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.value) {
            this.setState({
                maxDateValue: dxValueChange.value,
                endDate: this.sharedUtils.convertDateToString(dxValueChange.value),
            });
        }
    };

    //Fire the parent component's callback on an Apply click.
    onApply = (dateFrom: string, dateTo: string) => {
        const { startDate, endDate, isUnbilledItems,isUnPaidItems,isUnconfirmedItems,isUnapprovedItems} = this.state;
        if (!dateFrom && !dateTo) {
            this.props.onApplyButtonClick(startDate, endDate, isUnbilledItems,isUnPaidItems,isUnconfirmedItems,isUnapprovedItems);
        } else {
            this.props.onApplyButtonClick(dateFrom, dateTo, isUnbilledItems,isUnPaidItems,isUnconfirmedItems,isUnapprovedItems,true);
        }
    };

    onSubmitApply =(e:any)=>{
        e.preventDefault();
        this.onApply("","")
    }
    onEnterApply=()=>{
        this.onApply("","");
    }

    ondateTodateFromSelection = (dateFromValue: string, dateToValue: string) => {
        this.setState({
            minDateValue: this.sharedUtils.convertStringToDate(dateFromValue),
            maxDateValue: this.sharedUtils.convertStringToDate(dateToValue),
            startDate: dateFromValue,
            endDate: dateToValue,
            isUnbilledItems: false,
            isUnPaidItems: false,
            isUnconfirmedItems: false,
            isUnapprovedItems:false
        },()=>{
            this.onApply(dateFromValue, dateToValue);
        });
        // this.onApply(dateFromValue, dateToValue);
    };

    componentDidMount(): void {
        if(this.props.pointOfNavigation == GridTypes.addShiftBillingGrid){
            this.setState({isUnbilledItems:true});
        }
    }

    //The component will be updated when the filterText changes to default the date to the current week.
    componentDidUpdate = (prevProps: ManageShiftsDatePanelProps) => {
        if (this.props.filterText != prevProps.filterText) {
            this.setState({
                startDate: this.props.startDate ? this.props.startDate : "",
                endDate: this.props.endDate ? this.props.endDate : "",
                minDateValue: this.props.startDate ? this.sharedUtils.convertStringToDate(this.props.startDate) : null,
                maxDateValue: this.props.endDate ? this.sharedUtils.convertStringToDate(this.props.endDate) : null,
            });
        }
        if(this.props.pointOfNavigation == GridTypes.addShiftBillingGrid){
            if(this.props.isAddShiftClicked !== prevProps.isAddShiftClicked && this.props.isAddShiftClicked == true){
                this.setState({isUnbilledItems:true},()=>{
                    this.onApply("","");
                });
            }
            
        }
    };

    render() {
        return (
            <div>
                <DayMonthWeekPanel
                    ondateTodateFromSelection={this.ondateTodateFromSelection}
                    dateToValue={this.state.maxDateValue ? this.state.maxDateValue : null}
                    dateFromValue={this.state.minDateValue ? this.state.minDateValue : null}
                    serviceTypeId={""}
                    periodLookUpItems={this.state.dateList}
                    clientId={this.state.clientId}
                    pointOfNavigation={this.props.pointOfNavigation}
                    billableItemId={""}
                />
                <form onSubmit={this.onSubmitApply}>
                <div className="row mt-4">
                    <div className="col-10">
                        <h4 className="sidebar__heading font-weight-bold">Filter shifts</h4>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Date from</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeStartDateSelect}
                            value={this.state.minDateValue ? this.state.minDateValue : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                            onEnterKey={this.onEnterApply}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Date to</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeEndDateSelect}
                            value={this.state.maxDateValue ? this.state.maxDateValue : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                            onEnterKey={this.onEnterApply}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <CheckBox
                            value={this.state.isUnbilledItems}
                            onValueChanged={this.handleChangeUnbilledItems}
                        />
                        &nbsp; &nbsp;
                        Only Show Unbilled Items
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <CheckBox
                            value={this.state.isUnPaidItems}
                            onValueChanged={this.handleChangeUnPaidItems}
                        />
                        &nbsp; &nbsp;
                        Only Show UnPaid Items
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <CheckBox
                            value={this.state.isUnconfirmedItems}
                            onValueChanged={this.handleChangeUnconfirmedItems}
                        />
                        &nbsp; &nbsp;
                        Only Show Unconfirmed Items
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <CheckBox
                            value={this.state.isUnapprovedItems}
                            onValueChanged={this.handleChangeUnapprovedItems}
                        />
                        &nbsp; &nbsp;
                        Only Show Unapproved Items
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        {/* <Button
                            className="btn btn-primary btn--large"
                            disabled={false}
                            onClick={() => this.onApply("", "")}
                        >
                            Apply
                        </Button> */}
                        <button type='submit'
                            className="btn btn-primary btn--large"
                            disabled={false}>
                            Apply
                        </button>
                    </div>
                </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(ManageShiftsDatePanel);
