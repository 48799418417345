import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import ProviderProfileService from "../../services/ProviderProfileService";
import providerProfileUtils from "./ProviderProfileUtils";
import { CheckBox, LoadIndicator, RadioGroup, SelectBox, TextBox } from "devextreme-react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    CheckBoxOnValueChanged,
    RadioButtonOnSelectionChanged,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import { ArtistPageFormData } from "../Artist-Profile/ArtistProfileUtilitise";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import UserService, { RoleGroupNames } from "../../services/UserService";

interface ProviderProfileComponentProps extends WithTranslation {
    providerId: string;
    refreshSignal: boolean;
}
// State
interface ProviderProfileComponentState {
    editViewDataItem: ArtistPageFormData;
    businessTypeLU: LookupTypeItem[];
    showLoadIndicator: boolean;
    errorMessage: [];
    IsShowSuccess: boolean;
    IsShowError: boolean;
    isTermsAgreedDateTimeChanged: boolean;
}

class ProviderProfileComponent extends React.Component<ProviderProfileComponentProps> {
    utils: providerProfileUtils;
    state: ProviderProfileComponentState;
    service: ProviderProfileService;
    lookupService: lookupService;
    taxApplicable: LookupTypeItem[];
    constructor(props: ProviderProfileComponentProps) {
        super(props);
        this.service = new ProviderProfileService();
        this.utils = new providerProfileUtils();
        this.lookupService = new lookupService();
        this.taxApplicable = this.lookupService.getDecisionTypes();
        var convertedProviderProfileInformation = this.utils.initializeProviderProfileItem("");
        this.state = {
            editViewDataItem: convertedProviderProfileInformation,
            businessTypeLU: [],
            showLoadIndicator: false,
            errorMessage: [],
            IsShowSuccess: false,
            IsShowError: false,
            isTermsAgreedDateTimeChanged: false,
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        this.businessTypeDropDown();
    }

    componentDidUpdate(prevProps: ProviderProfileComponentProps) {
        if (this.props.providerId != prevProps.providerId || this.props.refreshSignal != prevProps.refreshSignal) {
            this.getDataSource();
        }
    }

    getDataSource = () => {
        this.setState({
            showLoadIndicator: true,
            errorMessage: [],
            IsShowSuccess: false
        });
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            this.service.getProviderPageData().then(this.handleSuccessProviderViewPage).catch(this.handleStandardError);
        } else if (
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
        ) {
            this.service
                .getProviderPageDataById(parseInt(this.props.providerId))
                .then(this.handleSuccessProviderViewPage)
                .catch(this.handleStandardError);
        }
    };

    handleSuccessProviderViewPage = (response: AxiosResponse<any>) => {
        this.setState({
            showLoadIndicator: false,
        });
        // Convert to proper data types
        var viewDataSource = this.utils.convertProviderProfileResponeToFormData(response.data.data);
        // In case of Amend status will be pre set to "Approve"
        this.setState({
            editViewDataItem: viewDataSource,
        });
    };

    handleStandardError = (error: any) => {
        this.setState({
            showLoadIndicator: false,
        });
        var respMessage: string = "Provider Page View load/ Post failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    businessTypeDropDown = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.lookuptype)
            .then(this.handleSuccessLookUpType)
            .catch(this.handleError);
    };

    handleSuccessLookUpType = (response: AxiosResponse<any>) => {
        this.setState({
            businessTypeLU: response.data.data,
        });
        this.getDataSource();
    };

    handleError = (error: any) => {
        var respMessage: string = "Get business type service failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    //Trading Information

    handleChangeBusinessName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                legalName: dxValue.value,
            },
        });
    };
    handleChangeBusinessType = (dxValue: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                businessEntityLegalStatusLookUpId: dxValue.value,
            },
        });
    };
    handleChangeTradingName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                tradingName: dxValue.value,
            },
        });
    };
    handleChangeTaxApplicable = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                taxApplicable: dxValue.value,
            },
        });
    };
    handleChangeVATNo = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                taxRegistrationNumber: dxValue.value,
            },
        });
    };
    handleChangeCompanyNo = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                companyNumber: dxValue.value,
            },
        });
    };
    handleChangeTradingEmail = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                email: dxValue.value,
            },
        });
    };
    handleChangeTradingOfficePhone = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                officePhone: dxValue.value,
            },
        });
    };
    handleChangeRegisteredAddressLine1 = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                addressStreet1: dxValue.value,
            },
        });
    };
    handleChangeRegisteredAddressLine2 = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                addressStreet2: dxValue.value,
            },
        });
    };
    handleChangeRegisteredAddressLine3 = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                addressStreet3: dxValue.value,
            },
        });
    };
    handleChangeTownCity = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                city: dxValue.value,
            },
        });
    };
    handleChangeCounty = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                country: dxValue.value,
            },
        });
    };
    handleChangePostcode = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                postCode: dxValue.value,
            },
        });
    };

    // Primary Contact Details

    handleChangeContactName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                providerSiteManagerInformation: {
                    ...this.state.editViewDataItem.providerSiteManagerInformation,
                    name: dxValue.value,
                },
            },
        });
    };
    handleChangeContactEmail = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                providerSiteManagerInformation: {
                    ...this.state.editViewDataItem.providerSiteManagerInformation,
                    email: dxValue.value,
                },
            },
        });
    };
    handleChangeContactOfficePhone = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                providerSiteManagerInformation: {
                    ...this.state.editViewDataItem.providerSiteManagerInformation,
                    otherTelephone: dxValue.value,
                },
            },
        });
    };
    handleChangeContactMobile = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                providerSiteManagerInformation: {
                    ...this.state.editViewDataItem.providerSiteManagerInformation,
                    mobileTelephone: dxValue.value,
                },
            },
        });
    };

    //Bank Details

    handleChangeBankName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    bankName: dxValue.value,
                },
            },
        });
    };

    handleChangeBranchName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    branchName: dxValue.value,
                },
            },
        });
    };

    handleChangeSortCode = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    sortCode: dxValue.value,
                },
            },
        });
    };

    handleChangeAccountNumber = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    accountNumber: dxValue.value,
                },
            },
        });
    };

    handleChangeNameOnAccount = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    nameOnAccount: dxValue.value,
                },
            },
        });
    };

    handleChangeReference = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    buildingSociety: dxValue.value,
                },
            },
        });
    };

    //terms and condition
    handleChangeTermsAndConditionCheck = (dxValue: CheckBoxOnValueChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                termsAgreed: dxValue.value,
            },
            errorMessage: [],
        });
        if (this.state.editViewDataItem.termsAgreed == true) {
            var timeStamp = new Date();
            var date = timeStamp.getUTCDate();
            var month = (timeStamp.getUTCMonth() + 1).toString();
            var actualMonth = "";
            if (month.length == 1) {
                actualMonth = "0" + month;
            } else {
                actualMonth = month;
            }
            var year = timeStamp.getUTCFullYear();
            var hours = timeStamp.getUTCHours();
            var minutes = timeStamp.getUTCMinutes().toString();
            var actualMinutes = "";
            if (minutes.length == 1) {
                actualMinutes = "0" + minutes;
            } else {
                actualMinutes = minutes;
            }
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    termsAgreedDateTime:
                        date + "/" + actualMonth + "/" + year + " " + "at" + " " + hours + ":" + actualMinutes,
                },
                isTermsAgreedDateTimeChanged: true,
            });
        }
    };

    onClickSave = () => {
        this.setState({
            showLoadIndicator: true,
        });
        var providerProfileSubmitRequest = this.utils.convertFormDataToProviderProfileRequest(
            this.state.editViewDataItem
        );
        this.service
            .saveProfilePageData(providerProfileSubmitRequest)
            .then(this.handleSuccessOnFormSubmit)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    showLoadIndicator: false,
                    IsShowError: true,
                    IsShowSuccess: false,
                });
                this.topFunction();
            })
            .finally(() => {
                this.setState({
                    showLoadIndicator: false,
                });
            });
    };

    handleSuccessOnFormSubmit = () => {
        this.setState({
            showLoadIndicator: false,
            IsShowError: false,
            IsShowSuccess: true,
        });
        this.topFunction();
    };
    topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    render() {
        let termsAgreedDateTime = "";
        if (this.state.editViewDataItem.termsAgreedDateTime && !this.state.isTermsAgreedDateTimeChanged) {
            var parts = this.state.editViewDataItem.termsAgreedDateTime.split(" ");
            termsAgreedDateTime = parts[0] + " at " + parts[1];
        }
        return (
            <div className="card">
                {this.state.showLoadIndicator ? (
                    <LoadIndicator
                        id="button-indicator"
                        height={80}
                        width={80}
                        className="home-page__loader"
                        visible={this.state.showLoadIndicator}
                    />
                ) : (
                    <div className="card-body">
                        {this.state.IsShowError && this.state.errorMessage != null ? (
                            <span className="unscheduled-shift">
                                {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                    return (
                                        <li key={uniqueKey}>
                                            {item.columnName}: {item.errorMessage}
                                        </li>
                                    );
                                })}
                                <br></br>
                            </span>
                        ) : this.state.IsShowSuccess ? (
                            <span className="text-success">
                                <b>Provider profile data saved successfully.</b>
                                <br></br>
                                <br></br>
                            </span>
                        ) : null}
                        <div className="row">
                            <div className="col-12">
                                <section>
                                    <h5>
                                        <b>Trading Information</b>
                                    </h5>
                                    <div>Business Name</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeBusinessName}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.legalName}
                                            placeholder="Legal name of business..."
                                        />
                                    </div>
                                    <div className="mt-3">Business Type</div>
                                    <div>
                                        <SelectBox
                                            dataSource={this.state.businessTypeLU}
                                            width={"40%"}
                                            disabled={true}
                                            valueExpr="id"
                                            displayExpr="value"
                                            onValueChanged={this.handleChangeBusinessType}
                                            value={this.state.editViewDataItem.businessEntityStatusId}
                                            //placeholder={businessTypeText}
                                        />
                                    </div>
                                    <div className="mt-3">Trading Name</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeTradingName}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.tradingName}
                                            placeholder="Required if trading under a different name"
                                        />
                                    </div>
                                    <div className="mt-3">Are you VAT registered?</div>
                                    <div>
                                        <RadioGroup
                                            items={this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                            displayExpr="value"
                                            valueExpr="value"
                                            value={
                                                this.state.editViewDataItem.taxApplicable == "Yes" ||
                                                this.state.editViewDataItem.taxApplicable == "True"
                                                    ? "Yes"
                                                    : "No"
                                            }
                                            onValueChanged={this.handleChangeTaxApplicable}
                                            layout="horizontal"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="mt-3">VAT Number</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeVATNo}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.taxRegistrationNumber}
                                            //placeholder={VATNumberText}
                                        />
                                    </div>
                                    <div className="mt-3">Company Number</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeCompanyNo}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.companyNumber}
                                            // placeholder={companyNumberText}
                                        />
                                    </div>
                                    <div className="mt-3">Email</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeTradingEmail}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.email}
                                            // placeholder={companyNumberText}
                                        />
                                    </div>
                                    <div className="mt-3">Office Phone</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeTradingOfficePhone}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.officePhone}
                                            // placeholder={companyNumberText}
                                        />
                                    </div>
                                    <br></br>
                                    <b>Registered Address</b>
                                    <div>Address Line 1</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeRegisteredAddressLine1}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.addressStreet1}
                                            //placeholder={registeredAddressLine1Text}
                                        />
                                    </div>
                                    <div className="mt-3">Address Line 2</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeRegisteredAddressLine2}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.addressStreet2}
                                            //placeholder={registeredAddressLine2Text}
                                        />
                                    </div>
                                    <div className="mt-3">Address Line 3</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeRegisteredAddressLine3}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.addressStreet3}
                                            //placeholder={registeredAddressLine2Text}
                                        />
                                    </div>
                                    <div className="mt-3">Town/City</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeTownCity}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.city}
                                            //placeholder={registeredAddressTown_CityText}
                                        />
                                    </div>
                                    <div className="mt-3">County</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeCounty}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.state}
                                            //placeholder={registeredAddressCountyText}
                                        />
                                    </div>
                                    <div className="mt-3">Postcode</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangePostcode}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.postCode}
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                </section>
                                <section className="provider-profile__edit-section">
                                    <h5>
                                        <b>Primary Contact Details</b>
                                    </h5>
                                    <div className="mt-3">Name</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeContactName}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.providerSiteManagerInformation
                                                    ? this.state.editViewDataItem.providerSiteManagerInformation.name
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                    <div className="mt-3">Email</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeContactEmail}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.providerSiteManagerInformation
                                                    ? this.state.editViewDataItem.providerSiteManagerInformation.email
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                    <div className="mt-3">Office Phone</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeContactOfficePhone}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.providerSiteManagerInformation
                                                    ? this.state.editViewDataItem.providerSiteManagerInformation
                                                          .otherTelephone
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                    <div className="mt-3">Mobile</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeContactMobile}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.providerSiteManagerInformation
                                                    ? this.state.editViewDataItem.providerSiteManagerInformation
                                                          .mobileTelephone
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                </section>
                                <section className="provider-profile__edit-section">
                                    <h5>
                                        <b>Bank Details</b>
                                    </h5>
                                    <div className="mt-3">Name of Bank</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeBankName}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.bankInformation
                                                    ? this.state.editViewDataItem.bankInformation.bankName
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                    <div className="mt-3">Branch Name</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeBranchName}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.bankInformation
                                                    ? this.state.editViewDataItem.bankInformation.branchName
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                    <div className="mt-3">Sort Code</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeSortCode}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.bankInformation
                                                    ? this.state.editViewDataItem.bankInformation.sortCode
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                    <div className="mt-3">Account Number</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeAccountNumber}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.bankInformation
                                                    ? this.state.editViewDataItem.bankInformation.accountNumber
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                    <div className="mt-3">Name on Account</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeNameOnAccount}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.bankInformation
                                                    ? this.state.editViewDataItem.bankInformation.nameOnAccount
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                    <div className="mt-3">Reference</div>
                                    <div>
                                        <TextBox
                                            width={"40%"}
                                            disabled={true}
                                            onValueChanged={this.handleChangeReference}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.state.editViewDataItem.bankInformation
                                                    ? this.state.editViewDataItem.bankInformation.buildingSociety
                                                    : ""
                                            }
                                            //placeholder={registeredAddressPostcodeText}
                                        />
                                    </div>
                                </section>
                                <section className="provider-profile__edit-section">
                                    <h5>
                                        <b>Attachments</b>
                                    </h5>
                                    <div className="incidents-document-display-div mt-4 row">
                                        {this.state.editViewDataItem.businessEntityDocument.length > 0
                                            ? this.state.editViewDataItem.businessEntityDocument.map(
                                                  (document: any, uniqueKey: number) => {
                                                      return (
                                                          <div className="col mb-2">
                                                              <span className="mr-2 ml-3">
                                                                  <FontAwesomeIcon
                                                                      icon={faFileAlt}
                                                                      className="text-dark"
                                                                  />
                                                              </span>
                                                              <a
                                                                  className="text-dark"
                                                                  href={document.displayLink}
                                                                  download={document.name}
                                                              >
                                                                  {document.name}&nbsp;
                                                              </a>
                                                          </div>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </div>
                                </section>
                                <section className="provider-profile__edit-section">
                                    <h5>
                                        <b>Terms and Conditions</b>
                                    </h5>
                                    <div>
                                        <CheckBox
                                            value={this.state.editViewDataItem.termsAgreed}
                                            onValueChanged={this.handleChangeTermsAndConditionCheck}
                                        />
                                        &nbsp; &nbsp;
                                        {
                                            "I/We have confirmed you have read and accepted Event Uk's terms and conditions."
                                        }
                                    </div>
                                    <div className="mt-3">
                                        <b>
                                            Accepted on{" "}
                                            {this.state.isTermsAgreedDateTimeChanged
                                                ? this.state.editViewDataItem.termsAgreedDateTime
                                                : termsAgreedDateTime}
                                        </b>
                                    </div>
                                </section>
                                <section>
                                    <div className="row mt-5">
                                        <div className="col-12 col-lg-2 mb-3 mr-auto">
                                            <button
                                                className="btn btn-primary btn--large"
                                                type="button"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.onClickSave();
                                                }}
                                                //disabled={this.state.isDeactivateButtonDisable || isNotActiveArtist}
                                            >
                                                <span className="btn__icon"></span>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(ProviderProfileComponent);
