import React, { MouseEvent, Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import AddEditPaymentForm from "../Payments/AddEditPaymentForm";
import LightModePageTemplate from "./Templates/LightModeTemplate";

interface AddEditPaymentFormPageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

interface AddEditPaymentFormPageState {}

class AddEditPaymentFormPage extends Component<AddEditPaymentFormPageProps> {
    //Initialize the component's state.
    state: AddEditPaymentFormPageState;

    constructor(props: AddEditPaymentFormPageProps) {
        super(props);
        this.state = {
            //Define the page state here if any.
        };
    }

    render() {
        return (
            <LightModePageTemplate>
                <section className="container page-content">
                    <header className="grid-info mb-3"></header>
                    <div className="row">
                        <div className="col-md">
                            <AddEditPaymentForm id={1} history={this.props.history} location={this.props.location} />
                        </div>
                        <div />
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(AddEditPaymentFormPage);
