import React from "react";
import { LoadIndicator } from "devextreme-react";
import { WithTranslation } from "react-i18next";

interface MaskedStatusForEarlyPayProps extends WithTranslation {
    data: any;
}

interface MaskedStatusForEarlyPayState {
    earlyPayStatus: string;
}

class MaskedStatusForEarlyPay extends React.Component<MaskedStatusForEarlyPayProps> {
    state: MaskedStatusForEarlyPayState;
    constructor(props: MaskedStatusForEarlyPayProps) {
        super(props);
        this.state = {
            earlyPayStatus: this.props.data.data.earlyPay ? "Yes" : "No",
        };
    }

    //This is needed here to check for some conditions when the component mounts.
    componentDidMount() {}
    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <span className="status-message-color">{this.state.earlyPayStatus}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default MaskedStatusForEarlyPay;
