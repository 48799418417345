import React from "react";
import DataGrid, { Pager, Paging, Export, FilterRow, Column, Scrolling } from "devextreme-react/data-grid";
import { DataGridOnInitializedEvent } from "../../types/DevExtremeTypes";
import BillableItemHeaderService, { SIAChildGridRowItem } from "../../services/BillableItemHeaderService";
import gridUtils from "./GridUtilities";
import { AxiosResponse } from "axios";
import { SearchPanel } from "devextreme-react/tree-list";
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";

// Props
//  - refreshSignal will indicate that a grid refresh is required.
//  - gridVisibilitySignal is going to signal when the grid will be shown.
interface SIAUploadedFileContentsGridProps {
    isChildGridVisible: boolean;
    IsShowColumn: string;
    headerId: string;
}

// State
interface SIAUploadedFileContentsGridState {
    SIAChildGridDataSource: SIAChildGridRowItem[];
}

// Component - displays both the header and child grids
class SIAUploadedFileContentsGrid extends React.Component<SIAUploadedFileContentsGridProps> {
    state: SIAUploadedFileContentsGridState;
    gridUtils: gridUtils;
    dataGridElement: any;
    billableItemUploadService: BillableItemHeaderService;

    constructor(props: SIAUploadedFileContentsGridProps) {
        super(props);
        this.state = {
            SIAChildGridDataSource: [],
        };

        this.gridUtils = new gridUtils();
        this.billableItemUploadService = new BillableItemHeaderService();
        this.dataGridElement = undefined;
        this.handleFileUploadOnInitialize = this.handleFileUploadOnInitialize.bind(this);
        this.updateChildGridDataSource = this.updateChildGridDataSource.bind(this);
    }

    // Initialize state from server
    componentDidMount() {
        this.updateChildGridDataSource(this.props.headerId);
    }

    updateChildGridDataSource = (headerId: string) => {
        if (headerId) {
            this.billableItemUploadService
                .getChildGridDataForSIA(headerId)
                .then(this.handleSIAFileSuccess)
                .catch(this.handleFailure);
        }
    };

    handleSIAFileSuccess = (response: AxiosResponse<any>) => {
        this.setState({ SIAChildGridDataSource: response.data.data });
    };

    handleFailure = (error: any) => {
        var respMessage: string = "getChildGridDataForSIA service failed with response: " + JSON.stringify(error);

        if (!this.billableItemUploadService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    componentDidUpdate(prevProps: SIAUploadedFileContentsGridProps) {
        if (this.props.headerId !== prevProps.headerId) {
            // refresh the grid
            this.updateChildGridDataSource(this.props.headerId);
        }
    }

    // Set the file uploader component
    handleFileUploadOnInitialize(e: DataGridOnInitializedEvent) {
        this.dataGridElement = e.component;
    }
    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    render() {
        return (
            <div>
                <div className="childGrid mt-5">
                    <h4 className="font-weight-bold mb-3" hidden={!this.props.isChildGridVisible}>
                        View Selected File Contents
                    </h4>
                    {
                        <DataGrid
                            dataSource={this.state.SIAChildGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            columnResizingMode={"widget"}
                            allowColumnResizing={true}
                            onExporting={this.onExporting}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <Paging defaultPageSize={1} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[1, 5, 10, 20]} showInfo={true} />
                            <Column dataField="idType" caption="ID Type" />
                            <Column dataField="idValue" caption="ID Value" />
                            <Column dataField="idName" caption="ID Name" />
                            <Column dataField="expiryDate" caption="Expiry Date" />
                            <Column dataField="asAtDate" caption="AsAt Date" />
                            <Column dataField="idCheckUploadStatus" caption="Status" />
                            <Column dataField="lastUpdatedOn" caption="Last Updated on" />
                            <Column dataField="lastUpdatedBy" caption="Last Updated By" />
                            <Column dataField="errorMessage" caption="Error Message" />
                            <FilterRow visible={true} applyFilter="auto" />
                            <Scrolling mode="standard" useNative={true} scrollByThumb={true} />
                        </DataGrid>
                    }
                </div>
            </div>
        );
    }
}

export default SIAUploadedFileContentsGrid;
