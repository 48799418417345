import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { DateBox, LoadPanel, NumberBox, SelectBox, TagBox, TextArea, TextBox, Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/data-grid";
import BillingService, { invoiceServices, billingCycleStatus } from "../../services/BillingService";
import BillingFormUtils, { ActionType, BillingFormData, BillingStatus } from "../Billing/BillingFormUtils";
import sharedUtils from "../grid/sharedUtilities";
import {
    DateBoxOnValueChangedEvent,
    NumberBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TagBoxOnValueChangeEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";

// Interfaces and Constants
type NullableDate = Date | null | undefined;
// Props
interface BillingPrepareComponentProps {
    location: any;
    onReceiveData: (
        invoiceId: string,
        formData: BillingFormData,
        status: BillingStatus,
        isRestartButtonDisable: boolean,
        isShowIncludedShift: boolean,
        isSuccessRestart: boolean,
        statusId?: string,
        forPreviewGrid?: boolean,
        isPreviewClicked?: boolean
    ) => void;
    saveBillingData: (invoiceId: string, formData: BillingFormData) => void;
    syncDateFields: (date: NullableDate, type: string) => void;
    syncAmountFields: (data: number) => void;
    calculationsObject: any;
    isClickedLockedShift: boolean;
    isClickedRestart: boolean;
    status: BillingStatus;
    statusId: string;
    invoiceSentDate: NullableDate;
    invoicePaidDate: NullableDate;
    invoiceAmount: number;
    refreshPage: boolean;
    vatConfig: string;
    pageType?: string;
    previousPage?: any;
    isError?: boolean;
    error?: [];
}

export interface LookUpTypeItemForServiceType {
    serviceTypeLookupId: number;
    value: string;
    parentMappingId: string;
}

interface DateRangeLookUp {
    id: string;
    value: NullableDate;
    parentMappingId: string;
}
// State
interface BillingPrepareComponentState {
    editBillingFormData: BillingFormData;
    periodMatrixData: any;
    periodLookUp: LookupTypeItem[];
    weekLookUp: LookupTypeItem[];
    dateToLookUp: DateRangeLookUp[];
    dateFromLookUp: DateRangeLookUp[];
    IsShowSuccess: boolean;
    isErrorMessageCriteriaOne: boolean;
    isErrorMessageCriteriaTwo: boolean;
    isErrorMessageCriteriaThree: boolean;
    clientLookUp: LookupTypeItem[];
    restartIndicator: boolean;
    IsShowErrorFromAPI: boolean;
    errorMessage: [];
    disableRestartButton: boolean;
    nextStepStatusId: number;
    nextStepStatusValue: string;
    statusLookUp: LookupTypeItem[];
    loadPanelVisible: boolean;
    dateToChanged: boolean;
    dateFromChanged: boolean;
    IsClientChanged: boolean;
    isStatusChanged: boolean;
    serviceTypeList: [];
    serviceTypeLookUp: LookUpTypeItemForServiceType[];
    prepareButtonColour: string;
    isDisablePrepareButton: boolean;
    isPrepareClicked: boolean;
    isSuccessRestart: boolean;
    billingStatusForRestart: BillingStatus;
    isDisableService: boolean;
}
/**
 * Component - Spline chart to display budget and spend
 */
class BillingPrepareComponent extends React.Component<BillingPrepareComponentProps> {
    state: BillingPrepareComponentState;
    lookupService: LookupService;
    billingService: BillingService;
    utils: BillingFormUtils;
    sharedUtils: sharedUtils;
    accrualTypesDataSource: LookupTypeItem[];
    groupAccrualTypesDataSource: LookupTypeItem[];
    constructor(props: BillingPrepareComponentProps) {
        super(props);
        this.lookupService = new LookupService();
        this.billingService = new BillingService();
        this.utils = new BillingFormUtils();
        this.sharedUtils = new sharedUtils();
        this.accrualTypesDataSource = this.lookupService.getAccrualTypes();
        this.groupAccrualTypesDataSource = this.lookupService.getGroupAccrualTypes();
        // Initialize state
        var convertedEditBillingData = this.utils.initializeBillingCycleItems("");
        this.state = {
            editBillingFormData: convertedEditBillingData,
            periodMatrixData: {},
            periodLookUp: [],
            weekLookUp: [],
            dateToLookUp: [],
            dateFromLookUp: [],
            IsShowSuccess: false,
            isErrorMessageCriteriaOne: true,
            isErrorMessageCriteriaTwo: true,
            isErrorMessageCriteriaThree: true,
            clientLookUp: [],
            restartIndicator: false,
            IsShowErrorFromAPI: false,
            errorMessage: [],
            disableRestartButton: false,
            nextStepStatusId: 0,
            nextStepStatusValue: "",
            statusLookUp: [],
            loadPanelVisible: false,
            dateToChanged: false,
            dateFromChanged: false,
            IsClientChanged: false,
            isStatusChanged: false,
            serviceTypeList: [],
            serviceTypeLookUp: [],
            prepareButtonColour: "saveCycleButtonColor",
            isDisablePrepareButton: false,
            isPrepareClicked: false,
            isSuccessRestart: false,
            billingStatusForRestart: BillingStatus.Draft,
            isDisableService: false
        };
    }

    componentDidUpdate(prevProps: BillingPrepareComponentProps) {
        if (this.props.isClickedRestart != prevProps.isClickedRestart) {
            this.onClickRestart();
            this.setState({
                billingStatusForRestart: BillingStatus.Pending,
            });
        } else if (this.props.statusId != prevProps.statusId) {
            this.setState({
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    statusId: this.props.statusId,
                },
                isStatusChanged: true,
            });
        } else if (this.props.refreshPage != prevProps.refreshPage) {
            this.setState({
                loadPanelVisible: true,
            });
            this.getEditViewDataSource();
        }
        if (this.props.invoiceSentDate != prevProps.invoiceSentDate) {
            this.setState({
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    invoiceSentDate: this.props.invoiceSentDate,
                },
            });
        }
        if (this.props.invoicePaidDate != prevProps.invoicePaidDate) {
            this.setState({
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    invoicePaidDate: this.props.invoicePaidDate,
                },
            });
        }
        if (this.props.invoiceAmount != prevProps.invoiceAmount) {
            this.setState({
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    invoiceAmount: this.props.invoiceAmount,
                },
            });
        }
    }

    setCalculationsObject = ()=>{
        let clientNet: number | undefined = 0;
        let vat: number | undefined = 0;
        let clientGross: number | undefined = 0;
        let vatConfig = parseFloat(this.props.vatConfig);
        if (this.state.isSuccessRestart) {
            clientNet = this.state.editBillingFormData.subTotal;
            vat = parseFloat((this.state.editBillingFormData.subTotal * vatConfig).toFixed(2));
            clientGross = clientNet + vat;
        }        
        else if (this.props.calculationsObject && this.props.calculationsObject.subTotal) {
            clientNet = parseFloat(this.props.calculationsObject.subTotal);
            vat = parseFloat(this.props.calculationsObject.tax);
            clientGross = parseFloat(this.props.calculationsObject.total);
        }
        else {
            clientNet = undefined;
            vat = undefined;
            clientGross = undefined;
        }  
        // this.setState({
        //     editBillingFormData: {
        //         ...this.state.editBillingFormData,
        //         total: clientGross,
        //         tax:vat,
        //         subTotal:clientNet
        //     },
        // }) 

        return {clientGross,vat,clientNet};
    }


    //#region 'OnClick Event'
    onClickRestart = () => {
        this.setState({
            loadPanelVisible: true,
        });
        var billingCycleSubmitRequest = this.utils.convertFormDataToBillingCycleRequest(
            this.state.editBillingFormData,
            ActionType.Restart,
            "",
            null,
            null,
            "",
            ""
        );
        //passing object data to the server
        this.billingService
            .billingDataService(billingCycleSubmitRequest)
            .then(this.handleRestartSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null,
                    IsShowSuccess: false,
                });
                this.failureMessageVisible();
            })
            .finally(() => { });
    };

    handleRestartSuccess = () => {
        this.setState(
            (prevState: BillingPrepareComponentState) => ({
                IsShowErrorFromAPI: false,
                isSuccessRestart: true,
                isDisablePrepareButton: false,
                isDisableService: false
            }),
            () => {
                this.getEditViewDataSource();
            }
        );
    };
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        var invoiceId = sessionStorage.getItem("invoiceId");
        if (
            (this.props.location && this.props.location.state && this.props.location.state.id) ||
            this.state.editBillingFormData.invoiceId ||
            invoiceId
        ) {
            this.setState({
                loadPanelVisible: true,
            });

            this.lookupService
                .getLookupByLookupTypeIndexAll(LookupTypeIndexes.clientType)
                .then(this.handleSuccessClientLookUp)
                .catch(this.handleStandardError);
        } else {
            this.setState({
                loadPanelVisible: false,
            });
            this.lookupService
                .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                .then(this.handleSuccessClientLookUp)
                .catch(this.handleStandardError);
        }
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.periodMatrix)
            .then(this.handleSuccesPeriodMatrixLookUp)
            .catch(this.handleStandardError);

        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.invoicestatus)
            .then(this.handleSuccessStatusLookUp)
            .catch(this.handleStandardError);

        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
            .then(this.handleSuccessServiceTypeLookUp)
            .catch(this.handleStandardError);
    }

    getEditViewDataSource = () => {
        var invoiceId = sessionStorage.getItem("invoiceId");
        if (this.props.location.state.id || this.state.editBillingFormData.invoiceId || invoiceId) {
            this.billingService
                .getBillingData(this.props.location.state.id || this.state.editBillingFormData.invoiceId || invoiceId)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
        }
    };

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        // Convert to proper data types
        var viewDataSource = this.utils.convertBillingCycleResponseToFormData(response.data.data);
        this.setState(
            {
                editBillingFormData: viewDataSource,
                isDisableService: this.props.isClickedRestart ? false : true
            },
            () => {
                this.serviceTypeSet();
            }
        );
    };

    serviceTypeSet = () => {
        var status: BillingStatus;
        var isRestartDisable: boolean = false;
        if (
            this.state.editBillingFormData.statusId == billingCycleStatus.Pending ||
            this.state.billingStatusForRestart == BillingStatus.Pending
        ) {
            status = BillingStatus.Pending;
            this.setState({
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editBillingFormData.statusId == billingCycleStatus.ReadyForBilling) {
            status = BillingStatus.ReadyForBilling;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editBillingFormData.statusId == billingCycleStatus.BillChecked) {
            status = BillingStatus.BillChecked;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editBillingFormData.statusId == billingCycleStatus.BillPackRequested) {
            status = BillingStatus.BillPackRequested;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editBillingFormData.statusId == billingCycleStatus.BillPackFailed) {
            status = BillingStatus.BillPackFailed;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editBillingFormData.statusId == billingCycleStatus.BillPackReady) {
            status = BillingStatus.BillPackReady;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editBillingFormData.statusId == billingCycleStatus.BillPackSent) {
            status = BillingStatus.BillPackSent;
            isRestartDisable = true;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editBillingFormData.statusId == billingCycleStatus.BillNotPaid) {
            status = BillingStatus.BillNotPaid;
            isRestartDisable = true;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editBillingFormData.statusId == billingCycleStatus.BillQueried) {
            status = BillingStatus.BillQueried;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editBillingFormData.statusId == billingCycleStatus.BillPaid) {
            status = BillingStatus.BillPaid;
            isRestartDisable = true;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        }
        var typeIdArray: number[];
        typeIdArray = [];
        if (this.state.editBillingFormData.invoiceServiceMappingList) {
            this.state.editBillingFormData.invoiceServiceMappingList.forEach((item: any, uniqueKey: number) => {
                typeIdArray.push(parseInt(item.serviceTypeLookupId));
            });
        }
        this.setState(
            {
                serviceTypeList: typeIdArray,
                loadPanelVisible: false,
            },
            () => {
                this.props.onReceiveData(
                    this.state.editBillingFormData.invoiceId,
                    this.state.editBillingFormData,
                    status,
                    isRestartDisable,
                    this.state.isSuccessRestart ? false : true,
                    this.state.isSuccessRestart
                );
            }
        );
    };

    handleSuccessServiceTypeLookUp = (response: AxiosResponse<any>) => {
        var serviceTypeLUItem: LookUpTypeItemForServiceType;
        var serviceTypeLUItemArray: LookUpTypeItemForServiceType[];
        serviceTypeLUItemArray = [];
        response.data.data.map((item: any) => {
            serviceTypeLUItem = {
                serviceTypeLookupId: parseInt(item.id),
                value: item.value,
                parentMappingId: "0",
            };
            serviceTypeLUItemArray.push(serviceTypeLUItem);
        });
        this.setState({
            serviceTypeLookUp: serviceTypeLUItemArray,
        });
    };

    handleSuccesPeriodMatrixLookUp = (response: AxiosResponse<any>) => {
        this.setState({
            periodMatrixData: response.data.data,
        });
    };

    handleSuccessStatusLookUp = (response: AxiosResponse<any>) => {
        var statusLUItem: LookupTypeItem;
        var statusLUItemArray: LookupTypeItem[];
        statusLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            statusLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            statusLUItemArray.push(statusLUItem);
        });
        this.setState({
            statusLookUp: statusLUItemArray,
        });
        this.getEditViewDataSource();
    };

    handleSuccessClientLookUp = (response: AxiosResponse<any>) => {
        var clientLUItem: LookupTypeItem;
        var clientLUItemArray: LookupTypeItem[];
        clientLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            clientLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            clientLUItemArray.push(clientLUItem);
        });
        this.setState({
            clientLookUp: clientLUItemArray,
        });
    };

    handleStandardError = (error: any) => {
        // TODO: use appInsights logging here
        var respMessage: string = "Add/Edit shift form load failed with response: " + JSON.stringify(error);

        if (!this.billingService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleChangeClient = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var currentPeriodData = this.utils.getCurrentPeriodWeekAndDatesByClient(
            dxValueChange.value,
            this.state.periodMatrixData
        );
        this.setState({
            IsClientChanged: true,
            editBillingFormData: {
                ...this.state.editBillingFormData,
                clientId: dxValueChange.value,
                period: currentPeriodData.period,
                week: currentPeriodData.week,
                dateRangeFrom: currentPeriodData.dateFrom,
                dateRangeTo: currentPeriodData.dateTo,
            },
        });
    };

    handleChangeInvoiceReference = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                invoiceReference: dxValueChange.value,
            },
        });
    };

    handleChangeInternalInvoiceReference = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                internalReference: dxValueChange.value,
            },
        });
    };

    handleChangeAccrualTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    includeAccruals: dxValueChange.value,
                },
            });
        }
    };

    handleChangeGroupAccrualTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    groupAccruals: dxValueChange.value,
                },
            });
        }
    };

    handleChangeDateRangeStart = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            dateFromChanged: true,
            editBillingFormData: {
                ...this.state.editBillingFormData,
                dateRangeFrom: dxValueChange.value,
            },
        });
    };

    handleChangeDateRangeFinish = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            dateToChanged: true,
            editBillingFormData: {
                ...this.state.editBillingFormData,
                dateRangeTo: dxValueChange.value,
            },
        });
    };

    handleChangePeriod = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        // The week must be reset everytime the period changes because the current week value may not exist in the new period
        var firstWeekValue = "1";
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                period: dxValueChange.value,
                week: firstWeekValue,
                dateRangeFrom: this.utils.getDateFromPeriodMatrixTable(
                    true,
                    this.state.periodMatrixData,
                    this.state.editBillingFormData.clientId,
                    dxValueChange.value,
                    firstWeekValue
                ),
                dateRangeTo: this.utils.getDateFromPeriodMatrixTable(
                    false,
                    this.state.periodMatrixData,
                    this.state.editBillingFormData.clientId,
                    dxValueChange.value,
                    firstWeekValue
                ),
            },
        });
    };

    handleChangeWeek = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                week: dxValueChange.value,
                dateRangeFrom: this.utils.getDateFromPeriodMatrixTable(
                    true,
                    this.state.periodMatrixData,
                    this.state.editBillingFormData.clientId,
                    this.state.editBillingFormData.period,
                    dxValueChange.value
                ),
                dateRangeTo: this.utils.getDateFromPeriodMatrixTable(
                    false,
                    this.state.periodMatrixData,
                    this.state.editBillingFormData.clientId,
                    this.state.editBillingFormData.period,
                    dxValueChange.value
                ),
            },
        });
    };

    handleChangeStatusTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                statusId: dxValueChange.value,
            },
            isStatusChanged: true,
        });
    };

    handleChangeInvoiceDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                invoiceDate: dxValueChange.value,
            },
        });
    };

    handleChangeInvoiceDueDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                invoiceDueDate: dxValueChange.value,
            },
        });
    };

    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                description: dxValueChange.value,
            },
        });
    };

    handleChangeServiceType = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setState({
            serviceTypeList: dxValue.value,
        });
        this.setServiceTypeListOnChange(dxValue.value);
    };

    //Performig a callback only after the set has been set decreases the time to repaint the DOM.
    handleChangeSentDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                invoiceSentDate: dxValueChange.value,
            },
        });
        this.props.syncDateFields(dxValueChange.value, "Sent");
    };

    handleChangePaidDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                invoicePaidDate: dxValueChange.value,
            },
        });
        this.props.syncDateFields(dxValueChange.value, "Paid");
    };

    handleChangeInvoiceAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                invoiceAmount: dxValueChange.value,
            },
        });
        this.props.syncAmountFields(dxValueChange.value);
    };

    setServiceTypeListOnChange = (listOfServiceType: number[]) => {
        var serviceTypeList: invoiceServices;
        var serviceTypeListArray: invoiceServices[];
        serviceTypeListArray = [];

        listOfServiceType.forEach((item: any) => {
            serviceTypeList = {
                invoiceId: "",
                serviceTypeLookupId: item.toString(),
                value: "",
            };
            serviceTypeListArray.push(serviceTypeList);
        });
        this.setState({
            editBillingFormData: {
                ...this.state.editBillingFormData,
                invoiceServiceMappingList: serviceTypeListArray,
            },
        });
    };

    onPrepare = () => {
        var matrixId = this.utils.getPeriodMatrixId(
            this.state.periodMatrixData,
            this.state.editBillingFormData.clientId,
            this.state.editBillingFormData.period,
            this.state.editBillingFormData.week
        );
        this.setState(
            {
                loadPanelVisible: true,
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    periodMatrixId: matrixId,
                },
            },
            () => {
                this.saveBillingData(this.handleBillingSaveSuccess, "");
            }
        );
    };

    onPreview = () => {
        this.props.onReceiveData(
            this.state.editBillingFormData.invoiceId,
            this.state.editBillingFormData,
            BillingStatus.Pending,
            false,
            true,
            false,
            billingCycleStatus.Pending,
            true,
            true
        );
    }

    statusSelection(): string {
        var item: number;
        var billingStatusId = "";
        if (this.state.isStatusChanged == false) {
            if (this.state.editBillingFormData.status) {
                for (item = 0; item < this.state.statusLookUp.length; item++) {
                    if (this.state.editBillingFormData.status == this.state.statusLookUp[item].value) {
                        billingStatusId = this.state.statusLookUp[item].id;
                        break;
                    }
                }
            } else {
                billingStatusId = this.state.statusLookUp[0].id;
            }
        }
        return billingStatusId;
    }

    saveBillingData(successHandler: (response: AxiosResponse<any>) => void, statusIdForNextStep: string): void {
        var billingStatusId = this.statusSelection();
        var billingCycleSubmitRequest = this.utils.convertFormDataToBillingCycleRequest(
            this.state.editBillingFormData,
            ActionType.Save,
            statusIdForNextStep,
            this.state.editBillingFormData.dateRangeFrom,
            this.state.editBillingFormData.dateRangeTo,
            this.state.editBillingFormData.period,
            billingStatusId
        );
        this.billingService
            .billingDataService(billingCycleSubmitRequest)
            .then(successHandler)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null,
                    IsShowSuccess: false,
                });
                this.failureMessageVisible();
                this.hideLoadpanel();
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    }
    hideLoadpanel() {
        this.setState({ loadPanelVisible: false });
    }

    failureMessageVisible = () => {
        this.setState({
            IsShowErrorFromAPI: true,
        });
    };

    handleBillingSaveSuccess = (response: AxiosResponse<any>) => {
        var invoiceId = response.data.toString();
        sessionStorage.setItem("invoiceId", invoiceId);
        this.setState(
            (prevState: BillingPrepareComponentState) => ({
                IsShowSuccess: true,
                IsShowErrorFromAPI: false,
                prepareButtonColour: "btn-primary",
                isSuccessRestart: false,
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    invoiceId: invoiceId,
                },
            }),

            () => {
                this.props.onReceiveData(
                    invoiceId,
                    this.state.editBillingFormData,
                    BillingStatus.Pending,
                    false,
                    true,
                    this.state.isSuccessRestart,
                    billingCycleStatus.Pending
                );
            }
        );
    };

    handleBillingSaveOnlySuccess = (response: AxiosResponse<any>) => {
        var invoiceId = response.data.toString();
        this.setState(
            {
                IsShowSuccess: true,
                IsShowErrorFromAPI: false,
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    invoiceId: invoiceId,
                },
            },
            () => {
                this.props.saveBillingData(invoiceId, this.state.editBillingFormData);
            }
        );
    };

    onSave = () => {
        let {clientGross,vat,clientNet} = this.setCalculationsObject();
        var matrixId = this.utils.getPeriodMatrixId(
            this.state.periodMatrixData,
            this.state.editBillingFormData.clientId,
            this.state.editBillingFormData.period,
            this.state.editBillingFormData.week
        );
        this.setState(
            {
                loadPanelVisible: true,
                editBillingFormData: {
                    ...this.state.editBillingFormData,
                    periodMatrixId: matrixId,
                    total: clientGross,
                    tax: vat,
                    subTotal: clientNet
                },
            },
            () => {
                this.saveBillingData(this.handleBillingSaveOnlySuccess, "");
            }
        );
    };

    render() {
        var statusId: string = this.state.statusLookUp.length > 0 ? this.state.statusLookUp[0].id : "";
        if (this.state.statusLookUp.length > 0) {
            if (this.state.editBillingFormData.statusId) {
                statusId = this.state.editBillingFormData.statusId;
            }
        }
        var disableSaveButton: boolean = statusId == billingCycleStatus.BillPackRequested ? true : false;
        var notificationText: string =
            statusId == billingCycleStatus.BillPackRequested
                ? "Please Note: The user will not be able to save the cycle if the Billing cycle status is Bill Pack Requested"
                : "";
        var isDisablePrepareButton: boolean = false;
        if (this.state.statusLookUp.length > 0) {
            if (
                this.state.editBillingFormData.statusId == this.state.statusLookUp[0].id ||
                this.state.editBillingFormData.statusId == ""
            ) {
                isDisablePrepareButton = false;
            } else {
                isDisablePrepareButton = true;
            }
        }
        let clientNet: number | undefined = 0;
        let vat: number | undefined = 0;
        let clientGross: number | undefined = 0;
        let vatConfig = parseFloat(this.props.vatConfig);
        if (this.state.isSuccessRestart) {
            clientNet = this.state.editBillingFormData.subTotal;
            vat = parseFloat((this.state.editBillingFormData.subTotal * vatConfig).toFixed(2));
            clientGross = clientNet + vat;
        }        
        else if (this.props.calculationsObject && this.props.calculationsObject.subTotal) {
            clientNet = parseFloat(this.props.calculationsObject.subTotal);
            vat = parseFloat(this.props.calculationsObject.tax);
            clientGross = parseFloat(this.props.calculationsObject.total);
        }
        else {
            clientNet = undefined;
            vat = undefined;
            clientGross = undefined;
        }   
        let errorMsg = this.props.isError && this.props.error && this.props.error?.length > 0 ? this.props.error : this.state.errorMessage;
        let showError =  this.props.isError && this.props.error && this.props.error?.length > 0 ? true : this.state.IsShowErrorFromAPI; 
        return (
            <div className="card">
                <div className="card-body">
                    <div className="col-12">
                        <>
                           { this.props.pageType == "BillingPreview" ? <h5>Preview Invoice Details</h5> : <h5>New Invoice Details</h5> }
                            <div className="row">
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Client</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <SelectBox
                                        dataSource={this.state.clientLookUp}
                                        displayExpr="value"
                                        valueExpr="id"
                                        disabled={this.props.location.state?.viewDisable ? true : false} // need to check
                                        onValueChanged={this.handleChangeClient}
                                        value={this.state.editBillingFormData.clientId}
                                    >
                                        <Validator>
                                            {this.state.isErrorMessageCriteriaOne == false ||
                                                this.state.isErrorMessageCriteriaTwo == false ||
                                                this.state.isErrorMessageCriteriaThree == false ? (
                                                <RequiredRule message="Client is required - please select carefully for this invoice cycle" />
                                            ) : (
                                                ""
                                            )}
                                        </Validator>
                                    </SelectBox>
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Client Ref.</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <TextBox
                                        value={this.state.editBillingFormData.invoiceReference}
                                        onValueChanged={this.handleChangeInvoiceReference}
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                    >
                                        <Validator>
                                            {this.state.isErrorMessageCriteriaOne == false ||
                                                this.state.isErrorMessageCriteriaTwo == false ||
                                                this.state.isErrorMessageCriteriaThree == false ? (
                                                <RequiredRule message="Invoice Reference is required - please enter carefully - this will be shown on the client's invoice" />
                                            ) : (
                                                ""
                                            )}
                                        </Validator>
                                    </TextBox>
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Our Ref.</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <TextBox
                                        value={this.state.editBillingFormData.internalReference}
                                        onValueChanged={this.handleChangeInternalInvoiceReference}
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                    ></TextBox>
                                </div>
                            </div>
                            <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                            <div className="row">
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Services</div>
                                <div className="mt-3 col-10 col-lg-11">
                                    <TagBox
                                        value={this.state.serviceTypeList}
                                        dataSource={this.state.serviceTypeLookUp}
                                        width={"100%"}
                                        valueExpr="serviceTypeLookupId"
                                        displayExpr="value"
                                        showSelectionControls={true}
                                        onValueChanged={this.handleChangeServiceType}
                                        disabled={this.props.location.state?.viewDisable || this.state.isDisableService}
                                    ></TagBox>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Date From</div>
                                <div className="mt-3 col-10 col-lg-4">
                                    <div className="edit-form__to-from-field">
                                        <DateBox
                                            displayFormat="dd/MM/yyyy"
                                            onValueChanged={this.handleChangeDateRangeStart}
                                            disabled={this.props.location.state?.viewDisable ? true : false}
                                            value={
                                                this.state.editBillingFormData.dateRangeFrom
                                                    ? this.state.editBillingFormData.dateRangeFrom
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                        >
                                            <Validator>
                                                {this.state.isErrorMessageCriteriaOne == false ||
                                                    this.state.isErrorMessageCriteriaTwo == false ||
                                                    this.state.isErrorMessageCriteriaThree == false ? (
                                                    <RequiredRule message="Date From is required - please verify carefully - this will dictates what is included on the invoice" />
                                                ) : (
                                                    ""
                                                )}
                                            </Validator>
                                        </DateBox>

                                        <div className="font-weight-bold font_size edit-form__to-from-field-text">
                                            To
                                        </div>
                                        <DateBox
                                            displayFormat="dd/MM/yyyy"
                                            onValueChanged={this.handleChangeDateRangeFinish}
                                            disabled={this.props.location.state?.viewDisable ? true : false}
                                            value={
                                                this.state.editBillingFormData.dateRangeTo
                                                    ? this.state.editBillingFormData.dateRangeTo
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                        >
                                            <Validator>
                                                {this.state.isErrorMessageCriteriaOne == false ||
                                                    this.state.isErrorMessageCriteriaTwo == false ||
                                                    this.state.isErrorMessageCriteriaThree == false ? (
                                                    <RequiredRule message="Date To is required - please verify carefully - this will dictates what is included on the invoice" />
                                                ) : (
                                                    ""
                                                )}
                                            </Validator>
                                        </DateBox>
                                    </div>
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Period</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <div className="edit-form__to-from-field">
                                        <SelectBox
                                            dataSource={this.utils.buildPeriodList(
                                                this.state.editBillingFormData.clientId,
                                                this.state.periodMatrixData
                                            )}
                                            displayExpr="value"
                                            valueExpr="id"
                                            disabled={this.props.location.state?.viewDisable ? true : false}
                                            onValueChanged={this.handleChangePeriod}
                                            value={this.state.editBillingFormData.period}
                                        ></SelectBox>

                                        <div className="font-weight-bold font_size edit-form__to-from-field-text">
                                            Week
                                        </div>
                                        <SelectBox
                                            dataSource={this.utils.buildWeekList(
                                                this.state.editBillingFormData.clientId,
                                                this.state.editBillingFormData.period,
                                                this.state.periodMatrixData
                                            )}
                                            displayExpr="value"
                                            valueExpr="id"
                                            disabled={this.props.location.state?.viewDisable ? true : false}
                                            onValueChanged={this.handleChangeWeek}
                                            value={this.state.editBillingFormData.week}
                                        >
                                            <Validator>
                                                {this.state.isErrorMessageCriteriaOne == false ||
                                                    this.state.isErrorMessageCriteriaTwo == false ||
                                                    this.state.isErrorMessageCriteriaThree == false ? (
                                                    <RequiredRule message="Week is required - please select carefully - this will be shown on the client's invoice" />
                                                ) : (
                                                    ""
                                                )}
                                            </Validator>
                                        </SelectBox>
                                    </div>
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Status</div>
                                <div className="mt-3 col-10 col-lg-2">
                                    <SelectBox
                                        dataSource={this.state.statusLookUp}
                                        displayExpr="value"
                                        valueExpr="id"
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                        onValueChanged={this.handleChangeStatusTypeSelect}
                                        value={statusId}
                                    ></SelectBox>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Client Net</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        disabled={true}
                                        value={
                                            clientNet
                                        }
                                    />
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">VAT</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        disabled={true}
                                        value={
                                            vat
                                        }
                                    />
                                </div>
                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Client Gross</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        disabled={true}
                                        value={
                                            clientGross
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Invoice Date</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        onValueChanged={this.handleChangeInvoiceDate}
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                        value={
                                            this.state.editBillingFormData.invoiceDate
                                                ? this.state.editBillingFormData.invoiceDate
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                    >
                                        <Validator>
                                            {this.state.isErrorMessageCriteriaOne == false ||
                                                this.state.isErrorMessageCriteriaTwo == false ||
                                                this.state.isErrorMessageCriteriaThree == false ? (
                                                <RequiredRule message="Invoice Date is required - please select carefully  - this will be shown on the client's invoice" />
                                            ) : (
                                                ""
                                            )}
                                        </Validator>
                                    </DateBox>
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Due Date</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        onValueChanged={this.handleChangeInvoiceDueDate}
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                        value={
                                            this.state.editBillingFormData.invoiceDueDate
                                                ? this.state.editBillingFormData.invoiceDueDate
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                    >
                                        <Validator>
                                            {this.state.isErrorMessageCriteriaOne == false ||
                                                this.state.isErrorMessageCriteriaTwo == false ||
                                                this.state.isErrorMessageCriteriaThree == false ? (
                                                <RequiredRule message="Invoice Due Date is required - please select carefully  - this will be shown on the client's invoice" />
                                            ) : (
                                                ""
                                            )}
                                        </Validator>
                                    </DateBox>
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">ID</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <TextBox
                                        value={this.state.editBillingFormData.invoiceId}
                                        readOnly={true}
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                    ></TextBox>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Invoice Sent Date</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        onValueChanged={this.handleChangeSentDate}
                                        value={
                                            this.state.editBillingFormData.invoiceSentDate
                                                ? this.state.editBillingFormData.invoiceSentDate
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                    />
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Paid Date</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        onValueChanged={this.handleChangePaidDate}
                                        value={
                                            this.state.editBillingFormData.invoicePaidDate
                                                ? this.state.editBillingFormData.invoicePaidDate
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                    />
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Amount Paid</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeInvoiceAmount}
                                        value={
                                            this.state.editBillingFormData.invoiceAmount
                                                ? this.state.editBillingFormData.invoiceAmount
                                                : 0
                                        }
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Accruals?</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <SelectBox
                                        dataSource={this.accrualTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeAccrualTypeSelect}
                                        value={
                                            this.state.editBillingFormData.includeAccruals == true ||
                                                this.state.editBillingFormData.includeAccruals.toString() == "Include"
                                                ? "Include"
                                                : "Don't Include"
                                        }
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                    />
                                </div>

                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Group Accruals?</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <SelectBox
                                        dataSource={this.groupAccrualTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeGroupAccrualTypeSelect}
                                        value={
                                            this.state.editBillingFormData.groupAccruals == true ||
                                                this.state.editBillingFormData.groupAccruals.toString() == "Group"
                                                ? "Group"
                                                : "Don't Group"
                                        }
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Comment</div>
                                <div className="mt-3 col-10 col-lg-11">
                                    <TextArea
                                        height={90}
                                        onValueChanged={this.handleChangeDescription}
                                        value={this.state.editBillingFormData.description}
                                        disabled={this.props.location.state?.viewDisable ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {showError ? (
                                    <ul className="card-form__errors">
                                        {errorMsg.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {item.columnName}: {item.errorMessage}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : null}
                            </div>
                            {notificationText ? (
                                <span className="text-danger font-weight-bold my-2">{notificationText}</span>
                            ) : (
                                <></>
                            )}
                            { this.props.pageType == "BillingPreview" ? 
                                <div className="row mt-3">
                                    <div className="col-12 col-lg-2 mb-3 mr-auto">
                                        <button
                                            className={
                                                (false ? "disabled " : "") + "btn btn-primary btn--large btn--with-icon"
                                            }
                                            type="button"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.onPreview();
                                            }}
                                        >
                                            PREVIEW
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-2 mb-3">
                                        <button
                                            className={"btn btn--ghost btn--large"}
                                            type="button"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.props.previousPage.goBack();
                                            }}
                                        >
                                            CLOSE
                                        </button>
                                    </div>
                                </div>
                               : <div className="row mt-3">
                                    <div className="col-12 col-lg-2 mb-3 mr-auto">
                                        <button
                                            className={
                                                (this.props.location.state?.viewDisable ? "disabled " : "") +
                                                "btn" +
                                                " " +
                                                this.state.prepareButtonColour +
                                                " " +
                                                "btn--large"
                                            }
                                            type="button"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.onPrepare();
                                            }}
                                            disabled={isDisablePrepareButton}
                                        >
                                            <span className="btn__icon">
                                                {/* <FontAwesomeIcon icon={faQuestionCircle} /> */}
                                            </span>
                                            PREPARE
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-2 mb-3">
                                        <button
                                            className={"btn btn--ghost btn--large"}
                                            type="button"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.props.previousPage?.goBack();
                                            }}
                                        >
                                            CLOSE
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-2 mb-3">
                                        <button
                                            className={
                                                (this.props.location.state?.viewDisable ? "disabled " : "") +
                                                "btn btn--ghost btn--large"
                                            }
                                            type="button"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.onSave();
                                            }}
                                            disabled={disableSaveButton}
                                        >
                                            <span className="btn__icon">
                                                {/* <FontAwesomeIcon icon={faQuestionCircle} /> */}
                                            </span>
                                            SAVE
                                        </button>
                                    </div>
                                </div>
                            }
                        </>
                    </div>
                </div>
            </div>
        );
    }
}

export default BillingPrepareComponent;
