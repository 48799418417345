import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface FixedOnDesktopSidePanelDesignProps extends RouteComponentProps {}

class FixedOnDesktopSidePanelDesign extends Component<FixedOnDesktopSidePanelDesignProps> {
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <section className="col">
                        test content with desktop sidebar, should be visible on desktop and hidden on mobile
                    </section>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(FixedOnDesktopSidePanelDesign);
