import { ServiceNames } from "./ServiceUtilities";

export default class CardListUtilities {
    getCssClass(service: ServiceNames, isExpanded: boolean) {
        let cssClasses = {
            sectionClass: "",
            buttonClass: "",
        };
        if (isExpanded) {
            cssClasses.sectionClass = "show card-accordion__section-content--" + service.toLowerCase();
            cssClasses.buttonClass = "btn card-accordion__header--" + service.toLowerCase();
        } else {
            cssClasses.sectionClass = "collapse card-accordion__section-content--" + service.toLowerCase();
            cssClasses.buttonClass = "btn collapsed card-accordion__header--" + service.toLowerCase();
        }
        return cssClasses;
    }

    getAccordionCssClass(
        service: ServiceNames,
        securityCardLength: number,
        cleaningCardLength: number,
        entertainmentCardLength: number,
        generalCardLength: number,
        otherServiceCardLength: number
    ) {
        if (service == ServiceNames.Security) {
            return this.getCssClass(
                service,
                securityCardLength > 0 &&
                    cleaningCardLength === 0 &&
                    entertainmentCardLength === 0 &&
                    generalCardLength === 0 && 
                    otherServiceCardLength === 0
            );
        } else if (service == ServiceNames.ContractCleaing) {
            return this.getCssClass(
                service,
                cleaningCardLength > 0 &&
                    securityCardLength === 0 &&
                    entertainmentCardLength === 0 &&
                    generalCardLength === 0 && 
                    otherServiceCardLength === 0
            );
        } else if (service == ServiceNames.Entertainment) {
            return this.getCssClass(
                service,
                entertainmentCardLength > 0 &&
                    cleaningCardLength === 0 &&
                    securityCardLength === 0 &&
                    generalCardLength === 0 && 
                    otherServiceCardLength === 0
            );
        } else if (service == ServiceNames.Other) {
            return this.getCssClass(
                service,
                otherServiceCardLength > 0 &&
                    cleaningCardLength === 0 &&
                    securityCardLength === 0 &&
                    entertainmentCardLength === 0 && 
                    generalCardLength === 0
            );
        }
        else if(service == ServiceNames.General) {
            return this.getCssClass(
                service,
                generalCardLength > 0 &&
                    cleaningCardLength === 0 &&
                    securityCardLength === 0 &&
                    entertainmentCardLength === 0 && 
                    otherServiceCardLength === 0
            );
        }

        return {
            sectionClass: "",
            buttonClass: "",
        };
    }
}
