import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { SelectBox, DateBox, LoadPanel, TextBox, CheckBox, TextArea } from "devextreme-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LookupService, { LookupTypeItem, LookupTypeIndexes } from "../../services/LookupService";
import MatrixGridService from "../../services/MatrixService";
import sharedUtils from "../grid/sharedUtilities";
import {
    TextBoxOnValueChangedEvent,
    DateBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
    onKeyDownEvent,
    SelectBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import FAQFormUtil, { FAQFormData } from "./FAQUtils";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";

type NullableDate = Date | null | undefined;
//#region props
interface FAQFormProps {
    location: any;
    history: any;
    onApplySuccess: (applySignal: string) => void;
}

// State
interface FAQFormState {
    categoryLookup: LookupTypeItem[];
    appTypeLookup: LookupTypeItem[];
    FAQFormData: FAQFormData;
    priorityLookup: LookupTypeItem[];
    loadPanelVisible: boolean;
    categoryLoaded: boolean;
    appTypeLoaded: boolean;
    priorityLoaded: boolean;
    errorMessage: any[];
}

class FAQForm extends React.Component<FAQFormProps> {
    lookupService: LookupService;
    state: FAQFormState;
    utils: FAQFormUtil;
    sharedUtils: sharedUtils;
    matrixService: MatrixGridService;

    constructor(props: FAQFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.utils = new FAQFormUtil();
        this.sharedUtils = new sharedUtils();
        this.matrixService = new MatrixGridService();
        var convertedData = this.utils.initializeFAQFormItem(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        this.state = {
            categoryLookup: [],
            appTypeLookup: [],
            FAQFormData: convertedData,
            priorityLookup: [],
            loadPanelVisible: false,
            categoryLoaded: false,
            appTypeLoaded: false,
            priorityLoaded: false,
            errorMessage: [],
        };
    }

    componentDidMount() {
        this.retrieveLookupValues(LookupTypeIndexes.faqCategory);
        this.retrieveLookupValues(LookupTypeIndexes.userDeviceApp);
        this.getFAQDataById();
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.faqCategory:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.faqCategory)
                    .then((response: AxiosResponse<any>) =>
                        this.setState({
                            categoryLookup: response.data.data,
                            categoryLoaded: true,
                        })
                    )
                    .catch(() =>
                        this.setState({
                            categoryLookup: [],
                            categoryLoaded: true,
                        })
                    );
                break;
            case LookupTypeIndexes.userDeviceApp:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.userDeviceApp)
                    .then((response: AxiosResponse<any>) =>
                        this.setState({
                            appTypeLookup: response.data.data,
                            appTypeLoaded: true,
                        })
                    )
                    .catch(() =>
                        this.setState({
                            appTypeLookup: [],
                            appTypeLoaded: true,
                        })
                    );
                break;
        }
    };

    getFAQDataById = () => {
        if (this.props.location.state && this.props.location.state.id) {
            this.setState({
                loadPanelVisible: true,
            });
            this.matrixService
                .getFAQFormData(this.props.location.state.id)
                .then(this.handleSuccess)
                .catch(this.handleStandardError);
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        var data = this.utils.convertFAQResponeToFormData(response.data.data);
        this.setState({
            FAQFormData: data,
            loadPanelVisible: false,
        });
    };

    handleStandardError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleChangeCategory = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                categoryLookUpId: dxValueChange.value,
            },
        });
        this.setCategoryOrder(dxValueChange.value);
    };

    handleChangeAppType  = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                app: dxValueChange.value,
                categoryOrder: "",
                categoryLookUpId: "",
            },
        });
    };

    setCategoryOrder = (categoryLookUpId: string) => {
        var categoryOrderArr: LookupTypeItem[] = [];
        var categoryOrderVal: string = "";
        categoryOrderArr = this.state.categoryLookup.filter((item) => {
            return item.id == categoryLookUpId;
        });
        if (categoryOrderArr.length > 0) {
            categoryOrderVal = categoryOrderArr[0].id;
        }
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                categoryOrder: categoryOrderVal
            }
        });
    }

    handleChangeQuestion = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                question: dxValueChange.value,
            },
        });
    };

    handleChangeName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                name: dxValueChange.value,
            },
        });
    };

    handleChangeQuestionOrder = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                questionOrder: dxValueChange.value,
            },
        });
    }

    handleChangeFAQDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                faqDate: dxValueChange.value,
            },
        });
    };

    handleChangeValidFrom = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                validFrom: dxValueChange.value,
            },
        });
    };

    handleChangeValidTo = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                validTo: dxValueChange.value,
            },
        });
    };

    handleChangeAnswer = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                answer: dxValueChange.value,
            },
        });
    };

    handleChangeActive = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                isActive: dxValueChange.value,
            },
        });
    };

    handleChangePopup = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            FAQFormData: {
                ...this.state.FAQFormData,
                isPopUpRequired: dxValueChange.value,
            },
        });
    };

    onApplyClick = () => {
        this.setState({
            loadPanelVisible: true,
        });
        var { FAQFormData } = this.state;
        var faQSubmitRequest = this.utils.convertFormDataToFAQRequest(FAQFormData);
        this.matrixService
            .postFAQFormData(faQSubmitRequest)
            .then(this.handleSuccessDataSubmission)
            .catch((err: any) => {
                this.setState({
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                    loadPanelVisible: false,
                });
            });
    };

    //A helper function to display the error message, might move this to a common utils or something.
    renderErrorMessage = (errorMessage: any[]) => {
        let errorData: React.ReactNode = <></>;
        if (errorMessage) {
            errorData = (
                <span className="unscheduled-shift">
                    <ul>
                        {errorMessage.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    {item.columnName}: {item.errorMessage}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            );
        }
        return errorData;
    };

    //A helper function that will prevent exponential "e" from being entered into the numberBox.
    handleKeyPressEvent = (e: onKeyDownEvent) => {
        if (e.event.key == "e") {
            e.event.preventDefault();
        }
    };

    handleSuccessDataSubmission = () => {
        this.setState({
            loadPanelVisible: false,
        });
        if (this.props.location.state && this.props.location.state.id) {
            this.props.onApplySuccess("EDIT");
        } else if (this.state.FAQFormData.id == "0") {
            this.props.onApplySuccess("ADD");
        }
    };

    //#region render Component
    render() {
        var {
            errorMessage,
            categoryLookup,
            appTypeLookup,
            priorityLookup,
            categoryLoaded,
            appTypeLoaded,
            priorityLoaded,
            FAQFormData,
        } = this.state;
        return (
            <form data-testid="addEditShift-form" className="edit-form my-5 form_border">
                <div className="container mb-6 edit-form__column">
                    <h2 className="billingForm-heading mt-3">
                        <strong>FAQ Form</strong>
                    </h2>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">App</div>
                        <div className="mt-3 col-10 col-lg-3">
                            {appTypeLoaded && (
                                <SelectBox
                                    dataSource={appTypeLookup}
                                    displayExpr="value"
                                    valueExpr="id"
                                    onValueChanged={this.handleChangeAppType}
                                    value={this.state.FAQFormData.app}
                                />
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Category</div>
                        <div className="mt-3 col-10 col-lg-3">
                            {categoryLoaded && (
                                <DropDownFromLookupTableDynamic
                                    lookupTypeIndex={LookupTypeIndexes.faqCategory}
                                    isRoot={false}
                                    onValueChanged={this.handleChangeCategory}
                                    parentMappingId={this.state.FAQFormData.app}
                                    value={this.state.FAQFormData.categoryLookUpId}
                                />
                            )}
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Category Order</div>
                        <div className="mt-3 col-10 col-lg-3">

                            <TextBox
                                value={this.state.FAQFormData.categoryOrder}
                                readOnly={true}
                            />

                        </div>
                        <div className="mt-3 col-10 col-lg-1"></div>
                        <div className="mt-3 col-10 col-lg-3">
                            <CheckBox
                                value={this.state.FAQFormData.isActive}
                                onValueChanged={this.handleChangeActive}
                                width={130}
                                text="Active?"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Name</div>
                        <div className="mt-3 col-10 col-lg-7">
                            <TextBox
                                value={this.state.FAQFormData.name}
                                onValueChanged={this.handleChangeName}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Question Order</div>
                        <div className="mt-3 col-10 col-lg-1">
                            <TextBox
                                value={this.state.FAQFormData.questionOrder}
                                onValueChanged={this.handleChangeQuestionOrder}
                            />
                        </div>
                        <div className="mt-3 col-10 col-lg-2">
                            <CheckBox
                                value={this.state.FAQFormData.isPopUpRequired}
                                onValueChanged={this.handleChangePopup}
                                width={130}
                                text="Pop Up?"
                            />
                        </div>
                    </div>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Question</div>
                        <div className="mt-3 col-lg-11">
                            <TextBox
                                value={this.state.FAQFormData.question}
                                onValueChanged={this.handleChangeQuestion}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Answer</div>
                        <div className="mt-3 col-lg-11">
                            <TextArea
                                value={this.state.FAQFormData.answer}
                                height={100}
                                onValueChanged={this.handleChangeAnswer}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">FAQ Date</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeFAQDate}
                                value={
                                    this.state.FAQFormData.faqDate
                                        ? this.state.FAQFormData.faqDate
                                        : undefined
                                }
                                useMaskBehavior={true}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Valid From</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeValidFrom}
                                value={
                                    this.state.FAQFormData.validFrom
                                        ? this.state.FAQFormData.validFrom
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Valid To</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeValidTo}
                                value={
                                    this.state.FAQFormData.validTo
                                        ? this.state.FAQFormData.validTo
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">ID</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.FAQFormData.id} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Last Updated On</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.FAQFormData.lastUpdatedOn} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Last Updated By</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.FAQFormData.lastUpdatedBy} readOnly={true} />
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    {this.renderErrorMessage(errorMessage)}
                    <div className="row mr-auto ml-3">
                        <div className="col-12 col-lg-2 mb-3">
                            <Link
                                to={{
                                    pathname: "/matrixView",
                                    state: {
                                        isCancel: true,
                                    },
                                }}
                                className="btn btn--ghost btn--large"
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                                Cancel
                            </Link>
                        </div>
                        <div className="col-12 col-lg-2 mb-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="button"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onApplyClick();
                                }}
                            >
                                <span className="btn__icon"></span>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default FAQForm;
