import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";

interface EditActionCellProps {
    data: any;
}

interface EditActionCellState {
    invoiceId: number;
}

class EditActionCell extends React.Component<EditActionCellProps> {
    state: EditActionCellState;

    constructor(props: EditActionCellProps) {
        super(props);
        this.state = {
            invoiceId: this.props.data.data.id,
        };
    }

    componentDidMount() {}

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    <Link
                        title="Edit"
                        className={(false ? "disabled " : "") + "icon-btn"}
                        to={{
                            pathname: "/BillingPageV2",
                            state: {
                                id: this.state.invoiceId.toString(),
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </Link>
                </div>
            </div>
        );
    }
}

export default EditActionCell;
