import React, { MouseEvent, KeyboardEventHandler } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DateBox, SelectBox, TextBox, NumberBox, TextArea, LoadPanel } from "devextreme-react";
import {
    DateBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
    ButtonOnClickEvent,
    NumberBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FindAnArtistService, { RequestTypeEnum } from "../../services/FindAnArtistService";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import IndependentBookingUtils, { RequestFormData } from "./IndependentBookingUtils";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
interface IndependentBookingFormProps extends WithTranslation {
    location: any;
    history: any;
}

interface IndependentBookingFormState {
    loadPanelVisible: boolean;
    requestFormData: RequestFormData;
    errorMessage: [];
    successMessage: string;
    subServiceLookUp: LookupTypeItem[];
}

// Component
class IndependentBookingForm extends React.Component<IndependentBookingFormProps> {
    utils: IndependentBookingUtils;
    state: IndependentBookingFormState;
    indBookReqSvc: FindAnArtistService;
    lookUpService: LookupService;

    constructor(props: IndependentBookingFormProps) {
        super(props);
        this.indBookReqSvc = new FindAnArtistService();
        this.lookUpService = new LookupService();
        this.utils = new IndependentBookingUtils();

        var convertedRequestFormData = this.utils.initializeRequestItem();
        // Initialize state
        this.state = {
            loadPanelVisible: false,
            requestFormData: convertedRequestFormData,
            subServiceLookUp: [],
            errorMessage: [],
            successMessage: "",
        };
    }

    componentDidMount() {
        this.lookUpService
            .getServiceSubType(LookupTypeIndexes.subServiceType, "Entertainment")
            .then(this.handleServiceLUSuccess)
            .catch(this.handleError);

        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
            .then(this.handleClientLookUpSuccess)
            .catch(this.handleError);
        /* this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLookUpSuccess)
            .catch(this.handleError); */
    }

    handleClientLookUpSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data && response.data.data && response.data.data.length > 0) {
            this.setState(
                {
                    requestFormData: {
                        ...this.state.requestFormData,
                        clientBusinessEntityId: response.data.data[0].id,
                    },
                },
                () => {
                    this.lookUpService
                        .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                        .then(this.handleVenueLookUpSuccess)
                        .catch(this.handleError);
                }
            );
        }
    };

    handleVenueLookUpSuccess = (response: AxiosResponse<any>) => {
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            !this.utils.isBlank(this.state.requestFormData.clientBusinessEntityId)
        ) {
            var venues: any[] = response.data.data;
            venues = venues.filter((item) => {
                return item.parentMappingId == this.state.requestFormData.clientBusinessEntityId;
            });

            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    venueId: venues[0].id,
                },
            });
        }
    };
    handleServiceLUSuccess = (response: AxiosResponse<any>) => {
        var serviceDropDown = this.utils.lookUpCreation(response);
        this.setState({
            subServiceLookUp: serviceDropDown,
        });
    };

    handleError = (err: any) => {
        this.setState({
            errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
            showError: true,
            loadPanelVisible: false,
        });
    };
    handleChangeClientSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                clientBusinessEntityId: dxValueChange.value,
            },
        });
    };
    handleChangeVenueSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                venueId: dxValueChange.value,
            },
        });
    };

    handleChangeSubServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                serviceSubTypeId: dxValueChange.value,
            },
        });
    };
    handleChangeWorkerName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                workerName: dxValueChange.value,
            },
        });
    };
    handleChangeWorkerEmail = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                workerEmail: dxValueChange.value,
            },
        });
    };
    handleChangeWorkerPhone = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                workerPhone: dxValueChange.value,
            },
        });
    };
    handleChangeEntDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                entertainmentDescription: dxValueChange.value,
            },
        });
    };
    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                additionalInformation: dxValueChange.value,
            },
        });
    };

    handleChangeWorkDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                dateFrom: dxValueChange.value,
            },
        });
    };

    handleChangeStartTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                startTime: dxValueChange.value,
            },
        });
    };
    handleChangeEndTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                finishTime: dxValueChange.value,
            },
        });
    };
    handleChangeBudget = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                budget: dxValueChange.value,
            },
        });
    };

    onSubmit = () => {
        this.setState({
            loadPanelVisible: true,
        });

        //passing object data to the server

        var independentBookingSubmitRequest = this.utils.convertFormDataToNewRequest(this.state.requestFormData);
        this.indBookReqSvc
            .saveNewArtistRequest(independentBookingSubmitRequest)
            .then(this.handleFormDataSubmissionSuccess)
            .catch(this.handleFormSubmissionFailure)
            .finally(() => {
                this.hideLoadpanel();
            });
    };

    handleFormDataSubmissionSuccess = (response: AxiosResponse<any>) => {
        this.hideLoadpanel();

        this.setState({
            errorMessage: [],
        });
        this.renderRedirect();
    };

    hideLoadpanel = () => {
        this.setState({ loadPanelVisible: false });
    };
    showLoadpanel = () => {
        this.setState({
            loadPanelVisible: true,
        });
    };
    handleFormSubmissionFailure = (err: any) => {
        this.hideLoadpanel();
        this.setState({
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null,
        });
    };

    onCancel = () => {
        this.renderRedirect();
    };
    renderRedirect = () => {
        this.props.history.push({
            pathname: "/",
        });
    };
    //#region render Component
    render() {
        var placeholderTextforAddInfo =
            "Please add any relevant booking information, e.g. is this a weekly residency? We will add this booking to your scheduled artists list";
        var placeholderTextforEntDesc = "e.g. DJ, Live music, Karaoke, Pub quiz, Drag act";
        var placeholderTextforWrkName = "Please provide artist or act name";
        var placeholderTextforWrkEmail = "Artist or act contact email";
        var placeholderTextforWrkPhone = "Artist or act contact phone number/s";

        return (
            <form data-testid="addEditPayment-form" className="card card-form my-5">
                <div className="card-body">
                    <section>
                        <h3 className="card-form__heading font-weight-bold">
                            Independent Entertainment Booking Notification
                        </h3>

                        {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
                            <div className="row justify-content-left pt-3">
                                <span className="text-danger">
                                    <ul>
                                        {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {item.columnName}: {item.errorMessage}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </span>
                            </div>
                        ) : null}
                        <div className="card-form__field card-form__field--narrow">
                            Client
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.clientType}
                                onValueChanged={this.handleChangeClientSelect}
                                value={this.state.requestFormData.clientBusinessEntityId}
                            />
                            <input
                                data-testid={LookupTypeIndexes.clientType}
                                type="hidden"
                                name={LookupTypeIndexes.clientType}
                            ></input>
                        </div>
                        <div className="card-form__field card-form__field--narrow">
                            Venue
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <LookupTypeListDynamic
                                lookupTypeIndex={LookupTypeIndexes.venueType}
                                onValueChanged={this.handleChangeVenueSelect}
                                isRoot={false}
                                parentMappingId={this.state.requestFormData.clientBusinessEntityId}
                                value={this.state.requestFormData.venueId}
                                displayExpression={"value"}
                            />
                        </div>

                        <div className="card-form__field card-form__field--narrow">
                            Entertainment type
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <SelectBox
                                dataSource={this.state.subServiceLookUp}
                                displayExpr={"value"}
                                valueExpr={"id"}
                                onValueChanged={this.handleChangeSubServiceTypeSelect}
                                value={this.state.requestFormData.serviceSubTypeId}
                            />
                        </div>

                        <div className="card-form__field card-form__field--narrow">
                            <h4 className="card-form__subheading">
                                Entertainment Description
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </h4>
                            <TextArea
                                onValueChanged={this.handleChangeEntDescription}
                                value={this.state.requestFormData.entertainmentDescription}
                                placeholder={placeholderTextforEntDesc}
                            ></TextArea>
                        </div>

                        <div className="card-form__field card-form__field--very-narrow">
                            Date
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <DateBox
                                onValueChanged={this.handleChangeWorkDate}
                                value={
                                    this.state.requestFormData.dateFrom
                                        ? this.state.requestFormData.dateFrom
                                        : undefined
                                }
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                                // min={calendarMin}
                            ></DateBox>
                        </div>
                        <div className="card-form__field card-form__field--narrow">
                            Artist/act
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <TextBox
                                onValueChanged={this.handleChangeWorkerName}
                                value={this.state.requestFormData.workerName}
                                placeholder={placeholderTextforWrkName}
                            ></TextBox>
                        </div>
                        <div className="card-form__field card-form__field--narrow">
                            Artist/act phone
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <TextBox
                                onValueChanged={this.handleChangeWorkerPhone}
                                value={this.state.requestFormData.workerPhone}
                                placeholder={placeholderTextforWrkPhone}
                            ></TextBox>
                        </div>
                        <div className="card-form__field card-form__field--narrow">
                            Artist/act email
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <TextBox
                                onValueChanged={this.handleChangeWorkerEmail}
                                value={this.state.requestFormData.workerEmail}
                                placeholder={placeholderTextforWrkEmail}
                            ></TextBox>
                        </div>
                        <div className="card-form__field card-form__field--very-narrow">
                            Start time
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="00:00"
                                useMaskBehavior={true}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.handleChangeStartTime}
                                value={
                                    this.state.requestFormData.startTime
                                        ? this.state.requestFormData.startTime
                                        : undefined
                                }
                            />
                        </div>

                        <div className="card-form__field card-form__field--narrow">
                            Total cost
                            <sup className="card-form__mandatory-symbol">*</sup>
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                onValueChanged={this.handleChangeBudget}
                                valueChangeEvent="keyup"
                                value={this.state.requestFormData.budget}
                            />
                        </div>
                        <div className="card-form__field card-form__field--wide">
                            <h4 className="card-form__subheading">
                                Gig info
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </h4>
                            Please provide as much insight as possible
                            <TextArea
                                // disabled={this.state.createAdjustment ? this.state.createAdjustment : this.props.location.state.isAmend}
                                onValueChanged={this.handleChangeDescription}
                                value={this.state.requestFormData.additionalInformation}
                                placeholder={placeholderTextforAddInfo}
                            ></TextArea>
                        </div>
                    </section>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="row mt-5">
                        <div className="col-12 col-lg-4">
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onCancel();
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col-12 col-lg-4">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onSubmit();
                                }}
                            >
                                Submit Request
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default withTranslation()(IndependentBookingForm);
