import i18n from "i18next";
import Backend from "i18next-xhr-backend";
//import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import "../assets/i18n/languages";

//console.log('Hash',process.env.HASH)
// "@types/webpack-env": "^1.18.4", devDependencies
//new webpack.ExtendedAPIPlugin() __webpack_hash__,
i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: "enGB",
        backend: {
            /* translation file path */
            loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
            customHeaders :{
                'Cache-Control':'no-cache'
            }
            // requestOptions:{
            //     cache:'no-store',
            // }
        },
        fallbackLng: "enGB",
        debug: true,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            wait: true,
        },
    });

export default i18n;
