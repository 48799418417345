import GigHighLevelService, {
    GigHighLevelData,
    RequestType,
    GigMessageType,
    ValidationErrorType,
} from "../../services/GigHighLevelService";

type NullableDate = Date | null | undefined;

export default class GigHighLevelUtilities {
    constructor() {
        this.initializeGigDetailItem = this.initializeGigDetailItem.bind(this);
    }

    initializeGigDetailItem(): RequestType {
        var formData: RequestType = {
            id: "",
            startTime: "",
            finishTime: "",
            serviceTypeId: "",
            serviceType: "",
            serviceSubTypeId: "",
            serviceSubType: "",
            providerBusinessEntityId: "",
            clientBusinessEntityId: "",
            venueId: "",
            townCity: "",
            country: "",
            dateFrom: "",
            providerPayAmountLocal: "",
            configurableText: "",
            entertainmentDescription: "",
            additionalInformation: "",
            lastUpdatedOn: "",
            lastUpdatedBy: "",
            requestStatusId: "",
            requestStatusValue: "",
            messages: null,
            showInterest: [],
            validationErrors: null,
        };
        return formData;
    }
}
