import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Texts,
    SearchPanel,
    StateStoring
} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import MatrixService, { PeriodMatrixGridRowItem } from "../../services/MatrixService";
import PeriodMatrixActionCellComponent from "./PeriodMatrixActionCellComponent";
import MaskedStatusForActive from "../ServiceFeeMatrix/MaskedStatusForActive";
import gridUtils from "../grid/GridUtilities";

//props
interface PeriodMatrixGridProps {
    refreshSignal: boolean;
}

// State
interface PeriodMatrixGridState {
    periodGridDataSource: PeriodMatrixGridRowItem[];
    showProgressIndicator: boolean;
}

// Component - displays the Rate Matrix Grid
class PeriodMatrixGrid extends React.Component<PeriodMatrixGridProps> {
    //Initialize the component's state.
    state: PeriodMatrixGridState;
    periodService: MatrixService;
    gridUtils: gridUtils;
    constructor(props: PeriodMatrixGridProps) {
        super(props);
        this.periodService = new MatrixService();
        // Initialize state and services/utils
        this.state = {
            periodGridDataSource: [],
            showProgressIndicator: false,
        };
        // Functions
        this.gridUtils = new gridUtils();
        this.periodMatrixDataSource = this.periodMatrixDataSource.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    componentDidMount() {
        this.periodMatrixDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: PeriodMatrixGridProps) {
        if (this.props.refreshSignal !== prevProps.refreshSignal) {
            // refresh the grid
            this.periodMatrixDataSource();
        }
    }

    periodMatrixDataSource() {
        this.setState({
            showProgressIndicator: true,
        });
        this.periodService.getPeriodMatrixDataItem().then(this.handleSuccess).catch(this.handleError);
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            periodGridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    }

    handleError(error: AxiosResponse<any>) {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "getPeriodMatrixDataItem failed with response: " + JSON.stringify(error);

        if (!this.periodService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }
    render() {
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.periodGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        columnResizingMode={"widget"}
                        allowColumnResizing={true}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Sorting mode="single" />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <StateStoring
                                enabled={true}
                                type="localStorage" storageKey="period_matrix_grid"
                                savingTimeout={0}
                            />
                        <Column dataField="id" allowSorting={true} caption="ID"  calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.id);
                            }} dataType='number'></Column>
                        <Column dataField="businessEntityShortName" allowSorting={true} caption="CLIENT NAME"></Column>
                        <Column dataField="daysOfWeekValue" allowSorting={true} caption="WEEK START DAY"></Column>
                        <Column dataField="yearNumber" allowSorting={true} caption="PERIOD YEAR" calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.yearNumber);
                            }} dataType='number'> </Column>
                        <Column dataField="periodNumber" allowSorting={true} caption="PERIOD NUMBER" calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.periodNumber);
                            }} dataType='number'></Column>
                        <Column dataField="periodWeek" allowSorting={true} caption="PERIOD WEEK" calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.periodWeek);
                            }} dataType='number'></Column>
                        <Column dataField="dateFrom" 
                        calculateSortValue={(rowData: any) => {
                            return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateFrom);
                        }} 
                        calculateCellValue={(rowData: any) => {
                            return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateFrom);
                        }}
                        caption="DATE FROM"
                        format="dd/MM/yyyy"
                        dataType="date"></Column>
                        <Column dataField="dateTo" 
                        calculateSortValue={(rowData: any) => {
                            return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                        }} 
                        calculateCellValue={(rowData: any) => {
                            return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                        }}
                        caption="DATE TO"
                        format="dd/MM/yyyy"
                        dataType="date"></Column>
                        <Column
                            dataField="isActive"
                            allowSorting={true}
                            caption="ACTIVE"
                            cellComponent={MaskedStatusForActive}
                        ></Column>
                        <Column dataField="lastUpdatedOn" allowSorting={true} caption="LAST UPDATED" 
                        calculateSortValue={(rowData: any) => {
                            return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                        }} 
                        calculateCellValue={(rowData: any) => {
                            return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                        }}
                        format="dd/MM/yyyy" dataType="date"></Column>
                        <Column dataField="lastUpdatedBy" allowSorting={true} caption="LAST UPDATED BY"></Column>
                        <Column cellComponent={PeriodMatrixActionCellComponent}></Column>
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default PeriodMatrixGrid;
