import React from "react";

interface PerformanceTimeCellProps {
    data: any;
}

interface PerformanceTimeCellState {
    startTime: string;
    finishTime: string;
}

class PerformanceTimeCell extends React.Component<PerformanceTimeCellProps> {
    //state object to manage the look of the Individual buttons and badges displayed in each row.
    state: PerformanceTimeCellState;

    constructor(props: PerformanceTimeCellProps) {
        super(props);
        this.state = {
            startTime: "",
            finishTime: ""
        };
    }

    componentDidMount() {
        this.setState({
            startTime: this.props.data.data.startTime,
            finishTime: this.props.data.data.finishTime
        });
    }

    render() {
        return (
            <>
                {this.state.startTime} - {this.state.finishTime}
            </>
        );
    }
}

export default PerformanceTimeCell;
