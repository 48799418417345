import React from "react";
import UploadSchedulePage from "./UploadSchedulePage";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";
import { TypeOfFileUploaded } from "../../services/BillableItemHeaderService";

interface UploadSchedulePageContainerProps extends RouteProps, withValidUserRoleProps {}

const UploadSchedulePageContainer = (props: UploadSchedulePageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return <UploadSchedulePage title={getTitle()} category={getType()} landingPage= {TypeOfFileUploaded.Shifts} landingPageTab = "New Submission File"/>;
};

export default withValidUserRole(UploadSchedulePageContainer);
