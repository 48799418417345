


import { TextArea } from "devextreme-react";
import React, { MouseEvent } from "react";
import { TextBoxOnValueChangedEvent } from "../../../types/DevExtremeTypes";

interface CardCommentsSectionProps {
    message: string;
    comments: string;
    handleChangeComments: (dxValueChange: TextBoxOnValueChangedEvent) => void;
    buttonText: string;
    queryButtonText: string;
    onSubmitQuery: () => void;
    handleSubmit: () => void;
    handleSave: () => void;
    disableApproveButton: boolean;
    disableQueryButton: boolean;
    disableSaveButton: boolean;
}

class CardCommentsSection extends React.Component<CardCommentsSectionProps> {
    constructor(props: CardCommentsSectionProps) {
        super(props);
    }

    render() {
        const { disableApproveButton,disableQueryButton,disableSaveButton } = this.props;
        return (
            <div className="text-center">
                <div className="mb-3">Comments</div>
                <TextArea
                    height={100}
                    value={this.props.comments}
                    placeholder={this.props.message ? this.props.message : ""}
                    onValueChanged={this.props.handleChangeComments}
                />

                {/* <div className="mt-3"></div> */}
                <div className="row mt-3 card__button_alignment">
                    <div className="col-5 pr-0 pl-1 pr-sm-3">
                        <button
                            className="btn btn--ghost btn--large"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.props.onSubmitQuery();
                            }}
                            disabled = {disableQueryButton}
                        >
                            {this.props.queryButtonText}
                        </button>
                    </div>
                    <div className="col-3 pr-0 pl-1 pr-sm-3">
                        <button
                            className="btn btn--ghost btn--large"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.props.handleSave();
                            }}
                            disabled={disableSaveButton}
                        >
                            Save
                        </button>
                    </div>
                    <div className="col-4 pl-1">
                        <button
                            className="btn btn-primary btn--large"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.props.handleSubmit();
                            }}
                            disabled={disableApproveButton}
                        >
                            {this.props.buttonText}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
export default CardCommentsSection;
