import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import sharedUtils from "../grid/sharedUtilities";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import CostReportService from "../../services/CostReportService";
import LookupService from "../../services/LookupService";
import outstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import IncidentsListDatePanel from "./IncidentListDatePanel";
import IncidentsListGrid from "./IncidentsListGrid";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IncidentsListPageProps extends RouteComponentProps, WithTranslation {
    title: string;
    category: string;
    location: any;
}

interface IncidentsListPageState {
    startDate: string;
    endDate: string;
    gridLoaded: boolean;
    onUpdate: boolean;
    configurableTextHeader: any[];
}

class IncidentsListPage extends Component<IncidentsListPageProps> {
    utils: sharedUtils;
    conversionUtils: outstandingActionsUtilities;
    state: IncidentsListPageState;
    Service: CostReportService;
    lookupService: LookupService;
    // Global Variable to store configurable text.
    constructor(props: IncidentsListPageProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.utils = new sharedUtils();
        this.conversionUtils = new outstandingActionsUtilities();
        this.state = {
            startDate: "",
            endDate: "",
            gridLoaded: false,
            onUpdate: false,
            configurableTextHeader: [],
        };
    }

    setDateRange = (startDate: string, endDate: string) => {
        this.setState({
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
        });
    };

    render() {
        var header: string = "Door Supervision - Incidents";
        return (
            <LightModePageTemplate>
                <SideBarTemplate closeSidebar={this.state.onUpdate} isFixed={true}>
                    <IncidentsListDatePanel onApplyButtonClick={this.setDateRange} />
                    <BulletinPanel />
                </SideBarTemplate>

                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="mt-3 mb-3">
                        <h2 className="page-title__black">{header}</h2>
                    </header>
                    <div className="col-md-12 col-xl-12 text-center text-lg-left mt-3 mt-md-0">
                        To update an existing incident, please click on the 'Update' icon below. Or, if you need to report
                        a new incident, please select 'Add Incident' and complete.
                    </div>
                    <div className="row py-3 px-3 justify-content-center justify-content-lg-start">
                        <div className="col-md-5 col-xl-3 grid-info__button-container">
                            <Link
                                className="btn--link btn btn-primary btn--large btn--with-icon"
                                to={{
                                    pathname: "/incident",
                                    state: {
                                        id: "0",
                                    },
                                }}
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                                Add Incident
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md">
                            <IncidentsListGrid dateFrom={this.state.startDate} dateTo={this.state.endDate} />
                        </div>
                        <div></div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(withTranslation()(IncidentsListPage));
