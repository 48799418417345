import React from "react";
import SupplierInformationPage from "./SupplierInformationPage";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";

interface SupplierInformationPageContainerProps extends RouteProps, withValidUserRoleProps {}

const SupplierInformationPageContainer = (props: SupplierInformationPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return <SupplierInformationPage title={getTitle()} category={getType()} />;
};

export default withValidUserRole(SupplierInformationPageContainer);
