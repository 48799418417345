export interface CookieSettingsType {
    AccountID: string;
    Analytics: string;
    Marketing: string;
    Settings: string;
}

class CookiePreferencesService {
    COOKIE_SETTINGS_STORAGE_KEY = "CookieSettings";

    getCookiePreferences(accountID: string): CookieSettingsType {
        let valuesFromStorage = localStorage.getItem(this.COOKIE_SETTINGS_STORAGE_KEY + "-" + accountID);
        let stateAsObject = null;

        if (valuesFromStorage && valuesFromStorage.length > 0) {
            stateAsObject = JSON.parse(valuesFromStorage);
        }

        return stateAsObject;
    }

    setCookiePreferences(cookieSettings: CookieSettingsType) {
        let values = JSON.stringify(cookieSettings);

        localStorage.setItem(this.COOKIE_SETTINGS_STORAGE_KEY + "-" + cookieSettings.AccountID, values);
    }
}

export default CookiePreferencesService;
