import React, { Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import LanguageSelector from "../select/LanguageSelector";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { getAppInsights } from "../AppInsights/TelemetryService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import LightModePageTemplate from "./Templates/LightModeTemplate";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

interface DevelopmentSupportPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
}

interface DevelopmentSupportPageComponentState {
    currentRoles: string;
    profileImageSrc: string;
    isImageCropPopupVisible: boolean;
}

class DevelopmentSupportPage extends Component<DevelopmentSupportPageComponentProps> {
    state: DevelopmentSupportPageComponentState;
    appInsights: ApplicationInsights;
    constructor(props: DevelopmentSupportPageComponentProps) {
        super(props);
        // Instance methods
        this.getCurrentRole = this.getCurrentRole.bind(this);
        this.trackException = this.trackException.bind(this);
        this.trackTrace = this.trackTrace.bind(this);
        this.trackEvent = this.trackEvent.bind(this);
        // Initialize instance variables
        var currentRoles = this.getCurrentRole();
        this.appInsights = getAppInsights();
        this.state = {
            currentRoles: currentRoles,
            profileImageSrc: "",
            isImageCropPopupVisible: false,
        };
    }

    getCurrentRole(): string {
        var currentRoles: string[] = [];
        var allRoles = [
            RoleGroupNames.EventUKRelationshipManager,
            RoleGroupNames.EventUKSeniorManager,
            RoleGroupNames.HeadDoorSupervisor,
            RoleGroupNames.ProviderScheduler,
            RoleGroupNames.ProviderSiteManager,
            RoleGroupNames.VenueManager,
        ];
        allRoles.forEach((role) => {
            if (UserService.isUserInGroup(role)) {
                currentRoles.push(role);
            }
        });

        return JSON.stringify(currentRoles);
    }

    toggleDarkMode() {
        const body = document.getElementsByTagName("body")[0];
        body.classList.toggle("dark-mode");
    }

    /**
     * In Azure Portal Logs, use this query to see exception
        exceptions 
        | where ['type'] contains "some error"
        | limit 10 
    */
    trackException() {
        this.appInsights.trackException({
            error: new Error("some error"),
            severityLevel: SeverityLevel.Error,
        });
    }

    /**
     * In Azure Portal Logs, use this query to see trace
        traces 
        | where message contains "some trace"
        | limit 10
     */
    trackTrace() {
        this.appInsights.trackTrace({
            message: "some trace",
            severityLevel: SeverityLevel.Information,
        });
    }

    trackEvent() {
        this.appInsights.trackEvent({ name: "some event" });
    }

    imageCapture = () => {};

    onSelectProfileImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({
                    profileImageSrc: reader.result,
                    isImageCropPopupVisible: true,
                })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onProfileImageCancel = (): void => {
        this.setState({
            profileImageSrc: "",
            isImageCropPopupVisible: false,
        });
    };

    onProfileImageSelect = (image: string): void => {
        this.setState({
            profileImageSrc: image,
            isImageCropPopupVisible: false,
        });
    };

    render() {
        var number = 1283999.75;
        var specificNumber = number.toLocaleString();
        return (
            <LightModePageTemplate>
                <div className="social-container">
                    <h3>Social Follow</h3>
                    <a href="https://www.youtube.com/c/jamesqquick" className="youtube social">
                        <FontAwesomeIcon icon={faYoutube} size="2x" />
                    </a>
                </div>
                <section className="container">
                    <div className="row starter-template">
                        <button onClick={this.toggleDarkMode}>Dark Mode</button>
                        <div className="col-md-12">
                            <LanguageSelector />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">User's current roles: {this.state.currentRoles}</div>
                    </div>
                    <div className="App">
                        <button onClick={this.trackException}>Track Exception</button>
                        <button onClick={this.trackEvent}>Track Event</button>
                        <button onClick={this.trackTrace}>Track Trace</button>
                    </div>
                    <div>
                        <Link
                            to={{
                                pathname: "/EntertainmentCompletionCard",
                                state: {
                                    id: 3,
                                },
                            }}
                            className="btn btn-primary btn--large"
                        >
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </span>
                            Confirm
                        </Link>
                        {/* <PhotoCapture imageCapture={this.imageCapture} /> */}
                    </div>
                    <div>
                        <div>
                            <input type="file" accept="image/*" onChange={this.onSelectProfileImage} />
                            <img
                                alt="Profile Image Selected"
                                style={{ maxWidth: "100%" }}
                                src={this.state.profileImageSrc}
                            />
                        </div>
                        <span>{specificNumber}</span>
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(DevelopmentSupportPage);
