import React from "react";
import { Button } from "devextreme-react";

// Props
interface SubmitGridRowComponentProps {
    onSubmitClick: (event: any) => void;
    buttonText: string;
    isDisabled: boolean;
    currentRowState: {
        id: string;
        fileFormatTypeLookUpId: string;
        fileFormatText: string;
        fileTypeText: string;
        fileName: string;
        serviceTypeId: string;
        serviceType: string;
        serviceSubTypeId: string;
        serviceSubType: string;
        status: string;
        errorMessage: string;
        lastUpdatedOn: string;
        lastUpdatedBy: string;
    };
}

// State
interface SubmitGridRowComponentState {}

// Component - Submit button that changes state based on status
class SubmitGridRowComponent extends React.Component<SubmitGridRowComponentProps> {
    render() {
        return (
            <Button
                className="btn btn-primary"
                disabled={this.props.isDisabled}
                onClick={this.props.onSubmitClick}
                text={this.props.buttonText}
                type="normal"
            />
        );
    }
}

export default SubmitGridRowComponent;
