import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { confirm } from "devextreme/ui/dialog";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { Link } from "react-router-dom";
import { LoadIndicator } from "devextreme-react";
import { faTrashAlt as faTrashAltSolid } from "@fortawesome/free-solid-svg-icons";
import BaseActionCell, { BaseActionCellProps } from "./BaseActionCell";
import GlobalService from "../../services/GlobalService";
import { GridTypes } from "../../services/SimpleGridService";

class DeleteEditShiftActionCellV2 extends BaseActionCell {
    constructor(props: BaseActionCellProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.data && this.props.data.data) {
            var deletableShift: boolean = false;
            var acceptDate: string = "";
            var approvalDate: string = "";
            if (this.props.data.data.approvalDate) {
                approvalDate = this.props.data.data.approvalDate;
            }
            else if (this.props.data.data.approval_Date) {
                approvalDate = this.props.data.data.approval_Date;
            }

            if (this.props.data.data.acceptDate) {
                acceptDate = this.props.data.data.acceptDate;
            }
            else if (this.props.data.data.accept_Date) {
                acceptDate = this.props.data.data.accept_Date;
            }
            if (
                this.props.data.data &&
                (!approvalDate) &&
                (!acceptDate) &&
                !this.props.data.data.invoiceId &&
                !this.props.data.data.paymentId
            ) {
                deletableShift = true;
            }
            this.setState({
                deletableShift: deletableShift,
            });

            if (
                this.props.data &&
                (this.props.data.data.itemTypeId == itemTypes.ClientCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderDebit ||
                    this.props.data.data.itemTypeId == itemTypes.Receivable ||
                    this.props.data.data.itemTypeId == itemTypes.Payable ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit)
            ) {
                this.setState({
                    isFinancialAdjustmentView: true,
                });
            }
            if (
                (this.props.data &&
                    (this.props.data.data.itemTypeId == itemTypes.Acceptance ||
                        this.props.data.data.itemTypeId == itemTypes.ClientCredit ||
                        this.props.data.data.itemTypeId == itemTypes.ClientDebit ||
                        this.props.data.data.itemTypeId == itemTypes.ProviderCredit ||
                        this.props.data.data.itemTypeId == itemTypes.ProviderDebit ||
                        this.props.data.data.itemTypeId == itemTypes.Receivable ||
                        this.props.data.data.itemTypeId == itemTypes.Payable)) ||
                this.props.data.data.childRecordId
            ) {
                this.setState({
                    isEditIconDisable: true,
                });
            }
            if (
                (this.props.data.data.itemTypeId == itemTypes.Original ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebit) &&
                (this.props.data.data.approvalDate || this.props.data.data.acceptDate ||
                    this.props.data.data.billDate || this.props.data.data.approval_Date ||
                    this.props.data.data.accept_Date || this.props.data.data.bill_Date)
            ) {
                this.setState({
                    isAdjustmentChange: true,
                });
            }
        }
    }

    handleDeleteOnClick = () => {
        confirm("<div class='text-center'>Delete this row?</div>", "Delete").then((hasConfirmed) => {
            if (hasConfirmed) {
                let billableItemId: string = "";
                if (this.props.data.data && this.props.data.data.billableItemId) {
                    billableItemId = this.props.data.data.billableItemId.toString();
                }
                else if (this.props.data.data && this.props.data.data.id) {
                    billableItemId = this.props.data.data.id.toString();
                }
                this.setLoadIndicatorVisibility(true);
                //call the API for posting all the Delete Billable Id's and handle the response
                this.Service.submitDeleteShift(billableItemId)
                    .then(this.handleSuccessDelete)
                    .catch(this.handleDeleteFailure);
            }
        });
    };
    handleDeleteFailure = (error: any) => {
        var respMessage: string = "submitDeleteShift service failed with response: " + JSON.stringify(error);

        if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    handleSuccessDelete = () => {
        this.props.data.component.deleteRow(this.props.data.rowIndex);
        this.props.data.component.repaint();
        GlobalService.setDeletedItemId(this.props.data.data.id);
    };

    saveBillableItemId = () => {
        let billableItemId: string = "";
        if (this.props.data.data && this.props.data.data.billableItemId) {
            billableItemId = this.props.data.data.billableItemId.toString();
        }
        else if (this.props.data.data && this.props.data.data.id) {
            billableItemId = this.props.data.data.id.toString();
        }
        localStorage.setItem("BillableIdFromPayment", billableItemId);
    }

    render() {
        var isEventSM = UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        var { deletableShift } = this.state;
        var gridType = GlobalService.getGridType();
        var pointOfNav: string = "";
        if (window.location.href.includes("BillingPageV2")) {
            pointOfNav = "Billing";
        }
        else if (gridType && gridType == GridTypes.paymentsRequest) {
            pointOfNav = "MatchedPopUp";
        }
        else if (gridType && (gridType == GridTypes.artistShiftMatrix || gridType == GridTypes.providerShiftMatrix)) {
            pointOfNav = "FindAnArtistLandingPage";
        }
        else if (gridType && gridType == GridTypes.residencyShifts) {
            pointOfNav = "Residencies";
        }
        else if (gridType && gridType == GridTypes.queryForm) {
            pointOfNav = "QueryManageForm";
        }
        else if (gridType && gridType == GridTypes.futureNegetiveShift) {
            pointOfNav = "PaymentsV2Page";
        }
        else if (gridType && gridType == GridTypes.contractCleaningShifts) {
            pointOfNav = "ContractCleaning";
        }
        else if (gridType && gridType == GridTypes.SimpleGrid) {
            pointOfNav = "SimpleShifts";
        }
        else {
            pointOfNav = "ManageShifts";
        }
        let billableItemId: string = "";
        if (this.props.data.data && this.props.data.data.billableItemId) {
            billableItemId = this.props.data.data.billableItemId.toString();
        }
        else if (this.props.data.data && this.props.data.data.id) {
            billableItemId = this.props.data.data.id.toString();
        }
        let service: string = "";
        if (this.props.data.data.service) {
            service = this.props.data.data.service;
        }
        else if (this.props.data.data.serviceTypeLookUp && this.props.data.data.serviceTypeLookUp.value) {
            service = this.props.data.data.serviceTypeLookUp.value;
        }

        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    {isEventSM ? (
                        <div className="mr-2">
                            {deletableShift ? (
                                <button className={"btn icon-btn"} onClick={this.handleDeleteOnClick} title="Delete">
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                            ) : (
                                <button className={"btn icon-btn disabled"}>
                                    <FontAwesomeIcon icon={faTrashAltSolid} />
                                </button>
                            )}
                        </div>
                    ) : null}
                    <span>
                        <LoadIndicator
                            className="ml-3"
                            id="button-indicator"
                            height={20}
                            width={20}
                            visible={this.state.loadPanelVisible}
                        />
                    </span>
                    {isEventSM ? GlobalService.getPopupType() == GridTypes.futureNegetiveShift ? 
                        (
                            <div>
                                <Link
                                    title="Edit"
                                    className={"icon-btn"}
                                    onClick={this.saveBillableItemId}
                                    to={
                                        this.state.isFinancialAdjustmentView
                                            ? {
                                                pathname: "/FinanceAdjustmentPopUp",
                                                state: {
                                                    id: billableItemId,
                                                    adjustment: false,
                                                    isMasterEdit: true,
                                                    pointOfNavigation: pointOfNav,
                                                    filterStartDate: this.props.data.data.filterStartDate,
                                                    filterEndDate: this.props.data.data.filterEndDate,
                                                    service: service,
                                                },
                                            }
                                            : {
                                                pathname: "/AddEditFormV2",
                                                state: {
                                                    id: billableItemId,
                                                    adjustment: false,
                                                    isMasterEdit: true,
                                                    pointOfNavigation: pointOfNav,
                                                    filterStartDate: this.props.data.data.filterStartDate,
                                                    filterEndDate: this.props.data.data.filterEndDate,
                                                    service: service,
                                                },
                                            }
                                    }
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </Link>
                            </div>
                        )
                        : (
                        <div>
                            <Link
                                title="Edit"
                                className={"icon-btn"}
                                to={
                                    this.state.isFinancialAdjustmentView
                                        ? {
                                            pathname: "/FinanceAdjustmentPopUp",
                                            state: {
                                                id: billableItemId,
                                                adjustment: false,
                                                isMasterEdit: true,
                                                pointOfNavigation: pointOfNav,
                                                filterStartDate: this.props.data.data.filterStartDate,
                                                filterEndDate: this.props.data.data.filterEndDate,
                                                service: service,
                                            },
                                        }
                                        : {
                                            pathname: "/AddEditFormV2",
                                            state: {
                                                id: billableItemId,
                                                adjustment: false,
                                                isMasterEdit: true,
                                                pointOfNavigation: pointOfNav,
                                                filterStartDate: this.props.data.data.filterStartDate,
                                                filterEndDate: this.props.data.data.filterEndDate,
                                                service: service,
                                            },
                                        }
                                }
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </Link>
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

export default DeleteEditShiftActionCellV2;
