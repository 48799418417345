import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble, faSquare } from "@fortawesome/pro-regular-svg-icons";
import { confirm } from "devextreme/ui/dialog";
import notify from 'devextreme/ui/notify';
import { Status } from "../../services/AddEditPopUpService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { AxiosResponse } from "axios";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { LoadPanel } from "devextreme-react";
import BaseActionCell, { BaseActionCellProps, FadSquare } from "./BaseActionCell";

class ApproveActionCell extends BaseActionCell {
    constructor(props: BaseActionCellProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.data && this.props.data.data) {
            var statusColumnIndex: number = 0;
            let payDateColumnIndex: number = 0;
            payDateColumnIndex = this.props.data.component.getVisibleColumnIndex("payDate");
            if (this.props.data.data.statusValue) {
                statusColumnIndex = this.props.data.component.getVisibleColumnIndex("statusValue");
            }
            else if (this.props.data.data.status) {
                statusColumnIndex = this.props.data.component.getVisibleColumnIndex("status");
            }
            else if (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value) {
                statusColumnIndex = this.props.data.component.getVisibleColumnIndex("billableStatusLookUp.value");
            }
            var disableQueryIcon: boolean = false;
            var noIcon: boolean = false;
            const isEventUk =
                UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
            const isProvider = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler);
            const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
            var pendingIconVisible = this.state.pendingIconVisible;
            var futureShift: boolean = false;
            var tenseIdentifier: string = this.props.data.data.tenseFlag;
            var statusId: string = "";
            if (this.props.data.data.statusId) {
                statusId = this.props.data.data.statusId;
            }
            else if (this.props.data.data.billableStatusLookupId) {
                statusId = this.props.data.data.billableStatusLookupId;
            }
            var itemTypeId: string =
                this.props && this.props.data && this.props.data.data ? this.props.data.data.itemTypeId : "";
            var iconDisabled: boolean = false;
            var isBilledItem: boolean = false;
            var isApprovalDate: boolean = false;
            if (this.props.data.data.approvalDate || this.props.data.data.approval_Date) {
                isApprovalDate = true;
            }
            else {
                isApprovalDate = false;
            }

            if (tenseIdentifier && tenseIdentifier.trim().toLowerCase() == "future") {
                iconDisabled = true;
                futureShift = true;
            } else {
                if (
                    (statusId && statusId == Status.Approved) ||
                    (statusId && statusId == Status.Accepted) ||
                    (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Approved") || (this.props.data.data.status == "Approved" || this.props.data.data.statusValue == "Approved") ||
                    (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Accepted") || (this.props.data.data.status == "Accepted" || this.props.data.data.statusValue == "Accepted") ||
                    this.props.data.data.approvalDate || this.props.data.data.approval_Date ||
                    this.props.data.data.acceptDate || this.props.data.data.accept_Date
                ) {
                    iconDisabled = true;
                    pendingIconVisible = false;
                } else if (
                    (tenseIdentifier && tenseIdentifier.trim().toLowerCase() == "past" &&
                        isEventUk || isVenueManager &&
                        statusId &&
                        statusId == Status.Pending && ((this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Pending") || this.props.data.data.status == "Pending")) ||
                    (isEventUk || isVenueManager && statusId && statusId == Status.PendingAcceptance && ((this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Pending Acceptance") || this.props.data.data.status == "Pending Acceptance")) ||
                    (isEventUk || isVenueManager && tenseIdentifier.trim().toLocaleLowerCase() == "past" && (!isApprovalDate) && this.props.data.data.isAccrual && !this.props.data.data.paymentId) ||
                    (isEventUk || isVenueManager && tenseIdentifier.trim().toLocaleLowerCase() == "past" && (!isApprovalDate) && !this.props.data.data.isAccrual && !this.props.data.data.invoiceId)
                ) {
                    iconDisabled = false;
                    pendingIconVisible = true;
                }
                else if ((this.props.data.data.invoiceId && !this.props.data.data.isAccrual) ||
                    (this.props.data.data.paymentId && this.props.data.data.invoiceId && this.props.data.data.isAccrual)) {
                    iconDisabled = true;
                    pendingIconVisible = false;
                    isBilledItem = true;
                } else if (
                    (isVenueManager && itemTypeId && itemTypeId == itemTypes.AdjustmentProviderDebit) ||
                    (isProvider &&
                        itemTypeId &&
                        itemTypeId == itemTypes.AdjustmentClientDebit &&
                        tenseIdentifier.trim().toLowerCase() == "past")
                ) {
                    iconDisabled = true;
                    pendingIconVisible = true;
                } else {
                    noIcon = true;
                }
            }
            if (this.props.data && this.props.data.data.queries && this.props.data.data.queries.length > 0) {
                disableQueryIcon = this.costReportUtils.disableRaiseQueryIcon(this.props.data.data.queries);
            }

            this.setState({
                disableQueryIcon: disableQueryIcon,
                futureShift: futureShift,
                pendingIconVisible: pendingIconVisible,
                iconDisabled: iconDisabled,
                noIconToDisplay: noIcon,
                isBilledItem: isBilledItem
            });

            if (
                this.props.data &&
                (this.props.data.data.itemTypeId == itemTypes.ClientCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderDebit ||
                    this.props.data.data.itemTypeId == itemTypes.Receivable ||
                    this.props.data.data.itemTypeId == itemTypes.Payable)
            ) {
                this.setState({
                    isFinancialAdjustmentView: true,
                });
            }
            if (this.props.data && this.props.data.data && this.props.data.data.editAllowed && this.props.data.data.editAllowed.toLowerCase() == "false") {
                this.setState({
                    isEditIconDisable: true,
                });
            }
            if (
                ((this.props.data.data.itemTypeId == itemTypes.Original ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebit) &&
                    (this.props.data.data.approvalDate || this.props.data.data.acceptDate || this.props.data.data.billDate) ||
                    (this.props.data.data.approval_Date || this.props.data.data.accept_Date || this.props.data.data.bill_Date) || (this.props.data.data.approval_Date || this.props.data.data.accept_Date || this.props.data.data.bill_Date))
                || (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Approved") || (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Accepted") ||
                this.props.data.data.status == "Approved" || this.props.data.data.status == "Accepted"
            ) {
                this.setState({
                    isAdjustmentChange: true,
                });
            }

            this.setState({
                statusColumnIndexVal: statusColumnIndex,
                payDateStatusColumnIndexVal: payDateColumnIndex
            });

            if(this.props.approvalSuccess == true && this.props.dataId.length > 0){ 
                this.props.dataId.forEach((item: any, uniqueKey: number) => {
                    if (item.id == this.props.data.data.billableItemId) {                        
                        let index = this.props.approvalResponse?.data?.data.map((o:any) => o.id).indexOf(item.id);
                        this.setState({
                            pendingIconVisible: false,
                            loadPanelVisible: false,
                            status: this.props.approvalResponse?.data?.data?.length > 0 ? this.props.approvalResponse?.data?.data[index].value : "",
                            isAdjustmentChange: true,
                        });
                        this.props.data.component.cellValue(
                            this.props.data.rowIndex,
                            item.statusColumnIndex,
                            this.props.approvalResponse?.data?.data?.length > 0 ? this.props.approvalResponse?.data?.data[index].value : "",
                        );
                        this.props.data.component.cellValue(
                            this.props.data.rowIndex,
                            item.payDateColumnIndex,
                            this.props.approvalResponse?.data?.data?.length > 0 ? this.props.approvalResponse?.data?.data[index].payDate : ""
                        ); 
                        //uniqueKey == this.props.dataId.length-1 ? this.props.getUpdate ? this.props.getUpdate(true) : null : null;               
                    }
                });                                          
            }             
        }
    }

    handleApprovalOnClick = () => {
        //this.props.getUpdate ? this.props.getUpdate(true) : null; 
        confirm("<div class='text-center'>Approve the Shift?</div>", "Submit").then((hasConfirmed) => {
            if (hasConfirmed) {
                //call the API for posting the Billable Item list to the server.
                this.onConfirmation();
            }
        });
    };

    onConfirmation = () => {
        this.showLoadPanel();
        let billableItemId: string = "";
        if (this.props.data.data.id) {
            billableItemId = this.props.data.data.id;
        }
        else {
            billableItemId = this.props.data.data.billableItemId;
        }
        let selectedId = [this.sharedUtils.convertStringToInt(billableItemId)]
        this.Service.submitApproveShift(selectedId)
            .then(this.handlePostApprovalSuccess)
            .catch((error) => {
                var respMessage: string = "Approval failed with response: " + JSON.stringify(error);
                this.hideLoadPanel();
                notify({ message: "Sorry! The shift could not be approved.", width: 300, shading: true }, 'error', 600);
                if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                    console.error(respMessage);
                }
            });
    };

    handlePostApprovalSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            pendingIconVisible: false,
            loadPanelVisible: false,
            status: response.data.data.length > 0 ? response.data.data[0].value : "",
            isAdjustmentChange: true,
        });
        notify({ message: "Thank you - shift has been approved.", width: 300, shading: true }, 'success', 600);
        // this.props.data.component.cellValue(
        //     this.props.data.rowIndex,
        //     this.state.statusColumnIndexVal,
        //     response.data.data.length > 0 ? response.data.data[0].value : ""
        // );
        // this.props.data.component.cellValue(
        //     this.props.data.rowIndex,
        //     this.state.payDateStatusColumnIndexVal,
        //     response.data.data.length > 0 ? response.data.data[0].payDate : ""
        // );
        this.props.onRefresh ? this.props.onRefresh(true) :null;
    };

    render() {        
        var {
            pendingIconVisible,
            iconDisabled,
            futureShift,
            noIconToDisplay,
            disableQueryIcon,
            isBilledItem
        } = this.state;
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        message="Please wait..."
                        visible={this.state.loadPanelVisible}
                    />
                    <div className="mr-2">
                        {futureShift || pendingIconVisible || disableQueryIcon ? (
                            <button
                                className={(iconDisabled ? "disabled " : "") + "btn icon-btn"}
                                onClick={this.handleApprovalOnClick}
                                disabled={disableQueryIcon}
                            >
                                {!futureShift && pendingIconVisible && !noIconToDisplay && !disableQueryIcon && (
                                    <FontAwesomeIcon icon={faSquare} />
                                )}
                                {futureShift && !noIconToDisplay && !disableQueryIcon && (
                                    <FadSquare
                                        height={16}
                                        width={16}
                                    />
                                )}
                                {disableQueryIcon && null}
                            </button>
                        ) : !futureShift && !pendingIconVisible && !noIconToDisplay && isBilledItem ? (
                            <div className="btn icon-btn">
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                        ) : !futureShift && !pendingIconVisible && !noIconToDisplay ? (
                            <div className="btn icon-btn">
                                <FontAwesomeIcon icon={faCheckDouble} />
                            </div>
                        ) : (
                            <div className="btn icon-btn"> </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default ApproveActionCell;
