import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "./Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import DatePanel from "../side-bar/DatePanel";
import BillableItemHeaderService, { TypeOfFileUploaded } from "../../services/BillableItemHeaderService";
import NewFileSubmissionComponent from "../ShiftImport/NewFileSubmissionComponent";
import RecentFileSubmissionComponent from "../ShiftImport/RecentFileSubmissionComponent";
import OtherFileUploaderComponent from "../ShiftImport/OtherFileUploaderComponent";
import GlobalService from "../../services/GlobalService";

// Props
interface UploadSchedulePageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    landingPage: string;
    typeId?: string; // TypeId props specifies which value the type dropdown should hold.
    landingPageTab: string;
}

// State - refreshSignal used to determine when a grid refresh is required
//State property - gridVisibilitySignal used to determine when to display the Upload file list grid.
interface UploadSchedulePageState {
    selectedNewSubmissionButton: boolean;
    selectedRecentFileSubmittedButton: boolean;
    isNewSubmissionTab: boolean;
    shiftType: string;
}

// Component - displays the file upload component and the two grids that show file data
class UploadSchedulePage extends Component<UploadSchedulePageComponentProps> {
    state: UploadSchedulePageState;
    service: BillableItemHeaderService;
    constructor(props: UploadSchedulePageComponentProps) {
        super(props);
        this.service = new BillableItemHeaderService();
        this.state = {
            selectedNewSubmissionButton: true,
            selectedRecentFileSubmittedButton: false,
            isNewSubmissionTab: false,
            shiftType: "",
        };
    }

    //A helper function that would toggle the state of the button to refresh the contents of the Parent Grid.
    toggleButtonStates = (toggleButtonState: boolean) => {
        this.setState({
            selectedNewSubmissionButton: toggleButtonState,
            selectedRecentFileSubmittedButton: !toggleButtonState,
        });
    };

    renderPageHeading = () => {
        let { landingPage, landingPageTab } = this.props;
        let shiftType = GlobalService.getGigComingFrom();
        if(landingPage == TypeOfFileUploaded.Shifts){
            if(shiftType == "ContractCleaning"){
                return (
                    <h4 className="font-weight-bold">Contract Cleaning - Shift Import</h4>
                )
            }
            if(shiftType == "Security"){
                return (
                    <h4 className="font-weight-bold">Door Supervision - Shift Import</h4>
                )
            }
            if(shiftType == "Entertainment"){
                return (
                    <h4 className="font-weight-bold">Entertainment - Shift Import</h4>
                )
            }
            if(shiftType == "Gardening"){
                return (
                    <h4 className="font-weight-bold">Gardening - Shift Import</h4>
                )
            }
            if(shiftType == "Maintenance"){
                return (
                    <h4 className="font-weight-bold">Maintenance - Shift Import</h4>
                )
            }
            if(shiftType == "Miscelleanous"){
                return (
                    <h4 className="font-weight-bold">Miscelleanous - Shift Import</h4>
                )
            }
            if(shiftType == "WindowCleaning"){
                return (
                    <h4 className="font-weight-bold">WindowCleaning - Shift Import</h4>
                )
            }
        }
        else {
            return (
                <h4 className="font-weight-bold">Please upload new files here</h4>
            )
        }
    }

    render() {
        var { landingPage, landingPageTab } = this.props;
        let shiftType = GlobalService.getGigComingFrom();
        return (
            <LightModePageTemplate>
                {landingPage == TypeOfFileUploaded.Shifts && (
                    <SideBarTemplate isFixed={true}>
                        <DatePanel />
                    </SideBarTemplate>
                )}
                <div
                    className={`page-content${
                        landingPage == TypeOfFileUploaded.Shifts ? "--with-sidebar--fixed" : ""
                    } container--small-margins`}
                >
                    <h3 className="sr-only">Upload Shifts</h3>
                    {landingPage == TypeOfFileUploaded.Shifts ? (
                        <div className="row">
                            <div className="col-md">
                                {this.renderPageHeading()}
                                <div className="view-queries-grid mt-5">
                                    <div className="view-queries-header__controls">
                                        <div className="view-queries-header__view-buttons">
                                            <button
                                                className={`btn
                                        view-queries-header__view-button ${
                                            this.state.selectedNewSubmissionButton
                                                ? "view-queries-header__view-button--selected"
                                                : ""
                                        }`}
                                                onClick={() => this.toggleButtonStates(true)}
                                            >
                                                New Submission
                                            </button>
                                            <button
                                                className={`btn
                                        view-queries-header__view-button ${
                                            this.state.selectedRecentFileSubmittedButton
                                                ? "view-queries-header__view-button--selected"
                                                : ""
                                        }`}
                                                onClick={() => this.toggleButtonStates(false)}
                                            >
                                                Recent Files
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {landingPage == TypeOfFileUploaded.Shifts &&
                    landingPageTab &&
                    this.state.selectedNewSubmissionButton ? (
                        <NewFileSubmissionComponent
                            title={this.props.title}
                            category={this.props.category}
                            landingPage={this.props.landingPage}
                            typeId={this.props.typeId} // TypeId props specifies which value the type dropdown should hold.
                            landingPageTab={this.props.landingPageTab}
                            shiftType={shiftType}
                        ></NewFileSubmissionComponent>
                    ) : this.state.selectedRecentFileSubmittedButton ? (
                        <RecentFileSubmissionComponent
                            title={this.props.title}
                            category={this.props.category}
                            landingPage={this.props.landingPage}
                            typeId={this.props.typeId} // TypeId props specifies which value the type dropdown should hold.
                            landingPageTab={this.props.landingPageTab}
                        ></RecentFileSubmissionComponent>
                    ) : (
                        <OtherFileUploaderComponent
                            title={this.props.title}
                            category={this.props.category}
                            landingPage={this.props.landingPage}
                            typeId={this.props.typeId} // TypeId props specifies which value the type dropdown should hold.
                            landingPageTab={this.props.landingPageTab}
                        ></OtherFileUploaderComponent>
                    )}
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(UploadSchedulePage);
