import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import GigHighLevelList from "../GigListHighLevel/GigHighLevelList";
import DarkModePageTemplate from "./Templates/DarkModePageTemplate";
interface HighLevelGigCardListPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}
// State
interface HighLevelGigCardListPageComponentState {}

class HighLevelGigCardListPage extends Component<HighLevelGigCardListPageComponentProps> {
    state: HighLevelGigCardListPageComponentState;
    constructor(props: HighLevelGigCardListPageComponentProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <DarkModePageTemplate>
                <div id="entLandPage" className="page-content">
                    <section className="container accent-container">
                        <div className="pb-3">
                            <GigHighLevelList location={this.props.location} history={this.props.history} />
                        </div>
                    </section>
                </div>
            </DarkModePageTemplate>
        );
    }
}

export default withRouter(HighLevelGigCardListPage);
