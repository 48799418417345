import React from "react";
import HomePage from "./HomePage";

type HomePageContainerProps = { location: { pathname: string }; getBuild: (flag: boolean) => void;  };

const HomePageContainer = (props: HomePageContainerProps) => {
    const getTitle = () => {
        const path = props.location.pathname;
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location.pathname;
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    const getBuild = (flag: boolean) => {
        props.getBuild(flag);
    };

    return <HomePage title={getTitle()} category={getType()} getBuild={getBuild} />;
};

export default HomePageContainer;
