import { BrowserUtils } from "@azure/msal-browser";
import { Button } from "devextreme-react";
import React from "react";
import { msalInstance } from "../../services/AuthService";
import { errorMessages } from "../../services/UserService";

interface AuthErrorMessageProps {
    messages: errorMessages[];
}

export default class AuthErrorMessage extends React.Component<AuthErrorMessageProps> {
    onClickLink = () => {
        sessionStorage.clear();
        msalInstance.logoutRedirect({
            account: msalInstance.getActiveAccount(),
            onRedirectNavigate: () => !BrowserUtils.isInIframe()
        });
    }
 
    render() {
        let errMsgPart1 = "";
        let errMsgPart2 = "";

        if(this.props.messages.length > 0) {
            errMsgPart1 =  this.props.messages[0].errorMessage;
        }
        if(this.props.messages.length === 2) {
            errMsgPart2 =  this.props.messages[1].errorMessage;
        }
        return (
            <div className="artist-signup__login__hero-image">
                <div className="artist-signup__login__container">
                    <div className="artist-signup__login__content">
                        <img
                            className="artist-signup__login__icon"
                            src="/assets/images/logo-icon-white-landscape.svg"
                        />
                        <div className="mt-5 p-5 artist-signup__login__options">
                            {errMsgPart1}
                            <div className="my-3">
                                <Button className="btn btn-primary btn--midlarge" onClick = {this.onClickLink}>SWITCH USER</Button>
                            </div>
                            {errMsgPart2}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
