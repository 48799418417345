import React from "react";
import { AxiosResponse, AxiosError } from "axios";
import DataGrid, { Pager, Paging, FilterRow, Column, SearchPanel, Scrolling } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import gridUtils, { currencyTypes, currencyTypeToSymbolMatrix } from "../grid/GridUtilities";
import PaymentService, { PaymentCycleGridRowItem, PaymentCycleItem } from "../../services/PaymentService";
import UserService, { RoleGroupNames } from "../../services/UserService";

//props
interface PaymentCycleItemGridProps {
    location: any;
    history: any;
    id: string;
    calculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    saveClick: boolean;
    refreshSignal: boolean;
    onSaveSuccess: () => void;
    disableUpdateButtonCallback: (statusId: string) => void; //A callback that would indicate the parent page of when to disable the "Update Payments" button.
}

// State
interface PaymentCycleItemGridState {
    gridDataSource: PaymentCycleGridRowItem[];
    showProgressIndicator: boolean;
    paymentCycleItem: PaymentCycleItem[];
    loadPanel: boolean;
}

// Component - displays the simple Grid for all users
//Grid_Utilities component can be reused here to format the date and Decimal fields.
class PaymentCycleItemGrid extends React.Component<PaymentCycleItemGridProps> {
    //Initialize the component's state.
    state: PaymentCycleItemGridState;
    //Initialize the service to fetch the data.
    Service: PaymentService;
    //Reuse the Grid utils component to format the cells.
    gridUtils: gridUtils;
    userHasAccess: boolean;

    constructor(props: PaymentCycleItemGridProps) {
        super(props);
        // Initialize state and services/utils
        this.Service = new PaymentService();
        this.gridUtils = new gridUtils();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        this.state = {
            gridDataSource: [],
            showProgressIndicator: false,
            paymentCycleItem: [],
            loadPanel: false,
        };
        // Functions
        this.updateGridDataSource = this.updateGridDataSource.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    //TODO: Call the function "updateGridDataSource" to make an API call and populate the Grid.
    componentDidMount() {
        this.updateGridDataSource();
    }
    componentDidUpdate(prevprops: PaymentCycleItemGridProps) {
        if (this.props.calculationsObject != prevprops.calculationsObject) {
            this.handleCalculations(this.state.gridDataSource);
        }
        if (this.props.saveClick != prevprops.saveClick) {
            this.setData();
        }
        if (this.props.refreshSignal != prevprops.refreshSignal) {
            this.updateGridDataSource();
        }
    }

    setData = () => {
        this.setState(
            {
                showProgressIndicator: true,
                paymentCycleItem: this.state.gridDataSource,
                loadPanel: true,
            },
            () => this.saveData()
        );
    };

    saveData = () => {
        let data: PaymentCycleItem[] = this.state.paymentCycleItem;
        data[0].actionType = "Save";
        this.setState({
            paymentCycleItem: data,
            loadPanel: true,
        });
        this.onSubmit();
    };

    onSubmit = () => {
        if (this.userHasAccess) {
            this.Service.postPaymentCycleData(this.state.paymentCycleItem[0])
                .then(this.handlePostSuccess)
                .catch((err) => {
                    var respMessage: string = "postPaymentCycleData failed with response: " + JSON.stringify(err);

                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        }
    };

    handlePostSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanel: false,
        });
        this.props.onSaveSuccess();
    };

    handleCalculations = (value: any) => {
        let gridData = value;
        if (gridData.length > 0) {
            if (this.props.calculationsObject.subTotal) {
                gridData[0].subTotal = this.props.calculationsObject.subTotal;
            }
            if (this.props.calculationsObject.total) {
                gridData[0].total = this.props.calculationsObject.total;
            }
            if (this.props.calculationsObject.tax) {
                gridData[0].tax = this.props.calculationsObject.tax;
            }

            this.setState({
                gridDataSource: gridData,
                showProgressIndicator: false,
                loadPanel: false,
            });
        }
    };

    updateGridDataSource() {
        if (this.userHasAccess) {
            this.setState({
                showProgressIndicator: true,
                loadPanel: true,
            });
            this.Service.getPaymentCycleById(this.props.id).then(this.handleSuccess).catch(this.handleError);
        }
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            showProgressIndicator: true,
            loadPanel: true,
        });

        this.handleCalculations([response.data.data]);
        this.props.disableUpdateButtonCallback(response.data.data.statusId);
    }

    handleError(error: AxiosError<any>) {
        // Redirect back to home page on a 401 unauth error
        if (error.response != null && error.response.status === 401) {
            this.props.history.push({
                pathname: "/",
            });
        } else {
            this.setState({
                showProgressIndicator: false,
                loadPanel: false,
            });
            var respMessage: string = "getPaymentCycleById failed with response: " + JSON.stringify(error);

            if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                // AppInsights is not available
                console.error(respMessage);
            }
        }
    }

    render() {
        return (
            <div>
                <h4 className="ml-3 font-weight-bold">Payment Cycle - Manage Items</h4>
                {this.state.loadPanel ? (
                    <div className="starter-template">
                        <LoadIndicator id="simple-grid-indicator" height={50} width={50} visible={true} />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        columnResizingMode={"widget"}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="paymentCycleId" caption="PAYMENT CYCLE #" />
                        <Column
                            dataField="payDate"
                            caption="PAY DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                        />
                        <Column
                            dataField="paySentDate"
                            caption="PAY SENT DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                        />
                        <Column
                            dataField="subTotal"
                            caption="SUB TOTAL"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.subTotal,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                        />
                        <Column
                            dataField="tax"
                            caption="VAT"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.tax,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                        />
                        <Column
                            dataField="total"
                            caption="TOTAL"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.total,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                        />
                        <Column dataField="status" caption="STATUS" />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default PaymentCycleItemGrid;
