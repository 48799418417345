import { AxiosResponse } from "axios";
import LookupService, { LookupTypeItem } from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";

export default class SelectAndLookUpBoxUtils {    
    service = new LookupService();
    utils = new sharedUtils();

    /**
     * Retrieve the requested lookup from the server
     *
     * @param lookupFunction {() => Promise<AxiosResponse<any>>} - the service call to execute for a specific lookup
     * @param retryCount  {number} - the number of tries that should be made to retrieve data from the server
     * @returns {void}
     */
    executeLookup = (
        lookupFunction: (lookupIndex: string) => Promise<AxiosResponse<any>>,
        lookupIndex: string,
        retryCount: number,
        handleSuccess: any,
        handleError: any,

    ): void => {
        if (retryCount > 0) {
            retryCount--;

            lookupFunction(lookupIndex)
                .then(handleSuccess)
                .catch(() => this.executeLookup(this.service.getLookupByLookupTypeIndex, lookupIndex, retryCount, handleSuccess, handleError));
        } else {
            handleError("Retry count exceeded for lookup service with type ==> " + lookupIndex);
        }
    };

    /**
     * Filter the lookup item data source
     *
     * @param parentMappingId {number} - filters by this Id, so if there is no Id to filter by, disable control
     * @returns {LookuptypeItem[]} - filtered data
     */
    filterDataSource = (data: LookupTypeItem[], parentMappingId: string): LookupTypeItem[] => {
        var mappingId: number = this.utils.convertStringToInt(parentMappingId);

        return mappingId > 0
            ? data.filter((item: LookupTypeItem) => {
                  return item.parentMappingId === parentMappingId;
              })
            : data;
    };

    /**
     * Determine if the lookup should be disabled
     *
     * @param status {string} - status of lookup (loading, loaded, etc..)
     * @param parentMappingId {string} - no id may mean to disable the lookup
     * @param isRoot {boolean} - ignore disable rules
     * @returns {boolean}
     */
    isDisabledRules = (status: string, parentMappingId: string, isRoot: boolean): boolean => {
        var isDisabled = true;
        var mappingId: number = this.utils.convertStringToInt(parentMappingId);
        var falseIfMapped = mappingId > 0 ? false : true;

        if (status === "loaded") {
            isDisabled = isRoot ? false : falseIfMapped;
        }

        return isDisabled;
    };
}