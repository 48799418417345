import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { getAccountID } from "../../services/AuthService";
import GlobalService from "../../services/GlobalService";
import CookiePreferencesService, { CookieSettingsType } from "../../services/CookiePreferencesService";
import { LoadIndicator } from "devextreme-react";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";

interface CookieConsentComponentState {
    showPanel: boolean;
    closePanel: boolean;
    errorPanelVisible: boolean;
    configurationValue1: string;
    configurationValue2: string;
    showProgressIndicator: boolean;
}

class CookieConsent extends Component<RouteComponentProps> {
    state: CookieConsentComponentState;
    cookiePreferencesService: CookiePreferencesService;
    accountID: string;

    constructor(props: RouteComponentProps) {
        super(props);
        this.cookiePreferencesService = new CookiePreferencesService();
        this.state = {
            showPanel: false,
            closePanel: false,
            errorPanelVisible: false,
            configurationValue1: "",
            configurationValue2: "",
            showProgressIndicator: true
        };
        this.accountID = getAccountID();
    }

    componentDidMount = () => {
        GlobalService.getConfigurationValueDetails().then(this.handleSuccessConfigTexts).catch(this.handleError);
        this.setState({
            showPanel: this.doShowPanel()
        })
    }

    doShowPanelOnThisPage = () => {
        return this.props.location.pathname !== "/cookiePolicy";
    }

    doShowPanel = () => {
        return this.cookiePreferencesService.getCookiePreferences(this.accountID) == null && this.doShowPanelOnThisPage();
    }

    handleSuccessConfigTexts = (response: AxiosResponse<any>) => {
        let configurationValue1 = response.data.data.filter((item: any) => {
            return item.id == ConfigurableTextIndexes.CookiePopUpHeader;
        })[0].value;
        let configurationValue2 = response.data.data.filter((item: any) => {
            return item.id == ConfigurableTextIndexes.CookiePopUpIntro;
        })[0].value;
        this.setState({
            configurationValue1: configurationValue1,
            configurationValue2: configurationValue2,
            showProgressIndicator: false
        });
    };


    onAcceptAllCookies = () => {
        if (this.accountID === "") {
            this.handleError();
        } else {
            let cookieSettings: CookieSettingsType = {
                AccountID: this.accountID,
                Analytics: "Accept",
                Marketing: "Accept",
                Settings: "Accept",
            };
            this.cookiePreferencesService.setCookiePreferences(cookieSettings);

            this.setState({
                closePanel: true
            });
        }
    }

    handleError() {
        //handle issue where we could not get account ID from JWT
        this.setState({
            errorPanelVisible: true,
        });
        console.error("There was an issue retrieving the account ID from the JWT token.");
    }

    render() {
        if(!this.state.showPanel) {
            return null;
        }
        return (
            <section className={!this.state.closePanel ? "cookie-consent" : "cookie-consent--close"}>
                <div className="cookie-consent__panel w-75 w-lg-50 w-xl-25">
                {this.state.showProgressIndicator ? (
                    <LoadIndicator
                        height={20}
                        width={25}
                        visible={this.state.showProgressIndicator}
                    />
                ) : (
                    <>
                        <h1>{this.state.configurationValue1}</h1>
                        <p>{this.state.configurationValue2}</p>
                        <p className="text-danger" style={{ display: this.state.errorPanelVisible ? "block" : "none" }}>Sorry, there was a problem saving your settings, please try again or <a href="/raiseQuery">contact us</a> for assistance.</p>
                        <div className="cookie-consent__buttons d-block d-sm-flex mx-6 w-md-75">
                            <button className="btn btn-primary btn--small mb-3" onClick={this.onAcceptAllCookies}>Accept all cookies</button>
                            <Link
                                className="btn btn--ghost btn--large ml-sm-3 mb-3"
                                onClick={() => this.setState({
                                    closePanel: true
                                })}
                                to={{
                                    pathname: "/cookiePolicy",
                                    state: {
                                        id: 0,
                                    },
                                }}
                            >
                                Set options
                            </Link>       
                        </div>
                    </>
                )}
                </div>
            </section>
        );
    }
}
export default withRouter(CookieConsent);