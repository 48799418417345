import React, { Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import PaymentsGridProvider from "../PaymentsGridProvider";
import { paymentGridTypes } from "../../../services/PaymentService";
import LightModePageTemplate from "../../page/Templates/LightModeTemplate";
import SideBarTemplate from "../../side-bar/SideBarTemplate";
import BulletinPanel from "../../BulletinPanel/BulletinPanel";
import PaymentSidebarPanel from "./PaymentSidebarPanel";
import SimpleShiftsByService from "../../SimpleShiftsByService/SimpleShiftsByService";
import { ServiceType } from "../../../common/ServiceUtilities";
// import PaymentCycleGrid from "../Payments/PaymentCyclesGrid";

var isServiceChanged: boolean = false;
interface ProviderPaymentsPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

interface ProviderPaymentsPageState {
    providerId: string;
    startDate: string;
    endDate: string;
    serviceTypeId: string;
    paymentId: string;
    gridRefreshSignal: boolean;
    gigsGridVisible: boolean;
    status: [];
    isFasterPayments: boolean;
}

class ProviderPaymentsPage extends Component<ProviderPaymentsPageComponentProps> {
    //Initialize the component's state.
    state: ProviderPaymentsPageState;

    constructor(props: ProviderPaymentsPageComponentProps) {
        super(props);

        this.state = {
            providerId: "",
            startDate: "",
            endDate: "",
            serviceTypeId: "",
            paymentId: "",
            gridRefreshSignal: false,
            gigsGridVisible: false,
            status: [],
            isFasterPayments: false,
        };
    }

    //function that would indicate that a Grid refresh is required on click of the button.
    signalGridRefresh = () => {
        this.setState({ gridRefreshSignal: !this.state.gridRefreshSignal });
    };

    setPaymentId = (paymentId: string) => {
        if (paymentId) {
            this.setState({
                paymentId: paymentId,
                gigsGridVisible: true,
            });
        }
    };

    

    getServiceTypeId = (id: string) => {
        if(id !== ""){
            this.setState({
                serviceTypeId: id,
            });
        }
    };

    setSummary = () => {};
    setButtonStates = () => {};
    setTotalGigsCounter = () => {};
    setIndicatorStates = () => {};
    setCalculatedValue = () => {};

    parentIdSet = (
        providerId: string,
        startDate: string,
        endDate: string,
        serviceTypeId: string,
        paymentId: string,
        status: [],
        isFasterPayments: boolean
    ) => {
        this.setState({
            providerId: providerId ? providerId : "",
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
            serviceTypeId: serviceTypeId,
            paymentId: paymentId,
            status: status,
            isFasterPayments: isFasterPayments
        });
    };
    
    componentDidUpdate(prevProps: ProviderPaymentsPageComponentProps) {
        if (this.props.location.state?.burgerMenuName != prevProps.location.state?.burgerMenuName) {
            this.setState({
                paymentId: "",
                serviceTypeId: "",
                providerId: "",
                status: [],
                isFasterPayments: false,
            });
        }
    }

    render() {
        let checkServiceTypeId: string | null = sessionStorage.getItem("serviceTypeFilter");
        let serviceTypeId: string | null = "";
        let serviceType: string = "";
        if(this.state.serviceTypeId !== ""){            
            serviceTypeId = this.state.serviceTypeId;
        }
        else{            
            serviceTypeId = checkServiceTypeId;
        }
        if (serviceTypeId) {
            if (serviceTypeId == ServiceType.Security) {
                serviceType = "Door Supervision" + " " + "Payments";
            } else if (serviceTypeId == ServiceType.ContractCleaning) {
                serviceType = "Contract Cleaning" + " " + "Payments";
            } else if (serviceTypeId == ServiceType.Entertainment) {
                serviceType = "Entertainment" + " " + "Payments";
            } else if (serviceTypeId == ServiceType.Gardening) {
                serviceType = "Gardening" + " " + "Payments";
            } else if (serviceTypeId == ServiceType.WindowCleaning) {
                serviceType = "Window Cleaning" + " " + "Payments";
            } else if (serviceTypeId == ServiceType.Maintenance) {
                serviceType = "Maintenance" + " " + "Payments";
            } else if (serviceTypeId == ServiceType.Miscelleanous) {
                serviceType = "Miscelleanous" + " " + "Payments";
            } else {
                serviceType = "Payments";
            }
        }      
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <PaymentSidebarPanel
                        onApplyButtonClick={this.parentIdSet}
                        serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                        getServiceTypeId={this.getServiceTypeId}
                        location={this.props.location}
                    ></PaymentSidebarPanel>
                    <BulletinPanel />
                </SideBarTemplate>
                <section className="page-content--with-sidebar-hidden-mobile">
                    <h2 className="page-title__black-table">{serviceType}</h2>
                    <header className="grid-info mb-3">
                        <div className="grid-info__row mt-3 mb-3">
                            <div className="grid-info__button-container">
                                <button
                                    className={(true ? "" : "disabled") + "btn btn-primary btn--large"}
                                    onClick={this.signalGridRefresh}
                                >
                                    Refresh
                                </button>
                            </div>
                        </div>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            <PaymentsGridProvider
                                location={this.props.location}
                                history={this.props.history}
                                paymentsRefresh={this.state.gridRefreshSignal}
                                gridType={serviceTypeId == ServiceType.Entertainment ? paymentGridTypes.artistPayment : paymentGridTypes.providerPayment}
                                setPaymentIdOnParent={this.setPaymentId}
                                providerId={this.state.providerId}
                                isFasterPayments={this.state.isFasterPayments}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                                status={this.state.status}
                            />
                        </div>
                        <div />
                    </div>
                    {
                        serviceTypeId == ServiceType.Entertainment ? <h5 className="font-weight-bold ml-3 mt-4">View Gigs Included In the Selected Payment</h5> : 
                        <h5 className="font-weight-bold ml-3 mt-4">Items Included In the Selected Payment</h5>
                    }                  
                    
                    {
                        <>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <SimpleShiftsByService
                                        clientId={""}
                                        venueId={""}
                                        serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        setCalculations={this.setCalculatedValue}
                                        paymentId={this.state.paymentId}
                                        burgerMenuName={
                                            this.props.location.state && this.props.location.state.burgerMenuName
                                                ? this.props.location.state.burgerMenuName
                                                : this.props.location.state && this.props.location.state.navigateFrom ? this.props.location.state.navigateFrom : ""
                                        }
                                        getBuild={() => { return null }}
                                        navigatedFrom="providerPayments"
                                    />
                                </div>
                            </div>
                        </>
                    }
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ProviderPaymentsPage);
