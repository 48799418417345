import React from "react";
import { RouteProps } from "react-router-dom";
import { withValidUserRoleProps } from "../Auth/ProtectedPage";
import IncidentsListPage from "./IncidentsListPage";

interface IncidentsListPageContainerProps extends RouteProps, withValidUserRoleProps {}

const IncidentsListPageContainer = (props: IncidentsListPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return <IncidentsListPage title={getTitle()} category={getType()} />;
};

export default IncidentsListPageContainer;
