import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, SelectBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import sharedUtils from "../grid/sharedUtilities";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import { DateBoxOnValueChangedEvent, LookUpOnValueChangedEvent, SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import CostReportService from "../../services/CostReportService";
import CostReportUtils, { periodLookupItem } from "../CostReports/CostReportUtils";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import { PeriodMatrixTableLookup } from "../Billing/BillingFormUtils";
interface PostApprovalPanelProps extends WithTranslation {
    onApplyButtonClick: (
        clientId: string,
        venueId: string,
        serviceId: string,
        startDateId: string,
        endDateId: string,
        statusId: string
    ) => void;
    serviceTypeId: string;
    clientId: string;
    venueId: string;
    startDateId: string;
    endDateId: string;
}

interface PostApprovalPanelState {
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    dateListDataSource: periodLookupItem[];
    startDateId: string;
    startDateDisplayPeriod: string;
    startDatePeriodNumber: string;
    startDatePeriodWeek: string;
    endDateDisplayPeriod: string;
    endDatePeriodNumber: string;
    endDatePeriodWeek: string;
    endDateId: string;
    singleVenue: boolean;
    dateList: PeriodMatrixTableLookup[];
    serviceTypeloaded: false;
    serviceTypeDataSource: LookupTypeItem[];
    venueListByClient: any[];
    entireVenueList: any[];
    venueDataLoaded: boolean;
    dateFromDayRange: string;
    dateToDayRange: string;
    dateFromValue: string;
    dateToValue: string;
    disableApplyButton: boolean;
    statusTypeList: any[];
    statusId: string;
}

class PostApprovalPanel extends Component<PostApprovalPanelProps> {
    lookupService: LookupService;
    state: PostApprovalPanelState;
    sharedUtils: sharedUtils;
    Service: CostReportService;
    utils: CostReportUtils;
    constructor(props: PostApprovalPanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.sharedUtils = new sharedUtils();
        this.utils = new CostReportUtils();
        this.state = {
            clientId: this.props.clientId ? this.props.clientId : "",
            venueId: this.props.venueId ? this.props.venueId : "",
            serviceTypeId: this.props.serviceTypeId,
            dateListDataSource: [],
            startDateId: this.props.startDateId ? this.props.startDateId : "",
            startDateDisplayPeriod: "",
            startDatePeriodNumber: "",
            startDatePeriodWeek: "",
            endDateId: this.props.endDateId ? this.props.endDateId : "",
            endDateDisplayPeriod: "",
            endDatePeriodNumber: "",
            endDatePeriodWeek: "",
            singleVenue: UserService.isUserInGroup(RoleGroupNames.VenueManager) ? true : false,
            dateList: [],
            serviceTypeloaded: false,
            serviceTypeDataSource: [],
            venueListByClient: [],
            entireVenueList: [],
            venueDataLoaded: false,
            dateFromDayRange: "",
            dateToDayRange: "",
            dateFromValue: "",
            dateToValue: "",
            disableApplyButton: false,
            statusTypeList: [],
            statusId: ""
        };
    }

    //Retrieve the lookup values inside the mount
    componentDidMount() {
        var { clientId, venueId } = this.props;
        if (clientId && venueId) {
            this.retrieveLookupValues(LookupTypeIndexes.venueType);
        } else {
            this.retrieveLookupValues(LookupTypeIndexes.clientType);
        }
        this.retrieveLookupValues(LookupTypeIndexes.serviceType);
        this.retrieveLookupValues(LookupTypeIndexes.statusType);
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleClientLookupSuccess)
                    .catch((error) => {});
                break;
            case LookupTypeIndexes.serviceType:
                var listOfServiceTypes: LookupTypeItem[] = UserService.operatingServiceList();
                if (listOfServiceTypes && listOfServiceTypes.length > 0) {
                    var serviceTypeDataSource: LookupTypeItem[] = listOfServiceTypes;
                    this.setState({
                        serviceTypeloaded: true,
                       // serviceTypeId: serviceTypeDataSource[0].id,
                        serviceTypeDataSource: serviceTypeDataSource,
                    });
                }
                break;
            case LookupTypeIndexes.venueType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                    .then(this.handleVenueDataRetrievalSuccess)
                    .catch((error) =>
                        this.setState({
                            venueDataLoaded: true,
                            venueListByClient: [],
                        })
                    );
                break;
            case LookupTypeIndexes.statusType:
                var statusType = this.lookupService.getSpecificStatusTypes();
                this.setState({
                    statusTypeList : statusType,
                    statusId : statusType[1].id
                });
                break;
        }
    };

    //This function would be called only wheh a props is passed down from the landing page resulting in calling the venue endpoint.
    handleVenueDataRetrievalSuccess = (response: AxiosResponse<any>) => {
        if (response.data && response.data.data) {
            var venueData: any[] = this.sharedUtils.extractVenueListPerClient(response.data.data, this.state.clientId);
            this.setState({
                venueDataLoaded: true,
                venueListByClient: venueData,
            });
        }
    };

    handleClientLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            if (response.data.data.length == 0) {
                this.onApplyButtonClicked();
            } else {
                this.setState(
                    {
                        clientId: response.data.data[0].id,
                    },
                    () => this.retrieveVenueLookupValues()
                );
            }
        }
    };

    retrieveVenueLookupValues = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLookupSuccess)
            .catch((error) => {});
    };

    handleVenueLookupSuccess = (response: AxiosResponse<any>) => {
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            !this.isBlank(this.state.clientId)
        ) {
            var venues: any[] = response.data.data;
            var clientMappedVenue: any[] = this.sharedUtils.extractVenueListPerClient(venues, this.state.clientId);
            var singleVenue: boolean = response.data.data.length == 1 ? true : false;
            this.setState(
                {
                    venueDataLoaded: true,
                    singleVenue: singleVenue,
                    venueId: clientMappedVenue[0].id,
                    venueListByClient: clientMappedVenue,
                    entireVenueList: venues,
                },
                () => this.retrieveDateList()
            );
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        var dateListPeriodMatrix: PeriodMatrixTableLookup[] = [];
        var periodLookupListByClient: PeriodMatrixTableLookup[] = [];
        var sortedPeriodMatrixList: any[] = [];
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            dateListPeriodMatrix = response.data.data;
            periodLookupListByClient = this.utils.buildPeriodListBasedOnClient(
                this.state.clientId,
                dateListPeriodMatrix
            );
        }
        if (periodLookupListByClient && periodLookupListByClient.length > 0) {
            sortedPeriodMatrixList = periodLookupListByClient.sort(this.utils.sortPeriodMatrixByDateFrom("dateFrom"));
        }
        var dateByFilteredList = this.utils.buildPeriodListBasedOnDateCriteria(sortedPeriodMatrixList);
        var startDateId: string = "";
        var endDateId: string = "";
        var startDateValue: string = "";
        var endDateValue: string = "";
        if (dateByFilteredList && dateByFilteredList.length > 0) {
            startDateId = dateByFilteredList[0].id;
            startDateValue = dateByFilteredList[0].dateFrom;
            endDateId = dateByFilteredList[dateByFilteredList.length - 1].id;
            endDateValue = dateByFilteredList[dateByFilteredList.length - 1].dateTo;
        }
        var dateListDataSource: periodLookupItem[] = this.utils.filterDatesBasedOnClientPostApproval(
            sortedPeriodMatrixList,
            this.state.clientId
        );

        this.setState(
            {
                dateList: response.data.data,
                dateListDataSource: dateListDataSource,
                startDateId: startDateId,
                endDateId: endDateId,
                dateFromValue: startDateValue,
                dateToValue: endDateValue,
            }     
        );
    };

    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };

    //Retrieve the DateList from the server.
    retrieveDateList = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.periodMatrix)
            .then(this.handleSuccess)
            .catch((error) => {});
    };

    //A helper function that will reset the date list based on the client dropdown selection.
    resetDateList = (clientId: string, serviceTypeId?: string) => {
        var dateListPeriodMatrix: PeriodMatrixTableLookup[] = [];
        var periodLookupListByClient: PeriodMatrixTableLookup[] = [];
        var sortedPeriodMatrixList: any[] = [];
        if (this.state.dateList && this.state.dateList.length > 0) {
            dateListPeriodMatrix = this.state.dateList;
            periodLookupListByClient = this.utils.buildPeriodListBasedOnClient(
                this.state.clientId,
                dateListPeriodMatrix
            );
        }
        if (periodLookupListByClient && periodLookupListByClient.length > 0) {
            sortedPeriodMatrixList = periodLookupListByClient.sort(this.utils.sortPeriodMatrixByDateFrom("dateFrom"));
        }
        var dateByFilteredList = this.utils.buildPeriodListBasedOnDateCriteria(sortedPeriodMatrixList);
        var startDateId: string = "";
        var endDateId: string = "";
        var dateFromValue: string = "";
        var dateToValue: string = "";
        if (dateByFilteredList && dateByFilteredList.length > 0) {
            startDateId = dateByFilteredList[0].id;
            dateFromValue = dateByFilteredList[0].dateFrom;
            endDateId = dateByFilteredList[dateByFilteredList.length - 1].id;
            dateToValue = dateByFilteredList[dateByFilteredList.length - 1].dateTo;
        }
        var dateListDataSource: periodLookupItem[] = this.utils.filterDatesBasedOnClientPostApproval(
            sortedPeriodMatrixList,
            clientId
        );
        this.setState(
            {
                serviceTypeId: serviceTypeId ? serviceTypeId : this.state.serviceTypeId,
                dateListDataSource: dateListDataSource,
                startDateId: startDateId,
                endDateId: endDateId,
                dateFromValue: dateFromValue,
                dateToValue: dateToValue,
            }
        );
    };

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var venueList: any[] = this.state.entireVenueList;
        var venueDataByClientId: any[] = this.sharedUtils.extractVenueListPerClient(venueList, dxValueChange.value);
        this.setState({
            clientId: dxValueChange.value,
            venueListByClient: venueDataByClientId,
            venueId: "",
        }); //When a client dropdown is changed, initiate the API call, and default the VenueID to "", because the State is still holding the previously selected VenueId.
        this.resetDateList(dxValueChange.value);
    };

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            venueId: dxValueChange.value,
        });
    };

    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        sessionStorage.setItem("serviceTypeFilter", dxValueChange.value);
        this.setState({
            serviceTypeId: dxValueChange.value,
        });
    };

    handleChangeStartDateSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            startDateId: dxValueChange.value,
        });

        var dateFromValue: periodLookupItem[] = this.state.dateListDataSource.filter((item: any) => {
            return item.id == dxValueChange.value;
        });

        this.setState(
            {
                dateFromValue: dateFromValue.length > 0 ? dateFromValue[0].dateFrom : "",
            },
            () => this.isEnableApplyButton()
        );
    };

    isEnableApplyButton = () => {
        if (this.state.dateFromValue && this.state.dateToValue) {
            this.setState({
                disableApplyButton: false,
            });
        }
    };

    handleChangeEndDateSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            endDateId: dxValueChange.value,
        });
        var dateToValue: periodLookupItem[] = this.state.dateListDataSource.filter((item: any) => {
            return item.id == dxValueChange.value;
        });

        this.setState(
            {
                dateToValue: dateToValue.length > 0 ? dateToValue[0].dateTo : "",
            },
            () => this.isEnableApplyButton()
        );
    };

    handleChangeStatusTypeChange = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            statusId: dxValueChange.value,
        });
    };


    componentDidUpdate = (prevprops: PostApprovalPanelProps, prevState: PostApprovalPanelState) => {
        if (
            this.props.serviceTypeId != prevprops.serviceTypeId &&
            this.props.serviceTypeId != prevState.serviceTypeId
        ) {
            this.resetDateList(this.state.clientId, this.props.serviceTypeId);
        }
    };

    //Fire the parent component's callback on an Apply click.
    onApplyButtonClicked = () => {
        var venueId: string;
        var statusId: string = "";
        if (this.state.venueId == "0") {
            venueId = "";
        } else {
            venueId = this.state.venueId;
        }
        if(this.state.statusId == "1"){
           statusId = "";
        }
        else if(this.state.statusId =="2"){
            statusId = "Pending";
        }
        else if(this.state.statusId =="3"){
            statusId = "Approved";
        }
        else if(this.state.statusId =="4"){
            statusId = "Billed";
        }
        else if(this.state.statusId =="5"){
            statusId = "NotBilled";
        }
        else if(this.state.statusId =="6"){
            statusId = "Paid";
        }
        else if(this.state.statusId =="7"){
            statusId = "NotPaid";
        }
        if (this.state.dateFromValue && this.state.dateToValue) {
            this.props.onApplyButtonClick(
                this.state.clientId,
                venueId,
                this.state.serviceTypeId,
                this.state.dateFromValue,
                this.state.dateToValue,
                statusId
            );
        } else {
            this.setState({
                disableApplyButton: true,
            });
        }
    };
    render() {
        var isSingleClientandVenue: boolean = this.state.singleVenue;
        return (
            <div>
                <div className="row mt-4">
                    <div className="col-10">
                        <h4 className="sidebar__heading font-weight-bold">Filter</h4>
                    </div>
                </div>
                {isSingleClientandVenue ? (
                    <></>
                ) : (
                    <>
                        {" "}
                        <div className="row mt-3">
                            <div className="col sidebar__heading">Client</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                    onValueChanged={this.handleChangeClientTypeSelect}
                                    value={this.state.clientId}
                                />
                                <input
                                    data-testid={LookupTypeIndexes.clientType}
                                    type="hidden"
                                    name={LookupTypeIndexes.clientType}
                                ></input>
                            </div>
                        </div>
                    </>
                )}
                {isSingleClientandVenue ? (
                    <></>
                ) : (
                    <>
                        {" "}
                        <div className="row mt-3">
                            <div className="col sidebar__heading">Venue</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                {this.state.venueDataLoaded ? (
                                    <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.venueType}
                                        onValueChanged={this.handleChangeVenueTypeSelect}
                                        isRoot={false}
                                        parentMappingId={this.state.clientId}
                                        value={this.state.venueId}
                                        displayExpression={"value"}
                                        itemAll={true}
                                    />
                                ) : (
                                    <span> Loading... </span>
                                )}{" "}
                            </div>
                        </div>
                    </>
                )}

                <div className="row mt-3">
                    <div className="col sidebar__heading">Service</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <>
                            {this.state.serviceTypeloaded ? (
                                <SelectBox
                                    dataSource={this.state.serviceTypeDataSource}
                                    displayExpr={"value"}
                                    valueExpr={"id"}
                                    value={this.state.serviceTypeId}
                                    onValueChanged={this.handleChangeServiceTypeSelect}
                                />
                            ) : (
                                <span> Loading... </span>
                            )}{" "}
                        </>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Date From</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <SelectBox
                            dataSource={this.state.dateListDataSource}
                            displayExpr="value"
                            valueExpr="id"
                            onValueChanged={this.handleChangeStartDateSelect}
                            value={this.state.startDateId}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Date To</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <SelectBox
                            dataSource={this.state.dateListDataSource}
                            displayExpr="value"
                            valueExpr="id"
                            onValueChanged={this.handleChangeEndDateSelect}
                            value={this.state.endDateId}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Status</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <SelectBox
                            dataSource={this.state.statusTypeList}
                            displayExpr="value"
                            valueExpr="id"
                            onValueChanged={this.handleChangeStatusTypeChange}
                            value={this.state.statusId}
                        />
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        <Button
                            className="btn btn-primary btn--large"
                            disabled={this.state.disableApplyButton}
                            onClick={this.onApplyButtonClicked}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PostApprovalPanel);
