import React, { MouseEvent } from "react";

interface CardEquipmentSectionProps {
    exceededEquipmentQuantity: string;
    equipmentNumber: string;
    equipmentQuantity: number;
    adjustment: boolean;
    isVenueManager: boolean;
    equipmentOriginalQuantity?: number;
    equipmentBillableQuantity?: number;
    type: string;
    providerPayAmount: string;
    handleChangeMinus: () => void;
    handleChangePlus: () => void;
    calculationText: string;
    disablePlusButton: boolean;
    disableMinusButton: boolean;
}



class CardEquipmentSection extends React.Component<CardEquipmentSectionProps> {
    constructor(props: CardEquipmentSectionProps) {
        super(props);
    }

    render() {
        var equipmentNumber = `${this.props.equipmentQuantity} x ${this.props.type}`
        //Note: We won't be working with multiple Billable Items per card for the Equipment, hence a list of items is not required.
        return this.props.adjustment ? (
            <ul className="euk-card__equipment-list">
                <li>
                    {this.props.calculationText}
                    <div className="row">
                        <div className="col-4 text-right">
                            <button className="btn btn-primary btn--small-inline"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.props.handleChangeMinus();
                                }}
                                disabled={this.props.disableMinusButton}
                            >
                                <span className="text-dark font-weight-bold"> − </span>
                            </button>
                        </div>
                        <div className="col-4 text-center">
                            {equipmentNumber}
                        </div>
                        <div className="col-4 text-left">
                            <button className="btn btn-primary btn--small-inline"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.props.handleChangePlus();
                                }}
                                disabled={this.props.disablePlusButton}
                            >
                                <span className="text-dark font-weight-bold"> + </span>
                            </button>
                        </div>
                    </div>
                </li>
                <br />
                <li>{this.props.providerPayAmount}</li>
            </ul>
        ) : (
            <>
                <div className="px-3">
                    <div className="row mb-2">
                        <div className="col-6 text-right pl-0">Previously billed</div>
                        <div className="col-6">
                            {`${this.props.type}(s) x ${this.props.equipmentOriginalQuantity ? this.props.equipmentOriginalQuantity : ""
                                }`}
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-3">
                            <button className="btn btn-primary btn--small-inline"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.props.handleChangeMinus();
                                }}
                                disabled={this.props.disableMinusButton}
                            >
                                <span className="text-dark font-weight-bold"> − </span>
                            </button>
                        </div>
                        <div className="col-3 text-right pl-0 euk-card__adjustment-text">Adjustment</div>
                        <div className="col-3 euk-card__adjustment-text">
                            {`${this.props.exceededEquipmentQuantity ? this.props.exceededEquipmentQuantity : ""}`}
                        </div>
                        <div className="col-3">
                            <button className="btn btn-primary btn--small-inline"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.props.handleChangePlus();
                                }}
                                disabled={this.props.disablePlusButton}
                            >
                                <span className="text-dark font-weight-bold"> + </span>
                            </button>
                        </div>
                    </div>

                    <div className="row dashed-divider--top pt-2">
                        <div className="col-6 text-right pl-0">
                            {this.props.isVenueManager ? "Total to approve" : "Total to accept"}
                        </div>
                        <div className="col-6">
                            {`${this.props.type}(s) ${this.props.exceededEquipmentQuantity ? this.props.exceededEquipmentQuantity : ""
                                }`}
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-12 text-center">
                        {this.props.providerPayAmount}
                    </div>
                </div>
            </>
        );
    }
}
export default CardEquipmentSection;
