import { off } from "devextreme/events";
import { LookupTypeItem } from "../../services/LookupService";
import moment from "moment";

type NullableDate = Date | null | undefined;

interface ConfigurationLookupValues {
    id: string[];
    value: string[];
}

/**
 * The possible values for a BusinessEntityTaxApplicableStatus.businessEntityType
 */
export const BusinessEntityTypes = {
    Client: "Client",
    Provider: "Provider",
    Company: "Company",
};
export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

/**
 * Used with the 'react-lazyload' component (generally on card pages) to keep settings centralized.
 */
export const LazyLoadConfig = {
    height: 500,
    offset: 200,
};
export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const radioOptions = {
    UNBILLED:'unbilled',
    UNPAID:'unpaid',
    UNCONFIRMED:'unconfirmed',
    UNAPPROVED:'unapproved'
}

export const B2cColumnOptions = {
    BULKINVITE:'bulkinvite',
    INVITELINK:'invitelink',
    CREATEACCOUNT:'createaccount',
    RESETPASSWORD:'reset',
}
export default class sharedUtils {
    constructor() {
        this.convertStringToDate = this.convertStringToDate.bind(this);
        this.convertStringToTime = this.convertStringToTime.bind(this);
        this.convertNumberToString = this.convertNumberToString.bind(this);
        this.convertStringToInt = this.convertStringToInt.bind(this);
        this.convertStringToFloat = this.convertStringToFloat.bind(this);
        this.convertCurrencyToFloat = this.convertCurrencyToFloat.bind(this);
        this.convertTimeToString = this.convertTimeToString.bind(this);
        this.convertDateToString = this.convertDateToString.bind(this);
        this.fetchMinValueOfCurrentWeek = this.fetchMinValueOfCurrentWeek.bind(this);
        this.fetchMaxValueOfCurrentWeek = this.fetchMaxValueOfCurrentWeek.bind(this);
        this.getConfigurationDataFromLookupResponse = this.getConfigurationDataFromLookupResponse.bind(this);
        this.isDefaultDate = this.isDefaultDate.bind(this);
    }
    
    isDefaultDate = (dateString:string) => {
        let defaultDate = new Date('Mon 01 Jan 0001');
        let dateFrom= new Date(dateString);
        let isDefault = false;
        if(defaultDate?.toDateString() == dateFrom?.toDateString())
        isDefault = true;
        return isDefault;
    }
    // Converts a number to a string
    convertNumberToString(value: number) {
        return isNaN(value) == false && value !== null && value !== undefined ? value.toString() : "";
    }

    convertStringToInt(value: string | null | undefined) {
        return value ? parseInt(value) : 0;
    }

    convertStringToFloat(value: string | null | undefined) {
        var actualValue: string = "";
        if (value) {
            if (value.includes("£")) {
                actualValue = value.replace("£", "");
                return parseFloat(actualValue);
            } else {
                return parseFloat(value);
            }
        } else {
            return 0.0;
        }
    }

    convertBooleanToString = (boolVal: boolean) => {
        if (boolVal) {
            return "Yes";
        }
        else {
            return "No";
        }
    }

    convertBooleanToSpecificVal = (boolVal: boolean) => {
        if (boolVal) {
            return "Yes";
        }
        else {
            return "Until Further Notice";
        }
    }

    convertSpecificStringValToBoolean = (strVal: string) => {
        if (strVal && strVal == "Yes") {
            return true;
        }
        else {
            return false;
        }
    }

    convertResidencyStringValToBoolean = (strVal: string) => {
        if (strVal && strVal == "Yes") {
            return true;
        }
        else {
            return false;
        }
    }

    convertCurrencyToFloat(value: string) {
        return value ? parseFloat(value.substr(1)) : 0.0;
    }

    convertFloatToString(value: number | string) {
        return value && value != "NaN" ? value.toString() : "0.00";
    }

    // Extracts the time section of a date time value and converts to a string; if already a string, then just retur the original value
    convertTimeToString(dateTime: Date | null | undefined) {
        let timeAsString = "";

        // Only convert the dateTime if it is in fact a date, otherwise leave it alone as it will already be correct
        if (dateTime && typeof dateTime !== "string") {
            var hours = dateTime.getHours();
            var mins = dateTime.getMinutes();
            timeAsString = hours < 10 ? `0${hours}:` : `${hours}:`;
            timeAsString += mins < 10 ? `0${mins}` : `${mins}`;
        } else {
            timeAsString = dateTime ? dateTime : ""; // this dateTime is actually a string already
        }

        return timeAsString;
    }
    
    getTimeFromDateString(dateString:string){
        let formattedTime = '';
        if(dateString){
        formattedTime = moment(dateString).format('HH:mm'); // Format as HH:mm:ss
        }
        return formattedTime;
    }
    //2024-07-23T09:24:10.8221282Z this to DD/MM/YYYY
    convertTZDateToDDMMYYY(dateString: string) {
        let date = '';
        if (dateString) {
            date = moment(dateString).format("DD/MM/YYYY");
        }
        return date;
    }
    convertCurrDateToDDMMYYY() {
        let dateString = new Date();
            let formattedDate = moment(dateString).format("DD/MM/YYYY");
        return formattedDate;
    }
    convertCurrDateToTime(){
        let dateString = new Date();
        let formattedTime = '';
        if(dateString){
        formattedTime = moment(dateString).format('HH:mm'); 
        }
        return formattedTime;
    }

    formatDateCreatedTimeUTC (dateString:string){
        let formattedTime;
        if(dateString){
            let time = moment.utc(dateString).format("HH:mm");
            formattedTime = this.convertStringToTime(time);
        }
        return formattedTime;
    }

    //Function that takes in the current date and returns the current time Plus-minus a delay on the checkin Card "Add shift"
    convertTimeToCheckinCardFormat = (time: Date | null | undefined) => {
        let timeInString = "";

        // Only convert the dateTime if it is in fact a date, otherwise leave it alone as it will already be correct
        if (time && typeof time !== "string") {
            var hours = time.getHours();
            var mins = time.getMinutes();
            var timeinHours = mins < 30 ? hours : hours + 1;
            timeInString = timeinHours < 10 ? `0${timeinHours}:` : `${timeinHours}:`;
            timeInString += `00`;
        } else {
            timeInString = time ? time : ""; // this dateTime is actually a string already
        }

        return timeInString;
    };

    convertStringToDate(dateAsStringmmddyyyy: string) {
        if (dateAsStringmmddyyyy != null && dateAsStringmmddyyyy.length > 0) {
            if (dateAsStringmmddyyyy.includes("/")) {
                var parts = dateAsStringmmddyyyy.split("/"); //"11/05/2020
                var year = parseInt(parts[2], 10);
                var mm = parseInt(parts[1], 10);
                var dd = parseInt(parts[0], 10);
                // if(parts[2]?.length == 2){
                //     let dateString = `${mm}/${dd}/${year}`;
                //     return new Date(dateString);
                // } else{
                return new Date(year, mm - 1, dd);
                //}
            } else {
                return new Date(dateAsStringmmddyyyy);
            }
        } else {
            return undefined;
        }
    }

    fullDayName = (initialOfDay: string) => {
        let dayName: string = "";
        if (initialOfDay) {
            if (initialOfDay.toLowerCase() == "mon") {
                dayName = "MONDAY";
            }
            else if (initialOfDay.toLowerCase() == "tue") {
                dayName = "TUESDAY";
            }
            else if (initialOfDay.toLowerCase() == "wed") {
                dayName = "WEDNESDAY";
            }
            else if (initialOfDay.toLowerCase() == "thu") {
                dayName = "THURSDAY";
            }
            else if (initialOfDay.toLowerCase() == "fri") {
                dayName = "FRIDAY";
            }
            else if (initialOfDay.toLowerCase() == "sat") {
                dayName = "SATURDAY";
            }
            else if (initialOfDay.toLowerCase() == "sun") {
                dayName = "SUNDAY"
            }
            return dayName;
        }
    }

    fullMonthName = (initialOfMonth: string) => {
        var monthName: string = "";
        if (initialOfMonth) {
            if (initialOfMonth.toLowerCase() == "jan") {
                monthName = "JANUARY";
            }
            else if (initialOfMonth.toLowerCase() == "feb") {
                monthName = "FEBRUARY";
            }
            else if (initialOfMonth.toLowerCase() == "mar") {
                monthName = "MARCH";
            }
            else if (initialOfMonth.toLowerCase() == "apr") {
                monthName = "APRIL";
            }
            else if (initialOfMonth.toLowerCase() == "may") {
                monthName = "MAY";
            }
            else if (initialOfMonth.toLowerCase() == "jun") {
                monthName = "JUNE";
            }
            else if (initialOfMonth.toLowerCase() == "jul") {
                monthName = "JULY";
            }
            else if (initialOfMonth.toLowerCase() == "aug") {
                monthName = "AUGUST";
            }
            else if (initialOfMonth.toLowerCase() == "sep") {
                monthName = "SEPTEMBER";
            }
            else if (initialOfMonth.toLowerCase() == "oct") {
                monthName = "OCTOBER";
            }
            else if (initialOfMonth.toLowerCase() == "nov") {
                monthName = "NOVEMBER";
            }
            else if (initialOfMonth.toLowerCase() == "dec") {
                monthName = "DECEMBER";
            }
            return monthName;
        }
    }

    // convert dateTime to specific format (Tuesday 22 March)
    convertDateTimetoSpecificFormat = (dateTime: string | undefined) => {
        if (dateTime) {
            let parts: string[] = [];
            parts = dateTime.split(" ");
            let parts1 = this.fullDayName(parts[0]);
            let parts2 = this.fullMonthName(parts[1]);
            let parts3 = parts[2];
            let finalFormat: string = "";
            finalFormat = `${parts1} ${parts3} ${parts2}`;
            return finalFormat;
        }
    }

    // converting dd/mm/yyyy hh:mm to typescript compatible date format
    convertStringToFormattedDate = (date: string) => {
        if (date != null && date.length > 0) {
            if (date.includes(" ")) {
                var parts = date.split(" ");    // 11/05/2020 13:20 
                return this.convertStringToDate(parts[0]);
            }
        }
    }

    convertStringToTime(timeAsString: string) {
        if (timeAsString) {
            // Need to set the hours and mintues from string
            var hours = 0;
            var minutes = 0;
            // Use today for the date parts
            var todaysDate = new Date();
            var timeParts = timeAsString && timeAsString.length > 0 ? timeAsString.split(":") : null;
            if (timeParts) {
                hours = parseInt(timeParts[0]);
                minutes = parseInt(timeParts[1]);
            }

            return new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDay(), hours, minutes, 0, 0);
        } else {
            return undefined;
        }
    }

    formattedDateValue(formattedDate: NullableDate) {
        var dateData = formattedDate ? formattedDate.toString().split(" ") : "";
        var formattedDatePart1 = dateData[0] + " " + dateData[2] + " " + dateData[1] + " " + dateData[3];
        var hour = formattedDate ? formattedDate.getHours() : undefined;
        var minute = formattedDate ? formattedDate.getMinutes() : undefined;
        let minuteVal: string = "";
        if ((minute || minute == 0) && minute.toString().length == 1) {
            minuteVal = "0" + minute;
        }
        else if (minute) {
            minuteVal = minute.toString();
        }
        return formattedDatePart1 + "  " + hour + ":" + minuteVal;
    }

    convertDateTimeStringToTime(timeAsString: string) {
        var d = new Date(timeAsString);
        var hours = d.getHours();
        var minutes = d.getMinutes();
        // Use today for the date parts
        var todaysDate = new Date();

        return new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDay(), hours, minutes, 0, 0);
    }

    // convert Thu Feb 03 2022 14:09:00 GMT+0530 (India Standard Time) to hh:mm
    convertDateTimeStringToHHMM(date: string) {
        var convertedTime: string = "";
        var hours: string = "";
        var mins: string = "";
        var timeParts = date ? date.split(" ") : null;
        var hourMinParts = timeParts ? timeParts[4].split(":") : null;
        var hourParts = hourMinParts ? hourMinParts[0] : null;
        var minParts = hourMinParts ? hourMinParts[1] : null;
        if (timeParts) {
            hours = hourParts ? hourParts : "";
            mins = minParts ? minParts : "";
            convertedTime = hours + ":" + mins;
        }
        return convertedTime;
    }

    convertDateToString(stringAsDate: Date | null | undefined): string {
        var convertedDate = "";
        var stringDate = stringAsDate ? stringAsDate.toString() : "";

        if (stringDate.length > 0) {
            var dd = "";
            var year = 0;
            var mm = "";
            var monthInStr = "";
            var parts = stringDate.split(" ");
            year = parseInt(parts[3], 10);
            mm = parts[1];
            dd = parts[2]; // it will take the actual date (if date is coming as "01" then instead of "1" it will take "01")
            switch (mm) {
                case "Jan":
                    monthInStr = "01";
                    break;
                case "Feb":
                    monthInStr = "02";
                    break;
                case "Mar":
                    monthInStr = "03";
                    break;
                case "Apr":
                    monthInStr = "04";
                    break;
                case "May":
                    monthInStr = "05";
                    break;
                case "Jun":
                    monthInStr = "06";
                    break;
                case "Jul":
                    monthInStr = "07";
                    break;
                case "Aug":
                    monthInStr = "08";
                    break;
                case "Sep":
                    monthInStr = "09";
                    break;
                case "Oct":
                    monthInStr = "10";
                    break;
                case "Nov":
                    monthInStr = "11";
                    break;
                case "Dec":
                    monthInStr = "12";
                    break;
            }

            convertedDate = `${dd}/${monthInStr}/${year}`;
        }

        return convertedDate;
    }
    // convert Thu Feb 03 2022 14:09:00 GMT+0530 (India Standard Time) to Thu 03 Feb 2022
    convertDayDateMonthToString(stringAsDate: Date | null | undefined): string {
        var convertedDate = "";
        var stringDate = stringAsDate ? stringAsDate.toString() : "";

        if (stringDate.length > 0) {
            let dd = "";
            let year = 0;
            let mm = "";
            let day = "";
            let parts = stringDate.split(" ");
            year = parseInt(parts[3], 10);
            day = parts[0];
            mm = parts[1];
            dd = parts[2]; // it will take the actual date (if date is coming as "01" then instead of "1" it will take "01")

            convertedDate = `${day} ${dd} ${mm} ${year}`;
        }

        return convertedDate;
    }
    // dd/mm/yyyy format to dd mmm
    convertStringToFormatedDate(dateValue: string): string {
        var convertedTypeDate = "";
        if (dateValue.length > 0) {
            var monthInStr = "";
            var parts = dateValue.split("/");
            var mm = parseInt(parts[1]);
            monthInStr = months[mm - 1];
            convertedTypeDate = parts[0] + " " + monthInStr;
        }
        return convertedTypeDate;
    }

    //convert 2020-11-04T08:33:43.8408388 to 04/11/2020 08:33
    convertTDateFormatToFormatedDate = (dateValue: string) => {
        let date: string[] = [];
        let dateParts: string = "";
        let timeparts: string = "";
        if (dateValue) {
            date = dateValue.split("T");
            let parts1 = date[0];
            let parts2 = date[1];
            if (parts1) {
                let splitDate = parts1.split("-");
                dateParts = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
                let splitTimeParts = parts2.split(":");
                timeparts = splitTimeParts[0] + ":" + splitTimeParts[1];
            }
        }
        return dateParts + " " + timeparts;
    }

    getConfigurationDataFromLookupResponse(luResponse: string[]): ConfigurationLookupValues {
        var configData: ConfigurationLookupValues = {
            id: [],
            value: [],
        };

        if (luResponse.length > 0) {
            luResponse.forEach((item: any, uniqueKey: number) => {
                configData.id.push(item.id);
                configData.value.push(item.value);
            });
        }

        return configData;
    }

    getStatusLookupDataResponse(luResponse: string[]): string {
        let statusId = "";
        var statusData: ConfigurationLookupValues = {
            id: [],
            value: [],
        };

        if (luResponse.length > 0) {
            luResponse.forEach((item: any, uniqueKey: number) => {
                statusData.id.push(item.id);
                statusData.value.push(item.value);
            });
        }

        for (let index = 0; statusData.id.length > 0 && statusData.value.length > 0; index++) {
            if (statusData.value[index].toUpperCase() == "APPROVED") {
                statusId = statusData.id[index];
                break;
            }
        }

        return statusId;
    }
    // Convert a string formated "10/07/2020" into a suitable date format i.e 17th July,2020.
    convertstringToddmmmyyyyFormat(rowValue: string): string {
        var convertedDate: string = "";
        var monthName: string = "";

        if (rowValue && rowValue.length > 0) {
            var dateParts = rowValue.split("/");
            var day = parseInt(dateParts[1] ? dateParts[1] : "");
            var month = parseInt(dateParts[0] ? dateParts[0] : "");
            var year = parseInt(dateParts[2] ? dateParts[2] : "");
            monthName = months[month - 1]; //Takes in the month number and converts it into the Month Name.
            var mnth = monthName ? monthName : "";
            convertedDate = `${day} ${mnth} ${year}`;
        }
        return convertedDate;
    }
    // Convert a string formated "10/07/2020" into a suitable date format i.e 17th July,2020.
    convertstringTohhmmFormat(rowValue: string): string {
        var convertedTime: string = "";
        var hours: string = "";
        var mins: string = "";
        var timeParts = rowValue && rowValue.length > 0 ? rowValue.split(":") : null;
        if (timeParts) {
            hours = timeParts[0] ? timeParts[0] : "";
            mins = timeParts[1] ? timeParts[1] : "";
            convertedTime = hours + ":" + mins;
        }
        return convertedTime;
    }

    convertTime12to24 = (time12h: string) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = (parseInt(hours, 10) + 12).toString();
        }

        return `${hours}:${minutes}`;
    }

    // Convert a string formated "10/07/2020" into a suitable date format i.e 17th July,2020.
    convertStringToddmmmyyyyForm(rowValue: string): string {
        var convertedDate: string = "";
        var monthName: string = "";

        if (rowValue && rowValue.length > 0) {
            var dateParts = rowValue.split("/");
            var day = dateParts[0] ? dateParts[0] : "";
            var month = parseInt(dateParts[1] ? dateParts[1] : "");
            var year = dateParts[2] ? dateParts[2] : "";
            monthName = months[month - 1]; //Takes in the month number and converts it into the Month Name.
            var mnth = monthName ? monthName : "";
            convertedDate = `${day} ${mnth} ${year}`;
        }
        return convertedDate;
    }

    //Fetch current week value

    fetchMinValueOfCurrentWeek(): NullableDate {
        let minvalue: NullableDate;
        minvalue = null;
        let todaysDate = new Date().toString();
        var parts = todaysDate.split(" ");
        var day = "";
        day = parts[0];
        switch (day) {
            case "Sun":
                minvalue = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6);
                break;
            case "Mon":
                minvalue = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 0);
                break;
            case "Tue":
                minvalue = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1);
                break;
            case "Wed":
                minvalue = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2);
                break;
            case "Thu":
                minvalue = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3);
                break;
            case "Fri":
                minvalue = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4);
                break;
            case "Sat":
                minvalue = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 5);
                break;
        }

        return minvalue;
    }

    fetchMaxValueOfCurrentWeek(): NullableDate {
        let maxvalue: NullableDate;
        maxvalue = null;
        let todaysDate = new Date().toString();
        var parts = todaysDate.split(" ");
        var day = "";
        day = parts[0];
        switch (day) {
            case "Sun":
                maxvalue = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 0);
                break;
            case "Mon":
                maxvalue = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 6);
                break;
            case "Tue":
                maxvalue = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 5);
                break;
            case "Wed":
                maxvalue = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 4);
                break;
            case "Thu":
                maxvalue = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3);
                break;
            case "Fri":
                maxvalue = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 2);
                break;
            case "Sat":
                maxvalue = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 1);
                break;
        }

        return maxvalue;
    }

    //A helper function that would return the Min and Max values for a week.
    calculateWeeklyDates = (isFromDate: boolean): string => {
        let returnDate: NullableDate;
        if (isFromDate) {
            returnDate = this.fetchMinValueOfCurrentWeek();
        } else {
            returnDate = this.fetchMaxValueOfCurrentWeek();
        }
        return this.convertDateToString(returnDate);
    };

    // Convert a string formated "10/07/2020" into a suitable date format i.e 17th July,2020.
    convertddmmyyyyStringToCustomizedFormat(rowValue: string): string {
        var convertedDate: string = "";
        var monthName: string = "";

        if (rowValue && rowValue.length > 0) {
            var dateParts = rowValue.split("/");
            var day = parseInt(dateParts[0]);
            var month = parseInt(dateParts[1]);
            var year = parseInt(dateParts[2]);
            var date = new Date(year, month - 1, day, 0, 0, 0, 0);
            var dayIndex = date.getDay();
            var dayName = days[dayIndex];
            monthName = months[month - 1]; //Takes in the month number and converts it into the Month Name.
            convertedDate = `${dayName} ${day} ${monthName} ${year}`;
        }

        return convertedDate;
    }

        // MM/DD/YYYY format
    convertTZStringToDateFormat(date:string):string{
        let newDate = ''
        if(date){
            // newDate = moment(date).format('YYYY-MM-DD[T]HH:mm:ss');
            //console.log(moment(date).toISOString());
            newDate = moment(date).format('MM/DD/YYYY');
        }
       
        return newDate;
    }

    isBlank = (filterId: string | null | undefined) => {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    };

    //A helper function that would filter the venues based on Client.
    extractVenueListPerClient = (venueList: any[], clientId: string): any[] => {
        return venueList.filter((item) => {
            return item.parentMappingId == clientId;
        });
    };

    //Helper function to check if the Query box is non-empty, only then submit the query.
    //Use this throughout the applicaation.
    validationCheckFunction = (queryText: string): boolean => {
        let commentsField: string = queryText.trim();
        if (commentsField.length === 0) {
            return false;
        } else {
            return true;
        }
    };

    //function for thousands separator
    thousandsSeparator = (numberToConvert: string): string => {
        if (numberToConvert) {
            var newNumberValue = "";
            if (numberToConvert.includes("£")) {
                newNumberValue = numberToConvert.replace("£", "");
                if (!newNumberValue) {
                    newNumberValue = "0";
                }
            }
            var number = newNumberValue ? parseFloat(newNumberValue) : parseFloat(numberToConvert);
            var convertedNumber = number.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return "£" + convertedNumber;
        } else {
            return "";
        }
    };

    thousandsSeparatorLandingPage = (numberToConvert: string): string => {
        if (numberToConvert) {
            var newNumberValue = "";
            if (numberToConvert.includes("£")) {
                newNumberValue = numberToConvert.replace("£", "");
            }
            var number = newNumberValue ? parseFloat(newNumberValue) : parseFloat(numberToConvert);
            var convertedNumber = number.toLocaleString();
            return "£" + convertedNumber;
        } else {
            return "";
        }
    };

    //A common utility function that would check for the Valid Mobile No. comparing it with a predefined RegEx.
    checkMobileNumber = (mobileTelephoneNumber: string): boolean => {
        var regexForMobile = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/;
        if (mobileTelephoneNumber && mobileTelephoneNumber.match(regexForMobile)) {
            return true;
        } else {
            return false;
        }
    };

    // creation of license number format

    licenseNumberFormat = (licenseNumber: string) => {
        var idValue = "";
        var licenseNumberPart1 = "";
        var licenseNumberPart2 = "";
        var licenseNumberPart3 = "";
        var licenseNumberPart4 = "";
        var finalLicenseNumber = "";
        if (licenseNumber) {
            idValue = licenseNumber;
            licenseNumberPart1 = idValue.slice(0, 4);
            licenseNumberPart2 = idValue.slice(4, 8);
            licenseNumberPart3 = idValue.slice(8, 12);
            licenseNumberPart4 = idValue.slice(12, 16);
            finalLicenseNumber =
                licenseNumberPart1 + "-" + licenseNumberPart2 + "-" + licenseNumberPart3 + "-" + licenseNumberPart4;
        }
        return finalLicenseNumber;
    };
    dropdDownincludeAll = (dataSource: LookupTypeItem[]): LookupTypeItem[] => {
        var lookupType: LookupTypeItem = {
            id: "",
            value: "ALL",
            parentMappingId: "0",
        };
        return [lookupType].concat(dataSource);
    };
}



