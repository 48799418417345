import { DashBoardResponse } from "../../services/ScheduleService";
import sharedUtils from "../grid/sharedUtilities";


export interface DashBoardData {
    venue: {
        venueNameWOLocation: string,
        houseNumber: string,
        venueName: string,
        address: string,
        city: string,
        county: string,
        postCode: string,
        phone: string,
        email: string,
        generalManagerId: number,
        contactId: number,
        division: string,
        generalManager: {
            name: string,
            mobileTelephone: string,
        },
        contact: {
            name: string,
            mobileTelephone: string,
            primarySecurityContactId: number,
            securityProviderBusinessEntityID: number,
            securityProviderBusinessEntity: {
                officePhone: string,
                oooProtocol: string,
                shortName:string,
            },
            contractCleaningBusinessEntityID: number,
            contractCleaningBusinessEntity: {
                officePhone: string,
                oooProtocol: string,
                shortName:string,
            },
            primarySecurityContact: {
                name: string,
                mobileTelephone: string,
            },
            primaryCleaningContact: {
                name: string,
                mobileTelephone: string,
                email: string;
            },
        }
    },
    entertainmentShifts: string,
    entertainmentShiftDetails: [
        {
            name: string,
            jobRole: string,
            shiftTime: string,
            contactNo: string,
            scheduledStartTime: string,
            scheduledFinishTime: string,
        }
    ],
    cleaningShifts: string,
    cleaningShiftDetails:  { 
        message: string,
        hoursTotal:string,
        isVariable: string,
        dailyBreakdownShifts:[
                {​​​​​​​​
                    dayOfWeek: string;
                    hours: string;
                    isToday: string;
                }​​​​​​​​
            
            ]
    },
    doorSupervisionShifts: string,
    doorSupervisionShiftDetails: [
        {
            name: string,
            jobRole: string,
            shiftTime: string,
            scheduledStartTime: string,
            scheduledFinishTime: string,
        }
    ]
    gardeningShifts: string,
    gardeningShiftDetails: [
        {
            provider: string,
            serviceSubTypeLookUpName: string,
            serviceTypeLookUpName: string,
            description: string,
            clientBillableAmountLocal: string,
        }
    ],
    maintenanceShifts: string,
    maintenanceShiftDetails: [
        {
            provider: string,
            serviceSubTypeLookUpName: string,
            serviceTypeLookUpName: string,
            description: string,
            clientBillableAmountLocal: string,
        }
    ],
    miscelleanousShifts: string,
    miscelleanousShiftDetails: [
        {
            provider: string,
            serviceSubTypeLookUpName: string,
            serviceTypeLookUpName: string,
            description: string,
            clientBillableAmountLocal: string,
        }
    ],
    windowCleaningShifts: string,
    windowCleaningShiftDetails: [
        {
            provider: string,
            serviceSubTypeLookUpName: string,
            serviceTypeLookUpName: string,
            description: string,
            clientBillableAmountLocal: string,
        }
    ],
}

export default class dashBoardUtils {
    //Declare the shared utils function here.
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializeDashBoardItems = this.initializeDashBoardItems.bind(this);
        this.convertDashBoardResponseToFormData = this.convertDashBoardResponseToFormData.bind(this);
    }

    initializeDashBoardItems(): DashBoardData {
        var formData: DashBoardData = {
            venue: {
                venueNameWOLocation: "",
                houseNumber: "",
                venueName: "",
                address: "",
                city: "",
                county: "",
                postCode: "",
                phone: "",
                email: "",
                generalManagerId: 0,
                contactId: 0,
                division: "",
                generalManager: {
                    name: "",
                    mobileTelephone: "",
                },
                contact: {
                    name: "",
                    mobileTelephone: "",
                    primarySecurityContactId: 0,
                    securityProviderBusinessEntityID: 0,
                    securityProviderBusinessEntity: {
                        officePhone: "",
                        oooProtocol: "",
                        shortName:"",
                    },
                    contractCleaningBusinessEntityID: 0,
                    contractCleaningBusinessEntity: {
                        officePhone: "",
                        oooProtocol: "",
                        shortName:"",
                    },
                    primarySecurityContact: {
                        name: "",
                        mobileTelephone: "",
                    },
                    primaryCleaningContact: {
                        name: "",
                        mobileTelephone: "",
                        email: "",
                    },
                }
            },
            entertainmentShifts: "",
            entertainmentShiftDetails: [
                {
                    name: "",
                    jobRole: "",
                    shiftTime: "",
                    contactNo: "",
                    scheduledStartTime: "",
                    scheduledFinishTime: "",
                }
            ],
            cleaningShifts: "",
            cleaningShiftDetails: {
                message: "",
                hoursTotal:"",
                isVariable: "",
        dailyBreakdownShifts:[
                {​​​​​​​​
                    dayOfWeek: "",
                    hours: "",
                    isToday: "",
                }​​​​​​​​
            
            ]
            },
            doorSupervisionShifts: "",
            doorSupervisionShiftDetails: [
                {
                    name: "",
                    jobRole: "",
                    shiftTime: "",
                    scheduledStartTime: "",
                    scheduledFinishTime: "",
                }
            ],
            gardeningShifts: "",
            gardeningShiftDetails: [
                {
                    provider: "",
                    serviceSubTypeLookUpName: "",
                    serviceTypeLookUpName: "",
                    description: "",
                    clientBillableAmountLocal: "",
                }
            ],
            maintenanceShifts: "",
            maintenanceShiftDetails: [
                {
                    provider: "",
                    serviceSubTypeLookUpName: "",
                    serviceTypeLookUpName: "",
                    description: "",
                    clientBillableAmountLocal: "",
                }
            ],
            miscelleanousShifts: "",
            miscelleanousShiftDetails: [
                {
                    provider: "",
                    serviceSubTypeLookUpName: "",
                    serviceTypeLookUpName: "",
                    description: "",
                    clientBillableAmountLocal: "",
                }
            ],
            windowCleaningShifts: "",
            windowCleaningShiftDetails: [
                {
                    provider: "",
                    serviceSubTypeLookUpName: "",
                    serviceTypeLookUpName: "",
                    description: "",
                    clientBillableAmountLocal: "",
                }
            ],
        };
        return formData;
    }

    // Convert the server response into the component's form state
    convertDashBoardResponseToFormData(serverResponse: DashBoardResponse): DashBoardData {
        var formData: DashBoardData = {
            venue: serverResponse.venue,
            entertainmentShifts: serverResponse.entertainmentShifts,
            entertainmentShiftDetails: serverResponse.entertainmentShiftDetails,
            cleaningShifts: serverResponse.cleaningShifts,
            cleaningShiftDetails: serverResponse.cleaningShiftDetails,
            doorSupervisionShifts: serverResponse.doorSupervisionShifts,
            doorSupervisionShiftDetails: serverResponse.doorSupervisionShiftDetails,
            gardeningShifts: serverResponse.gardeningShifts,
            gardeningShiftDetails: serverResponse.gardeningShiftDetails,
            maintenanceShifts: serverResponse.maintenanceShifts,
            maintenanceShiftDetails: serverResponse.maintenanceShiftDetails,
            miscelleanousShifts: serverResponse.miscelleanousShifts,
            miscelleanousShiftDetails: serverResponse.miscelleanousShiftDetails,
            windowCleaningShifts: serverResponse.windowCleaningShifts,
            windowCleaningShiftDetails: serverResponse.windowCleaningShiftDetails,
        };
        return formData;
    }
}
