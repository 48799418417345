import {
    BillableItemResponse,
    BillableItemSubmitRequest,
    CalculationResponse,
    ContractHourResponse
} from "../../services/OutstandingApprovalService";
import { EquipmentType, RoleTypeForEquipment } from "../../services/AddEditPopUpService";
import { LookupTypeItem } from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import { queryCard } from "../../services/QueryService";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import { postApprovalData } from "../../services/PostApprovalService";

export type NullableDate = Date | null | undefined;

interface ConfigurationLookupValues {
    id: string[];
    value: string[];
}

interface minuteSelectBoxLookup {
    id: string;
    value: string;
}

export const feedbackScoreType = {
    thumbsUp: "5",
    thumbsDown: "1",
};

// Calculation request interface
export interface CalculationRequest {
    StartTime: string;
    originalStartTime: string;
    FinishTime: string;
    originalFinishTime: string;
    Date: string;
    ClientId: string;
    ProviderId: string;
    Quantity: string;
    originalQuantity: string;
    Rate: string;
    originalRate: string;
    ServiceTypeId: string;
    ServiceSubTypeId: string;
    itemTypeId: string;
}

export const itemTypes = {
    AdjustmentClientDebitToProviderCredit: "21",
    AdjustmentProviderDebitToClientCredit: "22",
    ProviderCredit: "17",
    ProviderDebit: "18",
    Payable: "20",
    Receivable: "19",
    ClientCredit: "15",
    ClientDebit: "16",
    AdjustmentProviderDebit: "14",
    AdjustmentClientDebit: "13",
    Acceptance: "12",
    Adjustment: "11",
    Original: "10",
};

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const daysArray = ["01", "02", "03", "04", "05", "06", "07", "08", "09"];
export interface contractHourLookup {
    day: string;
    count: string;
}

export const cardsSubType = {
    Entertainment: "30",
    personnel: "11",
    equipment: "12",
};

export const contractedHours: contractHourLookup[] = [
    {
        day: "Mon",
        count: "2",
    },
    {
        day: "Tue",
        count: "3",
    },
    {
        day: "Wed",
        count: "2",
    },
    {
        day: "Thu",
        count: "2",
    },
    {
        day: "Fri",
        count: "2",
    },
    {
        day: "Sat",
        count: "2",
    },
    {
        day: "Sun",
        count: "2",
    },
    {
        day: "Total",
        count: "15",
    },
];

export const minuteLookup: LookupTypeItem[] = [
    {
        id: "00",
        value: "00",
        parentMappingId: "0",
    },
    {
        id: "25",
        value: "15",
        parentMappingId: "0",
    },
    {
        id: "50",
        value: "30",
        parentMappingId: "0",
    },
    {
        id: "75",
        value: "45",
        parentMappingId: "0",
    },
];

export const checkInStatus = {
    green: "Green",
    red: "Red",
};

export interface AddEditFormData {
    billableItemId: string;
    childRecordId: string;
    parentRecordId: string;
    date: NullableDate;
    dateTo: NullableDate;
    client: string;
    provider: string;
    venue: string;
    service: string;
    role: string;
    name: string;
    identification_Number: string;
    cover: string;
    check_In_Out: string;
    shift: string;
    billable_Hours_Units: string;
    billable_Minutes: number;
    originalBillableMinutes: number;
    rate: string;
    rate_Decimal: number;
    originalRate: number;
    cost: string;
    status: string;
    itemTypeId: string;
    itemTypeValue: string;
    invoiceId: string;
    paymentId: string;
    paymentCycleId: string;
    comments: string;
    billable_Start_Finish: string;
    provider_Tax_Applicable: string;
    client_Tax_Applicable: string;
    fee_Tax_Applicable: string;
    pay_Date: NullableDate;
    bill_Date: NullableDate;
    provider_Pay_Currency_Local_Ccy: string;
    provider_Pay_Amount_Local_Ccy: number;
    provider_Pay_Tax_Local_Ccy: number;
    provider_Pay_Total_Local_Ccy: number;
    provider_Pay_Original_Amount_Local_Ccy: number;
    fee_Currency_Local_Ccy: string;
    fee_Amount_Local_Ccy: number;
    fee_Tax_Local_Ccy: number;
    fee_Total_Local_Ccy: number;
    fee_Percentage_Local_Ccy: number;
    client_Billable_Currency_Local_Ccy: string;
    client_Billable_Amount_Local_Ccy: number;
    client_Billable_Tax_Local_Ccy: number;
    client_Billable_Total_Local_Ccy: number;
    client_Billable_Original_Amount_Local_Ccy: number;
    provider_Pay_Amount_Base_Ccy: number;
    provider_Pay_Tax_Base_Ccy: number;
    provider_Pay_Total_Base_Ccy: number;
    fee_Amount_Base_Ccy: number;
    fee_Tax_Base_Ccy: number;
    fee_Total_Base_Ccy: number;
    fee_Percentage_Base_Ccy: number;
    client_Billable_Amount_Base_Ccy: number;
    client_Billable_Tax_Base_Ccy: number;
    client_Billable_Total_Base_Ccy: number;
    accept_Date: NullableDate;
    approval_Date: NullableDate;
    non_Standard: string;
    checkin_Color: string;
    checkin_Font: string;
    checkOut_Color: string;
    checkOut_Font: string;
    status_Color: string;
    status_BackGroundColor: string;
    shift_Color: string;
    shift_Font: string;
    tenseFlag: string;
    billableStatusLookupId: string;
    absent: string;
    unscheduled: string;
    check_In: NullableDate;
    check_Out: NullableDate;
    shift_Start: NullableDate;
    shift_End: NullableDate;
    billable_Start: NullableDate;
    billable_Finish: NullableDate;
    originalBillableStartTime: NullableDate;
    originalBillableFinishTime: NullableDate;
    typeId: string;
    identificationTypeId: string;
    equipmentQuantity: number;
    equipmentOriginalQuantity: number;
    serviceTypeId: string;
    serviceSubTypeId: string;
    clientId: string;
    providerId: string;
    venueId: string;
    isAmend: string;
    noCharge: string;
    scheduled_Hours_Units: string;
    scheduledMinutes: number;
    validationErrors: string;
    internalComments: string;
    otherDescription: string;
    requestApprover: string;
    requestDate: string;
    contractHours: ContractHourResponse | null;
    previousHoursRunningTotal: string;
    previousQuantityRunningTotal: string;
    equipmentBillableQuantity: number;
    equipmentScheduleQuantity: number;
    scheduleRate: string;
    venueName: string;
    venuePostCode: string;
    billableRate: number;
    venueTownCity: string;
    venue_Phone: string;
    shiftCompleteAcknowledgment: boolean;
    shiftCompleteAcknowledgmentDateTime: NullableDate;
    genre: string;
    equipmentRequired: string;
    entertainmentDateInfo: string;
    performanceInformation: string;
    reoccurance: string;
    contacts: ContactType[] | null;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
    additionalInformation: string;
    isAccrual: boolean;
    accrual: {

        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null
}

export interface upcomingGigDetailedData {
    billableItemId: string;
    childRecordId: string;
    ParentRecordId: string;
    date: string;
    dateTo: string;
    client: string;
    provider: string;
    venueName: string;
    venuePostCode: string;
    venueTownCity: string;
    venue: string;
    service: string;
    role: string;
    name: string;
    identification_Number: string;
    cover: string;
    check_In_Out: string;
    shift: string;
    billable_Hours_Units: string;
    billable_Minutes: string;
    originalBillableMinutes: string;
    rate: string;
    rate_Decimal: string;
    originalRate: string;
    cost: string;
    status: string;
    itemTypeId: string;
    itemTypeValue: string;
    invoiceId: string;
    paymentId: string;
    paymentCycleId: string;
    comments: string;
    internalComments: string;
    billable_Start_Finish: string;
    provider_Tax_Applicable: string;
    client_Tax_Applicable: string;
    fee_Tax_Applicable: string;
    pay_Date: string;
    bill_Date: string;
    provider_Pay_Currency_Local_Ccy: string;
    provider_Pay_Amount_Local_Ccy: string;
    provider_Pay_Tax_Local_Ccy: string;
    provider_Pay_Total_Local_Ccy: string;
    provider_Pay_Original_Amount_Local_Ccy: string;
    fee_Currency_Local_Ccy: string;
    fee_Amount_Local_Ccy: string;
    fee_Tax_Local_Ccy: string;
    fee_Total_Local_Ccy: string;
    fee_Percentage_Local_Ccy: string;
    client_Billable_Currency_Local_Ccy: string;
    client_Billable_Amount_Local_Ccy: string;
    client_Billable_Tax_Local_Ccy: string;
    client_Billable_Total_Local_Ccy: string;
    client_Billable_Original_Amount_Local_Ccy: string;
    provider_Pay_Amount_Base_Ccy: string;
    provider_Pay_Tax_Base_Ccy: string;
    provider_Pay_Total_Base_Ccy: string;
    fee_Amount_Base_Ccy: string;
    fee_Tax_Base_Ccy: string;
    fee_Total_Base_Ccy: string;
    fee_Percentage_Base_Ccy: string;
    client_Billable_Amount_Base_Ccy: string;
    client_Billable_Tax_Base_Ccy: string;
    client_Billable_Total_Base_Ccy: string;
    accept_Date: string;
    approval_Date: string;
    non_Standard: string;
    checkin_Color: string;
    checkin_Font: string;
    checkOut_Color: string;
    checkOut_Font: string;
    status_Color: string;
    status_BackGroundColor: string;
    shift_Color: string;
    shift_Font: string;
    tenseFlag: string;
    billableStatusLookupId: string;
    absent: string;
    unscheduled: string;
    check_In: string;
    check_Out: string;
    shift_Start: string;
    shift_End: string;
    billable_Start: string;
    billable_Finish: string;
    originalBillableStartTime: string;
    originalBillableFinishTime: string;
    typeId: string;
    identificationTypeId: string;
    equipmentQuantity: string;
    equipmentOriginalQuantity: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    clientId: string;
    providerId: string;
    venueId: string;
    isAmend: string;
    noCharge: string;
    scheduled_Hours_Units: string;
    scheduledMinutes: string;
    imageLink: string;
    otherDescription: string;
    requestApprover: string;
    requestDate: string;
    validationErrors: string;
    contractHours: ContractHourResponse | null;
    performanceInformation: string;
    entertainmentDateInfo: string;
    performanceInformationSetsNumber?: string;
    performanceInformationSetsDuration?: string;
    address: string;
    reoccurance: string;
    venue_Phone: string;
    genre: string;
    additionalInformation: string;
    equipmentRequired: string;
    contacts: ContactType;
    shiftCompleteAcknowledgment: boolean;
}
export interface ContactType {
    name: string;
    mobileTelephone: string;
}

export interface feedbackRequestData {
    billableItemId: string;
    feedbackScore: string;
    feedbackComments: string;
    businessEntityId: string;
    feedbackType: string;
}

export default class PostApprovalUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializeBillableItem = this.initializeBillableItem.bind(this);
        this.convertBillableItemResponeToFormData = this.convertBillableItemResponeToFormData.bind(this);
        this.convertFormDataToBillableItemRequest = this.convertFormDataToBillableItemRequest.bind(this);
    }

    initializeContractedHours = (): ContractHourResponse => {
        var contractData: ContractHourResponse = {
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
            sunday: "",
            totalHours: "",
            variableHours: "",
        };
        return contractData;
    };

    initializeFeedbackRequestItem = (
        idFromGrid: string,
        billableItemResponse: BillableItemResponse
    ): feedbackRequestData => {
        var formData: feedbackRequestData = {
            billableItemId: idFromGrid,
            feedbackScore: "",
            feedbackComments: "",
            businessEntityId: billableItemResponse.clientId,
            feedbackType: "Venue",
        };
        return formData;
    };

    initializeBillableItem(): AddEditFormData {
        var formData: AddEditFormData = {
            billableItemId: "",
            childRecordId: "",
            parentRecordId: "",
            date: undefined,
            dateTo: undefined,
            client: "",
            provider: "",
            venue: "",
            service: "",
            role: "",
            name: "",
            identification_Number: "",
            cover: "",
            check_In_Out: "",
            shift: "",
            billable_Hours_Units: "",
            billable_Minutes: 0,
            originalBillableMinutes: 0,
            rate: "",
            rate_Decimal: 0,
            originalRate: 0,
            cost: "",
            status: "",
            itemTypeId: "",
            itemTypeValue: "",
            invoiceId: "",
            paymentId: "",
            paymentCycleId: "",
            comments: "",
            billable_Start_Finish: "",
            provider_Tax_Applicable: "",
            client_Tax_Applicable: "",
            fee_Tax_Applicable: "",
            pay_Date: undefined,
            bill_Date: undefined,
            provider_Pay_Currency_Local_Ccy: "",
            provider_Pay_Amount_Local_Ccy: 0,
            provider_Pay_Tax_Local_Ccy: 0,
            provider_Pay_Total_Local_Ccy: 0,
            provider_Pay_Original_Amount_Local_Ccy: 0,
            fee_Currency_Local_Ccy: "",
            fee_Amount_Local_Ccy: 0,
            fee_Tax_Local_Ccy: 0,
            fee_Total_Local_Ccy: 0,
            fee_Percentage_Local_Ccy: 0,
            client_Billable_Currency_Local_Ccy: "",
            client_Billable_Amount_Local_Ccy: 0,
            client_Billable_Tax_Local_Ccy: 0,
            client_Billable_Total_Local_Ccy: 0,
            client_Billable_Original_Amount_Local_Ccy: 0,
            provider_Pay_Amount_Base_Ccy: 0,
            provider_Pay_Tax_Base_Ccy: 0,
            provider_Pay_Total_Base_Ccy: 0,
            fee_Amount_Base_Ccy: 0,
            fee_Tax_Base_Ccy: 0,
            fee_Total_Base_Ccy: 0,
            fee_Percentage_Base_Ccy: 0,
            client_Billable_Amount_Base_Ccy: 0,
            client_Billable_Tax_Base_Ccy: 0,
            client_Billable_Total_Base_Ccy: 0,
            accept_Date: undefined,
            approval_Date: undefined,
            non_Standard: "",
            checkin_Color: "",
            checkin_Font: "",
            checkOut_Color: "",
            checkOut_Font: "",
            status_Color: "",
            status_BackGroundColor: "",
            shift_Color: "",
            shift_Font: "",
            tenseFlag: "",
            billableStatusLookupId: "",
            absent: "",
            unscheduled: "",
            check_In: this.utils.convertStringToTime("00:00"),
            check_Out: this.utils.convertStringToTime("00:00"),
            shift_Start: this.utils.convertStringToTime("00:00"),
            shift_End: this.utils.convertStringToTime("00:00"),
            billable_Start: this.utils.convertStringToTime("00:00"),
            billable_Finish: this.utils.convertStringToTime("00:00"),
            originalBillableStartTime: this.utils.convertStringToTime("00:00"),
            originalBillableFinishTime: this.utils.convertStringToTime("00:00"),
            typeId: "",
            identificationTypeId: "",
            equipmentQuantity: 0,
            equipmentOriginalQuantity: 0,
            serviceTypeId: "",
            serviceSubTypeId: "",
            clientId: "",
            providerId: "",
            venueId: "",
            isAmend: "",
            noCharge: "",
            scheduled_Hours_Units: "",
            scheduledMinutes: 0,
            validationErrors: "",
            internalComments: "",
            otherDescription: "",
            requestApprover: "",
            requestDate: "",
            contractHours: this.initializeContractedHours(),
            previousHoursRunningTotal: "",
            previousQuantityRunningTotal: "",
            equipmentBillableQuantity: 0,
            equipmentScheduleQuantity: 0,
            scheduleRate: "",
            venueName: "",
            venuePostCode: "",
            billableRate: 0,
            venueTownCity: "",
            venue_Phone: "",
            shiftCompleteAcknowledgment: false,
            shiftCompleteAcknowledgmentDateTime: undefined,
            genre: "",
            equipmentRequired: "",
            entertainmentDateInfo: "",
            performanceInformation: "",
            reoccurance: "",
            contacts: null,
            performanceInformationSetsNumber: "",
            performanceInformationSetsDuration: "",
            additionalInformation: "",
            isAccrual: false,
            accrual: {

                id: 0,
                accrualTypeId: 0,
                accrualStatusId: 0,
                accrualBillableItemId: 0,
                accrualInvoiceId: 0,
                providerPayAmountAccrual: 0,
                providerPayAmountTaxAccrual: 0,
                providerPayAmountTotalAccrual: 0,
                grossProfitAmountAccrual: 0,
                grossProfitAmountTaxAccrual: 0,
                grossProfitAmountTotalAccrual: 0,
                clientBillableAmountAccrual: 0,
                clientBillableAmountTaxAccrual: 0,
                clientBillableAmountTotalAccrual: 0,
                lastUpdatedOn: "",
                lastUpdatedBy: 0
            } 
        };

        return formData;
    }

    // Convert the server response into the component's form state
    convertBillableItemResponeToFormData(serverResponse: BillableItemResponse): AddEditFormData {
        var formData: AddEditFormData = {
            billableItemId: serverResponse.billableItemId,
            childRecordId: serverResponse.childRecordId,
            parentRecordId: serverResponse.parentRecordId,
            date: this.utils.convertStringToDate(serverResponse.date),
            dateTo: this.utils.convertStringToDate(serverResponse.dateTo),
            client: serverResponse.client,
            provider: serverResponse.provider,
            venue: serverResponse.venue,
            service: serverResponse.service,
            role: serverResponse.role,
            name: serverResponse.name,
            identification_Number: serverResponse.identification_Number,
            cover: serverResponse.cover,
            check_In_Out: serverResponse.check_In_Out,
            shift: serverResponse.shift,
            billable_Hours_Units: serverResponse.billable_Hours_Units,
            billable_Minutes: this.utils.convertStringToFloat(serverResponse.billable_Minutes),
            originalBillableMinutes: this.utils.convertStringToFloat(serverResponse.originalBillableMinutes),
            rate: serverResponse.rate,
            rate_Decimal: this.utils.convertStringToFloat(serverResponse.rate_Decimal),
            originalRate: this.utils.convertStringToFloat(serverResponse.originalRate),
            cost: serverResponse.cost,
            status: serverResponse.status,
            itemTypeId: serverResponse.itemTypeId,
            itemTypeValue: serverResponse.itemTypeValue,
            invoiceId: serverResponse.invoiceId,
            paymentId: serverResponse.paymentId,
            paymentCycleId: serverResponse.paymentCycleId,
            comments: serverResponse.comments ? serverResponse.comments : "",
            billable_Start_Finish: serverResponse.billable_Start_Finish,
            provider_Tax_Applicable:
                serverResponse.provider_Tax_Applicable == "True" || serverResponse.provider_Tax_Applicable == "Yes"
                    ? "Yes"
                    : "No",
            client_Tax_Applicable:
                serverResponse.client_Tax_Applicable == "True" || serverResponse.client_Tax_Applicable == "Yes"
                    ? "Yes"
                    : "No",
            fee_Tax_Applicable:
                serverResponse.fee_Tax_Applicable == "True" || serverResponse.fee_Tax_Applicable == "Yes"
                    ? "Yes"
                    : "No",
            pay_Date: this.utils.convertStringToDate(serverResponse.pay_Date),
            bill_Date: this.utils.convertStringToDate(serverResponse.bill_Date),
            provider_Pay_Currency_Local_Ccy: serverResponse.provider_Pay_Currency_Local_Ccy,
            provider_Pay_Amount_Local_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.provider_Pay_Amount_Local_Ccy
            ),
            provider_Pay_Tax_Local_Ccy: this.utils.convertCurrencyToFloat(serverResponse.provider_Pay_Tax_Local_Ccy),
            provider_Pay_Total_Local_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.provider_Pay_Total_Local_Ccy
            ),
            provider_Pay_Original_Amount_Local_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.provider_Pay_Original_Amount_Local_Ccy
            ),
            fee_Currency_Local_Ccy: serverResponse.fee_Currency_Local_Ccy,
            fee_Amount_Local_Ccy: this.utils.convertCurrencyToFloat(serverResponse.fee_Amount_Local_Ccy),
            fee_Tax_Local_Ccy: this.utils.convertCurrencyToFloat(serverResponse.fee_Tax_Local_Ccy),
            fee_Total_Local_Ccy: this.utils.convertCurrencyToFloat(serverResponse.fee_Total_Local_Ccy),
            fee_Percentage_Local_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Percentage_Local_Ccy),
            client_Billable_Currency_Local_Ccy: serverResponse.client_Billable_Currency_Local_Ccy,
            client_Billable_Amount_Local_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.client_Billable_Amount_Local_Ccy
            ),
            client_Billable_Tax_Local_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.client_Billable_Tax_Local_Ccy
            ),
            client_Billable_Total_Local_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.client_Billable_Total_Local_Ccy
            ),
            client_Billable_Original_Amount_Local_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.client_Billable_Original_Amount_Local_Ccy
            ),
            provider_Pay_Amount_Base_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.provider_Pay_Amount_Base_Ccy
            ),
            provider_Pay_Tax_Base_Ccy: this.utils.convertCurrencyToFloat(serverResponse.provider_Pay_Tax_Base_Ccy),
            provider_Pay_Total_Base_Ccy: this.utils.convertCurrencyToFloat(serverResponse.provider_Pay_Total_Base_Ccy),
            fee_Amount_Base_Ccy: this.utils.convertCurrencyToFloat(serverResponse.fee_Amount_Base_Ccy),
            fee_Tax_Base_Ccy: this.utils.convertCurrencyToFloat(serverResponse.fee_Tax_Base_Ccy),
            fee_Total_Base_Ccy: this.utils.convertCurrencyToFloat(serverResponse.fee_Total_Base_Ccy),
            fee_Percentage_Base_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Percentage_Base_Ccy),
            client_Billable_Amount_Base_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.client_Billable_Amount_Base_Ccy
            ),
            client_Billable_Tax_Base_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.client_Billable_Tax_Base_Ccy
            ),
            client_Billable_Total_Base_Ccy: this.utils.convertCurrencyToFloat(
                serverResponse.client_Billable_Total_Base_Ccy
            ),
            accept_Date: this.utils.convertStringToDate(serverResponse.accept_Date),
            approval_Date: this.utils.convertStringToDate(serverResponse.approval_Date),
            non_Standard: serverResponse.non_Standard,
            checkin_Color: serverResponse.checkin_Color,
            checkin_Font: serverResponse.checkin_Font,
            checkOut_Color: serverResponse.checkOut_Color,
            checkOut_Font: serverResponse.checkOut_Font,
            status_Color: serverResponse.status_Color,
            status_BackGroundColor: serverResponse.status_BackGroundColor,
            shift_Color: serverResponse.shift_Color,
            shift_Font: serverResponse.shift_Font,
            tenseFlag: serverResponse.tenseFlag,
            billableStatusLookupId: serverResponse.billableStatusLookupId,
            absent: serverResponse.absent,
            unscheduled: serverResponse.unscheduled,
            check_In: this.utils.convertStringToTime(serverResponse.check_In),
            check_Out: this.utils.convertStringToTime(serverResponse.check_Out),
            shift_Start: this.utils.convertStringToTime(serverResponse.shift_Start),
            shift_End: this.utils.convertStringToTime(serverResponse.shift_End),
            billable_Start: this.utils.convertStringToTime(serverResponse.billable_Start),
            billable_Finish: this.utils.convertStringToTime(serverResponse.billable_Finish),
            originalBillableStartTime: this.utils.convertStringToTime(serverResponse.originalBillableStartTime),
            originalBillableFinishTime: this.utils.convertStringToTime(serverResponse.originalBillableFinishTime),
            typeId: serverResponse.typeId,
            identificationTypeId: serverResponse.identificationTypeId,
            equipmentQuantity: this.utils.convertStringToFloat(serverResponse.equipmentQuantity),
            equipmentOriginalQuantity: this.utils.convertStringToFloat(serverResponse.equipmentOriginalQuantity),
            serviceTypeId: serverResponse.serviceTypeId,
            serviceSubTypeId: serverResponse.serviceSubTypeId,
            clientId: serverResponse.clientId,
            providerId: serverResponse.providerId,
            venueId: serverResponse.venueId,
            isAmend: serverResponse.isAmend,
            noCharge: serverResponse.noCharge,
            scheduled_Hours_Units: serverResponse.scheduled_Hours_Units,
            scheduledMinutes: this.utils.convertStringToFloat(serverResponse.scheduledMinutes),
            validationErrors: serverResponse.validationErrors,
            internalComments: serverResponse.internalComments,
            otherDescription: serverResponse.otherDescription,
            requestApprover: serverResponse.requestApprover,
            requestDate: serverResponse.requestDate,
            contractHours: serverResponse.contractHours ? serverResponse.contractHours : null,
            previousHoursRunningTotal: serverResponse.previousHoursRunningTotal,
            previousQuantityRunningTotal: serverResponse.previousQuantityRunningTotal,
            equipmentBillableQuantity: this.utils.convertStringToFloat(serverResponse.equipmentBillableQuantity),
            equipmentScheduleQuantity: this.utils.convertStringToFloat(serverResponse.equipmentScheduleQuantity),
            scheduleRate: serverResponse.scheduleRate,
            venueName: serverResponse.venueName,
            venuePostCode: serverResponse.venuePostCode,
            billableRate: this.utils.convertStringToFloat(serverResponse.billableRate),
            venueTownCity: serverResponse.venueTownCity,
            venue_Phone: serverResponse.venue_Phone,
            shiftCompleteAcknowledgment: serverResponse.shiftCompleteAcknowledgment,
            shiftCompleteAcknowledgmentDateTime:  this.utils.convertStringToDate(
                serverResponse.shiftCompleteAcknowledgmentDateTime
            ),
            genre: serverResponse.genre,
            equipmentRequired: serverResponse.equipmentRequired,
            entertainmentDateInfo: serverResponse.entertainmentDateInfo,
            performanceInformation: serverResponse.performanceInformation,
            reoccurance: serverResponse.reoccurance,
            contacts: serverResponse.contacts ? serverResponse.contacts : null,
            performanceInformationSetsNumber: serverResponse.performanceInformationSetsNumber,
            performanceInformationSetsDuration: serverResponse.performanceInformationSetsDuration,
            additionalInformation: serverResponse.additionalInformation,
            isAccrual: serverResponse.isAccrual,
            accrual: serverResponse.accrual
        };

        return formData;
    }

    // Convert the form's data into a proper billable item submit request
    convertFormDataToBillableItemRequest(
        formData: AddEditFormData,
        statusId: string,
        isBillableFieldsBlank: boolean,
        acceptDate?: NullableDate,
        approvalDate?: NullableDate,
        isAbsent?: string,
        isNoCharge?: string,
        isAmendActivity?: boolean,
        billableItemIdValueForAdjustment?: string,
        navigatedFrom?: string
    ): BillableItemSubmitRequest {
        var submitRequest: BillableItemSubmitRequest = {
            absent: isAbsent ? isAbsent : formData.absent,
            accept_Date:
                acceptDate != undefined || acceptDate != null
                    ? this.utils.convertDateToString(acceptDate)
                    : billableItemIdValueForAdjustment != ""
                        ? ""
                        : this.utils.convertDateToString(formData.accept_Date),
            approval_Date:
                approvalDate != undefined || approvalDate != null
                    ? this.utils.convertDateToString(approvalDate)
                    : billableItemIdValueForAdjustment != ""
                        ? ""
                        : isAmendActivity
                            ? this.utils.convertDateToString(formData.approval_Date)
                                ? this.utils.convertDateToString(formData.approval_Date)
                                : this.utils.convertDateToString(new Date())
                            : this.utils.convertDateToString(formData.approval_Date),
            bill_Date: billableItemIdValueForAdjustment != "" ? "" : this.utils.convertDateToString(formData.bill_Date),
            billable_Finish: isBillableFieldsBlank ? "" : this.utils.convertTimeToString(formData.billable_Finish),
            billable_Hours_Units: this.utils.convertNumberToString(formData.billable_Minutes),
            //billable_Minutes: "", <== this is a derived field;  send back as billable_Hours_Units
            billable_Start: isBillableFieldsBlank ? "" : this.utils.convertTimeToString(formData.billable_Start),
            billable_Start_Finish: formData.billable_Start_Finish,
            billableItemId: formData.billableItemId,
            billableStatusLookupId: statusId ? statusId : formData.billableStatusLookupId,
            check_In: isBillableFieldsBlank ? "" : this.utils.convertTimeToString(formData.check_In),
            check_In_Out: "",
            check_Out: isBillableFieldsBlank ? "" : this.utils.convertTimeToString(formData.check_Out),
            checkin_Color: "", // do not send back
            checkin_Font: "", // do not send back
            checkOut_Color: "", // do not send back
            checkOut_Font: "", // do not send back
            client: "", // do not know what this is, but it is not on the formData object
            client_Billable_Amount_Base_Ccy: this.utils.convertNumberToString(formData.client_Billable_Amount_Base_Ccy),
            client_Billable_Amount_Local_Ccy: this.utils.convertNumberToString(
                formData.client_Billable_Amount_Local_Ccy
            ),
            client_Billable_Currency_Local_Ccy: formData.client_Billable_Currency_Local_Ccy,
            client_Billable_Original_Amount_Local_Ccy: this.utils.convertNumberToString(
                formData.client_Billable_Original_Amount_Local_Ccy
            ),
            client_Billable_Tax_Base_Ccy: this.utils.convertNumberToString(formData.client_Billable_Tax_Base_Ccy),
            client_Billable_Tax_Local_Ccy: this.utils.convertNumberToString(formData.client_Billable_Tax_Local_Ccy),
            client_Billable_Total_Base_Ccy: this.utils.convertNumberToString(formData.client_Billable_Total_Base_Ccy),
            client_Billable_Total_Local_Ccy: this.utils.convertNumberToString(formData.client_Billable_Total_Local_Ccy),
            client_Tax_Applicable: formData.client_Tax_Applicable,
            clientId: formData.clientId,
            comments: formData.comments,
            cost: "", // this is not on the formData object
            cover: formData.cover,
            date: this.utils.convertDateToString(formData.date),
            dateTo: this.utils.convertDateToString(formData.dateTo),
            equipmentQuantity: this.utils.convertNumberToString(formData.equipmentQuantity),
            fee_Amount_Base_Ccy: this.utils.convertNumberToString(formData.fee_Amount_Base_Ccy),
            fee_Amount_Local_Ccy: this.utils.convertNumberToString(formData.fee_Amount_Local_Ccy),
            fee_Currency_Local_Ccy: formData.fee_Currency_Local_Ccy,
            fee_Percentage_Base_Ccy: this.utils.convertNumberToString(formData.fee_Percentage_Base_Ccy),
            fee_Percentage_Local_Ccy: this.utils.convertNumberToString(formData.fee_Percentage_Local_Ccy),
            fee_Tax_Applicable: formData.fee_Tax_Applicable,
            fee_Tax_Base_Ccy: this.utils.convertNumberToString(formData.fee_Tax_Base_Ccy),
            fee_Tax_Local_Ccy: this.utils.convertNumberToString(formData.fee_Tax_Local_Ccy),
            fee_Total_Base_Ccy: this.utils.convertNumberToString(formData.fee_Total_Base_Ccy),
            fee_Total_Local_Ccy: this.utils.convertNumberToString(formData.fee_Total_Local_Ccy),
            identification_Number: formData.identification_Number,
            identificationTypeId: formData.identificationTypeId,
            name: formData.name,
            non_Standard: formData.non_Standard,
            pay_Date: billableItemIdValueForAdjustment != "" ? "" : this.utils.convertDateToString(formData.pay_Date),
            provider: formData.provider,
            provider_Pay_Amount_Base_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Amount_Base_Ccy),
            provider_Pay_Amount_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Amount_Local_Ccy),
            provider_Pay_Currency_Local_Ccy: formData.provider_Pay_Currency_Local_Ccy,
            provider_Pay_Original_Amount_Local_Ccy: this.utils.convertNumberToString(
                formData.provider_Pay_Original_Amount_Local_Ccy
            ),
            provider_Pay_Tax_Base_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Tax_Base_Ccy),
            provider_Pay_Tax_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Tax_Local_Ccy),
            provider_Pay_Total_Base_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Total_Base_Ccy),
            provider_Pay_Total_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Total_Local_Ccy),
            provider_Tax_Applicable: formData.provider_Tax_Applicable,
            providerId: formData.providerId,
            //rate_Decimal: // send back as rate
            rate: this.utils.convertNumberToString(formData.rate_Decimal),
            role: formData.role,
            service: formData.service,
            serviceSubTypeId: formData.serviceSubTypeId.toString(),
            serviceTypeId: formData.serviceTypeId.toString(),
            shift: formData.shift, // not on the formData object
            shift_Color: "", // do not send back
            shift_Font: "", // do not send back
            shift_End:
                isBillableFieldsBlank && formData.serviceTypeId != ServiceType.Entertainment
                    ? ""
                    : this.utils.convertTimeToString(formData.shift_End),
            shift_Start:
                isBillableFieldsBlank && formData.serviceTypeId != ServiceType.Entertainment
                    ? ""
                    : this.utils.convertTimeToString(formData.shift_Start),
            status: formData.status,
            status_BackGroundColor: "", // do not send back
            status_Color: "", // do not send back
            tenseFlag: "", // do not send back
            typeId: formData.typeId ? formData.typeId : "",
            unscheduled: formData.unscheduled,
            validationErrors: null, // must be null
            venueId: formData.venueId,
            venue: formData.venue,
            isAmend: isAmendActivity ? isAmendActivity.toString() : "",
            invoiceId: formData.invoiceId,
            paymentId: formData.paymentId,
            itemTypeId: formData.itemTypeId,
            itemTypeValue: formData.itemTypeValue,
            paymentCycleId: formData.paymentCycleId,
            originalBillableStartTime: isBillableFieldsBlank
                ? ""
                : this.utils.convertTimeToString(formData.originalBillableStartTime),
            originalBillableFinishTime: isBillableFieldsBlank
                ? ""
                : this.utils.convertTimeToString(formData.originalBillableFinishTime),
            originalRate: this.utils.convertNumberToString(formData.originalRate),
            equipmentOriginalQuantity: this.utils.convertNumberToString(formData.equipmentOriginalQuantity),
            noCharge: isNoCharge ? isNoCharge : formData.noCharge,
            originalBillableMinutes: this.utils.convertNumberToString(formData.originalBillableMinutes),
            childRecordId: formData.childRecordId,
            parentRecordId: formData.parentRecordId,
            internalComments: formData.internalComments,
            otherDescription: formData.otherDescription,
            requestApprover: formData.requestApprover,
            requestDate: formData.requestDate,
            contractHours: formData.contractHours ? formData.contractHours : null,
            equipmentBillableQuantity: this.utils.convertNumberToString(formData.equipmentBillableQuantity),
            equipmentScheduleQuantity: this.utils.convertNumberToString(formData.equipmentScheduleQuantity),
            scheduleRate: formData.scheduleRate,
            venueName: formData.venueName,
            venuePostCode: formData.venuePostCode,
            billableRate: this.utils.convertNumberToString(formData.billableRate),
            venueTownCity: formData.venueTownCity,
            venue_Phone: formData.venue_Phone,
            shiftCompleteAcknowledgment: formData.shiftCompleteAcknowledgment,
            shiftCompleteAcknowledgmentDateTime:this.utils.convertDateToString(
                formData.shiftCompleteAcknowledgmentDateTime
            ),
            genre: formData.genre,
            equipmentRequired: formData.equipmentRequired,
            entertainmentDateInfo: formData.entertainmentDateInfo,
            performanceInformation: formData.performanceInformation,
            reoccurance: formData.reoccurance,
            contacts: formData.contacts ? formData.contacts : null,
            performanceInformationSetsNumber: formData.performanceInformationSetsNumber,
            performanceInformationSetsDuration: formData.performanceInformationSetsDuration,
            additionalInformation: formData.additionalInformation,
            navigateFrom: navigatedFrom ? navigatedFrom : "",
            holdPayment: "",
            hardApproval: "",
            accrual: formData.accrual,
            isAccrual: formData.isAccrual,
            scheduled_Hours_Units: formData.scheduled_Hours_Units,
            scheduledMinutes: formData.scheduledMinutes.toString()
        };

        return submitRequest;
    }

    //Helper function that would accept the current state of the Card and return the request object to be posted to the server.
    constructCalculationObject(currentCardState: AddEditFormData): CalculationRequest {
        var requestObject: CalculationRequest = {
            StartTime:
                currentCardState.serviceTypeId == ServiceType.Security
                    ? this.utils.convertTimeToString(currentCardState.billable_Start)
                    : "",
            originalStartTime: this.utils.convertTimeToString(currentCardState.originalBillableStartTime),
            FinishTime:
                currentCardState.serviceTypeId == ServiceType.Security
                    ? this.utils.convertTimeToString(currentCardState.billable_Finish)
                    : "",
            originalFinishTime: this.utils.convertTimeToString(currentCardState.originalBillableFinishTime),
            Date: this.utils.convertDateToString(currentCardState.date),
            ClientId: currentCardState.clientId,
            ProviderId: currentCardState.providerId,
            Quantity: this.utils.convertNumberToString(currentCardState.equipmentQuantity),
            originalQuantity: this.utils.convertNumberToString(currentCardState.equipmentOriginalQuantity),
            Rate: this.utils.convertNumberToString(currentCardState.rate_Decimal),
            originalRate: this.utils.convertNumberToString(currentCardState.originalRate),
            ServiceTypeId: currentCardState.serviceTypeId.toString(),
            ServiceSubTypeId: currentCardState.serviceSubTypeId.toString(),
            itemTypeId: currentCardState.itemTypeId.toString()
        };
        return requestObject;
    }
    //Helper function that accepts the current state and replaces the relevant fields with response from the server post calculation.
    alterStatePostCalculation(
        currentCardState: AddEditFormData,
        serverResponse: CalculationResponse,
        configuarationValueIndex: string[]
    ): AddEditFormData {
        var provider_Pay_Tax_Local_Ccy: number = 0.0;
        var provider_Pay_Tax_Base_Ccy: number = 0.0;
        var fee_Tax_Local_Ccy: number = 0.0;
        var fee_Tax_Base_Ccy: number = 0.0;
        var client_Billable_Tax_Local_Ccy: number = 0.0;
        var client_Billable_Tax_Base_Ccy: number = 0.0;

        if (
            currentCardState.provider_Tax_Applicable.toUpperCase() == "YES" ||
            currentCardState.provider_Tax_Applicable.toUpperCase() == "TRUE"
        ) {
            provider_Pay_Tax_Local_Ccy =
                configuarationValueIndex.length > 0
                    ? this.utils.convertStringToFloat(serverResponse.providerPayAmount) *
                    parseFloat(configuarationValueIndex[2])
                    : 0.0;
            provider_Pay_Tax_Base_Ccy =
                configuarationValueIndex.length > 0
                    ? this.utils.convertStringToFloat(serverResponse.providerPayAmount) *
                    parseFloat(configuarationValueIndex[2])
                    : 0.0;
        } else if (
            currentCardState.provider_Tax_Applicable.toUpperCase() == "NO" ||
            currentCardState.provider_Tax_Applicable == "" ||
            currentCardState.provider_Tax_Applicable.toUpperCase() == "FALSE"
        ) {
            provider_Pay_Tax_Local_Ccy = 0.0;
            provider_Pay_Tax_Base_Ccy = 0.0;
        }
        // Fee Tax Applicable
        if (
            currentCardState.fee_Tax_Applicable.toUpperCase() == "YES" ||
            currentCardState.fee_Tax_Applicable.toUpperCase() == "TRUE"
        ) {
            fee_Tax_Local_Ccy =
                configuarationValueIndex.length > 0
                    ? this.utils.convertStringToFloat(serverResponse.grossProfitAmountLocal) *
                    parseFloat(configuarationValueIndex[2])
                    : 0.0;
            fee_Tax_Base_Ccy =
                configuarationValueIndex.length > 0
                    ? this.utils.convertStringToFloat(serverResponse.grossProfitAmount) *
                    parseFloat(configuarationValueIndex[2])
                    : 0.0;
        } else if (
            currentCardState.fee_Tax_Applicable.toUpperCase() == "NO" ||
            currentCardState.fee_Tax_Applicable == "" ||
            currentCardState.fee_Tax_Applicable.toUpperCase() == "FALSE"
        ) {
            fee_Tax_Local_Ccy = 0.0;
            fee_Tax_Base_Ccy = 0;
        }

        // Client Tax Applicable
        if (
            currentCardState.client_Tax_Applicable.toUpperCase() == "YES" ||
            currentCardState.client_Tax_Applicable.toUpperCase() == "TRUE"
        ) {
            if (configuarationValueIndex.length > 0) {
                client_Billable_Tax_Local_Ccy =
                    this.utils.convertStringToFloat(serverResponse.clientBillableAmountLocal) *
                    parseFloat(configuarationValueIndex[2]);
                client_Billable_Tax_Base_Ccy =
                    this.utils.convertStringToFloat(serverResponse.clientBillableAmount) *
                    parseFloat(configuarationValueIndex[2]);
            }
        } else if (
            currentCardState.client_Tax_Applicable.toUpperCase() == "NO" ||
            currentCardState.client_Tax_Applicable == "" ||
            currentCardState.client_Tax_Applicable.toUpperCase() == "FALSE"
        ) {
            client_Billable_Tax_Local_Ccy = 0.0;
            client_Billable_Tax_Base_Ccy = 0.0;
        }

        var formData: AddEditFormData = {
            billableItemId: currentCardState.billableItemId,
            childRecordId: currentCardState.childRecordId,
            parentRecordId: currentCardState.parentRecordId,
            date: currentCardState.date,
            dateTo: currentCardState.dateTo,
            client: currentCardState.client,
            provider: currentCardState.provider,
            venue: currentCardState.venue,
            service: currentCardState.service,
            role: currentCardState.role,
            name: currentCardState.name,
            identification_Number: currentCardState.identification_Number,
            cover: currentCardState.cover,
            check_In_Out: currentCardState.check_In_Out,
            shift: currentCardState.shift,
            billable_Hours_Units: currentCardState.billable_Hours_Units,
            billable_Minutes: this.utils.convertStringToFloat(serverResponse.billableMinutes),
            originalBillableMinutes: currentCardState.originalBillableMinutes,
            rate: currentCardState.rate,
            rate_Decimal: currentCardState.rate_Decimal,
            originalRate: currentCardState.originalRate,
            cost: currentCardState.cost,
            status: currentCardState.status,
            itemTypeId: currentCardState.itemTypeId,
            itemTypeValue: currentCardState.itemTypeValue,
            invoiceId: currentCardState.invoiceId,
            paymentId: currentCardState.paymentId,
            paymentCycleId: currentCardState.paymentCycleId,
            comments: currentCardState.comments,
            billable_Start_Finish: currentCardState.billable_Start_Finish,
            provider_Tax_Applicable: currentCardState.provider_Tax_Applicable,
            client_Tax_Applicable: currentCardState.client_Tax_Applicable,
            fee_Tax_Applicable: currentCardState.fee_Tax_Applicable,
            pay_Date: currentCardState.pay_Date,
            bill_Date: currentCardState.bill_Date,
            provider_Pay_Currency_Local_Ccy: serverResponse.providerPayAmountLocalCurrency,
            provider_Pay_Amount_Local_Ccy: this.utils.convertStringToFloat(serverResponse.providerPayAmount),
            provider_Pay_Tax_Local_Ccy: provider_Pay_Tax_Local_Ccy,
            provider_Pay_Total_Local_Ccy:
                this.utils.convertStringToFloat(serverResponse.providerPayAmount) + provider_Pay_Tax_Local_Ccy,
            provider_Pay_Original_Amount_Local_Ccy: currentCardState.provider_Pay_Original_Amount_Local_Ccy,
            fee_Currency_Local_Ccy: serverResponse.grossProfitAmountLocalCurrency,
            fee_Amount_Local_Ccy: this.utils.convertStringToFloat(serverResponse.grossProfitAmountLocal),
            fee_Tax_Local_Ccy: fee_Tax_Local_Ccy,
            fee_Total_Local_Ccy:
                this.utils.convertStringToFloat(serverResponse.grossProfitAmountLocal) + fee_Tax_Local_Ccy,
            fee_Percentage_Local_Ccy: currentCardState.fee_Percentage_Local_Ccy,
            client_Billable_Currency_Local_Ccy: serverResponse.clientBillableAmountLocalCurrency,
            client_Billable_Amount_Local_Ccy: this.utils.convertStringToFloat(serverResponse.clientBillableAmountLocal),
            client_Billable_Tax_Local_Ccy: client_Billable_Tax_Local_Ccy,
            client_Billable_Total_Local_Ccy:
                this.utils.convertStringToFloat(serverResponse.clientBillableAmountLocal) +
                client_Billable_Tax_Local_Ccy,
            client_Billable_Original_Amount_Local_Ccy: currentCardState.client_Billable_Original_Amount_Local_Ccy,
            provider_Pay_Amount_Base_Ccy: this.utils.convertStringToFloat(serverResponse.providerPayAmount),
            provider_Pay_Tax_Base_Ccy: provider_Pay_Tax_Base_Ccy,
            provider_Pay_Total_Base_Ccy:
                this.utils.convertStringToFloat(serverResponse.providerPayAmount) + provider_Pay_Tax_Base_Ccy,
            fee_Amount_Base_Ccy: this.utils.convertStringToFloat(serverResponse.grossProfitAmount),
            fee_Tax_Base_Ccy: fee_Tax_Base_Ccy,
            fee_Total_Base_Ccy: this.utils.convertStringToFloat(serverResponse.grossProfitAmount) + fee_Tax_Base_Ccy,
            fee_Percentage_Base_Ccy: currentCardState.fee_Percentage_Base_Ccy,
            client_Billable_Amount_Base_Ccy: this.utils.convertStringToFloat(serverResponse.clientBillableAmount),
            client_Billable_Tax_Base_Ccy: client_Billable_Tax_Base_Ccy,
            client_Billable_Total_Base_Ccy:
                this.utils.convertStringToFloat(serverResponse.providerPayAmount) + provider_Pay_Tax_Base_Ccy,
            accept_Date: currentCardState.accept_Date,
            approval_Date: currentCardState.approval_Date,
            non_Standard: currentCardState.non_Standard,
            checkin_Color: currentCardState.checkin_Color,
            checkin_Font: currentCardState.checkin_Font,
            checkOut_Color: currentCardState.checkOut_Color,
            checkOut_Font: currentCardState.checkOut_Font,
            status_Color: currentCardState.status_Color,
            status_BackGroundColor: currentCardState.status_BackGroundColor,
            shift_Color: currentCardState.shift_Color,
            shift_Font: currentCardState.shift_Font,
            tenseFlag: currentCardState.tenseFlag,
            billableStatusLookupId: currentCardState.billableStatusLookupId,
            absent: currentCardState.absent,
            unscheduled: currentCardState.unscheduled,
            check_In: currentCardState.check_In,
            check_Out: currentCardState.check_Out,
            shift_Start: currentCardState.shift_Start,
            shift_End: currentCardState.shift_End,
            billable_Start: currentCardState.billable_Start,
            billable_Finish: currentCardState.billable_Finish,
            originalBillableStartTime: currentCardState.originalBillableStartTime,
            originalBillableFinishTime: currentCardState.originalBillableFinishTime,
            typeId: currentCardState.typeId,
            identificationTypeId: currentCardState.identificationTypeId,
            equipmentQuantity: currentCardState.equipmentQuantity,
            equipmentOriginalQuantity: currentCardState.equipmentOriginalQuantity,
            serviceTypeId: currentCardState.serviceTypeId,
            serviceSubTypeId: currentCardState.serviceSubTypeId,
            clientId: currentCardState.clientId,
            providerId: currentCardState.providerId,
            venueId: currentCardState.venueId,
            isAmend: currentCardState.isAmend,
            noCharge: currentCardState.noCharge,
            scheduled_Hours_Units: currentCardState.scheduled_Hours_Units,
            scheduledMinutes: currentCardState.scheduledMinutes,
            validationErrors: currentCardState.validationErrors,
            internalComments: currentCardState.internalComments,
            otherDescription: currentCardState.otherDescription,
            requestApprover: currentCardState.requestApprover,
            requestDate: currentCardState.requestDate,
            contractHours: currentCardState.contractHours,
            previousHoursRunningTotal: currentCardState.previousHoursRunningTotal,
            previousQuantityRunningTotal: currentCardState.previousQuantityRunningTotal,
            equipmentBillableQuantity: currentCardState.equipmentBillableQuantity,
            equipmentScheduleQuantity: currentCardState.equipmentScheduleQuantity,
            scheduleRate: currentCardState.scheduleRate,
            venueName: currentCardState.venueName,
            venuePostCode: currentCardState.venuePostCode,
            billableRate: currentCardState.billableRate,
            venueTownCity: currentCardState.venueTownCity,
            venue_Phone: currentCardState.venue_Phone,
            shiftCompleteAcknowledgment: currentCardState.shiftCompleteAcknowledgment,
            shiftCompleteAcknowledgmentDateTime:  currentCardState.shiftCompleteAcknowledgmentDateTime,
            genre: currentCardState.genre,
            equipmentRequired: currentCardState.equipmentRequired,
            entertainmentDateInfo: currentCardState.entertainmentDateInfo,
            performanceInformation: currentCardState.performanceInformation,
            reoccurance: currentCardState.reoccurance,
            contacts: currentCardState.contacts,
            performanceInformationSetsNumber: currentCardState.performanceInformationSetsNumber,
            performanceInformationSetsDuration: currentCardState.performanceInformationSetsDuration,
            additionalInformation: currentCardState.additionalInformation,
            isAccrual: currentCardState.isAccrual,
            accrual: currentCardState.accrual
        };

        return formData;
    }

    convertBillableHoursToAjustmentType(rowValue: string): string {
        var extraBillableMinutes: string = "";
        if (rowValue && rowValue.length > 0) {
            if (rowValue.includes("(")) {
                extraBillableMinutes = rowValue
                    .substring(rowValue.lastIndexOf("(") + 1, rowValue.lastIndexOf(")"))
                    .trim();
            }
        }
        return extraBillableMinutes;
    }

    initializeContactType = (): ContactType => {
        var contactData: ContactType = {
            name: "",
            mobileTelephone: "",
        };
        return contactData;
    };

    initializeGigDetailedItem = (idFromGrid: string): upcomingGigDetailedData => {
        var formData: upcomingGigDetailedData = {
            billableItemId: "",
            childRecordId: "",
            ParentRecordId: "",
            date: "",
            dateTo: "",
            client: "",
            provider: "",
            venueName: "",
            venuePostCode: "",
            venueTownCity: "",
            venue: "",
            service: "",
            role: "",
            name: "",
            identification_Number: "",
            cover: "",
            check_In_Out: "",
            shift: "",
            billable_Hours_Units: "",
            billable_Minutes: "",
            originalBillableMinutes: "",
            rate: "",
            rate_Decimal: "",
            originalRate: "",
            cost: "",
            status: "",
            itemTypeId: "",
            itemTypeValue: "",
            invoiceId: "",
            paymentId: "",
            paymentCycleId: "",
            comments: "",
            internalComments: "",
            billable_Start_Finish: "",
            provider_Tax_Applicable: "",
            client_Tax_Applicable: "",
            fee_Tax_Applicable: "",
            pay_Date: "",
            bill_Date: "",
            provider_Pay_Currency_Local_Ccy: "",
            provider_Pay_Amount_Local_Ccy: "",
            provider_Pay_Tax_Local_Ccy: "",
            provider_Pay_Total_Local_Ccy: "",
            provider_Pay_Original_Amount_Local_Ccy: "",
            fee_Currency_Local_Ccy: "",
            fee_Amount_Local_Ccy: "",
            fee_Tax_Local_Ccy: "",
            fee_Total_Local_Ccy: "",
            fee_Percentage_Local_Ccy: "",
            client_Billable_Currency_Local_Ccy: "",
            client_Billable_Amount_Local_Ccy: "",
            client_Billable_Tax_Local_Ccy: "",
            client_Billable_Total_Local_Ccy: "",
            client_Billable_Original_Amount_Local_Ccy: "",
            provider_Pay_Amount_Base_Ccy: "",
            provider_Pay_Tax_Base_Ccy: "",
            provider_Pay_Total_Base_Ccy: "",
            fee_Amount_Base_Ccy: "",
            fee_Tax_Base_Ccy: "",
            fee_Total_Base_Ccy: "",
            fee_Percentage_Base_Ccy: "",
            client_Billable_Amount_Base_Ccy: "",
            client_Billable_Tax_Base_Ccy: "",
            client_Billable_Total_Base_Ccy: "",
            accept_Date: "",
            approval_Date: "",
            non_Standard: "",
            checkin_Color: "",
            checkin_Font: "",
            checkOut_Color: "",
            checkOut_Font: "",
            status_Color: "",
            status_BackGroundColor: "",
            shift_Color: "",
            shift_Font: "",
            tenseFlag: "",
            billableStatusLookupId: "",
            absent: "",
            unscheduled: "",
            check_In: "",
            check_Out: "",
            shift_Start: "",
            shift_End: "",
            billable_Start: "",
            billable_Finish: "",
            originalBillableStartTime: "",
            originalBillableFinishTime: "",
            typeId: "",
            identificationTypeId: "",
            equipmentQuantity: "",
            equipmentOriginalQuantity: "",
            serviceTypeId: "",
            serviceSubTypeId: "",
            clientId: "",
            providerId: "",
            venueId: "",
            isAmend: "",
            noCharge: "",
            scheduled_Hours_Units: "",
            scheduledMinutes: "",
            imageLink: "",
            otherDescription: "",
            requestApprover: "",
            requestDate: "",
            validationErrors: "",
            contractHours: null,
            performanceInformation: "",
            entertainmentDateInfo: "",
            address: "",
            reoccurance: "",
            venue_Phone: "",
            genre: "",
            additionalInformation: "",
            equipmentRequired: "",
            contacts: this.initializeContactType(),
            shiftCompleteAcknowledgment: false,
        };
        return formData;
    };

    //Helper function to convert Billable Minutes like "550.00" to correct format like "9 Hrs 10 mins"
    convertBillableMinutesToHoursFormat(originalBillableMinutes: number): string {
        var hours: string = "";
        var mins: string = "";
        var hoursField: number = Math.floor(originalBillableMinutes / 60);
        var minutesField: number = originalBillableMinutes % 60;
        var hourHand: string = hoursField > 1 ? "Hrs" : "Hr";
        var minHand: string = minutesField > 1 ? "Mins" : "Min";
        hours = this.utils.convertNumberToString(hoursField);
        mins = this.utils.convertNumberToString(minutesField);
        var hoursSection: string = hoursField > 0 ? `${hours} ${hourHand}` : "";
        var minSection: string = minutesField > 0 ? `${mins} ${minHand}` : "";
        return `${hoursSection} ${minSection}`;
    }

    // Convert a string formated "10/07/2020" into a suitable date format i.e 17th July,2020.
    convertddmmyyyyStringToCustomizedFormat(rowValue: string): string {
        var convertedDate: string = "";
        var monthName: string = "";
        var dayOfWeek: string = "";
        var day: number = 0;
        var month: number = 0;
        var year: number = 0;
        var dayName: string = "";
        var date: Date;

        if (rowValue && rowValue.length > 0) {
            var dateParts = rowValue.split("/");
            day = parseInt(dateParts[0]);
            month = parseInt(dateParts[1]);
            year = parseInt(dateParts[2]);
            date = new Date(year, month - 1, day, 0, 0, 0, 0);
        } else {
            date = new Date();
            day = date.getDate();
            month = date.getMonth() + 1;
            year = date.getFullYear();
        }
        var dayIndex = date.getDay();
        dayName = days[dayIndex];
        if (day >= 1 && day < 10) {
            dayOfWeek = daysArray[day - 1];
        }
        monthName = months[month - 1]; //Takes in the month number and converts it into the Month Name.
        convertedDate = `${dayName} ${dayOfWeek ? dayOfWeek : day} ${monthName} ${year}`;
        return convertedDate;
    }

    isTypeEqualTo(typeId: string) {
        var type: string = "";
        if (typeId && typeId.length > 0) {
            if (typeId == EquipmentType.BodyCam) {
                type = RoleTypeForEquipment.BodyCam;
            } else if (typeId == EquipmentType.Clicker) {
                type = RoleTypeForEquipment.Clicker;
            } else {
                type = RoleTypeForEquipment.Radio;
            }
        }
        return type;
    }

    //Helper function to split a given quantity into two separate fields.
    splitQuantityIntoHoursMinutesFormat(quantity: string): string[] {
        var delimeter: string = ".";
        var subQuantityFields: string[] = quantity.split(delimeter);
        return subQuantityFields;
    }

    //Helper function that takes in an the contractHours Node from "BillableItemResponse" and returns as a Lookup.
    populateContractTable(contractedWeeklyHours: ContractHourResponse | null): contractHourLookup[] {
        var contractHoursForWeek: contractHourLookup[] = [];
        if (contractedWeeklyHours) {
            var size: number = Object.keys(contractedWeeklyHours).length;
            var objectValues: string[] = Object.values(contractedWeeklyHours);
            for (var item = 0; item < size - 1; item++) {
                contractedHours[item].count = objectValues[item];
            }
        }
        return contractedHours;
    }

    //Function that loops over the state and calculates the total of the outstanding cards.
    outstandingCardCount = (cards: BillableItemResponse[]): number => {
        var totalCardCount: number = 0;
        cards.forEach((item) => {
            totalCardCount += 1;
        });
        return totalCardCount;
    };

    queryCardCount = (cards: queryCard[]): number[] => {
        var totalCardCount: number = 0;
        var enumerationArray: number[] = [];
        cards.forEach((item) => {
            totalCardCount += 1;
        });
        enumerationArray.push(totalCardCount);
        return enumerationArray;
    };

    initializePostApprovalCardSections = (): postApprovalData => {
        var accordionSectionData: postApprovalData = {
            shiftCount: 0,
            serviceId: 0,
            dateFrom: "",
            dateTo: "",
            periodId: "",
            totalNonQueriedCost: "",
            totalNonQueriedMinutes: "",
            isItemQueried: false,
            totalQueriedMinutes: "",
            totalQueriedCost: "",
            postApprovalSecurityCards: {
                    providerName: "",
                    onTimePersonnelSummary: {
                        totalHours: 0,
                        totalShiftHours: "",
                        totalCost: 0,
                        totalShiftCost: 0,
                        summaryBreakdown: [
                            {
                                date: "",
                                totalNonQueriedHours: "",
                                totalQueriedHours: "",
                                totalNonQueriedCost: "",
                                totalQueriedCost: "",
                                shiftCount: "",
                                shiftList: []
                            }
                        ]
                    },
                    latePersonnelSummary: {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    onTimeEquipmentSummary: {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    lateEquipmentSummary: {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    adjustmentSummary: {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        adjustmentShiftList: []
                    },
                    disputedItems : {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        adjustmentShiftList: []
                    }

            },

            postApprovalCleaningCards: {
                    providerName: "",
                    onTimeWeeklyCleaningSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    lateWeeklyCleaningSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    onTimeAdditionalSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    lateAdditionalSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    adjustmentSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        adjustmentShiftList: []
                    },
                    disputedItems : {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        adjustmentShiftList: []
                    }
            },
            postApprovalEntertainmentCards: {
                onTimeEntertainmentSummary: {
                    totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                    summaryBreakdown: [
                        {
                            date: "",
                            totalQueriedCost:"",
                            totalNonQueriedCost:"",
                            totalQueriedHours:"",
                            totalNonQueriedHours:"",
                            shiftCount: "",
                            shiftList: []
                        }
                    ]
                },
                lateEntertainmentSummary: {
                    totalQueriedCost:"",
                    totalNonQueriedCost:"",
                    totalQueriedHours:"",
                    totalNonQueriedHours:"",
                    shiftCount: 0,
                    shiftDetails: []
                },
                adjustmentSummary: {
                    totalQueriedCost:"",
                    totalNonQueriedCost:"",
                    totalQueriedHours:"",
                    totalNonQueriedHours:"",
                    shiftCount: 0,
                    adjustmentShiftList: []
                },
                disputedItems : {
                    totalNonQueriedHours: "",
                    totalQueriedHours: "",
                    totalNonQueriedCost: "",
                    totalQueriedCost: "",
                    shiftCount: 0,
                    adjustmentShiftList: []
                }
            },
            postApprovalOtherCards: {
                onTimeEntertainmentSummary: {
                    totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                    summaryBreakdown: [
                        {
                            date: "",
                            totalQueriedCost:"",
                            totalNonQueriedCost:"",
                            totalQueriedHours:"",
                            totalNonQueriedHours:"",
                            shiftCount: "",
                            shiftList: []
                        }
                    ]
                },
                lateEntertainmentSummary: {
                    totalQueriedCost:"",
                    totalNonQueriedCost:"",
                    totalQueriedHours:"",
                    totalNonQueriedHours:"",
                    shiftCount: 0,
                    shiftDetails: []
                },
                adjustmentSummary: {
                    totalQueriedCost:"",
                    totalNonQueriedCost:"",
                    totalQueriedHours:"",
                    totalNonQueriedHours:"",
                    shiftCount: 0,
                    adjustmentShiftList: []
                },
                disputedItems : {
                    totalNonQueriedHours: "",
                    totalQueriedHours: "",
                    totalNonQueriedCost: "",
                    totalQueriedCost: "",
                    shiftCount: 0,
                    adjustmentShiftList: []
                }
            }

        };
        return accordionSectionData;
    };
    alterStatePostAmedQueries(
        accordionSectionData: postApprovalData,
    ): postApprovalData {
        var formData: postApprovalData = {
            shiftCount: accordionSectionData.shiftCount,
            serviceId: accordionSectionData.serviceId,
            dateFrom: accordionSectionData.dateFrom,
            dateTo: accordionSectionData.dateTo,
            periodId: accordionSectionData.periodId,
            totalNonQueriedCost: accordionSectionData.totalNonQueriedCost,
            totalNonQueriedMinutes: accordionSectionData.totalNonQueriedMinutes,
            isItemQueried: accordionSectionData.isItemQueried,
            totalQueriedMinutes: accordionSectionData.totalQueriedMinutes,
            totalQueriedCost: accordionSectionData.totalQueriedCost,
            postApprovalSecurityCards: {
                    providerName: accordionSectionData.postApprovalSecurityCards.providerName,
                    onTimePersonnelSummary: {
                        totalHours: accordionSectionData.postApprovalSecurityCards.onTimePersonnelSummary.totalHours,
                        totalShiftHours: accordionSectionData.postApprovalSecurityCards.onTimePersonnelSummary.totalShiftHours,
                        totalCost: accordionSectionData.postApprovalSecurityCards.onTimePersonnelSummary.totalCost,
                        totalShiftCost: accordionSectionData.postApprovalSecurityCards.onTimePersonnelSummary.totalShiftCost,
                        summaryBreakdown: accordionSectionData.postApprovalSecurityCards.onTimePersonnelSummary.summaryBreakdown
                    },
                    latePersonnelSummary: {
                        totalNonQueriedHours: accordionSectionData.postApprovalSecurityCards.latePersonnelSummary.totalNonQueriedHours,
                        totalQueriedHours: accordionSectionData.postApprovalSecurityCards.latePersonnelSummary.totalQueriedHours,
                        totalNonQueriedCost: accordionSectionData.postApprovalSecurityCards.latePersonnelSummary.totalNonQueriedCost,
                        totalQueriedCost: accordionSectionData.postApprovalSecurityCards.latePersonnelSummary.totalQueriedCost,
                        shiftCount: accordionSectionData.postApprovalSecurityCards.latePersonnelSummary.shiftCount,
                        shiftDetails: accordionSectionData.postApprovalSecurityCards.latePersonnelSummary.shiftDetails
                    },
                    onTimeEquipmentSummary: {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    lateEquipmentSummary: {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    adjustmentSummary: {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        adjustmentShiftList: []
                    },
                    disputedItems : {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        adjustmentShiftList: []
                    }

            },

            postApprovalCleaningCards: {
                    providerName: "",
                    onTimeWeeklyCleaningSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    lateWeeklyCleaningSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    onTimeAdditionalSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    lateAdditionalSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        shiftDetails: []
                    },
                    adjustmentSummary: {
                        totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                        shiftCount: 0,
                        adjustmentShiftList: []
                    },
                    disputedItems : {
                        totalNonQueriedHours: "",
                        totalQueriedHours: "",
                        totalNonQueriedCost: "",
                        totalQueriedCost: "",
                        shiftCount: 0,
                        adjustmentShiftList: []
                    }
            },
            postApprovalEntertainmentCards: {
                onTimeEntertainmentSummary: {
                    totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                    summaryBreakdown: [
                        {
                            date: "",
                            totalQueriedCost:"",
                            totalNonQueriedCost:"",
                            totalQueriedHours:"",
                            totalNonQueriedHours:"",
                            shiftCount: "",
                            shiftList: []
                        }
                    ]
                },
                lateEntertainmentSummary: {
                    totalQueriedCost:"",
                    totalNonQueriedCost:"",
                    totalQueriedHours:"",
                    totalNonQueriedHours:"",
                    shiftCount: 0,
                    shiftDetails: []
                },
                adjustmentSummary: {
                    totalQueriedCost:"",
                    totalNonQueriedCost:"",
                    totalQueriedHours:"",
                    totalNonQueriedHours:"",
                    shiftCount: 0,
                    adjustmentShiftList: []
                },
                disputedItems : {
                    totalNonQueriedHours: "",
                    totalQueriedHours: "",
                    totalNonQueriedCost: "",
                    totalQueriedCost: "",
                    shiftCount: 0,
                    adjustmentShiftList: []
                }
            },
            postApprovalOtherCards: {
                onTimeEntertainmentSummary: {
                    totalQueriedCost:"",
                        totalNonQueriedCost:"",
                        totalQueriedHours:"",
                        totalNonQueriedHours:"",
                    summaryBreakdown: [
                        {
                            date: "",
                            totalQueriedCost:"",
                            totalNonQueriedCost:"",
                            totalQueriedHours:"",
                            totalNonQueriedHours:"",
                            shiftCount: "",
                            shiftList: []
                        }
                    ]
                },
                lateEntertainmentSummary: {
                    totalQueriedCost:"",
                    totalNonQueriedCost:"",
                    totalQueriedHours:"",
                    totalNonQueriedHours:"",
                    shiftCount: 0,
                    shiftDetails: []
                },
                adjustmentSummary: {
                    totalQueriedCost:"",
                    totalNonQueriedCost:"",
                    totalQueriedHours:"",
                    totalNonQueriedHours:"",
                    shiftCount: 0,
                    adjustmentShiftList: []
                },
                disputedItems : {
                    totalNonQueriedHours: "",
                    totalQueriedHours: "",
                    totalNonQueriedCost: "",
                    totalQueriedCost: "",
                    shiftCount: 0,
                    adjustmentShiftList: []
                }
            }

        }
        return formData;
    }
}
