import { VenueHierarchyFormResponse, VenueHierarchyFormRequest } from "../../services/VenueHierarachyService";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import { AxiosResponse } from "axios";
import React from "react";
import sharedUtils from "../grid/sharedUtilities";

type NullableBool = boolean | null | undefined;
export interface VenueHierarchyFormUtilProps {}
export interface AddEditVenueHierarchyFormData {
    id: string;
    venueId: string;
    venueName: string;
    contactId: string;
    contactName: string;
    houseId: string;
    clientId: string;
    clientName: string;
    userId: string;
    userName: string;
    rollId: string;
    rollName: string;
    managerId: string;
    managerName: string;
    lastUpdatedOn: string;
    lastUpdateBy: string;
    level: number | undefined;
}

export interface VenueHierarchyFormUtilState {}
export default class VenueHierarchyFormUtil extends React.Component<VenueHierarchyFormUtilProps> {
    utils: sharedUtils;
    state: VenueHierarchyFormUtilState;
    constructor(props: VenueHierarchyFormUtilProps) {
        super(props);
        this.utils = new sharedUtils();
        this.state = {};
    }

    initializeVenueFormItem = (idFromGrid: string): AddEditVenueHierarchyFormData => {
        var formData: AddEditVenueHierarchyFormData = {
            id: "0",
            venueId: "",
            venueName: "",
            contactId: "",
            contactName: "",
            houseId: "",
            clientId: "",
            clientName: "",
            userId: "",
            userName: "",
            rollId: "",
            rollName: "",
            managerId: "",
            managerName: "",
            lastUpdatedOn: "",
            lastUpdateBy: "",
            level: 1
        };

        return formData;
    };

    // Convert the server response into the component's form state
    convertServerResponseToFormData = (serverResponse: VenueHierarchyFormResponse): AddEditVenueHierarchyFormData => {
        var formData: AddEditVenueHierarchyFormData = {
            id: serverResponse.id,
            venueId: serverResponse.venueId,
            venueName: serverResponse.venueName,
            contactId: serverResponse.contactId,
            contactName: serverResponse.contactName,
            houseId: serverResponse.houseId,
            clientId: serverResponse.clientId,
            clientName: serverResponse.clientName,
            userId: serverResponse.userId,
            userName: serverResponse.userName,
            rollId: serverResponse.rollId,
            rollName: serverResponse.rollName,
            managerId: serverResponse.managerId,
            managerName: serverResponse.managerName,
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            lastUpdateBy: serverResponse.lastUpdateBy,
            level: this.utils.convertStringToInt(serverResponse.level)
        };

        return formData;
    };

    // Convert the form's data into a proper item submit request
    convertFormDataToRequest = (
        formData: AddEditVenueHierarchyFormData,
        clientId: string
    ): VenueHierarchyFormRequest => {
        var submitRequest: VenueHierarchyFormRequest = {
            id: formData.id,
            clientId: clientId,
            venueId: formData.venueId,
            contactId: formData.contactId,
            houseId: formData.houseId,
            userId: formData.userId,
            userName: formData.userName,
            rollId: formData.rollId,
            rollName: formData.rollName,
            managerId: formData.managerId,
            level: (formData.level || formData.level == 0) ? this.utils.convertNumberToString(formData.level) : ""
        };
        return submitRequest;
    };

    lookUpCreation = (response: AxiosResponse<any>): LookupTypeItem[] => {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };
}
