import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, SelectBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import sharedUtils from "../grid/sharedUtilities";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import { LookUpOnValueChangedEvent, SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";

import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
interface DashBoardSidePanelProps extends WithTranslation {
    onApplyButtonClick: (
        serviceTypeId: string,
    ) => void;
    serviceTypeId: string;
}

interface DashBoardSidePanelState {
    serviceTypeId: string;
    serviceTypeloaded: boolean;
    serviceTypeDataSource: LookupTypeItem[];
}

class DashBoardSidePanel extends Component<DashBoardSidePanelProps> {
    lookupService: LookupService;
    state: DashBoardSidePanelState;
    constructor(props:DashBoardSidePanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.state = {
            serviceTypeId: this.props.serviceTypeId,
            serviceTypeloaded: false,
            serviceTypeDataSource: []
        };
    }
    //Retrieve the lookup values inside the mount
    componentDidMount() {
        this.retrieveLookupValues(LookupTypeIndexes.serviceType);
    }
    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.serviceType:
                var listOfServiceTypes: LookupTypeItem[] = UserService.operatingServiceList();
                if (listOfServiceTypes && listOfServiceTypes.length > 0) {
                    var serviceTypeDataSource: LookupTypeItem[] = listOfServiceTypes;
                    this.setState({
                        serviceTypeloaded: true,
                        serviceTypeDataSource: serviceTypeDataSource,
                    });
                }
                break;
        }
    };
    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        sessionStorage.setItem("serviceTypeFilter", dxValueChange.value);
        this.setState({
            serviceTypeId: dxValueChange.value,
        });
    };
    
    //Fire the parent component's callback on an Apply click.
    onApplyButtonClicked = (e:any) => {
        e.preventDefault();
        this.props.onApplyButtonClick(
            this.state.serviceTypeId
        );
    };

    render() {
        return (
            <div>
                <form onSubmit={this.onApplyButtonClicked}>
                <div className="row mt-3">
                    <div className="col sidebar__heading">Service</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <>
                            {this.state.serviceTypeloaded ? (
                                <SelectBox
                                    dataSource={this.state.serviceTypeDataSource}
                                    displayExpr={"value"}
                                    valueExpr={"id"}
                                    value={this.state.serviceTypeId}
                                    onValueChanged={this.handleChangeServiceTypeSelect}
                                />
                            ) : (
                                <span> Loading... </span>
                            )}{" "}
                        </>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        {/* <Button
                            className="btn btn-primary btn--large"
                            disabled={false}
                            onClick={this.onApplyButtonClicked}
                        >
                            Apply
                        </Button> */}
                        <button type='submit'
                        className="btn btn-primary btn--large"
                        disabled={false}>Apply</button>
                    </div>
                </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(DashBoardSidePanel);
