import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DataGrid } from "devextreme-react";
import { SearchPanel, Scrolling, Paging, HeaderFilter,Search, FilterRow, Column, Export, FilterPanel, GroupPanel, Pager } from "devextreme-react/data-grid";
import SimpleGridService, { eventUkUserGridRowItem } from "../services/SimpleGridService";
import CustomStore from "devextreme/data/custom_store";
import { buildRequestParameters } from "../services/RemoteOperationsService";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface VirtualScrollingDemoProps extends WithTranslation {
    
}

interface VirtualScrollingDemoState {
    dataSource : eventUkUserGridRowItem[]
}

class VirtualScrollingDemo extends Component<VirtualScrollingDemoProps> {
    state: VirtualScrollingDemoState;
    gridService: SimpleGridService;

    constructor(props: VirtualScrollingDemoProps) {
        super(props);
        this.state = {
            dataSource: []
        }
        this.gridService = new SimpleGridService();
    }
    
    getResults = (loadOptions: any) => {
        let params = buildRequestParameters(loadOptions);

        // return this.gridService.getSimpleGridRowsWithinRemoteOperations(params)
        //     .then((response) => {
        //         return response.data;
        //     })
        //     .catch(() => { throw 'Data Loading Error'; });
    }

    componentDidMount() {
        // let dataSource = new CustomStore({ key: "billableItemId", 
        //     load: this.getResults,
        // });

        // this.setState({
        //     dataSource: dataSource
        // });
    }
    
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    <DataGrid
                        elementAttr={{
                        id: "gridContainer"
                        }}
                        dataSource={this.state.dataSource}
                        showBorders={true}
                        remoteOperations={{sorting: true, paging: true, filtering: true, grouping: true, summary: false}}
                        wordWrapEnabled={true}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <HeaderFilter visible={true}>
                            <Search enabled={true} />
                        </HeaderFilter>
                        <Export enabled={true} />
                        <FilterRow visible={true} applyFilter="auto" />
                        <GroupPanel visible = {true}/>
                        <FilterPanel visible={true} />
                        <Column
                            dataField="billableItemId" 
                            />
                        <Column 
                            dataField="clientBusinessEntity.shortName" 
                            caption="client name (sortable)" 
                        />
                        <Column 
                            dataField="comments" 
                           
                        />
                    </DataGrid>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withTranslation()(VirtualScrollingDemo);