import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";

interface paymentActionCellProps {
    data: any;
}

class ActionCell extends React.Component<paymentActionCellProps> {
    constructor(props: paymentActionCellProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    <Link
                        title="Edit"
                        className={(false ? "disabled " : "") + "icon-btn"}
                        to={{
                            pathname: "/AddEditPaymentForm",
                            state: {
                                id: this.props.data.data.paymentId,
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </Link>
                </div>
            </div>
        );
    }
}

export default ActionCell;
