import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CustomStore from "devextreme/data/custom_store";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import SimpleGridService, { GridTypes, ShiftMatrixRemoteGridRowItem } from "../../services/SimpleGridService";
import ShiftMatrixGridContents from "../../components/ShiftMatrix/ShiftMatrixGridContents";
import GlobalService from "../../services/GlobalService";
import { LoadPanel } from "devextreme-react";

interface ShiftMatrixUnpaidGridProps extends WithTranslation {
    // dateRangeFrom: string,
    // dateRangeTo: string,
    // filterText: string,
    // isShowUnbilledItems?: boolean;
    defaultPageSize:number,
    filters : any[],
    findMatchesClicked:boolean,
    reloadShiftGrid:boolean,
    payDatesGridDataSource :any[],
    updateFoundRecords: (records:any[],paySource:any[]) => void;
}

interface ShiftMatrixUnpaidGridState {
    dataSource: [],
    modifiedDataSource: ShiftMatrixRemoteGridRowItem[],
    loadIndicatorForGrid: boolean,
}

class ShiftMatrixUnpaidGrid extends Component<ShiftMatrixUnpaidGridProps> {
    state: ShiftMatrixUnpaidGridState;
    service: SimpleGridService;
    constructor(props: ShiftMatrixUnpaidGridProps) {
        super(props);
        this.state = {
            dataSource: [],
            modifiedDataSource: [],
            loadIndicatorForGrid: false
        }
        this.service = new SimpleGridService();
    }

    // updateShiftAndPayDatesSOurce =(shiftData:any[])=>{
    //     // let matchedRecords:any[]=[];
    //     // let payDatesSource:any[] =[];
    //     // if (shiftData && shiftData.length && shiftData.length > 0 && !this.props.reloadShiftGrid) {
    //     //     const newStateSource = this.state.payDatesGridDataSource.map((record) => {
    //     //         let found = shiftData.filter((data) => {
    //     //             let isFound = false;
    //     //             if (record.id == data.id && this.isPayDateEqual(record.currentPayDate, data.payDate) && record.service?.toLowerCase() == data.serviceTypeLookUp?.value?.toLowerCase()){
    //     //                 isFound = true;
    //     //                 matchedRecords.push(data);
    //     //             }
                        
    //     //             return isFound;
    //     //         })
    //     //         if (found && found.length && found.length > 0) {
    //     //             return { ...record, itemMatched: true };
    //     //         }
    //     //         else {
    //     //             return { ...record, itemMatched: false };
    //     //         }
    //     //     });

    //     //     //this.setState({ payDatesGridDataSource: newStateSource })
    //     //     payDatesSource = newStateSource;
    //     // } else {
    //     //     if(this.props.reloadShiftGrid){
    //     //         matchedRecords = shiftData;
    //     //     }
    //     // }
    //     // this.props.updateFoundRecords(matchedRecords,payDatesSource)
    // }

    isPayDateEqual = (recordPaydate: string, shiftPayDate: string) => {
        let isEqual = false;
        if (new Date(recordPaydate).getTime() === new Date(shiftPayDate).getTime()) {
            isEqual = true;
        }
        return isEqual;
    }

    getResults = (loadOptions: any) => {
        this.setState({
            loadIndicatorForGrid: true
        })
        //let params = buildRequestParameters(loadOptions);
        
        let params = buildRequestParameters(loadOptions,'filter',this.props.filters);
        params += `&skip=0&take=${this.props.defaultPageSize}`
         //params += buildRequestParameters(loadOptions,'take',this.props.defaultPageSize);
        // if (params) {
        //     if(!this.props.isShowUnbilledItems){
        //         params = params + `&dateRangeFrom=${this.props.dateRangeFrom}&dateRangeTo=${this.props.dateRangeTo}`;
        //     }
        //     else {
        //         params = params + `&showUnbilledItems=${this.props.isShowUnbilledItems}`;
        //     }
        // }
        
        // else {
        //     if(!this.props.isShowUnbilledItems){
        //         params = `?dateRangeFrom=${this.props.dateRangeFrom}&dateRangeTo=${this.props.dateRangeTo}`;
        //     }
        //     else {
        //         params = `?showUnbilledItems=${this.props.isShowUnbilledItems}`;
        //     }
        // }
        return this.service.getShiftMatrixGridRowsWithinRemoteOperations(params)
            .then(this.handleSuccessLoadDataSource)
            .catch(() => { 
                this.setState({
                loadIndicatorForGrid: false
            });
            throw 'Data Loading Error'; });
    }

    handleSuccessLoadDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });
        let shiftData = response?.data?.billableItemRemoteGrid || []
        let matchedRecords:any[]=[];
        let payDatesSource = this.props.payDatesGridDataSource
        if (shiftData && shiftData.length && shiftData.length > 0 && !this.props.reloadShiftGrid) {
            const newStateSource = this.props.payDatesGridDataSource.map((record) => {
                let found = shiftData.filter((data:any) => {
                    let isFound = false;
                    if (record.id == data.id && this.isPayDateEqual(record.currentPayDate, data.payDate) && record.service?.toLowerCase() == data.serviceTypeLookUp?.value?.toLowerCase()){
                        isFound = true;
                        let filtered = matchedRecords.filter(a=>a.id == data.id) //avoid duplicate records
                        if(filtered && filtered.length < 1)
                        matchedRecords.push(data);
                    }
                        
                    return isFound;
                })
                if (found && found.length && found.length > 0) {
                    return { ...record, itemMatched: true };
                }
                else {
                    return { ...record, itemMatched: false };
                }
            });
            payDatesSource = newStateSource;
        } else {
            if(this.props.reloadShiftGrid){
                matchedRecords = shiftData;
            }
        }
        this.props.updateFoundRecords(matchedRecords,payDatesSource)

            //this.props?.updateFoundRecords(response?.data?.billableItemRemoteGrid || [])
            return {
                //data: response.data.billableItemRemoteGrid ?response.data.billableItemRemoteGrid:[],
                //totalCount: response.data.totalCount
                data : matchedRecords,  
                totalCount: response.data.totalCount
            };
        // }
    }

    componentDidMount() {
        this.loadDataSource();
    }

    componentDidUpdate(prevProps: ShiftMatrixUnpaidGridProps) {
        if (this.props.findMatchesClicked != prevProps.findMatchesClicked && this.props.findMatchesClicked) {
            this.loadDataSource();
        } else if(this.props.reloadShiftGrid != prevProps.reloadShiftGrid && this.props.reloadShiftGrid){
            this.loadDataSource();
        }

    }

    // //A helper function that will insert 3 nodes namely "dateFrom","dateTo" and "filterText" into the EventUk user grid.
    // //A helper function that will insert 3 nodes namely "dateFrom","dateTo" and "filterText" into the EventUk user grid.
    // modifyManageShiftsGridRow = (
    //     dataSource: ShiftMatrixRemoteGridRowItem[],
    //     filterText: string,
    //     dateFrom: string,
    //     dateTo: string
    // ): ShiftMatrixRemoteGridRowItem[] => {
    //     for (let item of dataSource) {
    //         item.statusText = filterText;
    //         item.filterStartDate = dateFrom;
    //         item.filterEndDate = dateTo;
    //     }
    //     return dataSource;
    // };
    loadDataSource = () => {
        let dataSource = new CustomStore({
            key: "id",
            load: this.getResults
        });
        GlobalService.setGridType(GridTypes.ShiftMatrixGrid);
        this.setState({
            dataSource: dataSource
        });
        
    }

    render() {
        return (
            <div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadIndicatorForGrid} />
                    <div className="row">
                        <div className="col-md">
                            <ShiftMatrixGridContents dataSource={this.state.dataSource} 
                            navigateFrom={GridTypes.managePayDatesUnpaidShiftGrid} 
                            defaultPageSize = {this.props.defaultPageSize}/>
                        </div>
                    </div>

            </div>
        );
    }
}

export default withTranslation()(ShiftMatrixUnpaidGrid);