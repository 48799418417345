import React, { Component, ChangeEvent, MouseEvent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import FilterItem from "../FindAnArtist/filterItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSync } from "@fortawesome/pro-regular-svg-icons";
import ShowcaseItem from "../FindAnArtist/showcaseItem";
import ArtistCardList from "../FindAnArtist/ArtistCardList";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import FindAnArtistService, {
    artistCardUIType,
    ArtistProfileResponse,
    RequestStatusTypes,
    showCasePanel,
} from "../../services/FindAnArtistService";
import { AxiosResponse } from "axios";
import LightModePageTemplate from "./Templates/LightModeTemplate";
import ArtistUtilities, { filterObject, subTypeCount } from "../FindAnArtist/FindAnArtistUtils";
import GeographyPanel from "../side-bar/GeographyPanel";
import ArtistService, { ServiceSubTypeId, AgreementType  } from "../../services/ArtistService";
import GlobalService from "../../services/GlobalService";
import { Popup, ScrollView, Slider, CheckBox, LoadPanel } from "devextreme-react";
import {
    SliderOnChangedEvent,
    CheckBoxOnValueChanged,
} from "../../types/DevExtremeTypes";
import { confirm } from "devextreme/ui/dialog";
import moment from "moment";
import sharedUtils from "../grid/sharedUtilities";
import { GridTypes } from "../../services/SimpleGridService";


interface FindAnArtistLandingPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

interface ArtistCardListState {
    cardUIType: string; //This would communicate to the Card List rendering panel of which type of cards to display.
    selectedAllButton: boolean;
    selectedFavoritrButton: boolean;
    cards: ArtistProfileResponse[];
    formView: boolean;
    isPending: boolean;
    requestId: string;
    requestStatus: string;
    typeListObject: filterObject;
    typeData: any;
    countOftypes: number;
    firstIndexToShow: number;
    lastIndexToShow: number;
    showCasePanelData: showCasePanel[];
    artistSearchField: string;
    subTypesList: string;
    requestButtonClickCount: number;
    firstTimeRequest: boolean;
    entityId: string;
    ShowCasePanelDataCopy: showCasePanel[];
    postCode: string;
    distance: string;
    artistPicture: string;
    isFindAnArtistPopupvisible: boolean;
    isPopUpToShow: boolean;
    artistSearchVal: string;
    isClickArtistSearch: boolean;
    isClickSearchButton: boolean;
    typeList: string;
    popupVisible: boolean;
    artistAdded: string;
    rating: number;
    trustedValue: boolean;
    gridClient: string;
    gridVenue: string;
    isRefreshButtonClicked: boolean;
    businessEntityId: string;
    signedArtistPopupVisible: boolean;
    loadPanelVisible: boolean;
}

class FindAnArtistLandingPage extends Component<FindAnArtistLandingPageComponentProps> {
    //Initialize Service
    service: FindAnArtistService;
    //Initialize the State
    state: ArtistCardListState;
    //Initialize the utilities
    utils: ArtistUtilities;
    conversionUtils: sharedUtils;
    artistService: ArtistService;
    isEditMode: string;
    constructor(props: FindAnArtistLandingPageComponentProps) {
        super(props);
        this.service = new FindAnArtistService();
        this.utils = new ArtistUtilities();
        this.conversionUtils = new sharedUtils();
        this.artistService = new ArtistService();
        this.isEditMode = "";
        this.state = {
            cards: [],
            cardUIType: "",
            artistPicture: "",
            selectedAllButton: true,
            selectedFavoritrButton: false,
            formView: false,
            isPending:
                this.props.location && this.props.location.state && this.props.location.state.isPendingRequest
                    ? true
                    : false,
            requestId: this.props.location && this.props.location.state && this.props.location.state.id,
            requestStatus: this.props.location && this.props.location.state && this.props.location.state.reqStatus,
            firstIndexToShow: 0,
            lastIndexToShow: 0,
            showCasePanelData: [],
            typeListObject: this.utils.initializeFilterObject(),
            typeData: [],
            countOftypes: 0,
            artistSearchField: "",
            subTypesList: "",
            requestButtonClickCount: 0,
            firstTimeRequest: true,
            entityId: "",
            ShowCasePanelDataCopy: [],
            postCode: "",
            distance: "",
            isFindAnArtistPopupvisible: false,
            isPopUpToShow: false,
            artistSearchVal: "",
            isClickArtistSearch: false,
            isClickSearchButton: false,
            typeList: "",
            popupVisible: false,
            artistAdded: "",
            rating: 0,
            trustedValue: false,
            gridClient: "",
            gridVenue: "",
            isRefreshButtonClicked: false,
            businessEntityId: "",
            signedArtistPopupVisible: false,
            loadPanelVisible: false,
        };
    }

    componentDidMount() {
        let envelopeId = localStorage.getItem("EnvelopeId");
        let businessEntityId = localStorage.getItem("businessEntityId");
        let dateFrom = localStorage.getItem("agreementDateFrom");
        let dateTo = localStorage.getItem("agreementDateTo");  
        let gridType = GlobalService.getGridType();      
        if(envelopeId && businessEntityId && dateFrom && dateTo){
            this.setState({loadPanelVisible : true});
            this.artistService.getSignedInformation(envelopeId, businessEntityId,dateFrom,dateTo).then(this.handleSuccessSignedInfo).catch(this.handleErrorSignedInfo);
        } 
        if (gridType && gridType == GridTypes.artistShiftMatrix && businessEntityId !== "" && businessEntityId !== null) {
            this.setState({
                businessEntityId: businessEntityId,
            });
            this.handlePopUpClose(true, "editMode");
        }  
        localStorage.setItem("businessEntityId", ""); 
        GlobalService.setGridType("");
        this.service.gettypeLookup().then(this.handleSuccessTypeLookUp).catch(this.handleStandardError);
        this.fetchShowcasePanelDataSource();
        this.setState({
            cardUIType: artistCardUIType.All,
        });
        // if (this.props?.location?.state?.navigation == "gigRequestAdd" || this.props?.location?.state?.navigation == "gigRequestEdit") {
        //     this.setPostCodeDistance("", "20");
        // }

        if (this.state.isPending) {
            this.selectRequested();
        }
    }

    componentDidUpdate(prevProps: Readonly<FindAnArtistLandingPageComponentProps>) {
        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.navigateFrom) {
                if (this.props.location.state.navigateFrom != prevProps.location.state.navigateFrom) {
                    this.setState({
                        formView: false
                    });
                }
            }
        }
    }

    handleSuccessSignedInfo = (response: AxiosResponse<any>) => {
        //let agreementDate = localStorage.getItem("agreementDate");  
        //let fileName = "Self-Billing-Agreement_" + response.data.data.businessEntityId;
        //let dateFrom = agreementDate ? this.conversionUtils.convertStringToDate(agreementDate) : ""; 
        this.setState({
            businessEntityId: response.data.data.businessEntityId
        });
        this.setState({
            loadPanelVisible: false
        });
        this.handlePopUpClose(true, "editMode");
        // let data = response.data.data.byteArray;
        // let convertedData = `data:application/pdf;base64,${data}`
        // let link = document.createElement("a");
        // link.href = convertedData;
        // link.download = "Self-Billing-Agreement";
        // link.click();
        //var formData = new FormData();
        // upload document to cloud
        //let dateTo = "";            
        // if(agreementDate){
        //     let converteddate = moment(dateFrom, "YYYY-MM-DD");
        //     let addYear1 = converteddate.add(1, 'y');
        //     let afterDate = moment(addYear1).format("YYYY-MM-DD");
        //     let convertedafterDate= moment(afterDate, "YYYY-MM-DD");
        //     let subtractDay = convertedafterDate.subtract(1, 'd');
        //     dateTo = moment(subtractDay).format("DD/MM/YYYY");
        //     formData.append("dateFrom", agreementDate);
        //     formData.append("dateTo", dateTo);
        // }
        
        // formData.append("documenttypeid", AgreementType.SelfBillInvoiceAgreement);        
        // formData.append("fileName:", fileName);
        // formData.append("BusinessEntityId", this.state.businessEntityId ? this.state.businessEntityId : "");
        // formData.append("file", data );
        // this.artistService
        //     .uploadPhoto(formData)
        //     .then(this.handleAgreementDocUploadSuccess)
        //     .catch(this.handleAgreementDocUploadFailure);
        localStorage.setItem("EnvelopeId", "");
        localStorage.setItem("businessEntityId", "");
        localStorage.setItem("agreementDateFrom", "");
        localStorage.setItem("agreementDateTo", "");
    }

    handleErrorSignedInfo = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
        localStorage.setItem("EnvelopeId", "");
        localStorage.setItem("businessEntityId", "");
        localStorage.setItem("agreementDateFrom", "");
        localStorage.setItem("agreementDateTo", "");

        // Log to AppInsights as a TRACE
        var respMessage: string = "Sign document is failed: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    // handleAgreementDocUploadSuccess = (response: AxiosResponse<any>) => {
    //     this.setState({loadPanelVisible : false});        
    // };
    // handleAgreementDocUploadFailure = (response: AxiosResponse<any>) => {
    //     this.setState({loadPanelVisible : false});
    //     // Log to AppInsights as a TRACE
    //     var respMessage: string = "Agreement Document Upload failed with response: " + JSON.stringify(response);

    //     if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
    //         // AppInsights is not available
    //         console.error(respMessage);
    //     }
    // };

    handleSuccessTypeLookUp = (response: AxiosResponse<any>) => {
        this.setState({
            typeData: response.data.data,
        });
    };

    handleStandardError = (error: any) => {
        var respMessage: string = "gettypeLookup failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    fetchShowcasePanelDataSource = () => {
        if (this.props.location?.state?.navigateFrom == "FindAnArtistMenu") {
            this.service
                .getListOfYoutubeLinks()
                .then(this.handleSuccessListOfYoutubeLink)
                .catch(this.handleErrorListOfYoutubeLink);
        }
    };
    handleSuccessListOfYoutubeLink = (response: AxiosResponse<any>) => {
        var showCasePanelArray = response.data.data;
        this.setFirstIndexAndSecondIndex(showCasePanelArray);
    };
    handleErrorListOfYoutubeLink = (error: AxiosResponse<any>) => {
        var respMessage: string = "getListOfYoutubeLinks failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    setFavouriteButtonState = (id: string) => {
        this.setState({
            entityId: id,
        });
    };

    setCloseButtonState = (businessEntityId: string) => {
        var showCasePanelArr: showCasePanel[];
        showCasePanelArr = this.state.ShowCasePanelDataCopy;
        for (var index = 0; index < showCasePanelArr.length; index++) {
            if (showCasePanelArr[index].businessEntityId == businessEntityId) {
                showCasePanelArr.splice(index, 1);
            }
        }
        this.setState({
            ShowCasePanelDataCopy: showCasePanelArr,
        });
    };
    setFirstIndexAndSecondIndex = (showCasePanelArray: showCasePanel[]) => {
        this.setState(
            {
                firstIndexToShow: 0,
                lastIndexToShow: showCasePanelArray.length > 4 ? 4 : showCasePanelArray.length - 1,
            },
            () => {
                this.youtubeLinkSet(showCasePanelArray);
            }
        );
    };

    youtubeLinkSet = (showCasePanelArray: showCasePanel[]) => {
        if (showCasePanelArray.length > 0) {
            for (var index = 0; index < showCasePanelArray.length; index++) {
                if (showCasePanelArray[index].utubeLink) {
                    var substr1 = "";
                    var substr2 = "";
                    if (
                        showCasePanelArray[index].utubeLink.toLowerCase().includes("list") &&
                        showCasePanelArray[index].utubeLink.toLowerCase().includes("playlist")
                    ) {
                        substr1 = showCasePanelArray[index].utubeLink.substring(
                            showCasePanelArray[index].utubeLink.indexOf("list")
                        );
                        substr2 = substr1.substring(substr1.indexOf("?"));
                        showCasePanelArray[index].utubeLink = "https://www.youtube.com/embed/videoseries" + substr2;
                    } else if (showCasePanelArray[index].utubeLink.toLowerCase().includes("list")) {
                        substr2 =
                            "?" +
                            showCasePanelArray[index].utubeLink.substring(
                                showCasePanelArray[index].utubeLink.indexOf("list")
                            );
                        showCasePanelArray[index].utubeLink = "https://www.youtube.com/embed/videoseries" + substr2;
                    } else {
                        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                        const match = showCasePanelArray[index].utubeLink.match(regExp);
                        showCasePanelArray[index].utubeLink = match !== null ? "https://www.youtube.com/embed/" + match[2] : "Not match";
                    }
                }
            }
        }
        var showCaseArray: showCasePanel[][];
        showCaseArray = new Array(showCasePanelArray);
        this.setState(
            {
                showCasePanelData: showCasePanelArray.slice(),
            },
            () => {
                this.setCopyDataOnLoad(showCasePanelArray);
            }
        );
    };

    setCopyDataOnLoad = (arr: showCasePanel[]) => {
        var splicedArr = arr.slice(this.state.firstIndexToShow, this.state.lastIndexToShow + 1);
        this.setState({
            ShowCasePanelDataCopy: splicedArr,
        });
    };
    onClickNextLink = () => {
        var firstIndex =
            this.state.lastIndexToShow < this.state.showCasePanelData.length - 1 ? this.state.lastIndexToShow + 1 : 0;
        var lastIndex =
            this.state.showCasePanelData.length > this.state.lastIndexToShow + 5
                ? this.state.lastIndexToShow + 5
                : firstIndex == 0
                    ? 4
                    : this.state.showCasePanelData.length - 1;
        if (
            this.state.showCasePanelData.length > 0 &&
            this.state.showCasePanelData.length > this.state.lastIndexToShow
        ) {
            this.setState(
                {
                    firstIndexToShow: firstIndex,
                    lastIndexToShow: lastIndex,
                },
                () => {
                    this.setArrayOnNextClick();
                }
            );
        }
    };
    setArrayOnNextClick = () => {
        var showCasePanelArray: showCasePanel[];
        showCasePanelArray = [];
        showCasePanelArray = this.state.showCasePanelData;
        var splicedArr = showCasePanelArray.slice();
        splicedArr = splicedArr.slice(this.state.firstIndexToShow, this.state.lastIndexToShow + 1);
        this.setState({
            ShowCasePanelDataCopy: splicedArr,
        });
        setTimeout(function () { }, 1000);
    };
    selectFavorites = () => {
        this.setState({
            cardUIType: artistCardUIType.Favorites,
            selectedAllButton: false,
            selectedFavoritrButton: true,
            formView: false,
        });
    };

    addArtistPopup = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hideArtistPopup = (e: any) => {
        e.cancel = true;
        this.setState({
            popupVisible: false,
        });
    };

    selectRequested = () => {
        this.setState((prevState: ArtistCardListState) => ({
            cardUIType: artistCardUIType.Requested,
            selectedAllButton: false,
            selectedFavoritrButton: false,
            formView: true,

            requestButtonClickCount: prevState.requestButtonClickCount + 1,
            isPopUpToShow: false,
            isFindAnArtistPopupvisible: false,
            firstTimeRequest:
                this.props.location.state &&
                    this.props.location.state.isNewRequest &&
                    prevState.requestButtonClickCount + 1 > 1
                    ? false
                    : true,
        }));
    };
    setNewRequestIndicator = () => {
        var newReqFlag =
            this.props.location?.state?.isNewRequest && this.state.requestButtonClickCount > 1 ? false : true;
        this.setState({
            firstTimeRequest: newReqFlag,
        });
    };
    selectAll = () => {
        this.setState({
            cardUIType: artistCardUIType.All,
            selectedAllButton: true,
            selectedFavoritrButton: false,
            formView: false
        });
    };


    buildTypeListArray = (typeList: filterObject, selected: boolean, parentNodeClicked: boolean, deSelectType: boolean,isEnter:boolean) => {
        let artistypeParam: string[] = [];
        let values: string = "";
        let subTypeTypeValues: string = "";
        this.setState({
            typeListObject: typeList,
            countOftypes: typeList.types.length,
        });
        if (typeList.serviceSubtype == ServiceSubTypeId.LiveMusic || typeList.serviceSubtype == ServiceSubTypeId.DJ || typeList.serviceSubtype == ServiceSubTypeId.PubQuiz) {
            if (typeList.types.length > 0) {
                artistypeParam = typeList.types.map((item: any) => {
                    return this.buildArtistTypeParameter(typeList.serviceSubtype, item);
                });
                values = artistypeParam.toString();
            }
        }
        else {
            values = this.buildArtistTypeParameter(typeList.serviceSubtype);
        }
        if (deSelectType) {
            subTypeTypeValues = GlobalService.getArtistTypeSubType();
        }
        else if (selected && parentNodeClicked || selected && !parentNodeClicked) {
            if(!isEnter){
            GlobalService.setArtistTypeSubType(values);
            }
            subTypeTypeValues = GlobalService.getArtistTypeSubType();
        }
        else if (!selected && parentNodeClicked) {
            GlobalService.removeArtistTypeSubType(typeList.serviceSubtype, "");
            subTypeTypeValues = GlobalService.getArtistTypeSubType();
        }
        if (subTypeTypeValues.startsWith(",")) {
            subTypeTypeValues = subTypeTypeValues.slice(1);
        }
        this.setState({
            typeList: subTypeTypeValues
        },()=>{
            if(isEnter){
                this.onSearch();
            }
        });
    };

    buildArtistTypeParameter = (serviceSubTypeId: string, typeId?: string) => {
        if (serviceSubTypeId && typeId) {
            return `${serviceSubTypeId}-${typeId}`;
        }
        else if (serviceSubTypeId) {
            return `${serviceSubTypeId}`;
        }
        else {
            return "";
        }
    }

    searchArtists = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            artistSearchField: event.target.value
        });
    };

    onSearch = () => {
        this.setState({
            artistSearchVal: this.state.artistSearchField,
            isClickArtistSearch: true,
            isClickSearchButton: false,
            postCode: "",
            distance: ""
        });
    }

    setPostCodeDistance = (postalCode: string, radius: string) => {
        this.setState({
            postCode: postalCode,
            distance: radius,
            isClickArtistSearch: false,
            isClickSearchButton: true,
            artistSearchVal: this.state.artistSearchField
        });
    };

    renderTypesList = (typesList: subTypeCount[]) => {
        var subTypesList = this.utils.displaySubTypeCount(typesList);
        this.setState({
            subTypesList: subTypesList,
        });
    };

    parentComponentImageLink = (displayLink: string) => {
        this.setState({
            artistPicture: displayLink
        });
    }

    parentAddArtist = (isShowInPopup: boolean) => {
        this.setState({
            isPopUpToShow: isShowInPopup,
            artistSearchField: ""
        });
        this.selectAll();
    }

    getVenue = (client: string, venue:string) => {
        this.setState({
            gridClient: client,
            gridVenue: venue
        });
    }

    updateSearchButton = (flag: boolean) => {
        this.setState({
            isClickArtistSearch: flag,
            isClickSearchButton: flag
        });
    }


    handleAddArtist = (isAdded: string) => {
        this.setState({ 
            artistAdded: isAdded,
            popupVisible: false,
         });
    }

    handleChangeRating = (dxValueChange: SliderOnChangedEvent) => {
        this.setState({
            rating: dxValueChange.value,
        });
        if(dxValueChange && dxValueChange.element){
            dxValueChange.element.onkeydown=(event: any) => {
                if(event.key && event.key === 'Enter'){
                    this.onSearch();
                }
             }
            } 
    };

    handleChangeTrusted = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            trustedValue: dxValueChange.value,
        });
        if(dxValueChange && dxValueChange.element){
        dxValueChange.element.onkeydown=(event: any) => {
            if(event.key && event.key === 'Enter'){
                this.onSearch();
            }
         }
        } 
    };

    onRefreshGigRequest = () => {
        this.setState({isRefreshButtonClicked: true});
    }

    resetRefreshGig = () => {
        this.setState({isRefreshButtonClicked: false});
    }

    handlePopUpClose = (flag:boolean, editmode: string) => {      
        this.isEditMode = editmode;
        this.setState({
            signedArtistPopupVisible: flag,
        },() => {
            // if(!flag){
            //     this.onSearch();
            // }
        });
    }
    refreshSearch = (flag:boolean) => {
        if(flag){
            this.onSearch();
        }
    }

    hideInfo = (e: any) => {
        e.cancel = true;
        if(this.isEditMode == "editMode"){
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        signedArtistPopupVisible: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else{
            this.setState({
                signedArtistPopupVisible: false,
            });  
        }
    };

    render() {
        let isNavigateFromGigReq: boolean = false;
        let isNavigateFindAnArtist: boolean = false;
        let pageTitle: string = "";
        let gigType = GlobalService.getGigComingFrom();     
        if ((this.props.location?.state?.navigation == "gigRequestAdd" || this.props.location?.state?.navigation == "gigRequestEdit")) {
            isNavigateFromGigReq = true;
        }
        if (isNavigateFromGigReq && !this.state.isPopUpToShow) {
            if (gigType == "ContractCleaning") {
                pageTitle = "Cleaning Requests";
            }
            else if (gigType == "Gardening") {
                pageTitle = "Gardening Requests";
            }
            else if (gigType == "WindowCleaning") {
                pageTitle = "Window Cleaning Requests";
            }
            else if (gigType == "Maintenance") {
                pageTitle = "Maintenance Requests";
            }
            else if (gigType == "Miscelleanous") {
                pageTitle = "Miscelleanous Requests";
            }
            else if (gigType == "Security") {
                pageTitle = "Door Supervision Requests";
            }
            else{
                pageTitle = "Gig Request";
            }            
        }
        else if (this.state.isPopUpToShow && isNavigateFromGigReq) {
            pageTitle = "Add Artist To Request";
        }
        else {
            pageTitle = "Find An Artist";
            isNavigateFindAnArtist = true;
        }
        return (
            <LightModePageTemplate>
                {(this.props?.location?.state?.navigation == "gigRequestAdd" || this.props?.location?.state?.navigation == "gigRequestEdit") && !this.state.isPopUpToShow ?
                    (null) : (
                        <SideBarTemplate isFullBleed={true}>
                            <form onSubmit={(e: any) => {
                                        e.preventDefault();
                                        this.onSearch();
                                    }}>
                            <div className="filter-box__search mt-3">
                                <input
                                    type="search"
                                    onChange={this.searchArtists}
                                    //aria-label="Search"
                                    placeholder="Search for artists"
                                ></input>
                                <button type="submit"
                                    // onClick={(e: MouseEvent) => {
                                    //     e.preventDefault();
                                    //     this.onSearch();
                                    // }}
                                    >
                                    <FontAwesomeIcon icon={faSearch} />
                                    <span className="sr-only">Submit Search</span>
                                </button>
                            </div>
                            </form>
                            <div className="filter-box mt-3">
                                <h5 className="filter-box__section-header">Artist type</h5>
                                <ul className="filter-box__filter-list">
                                    {this.state.typeData.map((item: any, index: number) => {
                                        return (
                                            (item.id == ServiceSubTypeId.LiveMusic || item.id == ServiceSubTypeId.DJ || item.id == ServiceSubTypeId.Karaoke || item.id == ServiceSubTypeId.PubQuiz || item.id == ServiceSubTypeId.Other) ?
                                                <FilterItem
                                                    key={index}
                                                    item={item}
                                                    sendTypeListToParent={this.buildTypeListArray}
                                                /> : <></>
                                        );
                                    })}
                                </ul>
                                <div className="row mt-3">
                                    <div className="col sidebar__heading">ARTIST RATING</div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <Slider
                                            min={0}
                                            max={5}
                                            value={this.state.rating}
                                            step={1}
                                            onValueChanged={this.handleChangeRating}
                                            tooltip={{
                                                enabled: true,
                                                showMode: "always",
                                                position: "bottom",
                                                format: () => {
                                                    return `${this.state.rating} Stars`;
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <br></br><br></br><br></br>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <div>
                                            <CheckBox value={this.state.trustedValue} onValueChanged={this.handleChangeTrusted} />
                                        </div>
                                    </div>
                                    <div className="col-7 sidebar__heading">Only Show Trusted</div>
                                </div>
                                <br></br>
                                <h5 className="filter-box__section-header">Geography</h5>
                                <GeographyPanel setPostCodeDistance={this.setPostCodeDistance} location={this.props.location} gridClient={this.state.gridClient}
                                    gridVenue={this.state.gridVenue}></GeographyPanel>
                            </div>
                            <div className="artist-showcase my-5 p-6">
                                <h4 className="artist-showcase__title">The Showcase</h4>
                                <Link to="#" className="showcasePanel_next5" onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onClickNextLink();
                                    }}>
                                    Next 5{" >>"}
                                </Link>
                                {this.state.ShowCasePanelDataCopy.length > 0 ? (
                                    <div className="artist-showcase__item-wrapper">
                                        {this.state.ShowCasePanelDataCopy.map((linkItem: showCasePanel, index: number) => {
                                            return (
                                                <div key={index}>
                                                    {
                                                        <ShowcaseItem
                                                            setCloseButtonState={this.setCloseButtonState}
                                                            businessEntityId={linkItem.businessEntityId}
                                                            artistName={linkItem.artistName}
                                                            url={linkItem.utubeLink}
                                                            venueFavourite={linkItem.venueFavourite}
                                                        />
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </SideBarTemplate>
                    )
                }
                <div className="page-content--with-sidebar">
                    <div className="container">
                        <div className="find-an-artist-header">
                            <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                            <div>
                                <h2 className="page-title__black">{pageTitle}  {isNavigateFromGigReq && !this.state.isPopUpToShow ? <button
                                        className="status-flow__refresh-button btn gig-icon-btn"
                                        aria-label="Refresh the current billing status"
                                        onClick={this.onRefreshGigRequest}
                                    >
                                        <FontAwesomeIcon icon={faSync} />
                                    </button> : null }<br /><br />
                                    {this.state.isPopUpToShow && isNavigateFromGigReq ?
                                        <button
                                            className="btn btn-primary"
                                            onClick={this.selectRequested}
                                            disabled={this.state.requestStatus == RequestStatusTypes.Booked ? true : false}
                                        //hidden={isNavigateFromGigReq}
                                        >
                                            Back to Request
                                        </button> : null
                                    }
                                </h2>
                            </div>
                            <h3 className="find-an-artist-header__title">{this.state.subTypesList}</h3>
                            <div className="find-an-artist-header__controls">
                                <div className="find-an-artist-header__view-buttons" hidden={isNavigateFromGigReq && !this.state.isPopUpToShow}>
                                    <button
                                        className={`btn
                                    find-an-artist-header__view-button ${this.state.selectedAllButton
                                                ? "find-an-artist-header__view-button--selected"
                                                : ""
                                            }`}
                                        onClick={this.selectAll}
                                        disabled={this.state.requestStatus == RequestStatusTypes.Booked ? true : false}
                                        hidden={isNavigateFromGigReq}
                                    >
                                        View All
                                    </button>
                                    <button
                                        className={`btn
                                    find-an-artist-header__view-button ${this.state.selectedFavoritrButton
                                                ? "find-an-artist-header__view-button--selected"
                                                : ""
                                            }`}
                                        onClick={this.selectFavorites}
                                        disabled={this.state.requestStatus == RequestStatusTypes.Booked ? true : false}
                                        hidden={isNavigateFromGigReq}
                                    >
                                        View Favourites
                                    </button>
                                </div>
                                <button
                                    className="btn btn-primary"
                                    onClick={this.addArtistPopup}
                                    hidden={isNavigateFromGigReq}
                                    disabled={this.state.requestStatus == RequestStatusTypes.Booked ? true : false}
                                >
                                    Add Artist
                                </button>


                            </div>
                        </div>

                        <ArtistCardList
                            cardType={this.state.cardUIType}
                            openForm={this.state.formView}
                            location={this.props.location}
                            history={this.props.history}
                            viewAll={this.selectAll}
                            pendingRequest={this.state.isPending}
                            requestId={this.state.requestId}
                            typeListObject={this.state.typeListObject}
                            countOftypes={this.state.countOftypes}
                            supplyTypesListToParent={this.renderTypesList}
                            firstTimeRequest={this.state.firstTimeRequest}
                            requestStatus={this.state.requestStatus}
                            postCode={this.state.postCode}
                            distance={this.state.distance}
                            trustedValue={this.state.trustedValue}
                            rating={this.state.rating}
                            parentComponentImageLink={this.parentComponentImageLink}
                            parentAddArtist={this.parentAddArtist}
                            getVenue={this.getVenue}
                            isShowPopUp={this.state.isPopUpToShow}
                            isNavigateFindAnArtist={isNavigateFindAnArtist}
                            searchFieldVal={this.state.artistSearchVal}
                            typeList={this.state.typeList}
                            isClickArtistSearch={this.state.isClickArtistSearch}
                            isClickSearchButton={this.state.isClickSearchButton}
                            artistAdded={this.state.artistAdded}
                            updateSearchButton={this.updateSearchButton}
                            isRefreshButtonClicked={this.state.isRefreshButtonClicked}
                            resetRefreshGig={this.resetRefreshGig}
                            refreshSearch={this.refreshSearch}
                        />
                    </div>
                    {
                        this.state.popupVisible ? (
                            <Popup
                                visible={this.state.popupVisible}
                                onHiding={this.hideArtistPopup}
                                dragEnabled={false}
                                hideOnOutsideClick={false}
                                showTitle={true}
                                showCloseButton={true}
                                title="Add Artist"
                                resizeEnabled={true}
                            >
                                <ScrollView width="100%" height="100%">
                                    <ArtistProfileComponent
                                        mode="edit"
                                        id="0"
                                        businessEntityId="0"
                                        handlePopUpClose={() => { return null }}
                                        handleFeedbackUpdate={() => { return null }}
                                        handleAddArtist={this.handleAddArtist}
                                        serviceType={"Entertainment"}
                                    ></ArtistProfileComponent>
                                </ScrollView>
                            </Popup>
                        ) : null
                    }
                    {
                        this.state.signedArtistPopupVisible ? (
                            <Popup
                                visible={this.state.signedArtistPopupVisible}
                                onHiding={this.hideInfo}
                                dragEnabled={false}
                                hideOnOutsideClick={false}
                                showTitle={true}
                                showCloseButton={true}
                                title="Artist Details"
                                resizeEnabled={true}
                            >
                                <ScrollView width="100%" height="100%">
                                    <ArtistProfileComponent
                                        mode="edit"
                                        id={this.state.businessEntityId.toString()}
                                        handlePopUpClose={this.handlePopUpClose}
                                        businessEntityId={this.state.businessEntityId}
                                        handleFeedbackUpdate={() => { return null }}
                                        handleAddArtist = {()=>{return null}}
                                        serviceType={"Entertainment"}
                                    ></ArtistProfileComponent>
                                </ScrollView>
                            </Popup>
                        ) : null
                    }
                </div>

            </LightModePageTemplate>
        );
    }
}
export default withRouter(FindAnArtistLandingPage);
