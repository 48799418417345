import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { LoadIndicator, Popover, ScrollView } from "devextreme-react";
import TodayCardPopOverWrapper from "./TodayCardPopOverWrapper";
import ScheduleService from "../../services/ScheduleService";
import {ConfigurableTextIndexes} from "../../services/configurationValueService";
import { DashBoardData } from "./DashBoardUtils";
import outstandingActionsUtilities from "../../components/Outstanding-Actions/OutstandingActionsUtilities";
import sharedUtils from "../../components/grid/sharedUtilities";
import GlobalService from "../../services/GlobalService";
import UserService, { OperatingServiceNames } from "../../services/UserService";
import { ServiceType } from "../../common/ServiceUtilities";

interface TodayCardProps {
    dashBoardData?: DashBoardData;
    clientId: string;
    venueId: string;
    isDashBoardDataLoaded: boolean;
    dataNotFoundMsg?: string;
}

interface TodayCardState {
    contractCleaningPopOverVisible: boolean;
    doorSupervisionPopOverVisible: boolean;
    entertainmentPopOverVisible: boolean;
    gardeningPopOverVisible: boolean;
    maintenancePopOverVisible: boolean;
    miscelleanousPopOverVisible: boolean;
    windowCleaningPopOverVisible: boolean;
}

class TodayCard extends React.Component<TodayCardProps> {
    state: TodayCardState;
    dashBoardService: ScheduleService;
    outStandingActionUtils: outstandingActionsUtilities;
    sharedUtils: sharedUtils;
    constructor(props: TodayCardProps) {
        super(props);
        this.state = {
            contractCleaningPopOverVisible: false,
            doorSupervisionPopOverVisible: false,
            entertainmentPopOverVisible: false,
            gardeningPopOverVisible: false,
            maintenancePopOverVisible: false,
            miscelleanousPopOverVisible: false,
            windowCleaningPopOverVisible: false,
        };
        this.dashBoardService = new ScheduleService();
        this.outStandingActionUtils = new outstandingActionsUtilities();
        this.sharedUtils = new sharedUtils();
        this.toggleContractCleaningPopOver = this.toggleContractCleaningPopOver.bind(this);
        this.toggleDoorSupervisionPopOver = this.toggleDoorSupervisionPopOver.bind(this);
        this.toggleEntertainmentPopOver = this.toggleEntertainmentPopOver.bind(this);
        this.toggleGardeningPopOver = this.toggleGardeningPopOver.bind(this);
        
    }

    componentDidMount() {}

    toggleContractCleaningPopOver = () => {
        this.setState({
            contractCleaningPopOverVisible: !this.state.contractCleaningPopOverVisible,
        });
    };
    toggleDoorSupervisionPopOver = () => {
        this.setState({
            doorSupervisionPopOverVisible: !this.state.doorSupervisionPopOverVisible,
        });
    };
    toggleEntertainmentPopOver = () => {
        this.setState({
            entertainmentPopOverVisible: !this.state.entertainmentPopOverVisible,
        });
    };
    toggleGardeningPopOver = () => {
        this.setState({
            gardeningPopOverVisible: !this.state.gardeningPopOverVisible,
        });
    };
    toggleMaintenancePopOver = () => {
        this.setState({
            maintenancePopOverVisible: !this.state.maintenancePopOverVisible,
        });
    };
    toggleMiscelleanousPopOver = () => {
        this.setState({
            miscelleanousPopOverVisible: !this.state.miscelleanousPopOverVisible,
        });
    };
    toggleWindowCleaningPopOver = () => {
        this.setState({
            windowCleaningPopOverVisible: !this.state.windowCleaningPopOverVisible,
        });
    };
    viewCostReportForService = (serviceTypeId: string) => {
        sessionStorage.setItem("serviceTypeFilter", serviceTypeId);
    };
    renderDoorSupervisionPopOverContent = () => {
        var title = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.securityProviderBusinessEntity
                        ? this.props.dashBoardData.venue.contact.securityProviderBusinessEntity.shortName
                        : ""
                    : ""
                : ""
            : "";
        var date = this.outStandingActionUtils.convertddmmyyyyStringToCustomizedFormat("");
        var officeContactPhoneNumber = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.securityProviderBusinessEntity
                        ? this.props.dashBoardData.venue.contact.securityProviderBusinessEntity.officePhone
                        : ""
                    : ""
                : ""
            : "";
        var oopProtocol = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.securityProviderBusinessEntity
                        ? this.props.dashBoardData.venue.contact.securityProviderBusinessEntity.oooProtocol
                        : ""
                    : ""
                : ""
            : "";
        var operationalContactName = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.primarySecurityContact
                        ? this.props.dashBoardData.venue.contact.primarySecurityContact.name
                        : ""
                    : ""
                : ""
            : "";
        var operationalContactPhone = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.primarySecurityContact
                        ? this.props.dashBoardData.venue.contact.primarySecurityContact.mobileTelephone
                        : ""
                    : ""
                : ""
            : "";
        return (
            <ScrollView>
                <TodayCardPopOverWrapper
                    title={title}
                    date={date}
                    officeContactPhoneNumber={officeContactPhoneNumber}
                    operationalContactName={operationalContactName}
                    operationalContactPhoneNumber={operationalContactPhone}
                    footerString={oopProtocol}
                    mainContent={
                        <>
                            <thead className="divider--bottom">
                                <tr>
                                    <th scope="col" className="font-weight-normal">
                                        Time
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Name
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Job Role
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divider--bottom">
                                {this.props.dashBoardData
                                    ? this.props.dashBoardData.doorSupervisionShiftDetails.length > 0
                                        ? this.props.dashBoardData.doorSupervisionShiftDetails.map((data: any) => {
                                              return (
                                                  <tr>
                                                      <td>{data.shiftTime}</td>
                                                      <td>
                                                          <Link
                                                              onClick={() =>
                                                                  this.viewCostReportForService(ServiceType.Security)
                                                              }
                                                              to={{
                                                                pathname: "/AddEditFormV2",
                                                                state: {
                                                                    id: data.billableItemId,
                                                                    pointOfNavigation : "Budget",
                                                                    adjustment: false,
                                                                    isReadOnly: true,
                                                                },
                                                              }}
                                                          >
                                                              {data.name}
                                                          </Link>
                                                      </td>
                                                      <td>{data.jobRole}</td>
                                                  </tr>
                                              );
                                          })
                                        : ""
                                    : ""}
                            </tbody>
                        </>
                    }
                />
            </ScrollView>
        );
    };
    renderCleaningPopOverContent = () => {
        var date = this.outStandingActionUtils.convertddmmyyyyStringToCustomizedFormat("");
        var title = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.contractCleaningBusinessEntity
                        ? this.props.dashBoardData.venue.contact.contractCleaningBusinessEntity.shortName
                        : ""
                    : ""
                : ""
            : "";
        var officeContactPhoneNumber = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.contractCleaningBusinessEntity
                        ? this.props.dashBoardData.venue.contact.contractCleaningBusinessEntity.officePhone
                        : ""
                    : ""
                : ""
            : "";
        var oopProtocol = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.securityProviderBusinessEntity
                        ? this.props.dashBoardData.venue.contact.securityProviderBusinessEntity.oooProtocol
                        : ""
                    : ""
                : ""
            : "";
        var operationalContactName = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.primaryCleaningContact
                        ? this.props.dashBoardData.venue.contact.primaryCleaningContact.name
                        : ""
                    : ""
                : ""
            : "";
        var operationalContactPhone = this.props.dashBoardData
            ? this.props.dashBoardData.venue
                ? this.props.dashBoardData.venue.contact
                    ? this.props.dashBoardData.venue.contact.primaryCleaningContact
                        ? this.props.dashBoardData.venue.contact.primaryCleaningContact.mobileTelephone
                        : ""
                    : ""
                : ""
            : "";
        var cleaningHours =
            this.props.dashBoardData && this.props.dashBoardData.cleaningShiftDetails.dailyBreakdownShifts
                ? this.props.dashBoardData.cleaningShiftDetails.hoursTotal
                : this.props.dashBoardData
                ? this.props.dashBoardData.cleaningShifts
                : null;
        return (
            <ScrollView>
                <TodayCardPopOverWrapper
                    title={title}
                    date={date}
                    officeContactPhoneNumber={officeContactPhoneNumber}
                    operationalContactName={operationalContactName}
                    operationalContactPhoneNumber={operationalContactPhone}
                    summaryText={
                        this.props.dashBoardData &&
                        this.props.dashBoardData.cleaningShiftDetails.isVariable === "False" ? (
                            <p className="text-center font-weight-bold">
                                <Link to={{ pathname: "" }}>{cleaningHours ? cleaningHours : null}</Link>{" "}
                                {this.props.dashBoardData && this.props.dashBoardData.cleaningShiftDetails.message}
                            </p>
                        ) : (
                            <></>
                        )
                        // Example of summary link for variable hours
                        // <p className="text-primary text-center">
                        // Cleaning hours may vary on a week by week basis and are approved by the venue manager each week.
                        // </p>
                    }
                    mainContent={
                        <>
                            {this.props.dashBoardData &&
                            this.props.dashBoardData.cleaningShiftDetails.isVariable === "True" ? (
                                <p className="font-weight-normal text-center text-primary">
                                    Cleaning hours vary on a week by week basis and are approved by the General Manager
                                    each week
                                </p>
                            ) : this.props.dashBoardData &&
                              this.props.dashBoardData.cleaningShiftDetails.dailyBreakdownShifts ? (
                                <>
                                    <thead className="divider--bottom">
                                        <tr>
                                            {this.props.dashBoardData.cleaningShiftDetails.dailyBreakdownShifts.map(
                                                (data: any, index: any) => {
                                                    return (
                                                        <th
                                                            key={index}
                                                            scope="col"
                                                            className={
                                                                data.isToday === "True"
                                                                    ? "font-weight-normal text-center text-primary"
                                                                    : "font-weight-normal text-center"
                                                            }
                                                        >
                                                            {data.dayOfWeek.slice(0, 3)}
                                                        </th>
                                                    );
                                                }
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {this.props.dashBoardData.cleaningShiftDetails.dailyBreakdownShifts.map(
                                                (data: any, index: any) => {
                                                    return (
                                                        <td
                                                            key={index}
                                                            scope="col"
                                                            className={
                                                                data.isToday === "True"
                                                                    ? "text-center text-primary"
                                                                    : "text-center"
                                                            }
                                                        >
                                                            {data.hours} Hrs
                                                        </td>
                                                    );
                                                }
                                            )}
                                        </tr>
                                    </tbody>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    }
                    footerString={oopProtocol}
                />
            </ScrollView>
        );
    };
    renderEntertainmentPopOverContent = () => {
        var date = this.outStandingActionUtils.convertddmmyyyyStringToCustomizedFormat("");
        var protocol = "";
        var configurableData = GlobalService.configurationValue();
        if (configurableData.length > 0) {
            protocol = configurableData.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_LandingPage_Ents_Popup_OOH;
            })[0].value;
        }
        return (
            <ScrollView>
                <TodayCardPopOverWrapper
                    title="Entertainment"
                    date={date}
                    footerString={protocol}
                    mainContent={
                        <>
                            <thead className="divider--bottom">
                                <tr>
                                    <th scope="col" className="font-weight-normal">
                                        Time
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Name
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Type
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Contact No.
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divider--bottom">
                                {this.props.dashBoardData
                                    ? this.props.dashBoardData.entertainmentShiftDetails.length > 0
                                        ? this.props.dashBoardData.entertainmentShiftDetails.map((data: any) => {
                                              return (
                                                  <tr>
                                                      <td>{data.shiftTime}</td>
                                                      <td>
                                                          <Link
                                                              onClick={() =>
                                                                  this.viewCostReportForService(
                                                                      ServiceType.Entertainment
                                                                  )
                                                              }
                                                              to={{
                                                                pathname: "/AddEditFormV2",
                                                                state: {
                                                                    id: data.billableItemId,
                                                                    pointOfNavigation : "Budget",
                                                                    adjustment: false,
                                                                    isReadOnly: true,
                                                                },
                                                              }}
                                                          >
                                                              {data.name}
                                                          </Link>
                                                      </td>
                                                      <td>{data.jobRole}</td>
                                                      <td>{data.contactNo}</td>
                                                  </tr>
                                              );
                                          })
                                        : ""
                                    : ""}
                            </tbody>
                        </>
                    }
                />
            </ScrollView>
        );
    };
    renderGardeningPopOverContent = () => {
        var date = this.outStandingActionUtils.convertddmmyyyyStringToCustomizedFormat("");
        var protocol = "";
        return (
            <ScrollView>
                <TodayCardPopOverWrapper
                    title="Gardening"
                    date={date}
                    footerString={protocol}
                    mainContent={
                        <>
                            <thead className="divider--bottom">
                                <tr>
                                    <th scope="col" className="font-weight-normal">
                                        Provider
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Sub Type
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Type / Description
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Spend
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divider--bottom">
                                {this.props.dashBoardData
                                    ? this.props.dashBoardData.gardeningShiftDetails.length > 0
                                        ? this.props.dashBoardData.gardeningShiftDetails.map((data: any) => {
                                              return (
                                                  <tr>
                                                      <td>{data.name}</td>
                                                      <td>{data.serviceSubTypeLookUpName}</td>
                                                      <td>{data.jobRole =="Other" ? data.otherDescription : data.jobRole}</td>
                                                      <td>
                                                          <Link
                                                              onClick={() =>
                                                                  this.viewCostReportForService(
                                                                      ServiceType.Gardening
                                                                  )
                                                              }
                                                              to={{
                                                                pathname: "/AddEditFormV2",
                                                                state: {
                                                                    id: data.billableItemId,
                                                                    pointOfNavigation : "Budget",
                                                                    adjustment: false,
                                                                    isReadOnly: true,
                                                                },
                                                              }}
                                                          >
                                                             £{data.clientBillableAmountLocal}
                                                          </Link>
                                                      </td>                                                      
                                                  </tr>
                                              );
                                          })
                                        : ""
                                    : ""}
                            </tbody>
                        </>
                    }
                />
            </ScrollView>
        );
    };
    renderMaintenancePopOverContent = () => {
        var date = this.outStandingActionUtils.convertddmmyyyyStringToCustomizedFormat("");
        var protocol = "";
        return (
            <ScrollView>
                <TodayCardPopOverWrapper
                    title="Maintenance"
                    date={date}
                    footerString={protocol}
                    mainContent={
                        <>
                            <thead className="divider--bottom">
                                <tr>
                                    <th scope="col" className="font-weight-normal">
                                        Provider
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Sub Type
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Type / Description
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Spend
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divider--bottom">
                                {this.props.dashBoardData
                                    ? this.props.dashBoardData.maintenanceShiftDetails.length > 0
                                        ? this.props.dashBoardData.maintenanceShiftDetails.map((data: any) => {
                                              return (
                                                  <tr>
                                                      <td>{data.name}</td>
                                                      <td>{data.serviceSubTypeLookUpName}</td>
                                                      <td>{data.jobRole =="Other" ? data.otherDescription : data.jobRole}</td>
                                                      <td>
                                                        <Link
                                                            onClick={() =>
                                                                this.viewCostReportForService(
                                                                    ServiceType.Maintenance
                                                                )
                                                            }
                                                            to={{
                                                            pathname: "/AddEditFormV2",
                                                            state: {
                                                                id: data.billableItemId,
                                                                pointOfNavigation : "Budget",
                                                                adjustment: false,
                                                                isReadOnly: true,
                                                            },
                                                            }}
                                                        >
                                                             £{data.clientBillableAmountLocal}
                                                          </Link>
                                                      </td>                                                      
                                                  </tr>
                                              );
                                          })
                                        : ""
                                    : ""}
                            </tbody>
                        </>
                    }
                />
            </ScrollView>
        );
    };
    renderMiscPopOverContent = () => {
        var date = this.outStandingActionUtils.convertddmmyyyyStringToCustomizedFormat("");
        var protocol = "";
        return (
            <ScrollView>
                <TodayCardPopOverWrapper
                    title="Miscelleanous"
                    date={date}
                    footerString={protocol}
                    mainContent={
                        <>
                            <thead className="divider--bottom">
                                <tr>
                                    <th scope="col" className="font-weight-normal">
                                        Provider
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Sub Type
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Type / Description
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Spend
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divider--bottom">
                                {this.props.dashBoardData
                                    ? this.props.dashBoardData.miscelleanousShiftDetails.length > 0
                                        ? this.props.dashBoardData.miscelleanousShiftDetails.map((data: any) => {
                                              return (
                                                  <tr>
                                                      <td>{data.name}</td>
                                                      <td>{data.serviceSubTypeLookUpName}</td>
                                                      <td>{data.jobRole =="Other" ? data.otherDescription : data.jobRole}</td>
                                                      <td>
                                                      <Link
                                                            onClick={() =>
                                                                this.viewCostReportForService(
                                                                    ServiceType.Miscelleanous
                                                                )
                                                            }
                                                            to={{
                                                            pathname: "/AddEditFormV2",
                                                            state: {
                                                                id: data.billableItemId,
                                                                pointOfNavigation : "Budget",
                                                                adjustment: false,
                                                                isReadOnly: true,
                                                            },
                                                            }}
                                                        >
                                                             £{data.clientBillableAmountLocal}
                                                          </Link>
                                                      </td>                                                      
                                                  </tr>
                                              );
                                          })
                                        : ""
                                    : ""}
                            </tbody>
                        </>
                    }
                />
            </ScrollView>
        );
    };
    renderWindowPopOverContent = () => {
        var date = this.outStandingActionUtils.convertddmmyyyyStringToCustomizedFormat("");
        var protocol = "";
        return (
            <ScrollView>
                <TodayCardPopOverWrapper
                    title="Window Cleaning"
                    date={date}
                    footerString={protocol}
                    mainContent={
                        <>
                            <thead className="divider--bottom">
                                <tr>
                                    <th scope="col" className="font-weight-normal">
                                        Provider
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Sub Type
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Type / Description
                                    </th>
                                    <th scope="col" className="font-weight-normal">
                                        Spend
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divider--bottom">
                                {this.props.dashBoardData
                                    ? this.props.dashBoardData.windowCleaningShiftDetails.length > 0
                                        ? this.props.dashBoardData.windowCleaningShiftDetails.map((data: any) => {
                                              return (
                                                  <tr>
                                                      <td>{data.name}</td>
                                                      <td>{data.serviceSubTypeLookUpName}</td>
                                                      <td>{data.jobRole =="Other" ? data.otherDescription : data.jobRole}</td>
                                                      <td>
                                                      <Link
                                                            onClick={() =>
                                                                this.viewCostReportForService(
                                                                    ServiceType.WindowCleaning
                                                                )
                                                            }
                                                            to={{
                                                            pathname: "/AddEditFormV2",
                                                            state: {
                                                                id: data.billableItemId,
                                                                pointOfNavigation : "Budget",
                                                                adjustment: false,
                                                                isReadOnly: true,
                                                            },
                                                            }}
                                                        >
                                                             £{data.clientBillableAmountLocal}
                                                          </Link>
                                                      </td>                                                      
                                                  </tr>
                                              );
                                          })
                                        : ""
                                    : ""}
                            </tbody>
                        </>
                    }
                />
            </ScrollView>
        );
    };
    render() {
        let securityServicePresent: boolean = UserService.isOperatingServicePresent(OperatingServiceNames.Security);
        let cleaningServicePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.ContractCleaing
        );
        let entertainmentServicePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.Entertainment
        );
        let gardeningServicePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.Gardening
        ); 
        let maintenanceServicePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.Maintenance
        );
        let miscelleanousServicePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.Miscelleanous
        ); 
        let windowServicePresent: boolean = UserService.isOperatingServicePresent(
            OperatingServiceNames.WindowCleaning
        ); 
        return (
            <>
                <h3 className="home-page-card__header">Today</h3>
                {this.props.isDashBoardDataLoaded && !this.props.dataNotFoundMsg ? (
                    <ul className="home-page__legend">
                        {cleaningServicePresent && (
                            <li className="row home-page__legend--cleaning">
                                <span className="col">Contract Cleaning</span>
                                <span className="col">
                                    {this.props.dashBoardData ? (
                                        this.props.dashBoardData.cleaningShifts &&
                                        this.props.dashBoardData.cleaningShifts.toLowerCase() == "nothing scheduled" ? (
                                            <span>{this.props.dashBoardData.cleaningShifts}</span>
                                        ) : (
                                            <Link
                                                to={{ pathname: "" }}
                                                /* THE ID HERE MUST BE UNIQUE AND REFERENCED BY THE CORRESPONDING POPOVER */
                                                id="contractCleaningLink"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.toggleContractCleaningPopOver();
                                                }}
                                            >
                                                {this.props.dashBoardData
                                                    ? this.props.dashBoardData.cleaningShifts
                                                        ? this.props.dashBoardData.cleaningShifts
                                                        : null
                                                    : null}
                                            </Link>
                                        )
                                    ) : null}
                                    <Popover
                                        /* THE ID HERE MUST MATCH THE CORRESPONDING LINK */
                                        target="#contractCleaningLink"
                                        position="bottom"
                                        width={550}
                                        visible={this.state.contractCleaningPopOverVisible}
                                        onHiding={this.toggleContractCleaningPopOver}
                                        contentRender={this.renderCleaningPopOverContent}
                                    />
                                </span>
                            </li>
                        )}
                        {securityServicePresent && (
                            <li className="row home-page__legend--security">
                                <span className="col">Door Supervision</span>
                                <span className="col">
                                    {this.props.dashBoardData && securityServicePresent ? (
                                        this.props.dashBoardData.doorSupervisionShifts &&
                                        this.props.dashBoardData.doorSupervisionShifts.toLowerCase() ==
                                            "nothing scheduled" ? (
                                            <span>{this.props.dashBoardData.doorSupervisionShifts}</span>
                                        ) : (
                                            <Link
                                                to={{ pathname: "" }}
                                                /* THE ID HERE MUST BE UNIQUE AND REFERENCED BY THE CORRESPONDING POPOVER */
                                                id="doorSupervisionLink"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.toggleDoorSupervisionPopOver();
                                                }}
                                            >
                                                {this.props.dashBoardData
                                                    ? this.props.dashBoardData.doorSupervisionShifts
                                                    : null}
                                            </Link>
                                        )
                                    ) : null}
                                    <Popover
                                        /* THE ID HERE MUST MATCH THE CORRESPONDING LINK */
                                        target="#doorSupervisionLink"
                                        position="top"
                                        width={550}
                                        visible={this.state.doorSupervisionPopOverVisible}
                                        onHiding={this.toggleDoorSupervisionPopOver}
                                        contentRender={this.renderDoorSupervisionPopOverContent}
                                    />
                                </span>
                            </li>
                        )}
                        {entertainmentServicePresent && (
                            <li className="row home-page__legend--entertainment">
                                <span className="col">Entertainment</span>
                                <span className="col">
                                    {this.props.dashBoardData && entertainmentServicePresent ? (
                                        this.props.dashBoardData.entertainmentShifts &&
                                        this.props.dashBoardData.entertainmentShifts.toLowerCase() ==
                                            "nothing scheduled" ? (
                                            <span>{this.props.dashBoardData.entertainmentShifts}</span>
                                        ) : (
                                            <Link
                                                to={{ pathname: "" }}
                                                /* THE ID HERE MUST BE UNIQUE AND REFERENCED BY THE CORRESPONDING POPOVER */
                                                id="entertainmentLink"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.toggleEntertainmentPopOver();
                                                }}
                                            >
                                                {this.props.dashBoardData
                                                    ? this.props.dashBoardData.entertainmentShifts
                                                    : null}
                                            </Link>
                                        )
                                    ) : null}
                                    <Popover
                                        /* THE ID HERE MUST MATCH THE CORRESPONDING LINK */
                                        target="#entertainmentLink"
                                        position="top"
                                        width={550}
                                        visible={this.state.entertainmentPopOverVisible}
                                        onHiding={this.toggleEntertainmentPopOver}
                                        contentRender={this.renderEntertainmentPopOverContent}
                                    />
                                </span>
                            </li>
                        )}
                        {gardeningServicePresent && (
                            <li className="row home-page__legend--gardening">
                                <span className="col">Gardening</span>
                                <span className="col">
                                    {this.props.dashBoardData && gardeningServicePresent ? (
                                        this.props.dashBoardData.gardeningShifts &&
                                        this.props.dashBoardData.gardeningShifts.toLowerCase() ==
                                            "nothing scheduled" ? (
                                            <span>{this.props.dashBoardData.gardeningShifts}</span>
                                        ) : (
                                            <Link
                                                to={{ pathname: "" }}
                                                /* THE ID HERE MUST BE UNIQUE AND REFERENCED BY THE CORRESPONDING POPOVER */
                                                id="gardeningLink"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.toggleGardeningPopOver();
                                                }}
                                            >
                                                {this.props.dashBoardData
                                                    ? this.props.dashBoardData.gardeningShifts
                                                    : null}
                                            </Link>
                                        )
                                    ) : null}
                                    <Popover
                                        /* THE ID HERE MUST MATCH THE CORRESPONDING LINK */
                                        target="#gardeningLink"
                                        position="top"
                                        width={550}
                                        visible={this.state.gardeningPopOverVisible}
                                        onHiding={this.toggleGardeningPopOver}
                                        contentRender={this.renderGardeningPopOverContent}
                                    />
                                </span>
                            </li>
                        )}
                        {maintenanceServicePresent && (
                            <li className="row home-page__legend--maintenance">
                                <span className="col">Maintenance</span>
                                <span className="col">
                                    {this.props.dashBoardData && maintenanceServicePresent ? (
                                        this.props.dashBoardData.maintenanceShifts &&
                                        this.props.dashBoardData.maintenanceShifts.toLowerCase() ==
                                            "nothing scheduled" ? (
                                            <span>{this.props.dashBoardData.maintenanceShifts}</span>
                                        ) : (
                                            <Link
                                                to={{ pathname: "" }}
                                                /* THE ID HERE MUST BE UNIQUE AND REFERENCED BY THE CORRESPONDING POPOVER */
                                                id="maintenanceLink"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.toggleMaintenancePopOver();
                                                }}
                                            >
                                                {this.props.dashBoardData
                                                    ? this.props.dashBoardData.maintenanceShifts
                                                    : null}
                                            </Link>
                                        )
                                    ) : null}
                                    <Popover
                                        /* THE ID HERE MUST MATCH THE CORRESPONDING LINK */
                                        target="#maintenanceLink"
                                        position="top"
                                        width={550}
                                        visible={this.state.maintenancePopOverVisible}
                                        onHiding={this.toggleMaintenancePopOver}
                                        contentRender={this.renderMaintenancePopOverContent}
                                    />
                                </span>
                            </li>
                        )}
                        {miscelleanousServicePresent && (
                            <li className="row home-page__legend--miscelleanous">
                                <span className="col">Miscelleanous</span>
                                <span className="col">
                                    {this.props.dashBoardData && miscelleanousServicePresent ? (
                                        this.props.dashBoardData.miscelleanousShifts &&
                                        this.props.dashBoardData.miscelleanousShifts.toLowerCase() ==
                                            "nothing scheduled" ? (
                                            <span>{this.props.dashBoardData.miscelleanousShifts}</span>
                                        ) : (
                                            <Link
                                                to={{ pathname: "" }}
                                                /* THE ID HERE MUST BE UNIQUE AND REFERENCED BY THE CORRESPONDING POPOVER */
                                                id="miscelleanousLink"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.toggleMiscelleanousPopOver();
                                                }}
                                            >
                                                {this.props.dashBoardData
                                                    ? this.props.dashBoardData.miscelleanousShifts
                                                    : null}
                                            </Link>
                                        )
                                    ) : null}
                                    <Popover
                                        /* THE ID HERE MUST MATCH THE CORRESPONDING LINK */
                                        target="#miscelleanousLink"
                                        position="top"
                                        width={550}
                                        visible={this.state.miscelleanousPopOverVisible}
                                        onHiding={this.toggleMiscelleanousPopOver}
                                        contentRender={this.renderMiscPopOverContent}
                                    />
                                </span>
                            </li>
                        )}
                        {windowServicePresent && (
                            <li className="row home-page__legend--windowCleaning">
                                <span className="col">Windows</span>
                                <span className="col">
                                    {this.props.dashBoardData && windowServicePresent ? (
                                        this.props.dashBoardData.windowCleaningShifts &&
                                        this.props.dashBoardData.windowCleaningShifts.toLowerCase() ==
                                            "nothing scheduled" ? (
                                            <span>{this.props.dashBoardData.windowCleaningShifts}</span>
                                        ) : (
                                            <Link
                                                to={{ pathname: "" }}
                                                /* THE ID HERE MUST BE UNIQUE AND REFERENCED BY THE CORRESPONDING POPOVER */
                                                id="windowCleaningLink"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.toggleWindowCleaningPopOver();
                                                }}
                                            >
                                                {this.props.dashBoardData
                                                    ? this.props.dashBoardData.windowCleaningShifts
                                                    : null}
                                            </Link>
                                        )
                                    ) : null}
                                    <Popover
                                        /* THE ID HERE MUST MATCH THE CORRESPONDING LINK */
                                        target="#windowCleaningLink"
                                        position="top"
                                        width={550}
                                        visible={this.state.windowCleaningPopOverVisible}
                                        onHiding={this.toggleWindowCleaningPopOver}
                                        contentRender={this.renderWindowPopOverContent}
                                    />
                                </span>
                            </li>
                        )}
                    </ul>
                ) : this.props.dataNotFoundMsg || this.props.venueId == "" ? (
                    this.props.dataNotFoundMsg
                ) : (
                    <LoadIndicator
                        id="button-indicator"
                        height={60}
                        width={60}
                        className="home-page__todayloader"
                        visible={true}
                    />
                )}
            </>
        );
    }
}
export default TodayCard;
