import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CustomStore from "devextreme/data/custom_store";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import { LoadPanel } from "devextreme-react";
import SimpleGridService, { GridTypes, ShiftMatrixRemoteGridRowItem } from "../../services/SimpleGridService";
import ShiftMatrixGridContents from "./ShiftMatrixGridContents";



interface InvoiceByIdGridProps extends WithTranslation {
    id: string;
    setBillableItemList: (billableItemList: string) => void;
}

interface InvoiceByIdGridState {
    dataSource: [],
    loadIndicatorForGrid: boolean
}

class InvoiceByIdGrid extends Component<InvoiceByIdGridProps> {
    state: InvoiceByIdGridState;
    service: SimpleGridService;
    constructor(props: InvoiceByIdGridProps) {
        super(props);
        this.state = {
            dataSource: [],
            loadIndicatorForGrid: false
        }
        this.service = new SimpleGridService();
    }

    getResults = (loadOptions: any) => {
        let params = buildRequestParameters(loadOptions);
        return this.service.getInvoiceByIdGridRowsWithinRemoteOperations(params, this.props.id)
            .then(this.handleSuccessLoadDataSource)
            .catch(() => { throw 'Data Loading Error'; });
    }

    handleSuccessLoadDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });
        let billableItemList = "";
        if (response.data.data && response.data.data.billableItemRemoteGrid) {
            response.data.data.billableItemRemoteGrid.forEach((rowElement: ShiftMatrixRemoteGridRowItem) => {
                billableItemList += rowElement.id + ",";
            });
            this.props.setBillableItemList(billableItemList);
        }
        if (response.data.data && response.data.data.billableItemRemoteGrid) {
            return {
                data: response.data.data.billableItemRemoteGrid ? response.data.data.billableItemRemoteGrid : [],
                totalCount: response.data.data.totalCount
            };

        }
        else {
            return {
                data: response.data.data ? response.data.data : [],
                totalCount: response.data.totalCount
            };
        }
    }

    componentDidMount() {
        this.setState({
            loadIndicatorForGrid: true
        });
        this.loadDataSource();
    }

    componentDidUpdate(prevProps: InvoiceByIdGridProps) {
        if (this.props.id != prevProps.id) {
            this.loadDataSource();
        }

    }

    loadDataSource = () => {
        let dataSource = new CustomStore({
            key: "id",
            load: this.getResults,
        });

        this.setState({
            dataSource: dataSource
        });
    }


    render() {
        return (
            <div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadIndicatorForGrid} />
                <ShiftMatrixGridContents dataSource={this.state.dataSource} navigateFrom={GridTypes.billing} />
            </div>
        );
    }
}

export default withTranslation()(InvoiceByIdGrid);