import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import { SelectBox, DateBox, LoadPanel, TextBox, CheckBox, NumberBox } from "devextreme-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import periodMatrixFormUtil, { AddEditPeriodMatrixFormData } from "../../components/Matrix/PeriodMatrixFormUtil";
import MatrixGridService from "../../services/MatrixService";
import BillingFormUtils from "../../components/Billing/BillingFormUtils";
import {
    DateBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
    NumberBoxOnValueChangedEvent,
    onNumberBoxKeyDownEvent
} from "../../types/DevExtremeTypes";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;
//#region props
interface PeriodMatrixFormProps extends WithTranslation {
    location: any;
    history: any;
    onApplySuccess: (applySignal: string) => void;
}

// State
interface PeriodMatrixFormState {
    //TODO : Define the form state here.
    clientLookUp: LookupTypeItem[];
    editPeriodFormData: AddEditPeriodMatrixFormData;
    weekDaysLookup: LookupTypeItem[];
    loadPanelVisible: boolean;
    errorMessage: any[];
}

class PeriodMatrixForm extends React.Component<PeriodMatrixFormProps> {
    lookupService: LookupService;
    state: PeriodMatrixFormState;
    utils: periodMatrixFormUtil;
    sharedUtils: sharedUtils;
    matrixService: MatrixGridService;
    periodWeekUtil: BillingFormUtils;
    constructor(props: PeriodMatrixFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.utils = new periodMatrixFormUtil();
        this.sharedUtils = new sharedUtils();
        this.matrixService = new MatrixGridService();
        this.periodWeekUtil = new BillingFormUtils();
        var convertedEditPeriodData = this.utils.initializePeriodFormItem(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        this.state = {
            clientLookUp: [],
            editPeriodFormData: convertedEditPeriodData,
            weekDaysLookup: [],
            loadPanelVisible: false,
            errorMessage: [],
        };
        this.getEditViewItemDataSource = this.getEditViewItemDataSource.bind(this);
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.dayType)
            .then(this.handleSuccesDayTypeLookup)
            .catch(this.handleStandardError);
        this.getEditViewItemDataSource();
    }
    handleSuccesDayTypeLookup = (response: AxiosResponse<any>) => {
        this.setState({
            weekDaysLookup: response.data.data,
        });
    };

    getEditViewItemDataSource() {
        if (this.props.location.state && this.props.location.state.id) {
            this.setState({
                loadPanelVisible: true,
            });
            this.matrixService
                .getPeriodFormData(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
            this.lookupService
                .getLookupByLookupTypeIndexAll(LookupTypeIndexes.clientType)
                .then(this.handleSuccessClientLookUp)
                .catch(this.handleStandardError);
        }
        else{
            this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
            .then(this.handleSuccessClientLookUp)
            .catch(this.handleStandardError);
        }
    }

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        var viewDataSource = this.utils.convertPeriodMatrixItemResponeToFormData(response.data.data);
        this.setState({
            editPeriodFormData: viewDataSource,
            loadPanelVisible: false,
        });
    };

    handleSuccessClientLookUp = (response: AxiosResponse<any>) => {
        var clientLUItem: LookupTypeItem;
        var clientLUItemArray: LookupTypeItem[];
        clientLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            clientLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            clientLUItemArray.push(clientLUItem);
        });
        this.setState({
            clientLookUp: clientLUItemArray,
        });
    };

    handleStandardError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleChangeClient = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                businessEntityId: dxValueChange.value,
            },
        });
    };

    handleChangePeriod = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                periodNumber: this.sharedUtils.convertNumberToString(dxValueChange.value),
            },
        });
    };

    handleChangeWeek = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                periodWeek: this.sharedUtils.convertNumberToString(dxValueChange.value),
            },
        });
    };

    handleChangeWeekStartDay = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                daysOfWeekId: dxValueChange.value,
            },
        });
    };

    handleChangeDateFrom = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                dateFrom: dxValueChange.value,
            },
        });
    };

    handleChangeDateTo = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                dateTo: dxValueChange.value,
            },
        });
    };

    handleChangeYearNumber = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                yearNumber: this.sharedUtils.convertNumberToString(dxValueChange.value),
            },
        });
    };

    handleChangeActive = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                isActive: dxValueChange.value,
            },
        });
    };

    onApplyClick = () => {
        this.setState({
            loadPanelVisible: true,
        });
        var periodSubmitRequest = this.utils.convertFormDataToPeriodMatrixItemRequest(this.state.editPeriodFormData);
        this.matrixService
            .postPeriodData(periodSubmitRequest)
            .then(this.handleSuccessDataSubmission)
            .catch((err) => {
                this.setState({
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                    loadPanelVisible: false,
                });
            });
    };

    //A helper function to display the error message, might move this to a common utils or something.
    renderErrorMessage = (errorMessage: any[]) => {
        let errorData: React.ReactNode = <></>;
        if (errorMessage) {
            errorData = (
                <span className="unscheduled-shift">
                    <ul>
                        {errorMessage.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    {item.columnName}: {item.errorMessage}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            );
        }
        return errorData;
    };

    //A helper function that will prevent exponential "e" from being entered into the numberBox.
    handleKeyPressEvent = (e: onNumberBoxKeyDownEvent) => {
        if (e.event.key == "e") {
            e.event.preventDefault();
        }
    };

    handleSuccessDataSubmission = () => {
        this.setState({
            loadPanelVisible: false,
        });

        if (this.props.location.state && this.props.location.state.id) {
            this.props.onApplySuccess("EDIT");
        } else if (this.state.editPeriodFormData.id == "0") {
            this.props.onApplySuccess("ADD");
        }
    };
    //#region render Component
    render() {
        var { errorMessage } = this.state;
        return (
            <form data-testid="addEditShift-form" className="edit-form my-5 form_border">
                <div className="container mb-6 edit-form__column">
                    <div className="row">
                        <h2 className="billingForm-heading">Period Matrix Form</h2>
                    </div>
                    <br></br>
                    {this.renderErrorMessage(errorMessage)}
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">ID</div>
                        <div className="mt-3 col-10 col-lg-2">
                            <TextBox value={this.state.editPeriodFormData.id} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Client</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <SelectBox
                                dataSource={this.state.clientLookUp}
                                displayExpr="value"
                                valueExpr="id"
                                onValueChanged={this.handleChangeClient}
                                value={this.state.editPeriodFormData.businessEntityId}
                            ></SelectBox>
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Week Start Day</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <SelectBox
                                dataSource={this.state.weekDaysLookup}
                                displayExpr="value"
                                valueExpr="id"
                                onValueChanged={this.handleChangeWeekStartDay}
                                value={this.state.editPeriodFormData.daysOfWeekId}
                            ></SelectBox>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Period Year</div>
                        <div className="mt-3 col-10 col-lg-2">
                            <NumberBox
                                min={1}
                                showSpinButtons={true}
                                placeholder=""
                                value={
                                    this.state.editPeriodFormData.yearNumber
                                        ? this.sharedUtils.convertStringToInt(this.state.editPeriodFormData.yearNumber)
                                        : undefined
                                }
                                onValueChanged={this.handleChangeYearNumber}
                                onKeyDown={this.handleKeyPressEvent}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Period Number</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                min={1}
                                showSpinButtons={true}
                                placeholder=""
                                onValueChanged={this.handleChangePeriod}
                                value={
                                    this.state.editPeriodFormData.periodNumber
                                        ? this.sharedUtils.convertStringToInt(
                                              this.state.editPeriodFormData.periodNumber
                                          )
                                        : undefined
                                }
                                onKeyDown={this.handleKeyPressEvent}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Period Week</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                min={1}
                                showSpinButtons={true}
                                placeholder=""
                                onValueChanged={this.handleChangeWeek}
                                value={
                                    this.state.editPeriodFormData.periodWeek
                                        ? this.sharedUtils.convertStringToInt(this.state.editPeriodFormData.periodWeek)
                                        : undefined
                                }
                                onKeyDown={this.handleKeyPressEvent}
                            />
                        </div>
                    </div>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="row">
                        <div className="mt-3 col-10 col-lg-3">
                            <CheckBox
                                value={this.state.editPeriodFormData.isActive}
                                onValueChanged={this.handleChangeActive}
                                width={130}
                                text="Active?"
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Date From</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeDateFrom}
                                value={
                                    this.state.editPeriodFormData.dateFrom
                                        ? this.state.editPeriodFormData.dateFrom
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Date To</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeDateTo}
                                value={
                                    this.state.editPeriodFormData.dateTo
                                        ? this.state.editPeriodFormData.dateTo
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2 col-lg-1" />
                        <div className="col-10 col-lg-2" />
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Last Updated On</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editPeriodFormData.lastUpdatedOn} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Last Updated By</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editPeriodFormData.lastUpdatedBy} readOnly={true} />
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="col-12 col-lg-2 mb-3"></div>
                        <div className="col-12 col-lg-2 mb-3"></div>
                        <div className="col-12 col-lg-3 mb-3"></div>
                        <div className="col-12 col-lg-2 mb-3">
                            <Link
                                to={{
                                    pathname: "/matrixView",
                                    state: {
                                        isCancel: true,
                                    },
                                }}
                                className="btn btn--ghost btn--large"
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                                {this.props.t("addEditShiftRow.financeSection.cancelButton.text")}
                            </Link>
                        </div>
                        <div className="col-12 col-lg-2 mb-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="button"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onApplyClick();
                                }}
                            >
                                <span className="btn__icon"></span>
                                {this.props.t("addEditShiftRow.financeSection.applyButton.text")}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default withTranslation()(PeriodMatrixForm);
