import React from "react";
import { ServiceTypes } from "./ServiceTypes";
import { ServiceTypeMappings } from "./ServiceTypesMappings";

//props
interface ServiceTypesContainerProps {
    location: any;
    history: any;
}
interface ServiceTypesContainerState {
    selectedServiceTypeMappingsButton: boolean;
    selectedServiceTypeButton: boolean;
    refreshSignal: boolean;
}

class ServiceTypesContainer extends React.Component {
    //Initialize the component's state.
    state: ServiceTypesContainerState;
    constructor(props: ServiceTypesContainerProps) {
        super(props);
        this.state = {
            selectedServiceTypeMappingsButton: true,
            selectedServiceTypeButton: false,
            refreshSignal: false,
        };
    }
    //A helper function that would toggle the state of the button to refresh the contents of the Parent Grid.
    toggleButtonStates = (toggleButtonState: boolean) => {
        this.setState({
            selectedServiceTypeMappingsButton: toggleButtonState,
            selectedServiceTypeButton: !toggleButtonState,
        });
    };
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md">
                        <div className="view-queries-grid mt-3">
                            <div className="view-queries-header__controls">
                                <div className="view-queries-header__view-buttons">
                                    <button
                                        className={`btn
                                        view-queries-header__view-button ${
                                            this.state.selectedServiceTypeMappingsButton
                                                ? "view-queries-header__view-button--selected"
                                                : ""
                                        }`}
                                        onClick={() => this.toggleButtonStates(true)}
                                    >
                                        SERVICE TYPE MAPPINGS
                                    </button>
                                    <button
                                        className={`btn
                                        view-queries-header__view-button ${
                                            this.state.selectedServiceTypeButton
                                                ? "view-queries-header__view-button--selected"
                                                : ""
                                        }`}
                                        onClick={() => this.toggleButtonStates(false)}
                                    >
                                        SERVICE TYPES
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    {this.state.selectedServiceTypeMappingsButton ? <ServiceTypeMappings /> : <ServiceTypes />}
                </div>
            </>
        );
    }
}
export default ServiceTypesContainer;
