import React, { MouseEvent, Component } from "react";

interface CardWrapperProps {
    type: string;
    typeTitle: string;
    enumeration: boolean;
    cardIndex?: number;
    cardsTotal?: number;
    fullHeight: boolean;

    children: React.ReactNode;
}

class CardWrapper extends React.Component<CardWrapperProps> {
    constructor(props: CardWrapperProps) {
        super(props);
    }

    render() {
        return (
            <div
                className={
                    "euk-card euk-card--" + this.props.type + (this.props.fullHeight ? " euk-card--full-height" : "")
                }
            >
                <div className="row euk-card__type-header">
                    <span className="col-12 col-md-8 euk-card__type-title">{this.props.typeTitle}</span>
                    {this.props.enumeration ? (
                        <span className="col-12 col-md-4 text-md-right euk-card__type-enumeration">
                            {this.props.cardIndex} of {this.props.cardsTotal}
                        </span>
                    ) : (
                        ""
                    )}
                </div>
                {this.props.children}
            </div>
        );
    }
}
export default CardWrapper;
