import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DateBox, TextBox, NumberBox, LoadPanel } from "devextreme-react";
import {
    DateBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
    NumberBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import { LookupTypeIndexes } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import AdditionalCleaningFormService from "../../services/AdditionalCleaningFormService";
import additionalCleaningFormUtils, { CleaningFormData } from "./AdditionalCleaningFormUtils";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";

interface AdditionalCleaningFormProps extends WithTranslation {
    location: any;
    history: any;
}

interface AdditionalCleaningFormState {
    loadPanelVisible: boolean;
    cleaningFormData: CleaningFormData;
    errorMessage: [];
    confirmationPanelVisible: boolean;
    errorPanelVisible: boolean;
    createNewRequestButtonVisible: boolean;
}

// Component
class AdditionalCleaningForm extends React.Component<AdditionalCleaningFormProps> {
    utils: additionalCleaningFormUtils;
    state: AdditionalCleaningFormState;
    additionalCleaningSvc: AdditionalCleaningFormService;

    constructor(props: AdditionalCleaningFormProps) {
        super(props);
        this.additionalCleaningSvc = new AdditionalCleaningFormService();
        this.utils = new additionalCleaningFormUtils();

        var convertedCleaningFormData = this.utils.initializeCleaningItems("");
        // Initialize state
        this.state = {
            loadPanelVisible: false,
            cleaningFormData: convertedCleaningFormData,
            errorMessage: [],
            confirmationPanelVisible: false,
            errorPanelVisible: false,
            createNewRequestButtonVisible: false,
        };
    }

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            cleaningFormData: {
                ...this.state.cleaningFormData,
                venueId: dxValueChange.value,
            },
        });
    };

    handleChangeWorkDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            cleaningFormData: {
                ...this.state.cleaningFormData,
                workDescription: dxValueChange.value,
            },
        });
    };

    handleChangeWorkDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            cleaningFormData: {
                ...this.state.cleaningFormData,
                workDate: dxValueChange.value,
            },
        });
    };

    handleChangeAgreedCost = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            cleaningFormData: {
                ...this.state.cleaningFormData,
                agreedCost: dxValueChange.value,
            },
        });
    };
    handleChangeAuthorisedBy = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            cleaningFormData: {
                ...this.state.cleaningFormData,
                authorisedBy: dxValueChange.value,
            },
        });
    };
    onSubmit = () => {
        this.setState({
            loadPanelVisible: true,
        });
        var addCleaningItemSubmitRequest = this.utils.convertFormDataToCleaningRequest(this.state.cleaningFormData);
        //passing object data to the server
        this.additionalCleaningSvc
            .saveAdditionalCleaningItem(addCleaningItemSubmitRequest)
            .then(this.handleFormDataSubmissionSuccess)
            .catch(this.handleFormSubmissionFailure)
            .finally(() => {
                this.hideLoadpanel();
            });
    };

    handleFormDataSubmissionSuccess = (response: AxiosResponse<any>) => {
        this.hideLoadpanel();
        this.setState({
            confirmationPanelVisible: true,
            errorPanelVisible: false,
            createNewRequestButtonVisible: true,
        });
    };

    hideLoadpanel() {
        this.setState({ loadPanelVisible: false });
    }

    handleFormSubmissionFailure = (err: any) => {
        this.hideLoadpanel();
        this.setState({
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null,
            errorPanelVisible: true,
            confirmationPanelVisible: false,
            createNewRequestButtonVisible: false,
        });
    };

    onCreateNewRequest = () => {
        let form = document.getElementById("additionalCleaningForm");

        if (form) Array.from(form.querySelectorAll("input")).forEach((input) => (input.value = ""));

        this.setState({
            errorPanelVisible: false,
            confirmationPanelVisible: false,
            createNewRequestButtonVisible: false,
            cleaningFormData: this.utils.initializeCleaningItems(""),
        });
    };

    //#region render Component
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <BulletinPanel />
                </SideBarTemplate>
                <div id="additionalCleaningForm" className="page-content--with-sidebar-hidden-mobile">
                    <div className="col-12">
                        <h3 className="card-form__page-heading">Contract Cleaning - Request Additional Cleaning</h3>
                        <form data-testid="addEditPayment-form" className="card card-form my-5">
                            <div className="card-body">
                                <section>
                                    <h3 className="card-form__heading">Request Additional Cleaning</h3>
                                    <div className="card-form__field card-form__field--wide">
                                        {this.props.t("additionalCleaning.raiseForm.venue.label")}
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.venueType}
                                            onValueChanged={this.handleChangeVenueTypeSelect}
                                            isRoot={true}
                                            parentMappingId=""
                                            value={this.state.cleaningFormData.venueId}
                                            displayExpression={"value"}
                                        />
                                    </div>
                                    <div className="card-form__field card-form__field--wide">
                                        {this.props.t("additionalCleaning.raiseForm.workDescription.label")}
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                        <TextBox
                                            onValueChanged={this.handleChangeWorkDescription}
                                            value={this.state.cleaningFormData.workDescription}
                                            placeholder={this.props.t(
                                                "additionalCleaning.raiseForm.workDescription.placeholder"
                                            )}
                                            maxLength={25}
                                        ></TextBox>
                                    </div>
                                    <div className="card-form__field card-form__field--very-narrow">
                                        {this.props.t("additionalCleaning.raiseForm.workDate.label")}
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                        <DateBox
                                            onValueChanged={this.handleChangeWorkDate}
                                            value={
                                                this.state.cleaningFormData.workDate
                                                    ? this.state.cleaningFormData.workDate
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                            placeholder={this.props.t(
                                                "additionalCleaning.raiseForm.workDate.placeholder"
                                            )}
                                        ></DateBox>
                                    </div>
                                    <div className="card-form__field card-form__field--very-narrow">
                                        {this.props.t("additionalCleaning.raiseForm.agreedCost.label")}
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                        <NumberBox
                                            format={{ type: "currency", precision: 2, currency: "GBP" }}
                                            onValueChanged={this.handleChangeAgreedCost}
                                            valueChangeEvent="keyup"
                                            value={this.state.cleaningFormData.agreedCost}
                                        />
                                    </div>
                                    <div className="card-form__field card-form__field--wide">
                                        {this.props.t("additionalCleaning.raiseForm.authorisedBy.label")}
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                        <TextBox
                                            onValueChanged={this.handleChangeAuthorisedBy}
                                            value={this.state.cleaningFormData.authorisedBy}
                                            placeholder={this.props.t(
                                                "additionalCleaning.raiseForm.authorisedBy.placeholder"
                                            )}
                                        ></TextBox>
                                    </div>
                                    {this.state.confirmationPanelVisible ? (
                                        <p className="card-form__confirmation">
                                            Your additional cleaning request has been submitted
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.errorPanelVisible ? (
                                        <ul className="card-form__errors">
                                            {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                                return (
                                                    <li key={uniqueKey}>
                                                        {item.columnName}: {item.errorMessage}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    ) : (
                                        ""
                                    )}
                                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                                </section>
                                <section className="card-form__button-container">
                                    {!this.state.createNewRequestButtonVisible ? (
                                        <div className="col-12 col-lg-4 col-xl-2">
                                            <button
                                                className="btn btn-primary btn--large mt-3"
                                                type="submit"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.onSubmit();
                                                }}
                                            >
                                                Submit Request
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="col-12 col-lg-6 col-xl-4">
                                            <button
                                                className="btn btn-primary btn--large mt-3"
                                                type="reset"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.onCreateNewRequest();
                                                }}
                                            >
                                                Create another request
                                            </button>
                                        </div>
                                    )}
                                </section>
                            </div>
                        </form>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withTranslation()(AdditionalCleaningForm);
