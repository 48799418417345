import { months, days, daysArray } from "../Outstanding-Actions/OutstandingActionsUtilities";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;
export interface RaiseIncidentObject {
    incidentId: string;
    clientBusinessEntityId: string;
    clientBusinessEntityName: string;
    providerBusinessEntityId: string;
    providerBusinessEntityName: string;
    venueId: string;
    houseNoVenueName: string;
    venueNamePostCode: string;
    description: string;
    severity: string;
    injuries: string;
    injuriesDescription: string;
    lastUpdatedOn: NullableDate;
    lastUpdatedBy: string;
    ambulanceAttended: string;
    date: NullableDate;
    emergenecyServicesAttended: string;
    fireAttended: string;
    policeAttended: string;
    reportedByName: string;
    reportedByRole: string;
    time: NullableDate;
    customDateTime: string;
    title: string;
    customEmergencyServices: string;
    incidentDocument: [
        {
            id: string;
            name: string;
            link: string;
            displayLink: string;
            formatType: string;
            documentType: string;
            dateFrom: string;
            dateTo: string;
            updatedBy: string;
            updatedOn: string;
        }
    ];
    incidentComment: [
        {
            id: number;
            incidentId: number;
            comment: string;
            reportedByName: string;
            reportedByRole: string;
            lastUpdatedBy: number;
            lastUpdatedOn: string;
            incident: string;
            user: string;
        }
    ];
    validationErrors: [];
}

export default class RaiseIncidentUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
    }
    initializeRequestItem = (): RaiseIncidentObject => {
        var formData: RaiseIncidentObject = {
            incidentId: "0",
            clientBusinessEntityId: "",
            clientBusinessEntityName: "",
            providerBusinessEntityId: "",
            providerBusinessEntityName: "",
            venueId: "",
            houseNoVenueName: "",
            venueNamePostCode: "",
            description: "",
            severity: "",
            injuries: "",
            injuriesDescription: "",
            lastUpdatedOn: undefined,
            lastUpdatedBy: "",
            ambulanceAttended: "",
            date: undefined,
            emergenecyServicesAttended: "",
            fireAttended: "",
            policeAttended: "",
            reportedByName: "",
            reportedByRole: "",
            time: undefined,
            customDateTime: "",
            title: "",
            customEmergencyServices: "",
            incidentDocument: [
                {
                    id: "",
                    name: "",
                    link: "",
                    displayLink: "",
                    formatType: "",
                    documentType: "",
                    dateFrom: "",
                    dateTo: "",
                    updatedBy: "",
                    updatedOn: "",
                },
            ],
            incidentComment: [
                {
                    id: 0,
                    incidentId: 0,
                    comment: "",
                    reportedByName: "",
                    reportedByRole: "",
                    lastUpdatedBy: 0,
                    lastUpdatedOn: "",
                    incident: "",
                    user: "",
                },
            ],
            validationErrors: [],
        };
        return formData;
    };

    convertdataBaseDateToCustomizedFormat(dateValue: string): string {
        var convertedDate: string = "";
        var monthName: string = "";
        var dayOfWeek: string = "";
        var day: number = 0;
        var month: number = 0;
        var year: number = 0;
        var dayWithTime: string = "";
        var dayName: string = "";
        var date: Date;

        if (dateValue && dateValue.length > 0) {
            var dateParts = dateValue.split("-");
            year = parseInt(dateParts[0]);
            month = parseInt(dateParts[1]);
            dayWithTime = dateParts[2];
            var dayWithTimeParts = dayWithTime.split("T");
            day = parseInt(dayWithTimeParts[0]);
            date = new Date(year, month - 1, day, 0, 0, 0, 0);
        } else {
            date = new Date();
            day = date.getDate();
            month = date.getMonth() + 1;
            year = date.getFullYear();
        }
        var dayIndex = date.getDay();
        dayName = days[dayIndex];
        if (day >= 1 && day < 10) {
            dayOfWeek = daysArray[day - 1];
        }
        monthName = months[month - 1]; //Takes in the month number and converts it into the Month Name.
        convertedDate = `${dayName} ${dayOfWeek ? dayOfWeek : day} ${monthName} ${year}`;
        return convertedDate;
    }

    // Convert the server response into the component's form state
    convertIncidentResponeToFormData(serverResponse: RaiseIncidentObject): RaiseIncidentObject {
        var formData: RaiseIncidentObject = {
            incidentId: serverResponse.incidentId,
            clientBusinessEntityId: serverResponse.clientBusinessEntityId,
            clientBusinessEntityName: serverResponse.clientBusinessEntityName,
            providerBusinessEntityId: serverResponse.providerBusinessEntityId,
            providerBusinessEntityName: serverResponse.providerBusinessEntityName,
            venueId: serverResponse.venueId,
            houseNoVenueName: serverResponse.houseNoVenueName,
            venueNamePostCode: serverResponse.venueNamePostCode,
            description: serverResponse.description,
            severity: serverResponse.severity,
            injuries: serverResponse.injuries,
            injuriesDescription: serverResponse.injuriesDescription,
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            lastUpdatedBy: serverResponse.lastUpdatedBy,
            ambulanceAttended: serverResponse.ambulanceAttended,
            date: serverResponse.date,
            emergenecyServicesAttended: serverResponse.emergenecyServicesAttended,
            fireAttended: serverResponse.fireAttended,
            policeAttended: serverResponse.policeAttended,
            reportedByName: serverResponse.reportedByName,
            reportedByRole: serverResponse.reportedByRole,
            time: serverResponse.time,
            customDateTime: serverResponse.customDateTime,
            title: serverResponse.title,
            customEmergencyServices: serverResponse.customEmergencyServices,
            incidentDocument: serverResponse.incidentDocument,
            incidentComment: serverResponse.incidentComment,
            validationErrors: [],
        };

        return formData;
    }
    isBlank = (filterId: string) => {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    };
}
