import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import DatePanel from "../side-bar/DatePanel";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import IndependentBookingForm from "./IndependentBookingForm";

interface IndependentBookingPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}
interface IndependentBookingPageComponentState {}
class IndependentBookingPage extends Component<IndependentBookingPageComponentProps> {
    state: IndependentBookingPageComponentState;
    constructor(props: IndependentBookingPageComponentProps) {
        super(props);
        this.state = {
            refreshGrid: false,
            gigCount: "0",
        };
    }
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>
                <div id="independentBooking" className="page-content--with-sidebar-hidden-mobile">
                    <div className="row">
                        <div className="col-12">
                            <IndependentBookingForm
                                location={this.props.location}
                                history={this.props.history}
                            ></IndependentBookingForm>
                        </div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(IndependentBookingPage);
