import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { WithTranslation } from "react-i18next";
import MatrixGridService from "../../services/MatrixService";
import { confirm } from "devextreme/ui/dialog";

interface PeriodMatrixActionCellComponentProps extends WithTranslation {
    data: any;
}

interface PeriodMatrixActionCellComponentState {}

class PeriodMatrixActionCellComponent extends React.Component<PeriodMatrixActionCellComponentProps> {
    state: PeriodMatrixActionCellComponentState;
    Service: MatrixGridService;
    constructor(props: PeriodMatrixActionCellComponentProps) {
        super(props);
        this.setLoadIndicatorVisibility = this.setLoadIndicatorVisibility.bind(this);
        this.Service = new MatrixGridService();
        this.state = {};
        this.handleDeleteOnClick = this.handleDeleteOnClick.bind(this);
        this.handleSuccessDelete = this.handleSuccessDelete.bind(this);
    }

    setLoadIndicatorVisibility(isVisible: boolean): void {
        this.setState({
            loadIndicatorVisible: isVisible,
        });
    }

    handleDeleteOnClick = () => {
        confirm("<div class='text-center'>Delete this row?</div>", "Delete").then((hasConfirmed) => {
            if (hasConfirmed) {
                this.setLoadIndicatorVisibility(true);
                //call the API for posting Delete Period Id's and handle the response
                this.Service.submitDeletePeriod(this.props.data.data.id.toString())
                    .then(this.handleSuccessDelete)
                    .catch((error) => {
                        var respMessage: string = "submitDeletePeriod failed with response: " + JSON.stringify(error);

                        if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                            // AppInsights is not available
                            console.error(respMessage);
                        }
                    });
            }
        });
    };

    handleSuccessDelete = () => {
        this.props.data.component.deleteRow(this.props.data.rowIndex);
        this.props.data.component.repaint();
    };

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <Link
                            title="Edit"
                            className="icon-btn"
                            onClick={this.handleDeleteOnClick}
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    isDelete: true,
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Link>
                        <Link
                            title="Edit"
                            className="icon-btn"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    id: this.props.data.data.id.toString(),
                                    isEdit: true,
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default PeriodMatrixActionCellComponent;
