import React, { Component, MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersV, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { isDesktop } from "../../common/CommonUIUtils";

interface SideBarProps extends WithTranslation {
    isFixed?: boolean; //the sidebar appears on the left for desktop and at the top for mobile - should then use sidebar--fixed class
    isFixedDesktopOnly?: boolean; //the sidebar appears on the left for desktop and does not appear for mobile - should then use sidebar--fixed-desktop
    isFullBleed?: boolean; //e.g. for find an artist where the showcase background goes full width of sidebar
    children?: React.ReactNode;
    closeSidebar?: boolean;
}

interface SideBarState {
    sidebarIsOpen: boolean;
}

class SideBarTemplate extends Component<SideBarProps> {
    state: SideBarState;
    asideClassName: string;
    toggleButtonClassName: string;
    sidebar: any;
    mainContent: any;
    footer: any;

    constructor(props: SideBarProps) {
        super(props);
        this.state = {
            sidebarIsOpen: false,
        };
        // This binding is necessary to make `this` work in the callback
        this.openSidebar = this.openSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);

        this.asideClassName = this.getAsideClassName();
        this.toggleButtonClassName = this.getToggleButtonClassName();
    }

    componentDidMount() {
        this.sidebar = document.getElementsByClassName("sidebar")[0];
        this.mainContent = document.getElementsByClassName("page-content--with-sidebar")[0];
        this.footer = document.getElementsByClassName("site-footer")[0];
        if (isDesktop()) {
            this.openSidebar(false);
        }
        this.toggleFooterWidth(false);
    }

    componentDidUpdate = (prevprops: SideBarProps, prevState: SideBarState) => {
        if (this.props.closeSidebar != prevprops.closeSidebar) {
            this.closeSidebar();
        }
    };

    componentWillUnmount() {
        this.resetFooterClasses();
    }

    getAsideClassName() {
        let asideClassName = "";

        if (this.props.isFixed && this.props.isFixedDesktopOnly) {
            throw new Error("Only one of the following properties can be set: isFixed, isFixedDesktopOnly");
        }
        if (this.props.isFixed) {
            asideClassName = " sidebar--open sidebar--wraps-on-narrow";
        } else if (this.props.isFixedDesktopOnly) {
            asideClassName = " sidebar--open-desktop-hidden-mobile";
        } else if (this.props.isFullBleed) {
            asideClassName = " sidebar--full-bleed-closed";
            if (isDesktop()) {
                asideClassName = " sidebar--full-bleed sidebar--open sidebar--open-desktop";
            }
        } else if (isDesktop()) {
            asideClassName = " sidebar--open sidebar--open-desktop";
        }
        return asideClassName;
    }

    getToggleButtonClassName() {
        if (this.props.isFixed) {
            return "sidebar__toggle--fixed";
        } else {
            return "";
        }
    }

    //handle the expand/collapse of full-bleed sidebars where we need to toggle the
    //css class depending on if the sidebar is open or closed
    toggleFullBleedCssClass(sidebar: any) {
        if (sidebar.classList.contains("sidebar--full-bleed")) {
            sidebar.classList.remove("sidebar--full-bleed");
            sidebar.classList.add("sidebar--full-bleed-closed");
        } else if (sidebar.classList.contains("sidebar--full-bleed-closed")) {
            sidebar.classList.remove("sidebar--full-bleed-closed");
            sidebar.classList.add("sidebar--full-bleed");
        }
    }

    //when the sidebar is opened, the footer needs to extend further to the right
    toggleFooterWidth(sidebarOpen: boolean) {
        if (this.footer !== undefined) {
            if (!this.props.isFixed && !this.props.isFixedDesktopOnly) {
                if (sidebarOpen) {
                    this.footer.classList.add("site-footer--with-sidebar-open");
                    this.footer.classList.remove("site-footer--with-sidebar-closed");
                } else {
                    this.footer.classList.remove("site-footer--with-sidebar-open");
                    this.footer.classList.add("site-footer--with-sidebar-closed");
                }
            } else {
                this.footer.classList.remove("site-footer--with-sidebar-open");
            }
        }
    }

    //remove any dynamic css classes from footer before leaving the screen
    resetFooterClasses() {
        if (this.footer !== undefined) {
            if (!this.props.isFixed && !this.props.isFixedDesktopOnly) {
                this.footer.classList.remove("site-footer--with-sidebar-closed");
                this.footer.classList.remove("site-footer--with-sidebar-open");
            }
        }
    }

    openSidebar(viaUserToggle: boolean) {
        this.sidebar.classList.add("sidebar--open");
        this.sidebar.setAttribute("aria-expanded", "true");
        if (viaUserToggle) {
            this.toggleFullBleedCssClass(this.sidebar);
        }

        // TODO once sidebar content is added, need to fix TabIndex on internal content
        if (this.mainContent !== undefined) {
            this.mainContent.classList.add(
                this.props.isFixed || this.props.isFixedDesktopOnly
                    ? "page-content--with-sidebar--fixed"
                    : "page-content--with-sidebar--open"
            );
            this.toggleFooterWidth(true);
        }
        this.setState({ sidebarIsOpen: true });
    }

    closeSidebar() {
        // want to put this into state rather than duplicate but can't get it to work
        this.sidebar.classList.remove("sidebar--open");
        this.sidebar.classList.remove("sidebar--open-desktop");
        this.sidebar.setAttribute("aria-expanded", "false");
        this.toggleFullBleedCssClass(this.sidebar);
        // TODO once sidebar content is added, need to fix TabIndex on internal content
        this.mainContent.classList.remove("page-content--with-sidebar--open");
        this.toggleFooterWidth(false);
        this.setState({ sidebarIsOpen: false });
    }

    toggleSidebar(e: MouseEvent) {
        this.state.sidebarIsOpen ? this.closeSidebar() : this.openSidebar(true);
    }

    render() {
        return (
            <aside
                id="sidebar--grid-controls"
                className={"sidebar " + this.asideClassName}
                aria-label="Grid Filters and Controls"
                aria-expanded="false"
            >
                <div className="sidebar__header">
                    <button
                        className={"sidebar__toggle " + this.toggleButtonClassName}
                        onClick={this.toggleSidebar}
                        aria-controls="sidebar--grid-controls"
                    >
                        <span className="sidebar__toggle-content--closed">
                            <FontAwesomeIcon icon={faSlidersV} />
                        </span>
                        <span className="sidebar__toggle-content--open">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </span>
                    </button>
                </div>
                <div className="sidebar__content" aria-hidden="true">
                    {this.props.children}
                </div>
            </aside>
        );
    }
}

export default withTranslation()(SideBarTemplate);
