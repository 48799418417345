import { SubstituteFormResponse, SubstituteFormRequest } from "../../services/EntertainmentSubstituteService";
import sharedUtils from "../grid/sharedUtilities";

type NullableBool = boolean | null | undefined;
type NullableDate = Date | null | undefined;

export interface AddEditSubstituteFormData {
    id: string;
    businessEntityId: string;
    businessEntityShortName: string;
    shiftBatchMainId: string;
    isActive: string;
    date: NullableDate;
    rate: number;
    shiftBatchMainDay: string;
    shiftBatchMainDateFrom: string;
    shiftBatchMainDateTo: string;
    feeOverride: number;
    feeOverrideFlag: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    internalComments: string;
}

export default class SubstituteFormUtil {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializePeriodFormItem = this.initializePeriodFormItem.bind(this);
        this.convertSubstituteItemResponeToFormData = this.convertSubstituteItemResponeToFormData.bind(this);
        this.convertFormDataToSubstituteItemRequest = this.convertFormDataToSubstituteItemRequest.bind(this);
    }

    initializePeriodFormItem(idFromGrid: string): AddEditSubstituteFormData {
        var formData: AddEditSubstituteFormData = {
            id: "0",
            businessEntityId: "",
            businessEntityShortName: "",
            shiftBatchMainId: "",
            isActive: "true",
            date: undefined,
            rate: 0,
            shiftBatchMainDay: "",
            shiftBatchMainDateFrom: "",
            shiftBatchMainDateTo: "",
            feeOverride: 0,
            feeOverrideFlag: "False",
            lastUpdatedOn: "",
            lastUpdatedBy: "",
            internalComments: "",
        };

        return formData;
    }

    // Convert the server response into the component's form state
    convertSubstituteItemResponeToFormData(serverResponse: SubstituteFormResponse): AddEditSubstituteFormData {
        var formData: AddEditSubstituteFormData = {
            id: serverResponse.id,
            businessEntityId: serverResponse.businessEntityId,
            businessEntityShortName: serverResponse.businessEntityShortName,
            shiftBatchMainId: serverResponse.shiftBatchMainId,
            isActive: serverResponse.isActive,
            date: this.utils.convertStringToDate(serverResponse.date),
            rate: this.utils.convertStringToFloat(serverResponse.rate),
            shiftBatchMainDay: serverResponse.shiftBatchMainDay,
            shiftBatchMainDateFrom: serverResponse.shiftBatchMainDateFrom,
            shiftBatchMainDateTo: serverResponse.shiftBatchMainDateTo,
            feeOverride: this.utils.convertStringToFloat(serverResponse.feeOverride),
            feeOverrideFlag: serverResponse.feeOverrideFlag,
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            lastUpdatedBy: serverResponse.lastUpdatedBy,
            internalComments: serverResponse.internalComments,
        };

        return formData;
    }

    // Convert the form's data into a proper item submit request
    convertFormDataToSubstituteItemRequest(
        formData: AddEditSubstituteFormData,
        resId: string,
        isActive: string,
        dateFrom: string,
        dateTo: string,
        day: string
    ): SubstituteFormRequest {
        var submitRequest: SubstituteFormRequest = {
            id: formData.id,
            businessEntityId: formData.businessEntityId,
            businessEntityShortName: formData.businessEntityShortName,
            shiftBatchMainId: resId,
            isActive: formData.isActive,
            date: this.utils.convertDateToString(formData.date),
            rate: this.utils.convertNumberToString(formData.rate),
            shiftBatchMainDay: day,
            shiftBatchMainDateFrom: dateFrom,
            shiftBatchMainDateTo: dateTo,
            feeOverride: this.utils.convertNumberToString(formData.feeOverride),
            feeOverrideFlag: formData.feeOverrideFlag,
            lastUpdatedOn: formData.lastUpdatedOn,
            lastUpdatedBy: formData.lastUpdatedBy,
            internalComments: formData.internalComments,
        };

        return submitRequest;
    }
}
