import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { SelectBox, NumberBox, TextBox, DateBox, TextArea, LoadIndicator, LoadPanel, Popup, ScrollView } from "devextreme-react";
import LookupService, { LookupTypeIndexes, LookupTypeItem, UnitOfMeasureTypes } from "../../services/LookupService";
import {
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
    DateBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
    NumberBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import AddEditPopUpService, {
    CalculationResponse,
    Status,
    RaiseQueryFormData,
    IdentificationType,
    RoleTypes,
    BillableItemSubmitRequest,
} from "../../services/AddEditPopUpService";
import addEditPopupUtils, {
    AddEditFormData,
    itemTypes,
    BusinessEntityTaxApplicableStatus,
    BusinessEntityTypes,
    virtualBillableItemFormData,
} from "./AddEditPopUpUtilities";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { currencyTypeToSymbolMatrix } from "../grid/GridUtilities";
import outstandingActionsUtilities, {
    minuteLookup,
} from "../../components/Outstanding-Actions/OutstandingActionsUtilities";
import sharedUtils from "./sharedUtilities";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import { SubServiceType, ServiceType } from "../../common/ServiceUtilities";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import billableItemUtils from "../Utility/billableItemUtils";
import { Types } from "../../services/ArtistService";
import FindAnArtistUtils from "../FindAnArtist/FindAnArtistUtils";
import notify from "devextreme/ui/notify";
import moment from "moment";
import GlobalService from "../../services/GlobalService";

//#region props
interface AddEditFormV2Props extends WithTranslation {
    hidePopup: (
        isPopupVisible: boolean
    ) => void;
    location: any;
    history: any;
    billableItemId: string;
}
//#endregion
type NullableDate = Date | null | undefined;
//#region State

// State
interface AddEditFormV2State {
    isPopupVisible: boolean;
    headerId: string;
    businessEntityTaxApplicableStatuses: BusinessEntityTaxApplicableStatus[];
    loadIndicatorVisible: boolean;
    loadPanelVisible: boolean;
    IsShowSuccess: boolean;
    IsShowError: boolean;
    IsLockBillableFields: boolean;
    IsLockTimeFields: boolean;
    IsLockQuantityFields: boolean;
    amendStatus: string;
    createAdjustment: boolean;
    configuarationValue: [];
    isProviderTaxFieldVisible: boolean;
    configurationIdIndex: string[];
    configuarationValueIndex: string[];
    isFeeTaxFieldVisible: boolean;
    isBillClientTaxFieldVisible: boolean;
    errorMessage: [];
    unitOfMeasureList: LookupTypeItem[];
    editViewDataItem: AddEditFormData;
    editViewVirtualDataItem: virtualBillableItemFormData;
    itemTypeLookUp: LookupTypeItem[];
    isTypeChanged: boolean;
    errorsAdjustment: [];
    billableStart: NullableDate;
    billableFinish: NullableDate;
    rate: number;
    quantity: number;
    billableMinutes: number;
    isLockItemTypeLU: boolean;
    itemTypeBeforeAdjustment: string;
    currenyTypeLookUp: LookupTypeItem[];
    isDisableApplyButton: boolean;
    onlyViewMessage: string[];
    acceptDate: NullableDate;
    payDate: NullableDate;
    approvalDate: NullableDate;
    billDate: NullableDate;
    isChangeLabelName: boolean;
    isCalculationMessageShown: boolean;
    calculationMessage: string[];
    isDisableStatusDropDown: boolean;
    isChangeBillableStart: boolean;
    isChangeBillableFinish: boolean;
    isChangeRate: boolean;
    isChangeQuantity: boolean;
    queryItem: RaiseQueryFormData;
    validationMessage: string;
    isHideForContract: boolean;
    isOther: boolean;
    isWeeklyCleaning: boolean;
    typeLUDisablement: boolean;
    isAdditionalCleaning: boolean;
    isLableChange: boolean;
    hours: number;
    minutes: string;
    virtualHours: number;
    virtualMinutes: string;
    prevHours: number;
    prevminutes: string;
    isChangeHour: boolean;
    isChangeMinute: boolean;
    isChangePendingToApprove: boolean;
    isShowForEntertainment: boolean;
    serviceMatrixDataSource: string[];
    loadIndicatorOnPercentageChange: boolean;
    percentageFieldChange: boolean;
    CleaningMinutesToDisplay: string;
    rateChangeTimer: any;
    multiplierChangeTimer: any;
    providerNetChangeTimer: any;
    hourChangeTimer: any;
    isSubTypeChange: boolean;
    clientLookUp: LookupTypeItem[];
    workerLookUp: LookupTypeItem[];
    venueNameLookUp: LookupTypeItem[];
    venueNoLookUp: LookupTypeItem[];
    isClientSelected: boolean;
    venueList: LookupTypeItem[];
    expectedFee: number;
    isClickedOnEdit: boolean;
    calculationMessageClassName: string;
    isLoadFinancialPanel: boolean;
    readOnlyView: boolean;
    providerOriginalAmount: number;
    clientOriginalAmount: number;
    isServiceTypeChange: boolean;
    isProviderAcceptance: boolean;
    isDisableRoleType: boolean;
    isShowOtherDescription: boolean;
    offeringsTypeLookup: LookupTypeItem[];
    typeLookUp: LookupTypeItem[];
    loadPopupVisible: boolean;
    isProviderNetOverride: boolean;
    isMultiplierOverride: boolean;
    providerOverrideValue: number | null | undefined;
    multiplierOverrideValue: number | undefined;
    approvalStartChangeTimer: any;
    approvalEndChangeTimer: any;
    isFasterPayChange: boolean;
    isArtistPayChange: boolean;
    isFasterPayProp: boolean;
    showWarningPopup: boolean;
    disabledApplybutton: boolean;
    //isCopy:boolean;
}
//#endregion

// Component
class AddEditFormV2 extends React.Component<AddEditFormV2Props> {
    sharedUtils: sharedUtils;
    billableItemUtils: billableItemUtils;
    lookupService: LookupService;
    utils: addEditPopupUtils;
    findAnArtistUtils: FindAnArtistUtils;
    state: AddEditFormV2State;
    editViewService: AddEditPopUpService;
    decisionTypesDataSource: LookupTypeItem[];
    nonStandardTypesDataSource: LookupTypeItem[];
    chargeTypesDataSource: LookupTypeItem[];
    absentTypeDataSource: LookupTypeItem[];
    fasterPaymentDataSource: LookupTypeItem[];
    advancedPaymentDataSource: LookupTypeItem[];
    earlyPayDataSource: LookupTypeItem[];
    outStandingActionUtils: outstandingActionsUtilities;
    updateCorrectionDataSource: LookupTypeItem[];
    editAllowed: boolean;
    constructor(props: AddEditFormV2Props) {
        super(props);
        //#region Service Initialization
        this.outStandingActionUtils = new outstandingActionsUtilities();
        this.lookupService = new LookupService();
        this.editViewService = new AddEditPopUpService();
        this.utils = new addEditPopupUtils();
        this.findAnArtistUtils = new FindAnArtistUtils();
        this.sharedUtils = new sharedUtils();
        this.billableItemUtils = new billableItemUtils();
        this.decisionTypesDataSource = this.lookupService.getDecisionTypes();
        this.nonStandardTypesDataSource = this.lookupService.getNonStandardTypes();
        this.chargeTypesDataSource = this.lookupService.getChargeTypes();
        this.absentTypeDataSource = this.lookupService.getAbsentTypes();
        this.fasterPaymentDataSource = this.lookupService.getDecisionTypes();
        this.advancedPaymentDataSource = this.lookupService.getDecisionTypes();
        this.earlyPayDataSource = this.lookupService.getDecisionTypes();
        this.updateCorrectionDataSource = this.lookupService.getUpdateOrCorrection();
        this.editAllowed =  UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ? false : true;
        //#region Set default values to the state object
        // Initialize state
        let itemId: string = "";
        if (props.billableItemId) {
            itemId = props.billableItemId;
        }
        else if (props.location && props.location.state && props.location.state.id) {
            itemId = props.location.state.id.toString();
        }
        let convertedEditViewDataItem = this.utils.initializeBillableItem(
            itemId
        );
        let convertedEditViewVirtualDataItem = this.utils.initializeVirtualBillableItem(
            itemId
        );
        let convertedQueryFormData = this.utils.initializeQueryFormData(
            itemId,
            "",
            "",
            "Shift / Item Status Query",
            ""
        );
        this.state = {
            // fetching headerId from parameter
            isPopupVisible: false,
            loadIndicatorVisible: false,
            businessEntityTaxApplicableStatuses: [],
            loadPanelVisible: false,
            headerId: itemId,
            IsShowSuccess: false,
            IsShowError: false,
            IsLockBillableFields: false,
            IsLockTimeFields: false,
            IsLockQuantityFields: false,
            amendStatus: "",
            configuarationValue: [],
            configuarationValueIndex: [],
            errorMessage: [],
            configurationIdIndex: [],
            createAdjustment: props.location && props.location.state && props.location.state.adjustment ? props.location.state.adjustment : false,
            isProviderTaxFieldVisible:
                convertedEditViewDataItem.provider_Tax_Applicable &&
                    convertedEditViewDataItem.provider_Tax_Applicable == "Yes"
                    ? true
                    : false,
            isFeeTaxFieldVisible:
                convertedEditViewDataItem.fee_Tax_Applicable && convertedEditViewDataItem.fee_Tax_Applicable == "Yes"
                    ? true
                    : false,
            isBillClientTaxFieldVisible:
                convertedEditViewDataItem.client_Tax_Applicable &&
                    convertedEditViewDataItem.client_Tax_Applicable == "Yes"
                    ? true
                    : false,
            unitOfMeasureList: [],
            editViewDataItem: convertedEditViewDataItem,
            editViewVirtualDataItem: convertedEditViewVirtualDataItem,
            itemTypeLookUp: [],
            isTypeChanged: false,
            errorsAdjustment: [],
            billableStart: undefined,
            billableFinish: undefined,
            rate: 0,
            quantity: 0,
            billableMinutes: 0,
            isLockItemTypeLU: false,
            itemTypeBeforeAdjustment: "",
            currenyTypeLookUp: [],
            isDisableApplyButton: false,
            onlyViewMessage: [],
            acceptDate: undefined,
            payDate: undefined,
            approvalDate: undefined,
            billDate: undefined,
            isChangeLabelName: false,
            isCalculationMessageShown: false,
            calculationMessage: [],
            isDisableStatusDropDown: false,
            isChangeBillableStart: false,
            isChangeBillableFinish: false,
            isChangeRate: false,
            isChangeQuantity: false,
            validationMessage: "",
            queryItem: convertedQueryFormData,
            isHideForContract: false,
            isOther: false,
            isWeeklyCleaning: false,
            typeLUDisablement: true,
            isAdditionalCleaning: false,
            isLableChange: false,
            hours: 0,
            minutes: "00",
            virtualHours: 0,
            virtualMinutes: "00",
            prevHours: 0,
            prevminutes: "00",
            isChangeHour: false,
            isChangeMinute: false,
            isChangePendingToApprove: false,
            isShowForEntertainment: false,
            serviceMatrixDataSource: [],
            loadIndicatorOnPercentageChange: false,
            percentageFieldChange: false,
            CleaningMinutesToDisplay: "",
            rateChangeTimer: null,
            hourChangeTimer: null,
            multiplierChangeTimer: null,
            providerNetChangeTimer: null,
            isSubTypeChange: false,
            clientLookUp: [],
            venueNameLookUp: [],
            venueNoLookUp: [],
            isClientSelected: false,
            venueList: [],
            expectedFee: 0,
            isClickedOnEdit: this.props.location && this.props.location.state && this.props.location.state.id ? false : true,
            calculationMessageClassName: "",
            isLoadFinancialPanel: false,
            readOnlyView:
                this.props.location && this.props.location.state && this.props.location.state.isReadOnly
                    ? this.props.location.state.isReadOnly
                    : false,
            providerOriginalAmount: 0,
            clientOriginalAmount: 0,
            isServiceTypeChange: false,
            isProviderAcceptance: false,
            workerLookUp: [],
            isDisableRoleType: true,
            isShowOtherDescription: false,
            offeringsTypeLookup: [],
            typeLookUp: [],
            loadPopupVisible: false,
            isProviderNetOverride: false,
            isMultiplierOverride: false,
            providerOverrideValue: null,
            multiplierOverrideValue: undefined,
            approvalStartChangeTimer: null,
            approvalEndChangeTimer: null,
            isFasterPayChange: false,
            isArtistPayChange: false,
            isFasterPayProp: false,
            showWarningPopup: false,
            disabledApplybutton: false,
            //isCopy:false
        };
        //#endregion
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        if (this.props.billableItemId) {
            this.setState({
                loadPopupVisible: true
            });
        }
        this.editViewDataSource();
    }

    componentDidUpdate(prevProps: Readonly<AddEditFormV2Props>): void {
        if (this.props.billableItemId != prevProps.billableItemId) {
            this.setState({ loadPopupVisible: true });
            this.editViewDataSource();
        }
        if(this.state.editViewDataItem.billableStatusLookupId == "" &&
            this.props.location && this.props.location.state &&
            this.props.location.state.id == 0) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        billableStatusLookupId: "121",
                    },
                })
        }              
    }

    retrieveFormData = () => {
        // Retrieve form data from server if an ID was provided
        let BillableId = localStorage.getItem('BillableIdFromPayment');
        if (this.state.headerId != "" || this.props.billableItemId) {
            let itemId: string = "";
            if (this.props.billableItemId) {
                itemId = this.props.billableItemId;
            }
            else if (this.props.location && this.props.location.state && this.props.location.state.id) {
                itemId = this.props.location.state.id;
            }
            this.editViewService
                .getEditViewData(parseInt(itemId))
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
            this.lookupService
                .getLookupByLookupTypeIndexAll(LookupTypeIndexes.clientType)
                .then(this.handleClientLookupSuccess)
                .catch(this.handleStandardError);
            this.lookupService
                .getLookupByLookupTypeIndexAll(LookupTypeIndexes.venueType)
                .then(this.handleVenueLookupSuccess)
                .catch(this.handleStandardError);
        } 
        else if(BillableId !== "" && BillableId !== null) {
            this.editViewService
                .getEditViewData(parseInt(BillableId))
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
            this.lookupService
                .getLookupByLookupTypeIndexAll(LookupTypeIndexes.clientType)
                .then(this.handleClientLookupSuccess)
                .catch(this.handleStandardError);
            this.lookupService
                .getLookupByLookupTypeIndexAll(LookupTypeIndexes.venueType)
                .then(this.handleVenueLookupSuccess)
                .catch(this.handleStandardError);

            localStorage.setItem("BillableIdFromPayment", "");
        } else if(this.props.location.state?.pointOfNavigation == "copy_simpleshifts" && this.props.location.state?.copiedBillableId) {
            this.editViewService
                .getEditViewData(parseInt(this.props.location.state.copiedBillableId))
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
            this.lookupService
                .getLookupByLookupTypeIndexAll(LookupTypeIndexes.clientType)
                .then(this.handleClientLookupSuccess)
                .catch(this.handleStandardError);
            this.lookupService
                .getLookupByLookupTypeIndexAll(LookupTypeIndexes.venueType)
                .then(this.handleVenueLookupSuccess)
                .catch(this.handleStandardError);
        } else {
            if (this.props.location && this.props.location.state && this.props.location.state.serviceTypeId) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        serviceTypeId: this.props.location.state.serviceTypeId
                    }
                });
            }
            if (this.props.location && this.props.location.state) {
                this.offeringsTypeDataSource(this.props.location.state.serviceTypeId);
            }
            this.setQuantityAutomaticallyBasedOnService();
            //as client id is mandatory for creating payment request so using that to differentiate between normal add and add from payment request
            //|| this.props.location.state.pointOfNavigation == "copy_simpleshifts"
            if (this.props.location && this.props.location.state && (this.props.location.state.pointOfNavigation == "MatchedPopUp")) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        billableStatusLookupId: Status.Pending
                    }
                });
                if (this.props.location.state.clientId) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            clientId: this.props.location.state.clientId
                        }
                    });
                }
                if (this.props.location && this.props.location.state && this.props.location.state.originatorId) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            originator: {
                                id: this.props.location.state.originatorId,
                                value: ""
                            }
                        },
                    });
                }
                if (this.props.location && this.props.location.state && this.props.location.state.serviceSubTypeId) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            serviceSubTypeId: this.props.location.state.serviceSubTypeId
                        }
                    });
                }
                if (this.props.location && this.props.location.state && this.props.location.state.typeLookUpId) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            typeId: this.props.location.state.typeLookUpId
                        }
                    });
                    if(this.props.location && this.props.location.state.typeLookUpValue && this.props.location.state.typeLookUpValue.toLowerCase() == "other"){
                        if (this.props.location && this.props.location.state.otherDescription) {
                            this.setState({
                                editViewDataItem: {
                                    ...this.state.editViewDataItem,
                                    otherDescription: this.props.location.state.otherDescription
                                }
                            });
                        }
                    }
                    else{
                        if (this.props.location && this.props.location.state.internalComments) {
                            this.setState({
                                editViewDataItem: {
                                    ...this.state.editViewDataItem,
                                    otherDescription: this.props.location.state.internalComments
                                }
                            });
                        }
                    }
                }
                if (this.props.location && this.props.location.state.providerId) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            providerId: this.props.location.state.providerId
                        }
                    });
                }
                if (this.props.location && this.props.location.state.venueId) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            venueId: this.props.location.state.venueId
                        }
                    });
                }
                if (this.props.location && this.props.location.state.date) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            date: this.props.location.state.date,
                            //dateTo: this.props.location.state.date
                        }
                    });
                }
                if (this.props.location && this.props.location.state.dateTo) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            dateTo: this.props.location.state.dateTo
                        }
                    });
                } 
                if (this.props.location && this.props.location.state.artistFee && this.props.location.state.serviceTypeId == ServiceType.Entertainment) { 
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            rate_Decimal: this.props.location.state.artistFee,
                            scheduleRate: this.props.location.state.artistFee,
                            equipmentScheduleQuantity: 1
                        }
                    });
                }
                if (this.props.location && this.props.location.state.rate && this.props.location.state.serviceTypeId !== ServiceType.Entertainment) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            rate_Decimal: this.props.location.state.rate,
                            scheduleRate: this.props.location.state.rate,
                        }
                    });
                }
                if (this.props.location && this.props.location.state.providerPayAmountLocalExtras) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            providerPayAmountLocalExtras: this.props.location.state.providerPayAmountLocalExtras
                        }
                    });
                }
                if (this.props.location && this.props.location.state.providerPayAmountLocalExtrasDescription) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            providerPayAmountLocalExtrasDescription: this.props.location.state.providerPayAmountLocalExtrasDescription
                        }
                    });
                }
                if (this.props.location && this.props.location.state.internalComments) {
                    //if(this.props.location.state.pointOfNavigation == "MatchedPopUp"){
                        this.setState({
                            editViewDataItem: {
                                ...this.state.editViewDataItem,
                                shiftCompleteAcknowledgmentDescription: this.props.location.state.internalComments
                            }
                        });
                    // } else {
                    //     this.setState({
                    //         editViewDataItem: {
                    //             ...this.state.editViewDataItem,
                    //             internalComments: this.props.location.state.internalComments
                    //         }
                    //     });
                    // }
                }
                // if (this.props.location.state.pointOfNavigation == "copy_simpleshifts") {
                //     let extComments = this.props.location?.state?.externalComments || '';
                //     let otherDesc = this.props.location?.state?.otherDescription || '';
                //     //isCopy = true;
                //     this.setState({

                //         editViewDataItem: {
                //             ...this.state.editViewDataItem,
                //             otherDescription: otherDesc,
                //             comments: extComments
                //         }
                //     });
                // } 
                if (this.state.editViewDataItem.internalComments == "" && this.props.location.state?.requestType?.id == "10") {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            internalComments: "Added from Payment Request"
                        }
                    });
                }
                // if (this.props.location?.state && this.props.location?.state?.requestType?.id == "10") {
                //     this.setState({
                //         editViewDataItem: {
                //             ...this.state.editViewDataItem,
                //             shiftCompleteAcknowledgment: this.props.location?.state?.shiftCompleteAcknowledgment,
                //             shiftCompleteAcknowledgmentDateTime: this.props.location?.state?.shiftCompleteAcknowledgmentDateTime?this.sharedUtils.convertStringToDate(
                //                 this.props.location?.state?.shiftCompleteAcknowledgmentDateTime):undefined,
                //             shiftCompleteAcknowledgmentTime: this.props.location?.state?.shiftCompleteAcknowledgmentTime ? this.sharedUtils.convertStringToTime(this.props.location?.state?.shiftCompleteAcknowledgmentTime):'',
                //         }
                //     });
                // }
                if (this.state.editViewDataItem.internalComments == "" && this.props.location.state.requestType?.id == "20") {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            internalComments: "Added from Add Job"
                        }
                    });
                }              
                if (this.props.location && this.props.location.state.quantity !== 0) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            equipmentQuantity: this.props.location.state.quantity,
                        }
                    });
                    if (this.props.location.state.serviceSubTypeId == SubServiceType.WeeklyCleaning) {             
                        let quantitySubFields: string[] = this.outStandingActionUtils.splitQuantityIntoHoursMinutesFormat(this.props.location.state.quantity.toString());               
                        this.setState({
                            hours:
                                quantitySubFields.length > 0 && quantitySubFields[0]
                                    ? this.sharedUtils.convertStringToInt(quantitySubFields[0])
                                    : 0,
                            minutes: quantitySubFields.length > 0 && quantitySubFields[1] ? quantitySubFields[1] : "00",
                        })
                    }
                }
                if (this.props.location && this.props.location.state.fasterPayment) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            fasterPayment: this.props.location.state.fasterPayment,
                        },
                        isFasterPayProp: this.props.location.state.fasterPayment == "true" || this.props.location.state.fasterPayment == "Yes" ? true : false
                    });
                }
                if (this.props.location && this.props.location.state.fasterPaymentWithdrawn) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            fasterPaymentWithdrawn: this.props.location.state.fasterPaymentWithdrawn,
                        },                        
                    });
                }
                if (this.props.location && this.props.location.state.providerFasterPayFee) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            providerFasterPayFee: this.props.location.state.providerFasterPayFee
                        }
                    });
                }
                if (this.props.location && this.props.location.state.startTime) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            shift_Start: this.props.location.state.startTime
                        }
                    });
                }
                if (this.props.location && this.props.location.state.finishTime) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            shift_End: this.props.location.state.finishTime
                        }
                    });
                }
                this.handleMultiplierValueSelection();
                this.selectHardApprovalHoldPaymentValue();
                this.setFeeTaxApplicableOnInitialLoad();
                if (this.props.location && this.props.location.state) {
                    this.setTaxApplicableStatus(this.props.location.state.clientId);
                    this.setTaxApplicableStatus(this.props.location.state.providerId);
                }
            }
            if (
                this.props.location && this.props.location.state.id == 0 &&
                this.props.location.state.serviceTypeId == ServiceType.Entertainment && !this.props.location.state.finishTime
            ) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        shift_End: undefined,
                    },
                });
            }
            this.setState({
                isLoadFinancialPanel: true,
                //isCopy:isCopy
            });
            this.lookupService
                .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                .then(this.handleClientLookupSuccess)
                .catch(this.handleStandardError);
            this.lookupService
                .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                .then(this.handleVenueLookupSuccess)
                .catch(this.handleStandardError);
            this.lookupService
                .getLookupByLookupTypeIndex(LookupTypeIndexes.workerstatus)
                .then(this.handleWorkerLookupSuccess)
                .catch(this.handleStandardError);
        }
    };
    editViewDataSource = () => {        
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.configuration)
            .then(this.handleConfigurationLookupSuccess)
            .catch(this.handleStandardError);

        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.itemType)
            .then(this.handleSuccessItemTypeLookUp)
            .catch(this.handleStandardError);

        // Retrieve data required for a new shift
        this.editViewService
            .getTaxApplicableStatus()
            .then(this.handleTaxApplicableSuccess)
            .catch(this.handleStandardError);


        //Retrieve the status list
        if (this.props.location && this.props.location.state && this.props.location.state.isAmend) {
            this.lookupService
                .getLookupByLookupTypeIndex(LookupTypeIndexes.statusType)
                .then(this.handleSuccesStatusLookUp)
                .catch(this.handleStandardError);
        }

        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.unitOfMeasure)
            .then(this.handleUnitOfMeasureLookupSuccess)
            .catch(this.handleStandardError);

        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.currencytype)
            .then(this.handleCurrencyTypeLookUpSuccess)
            .catch(this.handleStandardError);
    };

    offeringsTypeDataSource = (serviceTypeId: string) => {
        //retrieve the role list 
        if (serviceTypeId == ServiceType.Entertainment || serviceTypeId == ServiceType.ContractCleaning 
            || serviceTypeId == ServiceType.Gardening || serviceTypeId == ServiceType.WindowCleaning) {
            this.lookupService
                .getLookupByLookupTypeIndex(LookupTypeIndexes.offeringsType)
                .then(this.handleSuccesOfferingsLookUp)
                .catch(this.handleStandardError);
        }
    }

    handleSuccesOfferingsLookUp = (response: AxiosResponse<any>) => {
        let typeDropDown = this.findAnArtistUtils.lookUpCreation(response);
        this.setState({
            typeLookUp: typeDropDown,
        }, () => { this.filterDataSourceForRole(typeDropDown, this.state.editViewDataItem.serviceSubTypeId); });
        if (this.props.location && this.props.location.state && this.props.location.state.pointOfNavigation == "MatchedPopUp") {
            this.filterDataSourceForRole(typeDropDown, this.props.location.state.serviceSubTypeId);
            this.setRoleTypeOrShowOtherDesc(this.props.location.state.serviceSubTypeId);
        }
    }

    handleClientLookupSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            clientLookUp: response.data.data,
        });
    };

    handleWorkerLookupSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            workerLookUp: response.data.data,
        });
    }

    handleVenueLookupSuccess = (response: AxiosResponse<any>) => {
        var venueNameList = this.utils.filteredVenueNameValues(response.data.data);
        var venueNoList = this.utils.filteredVenueNoValues(response.data.data);
        this.setState({
            venueNameLookUp: venueNameList,
            venueNoLookUp: venueNoList,
            venueList: response.data.data,
        });
    };

    handleConfigurationLookupSuccess = (response: AxiosResponse<any>) => {
        var configData = this.sharedUtils.getConfigurationDataFromLookupResponse(response.data.data);
        this.setState({
            configuarationValue: response.data.data,
            configurationIdIndex: configData.id,
            configuarationValueIndex: configData.value,
        });
    };

    handleStandardError = (error: any) => {
        var respMessage: string = "Add/Edit shift form load failed with response: " + JSON.stringify(error);

        if (!this.editViewService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    handleMultiplierValueSelection = () => {
        var serviceFeeMatrixDerivedValues = this.billableItemUtils.serviceFeeMatrixDerivation(this.state.editViewDataItem.clientId, this.state.editViewDataItem.serviceTypeId, this.state.editViewDataItem.serviceSubTypeId);
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Percentage_Local_Ccy: this.sharedUtils.convertStringToFloat(serviceFeeMatrixDerivedValues.vaueOfMultiplier),
                isAccrual: serviceFeeMatrixDerivedValues.valueOfAccrual,
            },
            isProviderNetOverride: false,
            isMultiplierOverride: false,
            providerOverrideValue: null,
            multiplierOverrideValue: null,
            percentageFieldChange: false,
            isProviderAcceptance: serviceFeeMatrixDerivedValues.valueOfProviderAcceptance
        });
        if (
            (this.state.editViewDataItem.rate_Decimal && this.state.editViewDataItem.equipmentQuantity) ||
            (this.state.editViewDataItem.billable_Start &&
                this.state.editViewDataItem.billable_Finish &&
                this.state.editViewDataItem.rate_Decimal)
        ) {
            this.financialCalculations();
        } else {
            this.setFinanceFieldsToNull();
        }
    };

    itemTypeSelectionBasedOnProviderAmount = () => {
        if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == -1) {
            if (
                this.state.editViewDataItem.billableStatusLookupId == Status.Pending ||
                this.state.editViewDataItem.billableStatusLookupId == Status.PendingAcceptance
            ) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        itemTypeId: this.state.itemTypeLookUp[4].id,
                        billableStatusLookupId: Status.PendingAcceptance,
                    },
                });
            }
        }
        if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == 1) {
            if (
                this.state.editViewDataItem.billableStatusLookupId == Status.Pending ||
                this.state.editViewDataItem.billableStatusLookupId == Status.PendingAcceptance
            ) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        itemTypeId: this.state.itemTypeLookUp[3].id,
                        billableStatusLookupId: Status.Pending,
                    },
                });
            }
        }
    };

    selectHardApprovalHoldPaymentValue = () => {
        // if (this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment) {
        //     this.setState({
        //         editViewDataItem: {
        //             ...this.state.editViewDataItem,
        //             hardApproval: "No",
        //             holdPayment: "Yes"
        //         },
        //     });            
        // }
        // else 
        if (this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning || this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment ||
            this.state.editViewDataItem.serviceTypeId == ServiceType.Miscelleanous || this.state.editViewDataItem.serviceTypeId == ServiceType.Maintenance || 
            this.state.editViewDataItem.serviceTypeId == ServiceType.WindowCleaning || this.state.editViewDataItem.serviceTypeId == ServiceType.Gardening) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    hardApproval: "No",
                    holdPayment: "Yes"
                },
            });
        }
        else if (this.state.editViewDataItem.serviceTypeId == ServiceType.Security) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    hardApproval: "No",
                    holdPayment: "No"
                },
            });
        }
    }

    handleItemTypeSelection = () => {
        if (this.props.location && this.props.location.state && this.props.location.state.id) {
            if (
                this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[1].id ||
                this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[3].id ||
                this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[4].id
            ) {
                this.itemTypeSelectionBasedOnProviderAmount();
            }
        }
    };

    setTaxApplicableStatus = (businessEntityId: string) => {
        var taxStatus = this.state.businessEntityTaxApplicableStatuses.find(
            (item) => item.businessEntityID === businessEntityId
        );
        var taxApplicable: string = "No";

        // A client/provider is tax applicable if they have a tax registration number
        if (taxStatus && taxStatus.taxApplicable) {
            taxApplicable = "Yes";
        }

        if (taxStatus) {
            // Based on entity type, populate the tax applicable dropdown
            switch (taxStatus.businessEntityType) {
                case BusinessEntityTypes.Client:
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            client_Tax_Applicable: taxApplicable,
                        },
                    });
                    break;
                case BusinessEntityTypes.Provider:
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            provider_Tax_Applicable: taxApplicable,
                        },
                    });
                    break;
                // case BusinessEntityTypes.Company:
                //     this.setState({
                //         editViewDataItem: {
                //             ...this.state.editViewDataItem,
                //             fee_Tax_Applicable: taxApplicable,
                //         },
                //     });
                //     break;
            }
        }
        this.providerPayTaxCalculation();
        this.feeTaxCalculation();
        this.clientTaxCalculation();
    };

    /**
     * Handle the success response for retrieving Tax Applicable statuses
     *
     * @param response {AxiosResponse<any>} - server response
     */
    handleTaxApplicableSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                businessEntityTaxApplicableStatuses: response.data.data,
            }
        );
        this.setFeeTaxApplicableOnInitialLoad();
        this.retrieveFormData();
    };

    setFeeTaxApplicableOnInitialLoad = () => {
        var taxApplicable: string = "No";
        var statuses: BusinessEntityTaxApplicableStatus[] = this.state.businessEntityTaxApplicableStatuses;
        // Set the Fee tax applicable field immediately
        var businessEntityItem = statuses.find(
            (item) => item.businessEntityType === BusinessEntityTypes.Company
        );
        if (businessEntityItem && businessEntityItem.taxApplicable) {
            taxApplicable = "Yes";
        }
        if (businessEntityItem) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Tax_Applicable: taxApplicable,
                },
            });
        }
    }

    setBillableMinAndScheduleMin = () => {
        if (
            this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment ||
            this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning ||
            this.state.editViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning
        ) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    shift_Start: "",
                    shift_End: "",
                    billable_Start: "",
                    billable_Finish: "",
                    check_In: "",
                    check_Out: "",
                    billable_Minutes: 0,
                },
            });
        } else if (this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    equipmentQuantity: 0,
                    equipmentBillableQuantity: null,
                    billable_Start: this.sharedUtils.convertStringToTime("00:00"),
                    billable_Finish: this.sharedUtils.convertStringToTime("00:00"),
                },
            });
        }
    };
    handleCurrencyTypeLookUpSuccess = (response: AxiosResponse<any>) => {
        //currenyTypeLUItem
        var currenyLUItem: LookupTypeItem;
        var currenyLUItemArray: LookupTypeItem[];
        currenyLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            currenyLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            currenyLUItemArray.push(currenyLUItem);
        });
        this.setState({
            currenyTypeLookUp: currenyLUItemArray,
        });
    };
    handleSuccessItemTypeLookUp
        = (response: AxiosResponse<any>) => {
            var itemLUItem: LookupTypeItem;
            var itemLUItemArray: LookupTypeItem[];
            itemLUItemArray = [];
            response.data.data.map((item: any, uniqueKey: number) => {
                itemLUItem = {
                    id: item.id,
                    value: item.value,
                    parentMappingId: "0",
                };
                itemLUItemArray.push(itemLUItem);
            });
            this.setState({
                itemTypeLookUp: itemLUItemArray,
            });
        };

    handleSuccesStatusLookUp = (response: AxiosResponse<any>) => {
        var statusId = this.sharedUtils.getStatusLookupDataResponse(response.data.data);
        this.setState({
            amendStatus: statusId,
        });
    };

    handleUnitOfMeasureLookupSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            unitOfMeasureList: response.data.data,
        });

        this.initializeFieldsBasedOnUnitOfMeasure(
            this.state.editViewDataItem.serviceSubTypeId,
            this.state.unitOfMeasureList
        );
    };

    // change for PBI-18255 start
    setUpdateOrCorrectionConditionally = (isAccrual:boolean, invoiceId:string, paymentId:string) => {    
        if(isAccrual == true && invoiceId !== "" && paymentId == ""){
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    updateOrCorrection: "true",
                },
            })
        }         
    }
    // change for PBI-18255 end

    // Manage successful data source response
    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        let isPopup: boolean = false;
        if (this.props.billableItemId) {
            isPopup = true;
        }
        // Convert to proper data types
        var viewDataSource = this.utils.convertBillableItemResponeToFormData(response.data.data, isPopup);
        var viewVirtualDataSource = this.utils.convertBillableItemResponeToVirtualFormData(response.data.data);
        let isCopy =  this.props.location?.state?.pointOfNavigation == "copy_simpleshifts"? true : false;
        if(isCopy){
            viewDataSource = this.utils.copyBillableItem(response.data.data);
        } 
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                venueId: response.data.data.venueId ? response.data.data.venueId : "",
            },
        });
        // In case of Amend status will be pre set to "Approve"
        if (this.props.location && this.props.location.state && this.props.location.state.isAmend) {
            this.setState({
                editViewDataItem: viewDataSource,
            }, () => { this.setUpdateOrCorrectionConditionally(this.state.editViewDataItem.isAccrual, this.state.editViewDataItem.invoiceId, this.state.editViewDataItem.paymentId) });
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    billableStatusLookupId: this.state.amendStatus,
                },
            });
            if (this.state.editViewDataItem.absent.toUpperCase() == "YES") {
                this.setState({
                    IsLockBillableFields: true,
                });
            }
        } else {
            // Set form data onto state
            this.setState({
                editViewDataItem: viewDataSource,
                editViewVirtualDataItem: viewVirtualDataSource,
                billableStart: viewDataSource.billable_Start,
                billableFinish: viewDataSource.billable_Finish,
                rate: viewDataSource.rate_Decimal,
                quantity: viewDataSource.equipmentQuantity,
                billableMinutes: viewDataSource.billable_Minutes,
                acceptDate: viewDataSource.accept_Date,
                approvalDate: viewDataSource.approval_Date,
                billDate: viewDataSource.bill_Date,
                payDate: viewDataSource.pay_Date,
                CleaningMinutesToDisplay: response.data.data.equipmentQuantity,
                //CleaningMinutesToDisplay: viewDataSource.equipmentQuantity,
                expectedFee: viewDataSource.expectedFee,
                providerOriginalAmount: viewDataSource.provider_Pay_Amount_Local_Ccy,
                clientOriginalAmount: viewDataSource.client_Billable_Amount_Local_Ccy,
            }, () => { this.setUpdateOrCorrectionConditionally(this.state.editViewDataItem.isAccrual, this.state.editViewDataItem.invoiceId, this.state.editViewDataItem.paymentId) });
            this.offeringsTypeDataSource(viewDataSource.serviceTypeId);
            this.setState({ isLoadFinancialPanel: true, loadPopupVisible: false });
            this.setState({
                itemTypeBeforeAdjustment: this.state.editViewDataItem.itemTypeId,
            });
            if (this.state.editViewDataItem.contractHours) {
                this.setState({
                    isWeeklyCleaning: true,
                });
            }
            if (this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning) {
                var quantitySubFields: string[] = this.outStandingActionUtils.splitQuantityIntoHoursMinutesFormat(
                    this.state.CleaningMinutesToDisplay
                );
                var originalQuantitySubFields: string[] = this.outStandingActionUtils.splitQuantityIntoHoursMinutesFormat(
                    this.state.CleaningMinutesToDisplay
                );

                this.setState({
                    isLableChange: true,
                    hours:
                        quantitySubFields.length > 0 && quantitySubFields[0]
                            ? this.sharedUtils.convertStringToInt(quantitySubFields[0])
                            : 0,
                    minutes: quantitySubFields.length > 0 && quantitySubFields[1] ? quantitySubFields[1] : "00",
                    prevHours:
                        originalQuantitySubFields.length > 0 && originalQuantitySubFields[0]
                            ? this.sharedUtils.convertStringToInt(originalQuantitySubFields[0])
                            : 0,
                    prevminutes:
                        originalQuantitySubFields.length > 0 && originalQuantitySubFields[1]
                            ? originalQuantitySubFields[1]
                            : "00",
                    virtualHours:
                        quantitySubFields.length > 0 && quantitySubFields[0]
                            ? this.sharedUtils.convertStringToInt(quantitySubFields[0])
                            : 0,
                    virtualMinutes: quantitySubFields.length > 0 && quantitySubFields[1] ? quantitySubFields[1] : "00",
                });
            }
            if (this.state.editViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning) {
                this.setState({
                    isOther: true,
                });
            }
            if (this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning) {
                this.setState({
                    isHideForContract: true,
                });
            }
            if (this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment) {
                this.setState({
                    isShowForEntertainment: true,
                });
            }
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    provider_Tax_Applicable:
                        this.state.editViewDataItem.provider_Tax_Applicable.toUpperCase() == "TRUE" ? "Yes" : "No",
                    fee_Tax_Applicable:
                        this.state.editViewDataItem.fee_Tax_Applicable.toUpperCase() == "TRUE" ? "Yes" : "No",
                    client_Tax_Applicable:
                        this.state.editViewDataItem.client_Tax_Applicable.toUpperCase() == "TRUE" ? "Yes" : "No",
                },
            });
            if (this.props.location && this.props.location.state && this.props.location.state.isAdjust) {
                if (
                    UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                    UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
                    UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ||
                    UserService.isUserInGroup(RoleGroupNames.VenueManager)
                ) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            provider_Pay_Original_Amount_Local_Ccy: this.state.providerOriginalAmount,
                            client_Billable_Original_Amount_Local_Ccy: this.state.clientOriginalAmount,
                        },
                    });
                    if (
                        this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentClientDebit ||
                        this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentProviderDebit
                    ) {
                        this.setState({
                            editViewDataItem: {
                                ...this.state.editViewDataItem,
                                itemTypeId: viewDataSource.itemTypeId,
                            },
                        });
                    } else if (this.state.editViewDataItem.isAccrual) {
                        this.setState({
                            editViewDataItem: {
                                ...this.state.editViewDataItem,
                                itemTypeId: itemTypes.AdjustmentProviderDebit,
                            },
                        });
                    } else {
                        this.setState({
                            editViewDataItem: {
                                ...this.state.editViewDataItem,
                                itemTypeId: this.state.itemTypeLookUp[1].id,
                                billableStatusLookupId: Status.Pending
                            },
                        });
                    }
                    this.setState({
                        isChangeLabelName: true,
                    });
                    if (!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
                        this.setState({
                            isDisableStatusDropDown: true,
                        });
                    }
                    this.setOriginalFieldsOnPageLoadOnly();
                }
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        provider_Pay_Amount_Local_Ccy: 0.0,
                        provider_Pay_Tax_Local_Ccy: 0.0,
                        provider_Pay_Total_Local_Ccy: 0.0,
                        fee_Amount_Local_Ccy: 0.0,
                        fee_Tax_Local_Ccy: 0.0,
                        fee_Total_Local_Ccy: 0.0,
                        client_Billable_Amount_Local_Ccy: 0.0,
                        client_Billable_Tax_Local_Ccy: 0.0,
                        client_Billable_Total_Local_Ccy: 0.0,
                    },
                    expectedFee: 0.0
                });
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        accept_Date: undefined,
                        pay_Date: undefined,
                        bill_Date: undefined,
                        approval_Date: undefined,
                    },
                });
            } else {
                if (this.props.location && this.props.location.state && this.props.location.state.isAmend == undefined || this.props.location && this.props.location.state.isAmend == this.props.location && this.props.location.state && this.props.location.state.isAmend == undefined || this.props.location && this.props.location.state && this.props.location.state.isAmend == false) {
                    if (
                        UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                        UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ||
                        UserService.isUserInGroup(RoleGroupNames.VenueManager)
                    ) {
                        if (
                            this.state.editViewDataItem.childRecordId != null &&
                            this.state.editViewDataItem.childRecordId != ""
                        ) {
                            this.setState({
                                isDisableApplyButton: true,
                            });
                            this.state.onlyViewMessage.push(
                                this.props.t("addEditShiftRow.Message.createAdjustment.editMessage")
                            );
                        }
                    }
                }
            }
        }
        if(viewDataSource.billableItemId == ""){
            this.initializeFieldsBasedOnUnitOfMeasure(
                this.state.editViewDataItem.serviceSubTypeId,
                this.state.unitOfMeasureList
            );
            if(isCopy){
                //this.handleMultiplierValueSelection();
                this.financialCalculations();
                //this.selectHardApprovalHoldPaymentValue();
                //this.setFeeTaxApplicableOnInitialLoad();
                // this.setTaxApplicableStatus(viewDataSource.clientId);
                // this.setTaxApplicableStatus(viewDataSource.providerId);
            }
        }  
    };

    filterDataSourceForRole = (data: LookupTypeItem[], filterId: string) => {
        let mappingId: number = this.sharedUtils.convertStringToInt(filterId);
        let roleTypeItems = mappingId > 0
            ? data.filter((item: LookupTypeItem) => {
                return item.parentMappingId === filterId;
            })
            : data;

        this.setState({
            offeringsTypeLookup: roleTypeItems
        });

    };

    setOriginalFieldsOnPageLoadOnly = () => {
        if (
            this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[1].id ||
            this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[3].id ||
            this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[4].id
        ) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    originalBillableStartTime: this.state.billableStart
                        ? this.state.billableStart
                        : this.state.editViewDataItem.billable_Start,
                    originalBillableFinishTime: this.state.billableFinish
                        ? this.state.billableFinish
                        : this.state.editViewDataItem.billable_Finish,
                    originalBillableMinutes: this.state.billableMinutes
                        ? this.state.billableMinutes
                        : this.state.editViewDataItem.billable_Minutes,
                },
            });
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    originalRate: this.state.rate,
                    equipmentOriginalQuantity: this.state.quantity,
                },
            });

            if (this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning) {
                var prevQuantitySubFields: string[] = this.outStandingActionUtils.splitQuantityIntoHoursMinutesFormat(
                    this.state.editViewDataItem.equipmentOriginalQuantity.toString()
                );
                this.setState({
                    prevHours:
                        prevQuantitySubFields.length > 0 && prevQuantitySubFields[0]
                            ? this.sharedUtils.convertStringToInt(prevQuantitySubFields[0])
                            : 0,
                    prevminutes:
                        prevQuantitySubFields.length > 0 && prevQuantitySubFields[1] ? prevQuantitySubFields[1] : "00",
                });
            }
        }
    };

    //#endregion
    //#region 'handleChangeOnClickEvent
    // Series of callback functions that would set the state of the respective ID's when a selection is made inside the Dropdown.
    handleChangeClientTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    clientId: dxValueChange.value,
                    venueId:''
                },
                isClientSelected: true,
            });
            this.handleMultiplierValueSelection();
            // If this is a new shift, set the tax applicable status
            this.setTaxApplicableStatus(dxValueChange.value);
            this.setFeeTaxApplicableOnInitialLoad();
        }
    };

    handleChangeOriginator = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    originator: {
                        id: dxValueChange.value,
                        value: ""
                    }
                },
            });
        }
    }
    handleChangeCorrectionUpdate = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    updateOrCorrection: dxValueChange.value,
                },
            });
        }
    };
    handleChangeFasterPaymentTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.editViewDataItem.fasterPayment !== dxValueChange.value) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fasterPayment: dxValueChange.value,
                },
            });

            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        }
    };

    handleChangeFasterPaymentWithdrawnTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.editViewDataItem.fasterPaymentWithdrawn !== dxValueChange.value) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fasterPaymentWithdrawn: dxValueChange.value,
                },
            });
        }
    };

    handleChangeAdvancedPaymentTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    advancedpayment: dxValueChange.value,
                },
            });
        }
    };

    handleChangeEarlyPayTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    earlyPay: dxValueChange.value,
                },
            });
        }
    };

    handleChangeHours = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                hours: dxValueChange.value,
                isChangeHour: true,
                isProviderNetOverride: false,
                isMultiplierOverride: false,
                providerOverrideValue: null,
                multiplierOverrideValue: null
            });
            this.state.hourChangeTimer = setTimeout(this.reCalculateQuantity, 1000);
        }
    };

    handleChangeMinutes = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                minutes: dxValueChange.value,
                isChangeMinute: true,
                isProviderNetOverride: false,
                isMultiplierOverride: false,
                providerOverrideValue: null,
                multiplierOverrideValue: null
            });
            this.state.hourChangeTimer = setTimeout(this.reCalculateQuantity, 1000);
        }
    };

    //Helper function to recalulate the Quantity when either of the minute or Hour field varies.
    reCalculateQuantity = () => {
        var delimeter = ".";
        var { hours, minutes } = this.state; //Destructure the state to pull out the contents.
        var convertedHours: string = this.sharedUtils.convertNumberToString(hours);
        var quantity: string = `${convertedHours}${delimeter}${minutes}`;
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                equipmentQuantity: this.sharedUtils.convertStringToFloat(quantity),
            },
        });
        if (this.props.location && this.props.location.state && this.props.location.state.id == 0) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    equipmentScheduleQuantity: this.state.editViewDataItem.equipmentQuantity,
                },
            });
        }
        this.financialCalculations();
    };

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    venueId: dxValueChange.value,
                },
            });
            this.setState({
                queryItem: {
                    ...this.state.queryItem,
                    venueId: dxValueChange.value,
                },
            });
        }
    };

    handleChangeStatusTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    billableStatusLookupId: dxValueChange.value,
                },
            });
            if (dxValueChange.previousValue == Status.Pending && dxValueChange.value == Status.Approved) {
                this.setState({
                    isChangePendingToApprove: true,
                });
            }
            if (dxValueChange.value == Status.Pending && this.state.editViewDataItem.approval_Date) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        approval_Date: ""
                    }
                });
            }
        }
    };

    handleChangeAccrualStatusTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        accrualStatusId: dxValueChange.value,
                    },
                },
            });
        }
    };

    handleChangeArtistConfirmedDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    shiftCompleteAcknowledgmentDateTime: dxValueChange.value,
                },
            });
        }
    };

    dateToChangeLogic = () => {
        if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                this.state.editViewDataItem.serviceSubTypeId,
                UnitOfMeasureTypes.time,
                this.state.unitOfMeasureList
            )
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.time);
        } else if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                this.state.editViewDataItem.serviceSubTypeId,
                UnitOfMeasureTypes.quantity,
                this.state.unitOfMeasureList
            )
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        } else if (
            this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning ||
            this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        }
    };

    handleChangeDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    date: dxValueChange.value,
                },
                providerOverrideValue: null
            });
            this.dateToChangeLogic();
            if (
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel &&
                this.state.editViewDataItem.billable_Start &&
                this.state.editViewDataItem.billable_Finish &&
                this.state.editViewDataItem.rate_Decimal
            ) {
                this.financialCalculations();
            }
        }
    };

    handleChangeDateTo = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    dateTo: dxValueChange.value,
                },
            });
        }
    };

    handleChangeProviderTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerId: dxValueChange.value,
                },
            });
            this.setTaxApplicableStatus(dxValueChange.value);
            this.setFeeTaxApplicableOnInitialLoad();
        }
    };

    handleChangeWorkerStatus = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    workerStatusId: dxValueChange.value,
                },
            });
        }
    }

    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    serviceTypeId: dxValueChange.value,
                    serviceSubTypeId: "",
                    rate_Decimal: 0
                },
            }, () => { this.offeringsTypeDataSource(dxValueChange.value) });
            this.setState({
                typeLUDisablement: true,
                isServiceTypeChange: true
            });
            if (dxValueChange.value == ServiceType.Entertainment) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        equipmentScheduleQuantity: 1,
                        equipmentQuantity: 1
                    }
                });
            }
            if (this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning) {
                this.setState({
                    isHideForContract: true,
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        billable_Start: "",
                        billable_Finish: "",
                        equipmentQuantity: 1,
                        typeId: "",
                    },
                });
                this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
            } else {
                this.setState({
                    isHideForContract: false,
                    isOther: false,
                    isLableChange: false,
                });
            }
            this.setQuantityAutomaticallyBasedOnService();
            this.handleMultiplierValueSelection();
            this.selectHardApprovalHoldPaymentValue();
        }
    };

    setQuantityAutomaticallyBasedOnService = () => {
        if (this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment ||
            this.state.editViewDataItem.serviceTypeId == ServiceType.Gardening ||
            this.state.editViewDataItem.serviceTypeId == ServiceType.WindowCleaning ||
            this.state.editViewDataItem.serviceTypeId == ServiceType.Maintenance ||
            this.state.editViewDataItem.serviceTypeId == ServiceType.Miscelleanous) {
            this.setState({
                isShowForEntertainment: true,
                isHideForContract: false,
                isOther: false,
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    equipmentQuantity: 1,
                    billable_Start: "",
                    billable_Finish: "",
                    shift_End: undefined,
                },
            },()=>{
                this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
            });
        } else {
            this.setState({
                isShowForEntertainment: false,
            });
        }
    }
    handleChangeServiceSubTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    serviceSubTypeId: dxValueChange.value,
                    rate_Decimal: this.state.editViewDataItem.serviceTypeId == "10" ? 0 : this.state.editViewDataItem.rate_Decimal,
                    typeId: "",
                    otherDescription: ""

                },
            }, () => {
                if(dxValueChange.value == SubServiceType.WeeklyCleaning){
                    let quantitySubFields: string[] = this.outStandingActionUtils.splitQuantityIntoHoursMinutesFormat(this.state.editViewDataItem.equipmentQuantity.toString());               
                    this.setState({
                        hours:
                            quantitySubFields.length > 0 && quantitySubFields[0]
                                ? this.sharedUtils.convertStringToInt(quantitySubFields[0])
                                : 0,
                        minutes: quantitySubFields.length > 0 && quantitySubFields[1] ? quantitySubFields[1] : "00",
                    })
                }
                this.state.editViewDataItem.serviceTypeId == "10" && this.state.editViewDataItem.billableItemId !== "" ? notify({ message: "Amounts have been removed and need to be re-entered", width: 400, shading: true }, 'error', 600) : null;
                this.filterDataSourceForRole(this.state.typeLookUp, dxValueChange.value);
                this.setRoleTypeOrShowOtherDesc(dxValueChange.value);
            });

            this.setBillableMinAndScheduleMin();
            if (this.state.editViewDataItem.serviceSubTypeId) {
                if (this.state.editViewDataItem.serviceSubTypeId != SubServiceType.WeeklyCleaning) {
                    this.setState({
                        typeLUDisablement: false,
                        isLableChange: false,
                        isWeeklyCleaning: false,
                    });
                } else {
                    this.setState({
                        typeLUDisablement: true,
                        isLableChange: true,
                    });
                }
                if (this.state.editViewDataItem.serviceSubTypeId != SubServiceType.DoorSupervisionPersonnel) {
                    this.setState({
                        isSubTypeChange: true,
                    });
                }
            }

            this.handleMultiplierValueSelection();
            this.financeRecalculationOnPercentageChange();
            if (
                this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                    dxValueChange.value,
                    UnitOfMeasureTypes.time,
                    this.state.unitOfMeasureList
                )
            ) {
                this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.time);
            } else if (
                this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                    dxValueChange.value,
                    UnitOfMeasureTypes.quantity,
                    this.state.unitOfMeasureList
                )
            ) {
                this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
            }
            if (this.state.editViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning) {
                if (!this.state.editViewDataItem.equipmentQuantity) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            equipmentQuantity: 1,
                            equipmentScheduleQuantity: 1,
                        },
                    });
                }
            }
            if (this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning) {
                this.setState({
                    isOther: false,
                });
            } else if (
                this.state.editViewDataItem.typeId == RoleTypes.Other &&
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning
            ) {
                this.setState({
                    isOther: true,
                });
            }
            this.setQuantityAutomaticallyBasedOnService();
            this.selectHardApprovalHoldPaymentValue();
        }
    };

    setRoleTypeOrShowOtherDesc = (subTypeId: string) => {
        if (subTypeId == Types.Other) {
            this.setState({
                isShowOtherDescription: true
            });
        }
        else {
            this.setState({
                isShowOtherDescription: false
            });
        }
        //if()
    }

    initializeFieldsBasedOnUnitOfMeasure = (subTypeId: string, unitOfMeasureList: LookupTypeItem[]) => {
        // Disabling fields dependent on: 1) the form data server request 2) the unit of measure list
        // Both success promises (this is one) will need to set execute the disable as it cannot be known who's promise will complete first
        if (subTypeId && subTypeId.length > 0) {
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                subTypeId,
                UnitOfMeasureTypes.time,
                unitOfMeasureList
            )
                ? this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.time)
                : this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        }
    };

    /**
     * Set disabled attribute based on selected unit of measure (sub type).
     * Also reset the Date To field
     *  If Time      - Date To = Date From
     *  If Equipment - Date To = Date From + 6 days
     *
     * @param unitOfMeasureType {string} - the unit of measure
     * @returns {void}
     */
    disableFieldsBasedOnUnitOfMeasure = (unitOfMeasureType: string) => {
        var dateTo = this.utils.setDateToField(
            unitOfMeasureType,
            this.state.editViewDataItem.date,
            this.state.editViewDataItem.serviceTypeId,
            this.state.editViewDataItem.serviceSubTypeId
        );
        var isLockTimeFields = this.utils.lockTimeFields(unitOfMeasureType);
        var isLockQuantityFields = this.utils.lockQuantityFields(unitOfMeasureType);
        this.setState({
            IsLockTimeFields: isLockTimeFields,
            IsLockQuantityFields: isLockQuantityFields,
            editViewDataItem: {
                ...this.state.editViewDataItem,
                dateTo: dateTo,
            },
        });
    };

    clientAmountSetOnPercentageChange = () => {
        if (
            this.state.editViewDataItem.client_Tax_Applicable.toUpperCase() == "YES" ||
            this.state.editViewDataItem.client_Tax_Applicable.toUpperCase() == "TRUE"
        ) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy:
                        this.state.editViewDataItem.provider_Pay_Tax_Local_Ccy +
                        this.state.editViewDataItem.fee_Tax_Local_Ccy,
                },
            });
        } else if (
            this.state.editViewDataItem.client_Tax_Applicable.toUpperCase() == "NO" ||
            this.state.editViewDataItem.client_Tax_Applicable.toUpperCase() == "FALSE"
        ) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy: 0.0,
                },
            });
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Amount_Local_Ccy:
                    this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy +
                    this.state.editViewDataItem.fee_Amount_Local_Ccy,
            },
        });
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Total_Local_Ccy:
                    this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                    this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
            },
        });
    };

    financeRecalculationOnPercentageChange = () => {
        var feeAmount: number[] = [];
        var feeTaxLocalCcy: number = 0;
        var feeTotalLocalCcy: number = 0;
        if (
            (this.state.editViewDataItem.billable_Start &&
                this.state.editViewDataItem.billable_Finish &&
                this.state.editViewDataItem.rate_Decimal) ||
            ((this.state.editViewDataItem.equipmentQuantity || this.state.editViewDataItem.equipmentBillableQuantity) && this.state.editViewDataItem.rate_Decimal)
        ) {
            this.financialCalculations();
        }
        feeAmount = this.utils.feeTaxCal(this.state.editViewDataItem, this.state.configuarationValueIndex);
        if (feeAmount.length > 0) {
            feeTaxLocalCcy = feeAmount[0];
            feeTotalLocalCcy = feeAmount[1];
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Tax_Local_Ccy: feeTaxLocalCcy,
            },
        });
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Total_Local_Ccy: feeTotalLocalCcy,
            },
        });
        this.clientAmountSetOnPercentageChange();
    };

    handleChangeOfferingsTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    typeId: dxValueChange.value,
                    otherDescription: ""
                },
            });
            if (this.state.editViewDataItem.typeId == RoleTypes.Other) {
                this.setState({
                    isOther: true,
                });
            }
        }
    };

    handleChangeName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    name: dxValueChange.value,
                },
            });
        }
    };

    handleChangeIdentificationNumber = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    identification_Number: dxValueChange.value,
                },
            });
        }
    };

    handleChangeIdentificationTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    identificationTypeId: dxValueChange.value,
                },
            });
        }
    };

    handleChangeShiftStart = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    shift_Start: dxValueChange.value,
                },
            });
            if (this.state.editViewDataItem.serviceTypeId != ServiceType.Entertainment) {
                this.onChangeAbsentNoChargeSetBillableMinutes();
            }
        }
    };

    handleChangeShiftEnd = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    shift_End: dxValueChange.value,
                },
            });
            if (this.state.editViewDataItem.serviceTypeId != ServiceType.Entertainment) {
                this.onChangeAbsentNoChargeSetBillableMinutes();
            }
        }
    };
    handleChangeArtistConfirmedTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    shiftCompleteAcknowledgmentTime: dxValueChange.value,
                },
            });
        }
    };

    //sangeeta
    handleChangeBillableStart = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    billable_Start: dxValueChange.value,
                },
                isProviderNetOverride: false,
                isMultiplierOverride: false,
                providerOverrideValue: null,
                multiplierOverrideValue: null
            });
            this.setState({
                isChangeBillableStart: true,
            });
            if (this.state.editViewDataItem.equipmentQuantity) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        equipmentQuantity: 0,
                    },
                });
            }
            if (
                this.state.editViewDataItem.billable_Finish &&
                this.state.editViewDataItem.billable_Start &&
                this.state.editViewDataItem.rate_Decimal
            ) {
                this.state.approvalStartChangeTimer = setTimeout(this.startCallingfinancialOnType, 1000);
            }
        }
    };

    startCallingfinancialOnType = () => {
        if (this.state.approvalStartChangeTimer || this.state.approvalEndChangeTimer) {
            clearTimeout(this.state.approvalStartChangeTimer);
            clearTimeout(this.state.approvalEndChangeTimer);
            this.state.approvalStartChangeTimer = null;
            this.state.approvalEndChangeTimer = null;
            this.financialCalculations();
        }
    };

    handleChangeBillableFinish = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    billable_Finish: dxValueChange.value,
                },
                isProviderNetOverride: false,
                isMultiplierOverride: false,
                providerOverrideValue: null,
                multiplierOverrideValue: null
            });
            this.setState({
                isChangeBillableFinish: true,
            });
            if (this.state.editViewDataItem.equipmentQuantity) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        equipmentQuantity: 0,
                    },
                });
            }
            if (
                this.state.editViewDataItem.billable_Start &&
                this.state.editViewDataItem.billable_Finish &&
                this.state.editViewDataItem.rate_Decimal
            ) {
                this.state.approvalEndChangeTimer = setTimeout(this.startCallingfinancialOnType, 1000);
            }
        }
    };

    hideLoadIndicator = () => {
        this.setState({
            loadIndicatorVisible: false,
            loadIndicatorOnPercentageChange: false,
            isDisableApplyButton: false,
        });
    };

    showLoadIndicator = () => {
        this.setState({ loadIndicatorVisible: true, isDisableApplyButton: true });
    };

    financialCalculations = () => {
        if (this.state.percentageFieldChange) {
            this.setState({
                loadIndicatorOnPercentageChange: true,
                loadIndicatorVisible: false,
            });
        } else if (this.state.providerOverrideValue) {
            this.setState({
                loadIndicatorOnPercentageChange: true,
                loadIndicatorVisible: true,
            });
        } else {
            this.showLoadIndicator();
        }
        if (!this.state.isFasterPayChange && !this.state.isArtistPayChange &&
            ((this.state.editViewDataItem.fasterPayment == true || this.state.editViewDataItem.fasterPayment.toString() == "Yes") && !this.state.isFasterPayProp)) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerFasterPayFee: "",
                }
            });
        }
        if (this.state.isArtistPayChange) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerFasterPayFee: "",
                    providerPayAmountLocal: this.state.editViewDataItem.fasterPayment.toString() !== "true" || this.state.editViewDataItem.fasterPayment.toString() == "No" ? this.state.editViewDataItem.providerFasterPayOriginalAmount : this.state.editViewDataItem.providerPayAmountLocal,
                }
            });
        }       
        if(this.state.editViewDataItem.updateOrCorrection == "false" && this.state.editViewDataItem.serviceTypeId !== "30"){
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    shift_Start: this.state.editViewDataItem.billable_Start,
                    shift_End: this.state.editViewDataItem.billable_Finish,

                }
            });
        } 
        let calCulationObject = this.utils.convertCalcDataToCalcRequest(
            this.state.editViewDataItem,
            this.state.providerOverrideValue ? this.state.providerOverrideValue : null,
            this.state.multiplierOverrideValue,
            this.props.location && this.props.location.state ? this.props.location.state.id : this.props.billableItemId
        );

        this.editViewService
            .uploadFormData(calCulationObject)
            .then(this.handleCalculationSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    IsShowSuccess: false,
                    disabledApplybutton: true,
                });
                this.failureMessageVisible();
                this.hideLoadIndicator();
            })
            .finally(() => {
                this.hideLoadIndicator();
            });

    };
    handleCalculationSuccessForExpectedFee = (response: AxiosResponse<any>) => {
        var serverResponse: CalculationResponse = response.data.data;
        this.hideLoadIndicator();
        this.setState({
            IsShowError: false,
            expectedFee: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitAmountLocal),
        });
    };
    handleCalculationSuccess = (response: AxiosResponse<any>) => {
        let serverResponse: CalculationResponse = response.data.data;
        this.hideLoadIndicator();

        this.setState({
            IsShowError: false,
            disabledApplybutton: false,
        });
        if(this.state.editViewDataItem.updateOrCorrection == "false"){
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    scheduledMinutes: serverResponse.billableMinutes,
                }
            })
        }
        // Convert server response to proper data types
        this.setState({
            isFasterPayChange: false,
            isArtistPayChange: false,
            isFasterPayProp: false,
            editViewDataItem: {
                ...this.state.editViewDataItem,
                billable_Minutes: this.sharedUtils.convertStringToFloat(serverResponse.billableMinutes),
                provider_Pay_Amount_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmount),
                provider_Pay_Tax_Local_Ccy: this.state.editViewDataItem.provider_Tax_Applicable == "Yes" ? this.sharedUtils.convertStringToFloat(serverResponse.providerPayTaxLocalCcy) : 0.00,
                provider_Pay_Total_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.providerPayTotalLocalCcy),
                provider_Pay_Currency_Local_Ccy: serverResponse.providerPayAmountLocalCurrency, // Ex: "GBP"
                client_Billable_Amount_Local_Ccy: this.sharedUtils.convertStringToFloat(
                    serverResponse.clientBillableAmountLocal
                ),
                client_Billable_Tax_Local_Ccy: this.state.editViewDataItem.client_Tax_Applicable == "Yes" ? this.sharedUtils.convertStringToFloat(
                    serverResponse.clientBillableTaxLocalCcy
                ) : 0.00,
                client_Billable_Total_Local_Ccy: this.sharedUtils.convertStringToFloat(
                    serverResponse.clientBillableTotalLocalCcy
                ),
                client_Billable_Currency_Local_Ccy: serverResponse.clientBillableAmountLocalCurrency,
                fee_Amount_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitAmountLocal),
                fee_Tax_Local_Ccy: this.state.editViewDataItem.fee_Tax_Applicable == "Yes" ? this.sharedUtils.convertStringToFloat(serverResponse.grossProfitTaxLocalCcy) : 0.00,
                fee_Total_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitTotalLocalCcy),
                fee_Currency_Local_Ccy: serverResponse.grossProfitAmountLocalCurrency,
                equipmentBillableQuantity: this.sharedUtils.convertStringToFloat(serverResponse.billableQuantity),
                billableRate: this.sharedUtils.convertStringToFloat(serverResponse.billableRate),
                providerPayAmountLocal: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocal),
                providerPayAmountLocalExtras: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocalExtras),
                providerPayAmountLocalWOExtras: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocalWOExtras),
                providerFasterPayFee: this.sharedUtils.convertStringToFloat(serverResponse.providerFasterPayFee),
                providerFasterPayOriginalAmount: this.sharedUtils.convertStringToFloat(serverResponse.providerFasterPayOriginalAmount),
                accrual: serverResponse.accrual
                    ? {
                        id: serverResponse.accrual.id,
                        accrualTypeId: serverResponse.accrual.accrualTypeId,
                        accrualStatusId: serverResponse.accrual.accrualStatusId,
                        accrualBillableItemId: serverResponse.accrual.accrualBillableItemId,
                        accrualInvoiceId: serverResponse.accrual.accrualInvoiceId,
                        providerPayAmountAccrual: serverResponse.accrual.providerPayAmountAccrual,
                        providerPayAmountTaxAccrual: this.state.editViewDataItem.provider_Tax_Applicable == "Yes" ? serverResponse.accrual.providerPayAmountTaxAccrual : 0.00,
                        providerPayAmountTotalAccrual: serverResponse.accrual.providerPayAmountTotalAccrual,
                        grossProfitAmountAccrual: serverResponse.accrual.grossProfitAmountAccrual,
                        grossProfitAmountTaxAccrual: this.state.editViewDataItem.fee_Tax_Applicable == "Yes" ? serverResponse.accrual.grossProfitAmountTaxAccrual : 0.00,
                        grossProfitAmountTotalAccrual: serverResponse.accrual.grossProfitAmountTotalAccrual,
                        clientBillableAmountAccrual: serverResponse.accrual.clientBillableAmountAccrual,
                        clientBillableAmountTaxAccrual: this.state.editViewDataItem.client_Tax_Applicable == "Yes" ? serverResponse.accrual.clientBillableAmountTaxAccrual : 0.00,
                        clientBillableAmountTotalAccrual: serverResponse.accrual.clientBillableAmountTotalAccrual,
                        lastUpdatedOn: serverResponse.accrual.lastUpdatedOn,
                        lastUpdatedBy: serverResponse.accrual.lastUpdatedBy,
                    }
                    : null,
            },
            expectedFee: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitAmountLocal),
        }, () => { this.nonStandardDropDownValueSelection() });

        if (this.props.location && this.props.location.state && this.props.location.state.isAdjust) {
            // below block is applicable for Adjustment
            if (
                this.state.isChangeBillableStart ||
                this.state.isChangeBillableFinish ||
                this.state.isChangeRate ||
                this.state.isChangeQuantity ||
                this.state.isChangeHour ||
                this.state.isChangeMinute ||
                this.state.editViewDataItem.noCharge
            ) {
                if (
                    (this.state.acceptDate != null ||
                        this.state.acceptDate != undefined ||
                        this.state.editViewDataItem.paymentId != null ||
                        this.state.editViewDataItem.paymentId != undefined ||
                        this.state.approvalDate != null ||
                        this.state.approvalDate != undefined ||
                        this.state.editViewDataItem.invoiceId != null ||
                        this.state.editViewDataItem.invoiceId != undefined) &&
                    (this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[1].id ||
                        this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[3].id ||
                        this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[4].id)
                ) {
                    if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == 1) {
                        this.setState({
                            calculationMessageClassName: "check-red",
                        });
                        if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
                            this.state.calculationMessage.push(
                                "The new cost" +
                                currencyTypeToSymbolMatrix[
                                this.state.editViewDataItem.provider_Pay_Currency_Local_Ccy
                                ] +
                                this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy.toFixed(2) +
                                " will be sent for processing after this has been created"
                            );
                        } else {
                            this.state.calculationMessage.push(
                                "An additional cost " +
                                currencyTypeToSymbolMatrix[
                                this.state.editViewDataItem.provider_Pay_Currency_Local_Ccy
                                ] +
                                this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy.toFixed(2) +
                                " will be shown on the Client's next available cost report (after approval)"
                            );
                        }
                    } else if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == -1) {
                        this.setState({
                            calculationMessageClassName: "check-blue",
                        });
                        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
                            this.state.calculationMessage.push(
                                "The new cost " +
                                currencyTypeToSymbolMatrix[
                                this.state.editViewDataItem.provider_Pay_Currency_Local_Ccy
                                ] +
                                this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy.toFixed(2) +
                                " will be sent for processing after this has been created"
                            );
                        } else {
                            this.state.calculationMessage.push(
                                "A credit of " +
                                currencyTypeToSymbolMatrix[
                                this.state.editViewDataItem.provider_Pay_Currency_Local_Ccy
                                ] +
                                this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy.toFixed(2) +
                                " will be shown on the Client's next available cost report (after approval)"
                            );
                        }
                    } else if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == 0) {
                        this.state.calculationMessage.push("");
                    }
                }
            }
        }

        this.providerPayTaxCalculation();
        this.feeTaxCalculation();
        this.clientTaxCalculation();
        // taking the latest amount from state calculating the total amount field
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Total_Local_Ccy:
                    this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy +
                    this.state.editViewDataItem.provider_Pay_Tax_Local_Ccy,
                fee_Total_Local_Ccy:
                    this.state.editViewDataItem.fee_Amount_Local_Ccy + this.state.editViewDataItem.fee_Tax_Local_Ccy,
                client_Billable_Total_Local_Ccy:
                    this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                    this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
            },
        });
        if (this.state.editViewDataItem.isAccrual && serverResponse.accrual) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        providerPayAmountTotalAccrual:
                            serverResponse.accrual.providerPayAmountAccrual +
                            serverResponse.accrual.providerPayAmountTaxAccrual,
                        grossProfitAmountTotalAccrual:
                            serverResponse.accrual.grossProfitAmountAccrual +
                            serverResponse.accrual.grossProfitAmountTaxAccrual,
                        clientBillableAmountTotalAccrual:
                            serverResponse.accrual.clientBillableAmountAccrual +
                            serverResponse.accrual.clientBillableAmountTaxAccrual,
                    },
                },
            });
        }
        this.handleItemTypeSelection();
    };
    onCalculationTaxApplicableFieldSet = () => {
        this.providerPayTaxCalculation();
        this.feeTaxCalculation();
        this.clientTaxCalculation();
        if (this.state.editViewDataItem.accrual && this.state.editViewDataItem.isAccrual) {
            this.providerAccrualTaxCalculation();
            this.feeTaxAccrualCalculation();
            this.clientTaxAccrualCalculation();
        }
    };
    setFinanceFieldsToNull = () => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Total_Local_Ccy: 0.0,
                fee_Total_Local_Ccy: 0.0,
                client_Billable_Total_Local_Ccy: 0.0,
                provider_Pay_Amount_Local_Ccy: 0.0,
                fee_Amount_Local_Ccy: 0.0,
                client_Billable_Amount_Local_Ccy: 0.0,
                provider_Pay_Tax_Local_Ccy: 0.0,
                fee_Tax_Local_Ccy: 0.0,
                client_Billable_Tax_Local_Ccy: 0.0,
            },
        });
        if (!this.state.headerId) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Original_Amount_Local_Ccy: 0.0,
                    provider_Pay_Original_Amount_Local_Ccy: 0.0,
                },
            });
        }
    };
    handleChangeCheckInTime = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    check_In: dxValueChange.value,
                },
            });
        }
    };

    handleChangeCheckOutTime = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    check_Out: dxValueChange.value,
                },
            });
        }
    };

    handleChangeAbsent = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    absent: dxValueChange.value,
                },
            });
            if (this.props.location && this.props.location.state && this.props.location.state.isAmend) {
                if (this.state.editViewDataItem.absent.toLowerCase() == "not present") {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            billable_Finish: this.state.editViewDataItem.billable_Start,
                        },
                    });
                    if (
                        this.state.editViewDataItem.billable_Start &&
                        this.state.editViewDataItem.billable_Finish &&
                        this.state.editViewDataItem.rate_Decimal
                    ) {
                        this.financialCalculations();
                    }
                    this.setState({
                        IsLockBillableFields: true,
                    });
                }
            } else {
                this.onChangeAbsentNoChargeSetBillableMinutes();
            }
        }
    };

    onChangeAbsentNoChargeSetBillableMinutes = () => {
        if (this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel) {
            if (
                this.state.editViewDataItem.absent.toLowerCase() == "not present" ||
                this.state.editViewDataItem.noCharge.toLowerCase() == "waived"
            ) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        billable_Finish: this.state.editViewDataItem.billable_Start,
                    },
                });
                if (
                    this.state.editViewDataItem.billable_Start &&
                    this.state.editViewDataItem.billable_Finish &&
                    this.state.editViewDataItem.rate_Decimal
                ) {
                    this.financialCalculations();
                }
                this.setState({
                    IsLockBillableFields: true,
                });
            } else if (
                this.state.editViewDataItem.absent.toLowerCase() == "present" ||
                this.state.editViewDataItem.noCharge.toLowerCase() == "applied"
            ) {
                let shiftStart = this.sharedUtils.convertTimeToString(this.state.editViewDataItem.shift_Start);
                let shiftEnd = this.sharedUtils.convertTimeToString(this.state.editViewDataItem.shift_End);
                let checkIn = this.sharedUtils.convertTimeToString(this.state.editViewDataItem.check_In);
                let checkOut = this.sharedUtils.convertTimeToString(this.state.editViewDataItem.check_Out);
                if (shiftStart != "00:00" || shiftEnd != "00:00") {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            billable_Start: this.state.editViewDataItem.shift_Start,
                            billable_Finish: this.state.editViewDataItem.shift_End,
                        },
                    });
                } else if (
                    checkIn != "00:00" ||
                    checkOut != "00:00" ||
                    (shiftStart == "00:00" && shiftEnd == "00:00" && checkIn == "00:00" && checkOut == "00:00")
                ) {
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            billable_Start: this.state.editViewDataItem.check_In,
                            billable_Finish: this.state.editViewDataItem.check_Out,
                        },
                    });
                }
                if (
                    this.state.editViewDataItem.billable_Start &&
                    this.state.editViewDataItem.billable_Finish &&
                    this.state.editViewDataItem.rate_Decimal
                ) {
                    this.financialCalculations();
                }
                this.setState({
                    IsLockBillableFields: false,
                });
            }
        }
    };

    onChangeCharge = () => {
        if (this.state.editViewDataItem.noCharge.toLowerCase() == "waived") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    rate_Decimal: 0,
                    provider_Pay_Amount_Local_Ccy: 0.0,
                    fee_Amount_Local_Ccy: 0.0,
                    client_Billable_Amount_Local_Ccy: 0.0,
                    provider_Pay_Tax_Local_Ccy: 0.0,
                    fee_Tax_Local_Ccy: 0.0,
                    client_Billable_Tax_Local_Ccy: 0.0,
                    provider_Pay_Total_Local_Ccy: 0.0,
                    fee_Total_Local_Ccy: 0.0,
                    client_Billable_Total_Local_Ccy: 0.0,
                    provider_Pay_Original_Amount_Local_Ccy: 0.0,
                    client_Billable_Original_Amount_Local_Ccy: 0.0,
                },
                expectedFee: 0.0
            });
        } else if (this.state.editViewDataItem.noCharge.toLowerCase() == "applied") {
            this.setState(
                {
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        rate_Decimal: this.state.rate,
                    },
                },
                () => {
                    this.financialCalculations();
                }
            );
        }
    };

    handleChangeRequestApprover = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    requestApprover: dxValueChange.value,
                },
            });
        }
    };

    handleChangeRequestedOn = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    requestDate: dxValueChange.value,
                },
            });
        }
    };
    handleChangeOtherDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    otherDescription: dxValueChange.value,
                },
            });
        }
    };
    handleChangeRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.editViewDataItem.rate_Decimal !== dxValueChange.value) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    rate_Decimal: dxValueChange.value,
                    scheduleRate:
                        this.props.location && this.props.location.state && this.props.location.state.id == 0
                            ? dxValueChange.value
                            : this.state.editViewDataItem.scheduleRate,
                },
                isProviderNetOverride: false,
                isMultiplierOverride: false,
                isChangeRate: true,
                percentageFieldChange: false,
                providerOverrideValue: null,
                multiplierOverrideValue: null,
                isArtistPayChange: true,
            });

            if (this.state.editViewDataItem.providerFasterPayOriginalAmount == undefined) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        providerFasterPayOriginalAmount: dxValueChange.value,
                    },
                });
            }

            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        }
    };

    handleChangeExtras = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerPayAmountLocalExtras: dxValueChange.value,
                },
            });
        }
    };

    handleChangeAdjustedAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerPayAmountLocal: dxValueChange.value,
                },
            });
        }
    };

    handleChangeFasterPayFee = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.editViewDataItem.providerFasterPayFee !== dxValueChange.value) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerFasterPayFee: dxValueChange.value,
                },
                isFasterPayChange: true,
            });

            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        }
    };

    startCallingFinancialAdjustment = () => {
        if (this.state.rateChangeTimer || this.state.hourChangeTimer) {
            clearTimeout(this.state.rateChangeTimer);
            clearTimeout(this.state.hourChangeTimer);
            this.state.rateChangeTimer = null;
            if (this.state.editViewDataItem.billable_Finish && this.state.editViewDataItem.billable_Start) {
                this.financialCalculations();
            } else {
                if (
                    this.state.editViewDataItem.equipmentQuantity == 0 ||
                    this.state.editViewDataItem.equipmentQuantity
                ) {
                    this.financialCalculations();
                }
            }
        }
    };
    handleChangeOriginalRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    originalRate: dxValueChange.value,
                },
            });
        }
    };
    handleChangeQuantity = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    equipmentQuantity: dxValueChange.value,
                },
                isProviderNetOverride: false,
                isMultiplierOverride: false,
                providerOverrideValue: null,
                multiplierOverrideValue: null
            });
            this.setState({
                isChangeQuantity: true,
                percentageFieldChange: false,
            });
            if (this.props.location && this.props.location.state && this.props.location.state.id == 0) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        equipmentScheduleQuantity: this.state.editViewDataItem.equipmentQuantity,
                    },
                });
            }
            if (
                this.state.editViewDataItem.billable_Start ||
                this.state.editViewDataItem.billable_Finish ||
                this.state.editViewDataItem.billable_Minutes
            ) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        billable_Start: null,
                        billable_Finish: null,
                        billable_Minutes: null,
                    },
                });
            }
            if (
                this.state.editViewDataItem.rate_Decimal &&
                (this.state.editViewDataItem.equipmentQuantity == 0 || this.state.editViewDataItem.equipmentQuantity)
            ) {
                this.financialCalculations();
            }
        }
    };
    handleChangeOriginalQuantity = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    equipmentOriginalQuantity: dxValueChange.value,
                },
            });
        }
    };
    handleChangeCover = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    cover: dxValueChange.value,
                    coverIndex: dxValueChange.value,
                },
            });
        }
    };
    handleChangeNoChange = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    noCharge: dxValueChange.value,
                },
            });
            if (this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel) {
                this.onChangeAbsentNoChargeSetBillableMinutes();
            } else {
                this.onChangeCharge();
            }
        }
    };
    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    comments: dxValueChange.value,
                },
            });
            this.setState({
                queryItem: {
                    ...this.state.queryItem,
                    queryDescription: dxValueChange.value,
                },
            });
        }
    };

    handleChangeProviderTaxApplicableSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    provider_Tax_Applicable: dxValueChange.value,
                },
            });
            this.providerPayTaxCalculation();
            if (this.state.editViewDataItem.isAccrual && this.state.editViewDataItem.accrual && this.state.editViewDataItem.accrual.providerPayAmountAccrual) {
                this.providerAccrualTaxCalculation();
            }
        }
    };

    handleChangeUnscheduledSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    unscheduled: dxValueChange.value,
                },
            });
        }
    };
    providerPayTaxCalculation = () => {
        var providerCalculationVal: number[] = [];
        providerCalculationVal = this.utils.providerPayTaxCal(
            this.state.editViewDataItem,
            this.state.configuarationValue
        );

        var providerPayTaxLocalCcy: number = 0;
        var providerPayAmountTotalCcy: number = 0;
        var providerTaxFieldVisibility: number = 0;
        if (providerCalculationVal.length > 0) {
            providerPayTaxLocalCcy = providerCalculationVal[0];
            providerPayAmountTotalCcy = providerCalculationVal[1];
            if (providerCalculationVal.length == 3) {
                providerTaxFieldVisibility = providerCalculationVal[2];
            }
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Tax_Local_Ccy: providerPayTaxLocalCcy,
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Total_Local_Ccy: providerPayAmountTotalCcy,
            },
        });
        if (providerTaxFieldVisibility == 1) {
            this.setState({ isProviderTaxFieldVisible: true });
        } else {
            this.setState({ isProviderTaxFieldVisible: false });
        }
    };

    providerAccrualTaxCalculation = () => {
        var providerAccrualCalculationVal: number[] = [];
        providerAccrualCalculationVal = this.utils.providerPayAccrualTaxCal(
            this.state.editViewDataItem,
            this.state.configuarationValue
        );

        var providerPayTaxAccrual: number = 0;
        var providerPayAmountTotalAccrual: number = 0;
        var providerTaxFieldVisibility: number = 0;
        if (providerAccrualCalculationVal.length > 0) {
            providerPayTaxAccrual = providerAccrualCalculationVal[0];
            providerPayAmountTotalAccrual = providerAccrualCalculationVal[1];
            if (providerAccrualCalculationVal.length == 3) {
                providerTaxFieldVisibility = providerAccrualCalculationVal[2];
            }
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                accrual: {
                    ...this.state.editViewDataItem.accrual,
                    providerPayAmountTaxAccrual: providerPayTaxAccrual,
                },
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                accrual: {
                    ...this.state.editViewDataItem.accrual,
                    providerPayAmountTotalAccrual: providerPayAmountTotalAccrual,
                },
            },
        });
        if (providerTaxFieldVisibility == 1) {
            this.setState({ isProviderTaxFieldVisible: true });
        } else {
            this.setState({ isProviderTaxFieldVisible: false });
        }
    };
    handleChangeFeeTaxApplicableSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Tax_Applicable: dxValueChange.value,
                },
            });
            this.feeTaxCalculation();
            if (this.state.editViewDataItem.isAccrual && this.state.editViewDataItem.accrual && this.state.editViewDataItem.accrual.grossProfitAmountAccrual) {
                this.feeTaxAccrualCalculation();
            }
        }
    };

    feeTaxCalculation = () => {
        var feeCalculationVal: number[] = [];
        feeCalculationVal = this.utils.feeTaxCal(this.state.editViewDataItem, this.state.configuarationValue);
        var feeTaxLocalCcy: number = 0;
        var feeAmountTotalCcy: number = 0;
        var feeTaxFieldVisibility: number = 0;
        if (feeCalculationVal.length > 0) {
            feeTaxLocalCcy = feeCalculationVal[0];
            feeAmountTotalCcy = feeCalculationVal[1];
            if (feeCalculationVal.length == 3) {
                feeTaxFieldVisibility = feeCalculationVal[2];
            }
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Tax_Local_Ccy: feeTaxLocalCcy,
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Total_Local_Ccy: feeAmountTotalCcy,
            },
        });
        if (feeTaxFieldVisibility == 1) {
            this.setState({ isFeeTaxFieldVisible: true });
        } else {
            this.setState({ isFeeTaxFieldVisible: false });
        }
    };

    feeTaxAccrualCalculation = () => {
        var feeCalculationAccrualVal: number[] = [];
        feeCalculationAccrualVal = this.utils.feeAccrualTaxCal(
            this.state.editViewDataItem,
            this.state.configuarationValue
        );
        var feeTaxAccrual: number = 0;
        var feeAmountTotalAccrual: number = 0;
        var feeTaxFieldVisibility: number = 0;
        if (feeCalculationAccrualVal.length > 0) {
            feeTaxAccrual = feeCalculationAccrualVal[0];
            feeAmountTotalAccrual = feeCalculationAccrualVal[1];
            if (feeCalculationAccrualVal.length == 3) {
                feeTaxFieldVisibility = feeCalculationAccrualVal[2];
            }
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                accrual: {
                    ...this.state.editViewDataItem.accrual,
                    grossProfitAmountTaxAccrual: feeTaxAccrual,
                },
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                accrual: {
                    ...this.state.editViewDataItem.accrual,
                    grossProfitAmountTotalAccrual: feeAmountTotalAccrual,
                },
            },
        });
        if (feeTaxFieldVisibility == 1) {
            this.setState({ isFeeTaxFieldVisible: true });
        } else {
            this.setState({ isFeeTaxFieldVisible: false });
        }
    };

    handleChangeClientTaxApplicableSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Tax_Applicable: dxValueChange.value,
                },
            });

            this.clientTaxCalculation();
            if (this.state.editViewDataItem.isAccrual && this.state.editViewDataItem.accrual && this.state.editViewDataItem.accrual.clientBillableAmountAccrual) {
                this.clientTaxAccrualCalculation();
            }
        }
    };

    clientTaxCalculation = () => {
        var clientCalculationVal: number[] = [];
        clientCalculationVal = this.utils.clientTaxCal(
            this.state.editViewDataItem,
            this.state.configuarationValue
        );
        var clientTaxLocalCcy: number = 0;
        var clientAmountTotalCcy: number = 0;
        var clientTaxFieldVisibility: number = 0;
        if (clientCalculationVal.length > 0) {
            clientTaxLocalCcy = clientCalculationVal[0];
            clientAmountTotalCcy = clientCalculationVal[1];
            if (clientCalculationVal.length == 3) {
                clientTaxFieldVisibility = clientCalculationVal[2];
            }
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Tax_Local_Ccy: clientTaxLocalCcy,
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Total_Local_Ccy: clientAmountTotalCcy,
            },
        });
        if (clientTaxFieldVisibility == 1) {
            this.setState({ isBillClientTaxFieldVisible: true });
        } else {
            this.setState({ isBillClientTaxFieldVisible: false });
        }
    };

    clientTaxAccrualCalculation = () => {
        var clientCalculationAccrualVal: number[] = [];
        clientCalculationAccrualVal = this.utils.clientAccrualTaxCal(
            this.state.editViewDataItem,
            this.state.configuarationValue
        );
        var clientTaxLocalAccrual: number = 0;
        var clientAmountTotalAccrual: number = 0;
        var clientTaxFieldVisibility: number = 0;
        if (clientCalculationAccrualVal.length > 0) {
            clientTaxLocalAccrual = clientCalculationAccrualVal[0];
            clientAmountTotalAccrual = clientCalculationAccrualVal[1];
            if (clientCalculationAccrualVal.length == 3) {
                clientTaxFieldVisibility = clientCalculationAccrualVal[2];
            }
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                accrual: {
                    ...this.state.editViewDataItem.accrual,
                    clientBillableAmountTaxAccrual: clientTaxLocalAccrual,
                },
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                accrual: {
                    ...this.state.editViewDataItem.accrual,
                    clientBillableAmountTotalAccrual: clientAmountTotalAccrual,
                },
            },
        });
        if (clientTaxFieldVisibility == 1) {
            this.setState({ isBillClientTaxFieldVisible: true });
        } else {
            this.setState({ isBillClientTaxFieldVisible: false });
        }
    };
    handleChangeProviderCurrencyTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    provider_Pay_Currency_Local_Ccy: dxValueChange.value,
                },
            });
        }
    };

    handleChangeFeeCurrencyTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Currency_Local_Ccy: dxValueChange.value,
                },
            });
        }
    };

    handleChangeBillCurrencyTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Currency_Local_Ccy: dxValueChange.value,
                },
            });
        }
    };

    handleChangeAcceptDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accept_Date: dxValueChange.value,
                },
            });
        }
    };

    handleChangePercentage = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                // editViewDataItem: {
                //     ...this.state.editViewDataItem,
                //     fee_Percentage_Local_Ccy: dxValueChange.value,
                // },
                multiplierOverrideValue: dxValueChange.value,
                isMultiplierOverride: true
            });
            this.setState({
                percentageFieldChange: true,
            });
            this.state.multiplierChangeTimer = setTimeout(this.startCallingFinancialRecalculation, 1000);
        }
    };

    startCallingFinancialRecalculation = () => {
        if (this.state.multiplierChangeTimer) {
            clearTimeout(this.state.multiplierChangeTimer);
            this.state.multiplierChangeTimer = null;
            this.financeRecalculationOnPercentageChange();
        }
    }

    handleChangeApprovalDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    approval_Date: dxValueChange.value,
                },
            });
        }
    };

    handleChangeTwoTierApprovalDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            let header = GlobalService.getHeaderResopnse();
            let approvedBy = dxValueChange.value ? (header.description ? header.description : ''): '';
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    twoTierApprovalDate: dxValueChange.value,
                    twoTierApprovedBy : approvedBy
                },
            });
        }
    };

    handleChangeProviderLocalCurrancyAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    provider_Pay_Amount_Local_Ccy: dxValueChange.value,
                },
                providerOverrideValue: dxValueChange.value,
                isProviderNetOverride: true
            }, () => { this.state.providerNetChangeTimer = setTimeout(this.startCallingCalcOnProviderNetChange, 1000); });
        }


    };
    startCallingCalcOnProviderNetChange = () => {
        if (this.state.providerNetChangeTimer) {
            clearTimeout(this.state.providerNetChangeTimer);
            this.state.providerNetChangeTimer = null;
            this.financialCalculations();
        }
    }

    handleChangeProviderPayAccrualAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        var clientAccrualAmount: number[] = [];
        var providerAccrualAmount: number[] = [];
        var clientAccrualBillableTaxLocalCcy: number = 0;
        var clientAccrualBillableTotalLocalCcy: number = 0;
        var providerAccrualTaxLocalCcy: number = 0;
        var providerAccrualTotalLocalCcy: number = 0;
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        providerPayAmountAccrual: dxValueChange.value,
                    },
                },
            });
            if (this.state.editViewDataItem.accrual) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        accrual: {
                            ...this.state.editViewDataItem.accrual,
                            clientBillableAmountAccrual:
                                this.state.editViewDataItem.accrual.providerPayAmountAccrual +
                                this.state.editViewDataItem.accrual.grossProfitAmountAccrual,
                        },
                    },
                });
            }
            clientAccrualAmount = this.utils.clientAccrualTaxCal(
                this.state.editViewDataItem,
                this.state.configuarationValue
            );
            if (clientAccrualAmount.length > 0) {
                clientAccrualBillableTaxLocalCcy = clientAccrualAmount[0];
                clientAccrualBillableTotalLocalCcy = clientAccrualAmount[1];
            }
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        clientBillableAmountTaxAccrual: clientAccrualBillableTaxLocalCcy,
                    },
                },
            });
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        clientBillableAmountTotalAccrual: clientAccrualBillableTotalLocalCcy,
                    },
                },
            });
            providerAccrualAmount = this.utils.providerPayAccrualTaxCal(
                this.state.editViewDataItem,
                this.state.configuarationValue
            );
            if (providerAccrualAmount.length > 0) {
                providerAccrualTaxLocalCcy = providerAccrualAmount[0];
                providerAccrualTotalLocalCcy = providerAccrualAmount[1];
            }

            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        providerPayAmountTaxAccrual: providerAccrualTaxLocalCcy,
                    },
                },
            });

            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        providerPayAmountTotalAccrual: providerAccrualTotalLocalCcy,
                    },
                },
            });
        }
    };

    handleChangeFeeLocalCurrancyAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        var clientAmount: number[] = [];
        var feeAmount: number[] = [];
        var clientBillableTaxLocalCcy: number = 0;
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Amount_Local_Ccy: dxValueChange.value,
                },
            });

            if (this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        client_Billable_Amount_Local_Ccy:
                            this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy +
                            this.state.editViewDataItem.fee_Amount_Local_Ccy,
                    },
                });
            } else {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        client_Billable_Amount_Local_Ccy: this.state.editViewDataItem.fee_Amount_Local_Ccy,
                    },
                });
            }

            clientAmount = this.utils.clientTaxCal(this.state.editViewDataItem, this.state.configuarationValue);
            if (clientAmount.length > 0) {
                clientBillableTaxLocalCcy = clientAmount[0];
            }
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy: clientBillableTaxLocalCcy,
                },
            });

            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Total_Local_Ccy:
                        this.state.editViewDataItem.client_Tax_Applicable != ""
                            ? this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                            this.state.editViewDataItem.client_Billable_Tax_Local_Ccy
                            : this.state.editViewDataItem.client_Billable_Amount_Local_Ccy,
                },
            });

            feeAmount = this.utils.feeTaxCal(this.state.editViewDataItem, this.state.configuarationValue);
            if (feeAmount.length > 0) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        fee_Tax_Local_Ccy: feeAmount[0],
                    },
                });

                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        fee_Total_Local_Ccy: feeAmount[1],
                    },
                });
            }
            this.nonStandardDropDownValueSelection();
        }
    };

    handleChangeFeeAccrualAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        var clientAccrualAmount: number[] = [];
        var feeAccrualAmount: number[] = [];
        var clientBillableAccrualTaxLocalCcy: number = 0;
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        grossProfitAmountAccrual: dxValueChange.value,
                    },
                },
            });

            if (this.state.editViewDataItem.accrual && this.state.editViewDataItem.accrual.providerPayAmountAccrual) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        accrual: {
                            ...this.state.editViewDataItem.accrual,
                            clientBillableAmountAccrual:
                                this.state.editViewDataItem.accrual.providerPayAmountAccrual +
                                this.state.editViewDataItem.accrual.grossProfitAmountAccrual,
                        },
                    },
                });
            } else {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        accrual: {
                            ...this.state.editViewDataItem.accrual,
                            clientBillableAmountAccrual: this.state.editViewDataItem.accrual
                                ? this.state.editViewDataItem.accrual.grossProfitAmountAccrual
                                : 0,
                        },
                    },
                });
            }

            clientAccrualAmount = this.utils.clientAccrualTaxCal(
                this.state.editViewDataItem,
                this.state.configuarationValue
            );
            if (clientAccrualAmount.length > 0) {
                clientBillableAccrualTaxLocalCcy = clientAccrualAmount[0];
            }
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        clientBillableAmountTaxAccrual: clientBillableAccrualTaxLocalCcy,
                    },
                },
            });
            if (this.state.editViewDataItem.accrual) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        accrual: {
                            ...this.state.editViewDataItem.accrual,
                            clientBillableAmountTotalAccrual:
                                this.state.editViewDataItem.client_Tax_Applicable != ""
                                    ? this.state.editViewDataItem.accrual.clientBillableAmountAccrual +
                                    this.state.editViewDataItem.accrual.clientBillableAmountTaxAccrual
                                    : this.state.editViewDataItem.accrual.clientBillableAmountAccrual,
                        },
                    },
                });
            }

            feeAccrualAmount = this.utils.feeAccrualTaxCal(
                this.state.editViewDataItem,
                this.state.configuarationValue
            );
            if (feeAccrualAmount.length > 0) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        accrual: {
                            ...this.state.editViewDataItem.accrual,
                            grossProfitAmountTaxAccrual: feeAccrualAmount[0],
                        },
                    },
                });

                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        accrual: {
                            ...this.state.editViewDataItem.accrual,
                            grossProfitAmountTotalAccrual: feeAccrualAmount[1],
                        },
                    },
                });
            }
        }
    };
    handleChangeBillLocalCurrancyAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        var clientAmount: number[] = [];
        var clientBillableTaxLocalCcy: number = 0;
        var clientBillableTotalLocalCcy: number = 0;
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Amount_Local_Ccy: dxValueChange.value,
                },
            });

            clientAmount = this.utils.clientTaxCal(this.state.editViewDataItem, this.state.configuarationValue);
            if (clientAmount.length > 0) {
                clientBillableTaxLocalCcy = clientAmount[0];
                clientBillableTotalLocalCcy = clientAmount[1];
            }
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy: clientBillableTaxLocalCcy,
                },
            });
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Total_Local_Ccy: clientBillableTotalLocalCcy,
                },
            });
        }
    };

    handleChangeBillAccrualAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        var clientAccrualAmount: number[] = [];
        var clientBillableAccrualTax: number = 0;
        var clientBillableAccrualTotal: number = 0;
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        clientBillableAmountAccrual: dxValueChange.value,
                    },
                },
            });

            clientAccrualAmount = this.utils.clientAccrualTaxCal(
                this.state.editViewDataItem,
                this.state.configuarationValue
            );
            if (clientAccrualAmount.length > 0) {
                clientBillableAccrualTax = clientAccrualAmount[0];
                clientBillableAccrualTotal = clientAccrualAmount[1];
            }
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        clientBillableAmountTaxAccrual: clientBillableAccrualTax,
                    },
                },
            });
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: {
                        ...this.state.editViewDataItem.accrual,
                        clientBillableAmountTotalAccrual: clientBillableAccrualTotal,
                    },
                },
            });
        }
    };

    handleChangeProviderLocalCurrancyTax = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    provider_Pay_Tax_Local_Ccy: dxValueChange.value,
                    provider_Pay_Total_Local_Ccy: this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy + dxValueChange.value
                },
            });
        }
    };

    handleChangeFeeLocalCurrancyTax = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Tax_Local_Ccy: dxValueChange.value,
                    fee_Total_Local_Ccy: this.state.editViewDataItem.fee_Amount_Local_Ccy + dxValueChange.value
                },
            });
        }
    };

    handleChangeBillLocalCurrancyTax = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy: dxValueChange.value,
                    client_Billable_Total_Local_Ccy: this.state.editViewDataItem.client_Billable_Amount_Local_Ccy + dxValueChange.value
                },
            });
        }
    };

    handleChangeProviderOriginalAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    provider_Pay_Original_Amount_Local_Ccy: dxValueChange.value,
                },
            });
        }
    };

    handleChangeFeeNonStandardTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    non_Standard: dxValueChange.value,
                },
            });
        }
    };

    handleChangeAccrualTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    isAccrual: dxValueChange.value,
                    accrual: null
                },
            });
        }
        if (
            (this.state.editViewDataItem.isAccrual.toString() == "Yes" ||
                this.state.editViewDataItem.isAccrual == true) && (this.state.editViewDataItem.rate_Decimal)
        ) {
            this.financialCalculations();
        }
    };

    handleChangeHoldPayment = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    holdPayment: dxValueChange.value,
                },
            });
        }
    }

    handleChangeHardApproval = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    hardApproval: dxValueChange.value,
                },
            });
        }
    }

    handleChangeBillOriginalAmount = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Original_Amount_Local_Ccy: dxValueChange.value,
                },
            });
        }
    };

    handleChangePayDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    pay_Date: dxValueChange.value,
                },
            });
        }
    };

    handleChangeBillDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    bill_Date: dxValueChange.value,
                },
            });
        }
    };

    handleChangeInternalComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    internalComments: dxValueChange.value,
                },
            });
        }
    };
    handleChangeSetNumber = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    performanceInformationSetsNumber: dxValueChange.value,
                },
            });
        }
    };
    handleChangeDuration = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    performanceInformationSetsDuration: dxValueChange.value,
                },
            });
        }
    };
    handleChangePerformanceInfo = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    performanceInformation: dxValueChange.value,
                },
            });
        }
    };
    handleChangeAdditionalInfo = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    additionalInformation: dxValueChange.value,
                },
            });
        }
    };
    handleChangeEquipmentRequired = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    equipmentRequired: dxValueChange.value,
                },
            });
        }
    };
    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    description: dxValueChange.value,
                },
            });
        }
    };
    handleChangeExtraDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerPayAmountLocalExtrasDescription: dxValueChange.value,
                },
            });
        }
    };
    handleChangeArtistConfirmation = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    shiftCompleteAcknowledgment: dxValueChange.value,
                },
            });
        }
    };
    handleChangeReoccurance = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    reoccurance: dxValueChange.value,
                },
            });
        }
    };

    //#endregion
    //#region DataSubmissionToServer
    // Submit the form data to the server.
    successMessageVisible = () => {
        this.setState({
            IsShowSuccess: true,
        });
    };

    failureMessageVisible = () => {
        this.setState({
            IsShowError: true,
        });
    };

    hideLoadpanel = () => {
        this.setState({ loadPanelVisible: false });
    };

    renderRedirect = (billableItemId: string) => {
        //sangeeta
        this.utils.renderRedirectCommonMethod(this.props, this.state.IsShowSuccess, "apply",billableItemId);
    };

    onCancel = () => {
        if (window.location.pathname.includes("queryGrid")) {
            this.props.hidePopup(false);
        }
        this.utils.renderRedirectCommonMethod(this.props, this.state.IsShowSuccess, "cancel", "");
    };

    handleFormDataSubmissionSuccess = (response: any) => {
        this.hideLoadpanel();
        this.successMessageVisible();
        let billableItemId: string = "";
        if(response && response.data && response.data.data.length > 0){
            billableItemId = response.data.data[0].billableItemId;
        }
        if (window.location.pathname.includes("queryGrid")) {
            this.props.hidePopup(false);
        }
        this.setState({
            IsShowError: false,
        });
        this.renderRedirect(billableItemId);
    };
    validate = (statusId: string, itemTypeId: string) => {
        // we are going to store errors for all fields
        // in a signle array
        let errorsAdjustment: string[] = [];

        if (
            itemTypeId == this.state.itemTypeLookUp[1].id ||
            itemTypeId == this.state.itemTypeLookUp[3].id ||
            itemTypeId == this.state.itemTypeLookUp[4].id
        ) {
            if (
                this.state.editViewDataItem.approval_Date != null ||
                this.state.editViewDataItem.approval_Date != undefined ||
                this.state.editViewDataItem.bill_Date != null ||
                this.state.editViewDataItem.bill_Date != undefined ||
                this.state.editViewDataItem.accept_Date != null ||
                this.state.editViewDataItem.accept_Date != undefined
            ) {
                return errorsAdjustment;
            } else if (
                this.state.editViewDataItem.approval_Date == null ||
                this.state.editViewDataItem.approval_Date == undefined
            ) {
                errorsAdjustment.push("For Adjustment, Shift should be 'Approved'/'Billed'/'Accepted'/'Paid'");
            } else if (
                this.state.editViewDataItem.bill_Date == null ||
                this.state.editViewDataItem.bill_Date == undefined
            ) {
                errorsAdjustment.push("For Adjustment, Shift should be 'Approved'/'Billed'/'Accepted'/'Paid'");
            } else if (
                this.state.editViewDataItem.accept_Date == null ||
                this.state.editViewDataItem.accept_Date == undefined
            ) {
                errorsAdjustment.push("For Adjustment, Shift should be 'Approved'/'Billed'/'Accepted'/'Paid'");
            } else if (
                this.state.editViewDataItem.pay_Date == null ||
                this.state.editViewDataItem.pay_Date == undefined
            ) {
                errorsAdjustment.push("For Adjustment, Shift should be 'Approved'/'Billed'/'Accepted'/'Paid'");
            }
        }
        return errorsAdjustment;
    };

    checkAmounts = () => {
        if(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy == 0 || this.state.editViewDataItem.fee_Amount_Local_Ccy == 0 || 
            this.state.editViewDataItem.client_Billable_Amount_Local_Ccy == 0){
                this.setState({showWarningPopup: true});
        }
        else{
            this.onSubmit();
        }
    };

    hideWarningPopup = () => {
        this.setState({showWarningPopup: false});
    };

    onSubmit = () => {
        let itemType = "";
        let statusId = "";
        let billableItemIdValueForAdjustment = "";
        let paretRecordIdForAdjustment = "";
        let originalAmountForAdjustment = "";
        let approveDate = null;
        let acceptDate = null;
        let artistConfirmation: boolean = false;
        let amend: boolean = false;
        amend = this.props.location && this.props.location.state && this.props.location.state.isAmend;
        var serviceFeeMatrixVal = this.billableItemUtils.serviceFeeMatrixDerivation(
            this.state.editViewDataItem.clientId,
            this.state.editViewDataItem.serviceTypeId,
            this.state.editViewDataItem.serviceSubTypeId
        )
        this.setState({
            loadPanelVisible: true,
        });     
        if (this.state.editViewDataItem.shiftCompleteAcknowledgment) {
            if (this.state.editViewDataItem.shiftCompleteAcknowledgment.toString().toUpperCase() == "TRUE" || this.state.editViewDataItem.shiftCompleteAcknowledgment.toString().toUpperCase() == "YES") {
                artistConfirmation = true;
            }
        }
        if (this.props.location && this.props.location.state && this.props.location.state.id == 0) {
            if (this.state.editViewDataItem.itemTypeId == "") {
                itemType = this.state.itemTypeLookUp[0].id;
            }
        }
        if (this.props.location && this.props.location.state && this.props.location.state.isEdit) {
            if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
                if (this.state.editViewDataItem.billableStatusLookupId == Status.Approved) {
                    approveDate = new Date();
                }
            }
            if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
                if (this.state.editViewDataItem.billableStatusLookupId == Status.Accepted) {
                    acceptDate = new Date();
                }
            }
            amend = this.billableItemUtils.acceptanceRecordCreation(
                this.state.editViewDataItem.tenseFlag,
                this.state.editViewDataItem.itemTypeId,
                this.state.isChangePendingToApprove,
                this.state.editViewDataItem.serviceSubTypeId,
                this.state.editViewDataItem.billable_Minutes,
                this.state.editViewDataItem.scheduledMinutes,
                this.state.editViewDataItem.billableRate,
                this.state.editViewDataItem.scheduleRate,
                this.state.editViewDataItem.equipmentBillableQuantity,
                this.state.editViewDataItem.equipmentScheduleQuantity,
                serviceFeeMatrixVal.valueOfProviderAcceptance

            )
            if (amend) {
                statusId = this.billableItemUtils.billableItemStatusSetBasedOnItemType
                    (this.state.editViewDataItem.itemTypeId, serviceFeeMatrixVal.valueOfProviderAcceptance);
            }
        }
        if (this.props.location && this.props.location.state && this.props.location.state.isAdjust) {
            if (
                (this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[1].id ||
                    this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[3].id ||
                    this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[4].id) &&
                (this.state.acceptDate != null ||
                    this.state.acceptDate != undefined ||
                    this.state.editViewDataItem.paymentId != null ||
                    this.state.editViewDataItem.paymentId != undefined ||
                    this.state.approvalDate != null ||
                    this.state.approvalDate != undefined ||
                    this.state.editViewDataItem.invoiceId != null ||
                    this.state.editViewDataItem.invoiceId != undefined)
            ) {
                if (this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy == 0) {
                    statusId = Status.Pending;
                    itemType = this.state.itemTypeLookUp[1].id; // Adjustment
                } else if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == 1) {
                    if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
                        statusId = Status.Approved;
                        approveDate = new Date();
                    } else {
                        statusId = Status.Pending;
                    }
                    itemType = this.state.itemTypeLookUp[3].id; // AdjustmentClientDebit
                } else if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == -1) {
                    if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
                        statusId = Status.Accepted;
                        acceptDate = new Date();
                    } else {
                        statusId = this.billableItemUtils.billableItemStatusSetBasedOnItemType
                            (this.state.editViewDataItem.itemTypeId, serviceFeeMatrixVal.valueOfProviderAcceptance);
                    }
                    itemType = this.state.itemTypeLookUp[4].id; // adjustmentProviderDebit
                }
                billableItemIdValueForAdjustment = "0";
                paretRecordIdForAdjustment = this.state.editViewDataItem.billableItemId;
            }
        }

        var billableItemSubmitRequest = this.utils.convertFormDataToBillableItemRequest(
            this.state.editViewDataItem,
            statusId,
            itemType,
            amend,
            billableItemIdValueForAdjustment,
            paretRecordIdForAdjustment,
            originalAmountForAdjustment,
            acceptDate,
            approveDate,
            artistConfirmation,
            ""
        );       

        if(billableItemSubmitRequest.updateOrCorrection == false){
            billableItemSubmitRequest.equipmentScheduleQuantity = billableItemSubmitRequest.equipmentQuantity;
            billableItemSubmitRequest.scheduleRate = billableItemSubmitRequest.rate;
        }

        var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];        
        billableItemSubmitRequestArr.push(billableItemSubmitRequest);
        this.setState({showWarningPopup: false});
        //passing object data to the server
        this.editViewService
            .uploadEditAddDataV2(billableItemSubmitRequestArr)
            .then(this.handleFormDataSubmissionSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                    IsShowSuccess: false,
                });
                this.failureMessageVisible();
                this.hideLoadpanel();
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    };

    onSubmitQuery = () => {
        let querySubmissionError: string = "";
        querySubmissionError = this.validationCheckFunction();
        if (querySubmissionError.length > 0) {
            this.setState({
                validationMessage: querySubmissionError,
            });
        } else {
            this.setState({
                validationMessage: "",
                loadPanelVisible: true,
            });
            this.editViewService
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    validationCheckFunction = () => {
        let errorMessage: string = "";
        let commentsField: string = this.state.editViewDataItem.comments.trim();
        if (commentsField.length === 0) {
            errorMessage = "Please Enter the Query Description in the comments.";
        }
        return errorMessage;
    };

    handleQuerySubmissionSuccess = (response: AxiosResponse<any>) => {
        this.hideLoadpanel();
        this.navigateBack();
    };

    handleQuerySubmissionFailure = (error: any) => {
        this.hideLoadpanel();
    };

    navigateBack = () => {
        this.props.history.goBack(); // Return back to the page on a form submission success.
    };

    nonStandardDropDownValueSelection = () => {
        if (this.state.editViewDataItem.fee_Amount_Local_Ccy && this.state.expectedFee) {
            var eventNet: string = this.state.editViewDataItem.fee_Amount_Local_Ccy.toString();
            var expectedFee: string = this.state.expectedFee.toString();
            if (parseFloat(eventNet) == parseFloat(expectedFee)) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        non_Standard: "Standard",
                    },
                });
            } else {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        non_Standard: "Non - Standard",
                    },
                });
            }
        }
    };


    onContentReadyDateBox = (e: any) => {
        this.onClickElementSelection(e);
    }

    onContentReadyNumberBox = (e: any) => {
        this.onClickElementSelection(e);
    }

    onClickElementSelection = (e: any) => {
        e.element.addEventListener('click', function () {
            let inputElement = e.element.querySelector(".dx-texteditor-input");
            inputElement.selectionStart = 0;
            inputElement.selectionEnd = inputElement.value.length;
        });
    }

    //#endregion

    renderPersonnelShiftPanel = () => {
        var maskedValue: string =
            this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel
                ? "0000-0000-0000-0000"
                : "";

        let calendarMin;
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            calendarMin = new Date();
        }
        if (this.state.editViewDataItem.serviceTypeId == ServiceType.Security || this.props.location && this.props.location.state && this.props.location.state.id == 0) {
            if (
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel ||
                (this.state.editViewDataItem.serviceTypeId == ServiceType.Security &&
                    this.state.editViewDataItem.serviceSubTypeId != SubServiceType.DoorSupervisionEquipment) ||
                (this.props.location && this.props.location.state && this.props.location.state.id == 0 &&
                    !this.state.isSubTypeChange &&
                    !this.state.isServiceTypeChange &&
                    this.props.location.state.serviceTypeId == ServiceType.Security)
            ) {
                return (
                    <section className="card card-form my-5">
                        <div className="card-body">
                            <h3 className="card-form__section-heading">Shift</h3>
                            <hr className="horizontal_border_style"></hr>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Service
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTableDynamic
                                            lookupTypeIndex={LookupTypeIndexes.serviceType}
                                            isRoot={true}
                                            parentMappingId={"0"}
                                            onValueChanged={this.handleChangeServiceTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.serviceTypeId}
                                        />
                                        <input
                                            data-testid={LookupTypeIndexes.serviceType}
                                            type="hidden"
                                            name={LookupTypeIndexes.serviceType}
                                            value={
                                                this.state.editViewDataItem.serviceTypeId
                                                    ? this.state.editViewDataItem.serviceTypeId
                                                    : ""
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Status
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTable
                                            lookupTypeIndex={LookupTypeIndexes.statusType}
                                            onValueChanged={this.handleChangeStatusTypeSelect}
                                            signal={this.state.createAdjustment}
                                            disableSignal={ this.editAllowed ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.isDisableStatusDropDown ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.billableStatusLookupId} />
                                        <input
                                            data-testid={LookupTypeIndexes.statusType}
                                            type="hidden"
                                            name={LookupTypeIndexes.statusType}
                                            value={
                                                this.state.editViewDataItem.billableStatusLookupId
                                                    ? this.state.editViewDataItem.billableStatusLookupId
                                                    : 0
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Client
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.clientType}
                                            onValueChanged={this.handleChangeClientTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            isRoot={false}
                                            parentMappingId={""}
                                            displayExpression={"value"}
                                            value={this.state.editViewDataItem.clientId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Provider
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.providerType}
                                            onValueChanged={this.handleChangeProviderTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            isRoot={false}
                                            parentMappingId={""}
                                            displayExpression={"value"}
                                            value={this.state.editViewDataItem.providerId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Date
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DateBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeDate}
                                            value={
                                                this.state.editViewDataItem.date
                                                    ? this.state.editViewDataItem.date
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                            min={calendarMin}
                                        ></DateBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Venue
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            lookupTypeIndex={LookupTypeIndexes.venueType}
                                            onValueChanged={this.handleChangeVenueTypeSelect}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.clientId}
                                            value={this.state.editViewDataItem.venueId}
                                            displayExpression={"value"}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Sub Type
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.serviceTypeId}
                                            displayExpression={"value"}
                                            onValueChanged={this.handleChangeServiceSubTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.serviceSubTypeId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Unscheduled
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            dataSource={this.decisionTypesDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeUnscheduledSelect}
                                            value={
                                                this.state.editViewDataItem.unscheduled == "Yes" ||
                                                    this.state.editViewDataItem.unscheduled == "True"
                                                    ? "Yes"
                                                    : "No"
                                            }
                                            disabled={
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Worker Status
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTable
                                            lookupTypeIndex={LookupTypeIndexes.workerstatus}
                                            onValueChanged={this.handleChangeWorkerStatus}
                                            signal={this.state.createAdjustment}
                                            disableSignal={
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.workerStatusId}
                                            recordType="active"
                                        />
                                        <input
                                            data-testid={LookupTypeIndexes.workerstatus}
                                            type="hidden"
                                            name={LookupTypeIndexes.workerstatus}
                                            value={
                                                this.state.editViewDataItem.workerStatusId
                                                    ? this.state.editViewDataItem.workerStatusId
                                                    : 0
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <LoadIndicator
                                        id="load-indicator"
                                        className="load-indicator--centered"
                                        height={40}
                                        width={40}
                                        visible={this.state.loadIndicatorVisible}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Type/Role
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>

                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTableDynamic
                                            lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.serviceSubTypeId}
                                            onValueChanged={this.handleChangeOfferingsTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.typeId}
                                        />
                                        <input
                                            data-testid={LookupTypeIndexes.offeringsType}
                                            type="hidden"
                                            name={LookupTypeIndexes.offeringsType}
                                            value={
                                                this.state.editViewDataItem.typeId
                                                    ? this.state.editViewDataItem.typeId
                                                    : ""
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-md-auto col-12 col-xl-6 text-left text-xl-right">
                                                Scheduled Start
                                            </label>
                                            <sup className="card-form__mandatory-symbol-Scheduled-Start">*</sup>
                                            <div className="col-12 col-xl-6 pl-2">
                                                <DateBox
                                                    type="time"
                                                    interval={15}
                                                    placeholder="00:00"
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    disabled={
                                                        this.state.createAdjustment ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.IsLockTimeFields ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                    }
                                                    onValueChanged={this.handleChangeShiftStart}
                                                    value={
                                                        this.state.editViewDataItem.shift_Start
                                                            ? this.state.editViewDataItem.shift_Start
                                                            : undefined
                                                    }
                                                    onContentReady={this.onContentReadyDateBox}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                End
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <DateBox
                                                    type="time"
                                                    interval={15}
                                                    placeholder="00:00"
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    disabled={
                                                        this.state.createAdjustment ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.IsLockTimeFields ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                    }
                                                    onValueChanged={this.handleChangeShiftEnd}
                                                    value={
                                                        this.state.editViewDataItem.shift_End
                                                            ? this.state.editViewDataItem.shift_End
                                                            : undefined
                                                    }
                                                    onContentReady={this.onContentReadyDateBox}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Rate
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>

                                    <div className="col-12 col-xl-9 pl-0">
                                        <NumberBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            format={{
                                                type: "currency",
                                                precision: 2,
                                                currency: "GBP",
                                            }}
                                            onValueChanged={this.handleChangeRate}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.rate_Decimal
                                                    : this.state.editViewDataItem.rate_Decimal
                                            }
                                            onContentReady={this.onContentReadyNumberBox}
                                            step={0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Name
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeName}
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.name
                                                    : this.state.editViewDataItem.name
                                            }
                                        ></TextBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Check In
                                            </label>
                                            <div className="col-12 col-xl-6 pl-2">
                                                <DateBox
                                                    type="time"
                                                    placeholder="00:00"
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    disabled={true}
                                                    value={
                                                        this.state.editViewDataItem.check_In
                                                            ? this.state.editViewDataItem.check_In
                                                            : undefined
                                                    }
                                                ></DateBox>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Check Out
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <DateBox
                                                    type="time"
                                                    placeholder="00:00"
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    disabled={true}
                                                    value={
                                                        this.state.editViewDataItem.check_Out
                                                            ? this.state.editViewDataItem.check_Out
                                                            : undefined
                                                    }
                                                ></DateBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Chargeable Time
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <NumberBox
                                            disabled={true}
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.billable_Minutes
                                                    : this.state.editViewDataItem.billable_Minutes
                                            }
                                        ></NumberBox>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-md-auto col-xl-3 text-left text-xl-right">
                                        Licence No.
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Licence-No">*</sup>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            mask={maskedValue}
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeIdentificationNumber}
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.identification_Number
                                                    : this.state.editViewDataItem.identification_Number
                                            }
                                        ></TextBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-md-auto col-xl-6 text-left text-xl-right">
                                                Approved Start
                                            </label>
                                            <sup className="card-form__mandatory-symbol-Approved-Start">*</sup>
                                            <div className="col-12 col-xl-6 pl-2">
                                                <DateBox
                                                    type="time"
                                                    interval={15}
                                                    placeholder="00:00"
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    onValueChanged={this.handleChangeBillableStart}
                                                    valueChangeEvent="keyup"
                                                    value={
                                                        this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust &&
                                                            this.state.editViewVirtualDataItem.billable_Start
                                                            ? this.state.editViewVirtualDataItem.billable_Start
                                                            : this.state.editViewDataItem.billable_Start
                                                                ? this.state.editViewDataItem.billable_Start
                                                                : undefined
                                                    }
                                                    disabled={
                                                        this.state.IsLockBillableFields ||
                                                        this.state.IsLockTimeFields ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                    }
                                                    onContentReady={this.onContentReadyDateBox}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                End
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <DateBox
                                                    type="time"
                                                    interval={15}
                                                    placeholder="00:00"
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    onValueChanged={this.handleChangeBillableFinish}
                                                    valueChangeEvent="keyup"
                                                    value={
                                                        this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust &&
                                                            this.state.editViewVirtualDataItem.billable_Finish
                                                            ? this.state.editViewVirtualDataItem.billable_Finish
                                                            : this.state.editViewDataItem.billable_Finish
                                                                ? this.state.editViewDataItem.billable_Finish
                                                                : undefined
                                                    }
                                                    disabled={
                                                        this.state.IsLockBillableFields ||
                                                        this.state.IsLockTimeFields ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                    }
                                                    onContentReady={this.onContentReadyDateBox}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-md-auto col-md col-xl-3 text-left text-xl-right">
                                        Provider Net
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Provider-Net">*</sup>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <NumberBox
                                            format={{
                                                type: "currency",
                                                precision: 2,
                                                currency: "GBP",
                                            }}
                                            onValueChanged={this.handleChangeProviderLocalCurrancyAmount}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy}
                                            disabled={
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            step={0}
                                            onContentReady={this.onContentReadyNumberBox}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Cover
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.state.IsLockTimeFields ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            dataSource={this.decisionTypesDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeCover}
                                            value={
                                                this.state.editViewDataItem.cover == "" &&
                                                    this.props.location && this.props.location.state &&
                                                    this.props.location.state.id == 0
                                                    ? "No"
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                        ? this.state.editViewVirtualDataItem.cover
                                                        : this.state.editViewDataItem.cover
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Absent
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.state.IsLockTimeFields ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            dataSource={this.absentTypeDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeAbsent}
                                            value={
                                                this.state.editViewDataItem.absent == "" && this.props.location &&
                                                    this.props.location.state &&
                                                    this.props.location.state.id == 0
                                                    ? "Present"
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                        ? this.state.editViewVirtualDataItem.absent
                                                        : this.state.editViewDataItem.absent
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Charge
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            disabled={
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            dataSource={this.chargeTypesDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeNoChange}
                                            value={
                                                this.state.editViewDataItem.noCharge == "" &&
                                                    this.props.location && this.props.location.state &&
                                                    this.props.location.state.id == 0
                                                    ? "Applied"
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                        ? this.state.editViewVirtualDataItem.noCharge
                                                        : this.state.editViewDataItem.noCharge
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-1 text-left text-xl-right">
                                        External Comments (Public)
                                    </label>
                                    <div className="col-12 col-xl-11 pl-0">
                                        <TextArea
                                            height={100}
                                            value={this.state.editViewDataItem.comments}
                                            onValueChanged={this.handleChangeComments}
                                            disabled={this.state.readOnlyView}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-md-auto col-xl-1 text-left text-xl-right">
                                        Internal Comments (Private)
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Internal-Comments">*</sup>
                                    <div className="col-12 col-xl-11 pl-0">
                                        <TextArea
                                            height={100}
                                            value={this.state.editViewDataItem.internalComments}
                                            onValueChanged={this.handleChangeInternalComments}
                                            disabled={this.state.readOnlyView}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            }
        }
    };

    renderEquipmentShiftPanel = () => {
        var maskedValue: string =
            this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel
                ? "0000-0000-0000-0000"
                : "";
        let calendarMin;
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            calendarMin = new Date();
        }
        if (
            this.state.editViewDataItem.serviceTypeId == ServiceType.Security &&
            this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment
        ) {
            if (
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment ||
                this.props.location && this.props.location.state && this.props.location.state.service == "Security - Door Supervision Equipment" ||
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment
            ) {
                return (
                    <section className="card card-form my-5">
                        <div className="card-body">
                            <h3 className="card-form__section-heading">Shift</h3>
                            <hr className="horizontal_border_style"></hr>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Service
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTableDynamic
                                            lookupTypeIndex={LookupTypeIndexes.serviceType}
                                            isRoot={true}
                                            parentMappingId={"0"}
                                            onValueChanged={this.handleChangeServiceTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.serviceTypeId}
                                        />
                                        <input
                                            data-testid={LookupTypeIndexes.serviceType}
                                            type="hidden"
                                            name={LookupTypeIndexes.serviceType}
                                            value={
                                                this.state.editViewDataItem.serviceTypeId
                                                    ? this.state.editViewDataItem.serviceTypeId
                                                    : ""
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Status
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTable
                                            lookupTypeIndex={LookupTypeIndexes.statusType}
                                            onValueChanged={this.handleChangeStatusTypeSelect}
                                            signal={this.state.createAdjustment}
                                            disableSignal={ this.editAllowed || 
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.isDisableStatusDropDown ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.billableStatusLookupId} />
                                        <input
                                            data-testid={LookupTypeIndexes.statusType}
                                            type="hidden"
                                            name={LookupTypeIndexes.statusType}
                                            value={
                                                this.state.editViewDataItem.billableStatusLookupId
                                                    ? this.state.editViewDataItem.billableStatusLookupId
                                                    : 0
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Client
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.clientType}
                                            onValueChanged={this.handleChangeClientTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            isRoot={false}
                                            parentMappingId={""}
                                            displayExpression={"value"}
                                            value={this.state.editViewDataItem.clientId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Provider
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.providerType}
                                            onValueChanged={this.handleChangeProviderTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            isRoot={false}
                                            parentMappingId={""}
                                            displayExpression={"value"}
                                            value={this.state.editViewDataItem.providerId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                        Date From
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Date-From">*</sup>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DateBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeDate}
                                            value={
                                                this.state.editViewDataItem.date
                                                    ? this.state.editViewDataItem.date
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                            min={calendarMin}
                                        ></DateBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Venue
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            lookupTypeIndex={LookupTypeIndexes.venueType}
                                            onValueChanged={this.handleChangeVenueTypeSelect}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.clientId}
                                            value={this.state.editViewDataItem.venueId}
                                            displayExpression={"value"}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Sub Type
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.serviceTypeId}
                                            displayExpression={"value"}
                                            onValueChanged={this.handleChangeServiceSubTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.serviceSubTypeId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Date To
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DateBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.IsLockQuantityFields ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeDateTo}
                                            value={
                                                this.state.editViewDataItem.dateTo
                                                    ? this.state.editViewDataItem.dateTo
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                            onContentReady={this.onContentReadyDateBox}
                                        ></DateBox>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Role/Type
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTableDynamic
                                            lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.serviceSubTypeId}
                                            onValueChanged={this.handleChangeOfferingsTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                // this.state.typeLUDisablement ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.typeId}
                                        />
                                        <input
                                            data-testid={LookupTypeIndexes.offeringsType}
                                            type="hidden"
                                            name={LookupTypeIndexes.offeringsType}
                                            value={
                                                this.state.editViewDataItem.typeId
                                                    ? this.state.editViewDataItem.typeId
                                                    : ""
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Serial No.
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            mask={maskedValue}
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeIdentificationNumber}
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.identification_Number
                                                    : this.state.editViewDataItem.identification_Number
                                            }
                                        ></TextBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Charge
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            disabled={
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            dataSource={this.chargeTypesDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeNoChange}
                                            value={
                                                this.state.editViewDataItem.noCharge == "" &&
                                                    this.props.location && this.props.location.state &&
                                                    this.props.location.state.id == 0
                                                    ? "Applied"
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                        ? this.state.editViewVirtualDataItem.noCharge
                                                        : this.state.editViewDataItem.noCharge
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="w-50 mx-auto">
                                    <LoadIndicator
                                        id="load-indicator"
                                        className="load-indicator"
                                        height={40}
                                        width={40}
                                        visible={this.state.loadIndicatorVisible}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Quantity
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <NumberBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.IsLockQuantityFields ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeQuantity}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.equipmentQuantity
                                                    : this.state.editViewDataItem.equipmentQuantity
                                            }
                                            onContentReady={this.onContentReadyNumberBox}
                                            step={0}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Rate
                                        <sup className="card-form__mandatory-symbol">*</sup>


                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <NumberBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            format={{
                                                type: "currency",
                                                precision: 2,
                                                currency: "GBP",
                                            }}
                                            onValueChanged={this.handleChangeRate}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.rate_Decimal
                                                    : this.state.editViewDataItem.rate_Decimal
                                            }
                                            step={0}
                                            onContentReady={this.onContentReadyNumberBox}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                        Provider Net
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Provider-Net">*</sup>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <NumberBox
                                            format={{
                                                type: "currency",
                                                precision: 2,
                                                currency: "GBP",
                                            }}
                                            onValueChanged={this.handleChangeProviderLocalCurrancyAmount}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy}
                                            disabled={
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onContentReady={this.onContentReadyNumberBox}
                                            step={0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-1 text-left text-xl-right">
                                        External Comments (Public)
                                    </label>
                                    <div className="col-12 col-xl-11 pl-0">
                                        <TextArea
                                            height={100}
                                            value={this.state.editViewDataItem.comments}
                                            onValueChanged={this.handleChangeComments}
                                            disabled={this.state.readOnlyView}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-md-auto col-xl-1 text-left text-xl-right">
                                        Internal Comments (Private)
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Internal-Comments">*</sup>
                                    <div className="col-12 col-xl-11 pl-0">
                                        <TextArea
                                            height={100}
                                            value={this.state.editViewDataItem.internalComments}
                                            onValueChanged={this.handleChangeInternalComments}
                                            disabled={this.state.readOnlyView}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            }
        }
    };

    renderWeeklyCleaningShiftPanel = () => {
        let calendarMin;
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            calendarMin = new Date();
        }
        let isDisableOfferingsType: boolean = true;
        let showOtherDesc: boolean = false;
        if ((this.state.offeringsTypeLookup && this.state.offeringsTypeLookup.length > 0) && this.state.editViewDataItem.serviceSubTypeId) {
            isDisableOfferingsType = false;
        }                    
        if (this.state.editViewDataItem.serviceTypeId == ServiceType.WindowCleaning && this.state.editViewDataItem.serviceSubTypeId == "108" && this.state.editViewDataItem.typeId == "516") {
            showOtherDesc = true;
        }
        if (
            this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning ||
            this.props.location && this.props.location.state && this.props.location.state.id == 0
        ) {
            if (
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning ||
                (this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning &&
                    this.state.editViewDataItem.serviceSubTypeId != SubServiceType.AdditionalCleaning) ||
                (this.props.location && this.props.location.state && this.props.location.state.id == 0 &&
                    !this.state.isSubTypeChange &&
                    !this.state.isServiceTypeChange &&
                    this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.ContractCleaning && 
                    this.props.location.state.serviceSubTypeId == SubServiceType.WeeklyCleaning) ||
                this.props.location && this.props.location.state && this.props.location.state.service == "Contract Cleaning - Weekly Cleaning" ||
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning
            ) {
                return (
                    <section className="card card-form my-5">
                        <div className="card-body">
                            <h3 className="card-form__section-heading">Shift</h3>
                            <hr className="horizontal_border_style"></hr>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Service
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTableDynamic
                                            lookupTypeIndex={LookupTypeIndexes.serviceType}
                                            isRoot={true}
                                            parentMappingId={"0"}
                                            onValueChanged={this.handleChangeServiceTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.serviceTypeId}
                                        />
                                        <input
                                            data-testid={LookupTypeIndexes.serviceType}
                                            type="hidden"
                                            name={LookupTypeIndexes.serviceType}
                                            value={
                                                this.state.editViewDataItem.serviceTypeId
                                                    ? this.state.editViewDataItem.serviceTypeId
                                                    : ""
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Status
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTable
                                            lookupTypeIndex={LookupTypeIndexes.statusType}
                                            onValueChanged={this.handleChangeStatusTypeSelect}
                                            signal={this.state.createAdjustment}
                                            disableSignal={ this.editAllowed || 
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.isDisableStatusDropDown ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.billableStatusLookupId} />
                                        <input
                                            data-testid={LookupTypeIndexes.statusType}
                                            type="hidden"
                                            name={LookupTypeIndexes.statusType}
                                            value={
                                                this.state.editViewDataItem.billableStatusLookupId
                                                    ? this.state.editViewDataItem.billableStatusLookupId
                                                    : 0
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Client
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.clientType}
                                            onValueChanged={this.handleChangeClientTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            isRoot={false}
                                            parentMappingId={""}
                                            displayExpression={"value"}
                                            value={this.state.editViewDataItem.clientId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Provider
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.providerType}
                                            onValueChanged={this.handleChangeProviderTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            isRoot={false}
                                            parentMappingId={""}
                                            displayExpression={"value"}
                                            value={this.state.editViewDataItem.providerId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                        Date From
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Date-From">*</sup>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DateBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeDate}
                                            value={
                                                this.state.editViewDataItem.date
                                                    ? this.state.editViewDataItem.date
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                            min={calendarMin}
                                        ></DateBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Venue
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            lookupTypeIndex={LookupTypeIndexes.venueType}
                                            onValueChanged={this.handleChangeVenueTypeSelect}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.clientId}
                                            value={this.state.editViewDataItem.venueId}
                                            displayExpression={"value"}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Sub Type
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.serviceTypeId}
                                            displayExpression={"value"}
                                            onValueChanged={this.handleChangeServiceSubTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.serviceSubTypeId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Date To
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DateBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.IsLockQuantityFields ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeDateTo}
                                            value={
                                                this.state.editViewDataItem.dateTo
                                                    ? this.state.editViewDataItem.dateTo
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                        ></DateBox>
                                    </div>
                                </div>                                
                            </div>

                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Type
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            dataSource={this.state.offeringsTypeLookup}
                                            displayExpr={"value"}
                                            valueExpr={"id"}
                                            onValueChanged={this.handleChangeOfferingsTypeSelect}
                                            value={this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                ? this.state.editViewVirtualDataItem.typeId
                                                : this.state.editViewDataItem.typeId}
                                            disabled={this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend || isDisableOfferingsType ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)}
                                        />
                                    </div>
                                </div>   
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3" hidden={showOtherDesc}>
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Description
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeOtherDescription}
                                            value={this.state.editViewDataItem.otherDescription}
                                        ></TextBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Pay Request Description
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.paymentRequest?.description}
                                            readOnly={true}
                                        ></TextBox>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="w-50 mx-auto">
                                    <LoadIndicator
                                        id="load-indicator"
                                        className="load-indicator"
                                        height={40}
                                        width={40}
                                        visible={this.state.loadIndicatorVisible}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Hours
                                                <sup className="card-form__mandatory-symbol">*</sup>
                                            </label>
                                            <div className="col-12 col-xl-6 pl-2">
                                                <NumberBox
                                                    disabled={
                                                        this.state.createAdjustment ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                    }
                                                    onValueChanged={this.handleChangeHours}
                                                    valueChangeEvent="keyup"
                                                    value={
                                                        this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                            ? this.state.virtualHours
                                                            : this.state.hours
                                                    }
                                                    onContentReady={this.onContentReadyNumberBox}
                                                    step={0}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Min
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <SelectBox
                                                    dataSource={minuteLookup}
                                                    displayExpr="value"
                                                    valueExpr="id"
                                                    onValueChanged={this.handleChangeMinutes}
                                                    value={
                                                        this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                            ? this.state.virtualMinutes
                                                            : this.state.minutes
                                                    }
                                                    disabled={
                                                        this.state.createAdjustment ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Rate
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <NumberBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            format={{
                                                type: "currency",
                                                precision: 2,
                                                currency: "GBP",
                                            }}
                                            onValueChanged={this.handleChangeRate}
                                            valueChangeEvent="keyup"
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.rate_Decimal
                                                    : this.state.editViewDataItem.rate_Decimal
                                            }
                                            onContentReady={this.onContentReadyNumberBox}
                                            step={0}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                        Provider Net
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Provider-Net">*</sup>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <NumberBox
                                            format={{
                                                type: "currency",
                                                precision: 2,
                                                currency: "GBP",
                                            }}
                                            onValueChanged={this.handleChangeProviderLocalCurrancyAmount}
                                            valueChangeEvent="keyup"
                                            value={this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy}
                                            disabled={
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onContentReady={this.onContentReadyNumberBox}
                                            step={0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Charge
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            disabled={
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            dataSource={this.chargeTypesDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeNoChange}
                                            value={
                                                this.state.editViewDataItem.noCharge == "" && this.props.location &&
                                                    this.props.location.state &&
                                                    this.props.location.state.id == 0
                                                    ? "Applied"
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                        ? this.state.editViewVirtualDataItem.noCharge
                                                        : this.state.editViewDataItem.noCharge
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Cleaner Confirmed
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state &&
                                                    this.props.location.state.isAdjust)
                                            }
                                            dataSource={this.decisionTypesDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeArtistConfirmation}
                                            value={
                                                this.state.editViewDataItem.shiftCompleteAcknowledgment == true
                                                    ? "Yes"
                                                    : this.props.location && this.props.location.state && this.props.location.state.id != 0 &&
                                                        this.state.editViewDataItem.shiftCompleteAcknowledgment ==
                                                        false
                                                        ? "No"
                                                        : this.state.editViewDataItem.shiftCompleteAcknowledgment
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Confirmed Date
                                    </label>
                                    <div className="col-12 col-xl-5 pl-0">
                                        <DateBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state &&
                                                        this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeArtistConfirmedDate}
                                            value={
                                                this.state.editViewDataItem.shiftCompleteAcknowledgmentDateTime
                                                    ? this.state.editViewDataItem
                                                        .shiftCompleteAcknowledgmentDateTime
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                            min={calendarMin}
                                        ></DateBox>
                                    </div>
                                    
                                    <div className="col-12 col-xl-4 pl-0">
                                        <DateBox
                                            type="time"
                                            interval={15}
                                            useMaskBehavior={true}
                                            displayFormat={"HH:mm"}
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state &&
                                                    this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeArtistConfirmedTime}
                                            value={
                                                this.state.editViewDataItem.shiftCompleteAcknowledgmentTime
                                                    ? this.state.editViewDataItem
                                                        .shiftCompleteAcknowledgmentTime
                                                    : undefined
                                            }
                                            onContentReady={this.onContentReadyDateBox}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr className="horizontal_border_style"></hr>

                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Mon
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <TextBox
                                                    readOnly={true}
                                                    value={
                                                        this.state.editViewDataItem.contractHours
                                                            ? this.state.editViewDataItem.contractHours.monday
                                                            : undefined
                                                    }
                                                ></TextBox>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Tue
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <TextBox
                                                    readOnly={true}
                                                    value={
                                                        this.state.editViewDataItem.contractHours
                                                            ? this.state.editViewDataItem.contractHours.tuesday
                                                            : undefined
                                                    }
                                                ></TextBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Wed
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <TextBox
                                                    readOnly={true}
                                                    value={
                                                        this.state.editViewDataItem.contractHours
                                                            ? this.state.editViewDataItem.contractHours.wednesday
                                                            : undefined
                                                    }
                                                ></TextBox>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Thu
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <TextBox
                                                    readOnly={true}
                                                    value={
                                                        this.state.editViewDataItem.contractHours
                                                            ? this.state.editViewDataItem.contractHours.thursday
                                                            : undefined
                                                    }
                                                ></TextBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Fri
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <TextBox
                                                    readOnly={true}
                                                    value={
                                                        this.state.editViewDataItem.contractHours
                                                            ? this.state.editViewDataItem.contractHours.friday
                                                            : undefined
                                                    }
                                                ></TextBox>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Sat
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <TextBox
                                                    readOnly={true}
                                                    value={
                                                        this.state.editViewDataItem.contractHours
                                                            ? this.state.editViewDataItem.contractHours.saturday
                                                            : undefined
                                                    }
                                                ></TextBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 pr-0"> </div>
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Sun
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <TextBox
                                                    readOnly={true}
                                                    value={
                                                        this.state.editViewDataItem.contractHours
                                                            ? this.state.editViewDataItem.contractHours.sunday
                                                            : undefined
                                                    }
                                                ></TextBox>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Weekly Hours
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <TextBox
                                                    readOnly={true}
                                                    value={
                                                        this.state.editViewDataItem.contractHours
                                                            ? this.state.editViewDataItem.contractHours.totalHours
                                                            : undefined
                                                    }
                                                ></TextBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 pr-0"></div>
                            </div>
                            <hr className="horizontal_border_style"></hr>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-1 text-left text-xl-right">
                                        External Comments (Public)
                                    </label>
                                    <div className="col-12 col-xl-11 pl-0">
                                        <TextArea
                                            height={100}
                                            value={this.state.editViewDataItem.comments}
                                            onValueChanged={this.handleChangeComments}
                                            disabled={this.state.readOnlyView}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-md-auto col-xl-1 text-left text-xl-right">
                                        Internal Comments (Private)
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Internal-Comments">*</sup>
                                    <div className="col-12 col-xl-11 pl-0">
                                        <TextArea
                                            height={100}
                                            value={this.state.editViewDataItem.internalComments}
                                            onValueChanged={this.handleChangeInternalComments}
                                            disabled={this.state.readOnlyView}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            }
        }
    };

    renderAdditionalCleaningShiftPanel = () => {
        let calendarMin;
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            calendarMin = new Date();
        }
        let labelName: string = "";
        if (this.state.editViewDataItem)
            if (
                (this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning &&
                    this.state.editViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning) ||
                (this.state.editViewDataItem.serviceTypeId == ServiceType.Gardening ||
                    this.state.editViewDataItem.serviceTypeId == ServiceType.Maintenance ||
                    this.state.editViewDataItem.serviceTypeId == ServiceType.Miscelleanous ||
                    this.state.editViewDataItem.serviceTypeId == ServiceType.WindowCleaning ||
                    this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.Gardening ||
                    this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.Maintenance ||
                    this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.Miscelleanous ||
                    this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.WindowCleaning)
            ) {
                if (
                    this.state.editViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning ||
                    this.props.location && this.props.location.state && this.props.location.state.service == "Contract Cleaning - Additional Cleaning" ||
                    (this.state.editViewDataItem.serviceTypeId == ServiceType.Gardening ||
                        this.state.editViewDataItem.serviceTypeId == ServiceType.Maintenance ||
                        this.state.editViewDataItem.serviceTypeId == ServiceType.Miscelleanous ||
                        this.state.editViewDataItem.serviceTypeId == ServiceType.WindowCleaning ||
                        this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.Gardening ||
                        this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.Maintenance ||
                        this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.Miscelleanous ||
                        this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.WindowCleaning)
                ) {
                    
                    let isDisableOfferingsType: boolean = true;
                    let showOtherDesc: boolean = false;
                    let showType: boolean = true;
                    let showMisMainQuantity: boolean = false;
                    if (this.state.editViewDataItem.serviceTypeId == ServiceType.Maintenance || this.state.editViewDataItem.serviceTypeId == ServiceType.Miscelleanous) {
                        showType = false;
                    }
                    if ((this.state.offeringsTypeLookup && this.state.offeringsTypeLookup.length > 0) && this.state.editViewDataItem.serviceSubTypeId) {
                        isDisableOfferingsType = false;
                    }                    
                    if ((this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning && this.state.editViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning && this.state.editViewDataItem.typeId == "227") ||
                        (this.state.editViewDataItem.serviceTypeId == ServiceType.WindowCleaning && this.state.editViewDataItem.serviceSubTypeId == "108" && this.state.editViewDataItem.typeId == "516") || 
                        (this.state.editViewDataItem.serviceTypeId == ServiceType.Gardening && this.state.editViewDataItem.serviceSubTypeId == "106" && this.state.editViewDataItem.typeId == "405")) {
                            showOtherDesc = true;
                    }
                    if (this.state.editViewDataItem.serviceTypeId == ServiceType.Maintenance || this.state.editViewDataItem.serviceTypeId == ServiceType.Miscelleanous) {
                        showMisMainQuantity = true;
                    }
                    return (
                        <section className="card card-form my-5">
                            <div className="card-body">
                                <h3 className="card-form__section-heading">Shift</h3>
                                <hr className="horizontal_border_style"></hr>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Service
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <DropDownFromLookupTableDynamic
                                                lookupTypeIndex={LookupTypeIndexes.serviceType}
                                                isRoot={true}
                                                parentMappingId={"0"}
                                                onValueChanged={this.handleChangeServiceTypeSelect}
                                                isDisabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                value={this.state.editViewDataItem.serviceTypeId}
                                            />
                                            <input
                                                data-testid={LookupTypeIndexes.serviceType}
                                                type="hidden"
                                                name={LookupTypeIndexes.serviceType}
                                                value={
                                                    this.state.editViewDataItem.serviceTypeId
                                                        ? this.state.editViewDataItem.serviceTypeId
                                                        : ""
                                                }
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Status
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <DropDownFromLookupTable
                                                lookupTypeIndex={LookupTypeIndexes.statusType}
                                                onValueChanged={this.handleChangeStatusTypeSelect}
                                                signal={this.state.createAdjustment}
                                                disableSignal={ this.editAllowed || 
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.isDisableStatusDropDown ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                value={this.state.editViewDataItem.billableStatusLookupId} />
                                            <input
                                                data-testid={LookupTypeIndexes.statusType}
                                                type="hidden"
                                                name={LookupTypeIndexes.statusType}
                                                value={
                                                    this.state.editViewDataItem.billableStatusLookupId
                                                        ? this.state.editViewDataItem.billableStatusLookupId
                                                        : 0
                                                }
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Client
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <LookupTypeListDynamic
                                                lookupTypeIndex={LookupTypeIndexes.clientType}
                                                onValueChanged={this.handleChangeClientTypeSelect}
                                                isDisabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                isRoot={false}
                                                parentMappingId={""}
                                                displayExpression={"value"}
                                                value={this.state.editViewDataItem.clientId}
                                                recordType={
                                                    (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                        ? "all"
                                                        : "active"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Provider
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <LookupTypeListDynamic
                                                lookupTypeIndex={LookupTypeIndexes.providerType}
                                                onValueChanged={this.handleChangeProviderTypeSelect}
                                                isDisabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                isRoot={false}
                                                parentMappingId={""}
                                                displayExpression={"value"}
                                                value={this.state.editViewDataItem.providerId}
                                                recordType={
                                                    (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                        ? "all"
                                                        : "active"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                            Date From
                                        </label>
                                        <sup className="card-form__mandatory-symbol-Date-From">*</sup>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <DateBox
                                                disabled={
                                                    this.state.createAdjustment
                                                        ? this.state.createAdjustment
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeDate}
                                                value={
                                                    this.state.editViewDataItem.date
                                                        ? this.state.editViewDataItem.date
                                                        : undefined
                                                }
                                                useMaskBehavior={true}
                                                displayFormat="dd/MM/yyyy"
                                                min={calendarMin}
                                            ></DateBox>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Venue
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <LookupTypeListDynamic
                                                isDisabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                lookupTypeIndex={LookupTypeIndexes.venueType}
                                                onValueChanged={this.handleChangeVenueTypeSelect}
                                                isRoot={false}
                                                parentMappingId={this.state.editViewDataItem.clientId}
                                                value={this.state.editViewDataItem.venueId}
                                                displayExpression={"value"}
                                                recordType={
                                                    (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                        ? "all"
                                                        : "active"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Sub Type
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <LookupTypeListDynamic
                                                lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                                isRoot={false}
                                                parentMappingId={this.state.editViewDataItem.serviceTypeId}
                                                displayExpression={"value"}
                                                onValueChanged={this.handleChangeServiceSubTypeSelect}
                                                isDisabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                value={this.state.editViewDataItem.serviceSubTypeId}
                                                recordType={
                                                    (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                        ? "all"
                                                        : "active"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Date To
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <DateBox
                                                disabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.IsLockQuantityFields ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeDateTo}
                                                value={
                                                    this.state.editViewDataItem.dateTo
                                                        ? this.state.editViewDataItem.dateTo
                                                        : undefined
                                                }
                                                useMaskBehavior={true}
                                                displayFormat="dd/MM/yyyy"
                                            ></DateBox>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3" hidden={!showMisMainQuantity}>
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Quantity
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <NumberBox
                                                disabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeQuantity}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                        ? this.state.editViewVirtualDataItem.equipmentQuantity
                                                        : this.state.editViewDataItem.equipmentQuantity
                                                }
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3" hidden={!showType}>
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Type
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <SelectBox
                                                dataSource={this.state.offeringsTypeLookup}
                                                displayExpr={"value"}
                                                valueExpr={"id"}
                                                onValueChanged={this.handleChangeOfferingsTypeSelect}
                                                value={this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.typeId
                                                    : this.state.editViewDataItem.typeId}
                                                disabled={this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend || isDisableOfferingsType ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)}
                                            />
                                        </div>
                                    </div>                                    
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3"  hidden={!showType}>
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Description
                                            <sup className="card-form__mandatory-symbol" hidden={!showOtherDesc}>*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox
                                                disabled={
                                                    this.state.createAdjustment
                                                        ? this.state.createAdjustment
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeOtherDescription}
                                                value={this.state.editViewDataItem.otherDescription}
                                            ></TextBox>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3"  hidden={showMisMainQuantity}>
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Pay Request Description
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox
                                                disabled={
                                                    this.state.createAdjustment
                                                        ? this.state.createAdjustment
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                readOnly={true}
                                                value={this.state.editViewDataItem.paymentRequest?.description}
                                            ></TextBox>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="w-50 mx-auto">
                                        <LoadIndicator
                                            id="load-indicator"
                                            className="load-indicator"
                                            height={40}
                                            width={40}
                                            visible={this.state.loadIndicatorVisible}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3"  hidden={this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning || this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment || 
                                            this.state.editViewDataItem.serviceTypeId == ServiceType.WindowCleaning || this.state.editViewDataItem.serviceTypeId == ServiceType.Gardening}>
                                        <label className="card-form__label col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                            Description
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox
                                                disabled={
                                                    this.state.createAdjustment
                                                        ? this.state.createAdjustment
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeOtherDescription}
                                                value={this.state.editViewDataItem.otherDescription}
                                            ></TextBox>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3" hidden={showMisMainQuantity}>
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Quantity
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <NumberBox
                                                disabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeQuantity}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                        ? this.state.editViewVirtualDataItem.equipmentQuantity
                                                        : this.state.editViewDataItem.equipmentQuantity
                                                }
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Rate
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <NumberBox
                                                disabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                format={{
                                                    type: "currency",
                                                    precision: 2,
                                                    currency: "GBP",
                                                }}
                                                onValueChanged={this.handleChangeRate}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                        ? this.state.editViewVirtualDataItem.rate_Decimal
                                                        : this.state.editViewDataItem.rate_Decimal
                                                }
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-md-auto col-md col-xl-3 text-left text-xl-right">
                                            Provider Net
                                        </label>
                                        <sup className="card-form__mandatory-symbol-Provider-Net">*</sup>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <NumberBox
                                                format={{
                                                    type: "currency",
                                                    precision: 2,
                                                    currency: "GBP",
                                                }}
                                                onValueChanged={this.handleChangeProviderLocalCurrancyAmount}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy}
                                                disabled={
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                            Request Approver
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox
                                                disabled={
                                                    this.state.createAdjustment
                                                        ? this.state.createAdjustment
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeRequestApprover}
                                                value={this.state.editViewDataItem.requestApprover}
                                            ></TextBox>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label-requested-on col-12 col-xl-3 text-left text-xl-right">
                                            Requested On
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <DateBox
                                                disabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.IsLockQuantityFields ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeRequestedOn}
                                                value={
                                                    this.state.editViewDataItem.requestDate
                                                        ? this.state.editViewDataItem.requestDate
                                                        : undefined
                                                }
                                                useMaskBehavior={true}
                                                displayFormat="dd/MM/yyyy"
                                            ></DateBox>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Charge
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <SelectBox
                                                disabled={
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                dataSource={this.chargeTypesDataSource}
                                                displayExpr="value"
                                                valueExpr="value"
                                                onValueChanged={this.handleChangeNoChange}
                                                value={
                                                    this.state.editViewDataItem.noCharge == "" &&
                                                        this.props.location && this.props.location.state &&
                                                        this.props.location.state.id == 0
                                                        ? "Applied"
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                            ? this.state.editViewVirtualDataItem.noCharge
                                                            : this.state.editViewDataItem.noCharge
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Confirmed?
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <SelectBox
                                                disabled={
                                                    this.state.createAdjustment ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state &&
                                                        this.props.location.state.isAdjust)
                                                }
                                                dataSource={this.decisionTypesDataSource}
                                                displayExpr="value"
                                                valueExpr="value"
                                                onValueChanged={this.handleChangeArtistConfirmation}
                                                value={
                                                    this.state.editViewDataItem.shiftCompleteAcknowledgment == true
                                                        ? "Yes"
                                                        : this.props.location && this.props.location.state && this.props.location.state.id != 0 &&
                                                            this.state.editViewDataItem.shiftCompleteAcknowledgment ==
                                                            false
                                                            ? "No"
                                                            : this.state.editViewDataItem.shiftCompleteAcknowledgment
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Confirmed Date
                                        </label>
                                        <div className="col-12 col-xl-5 pl-0">
                                            <DateBox
                                                disabled={
                                                    this.state.createAdjustment
                                                        ? this.state.createAdjustment
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeArtistConfirmedDate}
                                                value={
                                                    this.state.editViewDataItem.shiftCompleteAcknowledgmentDateTime
                                                        ? this.state.editViewDataItem
                                                            .shiftCompleteAcknowledgmentDateTime
                                                        : undefined
                                                }
                                                useMaskBehavior={true}
                                                displayFormat="dd/MM/yyyy"
                                                min={calendarMin}
                                            ></DateBox>
                                        </div>
                                        <div className="col-12 col-xl-4 pl-0">
                                            <DateBox
                                                type="time"
                                                interval={15}
                                                useMaskBehavior={true}
                                                displayFormat={"HH:mm"}
                                                disabled={
                                                    this.state.createAdjustment ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state &&
                                                        this.props.location.state.isAdjust)
                                                }
                                                onValueChanged={this.handleChangeArtistConfirmedTime}
                                                value={
                                                    this.state.editViewDataItem.shiftCompleteAcknowledgmentTime
                                                        ? this.state.editViewDataItem
                                                            .shiftCompleteAcknowledgmentTime
                                                        : undefined
                                                }
                                                onContentReady={this.onContentReadyDateBox}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3"  hidden={!showMisMainQuantity}>
                                        <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                            Pay Request Description
                                        </label>
                                        <div className="col-12 col-xl-9 pl-0">
                                            <TextBox
                                                disabled={
                                                    this.state.createAdjustment
                                                        ? this.state.createAdjustment
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                }
                                                readOnly={true}
                                                value={this.state.editViewDataItem.paymentRequest?.description}
                                            ></TextBox>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-1 text-left text-xl-right">
                                            External Comments (Public)
                                        </label>
                                        <div className="col-12 col-xl-11 pl-0">
                                            <TextArea
                                                height={100}
                                                value={this.state.editViewDataItem.comments}
                                                onValueChanged={this.handleChangeComments}
                                                disabled={this.state.readOnlyView}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 pr-0">
                                    <div className="col-12 row mx-0 pr-0 mt-3">
                                        <label className="card-form__label col-12 col-md-auto col-xl-1 text-left text-xl-right">
                                            Internal Comments (Private)
                                        </label>
                                        <sup className="card-form__mandatory-symbol-Internal-Comments">*</sup>
                                        <div className="col-12 col-xl-11 pl-0">
                                            <TextArea
                                                height={100}
                                                value={this.state.editViewDataItem.internalComments}
                                                onValueChanged={this.handleChangeInternalComments}
                                                disabled={this.state.readOnlyView}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    );
                }
            }
    };

    renderEntertainmentShiftPanel = () => {
        let calendarMin;
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            calendarMin = new Date();
        }
        let isDisableOfferingsType: boolean = true;
        let isOtherDescToShow: boolean = false;
        if ((this.state.offeringsTypeLookup && this.state.offeringsTypeLookup.length > 0) && this.state.editViewDataItem.serviceSubTypeId) {
            isDisableOfferingsType = false;
        }
        else {
            if (this.state.editViewDataItem.serviceSubTypeId == Types.Other) {
                isOtherDescToShow = true;
            }
            else {
                isDisableOfferingsType = true;
            }
        }
        if (
            this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment ||
            this.props.location && this.props.location.state && this.props.location.state.id == 0
        ) {
            if (
                this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment ||
                (this.props.location && this.props.location.state && this.props.location.state.id == 0 &&
                    !this.state.isSubTypeChange &&
                    !this.state.isServiceTypeChange &&
                    this.props.location && this.props.location.state && this.props.location.state.serviceTypeId == ServiceType.Entertainment) ||
                (this.props.location && this.props.location.state &&
                    this.props.location.state.service &&
                    this.props.location.state.service.includes("Entertainment")) ||
                this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment
            ) {
                return (
                    <section className="card card-form my-5">
                        <div className="card-body">
                            <h3 className="card-form__section-heading">Shift</h3>
                            <hr className="horizontal_border_style"></hr>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Service
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTableDynamic
                                            lookupTypeIndex={LookupTypeIndexes.serviceType}
                                            isRoot={true}
                                            parentMappingId={"0"}
                                            onValueChanged={this.handleChangeServiceTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.serviceTypeId}
                                        />
                                        <input
                                            data-testid={LookupTypeIndexes.serviceType}
                                            type="hidden"
                                            name={LookupTypeIndexes.serviceType}
                                            value={
                                                this.state.editViewDataItem.serviceTypeId
                                                    ? this.state.editViewDataItem.serviceTypeId
                                                    : ""
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Status
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DropDownFromLookupTable
                                            lookupTypeIndex={LookupTypeIndexes.statusType}
                                            onValueChanged={this.handleChangeStatusTypeSelect}
                                            signal={this.state.createAdjustment}
                                            disableSignal={ this.editAllowed || 
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.isDisableStatusDropDown ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.billableStatusLookupId} />
                                        <input
                                            data-testid={LookupTypeIndexes.statusType}
                                            type="hidden"
                                            name={LookupTypeIndexes.statusType}
                                            value={
                                                this.state.editViewDataItem.billableStatusLookupId
                                                    ? this.state.editViewDataItem.billableStatusLookupId
                                                    : 0
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Client
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.clientType}
                                            onValueChanged={this.handleChangeClientTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            isRoot={false}
                                            parentMappingId={""}
                                            displayExpression={"value"}
                                            value={this.state.editViewDataItem.clientId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Provider
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.providerType}
                                            onValueChanged={this.handleChangeProviderTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            isRoot={false}
                                            parentMappingId={""}
                                            displayExpression={"value"}
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.providerId
                                                    : this.state.editViewDataItem.providerId
                                            }
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Date
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <DateBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeDate}
                                            value={
                                                this.state.editViewDataItem.date
                                                    ? this.state.editViewDataItem.date
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                            min={calendarMin}
                                        ></DateBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Venue
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            lookupTypeIndex={LookupTypeIndexes.venueType}
                                            onValueChanged={this.handleChangeVenueTypeSelect}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.clientId}
                                            value={this.state.editViewDataItem.venueId}
                                            displayExpression={"value"}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Sub Type
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                            isRoot={false}
                                            parentMappingId={this.state.editViewDataItem.serviceTypeId}
                                            displayExpression={"value"}
                                            onValueChanged={this.handleChangeServiceSubTypeSelect}
                                            isDisabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            value={this.state.editViewDataItem.serviceSubTypeId}
                                            recordType={
                                                (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                    this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                    ? "all"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Booking Id
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            disabled={true}
                                            value={this.state.editViewDataItem.requestId}
                                        ></TextBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Charge
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            disabled={
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            dataSource={this.chargeTypesDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeNoChange}
                                            value={
                                                this.state.editViewDataItem.noCharge == "" &&
                                                    this.props.location && this.props.location.state &&
                                                    this.props.location.state.id == 0
                                                    ? "Applied"
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                        ? this.state.editViewVirtualDataItem.noCharge
                                                        : this.state.editViewDataItem.noCharge
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <LoadIndicator
                                    id="load-indicator"
                                    className="load-indicator"
                                    height={40}
                                    width={40}
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Role/Type
                                        <sup className="card-form__mandatory-symbol" hidden={isDisableOfferingsType}>*</sup>
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            dataSource={this.state.offeringsTypeLookup}
                                            displayExpr={"value"}
                                            valueExpr={"id"}
                                            onValueChanged={this.handleChangeOfferingsTypeSelect}
                                            value={this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                ? this.state.editViewVirtualDataItem.typeId
                                                : this.state.editViewDataItem.typeId}
                                            disabled={this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend || isDisableOfferingsType ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Description
                                        {isOtherDescToShow ? <sup className="card-form__mandatory-symbol">*</sup> : null }
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeOtherDescription}
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.otherDescription
                                                    : this.state.editViewDataItem.otherDescription
                                            }
                                        ></TextBox>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Performance Start
                                            </label>
                                            <div className="col-12 col-xl-6 pl-2">
                                                <DateBox
                                                    type="time"
                                                    interval={15}
                                                    placeholder="00:00"
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    disabled={
                                                        this.state.createAdjustment ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                    }
                                                    onValueChanged={this.handleChangeShiftStart}
                                                    value={
                                                        this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust &&
                                                            this.state.editViewVirtualDataItem.shift_Start
                                                            ? this.state.editViewVirtualDataItem.shift_Start
                                                            : this.state.editViewDataItem.shift_Start
                                                                ? this.state.editViewDataItem.shift_Start
                                                                : undefined
                                                    }
                                                    onContentReady={this.onContentReadyDateBox}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Performance End
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <DateBox
                                                    type="time"
                                                    interval={15}
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    disabled={
                                                        this.state.createAdjustment ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                    }
                                                    onValueChanged={this.handleChangeShiftEnd}
                                                    value={
                                                        this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust &&
                                                            this.state.editViewVirtualDataItem.shift_End
                                                            ? this.state.editViewVirtualDataItem.shift_End
                                                            : this.state.editViewDataItem.shift_End
                                                                ? this.state.editViewDataItem.shift_End
                                                                : undefined
                                                    }
                                                    onContentReady={this.onContentReadyDateBox}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Residency
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            dataSource={this.decisionTypesDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeReoccurance}
                                            value={this.state.editViewDataItem.reoccurance}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Pay Request Description
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            readOnly={true}
                                            value={this.state.editViewDataItem.paymentRequest?.description}
                                        ></TextBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Sets
                                            </label>
                                            <div className="col-12 col-xl-6 pl-2">
                                                <NumberBox
                                                    value={
                                                        this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                            ? parseInt(
                                                                this.state.editViewVirtualDataItem
                                                                    .performanceInformationSetsNumber
                                                            )
                                                            : parseInt(
                                                                this.state.editViewDataItem
                                                                    .performanceInformationSetsNumber
                                                            )
                                                    }
                                                    max={5}
                                                    min={0}
                                                    showSpinButtons={true}
                                                    onValueChanged={this.handleChangeSetNumber}
                                                    disabled={
                                                        this.state.createAdjustment
                                                            ? this.state.createAdjustment
                                                            : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                            this.state.readOnlyView ||
                                                            (this.props.location && this.props.location.state &&
                                                                this.props.location.state.isAdjust)
                                                    }
                                                    onContentReady={this.onContentReadyNumberBox}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Duration
                                            </label>
                                            <div className="col-12 col-xl-6 pl-0">
                                                <NumberBox
                                                    min={0}
                                                    max={90}
                                                    step={15}
                                                    showSpinButtons={true}
                                                    value={
                                                        this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                            ? parseInt(
                                                                this.state.editViewVirtualDataItem
                                                                    .performanceInformationSetsDuration
                                                            )
                                                            : parseInt(
                                                                this.state.editViewDataItem
                                                                    .performanceInformationSetsDuration
                                                            )
                                                    }
                                                    onValueChanged={this.handleChangeDuration}
                                                    disabled={
                                                        this.state.createAdjustment
                                                            ? this.state.createAdjustment
                                                            : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                            this.state.readOnlyView ||
                                                            (this.props.location && this.props.location.state &&
                                                                this.props.location.state.isAdjust)
                                                    }
                                                    onContentReady={this.onContentReadyNumberBox}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Performance Information
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangePerformanceInfo}
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.performanceInformation
                                                    : this.state.editViewDataItem.performanceInformation
                                            }
                                        ></TextBox>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Equipment
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            disabled={
                                                this.state.createAdjustment
                                                    ? this.state.createAdjustment
                                                    : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                    this.state.readOnlyView ||
                                                    (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeEquipmentRequired}
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.equipmentRequired
                                                    : this.state.editViewDataItem.equipmentRequired
                                            }
                                        ></TextBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right mr-2">
                                        Artist's Fee
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Artist-Fee">*</sup>
                                    <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            format={{
                                                type: "currency",
                                                precision: 2,
                                                currency: "GBP",
                                            }}
                                            onValueChanged={this.handleChangeRate}
                                            onContentReady={this.onContentReadyNumberBox}
                                            valueChangeEvent="keyup"
                                            step={0}
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.rate_Decimal
                                                    : this.state.editViewDataItem.rate_Decimal
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Artist Description
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <TextBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            onValueChanged={this.handleChangeDescription}
                                            value={this.state.editViewDataItem.description}
                                        ></TextBox>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                                Extras
                                            </label>
                                            <div className="col-12 col-xl-6 pl-2">
                                                <NumberBox
                                                    disabled={true}
                                                    format={{
                                                        type: "currency",
                                                        precision: 2,
                                                        currency: "GBP",
                                                    }}
                                                    onValueChanged={this.handleChangeExtras}
                                                    onContentReady={this.onContentReadyNumberBox}
                                                    valueChangeEvent="keyup"
                                                    step={0}
                                                    value={this.state.editViewDataItem.providerPayAmountLocalExtras}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 row mx-0 mt-3">
                                            <div className="col-12 col-xl-8 pl-0">
                                                <TextBox
                                                    disabled={
                                                        this.state.createAdjustment ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                                    }
                                                    onValueChanged={this.handleChangeExtraDescription}
                                                    value={this.state.editViewDataItem.providerPayAmountLocalExtrasDescription}
                                                    className="extra-desc"
                                                ></TextBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-md-auto col-12 col-xl-6 text-left text-xl-right">
                                        Faster Pay Fee
                                    </label>
                                    <div className="col-12 col-xl-6 pl-0">
                                        <NumberBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                            format={{
                                                type: "currency",
                                                precision: 2,
                                                currency: "GBP",
                                            }}
                                            onValueChanged={this.handleChangeFasterPayFee}
                                            onContentReady={this.onContentReadyNumberBox}
                                            valueChangeEvent="keyup"
                                            step={0}
                                            value={this.state.editViewDataItem.providerFasterPayFee}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-md-auto col-12 col-xl-6 text-left text-xl-right">
                                        Adjusted Artist Fee
                                    </label>
                                    <div className="col-12 col-xl-6 pl-0">
                                        <NumberBox
                                            disabled={true}
                                            format={{
                                                type: "currency",
                                                precision: 2,
                                                currency: "GBP",
                                            }}
                                            onValueChanged={this.handleChangeAdjustedAmount}
                                            onContentReady={this.onContentReadyNumberBox}
                                            valueChangeEvent="keyup"
                                            step={0}
                                            value={this.state.editViewDataItem.providerPayAmountLocal}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                        Artist Confirmed
                                    </label>
                                    <div className="col-12 col-xl-9 pl-0">
                                        <SelectBox
                                            disabled={
                                                this.state.createAdjustment ||
                                                this.state.readOnlyView ||
                                                (this.props.location && this.props.location.state &&
                                                    this.props.location.state.isAdjust)
                                            }
                                            dataSource={this.decisionTypesDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeArtistConfirmation}
                                            value={
                                                this.state.editViewDataItem.shiftCompleteAcknowledgment == true
                                                    ? "Yes"
                                                    : this.props.location && this.props.location.state && this.props.location.state.id != 0 &&
                                                        this.state.editViewDataItem.shiftCompleteAcknowledgment ==
                                                        false
                                                        ? "No"
                                                        : this.state.editViewDataItem.shiftCompleteAcknowledgment
                                            }
                                        />
                                    </div>
                                </div>                                
                                <div className="col-12 col-lg-4 pr-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-8 row mx-0 mt-3">
                                            <label className="card-form__label label-padding col-12 col-xl-4 text-left text-xl-right">
                                                Confirmed Date/Time
                                            </label>
                                            <div className="col-12 col-xl-8 pl-2">
                                                <DateBox
                                                    disabled={
                                                        this.state.createAdjustment
                                                            ? this.state.createAdjustment
                                                            : this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                            this.state.readOnlyView ||
                                                            (this.props.location && this.props.location.state &&
                                                                this.props.location.state.isAdjust)
                                                    }
                                                    onValueChanged={this.handleChangeArtistConfirmedDate}
                                                    value={
                                                        this.state.editViewDataItem.shiftCompleteAcknowledgmentDateTime
                                                            ? this.state.editViewDataItem
                                                                .shiftCompleteAcknowledgmentDateTime
                                                            : undefined
                                                    }
                                                    useMaskBehavior={true}
                                                    displayFormat="dd/MM/yyyy"
                                                    min={calendarMin}
                                                    className="confirmed-date"
                                                ></DateBox>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 row mx-0 mt-3">
                                            <div className="col-12 pl-0">
                                                <DateBox
                                                    type="time"
                                                    interval={15}
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    disabled={
                                                        this.state.createAdjustment ||
                                                        this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                                        this.state.readOnlyView ||
                                                        (this.props.location && this.props.location.state &&
                                                            this.props.location.state.isAdjust)
                                                    }
                                                    onValueChanged={this.handleChangeArtistConfirmedTime}
                                                    value={
                                                        this.state.editViewDataItem.shiftCompleteAcknowledgmentTime
                                                            ? this.state.editViewDataItem
                                                                .shiftCompleteAcknowledgmentTime
                                                            : undefined
                                                    }
                                                    onContentReady={this.onContentReadyDateBox}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                
                            <div className="row mx-0 pr-0">
                                <div className="col-12 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-xl-1 text-left text-xl-right">
                                        External Comments (Public)
                                    </label>
                                    <div className="col-12 col-xl-11 pl-0">
                                        <TextArea
                                            height={100}
                                            value={this.state.editViewDataItem.comments}
                                            onValueChanged={this.handleChangeComments}
                                            disabled={this.state.readOnlyView}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 pr-0">
                                <div className="col-12 row mx-0 pr-0 mt-3">
                                    <label className="card-form__label col-12 col-md-auto col-xl-1 text-left text-xl-right">
                                        Internal Comments (Private)
                                    </label>
                                    <sup className="card-form__mandatory-symbol-Internal-Comments">*</sup>
                                    <div className="col-12 col-xl-11 pl-0">
                                        <TextArea
                                            height={100}
                                            value={this.state.editViewDataItem.internalComments}
                                            onValueChanged={this.handleChangeInternalComments}
                                            disabled={this.state.readOnlyView}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            }
        }
    };

    renderPersonnelAdjustmentPanel = () => {
        var readOnlyView: boolean = false;
        if (this.props.location && this.props.location.state && this.props.location.state.isReadOnly) {
            readOnlyView = this.props.location.state.isReadOnly;
        }
        var maskedValue: string =
            this.state.editViewDataItem.identificationTypeId == IdentificationType.SIALicense
                ? "0000-0000-0000-0000"
                : "";
        if (
            (this.props.location && this.props.location.state && this.props.location.state.isAdjust &&
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel) ||
            (this.props.location && this.props.location.state && this.props.location.state.isAdjust &&
                (this.props.location.state.service == "Security - Door Supervision Personnel" ||
                    this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel))
        ) {
            return (
                <section className="card card-form my-5">
                    <div className="card-body">
                        <h3 className="card-form__section-heading">Adjustment</h3>
                        <hr className="horizontal_border_style"></hr>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Type/Role
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <DropDownFromLookupTableDynamic
                                        lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                        isRoot={false}
                                        parentMappingId={this.state.editViewDataItem.serviceSubTypeId}
                                        onValueChanged={this.handleChangeOfferingsTypeSelect}
                                        isDisabled={
                                            this.state.createAdjustment ||
                                            this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                            this.state.typeLUDisablement ||
                                            readOnlyView
                                        }
                                        value={this.state.editViewDataItem.typeId}
                                    />
                                    <input
                                        data-testid={LookupTypeIndexes.offeringsType}
                                        type="hidden"
                                        name={LookupTypeIndexes.offeringsType}
                                        value={
                                            this.state.editViewDataItem.typeId ? this.state.editViewDataItem.typeId : ""
                                        }
                                    ></input>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 pr-0">
                                <div className="row">
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Approved Start
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <DateBox
                                                type="time"
                                                interval={15}
                                                placeholder="00:00"
                                                useMaskBehavior={true}
                                                displayFormat={"HH:mm"}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.originalBillableStartTime
                                                        ? this.state.editViewDataItem.originalBillableStartTime
                                                        : undefined
                                                }
                                                disabled={true}
                                                onContentReady={this.onContentReadyDateBox}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            End
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <DateBox
                                                type="time"
                                                interval={15}
                                                placeholder="00:00"
                                                useMaskBehavior={true}
                                                displayFormat={"HH:mm"}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.originalBillableFinishTime
                                                        ? this.state.editViewDataItem.originalBillableFinishTime
                                                        : undefined
                                                }
                                                disabled={true}
                                                onContentReady={this.onContentReadyDateBox}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Rate
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeOriginalRate}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.originalRate}
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-50 mx-auto">
                                <LoadIndicator
                                    id="load-indicator"
                                    className="load-indicator"
                                    height={40}
                                    width={40}
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Name
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <TextBox
                                        disabled={
                                            this.state.createAdjustment
                                                ? this.state.createAdjustment
                                                : this.props.location && this.props.location.state && this.props.location.state.isAmend || readOnlyView
                                        }
                                        onValueChanged={this.handleChangeName}
                                        value={this.state.editViewDataItem.name}
                                    ></TextBox>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 pr-0">
                                <div className="row">
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label-requested-on col-12 col-xl-6 text-left text-xl-right">
                                            Adjusted Start
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <DateBox
                                                type="time"
                                                interval={15}
                                                placeholder="00:00"
                                                useMaskBehavior={true}
                                                displayFormat={"HH:mm"}
                                                onValueChanged={this.handleChangeBillableStart}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.billable_Start
                                                        ? this.state.editViewDataItem.billable_Start
                                                        : undefined
                                                }
                                                disabled={
                                                    this.state.IsLockBillableFields ||
                                                    this.state.IsLockTimeFields ||
                                                    readOnlyView
                                                }
                                                onContentReady={this.onContentReadyDateBox}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            End
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <DateBox
                                                type="time"
                                                interval={15}
                                                placeholder="00:00"
                                                useMaskBehavior={true}
                                                displayFormat={"HH:mm"}
                                                onValueChanged={this.handleChangeBillableFinish}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.billable_Finish
                                                        ? this.state.editViewDataItem.billable_Finish
                                                        : undefined
                                                }
                                                disabled={
                                                    this.state.IsLockBillableFields ||
                                                    this.state.IsLockTimeFields ||
                                                    readOnlyView
                                                }
                                                onContentReady={this.onContentReadyDateBox}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label-requested-on col-12 col-xl-3 text-left text-xl-right">
                                    Adjusted Rate
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={
                                            this.state.createAdjustment ||
                                            this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                            readOnlyView
                                        }
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeRate}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.rate_Decimal}
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label-requested-on col-12 col-xl-3 text-left text-xl-right">
                                    License No.
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <TextBox
                                        mask={maskedValue}
                                        disabled={
                                            this.state.createAdjustment
                                                ? this.state.createAdjustment
                                                : this.props.location && this.props.location.state && this.props.location.state.isAmend || readOnlyView
                                        }
                                        onValueChanged={this.handleChangeIdentificationNumber}
                                        value={this.state.editViewDataItem.identification_Number}
                                    ></TextBox>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Difference
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        value={this.state.editViewDataItem.billable_Minutes}
                                    ></NumberBox>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Difference
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.editViewDataItem.billableRate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label-requested-on col-12 col-xl-3 text-left text-xl-right">
                                    Absent
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        disabled={
                                            this.state.createAdjustment || this.state.IsLockTimeFields || readOnlyView
                                        }
                                        dataSource={this.absentTypeDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeAbsent}
                                        value={
                                            this.state.editViewDataItem.absent == "" &&
                                                this.props.location && this.props.location.state &&
                                                this.props.location.state.id == 0
                                                ? "Present"
                                                : this.state.editViewDataItem.absent
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Cover
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        disabled={
                                            this.state.createAdjustment || this.state.IsLockTimeFields || readOnlyView
                                        }
                                        dataSource={this.decisionTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeCover}
                                        value={
                                            this.state.editViewDataItem.cover == "" &&
                                                this.props.location && this.props.location.state &&
                                                this.props.location.state.id == 0
                                                ? "No"
                                                : this.state.editViewDataItem.cover
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Charge
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        disabled={readOnlyView}
                                        dataSource={this.chargeTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeNoChange}
                                        value={
                                            this.state.editViewDataItem.noCharge == "" &&
                                                this.props.location && this.props.location.state &&
                                                this.props.location.state.id == 0
                                                ? "Applied"
                                                : this.state.editViewDataItem.noCharge
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 row mx-0 pr-0 mt-3">
                                <p className="text-danger font-weight-bold mx-auto">
                                    {
                                        <span className={this.state.calculationMessageClassName}>
                                            <b>
                                                {this.state.calculationMessage.length > 0
                                                    ? this.state.calculationMessage[
                                                    this.state.calculationMessage.length - 1
                                                    ]
                                                    : null}
                                            </b>
                                        </span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    };

    renderEquipmentAdjustmentPanel = () => {
        var readOnlyView: boolean = false;
        if (this.props.location && this.props.location.state && this.props.location.state.isReadOnly) {
            readOnlyView = this.props.location.state.isReadOnly;
        }
        var maskedValue: string =
            this.state.editViewDataItem.identificationTypeId == IdentificationType.SIALicense
                ? "0000-0000-0000-0000"
                : "";

        if (
            (this.props.location && this.props.location.state && this.props.location.state.isAdjust &&
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment) ||
            (this.props.location && this.props.location.state && this.props.location.state.isAdjust &&
                (this.props.location && this.props.location.state && this.props.location.state.service == "Security - Door Supervision Equipment" ||
                    this.state.editViewDataItem.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment))
        ) {
            return (
                <section className="card card-form my-5">
                    <div className="card-body">
                        <h3 className="card-form__section-heading">Adjustment</h3>
                        <hr className="horizontal_border_style"></hr>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Role/Type
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <DropDownFromLookupTableDynamic
                                        lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                        isRoot={false}
                                        parentMappingId={this.state.editViewDataItem.serviceSubTypeId}
                                        onValueChanged={this.handleChangeOfferingsTypeSelect}
                                        isDisabled={
                                            this.state.createAdjustment ||
                                            this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                            this.state.typeLUDisablement ||
                                            readOnlyView
                                        }
                                        value={this.state.editViewDataItem.typeId}
                                    />
                                    <input
                                        data-testid={LookupTypeIndexes.offeringsType}
                                        type="hidden"
                                        name={LookupTypeIndexes.offeringsType}
                                        value={
                                            this.state.editViewDataItem.typeId ? this.state.editViewDataItem.typeId : ""
                                        }
                                    ></input>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Quantity
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        onValueChanged={this.handleChangeOriginalQuantity}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.equipmentOriginalQuantity}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Previous Rate
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeOriginalRate}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.originalRate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-50 mx-auto">
                                <LoadIndicator
                                    id="load-indicator"
                                    className="load-indicator"
                                    height={40}
                                    width={40}
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Serial No.
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <TextBox
                                        mask={maskedValue}
                                        disabled={
                                            this.state.createAdjustment
                                                ? this.state.createAdjustment
                                                : this.props.location && this.props.location.state && this.props.location.state.isAmend || readOnlyView
                                        }
                                        onValueChanged={this.handleChangeIdentificationNumber}
                                        value={this.state.editViewDataItem.identification_Number}
                                    ></TextBox>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                    Adjusted  Quantity
                                </label>
                                <sup className="card-form__mandatory-symbol-Provider-Net">*</sup>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={
                                            this.state.createAdjustment ||
                                            this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                            this.state.IsLockQuantityFields ||
                                            readOnlyView
                                        }
                                        onValueChanged={this.handleChangeQuantity}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.equipmentQuantity}
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label-requested-on col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                    Adjusted Rate
                                </label>
                                <sup className="card-form__mandatory-symbol-Adjusted-Rate">*</sup>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={
                                            this.state.createAdjustment ||
                                            this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                            readOnlyView
                                        }
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeRate}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.rate_Decimal}
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Charge
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        disabled={readOnlyView}
                                        dataSource={this.chargeTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeNoChange}
                                        value={
                                            this.state.editViewDataItem.noCharge == "" &&
                                                this.props.location && this.props.location.state &&
                                                this.props.location.state.id == 0
                                                ? "Applied"
                                                : this.state.editViewDataItem.noCharge
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Difference
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        value={this.state.editViewDataItem.equipmentBillableQuantity}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Difference
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.editViewDataItem.billableRate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 row mx-0 pr-0 mt-3">
                                <p className="text-danger font-weight-bold mx-auto">
                                    {
                                        <span className={this.state.calculationMessageClassName}>
                                            {this.state.calculationMessage.length > 0
                                                ? this.state.calculationMessage[
                                                this.state.calculationMessage.length - 1
                                                ]
                                                : null}
                                        </span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    };

    renderWeeklyCleaningAdjustmentPanel = () => {
        var readOnlyView: boolean = false;
        if (this.props.location && this.props.location.state && this.props.location.state.isReadOnly) {
            readOnlyView = this.props.location.state.isReadOnly;
        }

        if (
            (this.props.location && this.props.location.state && this.props.location.state.isAdjust &&
                this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning) ||
            (this.props.location && this.props.location.state && this.props.location.state.isAdjust &&
                (this.props.location && this.props.location.state && this.props.location.state.service == "Contract Cleaning - Weekly Cleaning" ||
                    this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning))
        ) {
            return (
                <section className="card card-form my-5">
                    <div className="card-body">
                        <h3 className="card-form__section-heading">Adjustment</h3>
                        <hr className="horizontal_border_style"></hr>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 pr-0">
                                <div className="row">
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Hours
                                        </label>
                                        <div className="col-12 col-xl-6 pl-2">
                                            <NumberBox
                                                disabled={true}
                                                valueChangeEvent="keyup"
                                                value={this.state.prevHours}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Min
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <SelectBox
                                                disabled={true}
                                                dataSource={minuteLookup}
                                                displayExpr="value"
                                                valueExpr="id"
                                                value={this.state.prevminutes}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Rate</label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeOriginalRate}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.originalRate}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Charge
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        disabled={true}
                                        dataSource={this.chargeTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeNoChange}
                                        value={
                                            this.state.editViewDataItem.noCharge == "" &&
                                                this.props.location && this.props.location.state &&
                                                this.props.location && this.props.location.state.id == 0
                                                ? "Applied"
                                                : this.props.location && this.props.location.state && this.props.location.state.isAdjust
                                                    ? this.state.editViewVirtualDataItem.noCharge
                                                    : this.state.editViewDataItem.noCharge
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-50 mx-auto">
                                <LoadIndicator
                                    id="load-indicator"
                                    className="load-indicator"
                                    height={40}
                                    width={40}
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 pr-0">
                                <div className="row">
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Hours
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-6 pl-2">
                                            <NumberBox
                                                disabled={false}
                                                onValueChanged={this.handleChangeHours}
                                                valueChangeEvent="keyup"
                                                value={this.state.hours}
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Min
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <SelectBox
                                                dataSource={minuteLookup}
                                                displayExpr="value"
                                                valueExpr="id"
                                                onValueChanged={this.handleChangeMinutes}
                                                value={this.state.minutes}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label-requested-on col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                    Adjusted Rate
                                </label>
                                <sup className="card-form__mandatory-symbol-Adjusted-Rate">*</sup>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={
                                            this.state.createAdjustment ||
                                            this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                            readOnlyView
                                        }
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeRate}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.rate_Decimal}
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Difference
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        value={this.state.editViewDataItem.equipmentBillableQuantity}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Difference
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.editViewDataItem.billableRate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 row mx-0 pr-0 mt-3">
                                <p className="text-danger font-weight-bold mx-auto">
                                    {
                                        <span className={this.state.calculationMessageClassName}>
                                            {this.state.calculationMessage.length > 0
                                                ? this.state.calculationMessage[
                                                this.state.calculationMessage.length - 1
                                                ]
                                                : null}
                                        </span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    };

    renderEntertainmentAdjustmentPanel = () => {
        var readOnlyView: boolean = false;
        if (this.props.location && this.props.location.state && this.props.location.state.isReadOnly) {
            readOnlyView = this.props.location.state.isReadOnly;
        }
        let isDisableOfferingsType: boolean = true;
        let isOtherDescToShow: boolean = false;
        if ((this.state.offeringsTypeLookup && this.state.offeringsTypeLookup.length > 0) && this.state.editViewDataItem.serviceSubTypeId) {
            isDisableOfferingsType = false;
        }
        else {
            if (this.state.editViewDataItem.serviceSubTypeId == Types.Other) {
                isOtherDescToShow = true;
            }
            else {
                isDisableOfferingsType = true;
            }
        }
        if (
            (this.props.location && this.props.location.state && this.props.location.state.isAdjust &&
                this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment) ||
            (this.props.location && this.props.location.state && this.props.location.state.isAdjust &&
                ((this.props.location && this.props.location.state && this.props.location.state.service && this.props.location.state.service.includes("Entertainment")) ||
                    this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment))
        ) {
            return (
                <section className="card card-form my-5">
                    <div className="card-body">
                        <h3 className="card-form__section-heading">Adjustment</h3>
                        <hr className="horizontal_border_style"></hr>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Provider
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.providerType}
                                        onValueChanged={this.handleChangeProviderTypeSelect}
                                        isDisabled={
                                            this.state.createAdjustment ||
                                            this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                            this.state.readOnlyView
                                        }
                                        isRoot={false}
                                        parentMappingId={""}
                                        displayExpression={"value"}
                                        value={this.state.editViewDataItem.providerId}
                                        recordType={
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit) ||
                                                this.props.location && this.props.location.state && this.props.location.state.isMasterEdit
                                                ? "all"
                                                : "active"
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3"></div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Performance Information
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <TextBox
                                        disabled={
                                            this.state.createAdjustment
                                                ? this.state.createAdjustment
                                                : this.props.location && this.props.location.state && this.props.location.state.isAmend || this.state.readOnlyView
                                        }
                                        onValueChanged={this.handleChangePerformanceInfo}
                                        value={this.state.editViewDataItem.performanceInformation}
                                    ></TextBox>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    {isOtherDescToShow ? "Description" : "Role/Type"}
                                    {isOtherDescToShow ? <sup className="card-form__mandatory-symbol">*</sup> :
                                        <sup className="card-form__mandatory-symbol" hidden={isDisableOfferingsType}>*</sup>}
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    {isOtherDescToShow ? <TextBox
                                        onValueChanged={this.handleChangeOtherDescription}
                                        value={this.state.editViewDataItem.otherDescription}
                                    ></TextBox> : <SelectBox
                                        dataSource={this.state.offeringsTypeLookup}
                                        displayExpr={"value"}
                                        valueExpr={"id"}
                                        onValueChanged={this.handleChangeOfferingsTypeSelect}
                                        value={this.state.editViewDataItem.typeId}
                                    />}
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 pr-0">
                                <div className="row">
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Performance Start
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <DateBox
                                                type="time"
                                                interval={15}
                                                placeholder="00:00"
                                                useMaskBehavior={true}
                                                displayFormat={"HH:mm"}
                                                onValueChanged={this.handleChangeShiftStart}
                                                value={
                                                    this.state.editViewDataItem.shift_Start
                                                        ? this.state.editViewDataItem.shift_Start
                                                        : undefined
                                                }
                                                onContentReady={this.onContentReadyDateBox}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Performance End
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <DateBox
                                                type="time"
                                                interval={15}
                                                placeholder="00:00"
                                                useMaskBehavior={true}
                                                displayFormat={"HH:mm"}
                                                onValueChanged={this.handleChangeShiftEnd}
                                                value={
                                                    this.state.editViewDataItem.shift_End
                                                        ? this.state.editViewDataItem.shift_End
                                                        : undefined
                                                }
                                                onContentReady={this.onContentReadyDateBox}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Previous Provider Net
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeOriginalRate}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.originalRate}
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-50 mx-auto">
                                <LoadIndicator
                                    id="load-indicator"
                                    className="load-indicator"
                                    height={40}
                                    width={40}
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Pay Request Description
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <TextBox
                                        disabled={
                                            this.state.createAdjustment
                                                ? this.state.createAdjustment
                                                : this.props.location && this.props.location.state && this.props.location.state.isAmend || this.state.readOnlyView
                                        }
                                        readOnly={true}
                                        value={this.state.editViewDataItem.paymentRequest?.description}
                                    ></TextBox>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 pr-0">
                                <div className="row">
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Sets
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <NumberBox
                                                value={parseInt(
                                                    this.state.editViewDataItem.performanceInformationSetsNumber
                                                )}
                                                max={5}
                                                min={0}
                                                showSpinButtons={true}
                                                onValueChanged={this.handleChangeSetNumber}
                                                disabled={
                                                    this.state.createAdjustment
                                                        ? this.state.createAdjustment
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAmend || this.state.readOnlyView
                                                }
                                                onContentReady={this.onContentReadyNumberBox}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Duration
                                        </label>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <NumberBox
                                                min={0}
                                                max={90}
                                                step={15}
                                                showSpinButtons={true}
                                                value={parseInt(
                                                    this.state.editViewDataItem.performanceInformationSetsDuration
                                                )}
                                                onValueChanged={this.handleChangeDuration}
                                                disabled={
                                                    this.state.createAdjustment
                                                        ? this.state.createAdjustment
                                                        : this.props.location && this.props.location.state && this.props.location.state.isAmend || this.state.readOnlyView
                                                }
                                                onContentReady={this.onContentReadyNumberBox}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label-provider-net col-md-auto col-12 col-xl-3 text-left text-xl-right">
                                    Adjusted Provider Net
                                </label>
                                <sup className="card-form__mandatory-symbol-Adjusted-Provider-Rate">*</sup>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={
                                            this.state.createAdjustment ||
                                            this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                            readOnlyView
                                        }
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeRate}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.rate_Decimal}
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 pr-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Charge
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        disabled={readOnlyView}
                                        dataSource={this.chargeTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeNoChange}
                                        value={
                                            this.state.editViewDataItem.noCharge == "" &&
                                                this.props.location && this.props.location.state &&
                                                this.props.location.state.id == 0
                                                ? "Applied"
                                                : this.state.editViewDataItem.noCharge
                                        }
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Equipment
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <TextBox
                                        disabled={
                                            this.state.createAdjustment
                                                ? this.state.createAdjustment
                                                : this.props.location && this.props.location.state && this.props.location.state.isAmend || this.state.readOnlyView
                                        }
                                        onValueChanged={this.handleChangeEquipmentRequired}
                                        value={this.state.editViewDataItem.equipmentRequired}
                                    ></TextBox>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Difference
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        value={this.state.editViewDataItem.billableRate}
                                    ></NumberBox>
                                </div>
                            </div>
                        </div>

                        <div className="row mx-0 pr-0">
                            <div className="col-12 row mx-0 pr-0 mt-3">
                                <p className="text-danger font-weight-bold mx-auto">
                                    {
                                        <span className={this.state.calculationMessageClassName}>
                                            <b>
                                                {this.state.calculationMessage.length > 0
                                                    ? this.state.calculationMessage[
                                                    this.state.calculationMessage.length - 1
                                                    ]
                                                    : null}
                                            </b>
                                        </span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    };

    //Helper function to navigate to the Simple shifts page by storing the appropriate service type id's into session storage.
    viewSimpleShiftsForService = (serviceTypeId: string) => {
        sessionStorage.setItem("serviceTypeFilter", serviceTypeId);
    };

    renderFinancialPanel = () => {
        var readOnlyView: boolean = false;
        if (this.props.location && this.props.location.state && this.props.location.state.isReadOnly) {
            readOnlyView = this.props.location.state.isReadOnly;
        }

        var disableViewPaymentLink: boolean = false;
        var disableViewBillingLink: boolean = false;
        if (!this.state.editViewDataItem.paymentId) {
            disableViewPaymentLink = true;
        }
        if (!this.state.editViewDataItem.invoiceId) {
            disableViewBillingLink = true;
        }
        let paymentRequestVal: string = "";
        let paymentRequestId: string = "";
        let disableViewPaymentBtn: boolean = true;
        if (this.state.editViewDataItem.paymentRequest && this.state.editViewDataItem.paymentRequest.fasterPayment && this.state.editViewDataItem.paymentRequest.id) {
            paymentRequestVal = this.state.editViewDataItem.paymentRequest.fasterPayment;
            paymentRequestId = this.state.editViewDataItem.paymentRequest.id;
            disableViewPaymentBtn = false;
        }
        else if (this.state.editViewDataItem.paymentRequest == null && this.state.editViewDataItem.shiftCompleteAcknowledgment) {
            paymentRequestVal = "Confirmed";
        }
        if (this.state.isLoadFinancialPanel) {
            return (
                <section className="card card-form my-5">
                    <div className="card-body">
                        <h3 className="card-form__section-heading">Financials</h3>
                        <hr className="horizontal_border_style"></hr>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Provider VAT
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.decisionTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeProviderTaxApplicableSelect}
                                        value={
                                            this.state.editViewDataItem.provider_Tax_Applicable == "Yes" ||
                                                this.state.editViewDataItem.provider_Tax_Applicable == "True"
                                                ? "Yes"
                                                : "No"
                                        }
                                        disabled={
                                            this.state.readOnlyView || this.editAllowed || 
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Event VAT
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.decisionTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeFeeTaxApplicableSelect}
                                        value={
                                            this.state.editViewDataItem.fee_Tax_Applicable == "Yes" ||
                                                this.state.editViewDataItem.fee_Tax_Applicable == "True"
                                                ? "Yes"
                                                : "No"
                                        }

                                        disabled={
                                            this.state.readOnlyView || this.editAllowed || 
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Client VAT
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.decisionTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeClientTaxApplicableSelect}
                                        value={
                                            this.state.editViewDataItem.client_Tax_Applicable == "Yes" ||
                                                this.state.editViewDataItem.client_Tax_Applicable == "True"
                                                ? "Yes"
                                                : "No"
                                        }
                                        disabled={
                                            this.state.readOnlyView || this.editAllowed || 
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Provider NET
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeProviderLocalCurrancyAmount}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy}
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                    <span>
                                        {" "}
                                        <LoadIndicator
                                            height={40}
                                            width={40}
                                            visible={this.state.loadIndicatorOnPercentageChange}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Event NET
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeFeeLocalCurrancyAmount}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.fee_Amount_Local_Ccy}
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Client NET
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeBillLocalCurrancyAmount}
                                        valueChangeEvent="keyup"
                                        value={this.state.editViewDataItem.client_Billable_Amount_Local_Ccy}
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Provider VAT
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeProviderLocalCurrancyTax}
                                        value={this.state.editViewDataItem.provider_Pay_Tax_Local_Ccy}
                                        disabled={
                                            !this.state.isProviderTaxFieldVisible || this.editAllowed || 
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                        valueChangeEvent="keyup"
                                        step={0}
                                        onContentReady={this.onContentReadyNumberBox}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Event VAT
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeFeeLocalCurrancyTax}
                                        value={this.state.editViewDataItem.fee_Tax_Local_Ccy}
                                        disabled={
                                            !this.state.isFeeTaxFieldVisible ||
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                        valueChangeEvent="keyup"
                                        step={0}
                                        onContentReady={this.onContentReadyNumberBox}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Client VAT
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeBillLocalCurrancyTax}
                                        value={this.state.editViewDataItem.client_Billable_Tax_Local_Ccy}
                                        disabled={
                                            !this.state.isBillClientTaxFieldVisible ||
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Provider GROSS
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.editViewDataItem.provider_Pay_Total_Local_Ccy}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Event GROSS
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.editViewDataItem.fee_Total_Local_Ccy}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Client GROSS
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.editViewDataItem.client_Billable_Total_Local_Ccy}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Provider Previous Net
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.editViewDataItem.provider_Pay_Original_Amount_Local_Ccy}
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Expected Fee
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.expectedFee}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Client Previous Net
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeProviderOriginalAmount}
                                        value={this.state.editViewDataItem.client_Billable_Original_Amount_Local_Ccy}
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Acceptance Date
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <DateBox
                                        onValueChanged={this.handleChangeAcceptDate}
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                        value={
                                            this.state.editViewDataItem.accept_Date
                                                ? this.state.editViewDataItem.accept_Date
                                                : undefined
                                        }
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Event Multiplier
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format="0.0000"
                                        onValueChanged={this.handleChangePercentage}
                                        valueChangeEvent="keyup"
                                        value={this.state.isMultiplierOverride ? this.state.multiplierOverrideValue : this.state.editViewDataItem.fee_Percentage_Local_Ccy}
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    >
                                    </NumberBox>
                                    <span>
                                        {" "}
                                        <LoadIndicator
                                            height={40}
                                            width={40}
                                            visible={this.state.loadIndicatorOnPercentageChange}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-lg-2 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                    Approval Date
                                </label>
                                <div className="col-12 col-xl-6 pl-0">
                                    <DateBox
                                        onValueChanged={this.handleChangeApprovalDate}
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                        value={
                                            this.state.editViewDataItem.approval_Date
                                                ? this.state.editViewDataItem.approval_Date
                                                : undefined
                                        }
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-2 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                2<sup>nd</sup> Approval Date
                                </label>
                                <div className="col-12 col-xl-6 pl-0">
                                    <DateBox
                                        onValueChanged={this.handleChangeTwoTierApprovalDate}
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                        value={
                                            this.state.editViewDataItem.twoTierApprovalDate
                                                ? this.state.editViewDataItem.twoTierApprovalDate
                                                : undefined
                                        }
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Accepted By
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <TextBox
                                        disabled={true}
                                        value={
                                            this.state.editViewDataItem.accept_Date &&
                                                this.state.editViewDataItem.accepted_By
                                                ? this.state.editViewDataItem.accepted_By
                                                : ""
                                        }
                                    ></TextBox>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Accrual?
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.decisionTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeAccrualTypeSelect}
                                        value={
                                            this.state.editViewDataItem.isAccrual == true ||
                                                this.state.editViewDataItem?.isAccrual?.toString() == "Yes"
                                                ? "Yes"
                                                : "No"
                                        }
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust) ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-2 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                    Approved By
                                </label>
                                <div className="col-12 col-xl-6 pl-0">
                                    <TextBox
                                        disabled={true}
                                        value={
                                            this.state.editViewDataItem.approval_Date &&
                                                this.state.editViewDataItem.approved_By
                                                ? this.state.editViewDataItem.approved_By
                                                : ""
                                        }
                                    ></TextBox>
                                </div>
                            </div>
                            <div className="col-12 col-lg-2 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                2<sup>nd</sup> Approved By
                                </label>
                                <div className="col-12 col-xl-6 pl-0">
                                    <TextBox
                                        disabled={true}
                                        value={
                                                this.state.editViewDataItem.twoTierApprovedBy
                                                ? this.state.editViewDataItem.twoTierApprovedBy
                                                : ""
                                        }
                                    ></TextBox>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Pay Date
                                </label>
                                <div className="col-12 col-xl-5 pl-0">
                                    <DateBox
                                        onValueChanged={this.handleChangePayDate}
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                        value={
                                            this.state.editViewDataItem.pay_Date
                                                ? this.state.editViewDataItem.pay_Date
                                                : undefined
                                        }
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                                <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                    <Link
                                        to={{
                                            pathname: "/AddEditPaymentForm",
                                            state: {
                                                id: this.state.editViewDataItem.paymentId,
                                                cycleId: this.state.editViewDataItem.paymentCycleId,
                                                comesFrom: "ManageShift",
                                            },
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary btn--small"
                                            disabled={disableViewPaymentLink}
                                        >
                                            View Payment
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Non Standard
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.nonStandardTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeFeeNonStandardTypeSelect}
                                        value={
                                            this.state.editViewDataItem.non_Standard == "True"
                                                ? "Non - Standard"
                                                : this.state.editViewDataItem.non_Standard == "False"
                                                    ? "Standard"
                                                    : this.state.editViewDataItem.non_Standard
                                        }
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Bill Date
                                </label>
                                <div className="col-12 col-xl-5 pl-0">
                                    <DateBox
                                        onValueChanged={this.handleChangeBillDate}
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                        value={
                                            this.state.editViewDataItem.bill_Date
                                                ? this.state.editViewDataItem.bill_Date
                                                : undefined
                                        }
                                        disabled={
                                            readOnlyView ||
                                            !this.state.isClickedOnEdit ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                                <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                    <Link
                                        to={{
                                            pathname: "/BillingPageV2",
                                            state: {
                                                id: this.state.editViewDataItem.invoiceId
                                                    ? this.state.editViewDataItem.invoiceId.toString()
                                                    : "",
                                            },
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary btn--small"
                                            disabled={disableViewBillingLink}
                                        >
                                            View Bill
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Payment Request
                                </label>
                                <div className="col-12 col-xl-5 pl-0">
                                    <TextBox value={paymentRequestVal} disabled={true}></TextBox>
                                </div>
                                <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                    <Link
                                        className="approval-query-column"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            window.open(`/MatchedPopUpPage?filterBy=${paymentRequestId}`, "Related Payment Request/ Artist Job Notification",
                                                "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1050,height=400,left=100,top=100")
                                        }}
                                        to="#"
                                    >
                                        <button
                                            className="btn btn-primary btn--small"
                                            disabled={disableViewPaymentBtn}
                                        >
                                            View Request
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Originator
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <DropDownFromLookupTable
                                        lookupTypeIndex={LookupTypeIndexes.originator}
                                        onValueChanged={this.handleChangeOriginator}
                                        signal={this.state.createAdjustment}
                                        disableSignal={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                        value={this.state.editViewDataItem.originator && this.state.editViewDataItem.originator.id ? this.state.editViewDataItem.originator.id.toString() : ""}
                                        recordType="active"
                                    />
                                    <input
                                        data-testid={LookupTypeIndexes.originator}
                                        type="hidden"
                                        name={LookupTypeIndexes.originator}
                                        value={this.state.editViewDataItem.originator &&
                                            this.state.editViewDataItem.originator.id
                                            ? this.state.editViewDataItem.originator.id
                                            : 0
                                        }
                                    ></input>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Update / Correction
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.updateCorrectionDataSource}
                                        displayExpr="value"
                                        valueExpr="id"
                                        onValueChanged={this.handleChangeCorrectionUpdate}
                                        value={
                                            this.state.editViewDataItem.updateOrCorrection
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <LoadIndicator
                                    id="load-indicator"
                                    className="load-indicator--centered"
                                    height={40}
                                    width={40}
                                    visible={this.state.loadIndicatorVisible}
                                />
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 pr-0">
                                <div className="row">
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-6 text-left text-xl-right">
                                            Faster Payment
                                        </label>
                                        <div className="col-12 col-xl-5 pl-2">
                                        <SelectBox
                                            dataSource={this.fasterPaymentDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeFasterPaymentTypeSelect}
                                            value={
                                                this.state.editViewDataItem.fasterPayment != null &&
                                                    (this.state.editViewDataItem.fasterPayment == true ||
                                                        this.state.editViewDataItem.fasterPayment.toString() == "true" ||
                                                        this.state.editViewDataItem.fasterPayment.toString() == "Yes")
                                                    ? "Yes"
                                                    : "No"
                                            }
                                            disabled={
                                                this.state.readOnlyView || this.editAllowed || 
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                        />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 row mx-0 mt-3">
                                        <label className="card-form__label col-12 col-xl-7 text-left text-xl-right">
                                            Faster Payment Withdrawn
                                        </label>
                                        <div className="col-12 col-xl-5 pl-0">
                                        <SelectBox
                                            dataSource={this.fasterPaymentDataSource}
                                            displayExpr="value"
                                            valueExpr="value"
                                            onValueChanged={this.handleChangeFasterPaymentWithdrawnTypeSelect}
                                            value={
                                                this.state.editViewDataItem.fasterPaymentWithdrawn != null &&
                                                    (this.state.editViewDataItem.fasterPaymentWithdrawn == true ||
                                                        this.state.editViewDataItem.fasterPaymentWithdrawn.toString() == "true" ||
                                                        this.state.editViewDataItem.fasterPaymentWithdrawn.toString() == "Yes")
                                                    ? "Yes"
                                                    : "No"
                                            }
                                            disabled={
                                                this.state.readOnlyView || this.editAllowed || 
                                                (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                            }
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Advanced Payment
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.advancedPaymentDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeAdvancedPaymentTypeSelect}
                                        value={
                                            this.state.editViewDataItem.advancedpayment != null && (this.state.editViewDataItem.advancedpayment == true ||
                                                this.state.editViewDataItem.advancedpayment.toString() == "Yes")
                                                ? "Yes"
                                                : "No"
                                        }
                                        disabled={
                                            this.state.readOnlyView || this.editAllowed || 
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Early Pay
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.earlyPayDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeEarlyPayTypeSelect}
                                        value={
                                            this.state.editViewDataItem.earlyPay != null && (this.state.editViewDataItem.earlyPay == true ||
                                                this.state.editViewDataItem.earlyPay.toString() == "Yes")
                                                ? "Yes"
                                                : "No"
                                        }
                                        disabled={
                                            this.state.readOnlyView || this.editAllowed || 
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Hold Payment?
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.decisionTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeHoldPayment}
                                        value={
                                            this.state.editViewDataItem.holdPayment
                                        }
                                        disabled={
                                            this.state.readOnlyView || this.editAllowed || 
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Hard Approval?
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <SelectBox
                                        dataSource={this.decisionTypesDataSource}
                                        displayExpr="value"
                                        valueExpr="value"
                                        onValueChanged={this.handleChangeHardApproval}
                                        value={
                                            this.state.editViewDataItem.hardApproval
                                        }
                                        disabled={
                                            this.state.readOnlyView || this.editAllowed || 
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Bill Period
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <TextBox disabled={true} value={this.state.editViewDataItem.billPeriod}></TextBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            );
        }
    };
    renderAccrualPanel = () => {
        var readOnlyView: boolean = false;
        if (this.props.location && this.props.location.state && this.props.location.state.isReadOnly) {
            readOnlyView = this.props.location.state.isReadOnly;
        }
        var disableViewBillingLink: boolean = false;
        if (!this.state.editViewDataItem.invoiceId) {
            disableViewBillingLink = true;
        }
        if (this.state.isLoadFinancialPanel) {
            return (
                <section className="card card-form my-5">
                    <div className="card-body">
                        <h3 className="card-form__section-heading">Accruals</h3>
                        <hr className="horizontal_border_style"></hr>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Status
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <DropDownFromLookupTable
                                        lookupTypeIndex={LookupTypeIndexes.accrualStatus}
                                        onValueChanged={this.handleChangeAccrualStatusTypeSelect}
                                        signal={this.state.createAdjustment}
                                        disableSignal={
                                            this.props.location && this.props.location.state && this.props.location.state.isAmend ||
                                            this.state.isDisableStatusDropDown ||
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust) ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit)
                                        }
                                        value={
                                            this.state.editViewDataItem.accrual &&
                                                this.state.editViewDataItem.accrual.accrualStatusId
                                                ? this.state.editViewDataItem.accrual.accrualStatusId.toString()
                                                : ""
                                        }
                                    />
                                    <input
                                        data-testid={LookupTypeIndexes.accrualStatus}
                                        type="hidden"
                                        name={LookupTypeIndexes.accrualStatus}
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.accrualStatusId
                                                : 0
                                        }
                                    ></input>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Bill Date
                                </label>
                                <div className="col-12 col-xl-5 pl-0">
                                    <DateBox
                                        // onValueChanged={this.handleChangeBillDate}
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                        value={
                                            this.state.editViewDataItem.accrualBillableItemBillDate
                                                ? this.state.editViewDataItem.accrualBillableItemBillDate
                                                : undefined
                                        }
                                        disabled={
                                            readOnlyView ||
                                            !this.state.isClickedOnEdit ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust) ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit)
                                        }
                                    />
                                </div>
                                <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                    <Link
                                        to={{
                                            pathname: "/BillingPageV2",
                                            state: {
                                                id: this.state.editViewDataItem.invoiceId
                                                    ? this.state.editViewDataItem.invoiceId.toString()
                                                    : "",
                                            },
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary btn--small"
                                            disabled={disableViewBillingLink}
                                        >
                                            View Bill
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Accrual Item ID
                                </label>
                                <div className="col-12 col-xl-5 pl-0">
                                    <TextBox
                                        value={
                                            this.state.editViewDataItem.accrual &&
                                                this.state.editViewDataItem.accrual.id
                                                ? this.state.editViewDataItem.accrual.id.toString()
                                                : ""
                                        }
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                    <Link
                                        onClick={() =>
                                            this.viewSimpleShiftsForService(this.state.editViewDataItem.serviceTypeId)
                                        }
                                        to={{
                                            pathname: "/simpleShifts",
                                            state: {
                                                id: this.state.editViewDataItem.billableItemId,
                                            },
                                        }}
                                    >
                                        <button className="btn btn-primary btn--small">View Item</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Provider NET
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeProviderPayAccrualAmount}
                                        valueChangeEvent="keyup"
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.providerPayAmountAccrual
                                                : 0
                                        }
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust) ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit)
                                        }
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Event NET
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeFeeAccrualAmount}
                                        valueChangeEvent="keyup"
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.grossProfitAmountAccrual
                                                : 0
                                        }
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust) ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit)
                                        }
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Client NET
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeBillAccrualAmount}
                                        valueChangeEvent="keyup"
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.clientBillableAmountAccrual
                                                : 0
                                        }
                                        disabled={
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust) ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit)
                                        }
                                        onContentReady={this.onContentReadyNumberBox}
                                        step={0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Provider VAT
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.providerPayAmountTaxAccrual
                                                : 0
                                        }
                                        disabled={
                                            !this.state.isProviderTaxFieldVisible || this.editAllowed || 
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust) ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Event VAT
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.grossProfitAmountTaxAccrual
                                                : 0
                                        }
                                        disabled={
                                            !this.state.isFeeTaxFieldVisible ||
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust) ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Client VAT
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.clientBillableAmountTaxAccrual
                                                : 0
                                        }
                                        disabled={
                                            !this.state.isBillClientTaxFieldVisible ||
                                            this.state.readOnlyView ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isAdjust) ||
                                            (this.props.location && this.props.location.state && this.props.location.state.isEdit)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Provider GROSS
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.providerPayAmountTotalAccrual
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Event GROSS
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.grossProfitAmountTotalAccrual
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                                <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                    Client GROSS
                                </label>
                                <div className="col-12 col-xl-9 pl-0">
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={
                                            this.state.editViewDataItem.accrual
                                                ? this.state.editViewDataItem.accrual.clientBillableAmountTotalAccrual
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    };

    getCreatedAndUpdatedString = () => {
        let finalString = '';
        if(this.state.editViewDataItem.dateCreated){
          let formattedDate = moment.utc(this.state.editViewDataItem.dateCreated).format("Do MMM YYYY HH:mm:ss");
          let createdBy = '';
          if(this.state.editViewDataItem.createdBy){
            createdBy = this.state.editViewDataItem.createdBy;
          }
          finalString = `Created On ${formattedDate} ${createdBy?`by ${createdBy}`:''}`;
        }
        if(this.state.editViewDataItem?.clientAmendDateTime){
            if(finalString){
                finalString += ' / ';
            }
            let formattedDate = moment(this.state.editViewDataItem.clientAmendDateTime).format("Do MMM YYYY HH:mm:ss");
            finalString += `Client Amend Date - ${formattedDate}`;
        }
        if(this.state.editViewDataItem.lastUpdatedOnTZ){
            if(finalString){
                finalString += ' / ';
            }
            let formattedDate = moment(this.state.editViewDataItem.lastUpdatedOnTZ).format("Do MMM YYYY HH:mm:ss");
            let updatedBy = '';
            if(this.state.editViewDataItem.lastUpdatedBy){
                updatedBy = this.state.editViewDataItem.lastUpdatedBy;
            }
            finalString += `Last Updated On ${formattedDate} ${updatedBy?`by ${updatedBy}`:''}`;
          }
          if(finalString){
            finalString =` (${finalString})`;
          }
       return finalString;
    }

    getDateCreatedSection = () => {
        let show = false;
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            show = true;
        }
        if (show) {
            return (
                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                    <div className="row">
                        <div className="mx-0 mt-3">
                            <label className="card-form__label text-left text-xl-right">
                                Date Created
                            </label>
                        </div>
                        <div className="col-12 col-xl mx-0 mt-3">
                            <DateBox
                                // type='datetime'
                                type='date'
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.onHandleDateCreated}
                                value={
                                    this.state.editViewDataItem.dateCreated_UI
                                        ? this.state.editViewDataItem.dateCreated_UI
                                        : undefined
                                }
                                onContentReady={this.onContentReadyDateBox}
                            />
                        </div>
                        <div className="col-12 col-xl mx-0 mt-3">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="00:00"
                                useMaskBehavior={true}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.handleChangeDateCreatedTime}
                                value={
                                    this.state.editViewDataItem.dateCreatedTime
                                        ? this.state.editViewDataItem.dateCreatedTime
                                        : undefined
                                }
                                onContentReady={this.onContentReadyDateBox}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return;
    }

    onHandleDateCreated = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            //let dateValue = new Date(dxValueChange.value).toJSON();
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    dateCreated_UI: dxValueChange.value
                },
            });
        }
    }

    handleChangeDateCreatedTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            //let dateValue = new Date(dxValueChange.value).toJSON();
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    dateCreatedTime: dxValueChange.value
                },
            });
        }
    }

    renderButtonSection = () => {
        return (
            <>
                <section>
                    {this.state.isDisableApplyButton ? (
                        <span className="unscheduled-shift">
                            {this.state.onlyViewMessage.length > 0 ? this.state.onlyViewMessage[0] : null}
                        </span>
                    ) : null}
                    {this.state.IsShowSuccess ? <span className="text-success">Data Saved Successfully!</span> : null}
                    {this.state.IsShowError && this.state.errorMessage != null ? (
                        <span className="unscheduled-shift">
                            <ul>
                                {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                    return (
                                        <li key={uniqueKey}>
                                            {item.columnName}: {item.errorMessage}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    ) : null}
                    {this.state.validationMessage != "" ? (
                        <>
                            {" "}
                            <br />{" "}
                            <span className="text-danger font-weight-bold justify-content-center">
                                {this.state.validationMessage}
                            </span>{" "}
                        </>
                    ) : null}
                </section>
                <section className="card-form">
                    {this.state.editViewDataItem.billableItemId !== "" ?
                        <>
                            <div className="row">
                                <div className="col-12 col-lg row mx-0 pr-0 mt-3">
                                    <b> <div className="mx-4 mt-3">ID : {this.state.editViewDataItem.billableItemId} {this.getCreatedAndUpdatedString()}</div> </b>
                                </div>
                                {this.getDateCreatedSection()}
                            </div>

                        </> 
                        : null}
                </section>
                <section className="card-form__button-container">
                    {this.state.readOnlyView ? null : (
                        <div className="col-12 col-lg-4 col-xl-2">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                disabled={this.state.isDisableApplyButton || this.state.disabledApplybutton}
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.checkAmounts();
                                }}
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faCheck} />
                                </span>
                                {this.props.location && this.props.location.state && this.props.location.state.isAdjust ? "Create Adjustment" : "Apply"}
                            </button>
                        </div>
                    )}
                    <div className="col-12 col-lg-4 col-xl-2">
                        <button
                            className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onCancel();
                            }}
                        >
                            Close
                        </button>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-2">
                        <button
                            className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onSubmitQuery();
                            }}
                        >
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faQuestion} />
                            </span>
                            Raise Query
                        </button>
                    </div>
                </section>
            </>
        );
    };

    onClickEdit = () => {
        this.setState({
            readOnlyView: false,
        });
    };
    //#region render Component
    render() {
        var editAllowed: boolean = false;
        if (
            this.state.editViewDataItem.editAllowed == "True" &&
            this.state.readOnlyView &&
            (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager))
        ) {
            editAllowed = false;
        } else {
            editAllowed = true;
        }
        var pageTitle: string = "";
        if (this.props.location) {
            if (this.props.location.state && this.props.location.state.id == 0) {
                pageTitle = "Manage Shifts - Add";
            } else if (this.props.location.state && this.props.location.state.isAdjust) {
                pageTitle = "Manage Shifts - Adjustment";
            } else if (
                (this.props.location.state && this.props.location.state.isEdit) ||
                (!this.state.readOnlyView && this.props.location.state && !this.props.location.state.isMasterEdit)
            ) {
                pageTitle = "Manage Shifts - Edit";
            } else if (this.state.readOnlyView) {
                pageTitle = "Manage Shifts - View";
            } else if (this.props.location.state && this.props.location.state.isMasterEdit) {
                pageTitle = "Manage Shifts - Master Edit";
            }
        }

        return (
            <LightModePageTemplate>
                <div className="page-content">
                    <div className="col-12">
                        <h3 className="card-form__page-heading">{pageTitle}</h3>
                        <section className="card-form__button-container px-2" hidden={this.state.loadPopupVisible}>
                            <div className="col-12 col-lg-4 col-xl-6"></div>
                            <div className="col-12 col-lg-4">
                                <div className="row p-3 p-xl-0">
                                    <label className="card-form__label col-12 col-lg-4 text-left text-xl-right">
                                        Item Type
                                    </label>
                                    <div className="col-12 col-lg-8 pl-0">
                                        <SelectBox
                                            disabled={true}
                                            dataSource={this.state.itemTypeLookUp}
                                            displayExpr="value"
                                            valueExpr="id"
                                            value={
                                                this.props.location && this.props.location.state && this.props.location.state.id == 0 &&
                                                    this.state.itemTypeLookUp.length > 0 &&
                                                    this.state.isTypeChanged == false
                                                    ? this.state.itemTypeLookUp[0].id
                                                    : this.state.editViewDataItem.itemTypeId
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="row" hidden={this.state.loadPopupVisible}>
                            <div className="w-50 mx-auto">
                                <LoadIndicator
                                    id="load-indicator"
                                    className="load-indicator--centered"
                                    height={50}
                                    width={50}
                                    visible={!this.state.isLoadFinancialPanel}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-50 mx-auto">
                                <LoadIndicator
                                    id="load-indicator"
                                    className="load-indicator--centered"
                                    height={50}
                                    width={50}
                                    visible={this.state.loadPopupVisible}
                                />
                            </div>
                        </div>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <section hidden={this.state.loadPopupVisible}>
                            {this.renderPersonnelShiftPanel()}
                            {this.renderEquipmentShiftPanel()}
                            {this.renderWeeklyCleaningShiftPanel()}
                            {this.renderAdditionalCleaningShiftPanel()}
                            {this.renderEntertainmentShiftPanel()}
                            {this.renderPersonnelAdjustmentPanel()}
                            {this.renderEquipmentAdjustmentPanel()}
                            {this.renderWeeklyCleaningAdjustmentPanel()}
                            {this.renderEntertainmentAdjustmentPanel()}
                            {this.renderFinancialPanel()}
                            {this.state.editViewDataItem?.isAccrual?.toString() == "Yes" ||
                                this.state.editViewDataItem.isAccrual == true ? (
                                this.renderAccrualPanel()
                            ) : (
                                <></>
                            )}
                            {this.renderButtonSection()}
                        </section>
                        <Popup
                            visible={this.state.showWarningPopup}
                            onHiding={this.hideWarningPopup}
                            dragEnabled={false}
                            hideOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title={"WARNING: ZERO AMOUNTS"}
                            resizeEnabled={true}
                            width={"30%"}
                            height={300}                        
                        >
                            <ScrollView width='100%' height='100%'>
                                <div>
                                    <div className="text-center">Zero Amounts are present - please continue if expected otherwise please correct?</div>
                                    <div className="text-center pt-3"><b>Provider Net</b> is {this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy == 0 ? <b className="color-red">£{this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy}</b> : <b>£{this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy}</b>}</div>
                                    <div className="text-center pt-3"><b>Fee Net</b> is {this.state.editViewDataItem.fee_Amount_Local_Ccy == 0 ? <b className="color-red">£{this.state.editViewDataItem.fee_Amount_Local_Ccy}</b> : <b>£{this.state.editViewDataItem.fee_Amount_Local_Ccy}</b>}</div>
                                    <div className="text-center pt-3"><b>Client Net</b> is {this.state.editViewDataItem.client_Billable_Amount_Local_Ccy == 0 ? <b className="color-red">£{this.state.editViewDataItem.client_Billable_Amount_Local_Ccy}</b> : <b>£{this.state.editViewDataItem.client_Billable_Amount_Local_Ccy}</b>}</div>
                                </div>
                                <div className="row px-3">
                                        <div className="col-12 col-xl-6 pl-0">
                                            <button
                                                className="btn btn-primary btn--large mt-3"
                                                type="submit"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.onSubmit();
                                                }}
                                            >
                                                CONTINUE?
                                            </button>
                                        </div>
                                        <div className="col-12 col-xl-6 pl-0">
                                            <button
                                                className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.hideWarningPopup();
                                                }}
                                            >
                                                CLOSE
                                            </button>
                                        </div>
                                    </div> 
                            </ScrollView>
                        </Popup>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

//#endregion

export default withTranslation()(AddEditFormV2);