import React, { Component } from "react";
//import disableBrowserBackButton from 'disable-browser-back-navigation';

interface InvalidUserPageComponentProps  {
    location: any;
    history: any;
}
interface ThankYouPageComponentState {
}
class InvalidUserPage extends React.Component<InvalidUserPageComponentProps> {
    componentDidMount() {
        // Prevent user from using the browser back button to get back into the registration form
        window.addEventListener("popstate", () => {
            this.props.history.go(1);
        });
    }

    render() {
        return (
            <div className="artist-signup__login__hero-image">
                <div className="artist-signup__login__container">
                    <div className="artist-signup__login__content">
                        <img className="artist-signup__login__icon" src="/assets/images/logo-icon-white-landscape.svg" />
                        <div className="mt-5 p-5 artist-signup__login__options">
                            Unable to validate your account, please contact the Event Team.
                            <br></br>
                            <br></br>
                            Tel: 0208 669 6849 | Email: info@eventuk.co.uk
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default (InvalidUserPage);

