import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError } from "./BaseApiService";

const SCHEDULE_DATA_GET_API = "BillableItem/Scheduler";
const FINANCE_CHART_DATA_GET_API = "BillableItem/FinancialGraph";
const DASHBOARD_DATA_GET_API = "Dashboard";

export interface SchedulerItem {
    count: string;
    serviceType: string;
    serviceSubType: string;
    shiftStart: Date;
    shiftEnd: Date;
    clientId: string;
    venueId: string;
}

export const LandingPage = {
    SchedulePage: "Schedule",
    QueriesPage: "Queries",
};

export interface ResponsePeriodWeek {
    periodMatrixId: string;
    number: string;
    dateFrom: string;
    dateTo: string;
    budget: string;
    spend: string;
    current: string;
    itemToDate: string;
    invoiceId: string;
}

export interface ResponseFinancialItem {
    period: string;
    budget: string;
    spend: string;
    current: string;
    weeks: ResponsePeriodWeek[];
}

export interface PeriodWeek {
    periodMatrixId: string;
    number: string;
    dateFrom: string;
    dateTo: string;
    budget: number;
    spend: number;
    scheduleInfo: SchedulerItem;
    periodNumber: string;
    current: string;
    invoiceId: string;
}

export interface staticValues {
    serviceTypeId: string;
    serviceColor: string;
    clientId: string;
    venueId: string;
}

export interface periodDataSourceValues {}

export interface FinancialItem {
    serviceSpecificValue: staticValues;
    period: string;
    budget: number;
    spend?: number;
    current: string;
    weeks: PeriodWeek[];
}
export interface BudgetSpendBarChart {
    label: string;
    value: number;
}
// Fields required to manage state for financial chart
export interface ChartData {
    itemToDateSecurity: string;
    itemToDateCleaning: string;
    itemToDateEnt: string;
    itemToDateGard: string;
    itemToDateMain: string;
    itemToDateWind: string;
    itemToDateMisc: string;
    currentWeekSecurity: string;
    currentWeekCleaning: string;
    currentWeekEnt: string;
    currentWeekGard: string;
    currentWeekMain: string;
    currentWeekWind: string;
    currentWeekMisc: string;
    currentPeriodSecurity: string;
    currentPeriodCleaning: string;
    currentPeriodEnt: string;
    currentPeriodGard: string;
    currentPeriodMain: string;
    currentPeriodWind: string;
    currentPeriodMisc: string;
    periodDataSourceForSecurity: FinancialItem[];
    periodDataSourceForCleaning: FinancialItem[];
    periodDataSourceForEnt: FinancialItem[];
    periodDataSourceForGard: FinancialItem[];
    periodDataSourceForMain: FinancialItem[];
    periodDataSourceForWind: FinancialItem[];
    periodDataSourceForMisc: FinancialItem[];
    selectedPeriodSecurity: FinancialItem;
    selectedPeriodEnt: FinancialItem;
    selectedPeriodGard: FinancialItem;
    selectedPeriodMain: FinancialItem;
    selectedPeriodWind: FinancialItem;
    selectedPeriodMisc: FinancialItem;
    selectedPeriodCleaning: FinancialItem;
    yearToDateSpendSecurity: number;
    fullYearBudgetSecurity: number;
    yearToDateSpendCleaning: number;
    fullYearBudgetCleaning: number;
    yearToDateSpendEnt: number;
    fullYearBudgetEnt: number;
    yearToDateSpendGard: number;
    fullYearBudgetGard: number;
    yearToDateSpendMain: number;
    fullYearBudgetMain: number;
    yearToDateSpendWind: number;
    fullYearBudgetWind: number;
    yearToDateSpendMisc: number;
    fullYearBudgetMisc: number;
    BudgetSpendBarChartSecurity: BudgetSpendBarChart[];
    BudgetSpendBarChartCleaning: BudgetSpendBarChart[];
    BudgetSpendBarChartEnt: BudgetSpendBarChart[];
    BudgetSpendBarChartGard: BudgetSpendBarChart[];
    BudgetSpendBarChartMain: BudgetSpendBarChart[];
    BudgetSpendBarChartWind: BudgetSpendBarChart[];
    BudgetSpendBarChartMisc: BudgetSpendBarChart[];
    overspend: number;
}

export const financeChartDataTypes = [
    { value: "budget", name: "Budget" },
    { value: "spend", name: "Spend" },
];
export interface DashBoardResponse {
    venue: {
        venueNameWOLocation: string;
        houseNumber: string;
        venueName: string;
        address: string;
        city: string;
        county: string;
        postCode: string;
        phone: string;
        email: string;
        generalManagerId: number;
        contactId: number;
        division: string;
        generalManager: {
            name: string;
            mobileTelephone: string;
        };
        contact: {
            name: string;
            mobileTelephone: string;
            primarySecurityContactId: number;
            securityProviderBusinessEntityID: number;
            securityProviderBusinessEntity: {
                officePhone: string;
                oooProtocol: string;
                shortName: string;
            };
            contractCleaningBusinessEntityID: number;
            contractCleaningBusinessEntity: {
                officePhone: string;
                oooProtocol: string;
                shortName: string;
            };
            primarySecurityContact: {
                name: string;
                mobileTelephone: string;
            };
            primaryCleaningContact: {
                name: string;
                mobileTelephone: string;
                email: string;
            };
        };
    };
    entertainmentShifts: string;
    entertainmentShiftDetails: [
        {
            name: string;
            jobRole: string;
            shiftTime: string;
            contactNo: string;
            scheduledStartTime: string;
            scheduledFinishTime: string;
        }
    ];
    cleaningShifts: string;
    cleaningShiftDetails: { 
        hoursTotal:string,
        isVariable: string,
        message: string,
        dailyBreakdownShifts:[
                {​​​​​​​​
                    dayOfWeek: string;
                    hours: string;
                    isToday: string;
                }​​​​​​​​
            
            ]
    };
    doorSupervisionShifts: string;
    doorSupervisionShiftDetails: [
        {
            name: string;
            jobRole: string;
            shiftTime: string;
            scheduledStartTime: string;
            scheduledFinishTime: string;
        }
    ];
    gardeningShifts: string,
    gardeningShiftDetails: [
        {
            provider: string,
            serviceSubTypeLookUpName: string,
            serviceTypeLookUpName: string,
            description: string,
            clientBillableAmountLocal: string,
        }
    ],
    maintenanceShifts: string,
    maintenanceShiftDetails: [
        {
            provider: string,
            serviceSubTypeLookUpName: string,
            serviceTypeLookUpName: string,
            description: string,
            clientBillableAmountLocal: string,
        }
    ],
    miscelleanousShifts: string,
    miscelleanousShiftDetails: [
        {
            provider: string,
            serviceSubTypeLookUpName: string,
            serviceTypeLookUpName: string,
            description: string,
            clientBillableAmountLocal: string,
        }
    ],
    windowCleaningShifts: string,
    windowCleaningShiftDetails: [
        {
            provider: string,
            serviceSubTypeLookUpName: string,
            serviceTypeLookUpName: string,
            description: string,
            clientBillableAmountLocal: string,
        }
    ],
}

class ScheduleService {
    getScheduleData(
        clientId: string,
        venueId: string,
        minDateValue: string,
        maxDateValue: string
    ): Promise<AxiosResponse<any>> {
        return _get(
            `${SCHEDULE_DATA_GET_API}?clientId=${clientId}&venueId=${venueId}&dateFrom=${minDateValue}&dateTo=${maxDateValue}`
        );
    }
    getFinancialChartData(clientId: string, venueId: string, serviceType: string): Promise<AxiosResponse<any>> {
        return _get(`${FINANCE_CHART_DATA_GET_API}?venueId=${venueId}&clientId=${clientId}&serviceType=${serviceType}`);
    }
    getFinancialDashBoardData(clientId: string, venueId: string): Promise<AxiosResponse<any>> {
        return _get(`${DASHBOARD_DATA_GET_API}?venueId=${venueId}&clientId=${clientId}`);
    }
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default ScheduleService;
