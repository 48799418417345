import React from "react";
import { LoadPanel } from "devextreme-react";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import UserService, { RoleGroupNames } from "../../services/UserService";
import outstandingActionsUtilities, {
    AddEditFormData,
    itemTypes,
    feedbackScoreType,
    feedbackRequestData,
} from "./OutstandingActionsUtilities";
import OutstandingApprovalService, {
    BillableItemResponse,
    Status,
    BillableItemSubmitRequest,
} from "../../services/OutstandingApprovalService";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";
import LookupService, { LookupTypeItem } from "../../services/LookupService";
import AddEditPopUpService, { RaiseQueryFormData } from "../../services/AddEditPopUpService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import gridUtils, { currencyTypes, currencyTypeToSymbolMatrix } from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";
import CardWrapper3Part from "../Cards/CardWrapper3Part";
import CardHeaderSection from "../Cards/CardSections/CardHeaderSection";
import CardCommentsSection from "../Cards/CardSections/CardCommentsSection";
import CardConfirmCostSection from "../Cards/CardSections/CardConfirmCostSection";
import CardRecommendSection from "../Cards/CardSections/CardRecommendSection";
import { withTranslation, WithTranslation } from "react-i18next";
import GlobalService from "../../services/GlobalService";

// all of this info will be needed to populate a card
// not sure if status (late/on time) will come from db or be dervied here
interface EntertainmentApprovalCardV2Props extends WithTranslation {
    outstandingCardData: BillableItemResponse;
    renderCards: (billableItemId: string, serviceTypeId: string) => void; // A callback to the card List component once the Shift has been accepted or Approved.
    configuarationValueIndex?: string[];
    totalNumber?: number;
    serialNumber?: number;
    configurationValueArray: any;
    isApproveAll?: boolean;
    setCost: (currentProviderAmount: number, previousProviderAmount: number,
        currentBillableHours: string, previousBillableHours: string,
        cardSectionVal: string, acoordionNo: number, totalQueriedCost: string,
        totalNonQueriedCost: string, totalQueriedMinutes: string, totalNonQueriedMinutes: string, billableItemId: string,
        serviceTypeId: string, summaryBreakDownKey: number, navigatedFrom: string,
        paymentId: string, invoiceId: string, billableQuantity: string) => void;
    cardSection?: string;
    accordionNo?: number;
    totalNonQueriedCost?: string;
    totalNonQueriedMinutes?: string;
    totalQueriedCost?: string;
    totalQueriedMinutes?: string;
}

interface EntertainmentApprovalCardV2State {
    editViewDataItem: BillableItemResponse;
    convertedEditViewDataItem: AddEditFormData;
    feedbackRequestItem: feedbackRequestData;
    disableBilableStartFinishFields: boolean;
    cardText: string;
    buttonText: string;
    queryButtonText: string;
    errorMessage: string;
    queryItem: RaiseQueryFormData;
    loadPanelVisible: boolean;
    equipmentText: string;
    isProvider: boolean;
    toggleFeedbackBox: boolean;
    isThumbsUpClicked: boolean;
    isThumbsDownClicked: boolean;
    calculationErrorMessage: [];
    disableApproveButton: boolean;
    disableQueryButton: boolean;
    disableSaveButton: boolean;
    feedbackComments: string;
    isSuccssApproved?: boolean;
}

class EntertainmentApprovalCard extends React.Component<EntertainmentApprovalCardV2Props> {
    lookupService: LookupService;
    state: EntertainmentApprovalCardV2State;
    outstandingApprovalService: OutstandingApprovalService;
    utils: outstandingActionsUtilities;
    addEditUtils: addEditPopupUtils;
    sharedUtils: sharedUtils;
    editViewService: AddEditPopUpService;
    gridUtils: gridUtils;
    //service that would post the data back to the server.
    constructor(props: EntertainmentApprovalCardV2Props) {
        super(props);
        //Initialize services
        this.lookupService = new LookupService();
        this.outstandingApprovalService = new OutstandingApprovalService();
        this.utils = new outstandingActionsUtilities();
        this.sharedUtils = new sharedUtils();
        this.addEditUtils = new addEditPopupUtils();
        this.editViewService = new AddEditPopUpService();
        this.gridUtils = new gridUtils();
        //Initialize the service.
        this.outstandingApprovalService = new OutstandingApprovalService();
        // var convertedEditViewDataItem = this.utils.initializeBillableItem();
        var initializedBillableItem = this.utils.initializeBillableItem();
        var initializedfeedbackItem = this.utils.initializeFeedbackRequestItem(
            this.props.outstandingCardData.billableItemId
                ? this.props.outstandingCardData.billableItemId.toString()
                : "",
            this.props.outstandingCardData
        );
        var convertedQueryFormData = this.addEditUtils.initializeQueryFormData(
            this.props.outstandingCardData.billableItemId
                ? this.props.outstandingCardData.billableItemId.toString()
                : "",
            this.props.outstandingCardData.venueId,
            "",
            "Shift / Item Status Query",
            ""
        );
        this.state = {
            editViewDataItem: this.props.outstandingCardData,
            convertedEditViewDataItem: initializedBillableItem,
            feedbackRequestItem: initializedfeedbackItem,
            disableBilableStartFinishFields: false,
            cardText: "",
            buttonText: "Approve",
            queryButtonText: "Raise Query",
            errorMessage: "",
            queryItem: convertedQueryFormData,
            loadPanelVisible: false,
            equipmentText: "Cleaning works carried out",
            isProvider: false,
            toggleFeedbackBox: false,
            isThumbsUpClicked: false,
            isThumbsDownClicked: false,
            calculationErrorMessage: [],
            disableApproveButton: this.props.isApproveAll ? this.props.isApproveAll : false,
            disableQueryButton: false,
            disableSaveButton: false,
            feedbackComments: "",
            isSuccssApproved: false
        };
    }

    componentDidMount() {
        var convertedDataSource = this.utils.convertBillableItemResponeToFormData(this.state.editViewDataItem);
        var feedBackType: string = "";
        this.setState({
            convertedEditViewDataItem: convertedDataSource,
        });
        if (convertedDataSource.feedback) {
            if (convertedDataSource.feedback.feedbackScore == feedbackScoreType.thumbsUp) {
                feedBackType = "thumbsUp";
            }
            else if (convertedDataSource.feedback.feedbackScore == feedbackScoreType.thumbsDown) {
                feedBackType = "thumbsDown";
            }
            this.setState({
                toggleFeedbackBox: true,
                feedbackComments: convertedDataSource.feedback.feedbackComments,
                isThumbsUpClicked: feedBackType == "thumbsUp" ? true : false,
                isThumbsDownClicked: feedBackType == "thumbsDown" ? true : false,
            })
        }
        this.disableRoleBasedFields(convertedDataSource.itemTypeId);
        this.enableDisableQueryApproveButton(convertedDataSource);
    }

    enableDisableQueryApproveButton = (billableItem: AddEditFormData) => {
        var isPayment: boolean = false;
        if (billableItem && billableItem.paymentId) {
            isPayment = true;
        }
        if (billableItem.billableStatusLookupId == Status.Queried) {
            this.setState({
                disableQueryButton: true,
                queryButtonText: "Queried",
                disableApproveButton: true
            })
        }
        else if (billableItem.isAccrual && !billableItem.paymentId && !billableItem.approval_Date ||
            !billableItem.isAccrual && !billableItem.approval_Date && !billableItem.invoiceId) {
            this.setState({
                disableApproveButton: false
            });
        } else if (billableItem.approval_Date) {
            this.setState({
                disableApproveButton: true,
                disableSaveButton: true,
                buttonText: "Approved"
            });
        } else {
            this.setState({
                disableApproveButton: true
            });
        }
        if (isPayment) {
            this.setState({
                disableApproveButton: true,
                disableSaveButton: true,
                buttonText: "Paid"
            })
        }
    }

    disableRoleBasedFields = (itemTypeId: string) => {
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) || itemTypeId == "12" || itemTypeId == "14") {
            this.setState({
                webApprovalSecurityHeader: "Please accept the above changes or raise a query if required",
                buttonText: "Accept",
                equipmentText: "Equipment supplied to",
            });
        }
    };

    //Arrow functions doesn't need to be bind in the constructor, the context of "this" is set inside of them.
    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            convertedEditViewDataItem: {
                ...this.state.convertedEditViewDataItem,
                comments: dxValueChange.value,
            },
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
                serviceTypeId: this.state.editViewDataItem.serviceTypeId,
                clientId: this.state.editViewDataItem.clientId,
                venueId: this.state.editViewDataItem.venueId
            },
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
    };

    handleChangeFeedback = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            feedbackRequestItem: {
                ...this.state.feedbackRequestItem,
                feedbackComments: dxValueChange.value,
            },
            feedbackComments: dxValueChange.value
        });
    };

    handleSubmit = () => {
        var isBillableFieldsBlank: boolean = true;
        var acceptanceDate = undefined;
        var approvalDate = undefined;
        var statusId: string = "";
        var isAmend: boolean = false;
        var isAbsent: string = "";
        var isNoCharge: string = "";

        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) || this.state.convertedEditViewDataItem.itemTypeId == "12" || this.state.convertedEditViewDataItem.itemTypeId == "14") {
            acceptanceDate = new Date();
            statusId = Status.Accepted;
        } else {
            approvalDate = new Date();
            statusId = Status.Approved;
        }

        let feedbackSubmissionError: string = "";
        feedbackSubmissionError = this.feedbackErrorCheckFunction();
        if (feedbackSubmissionError.length > 0) {
            this.setState({
                errorMessage: feedbackSubmissionError,
            });
        } else {
            this.setState({
                errorMessage: "",
                loadPanelVisible: true,
            });
            var billableItemSubmitRequest = this.utils.convertFormDataToBillableItemRequest(
                this.state.convertedEditViewDataItem,
                statusId,
                isBillableFieldsBlank,
                acceptanceDate,
                approvalDate,
                isAbsent,
                isNoCharge,
                isAmend,
                "",
                "ApprovalCard"
            );
            var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
            billableItemSubmitRequestArr.push(billableItemSubmitRequest);

            this.outstandingApprovalService
                .uploadEditAddData(billableItemSubmitRequestArr)
                .then(this.handleSubmitSuccess)
                .catch((err) => {
                    this.setState({
                        calculationErrorMessage:
                            err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of approval.
                        loadPanelVisible: false,
                    });
                    var respMessage: string = "uploadEditAddData failed with response: " + JSON.stringify(err);

                    if (!this.outstandingApprovalService.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                })
                .finally(() => {
                    this.hideLoadpanel();
                });
        }
    };

    handleSave = () => {
        var isAmend: boolean = false;
        var isAbsent: string = "";
        var isNoCharge: string = "";

        let feedbackSubmissionError: string = "";
        feedbackSubmissionError = this.feedbackErrorCheckFunction();
        if (feedbackSubmissionError.length > 0) {
            this.setState({
                errorMessage: feedbackSubmissionError,
            });
        } else {
            this.setState({
                errorMessage: "",
                loadPanelVisible: true,
            });

            var billableItemSubmitRequest = this.utils.convertFormDataToBillableItemRequest(
                this.state.convertedEditViewDataItem,
                "",
                false,
                undefined,
                undefined,
                isAbsent,
                isNoCharge,
                isAmend,
                "",
                "ApprovalCard"
            );
            var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
            billableItemSubmitRequestArr.push(billableItemSubmitRequest);

            this.outstandingApprovalService
                .uploadEditAddData(billableItemSubmitRequestArr)
                .then(this.handleSaveSuccess)
                .catch((err) => {
                    this.setState({
                        calculationErrorMessage:
                            err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of approval.
                        loadPanelVisible: false,
                    });
                    var respMessage: string = "uploadEditAddData failed with response: " + JSON.stringify(err);

                    if (!this.outstandingApprovalService.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                })
                .finally(() => {
                    this.hideLoadpanel();
                });
        }
    }

    handleSaveSuccess = () => {
        if (this.state.feedbackRequestItem.feedbackScore || this.state.feedbackRequestItem.feedbackComments) {
            this.outstandingApprovalService
                .submitFeedBack(this.state.feedbackRequestItem)
                .then(this.handleFeedbackSubmissionSuccess)
                .catch(this.handleFeedbackSubmissionError);

        }
        else {
            this.hideLoadpanel();
        }
    };

    //Submit the feedback only when the promise resolves. In other words an entry to the feedback table is dependent on a corressponding entry into the BillableItem table.
    handleSubmitSuccess = () => {
        if (this.state.feedbackRequestItem.feedbackScore || this.state.feedbackRequestItem.feedbackComments) {
            this.outstandingApprovalService
                .submitFeedBack(this.state.feedbackRequestItem)
                .then(this.handleFeedbackSubmissionSuccess)
                .catch(this.handleFeedbackSubmissionError);

        }

        this.setState({
            disableApproveButton: true,
            isSuccssApproved: true,
            disableSaveButton: true,
            buttonText: "Approved"
        });
        this.hideLoadpanel();
        this.props.renderCards(
            this.state.convertedEditViewDataItem.billableItemId,
            this.state.convertedEditViewDataItem.serviceTypeId
        );

    };

    handleFeedbackSubmissionSuccess = () => {
        this.hideLoadpanel();
    };

    handleFeedbackSubmissionError = () => {
        this.hideLoadpanel();
    };

    feedbackErrorCheckFunction = () => {
        const { feedbackScore } = this.state.feedbackRequestItem;
        let errorMessage: string = "";
        let commentsField: string = this.state.feedbackRequestItem.feedbackComments.trim();
        if (commentsField.length === 0 && feedbackScore == feedbackScoreType.thumbsDown) {
            errorMessage = "Please leave a feedback below";
        }
        return errorMessage;
    };

    onSubmitQuery = () => {
        let querySubmissionError: boolean = true;
        querySubmissionError = this.sharedUtils.validationCheckFunction(this.state.convertedEditViewDataItem.comments);
        if (!querySubmissionError) {
            this.setState({
                errorMessage: this.props.t("approvalCard.weeklyCleaning.queryText.error"),
            });
        } else {
            this.setState({
                errorMessage: "",
                loadPanelVisible: true,
            });
            this.editViewService
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
        var previousClientAmountVal = this.state.convertedEditViewDataItem.client_Billable_Amount_Local_Ccy;
        this.props.setCost(0, previousClientAmountVal, "", "",
            this.props.cardSection ? this.props.cardSection : "", this.props.accordionNo ? this.props.accordionNo : 0,
            this.props.totalQueriedCost ? this.props.totalQueriedCost : "", this.props.totalNonQueriedCost ? this.props.totalNonQueriedCost : "", this.props.totalQueriedMinutes ? this.props.totalQueriedMinutes : "", this.props.totalNonQueriedMinutes ? this.props.totalNonQueriedMinutes : "",
            this.props.outstandingCardData.billableItemId, this.props.outstandingCardData.serviceTypeId, 0, "query", this.state.editViewDataItem.paymentId, this.state.editViewDataItem.invoiceId, "");
    };

    handleQuerySubmissionSuccess = () => {
        this.setState({
            queryButtonText: "Queried",
            disableQueryButton: true,
            disableApproveButton: true

        });
        this.hideLoadpanel();
        this.props.renderCards(
            this.state.convertedEditViewDataItem.billableItemId,
            this.state.convertedEditViewDataItem.serviceTypeId
        );
    };

    handleQuerySubmissionFailure = (error: any) => {
        var respMessage: string =
            "uploadQueryFormData - query submission failed with response: " + JSON.stringify(error);

        if (!this.editViewService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.hideLoadpanel();
    };

    hideLoadpanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    renderCustomData = (data: LookupTypeItem) => {
        return <div className="bg-warning font-weight-bold"> {data.value} </div>;
    };

    //Function that would toggle the state of the TextBox
    handleThumbsUpIconClick = (event: any) => {
        this.setState({
            feedbackRequestItem: {
                ...this.state.feedbackRequestItem,
                feedbackScore: feedbackScoreType.thumbsUp,
            },
            toggleFeedbackBox: true,
            isThumbsUpClicked: true,
            isThumbsDownClicked: false,
        });
        event.preventDefault();
    };

    handleThumbsDownIconClick = (event: any) => {
        this.setState({
            feedbackRequestItem: {
                ...this.state.feedbackRequestItem,
                feedbackScore: feedbackScoreType.thumbsDown,
            },
            toggleFeedbackBox: true,
            isThumbsUpClicked: false,
            isThumbsDownClicked: true,
        });
        event.preventDefault();
    };

    clearErrorMessage = () => {
        this.setState({
            errorMessage: "",
        });
    };

    render() {
        var headerTextAsPerRole: string = "";
        var bodyTextAsPerRole: string = "";
        var recommendationText: string = "";
        var entertainmentHeaderText: string = "";
        var entertainmentCommentText: string = "";
        if (this.props.configurationValueArray.length > 0) {
            entertainmentHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalEntertainment_Header;
            })[0].value;
            entertainmentCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalEntertainment_Comment;
            })[0].value;
            recommendationText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalEntertainment_Recommend;
            })[0].value;
        }
        var currencySymbol: string = "£";
        var delimeter: string = "-";
        var confirmationText: string = "Confirmed by entertainer on ";
        var exceededSign: string = "+";
        const {
            role,
            client_Billable_Amount_Local_Ccy,
            shift,
            originalRate,
            provider,
            itemTypeId,
            service,
            billableRate,
            venuePostCode,
            venueName,
            serviceSubTypeId,
            client
        } = this.state.convertedEditViewDataItem; //Destructuring the state variables.
        var clientBillableAmount: string = this.gridUtils.convertDecimalToCurrency(
            client_Billable_Amount_Local_Ccy,
            currencyTypeToSymbolMatrix[currencyTypes.GBP]
        );
        var displayRate: string =
            billableRate > 0 ? `${exceededSign}${currencySymbol}${billableRate}` : `${currencySymbol}${billableRate}`;
        const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
        var itemType: boolean =
            itemTypeId == itemTypes.Original || itemTypeId == "" || itemTypeId == itemTypes.Acceptance ? true : false;
        const startDate = this.utils.convertddmmyyyyStringToCustomizedFormat(this.state.editViewDataItem.date).trim();
        var dateRangeField: string = `${startDate}`;
        var headerText: string[] = service.split(delimeter);
        let venue: string = `${venueName ? venueName : ""}${venueName ? (venuePostCode ? `, ${venuePostCode}` : "") : venuePostCode
            }`;
        if (isVenueManager) {
            if (itemType) {
                headerTextAsPerRole = entertainmentHeaderText;
                bodyTextAsPerRole = entertainmentCommentText;
            } else {
                headerTextAsPerRole =
                    "This is your adjusted entertainment cost for the above provider on the above specified date";
                bodyTextAsPerRole = "Please approve or raise a query";
            }
        }

        return (
            <>
                <CardWrapper3Part
                    type="entertainer"
                    typeTitle={headerText[0]}
                    enumeration={true}
                    cardIndex={this.props.serialNumber}
                    cardsTotal={this.props.totalNumber}
                    header={
                        <CardHeaderSection
                            title={provider}
                            venue={venue}
                            date={dateRangeField}
                            message={headerTextAsPerRole ? headerTextAsPerRole : ""}
                            adjustment={itemType}
                            errorMessage={this.state.errorMessage}
                            clearErrorMessage={this.clearErrorMessage}
                            calculationErrorMessage={this.state.calculationErrorMessage}
                            provider={provider}
                            serviceSubTypeId={serviceSubTypeId}
                            client={client}
                        />
                    }
                    body={
                        <>
                            <CardConfirmCostSection
                                isItemType={itemType}
                                shift={shift}
                                clientBillableAmount={this.sharedUtils.thousandsSeparator(clientBillableAmount)}
                                confirmationText={confirmationText}
                                acceptDate={this.state.editViewDataItem.accept_Date}
                                currencySymbol={currencySymbol}
                                originalRate={parseFloat(this.sharedUtils.thousandsSeparator(originalRate.toString()))}
                                displayRate={this.sharedUtils.thousandsSeparator(displayRate)}
                                billableRate={parseFloat(this.sharedUtils.thousandsSeparator(billableRate.toString()))}
                                role={role}
                            />
                            <div className="mt-3">
                                <CardRecommendSection
                                    isItemType={itemType}
                                    message={recommendationText ? recommendationText : ""}
                                    handleThumbsUpIconClick={this.handleThumbsUpIconClick}
                                    handleThumbsDownIconClick={this.handleThumbsDownIconClick}
                                    toggleFeedbackBox={this.state.toggleFeedbackBox}
                                    isThumbsUpClicked={this.state.isThumbsUpClicked}
                                    isThumbsDownClicked={this.state.isThumbsDownClicked}
                                    handleChangeFeedback={this.handleChangeFeedback}
                                    feedback={this.state.feedbackComments}
                                />
                            </div>
                        </>
                    }
                    footer={
                        // ToDo: wire up comments section, currently it looks good, but doesnt work
                        <CardCommentsSection
                            message={bodyTextAsPerRole ? bodyTextAsPerRole : ""}
                            comments={this.state.convertedEditViewDataItem.comments}
                            handleChangeComments={this.handleChangeComments}
                            buttonText={this.state.buttonText}
                            queryButtonText={this.state.queryButtonText}
                            onSubmitQuery={this.onSubmitQuery}
                            handleSubmit={this.handleSubmit}
                            handleSave={this.handleSave}
                            disableApproveButton={this.state.disableApproveButton}
                            disableQueryButton={this.state.disableQueryButton}
                            disableSaveButton={this.state.disableSaveButton}
                        />
                    }
                />
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
            </>
        );
    }
}

export default withTranslation()(EntertainmentApprovalCard);
