import {
    BillableItemQuery,
    BillableItemResponse,
    BillableItemSubmitRequest,
    Contact,
    queryTypeLookup,
    virtualBillableItemObject,
} from "../../services/AddEditPopUpService";
import { RaiseQueryFormData,Status } from "../../services/AddEditPopUpService";
import sharedUtils from "./sharedUtilities";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import { LookupTypeItem, UnitOfMeasureTypes } from "../../services/LookupService";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";
import { serviceTypes } from "../../services/FileService";
import GlobalService from "../../services/GlobalService";
import { QuickShiftFormData } from "../QuickAddShift/QuickAddShiftUtils";

export type NullableDate = Date | null | undefined;
type NullableBooleanValue = boolean | null | undefined;

interface ConfigurationLookupValues {
    id: string[];
    value: string[];
}

/**
 * The possible values for a BusinessEntityTaxApplicableStatus.businessEntityType
 */
export const BusinessEntityTypes = {
    Client: "Client",
    Provider: "Provider",
    Company: "Company",
};
/**
 * Used on new shifts form to prepopulate tax applicable fields based on server-side business rules
 */
export interface BusinessEntityTaxApplicableStatus {
    businessEntityID: string;
    businessEntityTypeLookUpId: string;
    businessEntityType: string;
    legalName: string;
    shortName: string;
    businessEntityLegalStatusLookUpId: string;
    businessEntityLegalStatus: string;
    companyNumber: string;
    taxApplicable: string;
    providerPaymentOffsetDays: string;
    taxRegistrationNumber: string;
    addressStreet1: string;
    addressStreet2: string;
    addressStreet3: string;
    state: string;
    country: string;
    postCode: string;
}

// Calculation request interface
export interface CalculationRequest {
    StartTime: string;
    ScheduledStartTime: string;
    originalStartTime: string;
    FinishTime: string;
    ScheduledFinishTime: string;
    originalFinishTime: string;
    Date: string;
    ClientId: string;
    ProviderId: string;
    Quantity: string;
    ScheduledQuantity: string;
    originalQuantity: string;
    Rate: string;
    fasterPay: boolean;
    fasterPaymentWithdrawn?: boolean;
    providerPayAmountLocal?: string;
    providerPayAmountLocalExtras?: string;
    providerPayAmountLocalWOExtras?: string;
    providerFasterPayFee?: string;
    providerFasterPayOriginalAmount?: string;
    ScheduledRate: string;
    originalRate: string;
    ServiceTypeId: string;
    ServiceSubTypeId: string;
    itemTypeId: string;
    multiplier: string;
    IsAccrual: boolean;
    billableItemId: string;
    ProviderNetOverride: string;
    MultiplierOverride: string;
    feeAmountOverride: string;
}

export const itemTypes = {
    AdjustmentClientDebitToProviderCredit: "21",
    AdjustmentProviderDebitToClientCredit: "22",
    ProviderCredit: "17",
    ProviderDebit: "18",
    Payable: "20",
    Receivable: "19",
    ClientCredit: "15",
    ClientDebit: "16",
    AdjustmentProviderDebit: "14",
    AdjustmentClientDebit: "13",
    Acceptance: "12",
    Adjustment: "11",
    Original: "10",
};
export interface AddEditFormData {
    absent: string;
    accept_Date: NullableDate;
    approval_Date: NullableDate;
    bill_Date: NullableDate;
    billable_Finish: NullableDate;
    billable_Minutes: number;
    billable_Start: NullableDate;
    billableItemId: string;
    billableStatusLookupId: string;
    check_In: NullableDate;
    check_In_Out: string;
    check_Out: NullableDate;
    client: string;
    client_Billable_Amount_Base_Ccy: number;
    client_Billable_Amount_Local_Ccy: number;
    client_Billable_Currency_Local_Ccy: string; // Ex: "GBP"
    client_Billable_Original_Amount_Local_Ccy: number;
    client_Billable_Tax_Base_Ccy: number;
    client_Billable_Tax_Local_Ccy: number;
    client_Billable_Total_Base_Ccy: number;
    client_Billable_Total_Local_Ccy: number;
    client_Tax_Applicable: string;
    clientId: string;
    comments: string;
    cover: string;
    date: NullableDate;
    dateTo: NullableDate;
    accrualBillableItemBillDate: NullableDate;
    equipmentQuantity: number;
    equipmentScheduleQuantity: number;
    fee_Amount_Base_Ccy: number;
    fee_Amount_Local_Ccy: number;
    fee_Currency_Local_Ccy: string;
    fee_Percentage_Base_Ccy: number;
    fee_Percentage_Local_Ccy: number;
    fee_Tax_Applicable: string;
    fee_Tax_Base_Ccy: number;
    fee_Tax_Local_Ccy: number;
    fee_Total_Base_Ccy: number;
    fee_Total_Local_Ccy: number;
    identification_Number: string;
    identificationTypeId: string;
    name: string;
    non_Standard: string;
    pay_Date: NullableDate;
    provider: string;
    provider_Pay_Amount_Base_Ccy: number;
    provider_Pay_Amount_Local_Ccy: number;
    provider_Pay_Currency_Local_Ccy: string; // Ex: "GBP"
    provider_Pay_Original_Amount_Local_Ccy: number;
    provider_Pay_Tax_Base_Ccy: number;
    provider_Pay_Tax_Local_Ccy: number;
    provider_Pay_Total_Base_Ccy: number;
    provider_Pay_Total_Local_Ccy: number;
    provider_Tax_Applicable: string;
    providerId: string;
    rate_Decimal: number;
    scheduleRate: number;
    providerPayAmountLocal: number | undefined,
    providerPayAmountLocalExtras: number | undefined,
    providerPayAmountLocalWOExtras: number | undefined,
    providerFasterPayFee: number | undefined,
    providerFasterPayOriginalAmount: number | undefined,
    role: string;
    service: string;
    serviceSubTypeId: string;
    serviceTypeId: string;
    shift_End: NullableDate;
    shift_Start: NullableDate;
    status: string;
    typeId: string;
    twoTierApprovalDate:NullableDate;
    twoTierApprovedBy:string;
    unscheduled: string;
    validationErrors: string;
    venueId: string;
    venue: string;
    isAmend: boolean;
    invoiceId: string;
    paymentId: string;
    itemTypeId: string;
    itemTypeValue: string;
    paymentCycleId: string;
    originalBillableStartTime: NullableDate;
    originalBillableFinishTime: NullableDate;
    originalBillableMinutes: number;
    originalRate: number;
    equipmentOriginalQuantity: number;
    noCharge: string;
    childRecordId: string;
    parentRecordId: string;
    internalComments: string;
    Recalculate: boolean;
    requestApprover: string;
    requestDate: NullableDate;
    otherDescription: string;
    tenseFlag: string;
    scheduledMinutes: number;
    equipmentBillableQuantity: number;
    billableRate: number;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
    providerNetOverride: string;
    multiplierOverride: string;
    feeAmountOverride: string;
    performanceInformation: string;
    additionalInformation: string;
    genre: string;
    equipmentRequired: string;
    description?: string;
    shiftCompleteAcknowledgmentDescription?: string;
    providerPayAmountLocalExtrasDescription?: string;
    shiftCompleteAcknowledgment: NullableBooleanValue;
    shiftCompleteAcknowledgmentDateTime: NullableDate;
    shiftCompleteAcknowledgmentTime: NullableDate;
    reoccurance: string;
    requestId: string;
    contractHours: {
        monday: string;
        tuesday: string;
        wednesday: string;
        thursday: string;
        friday: string;
        saturday: string;
        sunday: string;
        totalHours: string;
        variableHours: string;
    };
    editAllowed: string;
    billPeriod: string;
    expectedFee: number;
    approved_By: string;
    accepted_By: string;
    hardApproval: string;
    holdPayment: string;
    isAccrual: boolean;
    accrual: {

        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null,
    lastupdatedOn: string,
    lastUpdatedOnTZ:string,
    lastUpdatedBy:string,
    dateCreated:string,
    dateCreated_UI:string,
    dateCreatedTime:NullableDate,
    createdBy:string,
    clientAmendDateTime:NullableDate,
    paymentRequestId: string,
    paymentRequestDate: NullableDate,
    paymentRequest: {
        id: string,
        fasterPayment: string,
        description: string
    } | null,
    workerStatusId: string,
    workerStatusValue: string,
    venueName: string,
    fasterPayment: boolean,
    fasterPaymentWithdrawn: boolean,
    advancedpayment: boolean,
    earlyPay: boolean,
    originator: {
        id: number,
        value: string
    } | null,
    batchId: string,
    childRecordItemType: string,
    clientAmount: string,
    venuePostCode: string,
    venueTownCity: string,
    timeDecimal: string,
    timeDiffIndicator: string,
    quantityDiffIndicator: string,
    gigTenseFlag: string,
    scheduled_Hours_Units: string,
    imageLink: string,
    previousHoursRunningTotal: string,
    previousQuantityRunningTotal: string,
    entertainmentDateInfo: string,
    address: string,
    venue_Phone: string,
    contacts: Contact | null,
    rateDiffIndicator: string,
    queries: BillableItemQuery | null,
    feedback: string,
    paymentRequestID: string,
    paymentRequestStatusID: string,
    paymentRequestStatus: string,
    isUserAmendable: boolean,
    scheduledStartTime: string,
    scheduledFinishTime: string,
    billableStartTime: string,
    billableFinishTime: string,
    serviceSubTypeValue: string,
    typeValue: string,
    updateOrCorrection: string,
}

export interface virtualBillableItemFormData {
    billable_Start: NullableDate;
    billable_Finish: NullableDate;
    billable_Minutes: number;
    rate_Decimal: number;
    name: string;
    identification_Number: string;
    noCharge: string;
    cover: string;
    absent: string;
    equipmentQuantity: number;
    providerId: string;
    performanceInformation: string;
    typeId: string;
    genre: string;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
    equipmentRequired: string;
    shift_End: NullableDate;
    shift_Start: NullableDate;
    otherDescription: string;
}

export default class addEditPopupUtils {
    //Declare the shared utils function here.
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializeBillableItem = this.initializeBillableItem.bind(this);
        this.convertBillableItemResponeToFormData = this.convertBillableItemResponeToFormData.bind(this);
        this.convertBillableItemResponeToVirtualFormData = this.convertBillableItemResponeToVirtualFormData.bind(this);
        this.convertFormDataToBillableItemRequest = this.convertFormDataToBillableItemRequest.bind(this);
    }

    initializeBillableItem(idFromGrid: string): AddEditFormData {
        var formData: AddEditFormData = {
            absent: "",
            accept_Date: undefined,
            approval_Date: undefined,
            bill_Date: undefined,
            billable_Finish: this.utils.convertStringToTime("00:00"),
            billable_Minutes: 0,
            billable_Start: this.utils.convertStringToTime("00:00"),
            billableItemId: idFromGrid, // INIT via param idFromGrid
            billableStatusLookupId: "",
            check_In: this.utils.convertStringToTime("00:00"),
            check_In_Out: "",
            check_Out: this.utils.convertStringToTime("00:00"),
            client: "",
            client_Billable_Amount_Base_Ccy: 0,
            client_Billable_Amount_Local_Ccy: 0,
            client_Billable_Currency_Local_Ccy: "",
            client_Billable_Original_Amount_Local_Ccy: 0,
            client_Billable_Tax_Base_Ccy: 0,
            client_Billable_Tax_Local_Ccy: 0,
            client_Billable_Total_Base_Ccy: 0,
            client_Billable_Total_Local_Ccy: 0,
            client_Tax_Applicable: "",
            clientId: "",
            comments: "",
            cover: "",
            date: undefined,
            dateTo: undefined,
            clientAmendDateTime:undefined,
            accrualBillableItemBillDate: undefined,
            equipmentQuantity: 0,
            fee_Amount_Base_Ccy: 0,
            fee_Amount_Local_Ccy: 0,
            fee_Currency_Local_Ccy: "",
            fee_Percentage_Base_Ccy: 0,
            fee_Percentage_Local_Ccy: 0,
            fee_Tax_Applicable: "",
            fee_Tax_Base_Ccy: 0,
            fee_Tax_Local_Ccy: 0,
            fee_Total_Base_Ccy: 0,
            fee_Total_Local_Ccy: 0,
            identification_Number: "",
            identificationTypeId: "",
            name: "",
            non_Standard: "",
            pay_Date: undefined,
            provider: "",
            provider_Pay_Amount_Base_Ccy: 0,
            provider_Pay_Amount_Local_Ccy: 0,
            provider_Pay_Currency_Local_Ccy: "",
            provider_Pay_Original_Amount_Local_Ccy: 0,
            provider_Pay_Tax_Base_Ccy: 0,
            provider_Pay_Tax_Local_Ccy: 0,
            provider_Pay_Total_Base_Ccy: 0,
            provider_Pay_Total_Local_Ccy: 0,
            provider_Tax_Applicable: "",
            providerId: "",
            rate_Decimal: 0,
            scheduleRate: 0,
            providerPayAmountLocal: undefined,
            providerPayAmountLocalExtras: undefined,
            providerPayAmountLocalWOExtras: undefined,
            providerFasterPayFee: undefined,
            providerFasterPayOriginalAmount: undefined,
            role: "",
            service: "",
            serviceTypeId: "",
            serviceSubTypeId: "",
            shift_End: this.utils.convertStringToTime("00:00"),
            shift_Start: this.utils.convertStringToTime("00:00"),
            status: "",
            typeId: "",
            twoTierApprovalDate:undefined,
            twoTierApprovedBy:"",
            unscheduled: "",
            validationErrors: "",
            venueId: "",
            venue: "",
            isAmend: false,
            invoiceId: "",
            paymentId: "",
            itemTypeId: "",
            itemTypeValue: "",
            paymentCycleId: "",
            originalBillableStartTime: this.utils.convertStringToTime("00:00"),
            originalBillableFinishTime: this.utils.convertStringToTime("00:00"),
            originalRate: 0,
            equipmentOriginalQuantity: 0,
            noCharge: "",
            originalBillableMinutes: 0,
            childRecordId: "",
            parentRecordId: "",
            internalComments: "",
            Recalculate: false,
            requestApprover: "",
            requestDate: undefined,
            otherDescription: "",
            tenseFlag: "",
            scheduledMinutes: 0,
            equipmentBillableQuantity: 0,
            equipmentScheduleQuantity: 0,
            billableRate: 0,
            performanceInformationSetsNumber: "",
            performanceInformationSetsDuration: "",
            providerNetOverride: "",
            multiplierOverride: "",
            feeAmountOverride: "",
            performanceInformation: "",
            additionalInformation: "",
            genre: "",
            equipmentRequired: "",
            description: "",
            shiftCompleteAcknowledgmentDescription: "",
            providerPayAmountLocalExtrasDescription: "",
            shiftCompleteAcknowledgment: undefined,
            shiftCompleteAcknowledgmentDateTime: undefined,
            shiftCompleteAcknowledgmentTime:  this.utils.convertStringToTime("00:00"),
            reoccurance: "",
            requestId: "",
            contractHours: {
                monday: "",
                tuesday: "",
                wednesday: "",
                thursday: "",
                friday: "",
                saturday: "",
                sunday: "",
                totalHours: "",
                variableHours: "",
            },
            editAllowed: "",
            billPeriod: "",
            expectedFee: 0,
            accepted_By: '',
            approved_By: '',
            isAccrual: false,
            hardApproval: "",
            holdPayment: "",
            accrual: {

                id: 0,
                accrualTypeId: 0,
                accrualStatusId: 0,
                accrualBillableItemId: 0,
                accrualInvoiceId: 0,
                providerPayAmountAccrual: 0,
                providerPayAmountTaxAccrual: 0,
                providerPayAmountTotalAccrual: 0,
                grossProfitAmountAccrual: 0,
                grossProfitAmountTaxAccrual: 0,
                grossProfitAmountTotalAccrual: 0,
                clientBillableAmountAccrual: 0,
                clientBillableAmountTaxAccrual: 0,
                clientBillableAmountTotalAccrual: 0,
                lastUpdatedOn: "",
                lastUpdatedBy: 0
            },
            lastupdatedOn: "",
            lastUpdatedOnTZ:'',
            lastUpdatedBy:'',
            dateCreated:'',
            dateCreated_UI:'',
            dateCreatedTime:this.utils.convertStringToTime("00:00"),
            createdBy:'',
            paymentRequestDate: undefined,
            paymentRequestId: "",
            paymentRequest: {
                id: "",
                fasterPayment: "",
                description: ""
            },
            workerStatusId: "",
            workerStatusValue: "",
            venueName: "",
            fasterPayment: false,
            fasterPaymentWithdrawn: false,
            advancedpayment: false,
            earlyPay: false,
            originator: {
                id: 0,
                value: ""
            },
            batchId: "",
            childRecordItemType: "",
            clientAmount: "",
            venueTownCity: "",
            timeDecimal: "",
            timeDiffIndicator: "",
            quantityDiffIndicator: "",
            gigTenseFlag: "",
            scheduled_Hours_Units: "",
            imageLink: "",
            previousHoursRunningTotal: "",
            previousQuantityRunningTotal: "",
            entertainmentDateInfo: "",
            address: "",
            venue_Phone: "",
            contacts: null,
            rateDiffIndicator: "",
            queries: null,
            feedback: "",
            paymentRequestID: "",
            paymentRequestStatusID: "",
            paymentRequestStatus: "",
            isUserAmendable: false,
            scheduledStartTime: "",
            scheduledFinishTime: "",
            billableStartTime: "",
            billableFinishTime: "",
            serviceSubTypeValue: "",
            typeValue: "",
            venuePostCode: "",
            updateOrCorrection: "false",
        };

        return formData;
    }

    getVatPercentageValue = (configuarationValueIndex: any[]) => {
        let configArr = configuarationValueIndex.filter((item: any) => {
            return item.id == ConfigurableTextIndexes.VatPercentage;
        });
        let configVal: string = "";
        if (configArr.length > 0) {
            configVal = configArr[0].value;
        }
        return configVal;
    }

    //calculation common function

    providerPayTaxCal = (formData: AddEditFormData, configuarationValueIndex: any[]) => {
        let providerAmount: number[] = [];
        let configurationValue = this.getVatPercentageValue(configuarationValueIndex);
        if (
            formData.provider_Tax_Applicable.toUpperCase() == "YES" ||
            formData.provider_Tax_Applicable.toUpperCase() == "TRUE"
        ) {
            if (formData.provider_Pay_Amount_Local_Ccy) {
                if (configuarationValueIndex.length > 0) {
                    providerAmount.push(
                        formData.provider_Pay_Amount_Local_Ccy * parseFloat(configurationValue)
                    );
                } else {
                    providerAmount.push(0.0);
                }
                if (providerAmount.length > 0) {
                    providerAmount.push(formData.provider_Pay_Amount_Local_Ccy + providerAmount[0]);
                }
                providerAmount.push(1);
            }
        }
        if (
            formData.provider_Tax_Applicable.toUpperCase() == "NO" ||
            formData.provider_Tax_Applicable.toUpperCase() == "FALSE"
        ) {
            providerAmount.push(0.0);
            providerAmount.push(formData.provider_Pay_Amount_Local_Ccy);
            providerAmount.push(2);
        }
        return providerAmount;
    };

    providerPayAccrualTaxCal = (formData: AddEditFormData, configuarationValueIndex: string[]) => {
        let providerAmount: number[] = [];
        let configurationValue = this.getVatPercentageValue(configuarationValueIndex);
        if (formData.isAccrual) {
            if (
                formData.provider_Tax_Applicable.toUpperCase() == "YES" ||
                formData.provider_Tax_Applicable.toUpperCase() == "TRUE"
            ) {
                if (formData.accrual && formData.accrual.providerPayAmountAccrual) {
                    if (configuarationValueIndex.length > 0) {
                        providerAmount.push(
                            formData.accrual.providerPayAmountAccrual * parseFloat(configurationValue)
                        );
                    } else {
                        providerAmount.push(0.0);
                    }
                    if (providerAmount.length > 0) {
                        providerAmount.push(formData.accrual.providerPayAmountAccrual + providerAmount[0]);
                    }
                    providerAmount.push(1);
                }

            }
            if (
                formData.provider_Tax_Applicable.toUpperCase() == "NO" ||
                formData.provider_Tax_Applicable.toUpperCase() == "FALSE"
            ) {
                providerAmount.push(0.0);
                if (formData.accrual && formData.accrual.providerPayAmountAccrual) {
                    providerAmount.push(formData.accrual.providerPayAmountAccrual);
                    providerAmount.push(2);
                }

            }
        }
        return providerAmount;
    };


    feeTaxCal = (formData: AddEditFormData, configuarationValueIndex: string[]) => {
        let feeAmount: number[] = [];
        let configurationValue = this.getVatPercentageValue(configuarationValueIndex);
        if (
            formData.fee_Tax_Applicable.toUpperCase() == "YES" ||
            formData.fee_Tax_Applicable.toUpperCase() == "TRUE"
        ) {
            if (formData.fee_Amount_Local_Ccy) {
                if (configuarationValueIndex.length > 0) {
                    feeAmount.push(
                        formData.fee_Amount_Local_Ccy * parseFloat(configurationValue)
                    );
                } else {
                    feeAmount.push(0.0);
                }
                if (feeAmount.length > 0) {
                    feeAmount.push(formData.fee_Amount_Local_Ccy + feeAmount[0]);
                }
                feeAmount.push(1);
            }
        }
        if (
            formData.fee_Tax_Applicable.toUpperCase() == "NO" ||
            formData.fee_Tax_Applicable.toUpperCase() == "FALSE"
        ) {
            feeAmount.push(0.0);
            feeAmount.push(formData.fee_Amount_Local_Ccy);
            feeAmount.push(2);
        }
        return feeAmount;
    }

    feeAccrualTaxCal = (formData: AddEditFormData, configuarationValueIndex: string[]) => {
        let feeAmount: number[] = [];
        let configurationValue = this.getVatPercentageValue(configuarationValueIndex);
        if (formData.isAccrual) {
            if (
                formData.fee_Tax_Applicable.toUpperCase() == "YES" ||
                formData.fee_Tax_Applicable.toUpperCase() == "TRUE"
            ) {
                if (formData.accrual && formData.accrual.grossProfitAmountAccrual) {
                    if (configuarationValueIndex.length > 0) {
                        feeAmount.push(
                            formData.accrual.grossProfitAmountAccrual * parseFloat(configurationValue)
                        );
                    } else {
                        feeAmount.push(0.0);
                    }
                    if (feeAmount.length > 0) {
                        feeAmount.push(formData.accrual.grossProfitAmountAccrual + feeAmount[0]);
                    }
                    feeAmount.push(1);
                }

            }
            if (
                formData.fee_Tax_Applicable.toUpperCase() == "NO" ||
                formData.fee_Tax_Applicable.toUpperCase() == "FALSE" ||
                formData.fee_Tax_Applicable == ""
            ) {
                feeAmount.push(0.0);
                if (formData.accrual && formData.accrual.grossProfitAmountAccrual) {
                    feeAmount.push(formData.accrual.grossProfitAmountAccrual);
                    feeAmount.push(2);
                }

            }
        }
        return feeAmount;
    }


    clientTaxCal = (formData: AddEditFormData, configuarationValueIndex: string[]) => {
        let clientAmount: number[] = [];
        let configurationValue = this.getVatPercentageValue(configuarationValueIndex);
        if (
            formData.client_Tax_Applicable.toUpperCase() == "YES" ||
            formData.client_Tax_Applicable.toUpperCase() == "TRUE"
        ) {
            if (formData.client_Billable_Amount_Local_Ccy) {
                if (configuarationValueIndex.length > 0) {
                    clientAmount.push(
                        formData.client_Billable_Amount_Local_Ccy * parseFloat(configurationValue)
                    );
                } else {
                    clientAmount.push(0.0);
                }
                if (clientAmount.length > 0) {
                    clientAmount.push(formData.client_Billable_Amount_Local_Ccy + clientAmount[0]);
                }
                clientAmount.push(1);
            }
        }
        if (
            formData.client_Tax_Applicable.toUpperCase() == "NO" ||
            formData.client_Tax_Applicable.toUpperCase() == "FALSE"
        ) {
            clientAmount.push(0.0);
            clientAmount.push(formData.client_Billable_Amount_Local_Ccy);
            clientAmount.push(2);
        }
        return clientAmount;

    }

    clientAccrualTaxCal = (formData: AddEditFormData, configuarationValueIndex: string[]) => {
        let clientAmount: number[] = [];
        let configurationValue = this.getVatPercentageValue(configuarationValueIndex);
        if (formData.isAccrual) {
            if (
                formData.client_Tax_Applicable.toUpperCase() == "YES" ||
                formData.client_Tax_Applicable.toUpperCase() == "TRUE"
            ) {
                if (formData.accrual && formData.accrual.clientBillableAmountAccrual) {
                    if (configuarationValueIndex.length > 0) {
                        clientAmount.push(
                            formData.accrual.clientBillableAmountAccrual * parseFloat(configurationValue)
                        );
                    } else {
                        clientAmount.push(0.0);
                    }
                    if (clientAmount.length > 0) {
                        clientAmount.push(formData.accrual.clientBillableAmountAccrual + clientAmount[0]);
                    }
                    clientAmount.push(1);
                }

            }
            if (
                formData.client_Tax_Applicable.toUpperCase() == "NO" ||
                formData.client_Tax_Applicable.toUpperCase() == "FALSE"
            ) {
                clientAmount.push(0.0);
                if (formData.accrual && formData.accrual.clientBillableAmountAccrual) {
                    clientAmount.push(formData.accrual.clientBillableAmountAccrual);
                    clientAmount.push(2);
                }
            }
        }
        return clientAmount;
    }

    //render redirect method for add edit pop up
    renderRedirectCommonMethod = (propsValue: any, successValue: boolean, navigation: string,billableItemId: string) => {
        if (
            propsValue.location.state?.pointOfNavigation &&
            (propsValue.location.state.pointOfNavigation.toLowerCase() == "simpleshifts" || propsValue.location.state.pointOfNavigation.toLowerCase() == "copy_simpleshifts")
        ) {
            propsValue.history.push({
                pathname: "/simpleShifts",
                state: {
                    pointOfNavigation: "popUp",
                    startDate: propsValue.location.state.filterStartDate,
                    endDate: propsValue.location.state.filterEndDate,
                },
            });
        } else if (
            propsValue.location.state?.pointOfNavigation &&
            propsValue.location.state.pointOfNavigation.toLowerCase() == "manageshifts"
        ) {
            propsValue.history.push({
                pathname: "/manageviewshift",
                state: {
                    status: propsValue.location.state.statusText,
                    dateFrom: propsValue.location.state.filterStartDate,
                    dateTo: propsValue.location.state.filterEndDate,
                },
            });
        } else if (
            propsValue.location.state?.pointOfNavigation &&
            (propsValue.location.state.pointOfNavigation.toLowerCase() == "matchedpopup")
        ) {
            if (navigation == "apply") {
                GlobalService.setApplyState(true);
                GlobalService.setBillableItemId(billableItemId);
                propsValue.history.goBack();
            }
            else if (navigation == "cancel") {
                GlobalService.setCloseState(true);
                if(propsValue.location.state.id == 0){
                    GlobalService.setNewShift(true);
                }
                propsValue.history.goBack();
            }
        }
        else if (propsValue.location.state?.pointOfNavigation &&
            (propsValue.location.state.pointOfNavigation.toLowerCase() == "billing" || propsValue.location.state.pointOfNavigation.toLowerCase() == "residencies" || 
                propsValue.location.state.pointOfNavigation.toLowerCase() == "budget" || propsValue.location.state.pointOfNavigation.toLowerCase() == "findanartistlandingpage")) {
            propsValue.history.goBack();
        }
        else {
            if (navigation.toLowerCase() == "apply") {
                if (successValue) {
                    propsValue.history.goBack();
                }
            }
            else {
                propsValue.history.goBack();
            }
        }
    };


    //Initialize virtual object data
    initializeVirtualBillableItem(idFromGrid: string): virtualBillableItemFormData {
        return {
            billable_Start: undefined,
            billable_Finish: undefined,
            billable_Minutes: 0,
            rate_Decimal: 0,
            name: "",
            identification_Number: "",
            noCharge: "",
            cover: "",
            absent: "",
            equipmentQuantity: 0,
            providerId: "",
            performanceInformation: "",
            typeId: "",
            genre: "",
            performanceInformationSetsNumber: "",
            performanceInformationSetsDuration: "",
            equipmentRequired: "",
            shift_End: undefined,
            shift_Start: undefined,
            otherDescription: "",
        };
    }

    //Initialize the Raise query form data.
    initializeQueryFormData = (
        idFromGrid: string,
        venueId?: string,
        venueName?: string,
        queryTypeText?: string,
        pageName?: string
    ): RaiseQueryFormData => {
        var finalQueryTypeLookupId: string = "";
        if (pageName && pageName.toLowerCase() == "costreport") {
            finalQueryTypeLookupId = queryTypeLookup.costReport;
        } else if (idFromGrid) {
            finalQueryTypeLookupId = queryTypeLookup.Specific;
        } else if (pageName && pageName.toLowerCase() == "PaymentRequset") {
            finalQueryTypeLookupId = queryTypeLookup.paymentRequest;
        } else {
            finalQueryTypeLookupId = queryTypeLookup.General;
        }
        var formData: RaiseQueryFormData = {
            id: "",
            queryTitle: "",
            queryDescription: "",
            queryTypeLookupID: finalQueryTypeLookupId,
            contactId: "",
            contactName: "",
            companyId: "",
            company: "",
            d365LinkID: "",
            resolutionReason: "",
            billableItemId: idFromGrid,
            queryStatusId: "",
            queryStatus: "",
            dateCreated: "",
            clientId: "",
            client: "",
            venueId: venueId ? venueId : "",
            venue: "",
            venueName: venueName ? venueName : "",
            comments: "",
            lastUpdate: "",
            assignedTo: "",
            raisedByContactName: "",
            raisedByContactTelNumber: "",
            queryTypeText: queryTypeText ? queryTypeText : "",
            serviceTypeId: "",
            linkedId: "",
            navigateFrom: "webApp"
        };
        return formData;
    };


    // filtered venue value
    filteredVenueDropDownValue = (venueDropDownValues: LookupTypeItem[], clientId: string) => {
        var venueLUItems = venueDropDownValues.filter((item) => {
            return item.parentMappingId == clientId;
        });

        return venueLUItems;
    };

    filteredVenueNameValues = (filteredVenueDropDownValues: LookupTypeItem[]) => {
        var venueNameLUItems: LookupTypeItem;
        var venueNameLUItemsArr: LookupTypeItem[] = [];

        filteredVenueDropDownValues.forEach((item: LookupTypeItem) => {
            var parts = item.value.split("-");
            var venueName = parts[1];
            venueNameLUItems = {
                id: item.id,
                value: venueName,
                parentMappingId: "0",
            };

            venueNameLUItemsArr.push(venueNameLUItems);
        });

        return venueNameLUItemsArr;
    };

    filteredVenueNoValues = (filteredVenueDropDownValues: LookupTypeItem[]) => {
        var venueNoLUItems: LookupTypeItem;
        var venueNoLUItemsArr: LookupTypeItem[] = [];

        filteredVenueDropDownValues.forEach((item: LookupTypeItem) => {
            var parts = item.value.split("-");
            var venueNo = parts[0];
            venueNoLUItems = {
                id: item.id,
                value: venueNo,
                parentMappingId: "0",
            };

            venueNoLUItemsArr.push(venueNoLUItems);
        });

        return venueNoLUItemsArr;
    };

    //convert the server response into the component's form state (virtual data)
    convertBillableItemResponeToVirtualFormData(serverResponse: virtualBillableItemObject): virtualBillableItemFormData {
        var formData: virtualBillableItemFormData = {
            billable_Start: serverResponse.billable_Start
                ? this.utils.convertStringToTime(serverResponse.billable_Start)
                : null,
            billable_Finish: serverResponse.billable_Finish
                ? this.utils.convertStringToTime(serverResponse.billable_Finish)
                : null,
            billable_Minutes: this.utils.convertStringToFloat(serverResponse.billable_Minutes),
            rate_Decimal: this.utils.convertStringToFloat(serverResponse.rate_Decimal),
            name: serverResponse.name,
            identification_Number: serverResponse.identification_Number,
            noCharge: serverResponse.noCharge,
            cover: serverResponse.cover,
            absent: this.setAbsentValue(serverResponse.absent),
            equipmentQuantity: this.utils.convertStringToFloat(serverResponse.equipmentQuantity),
            providerId: serverResponse.providerId,
            performanceInformation: serverResponse.performanceInformation,
            typeId: serverResponse.typeId,
            genre: serverResponse.genre,
            performanceInformationSetsNumber: serverResponse.performanceInformationSetsNumber,
            performanceInformationSetsDuration: serverResponse.performanceInformationSetsDuration,
            equipmentRequired: serverResponse.equipmentRequired,
            shift_End: this.utils.convertStringToTime(serverResponse.shift_End),
            shift_Start: this.utils.convertStringToTime(serverResponse.shift_Start),
            otherDescription: serverResponse.otherDescription,
        };
        return formData;
    }

    setAbsentValue = (absentValue: string) => {
        var actualAbsentValue: string = "";
        if (absentValue && absentValue.toUpperCase() == "YES") {
            actualAbsentValue = "Not Present";

        } else if (absentValue && absentValue.toUpperCase() == "NO") {
            actualAbsentValue = "Present";
        }
        return actualAbsentValue;
    }

    setNoChargeValue = (chargeValue: string) => {
        var chargeActualValue: string = "";
        if (chargeValue && chargeValue.toUpperCase() == "YES") {
            chargeActualValue = "Waived";

        } else if (chargeValue && chargeValue.toUpperCase() == "NO") {
            chargeActualValue = "Applied";
        }
        return chargeActualValue;
    }

    setDateToField = (
        unitOfMeasureType: string,
        dateFrom: NullableDate,
        serviceTypeId: string,
        serviceSubTypeId: string
    ) => {
        var isTimeSelected = unitOfMeasureType === UnitOfMeasureTypes.time;
        var dateFromPlusSix = undefined;

        if (dateFrom) {
            dateFromPlusSix = new Date(dateFrom);
            dateFromPlusSix.setDate(dateFromPlusSix.getDate() + 6);
        }

        if (isTimeSelected || serviceTypeId == ServiceType.Entertainment || 
            serviceSubTypeId == SubServiceType.AdditionalCleaning || 
            serviceSubTypeId == SubServiceType.AdditionalGardening || 
            serviceSubTypeId == SubServiceType.AdditionalWindowCleaning || 
            serviceSubTypeId == SubServiceType.AdditionalMaintenance_Misce ||
            serviceSubTypeId == SubServiceType.OtherMaintenance_Misce ||
            serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel) {
            return dateFrom;
        }
        else {
            return dateFromPlusSix;
        }


    };

    lockTimeFields = (unitOfMeasureType: string) => {
        var isTimeSelected = unitOfMeasureType === UnitOfMeasureTypes.time;
        return !isTimeSelected;

    }

    lockQuantityFields = (unitOfMeasureType: string) => {
        var isTimeSelected = unitOfMeasureType === UnitOfMeasureTypes.time;
        return isTimeSelected;
    }

    // Convert the server response into the component's form state
    convertBillableItemResponeToFormData(serverResponse: BillableItemResponse, isPopUp: boolean): AddEditFormData {
        var formData: AddEditFormData = {
            absent: window.location.pathname.includes("AddEditFormV2") || isPopUp ? this.setAbsentValue(serverResponse.absent) : serverResponse.absent,
            accept_Date: this.utils.convertStringToDate(serverResponse.accept_Date),
            approval_Date: this.utils.convertStringToDate(serverResponse.approval_Date),
            bill_Date: this.utils.convertStringToDate(serverResponse.bill_Date),
            billable_Finish: serverResponse.billable_Finish
                ? this.utils.convertStringToTime(serverResponse.billable_Finish)
                : null,
            billable_Minutes: this.utils.convertStringToFloat(serverResponse.billable_Minutes),
            tenseFlag: serverResponse.tenseFlag,
            scheduledMinutes: this.utils.convertStringToFloat(serverResponse.scheduledMinutes),
            billable_Start: serverResponse.billable_Start
                ? this.utils.convertStringToTime(serverResponse.billable_Start)
                : null,
            billableItemId: serverResponse.billableItemId,
            billableStatusLookupId: serverResponse.billableStatusLookupId,
            check_In: this.utils.convertStringToTime(serverResponse.check_In),
            check_In_Out: serverResponse.check_In_Out,
            check_Out: this.utils.convertStringToTime(serverResponse.check_Out),
            client: serverResponse.client,
            client_Billable_Amount_Base_Ccy: this.utils.convertStringToFloat(
                serverResponse.client_Billable_Amount_Base_Ccy
            ),
            client_Billable_Amount_Local_Ccy: this.utils.convertStringToFloat(
                serverResponse.client_Billable_Amount_Local_Ccy
            ),
            client_Billable_Currency_Local_Ccy: serverResponse.client_Billable_Currency_Local_Ccy, // Ex: "GBP"
            client_Billable_Original_Amount_Local_Ccy: this.utils.convertStringToFloat(
                serverResponse.client_Billable_Original_Amount_Local_Ccy
            ),
            client_Billable_Tax_Base_Ccy: this.utils.convertStringToFloat(serverResponse.client_Billable_Tax_Base_Ccy),
            client_Billable_Tax_Local_Ccy: this.utils.convertStringToFloat(
                serverResponse.client_Billable_Tax_Local_Ccy
            ),
            client_Billable_Total_Base_Ccy: this.utils.convertStringToFloat(
                serverResponse.client_Billable_Total_Base_Ccy
            ),
            client_Billable_Total_Local_Ccy: this.utils.convertStringToFloat(
                serverResponse.client_Billable_Total_Local_Ccy
            ),
            client_Tax_Applicable: serverResponse.client_Tax_Applicable,
            clientId: serverResponse.clientId,
            comments: serverResponse.comments ? serverResponse.comments : "",
            cover: serverResponse.cover,
            date: this.utils.convertStringToDate(serverResponse.date),
            dateTo: this.utils.convertStringToDate(serverResponse.dateTo),
            clientAmendDateTime:this.utils.convertStringToDate(serverResponse.clientAmendDateTime),
            accrualBillableItemBillDate: this.utils.convertStringToDate(serverResponse.accrualBillableItemBillDate),
            equipmentQuantity: this.utils.convertStringToFloat(serverResponse.equipmentQuantity),
            equipmentScheduleQuantity: this.utils.convertStringToFloat(serverResponse.equipmentScheduleQuantity),
            fee_Amount_Base_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Amount_Base_Ccy),
            fee_Amount_Local_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Amount_Local_Ccy),
            fee_Currency_Local_Ccy: serverResponse.fee_Currency_Local_Ccy, // Ex: "GBP"
            fee_Percentage_Base_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Percentage_Base_Ccy),
            fee_Percentage_Local_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Percentage_Local_Ccy),
            fee_Tax_Applicable: serverResponse.fee_Tax_Applicable,
            fee_Tax_Base_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Tax_Base_Ccy),
            fee_Tax_Local_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Tax_Local_Ccy),
            fee_Total_Base_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Total_Base_Ccy),
            fee_Total_Local_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Total_Local_Ccy),
            identification_Number: serverResponse.identification_Number,
            identificationTypeId: serverResponse.identificationTypeId,
            name: serverResponse.name,
            non_Standard: serverResponse.non_Standard,
            pay_Date: this.utils.convertStringToDate(serverResponse.pay_Date),
            provider: serverResponse.provider,
            provider_Pay_Amount_Base_Ccy: this.utils.convertStringToFloat(serverResponse.provider_Pay_Amount_Base_Ccy),
            provider_Pay_Amount_Local_Ccy: this.utils.convertStringToFloat(
                serverResponse.provider_Pay_Amount_Local_Ccy
            ),
            provider_Pay_Currency_Local_Ccy: serverResponse.provider_Pay_Currency_Local_Ccy, // Ex: "GBP"
            provider_Pay_Original_Amount_Local_Ccy: this.utils.convertStringToFloat(
                serverResponse.provider_Pay_Original_Amount_Local_Ccy
            ),
            provider_Pay_Tax_Base_Ccy: this.utils.convertStringToFloat(serverResponse.provider_Pay_Tax_Base_Ccy),
            provider_Pay_Tax_Local_Ccy: this.utils.convertStringToFloat(serverResponse.provider_Pay_Tax_Local_Ccy),
            provider_Pay_Total_Base_Ccy: this.utils.convertStringToFloat(serverResponse.provider_Pay_Total_Base_Ccy),
            provider_Pay_Total_Local_Ccy: this.utils.convertStringToFloat(serverResponse.provider_Pay_Total_Local_Ccy),
            provider_Tax_Applicable: serverResponse.provider_Tax_Applicable,
            providerId: serverResponse.providerId,
            rate_Decimal: this.utils.convertStringToFloat(serverResponse.rate_Decimal),
            scheduleRate: this.utils.convertStringToFloat(serverResponse.scheduleRate),
            providerPayAmountLocal: serverResponse.providerPayAmountLocal,
            providerPayAmountLocalExtras: this.utils.convertStringToFloat(serverResponse.providerPayAmountLocalExtras),
            providerPayAmountLocalWOExtras: this.utils.convertStringToFloat(serverResponse.providerPayAmountLocalWOExtras),
            providerFasterPayFee: this.utils.convertStringToFloat(serverResponse.providerFasterPayFee),
            providerFasterPayOriginalAmount: this.utils.convertStringToFloat(serverResponse.providerFasterPayOriginalAmount),
            role: serverResponse.role,
            service: serverResponse.service,
            serviceSubTypeId: serverResponse.serviceSubTypeId,
            serviceTypeId: serverResponse.serviceTypeId,
            shift_End: this.utils.convertStringToTime(serverResponse.shift_End),
            shift_Start: this.utils.convertStringToTime(serverResponse.shift_Start),
            status: serverResponse.status,
            typeId: serverResponse.typeId,
            twoTierApprovalDate:this.utils.convertStringToDate(serverResponse.twoTierApprovalDate),
            twoTierApprovedBy:serverResponse.twoTierApprovedBy,
            unscheduled: serverResponse.unscheduled,
            validationErrors: serverResponse.validationErrors,
            venueId: serverResponse.venueId,
            venue: serverResponse.venue,
            isAmend: JSON.parse(serverResponse.isAmend),
            invoiceId: serverResponse.invoiceId,
            paymentId: serverResponse.paymentId,
            itemTypeId: serverResponse.itemTypeId,
            itemTypeValue: serverResponse.itemTypeValue,
            paymentCycleId: serverResponse.paymentCycleId,
            originalBillableStartTime: this.utils.convertStringToTime(serverResponse.originalBillableStartTime),
            originalBillableFinishTime: this.utils.convertStringToTime(serverResponse.originalBillableFinishTime),
            originalRate: this.utils.convertStringToFloat(serverResponse.originalRate),
            equipmentOriginalQuantity: this.utils.convertStringToFloat(serverResponse.equipmentOriginalQuantity),
            noCharge: window.location.pathname.includes("AddEditFormV2") || isPopUp ? this.setNoChargeValue(serverResponse.noCharge) : serverResponse.noCharge,
            originalBillableMinutes: this.utils.convertStringToFloat(serverResponse.originalBillableMinutes),
            childRecordId: serverResponse.childRecordId,
            parentRecordId: serverResponse.parentRecordId,
            internalComments: serverResponse.internalComments ? serverResponse.internalComments : "",
            Recalculate: serverResponse.Recalculate,
            requestApprover: serverResponse.requestApprover,
            requestDate: this.utils.convertStringToDate(serverResponse.requestDate),
            otherDescription: serverResponse.otherDescription,
            contractHours: serverResponse.contractHours,
            equipmentBillableQuantity: this.utils.convertStringToFloat(serverResponse.equipmentBillableQuantity),
            billableRate: this.utils.convertStringToFloat(serverResponse.billableRate),
            performanceInformationSetsNumber: serverResponse.performanceInformationSetsNumber,
            performanceInformationSetsDuration: serverResponse.performanceInformationSetsDuration,
            providerNetOverride: serverResponse.providerNetOverride,
            multiplierOverride: serverResponse.multiplierOverride,
            feeAmountOverride: serverResponse.feeAmountOverride,
            performanceInformation: serverResponse.performanceInformation,
            additionalInformation: serverResponse.additionalInformation,
            genre: serverResponse.genre,
            equipmentRequired: serverResponse.equipmentRequired,
            description: serverResponse.description,
            shiftCompleteAcknowledgmentDescription: serverResponse.shiftCompleteAcknowledgmentDescription,
            providerPayAmountLocalExtrasDescription: serverResponse.providerPayAmountLocalExtrasDescription,
            shiftCompleteAcknowledgment: serverResponse.shiftCompleteAcknowledgment,
            shiftCompleteAcknowledgmentDateTime: this.utils.convertStringToDate(
                serverResponse.shiftCompleteAcknowledgmentDateTime
            ),
            shiftCompleteAcknowledgmentTime: this.utils.convertStringToTime(serverResponse.shiftCompleteAcknowledgmentTime),
            reoccurance: serverResponse.reoccurance,
            requestId: serverResponse.requestId,
            editAllowed: serverResponse.editAllowed,
            billPeriod: serverResponse.billPeriod,
            expectedFee: this.utils.convertStringToFloat(serverResponse.expectedFee),
            accepted_By: serverResponse.accepted_By,
            approved_By: serverResponse.approved_By,
            hardApproval: serverResponse.hardApproval,
            holdPayment: serverResponse.holdPayment,
            isAccrual: serverResponse.isAccrual,
            accrual: serverResponse.accrual ? {

                id: serverResponse.accrual.id,
                accrualTypeId: serverResponse.accrual.accrualTypeId,
                accrualStatusId: serverResponse.accrual.accrualStatusId,
                accrualBillableItemId: serverResponse.accrual.accrualBillableItemId,
                accrualInvoiceId: serverResponse.accrual.accrualInvoiceId,
                providerPayAmountAccrual: serverResponse.accrual.providerPayAmountAccrual,
                providerPayAmountTaxAccrual: serverResponse.accrual.providerPayAmountTaxAccrual,
                providerPayAmountTotalAccrual: serverResponse.accrual.providerPayAmountTotalAccrual,
                grossProfitAmountAccrual: serverResponse.accrual.grossProfitAmountAccrual,
                grossProfitAmountTaxAccrual: serverResponse.accrual.grossProfitAmountTaxAccrual,
                grossProfitAmountTotalAccrual: serverResponse.accrual.grossProfitAmountTotalAccrual,
                clientBillableAmountAccrual: serverResponse.accrual.clientBillableAmountAccrual,
                clientBillableAmountTaxAccrual: serverResponse.accrual.clientBillableAmountTaxAccrual,
                clientBillableAmountTotalAccrual: serverResponse.accrual.clientBillableAmountTotalAccrual,
                lastUpdatedOn: serverResponse.accrual.lastUpdatedOn,
                lastUpdatedBy: serverResponse.accrual.lastUpdatedBy
            } : null,
            lastupdatedOn: serverResponse.lastUpdatedOn,
            lastUpdatedOnTZ:serverResponse.lastUpdatedOnTZ,
            lastUpdatedBy:serverResponse.lastUpdatedBy,
            dateCreated:serverResponse.dateCreated,
            dateCreated_UI:serverResponse.dateCreated, //on UI it shows DD/MM/YYYY
            //dateCreatedTime:serverResponse.dateCreated,//on UI it shows HH:MM
            dateCreatedTime:this.utils.formatDateCreatedTimeUTC(serverResponse.dateCreated),
            createdBy:serverResponse.createdBy,
            paymentRequestDate: null,
            paymentRequestId: "",
            paymentRequest: serverResponse.paymentRequest ? {
                id: serverResponse.paymentRequest.id,
                fasterPayment: serverResponse.paymentRequest.fasterPayment,
                description: serverResponse.paymentRequest.description
            } : null,
            workerStatusId: serverResponse.workerStatusId,
            workerStatusValue: serverResponse.workerStatusValue,
            venueName: serverResponse.venueName,
            fasterPayment: serverResponse.fasterPayment,
            fasterPaymentWithdrawn: serverResponse.fasterPaymentWithdrawn,
            advancedpayment: serverResponse.advancedpayment,
            earlyPay: serverResponse.earlyPay,
            originator: serverResponse.originator ? {
                id: serverResponse.originator.id,
                value: serverResponse.originator.value
            } : null,
            batchId: serverResponse.batchId,
            childRecordItemType: serverResponse.childRecordId,
            clientAmount: serverResponse.clientAmount,
            venueTownCity: serverResponse.venueTownCity,
            timeDecimal: serverResponse.timeDecimal,
            timeDiffIndicator: serverResponse.timeDiffIndicator,
            quantityDiffIndicator: serverResponse.quantityDiffIndicator,
            gigTenseFlag: serverResponse.gigTenseFlag,
            scheduled_Hours_Units: serverResponse.scheduled_Hours_Units,
            imageLink: serverResponse.imageLink,
            previousHoursRunningTotal: serverResponse.previousHoursRunningTotal,
            previousQuantityRunningTotal: serverResponse.previousQuantityRunningTotal,
            entertainmentDateInfo: serverResponse.entertainmentDateInfo,
            address: serverResponse.address,
            venue_Phone: serverResponse.venue_Phone,
            contacts: serverResponse.contacts,
            rateDiffIndicator: serverResponse.rateDiffIndicator,
            queries: serverResponse.queries,
            feedback: serverResponse.feedback,
            paymentRequestID: serverResponse.paymentRequestID,
            paymentRequestStatusID: serverResponse.paymentRequestStatusID,
            paymentRequestStatus: serverResponse.paymentRequestStatus,
            isUserAmendable: serverResponse.isUserAmendable,
            scheduledStartTime: serverResponse.scheduledStartTime,
            scheduledFinishTime: serverResponse.scheduledFinishTime,
            billableStartTime: serverResponse.billableStartTime,
            billableFinishTime: serverResponse.billableFinishTime,
            serviceSubTypeValue: serverResponse.serviceSubTypeValue,
            typeValue: serverResponse.typeValue,
            venuePostCode: serverResponse.venuePostCode,
            updateOrCorrection: serverResponse.updateOrCorrection ? "true" : "false",
        };

        return formData;
    }

    copyBillableItem(source: BillableItemResponse): AddEditFormData {
        var formData: AddEditFormData = {
            absent: "",
            accept_Date: undefined,
            approval_Date: undefined,
            bill_Date: undefined,
            billable_Finish: null,
            billable_Minutes: 0,
            billable_Start: null,
            billableItemId: '',
            billableStatusLookupId: Status.Pending,
            check_In: null,
            check_In_Out: "",
            check_Out: null,
            client: "",
            client_Billable_Amount_Base_Ccy: this.utils.convertStringToFloat(source.client_Billable_Amount_Base_Ccy),
            client_Billable_Amount_Local_Ccy: this.utils.convertStringToFloat(source.client_Billable_Amount_Local_Ccy),
            client_Billable_Currency_Local_Ccy: source.client_Billable_Currency_Local_Ccy,
            client_Billable_Original_Amount_Local_Ccy: this.utils.convertStringToFloat(source.client_Billable_Original_Amount_Local_Ccy),
            client_Billable_Tax_Base_Ccy: this.utils.convertStringToFloat(source.client_Billable_Tax_Base_Ccy),
            client_Billable_Tax_Local_Ccy: this.utils.convertStringToFloat(source.client_Billable_Tax_Local_Ccy),
            client_Billable_Total_Base_Ccy: this.utils.convertStringToFloat(source.client_Billable_Total_Base_Ccy),
            client_Billable_Total_Local_Ccy: this.utils.convertStringToFloat(source.client_Billable_Total_Local_Ccy),
            client_Tax_Applicable: source.client_Tax_Applicable,
            clientId: source.clientId,
            comments: source.comments,
            cover: source.cover,
            date: undefined,
            dateTo: undefined,
            clientAmendDateTime:undefined,
            accrualBillableItemBillDate: undefined,
            equipmentQuantity: this.utils.convertStringToFloat(source.equipmentQuantity),
            fee_Amount_Base_Ccy: this.utils.convertStringToFloat(source.fee_Amount_Base_Ccy),
            fee_Amount_Local_Ccy: this.utils.convertStringToFloat(source.fee_Amount_Local_Ccy),
            fee_Currency_Local_Ccy: source.fee_Currency_Local_Ccy, // Ex: "GBP"
            fee_Percentage_Base_Ccy: this.utils.convertStringToFloat(source.fee_Percentage_Base_Ccy),
            fee_Percentage_Local_Ccy: this.utils.convertStringToFloat(source.fee_Percentage_Local_Ccy),
            fee_Tax_Applicable: source.fee_Tax_Applicable,
            fee_Tax_Base_Ccy: this.utils.convertStringToFloat(source.fee_Tax_Base_Ccy),
            fee_Tax_Local_Ccy: this.utils.convertStringToFloat(source.fee_Tax_Local_Ccy),
            fee_Total_Base_Ccy: this.utils.convertStringToFloat(source.fee_Total_Base_Ccy),
            fee_Total_Local_Ccy: this.utils.convertStringToFloat(source.fee_Total_Local_Ccy),
            identification_Number: source.identification_Number,
            identificationTypeId: source.identificationTypeId,
            name: source.name,
            non_Standard:source.non_Standard,
            pay_Date: undefined,
            provider: "",
            provider_Pay_Amount_Base_Ccy: this.utils.convertStringToFloat(source.provider_Pay_Amount_Base_Ccy),
            provider_Pay_Amount_Local_Ccy: this.utils.convertStringToFloat(source.provider_Pay_Amount_Local_Ccy),
            provider_Pay_Currency_Local_Ccy: source.provider_Pay_Currency_Local_Ccy, // Ex: "GBP"
            provider_Pay_Original_Amount_Local_Ccy: this.utils.convertStringToFloat(source.provider_Pay_Original_Amount_Local_Ccy),
            provider_Pay_Tax_Base_Ccy: this.utils.convertStringToFloat(source.provider_Pay_Tax_Base_Ccy),
            provider_Pay_Tax_Local_Ccy: this.utils.convertStringToFloat(source.provider_Pay_Tax_Local_Ccy),
            provider_Pay_Total_Base_Ccy: this.utils.convertStringToFloat(source.provider_Pay_Total_Base_Ccy),
            provider_Pay_Total_Local_Ccy: this.utils.convertStringToFloat(source.provider_Pay_Total_Local_Ccy),
            provider_Tax_Applicable: source.provider_Tax_Applicable,
            providerId: source.providerId,
            rate_Decimal: this.utils.convertStringToFloat(source.rate_Decimal),
            //scheduleRate: 0,
            scheduleRate:this.utils.convertStringToFloat(source.scheduleRate),
            providerPayAmountLocal: source.providerPayAmountLocal,
            providerPayAmountLocalExtras: this.utils.convertStringToFloat(source.providerPayAmountLocalExtras),
            providerPayAmountLocalWOExtras: this.utils.convertStringToFloat(source.providerPayAmountLocalWOExtras),
            providerFasterPayFee : undefined,
            providerFasterPayOriginalAmount: undefined,
            role: "",
            service: "",
            serviceTypeId: source.serviceTypeId,
            serviceSubTypeId: source.serviceSubTypeId,
            shift_End: this.utils.convertStringToTime(source.shift_End),
            shift_Start: this.utils.convertStringToTime(source.shift_Start),
            status: "",
            typeId: source.typeId,
            twoTierApprovalDate:undefined,
            twoTierApprovedBy:"",
            unscheduled: source.unscheduled,
            validationErrors: "",
            venueId: source.venueId,
            venue: "",
            isAmend: false,
            invoiceId: "",
            paymentId: "",
            itemTypeId: source.itemTypeId,
            itemTypeValue: "",
            paymentCycleId: "",
            originalBillableStartTime: null,
            originalBillableFinishTime: null,
            originalRate: 0,     
            equipmentOriginalQuantity: 0, //OriginalQuantity in req
            noCharge: "",
            originalBillableMinutes: 0,
            childRecordId: "",
            parentRecordId: "",
            internalComments: source.internalComments ? source.internalComments : "",
            Recalculate: false,
            requestApprover: "",
            requestDate: undefined,
            otherDescription: source.otherDescription,
            tenseFlag: "",
            scheduledMinutes: 0,
            equipmentBillableQuantity:this.utils.convertStringToFloat(source.equipmentBillableQuantity),
            equipmentScheduleQuantity :this.utils.convertStringToFloat(source.equipmentScheduleQuantity),
            billableRate: 0,
            performanceInformationSetsNumber: source.performanceInformationSetsNumber,
            performanceInformationSetsDuration: source.performanceInformationSetsDuration,
            performanceInformation: source.performanceInformation,
            providerNetOverride: source.providerNetOverride,
            multiplierOverride: source.multiplierOverride,
            feeAmountOverride: "",
            additionalInformation :source.additionalInformation,
            genre: "",
            equipmentRequired: source.equipmentRequired,
            description: source.description,
            shiftCompleteAcknowledgmentDescription: "",
            providerPayAmountLocalExtrasDescription: "",
            shiftCompleteAcknowledgment: undefined,
            shiftCompleteAcknowledgmentDateTime: undefined,
            shiftCompleteAcknowledgmentTime:  undefined,
            reoccurance: source.reoccurance,
            requestId: "",
            contractHours: {
                monday: "",
                tuesday: "",
                wednesday: "",
                thursday: "",
                friday: "",
                saturday: "",
                sunday: "",
                totalHours: "",
                variableHours: "",
            },
            //contractHours:source.contractHours,
            editAllowed: source.editAllowed,
            billPeriod: "",
            expectedFee: this.utils.convertStringToFloat(source.expectedFee),
            accepted_By: '',
            approved_By: '',
            isAccrual: false,
            //isAccrual:source.isAccrual,
            hardApproval:source.hardApproval,
            holdPayment: source.holdPayment,
            accrual: {
                id: 0,
                accrualTypeId: 0,
                accrualStatusId: 0,
                accrualBillableItemId: 0,
                accrualInvoiceId: 0,
                providerPayAmountAccrual: 0,
                providerPayAmountTaxAccrual: 0,
                providerPayAmountTotalAccrual: 0,
                grossProfitAmountAccrual: 0,
                grossProfitAmountTaxAccrual: 0,
                grossProfitAmountTotalAccrual: 0,
                clientBillableAmountAccrual: 0,
                clientBillableAmountTaxAccrual: 0,
                clientBillableAmountTotalAccrual: 0,
                lastUpdatedOn: "",
                lastUpdatedBy: 0
            },
            // accrual: {
            //     // id: source?.accrual?.id || 0,
            //     id:0,
            //     // accrualTypeId: source?.accrual?.accrualTypeId || 0,
            //     accrualTypeId: 0,
            //     // accrualStatusId: source?.accrual?.accrualStatusId || 0,
            //     accrualStatusId: 0,
            //     // accrualBillableItemId: source?.accrual?.accrualBillableItemId || 0,
            //     accrualBillableItemId: 0,
            //     // accrualInvoiceId: source?.accrual?.accrualInvoiceId,
            //     accrualInvoiceId: 0,
            //     providerPayAmountAccrual: source?.accrual?.providerPayAmountAccrual || 0,
            //     providerPayAmountTaxAccrual: source?.accrual?.providerPayAmountTaxAccrual || 0,
            //     providerPayAmountTotalAccrual: source?.accrual?.providerPayAmountTotalAccrual || 0,
            //     grossProfitAmountAccrual: source?.accrual?.grossProfitAmountAccrual || 0,
            //     grossProfitAmountTaxAccrual: source?.accrual?.grossProfitAmountTaxAccrual || 0,
            //     grossProfitAmountTotalAccrual: source?.accrual?.grossProfitAmountTotalAccrual || 0,
            //     clientBillableAmountAccrual: source?.accrual?.clientBillableAmountAccrual || 0,
            //     clientBillableAmountTaxAccrual: source?.accrual?.clientBillableAmountTaxAccrual || 0,
            //     clientBillableAmountTotalAccrual: source?.accrual?.clientBillableAmountTotalAccrual || 0,
            //     lastUpdatedOn: '',
            //     lastUpdatedBy: 0
            // },
            lastupdatedOn: "",
            lastUpdatedOnTZ:'',
            lastUpdatedBy:'',
            dateCreated:'',
            dateCreated_UI:'',
            dateCreatedTime:this.utils.convertStringToTime("00:00"),
            createdBy:'',
            paymentRequestDate: undefined,
            paymentRequestId: "",
            paymentRequest: {
                id: "",
                fasterPayment: "",
                description: ""
            },
            workerStatusId: "",
            workerStatusValue: "",
            venueName: "",
            fasterPayment: false,
            fasterPaymentWithdrawn: false,
            advancedpayment:source.advancedpayment,
            earlyPay:source.earlyPay,
            originator: {
                id:50,  //event uk default
                value:""
            },
            batchId: "",
            childRecordItemType: "",
            clientAmount: "",
            venueTownCity: "",
            //timeDecimal: "",
            // timeDiffIndicator: "",
            //quantityDiffIndicator: "",
            timeDecimal:source.timeDecimal,
            timeDiffIndicator :source.timeDiffIndicator,
            quantityDiffIndicator :source.quantityDiffIndicator,
            gigTenseFlag: "",
            scheduled_Hours_Units: "",
            imageLink: "",
            previousHoursRunningTotal: "",
            previousQuantityRunningTotal: "",
            entertainmentDateInfo: "",
            address: "",
            venue_Phone: "",
            contacts: null,
            rateDiffIndicator :source.rateDiffIndicator,
            queries: null,
            feedback: "",
            paymentRequestID: "",
            paymentRequestStatusID: "",
            paymentRequestStatus: "",
            isUserAmendable: false,
            scheduledStartTime: "",
            scheduledFinishTime: "",
            billableStartTime: "",
            billableFinishTime: "",
            serviceSubTypeValue: "",
            typeValue: "",
            venuePostCode: "",
            updateOrCorrection: "false",
        };

        return formData;
    }

    //convert the form's data into a proper billable item submit request for Match and UnMatch 

    // Convert the form's data into a proper billable item submit request
    convertFormDataToBillableItemRequestMatchUnmatch(
        formData: AddEditFormData,
        statusId: string,
        itemType: string,
        isAmendActivity: boolean,
        billableItemIdValueForAdjustment: string,
        paretRecordIdForAdjustment: string,
        originalAmountForAdjustment: string,
        acceptDate?: NullableDate,
        approvalDate?: NullableDate,
        artistConfirmation?: boolean,
        navigatedFrom?: string,
        ReCalculate?: boolean,
        paymentId?: string,
        paymentRequestDate?: string,
        providerId?: string
    ): BillableItemSubmitRequest {
        var paymentRequestId: string = "";
        var recalculateVal: boolean = false;
        var payRequestDate: string | undefined = "";
        console.log("accrual log" + formData.accrual);
        if (paymentId) {
            paymentRequestId = paymentId;
        }
        if (ReCalculate) {
            recalculateVal = ReCalculate;
        }
        else {
            recalculateVal = formData.Recalculate;
        }
        if (navigatedFrom == "paymentMatch") {
            payRequestDate = paymentRequestDate;
        }
        var submitRequest: BillableItemSubmitRequest = {
            absent: formData.absent
                ? formData.absent.toLowerCase() == "not present" || formData.absent.toLowerCase() == "yes"
                    ? "Yes"
                    : formData.absent.toLowerCase() == "no" || formData.absent.toLowerCase() == "present"
                        ? "No"
                        : ""
                : "",
            accept_Date:
                acceptDate != undefined || acceptDate != null
                    ? this.utils.convertDateToString(acceptDate)
                    : billableItemIdValueForAdjustment != ""
                        ? ""
                        : this.utils.convertDateToString(formData.accept_Date),
            approval_Date:
                approvalDate != undefined || approvalDate != null
                    ? this.utils.convertDateToString(approvalDate)
                    : billableItemIdValueForAdjustment != ""
                        ? ""
                        : isAmendActivity
                            ? this.utils.convertDateToString(formData.approval_Date)
                                ? this.utils.convertDateToString(formData.approval_Date)
                                : this.utils.convertDateToString(new Date())
                            : this.utils.convertDateToString(formData.approval_Date),
            bill_Date: billableItemIdValueForAdjustment != "" ? "" : this.utils.convertDateToString(formData.bill_Date),
            billable_Finish: this.utils.convertTimeToString(formData.billable_Finish),
            billable_Hours_Units: this.utils.convertNumberToString(formData.billable_Minutes),
            //billable_Minutes: "", <== this is a derived field;  send back as billable_Hours_Units
            billable_Start: this.utils.convertTimeToString(formData.billable_Start),
            billable_Start_Finish: "",
            billableItemId:
                billableItemIdValueForAdjustment != "" ? billableItemIdValueForAdjustment : formData.billableItemId,
            billableStatusLookupId: statusId != "" ? statusId : formData.billableStatusLookupId,
            check_In: this.utils.convertTimeToString(formData.check_In),
            check_In_Out: "",
            check_Out: this.utils.convertTimeToString(formData.check_Out),
            checkin_Color: "", // do not send back
            checkin_Font: "", // do not send back
            checkOut_Color: "", // do not send back
            checkOut_Font: "", // do not send back
            client: "", // do not know what this is, but it is not on the formData object
            client_Billable_Amount_Base_Ccy: this.utils.convertNumberToString(formData.client_Billable_Amount_Base_Ccy),
            client_Billable_Amount_Local_Ccy: this.utils.convertNumberToString(
                formData.client_Billable_Amount_Local_Ccy
            ),
            client_Billable_Currency_Local_Ccy: formData.client_Billable_Currency_Local_Ccy,
            client_Billable_Original_Amount_Local_Ccy:
                originalAmountForAdjustment
                    ? originalAmountForAdjustment
                    : this.utils.convertNumberToString(formData.client_Billable_Original_Amount_Local_Ccy),
            client_Billable_Tax_Base_Ccy: this.utils.convertNumberToString(formData.client_Billable_Tax_Base_Ccy),
            client_Billable_Tax_Local_Ccy: this.utils.convertNumberToString(formData.client_Billable_Tax_Local_Ccy),
            client_Billable_Total_Base_Ccy: this.utils.convertNumberToString(formData.client_Billable_Total_Base_Ccy),
            client_Billable_Total_Local_Ccy: this.utils.convertNumberToString(formData.client_Billable_Total_Local_Ccy),
            client_Tax_Applicable: formData.client_Tax_Applicable.toString(),
            clientId: formData.clientId,
            comments: formData.comments,
            cost: "", // this is not on the formData object
            cover: formData.cover,
            date: this.utils.convertDateToString(formData.date),
            dateTo: this.utils.convertDateToString(formData.dateTo),
            accrualBillableItemBillDate: this.utils.convertDateToString(formData.accrualBillableItemBillDate),
            equipmentQuantity: this.utils.convertNumberToString(formData.equipmentQuantity),
            fee_Amount_Base_Ccy: this.utils.convertNumberToString(formData.fee_Amount_Base_Ccy),
            fee_Amount_Local_Ccy: this.utils.convertNumberToString(formData.fee_Amount_Local_Ccy),
            fee_Currency_Local_Ccy: formData.fee_Currency_Local_Ccy,
            fee_Percentage_Base_Ccy: this.utils.convertNumberToString(formData.fee_Percentage_Local_Ccy),
            fee_Percentage_Local_Ccy: this.utils.convertNumberToString(formData.fee_Percentage_Local_Ccy),
            fee_Tax_Applicable: formData.fee_Tax_Applicable.toString(),
            fee_Tax_Base_Ccy: this.utils.convertNumberToString(formData.fee_Tax_Base_Ccy),
            fee_Tax_Local_Ccy: this.utils.convertNumberToString(formData.fee_Tax_Local_Ccy),
            fee_Total_Base_Ccy: this.utils.convertNumberToString(formData.fee_Total_Base_Ccy),
            fee_Total_Local_Ccy: this.utils.convertNumberToString(formData.fee_Total_Local_Ccy),
            identification_Number: formData.identification_Number,
            identificationTypeId: formData.identificationTypeId,
            name: formData.name,
            non_Standard: formData.non_Standard
                ? formData.non_Standard.toLowerCase() == "non - standard" ||
                    formData.non_Standard.toLowerCase() == "yes"
                    ? "Yes"
                    : formData.non_Standard.toLowerCase() == "standard" || formData.non_Standard.toLowerCase() == "no"
                        ? "No"
                        : ""
                : "",
            pay_Date: billableItemIdValueForAdjustment != "" ? "" : this.utils.convertDateToString(formData.pay_Date),
            provider: formData.provider,
            provider_Pay_Amount_Base_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Amount_Base_Ccy),
            provider_Pay_Amount_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Amount_Local_Ccy),
            provider_Pay_Currency_Local_Ccy: formData.provider_Pay_Currency_Local_Ccy,
            provider_Pay_Original_Amount_Local_Ccy:
                originalAmountForAdjustment
                    ? originalAmountForAdjustment
                    : this.utils.convertNumberToString(formData.provider_Pay_Original_Amount_Local_Ccy),
            provider_Pay_Tax_Base_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Tax_Base_Ccy),
            provider_Pay_Tax_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Tax_Local_Ccy),
            provider_Pay_Total_Base_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Total_Base_Ccy),
            provider_Pay_Total_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Total_Local_Ccy),
            provider_Tax_Applicable: formData.provider_Tax_Applicable.toString(),
            providerId: providerId ? providerId : formData.providerId,
            //rate_Decimal: // send back as rate
            rate: this.utils.convertNumberToString(formData.rate_Decimal),
            providerPayAmountLocal: formData.providerPayAmountLocal ? this.utils.convertNumberToString(formData.providerPayAmountLocal) : "",
            providerPayAmountLocalExtras: formData.providerPayAmountLocalExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalExtras) : "",
            providerPayAmountLocalWOExtras: formData.providerPayAmountLocalWOExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalWOExtras) : "",
            providerFasterPayFee: formData.providerFasterPayFee ? this.utils.convertNumberToString(formData.providerFasterPayFee) : "",
            providerFasterPayOriginalAmount: formData.providerFasterPayOriginalAmount ? this.utils.convertNumberToString(formData.providerFasterPayOriginalAmount) : "",
            role: formData.role,
            service: formData.service,
            serviceSubTypeId: formData.serviceSubTypeId.toString(),
            serviceTypeId: formData.serviceTypeId.toString(),
            shift: "", // not on the formData object
            shift_Color: "", // do not send back
            shift_Font: "", // do not send back
            shift_End: this.utils.convertTimeToString(formData.shift_End),
            shift_Start: this.utils.convertTimeToString(formData.shift_Start),
            status: formData.status,
            status_BackGroundColor: "", // do not send back
            status_Color: "", // do not send back
            tenseFlag: "", // do not send back
            typeId: formData.typeId ? formData.typeId : "",
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate),
            unscheduled: formData.unscheduled,
            validationErrors: null, // must be null
            venueId: formData.venueId,
            venue: formData.venue,
            isAmend: isAmendActivity ? isAmendActivity.toString() : "",
            invoiceId: formData.invoiceId,
            paymentId: formData.paymentId,
            itemTypeId: itemType != "" ? itemType : formData.itemTypeId,
            itemTypeValue: formData.itemTypeValue,
            paymentCycleId: formData.paymentCycleId,
            originalBillableStartTime: this.utils.convertTimeToString(formData.originalBillableStartTime),
            originalBillableFinishTime: this.utils.convertTimeToString(formData.originalBillableFinishTime),
            originalRate: this.utils.convertNumberToString(formData.originalRate),
            equipmentOriginalQuantity: this.utils.convertNumberToString(formData.equipmentOriginalQuantity),
            noCharge: formData.noCharge
                ? formData.noCharge.toLowerCase() == "applied" || formData.noCharge.toLowerCase() == "no"
                    ? "No"
                    : formData.noCharge.toLowerCase() == "waived" || formData.noCharge.toLowerCase() == "yes"
                        ? "Yes"
                        : ""
                : "",
            originalBillableMinutes: this.utils.convertNumberToString(formData.originalBillableMinutes),
            childRecordId: formData.childRecordId,
            parentRecordId: paretRecordIdForAdjustment != "" ? paretRecordIdForAdjustment : formData.parentRecordId,
            internalComments: formData.internalComments,
            Recalculate: recalculateVal,
            requestApprover: formData.requestApprover,
            requestDate: this.utils.convertDateToString(formData.requestDate),
            otherDescription: formData.otherDescription,
            equipmentBillableQuantity: this.utils.convertNumberToString(formData.equipmentBillableQuantity),
            billableRate: this.utils.convertNumberToString(formData.billableRate),
            equipmentScheduleQuantity: this.utils.convertNumberToString(formData.equipmentScheduleQuantity),
            scheduleRate: this.utils.convertNumberToString(formData.scheduleRate),
            performanceInformationSetsNumber: this.utils.convertNumberToString(
                parseInt(formData.performanceInformationSetsNumber)
            ),
            performanceInformationSetsDuration: this.utils.convertNumberToString(
                parseInt(formData.performanceInformationSetsDuration)
            ),
            providerNetOverride:  this.utils.convertNumberToString(
                parseInt(formData.providerNetOverride)
            ),
            multiplierOverride:  this.utils.convertNumberToString(
                parseInt(formData.multiplierOverride)
            ),
            feeAmountOverride: formData.feeAmountOverride,
            performanceInformation: formData.performanceInformation,
            additionalInformation: formData.additionalInformation,
            genre: formData.genre,
            equipmentRequired: formData.equipmentRequired,
            description: formData.description,
            shiftCompleteAcknowledgmentDescription: formData.shiftCompleteAcknowledgmentDescription,
            providerPayAmountLocalExtrasDescription: formData.providerPayAmountLocalExtrasDescription,
            reoccurance: formData.reoccurance,
            billPeriod: formData.billPeriod,
            navigateFrom: navigatedFrom ? navigatedFrom : "",
            isAccrual: formData.isAccrual !== null && (formData.isAccrual.toString() == "Yes" || formData.isAccrual.toString() == "true") ? true : false,
            hardApproval: formData.hardApproval,
            holdPayment: formData.holdPayment,
            shiftCompleteAcknowledgmentDateTime: payRequestDate ? this.utils.convertTZDateToDDMMYYY(payRequestDate): 
            this.utils.convertDateToString(formData.shiftCompleteAcknowledgmentDateTime),
            //shiftCompleteAcknowledgmentTime: artistConfirmation == false ? "" : this.utils.convertTimeToString(formData.shiftCompleteAcknowledgmentTime),
            shiftCompleteAcknowledgmentTime: artistConfirmation == false ? "" : payRequestDate ? this.utils.getTimeFromDateString(
            payRequestDate) : (formData.shiftCompleteAcknowledgmentTime ? this.utils.convertTimeToString(
            formData.shiftCompleteAcknowledgmentTime) : ''),
            accrual: ((formData.accrual && formData.billableItemId && (formData.isAccrual.toString() == "Yes" || formData.isAccrual == true))) ? {

                id: formData.accrual.id,
                accrualTypeId: formData.accrual.accrualTypeId,
                accrualStatusId: formData.accrual.accrualStatusId,
                accrualBillableItemId: formData.accrual.accrualBillableItemId,
                accrualInvoiceId: formData.accrual.accrualInvoiceId,
                providerPayAmountAccrual: formData.accrual.providerPayAmountAccrual,
                providerPayAmountTaxAccrual: formData.accrual.providerPayAmountTaxAccrual,
                providerPayAmountTotalAccrual: formData.accrual.providerPayAmountTotalAccrual,
                grossProfitAmountAccrual: formData.accrual.grossProfitAmountAccrual,
                grossProfitAmountTaxAccrual: formData.accrual.grossProfitAmountTaxAccrual,
                grossProfitAmountTotalAccrual: formData.accrual.grossProfitAmountTotalAccrual,
                clientBillableAmountAccrual: formData.accrual.clientBillableAmountAccrual,
                clientBillableAmountTaxAccrual: formData.accrual.clientBillableAmountTaxAccrual,
                clientBillableAmountTotalAccrual: formData.accrual.clientBillableAmountTotalAccrual,
                lastUpdatedOn: formData.accrual.lastUpdatedOn,
                lastUpdatedBy: formData.accrual.lastUpdatedBy
            } : null,
            lastupdatedOn: formData.lastupdatedOn,
            paymentRequestId: paymentRequestId,
            paymentRequest: formData.paymentRequest,
            workerStatusId: formData.workerStatusId,
            workerStatusValue: formData.workerStatusValue,
            requestId: formData.requestId,
            updateOrCorrection: formData.updateOrCorrection == "true" ? true : false,
        };

        return submitRequest;
    }

    // Convert the form's data into a proper billable item submit request
    convertFormDataToBillableItemRequest(
        formData: AddEditFormData,
        statusId: string,
        itemType: string,
        isAmendActivity: boolean,
        billableItemIdValueForAdjustment: string,
        paretRecordIdForAdjustment: string,
        originalAmountForAdjustment: string,
        acceptDate?: NullableDate,
        approvalDate?: NullableDate,
        artistConfirmation?: boolean,
        navigatedFrom?: string,
        ReCalculate?: boolean,
        paymentId?: string,
        paymentRequestDate?: string
    ): BillableItemSubmitRequest {
        var paymentRequestId: string = "";
        var recalculateVal: boolean = false;
        var payRequestDate: string | undefined = "";
        let equipmentScheduledQuantity: string = "";

        if (formData.serviceTypeId == serviceTypes.Entertainment) {
            equipmentScheduledQuantity = "1";
        }
        else {
            equipmentScheduledQuantity = this.utils.convertNumberToString(formData.equipmentScheduleQuantity);
        }
        if (paymentId) {
            paymentRequestId = paymentId;
        }
        if (ReCalculate) {
            recalculateVal = ReCalculate;
        }
        else {
            recalculateVal = formData.Recalculate;
        }
        if (navigatedFrom == "paymentMatch") {
            payRequestDate = paymentRequestDate;
        }
        var submitRequest: BillableItemSubmitRequest = {
            absent: formData.absent
                ? formData.absent.toLowerCase() == "not present" || formData.absent.toLowerCase() == "yes"
                    ? "Yes"
                    : formData.absent.toLowerCase() == "no" || formData.absent.toLowerCase() == "present"
                        ? "No"
                        : ""
                : "",
            accept_Date:
                acceptDate != undefined || acceptDate != null
                    ? this.utils.convertDateToString(acceptDate)
                    : billableItemIdValueForAdjustment != ""
                        ? ""
                        : this.utils.convertDateToString(formData.accept_Date),
            approval_Date:
                approvalDate != undefined || approvalDate != null
                    ? this.utils.convertDateToString(approvalDate)
                    : billableItemIdValueForAdjustment != ""
                        ? ""
                        : isAmendActivity
                            ? this.utils.convertDateToString(formData.approval_Date)
                                ? this.utils.convertDateToString(formData.approval_Date)
                                : this.utils.convertDateToString(new Date())
                            : this.utils.convertDateToString(formData.approval_Date),
            bill_Date: billableItemIdValueForAdjustment != "" ? "" : this.utils.convertDateToString(formData.bill_Date),
            billable_Finish: this.utils.convertTimeToString(formData.billable_Finish),
            billable_Hours_Units: this.utils.convertNumberToString(formData.billable_Minutes),
            //billable_Minutes: "", <== this is a derived field;  send back as billable_Hours_Units
            billable_Start: this.utils.convertTimeToString(formData.billable_Start),
            billable_Start_Finish: "",
            billableItemId:
                billableItemIdValueForAdjustment != "" ? billableItemIdValueForAdjustment : formData.billableItemId,
            billableStatusLookupId: statusId != "" ? statusId : formData.billableStatusLookupId,
            check_In: this.utils.convertTimeToString(formData.check_In),
            check_In_Out: "",
            check_Out: this.utils.convertTimeToString(formData.check_Out),
            checkin_Color: "", // do not send back
            checkin_Font: "", // do not send back
            checkOut_Color: "", // do not send back
            checkOut_Font: "", // do not send back
            client: "", // do not know what this is, but it is not on the formData object
            client_Billable_Amount_Base_Ccy: this.utils.convertNumberToString(formData.client_Billable_Amount_Base_Ccy),
            client_Billable_Amount_Local_Ccy: this.utils.convertNumberToString(
                formData.client_Billable_Amount_Local_Ccy
            ),
            client_Billable_Currency_Local_Ccy: formData.client_Billable_Currency_Local_Ccy,
            client_Billable_Original_Amount_Local_Ccy:
                originalAmountForAdjustment
                    ? originalAmountForAdjustment
                    : this.utils.convertNumberToString(formData.client_Billable_Original_Amount_Local_Ccy),
            client_Billable_Tax_Base_Ccy: this.utils.convertNumberToString(formData.client_Billable_Tax_Base_Ccy),
            client_Billable_Tax_Local_Ccy: this.utils.convertNumberToString(formData.client_Billable_Tax_Local_Ccy),
            client_Billable_Total_Base_Ccy: this.utils.convertNumberToString(formData.client_Billable_Total_Base_Ccy),
            client_Billable_Total_Local_Ccy: this.utils.convertNumberToString(formData.client_Billable_Total_Local_Ccy),
            client_Tax_Applicable: formData.client_Tax_Applicable.toString(),
            clientId: formData.clientId,
            comments: formData.comments,
            cost: "", // this is not on the formData object
            cover: formData.cover,
            date: this.utils.convertDateToString(formData.date),
            dateTo: this.utils.convertDateToString(formData.dateTo),
            // dateCreated:formData.billableItemId ? this.utils.convertTZDateToDDMMYYY(formData.dateCreated_UI) : '',
            // dateCreatedTime :formData.billableItemId ? this.utils.convertTimeToString(formData.dateCreatedTime) : '',
            dateCreated:formData.billableItemId ? this.utils.convertTZDateToDDMMYYY(formData.dateCreated_UI) : this.utils.convertCurrDateToDDMMYYY(),
            dateCreatedTime :formData.billableItemId ? this.utils.convertTimeToString(formData.dateCreatedTime) : this.utils.convertCurrDateToTime(),
            accrualBillableItemBillDate: this.utils.convertDateToString(formData.accrualBillableItemBillDate),
            equipmentQuantity: this.utils.convertNumberToString(formData.equipmentQuantity),
            fee_Amount_Base_Ccy: this.utils.convertNumberToString(formData.fee_Amount_Base_Ccy),
            fee_Amount_Local_Ccy: this.utils.convertNumberToString(formData.fee_Amount_Local_Ccy),
            fee_Currency_Local_Ccy: formData.fee_Currency_Local_Ccy,
            fee_Percentage_Base_Ccy: this.utils.convertNumberToString(formData.fee_Percentage_Local_Ccy),
            fee_Percentage_Local_Ccy: this.utils.convertNumberToString(formData.fee_Percentage_Local_Ccy),
            fee_Tax_Applicable: formData.fee_Tax_Applicable.toString(),
            fee_Tax_Base_Ccy: this.utils.convertNumberToString(formData.fee_Tax_Base_Ccy),
            fee_Tax_Local_Ccy: this.utils.convertNumberToString(formData.fee_Tax_Local_Ccy),
            fee_Total_Base_Ccy: this.utils.convertNumberToString(formData.fee_Total_Base_Ccy),
            fee_Total_Local_Ccy: this.utils.convertNumberToString(formData.fee_Total_Local_Ccy),
            identification_Number: formData.identification_Number,
            identificationTypeId: formData.identificationTypeId,
            name: formData.name,
            non_Standard: formData.non_Standard
                ? formData.non_Standard.toLowerCase() == "non - standard" ||
                    formData.non_Standard.toLowerCase() == "yes"
                    ? "Yes"
                    : formData.non_Standard.toLowerCase() == "standard" || formData.non_Standard.toLowerCase() == "no"
                        ? "No"
                        : ""
                : "",
            pay_Date: billableItemIdValueForAdjustment != "" ? "" : this.utils.convertDateToString(formData.pay_Date),
            provider: formData.provider,
            provider_Pay_Amount_Base_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Amount_Base_Ccy),
            provider_Pay_Amount_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Amount_Local_Ccy),
            provider_Pay_Currency_Local_Ccy: formData.provider_Pay_Currency_Local_Ccy,
            provider_Pay_Original_Amount_Local_Ccy:
                originalAmountForAdjustment
                    ? originalAmountForAdjustment
                    : this.utils.convertNumberToString(formData.provider_Pay_Original_Amount_Local_Ccy),
            provider_Pay_Tax_Base_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Tax_Base_Ccy),
            provider_Pay_Tax_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Tax_Local_Ccy),
            provider_Pay_Total_Base_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Total_Base_Ccy),
            provider_Pay_Total_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Total_Local_Ccy),
            provider_Tax_Applicable: formData.provider_Tax_Applicable.toString(),
            providerId: formData.providerId,
            //rate_Decimal: // send back as rate
            rate: this.utils.convertNumberToString(formData.rate_Decimal),
            providerPayAmountLocal: formData.providerPayAmountLocal ? this.utils.convertNumberToString(formData.providerPayAmountLocal) : "",
            providerPayAmountLocalExtras: formData.providerPayAmountLocalExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalExtras) : "",
            providerPayAmountLocalWOExtras: formData.providerPayAmountLocalWOExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalWOExtras) : "",
            providerFasterPayFee: formData.providerFasterPayFee ? this.utils.convertNumberToString(formData.providerFasterPayFee) : "",
            providerFasterPayOriginalAmount: formData.providerFasterPayOriginalAmount ? this.utils.convertNumberToString(formData.providerFasterPayOriginalAmount) : "",
            role: formData.role,
            service: formData.service,
            serviceSubTypeId: formData.serviceSubTypeId.toString(),
            serviceTypeId: formData.serviceTypeId.toString(),
            shift: "", // not on the formData object
            shift_Color: "", // do not send back
            shift_Font: "", // do not send back
            shift_End: this.utils.convertTimeToString(formData.shift_End),
            shift_Start: this.utils.convertTimeToString(formData.shift_Start),
            status: formData.status,
            status_BackGroundColor: "", // do not send back
            status_Color: "", // do not send back
            tenseFlag: "", // do not send back
            typeId: formData.typeId ? formData.typeId : "",
            twoTierApprovalDate : this.utils.convertDateToString(formData.twoTierApprovalDate),
            unscheduled: formData.unscheduled,
            validationErrors: null, // must be null
            venueId: formData.venueId,
            venue: formData.venue,
            isAmend: isAmendActivity ? isAmendActivity.toString() : "",
            invoiceId: formData.invoiceId,
            paymentId: formData.paymentId,
            itemTypeId: itemType != "" ? itemType : formData.itemTypeId,
            itemTypeValue: formData.itemTypeValue,
            paymentCycleId: formData.paymentCycleId,
            originalBillableStartTime: this.utils.convertTimeToString(formData.originalBillableStartTime),
            originalBillableFinishTime: this.utils.convertTimeToString(formData.originalBillableFinishTime),
            originalRate: this.utils.convertNumberToString(formData.originalRate),
            equipmentOriginalQuantity: this.utils.convertNumberToString(formData.equipmentOriginalQuantity),
            noCharge: formData.noCharge
                ? formData.noCharge.toLowerCase() == "applied" || formData.noCharge.toLowerCase() == "no"
                    ? "No"
                    : formData.noCharge.toLowerCase() == "waived" || formData.noCharge.toLowerCase() == "yes"
                        ? "Yes"
                        : ""
                : "",
            originalBillableMinutes: this.utils.convertNumberToString(formData.originalBillableMinutes),
            childRecordId: formData.childRecordId,
            parentRecordId: paretRecordIdForAdjustment != "" ? paretRecordIdForAdjustment : formData.parentRecordId,
            internalComments: formData.internalComments,
            Recalculate: recalculateVal,
            requestApprover: formData.requestApprover,
            requestDate: this.utils.convertDateToString(formData.requestDate),
            otherDescription: formData.otherDescription,
            equipmentBillableQuantity: this.utils.convertNumberToString(formData.equipmentBillableQuantity),
            billableRate: this.utils.convertNumberToString(formData.billableRate),
            equipmentScheduleQuantity: equipmentScheduledQuantity,
            scheduleRate: this.utils.convertNumberToString(formData.scheduleRate),
            performanceInformationSetsNumber: this.utils.convertNumberToString(
                parseInt(formData.performanceInformationSetsNumber)
            ),
            performanceInformationSetsDuration: this.utils.convertNumberToString(
                parseInt(formData.performanceInformationSetsDuration)
            ),
            providerNetOverride:  this.utils.convertNumberToString(
                parseInt(formData.providerNetOverride)
            ),
            multiplierOverride:  this.utils.convertNumberToString(
                parseInt(formData.multiplierOverride)
            ),
            feeAmountOverride: formData.feeAmountOverride,
            performanceInformation: formData.performanceInformation,
            additionalInformation: formData.additionalInformation,
            genre: formData.genre,
            equipmentRequired: formData.equipmentRequired,
            description: formData.description,
            shiftCompleteAcknowledgmentDescription: formData.shiftCompleteAcknowledgmentDescription,
            providerPayAmountLocalExtrasDescription: formData.providerPayAmountLocalExtrasDescription,
            shiftCompleteAcknowledgment: artistConfirmation ? artistConfirmation : false,
            shiftCompleteAcknowledgmentDateTime: payRequestDate ? payRequestDate : this.utils.convertDateToString(
                formData.shiftCompleteAcknowledgmentDateTime
            ),
            shiftCompleteAcknowledgmentTime: artistConfirmation == false ? "" : this.utils.convertTimeToString(formData.shiftCompleteAcknowledgmentTime),
            reoccurance: formData.reoccurance,
            billPeriod: formData.billPeriod,
            navigateFrom: navigatedFrom ? navigatedFrom : "",
            isAccrual: formData.isAccrual !== null && (formData.isAccrual.toString() == "Yes" || formData.isAccrual.toString() == "true") ? true : false,
            hardApproval: formData.hardApproval,
            holdPayment: formData.holdPayment,
            accrual: ((formData.accrual && formData.billableItemId && (formData.isAccrual.toString() == "Yes" || formData.isAccrual == true))) ? {

                id: formData.accrual.id,
                accrualTypeId: formData.accrual.accrualTypeId,
                accrualStatusId: formData.accrual.accrualStatusId,
                accrualBillableItemId: formData.accrual.accrualBillableItemId,
                accrualInvoiceId: formData.accrual.accrualInvoiceId,
                providerPayAmountAccrual: formData.accrual.providerPayAmountAccrual,
                providerPayAmountTaxAccrual: formData.accrual.providerPayAmountTaxAccrual,
                providerPayAmountTotalAccrual: formData.accrual.providerPayAmountTotalAccrual,
                grossProfitAmountAccrual: formData.accrual.grossProfitAmountAccrual,
                grossProfitAmountTaxAccrual: formData.accrual.grossProfitAmountTaxAccrual,
                grossProfitAmountTotalAccrual: formData.accrual.grossProfitAmountTotalAccrual,
                clientBillableAmountAccrual: formData.accrual.clientBillableAmountAccrual,
                clientBillableAmountTaxAccrual: formData.accrual.clientBillableAmountTaxAccrual,
                clientBillableAmountTotalAccrual: formData.accrual.clientBillableAmountTotalAccrual,
                lastUpdatedOn: formData.accrual.lastUpdatedOn,
                lastUpdatedBy: formData.accrual.lastUpdatedBy
            } : null,
            lastupdatedOn: formData.lastupdatedOn,
            paymentRequestId: paymentRequestId,
            paymentRequest: formData.paymentRequest,
            workerStatusId: formData.workerStatusId,
            workerStatusValue: formData.workerStatusValue,
            fasterPayment: formData.fasterPayment !== null && (formData.fasterPayment.toString() == "true" || formData.fasterPayment.toString() == "Yes") ? true : false,
            fasterPaymentWithdrawn: formData.fasterPaymentWithdrawn !== null && (formData.fasterPaymentWithdrawn.toString() == "true" || formData.fasterPaymentWithdrawn.toString() == "Yes") ? true : false,
            advancedpayment: formData.advancedpayment !== null && (formData.advancedpayment.toString() == "true" || formData.advancedpayment.toString() == "Yes") ? true : false,
            earlyPay: formData.earlyPay !== null && (formData.earlyPay.toString() == "true" || formData.earlyPay.toString() == "Yes") ? true : false,
            originator: formData.originator ? {
                id: formData.originator.id,
                value: formData.originator.value
            } : null,
            requestId: formData.requestId,
            batchId: formData.batchId,
            childRecordItemType: formData.childRecordId,
            clientAmount: formData.clientAmount,
            venueTownCity: formData.venueTownCity,
            timeDecimal: formData.timeDecimal,
            timeDiffIndicator: formData.timeDiffIndicator,
            quantityDiffIndicator: formData.quantityDiffIndicator,
            gigTenseFlag: formData.gigTenseFlag,
            scheduled_Hours_Units: formData.scheduled_Hours_Units,
            imageLink: formData.imageLink,
            previousHoursRunningTotal: formData.previousHoursRunningTotal,
            previousQuantityRunningTotal: formData.previousQuantityRunningTotal,
            entertainmentDateInfo: formData.entertainmentDateInfo,
            address: formData.address,
            venue_Phone: formData.venue_Phone,
            contacts: formData.contacts ? formData.contacts : null,
            rateDiffIndicator: formData.rateDiffIndicator,
            queries: formData.queries ? formData.queries : null,
            feedback: formData.feedback,
            paymentRequestID: formData.paymentRequestID,
            paymentRequestStatusID: formData.paymentRequestStatusID,
            paymentRequestStatus: formData.paymentRequestStatus,
            isUserAmendable: formData.isUserAmendable,
            scheduledStartTime: formData.scheduledStartTime,
            scheduledFinishTime: formData.scheduledFinishTime,
            scheduledMinutes: this.utils.convertNumberToString(formData.scheduledMinutes),
            billableStartTime: formData.billableStartTime,
            billableFinishTime: formData.billableFinishTime,
            serviceSubTypeValue: formData.serviceSubTypeValue,
            typeValue: formData.typeValue,
            venuePostCode: formData.venuePostCode,
            updateOrCorrection: formData.updateOrCorrection == "true" ? true : false,
        };

        return submitRequest;
    }

    convertCalcDataToCalcRequest(formData: AddEditFormData, providerNetOverride?: number | null | undefined, multiplierOverride?: number, isAddorEdit?: number): CalculationRequest {
        let itemTypeId: string = "";
        let startTime: string = "";
        let finishTime: string = "";
        let quantity: string = "0";
        if (isAddorEdit == 0) {
            itemTypeId = "10";
        }
        else {
            itemTypeId = formData.itemTypeId;
        }
        if (formData.serviceTypeId == ServiceType.Entertainment) {
            startTime = "";
            finishTime = "";
            quantity = "1";
        }
        else if(formData.serviceTypeId == ServiceType.ContractCleaning || 
                formData.serviceTypeId == ServiceType.Gardening || 
                formData.serviceTypeId == ServiceType.WindowCleaning ||
                formData.serviceTypeId == ServiceType.Maintenance || 
                formData.serviceTypeId == ServiceType.Miscelleanous ||
                (formData.serviceTypeId == ServiceType.Security && formData.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment)){
                    startTime = "";
                    finishTime = "";
                    if (formData.equipmentQuantity) {
                        quantity = this.utils.convertNumberToString(formData.equipmentQuantity);
                    }
        }
        else {
            if (formData.billable_Start) {
                startTime = this.utils.convertTimeToString(formData.billable_Start);
            }
            if (formData.billable_Finish) {
                finishTime = this.utils.convertTimeToString(formData.billable_Finish);
            }   
            if (formData.equipmentQuantity) {
                quantity = this.utils.convertNumberToString(formData.equipmentQuantity);
            }         
        }
        return {
            StartTime: startTime,
            ScheduledStartTime: formData.updateOrCorrection == "false" ? startTime : formData.shift_Start ? this.utils.convertTimeToString(formData.shift_Start) : "",
            originalStartTime: this.utils.convertTimeToString(formData.originalBillableStartTime),
            FinishTime: finishTime,
            ScheduledFinishTime: formData.updateOrCorrection == "false" ? finishTime : formData.shift_End ? this.utils.convertTimeToString(formData.shift_End) : "",
            originalFinishTime: this.utils.convertTimeToString(formData.originalBillableFinishTime),
            Date: this.utils.convertDateToString(formData.date),
            ClientId: formData.clientId,
            ProviderId: formData.providerId,
            Quantity: quantity,
            ScheduledQuantity: formData.updateOrCorrection == "false" ? quantity : this.utils.convertNumberToString(formData.equipmentScheduleQuantity),
            originalQuantity: this.utils.convertNumberToString(formData.equipmentOriginalQuantity),
            Rate: formData.rate_Decimal ? this.utils.convertNumberToString(formData.rate_Decimal) : "0.00",
            ScheduledRate: formData.updateOrCorrection == "false" ? formData.rate_Decimal ? this.utils.convertNumberToString(formData.rate_Decimal) : "0.00" : formData.scheduleRate ? this.utils.convertNumberToString(formData.scheduleRate) : "0.00",
            fasterPay: formData.fasterPayment !== null && (formData.fasterPayment.toString() == "true" || formData.fasterPayment.toString() == "Yes") ? true : false,
            fasterPaymentWithdrawn: formData.fasterPaymentWithdrawn !== null && (formData.fasterPaymentWithdrawn.toString() == "true" || formData.fasterPaymentWithdrawn.toString() == "Yes") ? true : false,
            providerPayAmountLocal: formData.providerPayAmountLocal ? this.utils.convertNumberToString(formData.providerPayAmountLocal) : "",
            providerPayAmountLocalExtras: formData.providerPayAmountLocalExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalExtras) : "",
            providerPayAmountLocalWOExtras: formData.providerPayAmountLocalWOExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalWOExtras) : "",
            providerFasterPayFee: formData.providerFasterPayFee ? this.utils.convertNumberToString(formData.providerFasterPayFee) : "",
            providerFasterPayOriginalAmount: formData.providerFasterPayOriginalAmount ? this.utils.convertNumberToString(formData.providerFasterPayOriginalAmount) : "",
            originalRate: this.utils.convertNumberToString(formData.originalRate),
            ServiceTypeId: formData.serviceTypeId.toString(),
            ServiceSubTypeId: formData.serviceSubTypeId.toString(),
            itemTypeId: itemTypeId,
            multiplier: formData.fee_Percentage_Local_Ccy.toString(),
            IsAccrual: formData.isAccrual !== null && (formData.isAccrual.toString() == "Yes" || formData.isAccrual.toString() == "true") ? true : false,
            billableItemId: formData.billableItemId,
            ProviderNetOverride: providerNetOverride || providerNetOverride == 0 ? this.utils.convertNumberToString(providerNetOverride) : "",
            MultiplierOverride: multiplierOverride ? multiplierOverride.toString() : "",
            feeAmountOverride: formData.feeAmountOverride ? formData.feeAmountOverride.toString() : "",
        }
    }

    convertQuickAddCalcDataToCalcRequest(formData: QuickShiftFormData, providerNetOverride?: number | null | undefined, multiplierOverride?: number, isAddorEdit?: number): CalculationRequest {
        let itemTypeId: string = "";
        let startTime: string = "";
        let finishTime: string = "";
        let quantity: string = "0";
        if (isAddorEdit == 0) {
            itemTypeId = "10";
        }
        else {
            itemTypeId = formData.itemTypeId;
        }
        if (formData.serviceTypeId == ServiceType.Entertainment) {
            startTime = "";
            finishTime = "";
            quantity = "1";
        }
        else if(formData.serviceTypeId == ServiceType.ContractCleaning || 
                formData.serviceTypeId == ServiceType.Gardening || 
                formData.serviceTypeId == ServiceType.WindowCleaning ||
                formData.serviceTypeId == ServiceType.Maintenance || 
                formData.serviceTypeId == ServiceType.Miscelleanous ||
                (formData.serviceTypeId == ServiceType.Security && formData.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment)){
                    startTime = "";
                    finishTime = "";
                    if (formData.equipmentQuantity) {
                        quantity = this.utils.convertNumberToString(formData.equipmentQuantity);
                    }
        }
        else {
            if (formData.billable_Start) {
                startTime = this.utils.convertTimeToString(formData.billable_Start);
            }
            if (formData.billable_Finish) {
                finishTime = this.utils.convertTimeToString(formData.billable_Finish);
            }   
            if (formData.equipmentQuantity) {
                quantity = this.utils.convertNumberToString(formData.equipmentQuantity);
            }         
        }
        return {
            StartTime: startTime,
            ScheduledStartTime: formData.updateOrCorrection == "false" ? startTime : formData.shift_Start ? this.utils.convertTimeToString(formData.shift_Start) : "",
            originalStartTime: formData.originalBillableStartTime,
            FinishTime: finishTime,
            ScheduledFinishTime: formData.updateOrCorrection == "false" ? finishTime : formData.shift_End ? this.utils.convertTimeToString(formData.shift_End) : "",
            originalFinishTime: formData.originalBillableFinishTime,
            Date: this.utils.convertDateToString(formData.date),
            ClientId: formData.clientId,
            ProviderId: formData.providerId,
            Quantity: quantity,
            ScheduledQuantity: formData.updateOrCorrection == "false" ? quantity : this.utils.convertNumberToString(formData.equipmentScheduleQuantity),
            originalQuantity: formData.equipmentOriginalQuantity,
            Rate: formData.rate_Decimal ? this.utils.convertNumberToString(formData.rate_Decimal) : "0.00",
            ScheduledRate: formData.updateOrCorrection == "false" ? formData.rate_Decimal ? this.utils.convertNumberToString(formData.rate_Decimal) : "0.00" : formData.scheduleRate ? this.utils.convertNumberToString(formData.scheduleRate) : "0.00",
            fasterPay: formData.fasterPayment !== null && (formData.fasterPayment.toString() == "true" || formData.fasterPayment.toString() == "Yes") ? true : false,
            fasterPaymentWithdrawn: formData.fasterPaymentWithdrawn !== null && (formData.fasterPaymentWithdrawn.toString() == "true" || formData.fasterPaymentWithdrawn.toString() == "Yes") ? true : false,
            providerPayAmountLocal: formData.providerPayAmountLocal ? this.utils.convertNumberToString(formData.providerPayAmountLocal) : "",
            providerPayAmountLocalExtras: "",
            providerPayAmountLocalWOExtras: formData.providerPayAmountLocalWOExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalWOExtras) : "",
            providerFasterPayFee: formData.providerFasterPayFee ? this.utils.convertNumberToString(formData.providerFasterPayFee) : "",
            providerFasterPayOriginalAmount: "",
            originalRate: formData.originalRate,
            ServiceTypeId: formData.serviceTypeId.toString(),
            ServiceSubTypeId: formData.serviceSubTypeId.toString(),
            itemTypeId: itemTypeId,
            multiplier: formData.fee_Percentage_Local_Ccy.toString(),
            IsAccrual: formData.isAccrual !== null && (formData.isAccrual.toString() == "Yes" || formData.isAccrual.toString() == "true") ? true : false,
            billableItemId: formData.billableItemId,
            ProviderNetOverride: providerNetOverride || providerNetOverride == 0 ? this.utils.convertNumberToString(providerNetOverride) : "",
            MultiplierOverride: multiplierOverride ? multiplierOverride.toString() : "",
            feeAmountOverride: formData.feeAmountOverride ? formData.feeAmountOverride.toString() : "",
        }
    }

    convertCalcDataToResidencyCalcRequest(formData: any, providerNetOverride?: number | null | undefined, multiplierOverride?: number, isAddorEdit?: number): CalculationRequest {
        let itemTypeId: string = "";
        let startTime: string = "";
        let finishTime: string = "";
        let quantity: string = "1";
        if (isAddorEdit == 0) {
            itemTypeId = "10";
        }
        else {
            itemTypeId = formData.itemTypeId;
        }
        return {
            StartTime: startTime,
            ScheduledStartTime: "",
            originalStartTime: "",
            FinishTime: finishTime,
            ScheduledFinishTime: "",
            originalFinishTime: "",
            Date: this.utils.convertDateToString(formData.DateFrom),
            ClientId: formData.ClientId,
            ProviderId: formData.ProviderId,
            Quantity: quantity,
            ScheduledQuantity: quantity,
            originalQuantity: quantity,
            Rate: formData.Rate ? this.utils.convertNumberToString(formData.Rate) : "0.00",
            ScheduledRate: formData.Rate ? this.utils.convertNumberToString(formData.Rate) : "0.00",
            fasterPay: false,
            fasterPaymentWithdrawn: false,
            providerPayAmountLocal: formData.providerPayAmountLocal ? this.utils.convertNumberToString(formData.providerPayAmountLocal) : "",
            providerPayAmountLocalExtras: formData.providerPayAmountLocalExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalExtras) : "",
            providerPayAmountLocalWOExtras: formData.providerPayAmountLocalWOExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalWOExtras) : "",
            providerFasterPayFee: formData.providerFasterPayFee ? this.utils.convertNumberToString(formData.providerFasterPayFee) : "",
            providerFasterPayOriginalAmount: formData.providerFasterPayOriginalAmount ? this.utils.convertNumberToString(formData.providerFasterPayOriginalAmount) : "",
            originalRate: "0",
            ServiceTypeId: formData.ServiceTypeId.toString(),
            ServiceSubTypeId: formData.ServiceSubTypeId.toString(),
            itemTypeId: itemTypeId,
            multiplier: "",
            IsAccrual: false,
            billableItemId: "",
            ProviderNetOverride: providerNetOverride || providerNetOverride == 0 ? this.utils.convertNumberToString(providerNetOverride) : "",
            MultiplierOverride: multiplierOverride ? multiplierOverride.toString() : "",
            feeAmountOverride: formData.feeAmountOverride ? formData.feeAmountOverride.toString() : "",
        }
    }
}
