import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { SelectBox, NumberBox, DateBox, TextArea, LoadPanel, TextBox,ScrollView,Popup } from "devextreme-react";
import LookupService, { LookupTypeIndexes, LookupTypeItem, UnitOfMeasureTypes } from "../../services/LookupService";
import {
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
    DateBoxOnValueChangedEvent,
    NumberBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import addEditPopupUtils, { AddEditFormData, BusinessEntityTaxApplicableStatus, itemTypes } from "./AddEditPopUpUtilities";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faQuestion } from "@fortawesome/pro-regular-svg-icons";
import sharedUtils, { BusinessEntityTypes } from "./sharedUtilities";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import AddEditPopUpService, { BillableItemSubmitRequest, RaiseQueryFormData, TaxPercentage } from "../../services/AddEditPopUpService";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import { Link } from "react-router-dom";
import billableItemUtils from "../Utility/billableItemUtils";
import moment from "moment";

//#region props
interface FinanceAdjustmentFormProps extends WithTranslation {
    location: any;
    history: any;
    billableItemId?: any;
}
//#endregion
type NullableDate = Date | null | undefined;
//#region State

// State
interface FinanceAdjustmentFormState {
    headerId: string;
    loadIndicatorVisible: boolean;
    loadPanelVisible: boolean;
    IsShowSuccess: boolean;
    IsShowError: boolean;
    configuarationValue: [];
    isProviderTaxFieldVisible: boolean;
    configurationIdIndex: string[];
    configuarationValueIndex: string[];
    isFeeTaxFieldVisible: boolean;
    isBillClientTaxFieldVisible: boolean;
    errorMessage: [];
    unitOfMeasureList: LookupTypeItem[];
    editViewDataItem: AddEditFormData;
    itemTypeLookUp: LookupTypeItem[];
    isTypeChanged: boolean;
    errorsAdjustment: [];
    isLockItemTypeLU: boolean;
    radioButtonElements: LookupTypeItem[];
    isProviderCreditDebit: boolean;
    isClientCreditDebit: boolean;
    isPayableReceivable: boolean;
    isProviderDivApplicable: boolean;
    isFeeDivApplicable: boolean;
    isClientDivApplicable: boolean;
    currenyTypeLookUp: LookupTypeItem[];
    statusLookUp: LookupTypeItem[];
    isAcceptDateChanged: boolean;
    isApprovalDateChanged: boolean;
    venueNameLookUp: LookupTypeItem[];
    readOnlyView: boolean;
    queryItem: RaiseQueryFormData;
    validationMessage: string;
    businessEntityTaxApplicableStatuses: BusinessEntityTaxApplicableStatus[];
    showWarningPopup:boolean;
}
//#endregion

// Component
class FinanceAdjustmentForm extends React.Component<FinanceAdjustmentFormProps> {
    lookupService: LookupService;
    addEditUtils: addEditPopupUtils;
    utils: sharedUtils;
    state: FinanceAdjustmentFormState;
    editViewService: AddEditPopUpService;
    decisionTypesDataSource: LookupTypeItem[];
    nonStandardTypeDataSource: LookupTypeItem[];
    billableItemUtils: billableItemUtils;
    constructor(props: FinanceAdjustmentFormProps) {
        super(props);
        //#region Service Initialization
        this.lookupService = new LookupService();
        this.editViewService = new AddEditPopUpService();
        this.utils = new sharedUtils();
        this.addEditUtils = new addEditPopupUtils();
        this.billableItemUtils = new billableItemUtils();
        this.decisionTypesDataSource = this.lookupService.getDecisionTypes();
        this.nonStandardTypeDataSource = this.lookupService.getNonStandardTypes();
        //#endregion
        //#region     Bind the functions to set the context of 'this' inside of the Execution context.
        this.editViewDataSource = this.editViewDataSource.bind(this);
        // Promise handlers
        this.handleSuccessViewDataSource = this.handleSuccessViewDataSource.bind(this);
        this.handleFormDataSubmissionSuccess = this.handleFormDataSubmissionSuccess.bind(this);
        this.handleConfigurationLookupSuccess = this.handleConfigurationLookupSuccess.bind(this);
        this.handleSuccesStatusLookUp = this.handleSuccesStatusLookUp.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        // Utility functions
        this.hideLoadpanel = this.hideLoadpanel.bind(this);
        this.initializeFieldsBasedOnUnitOfMeasure = this.initializeFieldsBasedOnUnitOfMeasure.bind(this);
        this.disableFieldsBasedOnUnitOfMeasure = this.disableFieldsBasedOnUnitOfMeasure.bind(this);
        this.financeRecalculationOnPercentageChange = this.financeRecalculationOnPercentageChange.bind(this);
        // Event handlers
        this.handleChangeClientTypeSelect = this.handleChangeClientTypeSelect.bind(this);
        this.handleChangeVenueTypeSelect = this.handleChangeVenueTypeSelect.bind(this);
        this.handleChangeStatusTypeSelect = this.handleChangeStatusTypeSelect.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
        this.handleChangeProviderTypeSelect = this.handleChangeProviderTypeSelect.bind(this);
        this.handleChangeServiceTypeSelect = this.handleChangeServiceTypeSelect.bind(this);
        this.handleChangeServiceSubTypeSelect = this.handleChangeServiceSubTypeSelect.bind(this);
        this.handleChangeComments = this.handleChangeComments.bind(this);
        this.handleChangeProviderTaxApplicableSelect = this.handleChangeProviderTaxApplicableSelect.bind(this);
        this.handleChangeFeeTaxApplicableSelect = this.handleChangeFeeTaxApplicableSelect.bind(this);
        this.handleChangeClientTaxApplicableSelect = this.handleChangeClientTaxApplicableSelect.bind(this);
        this.handleChangeProviderCurrencyTypeSelect = this.handleChangeProviderCurrencyTypeSelect.bind(this);
        this.handleChangeFeeCurrencyTypeSelect = this.handleChangeFeeCurrencyTypeSelect.bind(this);
        this.handleChangeBillCurrencyTypeSelect = this.handleChangeBillCurrencyTypeSelect.bind(this);
        this.handleChangeAcceptDate = this.handleChangeAcceptDate.bind(this);
        this.handleChangePercentage = this.handleChangePercentage.bind(this);
        this.handleChangeApprovalDate = this.handleChangeApprovalDate.bind(this);
        this.handleChangeProviderLocalCurrancyAmount = this.handleChangeProviderLocalCurrancyAmount.bind(this);
        this.handleChangeProviderBaseCurrancyAmount = this.handleChangeProviderBaseCurrancyAmount.bind(this);
        this.handleChangeFeeLocalCurrancyAmount = this.handleChangeFeeLocalCurrancyAmount.bind(this);
        this.handleChangeFeeBaseCurrancyAmount = this.handleChangeFeeBaseCurrancyAmount.bind(this);
        this.handleChangeBillLocalCurrancyAmount = this.handleChangeBillLocalCurrancyAmount.bind(this);
        this.handleChangeBillBaseCurrancyAmount = this.handleChangeBillBaseCurrancyAmount.bind(this);
        this.handleChangeProviderLocalCurrancyTax = this.handleChangeProviderLocalCurrancyTax.bind(this);
        this.handleChangeProviderBaseCurrancyTax = this.handleChangeProviderBaseCurrancyTax.bind(this);
        this.handleChangeFeeLocalCurrancyTax = this.handleChangeFeeLocalCurrancyTax.bind(this);
        this.handleChangeFeeBaseCurrancyTax = this.handleChangeFeeBaseCurrancyTax.bind(this);
        this.handleChangeBillLocalCurrancyTax = this.handleChangeBillLocalCurrancyTax.bind(this);
        this.handleChangeBillBaseCurrancyTax = this.handleChangeBillBaseCurrancyTax.bind(this);
        this.handleChangeProviderOriginalAmount = this.handleChangeProviderOriginalAmount.bind(this);
        this.handleChangeFeeNonStandardTypeSelect = this.handleChangeFeeNonStandardTypeSelect.bind(this);
        this.handleChangeBillOriginalAmount = this.handleChangeBillOriginalAmount.bind(this);
        this.handleChangePayDate = this.handleChangePayDate.bind(this);
        this.handleChangeBillDate = this.handleChangeBillDate.bind(this);
        this.handleUnitOfMeasureLookupSuccess = this.handleUnitOfMeasureLookupSuccess.bind(this);
        this.handleStandardError = this.handleStandardError.bind(this);
        this.handleSuccessItemTypeLookUp = this.handleSuccessItemTypeLookUp.bind(this);
        this.getRadioButtonElements = this.getRadioButtonElements.bind(this);
        this.handleChangeOfferingsTypeSelect = this.handleChangeOfferingsTypeSelect.bind(this);
        this.enableDisablementOfFields = this.enableDisablementOfFields.bind(this);
        this.handleCurrencyTypeLookUpSuccess = this.handleCurrencyTypeLookUpSuccess.bind(this);
        this.handleChangeItemTypeSelect = this.handleChangeItemTypeSelect.bind(this);
        //#endregion
        //#region Set default values to the state object
        // Initialize state
        var convertedEditViewDataItem = this.addEditUtils.initializeBillableItem(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        var convertedQueryFormData = this.addEditUtils.initializeQueryFormData(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : "",
            "",
            "",
            "Shift / Item Status Query",
            ""
        );
        this.state = {
            // fetching headerId from parameter
            loadIndicatorVisible: false,
            loadPanelVisible: false,
            headerId: props.location.state && props.location.state.id ? props.location.state.id.toString() : "",
            IsShowSuccess: false,
            IsShowError: false,
            configuarationValue: [],
            configuarationValueIndex: [],
            errorMessage: [],
            configurationIdIndex: [],
            isProviderTaxFieldVisible:
                convertedEditViewDataItem.provider_Tax_Applicable &&
                    convertedEditViewDataItem.provider_Tax_Applicable == "Yes"
                    ? true
                    : false,
            isFeeTaxFieldVisible:
                convertedEditViewDataItem.fee_Tax_Applicable && convertedEditViewDataItem.fee_Tax_Applicable == "Yes"
                    ? true
                    : false,
            isBillClientTaxFieldVisible:
                convertedEditViewDataItem.client_Tax_Applicable &&
                    convertedEditViewDataItem.client_Tax_Applicable == "Yes"
                    ? true
                    : false,
            unitOfMeasureList: [],
            editViewDataItem: convertedEditViewDataItem,
            itemTypeLookUp: [],
            isTypeChanged: false,
            errorsAdjustment: [],
            isLockItemTypeLU: false,
            radioButtonElements: [],
            isProviderCreditDebit: true,
            isClientCreditDebit: true,
            isPayableReceivable: true,
            isProviderDivApplicable: true,
            isFeeDivApplicable: true,
            isClientDivApplicable: true,
            currenyTypeLookUp: [],
            statusLookUp: [],
            isAcceptDateChanged: false,
            isApprovalDateChanged: false,
            venueNameLookUp: [],
            readOnlyView:
                this.props.location && this.props.location.state && this.props.location.state.isReadOnly
                    ? this.props.location.state.isReadOnly
                    : false,
            queryItem: convertedQueryFormData,
            validationMessage: "",
            businessEntityTaxApplicableStatuses: [],
            showWarningPopup:false
        };
        //#endregion
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        this.editViewDataSource();
    }
    getRadioButtonElements() {
        let buttonElements: LookupTypeItem[];
        buttonElements = [];
        this.state.itemTypeLookUp.forEach((item: any, uniqueKey: number) => {
            buttonElements.push(item);
        });
        let buttonElementsAfterSplice = buttonElements.splice(5, 10);
        this.setState({
            radioButtonElements: buttonElementsAfterSplice,
        });
    }
    editViewDataSource() {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.itemType)
            .then(this.handleSuccessItemTypeLookUp)
            .catch(this.handleStandardError);
        // Retrieve form data from server if an ID was provided
        if (this.state.headerId != "") {
            this.editViewService
                .getEditViewData(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
        } else if(this.props.location.state?.pointOfNavigation == "copy_simpleshifts" && this.props.location.state?.copiedBillableId) {
            this.editViewService
                .getEditViewData(this.props.location.state.copiedBillableId)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
        }
        else {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    accrual: null
                },
            });
            this.setHardApprovalHoldPayment();
        }

        //Retrieve the status list
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.statusType)
            .then(this.handleSuccesStatusLookUp)
            .catch(this.handleStandardError);

        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.configuration)
            .then(this.handleConfigurationLookupSuccess)
            .catch(this.handleStandardError);

        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.unitOfMeasure)
            .then(this.handleUnitOfMeasureLookupSuccess)
            .catch(this.handleStandardError);

        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.currencytype)
            .then(this.handleCurrencyTypeLookUpSuccess)
            .catch(this.handleStandardError);
        this.editViewService
            .getTaxApplicableStatus()
            .then(this.handleTaxApplicableSuccess)
            .catch(this.handleStandardError);
    }

    /**
   * Handle the success response for retrieving Tax Applicable statuses
   *
   * @param response {AxiosResponse<any>} - server response
   */
    handleTaxApplicableSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                businessEntityTaxApplicableStatuses: response.data.data,
            },
            () => {
                let isCopy =  this.props.location?.state?.pointOfNavigation == "copy_simpleshifts"? true : false;
                if(!isCopy){
                    this.setFeeTaxApplicableOnInitialLoad();
                }
            }
        );
    };

    // setting the company tax 

    setFeeTaxApplicableOnInitialLoad = () => {
        var taxApplicable: string = "No";
        var statuses: BusinessEntityTaxApplicableStatus[] = this.state.businessEntityTaxApplicableStatuses;
        // Set the Fee tax applicable field immediately
        var businessEntityItem = statuses.find(
            (item) => item.businessEntityType === BusinessEntityTypes.Company
        );
        if (businessEntityItem && businessEntityItem.taxApplicable) {
            taxApplicable = "Yes";
        }
        if (businessEntityItem) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Tax_Applicable: taxApplicable,
                },
            });
        }
    }

    setHardApprovalHoldPayment = () => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                hardApproval: "No",
                holdPayment: "No"
            },
        });
    }

    // set the provider and client tax applicable and calculating all the values

    setTaxApplicableStatus = (businessEntityId: string) => {
        var taxStatus = this.state.businessEntityTaxApplicableStatuses.find(
            (item) => item.businessEntityID === businessEntityId
        );
        var taxApplicable: string = "No";

        // A client/provider is tax applicable if they have a tax registration number
        if (taxStatus && taxStatus.taxApplicable) {
            taxApplicable = "Yes";
        }

        if (taxStatus) {
            // Based on entity type, populate the tax applicable dropdown
            switch (taxStatus.businessEntityType) {
                case BusinessEntityTypes.Client:
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            client_Tax_Applicable: taxApplicable,
                        },
                    });
                    break;
                case BusinessEntityTypes.Provider:
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            provider_Tax_Applicable: taxApplicable,
                        },
                    });
                    break;
                // case BusinessEntityTypes.Company:
                //     this.setState({
                //         editViewDataItem: {
                //             ...this.state.editViewDataItem,
                //             fee_Tax_Applicable: taxApplicable,
                //         },
                //     });
                //     break;
            }
        }
        this.providerPayTaxCalculation();
        this.feeTaxCalculation();
        this.clientTaxCalculation();
    };

    providerPayTaxCalculation = () => {
        let providerCalculationVal: number[] = [];
        providerCalculationVal = this.addEditUtils.providerPayTaxCal(
            this.state.editViewDataItem,
            this.state.configuarationValue
        );
        let providerPayTaxLocalCcy: number = 0;
        let providerPayAmountTotalCcy: number = 0;
        let providerTaxFieldVisibility: number = 0;
        if (providerCalculationVal.length > 0) {
            providerPayTaxLocalCcy = providerCalculationVal[0];
            providerPayAmountTotalCcy = providerCalculationVal[1];
            if (providerCalculationVal.length == 3) {
                providerTaxFieldVisibility = providerCalculationVal[2];
            }
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Tax_Local_Ccy: providerPayTaxLocalCcy,
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Total_Local_Ccy: providerPayAmountTotalCcy,
            },
        });
        if (providerTaxFieldVisibility == 1) {
            this.setState({ isProviderTaxFieldVisible: true });
        } else {
            this.setState({ isProviderTaxFieldVisible: false });
        }
    };

    feeTaxCalculation = () => {
        let feeCalculationVal: number[] = [];
        feeCalculationVal = this.addEditUtils.feeTaxCal(this.state.editViewDataItem, this.state.configuarationValue);
        let feeTaxLocalCcy: number = 0;
        let feeAmountTotalCcy: number = 0;
        let feeTaxFieldVisibility: number = 0;
        if (feeCalculationVal.length > 0) {
            feeTaxLocalCcy = feeCalculationVal[0];
            feeAmountTotalCcy = feeCalculationVal[1];
            if (feeCalculationVal.length == 3) {
                feeTaxFieldVisibility = feeCalculationVal[2];
            }
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Tax_Local_Ccy: feeTaxLocalCcy,
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Total_Local_Ccy: feeAmountTotalCcy,
            },
        });
        if (feeTaxFieldVisibility == 1) {
            this.setState({ isFeeTaxFieldVisible: true });
        } else {
            this.setState({ isFeeTaxFieldVisible: false });
        }
    };

    clientTaxCalculation = () => {
        let clientCalculationVal: number[] = [];
        clientCalculationVal = this.addEditUtils.clientTaxCal(
            this.state.editViewDataItem,
            this.state.configuarationValue
        );
        let clientTaxLocalCcy: number = 0;
        let clientAmountTotalCcy: number = 0;
        let clientTaxFieldVisibility: number = 0;
        if (clientCalculationVal.length > 0) {
            clientTaxLocalCcy = clientCalculationVal[0];
            clientAmountTotalCcy = clientCalculationVal[1];
            if (clientCalculationVal.length == 3) {
                clientTaxFieldVisibility = clientCalculationVal[2];
            }
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Tax_Local_Ccy: clientTaxLocalCcy,
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Total_Local_Ccy: clientAmountTotalCcy,
            },
        });
        if (clientTaxFieldVisibility == 1) {
            this.setState({ isBillClientTaxFieldVisible: true });
        } else {
            this.setState({ isBillClientTaxFieldVisible: false });
        }
    };


    handleStandardError(error: any) {
        var respMessage: string = "Add/Edit shift form load failed with response: " + JSON.stringify(error);

        if (!this.editViewService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }
    handleCurrencyTypeLookUpSuccess(response: AxiosResponse<any>) {
        //currenyTypeLUItem
        var currenyLUItem: LookupTypeItem;
        var currenyLUItemArray: LookupTypeItem[];
        currenyLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            currenyLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            currenyLUItemArray.push(currenyLUItem);
        });
        this.setState({
            currenyTypeLookUp: currenyLUItemArray,
        });
    }
    handleSuccessItemTypeLookUp(response: AxiosResponse<any>) {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            itemLUItemArray.push(itemLUItem);
        });
        this.setState({
            itemTypeLookUp: itemLUItemArray,
        });
        this.getRadioButtonElements();
    }
    handleSuccesStatusLookUp(response: AxiosResponse<any>) {
        var statusLUItem: LookupTypeItem;
        var statusLUItemArray: LookupTypeItem[];
        statusLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            statusLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            statusLUItemArray.push(statusLUItem);
        });
        this.setState({
            statusLookUp: statusLUItemArray,
        });
    }

    handleConfigurationLookupSuccess(response: AxiosResponse<any>) {
        var configData = this.utils.getConfigurationDataFromLookupResponse(response.data.data);

        this.setState({
            configuarationValue: response.data.data,
            configurationIdIndex: configData.id,
            configuarationValueIndex: configData.value,
        });
    }

    handleUnitOfMeasureLookupSuccess(response: AxiosResponse<any>) {
        this.setState({
            unitOfMeasureList: response.data.data,
        });

        this.initializeFieldsBasedOnUnitOfMeasure(
            this.state.editViewDataItem.serviceSubTypeId,
            this.state.unitOfMeasureList
        );
    }

    // Manage successful data source response
    handleSuccessViewDataSource(response: AxiosResponse<any>) {
        let isPopup: boolean = false;
        if (this.props.billableItemId) {
            isPopup = true;
        }
        // Convert to proper data types
        var viewDataSource = this.addEditUtils.convertBillableItemResponeToFormData(response.data.data, isPopup);
        let isCopy =  this.props.location?.state?.pointOfNavigation == "copy_simpleshifts"? true : false;
        if(isCopy){
            viewDataSource = this.addEditUtils.copyBillableItem(response.data.data);
        } 

        this.setState({
            queryItem: {
                ...this.state.queryItem,
                venueId: response.data.data.venueId ? response.data.data.venueId : "",
            },
        });
        // In case of Amend status will be pre set to "Approve"
        // Set form data onto state
        this.setState({
            editViewDataItem: viewDataSource,
        });
        this.enableDisablementOfFields(this.state.editViewDataItem.itemTypeId);
    }

    //#endregion
    //#region 'handleChangeOnClickEvent
    // Series of callback functions that would set the state of the respective ID's when a selection is made inside the Dropdown.
    handleChangeClientTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                clientId: dxValueChange.value,
                venueId:''
            },
        });
        this.setTaxApplicableStatus(dxValueChange.value);
    }

    handleChangeItemTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    itemTypeId: dxValueChange.value,
                },
            });
            this.enableDisablementOfFields(this.state.editViewDataItem.itemTypeId);
            this.resetForm();
        }
    }

    resetForm = () => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                clientId: "",
                serviceTypeId: "",
                serviceSubTypeId: "",
                date: undefined,
                dateTo: undefined,
                venueId: "",
                providerId: "",
                name: "",
                comments: "",
                internalComments: "",
                provider_Tax_Applicable: "",
                provider_Pay_Amount_Local_Ccy: 0,
                provider_Pay_Tax_Local_Ccy: 0,
                provider_Pay_Total_Local_Ccy: 0,
                provider_Pay_Original_Amount_Local_Ccy: 0,
                accept_Date: undefined,
                pay_Date: undefined,
                // fee_Tax_Applicable: "",
                fee_Amount_Local_Ccy: 0,
                fee_Tax_Local_Ccy: 0,
                fee_Total_Local_Ccy: 0,
                non_Standard: "",
                client_Tax_Applicable: "",
                client_Billable_Amount_Local_Ccy: 0,
                client_Billable_Tax_Local_Ccy: 0,
                client_Billable_Total_Local_Ccy: 0,
                client_Billable_Original_Amount_Local_Ccy: 0,
                approval_Date: undefined,
                bill_Date: undefined,
            },
        });
    };

    handleChangeOfferingsTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                typeId: dxValueChange.value,
            },
        });
    }
    handleChangeVenueTypeSelect(dxValueChange: LookUpOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                venueId: dxValueChange.value,
            },
        });

        this.setState({
            queryItem: {
                ...this.state.queryItem,
                venueId: dxValueChange.value,
            },
        });
    }

    handleChangeStatusTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                billableStatusLookupId: dxValueChange.value,
            },
        });
    }

    handleChangeDate(dxValueChange: DateBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                date: dxValueChange.value,
            },
        });
        if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                this.state.editViewDataItem.serviceSubTypeId,
                UnitOfMeasureTypes.time,
                this.state.unitOfMeasureList
            )
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.time);
        } else if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                this.state.editViewDataItem.serviceSubTypeId,
                UnitOfMeasureTypes.quantity,
                this.state.unitOfMeasureList
            )
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        } else if (
            this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning ||
            this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        }
    }

    handleChangeDateTo(dxValueChange: DateBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                dateTo: dxValueChange.value,
            },
        });
    }

    handleChangeProviderTypeSelect(dxValueChange: LookUpOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                providerId: dxValueChange.value,
            },
        });
        this.setTaxApplicableStatus(dxValueChange.value);
    }

    handleChangeServiceTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    serviceTypeId: dxValueChange.value,
                    serviceSubTypeId: "",
                },
            });
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        }
    }
    handleChangeServiceSubTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        if (dxValueChange.event !== undefined) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                serviceSubTypeId: dxValueChange.value,
            },
        });
        if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                dxValueChange.value,
                UnitOfMeasureTypes.time,
                this.state.unitOfMeasureList
            )
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.time);
        } else if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                dxValueChange.value,
                UnitOfMeasureTypes.quantity,
                this.state.unitOfMeasureList
            )
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        }
        this.financeRecalculationOnPercentageChange();
        this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
    }
    }

    initializeFieldsBasedOnUnitOfMeasure(subTypeId: string, unitOfMeasureList: LookupTypeItem[]) {
        // Disabling fields dependent on: 1) the form data server request 2) the unit of measure list
        // Both success promises (this is one) will need to set execute the disable as it cannot be known who's promise will complete first
        if (subTypeId && subTypeId.length > 0) {
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                subTypeId,
                UnitOfMeasureTypes.time,
                unitOfMeasureList
            )
                ? this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.time)
                : this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        }
    }

    /**
     * Set disabled attribute based on selected unit of measure (sub type).
     * Also reset the Date To field
     *  If Time      - Date To = Date From
     *  If Equipment - Date To = Date From + 6 days
     *
     * @param unitOfMeasureType {string} - the unit of measure
     * @returns {void}
     */
    // disableFieldsBasedOnUnitOfMeasure(unitOfMeasureType: string): void {
    //     var isTimeSelected = unitOfMeasureType === UnitOfMeasureTypes.time;
    //     var dateFromPlusSix = undefined;

    //     if (this.state.editViewDataItem.date) {
    //         dateFromPlusSix = new Date(this.state.editViewDataItem.date);
    //         dateFromPlusSix.setDate(dateFromPlusSix.getDate() + 6);
    //     }

    //     this.setState({
    //         IsLockTimeFields: !isTimeSelected,
    //         IsLockQuantityFields: isTimeSelected,
    //         editViewDataItem: {
    //             ...this.state.editViewDataItem,
    //             dateTo:
    //                 isTimeSelected ||
    //                     this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment ||
    //                     this.state.editViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning
    //                     ? this.state.editViewDataItem.date
    //                     : dateFromPlusSix,
    //         },
    //     });
    // }
    disableFieldsBasedOnUnitOfMeasure = (unitOfMeasureType: string) => {
        var dateTo = this.addEditUtils.setDateToField(
            unitOfMeasureType,
            this.state.editViewDataItem.date,
            this.state.editViewDataItem.serviceTypeId,
            this.state.editViewDataItem.serviceSubTypeId
        );
        var isLockTimeFields = this.addEditUtils.lockTimeFields(unitOfMeasureType);
        var isLockQuantityFields = this.addEditUtils.lockQuantityFields(unitOfMeasureType);
        this.setState({
            IsLockTimeFields: isLockTimeFields,
            IsLockQuantityFields: isLockQuantityFields,
            editViewDataItem: {
                ...this.state.editViewDataItem,
                dateTo: dateTo,
            },
        });
    };

    financeRecalculationOnPercentageChange() {
        if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                this.state.editViewDataItem.serviceSubTypeId,
                UnitOfMeasureTypes.time,
                this.state.unitOfMeasureList
            )
        ) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Amount_Local_Ccy:
                        (this.state.editViewDataItem.billable_Minutes / 60) *
                        this.state.editViewDataItem.fee_Percentage_Local_Ccy,
                    fee_Amount_Base_Ccy:
                        (this.state.editViewDataItem.billable_Minutes / 60) *
                        this.state.editViewDataItem.fee_Percentage_Local_Ccy,
                },
            });
        } else if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                this.state.editViewDataItem.serviceSubTypeId,
                UnitOfMeasureTypes.quantity,
                this.state.unitOfMeasureList
            )
        ) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Amount_Local_Ccy:
                        this.state.editViewDataItem.rate_Decimal *
                        this.state.editViewDataItem.equipmentQuantity *
                        this.state.editViewDataItem.fee_Percentage_Local_Ccy,
                    fee_Amount_Base_Ccy:
                        this.state.editViewDataItem.rate_Decimal *
                        this.state.editViewDataItem.equipmentQuantity *
                        this.state.editViewDataItem.fee_Percentage_Local_Ccy,
                },
            });
        }
        if (this.state.editViewDataItem.fee_Tax_Applicable == "Yes") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Tax_Local_Ccy: this.state.editViewDataItem.fee_Amount_Local_Ccy * TaxPercentage.Percentage,
                    fee_Tax_Base_Ccy: this.state.editViewDataItem.fee_Amount_Base_Ccy * TaxPercentage.Percentage,
                },
            });
        } else if (this.state.editViewDataItem.fee_Tax_Applicable == "No") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Tax_Local_Ccy: 0.0,
                    fee_Tax_Base_Ccy: 0.0,
                },
            });
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Total_Local_Ccy:
                    this.state.editViewDataItem.fee_Amount_Local_Ccy + this.state.editViewDataItem.fee_Tax_Local_Ccy,
                fee_Total_Base_Ccy:
                    this.state.editViewDataItem.fee_Amount_Base_Ccy + this.state.editViewDataItem.fee_Tax_Base_Ccy,
            },
        });
        if (this.state.editViewDataItem.client_Tax_Applicable == "Yes") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy:
                        this.state.editViewDataItem.provider_Pay_Tax_Local_Ccy +
                        this.state.editViewDataItem.fee_Tax_Local_Ccy,
                    client_Billable_Tax_Base_Ccy:
                        this.state.editViewDataItem.provider_Pay_Tax_Base_Ccy +
                        this.state.editViewDataItem.fee_Tax_Base_Ccy,
                },
            });
        } else if (this.state.editViewDataItem.client_Tax_Applicable == "No") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy: 0.0,
                    client_Billable_Tax_Base_Ccy: 0.0,
                },
            });
        }
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Amount_Local_Ccy:
                    this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy +
                    this.state.editViewDataItem.fee_Amount_Local_Ccy,
                client_Billable_Amount_Base_Ccy:
                    this.state.editViewDataItem.provider_Pay_Amount_Base_Ccy +
                    this.state.editViewDataItem.fee_Amount_Base_Ccy,
            },
        });
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Total_Local_Ccy:
                    this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                    this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
                client_Billable_Total_Base_Ccy:
                    this.state.editViewDataItem.client_Billable_Amount_Base_Ccy +
                    this.state.editViewDataItem.client_Billable_Tax_Base_Ccy,
            },
        });
    }

    hideLoadIndicator = () => {
        this.setState({ loadIndicatorVisible: false });
    };

    showLoadIndicator = () => {
        this.setState({ loadIndicatorVisible: true });
    };

    handleChangeComments(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                comments: dxValueChange.value,
            },
        });

        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
            },
        });
    }

    handleChangeInternalComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    internalComments: dxValueChange.value,
                },
            });
        }
    };

    enableDisablementOfFields(itemTypeId: string) {
        if (itemTypeId == itemTypes.ProviderCredit || itemTypeId == itemTypes.ProviderDebit) {
            this.setState({
                isProviderCreditDebit: false,
                isClientCreditDebit: true,
                isPayableReceivable: true,
                isProviderDivApplicable: false,
                isFeeDivApplicable: true,
                isClientDivApplicable: true,
            });
        } else if (itemTypeId == itemTypes.ClientCredit || itemTypeId == itemTypes.ClientDebit) {
            this.setState({
                isClientCreditDebit: false,
                isProviderCreditDebit: true,
                isPayableReceivable: true,
                isFeeDivApplicable: false,
                isProviderDivApplicable: true,
                isClientDivApplicable: true,
            });
        } else if (itemTypeId == itemTypes.Payable || itemTypeId == itemTypes.Receivable) {
            this.setState({
                isPayableReceivable: false,
                isProviderCreditDebit: true,
                isClientCreditDebit: true,
                isClientDivApplicable: false,
                isProviderDivApplicable: true,
                isFeeDivApplicable: true,
            });
        } else if (
            itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
            itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit
        ) {
            this.setState({
                isPayableReceivable: false,
                isProviderCreditDebit: false,
                isClientCreditDebit: false,
                isClientDivApplicable: false,
                isProviderDivApplicable: false,
                isFeeDivApplicable: false,
            });
        }
    }
    handleChangeProviderTaxApplicableSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Tax_Applicable: dxValueChange.value,
            },
        });

        if (this.state.editViewDataItem.provider_Tax_Applicable == "Yes") {
            if (this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        provider_Pay_Tax_Local_Ccy:
                            this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy * TaxPercentage.Percentage,
                    },
                });

                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        provider_Pay_Total_Local_Ccy:
                            this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy +
                            this.state.editViewDataItem.provider_Pay_Tax_Local_Ccy,
                    },
                });

                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        provider_Pay_Amount_Base_Ccy: this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy,
                        provider_Pay_Tax_Base_Ccy: this.state.editViewDataItem.provider_Pay_Tax_Local_Ccy,
                        provider_Pay_Total_Base_Ccy: this.state.editViewDataItem.provider_Pay_Total_Local_Ccy,
                    },
                });
            }

            this.setState({ isProviderTaxFieldVisible: true });
        } else {
            this.setState({ isProviderTaxFieldVisible: false });
        }

        if (this.state.editViewDataItem.provider_Tax_Applicable == "No") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    provider_Pay_Tax_Local_Ccy: 0.0,
                    provider_Pay_Tax_Base_Ccy: 0.0,
                    provider_Pay_Total_Local_Ccy: this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy,
                    provider_Pay_Total_Base_Ccy: this.state.editViewDataItem.provider_Pay_Amount_Base_Ccy,
                },
            });
        }
    }

    handleChangeFeeTaxApplicableSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Tax_Applicable: dxValueChange.value,
            },
        });

        if (this.state.editViewDataItem.fee_Tax_Applicable == "Yes") {
            if (this.state.editViewDataItem.fee_Amount_Local_Ccy) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        fee_Tax_Local_Ccy: this.state.editViewDataItem.fee_Amount_Local_Ccy * TaxPercentage.Percentage,
                    },
                });

                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        fee_Total_Local_Ccy:
                            this.state.editViewDataItem.fee_Amount_Local_Ccy +
                            this.state.editViewDataItem.fee_Tax_Local_Ccy,
                    },
                });

                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        fee_Amount_Base_Ccy: this.state.editViewDataItem.fee_Amount_Local_Ccy,
                        fee_Tax_Base_Ccy: this.state.editViewDataItem.fee_Tax_Local_Ccy,
                        fee_Total_Base_Ccy: this.state.editViewDataItem.fee_Total_Local_Ccy,
                    },
                });
            }

            this.setState({ isFeeTaxFieldVisible: true });
        } else {
            this.setState({ isFeeTaxFieldVisible: false });
        }

        if (this.state.editViewDataItem.fee_Tax_Applicable == "No") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Tax_Local_Ccy: 0.0,
                    fee_Tax_Base_Ccy: 0.0,
                    fee_Total_Local_Ccy: this.state.editViewDataItem.fee_Amount_Local_Ccy,
                    fee_Total_Base_Ccy: this.state.editViewDataItem.fee_Amount_Base_Ccy,
                },
            });
        }
    }

    handleChangeClientTaxApplicableSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Tax_Applicable: dxValueChange.value,
            },
        });

        if (this.state.editViewDataItem.client_Tax_Applicable == "Yes") {
            if (this.state.editViewDataItem.client_Billable_Amount_Local_Ccy) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        client_Billable_Tax_Local_Ccy:
                            this.state.editViewDataItem.client_Billable_Amount_Local_Ccy * TaxPercentage.Percentage,
                    },
                });

                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        client_Billable_Total_Local_Ccy:
                            this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                            this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
                    },
                });

                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        client_Billable_Amount_Base_Ccy: this.state.editViewDataItem.client_Billable_Amount_Local_Ccy,
                        client_Billable_Tax_Base_Ccy: this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
                        client_Billable_Total_Base_Ccy: this.state.editViewDataItem.client_Billable_Total_Local_Ccy,
                    },
                });
            }

            this.setState({ isBillClientTaxFieldVisible: true });
        } else {
            this.setState({ isBillClientTaxFieldVisible: false });
        }

        if (this.state.editViewDataItem.client_Tax_Applicable == "No") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy: 0.0,
                    client_Billable_Tax_Base_Ccy: 0.0,
                    client_Billable_Total_Local_Ccy: this.state.editViewDataItem.client_Billable_Amount_Local_Ccy,
                    client_Billable_Total_Base_Ccy: this.state.editViewDataItem.client_Billable_Amount_Base_Ccy,
                },
            });
        }
    }

    handleChangeProviderCurrencyTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Currency_Local_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeFeeCurrencyTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Currency_Local_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeBillCurrencyTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Currency_Local_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeAcceptDate(dxValueChange: DateBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                accept_Date: dxValueChange.value,
            },
        });
        this.setState({
            isAcceptDateChanged: true,
        });
    }

    handleChangePercentage(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Percentage_Local_Ccy: dxValueChange.value,
            },
        });
        this.financeRecalculationOnPercentageChange();
    }

    handleChangeApprovalDate(dxValueChange: DateBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                approval_Date: dxValueChange.value,
            },
        });

        this.setState({
            isApprovalDateChanged: true,
        });
    }

    handleChangeProviderLocalCurrancyAmount(dxValueChange: NumberBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Amount_Local_Ccy: dxValueChange.value,
            },
        });
        if (
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit
        ) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Amount_Local_Ccy:
                        this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy +
                        this.state.editViewDataItem.fee_Amount_Local_Ccy,
                },
            });
            if (this.state.editViewDataItem.client_Tax_Applicable == "Yes") {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        client_Billable_Tax_Local_Ccy:
                            this.state.editViewDataItem.client_Billable_Amount_Local_Ccy * TaxPercentage.Percentage,
                        client_Billable_Tax_Base_Ccy:
                            this.state.editViewDataItem.client_Billable_Amount_Local_Ccy * TaxPercentage.Percentage,
                    },
                });
            } else if (this.state.editViewDataItem.client_Tax_Applicable == "No") {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        client_Billable_Tax_Local_Ccy: 0.0,
                        client_Billable_Tax_Base_Ccy: 0.0,
                    },
                });
            }

            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Total_Local_Ccy:
                        this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                        this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
                },
            });

            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Amount_Base_Ccy: this.state.editViewDataItem.client_Billable_Amount_Local_Ccy,
                    client_Billable_Total_Base_Ccy: this.state.editViewDataItem.client_Billable_Total_Local_Ccy,
                },
            });
        }
        if (this.state.editViewDataItem.provider_Tax_Applicable == "Yes") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    provider_Pay_Tax_Local_Ccy:
                        this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy * TaxPercentage.Percentage,
                },
            });
        } else if (this.state.editViewDataItem.provider_Tax_Applicable == "No") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    provider_Pay_Tax_Local_Ccy: 0.0,
                },
            });
        }

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Total_Local_Ccy:
                    this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                    this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
                client_Billable_Total_Base_Ccy:
                    this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                    this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Total_Local_Ccy:
                    this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy +
                    this.state.editViewDataItem.provider_Pay_Tax_Local_Ccy,
            },
        });
    }

    handleChangeProviderBaseCurrancyAmount(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Amount_Base_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeFeeLocalCurrancyAmount(dxValueChange: NumberBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Amount_Local_Ccy: dxValueChange.value,
            },
        });
        if (
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit
        ) {
            if (this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        client_Billable_Amount_Local_Ccy:
                            this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy +
                            this.state.editViewDataItem.fee_Amount_Local_Ccy,
                    },
                });
            } else {
                this.setState({
                    editViewDataItem: {
                        ...this.state.editViewDataItem,
                        client_Billable_Amount_Local_Ccy: this.state.editViewDataItem.fee_Amount_Local_Ccy,
                    },
                });
            }
        }

        if (this.state.editViewDataItem.client_Tax_Applicable == "Yes" || this.state.editViewDataItem.client_Tax_Applicable == "True") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy:
                        this.state.editViewDataItem.client_Billable_Amount_Local_Ccy * TaxPercentage.Percentage
                },
            });
        } else if (this.state.editViewDataItem.client_Tax_Applicable == "No" || this.state.editViewDataItem.client_Tax_Applicable == "False") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy: 0.0
                },
            });
        }

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Total_Local_Ccy:
                    this.state.editViewDataItem.client_Tax_Applicable
                        ? this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                        this.state.editViewDataItem.client_Billable_Tax_Local_Ccy
                        : this.state.editViewDataItem.client_Billable_Amount_Local_Ccy,
            },
        });

        if (this.state.editViewDataItem.fee_Tax_Applicable == "Yes" || this.state.editViewDataItem.fee_Tax_Applicable == "True") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Tax_Local_Ccy: this.state.editViewDataItem.fee_Amount_Local_Ccy * TaxPercentage.Percentage,
                },
            });
        } else if (this.state.editViewDataItem.fee_Tax_Applicable == "No" || this.state.editViewDataItem.fee_Tax_Applicable == "False") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fee_Tax_Local_Ccy: 0.0,
                },
            });
        }

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Total_Local_Ccy:
                    this.state.editViewDataItem.fee_Amount_Local_Ccy + this.state.editViewDataItem.fee_Tax_Local_Ccy,
            },
        });
    }

    handleChangeFeeBaseCurrancyAmount(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Amount_Base_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeBillLocalCurrancyAmount(dxValueChange: NumberBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Amount_Local_Ccy: dxValueChange.value,
            },
        });

        if (this.state.editViewDataItem.client_Tax_Applicable == "Yes" || this.state.editViewDataItem.client_Tax_Applicable == "True") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy:
                        this.state.editViewDataItem.client_Billable_Amount_Local_Ccy * TaxPercentage.Percentage,
                    client_Billable_Tax_Base_Ccy:
                        this.state.editViewDataItem.client_Billable_Amount_Local_Ccy * TaxPercentage.Percentage,
                },
            });
        } else if (this.state.editViewDataItem.client_Tax_Applicable == "No" || this.state.editViewDataItem.client_Tax_Applicable == "False") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    client_Billable_Tax_Local_Ccy: 0.0,
                    client_Billable_Tax_Base_Ccy: 0.0,
                },
            });
        }

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Total_Local_Ccy:
                    this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                    this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Amount_Base_Ccy: this.state.editViewDataItem.client_Billable_Amount_Local_Ccy,
                client_Billable_Total_Base_Ccy: this.state.editViewDataItem.client_Billable_Total_Local_Ccy,
            },
        });
    }

    handleChangeBillBaseCurrancyAmount(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Amount_Base_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeProviderLocalCurrancyTax(dxValueChange: NumberBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Tax_Local_Ccy: dxValueChange.value,
            },
        });

        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Total_Local_Ccy:
                    this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy +
                    this.state.editViewDataItem.provider_Pay_Tax_Local_Ccy,
            },
        });
    }

    handleChangeProviderBaseCurrancyTax(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Tax_Base_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeFeeLocalCurrancyTax(dxValueChange: NumberBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Tax_Local_Ccy: dxValueChange.value,
            },
        });
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Total_Local_Ccy:
                    this.state.editViewDataItem.fee_Amount_Local_Ccy + this.state.editViewDataItem.fee_Tax_Local_Ccy,
            },
        });
    }

    handleChangeFeeBaseCurrancyTax(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                fee_Tax_Base_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeBillLocalCurrancyTax(dxValueChange: NumberBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Tax_Local_Ccy: dxValueChange.value,
            },
        });
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Total_Local_Ccy:
                    this.state.editViewDataItem.client_Billable_Amount_Local_Ccy +
                    this.state.editViewDataItem.client_Billable_Tax_Local_Ccy,
            },
        });
    }

    handleChangeBillBaseCurrancyTax(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Tax_Base_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeProviderOriginalAmount(dxValueChange: NumberBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                provider_Pay_Original_Amount_Local_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangeFeeNonStandardTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                non_Standard: dxValueChange.value,
            },
        });
    }
    handleChangeHardApproval = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    hardApproval: dxValueChange.value,
                },
            });
        }
    }
    handleChangeHoldPayment = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    holdPayment: dxValueChange.value,
                },
            });
        }
    }

    handleChangeBillOriginalAmount(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                client_Billable_Original_Amount_Local_Ccy: dxValueChange.value,
            },
        });
    }

    handleChangePayDate(dxValueChange: DateBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                pay_Date: dxValueChange.value,
            },
        });
    }

    handleChangeBillDate(dxValueChange: DateBoxOnValueChangedEvent) {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bill_Date: dxValueChange.value,
            },
        });
    }

    handleChangeName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    name: dxValueChange.value,
                },
            });
        }
    };

    //#endregion
    //#region DataSubmissionToServer
    // Submit the form data to the server.
    successMessageVisible = () => {
        this.setState({
            IsShowSuccess: true,
        });
    };

    failureMessageVisible = () => {
        this.setState({
            IsShowError: true,
        });
    };

    hideLoadpanel() {
        this.setState({ loadPanelVisible: false });
    }

    renderRedirect = () => {
        this.addEditUtils.renderRedirectCommonMethod(this.props, this.state.IsShowSuccess, "apply", "");
    };

    handleFormDataSubmissionSuccess() {
        this.hideLoadpanel();
        this.successMessageVisible();
        this.setState({
            IsShowError: false,
        });
        this.renderRedirect();
    }

    validateForProviderCreditDebit = () => {
        let errorsAdjustment: string[] = [];

        if (this.state.editViewDataItem.pay_Date == null || this.state.editViewDataItem.pay_Date == undefined) {
            errorsAdjustment.push(this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.payDate"));
        }
        if (this.state.editViewDataItem.itemTypeId == itemTypes.ProviderCredit) {
            if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == -1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.providerAmountPositive")
                );
            }
        }
        if (this.state.editViewDataItem.itemTypeId == itemTypes.ProviderDebit) {
            if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == 1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.providerAmountNegetive")
                );
            }
        }
        // var internalCommentsValidation = this.validateInternalComment();
        // if (internalCommentsValidation) {
        //     errorsAdjustment.push(this.validateInternalComment());
        // }
        return errorsAdjustment;
    };

    validateReceivablePayable = () => {
        let errorsAdjustment: string[] = [];

        if (this.state.editViewDataItem.non_Standard == "") {
            errorsAdjustment.push(this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.nonStandard"));
        }
        if (this.state.editViewDataItem.itemTypeId == itemTypes.Receivable) {
            if (Math.sign(this.state.editViewDataItem.fee_Amount_Local_Ccy) == -1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.feeAmountPositive")
                );
            }
        } else if (this.state.editViewDataItem.itemTypeId == itemTypes.Payable) {
            if (Math.sign(this.state.editViewDataItem.fee_Amount_Local_Ccy) == 1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.feeAmountNegetive")
                );
            }
        }
        // var internalCommentsValidation = this.validateInternalComment();
        // if (internalCommentsValidation) {
        //     errorsAdjustment.push(this.validateInternalComment());
        // }
        return errorsAdjustment;
    };

    validateClientCreditDebit = () => {
        let errorsAdjustment: string[] = [];

        if (this.state.editViewDataItem.itemTypeId == itemTypes.ClientCredit) {
            if (Math.sign(this.state.editViewDataItem.client_Billable_Amount_Local_Ccy) == 1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.clientAmountPositive")
                );
            }
        } else if (this.state.editViewDataItem.itemTypeId == itemTypes.ClientDebit) {
            if (Math.sign(this.state.editViewDataItem.client_Billable_Amount_Local_Ccy) == -1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.clientAmountNegetive")
                );
            }
        }
        // var internalCommentsValidation = this.validateInternalComment();
        // if (internalCommentsValidation) {
        //     errorsAdjustment.push(this.validateInternalComment());
        // }
        return errorsAdjustment;
    };

    validateAdjustmentItemType = () => {
        let errorsAdjustment: string[] = [];
        if (this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit) {
            if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == -1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.providerAmountPositive")
                );
            }
            if (Math.sign(this.state.editViewDataItem.fee_Amount_Local_Ccy) == -1) {
                errorsAdjustment.push("Fee amount MUST BE ZERO OR POSITIVE values only");
            }
            if (Math.sign(this.state.editViewDataItem.client_Billable_Amount_Local_Ccy) == -1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.clientAmountNegetive")
                );
            }
        } else if (this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit) {
            if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == 1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.providerAmountNegetive")
                );
            }
            if (Math.sign(this.state.editViewDataItem.fee_Amount_Local_Ccy) == 1) {
                errorsAdjustment.push("Fee amount MUST BE ZERO OR NEGATIVE values only");
            }
            if (Math.sign(this.state.editViewDataItem.client_Billable_Amount_Local_Ccy) == 1) {
                errorsAdjustment.push(
                    this.props.t("manageShift.financeAdjustmentPopUp.errorMessage.clientAmountPositive")
                );
            }
        }
        // var internalCommentsValidation = this.validateInternalComment();
        // if (internalCommentsValidation) {
        //     errorsAdjustment.push(this.validateInternalComment());
        // }
        return errorsAdjustment;
    };

    // validateInternalComment = () => {
    //     let errorsAdjustment: string = "";
    //     var internalCommentValidation = this.internalCommentsFieldValidation();
    //     if (internalCommentValidation) {
    //         errorsAdjustment = internalCommentValidation;
    //     }
    //     return errorsAdjustment;
    // };

    isProviderMandatory = () => {
        let mandate = false;
        if (this.state.editViewDataItem.itemTypeId == itemTypes.ProviderCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.ProviderDebit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.Receivable ||
            this.state.editViewDataItem.itemTypeId == itemTypes.Payable || 
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit) {
            mandate = true;
        }
        return mandate;
    }

    isClientMandatory = () => {
        let mandate = false;
        if (this.state.editViewDataItem.itemTypeId == itemTypes.ClientCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.ClientDebit || 
            this.state.editViewDataItem.itemTypeId == itemTypes.Receivable ||
            this.state.editViewDataItem.itemTypeId == itemTypes.Payable || 
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit ) {
            mandate = true;
        }
        return mandate;
    }

    isNonStandardMandatory = () => {
        let mandate = false;
        if (this.state.editViewDataItem.itemTypeId == itemTypes.Receivable ||
            this.state.editViewDataItem.itemTypeId == itemTypes.Payable) {
            mandate = true;
        }
        return mandate;
    }

    isPayDateMandatory = () => {
        let mandate = false;
        if (
            this.state.editViewDataItem.itemTypeId == itemTypes.ProviderCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.ProviderDebit
        ) {
            mandate = true;
        }
        return mandate;
    }
    validate = () => {
        // we are going to store errors for all fields
        // in a signle array
        let errorsAdjustment: string[] = [];
        if (
            this.state.editViewDataItem.itemTypeId == itemTypes.ProviderCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.ProviderDebit
        ) {
            errorsAdjustment = this.validateForProviderCreditDebit();
        } else if (
            this.state.editViewDataItem.itemTypeId == itemTypes.Receivable ||
            this.state.editViewDataItem.itemTypeId == itemTypes.Payable
        ) {
            errorsAdjustment = this.validateReceivablePayable();
        } else if (
            this.state.editViewDataItem.itemTypeId == itemTypes.ClientCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.ClientDebit
        ) {
            errorsAdjustment = this.validateClientCreditDebit();
        } else if (
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
            this.state.editViewDataItem.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit
        ) {
            errorsAdjustment = this.validateAdjustmentItemType();
        }
        return errorsAdjustment;
    };

    onSubmit() {
        let errorsAdjustment: string[] = [];
        let acceptDate: NullableDate;
        let approvalDate: NullableDate;
        let statusId = "";
        let amend: boolean = false;
        var serviceFeeMatrixVal = this.billableItemUtils.serviceFeeMatrixDerivation(
            this.state.editViewDataItem.clientId,
            this.state.editViewDataItem.serviceTypeId,
            this.state.editViewDataItem.serviceSubTypeId
        )
        this.setState({
            loadPanelVisible: true,
        });
        //errorsAdjustment = this.validate();
        if (errorsAdjustment.length > 0) {
            this.setState({ errorsAdjustment });
            this.hideLoadpanel();
        }
        if (this.props.location.state && this.props.location.state.id == "0") {
            if (
                this.state.editViewDataItem.accept_Date == undefined ||
                this.state.editViewDataItem.accept_Date == null
            ) {
                acceptDate = new Date();
            }
            if (
                this.state.editViewDataItem.approval_Date == undefined ||
                this.state.editViewDataItem.approval_Date == null
            ) {
                approvalDate = new Date();
            }
            statusId = this.state.statusLookUp[1].id;
        }

        if (this.props.location.state && this.props.location.state.isEdit) {
            statusId = this.billableItemUtils.billableItemStatusSetBasedOnItemType
                (this.state.editViewDataItem.itemTypeId, serviceFeeMatrixVal.valueOfProviderAcceptance);
        }
        var billableItemSubmitRequest = this.addEditUtils.convertFormDataToBillableItemRequest(
            this.state.editViewDataItem,
            statusId,
            "",
            this.props.location.state?.isAmend,
            "",
            "",
            "",
            acceptDate,
            approvalDate,
            false,
            "financial Adjustment"
        );

        var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
        billableItemSubmitRequestArr.push(billableItemSubmitRequest);
        //passing object data to the server
        if (errorsAdjustment.length == 0) {
            this.setState({ errorsAdjustment });
            this.editViewService
                .uploadEditAddDataV2(billableItemSubmitRequestArr)
                .then(this.handleFormDataSubmissionSuccess)
                .catch((err) => {
                    this.setState({
                        errorMessage:
                            err.response && err.response.data && err.response.data.error
                                ? JSON.parse(JSON.stringify(err.response.data.error))
                                : null,
                        IsShowSuccess: false,
                    });
                    this.failureMessageVisible();
                    this.hideLoadpanel();
                })
                .finally(() => {
                    this.hideLoadpanel();
                });
        } else {
            this.hideLoadpanel();
        }
    }
    onSubmitApply = () => {
        if(!this.state.editViewDataItem.venueId){
            this.setState({showWarningPopup:true})
        } else {
            this.onSubmit();
        }
    }

    onCancel = () => {
        this.addEditUtils.renderRedirectCommonMethod(this.props, this.state.IsShowSuccess, "cancel", "");
    };

    // internalCommentsFieldValidation = () => {
    //     let errorMessage: string = "";
    //     let commentsField: string = this.state.editViewDataItem.internalComments.trim();
    //     if (commentsField.length === 0) {
    //         errorMessage = "Please provide the Internal comments before submitting the Form.";
    //     }
    //     return errorMessage;
    // };

    validationCheckFunction = () => {
        let errorMessage: string = "";
        let commentsField: string = this.state.editViewDataItem.comments.trim();
        if (commentsField.length === 0) {
            errorMessage = "Please Enter the Query Description in the comments.";
        }
        return errorMessage;
    };

    onSubmitQuery = () => {
        let querySubmissionError: string = "";
        querySubmissionError = this.validationCheckFunction();
        if (querySubmissionError.length > 0) {
            this.setState({
                validationMessage: querySubmissionError,
            });
        } else {
            this.setState({
                validationMessage: "",
                loadPanelVisible: true,
            });
            this.editViewService
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    handleQuerySubmissionSuccess = (response: AxiosResponse<any>) => {
        this.hideLoadpanel();
        this.navigateBack();
    };

    navigateBack = () => {
        this.props.history.goBack(); // Return back to the page on a form submission success.
    };

    handleQuerySubmissionFailure = (error: any) => {
        this.hideLoadpanel();
    };

    onContentReadyNumberBox = (e: any) => {
        this.onClickElementSelection(e);
    }

    onClickElementSelection = (e: any) => {
        e.element.addEventListener('click', function () {
            let inputElement = e.element.querySelector(".dx-texteditor-input");
            inputElement.selectionStart = 0;
            inputElement.selectionEnd = inputElement.value.length;
        });
    }

    handleChangeOriginator = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    originator: {
                        id: dxValueChange.value,
                        value: ""
                    }
                },
            });
        }
    }


    renderFinancialShiftPanel = () => {
        var readOnlyView: boolean = false;
        if (this.props.location.state && this.props.location.state.isReadOnly) {
            readOnlyView = this.state.readOnlyView;
        }
        let calendarMin;
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            calendarMin = new Date();
        }
        let isReadOnlyView: boolean = true;
        if (readOnlyView) {
            isReadOnlyView = readOnlyView;
        } else if (!this.state.isProviderCreditDebit) {
            isReadOnlyView = this.state.isProviderCreditDebit;
        } else if (!this.state.isClientCreditDebit) {
            isReadOnlyView = this.state.isClientCreditDebit;
        } else if (!this.state.isPayableReceivable) {
            isReadOnlyView = this.state.isPayableReceivable;
        }

        var isStatusDisabled: boolean = false;
        if (this.props.location?.state?.isEdit) {
            isStatusDisabled = true;
        }
        return (
            <section className="card card-form my-5">
                <div className="card-body">
                    <h3 className="card-form__section-heading">Shift</h3>
                    <hr className="horizontal_border_style"></hr>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Service
                            <sup className="card-form__mandatory-symbol">*</sup></label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DropDownFromLookupTableDynamic
                                    lookupTypeIndex={LookupTypeIndexes.serviceType}
                                    isRoot={true}
                                    parentMappingId={"0"}
                                    onValueChanged={this.handleChangeServiceTypeSelect}
                                    isDisabled={isReadOnlyView}
                                    value={this.state.editViewDataItem.serviceTypeId}
                                />
                                <input
                                    data-testid={LookupTypeIndexes.serviceType}
                                    type="hidden"
                                    name={LookupTypeIndexes.serviceType}
                                    value={
                                        this.state.editViewDataItem.serviceTypeId
                                            ? this.state.editViewDataItem.serviceTypeId
                                            : ""
                                    }
                                ></input>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Status
                            <sup className="card-form__mandatory-symbol">*</sup></label>
                            <div className="col-12 col-xl-9 pl-0">
                                <SelectBox
                                    disabled={isStatusDisabled}
                                    dataSource={this.state.statusLookUp}
                                    displayExpr="value"
                                    valueExpr="id"
                                    onValueChanged={this.handleChangeStatusTypeSelect}
                                    value={
                                        this.state.statusLookUp.length > 0
                                            ? this.props.location.state && this.props.location.state.id == "0"
                                                ? this.state.statusLookUp[0].id
                                                : this.state.editViewDataItem.billableStatusLookupId
                                            : ""
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Client
                            {this.isClientMandatory() && <sup className="card-form__mandatory-symbol">*</sup>}</label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                    onValueChanged={this.handleChangeClientTypeSelect}
                                    signal={
                                        this.state.editViewDataItem.itemTypeId ? false : true
                                    }
                                    disableSignal={readOnlyView}
                                    value={this.state.editViewDataItem.clientId}
                                    recordType={
                                        this.props.location.state && this.props.location.state.isEdit ? "all" : "active"
                                    }
                                />
                                <input
                                    data-testid={LookupTypeIndexes.clientType}
                                    type="hidden"
                                    name={LookupTypeIndexes.clientType}
                                    value={
                                        this.state.editViewDataItem.clientId ? this.state.editViewDataItem.clientId : 0
                                    }
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Sub-Type
                            {/* <sup className="card-form__mandatory-symbol">*</sup> */}
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DropDownFromLookupTableDynamic
                                    lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                    isRoot={false}
                                    parentMappingId={this.state.editViewDataItem.serviceTypeId}
                                    onValueChanged={this.handleChangeServiceSubTypeSelect}
                                    isDisabled={isReadOnlyView}
                                    value={this.state.editViewDataItem.serviceSubTypeId}
                                />
                                <input
                                    data-testid={LookupTypeIndexes.subServiceType}
                                    type="hidden"
                                    name={LookupTypeIndexes.subServiceType}
                                    value={
                                        this.state.editViewDataItem.serviceSubTypeId
                                            ? this.state.editViewDataItem.serviceSubTypeId
                                            : ""
                                    }
                                ></input>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Date From<sup className="card-form__mandatory-symbol">*</sup>
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DateBox
                                    disabled={isReadOnlyView}
                                    onValueChanged={this.handleChangeDate}
                                    value={
                                        this.state.editViewDataItem.date ? this.state.editViewDataItem.date : undefined
                                    }
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                    min={calendarMin}
                                ></DateBox>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Venue</label>
                            <div className="col-12 col-xl-9 pl-0">
                                <LookupTypeListDynamic
                                    isDisabled={readOnlyView || !this.state.editViewDataItem.clientId}
                                    lookupTypeIndex={LookupTypeIndexes.venueType}
                                    onValueChanged={this.handleChangeVenueTypeSelect}
                                    isRoot={false}
                                    parentMappingId={this.state.editViewDataItem.clientId}
                                    value={this.state.editViewDataItem.venueId}
                                    displayExpression={"value"}
                                    recordType={
                                        this.props.location.state && this.props.location.state.isEdit ? "all" : "active"
                                    }
                                    //parentMappingRequired={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Provider
                            {this.isProviderMandatory() && <sup className="card-form__mandatory-symbol">*</sup>}</label>
                            <div className="col-12 col-xl-9 pl-0">
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.providerType}
                                    onValueChanged={this.handleChangeProviderTypeSelect}
                                    isRoot={false}
                                    parentMappingId={""}
                                     isDisabled={this.state.editViewDataItem.itemTypeId ? false : true}
                                    value={this.state.editViewDataItem.providerId}
                                    displayExpression={"value"}
                                    recordType={
                                        this.props.location.state && this.props.location.state.isEdit ? "all" : "active"
                                    }
                                />
                                <input
                                    data-testid={LookupTypeIndexes.providerType}
                                    type="hidden"
                                    name={LookupTypeIndexes.providerType}
                                    value={
                                        this.state.editViewDataItem.providerId
                                            ? this.state.editViewDataItem.providerId
                                            : 0
                                    }
                                ></input>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Date To<sup className="card-form__mandatory-symbol">*</sup></label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DateBox
                                    disabled={isReadOnlyView}
                                    onValueChanged={this.handleChangeDateTo}
                                    value={
                                        this.state.editViewDataItem.dateTo
                                            ? this.state.editViewDataItem.dateTo
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                ></DateBox>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 pr-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Name</label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    disabled={isReadOnlyView}
                                    onValueChanged={this.handleChangeName}
                                    value={this.state.editViewDataItem.name}
                                ></TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 pr-0">
                        <div className="col-12 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-1 text-left text-xl-right">
                                External Comments (Public)<sup className="card-form__mandatory-symbol">*</sup>
                            </label>
                            <div className="col-12 col-xl-11 pl-0">
                                <TextArea
                                    height={100}
                                    value={this.state.editViewDataItem.comments}
                                    onValueChanged={this.handleChangeComments}
                                    disabled={isReadOnlyView}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 pr-0">
                        <div className="col-12 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-1 text-left text-xl-right">
                                Internal Comments (Private)
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </label>
                            <div className="col-12 col-xl-11 pl-0">
                                <TextArea
                                    height={100}
                                    value={this.state.editViewDataItem.internalComments}
                                    onValueChanged={this.handleChangeInternalComments}
                                    disabled={isReadOnlyView}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
    renderFinancialAdjustmentFinancePanel = () => {
        var readOnlyView: boolean = false;
        if (this.props.location.state && this.state.readOnlyView) {
            readOnlyView = this.state.readOnlyView;
        }
        var disableViewPaymentLink: boolean = false;
        var disableViewBillingLink: boolean = false;
        if (!this.state.editViewDataItem.paymentId) {
            disableViewPaymentLink = true;
        }
        if (!this.state.editViewDataItem.invoiceId) {
            disableViewBillingLink = true;
        }

        return (
            <section className="card card-form my-5">
                <div className="card-body">
                    <h3 className="card-form__section-heading">Financials</h3>
                    <hr className="horizontal_border_style"></hr>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Provider VAT
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <SelectBox
                                    dataSource={this.decisionTypesDataSource}
                                    displayExpr="value"
                                    valueExpr="value"
                                    onValueChanged={this.handleChangeProviderTaxApplicableSelect}
                                    value={
                                        this.state.editViewDataItem.provider_Tax_Applicable == "Yes" ||
                                            this.state.editViewDataItem.provider_Tax_Applicable == "True"
                                            ? "Yes"
                                            : "No"
                                    }
                                    disabled={this.state.isProviderCreditDebit || readOnlyView}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Event VAT
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <SelectBox
                                    dataSource={this.decisionTypesDataSource}
                                    displayExpr="value"
                                    valueExpr="value"
                                    onValueChanged={this.handleChangeFeeTaxApplicableSelect}
                                    value={
                                        this.state.editViewDataItem.fee_Tax_Applicable == "Yes" ||
                                            this.state.editViewDataItem.fee_Tax_Applicable == "True"
                                            ? "Yes"
                                            : "No"
                                    }
                                    disabled={this.state.isPayableReceivable || readOnlyView}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Client VAT
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <SelectBox
                                    dataSource={this.decisionTypesDataSource}
                                    displayExpr="value"
                                    valueExpr="value"
                                    onValueChanged={this.handleChangeClientTaxApplicableSelect}
                                    value={
                                        this.state.editViewDataItem.client_Tax_Applicable == "Yes" ||
                                            this.state.editViewDataItem.client_Tax_Applicable == "True"
                                            ? "Yes"
                                            : "No"
                                    }
                                    disabled={this.state.isClientCreditDebit || readOnlyView}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Provider NET
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeProviderLocalCurrancyAmount}
                                    valueChangeEvent="keyup"
                                    value={this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy}
                                    disabled={this.state.isProviderCreditDebit || readOnlyView}
                                    step={0}
                                    onContentReady={this.onContentReadyNumberBox}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Event NET
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeFeeLocalCurrancyAmount}
                                    valueChangeEvent="keyup"
                                    value={this.state.editViewDataItem.fee_Amount_Local_Ccy}
                                    disabled={this.state.isPayableReceivable || readOnlyView}
                                    onContentReady={this.onContentReadyNumberBox}
                                    step={0}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Client NET
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeBillLocalCurrancyAmount}
                                    valueChangeEvent="keyup"
                                    value={this.state.editViewDataItem.client_Billable_Amount_Local_Ccy}
                                    disabled={this.state.isClientCreditDebit || readOnlyView}
                                    onContentReady={this.onContentReadyNumberBox}
                                    step={0}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Provider VAT
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeProviderLocalCurrancyTax}
                                    value={this.state.editViewDataItem.provider_Pay_Tax_Local_Ccy}
                                    disabled={this.state.isProviderCreditDebit || readOnlyView}
                                    valueChangeEvent="keyup"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Event VAT
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeFeeLocalCurrancyTax}
                                    value={this.state.editViewDataItem.fee_Tax_Local_Ccy}
                                    disabled={!this.state.isFeeTaxFieldVisible || readOnlyView}
                                    valueChangeEvent="Keyup"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Client VAT
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeBillLocalCurrancyTax}
                                    value={this.state.editViewDataItem.client_Billable_Tax_Local_Ccy}
                                    disabled={!this.state.isBillClientTaxFieldVisible || readOnlyView}
                                    valueChangeEvent="keyup"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Provider GROSS
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    disabled={true}
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    value={this.state.editViewDataItem.provider_Pay_Total_Local_Ccy}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Event GROSS
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    disabled={true}
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    value={this.state.editViewDataItem.fee_Total_Local_Ccy}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Client GROSS
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    disabled={true}
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    value={this.state.editViewDataItem.client_Billable_Total_Local_Ccy}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Provider Previous Net
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    value={this.state.editViewDataItem.provider_Pay_Original_Amount_Local_Ccy}
                                    disabled={this.state.isProviderCreditDebit || readOnlyView}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3"></div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Client Previous Net
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeProviderOriginalAmount}
                                    value={this.state.editViewDataItem.client_Billable_Original_Amount_Local_Ccy}
                                    disabled={this.state.isClientCreditDebit || readOnlyView}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Acceptance Date
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DateBox
                                    onValueChanged={this.handleChangeAcceptDate}
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                    disabled={readOnlyView}
                                    value={
                                        this.state.editViewDataItem.accept_Date
                                            ? this.state.editViewDataItem.accept_Date
                                            : undefined
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3"></div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Approval Date
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DateBox
                                    onValueChanged={this.handleChangeApprovalDate}
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                    value={
                                        this.state.editViewDataItem.approval_Date
                                            ? this.state.editViewDataItem.approval_Date
                                            : undefined
                                    }
                                    disabled={this.state.isClientCreditDebit || readOnlyView}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Accepted By
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    disabled={
                                        true
                                    }
                                    value={
                                        this.state.statusLookUp[1] && this.state.editViewDataItem.accept_Date && this.state.editViewDataItem.accepted_By ? this.state.editViewDataItem.accepted_By : ""
                                    }
                                ></TextBox>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Originator
                                <sup className="card-form__mandatory-symbol">*</sup>
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.originator}
                                    onValueChanged={this.handleChangeOriginator}
                                    disableSignal={
                                        this.state.readOnlyView 
                                    }
                                    value={this.state.editViewDataItem.originator && this.state.editViewDataItem.originator.id ? this.state.editViewDataItem.originator.id.toString() : ""}
                                    recordType="active"
                                />
                                <input
                                    data-testid={LookupTypeIndexes.originator}
                                    type="hidden"
                                    name={LookupTypeIndexes.originator}
                                    value={this.state.editViewDataItem.originator &&
                                        this.state.editViewDataItem.originator.id
                                        ? this.state.editViewDataItem.originator.id
                                        : 0
                                    }
                                ></input>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3"></div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Approved By
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    disabled={
                                        true
                                    }
                                    value={
                                        this.state.statusLookUp[13] && this.state.editViewDataItem.approval_Date && this.state.editViewDataItem.approved_By ? this.state.editViewDataItem.approved_By : ""
                                    }
                                ></TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">Pay Date
                            {this.isPayDateMandatory() && <sup className="card-form__mandatory-symbol">*</sup>}</label>
                            <div className="col-12 col-xl-5 pl-0">
                                <DateBox
                                    onValueChanged={this.handleChangePayDate}
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                    value={
                                        this.state.editViewDataItem.pay_Date
                                            ? this.state.editViewDataItem.pay_Date
                                            : undefined
                                    }
                                    disabled={this.state.isProviderCreditDebit || readOnlyView}
                                />
                            </div>
                            <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                <Link
                                    to={{
                                        pathname: "/AddEditPaymentForm",
                                        state: {
                                            id: this.state.editViewDataItem.paymentId,
                                        },
                                    }}
                                >
                                    <button className="btn btn-primary btn--small" disabled={disableViewPaymentLink}>
                                        View Payment
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Non Standard  {this.isNonStandardMandatory() && <sup className="card-form__mandatory-symbol">*</sup>}
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <SelectBox
                                    dataSource={this.nonStandardTypeDataSource}
                                    displayExpr="value"
                                    valueExpr="value"
                                    onValueChanged={this.handleChangeFeeNonStandardTypeSelect}
                                    value={
                                        this.state.editViewDataItem.non_Standard == "True"
                                            ? "Non - Standard"
                                            : this.state.editViewDataItem.non_Standard == "False"
                                                ? "Standard"
                                                : this.state.editViewDataItem.non_Standard
                                    }
                                    disabled={this.state.isPayableReceivable || readOnlyView}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Bill Date
                            </label>
                            <div className="col-12 col-xl-5 pl-0">
                                <DateBox
                                    onValueChanged={this.handleChangeBillDate}
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                    value={
                                        this.state.editViewDataItem.bill_Date
                                            ? this.state.editViewDataItem.bill_Date
                                            : undefined
                                    }
                                    disabled={true}
                                />
                            </div>
                            <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                <Link
                                    to={{
                                        pathname: "/BillingPageV2",
                                        state: {
                                            id: this.state.editViewDataItem.invoiceId
                                                ? this.state.editViewDataItem.invoiceId.toString()
                                                : "",
                                        },
                                    }}
                                >
                                    <button className="btn btn-primary btn--small" disabled={disableViewBillingLink}>
                                        View Bill
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Hold Payment?
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <SelectBox
                                    dataSource={this.decisionTypesDataSource}
                                    displayExpr="value"
                                    valueExpr="value"
                                    onValueChanged={this.handleChangeHoldPayment}
                                    value={
                                        this.state.editViewDataItem.holdPayment
                                    }
                                    disabled={
                                        this.state.readOnlyView ||
                                        (this.props.location.state && this.props.location.state.isAdjust)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Hard Approval?
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <SelectBox
                                    dataSource={this.decisionTypesDataSource}
                                    displayExpr="value"
                                    valueExpr="value"
                                    onValueChanged={this.handleChangeHardApproval}
                                    value={
                                        this.state.editViewDataItem.hardApproval
                                    }
                                    disabled={
                                        this.state.readOnlyView ||
                                        (this.props.location.state && this.props.location.state.isAdjust)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Bill Period
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox disabled={true} value={this.state.editViewDataItem.billPeriod}></TextBox>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    getCreatedAndUpdatedString = () => {
        let finalString = '';
        if(this.state.editViewDataItem.dateCreated){
          let formattedDate = moment.utc(this.state.editViewDataItem.dateCreated).format("Do MMM YYYY HH:mm:ss");
          let createdBy = '';
          if(this.state.editViewDataItem.createdBy){
            createdBy = this.state.editViewDataItem.createdBy;
          }
          finalString = `Created On ${formattedDate} ${createdBy?`by ${createdBy}`:''}`;
        }
        if(this.state.editViewDataItem.lastUpdatedOnTZ){
            if(finalString){
                finalString += ' / ';
            }
            let formattedDate = moment(this.state.editViewDataItem.lastUpdatedOnTZ).format("Do MMM YYYY HH:mm:ss");
            let updatedBy = '';
            if(this.state.editViewDataItem.lastUpdatedBy){
                updatedBy = this.state.editViewDataItem.lastUpdatedBy;
            }
            finalString += `Last Updated On ${formattedDate} ${updatedBy?`by ${updatedBy}`:''}`;
          }
          if(finalString){
            finalString =` (${finalString})`;
          }
       return finalString;
    }

    getDateCreatedSection = () => {
        let show = false;
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            show = true;
        }
        if (show) {
            return (
                <div className="col-12 col-lg-4 row mx-0 pr-0 mt-3">
                    <div className="row">
                        <div className="mx-0 mt-3">
                            <label className="card-form__label text-left text-xl-right">
                                Date Created
                            </label>
                        </div>
                        <div className="col-12 col-xl mx-0 mt-3">
                            <DateBox
                                type='date'
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.onHandleDateCreated}
                                value={
                                    this.state.editViewDataItem.dateCreated_UI
                                        ? this.state.editViewDataItem.dateCreated_UI
                                        : undefined
                                }
                                onContentReady={this.onContentReadyNumberBox}
                            />
                        </div>
                        <div className="col-12 col-xl mx-0 mt-3">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="00:00"
                                useMaskBehavior={true}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.handleChangeDateCreatedTime}
                                value={
                                    this.state.editViewDataItem.dateCreatedTime
                                        ? this.state.editViewDataItem.dateCreatedTime
                                        : undefined
                                }
                                onContentReady={this.onContentReadyNumberBox}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return;
    }

    onHandleDateCreated = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    dateCreated_UI: dxValueChange.value,
                },
            });
        }
    }

    handleChangeDateCreatedTime = (dxValueChange: DateBoxOnValueChangedEvent)=>{
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    dateCreatedTime: dxValueChange.value,
                },
            });
        }
    }

    renderFinancialAdjsutmentButtonSection = () => {
        var readOnlyView: boolean = false;
        if (this.props.location.state && this.props.location.state.isReadOnly) {
            readOnlyView = this.state.readOnlyView;
        }
        const { errorsAdjustment } = this.state;
        return (
            <>
                <section>
                    {this.state.IsShowSuccess ? <span className="text-success">Data Saved Successfully!</span> : null}
                    {this.state.IsShowError && this.state.errorMessage != null ? (
                        <span className="unscheduled-shift">
                            <ul>
                                {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                    return (
                                        <li key={uniqueKey}>
                                            {item.columnName}: {item.errorMessage}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    ) : null}
                    {errorsAdjustment.length > 0 ? (
                        <span className="unscheduled-shift">
                            <ul>
                                {errorsAdjustment.map((error) => (
                                    <li key={error}>Error : {error}</li>
                                ))}
                            </ul>
                        </span>
                    ) : null}

                    {this.state.validationMessage != "" ? (
                        <>
                            {" "}
                            <br />{" "}
                            <span className="text-danger font-weight-bold justify-content-center">
                                {this.state.validationMessage}
                            </span>{" "}
                        </>
                    ) : null}
                </section>
                <section className="card-form">
                    {this.state.editViewDataItem.billableItemId !== "" ?
                        <>
                            <div className="row">
                                <div className="col-12 col-lg row mx-0 pr-0 mt-3">
                                    <b> <div className="mx-4 mt-3">ID : {this.state.editViewDataItem.billableItemId} {this.getCreatedAndUpdatedString()}</div> </b>
                                </div>
                                {this.getDateCreatedSection()}
                            </div>

                        </> 
                        : null}
                </section>
                <section className="card-form__button-container">
                    {readOnlyView ? null : (
                        <div className="col-12 col-lg-4 col-xl-2">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                //disabled={this.state.isDisableApplyButton}
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onSubmitApply();
                                }}
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faCheck} />
                                </span>
                                Apply
                            </button>
                        </div>
                    )}

                    <div className="col-12 col-lg-4 col-xl-2">
                        <button
                            className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onCancel();
                            }}
                        >
                            Close
                        </button>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-2">
                        <button
                            className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onSubmitQuery();
                            }}
                        >
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faQuestion} />
                            </span>
                            Raise Query
                        </button>
                    </div>
                </section>
            </>
        );
    };

    hideWarningPopup = () => {
        this.setState({showWarningPopup: false});
    };
    continueWithoutVenue = () =>{
        this.setState({showWarningPopup: false},()=>{
            this.onSubmit();
        });
        
    }
    renderVenueWarningPopup = () => {
    return(
        <Popup
            visible={this.state.showWarningPopup}
            onHiding={this.hideWarningPopup}
            dragEnabled={false}
            //hideOnOutsideClick={true}
            showTitle={true}
            showCloseButton={true}
            title={"WARNING"}
            //resizeEnabled={true}
            width={"25%"}
            height={300}
        >
            <ScrollView width='100%' height='100%'>
                <div>
                  <h3 className="text-center">Venue is not provided</h3>
                    <div className="text-center card-form__heading">Please select <b>OK</b> to proceed or <b>Cancel</b> to amend</div>
                    {/* <div className="text-center pt-3"><b>Provider Net</b> is {this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy == 0 ? <b className="color-red">£0.00</b> : <b>{this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy}</b>}</div>
                            <div className="text-center pt-3"><b>Fee Net</b> is {this.state.quickShiftFormData.fee_Amount_Local_Ccy == 0 ? <b className="color-red">£0.00</b> : <b>{this.state.quickShiftFormData.fee_Amount_Local_Ccy}</b>}</div>
                            <div className="text-center pt-3"><b>Client Net</b> is {this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy == 0 ? <b className="color-red">£0.00</b> : <b>{this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy}</b>}</div> */}
                </div>
                {/* <div className="row px-3">
                    <div className="col-12 col-xl-6 pl-0">
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="submit"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.continueWithoutVenue()
                            }}
                        >
                            OK
                        </button>
                    </div>
                    <div className="col-12 col-xl-6 pl-0">
                        <button
                            className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.hideWarningPopup();
                            }}
                        >
                            CANCEL
                        </button>
                    </div>
                </div> */}

                {/* col col-lg-2 */}
                <div className="row justify-content-md-center">
                    <div className="col-3">
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="submit"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.continueWithoutVenue()
                            }}
                        >
                            OK
                        </button>
                    </div>

                    <div className="col-3">
                        <button
                            className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.hideWarningPopup();
                            }}
                        >
                            CANCEL
                        </button>
                    </div>
                </div>
            </ScrollView>
        </Popup>
        )
    }

    onClickEdit = () => {
        this.setState({
            readOnlyView: false,
        });
        this.enableDisablementOfFields(this.state.editViewDataItem.itemTypeId);
    };

    //#endregion
    //#region render Component
    render() {
        var readOnlyView: boolean = false;
        if (this.props.location.state && this.props.location.state.isReadOnly) {
            readOnlyView = this.state.readOnlyView;
        }
        var editAllowed: boolean = false;
        if (
            this.state.editViewDataItem.editAllowed == "True" &&
            this.props.location.state.isReadOnly &&
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
        ) {
            editAllowed = false;
        } else {
            editAllowed = true;
        }

        var pageTitle: string = "";
        if (this.props.location.state && this.props.location.state.id == 0) {
            pageTitle = "Financial Adjustment - Add";
        } else if (
            (this.props.location.state && this.props.location.state.isEdit) ||
            (!this.state.readOnlyView && this.props.location.state && !this.props.location.state.isMasterEdit)
        ) {
            pageTitle = "Financial Adjustment - Edit";
        } else if (this.state.readOnlyView) {
            pageTitle = "Financial Adjustment - View";
        } else if (this.props.location.state && this.props.location.state.isMasterEdit) {
            pageTitle = "Financial Adjustment - Master Edit";
        }
        return (
            <LightModePageTemplate>
                <div className="page-content">
                    <div className="col-12">
                        <h3 className="card-form__page-heading">{pageTitle}</h3>
                        <section className="card-form__button-container px-2">
                            <div className="col-12 col-lg-4 col-xl-2">
                                <button
                                    className="btn btn-primary btn--large"
                                    hidden={editAllowed}
                                    onClick={this.onClickEdit}
                                >
                                    Edit
                                </button>
                            </div>
                            <div className="col-12 col-lg-4 col-xl-6"></div>
                            <div className="col-12 col-lg-4">
                                <div className="row p-3 p-xl-0">
                                    <label className="card-form__label col-12 col-lg-4 text-left text-xl-right">
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                        Item Type
                                    </label>
                                    <div className="col-12 col-lg-8 pl-0">
                                        <SelectBox
                                            dataSource={this.state.radioButtonElements}
                                            displayExpr="value"
                                            valueExpr="id"
                                            value={this.state.editViewDataItem.itemTypeId}
                                            onValueChanged={this.handleChangeItemTypeSelect}
                                            disabled={readOnlyView}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="row"></div>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        {this.renderFinancialShiftPanel()}
                        {this.renderFinancialAdjustmentFinancePanel()}
                        {this.renderFinancialAdjsutmentButtonSection()}
                        {this.renderVenueWarningPopup()}
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

//#endregion

export default withTranslation()(FinanceAdjustmentForm);
