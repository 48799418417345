import React, { useEffect, useState } from "react";
import { BaseApiService } from "../../services/BaseApiService";
import LookupService, { LookupTypeItem } from "../../services/LookupService";
import { SelectBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import { SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import SelectAndLookUpBoxUtils from "./SelectAndLookUpBoxUtils";

// Props
interface SelectBoxFromLookupTableProps {
    lookupTypeIndex: string;
    onValueChanged: (e: SelectBoxOnValueChangedEvent) => void;
    signal?: boolean; // made this optional parameter
    disableSignal?: boolean;
    value?: string;
    recordType?: string;
    isReadOnly?: boolean;
}

// Component - Localizes state for a drop down control.
//      Having multiple controls on one page sharing state was causing load issues.
//      Localizing the state to the control itself solves the issues.
const SelectBoxFromLookupTable: React.FC<SelectBoxFromLookupTableProps> = (props) => {
    const [state, setResult] = useState<BaseApiService<LookupTypeItem>>({
        status: "loading",
    });
    const service = new LookupService();
    const selectAndLookUpBoxUtils = new SelectAndLookUpBoxUtils();
   
    // Load data on component load
    useEffect(() => {
        if(props.recordType && props.recordType == "all")
        {
            selectAndLookUpBoxUtils.executeLookup(service.getLookupByLookupTypeIndexAll, props.lookupTypeIndex, 3, handleSuccess, handleError);
        }
        else{
            selectAndLookUpBoxUtils.executeLookup(service.getLookupByLookupTypeIndex, props.lookupTypeIndex, 3, handleSuccess, handleError);
        }

    }, []);

    // Set data source from server
    const handleSuccess = (response: AxiosResponse<any>) => {
        setResult({
            status: "loaded",
            payload: response.data.data,
        });
    };

    // Capture error from server
    const handleError = (error: any) => {
        setResult({
            status: "error",
            error,
        });

        var respMessage: string =
            `Lookup Type ${props.lookupTypeIndex} data load failed with response: ` + JSON.stringify(error);

        if (!service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    return (
        <div id="lookupTypeListComponentId">
            {state.status === "loading" && <span>Loading...</span>}
            {state.status === "loaded" && (
                <SelectBox
                    dataSource={state.payload}
                    displayExpr={"value"}
                    valueExpr={"id"}
                    onValueChanged={props.onValueChanged}
                    disabled={props.signal ? props.signal : props.disableSignal}
                    value={props.value}
                    readOnly={props.isReadOnly}
                />
            )}
            {state.status === "error" && <SelectBox dataSource={[]} />}
        </div>
    );
};

export default SelectBoxFromLookupTable;
