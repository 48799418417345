import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CustomStore from "devextreme/data/custom_store";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import SimpleGridService, { GridTypes, ShiftMatrixRemoteGridRowItem } from "../../services/SimpleGridService";
import ShiftMatrixGridContents from "./ShiftMatrixGridContents";
import GlobalService from "../../services/GlobalService";



interface ShiftMatrixGridProps extends WithTranslation {
    dateRangeFrom: string,
    dateRangeTo: string,
    filterText: string,
    isShowUnbilledItems?: boolean;
    isUnPaidItems?: boolean;
    isUnconfirmedItems?: boolean;
    isUnapprovedItems?:boolean;
}

interface ShiftMatrixGridState {
    dataSource: [],
    modifiedDataSource: ShiftMatrixRemoteGridRowItem[],
    loadIndicatorForGrid: boolean,
    isRefreshButtonClicked: boolean;
}

class ShiftMatrixGrid extends Component<ShiftMatrixGridProps> {
    state: ShiftMatrixGridState;
    service: SimpleGridService;
    constructor(props: ShiftMatrixGridProps) {
        super(props);
        this.state = {
            dataSource: [],
            modifiedDataSource: [],
            loadIndicatorForGrid: false,
            isRefreshButtonClicked :false
        }
        this.service = new SimpleGridService();
    }

    getResults = (loadOptions: any) => {
        this.setState({
            loadIndicatorForGrid: true
        })
        let params = buildRequestParameters(loadOptions);
        if (params) {
            params = params + `&`;
        } else {
            params = params + `?`;
        }
        if (!this.props.isShowUnbilledItems && !this.props.isUnPaidItems && !this.props.isUnconfirmedItems && !this.props.isUnapprovedItems) {
            params = params + `dateRangeFrom=${this.props.dateRangeFrom}&dateRangeTo=${this.props.dateRangeTo}`;
        }
        else {
            if (this.props.isShowUnbilledItems) {
                params = params + `showUnbilledItems=${this.props.isShowUnbilledItems}`;
            } else if (this.props.isUnPaidItems) {
                params = params + `showUnpaidItems=${this.props.isUnPaidItems}`;
            } else if (this.props.isUnconfirmedItems) {
                params = params + `showUnconfirmedItems=${this.props.isUnconfirmedItems}`;
            } else if (this.props.isUnapprovedItems) {
                params = params + `showUnapprovedItems=${this.props.isUnapprovedItems}`;
            }
        }

        // if (params) {
        //     if(!this.props.isShowUnbilledItems && !this.props.isUnPaidItems && !this.props.isUnconfirmedItems && !this.props.isUnapprovedItems){
        //         params = params + `&dateRangeFrom=${this.props.dateRangeFrom}&dateRangeTo=${this.props.dateRangeTo}`;
        //     }
        //     else {
        //         if(this.props.isShowUnbilledItems){
        //             params = params + `&showUnbilledItems=${this.props.isShowUnbilledItems}`;
        //         } else if(this.props.isUnPaidItems){
        //             params = params + `&showUnpaidItems=${this.props.isUnPaidItems}`;
        //         } else if(this.props.isUnconfirmedItems){
        //             params = params + `&showUnconfirmedItems=${this.props.isUnconfirmedItems}`;
        //         } else if(this.props.isUnapprovedItems){
        //             params = params + `&showUnapprovedItems=${this.props.isUnapprovedItems}`;
        //         }
        //     }
        // }
        
        // else {
        //     if(!this.props.isShowUnbilledItems && !this.props.isUnPaidItems && !this.props.isUnconfirmedItems && !this.props.isUnapprovedItems){
        //         params = `?dateRangeFrom=${this.props.dateRangeFrom}&dateRangeTo=${this.props.dateRangeTo}`;
        //     }
        //     else {
        //         if(this.props.isShowUnbilledItems){
        //             params = params + `?showUnbilledItems=${this.props.isShowUnbilledItems}`;
        //         } else if(this.props.isUnPaidItems){
        //             params = params + `?showUnpaidItems=${this.props.isUnPaidItems}`;
        //         } else if(this.props.isUnconfirmedItems){
        //             params = params + `?showUnconfirmedItems=${this.props.isUnconfirmedItems}`;
        //         } else if(this.props.isUnapprovedItems){
        //             params = params + `?showUnapprovedItems=${this.props.isUnapprovedItems}`;
        //         }
        //         //params = `?showUnbilledItems=${this.props.isShowUnbilledItems}`;
        //     }
        // }
        return this.service.getShiftMatrixGridRowsWithinRemoteOperations(params)
            .then(this.handleSuccessLoadDataSource)
            .catch(() => { throw 'Data Loading Error'; });
    }

    handleSuccessLoadDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });
        this.setState({isRefreshButtonClicked: false});
        if (response.data && response.data.data) {
            return {
                data: response.data.data ? response.data.data : [],
                totalCount: response.data.totalCount
            };
        }
        else {
            return {
                data: response.data.billableItemRemoteGrid ?
                    this.modifyManageShiftsGridRow(response.data.billableItemRemoteGrid, this.props.filterText, this.props.dateRangeFrom, this.props.dateRangeTo) : [],
                totalCount: response.data.totalCount
            };
        }
    }
 
    onRefresh = (isRefresh:boolean) => {
        this.setState({isRefreshButtonClicked: isRefresh});
    }

    componentDidMount() {
        this.loadDataSource();
    }

    componentDidUpdate(prevProps: ShiftMatrixGridProps,prevState: ShiftMatrixGridState) {
        if (this.props.dateRangeFrom != prevProps.dateRangeFrom || 
            this.props.dateRangeTo != prevProps.dateRangeTo || 
            this.props.isShowUnbilledItems != prevProps.isShowUnbilledItems ||
            this.props.isUnPaidItems != prevProps.isUnPaidItems || 
            this.props.isUnconfirmedItems != prevProps.isUnconfirmedItems || 
            this.props.isUnapprovedItems != prevProps.isUnapprovedItems ||
            (this.state.isRefreshButtonClicked !== prevState.isRefreshButtonClicked && this.state.isRefreshButtonClicked == true)) {
            this.loadDataSource();
        }

    }

    //A helper function that will insert 3 nodes namely "dateFrom","dateTo" and "filterText" into the EventUk user grid.
    //A helper function that will insert 3 nodes namely "dateFrom","dateTo" and "filterText" into the EventUk user grid.
    modifyManageShiftsGridRow = (
        dataSource: ShiftMatrixRemoteGridRowItem[],
        filterText: string,
        dateFrom: string,
        dateTo: string
    ): ShiftMatrixRemoteGridRowItem[] => {
        for (let item of dataSource) {
            item.statusText = filterText;
            item.filterStartDate = dateFrom;
            item.filterEndDate = dateTo;
        }
        return dataSource;
    };
    loadDataSource = () => {
        let dataSource = new CustomStore({
            key: "id",
            load: this.getResults
        });
        GlobalService.setGridType(GridTypes.ShiftMatrixGrid);
        this.setState({
            dataSource: dataSource
        });
    }

    render() {
        return (
            <div>
                    <div className="row">
                        <div className="col-md">
                            <ShiftMatrixGridContents dataSource={this.state.dataSource} navigateFrom={GridTypes.EventUk} onRefresh={this.onRefresh} />
                        </div>
                    </div>

            </div>
        );
    }
}

export default withTranslation()(ShiftMatrixGrid);