import { AxiosResponse } from "axios";
import { _aiTraceAsError, _get ,} from "./BaseApiService";

// Endpoints
const LICENSECHECK_DATA_GRID_SERVICE_API = "IdCheck";

// Grid row 
export interface LicenseCheckServiceRowItem {
    id: string;
    service: string;
    subType: string;
    licenseType: string;
    name: string;
    licenseNumber: string;
    status: string;
    expiryDate: string;
    asAtDate: string;
    requestedDate: string;
    lastworkedDate: string;
    dueToWorkDate: string;
    lastUpdateDate: string;
    lastUpdatedBy: string;
}

/// Service
class LicenseCheckService {
    getLicenseCheckGridRows(): Promise<AxiosResponse<any>> {
            return _get(LICENSECHECK_DATA_GRID_SERVICE_API);
    }

    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default LicenseCheckService;
