import React, { Component, ChangeEvent, MouseEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import FilterItem from "../FindAnArtist/filterItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import FindAnArtistService, { artistCardUIType } from "../../services/FindAnArtistService";
import { AxiosResponse } from "axios";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import ArtistUtilities, { filterObject } from "../FindAnArtist/FindAnArtistUtils";
import { ServiceSubTypeId } from "../../services/ArtistService";
import GlobalService from "../../services/GlobalService";
import { Slider, CheckBox, Button, Popup, ScrollView } from "devextreme-react";
import {
    SliderOnChangedEvent,
    CheckBoxOnValueChanged,
} from "../../types/DevExtremeTypes";
import ArtistGrid from "./ArtistGrid";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import GeographyPanel from "../side-bar/GeographyPanel";


interface ArtistGridPageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

interface ArtistGridPageState {
    cardUIType: string; //This would communicate to the Card List rendering panel of which type of cards to display.
    showProgressIndicator: boolean;
    formView: boolean;
    isPending: boolean;
    requestStatus: string;
    typeListObject: filterObject;
    typeData: any;
    countOftypes: number;
    artistSearchField: string;
    subTypesList: string;
    requestButtonClickCount: number;
    firstTimeRequest: boolean;
    isPopUpToShow: boolean;
    artistSearchVal: string;
    isClickArtistSearch: boolean;
    isClickSearchButton: boolean;
    postCode: string;
    distance: string;
    typeList: string;
    popupVisible: boolean;
    artistAdded: string;
    rating: number;
    trustedValue: boolean;
    gridClient: string;
    gridVenue: string;
}

class ArtistGridPage extends Component<ArtistGridPageProps> {
    //Initialize Service
    service: FindAnArtistService;
    //Initialize the State
    state: ArtistGridPageState;
    //Initialize the utilities
    utils: ArtistUtilities;

    constructor(props: ArtistGridPageProps) {
        super(props);
        this.service = new FindAnArtistService();
        this.utils = new ArtistUtilities();
        this.state = {
            cardUIType: "",
            showProgressIndicator: false,
            formView: false,
            isPending:
                this.props.location && this.props.location.state && this.props.location.state.isPendingRequest
                    ? true
                    : false,
            requestStatus: this.props.location && this.props.location.state && this.props.location.state.reqStatus,
            typeListObject: this.utils.initializeFilterObject(),
            typeData: [],
            countOftypes: 0,
            artistSearchField: "",
            subTypesList: "",
            requestButtonClickCount: 0,
            firstTimeRequest: true,
            isPopUpToShow: false,
            artistSearchVal: "",
            isClickArtistSearch: false,
            isClickSearchButton: false,
            postCode: "",
            distance: "",
            typeList: "",
            popupVisible: false,
            artistAdded: "",
            rating: 0,
            trustedValue: false,
            gridClient: "",
            gridVenue: "",
        };
    }

    componentDidMount() {
        this.service.gettypeLookup().then(this.handleSuccessTypeLookUp).catch(this.handleStandardError);
        if (this.state.isPending) {
            this.selectRequested();
        }
    }

    componentDidUpdate(prevProps: Readonly<ArtistGridPageProps>) {
        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.navigateFrom) {
                if (this.props.location.state.navigateFrom != prevProps.location.state.navigateFrom) {
                    this.setState({
                        formView: false
                    });
                }
            }
        }
    }

    handleSuccessTypeLookUp = (response: AxiosResponse<any>) => {
        this.setState({
            typeData: response.data.data,
        });
    };

    handleStandardError = (error: any) => {
        var respMessage: string = "gettypeLookup failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    selectRequested = () => {
        this.setState((prevState: ArtistGridPageState) => ({
            cardUIType: artistCardUIType.Requested,
            formView: true,

            requestButtonClickCount: prevState.requestButtonClickCount + 1,
            isPopUpToShow: false,
            firstTimeRequest:
                this.props.location.state &&
                    this.props.location.state.isNewRequest &&
                    prevState.requestButtonClickCount + 1 > 1
                    ? false
                    : true,
        }));
    };

    buildTypeListArray = (typeList: filterObject, selected: boolean, parentNodeClicked: boolean, deSelectType: boolean) => {
        let artistypeParam: string[] = [];
        let values: string = "";
        let subTypeTypeValues: string = "";
        this.setState({
            typeListObject: typeList,
            countOftypes: typeList.types.length,
        });
        if (typeList.serviceSubtype == ServiceSubTypeId.LiveMusic || typeList.serviceSubtype == ServiceSubTypeId.DJ || typeList.serviceSubtype == ServiceSubTypeId.PubQuiz) {
            if (typeList.types.length > 0) {
                artistypeParam = typeList.types.map((item: any) => {
                    return this.buildArtistTypeParameter(typeList.serviceSubtype, item);
                });
                values = artistypeParam.toString();
            }
        }
        else {
            values = this.buildArtistTypeParameter(typeList.serviceSubtype);
        }
        if (deSelectType) {
            subTypeTypeValues = GlobalService.getArtistTypeSubType();
        }
        else if (selected && parentNodeClicked || selected && !parentNodeClicked) {
            GlobalService.setArtistTypeSubType(values);
            subTypeTypeValues = GlobalService.getArtistTypeSubType();
        }
        else if (!selected && parentNodeClicked) {
            GlobalService.removeArtistTypeSubType(typeList.serviceSubtype, "");
            subTypeTypeValues = GlobalService.getArtistTypeSubType();
        }
        if (subTypeTypeValues.startsWith(",")) {
            subTypeTypeValues = subTypeTypeValues.slice(1);
        }
        this.setState({
            typeList: subTypeTypeValues
        });
    };

    buildArtistTypeParameter = (serviceSubTypeId: string, typeId?: string) => {
        if (serviceSubTypeId && typeId) {
            return `${serviceSubTypeId}-${typeId}`;
        }
        else if (serviceSubTypeId) {
            return `${serviceSubTypeId}`;
        }
        else {
            return "";
        }
    }

    searchArtists = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            artistSearchField: event.target.value
        });
    };

    onSearch = () => {
        this.setState({
            artistSearchVal: this.state.artistSearchField,
            isClickArtistSearch: true,
            isClickSearchButton: false,
            postCode: "",
            distance: ""
        });
    }

    setPostCodeDistance = (postalCode: string, radius: string) => {
        this.setState({
            postCode: postalCode,
            distance: radius,
            isClickArtistSearch: false,
            isClickSearchButton: true,
            artistSearchVal: this.state.artistSearchField
        });
    };

    getVenue = (client: string, venue:string) => {
        this.setState({
            gridClient: client,
            gridVenue: venue
        });
    }

    updateSearchButton = (flag: boolean) => {
        this.setState({
            isClickArtistSearch: flag,
            isClickSearchButton: flag
        });
    }

    handleChangeRating = (dxValueChange: SliderOnChangedEvent) => {
        this.setState({
            rating: dxValueChange.value,
        });
        if(dxValueChange && dxValueChange.element){
            dxValueChange.element.onkeydown=(event: any) => {
                if(event.key && event.key === 'Enter'){
                    this.onSearch();
                }
             }
            }
    };

    handleChangeTrusted = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            trustedValue: dxValueChange.value
        });
        if(dxValueChange && dxValueChange.element){
        dxValueChange.element.onkeydown=(event: any) => {
            if(event.key && event.key === 'Enter'){
                this.onSearch();
            }
         }
        } 
    };
    
    addArtistPopup = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hideArtistPopup = (e: any) => {
        e.cancel = true;
        this.setState({
            popupVisible: false,
        });
    };

    handleAddArtist = (isAdded: string) => {
        this.setState({ 
            artistAdded: isAdded,
            popupVisible: false,
         });
    }

    render() {  
        let serviceType:any = GlobalService.getArtistGridComingFrom();
        let pageTitle;
        if(serviceType == "Entertainment"){
            pageTitle = "View Artists";
        }
        else if(serviceType == "ContractCleaning") {
            pageTitle = "Contract Cleaning - View Providers";
        }
        else if(serviceType == "Security") {
            pageTitle = "Door Supervision - View Providers";
        }
        else if(serviceType == "Gardening") {
            pageTitle = "Gardening - View Providers";
        }
        else if(serviceType == "Maintenance") {
            pageTitle = "Maintenance - View Providers";
        }
        else if(serviceType == "Miscelleanous") {
            pageTitle = "Miscelleanous - View Providers";
        }
        else {
            pageTitle = "Window Cleaning - View Providers";
        }
        return (
            <LightModePageTemplate>               
                <SideBarTemplate isFullBleed={true}>
                    <form onSubmit={(e: any) => {
                        e.preventDefault();
                        this.onSearch();
                    }}>
                        <div className="filter-box__search mt-3">
                            <input
                                type="search"
                                onChange={this.searchArtists}
                                //aria-label="Search"
                                placeholder="Search for artists"
                            ></input>
                            <button type="submit"
                            // onClick={(e: MouseEvent) => {
                            //     e.preventDefault();
                            //     this.onSearch();
                            // }}
                            >
                                <FontAwesomeIcon icon={faSearch} />
                                <span className="sr-only">Submit Search</span>
                            </button>
                        </div>
                    </form>
                    <div className="filter-box mt-3" hidden={serviceType != "Entertainment"}>
                        <h5 className="filter-box__section-header">Artist type</h5>
                        <ul className="filter-box__filter-list">
                            {this.state.typeData.map((item: any, index: number) => {
                                return (
                                    (item.id == ServiceSubTypeId.LiveMusic || item.id == ServiceSubTypeId.DJ || item.id == ServiceSubTypeId.Karaoke || item.id == ServiceSubTypeId.PubQuiz || item.id == ServiceSubTypeId.Other) ?
                                        <FilterItem
                                            key={index}
                                            item={item}
                                            sendTypeListToParent={this.buildTypeListArray}
                                        /> : <></>
                                );
                            })}
                        </ul>
                        <div className="row mt-3">
                            <div className="col sidebar__heading">ARTIST RATING</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <Slider
                                    min={0}
                                    max={5}
                                    value={this.state.rating}
                                    step={1}
                                    onValueChanged={this.handleChangeRating}
                                    tooltip={{
                                        enabled: true,
                                        showMode: "always",
                                        position: "bottom",
                                        format: () => {
                                            return `${this.state.rating} Stars`;
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <br></br><br></br><br></br>
                        <div className="row mt-2">
                            <div className="col-2">
                                <div>
                                    <CheckBox value={this.state.trustedValue} onValueChanged={this.handleChangeTrusted} />
                                </div>
                            </div>
                            <div className="col-7 sidebar__heading">Only Show Trusted</div>
                        </div>
                        <br></br>
                        <h5 className="filter-box__section-header">Geography</h5>
                        <GeographyPanel setPostCodeDistance={this.setPostCodeDistance} location={this.props.location} gridClient={this.state.gridClient}
                            gridVenue={this.state.gridVenue}></GeographyPanel>
                    </div>
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    <div className="find-an-artist-header">
                        <div>
                            <h2 className="page-title__black">
                                {pageTitle}
                            </h2>
                        </div>
                    </div>
                    <div className="row" hidden={serviceType == "Entertainment"}>
                        <div className="col-2 mt-3 mb-3">
                            <Button
                                className="btn btn-primary btn--large"
                                disabled={false}
                                onClick={this.addArtistPopup}
                            >
                                ADD PROFILE
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <ArtistGrid
                                location={this.props.location}
                                history={this.props.history}
                                typeListObject={this.state.typeListObject}
                                countOftypes={this.state.countOftypes}
                                requestStatus={this.state.requestStatus}
                                postCode={this.state.postCode}
                                distance={this.state.distance}
                                trustedValue={this.state.trustedValue}
                                rating={this.state.rating}
                                getVenue={this.getVenue}
                                isShowPopUp={this.state.isPopUpToShow}
                                searchFieldVal={this.state.artistSearchVal}
                                typeList={this.state.typeList}
                                isClickArtistSearch={this.state.isClickArtistSearch}
                                isClickSearchButton={this.state.isClickSearchButton}
                                updateSearchButton={this.updateSearchButton}
                                serviceType={serviceType}
                                artistAdded={this.state.artistAdded}
                            />
                        </div>
                    </div>                    
                    {
                        this.state.popupVisible ? (
                            <Popup
                                visible={this.state.popupVisible}
                                onHiding={this.hideArtistPopup}
                                dragEnabled={false}
                                hideOnOutsideClick={false}
                                showTitle={true}
                                showCloseButton={true}
                                title={serviceType == "Entertainment" ? "Add Artist" : "Add Provider"}
                                resizeEnabled={true}
                            >
                                <ScrollView width="100%" height="100%">
                                    <ArtistProfileComponent
                                        mode="edit"
                                        id="0"
                                        businessEntityId="0"
                                        handlePopUpClose={() => { return null }}
                                        handleFeedbackUpdate={() => { return null }}
                                        handleAddArtist={this.handleAddArtist}
                                        serviceType={serviceType}
                                    ></ArtistProfileComponent>
                                </ScrollView>
                            </Popup>
                        ) : null
                    }
                </div>

            </LightModePageTemplate>
        );
    }
}
export default withRouter(ArtistGridPage);
