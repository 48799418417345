import {
    BillingFormResponse,
    BillingFormSubmitRequest,
    invoiceServices,
} from "../../services/BillingCyclesFormService";
import { BillingCyclesGridServiceInvoiceItem } from "../../services/BillingCyclesGridService";
import { LookupTypeItem, PeriodMatrixLookupItem } from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import { months, daysArray } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { BudgetUploadChildGridRowItem } from "../../services/BillableItemHeaderService";

type NullableDate = Date | null | undefined;

interface LookUpInvoiceTypes {
    id: string[];
    value: string[];
}
export const ActionType = {
    Restart: "Reset",
    Apply: "Apply",
    Save: "Save",
};

export enum BillingStatus {
    Draft,
    Pending,
    ReadyForBilling,
    BillChecked,
    BillPackRequested,
    BillPackReady,
    BillPackFailed,
    BillPackSent,
    BillNotPaid,
    BillQueried,
    BillPaid,
    BillDefault,
}

export interface documentObject {
    id: string;
    link: string;
}

export interface documentsListObject {
    dateFrom: string;
    dateTo: string;
    displayLink: string;
    documentType: string;
    formatType: string;
    id: string;
    link: string;
    name: string;
    updatedBy: string;
    updatedOn: string;
}

export interface BillingFormData {
    documents: documentObject[];
    invoiceId: string;
    clientId: string;
    client: string;
    statusId: string;
    status: string;
    periodMatrixId: string;
    period: string;
    week: string;
    dateRangeFrom: NullableDate;
    dateRangeTo: NullableDate;
    tax: number;
    subTotal: number;
    total: number;
    invoiceDate: NullableDate;
    invoiceReference: string;
    invoiceSentDate: NullableDate;
    invoiceDueDate: NullableDate;
    invoicePaidDate: NullableDate;
    invoiceAmount: number;
    description: string;
    actionType: string;
    invoiceServiceMappingList: invoiceServices[];
    internalReference: string;
    includeAccruals: boolean;
    groupAccruals: boolean;
}

export interface PeriodMatrixTableLookup {
    id: string;
    businessEntityId: string;
    daysOfWeekId: string;
    daysOfWeekValue: string;
    periodNumber: string;
    periodWeek: string;
    dateFrom: string;
    dateTo: string;
}

export interface PeriodMatrixSelections {
    periodSelections: LookupTypeItem[];
    currentPeriod: string;
    weekSelections: LookupTypeItem[];
    currentWeek: string;
}

export interface CurrentPeriodMatrix {
    period: string;
    week: string;
    dateFrom: NullableDate;
    dateTo: NullableDate;
}

export default class BillingFormUtils {
    //Declare the shared utils function here.
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.parseTimestamp = this.parseTimestamp.bind(this);
        this.initializeBillingCycleItems = this.initializeBillingCycleItems.bind(this);
        this.convertBillingCycleResponseToFormData = this.convertBillingCycleResponseToFormData.bind(this);
        this.convertFormDataToBillingCycleRequest = this.convertFormDataToBillingCycleRequest.bind(this);
        this.compareStringValues = this.compareStringValues.bind(this);
        this.buildDaysOfWeekList = this.buildDaysOfWeekList.bind(this);
        this.compareValues = this.compareValues.bind(this);
        this.initializeBillingCyclesGridServiceInvoiceItem = this.initializeBillingCyclesGridServiceInvoiceItem.bind(
            this
        );
    }

    /**
     * Provide a sort comparrison function in order to sort a PeriodMatrixTableLookup[] by periodNumber or periodWeek
     *
     * @param key {string} - Can be either 'periodNumber' or 'periodWeek'
     * @returns {function compare(a: PeriodMatrixTableLookup, b: PeriodMatrixTableLookup)} - The compare function for sorting
     */
    compareStringValues(key: string): (a: PeriodMatrixTableLookup, b: PeriodMatrixTableLookup) => number {
        return function compare(a: PeriodMatrixTableLookup, b: PeriodMatrixTableLookup) {
            let keyA: number = 0;
            let keyB: number = 0;
            if (key == "periodNumber") {
                keyA = parseInt(a.periodNumber);
                keyB = parseInt(b.periodNumber);
            }
            else if (key == "periodWeek") {
                keyA = parseInt(a.periodWeek);
                keyB = parseInt(b.periodWeek);
            }
            let comparison = 0
            if (keyA > keyB) {
                comparison = 1;
            } else if (keyA < keyB) {
                comparison = -1;
            }
            return comparison;
        };
    }

    sortPeriodValue = () => {

    }

    /**
     * Provide a sort comparrison function in order to sort a PeriodMatrixTableLookup[] by daysOfWeek
     *
     * @param key {string} - Can be either 'periodNumber' or 'periodWeek'
     * @returns {function compare(a: PeriodMatrixTableLookup, b: PeriodMatrixTableLookup)} - The compare function for sorting
     */
    compareValues(key: string): (a: PeriodMatrixTableLookup, b: PeriodMatrixTableLookup) => number {
        return function compare(a: PeriodMatrixTableLookup, b: PeriodMatrixTableLookup) {
            let keyA = parseInt(a.daysOfWeekId);
            let keyB = parseInt(b.daysOfWeekId);
            let comparison = 0;

            if (keyA > keyB) {
                comparison = 1;
            } else if (keyA < keyB) {
                comparison = -1;
            }
            return comparison;
        };
    }

    /**
     * Get the period matrix id for the row that matches the selected period and week
     *
     * @param periodMatrixTable {PeriodMatrixTable}
     * @param clientId {string}
     * @param periodNumber {string}
     * @param periodWeek {string}
     */
    getPeriodMatrixId(
        periodMatrixTable: PeriodMatrixTableLookup[],
        clientId: string,
        periodNumber: string,
        periodWeek: string
    ): string {
        var matrixId: string = "";

        if (periodMatrixTable && periodMatrixTable.length > 0) {
            var matrixRow = periodMatrixTable.find((item) => {
                return (
                    item.businessEntityId == clientId &&
                    item.periodNumber == periodNumber &&
                    item.periodWeek == periodWeek
                );
            });
            matrixId = matrixRow ? matrixRow.id : "";
        }

        return matrixId;
    }

    /**
     * Get a date from the period matrix table.
     *
     * @param isFromDate {boolean}
     * @param periodMatrixTable {PeriodMatrixTable}
     * @param clientId {string}
     * @param periodNumber {string}
     * @param periodWeek {string}
     */
    getDateFromPeriodMatrixTable(
        isFromDate: boolean,
        periodMatrixTable: PeriodMatrixTableLookup[],
        clientId: string,
        periodNumber: string,
        periodWeek: string
    ): NullableDate {
        var retDate: NullableDate = undefined;

        if (periodMatrixTable && periodMatrixTable.length > 0) {
            var matrixRow = periodMatrixTable.find((item) => {
                return (
                    item.businessEntityId == clientId &&
                    item.periodNumber == periodNumber &&
                    item.periodWeek == periodWeek
                );
            });

            if (isFromDate) {
                retDate = matrixRow ? this.utils.convertStringToDate(matrixRow.dateFrom) : undefined;
            } else {
                retDate = matrixRow ? this.utils.convertStringToDate(matrixRow.dateTo) : undefined;
            }
        }

        return retDate;
    }

    /**
     * Get the current period, week, from and to dates from the period matrix table
     *
     * @param clientId {string} - BusiessEntityId for a specific client
     * @param periodMatrixTable {PeriodMatrixTableLookup[]} - The entire period matrix table
     */
    getCurrentPeriodWeekAndDatesByClient(
        clientId: string,
        periodMatrixTable: PeriodMatrixTableLookup[]
    ): CurrentPeriodMatrix {
        var currentData: CurrentPeriodMatrix = {
            period: "",
            week: "",
            dateFrom: undefined,
            dateTo: undefined,
        };
        if (periodMatrixTable && periodMatrixTable.length > 0) {
            var clientMatrix = periodMatrixTable.filter((item) => {
                return item.businessEntityId == clientId;
            });

            for (var item of clientMatrix) {
                var today = new Date();
                // Requirement for "current" period: derived by "systems current date minus 7 days"
                var todayMinusSevenDays = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
                var dateFromAsDate = this.utils.convertStringToDate(item.dateFrom);
                var dateToAsDate = this.utils.convertStringToDate(item.dateTo);

                if (dateFromAsDate && dateToAsDate) {
                    if (todayMinusSevenDays >= dateFromAsDate && todayMinusSevenDays <= dateToAsDate) {
                        currentData.dateFrom = this.utils.convertStringToDate(item.dateFrom);
                        currentData.dateTo = this.utils.convertStringToDate(item.dateTo);
                        currentData.period = item.periodNumber;
                        currentData.week = item.periodWeek;
                        // Done here
                        break;
                    }
                }
            }
        }

        return currentData;
    }
    getTodaysPeriodWeekAndDatesByClient(
        clientId: string,
        periodMatrixTable: PeriodMatrixTableLookup[],
        dateparam: Date
    ): CurrentPeriodMatrix {
        var currentData: CurrentPeriodMatrix = {
            period: "",
            week: "",
            dateFrom: undefined,
            dateTo: undefined,
        };

        if (periodMatrixTable && periodMatrixTable.length > 0) {
            var clientMatrix = periodMatrixTable.filter((item) => {
                return item.businessEntityId == clientId;
            });
            for (var item of clientMatrix) {
                // Requirement for "current" period: derived by "systems current date minus 7 days"
                var dateFromAsDate = this.utils.convertStringToDate(item.dateFrom);
                var dateToAsDate = this.utils.convertStringToDate(item.dateTo);

                if (dateFromAsDate && dateToAsDate) {
                    if (dateparam >= dateFromAsDate && dateparam <= dateToAsDate) {
                        currentData.dateFrom = this.utils.convertStringToDate(item.dateFrom);
                        currentData.dateTo = this.utils.convertStringToDate(item.dateTo);
                        currentData.period = item.periodNumber;
                        currentData.week = item.periodWeek;
                        // Done here
                        break;
                    }
                }
            }
        }
        return currentData;
    }
    /**
     * Create a new Period Selection when the period number changes
     *  - id: set to current client Id + period number in order to provide a known Id to the week selection's parentMappingId.
     *  - parentMappingId: set to the client Id so this selection can be filtered by client Id when a new client is selected
     */
    buildPeriodList(clientId: string, periodMatrixTable: PeriodMatrixTableLookup[]): LookupTypeItem[] {
        var periodLookupList: LookupTypeItem[] = [];
        if (periodMatrixTable && periodMatrixTable.length > 0) {
            this.buildDaysOfWeekList(periodMatrixTable);
            var lastPeriodNumber: string[] = [];
            var clientMatrix = periodMatrixTable.filter((item) => {
                return item.businessEntityId == clientId;
            });
            clientMatrix.sort(this.compareStringValues("periodNumber"));

            if (clientMatrix.length > 0) {
                clientMatrix.forEach((currentMatrixTableItem) => {
                    if (!lastPeriodNumber.includes(currentMatrixTableItem.periodNumber)) {
                        lastPeriodNumber.push(currentMatrixTableItem.periodNumber);
                        periodLookupList.push({
                            id: currentMatrixTableItem.periodNumber,
                            value: currentMatrixTableItem.periodNumber,
                            parentMappingId: "",
                        });
                    }
                });
            }
        }

        return periodLookupList;
    }

    //Helper function that will return the Period Year dropdown based on a client selection.
    buildPeriodYearList = (clientId: string, periodMatrixTable: PeriodMatrixLookupItem[]): LookupTypeItem[] => {
        var periodLookupList: LookupTypeItem[] = [];

        if (periodMatrixTable && periodMatrixTable.length > 0) {
            var clientMatrix = periodMatrixTable.filter((item) => {
                return item.businessEntityId == clientId;
            });

            if (clientMatrix) {
                const map = new Map();
                for (const item of clientMatrix) {
                    if (!map.has(item.yearNumber)) {
                        map.set(item.yearNumber, true); // set any value to Map
                        periodLookupList.push({
                            id: item.yearNumber,
                            value: item.yearNumber,
                            parentMappingId: item.businessEntityId,
                        });
                    }
                }
            }
        }

        return periodLookupList;
    };

    //Helper function that will return the Period Number dropdown based on a client selection.
    buildPeriodNumberList = (
        clientId: string,
        periodYear: string,
        periodMatrixTable: PeriodMatrixLookupItem[]
    ): LookupTypeItem[] => {
        var periodLookupList: LookupTypeItem[] = [];

        if (periodMatrixTable && periodMatrixTable.length > 0) {
            var clientMatrix = periodMatrixTable.filter((item) => {
                return item.businessEntityId == clientId && item.yearNumber == periodYear;
            });

            //Returns distinct values.
            if (clientMatrix) {
                const map = new Map();
                for (const item of clientMatrix) {
                    if (!map.has(item.periodNumber)) {
                        map.set(item.periodNumber, true); // set any value to Map
                        periodLookupList.push({
                            id: item.periodNumber,
                            value: item.periodNumber,
                            parentMappingId: item.yearNumber,
                        });
                    }
                }
            }
        }

        return periodLookupList;
    };

    //Helper function that will return the Period Week dropdown based on a client selection.
    buildPeriodWeekList = (
        clientId: string,
        periodYear: string,
        periodNumber: string,
        periodMatrixTable: PeriodMatrixLookupItem[]
    ): LookupTypeItem[] => {
        var periodLookupList: LookupTypeItem[] = [];

        if (periodMatrixTable && periodMatrixTable.length > 0) {
            var clientMatrix = periodMatrixTable.filter((item) => {
                return (
                    item.businessEntityId == clientId &&
                    item.yearNumber == periodYear &&
                    item.periodNumber == periodNumber
                );
            });

            //Returns distinct values.
            if (clientMatrix) {
                const map = new Map();
                for (const item of clientMatrix) {
                    if (!map.has(item.periodWeek)) {
                        map.set(item.periodWeek, true); // set any value to Map
                        periodLookupList.push({
                            id: item.periodWeek,
                            value: item.periodWeek,
                            parentMappingId: item.periodNumber,
                        });
                    }
                }
            }
        }
        return periodLookupList;
    };

    //Helper function that will return the Period Week dropdown based on a client selection.
    buildDateList = (
        clientId: string,
        periodYear: string,
        periodNumber: string,
        periodWeek: string,
        periodMatrixTable: PeriodMatrixLookupItem[],
        sendStartDates: boolean
    ): LookupTypeItem[] => {
        var periodLookupList: LookupTypeItem[] = [];
        if (periodMatrixTable && periodMatrixTable.length > 0) {
            var clientMatrix = periodMatrixTable.filter((item) => {
                return (
                    item.businessEntityId == clientId &&
                    item.yearNumber == periodYear &&
                    item.periodNumber == periodNumber &&
                    item.periodWeek == periodWeek
                );
            });
            if (clientMatrix) {
                clientMatrix.forEach((currentMatrixTableItem) => {
                    if (sendStartDates) {
                        periodLookupList.push({
                            id: this.convertDateIntoReadableFormat(currentMatrixTableItem.dateFrom),
                            value: this.convertDateIntoReadableFormat(currentMatrixTableItem.dateFrom),
                            parentMappingId: currentMatrixTableItem.periodWeek,
                        });
                    } else {
                        periodLookupList.push({
                            id: this.convertDateIntoReadableFormat(currentMatrixTableItem.dateTo),
                            value: this.convertDateIntoReadableFormat(currentMatrixTableItem.dateTo),
                            parentMappingId: currentMatrixTableItem.periodWeek,
                        });
                    }
                });
            }
        }

        return periodLookupList;
    };

    //Helper function that will convert the week dates into a customized format.
    //For Example - 20/02/2021 to "20-Feb-2021"
    convertDateIntoReadableFormat = (rowValue: string): string => {
        var convertedDate: string = "";
        var monthName: string = "";
        var day: number = 0;
        var month: number = 0;
        var year: number = 0;
        var yearNumber: string = "";
        var budgetYear: string = "";
        var dayOfWeek: string = "";

        if (rowValue && rowValue.length > 0) {
            var dateParts = rowValue.split("/");
            day = parseInt(dateParts[0]);
            month = parseInt(dateParts[1]);
            year = parseInt(dateParts[2]);
            yearNumber = year.toString();
            budgetYear = yearNumber.substr(yearNumber.length - 2);
        }
        if (day >= 1 && day < 10) {
            dayOfWeek = daysArray[day - 1];
        }
        monthName = months[month - 1]; //Takes in the month number and converts it into the Month Name.
        convertedDate = `${dayOfWeek ? dayOfWeek : day}-${monthName}-${budgetYear}`;
        return convertedDate;
    };

    //A helper function that will loop through the entire period matrxix dataset and will pull the distinct dateFrom and dateTo Values
    getDistinctStartEndDates = (
        periodMatrixTable: PeriodMatrixLookupItem[],
        sendStartDates: boolean
    ): LookupTypeItem[] => {
        const result: LookupTypeItem[] = [];
        const map = new Map();
        for (const item of periodMatrixTable) {
            if (!map.has(item.dateFrom)) {
                map.set(item.dateFrom, true); // set any value to Map
                if (sendStartDates) {
                    result.push({
                        id: this.convertDateIntoReadableFormat(item.dateFrom),
                        value: this.convertDateIntoReadableFormat(item.dateFrom),
                        parentMappingId: "0",
                    });
                } else {
                    result.push({
                        id: this.convertDateIntoReadableFormat(item.dateTo),
                        value: this.convertDateIntoReadableFormat(item.dateTo),
                        parentMappingId: "0",
                    });
                }
            }
        }
        return result;
    };

    //A helper function that will send a unique periodId based on the input combinations.
    getUniquePeriodId = (
        periodMatrixTable: PeriodMatrixLookupItem[],
        clientId: string,
        periodYear: string,
        periodNumber: string,
        periodWeek: string
    ): PeriodMatrixLookupItem[] => {
        var resultantMatrix: PeriodMatrixLookupItem[] = [];
        resultantMatrix = periodMatrixTable.filter((item: PeriodMatrixLookupItem) => {
            return (
                item.businessEntityId == clientId &&
                item.yearNumber == periodYear &&
                item.periodNumber == periodNumber &&
                item.periodWeek == periodWeek
            );
        });
        return resultantMatrix;
    };

    //A helper function that will validate the row values before being inserted/edited.
    budgetRowValidation = (
        id: string,
        clientId: string,
        venueId: string,
        serviceTypeId: string,
        periodId: string,
        budgetAmount: string,
        budgetRows: BudgetUploadChildGridRowItem[]
    ): boolean => {
        var validationSuccess: boolean = false;
        if (clientId && venueId && serviceTypeId && periodId && budgetAmount) {
            var duplicateRows: BudgetUploadChildGridRowItem[] = budgetRows.filter(
                (item: BudgetUploadChildGridRowItem) => {
                    return (
                        item.clientBusinessEntityId == clientId &&
                        item.periodId == periodId &&
                        item.serviceTypeId == serviceTypeId &&
                        item.venueId == venueId &&
                        item.id != id
                    );
                }
            );
            if (duplicateRows && duplicateRows.length == 0) {
                validationSuccess = true;
            }
        }
        return validationSuccess;
    };

    //The following set of functions will filter the VenueData Source to extract the House No. and Venue Name.
    filteredVenueValues = (venueDropDownValues: LookupTypeItem[], clientId: string) => {
        var venueLUItems = venueDropDownValues.filter((item) => {
            return item.parentMappingId == clientId;
        });

        return venueLUItems;
    };

    filterVenueNameValues = (filteredVenueDropDownValues: LookupTypeItem[]) => {
        var venueNameLUItems: LookupTypeItem;
        var venueNameLUItemsArr: LookupTypeItem[] = [];

        filteredVenueDropDownValues.forEach((item: LookupTypeItem) => {
            var parts = item.value.split("-");
            var venueName = parts[1];
            venueNameLUItems = {
                id: item.id,
                value: venueName,
                parentMappingId: item.parentMappingId,
            };

            venueNameLUItemsArr.push(venueNameLUItems);
        });

        return venueNameLUItemsArr;
    };

    filterVenueNoValues = (filteredVenueDropDownValues: LookupTypeItem[]) => {
        var venueNoLUItems: LookupTypeItem;
        var venueNoLUItemsArr: LookupTypeItem[] = [];

        filteredVenueDropDownValues.forEach((item: LookupTypeItem) => {
            var parts = item.value.split("-");
            var venueNo = parts[0];
            venueNoLUItems = {
                id: item.id,
                value: venueNo,
                parentMappingId: item.parentMappingId,
            };

            venueNoLUItemsArr.push(venueNoLUItems);
        });

        return venueNoLUItemsArr;
    };
    /**
     * Create a dropdown for DaysOfWeekList
     *  - id: set to current client Id + period number in order to provide a known Id to the week selection's parentMappingId.
     *  - parentMappingId: set to the client Id so this selection can be filtered by client Id when a new client is selected
     */
    buildDaysOfWeekList(periodMatrixTable: PeriodMatrixTableLookup[]): LookupTypeItem[] {
        var daysLookupList: LookupTypeItem[] = [];
        var sortedPeriodMatrixList: PeriodMatrixTableLookup[] = [];
        if (periodMatrixTable && periodMatrixTable.length > 0) {
            sortedPeriodMatrixList = periodMatrixTable.sort(this.compareValues("daysOfWeekId"));
        }
        if (sortedPeriodMatrixList && sortedPeriodMatrixList.length > 0) {
            var lastDaysOfWeekId = "0";
            if (sortedPeriodMatrixList) {
                sortedPeriodMatrixList.forEach((currentMatrixTableItem) => {
                    if (lastDaysOfWeekId != currentMatrixTableItem.daysOfWeekId) {
                        lastDaysOfWeekId = currentMatrixTableItem.daysOfWeekId;
                        daysLookupList.push({
                            id: currentMatrixTableItem.daysOfWeekId,
                            value: currentMatrixTableItem.daysOfWeekValue,
                            parentMappingId: "",
                        });
                    }
                });
            }
        }

        return daysLookupList;
    }

    /**
     * * Create a new Week Selection
     *   - id: set to current client Id + period number + week number just for symetry.  It is not used anywhere.
     *   - parentMappingId: set to the current Period Selection Id so this selection can be filtered when a new Period is selected
     *
     * @param clientId
     * @param period
     * @param periodMatrixTable
     */
    buildWeekList(clientId: string, period: string, periodMatrixTable: PeriodMatrixTableLookup[]): LookupTypeItem[] {
        var weekLookupList: LookupTypeItem[] = [];

        if (periodMatrixTable && periodMatrixTable.length > 0) {
            let lastWeekNumber: string[] = [];
            var clientMatrix = periodMatrixTable.filter((item) => {
                return item.businessEntityId == clientId && item.periodNumber == period;
            });
            clientMatrix.sort(this.compareStringValues("periodWeek"));



            if (clientMatrix.length > 0) {

                clientMatrix.forEach((currentMatrixTableItem) => {
                    if (!lastWeekNumber.includes(currentMatrixTableItem.periodWeek)) {
                        lastWeekNumber.push(currentMatrixTableItem.periodWeek);
                        weekLookupList.push({
                            id: currentMatrixTableItem.periodWeek,
                            value: currentMatrixTableItem.periodWeek,
                            parentMappingId: "",
                        });
                    }
                });
            }


        }

        return weekLookupList;
    }

    parseTimestamp(timestampStr: any) {
        return new Date(new Date(timestampStr).getTime() + new Date(timestampStr).getTimezoneOffset() * 60 * 1000);
    }

    initializeLinkObject = (): documentObject => {
        var formData: documentObject = {
            id: "",
            link: "",
        };
        return formData;
    };

    initializeBillingCycleItems(idFromGrid: string): BillingFormData {
        var formData: BillingFormData = {
            documents: [],
            invoiceId: "",
            clientId: "",
            client: "",
            statusId: "",
            status: "",
            periodMatrixId: "",
            period: "",
            week: "",
            dateRangeFrom: undefined,
            dateRangeTo: undefined,
            tax: 0,
            subTotal: 0,
            total: 0,
            invoiceDate: undefined,
            invoiceReference: "",
            invoiceSentDate: undefined,
            invoiceDueDate: undefined,
            invoicePaidDate: undefined,
            invoiceAmount: 0,
            description: "",
            actionType: "",
            invoiceServiceMappingList: [],
            internalReference: "",
            includeAccruals: false,
            groupAccruals: false
        };

        return formData;
    }

    initializeBillingCyclesGridServiceInvoiceItem(): BillingCyclesGridServiceInvoiceItem {
        var formData: BillingCyclesGridServiceInvoiceItem = {
            invoiceId: "",
            clientId: "",
            client: "",
            statusId: "",
            status: "",
            periodMatrixId: "",
            period: "",
            week: "",
            dateRangeFrom: "",
            dateRangeTo: "",
            tax: "",
            subTotal: "",
            total: "",
            invoiceDate: "",
            invoiceReference: "",
            invoiceSentDate: "",
            invoiceDueDate: "",
            invoicePaidDate: "",
            invoiceAmount: "",
            description: "",
            actionType: "",
            billableItemIds: "",
            internalReference: "",
            invoiceServiceMappingList: [],
            includeAccruals: false,
            groupAccruals: false,
        };

        return formData;
    }

    // Convert the server response into the component's form state
    convertBillingCycleResponseToFormData(serverResponse: BillingFormResponse): BillingFormData {
        var formData: BillingFormData = {
            documents: serverResponse.documents,
            invoiceId: serverResponse.invoiceId,
            clientId: serverResponse.clientId,
            client: serverResponse.client,
            statusId: serverResponse.statusId,
            status: serverResponse.status,
            periodMatrixId: serverResponse.periodMatrixId,
            period: serverResponse.period,
            week: serverResponse.week,
            dateRangeFrom: this.utils.convertStringToDate(serverResponse.dateRangeFrom),
            dateRangeTo: this.utils.convertStringToDate(serverResponse.dateRangeTo),
            tax: this.utils.convertStringToFloat(serverResponse.tax),
            subTotal: this.utils.convertStringToFloat(serverResponse.subTotal),
            total: this.utils.convertStringToFloat(serverResponse.total),
            invoiceDate: this.utils.convertStringToDate(serverResponse.invoiceDate),
            invoiceReference: serverResponse.invoiceReference,
            invoiceSentDate: this.utils.convertStringToDate(serverResponse.invoiceSentDate),
            invoiceDueDate: this.utils.convertStringToDate(serverResponse.invoiceDueDate),
            invoicePaidDate: this.utils.convertStringToDate(serverResponse.invoicePaidDate),
            invoiceAmount: serverResponse.invoiceAmount
                ? this.utils.convertStringToFloat(serverResponse.invoiceAmount)
                : 0,
            description: serverResponse.description,
            actionType: serverResponse.actionType,
            invoiceServiceMappingList: serverResponse.invoiceServiceMappingList,
            internalReference: serverResponse.internalReference,
            includeAccruals: serverResponse.includeAccruals,
            groupAccruals: serverResponse.groupAccruals
        };
        return formData;
    }

    // Convert the form's data into a proper billable item submit request
    convertFormDataToBillingCycleRequest(
        formData: BillingFormData,
        actionTypeParam: string,
        statusIdForNextStep: string,
        dateFromAutomatedValue: NullableDate,
        dateToAutomatedValue: NullableDate,
        periodValue: string,
        billingStatusId: string
    ): BillingFormSubmitRequest {
        var submitRequest: BillingFormSubmitRequest = {
            invoiceId: formData.invoiceId.toString(),
            clientId: formData.clientId.toString(),
            client: formData.client,
            statusId:
                statusIdForNextStep != ""
                    ? statusIdForNextStep
                    : billingStatusId != ""
                        ? billingStatusId
                        : formData.statusId.toString(),
            status: formData.status,
            periodMatrixId: formData.periodMatrixId,
            period: formData.period,
            week: formData.week,
            dateRangeFrom: dateFromAutomatedValue
                ? this.utils.convertDateToString(dateFromAutomatedValue)
                : this.utils.convertDateToString(formData.dateRangeFrom),
            dateRangeTo: dateToAutomatedValue
                ? this.utils.convertDateToString(dateToAutomatedValue)
                : this.utils.convertDateToString(formData.dateRangeTo),
            tax: this.utils.convertNumberToString(formData.tax),
            subTotal: this.utils.convertNumberToString(formData.subTotal),
            total: this.utils.convertNumberToString(formData.total),
            invoiceDate: this.utils.convertDateToString(formData.invoiceDate),
            invoiceReference: formData.invoiceReference,
            invoiceSentDate: this.utils.convertDateToString(formData.invoiceSentDate),
            invoiceDueDate: this.utils.convertDateToString(formData.invoiceDueDate),
            invoicePaidDate: this.utils.convertDateToString(formData.invoicePaidDate),
            invoiceAmount: this.utils.convertNumberToString(formData.invoiceAmount),
            description: formData.description,
            actionType: actionTypeParam,
            invoiceServiceMappingList: formData.invoiceServiceMappingList,
            internalReference: formData.internalReference,
            includeAccruals: formData.includeAccruals.toString() == "Include" || formData.includeAccruals == true ? true : false,
            groupAccruals: formData.groupAccruals.toString() == "Group" || formData.groupAccruals == true ? true : false
        };

        return submitRequest;
    }

    //A helper function that will extract the the "Id" and "Link" properties from the Documents List.
    extractDocumentLink = (billingData: BillingFormData): documentObject[] => {
        var documentLinkArray: documentObject[] = [];
        if (billingData && billingData.documents && billingData.documents.length > 0) {
            for (var item of billingData.documents) {
                var data = {
                    id: item.id,
                    link: item.link,
                };
                documentLinkArray.push(data);
            }
        }
        return documentLinkArray;
    };
}
