import { AxiosResponse } from "axios";
import { _get, _delete, _setURL, _post, _aiTraceAsError } from "./BaseApiService";

const SUBSTITUTE_GRID_API = "ShiftBatchSubstitute/ShiftBatchMain";
const SUBSTITUTE_FORM__API = "ShiftBatchSubstitute";

type NullableBool = boolean | null | undefined;

export interface EntertainmentSubstituteGridRowItem {
    id: string;
    businessEntityId: string;
    businessEntityShortName: string;
    shiftBatchMainId: string;
    isActive: string;
    date: string;
    rate: string;
    shiftBatchMainDay: string;
    shiftBatchMainDateFrom: string;
    shiftBatchMainDateTo: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
}

export interface SubstituteFormResponse {
    id: string;
    businessEntityId: string;
    businessEntityShortName: string;
    shiftBatchMainId: string;
    isActive: string;
    date: string;
    rate: string;
    shiftBatchMainDay: string;
    shiftBatchMainDateFrom: string;
    shiftBatchMainDateTo: string;
    feeOverride: string;
    feeOverrideFlag: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    internalComments: string;
}

export interface SubstituteFormRequest {
    id: string;
    businessEntityId: string;
    businessEntityShortName: string;
    shiftBatchMainId: string;
    isActive: string;
    date: string;
    rate: string;
    shiftBatchMainDay: string;
    shiftBatchMainDateFrom: string;
    shiftBatchMainDateTo: string;
    feeOverride: string;
    feeOverrideFlag: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    internalComments: string;
}

class EntertainmentSubstituteService {
    getSubstituteGridData(id: string): Promise<AxiosResponse<any>> {
        return _get(`${SUBSTITUTE_GRID_API}/${id}`);
    }

    getSubstituteDataItem(substituteId: string): Promise<AxiosResponse<any>> {
        return _get(`${SUBSTITUTE_FORM__API}/${substituteId}`);
    }

    saveSubstituteDataItem(substituteObject: object) {
        return _post(SUBSTITUTE_FORM__API, substituteObject);
    }
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default EntertainmentSubstituteService;
