import React from "react";
import { LoadPanel } from "devextreme-react";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { AxiosResponse } from "axios";
import UserService, { RoleGroupNames } from "../../services/UserService";
import outstandingActionsUtilities, { AddEditFormData, CalculationRequest, itemTypes } from "./OutstandingActionsUtilities";
import OutstandingApprovalService, {
    BillableItemResponse,
    Status,
    BillableItemSubmitRequest
} from "../../services/OutstandingApprovalService";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";
import { LookupTypeItem } from "../../services/LookupService";
import AddEditPopUpService, { CalculationResponse, RaiseQueryFormData } from "../../services/AddEditPopUpService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import gridUtils from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";
import CardWrapper3Part from "../Cards/CardWrapper3Part";
import CardHeaderSection from "../Cards/CardSections/CardHeaderSection";
import CardCommentsSection from "../Cards/CardSections/CardCommentsSection";
import CardEquipmentSection from "../Cards/CardSections/CardEquipmentSection";
import { WithTranslation, withTranslation } from "react-i18next";
import GlobalService from "../../services/GlobalService";

// all of this info will be needed to populate a card
// not sure if status (late/on time) will come from db or be dervied here
interface EquipmentApprovalCardProps extends WithTranslation {
    outstandingCardData: BillableItemResponse;
    renderCards: (billableItemId: string, serviceTypeId: string) => void; // A callback to the card List component once the Shift has been accepted or Approved.
    totalNumber?: number;
    serialNumber?: number;
    configurationValueArray: any;
    isApproveAll?: boolean;
    cardSection?: string;
    accordionNo?: number;
    totalNonQueriedCost?: string;
    totalNonQueriedMinutes?: string;
    totalQueriedCost?: string;
    totalQueriedMinutes?: string;
    setCost: (currentProviderAmount: number,
        previousProviderAmount: number, cardSectionVal: string,
        currentBillableHours: string, previousBillableHours: string,
        acoordionNo: number, totalQueriedCost: string,
        totalNonQueriedCost: string, totalQueriedMinutes: string,
        totalNonQueriedMinutes: string, billableItemId: string, serviceTypeId: string,
        summaryBreakDownKey: number, navigatedFrom: string,
        paymentId: string, invoiceId: string, billableQuantity: string) => void;
    summaryBreakDownKey?: number;
}

interface EquipmentApprovalCardState {
    editViewDataItem: BillableItemResponse;
    convertedEditViewDataItem: AddEditFormData;
    disableBilableStartFinishFields: boolean;
    cardText: string;
    buttonText: string;
    queryButtonText: string;
    errorMessage: string;
    queryItem: RaiseQueryFormData;
    loadPanelVisible: boolean;
    equipmentText: string;
    webApprovalSecurityHeader: string;
    calculationErrorMessage: [];
    disableApproveButton: boolean;
    disablePlusButton: boolean;
    disableMinusButton: boolean;
    disableQueryButton: boolean;
    disableSaveButton: boolean;
    calculationText: string;
    isSuccssApproved?: boolean;
}

class EquipmentApprovalCard extends React.Component<EquipmentApprovalCardProps> {
    state: EquipmentApprovalCardState;
    outstandingApprovalService: OutstandingApprovalService;
    utils: outstandingActionsUtilities;
    addEditUtils: addEditPopupUtils;
    sharedUtils: sharedUtils;
    editViewService: AddEditPopUpService;
    gridUtils: gridUtils;
    //service that would post the data back to the server.
    constructor(props: EquipmentApprovalCardProps) {
        super(props);
        //Initialize services
        this.outstandingApprovalService = new OutstandingApprovalService();
        this.utils = new outstandingActionsUtilities();
        this.addEditUtils = new addEditPopupUtils();
        this.editViewService = new AddEditPopUpService();
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        //Initialize the service.
        this.outstandingApprovalService = new OutstandingApprovalService();
        // var convertedEditViewDataItem = this.utils.initializeBillableItem();
        var initializedBillableItem = this.utils.initializeBillableItem();
        var convertedQueryFormData = this.addEditUtils.initializeQueryFormData(
            this.props.outstandingCardData.billableItemId
                ? this.props.outstandingCardData.billableItemId.toString()
                : "",
            this.props.outstandingCardData.venueId,
            "",
            "Shift / Item Status Query",
            ""
        );
        this.state = {
            webApprovalSecurityHeader: "",
            editViewDataItem: this.props.outstandingCardData,
            convertedEditViewDataItem: initializedBillableItem,
            disableBilableStartFinishFields: false,
            cardText: "Please approve or raise a query if any of the equipment was not supplied or is not functioning",
            buttonText: "Approve",
            queryButtonText: "Raise Query",
            errorMessage: "",
            queryItem: convertedQueryFormData,
            loadPanelVisible: false,
            equipmentText: "Equipment supplied by",
            calculationErrorMessage: [],
            disableApproveButton: this.props.isApproveAll ? this.props.isApproveAll : false,
            disablePlusButton: false,
            disableMinusButton: false,
            disableQueryButton: false,
            disableSaveButton: false,
            calculationText: "",
            isSuccssApproved: false
        };
    }

    componentDidMount() {
        var convertedDataSource = this.utils.convertBillableItemResponeToFormData(this.state.editViewDataItem);
        this.setState({
            convertedEditViewDataItem: convertedDataSource,
        });

        this.disableRoleBasedFields(convertedDataSource.itemTypeId);
        this.enableDisableQueryApproveButton(convertedDataSource);
    }

    enableDisableQueryApproveButton = (billableItem: AddEditFormData) => {
        var isPayment: boolean = false;
        var isQueriedItemExist: boolean = false;
        if (billableItem && billableItem.paymentId) {
            isPayment = true;
        }
        if (billableItem.billableStatusLookupId == Status.Queried) {
            this.setState({
                disableQueryButton: true,
                queryButtonText: "Queried",
                disableApproveButton: true,
                disablePlusButton: true,
                disableMinusButton: true
            })
        }
        else if (billableItem.isAccrual && !billableItem.paymentId && !billableItem.approval_Date ||
            !billableItem.isAccrual && !billableItem.approval_Date && !billableItem.invoiceId) {
            this.setState({
                disableApproveButton: false
            });
        } else if (billableItem.approval_Date) {
            this.setState({
                disableApproveButton: true,
                disableSaveButton: true,
                buttonText: "Approved",
                disablePlusButton: true,
                disableMinusButton: true
            });
        } else {
            this.setState({
                disableApproveButton: true,
                disablePlusButton: true,
                disableMinusButton: true
            });
        }
        if (isPayment) {
            this.setState({
                disableApproveButton: true,
                disablePlusButton: true,
                disableMinusButton: true,
                disableSaveButton: true,
                buttonText: "Paid"
            })
        }
    }

    //Arrow functions doesn't need to be bind in the constructor, the context of "this" is set inside of them.
    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            convertedEditViewDataItem: {
                ...this.state.convertedEditViewDataItem,
                comments: dxValueChange.value,
            },
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
                serviceTypeId: this.state.editViewDataItem.serviceTypeId,
                clientId: this.state.editViewDataItem.clientId,
                venueId: this.state.editViewDataItem.venueId
            }
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
    };

    handleSubmit = () => {
        var isAmend: boolean = false;
        var isAbsent: string = "";
        var isNoCharge: string = "";
        var { itemTypeId, equipmentBillableQuantity, equipmentScheduleQuantity } = this.state.convertedEditViewDataItem;
        var isBillableFieldsBlank: boolean = true;
        var acceptanceDate = undefined;
        var approvalDate = undefined;
        var statusId: string = "";

        this.setState({
            loadPanelVisible: true,
        });

        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) || itemTypeId == "12" || itemTypeId == "14") {
            acceptanceDate = new Date();
            statusId = Status.Accepted;
        } else {
            approvalDate = new Date();
            statusId = Status.Approved;
        }

        if (itemTypeId == itemTypes.Original || itemTypeId == "") {
            if (equipmentBillableQuantity - equipmentScheduleQuantity != 0) {
                isAmend = true;
            }
        }

        var billableItemSubmitRequest = this.utils.convertFormDataToBillableItemRequest(
            this.state.convertedEditViewDataItem,
            statusId,
            isBillableFieldsBlank,
            acceptanceDate,
            approvalDate,
            isAbsent,
            isNoCharge,
            isAmend,
            "",
            "ApprovalCard"
        );

        var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
        billableItemSubmitRequestArr.push(billableItemSubmitRequest);

        this.outstandingApprovalService
            .uploadEditAddData(billableItemSubmitRequestArr)
            .then(this.handleSubmitSuccess)
            .catch((err) => {
                this.setState({
                    calculationErrorMessage:
                        err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of approval.
                    loadPanelVisible: false,
                });
                var respMessage: string = "uploadEditAddData failed with response: " + JSON.stringify(err);

                if (!this.outstandingApprovalService.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    };

    handleSave = () => {
        var isAmend: boolean = false;
        var isAbsent: string = "";
        var isNoCharge: string = "";
        this.setState({
            loadPanelVisible: true,
        });

        var billableItemSubmitRequest = this.utils.convertFormDataToBillableItemRequest(
            this.state.convertedEditViewDataItem,
            "",
            false,
            undefined,
            undefined,
            isAbsent,
            isNoCharge,
            isAmend,
            "",
            "ApprovalCard"
        );

        var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
        billableItemSubmitRequestArr.push(billableItemSubmitRequest);

        this.outstandingApprovalService
            .uploadEditAddData(billableItemSubmitRequestArr)
            .then(this.handleSaveSuccess)
            .catch((err) => {
                this.setState({
                    calculationErrorMessage:
                        err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of approval.
                    loadPanelVisible: false,
                });
                var respMessage: string = "uploadEditAddData failed with response: " + JSON.stringify(err);

                if (!this.outstandingApprovalService.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    }

    handleSaveSuccess = () => {
        this.hideLoadpanel();
    };

    handleSubmitSuccess = () => {
        this.setState({
            disableApproveButton: true,
            disableSaveButton: true,
            isSuccssApproved: true,
            buttonText: "Approved"
        });
        this.hideLoadpanel();
        this.props.renderCards(
            this.state.convertedEditViewDataItem.billableItemId,
            this.state.convertedEditViewDataItem.serviceTypeId
        );
    };

    disableRoleBasedFields = (itemTypeId: string) => {
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) || itemTypeId == "12" || itemTypeId == "14") {
            this.setState({
                webApprovalSecurityHeader: "Please accept the above changes or raise a query if required",
                buttonText: "Accept",
                equipmentText: "Equipment supplied to",
            });
        }
    };

    onSubmitQuery = () => {
        let querySubmissionError: boolean = true;
        querySubmissionError = this.sharedUtils.validationCheckFunction(this.state.convertedEditViewDataItem.comments);
        if (!querySubmissionError) {
            this.setState({
                errorMessage: this.props.t("approvalCard.weeklyCleaning.queryText.error"),
            });
        } else {
            this.setState({
                errorMessage: "",
                loadPanelVisible: true,
            });
            this.editViewService
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
        this.props.setCost(0,
            this.state.convertedEditViewDataItem.provider_Pay_Amount_Local_Ccy, "", "",
            this.props.cardSection ? this.props.cardSection : "",
            this.props.accordionNo ? this.props.accordionNo : 0,
            this.props.totalQueriedCost ? this.props.totalQueriedCost : "",
            this.props.totalNonQueriedCost ? this.props.totalNonQueriedCost : "",
            this.props.totalQueriedMinutes ? this.props.totalQueriedMinutes : "",
            this.props.totalNonQueriedMinutes ? this.props.totalNonQueriedMinutes : "",
            this.props.outstandingCardData.billableItemId,
            this.props.outstandingCardData.serviceTypeId,
            this.props.summaryBreakDownKey ? this.props.summaryBreakDownKey : 0, "calculation",
            this.state.editViewDataItem.paymentId,
            this.state.editViewDataItem.invoiceId, "");
    };

    handleQuerySubmissionSuccess = () => {
        this.setState({
            queryButtonText: "Queried",
            disableQueryButton: true,
            disableApproveButton: true

        });
        this.hideLoadpanel();
        this.props.renderCards(
            this.state.convertedEditViewDataItem.billableItemId,
            this.state.convertedEditViewDataItem.serviceTypeId
        );
    };

    handleQuerySubmissionFailure = (error: any) => {
        var respMessage: string =
            "uploadQueryFormData - Query submission failed with response: " + JSON.stringify(error);

        if (!this.editViewService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.hideLoadpanel();
    };

    hideLoadpanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    renderCustomData = (data: LookupTypeItem) => {
        return <div className="bg-warning font-weight-bold"> {data.value} </div>;
    };

    clearErrorMessage = () => {
        this.setState({
            errorMessage: "",
        });
    };

    handleChangeMinus = () => {
        this.setState({
            convertedEditViewDataItem: {
                ...this.state.convertedEditViewDataItem,
                equipmentQuantity: this.state.convertedEditViewDataItem.equipmentQuantity - 1,
                equipmentBillableQuantity: this.state.convertedEditViewDataItem.equipmentBillableQuantity - 1
            },
            calculationText: "Calculating...",
            disableApproveButton: true,
            disablePlusButton: true,
            disableMinusButton: true,
        }, () => this.financialCalculations());
    }

    handleChangePlus = () => {
        this.setState({
            convertedEditViewDataItem: {
                ...this.state.convertedEditViewDataItem,
                equipmentQuantity: this.state.convertedEditViewDataItem.equipmentQuantity + 1,
                equipmentBillableQuantity: this.state.convertedEditViewDataItem.equipmentBillableQuantity + 1
            },
            calculationText: "Calculating...",
            disableApproveButton: true,
            disablePlusButton: true,
            disableMinusButton: true,
        }, () => this.financialCalculations());

    }

    financialCalculations = () => {
        GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem);
        let calCulationObject: CalculationRequest = this.utils.constructCalculationObject(
            this.state.convertedEditViewDataItem
        );

        this.outstandingApprovalService
            .uploadFormData(calCulationObject)
            .then(this.handleCalculationSuccess)
            .catch((err) => {
                this.setState({
                    calculationErrorMessage:
                        err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    IsShowSuccess: false,
                });
            })
            .finally(() => {
                // this.hideLoadIndicator();
            });
    };

    handleCalculationSuccess = (response: AxiosResponse<any>) => {
        var serverResponse: CalculationResponse = response.data.data;
        const { billableMinutes } = response.data.data;
        const { scheduledMinutes, originalBillableMinutes, itemTypeId } = this.state.convertedEditViewDataItem;
        var extraBillableMinutes: number = 0;
        if (itemTypeId == itemTypes.Original || itemTypeId == itemTypes.Acceptance || itemTypeId == "") {
            extraBillableMinutes = this.sharedUtils.convertStringToFloat(billableMinutes) - scheduledMinutes;
        } else {
            extraBillableMinutes = parseFloat(billableMinutes);
        }

        var cardStatePostCalculation: AddEditFormData = this.utils.alterStatePostCalculation(
            this.state.convertedEditViewDataItem,
            serverResponse,
            []
        );
        var previousProviderPayAmountVal = this.state.convertedEditViewDataItem.provider_Pay_Amount_Local_Ccy;
        this.setState({
            convertedEditViewDataItem: cardStatePostCalculation,
            billableHoursAdjustment: extraBillableMinutes
                ? `${extraBillableMinutes ? (extraBillableMinutes > 0 ? "+" : "-") : ""
                }${this.utils.convertBillableMinutesToHoursFormat(Math.abs(extraBillableMinutes)).trim()}`
                : "0 Hrs",
            disableApproveButton: this.state.buttonText == "Approved" ? true : false,
            calculationText: "",
            disablePlusButton: false,
            disableMinusButton: false,
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
        this.props.setCost(cardStatePostCalculation.provider_Pay_Amount_Local_Ccy,
            previousProviderPayAmountVal, "", "",
            this.props.cardSection ? this.props.cardSection : "",
            this.props.accordionNo ? this.props.accordionNo : 0,
            this.props.totalQueriedCost ? this.props.totalQueriedCost : "",
            this.props.totalNonQueriedCost ? this.props.totalNonQueriedCost : "",
            this.props.totalQueriedMinutes ? this.props.totalQueriedMinutes : "",
            this.props.totalNonQueriedMinutes ? this.props.totalNonQueriedMinutes : "",
            this.props.outstandingCardData.billableItemId,
            this.props.outstandingCardData.serviceTypeId,
            this.props.summaryBreakDownKey ? this.props.summaryBreakDownKey : 0, "calculation",
            this.state.editViewDataItem.paymentId, this.state.editViewDataItem.invoiceId, "");
    };

    render() {
        var headerTextAsPerRole: string = "";
        var bodyTextAsPerRole: string = "";
        var equipmentHeaderText: string = "";
        var equipmentCommentText: string = "";
        var equipmentAdjustmentHeaderText: string = "";
        var equipmentAdjustmentCommentText: string = "";
        var equipmentAcceptanceHeaderText: string = "";
        var equipmentAcceptanceCommentText: string = "";
        if (this.props.configurationValueArray.length > 0) {
            equipmentHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalSecurityEquipment_Header;
            })[0].value;
            equipmentCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalSecurityEquipment_Comments;
            })[0].value;
            equipmentAdjustmentHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalSecurityEquipmentAdjustment_Header;
            })[0].value;
            equipmentAdjustmentCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalSecurityEquipmentAdjustment_Comments;
            })[0].value;
            equipmentAcceptanceHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebAcceptanceSecurityEquipmentAdjustment_Header;
            })[0].value;
            equipmentAcceptanceCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebAcceptanceSecurityEquipmentAdjustment_Comments;
            })[0].value;
        }
        const {
            equipmentQuantity,
            equipmentOriginalQuantity,
            provider,
            role,
            service,
            itemTypeId,
            equipmentBillableQuantity,
            venueName,
            venuePostCode,
            client,
            serviceSubTypeId
        } = this.state.convertedEditViewDataItem; //Destructuring the state variables.
        const startDate = this.utils.convertddmmyyyyStringToCustomizedFormat(this.state.editViewDataItem.date);
        const endDate = this.utils.convertddmmyyyyStringToCustomizedFormat(this.state.editViewDataItem.dateTo);
        var dateRangeField: string =
            startDate.localeCompare(endDate) == 0 ? `${startDate}` : `${startDate} to ${endDate}`;
        const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
        const isEventUser = UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) || UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
        var itemType: boolean =
            itemTypeId == itemTypes.Original || itemTypeId == "" || itemTypeId == itemTypes.Acceptance ? true : false;
        var equipmentNumber: string = `${equipmentQuantity} x ${role}`;
        var exceededEquipmentQuantity: string =
            this.state.convertedEditViewDataItem.equipmentBillableQuantity &&
                this.state.convertedEditViewDataItem.equipmentBillableQuantity > 0
                ? `+${equipmentBillableQuantity}`
                : `${equipmentBillableQuantity}`;


        var venue: string = "";
        if (venueName && venuePostCode) {
            venue = venueName + "," + venuePostCode;
        }
        else if (venueName) {
            venue = venueName;
        }
        else if (venuePostCode) {
            venue = venuePostCode;
        }


        if (isVenueManager) {
            if (itemType) {
                headerTextAsPerRole = equipmentHeaderText;
                bodyTextAsPerRole = equipmentCommentText;
            } else {
                headerTextAsPerRole = equipmentAdjustmentHeaderText;
                bodyTextAsPerRole = equipmentAdjustmentCommentText;
            }
        } else {
            headerTextAsPerRole = equipmentAcceptanceHeaderText;
            bodyTextAsPerRole = equipmentAcceptanceCommentText;
        }

        return (
            <>
                <CardWrapper3Part
                    type="security"
                    typeTitle={service}
                    enumeration={true}
                    cardIndex={this.props.serialNumber}
                    cardsTotal={this.props.totalNumber}
                    header={
                        <CardHeaderSection
                            date={dateRangeField}
                            message={headerTextAsPerRole ? headerTextAsPerRole : ""}
                            adjustment={itemType}
                            errorMessage={this.state.errorMessage}
                            clearErrorMessage={this.clearErrorMessage}
                            calculationErrorMessage={this.state.calculationErrorMessage}
                            venue={venue}
                            title={isVenueManager || isEventUser ? provider : client}
                            provider={provider}
                            serviceSubTypeId={serviceSubTypeId}
                            client={client}
                        />
                    }
                    body={
                        <CardEquipmentSection
                            equipmentNumber={equipmentNumber}
                            equipmentQuantity={equipmentQuantity}
                            exceededEquipmentQuantity={exceededEquipmentQuantity}
                            adjustment={itemType}
                            isVenueManager={isVenueManager}
                            equipmentOriginalQuantity={equipmentOriginalQuantity}
                            equipmentBillableQuantity={equipmentBillableQuantity}
                            type={role}
                            providerPayAmount={this.sharedUtils.thousandsSeparator(this.state.convertedEditViewDataItem.provider_Pay_Amount_Local_Ccy.toString())}
                            handleChangeMinus={this.handleChangeMinus}
                            handleChangePlus={this.handleChangePlus}
                            calculationText={this.state.calculationText}
                            disablePlusButton={this.state.disablePlusButton}
                            disableMinusButton={this.state.disableMinusButton}
                        />
                    }
                    footer={
                        // ToDo: wire up comments section, currently it looks good, but doesnt work
                        <CardCommentsSection
                            message={bodyTextAsPerRole ? bodyTextAsPerRole : ""}
                            comments={this.state.convertedEditViewDataItem.comments}
                            handleChangeComments={this.handleChangeComments}
                            buttonText={this.state.buttonText}
                            queryButtonText={this.state.queryButtonText}
                            onSubmitQuery={this.onSubmitQuery}
                            handleSubmit={this.handleSubmit}
                            handleSave={this.handleSave}
                            disableApproveButton={this.state.disableApproveButton}
                            disableQueryButton={this.state.disableQueryButton}
                            disableSaveButton={this.state.disableSaveButton}
                        />
                    }
                />
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
            </>
        );
    }
}

export default withTranslation()(EquipmentApprovalCard);
