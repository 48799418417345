import React from "react";
import BillingCycleManagePage from "./BillingPageV2";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";

interface BillingPageV2ContainerProps extends RouteProps, withValidUserRoleProps {}

const BillingPageV2Container = (props: BillingPageV2ContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return <BillingCycleManagePage title={getTitle()} category={getType()} />;
};

export default withValidUserRole(BillingPageV2Container);
