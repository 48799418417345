import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

class DesignSystemNavigation extends Component<RouteComponentProps> {
    constructor(props: RouteComponentProps) {
        super(props);
    }

    render() {
        return (
            <>
                <h5>Low Level Elements</h5>
                <ul>
                    <li>
                        <Link to="/design-system/components">Components</Link>
                    </li>
                </ul>

                <h5>Components</h5>
                <ul>
                    <li>
                        <Link to="/design-system/buttons">Buttons</Link>
                    </li>
                    <li>
                        <Link to="/design-system/BillingStatusFlow">Billing Status Flow</Link>
                    </li>
                    <li>
                        <Link to="/design-system/PaymentStatusFlow">Payment Status Flow</Link>
                    </li>
                    <li>
                        <Link to="/design-system/VenueLookUpDesign">Venue Look Up Component Design</Link>
                    </li>
                    <li>
                        <Link to="/design-system/VirtualScrollingDemo">Virtual Scrolling (Grid) Demo</Link>
                    </li>                    
                    <li>
                        <Link to="/design-system/ShiftWorkerLookupDemo">Shift Worker Lookup Demo</Link>
                    </li>
                    <li>
                        <Link to="/design-system/MatchedPopUpCustomizedGrid">Coloured block grid</Link>
                    </li>
                    
                </ul>
                <h5>General Layouts</h5>
                <ul>
                    <li>
                        <Link to="/design-system/fixedSidePanelDesign">Fixed Side Panel Design</Link>
                    </li>
                    <li>
                        <Link to="/design-system/collapsibleSidePanelDesign">Collapsible Side Panel Design</Link>
                    </li>
                    <li>
                        <Link to="/design-system/fixedOnDesktopSidePanelDesign">
                            Fixed On Desktop Side Panel Design
                        </Link>
                    </li>
                    <li>
                        <Link to="/design-system/fullBleedSidePanelDesign">Full Bleed Side Panel Design</Link>
                    </li>
                    <li>
                        <Link to="/design-system/formDesign">Forms</Link>
                    </li>
                </ul>
                <h5>Page-Specific Layouts</h5>
                <ul>
                    <li>
                        <Link to="/design-system/landingPage">Landing Page</Link>
                    </li>
                    <li>
                        <Link to="/design-system/artistProfile">Artist Profile</Link>
                    </li>
                    <li>
                        <Link to="/design-system/artistSignup">Artist Signup</Link>
                    </li>
                    <li>
                        <Link to="/design-system/entertainerLandingPage">Entertainer Landing Page</Link>
                    </li>
                    <li>
                        <Link to="/design-system/findAnArtistLandingPage">Find An Artist Landing Page</Link>
                    </li>
                    <li>
                        <Link to="/design-system/pendingRequestPage">Pending Request Page</Link>
                    </li>
                    <li>
                        <Link to="/design-system/manageShiftsDesign">Manage Shifts Page</Link>
                    </li>
                    <li>
                        <Link to="/design-system/manageShiftsDesignAsPopUp">Manage Shifts Page (in a pop-up)</Link>
                    </li>
                </ul>
            </>
        );
    }
}

export default withRouter(DesignSystemNavigation);
