import React, { useEffect, useState } from "react";
import { SelectBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import { BaseApiService } from "../../services/BaseApiService";
import LookupService, {  LookupTypeItem } from "../../services/LookupService";
import { SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import SelectAndLookUpBoxUtils from "./SelectAndLookUpBoxUtils";

/**
 * Props
 */
interface SelectBoxFromLookupTableDynamicProps {
    /** The data this lookup should display */
    lookupTypeIndex: string;
    /** Callback to fire on user selection */
    onValueChanged: (e: SelectBoxOnValueChangedEvent) => void;
    /** Regardless of internal rules, disable the control */
    isDisabled?: boolean;
    /** The root lookup will not have a parentMappingId but should still be enabled (unlike child lookups) */
    isRoot: boolean;
    /** Filter by this Id (props.parentMappingId === lookup.parentMappingId) */
    parentMappingId: string;
    /** Set as the selected value */
    value?: string; 
    /** Showing active and inactive records in dropdowns */ 
     recordType?: string;  
     isReadOnly?: boolean;
}

/**
 * Component - Localizes state for a drop down control.
 *
 * - Copied from LookupTypeList
 * - DRY alert!  Why not update existing component?
 *  - Current component has been used a lot and updating it everywhere is too time consuming
 */
const SelectBoxFromLookupTableDynamic: React.FC<SelectBoxFromLookupTableDynamicProps> = (props) => {
    // Initialize state
    const [state, setResult] = useState<BaseApiService<LookupTypeItem>>({
        status: "loading",
    });
    // Initialize services
    const service = new LookupService();
    const selectAndLookUpBoxUtils = new SelectAndLookUpBoxUtils();

    /**
     * Load data on component load
     */
    useEffect(() => {
        if(props.recordType && props.recordType == "all") {
            
            selectAndLookUpBoxUtils.executeLookup(service.getLookupByLookupTypeIndexAll, props.lookupTypeIndex, 3, handleSuccess, handleError);
            }
        else {
            selectAndLookUpBoxUtils.executeLookup(service.getLookupByLookupTypeIndex, props.lookupTypeIndex, 3, handleSuccess, handleError);
             }
    
    }, []);

    /**
     * Update state with data source from server
     *
     * @param response {AxiosResponse<any>} - server response
     * @returns {void}
     */
    const handleSuccess = (response: AxiosResponse<any>): void => {
        setResult({
            status: "loaded",
            payload: selectAndLookUpBoxUtils.filterDataSource(response.data.data, props.parentMappingId),
        });
    };

    /**
     * Capture error from server
     *
     * @param error {any} - error resolved by promise
     * @returns {void}
     */
    const handleError = (error: any): void => {
        setResult({
            status: "error",
            error,
        });

        var respMessage: string =
            `Lookup Type ${props.lookupTypeIndex} data load failed with response: ` + JSON.stringify(error);

        if (!service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    return (
        <div id="lookupTypeListComponentId">
            {state.status === "loading" && <span>Loading...</span>}
            {state.status === "loaded" && (
                <SelectBox
                    dataSource={selectAndLookUpBoxUtils.filterDataSource(state.payload, props.parentMappingId)}
                    displayExpr={"value"}
                    valueExpr={"id"}
                    onValueChanged={props.onValueChanged}
                    disabled={
                        props.isDisabled
                            ? props.isDisabled
                            : selectAndLookUpBoxUtils.isDisabledRules(state.status, props.parentMappingId, props.isRoot)
                    }
                    value={props.value}
                    readOnly ={props.isReadOnly}
                />
            )}
            {state.status === "error" && <SelectBox dataSource={[]} />}
        </div>
    );
};

export default SelectBoxFromLookupTableDynamic;
