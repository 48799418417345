import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import BillingStatusFlow from "../components/Billing/BillingStatusFlow";
import { confirm } from "devextreme/ui/dialog";
import { BillingStatus } from "../components/Billing/BillingFormUtils";
import { CheckBox } from "devextreme-react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface BillingStatusFlowDesignProps extends RouteComponentProps {}

interface BillingStatusFlowDesignState {
    status: BillingStatus;
}

class BillingStatusFlowDesign extends Component<BillingStatusFlowDesignProps> {
    state: BillingStatusFlowDesignState;
    constructor(props: BillingStatusFlowDesignProps) {
        super(props);
        this.state = {
            status: BillingStatus.Draft,
        };
    }

    onPendingValueChanged = () => {
        this.setState({
            status: BillingStatus.Pending,
        });
    };

    onReadyForBillingValueChanged = () => {
        this.setState({
            status: BillingStatus.ReadyForBilling,
        });
    };

    onBillCheckedValueChanged = () => {
        this.setState({
            status: BillingStatus.BillChecked,
        });
    };

    onBillPackRequestedValueChanged = () => {
        this.setState({
            status: BillingStatus.BillPackRequested,
        });
    };

    onBillPackReadyValueChanged = () => {
        this.setState({
            status: BillingStatus.BillPackReady,
        });
    };

    onBillPackFailedValueChanged = () => {
        this.setState({
            status: BillingStatus.BillPackFailed,
        });
    };

    onBillPackSentValueChanged = () => {
        this.setState({
            status: BillingStatus.BillPackSent,
        });
    };

    onBillDefaultValueChanged = () => {
        this.setState({
            status: BillingStatus.BillDefault,
        });
    };

    onBillQueriedValueChanged = () => {
        this.setState({
            status: BillingStatus.BillQueried,
        });
    };

    onBillPaidValueChecked = () => {
        this.setState({
            status: BillingStatus.BillPaid,
        });
    };
    onRestartClicked = () => {
        confirm("Restart?", "Restart").then((hasConfirmed) => {
            if (hasConfirmed) {
                this.setState({
                    status: BillingStatus.Draft,
                });
            }
        });
    };
    onRefreshBillingStatusClicked = () => {};
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>{" "}
                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <h2>
                            <b>Billing - Edit Invoice</b>
                            <button
                                className="status-flow__refresh-button btn icon-btn"
                                aria-label="Refresh the current billing status"
                                onClick={this.onRefreshBillingStatusClicked}
                            >
                                <FontAwesomeIcon icon={faSync} />
                            </button>
                        </h2>
                    </header>

                    <div>
                        <BillingStatusFlow
                            statusId={""}
                            onRestartClicked={this.onRestartClicked}
                            isRestartDisable={false}
                        />
                    </div>
                    <div className="row mt-5">
                        <label className="col-8 col-sm-4">Pending</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.Pending}
                                onValueChange={this.onPendingValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">ReadyForBilling</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.ReadyForBilling}
                                onValueChanged={this.onReadyForBillingValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">BillChecked</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.BillChecked}
                                onValueChanged={this.onBillCheckedValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">BillPackRequested</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.BillPackRequested}
                                onValueChanged={this.onBillPackRequestedValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">BillPackReady</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.BillPackReady}
                                onValueChanged={this.onBillPackReadyValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">BillPackFailed</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.BillPackFailed}
                                onValueChanged={this.onBillPackFailedValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">BillPackSent</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.BillPackSent}
                                onValueChanged={this.onBillPackSentValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">BillDefault</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.BillDefault}
                                onValueChanged={this.onBillDefaultValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">BillQueried</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.BillQueried}
                                onValueChanged={this.onBillQueriedValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">BillPaid</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == BillingStatus.BillPaid}
                                onValueChanged={this.onBillPaidValueChecked}
                            />
                        </div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(BillingStatusFlowDesign);
