import React from "react";
import UserService, { RoleGroupNames } from "../../../services/UserService";
import sharedUtils from "../../grid/sharedUtilities";

export interface CostCellProps {
    data: any;
}

const delimeter = "&";

export interface CostCellState {
    costCellValues: string[];
}

class CostCell extends React.Component<CostCellProps> {
    state: CostCellState;
    sharedUtils: sharedUtils;
    constructor(props: CostCellProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.convertStringToArray = this.convertStringToArray.bind(this);

        this.state = {
            costCellValues: this.convertStringToArray(this.props.data.data.cost),
        };
    }

    splittingCost = (element: string) => {
        var splitByDash : string[] = [];
        var formattedPayAmount : string = "";
        if (element) {
            splitByDash = element.split(/-(.+)/);
            if (splitByDash[1]) {
                formattedPayAmount = this.sharedUtils.thousandsSeparator(splitByDash[1]);
            }
        }
        return splitByDash[0] + " - " + formattedPayAmount;
    };

    convertStringToArray(cellValueAsString: string): string[] {
        var values: string[] = [];
        if (cellValueAsString && cellValueAsString.length > 0) {
            if (cellValueAsString.includes(delimeter)) {
                var parts = cellValueAsString.split(delimeter);
                parts.forEach((element) => {
                    var formattedElement = this.splittingCost(element);
                    values.push(formattedElement.trim());
                });
            } else {
                values.push(cellValueAsString);
            }
        }
        return values;
    }

    render() {
        let venueCostOthers = false;
        let providerPayCost = false;
        if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            venueCostOthers = true;
        }
        if (
            UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ||
            UserService.isUserInGroup(RoleGroupNames.Artist)
        ) {
            providerPayCost = true;
        }
        return (
            <div className="container-fluid">
                {venueCostOthers
                    ? this.state.costCellValues[0]
                    : providerPayCost
                    ? this.state.costCellValues[0]
                    : this.state.costCellValues.map((item: any, uniqueKey: number) => {
                          return (
                              <div key={uniqueKey} className="row">
                                  {item}
                              </div>
                          );
                      })}
            </div>
        );
    }
}

export default CostCell;
