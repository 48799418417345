import { AxiosError, AxiosResponse } from "axios";
import { _get } from "./BaseApiService";

// Endpoints
const USER_ROLES_API = "group";

export interface operatingServiceList {
    value: string;
    id: string;
    parentMappingId: string;
}
// User roles response
interface userRolesResponse extends AxiosResponse {
    eventUKGroup: string[];
    nonEventUKGroup: string[];
    operatingServiceList: operatingServiceList[];
    webAccessKey: string | null;
}

// Stores the current user's roles
interface userInfo {
    groups: string[];
    opearingService: operatingServiceList[];
    webAccessKey: string | null;
}

// Error messages
export interface errorMessages {
    columnName: string;
    errorType: null;
    errorMessage: string;
}

export const NO_AUTHORIZATION = [
    {
        columnName: "Autherror_Header1",
        errorType: null,
        errorMessage: "User has no authorization to this application or is logged in with multiple accounts.",
    },
    {
        columnName: "Autherror_Header2",
        errorType: null,
        errorMessage:
            "Please log out of your other accounts or use an 'InPrivate' or 'incognito' window and try again.",
    },
];

// Role names to compare against the current user's role provided by the server
// This information cannot be retrieved from the user's JWT.
export const RoleGroupNames = {
    EventUKRelationshipManager: "Event UK Relationship Manager",
    EventUKSeniorManager: "Event UK Senior Manager",
    ProviderScheduler: "Provider Scheduler",
    ProviderSiteManager: "Provider Site Manager",
    VenueManager: "Venue Manager",
    HeadDoorSupervisor: "Head Door Supervisor",
    AreaManager: "Area Manager",
    RegionalBusinessManager: "Regional Business Manager",
    OperationsManager: "Operations Manager",
    OperationsDirector: "Operations Director",
    DivisionalDirector: "Divisional Director",
    ManagingDirector: "Managing Director",
    Worker: "Worker",
    Artist: "Artist",
};

export const OperatingServiceNames = {
    Security: "10",
    ContractCleaing: "20",
    Entertainment: "30",
    Miscelleanous: "80",
    Gardening: "40",
    WindowCleaning: "50",
    Maintenance: "60"
};

// User Service - Helper service to track user information that cannot be retrieved from the JWT
const UserService = (function () {
    var currentUser: userInfo = { groups: [], opearingService: [], webAccessKey: "" };

    // Compare 'roleGroupName' to current user's role
    const isUserInGroup = function (roleGroupName: string) {
        var isUserInGroup = false;

        if (currentUser.groups && currentUser.groups.length > 0) {
            isUserInGroup = currentUser.groups.includes(roleGroupName);
        }

        return isUserInGroup;
    };

    const isOperatingServicePresent = function (operatingServiceName: string) {
        var isOperatingServiceFound = false;

        if (currentUser.opearingService && currentUser.opearingService.length > 0) {
            var operatingService: operatingServiceList | undefined = currentUser.opearingService.find(
                (item) => item.id === operatingServiceName
            );
            if (operatingService) {
                isOperatingServiceFound = true;
            }
        }

        return isOperatingServiceFound;
    };

    const isUserInAnyGroup = function (roleGroupNameList: string[]) {
        var isUserInAnyGroup = false;
        var roleGroupNameListIndex;

        for (roleGroupNameListIndex = 0; roleGroupNameListIndex < roleGroupNameList.length; roleGroupNameListIndex++) {
            if (isUserInGroup(roleGroupNameList[roleGroupNameListIndex])) {
                isUserInAnyGroup = true;
                break;
            }
        }

        return isUserInAnyGroup;
    };

    // Retrieve the user's roles from server
    const getUserRoles = function (): Promise<AxiosResponse<any>> {
        return _get(USER_ROLES_API);
    };

    // Set the user's role
    const setUserRoles = async function () {
        return getUserRoles().then(handleSuccess).catch(handleError);
    };

    const handleSuccess = (response: AxiosResponse<any>) => {
        localStorage.setItem("ForbiddenError", "");
        if(response && response.data && response.data.data) {
            let data: userRolesResponse = response.data.data;
            currentUser.groups = data.eventUKGroup;
            currentUser.opearingService = data.operatingServiceList;
            currentUser.webAccessKey = data.webAccessKey;
        }
        else {
            return NO_AUTHORIZATION;
        }
    };

    const handleError = (error: AxiosError<any>) => {
        if (error && error.response && error.response.data) {
            return error.response.data.error;
        } 
        else if (error && error.message) {
            localStorage.setItem("ForbiddenError", "true");
            if (typeof window !== 'undefined') {
                window.location.href = "/";
           }
            return JSON.parse(error.message);
        } 
        else {
            return NO_AUTHORIZATION;
        }
    };

    const userHasARole = function (): boolean {
        return currentUser.groups.length > 0;
    };

    const userHasNotKey = function (): boolean {
        return currentUser.webAccessKey == "" || currentUser.webAccessKey == null;
    };

    //A function that would construct the serviceType data source based on the returned response from the group API.
    const operatingServiceList = function (): operatingServiceList[] {
        var serviceList: operatingServiceList[] = [];
        var doorSupervisionObject: operatingServiceList = {
            id: "10",
            value: "Door Supervision",
            parentMappingId: "0",
        };
        if (currentUser.opearingService && currentUser.opearingService.length > 0) {
            currentUser.opearingService.forEach((item) => {
                if (item.id === OperatingServiceNames.Security) {
                    serviceList.push(doorSupervisionObject);
                } else {
                    serviceList.push(item);
                }
            });
        }

        return serviceList;
    };

    return {
        userHasARole: userHasARole,
        userHasNotKey: userHasNotKey,
        isUserInGroup: isUserInGroup,
        isOperatingServicePresent: isOperatingServicePresent,
        isUserInAnyGroup: isUserInAnyGroup,
        setUserRoles: setUserRoles,
        currentUser: currentUser,
        operatingServiceList: operatingServiceList,
    };
})();

export default UserService;
