import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, SelectBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes, LookupTypeItem, VenueLookupTypeItem } from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import { LookUpOnValueChangedEvent, SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import QueryService from "../../services/QueryService";
import queryUtils from "./QueryUtils";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";

interface VenueFilterPanelProps extends WithTranslation {
    onApplyButtonClick: (clientId: string, venueId: string) => void;
    serviceTypeId?: string;
    status?: string;
    displayText?: boolean;
}

interface VenueFilterPanelState {
    clientId: string;
    venueId: string;
    venueList: VenueLookupTypeItem[];
    venueDataLoaded: boolean;
    singleVenue: boolean;
    venueListByClient: VenueLookupTypeItem[];
    clientDataSource: LookupTypeItem[];
}

class VenueFilterPanel extends Component<VenueFilterPanelProps> {
    lookupService: LookupService;
    state: VenueFilterPanelState;
    utils: queryUtils;
    sharedUtils: sharedUtils;
    Service: QueryService;

    constructor(props: VenueFilterPanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new QueryService();
        this.sharedUtils = new sharedUtils();
        this.utils = new queryUtils();

        this.state = {
            clientId: "",
            venueId: "",
            venueList: [],
            venueDataLoaded: false,
            singleVenue: true,
            venueListByClient: [],
            clientDataSource: [],
        };
    }

    //Retrieve the lookup values inside the mount
    componentDidMount() {
        this.retrieveLookupValues(LookupTypeIndexes.clientType);
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleClientLookupSuccess)
                    .catch((error) => { });
                break;
            case LookupTypeIndexes.venueType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                    .then(this.handleVenueLookupSuccess)
                    .catch((error) =>
                        this.setState({
                            venueDataLoaded: true,
                            venueListByClient: [],
                        })
                    );
                break;
        }
    };

    handleClientLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            if (response.data.data.length == 0) {
                this.onApplyButtonClicked();
            } else {
                var clientData: LookupTypeItem[] = response.data.data;
                this.setState(
                    {
                        clientId: clientData[0].id,
                        clientDataSource:
                            response.data && response.data.data
                                ? this.utils.lookUpincludeAll(response.data.data)
                                : [],
                    },
                    () => this.retrieveLookupValues(LookupTypeIndexes.venueType)
                );
            }
        }
    };

    handleVenueLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            var venues: VenueLookupTypeItem[] = response.data.data;
            var venueListPerClient: VenueLookupTypeItem[] = this.utils.extractVenueListBasedOnClient(
                venues,
                this.state.clientId
            );
            var singleVenue: boolean = response.data.data.length == 1 ? true : false;
            this.setState(
                {
                    venueDataLoaded: true,
                    singleVenue: singleVenue,
                    venueId: venueListPerClient.length > 0 && venueListPerClient[1] ? venueListPerClient[1].id : "",
                    venueListByClient: venueListPerClient,
                    venueList: venues,
                }, () => this.onApplyButtonClicked()
            );
        }
    };

    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var entireVenueList: VenueLookupTypeItem[] = this.state.venueList;
        var venueDataByClientId: VenueLookupTypeItem[] = this.utils.extractVenueListBasedOnClient(
            entireVenueList,
            dxValueChange.value
        );
        this.setState({
            clientId: dxValueChange.value,
            venueListByClient: venueDataByClientId,
            venueId: "",
        }); //When a client dropdown is changed, initiate the API call, and default the VenueID to "", because the State is still holding the previously selected VenueId.
    };

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            venueId: dxValueChange.value,
        });
    };

    componentDidUpdate(prevProps: VenueFilterPanelProps, prevState: VenueFilterPanelState) {
        var { clientDataSource, venueListByClient } = this.state;
        if (
            this.props.serviceTypeId != prevProps.serviceTypeId ||
            (this.props.status && this.props.status != prevProps.status)
        ) {
            var venueListPerClient: VenueLookupTypeItem[] = this.utils.extractVenueListBasedOnClient(
                this.state.venueList,
                this.state.clientDataSource.length > 0 ? this.state.clientDataSource[1].id : ""
            );
            this.setState(
                {
                    clientId: clientDataSource && clientDataSource.length > 0 ? clientDataSource[1].id : "",
                    venueId: venueListPerClient && venueListPerClient.length > 0 ? venueListPerClient[1].id : "",
                },
                () => this.onApplyButtonClicked()
            );
        }
    }

    //Fire the parent component's callback on an Apply click.
    onApplyButtonClicked = () => {
        var { clientId, venueId } = this.state;
        this.props.onApplyButtonClick(
            clientId && clientId != "0" ? clientId : "",
            venueId && venueId != "0" ? venueId : ""
        );
    };

    render() {
        var { displayText } = this.props;
        var { singleVenue } = this.state;
        return (
            <div>
                {!singleVenue ? (
                    <>
                        <div className="row mt-4">
                            <div className="col-10">
                                <h4 className="sidebar__heading font-weight-bold">
                                    {displayText ? "Filter" : "Filter queries"}
                                </h4>
                            </div>
                        </div>
                        <>
                            {" "}
                            <div className="row mt-3">
                                <div className="col sidebar__heading">Client</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    {this.state.venueDataLoaded ? (
                                        <SelectBox
                                            dataSource={this.state.clientDataSource}
                                            displayExpr={"value"}
                                            valueExpr={"id"}
                                            value={this.state.clientId}
                                            onValueChanged={this.handleChangeClientTypeSelect}
                                        />
                                    ) : (
                                        <span> Loading... </span>
                                    )}{" "}
                                </div>
                            </div>
                        </>
                        <>
                            {" "}
                            <div className="row mt-3">
                                <div className="col sidebar__heading">Venue</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    {this.state.venueDataLoaded ? (
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.venueType}
                                            onValueChanged={this.handleChangeVenueTypeSelect}
                                            isRoot={false}
                                            parentMappingId={this.state.clientId}
                                            value={this.state.venueId}
                                            displayExpression={"value"}
                                            itemAll={true}
                                        />
                                    ) : (
                                        <span> Loading... </span>
                                    )}{" "}
                                </div>
                            </div>
                        </>
                        <div className="row mt-4">
                            <div className="col-6 mx-auto">
                                <Button
                                    className="btn--link btn btn-primary btn--large"
                                    disabled={false}
                                    onClick={this.onApplyButtonClicked}
                                >
                                    Apply
                                </Button>
                            </div>
                        </div>{" "}
                    </>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}

export default withTranslation()(VenueFilterPanel);
