import React from "react";
import ManageViewShiftPage from "./ManageViewShiftPage";
import { PeriodGridTypeParams } from "../../services/SimpleGridService";
import ManageShiftGlobalFilterService from "../../services/ManageShiftGlobalFilterService";

type ManageViewShiftPageContainerProps = {
    location: { search: string; pathname: string; state: any };
};

const ManageViewShiftPageContainer = (props: ManageViewShiftPageContainerProps) => {
    const getTitle = () => {
        const path = props.location.pathname;
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location.pathname;
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };
    let billableId: any;
    const searchparam = new URLSearchParams(props.location.search.toLowerCase());

    if (props.location.search) {
        billableId = searchparam.get("billableitemid");
    }

    let clientId: any;
    if (props.location.search) {
        clientId = searchparam.get("clientid");
    }
    let venueId: any;
    if (props.location.search) {
        venueId = searchparam.get("venueid");
    }
    let shiftStart: any;
    if (props.location.search) {
        shiftStart = searchparam.get("shiftstart");
    }
    let shiftEnd: any;
    if (props.location.search) {
        shiftEnd = searchparam.get("shiftend");
    }
    let dateFrom: any;
    if (props.location.search) {
        dateFrom = searchparam.get("datefrom");
        ManageShiftGlobalFilterService.setDateFromValue(dateFrom);
    }
    let dateTo: any;
    if (props.location.search) {
        dateTo = searchparam.get("dateto");
        ManageShiftGlobalFilterService.setDateToValue(dateTo);
    }
    let isSchedulePage: any;
    if (props.location.search) {
        isSchedulePage = searchparam.get("isschedulepage");
    }
    let periodGridTypeParms: PeriodGridTypeParams = {
        serviceTypeId: "",
        periodNumber: "",
        periodWeek: "",
    };
    if (props.location.search) {
        periodGridTypeParms.serviceTypeId = searchparam.get("servicetypeid") || "";
        periodGridTypeParms.periodNumber = searchparam.get("periodnumber") || "";
        periodGridTypeParms.periodWeek = searchparam.get("periodweek") || "";
    }
    let filterTextFromHeader: any;
    let isHeader: any;
    return (
        <ManageViewShiftPage
            title={getTitle()}
            category={getType()}
            billableItemId={billableId}
            clientId={clientId}
            venueId={venueId}
            shiftStart={shiftStart}
            shiftEnd={shiftEnd}
            isSchedulePage={isSchedulePage}
            periodGridTypeParams={periodGridTypeParms}
            filterTextFromHeader={filterTextFromHeader}
            isHeader={isHeader}
        />
    );
};

export default ManageViewShiftPageContainer;
