import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError, _put } from "./BaseApiService";

// Endpoints
const EDIT_VIEW_API_URL = "BillableItem";
const RAISE_QUERY_API = "Query";
const CONFIRM_GIG_API = "BillableItem/Entertainment/Confirm";
const SUBMIT_FEEDBACK = "BillableItem/entertainment/feedback";

export interface EntertainmentCardResponse {
    billableItemId: string;
    providerId: string;
    date: string;
    dateTo: string;
    provider_Pay_Amount_Local_Ccy: string;
    service: string;
    shift_End: string;
    shift_Start: string;
    status: string;
    venueName: string;
    venuePostCode: string;
    venueTownCity: string;
    address: string;
    venue_Phone: string;
    performanceInformation: string;
    genre: string;
    reoccurance: string;
    feedbackScore: string;
    feedbackComments: string;
    businessEntityId: string;
    feedbackType: string;
}

export interface EntertainMentCardRequest {
    billableItemId: string;
    feedbackScore: string;
    feedbackComments: string;
    businessEntityId: string;
    feedbackType: string;
}

/**
 * REST services for the BillableItem resource.
 */
class EntertainmentCompletionCardService {
    // Retrieve all rows for the grid based on Id of row clicked on parent grid
    getCompletionCardData(id: number): Promise<AxiosResponse<any>> {
        return _get(`${EDIT_VIEW_API_URL}/${id}`);
    }
    // Complete Gigs API
    submitCompletionOfGigs(id: number): Promise<AxiosResponse<any>> {
        return _put(`${CONFIRM_GIG_API}/${id}`);
    }

    //submit feedback API
    submitFeedBack(data: object): Promise<AxiosResponse<any>> {
        return _post(SUBMIT_FEEDBACK, data);
    }

    //Post the query to the server.
    uploadQueryFormData(data: object): Promise<AxiosResponse<any>> {
        return _post(RAISE_QUERY_API, data);
    }
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default EntertainmentCompletionCardService;
