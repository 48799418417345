import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import TodayCard from "../components/Today/TodayCard";
import VenueCard from "../components/Venue/VenueCard";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface LandingPageDesignProps extends RouteComponentProps {}

class LandingPageDesign extends Component<LandingPageDesignProps> {
    constructor(props: LandingPageDesignProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e: { value: any }) {
        this.setState({
            type: e.value,
        });
    }
    setDate = () => {};
    customizePoint = (series: { argument: string }) => {
        return series.argument.toLowerCase() === "budget"
            ? { color: "url(#BudgetGradient)" }
            : { color: "url(#SpendGradient)" };
    };
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <section className="col">
                        <div className="row mb-lg-5">
                            <div className="col-12 col-lg-5 mb-4 mb-lg-0">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <TodayCard clientId="" venueId="" isDashBoardDataLoaded={false} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-7 mb-4 mb-lg-0">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <VenueCard isDashBoardDataLoaded={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(LandingPageDesign);
