import React from "react";

interface CardAdditionalCleaningSectionProps {
    otherDescription: string;
    displayRate: string;
    additionalCleaningText: string;
}

class CardAdditionalCleaningSection extends React.Component<CardAdditionalCleaningSectionProps> {
    constructor(props: CardAdditionalCleaningSectionProps) {
        super(props);
    }

    render() {
        var { otherDescription, displayRate, additionalCleaningText } = this.props;
        return (
            <div className="text-center">
                <div className="font-weight-bold">{otherDescription}</div>
                <div className="font-weight-bold mt-3">{`${displayRate}`}</div>
                <div className="mt-3">Authorised by {additionalCleaningText}</div>
            </div>
        );
    }
}
export default CardAdditionalCleaningSection;
