import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faChevronUp,
    faDoorOpen,
    faVacuum,
    faMicrophoneStand,
    faFlowerTulip,
    faWrench,
    faRectanglesMixed,
    faSoap
} from "@fortawesome/pro-regular-svg-icons";
import "../../../assets/images/logo-icon-white-landscape.svg";
import UserService, { OperatingServiceNames, RoleGroupNames } from "../../services/UserService";
import { closeDrawer, viewCostReportForService, viewSimpleShiftsForService } from "./NavigationUtils";
import { renderEventUKMainNavigationItems, renderEventUKCleaningNavigationItems, 
    renderEventUKDoorSupervisionNavigationItems, renderEventUKEntertainmentNavigationItems, 
    renderEventUKGardeningNavigationItems, renderEventUKMaintenanceNavigationItems, 
    renderEventUKMiscelleanousNavigationItems, renderEventUKWindowCleaningNavigationItems } from "./EventUKNavigation";
import { renderArtistMainNavigationItems } from "./ArtistNavigation";
import { renderDoorSupervisorMainNavigationItems } from "./DoorSupervisorNavigation";
import { renderProviderCleaningNavigationItems, renderProviderDoorSupervisionNavigationItems, 
    renderProviderGardeningNavigationItems, renderProviderMaintenanceNavigationItems,
    renderProviderMiscelleanousNavigationItems, renderProviderWindowCleaningNavigationItems} from "./ProviderNavigation";
import { renderVenueManagerMainNavigationItems, renderVenueManagerCleaningNavigationItems, 
    renderVenueManagerDoorSupervisionNavigationItems, renderVenueManagerEntertainmentNavigationItems, 
    renderVenueManagerGardeningNavigationItems, renderVenueManagerMaintenanceNavigationItems,
    renderVenueManagerMiscelleanousNavigationItems, renderVenueManagerWindowCleaningNavigationItems } from "./VenueManagerNavigation";
import { LoadIndicator } from "devextreme-react";

interface NavigationProps {}

class Navigation extends React.Component<NavigationProps> {
    isExpandCleaning: boolean = false;
    isExpandSecurity: boolean = false;
    displayDoorSupervision: boolean = false;
    displayContractCleaning: boolean = false;
    displayEntertainment: boolean = false;
    displayGardening: boolean = false;
    displayMaintenance: boolean = false;
    displayMiscelleanous: boolean = false;
    displayWindowCleaning: boolean = false;
    isProvider: boolean = false;
    isVenueManager: boolean = false;
    isEventUk: boolean = false;
    isArtist: boolean = false;
    isHeadDoorSupervisor: boolean = false;
    isRoleReceived: boolean = false;
    isLoaderToShow: boolean = false;
    
    constructor(props: NavigationProps) {
        super(props);
    }

    componentDidMount() {
        this.setRoles();
        this.setServicesToDisplay();
        this.setSectionsToDisplay();
    }

    setRoles = () => {
        this.isRoleReceived = true;
        this.isProvider = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler);
        this.isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
        this.isEventUk = UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        this.isArtist = UserService.isUserInGroup(RoleGroupNames.Artist);
        this.isHeadDoorSupervisor = UserService.isUserInGroup(RoleGroupNames.HeadDoorSupervisor);
    }

    setServicesToDisplay = () => {
        this.displayDoorSupervision = UserService.isOperatingServicePresent(OperatingServiceNames.Security);
        this.displayContractCleaning = UserService.isOperatingServicePresent(OperatingServiceNames.ContractCleaing);
        this.displayEntertainment = UserService.isOperatingServicePresent(OperatingServiceNames.Entertainment);
        this.displayGardening = UserService.isOperatingServicePresent(OperatingServiceNames.Gardening);
        this.displayMaintenance = UserService.isOperatingServicePresent(OperatingServiceNames.Maintenance);
        this.displayMiscelleanous = UserService.isOperatingServicePresent(OperatingServiceNames.Miscelleanous);
        this.displayWindowCleaning = UserService.isOperatingServicePresent(OperatingServiceNames.WindowCleaning);
    }

    setSectionsToDisplay = () => {
        //for providers we expand cleaning section if they use cleaning and security, or just cleaning
        //...we expand security section if they use just security and not cleaning
        this.isExpandCleaning = this.isProvider && (this.displayDoorSupervision && this.displayContractCleaning) || (this.displayContractCleaning && !this.displayDoorSupervision);
        this.isExpandSecurity = this.isProvider && (this.displayDoorSupervision && !this.displayContractCleaning)
    }
    
    //Start: Top Level Render Methods
    renderMainNavigationItems = (): ReactNode => {
        if(this.isRoleReceived){
            this.isLoaderToShow=false;
            if(this.isEventUk) {
                return renderEventUKMainNavigationItems();
            }
            else if(this.isVenueManager) {
                return renderVenueManagerMainNavigationItems();
            }
            else if(this.isHeadDoorSupervisor) {
                return renderDoorSupervisorMainNavigationItems();
            }
            else if(this.isArtist) {
                return renderArtistMainNavigationItems();
            }
        }
        else{
            this.isLoaderToShow = true;
        }
    }

    renderCleaningNavigationItems = (): ReactNode => {
        if(this.isEventUk) {
            return renderEventUKCleaningNavigationItems();
        }
        else if(this.isVenueManager) {
            return renderVenueManagerCleaningNavigationItems();
        }
        else if(this.isProvider) {
            return renderProviderCleaningNavigationItems();
        }
    }

    renderDoorSupervisionNavigationItems = (): ReactNode => {
        if(this.isEventUk) {
            return renderEventUKDoorSupervisionNavigationItems();
        }
        else if(this.isVenueManager) {
            return renderVenueManagerDoorSupervisionNavigationItems();
        }
        else if(this.isProvider) {
            return renderProviderDoorSupervisionNavigationItems();
        }
    }

    //The entertainment items are very similar, only difference is Event UK see 'payments'
    //if they were significantly different we would split them into EventUKNavigation and VenueManagerNavigation
    renderEntertainmentNavigationItems = (): ReactNode => {
        if(this.isEventUk) {
            return renderEventUKEntertainmentNavigationItems();
        }
        else if(this.isVenueManager) {
            return renderVenueManagerEntertainmentNavigationItems();
        }
    }

    renderGardeningNavigationItems = (): ReactNode => {
        if(this.isEventUk) {
            return renderEventUKGardeningNavigationItems();
        }
        else if(this.isVenueManager) {
            return renderVenueManagerGardeningNavigationItems();
        }
        else if(this.isProvider) {
            return renderProviderGardeningNavigationItems();
        }
    }

    renderWindowCleaningNavigationItems = (): ReactNode => {
        if(this.isEventUk) {
            return renderEventUKWindowCleaningNavigationItems();
        }
        else if(this.isVenueManager) {
            return renderVenueManagerWindowCleaningNavigationItems();
        }
        else if(this.isProvider) {
            return renderProviderWindowCleaningNavigationItems();
        }
    }

    renderMaintenanceNavigationItems = (): ReactNode => {
        if(this.isEventUk) {
            return renderEventUKMaintenanceNavigationItems();
        }
        else if(this.isVenueManager) {
            return renderVenueManagerMaintenanceNavigationItems();
        }
        else if(this.isProvider) {
            return renderProviderMaintenanceNavigationItems();
        }
    }

    renderMiscelleanousNavigationItems = (): ReactNode => {
        if(this.isEventUk) {
            return renderEventUKMiscelleanousNavigationItems();
        }
        else if(this.isVenueManager) {
            return renderVenueManagerMiscelleanousNavigationItems();
        }
        else if(this.isProvider) {
            return renderProviderMiscelleanousNavigationItems();
        }
    }

    //End: Top Level Render Methods

    render() {
        return (
            <div className="main-header__container">
                <div className="nav-drawer" id="navbarNavDrawer">
                    <button
                        className="navbar-toggler"
                        onClick={closeDrawer}
                        type="button"
                        data-target="#navbarNavDrawer"
                        aria-controls="navbarNavDrawer"
                        aria-expanded="true"
                        aria-label="Close navigation drawer"
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    {/* <h3 className="sr-only">Quick Links</h3> */}
                    {this.renderMainNavigationItems()}
                    <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.isLoaderToShow}
                        />
                    <div className="accordion stacked-nav" id="navAccordion">
                        <div className="stacked-nav__header" hidden={!this.displayContractCleaning || this.isHeadDoorSupervisor}>
                            <div id="headingContractCleaning">
                                <button
                                    className={
                                           this.isExpandCleaning
                                            ? "btn stacked-nav__section-button"
                                            : "btn collapsed stacked-nav__section-button"
                                    }
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#nav-contract-cleaning"
                                    aria-expanded="false"
                                    aria-controls="nav-contract-cleaning"
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faVacuum} />
                                    </span>
                                    <span>Contract Cleaning</span>
                                    <span className="stacked-nav__section-button__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </div>
                            <div
                                id="nav-contract-cleaning"
                                className={this.isExpandCleaning ? "collapse show" : "collapse"}
                                aria-labelledby="headingContractCleaning"
                                data-parent="#navAccordion"
                            >
                                {this.renderCleaningNavigationItems()}
                            </div>
                        </div>
                        <div className="stacked-nav__header" hidden={!this.displayDoorSupervision || this.isHeadDoorSupervisor}>
                            <div id="headingDoorSupervision">
                                <button
                                    className="btn collapsed stacked-nav__section-button"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#nav-door-supervision"
                                    aria-expanded="false"
                                    aria-controls="nav-door-supervision"
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faDoorOpen} />
                                    </span>
                                    Door Supervision
                                    <span className="stacked-nav__section-button__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </div>
                            <div
                                id="nav-door-supervision"
                                className={this.isExpandSecurity ? "collapse show" : "collapse"}
                                aria-labelledby="headingDoorSupervision"
                                data-parent="#navAccordion"
                            >
                                {this.renderDoorSupervisionNavigationItems()}    
                            </div>
                        </div>
                        <div className="stacked-nav__header" hidden={!this.displayEntertainment || this.isProvider || this.isHeadDoorSupervisor}>
                            <div id="headingEntertainment">
                                <button
                                    className="btn collapsed stacked-nav__section-button"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#nav-entertainment"
                                    aria-expanded="false"
                                    aria-controls="nav-entertainment"
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faMicrophoneStand} />
                                    </span>
                                    Entertainment
                                    <span className="stacked-nav__section-button__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </div>
                            <div
                                id="nav-entertainment"
                                className="collapse"
                                aria-labelledby="headingEntertainment"
                                data-parent="#navAccordion"
                            >
                                {this.renderEntertainmentNavigationItems()}
                            </div>
                        </div>
                        <div className="stacked-nav__header" hidden={!this.displayGardening || this.isHeadDoorSupervisor}>
                            <div id="headingGardening">
                                <button
                                    className="btn collapsed stacked-nav__section-button"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#nav-gardening"
                                    aria-expanded="false"
                                    aria-controls="nav-gardening"
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faFlowerTulip} />
                                    </span>
                                    <span>Gardening</span>
                                    <span className="stacked-nav__section-button__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </div>
                            <div
                                id="nav-gardening"
                                className="collapse"
                                aria-labelledby="headingGardening"
                                data-parent="#navAccordion"
                            >
                                {this.renderGardeningNavigationItems()}
                            </div>
                        </div>
                        <div className="stacked-nav__header" hidden={!this.displayMaintenance || this.isHeadDoorSupervisor}>
                            <div id="headingMaintenance">
                                <button
                                    className="btn collapsed stacked-nav__section-button"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#nav-maintenance"
                                    aria-expanded="false"
                                    aria-controls="nav-maintenance"
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faWrench} />
                                    </span>
                                    <span>Maintenance</span>
                                    <span className="stacked-nav__section-button__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </div>
                            <div
                                id="nav-maintenance"
                                className="collapse"
                                aria-labelledby="headingMaintenance"
                                data-parent="#navAccordion"
                            >
                                {this.renderMaintenanceNavigationItems()}
                            </div>
                        </div>
                        <div className="stacked-nav__header" hidden={!this.displayMiscelleanous || this.isHeadDoorSupervisor}>
                            <div id="headingMiscelleanous">
                                <button
                                    className="btn collapsed stacked-nav__section-button"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#nav-miscelleanous"
                                    aria-expanded="false"
                                    aria-controls="nav-miscelleanous"
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faRectanglesMixed} />
                                    </span>
                                    <span>Miscelleanous</span>
                                    <span className="stacked-nav__section-button__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </div>
                            <div
                                id="nav-miscelleanous"
                                className="collapse"
                                aria-labelledby="headingMiscelleanous"
                                data-parent="#navAccordion"
                            >
                                {this.renderMiscelleanousNavigationItems()}
                            </div>
                        </div>
                        <div className="stacked-nav__header" hidden={!this.displayWindowCleaning || this.isHeadDoorSupervisor}>
                            <div id="headingWindowCleaning">
                                <button
                                    className="btn collapsed stacked-nav__section-button"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#nav-windowCleaning"
                                    aria-expanded="false"
                                    aria-controls="nav-windowCleaning"
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faSoap} />
                                    </span>
                                    <span>Window Cleaning</span>
                                    <span className="stacked-nav__section-button__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </div>
                            <div
                                id="nav-windowCleaning"
                                className="collapse"
                                aria-labelledby="headingWindowCleaning"
                                data-parent="#navAccordion"
                            >
                                {this.renderWindowCleaningNavigationItems()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Navigation;
