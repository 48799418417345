import { AxiosResponse } from "axios";
import { _aiTraceAsError, _get, _getAbsolute } from "./BaseApiService";

// Endpoints
const FILE_API = "File";

/// Service
class StaticContentService {
    get(type: string): Promise<AxiosResponse<any>> {
        return _get(`${FILE_API}?type=${type}`).then((response) => {
            return _getAbsolute(response.data.data);
        });
    }

    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default StaticContentService;
