import React from "react";
import DataGrid, { Pager, Paging, Export, FilterRow, Column, Scrolling, SearchPanel } from "devextreme-react/data-grid";
import StatusColour from "./StatusColour";
import ErrorMessage from "./ErrorMessage";
import { DataGridOnInitializedEvent, onExportingEvent } from "../../types/DevExtremeTypes";
import BillableItemHeaderService, {
    fileListGridType,
    ShiftImportChildGridRowItem,
} from "../../services/BillableItemHeaderService";
import gridUtils from "./GridUtilities";
import { AxiosResponse } from "axios";
import { uploadedFileType } from "../../services/FileService";
import { LoadIndicator } from "devextreme-react";
import AcknowledgedCell from "../ShiftImportWarnings/AcknowledgedCell";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
import { parseNumber } from "devextreme/localization";

// Props
//  - refreshSignal will indicate that a grid refresh is required.
//  - gridVisibilitySignal is going to signal when the grid will be shown.
interface UploadedFileContentsGridProps {
    isChildGridVisible: boolean;
    IsShowColumn: string;
    headerId: string;
    pointOfNavigation?: string;
    clientId: string;
    providerId: string;
    typeId: string;
    dateFrom: string;
    dateTo: string;
    isAcknowledged: boolean;
    hideTab: boolean;
    refreshSignal: boolean;
}

// State
interface UploadedFileContentsGridState {
    ChildGridDataSource: ShiftImportChildGridRowItem[];
    filteredDataSource: ShiftImportChildGridRowItem[];
    excludeRateDataSource: ShiftImportChildGridRowItem[];
    errWarningDataSource: ShiftImportChildGridRowItem[];
    selectAll: boolean;
    selectWarnings: boolean;
    selectExcludeRate: boolean;
    childGridType: string;
    allRecordCount: number;
    warningErrRecordCount: number;
    excludeRateCount: number;
    isShowLoadIndicator: boolean;
    selectionMode: string;
    IsShowColumn: string;
}

// Component - displays both the header and child grids
class UploadedFileContentsGrid extends React.Component<UploadedFileContentsGridProps> {
    state: UploadedFileContentsGridState;
    gridUtils: gridUtils;
    dataGridElement: any;
    billableItemUploadService: BillableItemHeaderService;

    constructor(props: UploadedFileContentsGridProps) {
        super(props);
        this.state = {
            ChildGridDataSource: [],
            filteredDataSource: [],
            excludeRateDataSource: [],
            errWarningDataSource: [],
            selectAll: false,
            selectWarnings: false,
            selectExcludeRate: true,
            childGridType: fileListGridType.Warnings,
            allRecordCount: 0,
            warningErrRecordCount: 0,
            excludeRateCount: 0,
            isShowLoadIndicator: false,
            selectionMode: this.props.hideTab ? "single" : "none",
            IsShowColumn: this.props.IsShowColumn,
        };

        this.gridUtils = new gridUtils();
        this.billableItemUploadService = new BillableItemHeaderService();
        this.dataGridElement = undefined;
        this.handleFileUploadOnInitialize = this.handleFileUploadOnInitialize.bind(this);
        this.updateChildGridDataSource = this.updateChildGridDataSource.bind(this);
    }

    // Initialize state from server
    componentDidMount() {
        const { headerId, hideTab } = this.props;
        if (!hideTab) {
            this.updateChildGridDataSource(headerId);
        } else {
            this.getManageOurWarningsData();
        }
    }

    getManageOurWarningsData = () => {
        this.setState({
            isShowLoadIndicator: true,
            IsShowColumn: this.props.IsShowColumn,
        });
        const { clientId, typeId, providerId, dateFrom, dateTo, isAcknowledged } = this.props;
        this.billableItemUploadService
            .getManageOurWarningsGridData(clientId, providerId, typeId, dateFrom, dateTo, isAcknowledged)
            .then(this.handleWarningDataSuccess)
            .catch(this.handleFailure);
    };

    updateChildGridDataSource = (headerId: string) => {
        this.setState({
            isShowLoadIndicator: true,
        });
        if (headerId) {
            this.billableItemUploadService
                .getChildGridData(headerId)
                .then(this.handleSuccess)
                .catch(this.handleFailure);
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            isShowLoadIndicator: false,
        });
        var childGridResponse: ShiftImportChildGridRowItem[] = response.data.data;
        var cloneErrWarningResponse: ShiftImportChildGridRowItem[] = [] = JSON.parse(JSON.stringify(response.data.data));
        var errorWarningDataSource: ShiftImportChildGridRowItem[] = this.gridUtils.filterShiftImportChildGrid(
            cloneErrWarningResponse
        );
        var cloneExcludeRateResponse: ShiftImportChildGridRowItem[] = [] = JSON.parse(JSON.stringify(response.data.data));
        let excludeRateWarningDataSource: ShiftImportChildGridRowItem[] = this.gridUtils.removeRate(cloneExcludeRateResponse);
        this.setState({
            filteredDataSource: excludeRateWarningDataSource,
            ChildGridDataSource: childGridResponse,
            excludeRateDataSource: excludeRateWarningDataSource,
            errWarningDataSource: errorWarningDataSource,
            allRecordCount: childGridResponse.length,
            warningErrRecordCount: errorWarningDataSource.length,
            excludeRateCount: excludeRateWarningDataSource.length
        });
    };

    //Helper function for the Manage our warnings page.
    handleWarningDataSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            isShowLoadIndicator: false,
            filteredDataSource: response && response.data && response.data.data ? response.data.data : [],
        });
    };

    handleFailure = (error: any) => {
        this.setState({
            isShowLoadIndicator: false,
        });
        var respMessage: string = "getChildGridData service failed with response: " + JSON.stringify(error);

        if (!this.billableItemUploadService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    //Update the Grid when the props changes.
    componentDidUpdate(prevProps: UploadedFileContentsGridProps, prevState: UploadedFileContentsGridState) {
        var { hideTab } = this.props;
        if (this.props.headerId !== prevProps.headerId) {
            this.setState({
                childGridType: fileListGridType.ExcludeRate,
                selectAll: false,
                selectWarnings: false,
                selectExcludeRate: true

            });
            // refresh the grid
            this.updateChildGridDataSource(this.props.headerId);
        }
        if (this.props.refreshSignal != prevProps.refreshSignal) {
            this.getManageOurWarningsData();
        }
        if (this.props.IsShowColumn != prevProps.IsShowColumn && !hideTab) {
            this.setState({
                IsShowColumn: this.props.IsShowColumn,
            });
        }
    }

    // Set the file uploader component
    handleFileUploadOnInitialize(e: DataGridOnInitializedEvent) {
        this.dataGridElement = e.component;
    }

    //A helper function that would toggle the state of the button to refresh the contents of the Parent Grid.
    toggleButtonStates = (toggleButtonState: string) => {
        let dataSourceToShow: ShiftImportChildGridRowItem[] = [];
        let isSelectAll: boolean = false;
        let isSelectWarnings: boolean = false;
        let isExcludeRate: boolean = false;
        let childGridTypeVal: string = "";
        if (toggleButtonState == "All") {
            dataSourceToShow = this.state.ChildGridDataSource;
            isSelectAll = true;
            isSelectWarnings = false;
            isExcludeRate = false;
            childGridTypeVal = fileListGridType.All;
        }
        else if (toggleButtonState == "AllErrWarning") {
            dataSourceToShow = this.state.errWarningDataSource;
            isSelectAll = false;
            isSelectWarnings = true;
            isExcludeRate = false;
            childGridTypeVal = fileListGridType.Warnings;
        }
        else if (toggleButtonState == "excludeRate") {
            dataSourceToShow = this.state.excludeRateDataSource;
            isSelectAll = false;
            isSelectWarnings = false;
            isExcludeRate = true;
            childGridTypeVal = fileListGridType.ExcludeRate;
        }
        this.setState({
            selectAll: isSelectAll,
            selectWarnings: isSelectWarnings,
            selectExcludeRate: isExcludeRate,
            childGridType: childGridTypeVal,
            filteredDataSource: dataSourceToShow
        });
    };


    parseCommentObjectIntoReadableStringMethod = (comment: string[]) => {
        let str = "";
        comment.forEach((item: any) => {
            str += `${item.ColumnName}: "${item.ErrorType} - ${item.ErrorMessage}"\n`;
        });
        return str;
    };
    
    parseLicenseNumberIntoReadableStringObj = (licenseNumber: string ) => {
        let str1 = "";
        let str2 = "";
        let str3 = "";
        let str4 = "";

        str1 = licenseNumber.substring(0,4);
        str2 = licenseNumber.substring(4,8);
        str3 = licenseNumber.substring(8,12);
        str4 = licenseNumber.substring(12,16);
        return `${str1} - ${str2} - ${str3} - ${str4}`;
    }

    showStartFinishTimeToSingleFormat = (time: string) => {
        var actualValue: string = "";
        var timeArrVal = [];
        var part1: string = "";
        var part2: string = "";
        if (time.includes(":")) {
            timeArrVal = time.split(":");
            part1 = timeArrVal[0];
            part2 = timeArrVal[1];
            if (part1.length == 1) {
                part1 = "0" + part1;
            }
            if (part2.length == 1) {
                part2 = part2 + "0";
            }
            actualValue = part1 + ":" + part2;
        } else if (time.includes(".")) {
            timeArrVal = time.split(".");
            part1 = timeArrVal[0];
            part2 = timeArrVal[1];
            if (part1.length == 1) {
                part1 = "0" + part1;
            }
            if (part2.length == 1) {
                part2 = part2 + "0";
            }
            actualValue = part1 + ":" + part2;
        } else {
            if (time.length == 4) {
                part1 = time.slice(0, 2);
                part2 = time.slice(2);
                actualValue = part1 + ":" + part2;
            }
        }
        return actualValue;
    };

    showRateToCurrencyFormat = (rateValue: string) => {
        var rateValuewithoutPoundSymbol: number = 0;
        if (rateValue.includes("£")) {
            rateValuewithoutPoundSymbol = parseInt(rateValue.replace("£", ""));
        }
        return rateValuewithoutPoundSymbol;
    };

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            topLeftCell: { row: 2, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell && gridCell.rowType === "data") {
                    if (gridCell.column && gridCell.column.dataField === "licenseNumber") {
                        if (gridCell.value.includes("'")) {
                            gridCell.value = gridCell.value.replace("'", "");
                        }
                        excelCell.value = this.parseLicenseNumberIntoReadableStringObj(gridCell.value);
                        // excelCell.value = parse gridCell.value;
                        // excelCell.numFmt = "####-####-####-####";
                    }
                    if (gridCell.column && gridCell.column.dataField === "errorMessage") {
                        excelCell.value = this.parseCommentObjectIntoReadableStringMethod(gridCell.value ? JSON.parse(gridCell.value) : []);
                    }
                    if (gridCell.column && gridCell.column.dataField === "rate") {
                        excelCell.value = this.showRateToCurrencyFormat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.column && gridCell.column.dataField === "start") {
                        excelCell.value = this.showStartFinishTimeToSingleFormat(gridCell.value);
                    }
                    if (gridCell.column && gridCell.column.dataField === "finish") {
                        excelCell.value = this.showStartFinishTimeToSingleFormat(gridCell.value);
                    }
                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    };



    render() {
        const { hideTab } = this.props;
        const { IsShowColumn } = this.state;
        return this.state.isShowLoadIndicator ? (
            <div className="starter-template">
                <LoadIndicator
                    id="simple-grid-indicator"
                    height={100}
                    width={100}
                    visible={this.state.isShowLoadIndicator}
                />
            </div>
        ) : (
            <div className="mb-5">
                <div>
                    {this.props.pointOfNavigation == "New File Submission" ? (
                        <span className="configurationText_Color">
                            <b>Uploaded File Contents......</b>
                        </span>
                    ) : (
                        ""
                    )}
                </div>
                <br></br>
                {hideTab ? (
                    <></>
                ) : (
                    <div className="view-queries-header__controls">
                        <div className="view-queries-header__view-buttons">
                            <button
                                className={`btn
                                view-queries-header__view-button ${this.state.selectExcludeRate
                                        ? "view-queries-header__view-button--selected"
                                        : ""
                                    }`}
                                onClick={() => this.toggleButtonStates("excludeRate")}
                            >
                                Show Errors/Warnings ({this.state.excludeRateCount})
                            </button>
                            <button
                                className={`btn
                                        view-queries-header__view-button ${this.state.selectWarnings
                                        ? "view-queries-header__view-button--selected"
                                        : ""
                                    }`}
                                onClick={() => this.toggleButtonStates("AllErrWarning")}
                            >
                                Show Errors/Warnings incl. rates ({this.state.warningErrRecordCount})
                            </button>
                            <button
                                className={`btn
                                        view-queries-header__view-button ${this.state.selectAll ? "view-queries-header__view-button--selected" : ""
                                    }`}
                                onClick={() => this.toggleButtonStates("All")}
                            >
                                View All ({this.state.allRecordCount})
                            </button>
                        </div>
                    </div>
                )}

                <div>
                    { IsShowColumn === uploadedFileType.otherServiceUploader ? 
                        <DataGrid
                            dataSource={this.state.filteredDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            onExporting={this.onExporting}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[1, 5, 10, 20]} showInfo={true} />
                            {hideTab ? (
                                <Column caption="Acknowledged" cellComponent={AcknowledgedCell} width={110} />
                            ) : (
                                <></>
                            )}
                            <Column dataField="provider" />
                            <Column dataField="client" />
                            <Column dataField="houseNumber" caption="House ID" />
                            <Column dataField="houseName" caption="House Name" width={220} />
                            <Column dataField="service" caption="Service"/>
                            <Column dataField="subType" caption="Sub Type"/>
                            <Column dataField="type" caption="Type"/>
                            <Column dataField="name" caption="Other Description"/>
                            <Column dataField="rate" />
                            <Column dataField="quantity" />
                            <Column dataField="dateFrom" caption="Date From"/>
                            <Column dataField="dateTo" caption="Date To"/>
                            <Column dataField="start" caption="Start" />
                            <Column dataField="finish" caption="Finish" />
                            <Column
                                dataField="billableItemUploadStatus"
                                caption="Status"
                                cellComponent={StatusColour}
                            />
                            <Column dataField="errorMessage" caption="Shift Comment" cellComponent={ErrorMessage} />
                            <FilterRow visible={true} applyFilter="auto" />
                            <Scrolling mode="standard" useNative={true} scrollByThumb={true} />
                        </DataGrid> :
                        <DataGrid
                            dataSource={this.state.filteredDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            onExporting={this.onExporting}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[1, 5, 10, 20]} showInfo={true} />
                            {hideTab ? (
                                <Column caption="Acknowledged" cellComponent={AcknowledgedCell} width={110} />
                            ) : (
                                <></>
                            )}
                            <Column dataField="provider" />
                            <Column dataField="client" />
                            <Column dataField="houseNumber" caption="House ID" />
                            <Column dataField="houseName" caption="House Name" width={220} />
                            {IsShowColumn === uploadedFileType.personnel ? (
                                <Column dataField="name" />
                            ) : (
                                <Column dataField="equipmentType" />
                            )}
                            {IsShowColumn === uploadedFileType.personnel ? (
                                <Column dataField="licenseNumber" caption="Licence ID" />
                            ) : (
                                <Column dataField="equipmentId" caption="Equipment ID" />
                            )}
                            {IsShowColumn === uploadedFileType.personnel ? <Column dataField="position" /> : null}
                            {IsShowColumn === uploadedFileType.personnel ? <Column dataField="cover" /> : null}
                            <Column dataField="rate" />
                            {IsShowColumn === uploadedFileType.equipment ? <Column dataField="quantity" /> : null}
                            <Column dataField="dateFrom" caption="Date" />
                            {IsShowColumn === uploadedFileType.personnel ? (
                                <Column dataField="start" caption="Start" />
                            ) : null}
                            {IsShowColumn === uploadedFileType.personnel ? (
                                <Column dataField="finish" caption="Finish" />
                            ) : null}
                            {IsShowColumn === uploadedFileType.personnel ? (
                                <Column dataField="workerStatus" caption="Worker Status" />
                            ) : null}
                            {IsShowColumn === uploadedFileType.personnel ? (
                                <Column dataField="hours" caption="Hours" />
                            ) : null}
                            <Column
                                dataField="billableItemUploadStatus"
                                caption="Status"
                                cellComponent={StatusColour}
                            />
                            <Column dataField="errorMessage" caption="Shift Comment" cellComponent={ErrorMessage} />
                            <FilterRow visible={true} applyFilter="auto" />
                            <Scrolling mode="standard" useNative={true} scrollByThumb={true} />
                        </DataGrid>
                    }
                </div>
            </div>
        );
    }
}

export default UploadedFileContentsGrid;
