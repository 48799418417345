import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateBox, LoadPanel, Popup, ScrollView, TextArea } from "devextreme-react";
import BaseActionCell, { BaseActionCellProps } from "../GridCells/BaseActionCell";
import { faUserMusic } from "@fortawesome/pro-light-svg-icons";
import { faUserMusic as faSolidUserMusic } from "@fortawesome/pro-solid-svg-icons";
import FindAnArtistService from "../../services/FindAnArtistService";
import { AxiosResponse } from "axios";
import { DateBoxOnValueChangedEvent, TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import notify from "devextreme/ui/notify";

class GigRequestArtistConfirmedCell extends BaseActionCell {
    service: FindAnArtistService;
    constructor(props: BaseActionCellProps) {
        super(props);
        this.service = new FindAnArtistService();
    }
    closeArtistBox = () => {
        this.setState({
            onArtistConfirm: false,
        });
    };

    onClickMusicBtn = () => {
        this.setState({
            onArtistConfirm: true,
            errorMessage: []
        });
    };

    removeArtistConfirmClick = () => {
        this.showLoadPanel();
        let requestStatusId = this.props.data.data.previousRequestStatus?.id.toString();
        let previousRequestStatusId = "";
        let submitRequest = this.findAnArtistUtils.mapArtistRequest(this.props.data.data.id, false, null, this.state.internalComments, this.props.data.data.featured, this.props.data.data.reoccurrence, this.props.data.data.serviceTypeLookUp.id.toString(), this.props.data.data.serviceSubTypeLookUp.id.toString(), this.props.data.data.clientBusinessEntity.id.toString(), requestStatusId, previousRequestStatusId);
        this.service.updateArtistRequest(this.props.data.data.id, submitRequest)
            .then(this.handlePostArtistConfirmSuccess)
            .catch((error) => {
                let respMessage: string = "Re Confirmed failed with response: " + JSON.stringify(error);
                this.hideLoadPanel();
                notify({ message: "Sorry! Artist Confirmation failed", width: 300, shading: true }, 'error', 600);
                if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                    console.error(respMessage);
                }
            });
    }
    handlePostArtistConfirmSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            gridArtistConfirmationDate: response.data.data.artistConfirmDateTime,
            gridArtistConfirmationStatus: response.data.data.isArtistConfirmed
        });
        this.closeArtistBox();
        this.props.getRequestGridStatusUpdate ? this.props.getRequestGridStatusUpdate(true) : null;
        notify({ message: "Thank You!", width: 300, shading: true }, 'success', 600);
    };


    handleArtistConfirmOnClick = () => {
        this.showLoadPanel();
        let requestStatusId = "25";
        let previousRequestStatusId = "";
        if(this.props.data.data.requestStatus?.id.toString() !== requestStatusId){
            previousRequestStatusId = this.props.data.data.requestStatus?.id.toString();
        }
        let submitRequest = this.findAnArtistUtils.mapArtistRequest(this.props.data.data.id, true, this.state.aristConfirmationDate, this.state.internalComments, this.props.data.data.featured, this.props.data.data.reoccurrence, this.props.data.data.serviceTypeLookUp.id.toString(), this.props.data.data.serviceSubTypeLookUp.id.toString(), this.props.data.data.clientBusinessEntity.id.toString(), requestStatusId, previousRequestStatusId);
        this.service.updateArtistRequest(this.props.data.data.id, submitRequest)
            .then(this.handlePostArtistConfirmSuccess)
            .catch((error) => {
                if (error.response && error.response.data.error) {
                    this.setState({
                        errorMessage: JSON.parse(JSON.stringify(error.response.data.error))
                    });
                }
                else {
                    let respMessage: string = "Artist Confirmed failed with response: " + JSON.stringify(error);
                    notify({ message: "Sorry! The Artist Confirmation failed", width: 300, shading: true }, 'error', 600);
                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                }
                this.hideLoadPanel();
            });
    }

    handleChangeInternalComment = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            internalComments: dxValueChange.value
        });
    };

    handleChangeArtistConfirmDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState(
            {
                aristConfirmationDate: dxValueChange.value
            }
        );
    };

    render() {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        message="Please wait..."
                        visible={this.state.loadPanelVisible}
                    /> 
                    { this.props.data.data.requestStatus?.id == 30 ?
                            <button className={"disabled icon-btn"}>
                                {this.state.gridArtistConfirmationStatus ?
                                    <span><FontAwesomeIcon icon={faSolidUserMusic} /></span>
                                    : <span><FontAwesomeIcon icon={faUserMusic} /></span>}
                            </button> :
                            <button className={"btn icon-btn"} onClick={this.onClickMusicBtn}>
                                {this.state.gridArtistConfirmationStatus ?
                                    <span><FontAwesomeIcon icon={faSolidUserMusic} /></span>
                                    : <span><FontAwesomeIcon icon={faUserMusic} /></span>}
                            </button>
                    }
                    {this.state.gridArtistConfirmationDate}
                </div>
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.onArtistConfirm}
                    onHiding={this.closeArtistBox}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Please Confirm date and update comment (as reqd.)"
                    maxWidth="600px"
                    height="auto"
                >
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <ScrollView width="100%" height="100%">
                        <div className="pending-request-panel__raise-query">
                            <div className="pending-request-panel__query-box">
                                {this.state.errorMessage && this.state.errorMessage.length > 0 && this.state.errorMessage != null ? (
                                    <span className="unscheduled-shift">
                                        <ul>
                                            {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                                return (
                                                    <li key={uniqueKey}>
                                                        {item.columnName}: {item.errorMessage}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </span>
                                ) : null}
                                <div className="card-form__subheading ">Internal Comments
                                    <sup className="card-form__mandatory-symbol">*</sup></div>
                                <TextArea
                                    onValueChanged={this.handleChangeInternalComment}
                                    value={this.state.internalComments}
                                    height="50%"
                                />
                                <div className="card-form__subheading mt-4">
                                    Date Confirmed?
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <DateBox
                                        onValueChanged={this.handleChangeArtistConfirmDate}
                                        value={
                                            this.state.aristConfirmationDate
                                                ? this.state.aristConfirmationDate
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                    ></DateBox></div>
                                <div className="row">
                                    <div className={this.state.gridArtistConfirmationStatus ? "col-12 col-lg-6" : "col-12 col-lg-612"} >
                                        <button className="btn btn-primary btn--large" onClick={this.handleArtistConfirmOnClick}>
                                            Confirm Artist
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-6" hidden={!this.state.gridArtistConfirmationStatus}>
                                        <button className="btn btn--ghost btn--ghost--teal btn--large" onClick={this.removeArtistConfirmClick}>
                                            Remove Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </>
        );
    }
}
export default GigRequestArtistConfirmedCell;