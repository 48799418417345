import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import Schedule from "../schedule/Schedule";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import ClientInformationMainContent from "./ClientInformationMainContent";
import ClientInformationViewAllContent from "./ClientInformationViewAllContent";
import ClientLookUpComponent from "./ClientLookUpComponent";

interface ClientInformationPageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

interface ClientInformationPageState {
    clientId: string;
    venueId: string;
    isClientOrVenueFound: boolean;
    selectedViewByVenueButton: boolean;
    selectedViewAllButton: boolean;
    refreshSignal: boolean;
}

class ClientInformationPage extends Component<ClientInformationPageProps> {
    //Initialize the component's state.
    state: ClientInformationPageState;

    constructor(props: ClientInformationPageProps) {
        super(props);
        this.state = {
            clientId: "",
            venueId: "",
            isClientOrVenueFound: false,
            selectedViewByVenueButton: true,
            selectedViewAllButton: false,
            refreshSignal: false,
        };
    }
    setClientOrVenueId = (venueIdValue: string, clientIdValue: string, isClientOrVenueValue: boolean) => {
        this.setState({
            clientId: clientIdValue,
            venueId: venueIdValue,
            isClientOrVenueFound: isClientOrVenueValue,
            isDashBoardDataLoaded: false,
        });
    };

    //A helper function that would toggle the state of the button to refresh the contents of the Parent Grid.
    toggleButtonStates = (toggleButtonState: boolean) => {
        this.setState({
            selectedViewByVenueButton: toggleButtonState,
            selectedViewAllButton: !toggleButtonState,
        });
    };

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <BulletinPanel />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <h2 className="page-title__black">
                            {/* Door Supervision - Client Information & Schedule */}
                            {`${this.props.location.state.Header} - Client Information & Schedule`}
                        </h2>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            <div className="view-queries-grid mt-3">
                                <div className="view-queries-header__controls">
                                    <div className="view-queries-header__view-buttons">
                                        <button
                                            className={`btn
                                        view-queries-header__view-button ${
                                            this.state.selectedViewByVenueButton
                                                ? "view-queries-header__view-button--selected"
                                                : ""
                                        }`}
                                            onClick={() => this.toggleButtonStates(true)}
                                        >
                                            View By Venue
                                        </button>
                                        <button
                                            className={`btn
                                        view-queries-header__view-button ${
                                            this.state.selectedViewAllButton
                                                ? "view-queries-header__view-button--selected"
                                                : ""
                                        }`}
                                            onClick={() => this.toggleButtonStates(false)}
                                        >
                                            View All
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {this.state.selectedViewByVenueButton ? (
                                <>
                                    <div className="mt-5">
                                        <Schedule
                                            venueAll={false}
                                            setParentFilterValuesCallback={this.setClientOrVenueId}
                                            navigateFrom ="clientinformation"
                                        />
                                    </div>
                                    <ClientInformationMainContent
                                        clientId={this.state.clientId}
                                        venueId={this.state.venueId}
                                        isShowByVenue={true}
                                        serviceTypeId={this.props.location.state.serviceTypeId}
                                    ></ClientInformationMainContent>
                                </>
                            ) : (
                                <>
                                    <div className="mt-5">
                                        <ClientLookUpComponent
                                            setParentFilterValuesCallback={this.setClientOrVenueId}
                                            refreshSignal={this.state.refreshSignal}
                                        />
                                    </div>
                                    <ClientInformationViewAllContent
                                        clientId={this.state.clientId}
                                        venueId={this.state.venueId}
                                    >
                                        {" "}
                                    </ClientInformationViewAllContent>
                                </>
                            )}
                        </div>
                        <div />
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ClientInformationPage);
