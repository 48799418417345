import React, { MouseEvent, Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import AdditionalCleaningForm from "../AdditionalCleaning/AdditionalCleaningForm";

interface AdditionalCleaningPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

class AdditionalCleaningPage extends Component<AdditionalCleaningPageComponentProps> {
    render() {
        return <AdditionalCleaningForm history={this.props.history} location={this.props.location} />;
    }
}

export default withRouter(AdditionalCleaningPage);
