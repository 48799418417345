import { faBuilding, faEnvelope, faPhone, faTheaterMasks, faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosResponse } from "axios";
import { LoadIndicator } from "devextreme-react";
import React from "react";
import lookupService, { LookupTypeIndexes } from "../../services/LookupService";
import { LookUpOnValueChangedEvent, SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { DashBoardData } from "../Today/DashBoardUtils";

interface VenueCardProps {
    dashBoardData?: DashBoardData;
    isDashBoardDataLoaded: boolean;
    clientId?: string;
    venueId?: string;
    dataNotFoundMsg?: string;
}

interface VenueCardState {
    clientId: string;
    venueId: string;
}

class VenueCard extends React.Component<VenueCardProps> {
    state: VenueCardState;
    lookUpService: lookupService;
    constructor(props: VenueCardProps) {
        super(props);
        this.lookUpService = new lookupService();
        this.state = {
            clientId: "",
            venueId: ""
        };
    }

    isBlank(param: string) {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    }

    retrieveLookup = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookUpService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleClientLookUpSuccess)
                break;
            case LookupTypeIndexes.venueType:
                this.lookUpService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                    .then(this.handleVenueLookUpSuccess)
            //.catch(this.handleStandardError);
        }
    };
    handleClientLookUpSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data.data.length > 0) {

            this.setState(
                {
                    clientId: response.data.data[0].id,
                },
                () => {
                    this.retrieveLookup(LookupTypeIndexes.venueType);
                }
            );
        }
    }

    handleVenueLookUpSuccess = (response: AxiosResponse<any>) => {
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            !this.isBlank(this.state.clientId)
        ) {
            let venues: any[] = response.data.data;
            venues = venues.filter((item) => {
                return item.parentMappingId == this.state.clientId;
            });

            this.setState(
                {
                    venueId: venues[0].id
                }
            );
        }
    };
    getVenueNameWOLocation = () => {
        if (this.props.dashBoardData && this.props.dashBoardData.venue) {
            if (this.props.dashBoardData.venue.venueNameWOLocation) {
                return this.props.dashBoardData.venue.venueNameWOLocation;
            }
            else if (this.props.dashBoardData.venue.venueName) {
                return this.props.dashBoardData.venue.venueName;
            }
        }
        return null;
    };

    getVenueHouseNumber = () => {
        if (this.props.dashBoardData && this.props.dashBoardData.venue) {
            return this.props.dashBoardData.venue.houseNumber;
        }
        return null;
    };

    getVenuePhone = () => {
        if (this.props.dashBoardData && this.props.dashBoardData.venue) {
            return this.props.dashBoardData.venue.phone;
        }
        return null;
    };

    getVenueEmail = () => {
        if (this.props.dashBoardData && this.props.dashBoardData.venue) {
            return this.props.dashBoardData.venue.email;
        }
        return null;
    };

    getVenueGeneralManagerDetails = () => {
        let details = "";
        if (
            this.props.dashBoardData &&
            this.props.dashBoardData.venue &&
            this.props.dashBoardData.venue.generalManager
        ) {
            details = this.props.dashBoardData.venue.generalManager.name;
            if (this.props.dashBoardData.venue.generalManager.mobileTelephone) {
                details += " " + this.props.dashBoardData.venue.generalManager.mobileTelephone;
            }
        }

        return details;
    };

    getVenueDivision = () => {
        if (this.props.dashBoardData && this.props.dashBoardData.venue) {
            return this.props.dashBoardData.venue.division;
        }
        return null;
    };

    getAddress = () => {
        var address = "";
        if (this.props.dashBoardData && this.props.dashBoardData.venue) {
            var address1 = this.props.dashBoardData.venue.address ? this.props.dashBoardData.venue.address : "";
            var city = this.props.dashBoardData.venue.city ? this.props.dashBoardData.venue.city : "";
            var county = this.props.dashBoardData.venue.county ? this.props.dashBoardData.venue.county : "";
            var postCode = this.props.dashBoardData.venue.postCode ? this.props.dashBoardData.venue.postCode : "";

            address = address1;
            if (city) {
                address += ", " + city;
            }
            if (county) {
                address += ", " + county;
            }
            if (postCode) {
                address += ", " + postCode;
            }
        }
        return address;
    };
    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            clientId: dxValueChange.value,
            venueId: ""
        });
    };

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            venueId: dxValueChange.value
        });
    };

    render() {
        const venueData = [
            {
                key: 1,
                icon: faUser,
                description: "General Manager:",
                value: this.getVenueGeneralManagerDetails()
            },
            {
                key: 2,
                icon: faPhone,
                description: "Telephone:",
                value: this.getVenuePhone(),
                href: `tel:${this.getVenuePhone()}`
            },
            {
                key: 3,
                icon: faEnvelope,
                description: "Email:",
                value: this.getVenueEmail(),
                href: `mailto:${this.getVenueEmail()}`
            },
            {
                key: 4,
                icon: faBuilding,
                description: "Address:",
                value: this.getAddress()
            },
            {
                key: 5,
                icon: faTheaterMasks,
                description: "Division:",
                value: this.getVenueDivision()
            }
        ]
        return (
            <>
                {this.props.isDashBoardDataLoaded && !this.props.dataNotFoundMsg ? (
                    <>
                        <h4>
                            {this.getVenueNameWOLocation()} ({this.getVenueHouseNumber()})
                        </h4>
                        <ul className="list-unstyled">
                            {venueData.map(item =>
                                <>
                                    {
                                        item.value !== "" ? // only show if there is a value
                                            <li>
                                                {item.href ?
                                                    <a className="d-inline-flex py-1" href={item.href}>
                                                        <div>
                                                            <FontAwesomeIcon icon={item.icon} />
                                                            <span className="sr-only">
                                                                {item.description}
                                                            </span>
                                                        </div>
                                                        <div className="ml-3">
                                                            {item.value}
                                                        </div>
                                                    </a>
                                                    :
                                                    <div className="d-inline-flex py-1">
                                                        <div>
                                                            <FontAwesomeIcon icon={item.icon} />
                                                            <span className="sr-only">
                                                                {item.description}
                                                            </span>
                                                        </div>
                                                        <div className="ml-3">
                                                            {item.value}
                                                        </div>
                                                    </div>
                                                }
                                            </li>
                                            :
                                            null
                                    }
                                </>
                            )}
                        </ul>
                    </>
                ) : this.props.dataNotFoundMsg || this.props.venueId == "" ? (
                    this.props.dataNotFoundMsg
                ) : (
                    <LoadIndicator
                        id="button-indicator"
                        height={60}
                        width={60}
                        className="home-page__todayloader"
                        visible={true}
                    />
                )}
            </>
        );
    }
}
export default VenueCard;
