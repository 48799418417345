import React from "react";
import { Link } from "react-router-dom";
import GlobalService from "../../services/GlobalService";
import sharedUtils from "../grid/sharedUtilities";
interface VenueFinancePeriodPopUpProps {
    point: any;
}

const VenueFinancePeriodPopUp: React.FC<VenueFinancePeriodPopUpProps> = (props: VenueFinancePeriodPopUpProps) => {
    let totalSpendValue = 0;
    let totalBudget = 0;
    let differenceOfWeek1 = 0;
    let differenceOfWeek2 = 0;
    let differenceOfWeek3 = 0;
    let differenceOfWeek4 = 0;
    let differenceOfWeek5 = 0;
    let totalDifference = 0;
    let isFutureRecord = false;
    let periodMatrixStartId = "";
    let periodMatrixEndId = "";
    let week1InvoiceId = 0;
    let week2InvoiceId = 0;
    let week3InvoiceId = 0;
    let week4InvoiceId = 0;
    let week5InvoiceId = 0;
    let periodStartDate = "";
    let periodEndDate = "";
    var utils = new sharedUtils();
    const total = () => {
        var currentPeriod = parseInt(GlobalService.getCurrentPeriod());
        var currentWeek = parseInt(GlobalService.getCurrentWeek());
        var utils = new sharedUtils();
        props.point.data.weeks.map((week: any) => {
            if (week.number == "1") {
                periodStartDate = utils.convertStringToFormatedDate(week.dateFrom);
            }
            periodEndDate = utils.convertStringToFormatedDate(week.dateTo);
        });
        if (props.point.data.weeks[0]) {
            if (props.point.data.period <= currentPeriod) {
                if (
                    (props.point.data.weeks[0].number <= currentWeek ||
                        (props.point.data.weeks[0].number > currentWeek && props.point.data.period <= currentPeriod)) &&
                    parseInt(props.point.data.weeks[0].invoiceId) > 0
                ) {
                    totalSpendValue += parseFloat(props.point.data.weeks[0].spend);
                    totalBudget += parseFloat(props.point.data.weeks[0].budget);
                    periodMatrixStartId = props.point.data.weeks[0].periodMatrixId;
                    periodMatrixEndId = props.point.data.weeks[0].periodMatrixId;
                    differenceOfWeek1 =
                        parseFloat(props.point.data.weeks[0].budget) - parseFloat(props.point.data.weeks[0].spend);
                } else if (props.point.data.period > currentPeriod && props.point.data.weeks[0].number > currentWeek) {
                    isFutureRecord = true;
                }
                week1InvoiceId = parseInt(props.point.data.weeks[0].invoiceId);
            } else if (props.point.data.period > currentPeriod) {
                isFutureRecord = true;
            }
        }
        if (props.point.data.weeks[1]) {
            if (props.point.data.period <= currentPeriod) {
                if (
                    (props.point.data.weeks[1].number <= currentWeek ||
                        (props.point.data.weeks[1].number > currentWeek && props.point.data.period <= currentPeriod)) &&
                    parseInt(props.point.data.weeks[1].invoiceId) > 0
                ) {
                    totalSpendValue += parseFloat(props.point.data.weeks[1].spend);
                    totalBudget += parseFloat(props.point.data.weeks[1].budget);
                    periodMatrixStartId = periodMatrixStartId
                        ? periodMatrixStartId
                        : props.point.data.weeks[1].periodMatrixId;
                    periodMatrixEndId = props.point.data.weeks[1].periodMatrixId;
                    differenceOfWeek2 =
                        parseFloat(props.point.data.weeks[1].budget) - parseFloat(props.point.data.weeks[1].spend);
                } else if (props.point.data.period > currentPeriod && props.point.data.weeks[1].number > currentWeek) {
                    isFutureRecord = true;
                }
                week2InvoiceId = parseInt(props.point.data.weeks[1].invoiceId);
            } else if (props.point.data.period > currentPeriod) {
                isFutureRecord = true;
            }
        }
        if (props.point.data.weeks[2]) {
            if (props.point.data.period <= currentPeriod) {
                if (
                    (props.point.data.weeks[2].number <= currentWeek ||
                        (props.point.data.weeks[2].number > currentWeek && props.point.data.period <= currentPeriod)) &&
                    parseInt(props.point.data.weeks[2].invoiceId) > 0
                ) {
                    totalSpendValue += parseFloat(props.point.data.weeks[2].spend);
                    totalBudget += parseFloat(props.point.data.weeks[2].budget);
                    periodMatrixStartId = periodMatrixStartId
                        ? periodMatrixStartId
                        : props.point.data.weeks[2].periodMatrixId;
                    periodMatrixEndId = props.point.data.weeks[2].periodMatrixId;
                    differenceOfWeek3 =
                        parseFloat(props.point.data.weeks[2].budget) - parseFloat(props.point.data.weeks[2].spend);
                } else if (props.point.data.period > currentPeriod && props.point.data.weeks[2].number > currentWeek) {
                    isFutureRecord = true;
                }
                week3InvoiceId = parseInt(props.point.data.weeks[2].invoiceId);
            } else if (props.point.data.period > currentPeriod) {
                isFutureRecord = true;
            }
        }
        if (props.point.data.weeks[3]) {
            if (props.point.data.period <= currentPeriod) {
                if (
                    (props.point.data.weeks[3].number <= currentWeek ||
                        (props.point.data.weeks[3].number > currentWeek && props.point.data.period <= currentPeriod)) &&
                    parseInt(props.point.data.weeks[3].invoiceId) > 0
                ) {
                    totalSpendValue += parseFloat(props.point.data.weeks[3].spend);
                    totalBudget += parseFloat(props.point.data.weeks[3].budget);
                    periodMatrixStartId = periodMatrixStartId
                        ? periodMatrixStartId
                        : props.point.data.weeks[3].periodMatrixId;
                    periodMatrixEndId = props.point.data.weeks[3].periodMatrixId;
                    differenceOfWeek4 =
                        parseFloat(props.point.data.weeks[3].budget) - parseFloat(props.point.data.weeks[3].spend);
                } else if (props.point.data.period > currentPeriod && props.point.data.weeks[3].number > currentWeek) {
                    isFutureRecord = true;
                }
                week4InvoiceId = parseInt(props.point.data.weeks[3].invoiceId);
            } else if (props.point.data.period > currentPeriod) {
                isFutureRecord = true;
            }
        }
        if (props.point.data.weeks[4]) {
            if (props.point.data.period <= currentPeriod) {
                if (
                    (props.point.data.weeks[4].number <= currentWeek ||
                        (props.point.data.weeks[4].number > currentWeek && props.point.data.period <= currentPeriod)) &&
                    parseInt(props.point.data.weeks[4].invoiceId) > 0
                ) {
                    totalSpendValue += parseFloat(props.point.data.weeks[4].spend);
                    totalBudget += parseFloat(props.point.data.weeks[4].budget);
                    periodMatrixStartId = periodMatrixStartId
                        ? periodMatrixStartId
                        : props.point.data.weeks[4].periodMatrixId;
                    periodMatrixEndId = props.point.data.weeks[4].periodMatrixId;
                    differenceOfWeek5 =
                        parseFloat(props.point.data.weeks[4].budget) - parseFloat(props.point.data.weeks[4].spend);
                } else if (props.point.data.period > currentPeriod && props.point.data.weeks[4].number > currentWeek) {
                    isFutureRecord = true;
                }
                week5InvoiceId = parseInt(props.point.data.weeks[4].invoiceId);
            } else if (props.point.data.period > currentPeriod) {
                isFutureRecord = true;
            }
        }
        totalDifference =
            differenceOfWeek1 + differenceOfWeek2 + differenceOfWeek3 + differenceOfWeek4 + differenceOfWeek5;
    };
    total();
    const viewCostReportForService = (serviceTypeId: string) => {
        sessionStorage.setItem("serviceTypeFilter", serviceTypeId);
    };
    // Renderer for each week item row in the bubble
    const weekItems = props.point.data.weeks.map((week: any, uniqueKey: number) => {
        return (
            <tr key={uniqueKey} className={isFutureRecord || parseInt(week.invoiceId) == 0 ? "light--grey--text" : "my-2"}>
                <td>{week.number}</td>
                <td className="text-left">
                    {isFutureRecord == false && parseInt(week.invoiceId) > 0 ? (
                        <Link
                            onClick={() =>
                                viewCostReportForService(props.point.data.serviceSpecificValue.serviceTypeId)
                            }
                            to={{
                                pathname: "/CostReport",
                                state: {
                                    clientId: props.point.data.serviceSpecificValue.clientId,
                                    venueId: props.point.data.serviceSpecificValue.venueId,
                                    startDateId: week.periodMatrixId,
                                    endDateId: week.periodMatrixId,
                                },
                            }}
                            style={{
                                color: props.point.data.serviceSpecificValue.serviceColor,
                            }}
                        >
                            {utils.thousandsSeparatorLandingPage(week.spend.toFixed())}{" "}
                        </Link>
                    ) : (
                        <div className="#D3D3D3"> - </div>
                    )}

                    {isFutureRecord == false && parseInt(week.invoiceId) > 0 ? (
                        <Link
                            className="text-right"
                            onClick={() =>
                                viewCostReportForService(props.point.data.serviceSpecificValue.serviceTypeId)
                            }
                            to={{
                                pathname: "/CostReport",
                                state: {
                                    clientId: props.point.data.serviceSpecificValue.clientId,
                                    venueId: props.point.data.serviceSpecificValue.venueId,
                                    startDateId: week.periodMatrixId,
                                    endDateId: week.periodMatrixId,
                                },
                            }}
                            style={{
                                color: props.point.data.serviceSpecificValue.serviceColor,
                            }}
                        >
                            <span className="home-page__data-table__link-label">View</span>
                        </Link>
                    ) : null}
                </td>
                <td className="text-left">{utils.thousandsSeparatorLandingPage(week.budget.toFixed())}</td>
                <td
                    className={
                        isFutureRecord || parseInt(week.invoiceId) == 0
                            ? "light--grey--text text-right"
                            : Math.sign(parseFloat(week.budget) - parseFloat(week.spend)) == 1 ||
                              parseFloat(week.budget) - parseFloat(week.spend) == 0
                            ? "text-success text-right"
                            : Math.sign(parseFloat(week.budget) - parseFloat(week.spend)) == -1
                            ? "text-danger text-right"
                            : ""
                    }
                >
                    {isFutureRecord || parseInt(week.invoiceId) == 0
                        ? "-"
                        :  utils.thousandsSeparatorLandingPage((parseFloat(week.budget) - parseFloat(week.spend)).toFixed())}
                </td>
            </tr>
        );
    });

    return (
        <div className="home-page__breakdown-container">
            <div className="row">
                <div className="col-12 col-lg-6 text-lg-left">
                    <label className="d-block font-weight-bold">Period {props.point.data.period}</label>
                </div>
                <div className="col-12 col-lg-6 text-lg-right">
                    <label className="font-weight-bold">
                        {periodStartDate} - {periodEndDate}
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <table className="table w-100 text-center home-page__data-table">
                        <thead className="divider--bottom ">
                            <tr>
                                <th
                                    scope="col"
                                    className="font-weight-normal"
                                    style={{
                                        width: "30%",
                                    }}
                                >
                                    Week
                                </th>
                                <th
                                    scope="col"
                                    className="font-weight-normal text-left"
                                    style={{
                                        width: "30%",
                                    }}
                                >
                                    Spend
                                </th>
                                <th
                                    scope="col"
                                    className="font-weight-normal text-left"
                                    style={{
                                        width: "30%",
                                    }}
                                >
                                    Budget
                                </th>
                                <th
                                    scope="col"
                                    className="font-weight-normal text-right"
                                    style={{
                                        width: "10%",
                                    }}
                                >
                                    Difference
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divider--bottom">
                            {weekItems.map(function (week: any) {
                                return week;
                            })}
                        </tbody>
                        <tfoot>
                            <tr
                                className={
                                    isFutureRecord ||
                                    (week1InvoiceId == 0 &&
                                        week2InvoiceId == 0 &&
                                        week3InvoiceId == 0 &&
                                        week4InvoiceId == 0 &&
                                        week5InvoiceId == 0)
                                        ? "light--grey--text"
                                        : "font-weight-bold"
                                }
                            >
                                <td>Total</td>
                                <td
                                    className="text-left"
                                    style={{
                                        color:
                                            isFutureRecord ||
                                            (week1InvoiceId == 0 &&
                                                week2InvoiceId == 0 &&
                                                week3InvoiceId == 0 &&
                                                week4InvoiceId == 0 &&
                                                week5InvoiceId == 0)
                                                ? "#D3D3D3"
                                                : props.point.data.serviceSpecificValue.serviceColor,
                                    }}
                                >
                                    {isFutureRecord == false &&
                                    (week1InvoiceId > 0 ||
                                        week2InvoiceId > 0 ||
                                        week3InvoiceId > 0 ||
                                        week4InvoiceId > 0 ||
                                        week5InvoiceId > 0) ? (
                                        <Link
                                            onClick={() =>
                                                viewCostReportForService(
                                                    props.point.data.serviceSpecificValue.serviceTypeId
                                                )
                                            }
                                            to={{
                                                pathname: "/CostReport",
                                                state: {
                                                    clientId: props.point.data.serviceSpecificValue.clientId,
                                                    venueId: props.point.data.serviceSpecificValue.venueId,
                                                    startDateId: periodMatrixStartId,
                                                    endDateId: periodMatrixEndId,
                                                },
                                            }}
                                            style={{
                                                color: isFutureRecord
                                                    ? "#D3D3D3"
                                                    : props.point.data.serviceSpecificValue.serviceColor,
                                            }}
                                        >
                                            {isFutureRecord ? "-" : utils.thousandsSeparatorLandingPage(totalSpendValue.toFixed())}
                                        </Link>
                                    ) : (
                                        <div className="#D3D3D3"> - </div>
                                    )}
                                    {isFutureRecord == false &&
                                    (week1InvoiceId > 0 ||
                                        week2InvoiceId > 0 ||
                                        week3InvoiceId > 0 ||
                                        week4InvoiceId > 0 ||
                                        week5InvoiceId > 0) ? (
                                        <Link
                                            onClick={() =>
                                                viewCostReportForService(
                                                    props.point.data.serviceSpecificValue.serviceTypeId
                                                )
                                            }
                                            to={{
                                                pathname: "/CostReport",
                                                state: {
                                                    clientId: props.point.data.serviceSpecificValue.clientId,
                                                    venueId: props.point.data.serviceSpecificValue.venueId,
                                                    startDateId: periodMatrixStartId,
                                                    endDateId: periodMatrixEndId,
                                                },
                                            }}
                                            style={{
                                                color: props.point.data.serviceSpecificValue.serviceColor,
                                            }}
                                        >
                                            <span className="home-page__data-table__link-label">View</span>
                                        </Link>
                                    ) : null}
                                </td>
                                <td className="text-left">
                                    {isFutureRecord ||
                                    (week1InvoiceId == 0 &&
                                        week2InvoiceId == 0 &&
                                        week3InvoiceId == 0 &&
                                        week4InvoiceId == 0 &&
                                        week5InvoiceId == 0)
                                        ? "-"
                                        :  utils.thousandsSeparatorLandingPage(totalBudget.toFixed())}
                                </td>
                                <td
                                    className={
                                        isFutureRecord ||
                                        (week1InvoiceId == 0 &&
                                            week2InvoiceId == 0 &&
                                            week3InvoiceId == 0 &&
                                            week4InvoiceId == 0 &&
                                            week5InvoiceId == 0)
                                            ? "light--grey--text text-right"
                                            : Math.sign(totalDifference) == 1 || totalDifference == 0
                                            ? "text-success text-right"
                                            : Math.sign(totalDifference) == -1
                                            ? "text-danger text-right"
                                            : ""
                                    }
                                >
                                    {isFutureRecord ||
                                    (week1InvoiceId == 0 &&
                                        week2InvoiceId == 0 &&
                                        week3InvoiceId == 0 &&
                                        week4InvoiceId == 0 &&
                                        week5InvoiceId == 0)
                                        ? "-"
                                        :  utils.thousandsSeparatorLandingPage(totalDifference.toFixed())}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default VenueFinancePeriodPopUp;
