import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, { Pager, Paging, FilterRow, Column, Sorting, Editing, SearchPanel } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import lookupService from "../../services/LookupService";
import gridUtils, { currencyTypeToSymbolMatrix, currencyTypes } from "../grid/GridUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import EntertainmentSubstituteService, {
    EntertainmentSubstituteGridRowItem,
} from "../../services/EntertainmentSubstituteService";

//props
interface EntertainmentSubstituteGridProps {
    refreshSignal: boolean;
    resId: string;
    handleFormOpenClose: (applySignal: string, id: string) => void;
}

export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}

// State
interface EntertainmentSubstituteGridState {
    substituteGridDataSource: EntertainmentSubstituteGridRowItem[];
    showProgressIndicator: boolean;
    dataSource: EntertainmentSubstituteGridRowItem[];
}

// Component - displays the Rate Matrix Grid
class EntertainmentSubstituteGrid extends React.Component<EntertainmentSubstituteGridProps> {
    //Initialize the component's state.
    state: EntertainmentSubstituteGridState;
    service: EntertainmentSubstituteService;
    dropdownService: lookupService;
    gridUtils: gridUtils;

    constructor(props: EntertainmentSubstituteGridProps) {
        super(props);
        this.service = new EntertainmentSubstituteService();
        this.dropdownService = new lookupService();
        // Initialize state and services/utils
        this.state = {
            substituteGridDataSource: [],
            showProgressIndicator: false,
            dataSource: [
                {
                    id: "5",
                    businessEntityId: "6",
                    businessEntityShortName: "BigPubCo",
                    shiftBatchMainId: "2",
                    isActive: "False",
                    date: "05/08/2020",
                    rate: "1.00",
                    shiftBatchMainDay: "4",
                    shiftBatchMainDateFrom: "07/06/2020",
                    shiftBatchMainDateTo: "07/08/2020",
                    lastUpdatedOn: "13/08/2020",
                    lastUpdatedBy: "Event UK Senior manager 1",
                },
            ],
        };
        this.gridUtils = new gridUtils();
        // Functions
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleForm = this.handleForm.bind(this);
    }

    componentDidMount() {
        this.substituteDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: EntertainmentSubstituteGridProps) {
        if (this.props.refreshSignal !== prevProps.refreshSignal) {
            // refresh the grid
            this.substituteDataSource();
        }
    }

    substituteDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.service
            .getSubstituteGridData(this.props.resId) //TODO: Replace this hardcoded paramter with the ID of the parent Grid Row.
            .then(this.handleSuccess)
            .catch(this.handleError);
    };

    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            substituteGridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    }

    handleError(error: AxiosResponse<any>) {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "getSubstituteGridData service failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }

    //NOTE- A custom cell Render function that would prevent the need of creating a separate action cell component for an Edit pencil Button.
    renderCustomData = (data: any) => {
        var id = data.data.id;
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    <span className="icon-btn" onClick={() => this.handleForm(id)}>
                        <FontAwesomeIcon icon={faPen} />
                    </span>
                </div>
            </div>
        );
    };
    handleForm = (id: string) => {
        this.props.handleFormOpenClose("OPEN", id);
    };
    render() {
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.substituteGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Sorting mode="single" />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="id" allowSorting={true} caption="ID" />
                        <Column dataField="businessEntityShortName" allowSorting={true} caption="ARTIST" />
                        <Column
                            dataField="date"
                            caption="DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                        />
                        <Column
                            dataField="rate"
                            allowSorting={true}
                            caption="RATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate);
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.rate,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                        />
                        <Column dataField="lastUpdatedOn" allowSorting={true} caption="LAST UPDATED TIME" />
                        <Column
                            dataField="lastUpdatedBy"
                            allowSorting={true}
                            caption="LAST UPDATED BY"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedBy);
                            }}
                        />
                        <Column dataField="isActive" allowSorting={true} caption="ACTIVE" />
                        <Column cellRender={this.renderCustomData} />
                        <Editing mode="row" useIcons={true} />

                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default EntertainmentSubstituteGrid;
