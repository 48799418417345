import React, { useState } from "react";
import { RouteProps, withRouter } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";
import FinancialAdjustmentsPage from "./FinancialAdjustmentsPage";

interface FinanceAdjustmentPageContainerProps extends RouteProps, withValidUserRoleProps {}

const FinanceAdjustmentPageContainer = (props: FinanceAdjustmentPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return <FinancialAdjustmentsPage title={getTitle()} category={getType()} />;
};

export default withValidUserRole(FinanceAdjustmentPageContainer);
