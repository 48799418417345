import { LoadIndicator } from "devextreme-react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { companyInformationData, linkData, socialMediaType } from "../../services/QueryService";
import helpScreenUtils from "./HelpScreenUtils";

interface SocialMediaCardProps {
    socialMediaData: companyInformationData;
    dataLoaded: boolean;
}

interface SocialMediaCardState {}

class SocialMediaCard extends React.Component<SocialMediaCardProps> {
    state: SocialMediaCardState;
    utils: helpScreenUtils;

    constructor(props: SocialMediaCardProps) {
        super(props);
        this.utils = new helpScreenUtils();
        this.state = {};
    }

    render() {
        const { socialMediaData, dataLoaded } = this.props;
        var navigationLink: linkData[] =
            socialMediaData && socialMediaData.linkInformation && socialMediaData.linkInformation.length > 0
                ? this.utils.returnLink(socialMediaData.linkInformation)
                : [];
        return (
            <>
                <h4>
                    <b>Social Media</b>
                </h4>
                {dataLoaded ? (
                    navigationLink ? (
                        <>
                            <div className="row justify-content-center">
                                <div className="px-2 py-2">
                                    {navigationLink.map((item: linkData, uniqueNumber: number) => {
                                        switch (item.linkTypeId) {
                                            case socialMediaType.Facebook:
                                                return (
                                                    <a key={uniqueNumber} href={item.link} className="facebook social">
                                                        <FontAwesomeIcon icon={faFacebook} size="4x" />
                                                    </a>
                                                );
                                            case socialMediaType.Instagram:
                                                return (
                                                    <a key={uniqueNumber} href={item.link} className="instagram social">
                                                        <FontAwesomeIcon icon={faInstagram} size="4x" />
                                                    </a>
                                                );
                                            case socialMediaType.Twitter:
                                                return (
                                                    <a key={uniqueNumber} href={item.link} className="twitter social">
                                                        <FontAwesomeIcon icon={faTwitter} size="4x" />
                                                    </a>
                                                );
                                            default:
                                                return <></>;
                                        }
                                    })}
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )
                ) : (
                    <LoadIndicator
                        id="button-indicator"
                        height={60}
                        width={60}
                        className="home-page__todayloader"
                        visible={true}
                    />
                )}
            </>
        );
    }
}

export default SocialMediaCard;
