import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

interface PendingRequestDateListProps {
    date: string;
    serialNo: number;
    selectDate: (date: string, selection: boolean) => void;
}

interface PendingRequestDateListState {
    showIcon: boolean;
    itemColour: string;
    selected: boolean;
}

class PendingRequestDateList extends React.Component<PendingRequestDateListProps> {
    state: PendingRequestDateListState;

    constructor(props: PendingRequestDateListProps) {
        super(props);

        this.state = {
            showIcon: false,
            itemColour: "white",
            selected: false,
        };
    }

    //On a component did Mount make the 1st date in the date picker as selected.
    componentDidMount() {
        if (this.props.serialNo == 0) {
            this.setState({
                selected: true,
            });
        }
    }

    //We should use this more often, if a state has to toggle, a prevState argument should be passedin to the setState method.
    toggleSelection = () => {
        this.setState(
            (prevState: PendingRequestDateListState) => ({
                selected: !prevState.selected,
            }),
            () => this.props.selectDate(this.props.date, this.state.selected)
        );
    };

    render() {
        var pathname: string = window.location.pathname;
        var searchParams: string = window.location.search ? `${window.location.search}` : "";
        var iconVisible: boolean = this.state.selected ? true : false;
        return (
            <li
                className={`pending-request-list__item
                ${iconVisible ? "" : "pending-request-list__item--no-icon"}
                ${this.state.selected ? "pending-request-list__item--selected" : ""}`}
            >
                <Link to={`${pathname}${searchParams}`} onClick={this.toggleSelection}>
                    {iconVisible ? <FontAwesomeIcon icon={faCheck} /> : null} {this.props.date}
                </Link>
            </li>
        );
    }
}

export default PendingRequestDateList;
