import React from "react";
import ArtistProfilePage from "./ArtistProfilePage";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";

interface ArtistProfilePageContainerProps extends RouteProps, withValidUserRoleProps {}

const ArtistProfilePageContainer = (props: ArtistProfilePageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    const searchparam = props.location ? new URLSearchParams(props.location.search.toLowerCase()) : null;
    let businessEntityId: any;
    if (props.location && props.location.search) {
        businessEntityId = searchparam ? searchparam.get("filterby") : "";
    }
    return <ArtistProfilePage title={getTitle()} category={getType()} businessEntityId={businessEntityId} />;
};

export default withValidUserRole(ArtistProfilePageContainer);
