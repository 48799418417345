import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SignUpProfileUpdateGrid from "./SignUpProfileUpdateGrid";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import SignUpProfileUpdateSidePanel from "./SignUpProfileUpdateSidePanel";
import GridTitleCard from "../Cards/GridTitleCard";
import { faUserCog } from "@fortawesome/pro-solid-svg-icons";

interface SignUpProfileUpdatePageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    queryId: string;
}

interface SignUpProfileUpdatePageState {
    statusId: string
}

class SignUpProfileUpdatePage extends Component<SignUpProfileUpdatePageProps> {
    //Initialize the State
    state: SignUpProfileUpdatePageState;

    constructor(props: SignUpProfileUpdatePageProps) {
        super(props);

        this.state = {
            statusId: ""
        };
    }

    onApplyButtonClick = (statusId: string) => {
        // if (this.props?.location?.state) {
        //     this.props.location.state = {};
        // }
        this.setState({
            statusId: statusId
        });
    }

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <SignUpProfileUpdateSidePanel
                        onApplyButtonClick={this.onApplyButtonClick}
                        navigateFrom = {this.props.location && this.props.location.state && this.props.location.state.navigateFrom ? this.props.location.state.navigateFrom : ""}
                    />
                    <BulletinPanel />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mt-3 mb-3">
                        <GridTitleCard title="Sign-Ups / Profile Updates" icon={faUserCog} cardStyle="admin"/>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            {
                                <SignUpProfileUpdateGrid statusId={this.state.statusId}
                                />
                            }
                        </div>
                        <div></div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(SignUpProfileUpdatePage);
