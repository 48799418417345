import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import SimpleGridService, { eventUkUserGridRowItem } from "../services/SimpleGridService";
import CustomStore from "devextreme/data/custom_store";
import { buildRequestParameters } from "../services/RemoteOperationsService";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { AxiosResponse } from "axios";

interface MatchedPopUpCustomizedGridProps extends WithTranslation {

}

interface MatchedPopUpCustomizedGridState {
    dataSource: eventUkUserGridRowItem[]
}

class MatchedPopUpCustomizedGrid extends Component<MatchedPopUpCustomizedGridProps> {
    state: MatchedPopUpCustomizedGridState;
    eventukgridservice: SimpleGridService;

    constructor(props: MatchedPopUpCustomizedGridProps) {
        super(props);
        this.state = {
            dataSource: []
        }
        this.eventukgridservice = new SimpleGridService();
    }



    componentDidMount() {
        this.eventukgridservice.getFilteredShifts("01/05/2022", "10", "", "").then(this.handleSuccess).catch();
    }

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            dataSource: response.data.data.eventUkUserGridLookUp
        });
    }

    renderBlueColouredBlock = (cellInfo: any) => {
        return (
            <div className="grid-info__card_position">
                <p className="grid-info__grid-card--blue">
                    {cellInfo.data.provider}
                    <br></br>
                    {cellInfo.data.venue}<br>
                    </br>
                    {cellInfo.data.dateTo}<br></br>
                    {cellInfo.data.fee_Amount_Local_Ccy}
                </p>
            </div>
        )
    }

    renderGreyColouredBlock = (cellInfo: any) => {
        return (
            <div className="grid-info__card_position">
                <p className="grid-info__grid-card--grey">
                    {cellInfo.data.provider}
                    <br></br>
                    {cellInfo.data.venue}<br>
                    </br>
                    {cellInfo.data.dateTo}<br></br>
                    {cellInfo.data.fee_Amount_Local_Ccy}
                </p>
            </div>
        )
    }

    renderYellowColouredBlock = (cellInfo: any) => {
        return (
            <div className="grid-info__card_position">
                <p className="grid-info__grid-card--yellow">
                    {cellInfo.data.provider}
                    <br></br>
                    {cellInfo.data.venue}<br>
                    </br>
                    {cellInfo.data.dateTo}<br></br>
                    {cellInfo.data.fee_Amount_Local_Ccy}
                </p>
            </div>
        )
    }

    renderGreenColouredBlock = (cellInfo: any) => {
        return (
            <div className="grid-info__card_position">
                <p className="grid-info__grid-card--green">
                    {cellInfo.data.provider}
                    <br></br>
                    {cellInfo.data.venue}<br>
                    </br>
                    {cellInfo.data.dateTo}<br></br>
                    {cellInfo.data.fee_Amount_Local_Ccy}
                </p>
            </div>
        )
    }

    renderOrangeColouredBlock = (cellInfo: any) => {
        return (
            <div className="grid-info__card_position">
                <p className="grid-info__grid-card--orange">
                    {cellInfo.data.provider}
                    <br></br>
                    {cellInfo.data.venue}<br>
                    </br>
                    {cellInfo.data.dateTo}<br></br>
                    {cellInfo.data.fee_Amount_Local_Ccy}
                </p>
            </div>
        )
    }

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    <DataGrid
                        dataSource={this.state.dataSource}
                        //keyExpr="ID"
                        columnAutoWidth={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                    //</div>dataRowRender={DataRow}
                    >
                        <Column caption="MONDAY 21 MARCH" cellRender={this.renderBlueColouredBlock} />
                        <Column caption="TUESDAY 22 MARCH" cellRender={this.renderGreenColouredBlock} />
                        <Column caption="WEDNESDAY 23 MARCH" cellRender={this.renderGreyColouredBlock} />
                        <Column caption="THURSDAY 24 MARCH" cellRender={this.renderOrangeColouredBlock} />
                        <Column caption="FRIDAY 25 MARCH" cellRender={this.renderYellowColouredBlock} />
                        <Column caption="SATURDAY 26 MARCH" />
                        <Column caption="SUNDAY 27 MARCH" />

                    </DataGrid>
                </div>
            </LightModePageTemplate>
        )
    }
}

export default withTranslation()(MatchedPopUpCustomizedGrid);