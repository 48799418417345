import { AxiosResponse } from "axios";
import FAQGrid from "../components/FAQ/FAQGrid";
import { _get, _delete, _post, _aiTraceAsError,_postFormData } from "./BaseApiService";

const RATEMATRIX_API = "RateMatrix";
const PERIODMATRIX_API = "PeriodMatrix";
const BULLETIN_API = "Bulletin";
const FAQ_API = "FAQs";
const USERVIEW_API = "User";
const SERVICETYPEMAPPING_API = "";
const SERVICETYPE_API = "";
const UPLOADMAPPINGS_PROVIDER = "ProviderUploader"
const UPLOADMAPPINGS_CLIENT = "ClientUploader"
const BULLETIN_API_UPLOAD = "Bulletin/Upload";
const BULK_INVITES = 'Contact/SendBulkInvite'
const GET_INVITE_LINK = 'Contact/InviteLink'
const RESET_PASSWORD = '';
const FIND_CONTACT_BULK_INVITES = 'Contact/FindContacts';
const CREATE_UPDATE_USERS = 'Contact/UserRecord';
const CREATE_CONTACTS = 'Contact/CreateContacts';

export interface ProviderUploadMappingsGridRowItem {
    id: number;
    businessEntityId: string;
    legalName:string;
    shortName: string;
}

export interface ClientUploadMappingsGridRowItem {
    id: number;
    businessEntityId: string;
    legalName:string;
    shortName: string;
}

export interface RateMatrixGridRowItem {
    rateMatrixId: number;
    providerBusinessEntityId: string;
    providerBusinessEntity: string;
    clientBusinessEntityId: number;
    clientBusinessEntity: string;
    serviceTypeLookUpId: number;
    serviceTypeLookUp: string;
    serviceSubTypeLookUpId: number;
    serviceSubTypeLookUp: string;
    typeLookUpId: number;
    typeLookUp: string;
    venueId: string;
    venue: string;
    idTypeLookUpId: string;
    idTypeLookUp: string;
    idValue: string;
    rate_Lower: number;
    rate_Higher: number;
    comment: string;
    validationErrors: string;
    name: string;
}

export interface PeriodMatrixGridRowItem {
    id: string;
    businessEntityId: number;
    businessEntityShortName: string;
    daysOfWeekId: string;
    daysOfWeekValue: string;
    periodNumber: string;
    periodWeek: string;
    dateFrom: string;
    dateTo: string;
    active: boolean;
    yearNumber: string;
}

export interface PeriodFormResponse {
    id: string;
    businessEntityId: string;
    businessEntityShortName: string;
    daysOfWeekId: string;
    daysOfWeekValue: string;
    periodNumber: string;
    periodWeek: string;
    dateFrom: string;
    dateTo: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    isActive: boolean;
    yearNumber: string;
}

export interface PeriodFormRequest {
    id: string;
    businessEntityId: string;
    daysOfWeekId: string;
    periodNumber: string;
    periodWeek: string;
    dateFrom: string;
    dateTo: string;
    isActive: boolean;
    yearNumber: string;
}

export interface IbulletinGridRowItem {
    id: string;
    bulletinCategoryId: string;
    bulletinCategoryValue: string;
    bulletinPriorityId: string;
    bulletinPriorityValue: string;
    bulletinTitle: string;
    bulletinName: string;
    bulletinDescription: string;
    bulletinDate: string;
    validFrom: string;
    validTo: string;
    isActive: boolean;
    isPopUpRequired: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    validationErrors: string;
}

export interface IFAQGridRowItem {
    id: string,
    categoryLookUpId: string,
    categoryLookUpValue: string,
    app: string,
    appName: string,
    categoryOrder: string,
    questionOrder: string,
    name: string,
    question: string,
    answer: string,
    isPopUpRequired: boolean,
    faqDate: string,
    validFrom: string,
    validTo: string,
    isActive: boolean,
    lastUpdatedOn: string,
    lastUpdatedBy: string,
    validationErrors: null
}

export interface IbulletinFormRequest {
    id: string;
    bulletinCategoryId: string;
    bulletinPriorityId: string;
    bulletinTitle: string;
    bulletinName: string;
    bulletinDescription: string;
    bulletinDate: string;
    validFrom: string;
    validTo: string;
    isActive: boolean;
    isPopUpRequired: boolean;
    userRoleID: string;
    serviceTypeID: string;
    businessEntityID: string;
    linkId:string;
}

export interface IbulletinFormResponse {
    id: string;
    bulletinCategoryId: string;
    bulletinCategoryValue: string;
    bulletinPriorityId: string;
    bulletinPriorityValue: string;
    bulletinTitle: string;
    bulletinName: string;
    bulletinDescription: string;
    bulletinDate: string;
    validFrom: string;
    validTo: string;
    isActive: boolean;
    isPopUpRequired: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    validationErrors: string;
    userRoleID: string;
    serviceTypeID: string;
    businessEntityID: string;
    linkId:string;
    link:string;
}

export interface IFAQFormRequest {
    id: string,
    categoryLookUpId: string,
    app: number,
    questionOrder: string,
    name: string,
    question: string,
    answer: string,
    isPopUpRequired: boolean,
    faqDate: string,
    validFrom: string,
    validTo: string,
    isActive: boolean
}

export interface IFAQFormResponse {
    id: string,
    categoryLookUpId: string,
    categoryLookUpValue: string,
    app: number,
    appName: string,
    categoryOrder: string,
    questionOrder: string,
    name: string,
    question: string,
    answer: string,
    isPopUpRequired: boolean,
    faqDate: string,
    validFrom: string,
    validTo: string,
    isActive: boolean,
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    validationErrors: [];
}

export interface UserViewGridRowItem {
    id: number;
    company: string;
    name: string;
    email: string;
    role: string;
    isActive: string;
    contactType: string;
    link: string;
    lastUpdated: string;
}

export interface ServiceTypeMappingRowItem {
    id: number;
    serviceTypeLookUpId: number;
    serviceTypeLookUp: string;
    serviceSubTypeLookUpId: number;
    serviceSubTypeLookUp: string;
    typeLookUpId: number;
    typeLookUp: string;
}

export interface ServiceTypeRowItem {
    id: number;
    typeLookUpId: number;
    typeLookUp: string;
}

export interface BulkInviteRowItem {
    contactId?:string;
    name: string;
    email: string;
    providerId: string;
    provider: string;
    userRole: string;
    isUserRecordOk:boolean | null;
    isUserRoleOk:boolean | null;
    isContactRecordOk:boolean | null;
    itemFound: boolean | null;
    itemInvited: boolean | null;
    uuid: number;
    recordId:string;
    errorMessage:any[];
}

export interface BulkInviteMatchingRowItem {
    recordId:string;
    contactId:string;
    name: string;
    email: string;
    providerId: string;
    provider: string;
    itemMatched: boolean | null;
    emailMatched:boolean | null;
    providerMatched : boolean | null;
    providerId_fromAPI?:string;
    provider_fromAPI?:string;
    userRole_fromAPI:string;
    contactTypeId: string;
    contactType: string;
    contactType_fromAPI:string;
    contactActive: boolean | null;
    userId: string | null;
    userRecord:boolean | null;
    userBusinessEntityId:string;
    userActive: boolean | null;
    userRole: string | null;
    userRoleId: string | null;
    canInviteUser:boolean;
    canCRUDUser:boolean;
}

export interface FindContactResponse {
    contactId: string,
    contactName: string,
    contactEmail: string,
    providerId: string,
    provider: string,
    contactTypeId: string
    contactType: string
    contactActive: boolean | null,
    userId: string | null,
    userActive: boolean | null,
    userRole: string,
    userRoleId: string;
    userRecord:boolean | null;
    userBusinessEntityId:string;
    validationErrors : any[];
}

export interface BulkCreateContactResponse {
    active:boolean,
    businessEntityId:string,
    contactId:string,
    email:string,
    name:string,
    recordId:string,
    role:string,
    roleId:string,
    success:boolean,
    validationErrors : any[];
}

export const services = {
    periodMatrix: "PeriodMatrix",
    rateMatrix: "RateMatrix",
    contract: "ContractCleaning",
    userView: "User",
};

export const BULK_FLOW = {
BULK_INVITES:'bulk_invites',
BULK_CREATE_CONTACTS:'bulk_create_contacts'
}

class MatrixGridService {
    //#region Rate Matrix Service Call
    getRateMatrixDataItem(): Promise<AxiosResponse<any>> {
        return _get(RATEMATRIX_API);
    }

    saveRateMatrixDataItem(rateMatrixObject: object) {
        return _post(RATEMATRIX_API, rateMatrixObject);
    }

    deleteRateMatrixDataItem(rateMatrixId: number) {
        return _delete(RATEMATRIX_API + "?id=" + rateMatrixId);
    }
    //#endregion

    //#region Period Matrix Service Call
    getPeriodMatrixDataItem(): Promise<AxiosResponse<any>> {
        return _get(PERIODMATRIX_API);
    }

    getPeriodFormData(id: number) {
        return _get(`${PERIODMATRIX_API}/${id}`);
    }
    postPeriodData(formData: object): Promise<AxiosResponse<any>> {
        return _post(PERIODMATRIX_API, formData);
    }
    submitDeletePeriod(deleteData: string): Promise<AxiosResponse<any>> {
        return _delete(PERIODMATRIX_API + "?id=" + deleteData);
    }
    //#endregion

    //FAQ Grid Region Starts here 
    getFAQDataItem(): Promise<AxiosResponse<any>> {
        return _get(FAQ_API);
    }
    
    getFAQFormData(id: number) {
        return _get(`${FAQ_API}/${id}`);
    }

    postFAQFormData(formData: object): Promise<AxiosResponse<any>> {
        return _post(FAQ_API, formData);
    }

    //Bulletin Grid Region starts here
    getBulletinDataItem(): Promise<AxiosResponse<any>> {
        return _get(BULLETIN_API);
    }

    getBulletinFormData(id: number) {
        return _get(`${BULLETIN_API}/${id}`);
    }

    postBulletinFormData(formData: object): Promise<AxiosResponse<any>> {
        return _post(BULLETIN_API, formData);
    }

    uploadBulletinPhoto(formData: FormData): Promise<AxiosResponse<any>> {
        return _postFormData(BULLETIN_API_UPLOAD, formData);
    }

    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
    //userView Grid Region starts here
    getUserViewDataItem(): Promise<AxiosResponse<any>> {
        return _get(USERVIEW_API);
    }
    // userView Grid endregion

    // ServiceTypeMapping Grid Region starts here
    getServiceTypeMappingDataItem(): Promise<AxiosResponse<any>> {
        return _get(SERVICETYPEMAPPING_API);
    }
    saveServiceTypeMappingDataItem(serviceTypeMappingObject: object) {
        return _post(SERVICETYPEMAPPING_API, serviceTypeMappingObject);
    }
    deleteServiceTypeMappingDataItem(serviceTypeMappingId: number) {
        return _delete(SERVICETYPEMAPPING_API + "?id=" + serviceTypeMappingId);
    }
    //#endregion

    // ServiceTypeMapping Grid Region starts here
    getServiceTypeDataItem(): Promise<AxiosResponse<any>> {
        return _get(SERVICETYPE_API);
    }
    saveServiceTypeDataItem(serviceTypeObject: object) {
        return _post(SERVICETYPE_API, serviceTypeObject);
    }
    //#endregion

    //Provider upload mapping region starts here
    getProviderUploadMappingDataItem(): Promise<AxiosResponse<any>> {
        return _get(UPLOADMAPPINGS_PROVIDER);
    }

    saveProviderUploadMappingDataItem(mappingObject: object) {
        return _post(UPLOADMAPPINGS_PROVIDER, mappingObject);
    }

    deleteProviderUploadMappingDataItem(Id: number) {
        return _delete(UPLOADMAPPINGS_PROVIDER + "?id=" + Id);
    }
    //#endregion

    //Client upload mapping region starts here
    getClientUploadMappingDataItem(): Promise<AxiosResponse<any>> {
        return _get(UPLOADMAPPINGS_CLIENT);
    }

    saveClientUploadMappingDataItem(mappingObject: object) {
        return _post(UPLOADMAPPINGS_CLIENT, mappingObject);
    }

    deleteClientUploadMappingDataItem(Id: number) {
        return _delete(UPLOADMAPPINGS_CLIENT + "?id=" + Id);
    }
    //#endregion
    //b2c integration
    sendBulkInvites(userObjs:any){
        return _post(BULK_INVITES, userObjs);
    }
    getInviteLink(userObj:any){
        return _post(GET_INVITE_LINK, userObj);
    }
    resetPassword(userObj:any){
        return _post(RESET_PASSWORD, userObj);
    }

    fetchContactDetails(emails: any[]): Promise<AxiosResponse<any>> {
        return _post(FIND_CONTACT_BULK_INVITES,emails);
    }

    createOrUpdateUsers(users: any[]): Promise<AxiosResponse<any>> {
        return _post(CREATE_UPDATE_USERS,users);
    }
    createContacts(contacts: any[]): Promise<AxiosResponse<any>> {
        return _post(CREATE_CONTACTS,contacts);
    }
}

export default MatrixGridService;
