import { AxiosResponse } from "axios";
import { _get, _post, _postBatchRequest, _aiTraceAsError } from "./BaseApiService";

// Endpoints
const ADD_CLEANING_API = "BillableItem/AdditionalCleaning";
// Interfaces
export interface AdditionalCleaningRequest {
    venueId: string;
    workDescription: string;
    workDate: string;
    agreedCost: string;
    authorisedBy: string;
}

class AdditionalCleaningFormService {
    // Submit request
    saveAdditionalCleaningItem(additionalCleaningObject: object) {
        return _post(ADD_CLEANING_API, additionalCleaningObject);
    }
    //#endregion
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default AdditionalCleaningFormService;
