import { AxiosResponse } from "axios";
import { _get, _delete, _setURL, _post, _aiTraceAsError } from "./BaseApiService";

const Residency_API = "Residencies";
const Residency_CHECK_DATA_API = "Residencies/Validate"

export interface ResidencyGridRowItem {
    residenciesId: string;
    clientId: string;
    clientName: string;
    serviceTypeId: string;
    serviceTypeName: string;
    serviceSubTypeId: string;
    serviceSubTypeName: string;
    providerId: string;
    providerName: string;
    venueId: string;
    venueName: string;
    isActive: string;
    rate: string;
    dateFrom: string;
    dateTo: string;
    dayId: string;
    day: string;
    startTime: string;
    endTime: string;
    frequencyId: string;
    frequencyName: string;
    feeOverride: string;
    clientNet: string;
    lastUpdatedon: string;
    lastUpdatedBy: string;
}

export interface ResidencyFormResponse {
    residenciesId: string;
    clientId: string;
    clientName: string;
    serviceTypeId: string;
    serviceTypeName: string;
    serviceSubTypeId: string;
    serviceSubTypeName: string;
    providerId: string;
    providerName: string;
    venueId: string;
    venueName: string;
    isActive: string;
    rate: string;
    dateFrom: string;
    dateTo: string;
    dayId: string;
    day: string;
    startTime: string;
    endTime: string;
    frequencyId: string;
    frequencyName: string;
    feeOverride: string;
    clientNet: string;
    typeLookUpId: string;
    feeOverrideFlag: string;
    lastUpdatedon: string;
    lastUpdatedBy: string;
    internalComments: string;
}

export interface ResidencyFormRequest {
    residenciesId: string;
    clientId: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    providerId: string;
    venueId: string;
    isActive: string;
    rate: string;
    dateFrom: string;
    dateTo: string;
    dayId: string;
    startTime: string;
    endTime: string;
    frequencyId: string;
    feeOverride: string;
    clientNet: string;
    typeLookUpId: string;
    feeOverrideFlag: string;
    internalComments: string;
}
export const service = {
    entertainment: "Entertainment",
};
class EntertainmentResidencyService {
    //#region Contract Cleaning Service Call
    getResidencyData(): Promise<AxiosResponse<any>> {
        return _get(Residency_API);
    }
    getResidencyDataItem(residencyId: any): Promise<AxiosResponse<any>> {
        return _get(`${Residency_API}/${residencyId}`);
    }
    getResidencyDataItemByVenue(venueId: any): Promise<AxiosResponse<any>> {
        return _get(Residency_API + `?venueId=${venueId}`);
    }    
    saveResidencyDataItem(residencyObject: object) {
        return _post(Residency_API, residencyObject);
    }
    // validate duplicate data before save
    validateResidencyDataItem(residencyObject: object) {
        return _post(Residency_CHECK_DATA_API, residencyObject);
    }    
    //#endregion
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}
export default EntertainmentResidencyService;
