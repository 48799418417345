import React from "react";
import GigListPage from "./GigListPage";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";

interface GigListPageContainerProps extends RouteProps, withValidUserRoleProps {}

const GigListPageContainer = (props: GigListPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return <GigListPage title={getTitle()} category={getType()} />;
};

export default withValidUserRole(GigListPageContainer);
