import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    DateBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
    NumberBoxOnValueChangedEvent,
    RadioButtonOnSelectionChanged,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faBuilding } from "@fortawesome/pro-regular-svg-icons";
import UserService, { RoleGroupNames } from "../../services/UserService";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import PaymentRequestUtils, { PaymentRequestFormData } from "./PaymentRequestUtils";
import FindAnArtistUtils from '../../components/FindAnArtist/FindAnArtistUtils';
import PaymentService, { CalculationResponse } from "../../services/PaymentService";
import sharedUtils from "../grid/sharedUtilities";
import { DateBox, LoadIndicator, LoadPanel, NumberBox, RadioGroup, SelectBox, TextArea, TextBox, Popup, ScrollView } from "devextreme-react";
import { NullableDate } from "../PostApproval/PostApprovalUtils";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import { PaymentTypes } from "../../services/FindAnArtistService";
import AddEditPopUpService from "../../services/AddEditPopUpService";
import MatchedCard from "./MatchedCard";
import outstandingActionsUtilities, { minuteLookup} from "../../components/Outstanding-Actions/OutstandingActionsUtilities";

interface PaymentRequestFormProps extends WithTranslation {
    location: any;
    history: any;
}
// State
interface PaymentRequestFormState {
    editViewDataItem: PaymentRequestFormData;
    errorMessage: [];
    IsShowSuccess: boolean;
    IsShowError: boolean;
    validationMessage: string;
    isViewMode: boolean;
    loadPanelVisible: boolean;
    serviceTypeDataSource: LookupTypeItem[];
    paymentTypeDataSource: LookupTypeItem[];
    paymentRequestStatusForAddJob: LookupTypeItem[];
    paymentRequestStatusForPayment: LookupTypeItem[];
    offeringsTypeLookupAll :LookupTypeItem[];
    offeringsTypeLookup: LookupTypeItem[];
    isDisableStatusDropDown: boolean;
    rateChangeTimer: any;
    isFasterPayChange: boolean;
    isArtistPayChange: boolean;
    paymentRequestStatusEdit: boolean;
    showDuplicateItem: boolean;
    DuplicateResponse: any;
    loadIndicatorVisible: boolean;
    hours: number;
    minutes: string;
}

class PaymentRequestForm extends React.Component<PaymentRequestFormProps> {
    utils: PaymentRequestUtils;
    artistUtils :FindAnArtistUtils;
    sharedUtils: sharedUtils;
    state: PaymentRequestFormState;
    paymentService: PaymentService;
    lookupService: lookupService;
    editViewService: AddEditPopUpService;
    fasterPaymentDataSource: LookupTypeItem[];
    outStandingActionUtils: outstandingActionsUtilities;
    constructor(props: PaymentRequestFormProps) {
        super(props);
        this.outStandingActionUtils = new outstandingActionsUtilities();
        this.utils = new PaymentRequestUtils();
        this.artistUtils = new FindAnArtistUtils();
        this.sharedUtils = new sharedUtils();
        this.paymentService = new PaymentService();
        this.lookupService = new lookupService();
        this.editViewService = new AddEditPopUpService();
        this.fasterPaymentDataSource = this.lookupService.getDecisionTypes();
        var convertedEditViewDataItem = this.utils.initializeAddEditPaymentItems(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        this.state = {
            editViewDataItem: convertedEditViewDataItem,
            errorMessage: [],
            IsShowSuccess: false,
            IsShowError: false,
            validationMessage: "",
            loadPanelVisible: false,
            isViewMode: this.props.location.state && this.props.location.state.isReadOnly ? this.props.location.state.isReadOnly : false,
            serviceTypeDataSource: [],
            paymentTypeDataSource: [],
            paymentRequestStatusForAddJob: [],
            paymentRequestStatusForPayment: [],
            offeringsTypeLookupAll:[],
            offeringsTypeLookup: [],
            isDisableStatusDropDown: true,
            rateChangeTimer: null,
            isFasterPayChange: false,
            isArtistPayChange: false,
            paymentRequestStatusEdit: false,
            showDuplicateItem: false,
            DuplicateResponse: [],
            loadIndicatorVisible: false,
            hours: 0,
            minutes: "00",
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            this.setState({
                isDisableStatusDropDown: false
            });
        }
        this.lookupService.getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
            .then(this.handleSuccessServiceTypeLookupSuccess)
            .catch(this.handleError);
        this.lookupService.getLookupByLookupTypeIndex(LookupTypeIndexes.paymentrequesttype)
            .then(this.handleSuccessPaymentRequestTypeLookupSuccess)
            .catch(this.handleError);
            this.lookupService.getLookupByLookupTypeIndex(LookupTypeIndexes.offeringsType)
            .then(this.handleTypeLUSuccess)
            .catch(this.handleError);
        this.getPaymentRequestFormDataById();
    }    

    getPaymentRequestFormDataById = () => {
        if (this.props.location.state?.id) {
            this.paymentService.getPaymentRequestFormData(this.props.location.state.id)
                .then(this.handleSuccess)
                .catch(this.handleError)
        }
    }

    getPaymentRequestStatus = () => {
        this.paymentService.getPaymentReqStatusDataSource(LookupTypeIndexes.paymentrequeststatus, "addjob")
            .then(this.handleSuccessAddJobStatusLookupSuccess)
            .catch(this.handleError)
        this.paymentService.getPaymentReqStatusDataSource(LookupTypeIndexes.paymentrequeststatus, "paymentrequest")
            .then(this.handleSuccessPaymentRequestStatusLookupSuccess)
            .catch(this.handleError)
    }

    handleSuccessAddJobStatusLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data.data) {
            this.setState({
                paymentRequestStatusForAddJob: response.data.data
            });
        }
    }


    handleSuccessPaymentRequestStatusLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data.data) {
            this.setState({
                paymentRequestStatusForPayment: response.data.data
            });
        }
    }

    handleSuccessPaymentRequestTypeLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data.data) {
            let paymentTypeDataSource = response.data.data;
            this.setState({
                paymentTypeDataSource: paymentTypeDataSource
            });
        }
    }

    handleSuccessServiceTypeLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data.data) {
            let serviceTypeDataSource = response.data.data.filter((item: any) => {
                return (item.id != ServiceType.Security)
            });
            this.setState({
                serviceTypeDataSource: serviceTypeDataSource
            });
        }
    }

    handleSuccess = (response: AxiosResponse<any>) => {
        this.getPaymentRequestStatus();
        let serviceSubType: string = "";
        let typeLookUpId: string = "";
        let clientBusinessEntityId: string = "";
        let providerBusinessEntityId: string = "";
        let venueId: string = "";
        let paymentRequestTypeId: string = "";
        let startTime: NullableDate = undefined;
        let finishTime: NullableDate = undefined;
        let paymentRequestStatusId: string = "";
        let quantityOrHours: string = "";
        if (response && response.data && response.data.data.serviceSubType && response.data.data.serviceSubType.id) {
            serviceSubType = response.data.data.serviceSubType.id.toString();
        }
        if (response && response.data && response.data.data.clientBusinessEntity && response.data.data.clientBusinessEntity.id) {
            clientBusinessEntityId = response.data.data.clientBusinessEntity.id.toString();
        }
        if (response && response.data && response.data.data.providerBusinessEntity && response.data.data.providerBusinessEntity.id) {
            providerBusinessEntityId = response.data.data.providerBusinessEntity.id.toString();
        }
        if (response && response.data && response.data.data.venue && response.data.data.venue.id) {
            venueId = response.data.data.venue.id.toString();
        }
        if (response && response.data && response.data.data.typeLookUp && response.data.data.typeLookUp.id) {
            typeLookUpId = response.data.data.typeLookUp.id.toString();
        }
        if (response && response.data && response.data.data.paymentRequestType && response.data.data.paymentRequestType.id) {
            paymentRequestTypeId = response.data.data.paymentRequestType.id.toString();
        }
        if (response && response.data && response.data.data.startTime) {
            startTime = this.sharedUtils.convertStringToTime(response.data.data.startTime);
        }
        if (response && response.data && response.data.data.finishTime) {
            finishTime = this.sharedUtils.convertStringToTime(response.data.data.finishTime);
        }
        if (response && response.data && response.data.data.quantityOrHours !== null && response.data.data.quantityOrHours !== "") {
            quantityOrHours = response.data.data.quantityOrHours.toString();

            // converting back quantity into hours and min for weekly cleaning only
            if (serviceSubType == SubServiceType.WeeklyCleaning) {
                let quantitySubFields: string[] = this.outStandingActionUtils.splitQuantityIntoHoursMinutesFormat(response.data.data.quantityOrHours);               
                this.setState({
                    hours:
                        quantitySubFields.length > 0 && quantitySubFields[0]
                            ? this.sharedUtils.convertStringToInt(quantitySubFields[0])
                            : 0,
                    minutes: quantitySubFields.length > 0 && quantitySubFields[1] ? quantitySubFields[1] : "00",
                })
            }
        }
        if (response && response.data && response.data.data.paymentRequestStatusLookUp && response.data.data.paymentRequestStatusLookUp.id) {
            paymentRequestStatusId = response.data.data.paymentRequestStatusLookUp.id.toString();
            if(paymentRequestStatusId == "40" || paymentRequestStatusId == "63"){
                this.setState({ paymentRequestStatusEdit: true })
            }
            else{
                this.setState({ paymentRequestStatusEdit: false }) 
            }            
        }
        if (response && response.data) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    clientBusinessEntityId: clientBusinessEntityId,
                    providerBusinessEntityId: providerBusinessEntityId,
                    venueId: venueId,
                    serviceTypeId: response.data.data.serviceType.id.toString(),
                    serviceSubTypeId: serviceSubType,
                    typeLookUpId: typeLookUpId,
                    startTime: startTime,
                    finishTime: finishTime,
                    description: response.data.data.description,
                    otherDescription: response.data.data.otherDescription,
                    dateFrom: this.sharedUtils.convertStringToDate(response.data.data.dateFrom),
                    dateTo: this.sharedUtils.convertStringToDate(response.data.data.dateTo),
                    providerPayAmountLocal: this.sharedUtils.convertStringToFloat(response.data.data.providerPayAmountLocal),
                    comments: response.data.data.comments,
                    internalComments: response.data.data.internalComments,
                    requestDate: response.data.data.requestDate,
                    requestDate_Web: response.data.data.requestDate_Web,
                    requestApprover:response.data.data.requestApprover,
                    status: response.data.data.paymentRequestStatusLookUp.value,
                    id: response.data.data.id,
                    fasterPayment: this.sharedUtils.convertBooleanToString(response.data.data.fasterPayment),
                    paymentRequestTypeID: paymentRequestTypeId,
                    paymentRequestStatusID: paymentRequestStatusId,
                    providerPayAmountLocalExtras: this.sharedUtils.convertStringToFloat(response.data.data.providerPayAmountLocalExtras),
                    providerPayAmountLocalWOExtras: this.sharedUtils.convertStringToFloat(response.data.data.providerPayAmountLocalWOExtras),
                    providerPayAmountLocalExtrasDescription: response.data.data.providerPayAmountLocalExtrasDescription,
                    providerFasterPayFee: this.sharedUtils.convertStringToFloat(response.data.data.providerFasterPayFee),
                    providerFasterPayOriginalAmount: this.sharedUtils.convertStringToFloat(response.data.data.providerFasterPayOriginalAmount),
                    rate: this.sharedUtils.convertStringToFloat(response.data.data.rate),
                    quantityOrHours: quantityOrHours,
                    venueDetails: response.data.data.venueDetails,
                    houseNumber: response.data.data.houseNumber,
                    client: response.data.data.client,
                    subType:response.data?.data?.subType,
                    venue: {
                        id: response.data?.data?.venue?.id,
                        houseNumber: response.data?.data?.venue?.houseNumber,
                        venueName: response.data?.data?.venue?.venueName,
                        address:response.data?.data?.venue?.address,
                        city:response.data?.data?.venue?.city,
                        county:response.data?.data?.venue?.county,
                        postCode:response.data?.data?.venue?.postCode
                    }
                },
            },()=>{
                this.filterDataSource(this.state.offeringsTypeLookupAll, this.state.editViewDataItem.serviceSubTypeId);
            })
        }
    }

    handleError = (error: AxiosResponse<any>) => {
        var respMessage: string = "Payment request form load failed with response: " + JSON.stringify(error);

        if (!this.paymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }
    handleTypeLUSuccess = (response: AxiosResponse<any>) => {
        let typeDropDown = this.artistUtils.lookUpCreation(response);
        this.setState({
            offeringsTypeLookupAll: typeDropDown,            
        });
    };
    filterDataSource = (data: LookupTypeItem[], filterId: string) => {
        let typeLookupItems = !this.artistUtils.isBlank(filterId)
            ? data.filter((item: LookupTypeItem) => {
                return item.parentMappingId === filterId;
            })
            :[]
            //: data;        
        this.setState({
            offeringsTypeLookup: typeLookupItems
        }, () => { 
        });
    };

    handleChangeProviderTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerBusinessEntityId: dxValueChange.value,
                },
            });
        }
    }

    handleChangePaymentRequestType = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    paymentRequestTypeID: dxValueChange.value,
                },
                isDisableStatusDropDown: false
            });
        }
    }

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    clientBusinessEntityId: dxValueChange.value,
                },
            });
        }
    }

    handleChangePaymentStatus = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    paymentRequestStatusID: dxValueChange.value,
                },
            });
        }
    }

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    venueId: dxValueChange.value,
                },
            });
        }
    }

    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    serviceTypeId: dxValueChange.value,
                    serviceSubTypeId:'',
                    typeLookUpId: "",
                },
            },()=>{
                this.filterDataSource(this.state.offeringsTypeLookupAll, this.state.editViewDataItem.serviceSubTypeId);
            });
        }
    }

    handleChangeServiceSubTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    serviceSubTypeId: dxValueChange.value,
                    typeLookUpId: "",
                    otherDescription: "",
                },
            },()=>{
                this.filterDataSource(this.state.offeringsTypeLookupAll, this.state.editViewDataItem.serviceSubTypeId);
            });
        }
    }

    handleChangeTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    typeLookUpId: dxValueChange.value,
                    otherDescription: ""
                },
            });
        }
    };

    handleChangeOtherDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    otherDescription: dxValueChange.value,
                },
            });
        }
    };

    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    comments: dxValueChange.value,
                },
            });
        }
    }
    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    description: dxValueChange.value,
                },
            });
        }
    }
    handleChangeExtrasDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerPayAmountLocalExtrasDescription: dxValueChange.value,
                },
            });
        }
    }
    handleChangeInternalComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    internalComments: dxValueChange.value,
                },
            });
        }
    }

    handleChangeDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    dateFrom: dxValueChange.value,
                },
            });
        }
    }

    handleChangeDateTo = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    dateTo: dxValueChange.value,
                },
            });
        }
    }

    handleChangeStartTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    startTime: dxValueChange.value,
                },
            });
        }
    };

    handleChangeFinishTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    finishTime: dxValueChange.value,
                },
            });
        }
    };

    handleChangeProviderLocalCurrancyAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.editViewDataItem.providerPayAmountLocal !== dxValueChange.value) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerPayAmountLocal: dxValueChange.value,
                },
            });
        }
    }

    handleChangeExtraAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerPayAmountLocalExtras: dxValueChange.value,
                },
            });
        }
        if (dxValueChange.value == 0 || dxValueChange.value == undefined) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerPayAmountLocalExtrasDescription: "",
                },
            });
        }
    }

    handleChangeFasterPayFee = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.editViewDataItem.providerFasterPayFee !== dxValueChange.value) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerFasterPayFee: dxValueChange.value,
                },
                isFasterPayChange: true,
            });

            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        }
    }

    calculateProviderNet = () => {
        let rate:number = this.state.editViewDataItem.rate ? this.state.editViewDataItem.rate : 0;
        let quantity:number = this.state.editViewDataItem.quantityOrHours ? this.state.editViewDataItem.quantityOrHours : 1;
        let amount = rate * quantity;
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                providerFasterPayOriginalAmount: amount,
            },
            isArtistPayChange: true,
        }, () =>{
            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        });

    }

    handleChangeQuantity = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.editViewDataItem.quantityOrHours !== dxValueChange.value) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    quantityOrHours: dxValueChange.value,
                },
            },() =>{
                setTimeout(this.calculateProviderNet, 1000);
            });
        }
    }

    handleChangeRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.editViewDataItem.rate !== dxValueChange.value) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    rate: dxValueChange.value,
                },
            },() =>{
                setTimeout(this.calculateProviderNet, 1000);
            });
        }
    }

    handleChangeOriginalAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                providerFasterPayOriginalAmount: dxValueChange.value,
            },
            isArtistPayChange: true,
        });

        this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
    }


    handleChangeFasterPaymentApplicable = (dxValue: RadioButtonOnSelectionChanged) => {
        if (this.state.editViewDataItem.fasterPayment !== dxValue.value) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    fasterPayment: dxValue.value,
                },
            });

            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        }
    }

    onSubmit = () => {
        this.setState({
            loadPanelVisible: true,
        });
        var paymentRequestSubmitRequest = this.utils.convertFormDataToPaymentRequest(this.state.editViewDataItem);
        //before submitting check duplicate records
        this.paymentService
            .postPaymentRequestValidateData(paymentRequestSubmitRequest)
            .then(this.handlePaymentCheckSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                    IsShowSuccess: false,
                });
                this.failureMessageVisible();
                this.hideLoadpanel();
            })
            .finally(() => {
                this.hideLoadpanel();
            });      

    };

    onProceed = () => {
        this.setState({
            loadPanelVisible: true,
        });
        let paymentRequestSubmitRequest = this.utils.convertFormDataToPaymentRequest(this.state.editViewDataItem);
        if(paymentRequestSubmitRequest.paymentRequestStatusID == "10"){
            paymentRequestSubmitRequest.paymentRequestStatusID = "20"
        }      
        if(paymentRequestSubmitRequest.paymentRequestStatusID == "61"){
            paymentRequestSubmitRequest.paymentRequestStatusID = "62"
        } 
         //passing object data to the server
        this.paymentService
        .postPaymentRequestFormData(paymentRequestSubmitRequest)
        .then(this.handleFormDataSubmissionSuccess)
        .catch((err) => {
            this.setState({
                errorMessage:
                    err.response && err.response.data && err.response.data.error
                        ? JSON.parse(JSON.stringify(err.response.data.error))
                        : null,
                IsShowSuccess: false,
            });
            this.failureMessageVisible();
            this.hideLoadpanel();
        })
        .finally(() => {
            this.hideLoadpanel();
        });  
    };

    hideDuplicateItem = () => {
        this.setState({
            showDuplicateItem: false
        });
    };

    handlePaymentCheckSuccess = (response: AxiosResponse<any>) => {
        let serverResponse: any = response.data.data.data;        
        this.hideLoadIndicator();
        this.setState({
            IsShowError: false,
        });
        if(serverResponse.length > 0){
            this.setState({
                showDuplicateItem: true,
                DuplicateResponse: serverResponse
            });  
        }
        else {
            this.setState({
                loadPanelVisible: true,
            });
            let paymentRequestSubmitRequest = this.utils.convertFormDataToPaymentRequest(this.state.editViewDataItem);
            if(paymentRequestSubmitRequest.paymentRequestStatusID == "10"){
                paymentRequestSubmitRequest.paymentRequestStatusID = "20"
            }      
            if(paymentRequestSubmitRequest.paymentRequestStatusID == "61"){
                paymentRequestSubmitRequest.paymentRequestStatusID = "62"
            } 
            //passing object data to the server
            this.paymentService
                .postPaymentRequestFormData(paymentRequestSubmitRequest)
                .then(this.handleFormDataSubmissionSuccess)
                .catch((err) => {
                    this.setState({
                        errorMessage:
                            err.response && err.response.data && err.response.data.error
                                ? JSON.parse(JSON.stringify(err.response.data.error))
                                : null,
                        IsShowSuccess: false,
                    });
                    this.failureMessageVisible();
                    this.hideLoadpanel();
                })
                .finally(() => {
                    this.hideLoadpanel();
                });   
        }    
    };

    handleFormDataSubmissionSuccess = () => {
        this.hideLoadpanel();
        this.successMessageVisible();
        this.setState({
            IsShowError: false,
        });
        this.renderRedirect();
    };

    startCallingFinancialAdjustment = () => {
        if (this.state.rateChangeTimer) {
            clearTimeout(this.state.rateChangeTimer);
            this.state.rateChangeTimer = null;
            if (this.state.editViewDataItem.providerFasterPayOriginalAmount || this.state.editViewDataItem.providerFasterPayFee) {
                this.financialCalculations();
            }
        }
    };

    hideLoadIndicator = () => {
        this.setState({
            loadIndicatorVisible: false,
        });
    };

    showLoadIndicator = () => {
        this.setState({ loadIndicatorVisible: true });
    };

    financialCalculations = () => {
        this.showLoadIndicator();
        if (!this.state.isFasterPayChange && !this.state.isArtistPayChange && (this.state.editViewDataItem.fasterPayment == "true" || this.state.editViewDataItem.fasterPayment.toString() == "Yes")) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerFasterPayFee: "",
                }
            });
        }
        if (this.state.editViewDataItem.fasterPayment.toString() !== "true" || this.state.editViewDataItem.fasterPayment.toString() !== "Yes") {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerPayAmountLocal: this.state.editViewDataItem.providerFasterPayOriginalAmount,
                }
            });
        }
        if (this.state.isArtistPayChange) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    providerFasterPayFee: "",
                }
            });
        }
        let calCulationObject = this.utils.convertPayCalcDataToCalcRequest(
            this.state.editViewDataItem,
            null,
            undefined,
            this.props.location && this.props.location.state ? this.props.location.state.id : this.state.editViewDataItem.id
        );

        this.editViewService
            .uploadPaymentFormData(calCulationObject)
            .then(this.handleCalculationSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    IsShowSuccess: false,
                });
                this.failureMessageVisible();
                this.hideLoadIndicator();
            })
            .finally(() => {
                this.hideLoadIndicator();
            });

    };

    handleCalculationSuccess = (response: AxiosResponse<any>) => {
        let serverResponse: CalculationResponse = response.data.data;
        this.hideLoadIndicator();

        this.setState({
            IsShowError: false,
            isFasterPayChange: false,
            isArtistPayChange: false,
        });
        // Convert server response to proper data types
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                providerPayAmountLocal: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocal),
                providerPayAmountLocalExtras: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocalExtras),
                providerFasterPayFee: this.sharedUtils.convertStringToFloat(serverResponse.providerFasterPayFee),
                providerFasterPayOriginalAmount: this.sharedUtils.convertStringToFloat(serverResponse.providerFasterPayOriginalAmount),
            }
        })
    };

    failureMessageVisible = () => {
        this.setState({
            IsShowError: true,
        });
    };

    hideLoadpanel = () => {
        this.setState({ loadPanelVisible: false });
    };

    successMessageVisible = () => {
        this.setState({
            IsShowSuccess: true,
        });
    };

    renderRedirect = () => {
        this.props.history.push({
            pathname: "/paymentRequests",
            state: {
                pointOfNavigation: "popUp"
            },
        });
    };

    onCancel = () => {
        this.props.history.push({
            pathname: "/paymentRequests",
            state: {
                pointOfNavigation: "popUp"
            },
        });
    }
    onContentReadyNumberBox = (e: any) => {
        this.onClickElementSelection(e);
    }

    onClickElementSelection = (e: any) => {
        e.element.addEventListener('click', function () {
            let inputElement = e.element.querySelector(".dx-texteditor-input");
            inputElement.selectionStart = 0;
            inputElement.selectionEnd = inputElement.value.length;
        });
    }

    handleChangeHours = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                hours: dxValueChange.value,
            });
            setTimeout(this.reCalculateQuantity, 1000);
        }
    };

    handleChangeMinutes = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                minutes: dxValueChange.value,
            });
            setTimeout(this.reCalculateQuantity, 1000);
        }
    };

    //Helper function to recalulate the Quantity when either of the minute or Hour field varies.
    reCalculateQuantity = () => {
        var delimeter = ".";
        var { hours, minutes } = this.state; //Destructure the state to pull out the contents.
        var convertedHours: string = this.sharedUtils.convertNumberToString(hours);
        var quantity: string = `${convertedHours}${delimeter}${minutes}`;
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                quantityOrHours: this.sharedUtils.convertStringToFloat(quantity),
            },
        },() =>{
            setTimeout(this.calculateProviderNet, 1000);
        });
    };

    approverDetails = () => {
        let approver;
        if (this.state.editViewDataItem.requestApprover) {
            approver = <b> ({this.state.editViewDataItem.requestApprover})</b>
        }
        return approver;
    }

    getAddress = () => {
        let address = "";
        if (this.props.location && this.props.location.state && this.props.location.state.id &&  this.props.location.state.id != "0") {
            if (this.state.editViewDataItem && this.state.editViewDataItem.venue) {
                let address1 = this.state.editViewDataItem.venue?.address || '';
                let city = this.state.editViewDataItem.venue?.city || '';
                let county = this.state.editViewDataItem.venue?.county || '';
                let postCode = this.state.editViewDataItem.venue?.postCode || '';

                address = address1;
                if (city) {
                    if(address && city){
                        address += `, ${city}`;
                    } else {
                        address += city ? city :'';
                    }
                    //address += ", " + city;
                }
                if (county) {
                    if(address && county){
                        address += `, ${county}`;
                    } else {
                        address += county ? county :'';
                    }
                    //address += ", " + county;
                }
                if (postCode) {
                    if(address && postCode){
                        address += `, ${postCode}`;
                    } else {
                        address += postCode ? postCode :'';
                    }
                    //address += ", " + postCode;
                }
                if (address) {
                    return (<div className="card-form__field">
                        <FontAwesomeIcon icon={faBuilding} />
                        <span style={{paddingLeft:'10px'}}>{address}</span>
                    </div>)
                }
            }
            return;
        }
        return;
    };

    showTypeSelectBox = ()=>{
        let isShow = true;
        if(this.state.offeringsTypeLookup && this.state.offeringsTypeLookup.length <= 0){
            isShow = false;
        }
        return isShow;
    }

    render() {
        var isEventUKUser = (UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager));
        let statusDataSource : LookupTypeItem[] = [];
        let EditStatusDataSource : LookupTypeItem[] = [];
        let showOtherDesc: boolean = false;
        //let showType: boolean = true;
        let showQuantity: boolean = true;
        // if (this.state.editViewDataItem.serviceTypeId == ServiceType.Maintenance || this.state.editViewDataItem.serviceTypeId == ServiceType.Miscelleanous 
        //     || (this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning && this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning) 
        //     || (this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment && this.state.editViewDataItem.serviceSubTypeId !== "31" && this.state.editViewDataItem.serviceSubTypeId !== "32" && this.state.editViewDataItem.serviceSubTypeId !== "34")) {
        //     showType = false;
        // }                 
        if ((this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment && this.state.editViewDataItem.serviceSubTypeId == "50") || 
            (this.state.editViewDataItem.serviceTypeId == ServiceType.Maintenance && this.state.editViewDataItem.serviceSubTypeId == "83") || 
            (this.state.editViewDataItem.serviceTypeId == ServiceType.Miscelleanous && this.state.editViewDataItem.serviceSubTypeId == "83") || 
            (this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning && this.state.editViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning && this.state.editViewDataItem.typeLookUpId == "227") ||
            (this.state.editViewDataItem.serviceTypeId == ServiceType.WindowCleaning && this.state.editViewDataItem.serviceSubTypeId == "108" && this.state.editViewDataItem.typeLookUpId == "516") || 
            (this.state.editViewDataItem.serviceTypeId == ServiceType.Gardening && this.state.editViewDataItem.serviceSubTypeId == "106" && this.state.editViewDataItem.typeLookUpId == "405")) {
                showOtherDesc = true;
        }
        if (this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning && this.state.editViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning) {
                showQuantity = false;
        } 
        if(this.state.editViewDataItem.paymentRequestTypeID == PaymentTypes.PaymentRequest){
            EditStatusDataSource = this.state.paymentRequestStatusForPayment;
            statusDataSource = EditStatusDataSource.filter(item => item.id !== "40");
        }
        else if(this.state.editViewDataItem.paymentRequestTypeID == PaymentTypes.AddJob){
            EditStatusDataSource = this.state.paymentRequestStatusForAddJob;
            statusDataSource = EditStatusDataSource.filter(item => item.id !== "63");
        }
        let header: string = "Payment Request Notification";
        if (this.state.editViewDataItem.paymentRequestTypeID == PaymentTypes.AddJob) {
            header = "Add Job Notification";
        }
        let finalFormattedDate: string = "";
        if(this.state.editViewDataItem.requestDate_Web){           
            let dateCreatedTime = this.state.editViewDataItem.requestDate_Web;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        return (
            <LightModePageTemplate>
                <div className="page-content--with-sidebar">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <section>
                                        <h5>
                                            <b>{header}</b>
                                        </h5>
                                        <div className="card-form__field card-form__field--narrow">Type - Add Job or Payment Request<sup className="card-form__mandatory-symbol">*</sup>
                                            <DropDownFromLookupTable
                                                lookupTypeIndex={LookupTypeIndexes.paymentrequesttype}
                                                onValueChanged={this.handleChangePaymentRequestType}
                                                value={this.state.editViewDataItem.paymentRequestTypeID}
                                                disableSignal={this.state.isViewMode}
                                            />
                                            <input
                                                data-testid={LookupTypeIndexes.paymentrequesttype}
                                                type="hidden"
                                                name={LookupTypeIndexes.paymentrequesttype}
                                                value={
                                                    this.state.editViewDataItem.paymentRequestTypeID
                                                        ? this.state.editViewDataItem.paymentRequestTypeID
                                                        : 0
                                                }
                                            ></input>
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">Status<sup className="card-form__mandatory-symbol">*</sup>
                                            <SelectBox
                                                dataSource={this.state.paymentRequestStatusEdit ? EditStatusDataSource : statusDataSource}
                                                displayExpr={"value"}
                                                valueExpr={"id"}
                                                value={this.state.editViewDataItem.paymentRequestStatusID
                                                    ? this.state.editViewDataItem.paymentRequestStatusID
                                                    : ""}
                                                onValueChanged={this.handleChangePaymentStatus}
                                                disabled={this.state.isViewMode || this.state.isDisableStatusDropDown
                                                    || this.state.editViewDataItem.paymentRequestStatusID == "40" || this.state.editViewDataItem.paymentRequestStatusID == "63"}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">Provider <sup className="card-form__mandatory-symbol">*</sup>
                                            <LookupTypeListDynamic
                                                lookupTypeIndex={LookupTypeIndexes.providerType}
                                                onValueChanged={this.handleChangeProviderTypeSelect}
                                                isRoot={false}
                                                parentMappingId={""}
                                                displayExpression={"value"}
                                                value={this.state.editViewDataItem.providerBusinessEntityId}
                                                isDisabled={this.state.isViewMode}
                                            />
                                        </div>
                                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                                        <div className="card-form__field">Client<sup className="card-form__mandatory-symbol">*</sup>
                                            <div className="row mx-0">
                                                <div className="col-12 col-lg-6 row">
                                                    <div className="client-width">
                                                        <DropDownFromLookupTable
                                                            lookupTypeIndex={LookupTypeIndexes.clientType}
                                                            onValueChanged={this.handleChangeClientTypeSelect}
                                                            value={this.state.editViewDataItem.clientBusinessEntityId}
                                                            disableSignal={this.state.isViewMode}
                                                        />
                                                        <input
                                                            data-testid={LookupTypeIndexes.clientType}
                                                            type="hidden"
                                                            name={LookupTypeIndexes.clientType}
                                                            value={
                                                                this.state.editViewDataItem.clientBusinessEntityId
                                                                    ? this.state.editViewDataItem.clientBusinessEntityId
                                                                    : 0
                                                            }
                                                        ></input>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6 row mx-0" hidden={this.state.editViewDataItem.paymentRequestStatusID !== "10" && this.state.editViewDataItem.paymentRequestStatusID !== "61"}>
                                                    <div className="payment-box">
                                                        <TextBox
                                                            disabled={true}
                                                            value={this.state.editViewDataItem.client}
                                                        ></TextBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-form__field">Venue<sup className="card-form__mandatory-symbol">*</sup>                                            

                                            <div className="row mx-0">
                                                <div className="col-12 col-lg-6 row">
                                                    <div className="client-width">
                                                        <LookupTypeListDynamic
                                                            lookupTypeIndex={LookupTypeIndexes.venueType}
                                                            onValueChanged={this.handleChangeVenueTypeSelect}
                                                            isRoot={false}
                                                            parentMappingId={this.state.editViewDataItem.clientBusinessEntityId}
                                                            value={this.state.editViewDataItem.venueId}
                                                            displayExpression={"value"}
                                                            isDisabled={this.state.isViewMode}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-2 row mx-0" hidden={this.state.editViewDataItem.paymentRequestStatusID !== "10" && this.state.editViewDataItem.paymentRequestStatusID !== "61"}>
                                                    <div className="house-box">
                                                        <TextBox
                                                            disabled={true}
                                                            value={this.state.editViewDataItem.houseNumber}
                                                        ></TextBox>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4 row mx-0" hidden={this.state.editViewDataItem.paymentRequestStatusID !== "10" && this.state.editViewDataItem.paymentRequestStatusID !== "61"}>
                                                    <div className="venue-box">
                                                        <TextBox
                                                            disabled={true}
                                                            value={this.state.editViewDataItem.venueDetails}
                                                        ></TextBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         {this.getAddress()}
                                        <div className="card-form__field card-form__field--narrow">Service<sup className="card-form__mandatory-symbol">*</sup>
                                            <SelectBox
                                                dataSource={this.state.serviceTypeDataSource}
                                                displayExpr={"value"}
                                                valueExpr={"id"}
                                                value={this.state.editViewDataItem.serviceTypeId
                                                    ? this.state.editViewDataItem.serviceTypeId
                                                    : ""}
                                                onValueChanged={this.handleChangeServiceTypeSelect}
                                                disabled={this.state.isViewMode}
                                            />
                                        </div>
                       
                                        <div className="card-form__field">Sub Type<sup className="card-form__mandatory-symbol">*</sup>
                                            <div className="row mx-0">
                                                <div className="col-12 col-lg-6 row">
                                                    <div className="client-width">
                                                        <DropDownFromLookupTableDynamic
                                                            lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                                            isRoot={false}
                                                            parentMappingId={this.state.editViewDataItem.serviceTypeId}
                                                            onValueChanged={this.handleChangeServiceSubTypeSelect}
                                                            isDisabled={this.state.isViewMode}
                                                            value={this.state.editViewDataItem.serviceSubTypeId} />
                                                        <input
                                                            data-testid={LookupTypeIndexes.subServiceType}
                                                            type="hidden"
                                                            name={LookupTypeIndexes.subServiceType}
                                                            value={
                                                                this.state.editViewDataItem.serviceSubTypeId
                                                                    ? this.state.editViewDataItem.serviceSubTypeId
                                                                    : ""
                                                            }></input>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6 row mx-0" hidden={this.state.editViewDataItem.paymentRequestStatusID !== "10" && this.state.editViewDataItem.paymentRequestStatusID !== "61"}>
                                                    <div className="payment-box">
                                                        <TextBox
                                                            disabled={true}
                                                            value={this.state.editViewDataItem.subType}
                                                        ></TextBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="card-form__field card-form__field--narrow" hidden={!showType}>Type
                                            <DropDownFromLookupTableDynamic
                                                lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                                isRoot={false}
                                                parentMappingId={this.state.editViewDataItem.serviceSubTypeId}
                                                onValueChanged={this.handleChangeTypeSelect}

                                                value={this.state.editViewDataItem.typeLookUpId}
                                            />
                                            <input
                                                data-testid={LookupTypeIndexes.offeringsType}
                                                type="hidden"
                                                name={LookupTypeIndexes.offeringsType}
                                                value={
                                                    this.state.editViewDataItem.typeLookUpId
                                                        ? this.state.editViewDataItem.typeLookUpId
                                                        : ""
                                                }
                                            ></input>
                                        </div> */}
                                        <div className="card-form__field card-form__field--narrow" hidden={!this.showTypeSelectBox()}>Type
                                            <SelectBox
                                                dataSource={this.state.offeringsTypeLookup}
                                                displayExpr={"value"}
                                                valueExpr={"id"}
                                                onValueChanged={this.handleChangeTypeSelect}
                                                value = {
                                                    this.state.editViewDataItem.typeLookUpId
                                                        ? this.state.editViewDataItem.typeLookUpId
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow"  hidden={!showOtherDesc}>
                                            Description
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                            <TextArea
                                                height={100}
                                                value={this.state.editViewDataItem.otherDescription}
                                                onValueChanged={this.handleChangeOtherDescription}
                                                disabled={this.state.isViewMode}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow"   hidden={showOtherDesc}>Description
                                            <sup className="card-form__mandatory-symbol" hidden={this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment || this.state.editViewDataItem.serviceTypeId == ServiceType.ContractCleaning || 
                                                this.state.editViewDataItem.serviceTypeId == ServiceType.WindowCleaning || this.state.editViewDataItem.serviceTypeId == ServiceType.Gardening}>*</sup>
                                            <TextArea
                                                height={100}
                                                value={this.state.editViewDataItem.description}
                                                onValueChanged={this.handleChangeDescription}
                                                disabled={this.state.isViewMode}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">Date Worked<sup className="card-form__mandatory-symbol">*</sup>

                                            <DateBox

                                                onValueChanged={this.handleChangeDate}
                                                value={

                                                    this.state.editViewDataItem.dateFrom
                                                        ? this.state.editViewDataItem.dateFrom
                                                        : undefined
                                                }
                                                useMaskBehavior={true}
                                                displayFormat="dd/MM/yyyy"
                                                disabled={this.state.isViewMode}
                                            ></DateBox>
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">Date Worked To
                                        {/* <sup className="card-form__mandatory-symbol">*</sup> */}
                                            <DateBox
                                                onValueChanged={this.handleChangeDateTo}
                                                value={

                                                    this.state.editViewDataItem.dateTo
                                                        ? this.state.editViewDataItem.dateTo
                                                        : undefined
                                                }
                                                useMaskBehavior={true}
                                                displayFormat="dd/MM/yyyy"
                                                disabled={this.state.isViewMode}
                                            ></DateBox>
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">Start<sup className="card-form__mandatory-symbol" hidden={this.state.editViewDataItem.serviceTypeId !== ServiceType.Entertainment}>*</sup>
                                            <DateBox
                                                type="time"
                                                interval={15}
                                                placeholder="00:00"
                                                useMaskBehavior={true}
                                                displayFormat={"HH:mm"}
                                                disabled={this.state.isViewMode}
                                                onValueChanged={this.handleChangeStartTime}
                                                value={
                                                    this.state.editViewDataItem.startTime
                                                        ? this.state.editViewDataItem.startTime
                                                        : undefined
                                                }
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">Finish
                                            <DateBox
                                                type="time"
                                                interval={15}
                                                placeholder="00:00"
                                                useMaskBehavior={true}
                                                displayFormat={"HH:mm"}
                                                disabled={this.state.isViewMode}
                                                onValueChanged={this.handleChangeFinishTime}
                                                value={
                                                    this.state.editViewDataItem.finishTime
                                                        ? this.state.editViewDataItem.finishTime
                                                        : undefined
                                                }
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">Faster Payments?
                                            <RadioGroup
                                                items={this.fasterPaymentDataSource.length > 0 ? this.fasterPaymentDataSource : []}
                                                displayExpr="value"
                                                valueExpr="value"
                                                value={
                                                    this.state.editViewDataItem.fasterPayment == "Yes" ||
                                                        this.state.editViewDataItem.fasterPayment == "true"
                                                        ? "Yes"
                                                        : "No"
                                                }
                                                onValueChanged={this.handleChangeFasterPaymentApplicable}
                                                layout="horizontal"
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow" hidden={showQuantity}>Hours
                                            <NumberBox
                                                onValueChanged={this.handleChangeHours}
                                                valueChangeEvent="keyup"
                                                value={this.state.hours}
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow" hidden={showQuantity}>Min
                                            <SelectBox
                                                dataSource={minuteLookup}
                                                displayExpr="value"
                                                valueExpr="id"
                                                onValueChanged={this.handleChangeMinutes}
                                                value={this.state.minutes}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow" hidden={!showQuantity || this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment}>
                                            Quantity
                                            <NumberBox
                                                onValueChanged={this.handleChangeQuantity}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.quantityOrHours}
                                                step={0}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">
                                            Rate
                                            <NumberBox
                                                format={{
                                                    type: "currency",
                                                    precision: 2,
                                                    currency: "GBP",
                                                }}
                                                onValueChanged={this.handleChangeRate}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.rate}
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">
                                            {this.state.editViewDataItem.serviceTypeId == ServiceType.Entertainment ? "Original Artist Fee" : "Provider Net"}
                                            <sup className="card-form__mandatory-symbol">*</sup>
                                            <NumberBox
                                                format={{
                                                    type: "currency",
                                                    precision: 2,
                                                    currency: "GBP",
                                                }}
                                                onValueChanged={this.handleChangeOriginalAmount}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.providerFasterPayOriginalAmount}
                                                step={0}
                                                disabled={this.state.isViewMode}
                                                onContentReady={this.onContentReadyNumberBox}
                                            />
                                            <div className="w-50 mx-auto">
                                                <LoadIndicator
                                                    id="load-indicator"
                                                    className="load-indicator"
                                                    height={40}
                                                    width={40}
                                                    visible={this.state.loadIndicatorVisible}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="card-form__field card-form__field--narrow">

                                        </div> */}
                                        <div className="card-form__field card-form__field--narrow" hidden={this.state.editViewDataItem.fasterPayment == "No" || this.state.editViewDataItem.fasterPayment == "false" || this.state.editViewDataItem.fasterPayment == ""}>
                                            Faster Pay Fee
                                            <NumberBox
                                                format={{
                                                    type: "currency",
                                                    precision: 2,
                                                    currency: "GBP",
                                                }}
                                                onValueChanged={this.handleChangeFasterPayFee}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.providerFasterPayFee}
                                                step={0}
                                                disabled={this.state.isViewMode}
                                                onContentReady={this.onContentReadyNumberBox}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow" hidden={this.state.editViewDataItem.fasterPayment == "No" || this.state.editViewDataItem.fasterPayment == "false" || this.state.editViewDataItem.fasterPayment == ""}>
                                            Net Artist Fee
                                            <NumberBox
                                                format={{
                                                    type: "currency",
                                                    precision: 2,
                                                    currency: "GBP",
                                                }}
                                                onValueChanged={this.handleChangeProviderLocalCurrancyAmount}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.providerPayAmountLocal}
                                                onContentReady={this.onContentReadyNumberBox}
                                                step={0}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">Extras
                                            <NumberBox
                                                format={{
                                                    type: "currency",
                                                    precision: 2,
                                                    currency: "GBP",
                                                }}
                                                onValueChanged={this.handleChangeExtraAmount}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.providerPayAmountLocalExtras}
                                                step={0}
                                                disabled={this.state.isViewMode}
                                                onContentReady={this.onContentReadyNumberBox}
                                            />
                                        </div>
                                        <div className="card-form__field card-form__field--narrow" hidden={this.state.editViewDataItem.providerPayAmountLocalExtras == 0 || this.state.editViewDataItem.providerPayAmountLocalExtras == undefined}>
                                            Extras Description
                                            <TextBox
                                                onValueChanged={this.handleChangeExtrasDescription}
                                                value={this.state.editViewDataItem.providerPayAmountLocalExtrasDescription}
                                                disabled={this.state.isViewMode}
                                            ></TextBox>
                                        </div>
                                        <div className="card-form__field card-form__field--narrow">Comments

                                            <TextArea
                                                height={100}
                                                value={this.state.editViewDataItem.comments}
                                                onValueChanged={this.handleChangeComments}
                                                disabled={this.state.isViewMode}
                                            />
                                        </div>
                                        {isEventUKUser ?
                                            <>
                                                <div className="card-form__field card-form__field--narrow">Internal Comments

                                                    <TextArea
                                                        height={100}
                                                        value={this.state.editViewDataItem.internalComments}
                                                        onValueChanged={this.handleChangeInternalComments}
                                                        disabled={this.state.isViewMode}
                                                    />
                                                </div>
                                            </> : null}  
                                        {this.props.location.state?.id != "0" ?
                                            <div className="mt-3">Date Requested {finalFormattedDate}{this.approverDetails()}</div> : null}
                                        <b><div className="mt-3">{this.state.editViewDataItem.status}</div></b>
                                        <div className="mt-3"></div>
                                        <section>
                                            {this.state.IsShowSuccess ? <span className="text-success">Data Saved Successfully!</span> : null}
                                            {this.state.IsShowError && this.state.errorMessage != null ? (
                                                <span className="unscheduled-shift">
                                                    <ul>
                                                        {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                                            return (
                                                                <li key={uniqueKey}>
                                                                    {item.columnName}: {item.errorMessage}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </span>
                                            ) : null}
                                            {this.state.validationMessage != "" ? (
                                                <>
                                                    {" "}
                                                    <br />{" "}
                                                    <span className="text-danger font-weight-bold justify-content-center">
                                                        <li>
                                                            {this.state.validationMessage}
                                                        </li>
                                                    </span>{" "}
                                                </>
                                            ) : null}
                                        </section>
                                        <div className="row">
                                            <div className="col-12 col-xl-2 pl-0">
                                                <button
                                                    className="btn btn-primary btn--large mt-3"

                                                    type="submit"
                                                    onClick={(e: MouseEvent) => {
                                                        e.preventDefault();
                                                        this.onSubmit();
                                                    }}
                                                    disabled={this.state.isViewMode}
                                                >
                                                    <span className="btn__icon">
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </span>
                                                    Submit
                                                </button>
                                            </div>
                                            <div className="col-12 col-xl-2 pl-0">
                                                <button
                                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                                    onClick={(e: MouseEvent) => {
                                                        e.preventDefault();
                                                        this.onCancel();
                                                    }}
                                                >
                                                    Close
                                                </button>
                                            </div>

                                        </div>
                                        {this.props.location.state?.id != "0" ?
                                            <b> <div className="mt-3">ID : {this.state.editViewDataItem.id}</div> </b> : null}
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>    
                    <Popup
                        visible={this.state.showDuplicateItem}
                        onHiding={this.hideDuplicateItem}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                        showTitle={true}
                        showCloseButton={true}
                        title={"Warning – Possible Duplicates Exist – Please click PROCEED to add this request or else CANCEL"}
                        resizeEnabled={true}
                        maxWidth={1200}
                        height={400}                        
                    >
                        <ScrollView width='95%' height='100%' direction={"both"}>
                            <article className="grid-info d-flex flex-row">
                                {this.state.DuplicateResponse.map((item: any, uniqueKey: number) => {
                                    return (                                
                                        <MatchedCard data={item} dateFrom={this.state.editViewDataItem.dateFrom} venueId={this.state.editViewDataItem.venueId} providerBusinessEntityId={this.state.editViewDataItem.providerBusinessEntityId} 
                                            providerFasterPayOriginalAmount={this.state.editViewDataItem.providerFasterPayOriginalAmount !== null ? this.state.editViewDataItem.providerFasterPayOriginalAmount : this.state.editViewDataItem.providerPayAmountLocal}/>
                                    );
                                })}                               
                            </article>
                            <div className="row px-3 w-50">
                                    <div className="col-12 col-xl-6 pl-0">
                                        <button
                                            className="btn btn-primary btn--large mt-3"
                                            type="submit"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.onProceed();
                                            }}
                                        >
                                            Proceed
                                        </button>
                                    </div>
                                    <div className="col-12 col-xl-6 pl-0">
                                        <button
                                            className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.hideDuplicateItem();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div> 
                        </ScrollView>
                    </Popup>                
                </div>
            </LightModePageTemplate>


        );
    }
}

export default withTranslation()(PaymentRequestForm);
