import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextArea } from "devextreme-react";
import React from "react";
import { TextBoxOnValueChangedEvent } from "../../../types/DevExtremeTypes";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";

interface CardRecommendSectionProps {
    message?: string;
    isItemType: boolean;
    handleThumbsUpIconClick: (event: any) => void;
    handleThumbsDownIconClick: (event: any) => void;
    isThumbsDownClicked?: boolean;
    isThumbsUpClicked?: boolean;
    toggleFeedbackBox?: boolean;
    handleChangeFeedback: (dxValueChange: TextBoxOnValueChangedEvent) => void;
    feedback: string;
}

class CardRecommendSection extends React.Component<CardRecommendSectionProps> {
    constructor(props: CardRecommendSectionProps) {
        super(props);
    }

    render() {
        return (
            <div className="text-center">
                {this.props.isItemType && (
                    <>
                        <span>{this.props.message ? this.props.message : ""}</span>
                        <div className="d-inline-block">
                            <a
                                href="#"
                                className={`faThumbsUp-btn ${this.props.isThumbsUpClicked ? "text-success" : ""}`}
                                onClick={this.props.handleThumbsUpIconClick}
                            >
                                <FontAwesomeIcon icon={faThumbsUp} />
                            </a>
                            <a
                                href="#"
                                className={`faThumbsDown-btn ${this.props.isThumbsDownClicked ? "text-danger" : ""}`}
                                onClick={this.props.handleThumbsDownIconClick}
                            >
                                <FontAwesomeIcon icon={faThumbsDown} />
                            </a>
                        </div>
                    </>
                )}

                {this.props.toggleFeedbackBox && (
                    <>
                        <div className="mt-3">Please provide feedback for Event UK </div>
                        <TextArea height={80} onValueChanged={this.props.handleChangeFeedback} value = {this.props.feedback}/>
                    </>
                )}
            </div>
        );
    }
}
export default CardRecommendSection;
