import { faCoins } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ServiceType } from "../../common/ServiceUtilities";
import { outstandingActions } from "../../services/OutstandingApprovalService";
import { viewSimpleShiftsForService, closeDrawer , viewGigReq} from "./NavigationUtils";

export const viewPaymentRequestForService = (serviceTypeId: string) => {
    sessionStorage.setItem("serviceTypeFilter", serviceTypeId);
    closeDrawer();
};


export const renderProviderCleaningNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.ContractCleaning)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.ContractCleaning)}
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "ContractCleaningPayment",
                        },
                    }}
                >
                    Payments
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to="/additionalCleaning"
                >
                    Request Additional Cleaning
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={closeDrawer} to={{
                    pathname: "/ClientInformationPage",
                    state: {
                        Header: "Contract Cleaning",
                        serviceTypeId: ServiceType.ContractCleaning,
                    },
                }}>
                    Client Information & Venue Conditions
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={closeDrawer} to="/providerProfile">
                    Profile
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/viewQuery",
                        state: {
                            id: "",
                            serviceTypeId: ServiceType.ContractCleaning,
                        },
                    }}
                >
                    Queries
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" onClick={() => viewPaymentRequestForService(ServiceType.ContractCleaning)} to={{
                    pathname: "/paymentRequests",
                    state: {
                        pointOfNavigation: "burgerMenuPayments",
                    },
                }}>
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCoins} />
                    </span>
                    Requests - Payments/Add Job
                </Link>
            </li>
        </ul>
    );
}

export const renderProviderDoorSupervisionNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={closeDrawer} to="/uploadSchedule">
                    Shift Import
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Security)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Security)}
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "SecurityPayment",
                        },
                    }}
                >
                    Payments
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/outstandingActions",
                        state: {
                            status: outstandingActions.acceptances,
                            isHeader: true,
                            serviceTypeId: ServiceType.Security,
                        },
                    }}
                >
                    Client Amendments
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    // to="/ClientInformationPage"
                    to={{
                        pathname: "/ClientInformationPage",
                        state: {
                            Header: "Door Supervision",
                            serviceTypeId: ServiceType.ContractCleaning,
                        },
                    }}
                >
                    Client Information & Venue Conditions
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={closeDrawer} to="/providerProfile">
                    Profile
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/viewQuery",
                        state: {
                            id: "",
                            serviceTypeId: ServiceType.Security,
                        },
                    }}
                >
                    Queries
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" onClick={() => viewPaymentRequestForService(ServiceType.Security)} to={{
                    pathname: "/paymentRequests",
                    state: {
                        pointOfNavigation: "burgerMenuPayments",
                    },
                }}>
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCoins} />
                    </span>
                    Requests - Payments/Add Job
                </Link>
            </li>
        </ul>
    );
}

export const renderProviderGardeningNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Gardening)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
        </ul>
    );
}

export const renderProviderWindowCleaningNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.WindowCleaning)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
        </ul>
    );
}

export const renderProviderMaintenanceNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Maintenance)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
        </ul>
    );
}

export const renderProviderMiscelleanousNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Miscelleanous)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
        </ul>
    );
}

