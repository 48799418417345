import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { DateBox, LoadPanel, SelectBox, TagBox, TextArea, TextBox, Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/data-grid";
import PaymentService, { paymentCycleStatus, paymentsServices, paymentStatus } from "../../services/PaymentService";
import { ActionType } from "../Billing/BillingFormUtils";
import sharedUtils from "../grid/sharedUtilities";
import {
    DateBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TagBoxOnValueChangeEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import paymentFormUtils, { PaymentFormData } from "../Payments/PaymentFormUtils";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { ExcludedEntityList } from "../../services/FindAnArtistService";

// Interfaces and Constants
type NullableDate = Date | null | undefined;
// Props
interface PaymentPrepareComponentProps {
    location: any;
    onReceiveData: (
        paymentCycleId: string,
        formData: PaymentFormData,
        status: string,
        isRestartButtonDisable: boolean,
        isShowIncludedPayments: boolean,
        isSuccessRestart: boolean,
        statusId: string
    ) => void;
    savePaymentData: (paymentCycleId: string, formData: PaymentFormData) => void;
    calculationsObject: any;
    isClickedLockedShift: boolean;
    isClickedRestart: boolean;
    status: string;
    clickedOnButton: string;
    statusId: string;
    refreshPage: boolean;
    history?: any;
}

export interface LookUpTypeItemForServiceType {
    serviceTypeLookupId: number;
    value: string;
    parentMappingId: string;
}

interface DateRangeLookUp {
    id: string;
    value: NullableDate;
    parentMappingId: string;
}
// State
interface PaymentPrepareComponentState {
    editPaymentFormData: PaymentFormData;
    periodMatrixData: any;
    periodLookUp: LookupTypeItem[];
    weekLookUp: LookupTypeItem[];
    dateToLookUp: DateRangeLookUp[];
    dateFromLookUp: DateRangeLookUp[];
    IsShowSuccess: boolean;
    isErrorMessageCriteriaOne: boolean;
    isErrorMessageCriteriaTwo: boolean;
    isErrorMessageCriteriaThree: boolean;
    clientLookUp: LookupTypeItem[];
    restartIndicator: boolean;
    IsShowErrorFromAPI: boolean;
    errorMessage: [];
    disableRestartButton: boolean;
    nextStepStatusId: number;
    nextStepStatusValue: string;
    statusLookUp: LookupTypeItem[];
    loadPanelVisible: boolean;
    dateToChanged: boolean;
    dateFromChanged: boolean;
    IsClientChanged: boolean;
    isStatusChanged: boolean;
    serviceTypeList: [];
    serviceTypeLookUp: LookUpTypeItemForServiceType[];
    prepareButtonColour: string;
    isDisablePrepareButton: boolean;
    isSuccessRestart: boolean;
    paymentStatusForRestart: string;
    excludeEntityList: [];
    clientTypeLookUp: LookupTypeItem[];
    paymentTypeLookUp: LookupTypeItem[];
}
/**
 * Component - Spline chart to display budget and spend
 */
class PaymentPrepareComponent extends React.Component<PaymentPrepareComponentProps> {
    userHasAccess: boolean;
    state: PaymentPrepareComponentState;
    lookupService: LookupService;
    paymentService: PaymentService;
    utils: paymentFormUtils;
    sharedUtils: sharedUtils;
    constructor(props: PaymentPrepareComponentProps) {
        super(props);
        this.lookupService = new LookupService();
        this.paymentService = new PaymentService();
        this.utils = new paymentFormUtils();
        this.sharedUtils = new sharedUtils();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        // Initialize state
        var convertedEditPaymentData = this.utils.initializePaymentItems("");
        this.state = {
            editPaymentFormData: convertedEditPaymentData,
            periodMatrixData: {},
            periodLookUp: [],
            weekLookUp: [],
            dateToLookUp: [],
            dateFromLookUp: [],
            IsShowSuccess: false,
            isErrorMessageCriteriaOne: true,
            isErrorMessageCriteriaTwo: true,
            isErrorMessageCriteriaThree: true,
            clientLookUp: [],
            restartIndicator: false,
            IsShowErrorFromAPI: false,
            errorMessage: [],
            disableRestartButton: false,
            nextStepStatusId: 0,
            nextStepStatusValue: "",
            statusLookUp: [],
            loadPanelVisible: false,
            dateToChanged: false,
            dateFromChanged: false,
            IsClientChanged: false,
            isStatusChanged: false,
            serviceTypeList: [],
            serviceTypeLookUp: [],
            prepareButtonColour: "saveCycleButtonColor",
            isDisablePrepareButton: false,
            isSuccessRestart: false,
            paymentStatusForRestart: paymentStatus.Draft,
            excludeEntityList: [],
            clientTypeLookUp: [],
            paymentTypeLookUp: [],
        };
    }

    componentDidUpdate(prevProps: PaymentPrepareComponentProps) {
        if (this.props.isClickedRestart != prevProps.isClickedRestart) {
            this.onClickRestart();
            this.setState({
                paymentStatusForRestart: paymentStatus.Pending,
            });
        } else if (this.props.statusId != prevProps.statusId) {
            this.setState({
                editPaymentFormData: {
                    ...this.state.editPaymentFormData,
                    statusId: this.props.statusId,
                },
                isStatusChanged: true,
            });
        }
        else if (this.props.refreshPage != prevProps.refreshPage) {
            this.getEditViewDataSource();
        }
    }

    //#region 'OnClick Event'
    onClickRestart = () => {
        var paymentCycleSubmitRequest = this.utils.convertFormDataToPaymentCycleRequest(
            this.state.editPaymentFormData,
            ActionType.Restart,
            "",
            ""
        );
        paymentCycleSubmitRequest.subTotal = this.props.calculationsObject.subTotal;
        paymentCycleSubmitRequest.tax = this.props.calculationsObject.tax;
        paymentCycleSubmitRequest.total = this.props.calculationsObject.total;
        //passing object data to the server
        this.paymentService
            .postPaymentCycleDataV2(paymentCycleSubmitRequest)
            .then(this.handleRestartSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null,
                    IsShowSuccess: false,
                });
                this.failureMessageVisible();
                //this.hideRestartIndicator();
            })
            .finally(() => {
                //this.hideRestartIndicator();
            });
    };

    handleRestartSuccess = () => {
        this.setState(
            {
                IsShowErrorFromAPI: false,
                isSuccessRestart: true,
                isDisablePrepareButton: false,
            },
            () => {
                this.getEditViewDataSource();
            }
        );
    };
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.paymentStatus)
            .then(this.handleSuccessStatusLookUp)
            .catch(this.handleStandardError);
        this.lookupService
            .getLookupByLookupTypeIndexAll(LookupTypeIndexes.clientType)
            .then(this.handleSuccessClientLookUp)
            .catch(this.handleStandardError);
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.paymentTypeLookUp)
            .then(this.handleSuccessPaymentTypeLookUp)
            .catch(this.handleStandardError);
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
            .then(this.handleSuccessServiceTypeLookUp)
            .catch(this.handleStandardError);
    }

    getEditViewDataSource = () => {
        var paymentCycleId = sessionStorage.getItem("paymentCycleId");
        if (this.props.location.state?.id || this.state.editPaymentFormData.paymentCycleId || paymentCycleId) {
            this.setState({
                loadPanelVisible: true,
            });
            this.paymentService
                .getPaymentCycleByIdV2(
                    this.props.location.state?.id || this.state.editPaymentFormData.paymentCycleId || paymentCycleId
                )
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
        }
    };

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        // Convert to proper data types
        var viewDataSource = this.utils.convertPaymentCycleResponseToFormData(response.data.data);
        this.setState(
            {
                editPaymentFormData: viewDataSource,
            },
            () => {
                this.serviceTypeSet();
            }
        );
    };

    serviceTypeSet = () => {
        var status: string;
        var isRestartDisable: boolean = false;
        // set exclude entity data on load
        this.excludeEntitySet();
        if (
            this.state.editPaymentFormData.status == paymentCycleStatus.Pending 
        ) {
            status = paymentStatus.Pending;
            this.setState({
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editPaymentFormData.status == paymentCycleStatus.ReadyForPayment) {
            status = paymentStatus.ReadyForPayment;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentNetted) {
            status = paymentStatus.PaymentNetted;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentChecked) {
            status = paymentStatus.PaymentChecked;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentPackRequested) {
            status = paymentStatus.PaymentPackRequested;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentPackReady) {
            status = paymentStatus.PaymentPackReady;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentPaid) {
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentAdviseRequested) {
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        } else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentAdvised) {
            status = paymentStatus.PaymentAdvised;
            isRestartDisable = false;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        }
        else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentSettled) {
            status = paymentStatus.PaymentSettled;
            isRestartDisable = false;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        }
        else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentRemittanceRequested) {
            status = paymentStatus.PaymentRemittanceRequested;
            isRestartDisable = false;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        }
        else if (this.state.editPaymentFormData.status == paymentCycleStatus.PaymentRemitted) {
            status = paymentStatus.PaymentRemitted;
            isRestartDisable = false;
            this.setState({
                isDisablePrepareButton: true,
                prepareButtonColour: "btn-primary",
            });
        }
        var typeIdArray: number[];
        typeIdArray = [];
        var typeValueArray: string[];
        var serviceTypeValue = "";
        typeValueArray = [];
        if (this.state.editPaymentFormData.serviceMappingList) {
            this.state.editPaymentFormData.serviceMappingList.forEach((item: any, uniqueKey: number) => {
                typeIdArray.push(parseInt(item.serviceTypeLookupId));
                typeValueArray.push(item.value);
            });
        }
        serviceTypeValue = typeValueArray.toString();
        this.setState(
            {
                serviceTypeList: typeIdArray,
                loadPanelVisible: false,
            },
            () => {
                this.props.onReceiveData(
                    this.state.editPaymentFormData.paymentCycleId,
                    this.state.editPaymentFormData,
                    status,
                    isRestartDisable,
                    this.state.isSuccessRestart ? false : true,
                    this.state.isSuccessRestart,
                    status
                );
            }
        );
    };

    handleSuccessServiceTypeLookUp = (response: AxiosResponse<any>) => {
        var serviceTypeLUItem: LookUpTypeItemForServiceType;
        var serviceTypeLUItemArray: LookUpTypeItemForServiceType[];
        serviceTypeLUItemArray = [];
        response.data.data.map((item: any) => {
            serviceTypeLUItem = {
                serviceTypeLookupId: parseInt(item.id),
                value: item.value,
                parentMappingId: "0",
            };
            serviceTypeLUItemArray.push(serviceTypeLUItem);
        });
        this.setState({
            serviceTypeLookUp: serviceTypeLUItemArray,
        });
    };

    handleSuccessStatusLookUp = (response: AxiosResponse<any>) => {
        var statusLUItem: LookupTypeItem;
        var statusLUItemArray: LookupTypeItem[];
        statusLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            statusLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            statusLUItemArray.push(statusLUItem);
        });
        this.setState({
            statusLookUp: statusLUItemArray,
        });
        this.getEditViewDataSource();
        // this.nextStepValue();
    };

    handleSuccessClientLookUp = (response: AxiosResponse<any>) => {
        var clientLUItem: LookupTypeItem;
        var clientLUItemArray: LookupTypeItem[];
        clientLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            clientLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            clientLUItemArray.push(clientLUItem);
        });
        this.setState({
            clientLookUp: clientLUItemArray,
        });
    };

    handleSuccessPaymentTypeLookUp = (response: AxiosResponse<any>) => {
        var paymentLUItem: LookupTypeItem;
        var paymentLUItemArray: LookupTypeItem[];
        paymentLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            paymentLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            paymentLUItemArray.push(paymentLUItem);
        });
        this.setState({
            paymentTypeLookUp: paymentLUItemArray,
        });
    };

    handleStandardError = (error: any) => {
        // TODO: use appInsights logging here
        var respMessage: string = "Add/Edit shift form load failed with response: " + JSON.stringify(error);

        if (!this.paymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    handleChangeInternalInvoiceReference = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                internalReference: dxValueChange.value,
            },
        });
    };

    handleChangeStatusTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                statusId: dxValueChange.value,
                status : this.state.statusLookUp.filter(a=>a.id == dxValueChange.value)[0]?.value
            },
        });
        this.setState({
            isStatusChanged: true,
        });
        //this.nextStepValue();
    };

    handleChangeCycleTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                paymentCycleTypeId: dxValueChange.value,
            },
        });
    };

    handleChangeCutOffDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                payDate: dxValueChange.value,
            },
        });
    };

    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                description: dxValueChange.value,
            },
        });
    };

    handleChangeServiceType = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setState({
            serviceTypeList: dxValue.value,
        });
        this.setServiceTypeListOnChange(dxValue.value);
    };

    setServiceTypeListOnChange = (listOfServiceType: number[]) => {
        var serviceTypeList: paymentsServices;
        var serviceTypeListArray: paymentsServices[];
        serviceTypeListArray = [];
        listOfServiceType.forEach((item: any) => {
            serviceTypeList = {
                paymentCycleId: "",
                serviceTypeLookupId: item.toString(),
                value: "",
            };
            serviceTypeListArray.push(serviceTypeList);
        });
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                serviceMappingList: serviceTypeListArray,
            },
        });
    };

    excludeEntitySet = () => {
        let excludeEntityIdArray: string[];
        excludeEntityIdArray = [];
        let excludeEntityValueArray: string[];
        let excludeEntityValue = "";
        excludeEntityValueArray = [];
        if (this.state.editPaymentFormData.paymentCycleExcludedEntities) {
            this.state.editPaymentFormData.paymentCycleExcludedEntities.forEach((item: any, uniqueKey: number) => {
                excludeEntityIdArray.push(item.businessEntityId);
                excludeEntityValueArray.push(item.shortName);
            });
        }
        excludeEntityValue = excludeEntityValueArray.toString();
        this.setState({
            excludeEntityList: excludeEntityIdArray,
            excludeEntityValue: excludeEntityValue
        });
    }

    handleChangeExcludeEntitySelect = (dxValue: TagBoxOnValueChangeEvent) => {
        if (this.state.excludeEntityList.length !== dxValue.value.length) {
            this.setState({
                excludeEntityList: dxValue.value
            });
            this.setExcludeEntityOnChange(dxValue.value);
        }
    }

    setExcludeEntityOnChange = (listOfDjStyleType: number[]) => {
        let excludeEntityList: ExcludedEntityList;
        let excludeEntityArray: ExcludedEntityList[];
        excludeEntityArray = [];
        listOfDjStyleType.forEach((item: any) => {
            excludeEntityList = {
                businessEntityId: item,
                shortName: "",
            };
            excludeEntityArray.push(excludeEntityList);
        });
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                paymentCycleExcludedEntities: excludeEntityArray,
            },
        })
    };

    onPrepare = () => {
        if (this.userHasAccess) {
            this.setState(
                {
                    loadPanelVisible: true,
                },
                () => {
                    this.savePaymentFormData(this.handlePaymentSaveSuccess, "");
                }
            );
        }
    };

    onSave = () => {
        if (this.userHasAccess) {
            this.setState(
                {
                    loadPanelVisible: true,
                },
                () => {
                    this.savePaymentFormData(this.handlePaymentOnlySaveSuccess, "");
                }
            );
        }
    };

    handlePaymentSaveSuccess = (response: AxiosResponse<any>) => {
        var paymentCycleId = response.data.toString();
        sessionStorage.setItem("paymentCycleId", paymentCycleId);
        this.setState(
            {
                IsShowSuccess: true,
                IsShowErrorFromAPI: false,
                prepareButtonColour: "btn-primary",
                isSuccessRestart: false,
                editPaymentFormData: {
                    ...this.state.editPaymentFormData,
                    paymentCycleId: paymentCycleId,
                },
            },
            () => {
                this.props.onReceiveData(
                    paymentCycleId,
                    this.state.editPaymentFormData,
                    paymentStatus.Pending,
                    false,
                    true,
                    this.state.isSuccessRestart,
                    paymentStatus.Pending
                );
            }
        );
    };

    handlePaymentOnlySaveSuccess = (response: AxiosResponse<any>) => {
        var paymentCycleId = response.data.toString();
        sessionStorage.setItem("paymentCycleId", paymentCycleId);
        this.setState(
            {
                IsShowSuccess: true,
                IsShowErrorFromAPI: false,
                editPaymentFormData: {
                    ...this.state.editPaymentFormData,
                    paymentCycleId: paymentCycleId,
                },
            },
            () => {
                this.props.savePaymentData(paymentCycleId, this.state.editPaymentFormData);
            }
        );
    };

    savePaymentFormData(successHandler: (response: AxiosResponse<any>) => void, statusIdForNextStep: string): void {
        var paymentStatusId = this.statusSelection();
        var paymentCycleSubmitRequest = this.utils.convertFormDataToPaymentCycleRequest(
            this.state.editPaymentFormData,
            ActionType.Save,
            statusIdForNextStep,
            paymentStatusId
        );
        paymentCycleSubmitRequest.subTotal = this.props.calculationsObject.subTotal;
        paymentCycleSubmitRequest.tax = this.props.calculationsObject.tax;
        paymentCycleSubmitRequest.total = this.props.calculationsObject.total;
        this.paymentService
            .postPaymentCycleDataV2(paymentCycleSubmitRequest)
            .then(successHandler)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null,
                    IsShowSuccess: false,
                });
                this.failureMessageVisible();
                this.hideLoadpanel();
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    }

    statusSelection(): string {
        var item: number;
        var billingStatusId = "";
        if (this.state.isStatusChanged == false) {
            if (this.state.editPaymentFormData.status) {
                for (item = 0; item < this.state.statusLookUp.length; item++) {
                    if (this.state.editPaymentFormData.status == this.state.statusLookUp[item].value) {
                        billingStatusId = this.state.statusLookUp[item].id;
                        break;
                    }
                }
            } else {
                billingStatusId = this.state.statusLookUp[0].id;
            }
        }
        return billingStatusId;
    }

    hideLoadpanel() {
        this.setState({ loadPanelVisible: false });
    }

    failureMessageVisible = () => {
        this.setState({
            IsShowErrorFromAPI: true,
        });
    };

    handleBillingOnlySaveSuccess = (response: AxiosResponse<any>) => {
        var invoiceId = response.data.toString();
        this.setState({
            IsShowSuccess: true,
            IsShowErrorFromAPI: false,
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                invoiceId: invoiceId,
            },
        });
    };

    render() {
        var disableSaveButton: boolean = false;
        var notificationText: string = "";
        var viewDisabled:boolean = this.props.location?.state?.viewDisabled;
        if (
            this.props.statusId == paymentStatus.PaymentPackRequested ||
            this.props.statusId == paymentStatus.PaymentAdviseRequested ||
            this.props.statusId == paymentStatus.PaymentRemittanceRequested ||
            this.state.editPaymentFormData.statusId == paymentStatus.PaymentPackRequested ||
            this.state.editPaymentFormData.statusId == paymentStatus.PaymentAdviseRequested ||
            this.state.editPaymentFormData.statusId == paymentStatus.PaymentRemittanceRequested
        ) {
            notificationText =
                "Please Note: The user will not be able to save the cycle if the Payment cycle status is Payment Advise Requested/Payment Pack Requested/Payment Remittance Requested";
            disableSaveButton = true;
        } else {
            notificationText = "";
        }
        var isDisablePrepareButton: boolean = false;
        var prepareButtonClassName: string = "";
        if (this.state.statusLookUp.length > 0) {
            if (
                this.state.editPaymentFormData.statusId == this.state.statusLookUp[0].id ||
                this.state.editPaymentFormData.statusId == ""
            ) {
                isDisablePrepareButton = false;
                prepareButtonClassName = "btn" + " " + this.state.prepareButtonColour + " " + "btn--large";
            } else {
                isDisablePrepareButton = true;
                prepareButtonClassName = "btn" + " " + "disabledCycleButtonColor" + " " + "btn--large";
            }
        }
        return (
            <div className="card">
                <div className="card-body">
                    <div className="col-12">
                        <>
                            <h5>New Payment Details</h5>
                            <div className="row">
                                {this.state.IsShowErrorFromAPI && this.state.IsShowErrorFromAPI != null ? (
                                    <span className="unscheduled-shift">
                                        <ul>
                                            {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                                return (
                                                    <li key={uniqueKey}>
                                                        {item.columnName}: {item.errorMessage}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </span>
                                ) : null}
                            </div>

                            <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                            <div className="row">
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Status</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <SelectBox
                                        dataSource={this.state.statusLookUp}
                                        displayExpr="value"
                                        valueExpr="id"
                                         disabled={viewDisabled}
                                        onValueChanged={this.handleChangeStatusTypeSelect}
                                        value={
                                            this.props.location.state?.id == 0 &&
                                            this.state.statusLookUp.length > 0 &&
                                            this.state.isStatusChanged == false
                                                ? this.state.statusLookUp[0].id
                                                : this.state.editPaymentFormData.statusId
                                        }
                                    ></SelectBox>
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Services</div>
                                <div className="mt-3 col-10 col-lg-7">
                                    <TagBox
                                        value={this.state.serviceTypeList}
                                        dataSource={this.state.serviceTypeLookUp}
                                        width={"100%"}
                                        valueExpr="serviceTypeLookupId"
                                        displayExpr="value"
                                        showSelectionControls={true}
                                        onValueChanged={this.handleChangeServiceType}
                                        // placeholder={artistTypeText}
                                        disabled = {viewDisabled}
                                    ></TagBox>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Cut Off Date</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        onValueChanged={this.handleChangeCutOffDate}
                                        disabled={viewDisabled}
                                        value={
                                            this.state.editPaymentFormData.payDate
                                                ? this.state.editPaymentFormData.payDate
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                    >
                                        <Validator>
                                            {this.state.isErrorMessageCriteriaOne == false ||
                                            this.state.isErrorMessageCriteriaTwo == false ||
                                            this.state.isErrorMessageCriteriaThree == false ? (
                                                <RequiredRule message="Invoice Date is required - please select carefully  - this will be shown on the client's invoice" />
                                            ) : (
                                                ""
                                            )}
                                        </Validator>
                                    </DateBox>
                                </div>

                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Our Ref.</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <TextBox
                                        value={this.state.editPaymentFormData.internalReference}
                                        onValueChanged={this.handleChangeInternalInvoiceReference}
                                         disabled={viewDisabled}
                                         maxLength={100}
                                    ></TextBox>
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">ID</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <TextBox
                                        value={this.state.editPaymentFormData.paymentCycleId}
                                        readOnly={true}
                                        disabled={viewDisabled}
                                    ></TextBox>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Cycle Type</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <SelectBox
                                        dataSource={this.state.paymentTypeLookUp}
                                        displayExpr="value"
                                        valueExpr="id"
                                         disabled={viewDisabled}
                                        onValueChanged={this.handleChangeCycleTypeSelect}
                                        value={this.state.editPaymentFormData.paymentCycleTypeId}
                                    ></SelectBox>
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Exclude Entities</div>
                                <div className="mt-3 col-10 col-lg-7">
                                    <TagBox
                                        value={this.state.excludeEntityList}
                                        dataSource={this.state.clientLookUp}
                                        width={"100%"}
                                        valueExpr="id"
                                        displayExpr="value"
                                        showSelectionControls={true}
                                        onValueChanged={this.handleChangeExcludeEntitySelect}
                                    ></TagBox>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Comment</div>
                                <div className="mt-3 col-10 col-lg-11">
                                    <TextArea
                                        height={90}
                                        onValueChanged={this.handleChangeDescription}
                                        value={this.state.editPaymentFormData.description}
                                        disabled={viewDisabled}
                                    />
                                </div>
                            </div>

                            {notificationText ? (
                                <span className="text-danger font-weight-bold my-2">{notificationText}</span>
                            ) : (
                                <></>
                            )}
                            <div className="row mt-3">
                                <div className="col-12 col-lg-2 mb-3">
                                    <button
                                        className={(viewDisabled?"disabled ":"")+prepareButtonClassName}
                                        type="button"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onPrepare();
                                        }}
                                        disabled={isDisablePrepareButton}
                                    >
                                        <span className="btn__icon">
                                            {/* <FontAwesomeIcon icon={faQuestionCircle} /> */}
                                        </span>
                                        PREPARE
                                    </button>
                                </div>
                                <div className="col-12 col-lg-2 mb-3 ml-auto">
                                    <button
                                        className={"btn btn--ghost btn--large"}
                                        type="button"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.props.history?.goBack();
                                        }}
                                    >
                                        ClOSE
                                    </button>
                                </div>
                                <div className="col-12 col-lg-2 mb-3">
                                    <button
                                        className={(viewDisabled?"disabled ":"")+"btn btn--ghost btn--large"}
                                        type="button"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onSave();
                                        }}
                                        disabled={disableSaveButton}
                                    >
                                        <span className="btn__icon">
                                            {/* <FontAwesomeIcon icon={faQuestionCircle} /> */}
                                        </span>
                                        SAVE
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentPrepareComponent;
