import React from "react";
import DataGrid, { Column, Pager, Paging, SearchPanel } from "devextreme-react/data-grid";
import { ExceptionalRatesResponse } from "../../services/SupplierInformationService";

//props
interface SupplierInformationPageGridProps {
    exceptionalRateGridDataSource: ExceptionalRatesResponse[];
}

// State
interface SupplierInformationPageGridState {
    gridDataSource: ExceptionalRatesResponse[];
}

// Component - displays the simple Grid for all users
class SupplierInformationPageGrid extends React.Component<SupplierInformationPageGridProps> {
    state: SupplierInformationPageGridState;
    constructor(props: SupplierInformationPageGridProps) {
        super(props);
        this.state = {
            gridDataSource: [],
        };
    }

    componentDidMount() {}
    componentDidUpdate(prevProps: SupplierInformationPageGridProps) {
        if (this.props.exceptionalRateGridDataSource != prevProps.exceptionalRateGridDataSource) {
            this.setState({
                gridDataSource: this.props.exceptionalRateGridDataSource,
            });
        }
    }
    render() {
        return (
            <div>
                <DataGrid
                    dataSource={this.state.gridDataSource}
                    showBorders={false}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    noDataText={"No exceptional rates found for this venue"}
                    columnResizingMode={"widget"}
                >
                    <SearchPanel visible={true} placeholder={"Search"} />
                    <Paging defaultPageSize={5} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                    <Column dataField="name" caption="NAME" />
                    <Column dataField="idValue" caption="LICENCE NO" />
                    <Column dataField="typeLookValue" caption="ROLE" />
                    <Column dataField="rateText" caption="RATE" />
                </DataGrid>
            </div>
        );
    }
}

export default SupplierInformationPageGrid;
