import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DropDownFromLookupTable from "../components/select/SelectBoxFromLookupTable";
import { LookupTypeIndexes } from "../services/LookupService";
import { SelectBox, LoadPanel } from "devextreme-react";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface FormDesignProps extends RouteComponentProps {}

class FormDesign extends Component<FormDesignProps> {
    constructor(props: FormDesignProps) {
        super(props);
    }
    handleChange(e: { value: any }) {
        this.setState({
            type: e.value,
        });
    }

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>{" "}
                <div id="independentBooking" className="page-content--with-sidebar-hidden-mobile">
                    <div className="col-12">
                        <h3 className="card-form__page-heading">Form Page Header</h3>
                        <form data-testid="addEditPayment-form" className="card card-form my-5">
                            <div className="card-body">
                                <section>
                                    <h3 className="card-form__heading">Form Header</h3>
                                    <div className="card-form__field card-form__field--narrow">
                                        Narrow Field
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                        <DropDownFromLookupTable
                                            lookupTypeIndex={LookupTypeIndexes.clientType}
                                            onValueChanged={() => {}}
                                        />
                                    </div>
                                    <div className="card-form__inline-field ">
                                        Wide Field
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                        <SelectBox displayExpr={"value"} valueExpr={"id"} onValueChanged={() => {}} />
                                    </div>
                                    <p className="card-form__confirmation">This is the confirmation message</p>
                                    <ul className="card-form__errors">
                                        <li>This is an error</li>
                                        <li>This is another error</li>
                                    </ul>
                                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={false} />
                                </section>
                                <section className="card-form__button-container">
                                    <div className="col-12 col-lg-4 col-xl-2">
                                        <button
                                            className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                            onClick={() => {}}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-4 col-xl-2">
                                        <button
                                            className="btn btn-primary btn--large mt-3"
                                            type="submit"
                                            onClick={() => {}}
                                        >
                                            Submit Request
                                        </button>
                                    </div>
                                    <div className="col-12 mt-5">
                                        Note: Cancel is only to be used for pop up forms where it closes the popup.
                                    </div>
                                </section>
                            </div>
                        </form>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(FormDesign);
