import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TransactionReconcilliationGrid from "../Billing/Reconciliation/TransactionReconcilliationGrid";
import WeekOnWeekReconciliationGrid from "../Billing/Reconciliation/WeekOnWeekReconciliationGrid";
import LightModePageTemplate from "./Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import DatePanel from "../side-bar/DatePanel";

interface BillingReconciliationPageProps extends RouteComponentProps {
    location: any;
    title: string;
    category: string;
}

interface BillingReconciliationPageState {}
const calsObject = {
    subTotal: "",
    total: "",
    tax: "",
};

class BillingReconciliationPage extends Component<BillingReconciliationPageProps> {
    //Initialize the component's state.
    state: BillingReconciliationPageState;

    constructor(props: BillingReconciliationPageProps) {
        super(props);
        this.state = {};
    }
    setGridData = () => {};

    disableSaveCycleButton = (statusId: string) => {};

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>
                <section className="page-content--with-sidebar">
                    <header className="grid-info mb-3" />
                    <div className="row mb-3">
                        <div className="col-md">
                            <TransactionReconcilliationGrid
                                tabVisible={false}
                                id={
                                    this.props.location.state
                                        ? this.props.location.state.id
                                            ? this.props.location.state.id
                                            : ""
                                        : ""
                                }
                                refreshGridData={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <WeekOnWeekReconciliationGrid
                                tabVisible={false}
                                id={
                                    this.props.location.state
                                        ? this.props.location.state.id
                                            ? this.props.location.state.id
                                            : ""
                                        : ""
                                }
                                refreshGridData={false}
                            />
                        </div>
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(BillingReconciliationPage);
