import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Lookup,
    Format,
    PatternRule,
    SearchPanel,
    Export
} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import ContractCleaningService, { ContractCleaningGridRowItem } from "../../services/ContractCleaningService";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { OnRowUpdatingEvent, OnRowInsertingEvent, OnRowRemovingEvent,onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import ContractActionCellComponent from "./ContractActionCellComponent";
import MatrixGridService, { PeriodMatrixGridRowItem } from "../../services/MatrixService";
import gridUtils, { currencyTypeToSymbolMatrix, currencyTypes } from "../grid/GridUtilities";
import { saveAs } from "file-saver";
//props
interface ContractCleaningGridProps {
    refreshSignal: boolean;
    filterText: any;
}

export interface ContractCleaningAddEditObjectItem {
    contractId: number;
    providerBusinessEntityId: number;

    clientBusinessEntityId: number;

    serviceTypeLookUpId: number;

    serviceSubTypeLookUpId: number;

    venueId: string;

    isActive: boolean;
    isVariableHours: boolean;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    sunday: number;
    rate: number;
    total: number;
    lastUpdatedDate: Date;
    lastUpdatedBy: string;
}
export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}
// State
interface ContractCleaningGridState {
    contractGridDataSource: ContractCleaningGridRowItem[];
    showProgressIndicator: boolean;
    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    venueLookUp: LookupTypeItem[];
    providerLookUp: LookupTypeItem[];
    frequencyLookUp: LookupTypeItem[];
    contractCleaningAddEditObject: ContractCleaningAddEditObjectItem;
}

// Component - displays the Rate Matrix Grid
class ContractCleaningGrid extends React.Component<ContractCleaningGridProps> {
    //Initialize the component's state.
    state: ContractCleaningGridState;
    cleaningService: ContractCleaningService;
    dropdownService: lookupService;
    manageService: MatrixGridService;
    gridUtils: gridUtils;
    constructor(props: ContractCleaningGridProps) {
        super(props);
        this.cleaningService = new ContractCleaningService();
        this.dropdownService = new lookupService();
        this.manageService = new MatrixGridService();
        // Initialize state and services/utils
        this.state = {
            contractGridDataSource: [],
            showProgressIndicator: false,
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            venueLookUp: [],
            providerLookUp: [],
            frequencyLookUp: [],
            contractCleaningAddEditObject: {
                contractId: 0,
                providerBusinessEntityId: 0,
                clientBusinessEntityId: 0,
                serviceTypeLookUpId: 0,
                serviceSubTypeLookUpId: 0,
                venueId: "",
                isActive: false,
                isVariableHours: false,
                monday: 0,
                tuesday: 0,
                wednesday: 0,
                thursday: 0,
                friday: 0,
                saturday: 0,
                sunday: 0,
                rate: 0,
                total: 0,
                lastUpdatedDate: new Date(),
                lastUpdatedBy: "",
            },
        };
        this.gridUtils = new gridUtils();
        // Functions
        this.contractCleaningDataSource = this.contractCleaningDataSource.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.dropDownDataSource = this.dropDownDataSource.bind(this);
        this.lookUpCreation = this.lookUpCreation.bind(this);
        this.handleClientLUSuccess = this.handleClientLUSuccess.bind(this);
        this.handleServiceLUSuccess = this.handleServiceLUSuccess.bind(this);
        this.handleSubServiceLUSuccess = this.handleSubServiceLUSuccess.bind(this);
        this.handleVenueLUSuccess = this.handleVenueLUSuccess.bind(this);
        this.handleProviderLUSuccess = this.handleProviderLUSuccess.bind(this);
        this.handleFrequencyLUSuccess = this.handleFrequencyLUSuccess.bind(this);

        this.onRowRemoving = this.onRowRemoving.bind(this);

        this.onRowUpdatedInsertedDeleted = this.onRowUpdatedInsertedDeleted.bind(this);
    }

    componentDidMount() {
        this.dropDownDataSource();
        this.contractCleaningDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: ContractCleaningGridProps) {
        if (this.props.refreshSignal !== prevProps.refreshSignal || this.props.filterText !== prevProps.filterText) {
            // refresh the grid
            this.contractCleaningDataSource();
        }
    }

    dropDownDataSource() {
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
            .then(this.handleClientLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
            .then(this.handleServiceLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.subServiceType)
            .then(this.handleSubServiceLUSuccess)
            .catch(this.handleError);

        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.providerType)
            .then(this.handleProviderLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.frequencyType)
            .then(this.handleFrequencyLUSuccess)
            .catch(this.handleError);
    }
    contractCleaningDataSource() {
        this.setState({
            showProgressIndicator: true,
        });
        let subTypeId = this.props.filterText == "ContractCleaning" ? "21" : "22";
        this.cleaningService.getContractCleaningData(subTypeId).then(this.handleSuccess).catch(this.handleError);
    }

    lookUpCreation(response: AxiosResponse<any>): LookupTypeItem[] {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    }
    handleClientLUSuccess(response: AxiosResponse<any>) {
        var clientDropDown = this.lookUpCreation(response);
        this.setState({
            clientLookUp: clientDropDown,
        });
    }
    handleServiceLUSuccess(response: AxiosResponse<any>) {
        var serviceDropDown = this.lookUpCreation(response);
        this.setState({
            serviceLookUp: serviceDropDown,
        });
    }
    handleSubServiceLUSuccess(response: AxiosResponse<any>) {
        var subServiceDropDown = this.lookUpCreation(response);
        this.setState({
            subServiceLookUp: subServiceDropDown,
        });
    }

    handleVenueLUSuccess(response: AxiosResponse<any>) {
        var venueDropDown = this.lookUpCreation(response);
        this.setState({
            venueLookUp: venueDropDown,
        });
    }
    handleProviderLUSuccess(response: AxiosResponse<any>) {
        var providerDropDown = this.lookUpCreation(response);
        this.setState({
            providerLookUp: providerDropDown,
        });
    }
    handleFrequencyLUSuccess(response: AxiosResponse<any>) {
        var frequencyDropDown = this.lookUpCreation(response);
        this.setState({
            frequencyLookUp: frequencyDropDown,
        });
    }
    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            contractGridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    }

    handleError(error: AxiosResponse<any>) {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "Contract cleaning service failed with response: " + JSON.stringify(error);

        if (!this.cleaningService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }

    onRowUpdatedInsertedDeleted() {
        this.contractCleaningDataSource();
    }

    onRowRemoving(e: OnRowRemovingEvent) {
        this.cleaningService
            .deleteContractCleaningDataItem(e.data.contractCleaningId)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch(this.handleError);
    }

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    render() {
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : ( this.props.filterText == "ContractCleaning" ? 
                    <DataGrid
                        dataSource={this.state.contractGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        onRowRemoving={this.onRowRemoving}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        onExporting={this.onExporting}
                    >
                        <Export enabled={true} />
                        <SearchPanel visible={true} placeholder={"Search"} />

                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="contractCleaningId" caption="ID"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.contractCleaningId);
                            }}></Column>
                        <Column dataField="clientName" allowSorting={true} caption="CLIENT"></Column>
                        <Column dataField="serviceTypeName" allowSorting={true} caption="SERVICE"></Column>
                        <Column dataField="serviceSubTypeName" allowSorting={true} caption="SERVICE TYPE"></Column>
                        <Column dataField="providerName" allowSorting={true} caption="PROVIDER"></Column>
                        <Column dataField="venuName" allowSorting={true} caption="VENUE NAME"></Column>
                        <Column
                            dataField="monday"
                            allowSorting={true}
                            caption="MON"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.monday);
                            }}
                        ></Column>
                        <Column
                            dataField="tuesday"
                            allowSorting={true}
                            caption="TUE"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.tuesday);
                            }}
                        ></Column>
                        <Column
                            dataField="wednesday"
                            allowSorting={true}
                            caption="WED"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.wednesday);
                            }}
                        ></Column>
                        <Column
                            dataField="thursday"
                            allowSorting={true}
                            caption="THU"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.thursday);
                            }}
                        ></Column>
                        <Column
                            dataField="friday"
                            allowSorting={true}
                            caption="FRI"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.friday);
                            }}
                        ></Column>
                        <Column
                            dataField="saturday"
                            allowSorting={true}
                            caption="SAT"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.saturday);
                            }}
                        ></Column>
                        <Column
                            dataField="sunday"
                            allowSorting={true}
                            caption="SUN"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.sunday);
                            }}
                        ></Column>

                        <Column
                            dataField="total"
                            allowSorting={true}
                            caption="TOTAL"
                            format={{ type: "float", precision: 2 }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.total);
                            }}
                        ></Column>
                        <Column
                            dataField="rate"
                            allowSorting={true}
                            caption="RATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate);
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.rate,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                        ></Column>
                        <Column dataField="frequencyName" allowSorting={true} caption="FREQUENCY"></Column>
                        <Column dataField="isActive" allowSorting={true} caption="ACTIVE"></Column>
                        <Column
                            dataField="lastUpdatedOn"
                            allowSorting={true}
                            caption="LAST UPDATED"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                            }}
                        ></Column>
                        <Column dataField="lastUpdatedBy" allowSorting={true} caption="LAST UPDATED BY"></Column>
                        <Column cellComponent={ContractActionCellComponent}></Column>
                        <Editing mode="row" useIcons={true} allowDeleting={true} />
                        <Column type="buttons" buttons={["delete"]} />

                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                  : 
                  <DataGrid
                        dataSource={this.state.contractGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        onRowRemoving={this.onRowRemoving}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        onExporting={this.onExporting}
                    >
                        <Export enabled={true} />
                        <SearchPanel visible={true} placeholder={"Search"} />

                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="contractCleaningId" caption="ID"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.contractCleaningId);
                            }}></Column>
                        <Column dataField="clientName" allowSorting={true} caption="CLIENT"></Column>
                        <Column dataField="venuName" allowSorting={true} caption="VENUE"></Column>
                        <Column dataField="houseNumber" allowSorting={true} caption="HOUSE ID"></Column>
                        <Column dataField="serviceTypeName" allowSorting={true} caption="SERVICE"></Column>
                        <Column dataField="serviceSubTypeName" allowSorting={true} caption="SERVICE TYPE"></Column>
                        <Column dataField="typeLookUpName" allowSorting={true} caption="TYPE"></Column>
                        <Column dataField="otherDescription" allowSorting={true} caption="DESCRIPTION"></Column>
                        <Column dataField="providerName" allowSorting={true} caption="PROVIDER"></Column>
                        <Column dataField="frequencyName" allowSorting={true} caption="FREQUENCY"></Column>
                        <Column dataField="total" allowSorting={true} caption="QTY"></Column>                      

                        <Column
                            dataField="rate"
                            allowSorting={true}
                            caption="RATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate);
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.rate,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                        ></Column>
                        <Column
                            dataField="providerPayAmountLocal"
                            allowSorting={true}
                            caption="NET"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.providerPayAmountLocal);
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.providerPayAmountLocal,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                        ></Column>
                        <Column
                            dataField="feeOverride"
                            allowSorting={true}
                            caption="OVERRIDE FEE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.feeOverride);
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.feeOverride,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                        ></Column>

                        <Column dataField="isActive" allowSorting={true} caption="ACTIVE"></Column>                         
                        
                        <Column
                            dataField="lastUpdatedOn"
                            allowSorting={true}
                            caption="LAST UPDATED"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                            }}
                        ></Column>
                        <Column dataField="lastUpdatedBy" allowSorting={true} caption="LAST UPDATED BY"></Column>
                        <Column cellComponent={ContractActionCellComponent}></Column>
                        <Editing mode="row" useIcons={true} allowDeleting={true} />
                        <Column type="buttons" buttons={["delete"]} />

                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default ContractCleaningGrid;
