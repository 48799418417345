import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DatePanel from "../side-bar/DatePanel";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import BillingStatusFlow from "../Billing/BillingStatusFlow";
import BillingFormUtils, { BillingFormData, BillingStatus } from "../Billing/BillingFormUtils";
import BillingPrepareComponent from "./BillingPrepareComponent";
import LockShiftComponent from "./LockShiftComponent";
import ReportsGridComponent from "./ReportsGridComponent";
import { billingCycleStatus } from "../../services/BillingService";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentsSection from "./DocumentsSection";
import InvoicePaymentSection from "./InvoicePaymentSection";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import { custom } from "devextreme/ui/dialog";
import lookupService, { LookupTypeIndexes } from "../../services/LookupService";
import { AxiosResponse } from "axios";

interface BillingPageV2Props extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
}

interface BillingPageV2State {
    billingStatus: BillingStatus;
    isClickedRestart: boolean;
    isRestartDisable: boolean;
    invoiceId: number;
    calculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    billingData: BillingFormData;
    isClickedLockedShift: boolean;
    isShowIncludedShift: boolean;
    isPrepareDisable: boolean;
    status: BillingStatus;
    statusId: string;
    isPrepareClicked: boolean;
    isSuccessRestart: boolean;
    loadPanelVisible: boolean;
    invoiceSentDate: NullableDate;
    invoicePaidDate: NullableDate;
    invoiceAmount: number;
    refreshPage: boolean;
    vatConfig: string;
}

class BillingPageV2 extends Component<BillingPageV2Props> {
    //Initialize the component's state.
    state: BillingPageV2State;
    utils: BillingFormUtils;
    lookupService: lookupService;
    constructor(props: BillingPageV2Props) {
        super(props);
        this.utils = new BillingFormUtils();
        this.lookupService = new lookupService();
        var convertedEditBillingData = this.utils.initializeBillingCycleItems("");
        this.state = {
            billingStatus: BillingStatus.Draft,
            isClickedRestart: false,
            isRestartDisable: false,
            invoiceId: 0,
            calculationsObject: {
                subTotal: "",
                total: "",
                tax: "",
            },
            billingData: convertedEditBillingData,
            isClickedLockedShift: false,
            isShowIncludedShift: false,
            isPrepareDisable: false,
            status: BillingStatus.BillDefault,
            statusId: billingCycleStatus.Draft,
            isPrepareClicked: false,
            isSuccessRestart: false,
            loadPanelVisible: false,
            invoiceSentDate: undefined,
            invoicePaidDate: undefined,
            invoiceAmount: 0,
            refreshPage: false,
            vatConfig: "",
        };
    }

    componentDidMount() {
        this.vatConfig();
    }

    handleRestartOnClick = (event: any) => {
        let Restart = custom({
            title: "Confirm Restart?",
            messageHtml: "<b>Please confirm you wish to restart this cycle?</b>",
            buttons: [
                {
                    text: "Cancel",
                },
                {
                    text: "Confirm",
                    onClick: (isClickedRestart) => {
                        this.setState({
                            isClickedRestart: isClickedRestart,
                            status: BillingStatus.Pending,
                        });
                    },
                },
            ],
        });
        Restart.show();
    };
    // A helper function that would fire when the "Restart" button is clicked.
    onRestartClicked = (isClickedRestart: boolean) => {
        this.handleRestartOnClick(isClickedRestart);
    };

    onReceiveData = (
        invoiceId: string,
        formData: BillingFormData,
        status: BillingStatus,
        isRestartDisable: boolean,
        isShowIncludedShift: boolean,
        isSuccessRestart: boolean,
        statusId?: string
    ) => {
        this.setState((prevState: BillingPageV2State) => ({
            invoiceId: invoiceId,
            billingData: formData,
            isShowIncludedShift: isShowIncludedShift,
            isSuccessRestart: isSuccessRestart ? !prevState.isSuccessRestart : prevState.isSuccessRestart,
            isPrepareClicked: isSuccessRestart ? prevState.isPrepareClicked : !prevState.isPrepareClicked,
            billingStatus: status,
            isRestartDisable: isRestartDisable,
            statusId: statusId ? statusId : formData.statusId,
        }));
    };

    // A function which is invoked when the "Save" button is clicked on the Summary panel.
    onSaveBillingData = (invoiceId: string, formData: BillingFormData) => {
        this.setState({
            invoiceId: invoiceId,
            billingData: formData,
            statusId: formData.statusId,
            isRestartDisable:
                formData.statusId == billingCycleStatus.BillPaid ||
                formData.statusId == billingCycleStatus.BillPackSent ||
                formData.statusId == billingCycleStatus.BillQueried
                    ? true
                    : false,
        });
    };

    setCalculationsCallback = (calculationsObject: any) => {
        if (calculationsObject.subTotal) {
            let vatConfig = parseFloat(this.state.vatConfig);
            let tax = parseFloat((calculationsObject.subTotal * vatConfig).toFixed(2));
            let clientGross = calculationsObject.subTotal + tax;
            // let tax = calculationsObject.tax;
            // let clientGross = calculationsObject.total;
            this.setState({
                calculationsObject: {
                    ...this.state.calculationsObject,
                    total: clientGross,
                    subTotal: calculationsObject.subTotal,
                    tax: tax,
                },
            });
        }
    };

    //A callback function that would trigger when the Lock shifts button is clicked.
    onClickLockedShift = (isClickedLockedShift: boolean, status: BillingStatus, statusId: string) => {
        this.setState({
            isClickedLockedShift: isClickedLockedShift,
            status: status,
            billingStatus: status,
            isRestartDisable: false,
            statusId: statusId,
        });
    };

    //A callback function that would trigger when the "Check shifts" button is clicked.
    onShiftsChecked = (status: BillingStatus, statusId: string) => {
        this.setState({
            status: status,
            billingStatus: status,
            isRestartDisable: false,
            statusId: statusId,
        });
    };

    //A callback function that would trigger when the "Produce" button is clicked.
    onBillProduced = (status: BillingStatus, statusId: string) => {
        this.setState({
            status: status,
            billingStatus: status,
            isRestartDisable: true,
            statusId: statusId,
        });
    };

    //A callback function that would trigger when the "Paid" button is clicked.
    onBillPaid = (status: BillingStatus, statusId: string) => {
        this.setState({
            status: status,
            billingStatus: status,
            isRestartDisable: true,
            statusId: statusId,
        });
    };

    //A helper function that will sync the date
    syncDateFields = (date: NullableDate, type: string) => {
        if (type.toLowerCase() == "sent") {
            this.setState({
                invoiceSentDate: date,
                billingData: {
                    ...this.state.billingData,
                    invoiceSentDate: date,
                },
            });
        } else {
            this.setState({
                invoicePaidDate: date,
            });
        }
    };

    syncAmountFields = (data: number) => {
        this.setState({
            invoiceAmount: data ? data : 0,
        });
    };

    onRefreshBillingStatusClicked = () => {
        this.setState((prevState: BillingPageV2State) => ({
            refreshPage: !prevState.refreshPage,
        }));
    };

    vatConfig = () => {
        this.lookupService
            .getVATValue(LookupTypeIndexes.configuration, "VatPercentage")
            .then(this.handleSuccessVATLookUp)
            .catch(this.handleError);
    };

    handleSuccessVATLookUp = (response: AxiosResponse<any>) => {
        this.setState({
            vatConfig: response.data.data[0].value,
        });
    };

    handleError = (error: any) => {
        var respMessage: string = "Get Configuration Text service failed with response: " + JSON.stringify(error);

        if (!this.lookupService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    render() {
        var { statusId } = this.state;
        var title = "";
        if (this.props.location.state && this.props.location.state.id) {
            title = "Billing - Edit Invoice";
        } else {
            title = "Billing - Add Invoice";
        }
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>
                <section className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <h2>
                            <b>{title}</b>
                            <button
                                className={
                                    (this.props.location.state?.viewDisable ? "disabled " : "") +
                                    "status-flow__refresh-button btn icon-btn"
                                }
                                aria-label="Refresh the current billing status"
                                onClick={this.onRefreshBillingStatusClicked}
                            >
                                <FontAwesomeIcon icon={faSync} />
                            </button>
                        </h2>
                    </header>
                    <BillingStatusFlow
                        statusId={this.state.statusId}
                        onRestartClicked={this.onRestartClicked}
                        isRestartDisable={this.props.location.state?.viewDisable ? true : this.state.isRestartDisable}
                    />
                    <BillingPrepareComponent
                        isClickedLockedShift={this.state.isClickedLockedShift}
                        location={this.props.location}
                        onReceiveData={this.onReceiveData}
                        saveBillingData={this.onSaveBillingData}
                        calculationsObject={this.state.calculationsObject}
                        isClickedRestart={this.state.isClickedRestart}
                        status={this.state.status}
                        statusId={this.state.statusId}
                        syncDateFields={this.syncDateFields}
                        syncAmountFields={this.syncAmountFields}
                        invoiceSentDate={this.state.invoiceSentDate}
                        invoicePaidDate={this.state.invoicePaidDate}
                        invoiceAmount={this.state.invoiceAmount}
                        refreshPage={this.state.refreshPage}
                        vatConfig={this.state.vatConfig}
                        previousPage={this.props.history}
                    />
                    {this.state.isShowIncludedShift ? (
                        <LockShiftComponent
                            viewDisable={this.props.location.state?.viewDisable}
                            invoiceId={this.state.invoiceId}
                            billingData={this.state.billingData}
                            setCalculationsCallback={this.setCalculationsCallback}
                            onClickLockedShift={this.onClickLockedShift}
                            isPrepareClicked={this.state.isPrepareClicked}
                            isSuccessRestart={this.state.isSuccessRestart}
                            statusId={this.state.statusId}
                        />
                    ) : (
                        <></>
                    )}
                    {statusId && statusId != billingCycleStatus.Draft && statusId != billingCycleStatus.Pending ? (
                        <ReportsGridComponent
                            viewDisable={this.props.location.state?.viewDisable}
                            invoiceId={this.state.invoiceId}
                            billingData={this.state.billingData}
                            onShiftsChecked={this.onShiftsChecked}
                            setTaxValues={this.setCalculationsCallback}
                            statusId={this.state.statusId}
                            calculationsObject={this.state.calculationsObject}
                        />
                    ) : (
                        <></>
                    )}
                    {statusId &&
                    statusId != billingCycleStatus.Draft &&
                    statusId != billingCycleStatus.Pending &&
                    statusId != billingCycleStatus.ReadyForBilling ? (
                        <DocumentsSection
                            viewDisable={this.props.location.state?.viewDisable}
                            invoiceId={this.state.invoiceId}
                            billingData={this.state.billingData}
                            onBillProduced={this.onBillProduced}
                            statusId={this.state.statusId}
                            invoiceSentDate={this.state.invoiceSentDate}
                            syncDateFields={this.syncDateFields}
                            calculationsObject={this.state.calculationsObject}
                        />
                    ) : (
                        <></>
                    )}
                    {statusId &&
                    statusId != billingCycleStatus.Draft &&
                    statusId != billingCycleStatus.Pending &&
                    statusId != billingCycleStatus.ReadyForBilling &&
                    statusId != billingCycleStatus.BillChecked &&
                    statusId != billingCycleStatus.BillPackRequested &&
                    statusId != billingCycleStatus.BillPackReady ? (
                        <InvoicePaymentSection
                            viewDisable={this.props.location.state?.viewDisable}
                            invoiceId={this.state.invoiceId}
                            billingData={this.state.billingData}
                            onBillPaid={this.onBillPaid}
                            statusId={this.state.statusId}
                            syncDateFields={this.syncDateFields}
                            syncAmountFields={this.syncAmountFields}
                            invoicePaidDate={this.state.invoicePaidDate}
                            invoiceTotal={this.state.calculationsObject ? this.state.calculationsObject.total : ""}
                            invoiceAmount={this.state.invoiceAmount}
                            calculationsObject={this.state.calculationsObject}
                        />
                    ) : (
                        <></>
                    )}
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(BillingPageV2);
