import { faHome, faClock, faCheckCircle, faQuestionCircle, faCoins, faArchive, faCog } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ServiceType } from "../../common/ServiceUtilities";
import { PaymentTypes } from "../../services/FindAnArtistService";
import { outstandingActions } from "../../services/OutstandingApprovalService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { closeDrawer, onClickOfManageViewShiftsForAll, setDateForCurrentWeek, viewCostReportForService, viewPaymentRequestForService, viewSimpleShiftsForService, viewGigReq, viewArtistGrid, viewQueryReq, viewPaymentReqForServiceType, viewShiftImport } from "./NavigationUtils";

export const renderEventUKMainNavigationItems = (): ReactNode => {
    return (
        <ul className="nav-drawer__quick-links navbar-nav">
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link className="nav-link" onClick={closeDrawer} to="/">
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faHome} />
                    </span>
                    Home <span className="sr-only">(current)</span>
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large" hidden={UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)}>
                <Link
                    className="nav-link"
                    onClick={() => viewCostReportForService(ServiceType.Security)}
                    to={{
                        pathname: "/postApprovalActions",
                        state: {
                            status: outstandingActions.approvals,
                            serviceTypeId: "",
                            isHeader: true,
                        },
                    }}
                >
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    Approvals
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link
                    className="nav-link"
                    onClick={() => viewQueryReq()}
                    to={{
                        pathname: "/queryGrid",
                    }}
                >
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                    Manage Queries
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large" hidden={UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)}>
                <Link
                    className="nav-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.ContractCleaning)}
                    to={{
                        pathname: "/simpleShifts",
                        state: {
                            pointOfNavigation: "burgerMenu",
                        },
                    }}
                >
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    Manage Shifts
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link className="nav-link" onClick={closeDrawer} to="/budgetView">
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCoins} />
                    </span>
                    Budgets
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large" hidden={UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)}>
                <Link className="nav-link" onClick={closeDrawer} to="/manageBillingCycle">
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCoins} />
                    </span>
                    Billing
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large" hidden={UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)}>
                <Link className="nav-link" onClick={() => viewPaymentRequestForService(ServiceType.Entertainment)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCoins} />
                    </span>
                    Requests - Payments/Add Job
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large" hidden={UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)}>
                <Link className="nav-link" onClick={closeDrawer} to="/managePaymentCycle">
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCoins} />
                    </span>
                    Payments
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link
                    className="nav-link"
                    onClick={onClickOfManageViewShiftsForAll}
                    to={{
                        pathname: "/manageviewshift",
                        state: {
                            status: "",
                            dateFrom: setDateForCurrentWeek(true),
                            dateTo: setDateForCurrentWeek(false),
                        },
                    }}
                >
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faArchive} />
                    </span>
                    Shift Matrix
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link className="nav-link" onClick={closeDrawer} to="/matrixView">
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCog} />
                    </span>
                    Manage Our Services
                </Link>
            </li>
        </ul>
    );
}

export const renderEventUKCleaningNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewArtistGrid("ContractCleaning")} to="/artistGrid">
                    View Providers
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewGigReq("ContractCleaning")} to="/gigRequest">
                    Work Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewShiftImport("ContractCleaning")} to="/uploadSchedule">
                    Shift Import
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.ContractCleaning, PaymentTypes.AddJob)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Cleaning Job Notification
                </Link>
            </li>            
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.ContractCleaning)}
                    to={{
                        pathname: "/simpleShifts",
                        state: {
                            pointOfNavigation: "burgerMenu",
                        },
                    }}
                >
                    Shifts
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.ContractCleaning, PaymentTypes.PaymentRequest)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Payment Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.ContractCleaning)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.ContractCleaning)}
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "ContractCleaningPayment",
                        },
                    }}
                >
                    Payments
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.ContractCleaning)}
                    to={{
                        pathname: "/postApprovalActions",
                        state: {
                            status: outstandingActions.approvals,
                            isHeader: true,
                            serviceTypeId: ServiceType.ContractCleaning,
                        },
                    }}
                >
                    Approvals
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/outstandingActions",
                        state: {
                            status: outstandingActions.acceptances,
                            isHeader: true,
                            serviceTypeId: ServiceType.ContractCleaning,
                        },
                    }}
                >
                    Client Amendments
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    // to="/ClientInformationPage"
                    to={{
                        pathname: "/ClientInformationPage",
                        state: {
                            Header: "Contract Cleaning",
                            serviceTypeId: ServiceType.ContractCleaning,
                        },
                    }}
                >
                    Client Information and Venue Conditions
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    // to="/SupplierInformationPage"
                    to={{
                        pathname: "/SupplierInformationPage",
                        state: {
                            Header: "Contract Cleaning",
                            serviceTypeId: ServiceType.ContractCleaning,
                        },
                    }}
                >
                    Supplier Information and Venue Conditions
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/providerProfile",
                        state: {
                            id: "",
                            serviceTypeId: ServiceType.ContractCleaning,
                        },
                    }}
                >
                    Profile
                </Link>
            </li>
        </ul>
    );
}

export const renderEventUKDoorSupervisionNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewArtistGrid("Security")} to="/artistGrid">
                    View Providers
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewGigReq("Security")} to="/gigRequest">
                    Work Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewShiftImport("Security")} to="/uploadSchedule">
                    Shift Import
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Security)}
                    to={{
                        pathname: "/simpleShifts",
                        state: {
                            pointOfNavigation: "burgerMenu",
                        },
                    }}
                >
                    Shifts
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Security)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Security)}
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "SecurityPayment",
                        },
                    }}
                >
                    Payments
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Security)}
                    to={{
                        pathname: "/postApprovalActions",
                        state: {
                            status: outstandingActions.approvals,
                            isHeader: true,
                            serviceTypeId: ServiceType.Security,
                        },
                    }}
                >
                    Approvals
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/outstandingActions",
                        state: {
                            status: outstandingActions.acceptances,
                            isHeader: true,
                            serviceTypeId: ServiceType.Security,
                        },
                    }}
                >
                    Client Amendments
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    // to="/ClientInformationPage"
                    to={{
                        pathname: "/ClientInformationPage",
                        state: {
                            Header: "Door Supervision",
                            serviceTypeId: ServiceType.Security,
                        },
                    }}
                >
                    Client Information & Venue Conditions
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    // to="/SupplierInformationPage"
                    to={{
                        pathname: "/SupplierInformationPage",
                        state: {
                            Header: "Door Supervision",
                            serviceTypeId: ServiceType.Security,
                        },
                    }}
                >
                    Supplier Information & Venue Conditions
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/providerProfile",
                        state: {
                            id: "",
                            serviceTypeId: ServiceType.Security,
                        },
                    }}
                >
                    Profile
                </Link>
            </li>
        </ul>
    );
}

export const renderEventUKEntertainmentNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Entertainment)}
                    to={{
                        pathname: "/signUpProfileUpdate"
                    }}
                >
                    Sign Ups / Profile Updates
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/findAnArtistLandingPage",
                        state: {
                            isNewRequest: true,
                            navigateFrom: "FindAnArtistMenu"
                        },
                    }}
                >
                    Find an Artist
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewArtistGrid("Entertainment")} to="/artistGrid">
                    View Artists
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewGigReq("Entertainment")} to="/gigRequest">
                    Gig Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewShiftImport("Entertainment")} to="/uploadSchedule">
                    Shift Import
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.Entertainment, PaymentTypes.AddJob)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Artist Job Notification
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Entertainment)}
                    to={{
                        pathname: "/simpleShifts",
                        state: {
                            pointOfNavigation: "burgerMenu",
                        },
                    }}
                >
                    Scheduled Artists
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.Entertainment, PaymentTypes.PaymentRequest)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Payment Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Entertainment)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Entertainment)}
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "EntertainmentPayment",
                        },
                    }}
                >
                    Payments
                </Link>
            </li>
        </ul>
    );
}

export const renderEventUKGardeningNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewArtistGrid("Gardening")} to="/artistGrid">
                    View Providers
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewGigReq("Gardening")} to="/gigRequest">
                    Work Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewShiftImport("Gardening")} to="/uploadSchedule">
                    Shift Import
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.Gardening, PaymentTypes.AddJob)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Gardening Job Notification
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Gardening)}
                    to={{
                        pathname: "/simpleShifts",
                        state: {
                            pointOfNavigation: "burgerMenu",
                        },
                    }}
                >
                    Shifts
                </Link>
            </li>     
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.Gardening, PaymentTypes.PaymentRequest)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Payment Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Gardening)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Gardening)}
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "GardeningPayment",
                        },
                    }}
                >
                    Payments
                </Link>
            </li>
        </ul>
    );
}

export const renderEventUKMaintenanceNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewArtistGrid("Maintenance")} to="/artistGrid">
                    View Providers
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewGigReq("Maintenance")} to="/gigRequest">
                    Work Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewShiftImport("Maintenance")} to="/uploadSchedule">
                    Shift Import
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.Maintenance, PaymentTypes.AddJob)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Maintenance Job Notification
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Maintenance)}
                    to={{
                        pathname: "/simpleShifts",
                        state: {
                            pointOfNavigation: "burgerMenu",
                        },
                    }}
                >
                    Shifts
                </Link>
            </li>     
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.Maintenance, PaymentTypes.PaymentRequest)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Payment Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Maintenance)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Maintenance)}
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "MaintenancePayment",
                        },
                    }}
                >
                    Payments
                </Link>
            </li>
        </ul>
    );
}

export const renderEventUKMiscelleanousNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewArtistGrid("Miscelleanous")} to="/artistGrid">
                    View Providers
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewGigReq("Miscelleanous")} to="/gigRequest">
                    Work Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewShiftImport("Miscelleanous")} to="/uploadSchedule">
                    Shift Import
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.Miscelleanous, PaymentTypes.AddJob)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Miscelleanous Job Notification
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Miscelleanous)}
                    to={{
                        pathname: "/simpleShifts",
                        state: {
                            pointOfNavigation: "burgerMenu",
                        },
                    }}
                >
                    Shifts
                </Link>
            </li>     
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.Miscelleanous, PaymentTypes.PaymentRequest)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Payment Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Miscelleanous)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Miscelleanous)}
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "MiscelleanousPayment",
                        },
                    }}
                >
                    Payments
                </Link>
            </li>
        </ul>
    );
}

export const renderEventUKWindowCleaningNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewArtistGrid("WindowCleaning")} to="/artistGrid">
                    View Providers
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewGigReq("WindowCleaning")} to="/gigRequest">
                    Work Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewShiftImport("WindowCleaning")} to="/uploadSchedule">
                    Shift Import
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.WindowCleaning, PaymentTypes.AddJob)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Window Cleaning Job Notification
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.WindowCleaning)}
                    to={{
                        pathname: "/simpleShifts",
                        state: {
                            pointOfNavigation: "burgerMenu",
                        },
                    }}
                >
                    Shifts
                </Link>
            </li>     
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={() => viewPaymentReqForServiceType(ServiceType.WindowCleaning, PaymentTypes.PaymentRequest)}
                    to={{
                        pathname: "/paymentRequests",
                        state: {
                            pointOfNavigation: "burgerMenuPayments",
                        },
                    }}>
                    Payment Requests
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.WindowCleaning)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.WindowCleaning)}
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "WindowCleaningPayment",
                        },
                    }}
                >
                    Payments
                </Link>
            </li>
        </ul>
    );
}
