import React, { Component } from "react";

// Props
interface GridToggleTabComponentProps {
    onToggle: (isToggle: boolean) => void;
    primaryTabText: string;
    secondaryTabText: string;
    className: string;
}

interface GridToggleTabComponentState {
    selectedPrimaryButton: boolean;
    selectedSecondaryButton: boolean;
    isNewSubmissionTab: boolean;
}

// Component - displays the file upload component and the two grids that show file data
class GridToggleTab extends Component<GridToggleTabComponentProps> {
    state: GridToggleTabComponentState;
    constructor(props: GridToggleTabComponentProps) {
        super(props);
        this.state = {
            selectedPrimaryButton: true,
            selectedSecondaryButton: false,
            isNewSubmissionTab: false,
        };
    }

    //A helper function that would toggle the state of the button to refresh the contents of the Parent Grid.
    toggleButtonStates = (toggleButtonState: boolean) => {
        this.setState({
            selectedPrimaryButton: toggleButtonState,
            selectedSecondaryButton: !toggleButtonState,
        });
        this.props.onToggle(toggleButtonState);
    };

    render() {
        var { primaryTabText, secondaryTabText, className } = this.props;
        var outerClassName: string = className == "Yellow" ? "tab-toggle-header" : "view-queries-header";
        var buttonClassName: string =
            className == "Yellow" ? "tab-toggle-header__toggle-buttons" : "view-queries-header__view-buttons";
        var tabClassName: string =
            className == "Yellow" ? "tab-toggle-header__toggle-button" : "view-queries-header__view-button";
        return (
            <div className="tab-toggle-grid mt-2">
                <div className={`${outerClassName}__controls`}>
                    <div className={`${buttonClassName}`}>
                        <button
                            className={`btn
                            ${tabClassName} ${this.state.selectedPrimaryButton ? `${tabClassName}--selected` : ""}`}
                            onClick={() => this.toggleButtonStates(true)}
                        >
                            {primaryTabText}
                        </button>
                        <button
                            className={`btn
                            ${tabClassName} ${this.state.selectedSecondaryButton ? `${tabClassName}--selected` : ""}`}
                            onClick={() => this.toggleButtonStates(false)}
                        >
                            {secondaryTabText}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default GridToggleTab;
