
import { Link } from "react-router-dom";
import React from "react";
import { LoadIndicator } from "devextreme-react";
import DashBoardService, { DashboardResponseCount } from "../../services/DashBoardService";
import { ServiceType } from "../../common/ServiceUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd, faMusic, faBroom, faFlowerTulip, faSoap, faWrench, faRectanglesMixed, faUserSecret, faUserCog } from "@fortawesome/pro-solid-svg-icons";
import { serviceTypes } from "../../services/FileService";
import GlobalService from "../../services/GlobalService";
import queryUtils from "../Query/QueryUtils";

interface NewRegistrationCardProps {
    serviceTypeId: string;
    dashBoardCount: DashboardResponseCount;
    isDashBoardDataLoaded: boolean;
}

interface NewRegistrationCardState {
}

class NewRegistrationCard extends React.Component<NewRegistrationCardProps> {
    state: NewRegistrationCardState;
    service: DashBoardService;
    queryUtils: queryUtils;
    constructor(props: NewRegistrationCardProps) {
        super(props);
        this.service = new DashBoardService();
        this.queryUtils = new queryUtils
        this.state = {
        };
    }

    isNavigateFromPendingQueries = (item:any) =>{
        let isPendingQueries = false;
        let navigateFrom = item.navigateFrom || '';
        if (navigateFrom == "PendingQueries_JustPay" || navigateFrom == "PendingQueries_EventUK" || navigateFrom == "PendingQueries_Other") {
           isPendingQueries = true;
        }
        return isPendingQueries;
    }

    onClickSetServiceType = (item:any) => {
        sessionStorage.setItem("serviceTypeFilter", this.props.serviceTypeId);
        if(item && this.isNavigateFromPendingQueries(item)){
            let raisedById = '';
            let raisedByList = this.queryUtils.lookUpRaisedBy();
            if(item.navigateFrom == 'PendingQueries_JustPay'){
                raisedById = raisedByList[3].id; //provider

            } else if(item.navigateFrom == 'PendingQueries_EventUK'){
                raisedById = raisedByList[1].id; //client
                
            } else if(item.navigateFrom == 'PendingQueries_Other'){
                raisedById = raisedByList[2].id; //event
                
            }
            GlobalService.setQueryFilterValues('', '', '','',raisedById);
            //GlobalService.setQueryFilterValues('', '', this.props.serviceTypeId,'',raisedById);
        }
    };

    getQueriesAdminData = ()=>{
        let arr = [{
            key: 3.2,
            text: "JustPay Queries",
            number: this.props.dashBoardCount.justPayQueriesPending,
            path: "/queryGrid",
            navigateFrom: "PendingQueries_JustPay",
            serviceType: this.props.serviceTypeId,
            pointOfNavigation:'home'
        },
        {
            key: 3.3,
            text: "Event UK Queries",
            number: this.props.dashBoardCount.eventUKQueriesPending,
            path: "/queryGrid",
            navigateFrom: "PendingQueries_EventUK",
            serviceType: this.props.serviceTypeId,
            pointOfNavigation:'home'

        },
        {
            key: 3.4,
            text: "Other Queries",
            number: this.props.dashBoardCount.queriesPending,
            path: "/queryGrid",
            navigateFrom: "PendingQueries_Other",
            serviceType: this.props.serviceTypeId,
            pointOfNavigation:'home'
        }]

        return arr;
    }


    render() {
        let serviceIcon;
        // let adminData = [
        //     {
        //         key: 3.1,
        //         text: "Pending Queries",
        //         number: this.props.dashBoardCount.queriesPending,
        //         path: "/queryGrid",
        //         navigateFrom: "PendingQueries",
        //         serviceType: this.props.serviceTypeId,
        //         pointOfNavigation:'home'
        //     }
        // ];
        let adminData = this.getQueriesAdminData();
        if(this.props.serviceTypeId == serviceTypes.Entertainment){
            serviceIcon = faMusic;
            adminData = [
                {
                    key: 3.1,
                    text: "Registrations / Profile Updates",
                    number: this.props.dashBoardCount.signUpPending,
                    path: "/signUpProfileUpdate",
                    navigateFrom: "SignUpPending",
                    serviceType: this.props.serviceTypeId,
                    pointOfNavigation:'home'
                },
                ...this.getQueriesAdminData()
                // {
                //     key: 3.2,
                //     text: "Pending Queries",
                //     number: this.props.dashBoardCount.queriesPending,
                //     path: "/queryGrid",
                //     navigateFrom: "PendingQueries",
                //     serviceType: this.props.serviceTypeId,
                //     pointOfNavigation:'home'
                // }
            ]
        }
        else if(this.props.serviceTypeId == serviceTypes.ContractCleaning){
            serviceIcon = faBroom;
        }
        else if(this.props.serviceTypeId == serviceTypes.Gardening){
            serviceIcon = faFlowerTulip;
        }
        else if(this.props.serviceTypeId == serviceTypes.WindowCleaning){
            serviceIcon = faSoap;
        }
        else if(this.props.serviceTypeId == serviceTypes.Maintenance){
            serviceIcon = faWrench;
        }
        else if(this.props.serviceTypeId == serviceTypes.Miscelleanous){
            serviceIcon = faRectanglesMixed;
        }
        else{
            serviceIcon = faUserSecret;
        }
        const outstandingActionsData = [
            {
                key: 1,
                title: "Payment Requests",
                icon: faHandHoldingUsd,
                style: "payment",
                data: [
                    {
                        key: 1.1,
                        text: "Unmatched",
                        number: this.props.dashBoardCount.paymentRequestPending,
                        path: "/paymentRequests",
                        navigateFrom: "PaymentRequestPending",
                        serviceType: this.props.serviceTypeId,
                        pointOfNavigation:'home'
                    },
                    {
                        key: 1.2,
                        text: "Disputed",
                        number: this.props.dashBoardCount.paymentRequestQueried,
                        path: "/paymentRequests",
                        navigateFrom: "PaymentRequestQueried",
                        serviceType: this.props.serviceTypeId,
                        pointOfNavigation:'home'
                    },
                    {
                        key: 1.3,
                        text: "Failed",
                        number: this.props.dashBoardCount.paymentFailed,
                        path: "/ProviderPayments",
                        navigateFrom: "PaymentRequestFailed",
                        serviceType: this.props.serviceTypeId,
                        pointOfNavigation:'home'
                    },
                    {
                        key: 1.4,
                        text: "Recent Faster Payments",
                        number: this.props.dashBoardCount.fasterPayment,
                        path: "/ProviderPayments",
                        navigateFrom: "PaymentWithFasterPayments",
                        serviceType: this.props.serviceTypeId,
                        pointOfNavigation:'home'
                    }
                ]
            },
            {
                key: 2,
                title: this.props.serviceTypeId == serviceTypes.Entertainment ? "Artist Jobs" : "Provider Jobs",
                icon: serviceIcon,
                style: "jobs",
                data: [
                    {
                        key: 2.1,
                        text: "Unmatched",
                        number: this.props.dashBoardCount.addJobPending,
                        path: "/paymentRequests",
                        navigateFrom: "AddJobPending",
                        serviceType: this.props.serviceTypeId,
                        pointOfNavigation:'home'
                    },
                    {
                        key: 2.2,
                        text: "Disputed",
                        number: this.props.dashBoardCount.addJobQueried,
                        path: "/paymentRequests",
                        navigateFrom: "AddJobQueried",
                        serviceType: this.props.serviceTypeId,
                        pointOfNavigation:'home'
                    }
                ]
            },
            {
                key: 3,
                title: "Admin",
                icon: faUserCog,
                style: "admin",
                data: adminData
            }

        ]

        return (
            <>
                {this.props.isDashBoardDataLoaded ? (
                    <div className="row">
                        {outstandingActionsData.map((card,index) =>
                            <div key={card.key} className={`${index == 0 || index == 2 ? 'col-lg':'col-lg-4'} col-12 mb-3 mb-lg-0`}>
                                <div className={`outstanding-actions__card outstanding-actions__card--${card.style}`}>
                                    <div className="outstanding-actions__header">
                                        <div className="outstanding-actions__icon">
                                            <FontAwesomeIcon icon={card.icon} />
                                        </div>
                                        <h4 className="outstanding-actions__title">
                                            {card.title}
                                        </h4>
                                    </div>
                                    <div className="d-flex mt-3">
                                        {card.data.map(item =>
                                            <Link
                                                key={item.key}
                                                to={{
                                                    pathname: item.path,
                                                    state: {
                                                        navigateFrom: item.navigateFrom,
                                                        serviceType: item.serviceType,
                                                        ...item.pointOfNavigation && {pointOfNavigation:item.pointOfNavigation}
                                                    },
                                                }}
                                                onClick={() => this.onClickSetServiceType(item)}
                                                className="outstanding-actions__item"
                                            >
                                                <span className="outstanding-actions__stat">{item.number}</span>
                                                <span>{item.text}</span>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <LoadIndicator
                        id="button-indicator"
                        height={60}
                        width={60}
                        className="home-page__todayloader"
                        visible={true}
                    />
                )}
            </>
        );
    }
}
export default NewRegistrationCard;
