import React from "react";
import { faCheck, faTimes, faClock, faMusic, faFlagCheckered } from "@fortawesome/pro-regular-svg-icons";
import gridUtils from "../grid/GridUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//props
interface MatchedCardProps {
    data: any;
    dateFrom: any;
    venueId: string;
    providerBusinessEntityId: string;
    providerFasterPayOriginalAmount: number | undefined;

}

// State
interface MatchedCardState {
    showProgressIndicator: boolean
}

// Component - displays both the header and child grids
class MatchedCard extends React.Component<MatchedCardProps> {
    state: MatchedCardState;
    gridUtils: gridUtils;
    constructor(props: MatchedCardProps) {
        super(props);
        // Initialize state and services/utils
        this.state = {
            showProgressIndicator: false
        };
        this.gridUtils = new gridUtils();
    }

    // Initialize state from server
    componentDidMount() {
        this.setState({
            showProgressIndicator: true,
        });
    }


    render() {
        let serviceSubType: string = "";
        serviceSubType = this.props.data.serviceSubType?.value;
        let stateText: string = ""; 
        let originalAmount = this.props.data.providerFasterPayOriginalAmount !== null ? this.props.data.providerFasterPayOriginalAmount : this.props.data.providerPayAmountLocal; 
        // define anything that is possibly null or needs formatting
        const dateToMatch = new Date(this.props.data.dateFrom.split("T")[0]);
        const dateToMatchString = dateToMatch.toLocaleDateString("en-GB");
        const propsDateToMatchString = this.props.dateFrom.toLocaleDateString("en-GB");
        // separate the data out from the HTML
        // this allows it to exist in the most basic state possible
        // later, iterate over the data to produce the HTML
        const cardData = [
            {
                key: 1,
                value: this.props.data.venue.venueName,
                isMatch: this.props.data.venue.id === this.props.venueId,
                importance: "danger"
            },
            {
                key: 2,
                value: dateToMatchString,
                isMatch: dateToMatchString === propsDateToMatchString,
                importance: "danger"
            },
            {
                key: 3,
                value: this.props.data.providerBusinessEntity.shortName,
                isMatch: this.props.data.providerBusinessEntity.id === this.props.providerBusinessEntityId,
                importance: "warning"
            },
            {
                key: 4,
                value: originalAmount,
                isMatch: this.gridUtils.convertCurrencyAsStringToFloat(originalAmount) === this.props.providerFasterPayOriginalAmount,
                importance: "warning"
            },
            {
                key: 5,
                value: this.props.data.startTime,
                icon: faClock,
                importance: "reference"
            },
            {
                key: 6,
                value: serviceSubType,
                icon: faMusic,
                importance: "reference"
            },
            {
                key: 7,
                value: this.props.data.paymentRequestStatusLookUp.value,
                icon: faFlagCheckered,
                importance: "reference"
            },
        ];

        return (
            <div className="grid-info__grid-card__container card-popup mr-5">
                <button className="btn grid-info__grid-card--orange"
                    // onClick={(e: MouseEvent) => {
                    //     e.preventDefault();
                    // }}
                    >
                    <span className="sr-only">{stateText}</span>
                    {
                        // iterate over the data
                        // this means the HMTL code can be written once rather than multiple times
                        cardData.map(item =>
                            <span key={item.key} className={`grid-info__grid-card__item--${item.importance}`}>
                                {item.icon != null ?
                                    // if icon provied, use icon
                                    <span className="grid-info__grid-card__item__icon">
                                        <FontAwesomeIcon icon={item.icon} />
                                    </span>
                                    :
                                    // else use isMatch
                                    item.isMatch ?
                                        <span className="grid-info__grid-card__item__icon grid-info__grid-card__item__icon--matching">
                                            <FontAwesomeIcon icon={faCheck} />
                                        </span>
                                        :
                                        <span className="grid-info__grid-card__item__icon grid-info__grid-card__item__icon--not-matching">
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                }
                                { item.value == "Cancelled" ? 
                                    <span className="ml-2 grid-info__grid-card__item__red">
                                        {item.value}
                                    </span>
                                    : item.value == "Queried" ?
                                        <span className="ml-2 grid-info__grid-card__item__ember">
                                            {item.value}
                                        </span>
                                        : <span className="ml-2 grid-info__grid-card__item__value">
                                                {item.value}
                                        </span>

                                }
                            </span>)

                    }
                </button>
            </div>
            
        )
    }
}

export default MatchedCard;
