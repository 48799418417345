import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Lookup,
    RequiredRule,
    FilterPanel,
    SearchPanel,
    StateStoring,
    Export
} from "devextreme-react/data-grid";
import { LoadIndicator, LoadPanel, SelectBox } from "devextreme-react";
import MatrixService, { ClientUploadMappingsGridRowItem } from "../../services/MatrixService";
import lookupService, { LookupTypeIndexes } from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import gridUtils from "../grid/GridUtilities";
import {
    OnRowUpdatingEvent,
    OnRowInsertingEvent,
    OnRowRemovingEvent,
    onEditCanceledEvent,
} from "../../types/DevExtremeTypes";
import periodMatrixFormUtil from "./PeriodMatrixFormUtil";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import CustomStore from 'devextreme/data/custom_store';
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
//props
interface ClientUploadMappingsGridProps { }

export interface ClientUploadMappingsAddEditObjectItem {
    id: number;
    businessEntityId: number;
    shortName: string;
    legalName: string;
}
export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}
// State
interface ClientUploadMappingsGridState {
    mappingsGridDataSource: ClientUploadMappingsGridRowItem[];
    showProgressIndicator: boolean;
    clientLookUp: LookupTypeItemGrid[];
    mappingsAddEditObject: ClientUploadMappingsAddEditObjectItem;
    errorMessage: any[];
    loadPanelVisible: boolean;
    clientLookUpAll: LookupTypeItemGrid[];
    active: boolean;
}

// Component - displays the provider upload mappings Grid
class ClientUploadMappingsGrid extends React.Component<ClientUploadMappingsGridProps> {
    //Initialize the component's state.
    state: ClientUploadMappingsGridState;
    rateService: MatrixService;
    sharedUtils: sharedUtils;
    dropdownService: lookupService;
    utils: periodMatrixFormUtil;
    gridUtils: gridUtils;
    constructor(props: ClientUploadMappingsGridProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.rateService = new MatrixService();
        this.dropdownService = new lookupService();
        this.utils = new periodMatrixFormUtil();
        this.gridUtils = new gridUtils();
        // Initialize state and services/utils
        this.state = {
            mappingsGridDataSource: [],
            showProgressIndicator: false,
            clientLookUp: [],
            clientLookUpAll: [],
            mappingsAddEditObject: {
                id: 0,
                businessEntityId: 0,
                shortName: "",
                legalName: ''
            },
            errorMessage: [],
            loadPanelVisible: false,
            active: true,
        };
    }

    componentDidMount() {
        this.dropDownDataSource();
        this.clientMappingDataSource();
    }

    dropDownDataSource = () => {
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.clientLegalNameLookup)
            .then(this.handleClientLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndexAll(LookupTypeIndexes.clientLegalNameLookup)
            .then(this.handleClientLUAllSuccess)
            .catch(this.handleError);
    };

    clientMappingDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.rateService.getClientUploadMappingDataItem().then(this.handleSuccess).catch(this.handleError);
    };

    lookUpCreationSelect = (response: AxiosResponse<any>): LookupTypeItemGrid[] => {
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        var select: LookupTypeItemGrid = {
            id: 0,
            value: "select",
            parentMappingId: "",
        };
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: parseInt(item.id),
                value: item.value,
                //value: item.id,
                parentMappingId: item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return [select].concat(itemLUItemArray);
    };

    lookUpCreation = (response: AxiosResponse<any>): LookupTypeItemGrid[] => {
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                // id: parseInt(item.id),
                id: item.id,
                value: item.value,
                parentMappingId: item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };
    
    handleClientLUSuccess = (response: AxiosResponse<any>) => {
        //var clientDropDown = this.lookUpCreationSelect(response);
        var clientDropDown = this.lookUpCreation(response);
        this.setState({
            clientLookUp: clientDropDown,
            //clientLookUpAll: clientDropDown,
        });
    };

    handleClientLUAllSuccess = (response: AxiosResponse<any>) => {
        // var clientDropDown = this.lookUpCreationSelect(response);
        var clientDropDown = this.lookUpCreation(response);
        this.setState({
            clientLookUpAll: clientDropDown,
        });
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            mappingsGridDataSource: response.data.data,
            showProgressIndicator: false,
            loadPanelVisible: false,
        });
    };

    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
            loadPanelVisible: false,
        });
        var respMessage: string = "Rate service failed with response: " + JSON.stringify(error);

        if (!this.rateService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    onRowUpadating = (e: OnRowUpdatingEvent) => {
        // var typeId: string =
        //     e.newData.idTypeLookUpId == undefined ? e.oldData.idTypeLookUpId : e.newData.idTypeLookUpId;
        // var typeValue: string = e.newData.idValue == undefined ? e.oldData.idValue : e.newData.idValue;
        // var typeId: string =
        //     e.newData.businessEntityId == undefined ? e.oldData.businessEntityId : e.newData.businessEntityId;
        // var typeValue: string = e.newData.legalName == undefined ? e.oldData.legalName : e.newData.legalName;
        // var name: string = e.newData.shortName == undefined ? e.oldData.shortName : e.newData.shortName;
        this.setState({
            loadPanelVisible: true,
            errorMessage:[],
            mappingsAddEditObject: {
                ...this.state.mappingsAddEditObject,
                id: e.oldData.id,
                businessEntityId:
                    e.newData.businessEntityId ||
                        e.newData.businessEntityId === null ||
                        e.newData.businessEntityId === 0 ||
                        e.newData.businessEntityId === ""
                        ? e.newData.businessEntityId !== 0
                            ? e.newData.businessEntityId
                            : null
                        : e.oldData.businessEntityId !== 0
                            ? e.oldData.businessEntityId
                            : null,
                legalName:
                    e.newData.legalName || e.newData.legalName === null || e.newData.legalName === ""
                        ? e.newData.legalName
                        : e.oldData.legalName,
                shortName:
                    e.newData.shortName || e.newData.shortName === null || e.newData.shortName === ""
                        ? e.newData.shortName
                        : e.oldData.shortName,
            },
        }, () => {
            this.rateService
                .saveClientUploadMappingDataItem(this.state.mappingsAddEditObject)
                .then(this.onRowUpdatedInsertedDeleted)
                .catch((err) => {
                    this.setState({
                        loadPanelVisible: false,
                        errorMessage:
                            err.response && err.response.data && err.response.data.error
                                ? JSON.parse(JSON.stringify(err.response.data.error))
                                : null,
                    });
                });
            var typeId = this.state.mappingsAddEditObject.businessEntityId;
            var typeValue = this.state.mappingsAddEditObject.legalName;
            var name = this.state.mappingsAddEditObject.shortName;
            e.cancel = this.addEditCancel(e, typeId, typeValue, name);
        });


    };

    addEditCancel = (e: any, typeId: any, typeValue: any, name: any) => {
        var cancel: boolean = false;
        var isValid: boolean = this.utils.shortNameValidation(typeId, typeValue, name);
        if (!isValid || this.state.errorMessage.length > 0) {
            cancel = true;
            // this.setState({
            //     errorMessage: [],
            // });
        } else {
            cancel = false;
            this.setState({
                errorMessage: [],
            });
        }
        return cancel;
    };

    onRowUpdatedInsertedDeleted = () => {
        this.setState({
            // loadPanelVisible: false,
            errorMessage: [],
        });
        this.rateService.getClientUploadMappingDataItem().then(this.handleSuccess).catch(this.handleError);
    };

    onRowInserting = (e: OnRowInsertingEvent) => {
        this.setState({
            active: true,
            loadPanelVisible: true,
            errorMessage:[],
            mappingsAddEditObject: {
                ...this.state.mappingsAddEditObject,
                id: 0,
                businessEntityId: e.data.businessEntityId !== 0 ? e.data.businessEntityId : null,
                shortName: e.data.shortName ? e.data.shortName : "",
                legalName: e.data.legalName ? e.data.legalName : "",
            },
        }, () => {
            this.rateService
                .saveClientUploadMappingDataItem(this.state.mappingsAddEditObject)
                .then(this.onRowUpdatedInsertedDeleted)
                .catch((err) => {
                    this.setState({
                        loadPanelVisible: false,
                        errorMessage:
                            err.response && err.response.data && err.response.data.error
                                ? JSON.parse(JSON.stringify(err.response.data.error))
                                : null,
                    });
                });
                e.cancel = this.addEditCancel(
                    e,
                    this.state.mappingsAddEditObject.businessEntityId,
                    this.state.mappingsAddEditObject.legalName,
                    this.state.mappingsAddEditObject.shortName
                );
        });


    };

    onRowRemoving = (e: OnRowRemovingEvent) => {
        this.rateService
            .deleteClientUploadMappingDataItem(e.data.id)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch(this.handleError);
    };

    onEditCancelled = (e: onEditCanceledEvent) => {
        this.setState({
            errorMessage: [],
        });
    };

    renderErrorMessage = (errorMessage: any[]) => {
        let errorData: React.ReactNode = <></>;
        if (errorMessage) {
            errorData = (
                <span className="unscheduled-shift">
                    <ul>
                        {errorMessage.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    {item.columnName}: {item.errorMessage}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            );
        }
        return errorData;
    };

    onRowEditting = () => {
        this.setState({
            active: false,
            errorMessage:[]
        });
    };

    onRowAdding = () => {
        this.setState({
            active: true,
            errorMessage:[]
        });
    };

    onEditorPreparing = (editEvent: any) => {
        // const currentDataField = editEvent.dataField;
        // const caption = editEvent.caption;
        // const currentRow = editEvent.row;
        // if (currentDataField == 'providerBusinessEntityId' && caption.toLowerCase() == 'provider') {
        //     editEvent.editorName = 'dxLookupTypeListDynamic';
        //     editEvent.editorOptions.dataSource = this.state.active ? this.state.providerLookUp : this.state.providerLookUpAll;
        //     editEvent.editorOptions.displayExpression = 'value';
        //     editEvent.editorOptions.parentMappingId = '';
        //     //editEvent.editorOptions.isRoot = true;
        //     editEvent.editorOptions.onValueChanged = function (args:any) {  // Override the default handler
        //         console.log(currentRow);
        //     }

        // }
        if (editEvent.parentType === "filterRow" && editEvent.dataField === "businessEntityId" && editEvent.caption?.toLowerCase() == 'client') {
            editEvent.editorName = "dxTextBox"
            editEvent.editorOptions.valueChangeEvent = "keyup";
        }
    }

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    setClientCellValue = (rowData: any, value: any) => {
        let filtered = this.state.active ? this.state.clientLookUp.filter(a => a.id == value) : this.state.clientLookUpAll.filter(a => a.id == value)
        if (filtered && filtered.length) {
            let id = filtered[0].id;
            let name = filtered[0].value;
            rowData.businessEntityId = id === 0 ? null : id;
            rowData.legalName = name;
        }
        //rowData.businessEntityId = value === 0 ? null : value;
    };

    setClientCellComponent = (cellInfo: any) => {
        return (
            <div>
                {cellInfo.data.legalName}
            </div>
        );
    };
    onCustomGridLoad() {
        return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.clientUploadMapping);
    }

    onCustomGridSave(gridState: Object) {
        CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.clientUploadMapping, gridState);
    }

    // getlookupDataSourceConfig1 = () => {
    //     let obj = {
    //         store: {
    //             type: 'array',
    //             data: this.state.active ? this.state.clientLookUp : this.state.clientLookUpAll
    //         },
    //         pageSize: 10,
    //         paginate: true
    //     }
    //     return obj;
    // }

    getlookupDataSourceConfig = () =>{
        let config = {
            store: new CustomStore({
                //key: "id",
                loadMode: "raw",
                load: () => {
                    return this.state.active ? this.state.clientLookUp : this.state.clientLookUpAll;
                }
            }),
            // sort: "name",
            pageSize: 10,
            paginate: true
        };
        return config;
    }

    calculateFilterExpression(this:any,filterValue:any, selectedFilterOperation:any, target:any){
        //filterBuilder
        if (target == "filterRow" && this.caption?.toLowerCase() == 'client') {
            if(selectedFilterOperation){
                //let filterExpression = [['legalName',selectedFilterOperation,filterValue],'or',[this.dataField,selectedFilterOperation,filterValue]]
                let filterExpression = ['legalName',selectedFilterOperation,filterValue]
                return [filterExpression];
            }
        }
        return this.defaultCalculateFilterExpression?.apply(this, arguments);
    }
    
    calculateSortValue(this:any,data:any) {
        //var value = this.calculateCellValue(data);
        //let value = data.legalName;
        //return this.lookup.calculateCellValue(value);
        return data.legalName;
    }
    render() {
        var { errorMessage, loadPanelVisible } = this.state;
        return (
            <div>
                <h2 className="page-title__black-table">Client Upload Mappings</h2>
                {this.renderErrorMessage(errorMessage)}
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={loadPanelVisible} />
                        <DataGrid
                            dataSource={this.state.mappingsGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            onRowUpdating={this.onRowUpadating}
                            onRowInserting={this.onRowInserting}
                            onRowRemoving={this.onRowRemoving}
                            onEditCanceling={this.onEditCancelled}
                            onEditingStart={this.onRowEditting}
                            onInitNewRow={this.onRowAdding}
                            onEditorPreparing={this.onEditorPreparing}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            onExporting={this.onExporting}
                        >
                            <Export enabled={true} />
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Sorting mode="single" />
                            <FilterPanel visible={false} />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <Editing
                                mode="row"
                                useIcons={true}
                                allowAdding={true}
                                allowUpdating={true}
                                allowDeleting={true}
                            />
                            <StateStoring
                                enabled={true}
                                type="custom"
                                customLoad={this.onCustomGridLoad}
                                customSave={this.onCustomGridSave}
                                savingTimeout={0}
                            />
                            <Column type="buttons" buttons={["edit", "delete"]} />
                           {/* <Column dataField="id" allowSorting={true} caption="ID" allowEditing={false} calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.id);
                            }} /> */}
                            <Column dataField="id" caption="ID" allowEditing={false} cssClass='grid-column-padding'></Column>
                            <Column
                                dataField="businessEntityId"
                                name='client'
                                allowSorting={true}
                                caption="CLIENT"
                                setCellValue={this.setClientCellValue}
                                cellRender={this.setClientCellComponent}
                                filterOperations={[ "contains", "notcontains", "startswith", "endswith", "=", "<>" ]}
                                calculateFilterExpression={this.calculateFilterExpression}
                                calculateSortValue={this.calculateSortValue}
                                dataType="string"
                            >
                                <Lookup
                                    // dataSource={
                                    //     this.state.active ? this.state.clientLookUp : this.state.clientLookUpAll
                                    // }
                                    dataSource={this.getlookupDataSourceConfig}
                                    displayExpr="value"
                                    valueExpr="id"
                                />
                            </Column>
                            <Column
                                dataField="businessEntityId"
                                name='clientID'
                                allowSorting={true}
                                caption="CLIENT ID"
                                allowEditing={false}
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertStringToInt(rowData.businessEntityId);
                                }}>
                                    <RequiredRule/>
                            </Column>
                            <Column dataField="shortName" allowSorting={true} caption="MAPPING NAME"> <RequiredRule/></Column>
                            <Column
                                dataField="lastUpdatedOn"
                                allowSorting={true}
                                caption="LAST UPDATED ON"
                                allowEditing={false}
                            />
                            <Column
                                dataField="lastUpdatedBy"
                                allowSorting={true}
                                caption="LAST UPDATED BY"
                                allowEditing={false}
                            />
                            <FilterRow visible={true} applyFilter="auto" />
                        </DataGrid>
                    </>
                )}
            </div>
        );
    }
}

export default ClientUploadMappingsGrid;
