import React from "react";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";
import LicenseChecksPage from "./LicenseChecksPage";

interface LicenseChecksPageContainerProps extends RouteProps, withValidUserRoleProps {}

const LicenseChecksPageContainer = (props: LicenseChecksPageContainerProps) => {
    return <LicenseChecksPage/>;
};

export default withValidUserRole(LicenseChecksPageContainer);
