import React, { MouseEvent } from "react";
import { AxiosResponse,AxiosError } from "axios";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Scrolling,
    Editing,
    Texts,
    SearchPanel,
    FilterPanel,
    GroupPanel,
    Selection
} from "devextreme-react/data-grid";
import gridUtils from "../grid/GridUtilities";
import sharedUtils,{B2cColumnOptions} from "../grid/sharedUtilities";
import { LoadIndicator, Popup, ScrollView,Button } from "devextreme-react";
import { confirm,alert } from "devextreme/ui/dialog";
import AddContactComponent from "./AddContactComponent";
import FindAnArtistService from "../../services/FindAnArtistService";
import { ContactPageFormData } from "../Artist-Profile/ArtistProfileUtilitise";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser,faLink,faKey,faUserEdit } from "@fortawesome/free-solid-svg-icons";
import {faSync } from "@fortawesome/pro-regular-svg-icons";
import MatrixGridService from "../../services/MatrixService";
import { LoadPanel } from "devextreme-react";
import { DataGridOnSelectionChanged,SelectionType,onExportingEvent} from "../../types/DevExtremeTypes";
//import { faKeySkeleton } from "@fortawesome/pro-regular-svg-icons";
import notify from 'devextreme/ui/notify';
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
//props
interface ContactGridProps {
    location: any;
    history: any;
    contactType?: any;
    artistAdded: string;
    addContactPopup?:(type:any)=>void;
}

// State
interface ContactGridState {
    ContactGridDataSource: ContactPageFormData[];
    showProgressIndicator: boolean;
    businessEntityId: string;
    providerPopupVisibleEntertainment?: boolean;
    loadPanelVisible: boolean;
    selectedRows:any;
    showInviteLinkPopup:boolean;
    showErrorPopup:boolean;
    errorStatus:any;
    inviteLinkURL:string;
    isRefresh:boolean;
}

// Component - displays both the header and child grids
class ContactGrid extends React.Component<ContactGridProps> {
    state: ContactGridState;
    service: FindAnArtistService;
    matrixService: MatrixGridService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    isEditMode: string;
    dataGridRef:any;
    constructor(props: ContactGridProps) {
        super(props);
        this.isEditMode = "";
        // Initialize state and services/utils
        this.state = {
            ContactGridDataSource: [],
            showProgressIndicator: false,
            businessEntityId: "0",
            providerPopupVisibleEntertainment: false,
            loadPanelVisible: false,
            selectedRows:[],
            showInviteLinkPopup:false,
            showErrorPopup:false,
            inviteLinkURL:'',
            errorStatus:{},
            isRefresh:false
        };
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        // Functions
        this.service = new FindAnArtistService();
        this.matrixService = new MatrixGridService();
        this.dataGridRef = null;
    } 

    getOrganisationTypeByContactType = () => {
        if (this.props.contactType == "ViewClientContact") {
            this.service.getListOfContactGrid("10").then(this.handleSuccess).catch(this.handleFailure);
        }
        else if (this.props.contactType == "ViewProviderContact"){
            this.service.getListOfContactGrid("20").then(this.handleSuccess).catch(this.handleFailure);
        }
        else {
            this.service.getListOfContactGrid("30").then(this.handleSuccess).catch(this.handleFailure); 
        }
    }

    componentDidMount() {
        this.setState({loadPanelVisible:true});
        this.getOrganisationTypeByContactType();
    }
    
    componentDidUpdate = (prevprops: ContactGridProps, prevState: ContactGridState) => { 
        if(this.props.contactType != prevprops.contactType){
            this.setState({
                ContactGridDataSource: [],
                loadPanelVisible: true,
            });
            this.getOrganisationTypeByContactType();
        }
        if (this.props.artistAdded !== prevprops.artistAdded || (this.state.isRefresh !== prevState.isRefresh && this.state.isRefresh)) {
            this.setState({loadPanelVisible:true});
            this.getOrganisationTypeByContactType();         
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        for(let item of response.data.data){
            item.typeList = JSON.stringify(item.typeList);
        }
        this.setState(
            {
                loadPanelVisible: false,
                showProgressIndicator: false,
                ContactGridDataSource: response.data.data,
                isRefresh:false
            });
    };

    // handleProfileSuccess = () => {
    //     this.setState(
    //         {
    //             loadPanelVisible: false,
    //             showProgressIndicator: false,
    //         });
    // };

    handleFailure = () => {
        this.setState({
            loadPanelVisible: false,
            showProgressIndicator: false,
            isRefresh:false
        });
    };

    showInfoProvider = (businessEntityId: string) => {
        this.setState({
            businessEntityId: businessEntityId,
            providerPopupVisibleEntertainment: true,
        });
    };


    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
        });
        if(editmode == ""){
            this.setState({loadPanelVisible:true,});
            this.getOrganisationTypeByContactType();
        }
    }

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
            });
        }
    };

    artistProfileDisplay = (cellInfo: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <button
                    className={"btn icon-btn"}
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.id);
                    }}
                >
                    <FontAwesomeIcon icon={faUser} />
                </button>
            </div>
        );
    }

    contactLastUpdatedDisplay = (cellInfo: any) => {
        let finalFormattedDate: string = "";
        if(cellInfo.data?.lastUpdatedOn !== null && cellInfo.data?.lastUpdatedOn !== ""){
            let dateCreatedTime = cellInfo.data?.lastUpdatedOn;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        return (
            <div>
                {cellInfo.data?.lastUpdatedOn !== null && cellInfo.data?.lastUpdatedOn !== "" 
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };

    userLastUpdatedDisplay = (cellInfo: any) => {
        let finalFormattedDate: string = "";
        if(cellInfo.data?.user !== null && cellInfo.data?.user?.lastUpdatedOn !== null && cellInfo.data?.user?.lastUpdatedOn !== ""){
            let dateCreatedTime = cellInfo.data?.user?.lastUpdatedOn;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        return (
            <div>
                {cellInfo.data?.user?.lastUpdatedOn !== null && cellInfo.data?.user?.lastUpdatedOn !== "" 
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };

    onSelectionChanged = (value: DataGridOnSelectionChanged) => {
        this.setState({selectedRows:value.selectedRowsData});
    };

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    showB2cColumns = ()=>{
        let show = false;
        if(this.props.contactType && this.props.contactType == "ViewProviderContact"){
            show = true;
        }
        return show;
    }

    b2cActions =(cellInfo:any,type:string)=>{
      let header = '';
      if(type){
        if(type == B2cColumnOptions.INVITELINK){
            header = `Get Invite Link?`
        } else if(type == B2cColumnOptions.CREATEACCOUNT){
            header = 'Create Account?'
        } 
        // else if(type == B2cColumnOptions.RESETPASSWORD){
        //     header = 'Reset Password?'
        // } 
        if(header){
            // if(header && cellInfo?.data?.id){
            //     header = `${header} contact id - ${cellInfo?.data?.id}`;
            // }
            confirm(`<div class='text-center'>${header}</div>`, `Submit`).then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.onConfirmation(type,cellInfo.data);
                }
            });
        }
      }
    }

    renderB2cColumns = (cellInfo:any,type:string)=>{
        if(type && type == B2cColumnOptions.INVITELINK){
            return (
                <div className="d-flex justify-content-between align-items-center">
                    <button
                        className={"btn icon-btn"}
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.b2cActions(cellInfo,type);
                        }}
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </button>
                </div>
            );
        } else if(type && type == B2cColumnOptions.CREATEACCOUNT){
            return (
                <div className="d-flex justify-content-between align-items-center">
                    <button
                        className={"btn icon-btn"}
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.b2cActions(cellInfo,type);
                        }}
                    >
                        {/* <FontAwesomeIcon icon={faUser} /> */}
                        <FontAwesomeIcon icon={faUserEdit} />
                        {/* <FontAwesomeIcon icon={<img width="16" height="16" src="https://img.icons8.com/tiny-glyph/16/edit-user-male.png" alt="edit-user-male"/>} /> */}
                        {/* <img width="16" height="16" src="https://img.icons8.com/tiny-glyph/16/edit-user-male.png" alt="edit-user-male"/> */}
                    </button>
                </div>
            );
        } else if(type && type == B2cColumnOptions.RESETPASSWORD){
            return (
                <div className="d-flex justify-content-between align-items-center">
                    <button
                        className={"btn icon-btn"}
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.b2cActions(cellInfo,type);
                        }}
                    >
                        <FontAwesomeIcon icon={faKey} />
                        {/* <FontAwesomeIcon icon ={faKeySkeleton}/> */}
                    </button>
                </div>
            );
        } 
        //else{
            return null;
        //}
        
    }

    isBulkInviteDisabled = () => {
        let isDisabled = true;
        if (this.state.selectedRows && this.state.selectedRows.length && this.state.selectedRows.length > 0) {
            isDisabled = false;
        }
        return isDisabled;
    }

    handleBulkInviteClick = ()=>{
            if(this.state.selectedRows && this.state.selectedRows.length > 0){
                confirm("<div class='text-center'>Send Bulk Invite?</div>", "Submit").then((hasConfirmed) => {
                    if (hasConfirmed) {
                        this.onConfirmation(B2cColumnOptions.BULKINVITE,this.state.selectedRows);
                    }
                });
            }
    }
    
    onConfirmation = (type:string,reqData:any) => {
        this.setState({loadPanelVisible:true,inviteLinkURL:'',errorStatus:{}});
            switch (type) {
                case B2cColumnOptions.BULKINVITE:
                    let request = reqData.map((item:any)=>{
                        return {
                            id:item.id,
                            displayName:item.name,
                            email:item.email,
                            userId :item?.user?.id || ''
                        }
                    })
                    this.matrixService.sendBulkInvites(request)
                       .then((response)=>this.handleB2cSuccess(response,type))
                       .catch((error)=>{this.handleB2cError(error,type)})
                    break;
                case B2cColumnOptions.INVITELINK:
                case B2cColumnOptions.CREATEACCOUNT:
                    let reqObj = {
                        id:reqData.id,
                        displayName:reqData.name,
                        email:reqData.email,
                        userId :reqData?.user?.id || ''
                    }
                    this.matrixService.getInviteLink(reqObj)
                    .then((response)=>this.handleB2cSuccess(response,type))
                    .catch((error)=>{this.handleB2cError(error,type)})
                    break;
                case B2cColumnOptions.RESETPASSWORD:
                    let req = {
                        id:reqData.id,
                        displayName:reqData.name,
                        email:reqData.email
                    }
                    this.matrixService.resetPassword(req)
                    .then((response:any)=>this.handleB2cSuccess(response,type))
                    .catch((error:any)=>{this.handleB2cError(error,type)})
                    break;
                case 'default':
                    break;
            }
    };

    createErrorObject = (data:any) => {
        let errorObj = {
            success: [],
            error: [],
            validations: []
        }
        let showErros = false;
        if(data && data.length && data.length > 0){
            let success = data.filter((item:any)=>{return item.success});
            let error = data.filter((item:any)=>{return !item.success && item.validationErrors?.length <= 0});
            let validation = data.filter((item:any)=>{return !item.success && item.validationErrors?.length > 0});
            errorObj = {...errorObj,...{success :success,error:error,validations:validation}}
            showErros = true;
        }
        this.setState({errorStatus:errorObj,showErrorPopup:showErros})
    }

    inviteLinkValidations = (errorResponse:any) =>{
        let validationError = '';
        if(errorResponse && errorResponse.validationErrors?.length && errorResponse.validationErrors?.length > 0){
            validationError = errorResponse[0].errorMessage;
        }
        return validationError;
    }

    handleB2cSuccess = (response: AxiosResponse<any>, type: string) => {
        this.setState({ loadPanelVisible: false }, () => {
            if (type) {
                if (type == B2cColumnOptions.BULKINVITE) {
                    this.dataGridRef?.instance()?.clearSelection();
                    if (response?.data?.data) {
                        let allSuccess = response?.data?.data.every((item: any) => { return item.success });
                        if (allSuccess) {
                            notify({ message: "Thank you - invites has been sent to all contacts.", width: 300, shading: true, position: "center" }, 'success', 600);
                        } else {
                            this.createErrorObject(response?.data?.data);
                        }
                    }

                } else if (type == B2cColumnOptions.INVITELINK) {
                    let inviteLink = response?.data?.data?.inviteLink || '';
                    let hasInviteLink = inviteLink ? true : false;
                    let error = '';
                    if(!hasInviteLink)
                    error = this.inviteLinkValidations(response?.data?.data);
                    if (hasInviteLink) {
                        this.setState({
                            inviteLinkURL: inviteLink || '',
                            showInviteLinkPopup: true
                        });
                    } else if(error){
                    notify({ message: error, width: 300, shading: true }, 'error', 600);
                    }
                    else {
                        notify({ message: "Sorry! invite link could not be generated.", width: 300, shading: true }, 'error', 600);
                    }

                } else if (type == B2cColumnOptions.CREATEACCOUNT) {
                    let inviteLink = response?.data?.data?.inviteLink || '';
                    let hasInviteLink = inviteLink ? true : false;
                    let error = '';
                    if(!hasInviteLink)
                    error = this.inviteLinkValidations(response?.data?.data);
                    if (hasInviteLink) {
                        window.open(inviteLink, '_blank', 'noreferrer');
                    } else if(error) {
                        notify({ message: error, width: 300, shading: true }, 'error', 600);
                    } else { 
                        notify({ message: "Sorry! account could not be generated.", width: 300, shading: true }, 'error', 600);
                    }

                } else if (type == B2cColumnOptions.RESETPASSWORD) {
                    notify({ message: "Thank you - password reset link has been sent.", width: 300, shading: true, position: "center" }, 'success', 600);
                }
            }
        })
    }

    handleB2cError = (error: AxiosError<any>, type: string) => {
        this.setState({ loadPanelVisible: false }, () => {
            this.dataGridRef?.instance()?.clearSelection();
            if (type) {
                if (type == B2cColumnOptions.BULKINVITE) {
                    var respMessage: string = "Bulk invite failed with response: " + JSON.stringify(error);
                    notify({ message: "Sorry! Bulk invite could not be sent.", width: 300, shading: true }, 'error', 600);
                    if (!this.matrixService.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }

                } else if (type == B2cColumnOptions.INVITELINK) {
                    var respMessage: string = "Get invite link failed with response: " + JSON.stringify(error);
                    if (error.response?.data?.error && error.response?.data?.error.length && error.response?.data?.error.length > 0) {
                        let errorMessage = error.response.data.error[0].errorMessage;
                        notify({ message: errorMessage, width: 300, shading: true }, 'error', 600);
                    }
                    else {
                        notify({ message: "Sorry! invite link could not be generated.", width: 300, shading: true }, 'error', 600);
                    }

                    if (!this.matrixService.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }

                } else if (type == B2cColumnOptions.CREATEACCOUNT) {
                    var respMessage: string = "create account failed with response: " + JSON.stringify(error);
                    //notify({ message: "Sorry! account could not be generated.", width: 300, shading: true }, 'error', 600);
                    if (error.response?.data?.error && error.response?.data?.error.length && error.response?.data?.error.length > 0) {
                        let errorMessage = error.response.data.error[0].errorMessage;
                        notify({ message: errorMessage, width: 300, shading: true }, 'error', 600);
                    }
                    else {
                        notify({ message: "Sorry! account could not be generated.", width: 300, shading: true }, 'error', 600);
                    }

                    if (!this.matrixService.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                } else if (type == B2cColumnOptions.RESETPASSWORD) {
                    var respMessage: string = "reset password failed with response: " + JSON.stringify(error);
                    notify({ message: "Sorry! reset password link could not be generated.", width: 300, shading: true }, 'error', 600);
                    if (!this.matrixService.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                }
            }
        })
    }

    hideInvitePopup = () => {
        this.setState({showInviteLinkPopup: false,inviteLinkURL:''});
    };
    hideErrorPopup = () => {
        this.setState({showErrorPopup: false,errorStatus:{}});
    };

    onCopyClick = () =>{
        //this.setState({showInviteLinkPopup: false},()=>{
            navigator?.clipboard?.writeText(this.state.inviteLinkURL);
            //alert("", "success");
            notify({ message: "Link copied to the clipboard.", width: 300, shading: true, position: "center" }, 'success', 600);
        //});
    }

    renderInviteLinkPopup = () => {
        return(
            <Popup
                visible={this.state.showInviteLinkPopup}
                onHiding={this.hideInvitePopup}
                dragEnabled={false}
                showTitle={true}
                showCloseButton={true}
                title={"INVITE LINK GENERATED"}
                width={"30%"}
                height={400}
            >
                <ScrollView width='100%' height='80%'>
                {/* <ScrollView> */}
                    <div>
                    <div className="text-center card-form__heading" style={{paddingRight:'10px',wordBreak:'break-all'}}>{this.state.inviteLinkURL}</div>
                    {/* <div className="row justify-content-md-center">
                        <div className="col-3">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onCopyClick()
                                }}
                            >
                                COPY
                            </button>
                        </div>
    
                        <div className="col-3">
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.hideWarningPopup();
                                }}
                            >
                                CLOSE
                            </button>
                        </div>
                    </div> */}
                    </div>
                </ScrollView>
                <div className="row justify-content-md-center">
                        <div className="col-3">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onCopyClick()
                                }}
                            >
                                COPY
                            </button>
                        </div>
    
                        <div className="col-3">
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.hideInvitePopup();
                                }}
                            >
                                CLOSE
                            </button>
                        </div>
                </div>
            </Popup>
            )
    }

    renderErrorPopup = () => {
        return (
            <Popup
                visible={this.state.showErrorPopup}
                onHiding={this.hideErrorPopup}
                dragEnabled={false}
                showTitle={true}
                showCloseButton={true}
                title={"BULK INVITE STATUS"}
                width={"30%"}
                height={400}
            >
                <ScrollView width='100%' height='80%'>
                    <div>
                        {/* <div className="text-center card-form__heading" style={{ paddingRight: '10px', wordBreak: 'break-all' }}>{this.state.inviteLinkURL}</div> */}
                        {this.state.showErrorPopup && this.state.errorStatus?.error && this.state.errorStatus?.error?.length > 0 ? (
                            <>
                                <h5 className="unscheduled-shift">Failed :</h5>
                                <span className="unscheduled-shift">
                                    <ul>
                                        {this.state.errorStatus?.error?.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {/* {item.columnName}: {item.errorMessage} */}
                                                    {`Invite failed for contact`} : {item.id}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </span>
                            </>
                        ) : null}
                        {this.state.showErrorPopup && this.state.errorStatus?.validations && this.state.errorStatus?.validations?.length > 0 ? (
                            <>
                                <h5 className="unscheduled-shift">Issues :</h5>
                                <span className="unscheduled-shift">
                                    <ul>
                                        {/* {this.state.errorStatus?.validations?.validationErrors?.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {item.columnName}: {item.errorMessage}
                                                </li>
                                            );
                                        })} */}
                                        {this.state.errorStatus?.validations?.map((item: any, uniqueKey: number) => {
                                            {
                                               return (
                                                <>
                                                {item.validationErrors.map((error: any) => {
                                                    return (
                                                        <li key={uniqueKey}>
                                                            {/* {error.columnName} :  */}
                                                            {error.errorMessage} ( contact - {item.id} )
                                                        </li>)
                                                })}
                                                </>
                                               )
                                                
                                            }
                                        })}
                                    </ul>
                                </span>
                            </>
                        ) : null}
                        {this.state.showErrorPopup && this.state.errorStatus?.success && this.state.errorStatus?.success?.length > 0 ? (
                            <>
                                <h5 className="unscheduled-shift-success">Successful :</h5>
                                <span className="unscheduled-shift-success">
                                    <ul>
                                        {this.state.errorStatus?.success?.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {/* {item.columnName}: {item.errorMessage} */}
                                                    {`invite sent to contact`} : {item.id}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </span>
                            </>
                        ) : null}
                    </div>
                </ScrollView>
                <div className="row justify-content-md-center">
                    <div className="col-3">
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="submit"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.hideErrorPopup()
                            }}>
                            OK
                        </button>
                    </div>
                </div>
            </Popup>
        )
    }

    onRefresh = (refresh:boolean)=> {
        this.setState({isRefresh:refresh},()=>{
            if(refresh){
                this.dataGridRef?.instance?.clearSelection();
            }
            
        });
    }

    render() {
        let selectionMode:SelectionType;
        selectionMode = this.props.contactType == "ViewProviderContact"?  "multiple":"none";
        let headerText = this.props.contactType == 'ViewProviderContact' ?'Provider - Contact':'Client - Contacts';
        return (
            <div>
                {(this.props.contactType == 'ViewProviderContact' || this.props.contactType == 'ViewClientContact') && <>
                    <div>
                        <h2 className="page-title__black-table">{headerText}
                            <button
                                className="status-flow__refresh-shiftbutton btn gig-icon-btn"
                                aria-label="Refresh the current billing status"
                                //style={{paddingTop:0}}
                                onClick={() => this.onRefresh(true)}>
                                <FontAwesomeIcon icon={faSync} />
                            </button>
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-2 mt-3 mb-3">
                            <Button
                                className="btn btn-primary btn--large"
                                disabled={false}
                                onClick={() => 
                                    this.props.addContactPopup ? this.props.addContactPopup(this.props.contactType) : null
                            }
                            >
                                ADD CONTACT
                            </Button>
                        </div>
                        {this.props.contactType == 'ViewProviderContact' && <div className="col-2 mt-3 mb-3">
                            <Button
                                className="btn btn-primary btn--large"
                            disabled={this.isBulkInviteDisabled()}
                            onClick={() => this.handleBulkInviteClick()}
                            >
                                BULK INVITE
                            </Button>
                        </div>}
                        {/* <div className="col-2 col-lg-2 mb-3">
                            <button
                                className="status-flow__refresh-shiftbutton btn gig-icon-btn"
                                aria-label="Refresh the current billing status"
                                //style={{paddingTop:0}}
                                onClick={()=>this.onRefresh(true)}>
                                <FontAwesomeIcon icon={faSync} />
                            </button>
                        </div>  */}
                    </div>
                </>}
                {this.renderInviteLinkPopup()}
                {this.renderErrorPopup()}
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (  
                    <DataGrid
                        dataSource={this.state.ContactGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        columnResizingMode={"widget"}
                        noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                        columnMinWidth={100}
                        //keyExpr="id"
                        onSelectionChanged={this.onSelectionChanged}
                        ref={(ref) => { this.dataGridRef = ref; }}
                        onExporting={this.onExporting}
                    >
                        <Selection mode={selectionMode} selectAllMode="allPages" showCheckBoxesMode="always"></Selection>
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <GroupPanel visible={true} />
                        <FilterPanel visible={true} />
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Export enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        
                        <Column
                            caption="PROFILE"
                            allowExporting={false}
                            allowFiltering={false}
                            allowSorting={false}
                            allowGrouping={false}
                            cellRender={this.artistProfileDisplay}
                        />
                            {this.showB2cColumns() &&
                                <Column
                                    caption="GET INVITE LINK"
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowSorting={false}
                                    allowGrouping={false}
                                    cellRender={(rowData: any) => {
                                        return this.renderB2cColumns(rowData, B2cColumnOptions.INVITELINK)
                                    }}
                                />
                            }
                            {this.showB2cColumns() &&
                                <Column
                                    caption="CREATE ACCOUNT"
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowSorting={false}
                                    allowGrouping={false}
                                    cellRender={(rowData: any) => {
                                        return this.renderB2cColumns(rowData, B2cColumnOptions.CREATEACCOUNT)
                                    }}
                                />
                            }
                            {/* {this.showB2cColumns() &&
                                <Column
                                    caption="RESET PASSWORD"
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowSorting={false}
                                    allowGrouping={false}
                                    cellRender={(rowData: any) => {
                                        return this.renderB2cColumns(rowData, B2cColumnOptions.RESETPASSWORD)
                                    }}
                                />
                            } */}
                            
                        <Column 
                            dataField="id" 
                            caption="Contact Id" 
                        />
                        <Column 
                            dataField="businessEntity.shortName" 
                            caption={this.props.contactType == "ViewClientContact" ? "Client" : this.props.contactType == "ViewProviderContact" ? "Provider" : "Company"}
                        />
                        <Column 
                            dataField="name" 
                            caption="Name" 
                        />
                        <Column 
                            dataField="organisationType.value" 
                            caption="Organisation" 
                        />
                        <Column 
                            dataField="contactType.value" 
                            caption="Contact Type"
                        />
                        <Column 
                            dataField="jobTitle" 
                            caption="Job Title" 
                        />
                        <Column 
                            dataField="email" 
                            caption="Email" 
                        />
                        <Column 
                            dataField="mobile" 
                            caption="Mobile" 
                        />
                        <Column 
                            dataField="active" 
                            caption="Active" 
                        />
                        <Column 
                            dataField="lastUpdatedOn" 
                            caption="Last Updated"  
                            cellRender={this.contactLastUpdatedDisplay}
                            dataType="date"
                        />
                        <Column 
                            dataField="user.active" 
                            caption="Active User" 
                        />
                        <Column 
                            dataField="user.name" 
                            caption="User Name" 
                        />
                        <Column 
                            dataField="user.userRole.value" 
                            caption="User Role" 
                        />
                        <Column 
                            dataField="user.lastUpdatedOn" 
                            caption="User Last Updated" 
                            cellRender={this.userLastUpdatedDisplay}
                            dataType="date"
                        />                       
                        
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>                   
                )}
                {
                this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            hideOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title="Contact Details"
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <AddContactComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    businessEntityId={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    handleContact={() => { return null }}
                                    contactType={this.props.contactType}
                                ></AddContactComponent>
                            </ScrollView>
                        </Popup>
                    ) : (null)
                }
            </div>
        );
    }
}

export default ContactGrid;
