import React from "react";
import { ContractHourResponse } from "../../../services/OutstandingApprovalService";
import { contractHourLookup } from "../../Outstanding-Actions/OutstandingActionsUtilities";

interface CardCleaningSectionProps {
    itemType: boolean;
    contractHours: ContractHourResponse | null;
    weeklyHours: contractHourLookup[];
    isProvider: boolean;
    equipmentOriginalQuantity: number;
    equipmentTotalBillableQuantity: string;
    equipmentBillableQuantity: number;
    hoursApproved: string;
    providerPayAmount: string;
}

class CardCleaningSection extends React.Component<CardCleaningSectionProps> {
    constructor(props: CardCleaningSectionProps) {
        super(props);
    }

    render() {
        var {
            itemType,
            contractHours,
            weeklyHours,
            isProvider,
            equipmentBillableQuantity,
            equipmentTotalBillableQuantity,
            equipmentOriginalQuantity,
            hoursApproved,
        } = this.props;

        let hasDayRow = itemType == true && contractHours && contractHours.variableHours.toLowerCase() == "false";
        let outerClass = "";
        if (hasDayRow) {
            outerClass = "px-3 euk-card__calculation-box--right-aligned";
        } else {
            outerClass = "px-3";
        }

        return (
            <div className={outerClass}>
                {itemType == true && contractHours && contractHours.variableHours.toLowerCase() == "false" ? (
                    <div className="day-row">
                        {weeklyHours.map((item, index) => (
                            <div key={index}>
                                <div>{item.day}</div>
                                <div>{item.count}</div>
                            </div>
                        ))}
                    </div>
                ) : null}

                {itemType == false ? (
                    <div className="row mb-2 font-weight-bold">
                        <div className="col-9 text-right">{isProvider ? "Total Hours" : "Previously Billed Hours"}</div>
                        <div className="col-3 euk-card__calculation-box__value">{equipmentOriginalQuantity}</div>
                    </div>
                ) : null}

                {itemType == false || hoursApproved != "0" ? (
                    <div className="row mb-2 euk-card__adjustment-text">
                        <div className="col-9 text-right">Hours Adjustment</div>
                        <div className="col-3 euk-card__calculation-box__value">
                            {itemType == false ? equipmentTotalBillableQuantity : hoursApproved}
                        </div>
                    </div>
                ) : null}

                {contractHours && contractHours.variableHours.toLowerCase() == "true" && itemType == true ? null : (
                    <>
                        <div className="amount-row">
                            <div>
                                {this.props.providerPayAmount}
                            </div>
                            <div>
                                {isProvider
                                    ? itemType
                                        ? "Total Hours:"
                                        : "Total Hours Adjustment:"
                                    : "Total hours to approve:"}{" "}
                            </div>
                            <div>
                                {itemType ? equipmentBillableQuantity : equipmentTotalBillableQuantity}
                            </div>
                        </div>

                    </>
                )}
            </div>
        );
    }
}
export default CardCleaningSection;
