import sharedUtils from "../../grid/sharedUtilities";
import { IbulletinFormResponse, IbulletinFormRequest } from "../../../services/MatrixService";

type NullableDate = Date | null | undefined;

export interface BulletinFormData {
    id: string;
    bulletinCategoryId: string;
    bulletinPriorityId: string;
    bulletinTitle: string;
    bulletinName: string;
    bulletinDescription: string;
    bulletinDate: NullableDate;
    validFrom: NullableDate;
    validTo: NullableDate;
    isActive: boolean;
    isPopUpRequired: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    userRoleID: string;
    serviceTypeID: string;
    businessEntityID: string;
    linkId:string;
    link:string;
}

export default class BulletinFormUtil {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
    }

    initializeBulletinFormItem = (idFromGrid: string): BulletinFormData => {
        var formData: BulletinFormData = {
            id: "0",
            bulletinCategoryId: "",
            bulletinPriorityId: "",
            bulletinTitle: "",
            bulletinName: "",
            bulletinDescription: "",
            bulletinDate: undefined,
            validFrom: undefined,
            validTo: undefined,
            isActive: true,
            isPopUpRequired: false,
            lastUpdatedOn: "",
            lastUpdatedBy: "",
            businessEntityID: "",
            serviceTypeID: "",
            userRoleID: "",
            linkId:'',
            link:''
        };
        return formData;
    };

    // Convert the server response into the component's form state
    convertBulletinResponeToFormData = (serverResponse: IbulletinFormResponse): BulletinFormData => {
        var formData: BulletinFormData = {
            id: serverResponse.id ? serverResponse.id : "",
            bulletinCategoryId: serverResponse.bulletinCategoryId ? serverResponse.bulletinCategoryId : "",
            bulletinPriorityId: serverResponse.bulletinPriorityId ? serverResponse.bulletinPriorityId : "",
            bulletinTitle: serverResponse.bulletinTitle ? serverResponse.bulletinTitle : "",
            bulletinName: serverResponse.bulletinName ? serverResponse.bulletinName : "",
            bulletinDescription: serverResponse.bulletinDescription ? serverResponse.bulletinDescription : "",
            bulletinDate: serverResponse.bulletinDate ? this.utils.convertStringToDate(serverResponse.bulletinDate) : undefined,
            validFrom: serverResponse.validFrom ? this.utils.convertStringToDate(serverResponse.validFrom) : undefined,
            validTo: serverResponse.validTo ? this.utils.convertStringToDate(serverResponse.validTo) : undefined,
            isActive: serverResponse.isActive,
            isPopUpRequired: serverResponse.isPopUpRequired,
            lastUpdatedOn: serverResponse.lastUpdatedOn ? serverResponse.lastUpdatedOn : "",
            lastUpdatedBy: serverResponse.lastUpdatedBy ? serverResponse.lastUpdatedBy : "",
            userRoleID: serverResponse.userRoleID,
            serviceTypeID: serverResponse.serviceTypeID,
            businessEntityID: serverResponse.businessEntityID,
            linkId:serverResponse.linkId,
            link:serverResponse.link
        };
        return formData;
    };

    // Convert the form's data into a proper item submit request
    convertFormDataToBulletinRequest = (formData: BulletinFormData): IbulletinFormRequest => {
        var submitRequest: IbulletinFormRequest = {
            id: formData.id,
            bulletinCategoryId: formData.bulletinCategoryId,
            bulletinPriorityId: formData.bulletinPriorityId,
            bulletinTitle: formData.bulletinTitle,
            bulletinName: formData.bulletinName,
            bulletinDescription: formData.bulletinDescription,
            bulletinDate: formData.bulletinDate ? this.utils.convertDateToString(formData.bulletinDate) : "",
            validFrom: formData.validFrom ? this.utils.convertDateToString(formData.validFrom) : "",
            validTo: formData.validTo ? this.utils.convertDateToString(formData.validTo) : "",
            isActive: formData.isActive,
            isPopUpRequired: formData.isPopUpRequired,
            serviceTypeID: formData.serviceTypeID,
            userRoleID: formData.userRoleID,
            businessEntityID: formData.businessEntityID,
            linkId:formData.linkId
        };
        return submitRequest;
    };
}
