import Cookies from "js-cookie";
import LZString from "lz-string";

export const DXGridCookieKeyTypes = {
    simpleManageShiftsGrid: "simple_manage_shifts_grid",
    doorsupervisionShiftsGrid: "security_shifts_grid",
    contractCleaningShiftGrid: "cleaning_shifts_grid",
    entertainmentShiftGrid: "entertainment_shifts_grid",
    eventUKManageShiftsGrid: "eventuk_manage_shifts_grid",
    rateMatrixGrid:"rate_matrix_grid",
    providerUploadMapping:'provider_upload_mapping',
    clientUploadMapping:'client_upload_mapping',
    paymentRequestGridMatched: "payment_req_grid",
    paymentRequestGridForm: "payment_req_grid_form",
    gigRequestGrid :"gig_req_grid",
    queryGrid: "queryGrid",
    youtubeGrid: "youtubeGrid",
};

interface CookieCacheDictionary {
    [key: string]: string;
}

const CookieService = (function () {
    /** Initialize */
    var internalCookieCache: CookieCacheDictionary = {
        [DXGridCookieKeyTypes.eventUKManageShiftsGrid]: "",
        [DXGridCookieKeyTypes.simpleManageShiftsGrid]: "",
    };
    /**
     * js-cookie library auto encodes/decodes on set/get calls.
     * Our requirements demand compressing the string, so we need to override the default encoding/decoding
     */
    var cookiesWithNoDecoding = Cookies.withConverter({
        read: function (value: string, name: string): string {
            // decode override
            return value;
        },
        write: function (value: string | object, name: string): string {
            // encode override
            return value as string;
        },
    });
    /**
     * Save DevExtreme grid configurations.  Note: this is not per user.
     * Compression required as the Manage Shifts grid is too large to store uncompressed and it will grow with future requirements
     *
     * NOTE:  Using a Cookie will have the following issues
     *          1. Will be included on all API requests - impacting performace of the entire application.
     *          2. Has a 4kb storage limit, hence the need to compress any data being stored.  Uncompressing this data also has a performance impact.
     */
    const save = (storageKey: string, gridState: Object): void => {
        //var stateAsString = JSON.stringify(gridState);
        internalCookieCache[storageKey] = JSON.stringify(gridState);
        var compressedState = LZString.compressToUTF16(internalCookieCache[storageKey]);
        cookiesWithNoDecoding.set(storageKey, compressedState, {
            expires: 365,
            path: "/",
        });
    };
    /**
     * Load DevExtreme grid configurations.  Note: this is not per user.
     */
    const load = (storageKey: string): Promise<Object> => {
        var stateAsObject = null;

        if (internalCookieCache[storageKey].length > 0) {
            stateAsObject = JSON.parse(internalCookieCache[storageKey]);
        } else {
            var gridState: string = cookiesWithNoDecoding.get(storageKey) || "";

            if (gridState && gridState.length > 0) {
                var decompressedState = LZString.decompressFromUTF16(gridState) || "";
                stateAsObject = JSON.parse(decompressedState);
            }
        }

        if (stateAsObject) {
            return Promise.resolve(stateAsObject);
        } else {
            // Do not .reject() as it will only cause the grid to display an infinite loading spinner.
            // Sending back an empty object will force the grid use the default settings.
            return Promise.resolve({});
        }
    };
    /**
     * Use local storage
     */
    const localSave = (storageKey: string, gridState: Object): void => {
        localStorage.setItem(storageKey, JSON.stringify(gridState));
    };

    const localLoad = (storageKey: string): Promise<Object> => {
        var stateAsObject = null;
        var valueFromStorage = localStorage.getItem(storageKey);

        if (valueFromStorage && valueFromStorage.length > 0) {
            stateAsObject = JSON.parse(valueFromStorage);
        }

        if (stateAsObject) {
            return Promise.resolve(stateAsObject);
        } else {
            // Do not .reject() as it will only cause the grid to display an infinite loading spinner.
            // Sending back an empty object will force the grid use the default settings.
            return Promise.resolve({});
        }
    };

    return {
        saveDXGridConfiguration: localSave,
        loadDXGridConfiguration: localLoad
    };
})();

export default CookieService;
