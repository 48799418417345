
import { Break } from "devextreme-react/range-selector";
import { SubServiceType } from "../../common/ServiceUtilities";
import GlobalService from "../../services/GlobalService";
import { Status } from "../../services/OutstandingApprovalService";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";

export interface IServiceFeeMatrixDerivedVal {
    vaueOfMultiplier: string;
    valueOfAccrual: string;
    valueOfProviderAcceptance: boolean;
}


// adding one utility class to manage billableItem related common functionalities
// as of now (24/05/2022) adding two functionalities
export default class BillableItemUtils {
    //Product Backlog Item 13370: "Acceptance" & "AdjustmentProviderDebit" or "AdjustmentProviderDebitToClientCredit" - Core Logic Change
    serviceFeeMatrixDerivation = (clientId: string, serviceTypeId: string, serviceSubTypeId: string) => {
        let serviceFeeMatrixitem: any;
        var dataSourceOfServiceFeeMatrix = GlobalService.serviceFeeMatrixValue();
        if (dataSourceOfServiceFeeMatrix && dataSourceOfServiceFeeMatrix.length > 0) {
            if (
                clientId &&
                serviceTypeId &&
                serviceSubTypeId
            ) {
                let itemMatchingAll = dataSourceOfServiceFeeMatrix.filter((item) => {
                    return (item.clientBusinessEntityId == clientId &&
                        item.serviceTypeId == serviceTypeId &&
                        item.serviceSubTypeId == serviceSubTypeId &&
                        item.isActive)
                });
                let itemHavingServiceSubType = dataSourceOfServiceFeeMatrix.filter((item) => {
                    return (item.clientBusinessEntityId == clientId &&
                        item.serviceTypeId == serviceTypeId &&
                        item.serviceSubTypeId == serviceSubTypeId)
                });
                serviceFeeMatrixitem = itemMatchingAll;
                let itemMatchingClientService = dataSourceOfServiceFeeMatrix.filter((item) => {
                    return (item.clientBusinessEntityId == clientId &&
                        item.serviceTypeId == serviceTypeId &&
                        item.isActive)
                });
                // if (itemMatchingAll && itemMatchingAll.length > 0) {
                    // serviceFeeMatrixitem = itemMatchingAll;
                // }
                if (itemHavingServiceSubType && itemHavingServiceSubType.length == 0) {
                    serviceFeeMatrixitem = itemMatchingClientService;

                }
            } else if (clientId && serviceTypeId) {
                let itemForClientService = dataSourceOfServiceFeeMatrix.filter((item) => {
                    return (item.clientBusinessEntityId == clientId &&
                        item.serviceTypeId == serviceTypeId &&
                        item.isActive)
                });
                serviceFeeMatrixitem = itemForClientService;
            }
        }
        return {
            vaueOfMultiplier: serviceFeeMatrixitem && serviceFeeMatrixitem.length > 0 ? serviceFeeMatrixitem[0].multiplier : "",
            valueOfAccrual: serviceFeeMatrixitem && serviceFeeMatrixitem.length > 0 ? serviceFeeMatrixitem[0].isAccruals : "false",
            valueOfProviderAcceptance: serviceFeeMatrixitem && serviceFeeMatrixitem.length ? serviceFeeMatrixitem[0].providerAcceptances : ""
        }
    }
    // Product Backlog Item 13370: "Acceptance" & "AdjustmentProviderDebit" or "AdjustmentProviderDebitToClientCredit" - Core Logic Change
    acceptanceRecordCreation = (
        tenseFlag: string,
        itemTypeId: string,
        statusChanged: boolean,
        serviceSubTypeId: string,
        billableMin: number,
        scheduledMin: number,
        billableRate: number,
        scheduledRate: number,
        billableQuantity: number,
        scheduledQuantity: number,
        providerAcceptance: boolean
    ) => {
        let amend: boolean = false;
        var dataSourceOfServiceFeeMatrix = GlobalService.serviceFeeMatrixValue();
        if (dataSourceOfServiceFeeMatrix.length > 0 && providerAcceptance) {
            if (
                tenseFlag.toUpperCase() == "PAST" &&
                itemTypeId == itemTypes.Original &&
                statusChanged
            ) {
                if (serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel) {
                    if (
                        (billableMin &&
                            scheduledMin) ||
                        (billableRate && scheduledRate)
                    ) {
                        if (
                            billableMin !=
                            scheduledMin ||
                            billableRate != scheduledRate
                        ) {
                            amend = true;
                        }
                    }
                } else if (
                    serviceSubTypeId == SubServiceType.DoorSupervisionEquipment ||
                    serviceSubTypeId == SubServiceType.WeeklyCleaning ||
                    serviceSubTypeId == SubServiceType.AdditionalCleaning
                ) {
                    if (
                        (billableQuantity &&
                            scheduledQuantity) ||
                        (billableRate && scheduledRate)
                    ) {
                        if (
                            billableQuantity !=
                            scheduledQuantity ||
                            billableRate != scheduledRate
                        ) {
                            amend = true;
                        }
                    }
                }
            }
        }
        return amend;
    }

    // Product Backlog Item 13370: "Acceptance" & "AdjustmentProviderDebit" or "AdjustmentProviderDebitToClientCredit" - Core Logic Change
    billableItemStatusSetBasedOnItemType = (itemTypeId: string, providerAcceptance: boolean) => {
        var dataSourceOfServiceFeeMatrix = GlobalService.serviceFeeMatrixValue();
        var statusId: string = "";
        if ((itemTypeId == itemTypes.AdjustmentProviderDebit || itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit) && (dataSourceOfServiceFeeMatrix.length > 0 && providerAcceptance)) {
            statusId = Status.PendingAcceptance;
        }
        else {
            statusId = Status.Pending;
        }
        return statusId;
    }

}