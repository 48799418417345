import React from "react";

interface CardWrapper3PartProps {
    type: string;
    typeTitle: string;
    enumeration: boolean;
    cardIndex?: number;
    cardsTotal?: number;
    header: React.ReactNode;
    body?: React.ReactNode;
    footer?: React.ReactNode;
}

class CardWrapper3Part extends React.Component<CardWrapper3PartProps> {
    constructor(props: CardWrapper3PartProps) {
        super(props);
    }

    render() {
        return (
            <div className={"euk-card euk-card--full-height euk-card--" + this.props.type}>
                {/* Full height keeps cards aligned in the grid, may not be useful when using a single card as the whole page content/pop-up */}
                <div className="row euk-card__type-header">
                    <span className="col-12 col-md-8 euk-card__type-title">{this.props.typeTitle}</span>
                    {this.props.enumeration ? (
                        <span className="col-12 col-md-4 text-md-right euk-card__type-enumeration">
                            {this.props.cardIndex} of {this.props.cardsTotal}
                        </span>
                    ) : null}
                </div>
                <div className="euk-card__content-section euk-card__content-section--header">
                    {/* empty div for proper flex behaviour */}
                    <div>{this.props.header}</div>
                </div>
                <div className="euk-card__content-section--highlight euk-card__content-section--body">
                    {/* empty div for proper flex behaviour */}
                    <div>{this.props.body}</div>
                </div>
                {this.props.footer ? (
                    <div className="euk-card__content-section euk-card__content-section--footer">
                        {/* empty div for proper flex behaviour */}
                        <div>{this.props.footer}</div>
                    </div>
                ) : null}
            </div>
        );
    }
}
export default CardWrapper3Part;
