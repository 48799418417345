import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { alert } from "devextreme/ui/dialog";
import { Status, RaiseQueryFormData } from "../../services/AddEditPopUpService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import { LoadPanel } from "devextreme-react";
import CostReportUtils from "../CostReports/CostReportUtils";
import SimpleGridService, { GridTypes } from "../../services/SimpleGridService";
import sharedUtils from "../grid/sharedUtilities";
import UserService, { RoleGroupNames } from "../../services/UserService";
import CostReportService from "../../services/CostReportService";
import { AxiosResponse } from "axios";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { Link } from "react-router-dom";
import GlobalService from "../../services/GlobalService";

interface ViewShiftMatrixCellProps {
    data: any;
}

interface ViewShiftMatrixCellState {
    queryItem: RaiseQueryFormData;
    loadPanelVisible: boolean;
    onQueryRaising: boolean;
    isFinancialAdjustmentView: boolean;
    isAdjustmentChange: boolean;
    isEditIconDisable: boolean;
    status: string;
    statusColumnIndexVal: number;
}

class ViewShiftMatrixCell extends React.Component<ViewShiftMatrixCellProps> {
    state: ViewShiftMatrixCellState;
    Service: SimpleGridService;
    queryService: CostReportService;
    utils: addEditPopupUtils;
    costReportUtils: CostReportUtils;
    sharedUtils: sharedUtils;
    constructor(props: ViewShiftMatrixCellProps) {
        super(props);
        this.Service = new SimpleGridService();
        this.queryService = new CostReportService();
        this.utils = new addEditPopupUtils();
        this.costReportUtils = new CostReportUtils();
        this.sharedUtils = new sharedUtils();
        var convertedQueryFormData = this.utils.initializeQueryFormData(
            props.data.data && props.data.data.billableItemId ? props.data.data.billableItemId.toString() : "",
            "",
            "",
            "Shift / Item Status Query"
        );
        this.state = {
            queryItem: convertedQueryFormData,
            loadPanelVisible: false,
            onQueryRaising: false,
            isFinancialAdjustmentView: false,
            isAdjustmentChange: false,
            isEditIconDisable: false,
            status: this.props.data.data.status,
            statusColumnIndexVal: 0,
        };
    }

    componentDidMount() {
        if (this.props.data && this.props.data.data) {
            var statusColumnIndex: number = this.props.data.component.getVisibleColumnIndex("billableStatusLookUp.value");
            var noIcon: boolean = false;
            var tenseIdentifier: string = this.props.data.data.tenseFlag;

            var itemTypeId: string =
                this.props && this.props.data && this.props.data.data ? this.props.data.data.itemTypeId : "";
            if (
                (itemTypeId && itemTypeId == itemTypes.AdjustmentProviderDebit) ||
                (itemTypeId &&
                    itemTypeId == itemTypes.AdjustmentClientDebit &&
                    tenseIdentifier.trim().toLowerCase() == "past")
            ) {
            } else {
                noIcon = true;
            }

            if (
                this.props.data &&
                (this.props.data.data.itemTypeId == itemTypes.ClientCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderDebit ||
                    this.props.data.data.itemTypeId == itemTypes.Receivable ||
                    this.props.data.data.itemTypeId == itemTypes.Payable ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit)
            ) {
                this.setState({
                    isFinancialAdjustmentView: true,
                });
            }
            if (this.props.data && this.props.data.data && this.props.data.data.editAllowed && this.props.data.data.editAllowed.toLowerCase() == "false") {
                this.setState({
                    isEditIconDisable: true,
                });
            }
            if (
                ((this.props.data.data.itemTypeId == itemTypes.Original ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebit) &&
                    (this.props.data.data.approvalDate ||
                        this.props.data.data.acceptDate ||
                        this.props.data.data.billDate)) ||
                (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Approved") ||
                (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Accepted")
            ) {
                this.setState({
                    isAdjustmentChange: true,
                });
            }

            this.setState({
                statusColumnIndexVal: statusColumnIndex,
            });
        }
    }

    showLoadPanel = () => {
        this.setState({
            loadPanelVisible: true,
        });
    };

    hideLoadPanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    onQuerySubmission = () => {
        let queryDescriptionAdded: boolean = false;
        queryDescriptionAdded = this.sharedUtils.validationCheckFunction(this.state.queryItem.queryDescription);
        if (!queryDescriptionAdded) {
            alert("Please enter the query before pushing the submit button", "Raise Query");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            this.queryService
                .uploadQueryData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    handleQuerySubmissionSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            onQueryRaising: false,
        });
        alert("The Query has been submitted, an Event Uk staff will be in touch soon. Thanks!", "Query Submitted");
    };

    handleQuerySubmissionFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    //Helper function to close the Query popup.
    closeQueryBox = () => {
        this.setState({
            onQueryRaising: false,
        });
    };

    render() {
        var isEventUser =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        var gridType = GlobalService.getGridType();
        var pointOfNav: string = "";
        if (gridType && gridType == GridTypes.paymentsRequest) {
            pointOfNav = "MatchedPopUp";
        }
        else if (gridType && (gridType == GridTypes.artistShiftMatrix || gridType == GridTypes.providerShiftMatrix)) {
            pointOfNav = "FindAnArtistLandingPage";
        }
        else if (window.location.href.includes("BillingPageV2")) {
            pointOfNav = "Billing";
        }
        else {
            pointOfNav = "ManageShifts";
        }
        let statusVal: string = "";
        if (this.props.data.data.statusText) {
            statusVal = this.props.data.data.statusText;
        }
        else if (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value) {
            statusVal = this.props.data.data.billableStatusLookUp.value;
        }
        let service: string = "";
        if (this.props.data.data.service) {
            service = this.props.data.data.service;
        }
        else if (this.props.data.data.serviceTypeLookUp && this.props.data.data.serviceTypeLookUp.value) {
            service = this.props.data.data.serviceTypeLookUp.value;
        }
        let billableItemId: string = "";
        if (this.props.data.data && this.props.data.data.billableItemId) {
            billableItemId = this.props.data.data.billableItemId.toString();
        }
        else if (this.props.data.data && this.props.data.data.id) {
            billableItemId = this.props.data.data.id.toString();
        }
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        message="Please wait..."
                        visible={this.state.loadPanelVisible}
                    />
                    {isEventUser ? (
                        <div>
                            <Link
                                title="View"
                                className={"icon-btn"}
                                to={
                                    this.state.isFinancialAdjustmentView
                                        ? {
                                            pathname: "/FinanceAdjustmentPopUp",
                                            state: {
                                                id: billableItemId,
                                                adjustment: false,
                                                isReadOnly: true,
                                            },
                                        }
                                        : {
                                            pathname: "/AddEditFormV2",
                                            state: {
                                                id: billableItemId,
                                                adjustment: false,
                                                isReadOnly: true,
                                                pointOfNavigation: pointOfNav,
                                                statusText: statusVal,
                                                filterStartDate: this.props.data.data.filterStartDate,
                                                filterEndDate: this.props.data.data.filterEndDate,
                                                service: service,
                                            },
                                        }
                                }
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Link>
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

export default ViewShiftMatrixCell;
