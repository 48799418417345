import { PublicClientApplication, EventType, AuthenticationResult, EventMessage, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../components/Auth/AuthConfig";
import { _aiTraceAsError } from "./BaseApiService";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;

        msalInstance.setActiveAccount(account);
    }
});

export const getAccessToken = async () => {
    await msalInstance.handleRedirectPromise();

    const activeAccount = msalInstance.getActiveAccount(); 
    const accounts = msalInstance.getAllAccounts();
    const request = {
        scopes: loginRequest.scopes,
        account: activeAccount || accounts[0],
    };

    if (!activeAccount && accounts.length === 0) {
        // No active account, acquire with interaction.
        await msalInstance.acquireTokenRedirect(request);
    }
    else {
        const tokenResponse = await msalInstance.acquireTokenSilent(request).catch(error => {
            //Handle where refresh token cannot be used to silently aqcuire access token
            if (error instanceof InteractionRequiredAuthError) {
                //Fallback to interaction
                return msalInstance.acquireTokenRedirect(request)
            }
            _aiTraceAsError(error);
        });
        if(tokenResponse && tokenResponse.accessToken) {
            return tokenResponse.accessToken;
        }
        else {
            _aiTraceAsError("Failure acquiring access token.");
        }
    }
};

export const getAccountID = () => {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount) {
        return activeAccount.localAccountId;
    }
    return "";
};
