import React, { MouseEvent, Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import UserService, { RoleGroupNames } from "../../services/UserService";
import PaymentBACSGrid from "../Payments/BACS/BacsGrid";
import LightModePageTemplate from "./Templates/LightModeTemplate";

interface PaymentBACSPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
}

interface PaymentBACSPageComponentState {
    gridRefreshSignal: boolean;
}

class PaymentBACSPage extends Component<PaymentBACSPageComponentProps> {
    //Initialize the component's state.
    state: PaymentBACSPageComponentState;

    constructor(props: PaymentBACSPageComponentProps) {
        super(props);

        this.state = {
            gridRefreshSignal: false,
        };
    }
    //function that would indicate that a Grid refresh is required on click of the button.
    signalGridRefresh = () => {
        this.setState({ gridRefreshSignal: !this.state.gridRefreshSignal });
    };

    render() {
        let isaddButtonDisabled: boolean = true;
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            isaddButtonDisabled = false;
        }
        return (
            <LightModePageTemplate>
                <section className="container page-content">
                    <header className="grid-info mb-3">
                        <div className="grid-info__row mt-2 mb-3">
                            <div className="grid-info__button-container mr-auto">
                                <button
                                    className={(true ? "" : "disabled") + "btn btn-primary btn--large"}
                                    onClick={this.signalGridRefresh}
                                >
                                    Refresh
                                </button>
                            </div>
                            <div className="grid-info__button-container">
                                <Link
                                    className={(false ? "disabled " : "") + " btn btn-primary btn--large"}
                                    to="/managePaymentCycle"
                                >
                                    Close
                                </Link>
                            </div>
                        </div>
                        <h3 className="font-weight-bold ml-3">BACS VIEW</h3>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            <PaymentBACSGrid
                                location={this.props.location}
                                history={this.props.history}
                                refreshSignal={this.state.gridRefreshSignal}
                                id={this.props.location.state.id}
                            />
                        </div>
                        <div />
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(PaymentBACSPage);
