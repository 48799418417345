import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import DatePanel from "../side-bar/DatePanel";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import RaiseIncidentForm from "./RaiseIncidentForm";

interface RaiseIncidentPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
    incidentId: string;
}
interface RaiseIncidentPageComponentState {}
class RaiseIncidentPage extends Component<RaiseIncidentPageComponentProps> {
    state: RaiseIncidentPageComponentState;
    constructor(props: RaiseIncidentPageComponentProps) {
        super(props);
        this.state = {
            refreshGrid: false,
            gigCount: "0",
        };
    }

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DatePanel />
                </SideBarTemplate>
                <div id="independentBooking" className="page-content--with-sidebar-hidden-mobile">
                    <div className="col-12">
                        <h3 className="card-form__page-heading">
                            {this.props.location.state && this.props.location.state.id == "0"
                                ? "Door Supervision - Add New Incident"
                                : "Door Supervision - Update Incident"}
                        </h3>
                        <RaiseIncidentForm
                            location={this.props.location}
                            history={this.props.history}
                            incidentId={this.props.incidentId}
                        ></RaiseIncidentForm>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(RaiseIncidentPage);
