import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import lookupService from "../../services/LookupService";
import ScheduleService, { SchedulerItem } from "../../services/ScheduleService";
import BillingFormUtils from "../Billing/BillingFormUtils";
import sharedUtils from "../grid/sharedUtilities";
import { Link } from "react-router-dom";
import DataGrid, { Column } from "devextreme-react/data-grid";
import outstandingActionsUtilities, { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { RequestStatus } from "../../services/FindAnArtistService";
import GlobalService from "../../services/GlobalService";


interface BookingsByWeekProps extends WithTranslation {
    dataSource: [];
    isDashBoardDataLoaded: boolean;
    dateFrom: string;
    dateTo: string;
    serviceTypeId: string;
}
interface BookingsByWeekState {
    clientId: string;
    venueId: string;
    minDateValue: string;
    maxDateValue: string;
    schedulerData: SchedulerItem[];
    currentDate: Date;
    isLoad: boolean;
    periodMatrixData: any;
    period: string;
    week: string;
    dateFrom: NullableDate;
    dateTo: NullableDate;
    isShowDropDown: boolean;
    dataSource: [];
}
class BookingsByWeek extends React.Component<BookingsByWeekProps> {
    state: BookingsByWeekState;
    utils: sharedUtils;
    billingUtils: BillingFormUtils;
    conversionUtils: outstandingActionsUtilities;
    service: ScheduleService;
    lookUp: lookupService;
    constructor(props: BookingsByWeekProps) {
        super(props);
        this.utils = new sharedUtils();
        this.service = new ScheduleService();
        this.lookUp = new lookupService();
        this.billingUtils = new BillingFormUtils();
        this.conversionUtils = new outstandingActionsUtilities();
        this.state = {
            clientId: "",
            venueId: "",
            minDateValue: "",
            maxDateValue: "",
            schedulerData: [],
            currentDate: new Date(),
            isLoad: true,
            periodMatrixData: {},
            period: "",
            week: "",
            dateFrom: null,
            dateTo: null,
            isShowDropDown: false,
            dataSource: []
        };
    }
    isBlank(param: string) {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    }

    renderColumn = (navigateFrom: string, data: any[]) => {
        let blockToReturn: any[] = [];
        let items = data
            ? data.filter((item) => item.day == navigateFrom)
            : [];
        if (items.length > 0) {
            let dataToShow = items;
            blockToReturn.push(this.dataToShowBasedOnDay(dataToShow));
        }
        return blockToReturn;
    }


    renderDatarow = (rowInfo: any) => {
        return (
            <>
                <tr>
                    <td>
                        {this.renderColumn("Monday", rowInfo.data.values)}
                    </td>
                    <td>
                        {this.renderColumn("Tuesday", rowInfo.data.values)}
                    </td>
                    <td>
                        {this.renderColumn("Wednesday", rowInfo.data.values)}
                    </td>
                    <td>
                        {this.renderColumn("Thursday", rowInfo.data.values)}
                    </td>
                    <td>
                        {this.renderColumn("Friday", rowInfo.data.values)}
                    </td>
                    <td>
                        {this.renderColumn("Saturday", rowInfo.data.values)}
                    </td>
                    <td>
                        {this.renderColumn("Sunday", rowInfo.data.values)}
                    </td>

                </tr>
            </>
        )
    }

    checkService = () => {
        if(this.props.serviceTypeId == "10"){
           return "Security"
        }  
        else if(this.props.serviceTypeId == "20"){
            return "ContractCleaning"
        } 
        else if(this.props.serviceTypeId == "30"){
            return "Entertainment"
        }
        else if(this.props.serviceTypeId == "40"){
            return "Gardening"
        } 
        else if(this.props.serviceTypeId == "50"){
            return "WindowCleaning"
        } 
        else if(this.props.serviceTypeId == "60"){
            return "Maintenance"
        } 
        else{
            return "Miscelleanous"
        }  
    }

    dataToShowBasedOnDay = (dataToShow: any[]) => {
        let itemDataEnt = [
            {
                key: 1,
                title: "New Request",
                numberOf: dataToShow[0].newRequest,
                isActive: dataToShow.length > 0 && dataToShow[0].newRequest != "0",
                styleString: "bookings-by-week-item--new-request",
                statusToShow: RequestStatus.Pending,
                serviceType: this.props.serviceTypeId
            },
            {
                key: 2,
                title: "Advertised",
                numberOf: dataToShow[0].advertised,
                isActive: dataToShow.length > 0 && dataToShow[0].advertised != "0",
                styleString: "bookings-by-week-item--advertised",
                statusToShow: RequestStatus.Published,
                serviceType: this.props.serviceTypeId
            },
            {
                key: 3,
                title: "Options Sent",
                numberOf: dataToShow[0].optionSent,
                isActive: dataToShow.length > 0 && dataToShow[0].optionSent != "0",
                styleString: "bookings-by-week-item--optionSent",
                statusToShow: RequestStatus.OptionsSent,
                serviceType: this.props.serviceTypeId
            },
            {
                key: 4,
                title: "Shortlisted",
                numberOf: dataToShow[0].shortlisted,
                isActive: dataToShow.length > 0 && dataToShow[0].shortlisted != "0",
                styleString: "bookings-by-week-item--shortlisted",
                statusToShow: RequestStatus.Shortlisted,
                serviceType: this.props.serviceTypeId
            },
            {
                key: 5,
                title: "Offered",
                numberOf: dataToShow[0].offered,
                isActive: dataToShow.length > 0 && dataToShow[0].offered != "0",
                styleString: "bookings-by-week-item--offered",
                statusToShow: RequestStatus.Offered,
                serviceType: this.props.serviceTypeId
            },
            {
                key: 6,
                title: "Confirming",
                numberOf: dataToShow[0].confirming,
                isActive: dataToShow.length > 0 && dataToShow[0].confirming != "0",
                styleString: "bookings-by-week-item--confirming",
                statusToShow: "Confirm",
                serviceType: this.props.serviceTypeId
            },
            {
                key: 7,
                title: "Booked",
                numberOf: dataToShow[0].booked,
                isActive: dataToShow.length > 0 && dataToShow[0].booked != "0",
                styleString: "bookings-by-week-item--booked",
                statusToShow: RequestStatus.Booked,
                serviceType: this.props.serviceTypeId
            },
            {
                key: 8,
                title: "Re-Confirm",
                numberOf: dataToShow[0].reConfirm,
                isActive: dataToShow.length > 0 && dataToShow[0].reConfirm != "0",
                styleString: "bookings-by-week-item--reconfirm",
                statusToShow: "reConfirm",
                serviceType: this.props.serviceTypeId
            },
            
        ]
        let itemDataOther = [
            {
                key: 1,
                title: "New Request",
                numberOf: dataToShow[0].newRequest,
                isActive: dataToShow.length > 0 && dataToShow[0].newRequest != "0",
                styleString: "bookings-by-week-item--new-request",
                statusToShow: RequestStatus.Pending,
                serviceType: this.props.serviceTypeId
            },
            {
                key: 2,
                title: "Options Sent",
                numberOf: dataToShow[0].optionSent,
                isActive: dataToShow.length > 0 && dataToShow[0].optionSent != "0",
                styleString: "bookings-by-week-item--optionSent",
                statusToShow: RequestStatus.OptionsSent,
                serviceType: this.props.serviceTypeId
            },
            {
                key: 3,
                title: "Confirming",
                numberOf: dataToShow[0].confirming,
                isActive: dataToShow.length > 0 && dataToShow[0].confirming != "0",
                styleString: "bookings-by-week-item--confirming",
                statusToShow: "Confirm",
                serviceType: this.props.serviceTypeId
            },
            {
                key: 4,
                title: "Booked",
                numberOf: dataToShow[0].booked,
                isActive: dataToShow.length > 0 && dataToShow[0].booked != "0",
                styleString: "bookings-by-week-item--booked",
                statusToShow: RequestStatus.Booked,
                serviceType: this.props.serviceTypeId
            },
            
        ]
        GlobalService.setGigComingFrom(this.checkService());
        return (
            <div>
                { this.props.serviceTypeId == "30" ? 
                    itemDataEnt.map(item =>
                        item.isActive && item.numberOf ?
                            <Link key={item.key} className={`bookings-by-week-item ${item.styleString}`} to={{
                                pathname: "/gigRequest",
                                state: {
                                    navigateFrom: "BookingsByWeek",
                                    statusToShow: item.statusToShow,
                                    dateFrom: dataToShow[0].date,
                                    dateTo: dataToShow[0].date,
                                    serviceType: item.serviceType
                                }
                            }}>
                                {item.numberOf} {item.title}
                            </Link>
                            :
                            <Link key={item.key} className="bookings-by-week-item bookings-by-week-item--disabled" to="#" >
                                {item.numberOf} {item.title}
                            </Link>
                    ) : 
                    itemDataOther.map(item =>
                        item.isActive && item.numberOf ?
                            <Link key={item.key} className={`bookings-by-week-item ${item.styleString}`} to={{
                                pathname: "/gigRequest",
                                state: {
                                    navigateFrom: "BookingsByWeek",
                                    statusToShow: item.statusToShow,
                                    dateFrom: dataToShow[0].date,
                                    dateTo: dataToShow[0].date,
                                    serviceType: item.serviceType
                                }
                            }}>
                                {item.numberOf} {item.title}
                            </Link>
                            :
                            <Link key={item.key} className="bookings-by-week-item bookings-by-week-item--disabled" to="#" >
                                {item.numberOf} {item.title}
                            </Link>
                    )
                }
            </div>
        )
    }

    renderColumnHeader = (position: number, data: any) => {

        let headersData = []

        // the structure of the data seems to vary, so this will regularise it
        if (data.length === 1) {
            headersData = data[0].values;
        }
        else {
            headersData = data;
        }

        const dateToShow = headersData[position].date.split("/")[0];

        // highlight the matching date
        let matchingDateClass = "";
        if (this.state.currentDate.toLocaleDateString() === headersData[position].date){
            matchingDateClass = "data-grid--bookings-by-week__date-header--matching"
        }
        return (
            <div className={`data-grid--bookings-by-week__date-header ${matchingDateClass}`}>
                <span className="data-grid--bookings-by-week__date-header__date">{dateToShow}</span>
                <span className="data-grid--bookings-by-week__date-header__day">{headersData[position].day}</span>
            </div>
        )
    }

    render() {
        return (
            <>
                {this.props.isDashBoardDataLoaded ?
                    <DataGrid dataSource={this.props.dataSource} columnAutoWidth={true}
                        showBorders={false}
                        rowAlternationEnabled={true}
                        dataRowRender={this.renderDatarow}
                        className="data-grid--bookings-by-week"
                    >
                        <Column headerCellRender={() => this.renderColumnHeader(0, this.props.dataSource)} />
                        <Column headerCellRender={() => this.renderColumnHeader(1, this.props.dataSource)} />
                        <Column headerCellRender={() => this.renderColumnHeader(2, this.props.dataSource)} />
                        <Column headerCellRender={() => this.renderColumnHeader(3, this.props.dataSource)} />
                        <Column headerCellRender={() => this.renderColumnHeader(4, this.props.dataSource)} />
                        <Column headerCellRender={() => this.renderColumnHeader(5, this.props.dataSource)} />
                        <Column headerCellRender={() => this.renderColumnHeader(6, this.props.dataSource)} />
                    </DataGrid> : <></>}
            </>
        );
    }
}

export default withTranslation()(BookingsByWeek);
