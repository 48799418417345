import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError, _postFormData,_postBatchRequest} from "./BaseApiService";
import {
    linkTypeInfo,
    typeList,
    businessDocumentInfo,
    postSignUpMessageInfo,
    genreList,
    musicTypeList,
} from "../components/Artist-Profile/ArtistProfileUtilitise";

const QUERY_CARDS_LIST_API = "Query";
const BILLABLE_LIST_API = "BillableItem";
const UPLOAD_API = "BusinessEntity/Upload";
const ARTIST_PAGE_API = "BusinessEntity/Profile";
const DEACTIVATION_API = "BusinessEntity/Profile/Deactivate";
const CONFIGURATIONTEXT_API = "Lookup";
const ARTIST_PROFILE_API = "BusinessEntity/artistProfile";
const GIG_REQUEST_REMOTE_OPERATIONS = "Request/RemoteOperation";
const ARTIST_APPLIED = "Request/ShowApplied";
const ARTIST_VAT_API = "lookup/checkvatnumber";
const ARTIST_BANK_DETAILS_API = "BankAccount/AllAccounts"
const VENUE_PAGE_GET_API = "Venue";
const VENUE_PAGE_API = "Venue/Profile"
const CONTACT_PAGE_GET_API = "Contact";
const CONTACT_PAGE_API = "Contact/Profile"
const CREATE_AGREEMENT_DOC = "BusinessEntity/SelfBillAgreement";
const GET_SIGNED_DOC = "BusinessEntity/Envelope"
const CONTACT_SYNC_LOGIC_APP = "ContactSync"


export const PhotoIdType = {
    PhotoDrivingLicense: "40",
    Passport: "41",
    NationalIDCard: "42",
    OtherID: "43",
};

export const AgreementType = {
    SelfBillInvoiceAgreement: "25",
};


export const OtherType = {
    YouTube: "10",
    Website: "11",
    Facebook: "12",
    Instagram: "13",
    LinkedIn: "14",
    Twitter: "15",
    PrimaryPhoto: "20",    
    Photo: "21",
    PhotoId: "22",
    ProofOfAddressId: "23",
    AlternatePhoto1: "24",
    AlternatePhoto2: "25",
    AlternatePhoto3: "26",
    AlternatePhoto4: "27",
    AlternatePhoto5: "28",
    ProfilePhoto: "29",
    Video: "30",
    Track1: "31",
	Track2: "32",
	Track3: "33",
	Track4: "34",
	Track5: "35",
    BulletinPhoto:'80'
};
export const ServiceSubTypeId = {
    LiveMusic: "31",
    DJ: "32",
    Karaoke: "33",
    PubQuiz: "34",
    ChildrensEntertainer: "35",
    Drag: "36",
    TableMagician: "37",
    MascotCharacter: "38",
    MultiSkilled: "39",
    OpenMicHist: "40",
    Poker: "41",
    SoundEngineer: "42",
    Other: "50",
    RaceNight: "84",
    Salsa: "85",
    Dancer: "86",
    Comedy: "87",
    Bingo: "88",
    Agents: "89",
    EquipmentHire: "90",
    Photography: "91",
    Promotions: "92",
    Medical: "93",
    LightingJock: "94",
    MiniZoo: "95",
    Sponsorship: "96",
    Printing: "97",
    Chalkboards: "100",
    BouncyCastle: "101",
    Miscellaneous: "102"
};

export const Types = {
    Other: "50"
}


export interface queryCardData {
    id: string;
    queryTitle: string;
    queryDescription: string;
    contactId: string;
    contactName: string;
    companyId: string;
    company: string;
    d365LinkID: string;
    resolutionReason: string;
    billableItemId: string;
    queryStatusId: string;
    queryStatus: string;
    dateCreated: string;
    clientId: string;
    client: string;
    venueId: string;
    venue: string;
    comments: string;
    lastUpdate: string;
    assignedTo: string;
    serviceTypeId: string;
    serviceTypeValue: string;
    serviceSubTypeId: string;
    serviceSubTypeValue: string;
    dateFrom: string;
    startTime: string;
    finishTime: string;
    venueName: string;
    city: string;
    entertainmentDateInfo: string;
}
export interface actionItemData {
    billableItemId: string;
    venueName: string;
    service: string;
    performanceInformation: string;
    entertainmentDateInfo: string;
    pay_Date: string;
    provider_Pay_Amount_Local_Ccy: string;
    venueTownCity: string;
    shiftCompleteAcknowledgment: boolean;
    serviceSubTypeId: string;
    typeId: string;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
}
export interface gigItemData {
    billableItemId: string;
    venueName: string;
    service: string;
    performanceInformation: string;
    entertainmentDateInfo: string;
    pay_Date: string;
    provider_Pay_Amount_Local_Ccy: string;
    venueTownCity: string;
    serviceSubTypeId: string;
    typeId: string;
    role: string;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
}
export interface providerProfileResponse {
    businessEntityID: string;
    businessEntityTypeLookUpId: string;
    businessEntityType: string;
    legalName: string;
    tradingName: string;
    shortName: string;
    businessEntityLegalStatusLookUpId: string;
    businessEntityLegalStatus: string;
    companyNumber: string;
    taxApplicable: boolean;
    taxVerified: boolean;
    taxVerifiedBy: string;
    taxVerifiedDateTime: string;
    verified: boolean;
    verifiedBy: string;
    verifiedDateTime: string;
    openingHours: string;
    webSite: string;
    officePhone: string;
    email: string;
    providerPaymentOffsetDays: string;
    taxRegistrationNumber: string;
    addressStreet1: string;
    addressStreet2: string;
    addressStreet3: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
    professionalName: string;
    score: string;
    bio: string;
    shortDescription: string;
    coverageMiles: string;
    coverageTownCity: string;
    termsAgreed: boolean;
    businessEntityStatusId: string;
    businessEntityStatus: string;
    termsAgreedDateTime: string;
    venueFavourite: string;
    lastPlayedVenue: string;
    lastUpdatedOn: string,
    lastUpdatedBy:string,
    isActive: string;
    trustedUser: boolean;
    hideProfileExternally:boolean;
    hideFromSource:boolean;
    agent:boolean;
    //artist:boolean;
    factored:boolean;
    updateEmail: boolean;
    onboardedDate: string;
    contactInformation: {
        contactId: string;
        title: string;
        name: string;
        firstName: string;
        lastName: string;
        mobileTelephone: string;
        businessTelephone: string;
        otherTelephone: string;
        email: string;
        addressStreet1: string;
        addressStreet2: string;
        city: string;
        state: string;
        postCode: string;
        emailNotification: string;
        dateOfBirth: string;
        utr: string;
        ni: string;
        optOut: boolean;
        active: boolean;
        lastUpdatedOn: string;
    };
    user: {
        id: string;
        name: string;
        active: string;
        lastUpdatedOn: string;
        allowWebApp: boolean;
        disallowApp: boolean;
        disallowPortal: boolean;
        userRole: {
            id: string;
            value: string;
            parentMappingId: any;
        },
    };
    providerSiteManagerInformation: {
        contactId: string;
        title: string;
        firstName: string;
        lastName: string;
        name: string;
        mobileTelephone: string;
        businessTelephone: string;
        otherTelephone: string;
        email: string;
        addressStreet1: string;
        addressStreet2: string;
        city: string;
        state: string;
        postCode: string;
        emailNotification: string;
        dateOfBirth: string;
        utr: string;
        ni: string;
    } | null;
    bankInformation: {
        id: string;
        businessEntityID: string;
        contactID: string;
        bankName: string;
        branchName: string;
        accountNumber: string;
        sortCode: string;
        buildingSociety: string;
        nameOnAccount: string;
        active: string;
        verified: string;
        verifiedBy: string;
        verifiedDateTime: string;
        createdDateTime: string;
        serviceTypeLookUpId: string;
        serviceSubTypeLookUpId: string;
    };
    requirePayment: string;
    requirePaymentComment: string;
    artistApart: string;
    linkInformation: linkTypeInfo[];
    typeList: typeList[];
    businessEntityDocument: businessDocumentInfo[] | null;
    validationErrors: string;
    postSignUpMessages: postSignUpMessageInfo[];
    genreList: genreList[];
    musicTypeList: musicTypeList[];
    equipment: boolean;
    coveragePostCode: string;
    registration: boolean;
    profile: boolean;
    bankaccount: boolean;
    feedbackTypeId: string;
    feedbackScore: string;
    feedbackComments: string;
    oooProtocol: string;
    serviceTypeList: any[];
    profileFlag: string;
}

export interface providerProfileRequest {
    businessEntityID: string;
    businessEntityTypeLookUpId: string;
    businessEntityType: string;
    legalName: string;
    tradingName: string;
    shortName: string;
    businessEntityLegalStatusLookUpId: string;
    businessEntityLegalStatus: string;
    companyNumber: string;
    taxApplicable: boolean;
    taxVerified: boolean;
    taxVerifiedBy: string;
    taxVerifiedDateTime: string;
    verified: boolean;
    verifiedBy: string;
    verifiedDateTime: string;
    openingHours: string;
    webSite: string;
    officePhone: string;
    email: string;
    providerPaymentOffsetDays: string;
    taxRegistrationNumber: string;
    addressStreet1: string;
    addressStreet2: string;
    addressStreet3: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
    professionalName: string;
    score: string;
    bio: string;
    shortDescription: string;
    coverageMiles: string;
    coverageTownCity: string;
    termsAgreed: boolean;
    businessEntityStatusId: string;
    businessEntityStatus: string;
    termsAgreedDateTime: string;
    venueFavourite: string;
    lastPlayedVenue: string;
    isActive: string;
    trustedUser: boolean;
    hideProfileExternally:boolean;
    hideFromSource:boolean;
    agent:boolean;
    //artist:boolean;
    factored:boolean;
    updateEmail: boolean;
    onboardedDate:string;
    contactInformation: {
        contactId: string;
        name: string;
        mobileTelephone: string;
        businessTelephone: string;
        otherTelephone: string;
        email: string;
        addressStreet1: string;
        addressStreet2: string;
        city: string;
        state: string;
        postCode: string;
        emailNotification: string;
        dateOfBirth: string;
        utr: string;
        ni: string;
        optOut: boolean;
        active: boolean;
        lastUpdatedOn: string;
        contactTypeLookupId:string;
    } | null;
    user: {
        id: string;
        name: string;
        active: string;
        lastUpdatedOn: string;
        allowWebApp: boolean;
        disallowApp: boolean;
        disallowPortal: boolean;
        userRole: {
            id: string;
            value: string;
            parentMappingId: any;
        },
    } | null;
    providerSiteManagerInformation: {
        contactId: string;
        title: string;
        firstName: string;
        lastName: string;
        name: string;
        mobileTelephone: string;
        businessTelephone: string;
        otherTelephone: string;
        email: string;
        addressStreet1: string;
        addressStreet2: string;
        city: string;
        state: string;
        postCode: string;
        emailNotification: string;
        dateOfBirth: string;
        utr: string;
        ni: string;
    } | null;
    bankInformation: {
        id: string;
        businessEntityID: string;
        contactID: string;
        bankName: string;
        branchName: string;
        accountNumber: string;
        sortCode: string;
        buildingSociety: string;
        nameOnAccount: string;
        active: string;
        verified: string;
        verifiedBy: string;
        verifiedDateTime: string;
        createdDateTime: string;
        serviceTypeLookUpId: string;
        serviceSubTypeLookUpId: string;
    } | null;
    requirePayment: string;
    requirePaymentComment: string;
    artistApart: string;
    linkInformation: linkTypeInfo[];
    typeList: typeList[];
    businessEntityDocument: businessDocumentInfo[] | null;
    validationErrors: string | null;
    postSignUpMessages: postSignUpMessageInfo[];
    genreList: genreList[];
    musicTypeList: musicTypeList[];
    equipment: boolean;
    coveragePostCode: string;
    registration: boolean;
    profile: boolean;
    bankaccount: boolean;
    feedbackTypeId: string;
    feedbackScore: string;
    feedbackComments: string;
    oooProtocol: string;
    serviceTypeList: any[];
    profileFlag: string;
}

export interface venueProfileRequest {
    id: string;
    venueName: string;
    venueNameWOLocation: string;
    contact: {
        id: string;
        contactTypeLookUpId: string;
        active: string;
        contactTypeLookUp: {
            id: string;
            value: string;
        };
    };
    businessEntityId: string;
    businessEntity: {
        id: string;
        shortName: string;
    };
    generalManager: {
        id: string;
        value: string;
    },
    organisationType: {
        id: string;
        value: string;
    };
    organisationTypeId: string;
    email: string;
    phone: string;
    houseNumber: string;
    address: string;
    city: string;
    county: string;
    postCode: string;
    venueTypeId: string;
    venueType: {
        id: string;
        value: string;
    };
    criticalVenueInfo: string;
    division: string;
    brand: string;
    onboarded:boolean;
    dynamicsContactID: string;
    contactId: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    user: {
        id: string;
        name: string;
        active: string;
        lastUpdatedOn: string;
        userRole: {
            id: string;
            value: string;
            parentMappingId: any;
        },
    };
    website: string;
    contractCleaningBusinessEntity: {
        id: string;
        shortName: string;
    };
    securityProviderBusinessEntity: {
        id: string;
        shortName: string;
    };
    gardeningProviderBusinessEntity: {
        id: string;
        shortName: string;
    };
    windowProviderBusinessEntity: {
        id: string;
        shortName: string;
    };
    primaryCleaningContact: {
        id: string;
        name: string;
    };
    primarySecurityContact: {
        id: string;
        name: string;
    };
    primaryGardeningContact: {
        id: string;
        name: string;
    };
    primaryWindowContact: {
        id: string;
        name: string;
    };
}

export interface ContactProfileRequest {
    id: string;
    name: string;
    businessEntityId: string;
    organisationTypeId: string;
    organisationType: {
        id: string;
        value: string;
    };
    contactTypeId: string;
    email: string;
    mobile: string;
    jobTitle: string;
    active: string;
    lastUpdatedOn: string;
    businessEntity: {
        id: string;
        shortName: string;
    };
    contactType: {
        id: string;
        value: string;
    };
    user: {
        id: string;
        name: string;
        active: string;
        lastUpdatedOn: string;
        userRole: {
            id: string;
            value: string;
            parentMappingId: any;
        };
        allowWebApp: boolean;
        disallowApp: boolean;
        disallowPortal: boolean;
    }
}

export interface providerProfileTNCRequest {
    businessEntityID: string;
    termsAgreed: string;
}
interface VirtualScrollingResponse {
    totalCount: number,
    data: any
}

export interface logicAppPostRequest {
    ContactId: string;
}

class ArtistService {

    getAppliedArtist(gigRequestId: string): Promise<AxiosResponse<any>> {
        return _get(`${ARTIST_APPLIED}/${gigRequestId}`);
    }

    // RemoteOperations Service 
    getGigGridRowsWithinRemoteOperations(params: string): Promise<AxiosResponse<VirtualScrollingResponse>> {
        return _get(GIG_REQUEST_REMOTE_OPERATIONS + params);
    }

    getQueryCardList(id: string): Promise<AxiosResponse<any>> {
        return _get(`${QUERY_CARDS_LIST_API}?serviceTypeId=${id}`);
    }

    getGigCardList(id: string) {
        return _get(`${BILLABLE_LIST_API}?serviceTypeId=${id}`);
    }
    getActionCardList(name: string) {
        return _get(`${BILLABLE_LIST_API}?outStandingActionStatus=${name}`);
    }
    getEditViewData(id: number): Promise<AxiosResponse<any>> {
        return _get(`${BILLABLE_LIST_API}/${id}`);
    }
    //#region Manage Artist Profile Page
    uploadPhoto(formData: FormData): Promise<AxiosResponse<any>> {
        return _postFormData(UPLOAD_API, formData);
    }
    getArtistPageData(): Promise<AxiosResponse<any>> {
        return _get(ARTIST_PAGE_API);
    }
    getArtistPageDataById(businessEntityId: number): Promise<AxiosResponse<any>> {
        return _get(`${ARTIST_PAGE_API}/${businessEntityId}`);
    }
    saveArtistPageData(artistData: object): Promise<AxiosResponse<any>> {
        return _post(ARTIST_PAGE_API, artistData);
    }
    doAccountDeactivation(businessEntity: object): Promise<AxiosResponse<any>> {
        return _post(DEACTIVATION_API , businessEntity);
    }
    getConfigurationText(type: string, filterBy: string): Promise<AxiosResponse<any>> {
        // return _get(`${ARTIST_PAGE_API}/${businessEntityId}`);
        return _get(CONFIGURATIONTEXT_API + `?type=${type}&filterby=${filterBy}`);
    }

    getArtistPageDataByQueryParam(businessEntityID: number): Promise<AxiosResponse<any>> {
        return _get(`${ARTIST_PROFILE_API}?filterby=${businessEntityID}`);
    }

    getBusinessEntityTypeIdDetails(businessEntityTypeID: number): Promise<AxiosResponse<any>> {
        return _get(`${ARTIST_PROFILE_API}?businessEntityTypeId=${businessEntityTypeID}`);
    }
    // get VAT details by vat number
    getArtistVATDetails(vatNumber: string): Promise<AxiosResponse<any>> {
        return _get(ARTIST_VAT_API + `?vatNumber=${vatNumber}`);
    }

    // get Bank details by business entity id
    getBankDetails(id: string): Promise<AxiosResponse<any>> {
        return _get(ARTIST_BANK_DETAILS_API + `?businessEntityId=${id}`);
    }
    // save venue profile data
    saveVenuePageData(data: object): Promise<AxiosResponse<any>> {
        return _post(VENUE_PAGE_API, data);
    }
    // get venue profile data by id
    getVenuePageDataById(id: number): Promise<AxiosResponse<any>> {
        return _get(`${VENUE_PAGE_GET_API}/${id}`);
    }
    // save contact profile data
    saveContactPageData(data: object): Promise<AxiosResponse<any>> {
        return _post(CONTACT_PAGE_API, data);
    }
    // get contact profile data by id
    getContactPageDataById(id: number): Promise<AxiosResponse<any>> {
        return _get(`${CONTACT_PAGE_GET_API}/${id}`);
    }
    // get agreement document
    getAgreementDoc(data: object): Promise<AxiosResponse<any>> {
        return _post(CREATE_AGREEMENT_DOC, data);
    }
    // get signed information
    getSignedInformation(id: string, businessEntityId: string,dateFrom:any,dateTo:any): Promise<AxiosResponse<any>> {
        return _get(`${GET_SIGNED_DOC}/${id}`  + `?businessEntityId=${businessEntityId}&storeDocument=true&dateFrom=${dateFrom}&dateTo=${dateTo}`);
    }
    //#endregion
    triggerLogicAppForContactSync(formData: object): Promise<AxiosResponse<any>> {
        return _postBatchRequest(CONTACT_SYNC_LOGIC_APP, formData);
    }
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}
export default ArtistService;
