import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DatePanel from "../components/side-bar/DatePanel";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface FixedSidePanelDesignProps extends RouteComponentProps {}

class FixedSidePanelDesign extends Component<FixedSidePanelDesignProps> {
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <section className="col">
                        test content with fixed side bar - sidebar should be on the left and fixed for desktop and at
                        the top for mobile
                    </section>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(FixedSidePanelDesign);
