import React from "react";
import CheckInCardService, { checkinCardData } from "../../services/CheckInCardService";
import CheckinCard from "./CheckinCard";
import { AxiosError, AxiosResponse } from "axios";
import { LoadIndicator } from "devextreme-react";
import LazyLoad from "react-lazyload";
import { LazyLoadConfig } from "../grid/sharedUtilities";

interface CheckinCardListProps {
    billableId?: string;
    setParentCardCount: (cardCountFlag: number) => void; //A callback that would indicate whether an card is present in the deck or not.
    clientId: string;
    venueId: string;
}

interface CheckinCardListState {
    cards: checkinCardData[];
    totalNumber: number;
    showProgressIndicator: boolean;
    clientId: string;
    venueId: string;
}

class CheckinCardList extends React.Component<CheckinCardListProps> {
    //State object to manage the state of the List of cards.
    state: CheckinCardListState;
    //Service that fetches the data for the individual cards.
    cardService: CheckInCardService;

    constructor(props: CheckinCardListProps) {
        super(props);
        //Initializing the service.
        this.cardService = new CheckInCardService();

        this.state = {
            cards: [],
            totalNumber: 0,
            showProgressIndicator: false,
            clientId: this.props.clientId,
            venueId: this.props.venueId,
        };
    }

    componentDidMount() {
        if (this.props.billableId) {
            const id = this.props.billableId;
            this.fetchSingleCardDetails(id);
        }
    }

    componentDidUpdate = (prevprops: CheckinCardListProps) => {
        if (this.props.clientId != prevprops.clientId || this.props.venueId != prevprops.venueId) {
            this.setState({
                clientId: this.props.clientId,
                venueId: this.props.venueId,
            });
            this.fetchCardDetails();
        }
    };

    // Function to retrieve the data from the server for a single card.
    fetchSingleCardDetails = (id: string) => {
        this.setState({
            showProgressIndicator: true,
        });
        this.cardService.getCheckinCardData(id).then(this.handleretrieveSuccess).catch(this.handleretrieveFailure);
    };

    handleretrieveSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        let cardData: checkinCardData = {
            billableItemId: response.data.data.billableItemId ? response.data.data.billableItemId : "",
            name: response.data.data.name ? response.data.data.name : "",
            idValue: response.data.data.identification_Number ? response.data.data.identification_Number : "",
            scheduledStartTime: response.data.data.shift_Start ? response.data.data.shift_Start : "",
            scheduledFinishTime: response.data.data.shift_End ? response.data.data.shift_End : "",
            serviceTypeLookUpID: response.data.data.serviceTypeId ? response.data.data.serviceTypeId : "",
            serviceTypeLookUpName: response.data.data.service ? response.data.data.service : "",
            serviceSubTypeLookUpID: response.data.data.serviceSubTypeId ? response.data.data.serviceSubTypeId : "",
            serviceSubTypeLookUpName: "",
            checkInTime: response.data.data.check_In ? response.data.data.check_In : "",
            checkOutTime: response.data.data.check_Out ? response.data.data.check_Out : "",
            venueName: response.data.data.venueName ? response.data.data.venueName : "",
            jobRole: response.data.data.jobRole ? response.data.data.jobRole : "",
            dateFrom: response.data.data.dateFrom ? response.data.data.dateFrom : "",
        };
        this.setState({ cards: [cardData] });
    };

    handleretrieveFailure = (error: any) => {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "Get Check in card data by id service failed with response: " + JSON.stringify(error);

        if (!this.cardService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    //Arrow functions bind the context of 'this' inside the function itself, so no need to bind inside of the constructor.
    //To fetch in the card details from the server.
    fetchCardDetails = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.cardService
            .getCurrentDayShiftData(this.props.clientId, this.props.venueId)
            .then(this.handleSuccess)
            .catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        this.setState({
            cards: response.data.data,
            totalNumber: response.data.data.length,
        });
        if (response.data && response.data.data) {
            this.props.setParentCardCount(response.data.data.length);
        }
    };

    handleFailure = (error: AxiosError<any>) => {
        var respMessage: string = "Get current day shift data service failed with response: " + JSON.stringify(error);

        if (!this.cardService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    render() {
        let serialNo = 1;
        return this.state.showProgressIndicator ? (
            <div className="row col-lg-12">
                <span className="col-lg-12 text-center">
                    <LoadIndicator
                        id="simple-grid-indicator"
                        height={100}
                        width={100}
                        visible={this.state.showProgressIndicator}
                    />
                </span>
            </div>
        ) : this.state.cards.length > 0 ? (
            <LazyLoad height={LazyLoadConfig.height} offset={LazyLoadConfig.offset}>
                <div className="row my-3">
                    {this.state.cards.map((card: checkinCardData) => {
                        return (
                            <div className="col-12 col-xl-4 mb-4 mt-3 px-3" key={card.billableItemId}>
                                <CheckinCard
                                    cardUIType="manual"
                                    checkinCard={card}
                                    totalNumber={this.state.totalNumber}
                                    serialNumber={serialNo++}
                                />
                            </div>
                        );
                    })}
                </div>
            </LazyLoad>
        ) : (
            <div className="text-center font-weight-bold">
                Your door supervision provider has not uploaded any scheduled shifts for today.
            </div>
        );
    }
}

export default CheckinCardList;
