import { ContractFormResponse, ContractFormRequest } from "../../services/ContractCleaningService";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import React from "react";
import sharedUtils from "../grid/sharedUtilities";

type NullableBool = boolean | null | undefined;
export interface ContractFormUtilProps {}
export interface AddEditContractFormData {
    contractCleaningId: string;
    clientId: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    providerId: string;
    venuId: string;
    isActive: string;
    variableHours: string;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    sunday: number;
    rate: number;
    total: number;
    frequencyId: string;
    feeOverride: number;
    feeOverrideFlag: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    typeLookUpId: string;
    providerPayAmountLocal: number;
    otherDescription: string;
}

export interface LookUpDetailsInformation {
    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    venueLookUp: LookupTypeItem[];
    providerLookUp: LookupTypeItem[];
    showProgressIndicator: boolean;
}
export default class ContractFormUtil extends React.Component<ContractFormUtilProps> {
    utils: sharedUtils;
    dropdownService: lookupService;
    state: LookUpDetailsInformation;
    constructor(props: ContractFormUtilProps) {
        super(props);
        this.utils = new sharedUtils();
        this.dropdownService = new lookupService();
        this.state = {
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            venueLookUp: [],
            providerLookUp: [],
            showProgressIndicator: false,
        };
        this.initializePeriodFormItem = this.initializePeriodFormItem.bind(this);
        this.convertContractItemResponeToFormData = this.convertContractItemResponeToFormData.bind(this);
        this.dropDownDataSource = this.dropDownDataSource.bind(this);
        this.lookUpCreation = this.lookUpCreation.bind(this);
        this.handleClientLUSuccess = this.handleClientLUSuccess.bind(this);
        this.handleServiceLUSuccess = this.handleServiceLUSuccess.bind(this);
        this.handleSubServiceLUSuccess = this.handleSubServiceLUSuccess.bind(this);
        this.handleVenueLUSuccess = this.handleVenueLUSuccess.bind(this);
        this.handleProviderLUSuccess = this.handleProviderLUSuccess.bind(this);
    }

    initializePeriodFormItem(idFromGrid: string): AddEditContractFormData {
        var formData: AddEditContractFormData = {
            contractCleaningId: "0",
            clientId: "",
            serviceTypeId: "",
            serviceSubTypeId: "",
            providerId: "",
            venuId: "",
            isActive: "True",
            variableHours: "False",
            monday: 0.00,
            tuesday: 0.00,
            wednesday: 0.00,
            thursday: 0.00,
            friday: 0.00,
            saturday: 0.00,
            sunday: 0.00,
            rate: 0.00,
            total: 0.00,
            frequencyId: "11",
            feeOverride: 0,
            feeOverrideFlag: "False",
            lastUpdatedOn: "",
            lastUpdatedBy: "",
            typeLookUpId: "",
            providerPayAmountLocal: 0.00,
            otherDescription: "",
        };

        return formData;
    }

    // Convert the server response into the component's form state
    convertContractItemResponeToFormData(serverResponse: ContractFormResponse): AddEditContractFormData {
        var formData: AddEditContractFormData = {
            contractCleaningId: serverResponse.contractCleaningId,
            clientId: serverResponse.clientId,
            serviceTypeId: serverResponse.serviceTypeId,
            serviceSubTypeId: serverResponse.serviceSubTypeId,
            providerId: serverResponse.providerId,
            venuId: serverResponse.venuId,
            isActive: serverResponse.isActive,
            variableHours: serverResponse.variableHours,
            monday: this.utils.convertStringToFloat(serverResponse.monday),
            tuesday: this.utils.convertStringToFloat(serverResponse.tuesday),
            wednesday: this.utils.convertStringToFloat(serverResponse.wednesday),
            thursday: this.utils.convertStringToFloat(serverResponse.thursday),
            friday: this.utils.convertStringToFloat(serverResponse.friday),
            saturday: this.utils.convertStringToFloat(serverResponse.saturday),
            sunday: this.utils.convertStringToFloat(serverResponse.sunday),
            rate: this.utils.convertStringToFloat(serverResponse.rate),
            total: this.utils.convertStringToFloat(serverResponse.total),
            frequencyId: serverResponse.frequencyId,
            feeOverride: this.utils.convertStringToFloat(serverResponse.feeOverride),
            feeOverrideFlag: serverResponse.feeOverrideFlag,
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            lastUpdatedBy: serverResponse.lastUpdatedBy,
            typeLookUpId: serverResponse.typeLookUpId,
            providerPayAmountLocal: this.utils.convertStringToFloat(serverResponse.providerPayAmountLocal),
            otherDescription: serverResponse.otherDescription,
        };

        return formData;
    }

    // Convert the form's data into a proper item submit request
    convertFormDataToContractItemRequest(formData: AddEditContractFormData): ContractFormRequest {
        var submitRequest: ContractFormRequest = {
            contractCleaningId: formData.contractCleaningId,
            clientId: formData.clientId,
            serviceTypeId: formData.serviceTypeId,
            serviceSubTypeId: formData.serviceSubTypeId,
            providerId: formData.providerId,
            venuId: formData.venuId,
            isActive: formData.isActive,
            variableHours: formData.variableHours,
            monday: formData.monday.toFixed(2).toString(),
            tuesday: formData.tuesday.toFixed(2).toString(),
            wednesday: formData.wednesday.toFixed(2).toString(),
            thursday: formData.thursday.toFixed(2).toString(),
            friday: formData.friday.toFixed(2).toString(),
            saturday: formData.saturday.toFixed(2).toString(),
            sunday: formData.sunday.toFixed(2).toString(),
            rate: formData.rate.toFixed(2).toString(),
            total: formData.total.toFixed(2).toString(),
            frequencyId: formData.frequencyId,
            feeOverride: formData.feeOverride.toFixed(2).toString(),
            feeOverrideFlag: formData.feeOverrideFlag,
            typeLookUpId: formData.typeLookUpId,
            providerPayAmountLocal: formData.providerPayAmountLocal.toFixed(2).toString(),
            otherDescription: formData.otherDescription,
        };
        return submitRequest;
    }

    getLookUpDetails(): LookUpDetailsInformation {
        this.dropDownDataSource();
        var lookUpResponse: LookUpDetailsInformation = {
            clientLookUp: this.state.clientLookUp,
            venueLookUp: this.state.venueLookUp,
            serviceLookUp: this.state.serviceLookUp,
            subServiceLookUp: this.state.subServiceLookUp,
            providerLookUp: this.state.providerLookUp,
            showProgressIndicator: this.state.showProgressIndicator,
        };

        return lookUpResponse;
    }

    dropDownDataSource() {
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
            .then(this.handleClientLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
            .then(this.handleServiceLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.subServiceType)
            .then(this.handleSubServiceLUSuccess)
            .catch(this.handleError);

        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.providerType)
            .then(this.handleProviderLUSuccess)
            .catch(this.handleError);
    }

    lookUpCreation(response: AxiosResponse<any>): LookupTypeItem[] {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    }

    handleClientLUSuccess(response: AxiosResponse<any>): LookupTypeItem[] {
        var clientDropDown = this.lookUpCreation(response);
        return clientDropDown;
    }

    handleServiceLUSuccess(response: AxiosResponse<any>): LookupTypeItem[] {
        var serviceDropDown = this.lookUpCreation(response);
        return serviceDropDown;
    }

    handleSubServiceLUSuccess(response: AxiosResponse<any>): LookupTypeItem[] {
        var subServiceDropDown = this.lookUpCreation(response);
        return subServiceDropDown;
    }

    handleVenueLUSuccess(response: AxiosResponse<any>): LookupTypeItem[] {
        var venueDropDown = this.lookUpCreation(response);
        return venueDropDown;
    }

    handleProviderLUSuccess(response: AxiosResponse<any>): LookupTypeItem[] {
        var providerDropDown = this.lookUpCreation(response);
        return providerDropDown;
    }

    handleError(error: AxiosResponse<any>) {
        this.setState({
            showProgressIndicator: false,
        });
        this.state.showProgressIndicator = false;
    }
}
