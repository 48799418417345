import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import { confirm } from "devextreme/ui/dialog";
import { CheckBox } from "devextreme-react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaymentStatus } from "../components/Payments/PaymentFormUtils";
import PaymentStatusFlow from "../components/Payments/PaymentStatusFlow";
import DesignSystemNavigation from "./DesignSystemNavigation";
import { paymentStatus } from "../services/PaymentService";

interface PaymentStatusFlowDesignProps extends RouteComponentProps {}

interface PaymentStatusFlowDesignState {
    status: PaymentStatus;
    statusId: string;
}

class PaymentStatusFlowDesign extends Component<PaymentStatusFlowDesignProps> {
    state: PaymentStatusFlowDesignState;
    constructor(props: PaymentStatusFlowDesignProps) {
        super(props);
        this.state = {
            status: PaymentStatus.Draft,
            statusId: paymentStatus.Draft,
        };
    }

    onPendingValueChanged = () => {
        this.setState({
            status: PaymentStatus.Pending,
        });
    };

    onReadyForPaymentValueChanged = () => {
        this.setState({
            status: PaymentStatus.ReadyForPayment,
        });
    };

    onPaymentNettedValueChanged = () => {
        this.setState({
            status: PaymentStatus.PaymentNetted,
        });
    };

    onPaymentCheckedValueChanged = () => {
        this.setState({
            status: PaymentStatus.PaymentChecked,
        });
    };

    onPackRequestedValueChanged = () => {
        this.setState({
            status: PaymentStatus.PackRequested,
        });
    };

    onPaymentPackReadyValueChanged = () => {
        this.setState({
            status: PaymentStatus.PaymentPackReady,
        });
    };

    onPaymentPackFailedValueChanged = () => {
        this.setState({
            status: PaymentStatus.PaymentPackFailed,
        });
    };

    onPaymentPaidValueChanged = () => {
        this.setState({
            status: PaymentStatus.PaymentPaid,
        });
    };

    onAdviseRequestedValueChanged = () => {
        this.setState({
            status: PaymentStatus.AdviseRequested,
        });
    };

    onPaymentAdvisedValueChecked = () => {
        this.setState({
            status: PaymentStatus.PaymentAdvised,
        });
    };

    onPaymentSettledValueChecked = () => {
        this.setState({
            status: PaymentStatus.PaymentSettled,
        });
    };

    onRemittanceRequestedValueChecked = () => {
        this.setState({
            status: PaymentStatus.RemittanceRequested,
        });
    };

    onPaymentRemittedValueChecked = () => {
        this.setState({
            status: PaymentStatus.PaymentRemitted,
        });
    };

    onRestartClicked = () => {
        confirm("Restart?", "Restart").then((hasConfirmed) => {
            if (hasConfirmed) {
                this.setState({
                    status: PaymentStatus.Draft,
                });
            }
        });
    };
    onRefreshPaymentStatusClicked = () => {};
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>{" "}
                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <h2>
                            <b>Payment</b>
                            <button
                                className="status-flow__refresh-button btn icon-btn"
                                aria-label="Refresh the current billing status"
                                onClick={this.onRefreshPaymentStatusClicked}
                            >
                                <FontAwesomeIcon icon={faSync} />
                            </button>
                        </h2>
                    </header>

                    <div>
                        <PaymentStatusFlow
                            statusId={this.state.statusId}
                            onRestartClicked={this.onRestartClicked}
                            isRestartDisable={false}
                        />
                    </div>
                    <div className="row mt-5">
                        <label className="col-8 col-sm-4">Pending</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.Pending}
                                onValueChange={this.onPendingValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">ReadyForPayment</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.ReadyForPayment}
                                onValueChanged={this.onReadyForPaymentValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">PaymentNetted</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.PaymentNetted}
                                onValueChanged={this.onPaymentNettedValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">PaymentChecked</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.PaymentChecked}
                                onValueChanged={this.onPaymentCheckedValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">PackRequested</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.PackRequested}
                                onValueChanged={this.onPackRequestedValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">PaymentPackReady</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.PaymentPackReady}
                                onValueChanged={this.onPaymentPackReadyValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">PaymentPackFailed</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.PaymentPackFailed}
                                onValueChanged={this.onPaymentPackFailedValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">PaymentPaid</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.PaymentPaid}
                                onValueChanged={this.onPaymentPaidValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">AdviseRequested</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.AdviseRequested}
                                onValueChanged={this.onAdviseRequestedValueChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">PaymentAdvised</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.PaymentAdvised}
                                onValueChanged={this.onPaymentAdvisedValueChecked}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">PaymentSettled</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.PaymentSettled}
                                onValueChanged={this.onPaymentSettledValueChecked}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">RemittanceRequested</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.RemittanceRequested}
                                onValueChanged={this.onRemittanceRequestedValueChecked}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-8 col-sm-4">PaymentRemitted</label>
                        <div className="col-4">
                            <CheckBox
                                value={this.state.status == PaymentStatus.PaymentRemitted}
                                onValueChanged={this.onPaymentRemittedValueChecked}
                            />
                        </div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(PaymentStatusFlowDesign);
