import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface FullBleedSidePanelDesignProps extends RouteComponentProps {}

class FullBleedSidePanelDesign extends Component<FullBleedSidePanelDesignProps> {
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFullBleed={true}>
                    <div className="artist-showcase my-5 p-6">
                        <h4 className="artist-showcase__title">The Showcase</h4>
                    </div>
                    <div className="m-4">
                        <DesignSystemNavigation />
                    </div>
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    test content with full-bleed collapsible sidebar, should be expanded on desktop and collapsed on
                    mobile
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(FullBleedSidePanelDesign);
