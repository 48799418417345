import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble, faSquare } from "@fortawesome/pro-regular-svg-icons";
import { confirm, alert } from "devextreme/ui/dialog";
import { Status } from "../../services/AddEditPopUpService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { AxiosResponse } from "axios";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { LoadPanel } from "devextreme-react";
import CostReportUtils from "../CostReports/CostReportUtils";
import SimpleGridService, { eventUkUserGridRowItem } from "../../services/SimpleGridService";
import sharedUtils from "./sharedUtilities";
import { FadSquare } from "../GridCells/BaseActionCell";

export interface CardApproveComponentProps {
    data: eventUkUserGridRowItem;

}

export interface CardApproveComponentState {
    pendingIconVisible: boolean,
    loadPanelVisible: boolean,
    iconDisabled: boolean;
    futureShift: boolean;
    noIconToDisplay: boolean;
    disableQueryIcon: boolean;
    isBilledItem: boolean;
}

class CardApproveComponent extends React.Component<CardApproveComponentProps> {
    state: CardApproveComponentState;
    costReportUtils: CostReportUtils;
    Service: SimpleGridService;
    sharedUtils: sharedUtils;
    constructor(props: CardApproveComponentProps) {
        super(props);
        this.state = {
            pendingIconVisible: false,
            loadPanelVisible: false,
            iconDisabled: false,
            futureShift: false,
            noIconToDisplay: false,
            disableQueryIcon: false,
            isBilledItem: false
        }
        this.costReportUtils = new CostReportUtils();
        this.Service = new SimpleGridService();
        this.sharedUtils = new sharedUtils();
    }

    componentDidMount() {
        var statusColumnIndex: number = 0;
        var disableQueryIcon: boolean = false;
        var noIcon: boolean = false;
        const isEventUk =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        const isProvider = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler);
        const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
        var pendingIconVisible = this.state.pendingIconVisible;
        var futureShift: boolean = false;
        var tenseIdentifier: string = this.props.data.tenseFlag;
        var statusId: string = "";
        if (this.props.data.statusId) {
            statusId = this.props.data.statusId;
        }
        else if (this.props.data.billableStatusLookupId) {
            statusId = this.props.data.billableStatusLookupId;
        }
        var itemTypeId: string =
            this.props && this.props.data  ? this.props.data.itemTypeId : "";
        var iconDisabled: boolean = false;
        var isBilledItem: boolean = false;
        var isApprovalDate: boolean = false;
        if (this.props.data.approvalDate || this.props.data.approval_Date) {
            isApprovalDate = true;
        }
        else {
            isApprovalDate = false;
        }

        if (tenseIdentifier.trim().toLowerCase() == "future") {
            iconDisabled = true;
            futureShift = true;
        } else {

            if (
                (statusId && statusId == Status.Approved) ||
                (statusId && statusId == Status.Accepted) ||
                this.props.data.statusValue == "Approved" || this.props.data.status == "Approved" ||
                this.props.data.statusValue == "Accepted" || this.props.data.status == "Accepted" ||
                this.props.data.approvalDate || this.props.data.approval_Date ||
                this.props.data.acceptDate || this.props.data.accept_Date
            ) {
                iconDisabled = true;
                pendingIconVisible = false;
            } else if (
                (tenseIdentifier.trim().toLowerCase() == "past" &&
                    isEventUk || isVenueManager &&
                    statusId &&
                    statusId == Status.Pending && (this.props.data.statusValue == "Pending" || this.props.data.status == "Pending")) ||
                (isEventUk || isVenueManager && statusId && statusId == Status.PendingAcceptance && (this.props.data.statusValue == "Pending Acceptance" || this.props.data.status == "Pending Acceptance")) ||
                (isEventUk || isVenueManager && tenseIdentifier.trim().toLocaleLowerCase() == "past" && (!isApprovalDate) && this.props.data.isAccrual && !this.props.data.paymentId) ||
                (isEventUk || isVenueManager && tenseIdentifier.trim().toLocaleLowerCase() == "past" && (!isApprovalDate) && !this.props.data.isAccrual && !this.props.data.invoiceId)
            ) {
                iconDisabled = false;
                pendingIconVisible = true;
            }
            else if ((this.props.data.invoiceId && !this.props.data.isAccrual) ||
                (this.props.data.paymentId && this.props.data.invoiceId && this.props.data.isAccrual)) {
                iconDisabled = true;
                pendingIconVisible = false;
                isBilledItem = true;
            } else if (
                (isVenueManager && itemTypeId && itemTypeId == itemTypes.AdjustmentProviderDebit) ||
                (isProvider &&
                    itemTypeId &&
                    itemTypeId == itemTypes.AdjustmentClientDebit &&
                    tenseIdentifier.trim().toLowerCase() == "past")
            ) {
                iconDisabled = true;
                pendingIconVisible = true;
            } else {
                noIcon = true;
            }
        }
        if (this.props.data && this.props.data.queries && this.props.data.queries.length > 0) {
            disableQueryIcon = this.costReportUtils.disableRaiseQueryIcon(this.props.data.queries);
        }

        this.setState({
            disableQueryIcon: disableQueryIcon,
            futureShift: futureShift,
            pendingIconVisible: pendingIconVisible,
            iconDisabled: iconDisabled,
            noIconToDisplay: noIcon,
            isBilledItem: isBilledItem,
        });

        if (
            this.props.data &&
            (this.props.data.itemTypeId == itemTypes.ClientCredit ||
                this.props.data.itemTypeId == itemTypes.ClientDebit ||
                this.props.data.itemTypeId == itemTypes.ProviderCredit ||
                this.props.data.itemTypeId == itemTypes.ProviderDebit ||
                this.props.data.itemTypeId == itemTypes.Receivable ||
                this.props.data.itemTypeId == itemTypes.Payable)
        ) {
            this.setState({
                isFinancialAdjustmentView: true,
            });
        }
        if (this.props.data && this.props.data.editAllowed.toLowerCase() == "false") {
            this.setState({
                isEditIconDisable: true,
            });
        }
        if (
            ((this.props.data.itemTypeId == itemTypes.Original ||
                this.props.data.itemTypeId == itemTypes.AdjustmentClientDebit ||
                this.props.data.itemTypeId == itemTypes.AdjustmentProviderDebit) &&
                (this.props.data.approvalDate || this.props.data.acceptDate || this.props.data.billDate) ||
                (this.props.data.approval_Date || this.props.data.accept_Date || this.props.data.bill_Date) || (this.props.data.approval_Date || this.props.data.accept_Date || this.props.data.bill_Date))
            || this.props.data.statusValue == "Approved" || this.props.data.statusValue == "Accepted" ||
            this.props.data.status == "Approved" || this.props.data.status == "Accepted"
        ) {
            this.setState({
                isAdjustmentChange: true,
            });
        }

        this.setState({
            statusColumnIndexVal: statusColumnIndex
        });
    }

    handleApprovalOnClick = () => {
        confirm("<div class='text-center'>Approve the Shift?</div>", "Submit").then((hasConfirmed) => {
            if (hasConfirmed) {
                //call the API for posting the Billable Item list to the server.
                this.onConfirmation();
            }
        });
    };

    showLoadPanel = () => {
        this.setState({
            loadPanelVisible: true,
        });
    };

    hideLoadPanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    onConfirmation = () => {
        this.showLoadPanel();
        this.Service.submitApproveShift([this.sharedUtils.convertStringToInt(this.props.data.billableItemId)])
            .then(this.handlePostApprovalSuccess)
            .catch((error) => {
                var respMessage: string = "Approval failed with response: " + JSON.stringify(error);
                this.hideLoadPanel();
                alert("Sorry! The shift could not be approved", "Error!");
                if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                    console.error(respMessage);
                }
            });
    };

    handlePostApprovalSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            pendingIconVisible: false,
            loadPanelVisible: false,
            status: response.data.data.length > 0 ? response.data.data[0].value : "",
            isAdjustmentChange: true,
        });
        alert("Thank you - shift has been approved", "Success");
    };

    render() {
        var {
            pendingIconVisible,
            iconDisabled,
            futureShift,
            noIconToDisplay,
            disableQueryIcon,
            isBilledItem,
        } = this.state;
        return (
            <>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    message="Please wait..."
                    visible={this.state.loadPanelVisible}
                />
                {pendingIconVisible || futureShift || disableQueryIcon ? (
                    <button
                        className={(iconDisabled ? "disabled " : "") + "btn icon-btn-card"}
                        onClick={this.handleApprovalOnClick}
                        disabled={disableQueryIcon}
                    >
                        {!futureShift && pendingIconVisible && !noIconToDisplay && !disableQueryIcon && (
                            <FontAwesomeIcon icon={faSquare} />
                        )}
                        {futureShift && !noIconToDisplay && !disableQueryIcon && (
                            <FadSquare
                                height={16}
                                width={16}
                            />
                        )}
                        {disableQueryIcon && null}
                        <span className="icon-btn-card__text">Approve</span>
                    </button>
                ) : !futureShift && !pendingIconVisible && !noIconToDisplay && isBilledItem ? (
                    <div className="btn icon-btn-card">
                        <FontAwesomeIcon icon={faCheck} />
                        <span className="icon-btn-card__text">Approve</span>
                    </div>
                ) : !futureShift && !pendingIconVisible && !noIconToDisplay ? (
                    <div className="btn icon-btn-card">
                        <FontAwesomeIcon icon={faCheckDouble} />
                        <span className="icon-btn-card__text">Approve</span>
                    </div>

                ) : (
                    <div className="btn icon-btn-card"> <span className="icon-btn-card__text">Approve</span></div>
                )}
            </>
        );
    }
}

export default CardApproveComponent;
