import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface GridTitleCardProps {
    title: string;
    icon: IconDefinition;
    cardStyle: string; // payment-requests, artist-jobs, admin, gig-requests,scheduled-artist
}

class GridTitleCard extends React.Component<GridTitleCardProps> {

    constructor(props: GridTitleCardProps) {
        super(props);
    }

    render() {
        return (
            <div className={`grid-title-card grid-title-card--${this.props.cardStyle}`}>
                <h2 className="grid-title-card__title">{this.props.title}</h2>
                <div className="grid-title-card__icon">
                    <FontAwesomeIcon icon={this.props.icon} />
                </div>
            </div>
        );
    }
}
export default GridTitleCard;
