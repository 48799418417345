import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, SelectBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import CostReportService from "../../services/CostReportService";
import CostReportUtils from "../CostReports/CostReportUtils";
import queryUtils from "../Query/QueryUtils";
import { SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import GlobalService from "../../services/GlobalService";
interface SignUpProfileUpdateSidePanelProps extends WithTranslation {
    onApplyButtonClick: (
        statusId: string
    ) => void;
    navigateFrom: string;
}

interface SignUpProfileUpdateSidePanelState {
    disableApplyButton: boolean;
    statusTypeList: any[];
    statusId: string;
}

class SignUpProfileUpdateSidePanel extends Component<SignUpProfileUpdateSidePanelProps> {
    lookupService: LookupService;
    state: SignUpProfileUpdateSidePanelState;
    sharedUtils: sharedUtils;
    Service: CostReportService;
    utils: CostReportUtils;
    queryUtils: queryUtils;
    constructor(props: SignUpProfileUpdateSidePanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.sharedUtils = new sharedUtils();
        this.utils = new CostReportUtils();
        this.queryUtils = new queryUtils();
        this.state = {
            disableApplyButton: false,
            statusTypeList: [],
            statusId: ""
        };
    }

    //Retrieve the lookup values inside the mount
    componentDidMount() {
        this.retrieveLookupValues();
    }

    retrieveLookupValues = () => {
        this.lookupService
            .getLookupByLookupTypeIndexAll(LookupTypeIndexes.query)
            .then(this.handleRequestLookupSuccess)
            .catch(() => {
                this.setState({
                    statusTypeList: []
                });
            })
    };

    handleRequestLookupSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            statusTypeList: response.data.data
        }, () => { this.setIntialValueForStatus(this.state.statusTypeList) });
    }

    // componentWillUnmount(): void {
    //     GlobalService.removeSignUpFilterValues();
    // }

    setIntialValueForStatus = (statusObject: LookupTypeItem[]) => {
        var queryFilterValues = GlobalService.getSignUpFilterValues();
        if (this.props.navigateFrom) {
            this.setState({
                statusId: statusObject[0].id
            }, () => { this.onApplyButtonClicked() });
        }
        else if (queryFilterValues.statusId) {
            this.setState({
                statusId: queryFilterValues.statusId
            }, () => { this.onApplyButtonClicked() });
        }
        else if (statusObject.length > 0) {
            this.setState({
                statusId: statusObject[0].id
            }, () => { this.onApplyButtonClicked() });
        }
    }


    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };



    handleChangeStatusTypeChange = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            statusId: dxValueChange.value
        });
    };


    //Fire the parent component's callback on an Apply click.
    onApplyButtonClicked = () => {
        this.props.onApplyButtonClick(this.state.statusId);
        GlobalService.setSignUpGridFilterValues(this.state.statusId);
    };
    render() {
        return (
            <div>
                <div className="row mt-4">
                    <div className="col-10">
                        <h4 className="sidebar__heading font-weight-bold">Filter</h4>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col sidebar__heading">Status</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <SelectBox
                            dataSource={this.state.statusTypeList}
                            displayExpr="value"
                            valueExpr="id"
                            onValueChanged={this.handleChangeStatusTypeChange}
                            value={this.state.statusId}
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        <Button
                            className="btn btn-primary btn--large"
                            disabled={this.state.disableApplyButton}
                            onClick={this.onApplyButtonClicked}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SignUpProfileUpdateSidePanel);
