import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ServiceType } from "../../common/ServiceUtilities";
import { outstandingActions } from "../../services/OutstandingApprovalService";
import { closeDrawer, viewSimpleShiftsForService, viewCostReportForService , viewGigReq} from "./NavigationUtils";

export const renderVenueManagerMainNavigationItems = (): ReactNode => {
    return (
        <ul className="nav-drawer__quick-links navbar-nav">
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link className="nav-link" onClick={closeDrawer} to="/">
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faHome} />
                    </span>
                    Home <span className="sr-only">(current)</span>
                </Link>
            </li>
        </ul>
    );
}

export const renderVenueManagerCleaningNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.ContractCleaning)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.ContractCleaning)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/outstandingActions",
                        state: {
                            status: outstandingActions.approvals,
                            isHeader: true,
                            serviceTypeId: ServiceType.ContractCleaning,
                        },
                    }}
                >
                    Approvals
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/postApprovalActions",
                        state: {
                            status: outstandingActions.approvals,
                            isHeader: true,
                            serviceTypeId: ServiceType.ContractCleaning,
                        },
                    }}
                >
                    Post Approvals
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn-link" onClick={closeDrawer} to="/">
                    Supplier Information & Venue Conditions
                </Link>
            </li>
            
        </ul>
    );
}

export const renderVenueManagerDoorSupervisionNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={() => viewSimpleShiftsForService(ServiceType.Security)}
                to="/simpleShifts"
            >
                Shifts
            </Link>
        </li>
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={() => viewCostReportForService(ServiceType.Security)}
                to="/CostReport"
            >
                Cost Report
            </Link>
        </li>
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={closeDrawer}
                to={{
                    pathname: "/todaysViewCheckinOut",
                    state: {
                        id: "",
                    },
                }}
            >
                Today's Check In & Out
            </Link>
        </li>
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={closeDrawer}
                to={{
                    pathname: "/outstandingActions",
                    state: {
                        status: outstandingActions.approvals,
                        isHeader: true,
                        serviceTypeId: ServiceType.Security,
                    },
                }}
            >
                Approvals
            </Link>
        </li>
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={closeDrawer}
                to={{
                    pathname: "/postApprovalActions",
                    state: {
                        status: outstandingActions.approvals,
                        isHeader: true,
                        serviceTypeId: ServiceType.Security,
                    },
                }}
            >
                Post Approvals
            </Link>
        </li>
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={closeDrawer}
                to={{
                    pathname: "/SupplierInformationPage",
                    state: {
                        Header: "Door Supervision",
                        serviceTypeId: ServiceType.Security,
                    },
                }}
            >
                Supplier Information & Venue Conditions
            </Link>
        </li>
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={closeDrawer}
                to={{
                    pathname: "/viewQuery",
                    state: {
                        id: "",
                        serviceTypeId: ServiceType.Security,
                    },
                }}
            >
                Queries
            </Link>
        </li>
    </ul>
    );
}

export const renderVenueManagerEntertainmentNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
             <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={closeDrawer}
                to={{
                    pathname: "/findAnArtistLandingPage",
                    state: {
                        isNewRequest: true,
                        navigateFrom: "FindAnArtistMenu"
                    },
                }}
            >
                Find an Artist
            </Link>
        </li>
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={() => viewSimpleShiftsForService(ServiceType.Entertainment)}
                to={{
                    pathname: "/simpleShifts",
                    state: {
                        pointOfNavigation: "burgerMenu",
                    },
                }}
            >
                Scheduled Artists
            </Link>
        </li>
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={() => viewCostReportForService(ServiceType.Entertainment)}
                to="/CostReport"
            >
                Cost Report
            </Link>
        </li>
      
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={() => viewCostReportForService(ServiceType.Entertainment)}
                to={{
                    pathname: "/postApprovalActions",
                    state: {
                        status: outstandingActions.approvals,
                        isHeader: true,
                        serviceTypeId: ServiceType.Entertainment,
                    },
                }}
            >
                 Approvals
            </Link>
        </li>
       
        <li className="nav-item">
            <Link
                className="nav-link btn-link"
                onClick={closeDrawer}
                to="/independentBookingPage"
            >
                Add Independent Booking
            </Link>
        </li>
    </ul>
    );
}

export const renderVenueManagerGardeningNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Gardening)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Gardening)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            
        </ul>
    );
}

export const renderVenueManagerWindowCleaningNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.WindowCleaning)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.WindowCleaning)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            
        </ul>
    );
}

export const renderVenueManagerMaintenanceNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Maintenance)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Maintenance)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            
        </ul>
    );
}

export const renderVenueManagerMiscelleanousNavigationItems = (): ReactNode => {
    return (
        <ul className="stacked-nav__subnav navbar-nav">
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Miscelleanous)}
                    to="/simpleShifts"
                >
                    Shifts
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link btn-link"
                    onClick={() => viewCostReportForService(ServiceType.Miscelleanous)}
                    to="/CostReport"
                >
                    Cost Report
                </Link>
            </li>
            
        </ul>
    );
}
