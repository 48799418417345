import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import sharedUtils from "../grid/sharedUtilities";
import QueryService, { queryStatusType, queryTypes } from "../../services/QueryService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import { ScrollView, Popup } from "devextreme-react";
import queryUtils from "../Query/QueryUtils";
import { confirm } from "devextreme/ui/dialog";
import { CheckBoxOnValueChanged } from "../../types/DevExtremeTypes";
import AddEditFormV2 from "../grid/AddEditFormV2";
import TagCell from "../GigRequest/TagCell";
import SupplierInformationMainContent from "../SupplierInformation/SupplierInformationMainContent";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import { CheckBox } from "devextreme-react";

let billableItemId: string = "";
export interface QueryGridRenderDataRowProps extends WithTranslation {
    data?: any
}

export interface QueryGridRenderDataRowState {
    isSelected: boolean;
    loadPanelVisible: boolean;
    isVisibleManageShiftPopup: boolean;
    titlePopupVisible1: boolean;
    title1: string;
    clientId: string;
    venueId: string;
    businessEntityId: string;
    serviceId: string;
    providerPopupVisibleEntertainment: boolean;
    providerPopupVisibleOther: boolean;
    resolutionReasonPopUpVisible: boolean;
    resolutionReason: string;
    internalCommentsPopupVisible: boolean;
    internalComments: string;
}

class QueryGridRenderDataRow extends React.Component<QueryGridRenderDataRowProps> {
    status: string;
    state: QueryGridRenderDataRowState;
    queryUtils: queryUtils;
    sharedUtils: sharedUtils;
    queryService: QueryService;
    isEditMode: string;
    constructor(props: QueryGridRenderDataRowProps) {
        super(props);
        this.status = this.props.data.data.statusValue;
        this.queryUtils = new queryUtils();
        this.sharedUtils = new sharedUtils();
        this.queryService = new QueryService();
        this.isEditMode = "";
        this.state = {
            isSelected: this.props.data.data.isSelected,
            loadPanelVisible: false,
            isVisibleManageShiftPopup: false,
            titlePopupVisible1: false,
            title1: "",
            clientId: "",
            venueId: "",
            businessEntityId: "",
            serviceId: "",
            providerPopupVisibleEntertainment: false,
            providerPopupVisibleOther: false,
            resolutionReasonPopUpVisible: false,
            resolutionReason: "",
            internalCommentsPopupVisible: false,
            internalComments: "",
        };
    }  

    handleMultiSelectChange = (dxValueChange: CheckBoxOnValueChanged) => {
        if (dxValueChange.value == true) {
            this.props.data.component.selectRows(this.props.data.key, true);
        } else {
            this.props.data.component.deselectRows(this.props.data.key);
        }
        this.setState({
            isSelected: dxValueChange.value
        });
    };

    showRelatedItemPopup = (itemId: string) => {
        billableItemId = itemId;
        this.setState({
            isVisibleManageShiftPopup: true
        });
    } 

    closeManageShiftPopup = () => {
        billableItemId="";
        this.setState({
            isVisibleManageShiftPopup: false
        });
    }

    hidePopup = (isPopupVisible: boolean) => {
        this.setState({
            isVisibleManageShiftPopup: isPopupVisible
        });
    }

    titleCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoTitle(cellInfo.data.query_Title);
                    }}
                    className="approval-query-column"
                    to="#"

                >
                    {cellInfo.data.query_Title
                        ? cellInfo.data.query_Title.length > 16
                            ? `${cellInfo.data.query_Title.substring(0, 16)}...`
                            : cellInfo.data.query_Title
                        : ""}
                </Link>
            </div>
        );
    };

    onboardedCellDisplayContent = (cellInfo: any) => {
        let onboardedVal = cellInfo.venue?.onboarded;
        let valToShow: string = "";
        if (onboardedVal) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {valToShow}
            </div>
        );

    }

    showInfoTitle = (title: string) => {      
        this.setState({
            titlePopupVisible1: true,
            title1: title,
        });
    };

    hideInfoTitle = () => {
        this.setState({
            titlePopupVisible1: false
        });
    };

    providerCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.clientBusinessEntity?.id, cellInfo.data.venue?.id, cellInfo.data.serviceTypeLookUp?.id, cellInfo.data.providerBusinessEntity?.id);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.providerBusinessEntity?.shortName}
                </Link>
            </div>
        );
    };

    showInfoProvider = (clientId: string, venueId: string, serviceTypeId: string, businessEntityId: string) => {
        this.setState({
            clientId: clientId,
            venueId: venueId,
            serviceId: serviceTypeId,
            businessEntityId: businessEntityId,
        });
        if(serviceTypeId == "30"){
            this.setState({
                providerPopupVisibleEntertainment: true,
            });
        }
        else{
            this.setState({
                providerPopupVisibleOther: true,
            }); 
        }
    };

    hideInfoProvider = (e: any) => {       
        this.setState({
            providerPopupVisibleOther: false,
        });  
    };

    handlePopUpClose = (flag:boolean, editmode: string) => {        
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
        });
    }

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if(this.isEditMode == "editMode"){
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else{
            this.setState({
                providerPopupVisibleEntertainment: false,
            });  
        }
    };

    resolutionReasonCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoResolutionReason(cellInfo.data.resolution_Reason);
                    }}
                    className="approval-query-column"
                    to="#"

                >
                    {cellInfo.data.resolution_Reason
                        ? cellInfo.data.resolution_Reason.length > 16
                            ? `${cellInfo.data.resolution_Reason.substring(0, 16)}...`
                            : cellInfo.data.resolution_Reason
                        : ""}
                </Link>
            </div>
        );
    };

    showInfoResolutionReason = (reason: string) => {
        this.setState({
            resolutionReasonPopUpVisible: true,
            resolutionReason: reason
        });
    };

    hideInfoResolutionReason = () => {
        this.setState({
            resolutionReasonPopUpVisible: false
        });
    };

    internalCommentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoInternalComments(cellInfo.data.internal_Comments);
                    }}
                    className="approval-query-column"
                    to="#"

                >
                    {cellInfo.data.internal_Comments
                        ? cellInfo.data.internal_Comments.length > 16
                            ? `${cellInfo.data.internal_Comments.substring(0, 16)}...`
                            : cellInfo.data.internal_Comments
                        : ""}
                </Link>
            </div>
        );
    };

    showInfoInternalComments = (comments: string) => {
        this.setState({
            internalCommentsPopupVisible: true,
            internalComments: comments
        });
    };

    hideInfoInternalComments = () => {
        this.setState({
            internalCommentsPopupVisible: false
        });
    };


    render() {
        let finalFormattedLastUpdatedDate: string = "";
        let finalFormattedDate: string = "";
        if (this.props.data.data.date_Created) {
            let dateCreatedTime = this.props.data.data.date_Created;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        if(this.props.data.data.lastUpdated_DateTime){
            let lastUpdatedTime = this.props.data.data.lastUpdated_DateTime;
            let lastUpdatedTimeLocal = new Date(lastUpdatedTime);
            let formattedLastUpdatedTime = this.sharedUtils.convertDateTimeStringToHHMM(lastUpdatedTimeLocal.toString());
            let formattedLastUpdatedDate = this.sharedUtils.convertDateToString(lastUpdatedTimeLocal);
            finalFormattedLastUpdatedDate = `${formattedLastUpdatedDate} ${formattedLastUpdatedTime}`;
        }
        let billableItemId: string = "";
        let linkedId: string = "";
        let disablerelatedItemIcon: boolean = true;
        if (this.props.data.data.billableItemId) {
            billableItemId = this.props.data.data.billableItemId;
        }

        if (this.props.data.data.linkedId && (this.props.data.data.queryTypeLookUp.id.toString() == queryTypes.AddJob || this.props.data.data.queryTypeLookUp.id.toString() == queryTypes.PaymentRequest)) {
            linkedId = this.props.data.data.linkedId
        }
        if (this.props.data.data.queryTypeLookUp.id.toString() == queryTypes.AddJob || this.props.data.data.queryTypeLookUp.id.toString() == queryTypes.PaymentRequest || this.props.data.data.queryTypeLookUp.id.toString() == queryTypes.BillableItem || this.props.data.data.queryTypeLookUp.id.toString() == queryTypes.CostReport) {
            disablerelatedItemIcon = false;
        }    
        return (
            <>
                <tr>
                    <td rowSpan={2}>
                        <div className="d-flex justify-content-between align-items-center p-1">
                            <CheckBox onValueChanged={this.handleMultiSelectChange} disabled={this.props.data.data.linkedCaseID!==null}/>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex justify-content-between align-items-center">
                            {billableItemId ?
                                <button
                                    className={disablerelatedItemIcon ? "disabled icon-btn without-border-button" : "without-border-button"}
                                    onClick={() => {
                                        this.showRelatedItemPopup(billableItemId);
                                    }}
                                    disabled={disablerelatedItemIcon}
                                >
                                    <FontAwesomeIcon icon={faFileAlt} />
                                </button> :
                                <button
                                    className={disablerelatedItemIcon ? "disabled icon-btn without-border-button" : "without-border-button"}
                                    onClick={() => {
                                        window.open(`/MatchedPopUpPage?filterBy=${linkedId}`, "Related Payment Request/ Artist Job Notification",
                                            "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1050,height=400,left=100,top=100")
                                    }}
                                    disabled={disablerelatedItemIcon} >
                                    <FontAwesomeIcon icon={faFileAlt} />
                                </button>}
                        </div>
                    </td>
                    <td> <TagCell data={this.props.data} i18n={this.props.data} tReady={false} t={this.props.data} /> </td>
                    <td className="datagrid__text-cell">{this.props.data.data.taggedUser?.name}</td>
                    <td className="datagrid__text-cell">{this.props.data.data.caseStatusLU.case_Status_Value}</td>
                    <td className="datagrid__text-cell">{this.props.data.data.queryTypeLookUp.value}</td>
                    <td className="datagrid__text-cell">{finalFormattedDate}</td>
                    <td className="datagrid__text-cell">{this.props.data.data.clientBusinessEntity ? this.props.data.data.clientBusinessEntity.shortName : ""}</td>
                    <td className="datagrid__text-cell">{this.props.data.data.venue ? this.props.data.data.venue.venueName : ""}</td>
                    <td className="datagrid__text-cell">{this.onboardedCellDisplayContent(this.props.data.data)}</td>
                    <td className="datagrid__text-cell">{this.props.data.data.serviceTypeLookUp ? this.props.data.data.serviceTypeLookUp.value : ""}</td>
                    <td className="datagrid__text-cell">
                        {this.titleCellDisplayContent(this.props.data)}
                    </td>
                    <td className="datagrid__text-cell">{this.providerCellDisplayContent(this.props.data)}</td>
                    <td className="datagrid__text-cell">{this.props.data.data.providerBusinessEntity?.id}</td>
                    <td className="datagrid__text-cell">{this.resolutionReasonCellDisplayContent(this.props.data)}</td>
                    <td className="datagrid__text-cell">{this.internalCommentsCellDisplayContent(this.props.data)}</td>
                    <td className="datagrid__text-cell">{this.props.data.data.raisedByContactName}</td>
                    <td className="datagrid__text-cell">{this.props.data.data.raisedByContactTelNumber}</td>
                    <td className="datagrid__text-cell">{finalFormattedLastUpdatedDate}</td>
                    <td className="datagrid__text-cell">{this.props.data.data.user ? this.props.data.data.user.name : ""}</td>
                    <td rowSpan={2} className="datagrid__text-cell">{this.props.data.data.case_ID}</td>
                    <td rowSpan={2} className="datagrid__text-cell">{this.props.data.data.linkedCaseID}</td>
                </tr>
                <tr>
                    <td className="datagrid__description-row datagrid__text-description-cell" colSpan={15}><div>{this.props.data.data.query_Description}</div></td>
                </tr>                
                <Popup
                    visible={this.state.isVisibleManageShiftPopup}
                    onHiding={this.closeManageShiftPopup}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Related Item"
                    defaultWidth="90%"
                    defaultHeight="80%"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            {billableItemId ? <AddEditFormV2 location={null} history={null} billableItemId={billableItemId} hidePopup={this.hidePopup}></AddEditFormV2> : null}
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.titlePopupVisible1}
                    onHiding={this.hideInfoTitle}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"TITLE"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.title1}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.providerPopupVisibleOther}
                    onHiding={this.hideInfoProvider}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Supplier Information & Venue Conditions"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <SupplierInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            serviceTypeId={this.state.serviceId}
                            navigationFromShift="SimpleShiftGrid"
                        ></SupplierInformationMainContent>
                    </ScrollView>
                </Popup>
                {
                    this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            hideOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title="Artist Details"
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <ArtistProfileComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    businessEntityId={this.state.businessEntityId}
                                    handleFeedbackUpdate = {()=>{return null}}
                                    handleAddArtist = {()=>{return null}}
                                    serviceType={"Entertainment"}
                                ></ArtistProfileComponent>
                            </ScrollView>
                        </Popup>
                    ):(null)
                }
                <Popup
                    visible={this.state.resolutionReasonPopUpVisible}
                    onHiding={this.hideInfoResolutionReason}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"RESOLUTION REASON"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.resolutionReason}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.internalCommentsPopupVisible}
                    onHiding={this.hideInfoInternalComments}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"INTERNAL COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.internalComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </>

        );
    }
}

export default withTranslation()(QueryGridRenderDataRow);