import React, { useEffect, useState } from "react";
import { SelectBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import { BaseApiService } from "../../services/BaseApiService";
import LookupService, {
    LookupTypeItemForEntityId,
} from "../../services/LookupService";
import { SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import sharedUtils from "../grid/sharedUtilities";

/**
 * Props
 */
interface SelectBoxFromLookupTableStaticProps {
    /** The data this lookup should display */
    entityID: string;
    /** Callback to fire on user selection */
    onValueChanged: (e: SelectBoxOnValueChangedEvent) => void;
    /** Regardless of internal rules, disable the control */
    isDisabled: boolean;
    /** The root lookup will not have a parentMappingId but should still be enabled (unlike child lookups) */
    isRoot: boolean;
    /** Filter by this Id (props.parentMappingId === lookup.parentMappingId) */
    parentMappingId: string;
    /** Set as the selected value */
    value?: string;
    /** Set as the dropdown display value from the returned dataset */
    displayDataset?: string;
    /** Set as the dropdown id value from the returned dataset */
    valueDataset?: string;
}

/**
 * Component - Localizes state for a drop down control.
 *
 * - Copied from LookupTypeList
 * - DRY alert!  Why not update existing component?
 *  - The existing component was not reused based on the fact that it was using the same existing URL to populate the lookups.
 */
const SelectBoxFromLookupTableStatic: React.FC<SelectBoxFromLookupTableStaticProps> = (props) => {
    // Initialize state
    const [state, setResult] = useState<BaseApiService<LookupTypeItemForEntityId>>({
        status: "loading",
    });
    // Initialize services
    const service = new LookupService();
    const utils = new sharedUtils();

    /**
     * Load data on component load
     */
    useEffect(() => {
        executeLookup(service.getLookupByEntityId, props.entityID, 3);
    }, [props.entityID, props.parentMappingId]); //Use Effect would trigger the API call whenever the Entity dropdown value is changed in the parent component.

    /**
     * Retrieve the requested lookup from the server
     *
     * @param lookupFunction {() => Promise<AxiosResponse<any>>} - the service call to execute for a specific lookup
     * @param retryCount  {number} - the number of tries that should be made to retrieve data from the server
     * @returns {void}
     */
    const executeLookup = (
        lookupFunction: (entityId: string) => Promise<AxiosResponse<any>>,
        entityId: string,
        retryCount: number
    ): void => {
        if (retryCount > 0) {
            retryCount--;
            lookupFunction(entityId)
                .then(handleSuccess)
                .catch(() => executeLookup(service.getLookupByEntityId, entityId, retryCount));
        } else {
            handleError("Retry count exceeded for lookup service with type ==> " + props.entityID);
        }
    };

    /**
     * Filter the lookup item data source
     *
     * @param parentMappingId {number} - filters by this Id, so if there is no Id to filter by, disable control
     * @returns {LookupTypeItemForEntityId[]} - filtered data
     */
    const filterDataSource = (
        data: LookupTypeItemForEntityId[],
        parentMappingId: string
    ): LookupTypeItemForEntityId[] => {
        var mappingId: number = utils.convertStringToInt(parentMappingId);

        return mappingId > 0
            ? data.filter((item: LookupTypeItemForEntityId) => {
                  return item.contactID === parentMappingId;
              })
            : data;
    };

    /**
     * Update state with data source from server
     *
     * @param response {AxiosResponse<any>} - server response
     * @returns {void}
     */
    const handleSuccess = (response: AxiosResponse<any>): void => {
        setResult({
            status: "loaded",
            payload: filterDataSource(response.data.data, props.parentMappingId),
        });
    };

    /**
     * Capture error from server
     *
     * @param error {any} - error resolved by promise
     * @returns {void}
     */
    const handleError = (error: any): void => {
        setResult({
            status: "error",
            error,
        });

        var respMessage: string =
            `Lookup Type ${props.entityID} data load failed with response: ` + JSON.stringify(error);

        if (!service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    /**
     * Determine if the lookup should be disabled
     *
     * @param status {string} - status of lookup (loading, loaded, etc..)
     * @param parentMappingId {string} - no id may mean to disable the lookup
     * @param isRoot {boolean} - ignore disable rules
     * @returns {boolean}
     */
    const isDisabledRules = (status: string, parentMappingId: string, isRoot: boolean): boolean => {
        var isDisabled = true;
        var mappingId: number = utils.convertStringToInt(parentMappingId);
        var falseIfMapped = mappingId > 0 ? false : true;
        if (status === "loaded") {
            isDisabled = isRoot ? false : falseIfMapped;
        }

        return isDisabled;
    };

    return (
        <div id="lookupTypeListComponentId">
            {state.status === "loading" && <span>Loading...</span>}
            {state.status === "loaded" && (
                <SelectBox
                    dataSource={filterDataSource(state.payload, props.parentMappingId)}
                    displayExpr={props.displayDataset}
                    valueExpr={props.valueDataset}
                    onValueChanged={props.onValueChanged}
                    disabled={
                        props.isDisabled
                            ? props.isDisabled
                            : isDisabledRules(state.status, props.parentMappingId, props.isRoot)
                    }
                    value={props.value}
                />
            )}
            {state.status === "error" && <SelectBox dataSource={[]} />}
        </div>
    );
};

export default SelectBoxFromLookupTableStatic;
