import React from "react";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Scrolling,
    SearchPanel,
    Editing,
    Lookup,
    PatternRule,
} from "devextreme-react/data-grid";
import {
    DataGridOnInitializedEvent,
    OnRowInsertingEvent,
    OnRowUpdatingEvent,
    onEditCanceledEvent,
    OnRowRemovingEvent,
} from "../../types/DevExtremeTypes";
import BillableItemHeaderService, { BudgetUploadChildGridRowItem } from "../../services/BillableItemHeaderService";
import gridUtils from "../grid/GridUtilities";
import { AxiosResponse } from "axios";
import lookupService, { LookupTypeItem, PeriodMatrixLookupItem } from "../../services/LookupService";
import BillingFormUtils from "../Billing/BillingFormUtils";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import sharedUtils from "../grid/sharedUtilities";
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
// Props
//  - refreshSignal will indicate that a grid refresh is required.
//  - gridVisibilitySignal is going to signal when the grid will be shown.
interface BudgetGridProps {
    clientDataSource: LookupTypeItem[];
    clientDataSourceAll: LookupTypeItem[];
    periodMatrixDataSource: PeriodMatrixLookupItem[];
    serviceTypeDataSource: LookupTypeItem[];
    venueDataSource: LookupTypeItem[];
    clientId: string;
    venueId: string;
    serviceId: string;
    dateFrom: string;
    dateTo: string;
    refreshSignal: boolean;
    houseNoDataSource: LookupTypeItem[];
    venueNameDataSource: LookupTypeItem[];
    venueNameDataSourceAll: LookupTypeItem[];
}

export interface BudgetGridAddEditItem {
    id: string;
    clientBusinessEntityId: string;
    venueId: string;
    serviceTypeId: string;
    periodId: string;
    budgetAmount: string;
}

// State
interface BudgetGridState {
    budgetRows: BudgetUploadChildGridRowItem[];
    loadPanelVisible: boolean;
    yearColumnVisible: boolean;
    budgetGridAddEditObject: BudgetGridAddEditItem;
    errorMessage: [];
    loading: boolean;
    venueActiveDataSource: LookupTypeItem[];
    venueDataSourceAll: LookupTypeItem[];
    active: boolean;
}

// Component - displays both the header and child grids
class BudgetGrid extends React.Component<BudgetGridProps> {
    state: BudgetGridState;
    gridUtils: gridUtils;
    utils: BillingFormUtils;
    dataGridElement: any;
    service: BillableItemHeaderService;
    LookupService: lookupService;
    sharedUtils: sharedUtils;
    constructor(props: BudgetGridProps) {
        super(props);
        this.state = {
            budgetRows: [],
            loadPanelVisible: false,
            yearColumnVisible: true,
            budgetGridAddEditObject: {
                id: "",
                clientBusinessEntityId: "",
                venueId: "",
                serviceTypeId: "",
                periodId: "",
                budgetAmount: "",
            },
            errorMessage: [],
            loading: false,
            venueActiveDataSource: [],
            venueDataSourceAll: [],
            active: true
        };

        this.gridUtils = new gridUtils();
        this.service = new BillableItemHeaderService();
        this.utils = new BillingFormUtils();
        this.LookupService = new lookupService();
        this.sharedUtils = new sharedUtils();
        this.dataGridElement = undefined;
        this.handleFileUploadOnInitialize = this.handleFileUploadOnInitialize.bind(this);
    }

    // Initialize state from server
    componentDidMount() {
        this.getBudgetRows();
    }

    getBudgetRows = () => {
        const { clientId, venueId, serviceId, dateFrom, dateTo } = this.props;
        //Only initiate the API call if any of the parameters are supplied in the props.
        if (clientId || venueId || serviceId || dateFrom || dateTo) {
            this.setState({
                loadPanelVisible: true,
            });
            this.service
                .getBudgetData(clientId, venueId, serviceId, dateFrom, dateTo)
                .then(this.catchRowData)
                .catch(this.handleFailure);
        }
    };

    catchRowData = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
        if (response && response.data) {
            this.setState({
                budgetRows: response.data.data,
            });
        }
    };

    handleFailure = (error: any) => {
        this.setState({
            loading: false,
        });
        var respMessage: string = "getChildGridDataForVenueHierarchy failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    //Refresh the Grid on an Apply click.
    componentDidUpdate(prevProps: BudgetGridProps) {
        if (this.props.refreshSignal != prevProps.refreshSignal) {
            this.getBudgetRows();
        }
    }

    // Set the file uploader component
    handleFileUploadOnInitialize(e: DataGridOnInitializedEvent) {
        this.dataGridElement = e.component;
    }

    setClientCellValue = (rowData: any, value: any) => {
        rowData.venueId = null;
        rowData.periodYear = null;
        rowData.periodNumber = null;
        rowData.periodWeek = null;
        rowData.periodStart = null;
        rowData.periodEnd = null;
        rowData.clientBusinessEntityId = value;
    };

    setHouseCellValue = (rowData: any, value: any) => {
        rowData.venueId = value;
    };

    setVenueCellValue = (rowData: any, value: any) => {
        rowData.venueId = value;
    };

    setPeriodYearCellValue = (rowData: any, value: any) => {
        rowData.periodWeek = null;
        rowData.periodNumber = null;
        rowData.periodStart = null;
        rowData.periodEnd = null;
        rowData.periodYear = value;
    };

    setPeriodNumberCellValue = (rowData: any, value: any) => {
        rowData.periodWeek = null;
        rowData.periodStart = null;
        rowData.periodEnd = null;
        rowData.periodNumber = value;
    };

    setPeriodWeekCellValue = (rowData: any, value: any) => {
        rowData.periodStart = null;
        rowData.periodEnd = null;
        rowData.periodWeek = value;
    };

    setWeekStartDayValue = (rowData: any, value: any) => {
        rowData.periodStart = value;
    };

    setWeekEndDayValue = (rowData: any, value: any) => {
        rowData.periodEnd = value;
    };

    //Filter the House Name list based on the client value.
    getFilteredHouseList = (options: any) => {
        var { houseNoDataSource } = this.props;
        var venueData: LookupTypeItem[] =
            houseNoDataSource && options.data && options.data.clientBusinessEntityId
                ? this.utils.filteredVenueValues(houseNoDataSource, options.data.clientBusinessEntityId)
                : houseNoDataSource;
        return {
            store: venueData,
            filter: options.data ? ["parentMappingId", "=", options.data.clientBusinessEntityId] : null,
        };
    };

    //Filter the Venue Name list based on the client value.
    getFilteredVenueList = (options: any) => {
        var { venueNameDataSource } = this.props;
        var venueData: LookupTypeItem[] =
            venueNameDataSource && options.data && options.data.clientBusinessEntityId
                ? this.utils.filteredVenueValues(venueNameDataSource, options.data.clientBusinessEntityId)
                : venueNameDataSource;
        return {
            store: venueData,
            filter: options.data ? ["parentMappingId", "=", options.data.clientBusinessEntityId] : null,
        };
    };

    //Filter the secondary dropdown based on the value of primary.
    getFilteredYearType = (options: any) => {
        var { periodMatrixDataSource } = this.props;
        var periodYearDataSource: LookupTypeItem[] =
            periodMatrixDataSource && options.data && options.data.clientBusinessEntityId
                ? this.utils.buildPeriodYearList(options.data.clientBusinessEntityId, periodMatrixDataSource)
                : this.LookupService.getDefaultPeriodValues();
        return {
            store: periodYearDataSource,
            filter: options.data ? ["parentMappingId", "=", options.data.clientBusinessEntityId] : null,
        };
    };

    getFilteredPeriodNumberType = (options: any) => {
        var { periodMatrixDataSource } = this.props;
        var periodNumberDataSource: LookupTypeItem[] =
            periodMatrixDataSource && options.data && options.data.clientBusinessEntityId && options.data.periodYear
                ? this.utils.buildPeriodNumberList(
                    options.data.clientBusinessEntityId,
                    options.data.periodYear,
                    periodMatrixDataSource
                )
                : this.LookupService.getDefaultPeriodValues();
        return {
            store: periodNumberDataSource,
            filter: options.data ? ["parentMappingId", "=", options.data.periodYear] : null,
        };
    };

    getFilteredPeriodWeekType = (options: any) => {
        var { periodMatrixDataSource } = this.props;
        var periodWeekDataSource: LookupTypeItem[] =
            periodMatrixDataSource &&
                options.data &&
                options.data.clientBusinessEntityId &&
                options.data.periodYear &&
                options.data.periodNumber
                ? this.utils.buildPeriodWeekList(
                    options.data.clientBusinessEntityId,
                    options.data.periodYear,
                    options.data.periodNumber,
                    periodMatrixDataSource
                )
                : this.LookupService.getDefaultPeriodValues();
        return {
            store: periodWeekDataSource,
            filter: options.data ? ["parentMappingId", "=", options.data.periodNumber] : null,
        };
    };

    getFilteredPeriodStartDates = (options: any) => {
        var { periodMatrixDataSource } = this.props;
        var periodDateSource: LookupTypeItem[] =
            periodMatrixDataSource &&
                options.data &&
                options.data.clientBusinessEntityId &&
                options.data.periodYear &&
                options.data.periodNumber &&
                options.data.periodWeek
                ? this.utils.buildDateList(
                    options.data.clientBusinessEntityId,
                    options.data.periodYear,
                    options.data.periodNumber,
                    options.data.periodWeek,
                    periodMatrixDataSource,
                    true
                )
                : this.utils.getDistinctStartEndDates(periodMatrixDataSource, true);
        return {
            store: periodDateSource,
            filter: options.data ? ["parentMappingId", "=", options.data.periodWeek] : null,
        };
    };

    getFilteredPeriodEndDates = (options: any) => {
        var { periodMatrixDataSource } = this.props;
        var periodDateSource: LookupTypeItem[] =
            periodMatrixDataSource &&
                options.data &&
                options.data.clientBusinessEntityId &&
                options.data.periodYear &&
                options.data.periodNumber &&
                options.data.periodWeek
                ? this.utils.buildDateList(
                    options.data.clientBusinessEntityId,
                    options.data.periodYear,
                    options.data.periodNumber,
                    options.data.periodWeek,
                    periodMatrixDataSource,
                    false
                )
                : this.utils.getDistinctStartEndDates(periodMatrixDataSource, false);
        return {
            store: periodDateSource,
            filter: options.data ? ["parentMappingId", "=", options.data.periodWeek] : null,
        };
    };

    onRowInserting = (e: OnRowInsertingEvent) => {
        var { periodMatrixDataSource } = this.props;
        var { budgetRows } = this.state;
        var periodId: string = "";
        //Get a unique periodID.
        if (e.data && e.data.clientBusinessEntityId && e.data.periodYear && e.data.periodNumber && e.data.periodWeek) {
            var uniquePeriodIdArray: PeriodMatrixLookupItem[] = this.utils.getUniquePeriodId(
                periodMatrixDataSource,
                e.data.clientBusinessEntityId,
                e.data.periodYear,
                e.data.periodNumber,
                e.data.periodWeek
            );
            periodId = uniquePeriodIdArray && uniquePeriodIdArray.length > 0 ? uniquePeriodIdArray[0].id : "";
        }
        this.setState({
            active: true,
            loading: true,
            budgetGridAddEditObject: {
                ...this.state.budgetGridAddEditObject,
                id: "",
                clientBusinessEntityId: e.data.clientBusinessEntityId ? e.data.clientBusinessEntityId : "",
                venueId: e.data.venueId ? e.data.venueId : "",
                serviceTypeId: e.data.serviceTypeId ? e.data.serviceTypeId : "",
                periodId: periodId ? periodId : "",
                budgetAmount: e.data.budgetAmount ? e.data.budgetAmount : "",
            },
        });
        this.service
            .saveBudgetDataItem(this.state.budgetGridAddEditObject)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch((err) => {
                this.setState({
                    loading: false,
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                });
            });
        var insertingCancelled: boolean = this.utils.budgetRowValidation(
            e.data.id,
            e.data.clientBusinessEntityId,
            e.data.venueId,
            e.data.serviceTypeId,
            periodId,
            e.data.budgetAmount,
            budgetRows
        );
        if (insertingCancelled) {
            e.cancel = false;
        } else {
            e.cancel = true;
        }
    };

    //A common helper function that will be triggered on Add/Edit/Delete success.
    onRowUpdatedInsertedDeleted() {
        this.setState({
            loading: false,
            errorMessage: [],
        });
        this.getBudgetRows();
    }

    onRowUpadating = (e: OnRowUpdatingEvent) => {
        var { periodMatrixDataSource } = this.props;
        var { budgetRows } = this.state;
        var clientId: string = "";
        var venueId: string = "";
        var periodYear: string = "";
        var periodNumber: string = "";
        var periodWeek: string = "";
        var periodId: string = "";
        if (
            e.newData.clientBusinessEntityId ||
            e.newData.clientBusinessEntityId === null ||
            e.newData.clientBusinessEntityId === ""
        ) {
            clientId = e.newData.clientBusinessEntityId;
        } else {
            clientId = e.oldData.clientBusinessEntityId;
        }
        if (e.newData.venueId || e.newData.venueId === "" || e.newData.venueId === null) {
            venueId = e.newData.venueId;
        } else {
            venueId = e.oldData.venueId;
        }
        if (e.newData.periodYear || e.newData.periodYear === "" || e.newData.periodYear === null) {
            periodYear = e.newData.periodYear;
        } else {
            periodYear = e.oldData.periodYear;
        }
        if (e.newData.periodNumber || e.newData.periodNumber === "" || e.newData.periodNumber === null) {
            periodNumber = e.newData.periodNumber;
        } else {
            periodNumber = e.oldData.periodNumber;
        }
        if (e.newData.periodWeek || e.newData.periodWeek === null || e.newData.periodWeek === "") {
            periodWeek = e.newData.periodWeek;
        } else {
            periodWeek = e.oldData.periodWeek;
        }
        if (clientId && periodYear && periodNumber && periodWeek) {
            var uniquePeriodIdArray: PeriodMatrixLookupItem[] = this.utils.getUniquePeriodId(
                periodMatrixDataSource,
                clientId,
                periodYear,
                periodNumber,
                periodWeek
            );
            periodId = uniquePeriodIdArray && uniquePeriodIdArray.length > 0 ? uniquePeriodIdArray[0].id : "";
        }
        //Calculate the unique PeriodId.
        this.setState({
            loading: true,
            budgetGridAddEditObject: {
                ...this.state.budgetGridAddEditObject,
                id: e.oldData.id,
                clientBusinessEntityId: clientId ? clientId : "",
                venueId: venueId ? venueId : "",
                serviceTypeId: e.newData.serviceTypeId == undefined ? e.oldData.serviceTypeId : e.newData.serviceTypeId,
                periodId: periodId ? periodId : "",
                budgetAmount: e.newData.budgetAmount == undefined ? e.oldData.budgetAmount : e.newData.budgetAmount,
            },
        });

        this.service
            .saveBudgetDataItem(this.state.budgetGridAddEditObject)
            .then(this.onRowUpdatedInsertedDeleted)
            .catch((err) => {
                this.setState({
                    loading: false,
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                });
            });
        var insertingCancelled: boolean = this.utils.budgetRowValidation(
            e.oldData.id,
            clientId,
            venueId,
            e.newData.serviceTypeId == undefined ? e.oldData.serviceTypeId : e.newData.serviceTypeId,
            periodId,
            e.newData.budgetAmount == undefined ? e.oldData.budgetAmount : e.newData.budgetAmount,
            budgetRows
        );
        if (insertingCancelled) {
            e.cancel = false;
        } else {
            e.cancel = true;
        }
    };

    //A helper function that will be trigered when a row is cancelled.
    onEditCancelled = (e: onEditCanceledEvent) => {
        this.setState({
            errorMessage: [],
        });
    };

    //Helper function to delete a row.
    onRowRemoving = (e: OnRowRemovingEvent) => {
        this.setState({
            loading: true,
        });
        this.service
            .deleteBudgetDataItem(e.data.id)
            .then(this.onRowDeleted)
            .catch((err) => {
                this.setState({
                    loading: false,
                });
            });
    };

    onRowDeleted = (response: AxiosResponse<any>) => {
        this.setState({
            loading: true,
        });
        this.getBudgetRows();
    };

    //Displays the error message on top of the Grid.
    renderErrorMessage = (errorMessage: any[]) => {
        let errorData: React.ReactNode = <></>;
        if (errorMessage) {
            errorData = (
                <span className="unscheduled-shift">
                    <ul>
                        {errorMessage.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    {item.columnName}: {item.errorMessage}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            );
        }
        return errorData;
    };

    onRowEditting = () => {
        this.setState({
            active: false
        })
    }
    onRowAdding = () => {
        this.setState({
            active: true
        })
    }
    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    render() {
        var { clientDataSource, clientDataSourceAll, serviceTypeDataSource } = this.props;
        var { loadPanelVisible, errorMessage, loading } = this.state;
        return loadPanelVisible ? (
            <div className="starter-template">
                <LoadIndicator id="simple-grid-indicator" height={100} width={100} visible={loadPanelVisible} />
            </div>
        ) : (
            <div>
                {this.renderErrorMessage(errorMessage)}
                <div>
                    <DataGrid
                        dataSource={this.state.budgetRows}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        onRowUpdating={this.onRowUpadating}
                        onRowInserting={this.onRowInserting}
                        onEditCanceling={this.onEditCancelled}
                        onRowRemoving={this.onRowRemoving}
                        onEditingStart={this.onRowEditting}
                        onInitNewRow={this.onRowAdding}
                        columnResizingMode={"widget"}
                        onExporting={this.onExporting}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Paging defaultPageSize={5} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[1, 5, 10, 20]} showInfo={true} />
                        <Editing
                            mode="row"
                            useIcons={true}
                            allowAdding={true}
                            allowUpdating={true}
                            allowDeleting={true}
                        />
                        <Column type="buttons" buttons={["edit", "delete"]} />
                        <Column dataField="id" caption="ID" allowEditing={false} calculateSortValue={(rowData: any) => {
                            return this.gridUtils.convertStringToInt(rowData.id);
                        }} />
                        <Column
                            dataField="clientBusinessEntityId"
                            caption="CLIENT SHORT NAME"
                            setCellValue={this.setClientCellValue}
                        >
                            <Lookup dataSource={this.state.active ? clientDataSource : clientDataSourceAll} displayExpr="value" valueExpr="id" />
                        </Column>
                        <Column dataField="venueId" caption="HOUSE NUMBER" setCellValue={this.setHouseCellValue}>
                            <Lookup dataSource={this.getFilteredHouseList} displayExpr="value" valueExpr="id" />
                        </Column>
                        <Column dataField="venueId" caption="VENUE NAME" setCellValue={this.setVenueCellValue}>
                            <Lookup dataSource={this.getFilteredVenueList} displayExpr="value" valueExpr="id" />
                        </Column>
                        <Column dataField="serviceTypeId" caption="SERVICE">
                            <Lookup dataSource={serviceTypeDataSource} displayExpr="value" valueExpr="id" />
                        </Column>
                        <Column dataField="periodYear" caption="YEAR" setCellValue={this.setPeriodYearCellValue}>
                            <Lookup dataSource={this.getFilteredYearType} displayExpr="value" valueExpr="id" />
                        </Column>
                        <Column dataField="periodNumber" caption="PERIOD" setCellValue={this.setPeriodNumberCellValue}>
                            <Lookup dataSource={this.getFilteredPeriodNumberType} displayExpr="value" valueExpr="id" />
                        </Column>
                        <Column dataField="periodWeek" caption="WEEK" setCellValue={this.setPeriodWeekCellValue}>
                            <Lookup dataSource={this.getFilteredPeriodWeekType} displayExpr="value" valueExpr="id" />
                        </Column>
                        <Column dataField="periodStart" caption="START" setCellValue={this.setWeekStartDayValue}>
                            <Lookup dataSource={this.getFilteredPeriodStartDates} displayExpr="value" valueExpr="id" />
                        </Column>
                        <Column dataField="periodEnd" caption="END" setCellValue={this.setWeekEndDayValue}>
                            <Lookup dataSource={this.getFilteredPeriodEndDates} displayExpr="value" valueExpr="id" />
                        </Column>
                        <Column
                            dataField="budgetAmount"
                            allowSorting={true}
                            caption="AMOUNT"
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.budgetAmount);
                            }}
                        >
                            <PatternRule
                                message={"Budget amount should be in two decimal place"}
                                pattern={/^[0-9]+(\.[0-9][0-9]?)?$/}
                            />{" "}
                        </Column>
                        <FilterRow visible={true} applyFilter="auto" />
                        <Scrolling mode="standard" useNative={true} scrollByThumb={true} />
                    </DataGrid>
                </div>
            </div>
        );
    }
}

export default BudgetGrid;
