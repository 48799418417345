import React from "react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes } from "../../services/LookupService";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import sharedUtils from "../grid/sharedUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { ServiceNames, ServiceType } from "../../common/ServiceUtilities";
import CardListUtilities from "../../common/CardListUtilities";
import PostApprovalService, { postApprovalData } from "../../services/PostApprovalService";
import PostApprovalUtils from "./PostApprovalUtils";
import {
    BillableItemResponse,
    BillableItemSubmitRequest,
    ConfigurableTextType,
    Status,
} from "../../services/OutstandingApprovalService";
import OutstandingApprovalCard from "../Outstanding-Actions/OutstandingApprovalCard";
import EquipmentApprovalCard from "../Outstanding-Actions/EquipmentApprovalCard";
import CleaningApprovalCard from "../Outstanding-Actions/CleaningApprovalCard";
import EntertainmentApprovalCard from "../Outstanding-Actions/EntertainmentApprovalCard";
import OutstandingApprovalService from "../../services/OutstandingApprovalService";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import GlobalService from "../../services/GlobalService";
import FinancialAdjustmentCard from "../Outstanding-Actions/FinancialAdjustmentCard";
import OtherServicesCard from "../Outstanding-Actions/OtherServicesCard";

interface PostApprovalCardListProps {
    serviceTypeId: string;
    clientId: string;
    venueId: string;
    dateRangeFrom: string;
    dateRangeTo: string;
    statusId: string;
    updateCardCount: (cardCount: number) => void;
}

interface PostApprovalCardListState {
    configuarationValueIndex: string[];
    showProgressIndicator: boolean;
    count: number;
    postApprovalData: postApprovalData;
    clientId: string;
    venueId: string;
    configurationValueArray: ConfigurableTextType[];
    disableApproveAllBtn: boolean;
    loadPanelVisible: boolean;
    isApproveAll: boolean;
    noDataMessage: string;
}

class PostApprovalCardList extends React.Component<PostApprovalCardListProps> {
    lookupService: LookupService;
    service: OutstandingApprovalService;
    state: PostApprovalCardListState;
    postApprovalService: PostApprovalService;
    sharedUtils: sharedUtils;
    cardListUtilities: CardListUtilities;
    utils: PostApprovalUtils;
    constructor(props: PostApprovalCardListProps) {
        super(props);
        //Initializing the service.
        this.lookupService = new LookupService();
        this.postApprovalService = new PostApprovalService();
        this.sharedUtils = new sharedUtils();
        this.cardListUtilities = new CardListUtilities();
        this.utils = new PostApprovalUtils();
        this.service = new OutstandingApprovalService();
        var accordionSectionCardData: postApprovalData = this.utils.initializePostApprovalCardSections();
        this.state = {
            configuarationValueIndex: [],
            showProgressIndicator: false,
            count: 0,
            postApprovalData: accordionSectionCardData,
            clientId: this.props.clientId,
            venueId: this.props.venueId,
            configurationValueArray: [],
            disableApproveAllBtn: false,
            loadPanelVisible: false,
            isApproveAll: false,
            noDataMessage: ""
        };
    }

    componentDidMount() {
        // this.setState({
        //     showProgressIndicator: true,
        // });
        this.getConfigurationLookup();
    }
    getConfigurationLookup = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.configuration)
            .then(this.handleConfigurationLookupSuccess)
            .catch(this.handleFailure);
    };
    handleConfigurationLookupSuccess = (response: AxiosResponse<any>) => {
        var configData = this.sharedUtils.getConfigurationDataFromLookupResponse(response.data.data);
        this.setState({
            configuarationValueIndex: configData.value,
        });
        this.setState({
            configurationValueArray: response.data.data,
        });
    };

    // Arrow functions bind the context of 'this' inside the function itself, so no need to bind inside of the constructor.
    // To fetch in the card details from the server.
    fetchCardDetails = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.postApprovalService
            .getPostApprovalData(
                this.props.clientId,
                this.props.venueId,
                this.props.serviceTypeId,
                this.props.dateRangeFrom,
                this.props.dateRangeTo,
                this.props.statusId
            )
            .then(this.handleSuccess)
            .catch(this.handleFailure);
    };


    handleSuccess = (response: AxiosResponse<any>) => {
        var accordionSectionData: postApprovalData = response.data.data.postApprovalSummaryList;
        if (response.data.data.postApprovalSummaryList.length > 0) {
            var countWithZeroShift: number = 0;
            var countWithShift: number = 0;
            response.data.data.postApprovalSummaryList.forEach((item: any) => {
                if (item.shiftCount == 0) {
                    countWithZeroShift = countWithZeroShift + 1;
                }
                else if (item.shiftCount != 0) {
                    countWithShift = countWithShift + 1;
                }
            });
            if (countWithShift > 0) {
                this.setState({
                    showProgressIndicator: false,
                    cards: response.data.data.eventUkUserGridLookUp,
                    count: response.data.data.length,
                    postApprovalData: accordionSectionData,
                    noDataMessage: ""
                });
            }
            else if (countWithZeroShift > 0) {
                this.setState({
                    showProgressIndicator: false,
                    noDataMessage: "There are no post delivery items found for the filters used"
                });
            }
        }
        else {
            this.setState({
                showProgressIndicator: false,
                noDataMessage: "There are no post delivery items found for the filters used"
            });
        }
        var shiftCount = this.cardCountSet(accordionSectionData);
        this.props.updateCardCount(shiftCount);
    };

    cardCountSet = (accordionApprovalPanelData: postApprovalData) => {
        var accordionSectionData: postApprovalData[] = [];
        accordionSectionData = JSON.parse(JSON.stringify(accordionApprovalPanelData));
        var totalShiftCount = 0;
        accordionSectionData.length > 0 &&
            accordionSectionData.map((item: any) => {
                totalShiftCount += item.shiftCount;
                this.props.updateCardCount(totalShiftCount);
            });
        return totalShiftCount;
    };

    handleFailure = () => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    filterCardList = () => { };

    //When the count of the card changes update the sidepanel.
    componentDidUpdate(prevProps: PostApprovalCardListProps) {
        if (
            this.props.serviceTypeId != prevProps.serviceTypeId ||
            this.props.clientId != prevProps.clientId ||
            this.props.venueId != prevProps.venueId ||
            this.props.dateRangeFrom != prevProps.dateRangeFrom ||
            this.props.dateRangeTo != prevProps.dateRangeTo ||
            this.props.statusId != prevProps.statusId
        ) {
            this.fetchCardDetails();
        }
    }

    handleApproveAll = (cardItem: BillableItemSubmitRequest[]) => {
        var editedApprovalItems = GlobalService.getEditedApprovalItems();
        if (editedApprovalItems.length > 0) {
            editedApprovalItems.forEach((editedApprovalItem: any) => {
                var isAmend: boolean = false;
                if (
                    (editedApprovalItem && editedApprovalItem.itemTypeId == itemTypes.Original) ||
                    editedApprovalItem.itemTypeId == ""
                ) {
                    if (
                        editedApprovalItem.equipmentBillableQuantity - editedApprovalItem.equipmentScheduleQuantity !=
                        0
                    ) {
                        isAmend = true;
                    }
                }
                cardItem.forEach((item: any, key: number) => {
                    if (editedApprovalItem.billableItemId == item.billableItemId) {
                        cardItem.splice(
                            key,
                            1,
                            this.utils.convertFormDataToBillableItemRequest(
                                editedApprovalItem,
                                Status.Approved,
                                false,
                                null,
                                new Date(),
                                "",
                                "",
                                isAmend,
                                "",
                                "ApprovalCard"
                            )
                        );
                    }
                });
            });
            GlobalService.removeEditapprovalItems();
        }
        if (cardItem.length > 0) {
            this.setState({
                loadPanelVisible: true
            });
            this.service
                .uploadEditAddData(cardItem)
                .then(this.handleSubmitSuccess)
                .catch((err) => {
                    var respMessage: string = "uploadEditAddData failed with response: " + JSON.stringify(err);
                    if (!this.service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        }
    };
    handleSubmitSuccess = (response: AxiosResponse<any>) => {
        if (response.status == 200) {
            this.setState({
                loadPanelVisible: false,
                isApproveAll: true
            });
        }
        this.fetchCardDetails();
    };

    convertHourMinuteToMinute = (value: string) => {
        var timePart1: string = "";
        var timePart2: string = "";
        var minuteVal: string = "";
        var timePart3: string[] = [];
        if (value && value.trim().includes("(")) {
            var valueParts = value.trim().split("(");
            var valuePart1 = valueParts[0];
            value = valuePart1;
        }
        if (value && value.trim().includes("h")) {
            timePart1 = value.replace("h", "");
        }
        else if (value && value.trim().includes("Hrs")) {
            timePart1 = value.replace("Hrs", "");
        }
        else if (value && value.trim().includes("Hr")) {
            timePart1 = value.replace("Hr", "");
        }
        if (timePart1 && timePart1.trim().includes("m")) {
            timePart2 = timePart1.replace("m", "");
        }
        else if (timePart1 && timePart1.trim().includes("Mins")) {
            timePart2 = timePart1.replace("Mins", "");
        }
        else if (value && value.trim().includes("Mins")) {
            minuteVal = value.replace("Mins", "");
        }
        else if (value && value.trim().includes("m")) {
            minuteVal = value.replace("m", "");
        }
        if (timePart2) {
            timePart3 = timePart2.trim().split(" ");
        }
        var hourToMinute: number = 0;
        if (timePart3.length > 0) {
            hourToMinute = parseFloat(timePart3[0]) * 60;
        }
        else if (timePart1) {
            hourToMinute = parseFloat(timePart1.trim()) * 60;
        }
        var minute: number = 0;
        if (timePart3.length > 0 && timePart3[1] && timePart3[1] != "") {
            minute = parseFloat(timePart3[1]);
        }
        else if (timePart3.length > 0 && timePart3[2] && timePart3[2] != "") {
            minute = parseFloat(timePart3[2]);
        }
        else if (minuteVal) {
            minute = parseFloat(minuteVal);
        }
        var time: number;
        if (hourToMinute | minute) {
            time = hourToMinute + minute;
            return time;
        }
        else {
            return 0;
        }

    }

    convertMinstoHoursMins = (minuteValue: number) => {
        var hour = parseInt(Math.floor(minuteValue / 60).toString());
        var minute = Math.round(minuteValue % 60);
        return hour + "h" + " " + minute + "m";
    }


    setChangedPostApprovalData = (cardSection: string, previousProviderAmount: number,
        currentProviderAmount: number,
        currentBillableHours: string,
        previousBillableHours: string,
        accordionNo: number,
        cost: string,
        serviceTypeId: string, summaryBreakDownKey: number,
        hourMinutes: string, navigatedFrom: string, totalNonQueriedCost: string,
        totalNonQueriedMinutes: string,
        paymentId: string,
        invoiceId: string, billableQuantity: string) => {
        var accordionTotalNonQueriedCost: number = 0;
        var accordionNonQueriedTime: number = 0;
        if (totalNonQueriedCost && totalNonQueriedMinutes) {
            accordionTotalNonQueriedCost = parseFloat(totalNonQueriedCost.replace("£", ""));
            accordionNonQueriedTime = this.convertHourMinuteToMinute(totalNonQueriedMinutes);
        }
        var accordionCost = parseFloat(cost.replace("£", ""));
        var accordionTime: number = this.convertHourMinuteToMinute(hourMinutes);
        var convertedCurrentBillableHours: number = 0;

        var convertedPreviousBillableHours: number = 0;
        if (currentBillableHours && previousBillableHours) {
            convertedCurrentBillableHours = this.convertHourMinuteToMinute(currentBillableHours);
            convertedPreviousBillableHours = parseFloat(previousBillableHours);
        }
        else if (billableQuantity) {
            convertedCurrentBillableHours = parseFloat(billableQuantity);
        }
        var sectionCost: number = 0.00;
        var sectionTime: number = 0.00;
        var accordionSectionData: postApprovalData[] = [];
        accordionSectionData = JSON.parse(JSON.stringify(this.state.postApprovalData));
        accordionSectionData =
            accordionSectionData.length > 0
                ? accordionSectionData.filter((x) => x.serviceId == parseInt(serviceTypeId))
                : [];
        var diff: number = 0;
        diff = parseFloat((previousProviderAmount - currentProviderAmount).toString());
        var diffOfMinutes = parseFloat((convertedPreviousBillableHours - convertedCurrentBillableHours).toString())
        var newProviderAmountAccordion = parseFloat((accordionCost - (diff)).toString());
        var newProviderAmountSection: number = 0;
        var newProviderTimeAccordion: string = this.convertMinstoHoursMins((accordionTime - (diffOfMinutes)));
        var newProviderTimeSection: string = "";
        if (cardSection == "latePersonnelSummary") {
            if (accordionSectionData && accordionSectionData.length > 0) {
                if (accordionSectionData[accordionNo].postApprovalSecurityCards.latePersonnelSummary && accordionSectionData[accordionNo].postApprovalSecurityCards.latePersonnelSummary.totalNonQueriedCost
                    || accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems
                    && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost) {
                    sectionCost = parseFloat((accordionSectionData[accordionNo].postApprovalSecurityCards.latePersonnelSummary.totalNonQueriedCost).replace("£", ""));
                    newProviderAmountSection = parseFloat((sectionCost - (diff)).toString())
                    if (navigatedFrom == "calculation") {
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + newProviderAmountAccordion;
                        accordionSectionData[accordionNo].postApprovalSecurityCards.latePersonnelSummary.totalNonQueriedCost = "£" + newProviderAmountSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedCost = "£" + (previousProviderAmount + accordionCost);
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + (accordionTotalNonQueriedCost - previousProviderAmount);
                        if (accordionSectionData[accordionNo].postApprovalSecurityCards && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems) {
                            if (accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost) {
                                accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost = ("£" +
                                    (parseFloat((accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                            } else {
                                accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost = ("£" +
                                    previousProviderAmount);
                            }
                        }
                    }

                }
                if (accordionSectionData[accordionNo].postApprovalSecurityCards.latePersonnelSummary && accordionSectionData[accordionNo].postApprovalSecurityCards.latePersonnelSummary.totalNonQueriedHours ||
                    accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours) {

                    sectionTime = this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalSecurityCards.latePersonnelSummary.totalNonQueriedHours);
                    newProviderTimeSection = this.convertMinstoHoursMins((sectionTime - (diffOfMinutes)));
                    if (navigatedFrom == "calculation") {
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = newProviderTimeAccordion;
                        accordionSectionData[accordionNo].postApprovalSecurityCards.latePersonnelSummary.totalNonQueriedHours = newProviderTimeSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedMinutes = this.convertMinstoHoursMins(convertedPreviousBillableHours + accordionTime);
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = this.convertMinstoHoursMins(accordionNonQueriedTime - convertedPreviousBillableHours);
                        if (accordionSectionData[accordionNo].postApprovalSecurityCards && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems) {
                            accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours
                                = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                        }


                    }
                }

            }
        }
        else if (cardSection == "lateEquipmentSummary") {
            if (accordionSectionData && accordionSectionData.length > 0) {
                if (accordionSectionData[accordionNo].postApprovalSecurityCards.lateEquipmentSummary && accordionSectionData[accordionNo].postApprovalSecurityCards.lateEquipmentSummary.totalNonQueriedCost ||
                    accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost) {
                    sectionCost = parseFloat((accordionSectionData[accordionNo].postApprovalSecurityCards.lateEquipmentSummary.totalNonQueriedCost).replace("£", ""));
                    newProviderAmountSection = parseFloat((sectionCost - (diff)).toString())
                    if (navigatedFrom == "calculation") {
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + newProviderAmountAccordion;
                        accordionSectionData[accordionNo].postApprovalSecurityCards.lateEquipmentSummary.totalNonQueriedCost = "£" + newProviderAmountSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedCost = "£" + (previousProviderAmount + accordionCost);
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + (accordionTotalNonQueriedCost - previousProviderAmount);
                        if (accordionSectionData[accordionNo].postApprovalSecurityCards && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems) {
                            accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost = ("£" +
                                (parseFloat((accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        }
                    }
                }
                if (accordionSectionData[accordionNo].postApprovalSecurityCards.lateEquipmentSummary && accordionSectionData[accordionNo].postApprovalSecurityCards.lateEquipmentSummary.totalNonQueriedHours ||
                    accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours) {
                    sectionTime = this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalSecurityCards.lateEquipmentSummary.totalNonQueriedHours);
                    newProviderTimeSection = this.convertMinstoHoursMins((sectionTime - (diffOfMinutes)));
                    if (navigatedFrom == "calculation") {
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = newProviderTimeAccordion;
                        accordionSectionData[accordionNo].postApprovalSecurityCards.lateEquipmentSummary.totalNonQueriedHours = newProviderTimeSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedMinutes = this.convertMinstoHoursMins(convertedPreviousBillableHours + accordionTime);
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = this.convertMinstoHoursMins(accordionNonQueriedTime - convertedPreviousBillableHours);
                        if (accordionSectionData[accordionNo].postApprovalSecurityCards && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems) {
                            accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours
                                = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                        }
                    }
                }

            }
        }
        else if (cardSection == "onTimePersonnelSummary") {
            if (accordionSectionData && accordionSectionData.length > 0) {
                if (accordionSectionData[accordionNo].postApprovalSecurityCards.onTimePersonnelSummary && accordionSectionData[accordionNo].postApprovalSecurityCards.onTimePersonnelSummary.summaryBreakdown.length > 0) {
                    sectionCost = parseFloat((accordionSectionData[accordionNo].postApprovalSecurityCards.onTimePersonnelSummary.summaryBreakdown[summaryBreakDownKey - 1].totalNonQueriedCost).replace("£", ""));
                    newProviderAmountSection = parseFloat((sectionCost - (diff)).toString())
                    if (navigatedFrom == "calculation") {
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + newProviderAmountAccordion;
                        accordionSectionData[accordionNo].postApprovalSecurityCards.onTimePersonnelSummary.summaryBreakdown[summaryBreakDownKey - 1].totalNonQueriedCost = "£" + newProviderAmountSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedCost = "£" + (previousProviderAmount + accordionCost);
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + (accordionTotalNonQueriedCost - previousProviderAmount);
                        if (accordionSectionData[accordionNo].postApprovalSecurityCards && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems) {
                            accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost = ("£" +
                                (parseFloat((accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        }
                    }
                }
                if (accordionSectionData[accordionNo].postApprovalSecurityCards.onTimePersonnelSummary && accordionSectionData[accordionNo].postApprovalSecurityCards.onTimePersonnelSummary.summaryBreakdown.length > 0) {
                    sectionTime = this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalSecurityCards.onTimePersonnelSummary.summaryBreakdown[summaryBreakDownKey - 1].totalNonQueriedHours);
                    newProviderTimeSection = this.convertMinstoHoursMins((sectionTime - (diffOfMinutes)));
                    if (navigatedFrom == "calculations") {
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = newProviderTimeAccordion;
                        accordionSectionData[accordionNo].postApprovalSecurityCards.onTimePersonnelSummary.summaryBreakdown[summaryBreakDownKey - 1].totalNonQueriedHours = newProviderTimeSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedMinutes = this.convertMinstoHoursMins(convertedPreviousBillableHours + accordionTime);
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = this.convertMinstoHoursMins(accordionNonQueriedTime - convertedPreviousBillableHours);
                        if (accordionSectionData[accordionNo].postApprovalSecurityCards && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems) {
                            accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours
                                = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                        }
                    }
                }

            }
        }
        else if (cardSection == "onTimeEquipmentSummary") {
            if (accordionSectionData && accordionSectionData.length > 0) {
                if (accordionSectionData[accordionNo].postApprovalSecurityCards.onTimeEquipmentSummary && accordionSectionData[accordionNo].postApprovalSecurityCards.onTimeEquipmentSummary.totalNonQueriedCost) {
                    sectionCost = parseFloat((accordionSectionData[accordionNo].postApprovalSecurityCards.onTimeEquipmentSummary.totalNonQueriedCost).replace("£", ""));
                    newProviderAmountSection = parseFloat((sectionCost - (diff)).toString())
                    if (navigatedFrom == "calculation") {
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + newProviderAmountAccordion;
                        accordionSectionData[accordionNo].postApprovalSecurityCards.onTimeEquipmentSummary.totalNonQueriedCost = "£" + newProviderAmountSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedCost = "£" + (previousProviderAmount + accordionCost);
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + (accordionTotalNonQueriedCost - previousProviderAmount);
                        if (accordionSectionData[accordionNo].postApprovalSecurityCards && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems) {
                            accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost = ("£" +
                                (parseFloat((accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        }
                    }
                }
                if (accordionSectionData[accordionNo].postApprovalSecurityCards.onTimeEquipmentSummary && accordionSectionData[accordionNo].postApprovalSecurityCards.onTimeEquipmentSummary.totalNonQueriedHours) {
                    sectionTime = this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalSecurityCards.onTimeEquipmentSummary.totalNonQueriedHours);
                    newProviderTimeSection = this.convertMinstoHoursMins((sectionTime - (diffOfMinutes)));
                    if (navigatedFrom == "calculation") {
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = newProviderTimeAccordion;
                        accordionSectionData[accordionNo].postApprovalSecurityCards.onTimeEquipmentSummary.totalNonQueriedHours = newProviderTimeSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedMinutes = this.convertMinstoHoursMins(convertedPreviousBillableHours + accordionTime);
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = this.convertMinstoHoursMins(accordionNonQueriedTime - convertedPreviousBillableHours);
                        if (accordionSectionData[accordionNo].postApprovalSecurityCards && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems) {
                            accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours
                                = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                        }
                    }
                }

            }
        }

        else if (cardSection == "onTimeWeeklyCleaningSummary") {
            if (accordionSectionData && accordionSectionData.length > 0) {
                if (accordionSectionData[accordionNo].postApprovalCleaningCards.onTimeWeeklyCleaningSummary && accordionSectionData[accordionNo].postApprovalCleaningCards.onTimeWeeklyCleaningSummary.totalNonQueriedCost) {
                    sectionCost = parseFloat((accordionSectionData[accordionNo].postApprovalCleaningCards.onTimeWeeklyCleaningSummary.totalNonQueriedCost).replace("£", ""));
                    newProviderAmountSection = parseFloat((sectionCost - (diff)).toString())
                    if (navigatedFrom == "calculation") {
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + newProviderAmountAccordion;
                        accordionSectionData[accordionNo].postApprovalCleaningCards.onTimeWeeklyCleaningSummary.totalNonQueriedCost = "£" + newProviderAmountSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedCost = "£" + (previousProviderAmount + accordionCost);
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + (accordionTotalNonQueriedCost - previousProviderAmount);
                        accordionSectionData[accordionNo].totalQueriedMinutes = this.convertMinstoHoursMins(convertedCurrentBillableHours + accordionTime);
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = this.convertMinstoHoursMins(accordionNonQueriedTime - convertedCurrentBillableHours);
                        if (accordionSectionData[accordionNo].postApprovalCleaningCards && accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems) {
                            accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedCost = ("£" +
                                (parseFloat((accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                            accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedHours
                                = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedHours) + convertedCurrentBillableHours);
                        }
                    }
                }
            }
        }

        else if (cardSection == "lateWeeklyCleaningSummary") {
            if (accordionSectionData && accordionSectionData.length > 0) {
                if (accordionSectionData[accordionNo].postApprovalCleaningCards.lateWeeklyCleaningSummary && accordionSectionData[accordionNo].postApprovalCleaningCards.lateWeeklyCleaningSummary.totalNonQueriedCost) {
                    sectionCost = parseFloat((accordionSectionData[accordionNo].postApprovalCleaningCards.lateWeeklyCleaningSummary.totalNonQueriedCost).replace("£", ""));
                    newProviderAmountSection = parseFloat((sectionCost - (diff)).toString())
                    if (navigatedFrom == "calculation") {
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + newProviderAmountAccordion;
                        accordionSectionData[accordionNo].postApprovalCleaningCards.lateWeeklyCleaningSummary.totalNonQueriedCost = "£" + newProviderAmountSection;
                    }
                    else if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                        accordionSectionData[accordionNo].totalQueriedCost = "£" + (previousProviderAmount + accordionCost);
                        accordionSectionData[accordionNo].totalNonQueriedCost = "£" + (accordionTotalNonQueriedCost - previousProviderAmount);
                        if (accordionSectionData[accordionNo].postApprovalCleaningCards && accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems) {
                            accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedCost = ("£" +
                                (parseFloat((accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                            accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedHours
                                = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedHours) + convertedCurrentBillableHours);
                        }
                        accordionSectionData[accordionNo].totalQueriedMinutes = this.convertMinstoHoursMins(convertedCurrentBillableHours + accordionTime);
                        accordionSectionData[accordionNo].totalNonQueriedMinutes = this.convertMinstoHoursMins(accordionNonQueriedTime - convertedCurrentBillableHours);
                    }
                }

            }
        }
        else if (cardSection == "onTimeAdditionalSummary" || cardSection == "lateAdditionalSummary" ||
            cardSection == "adjustmentSummary" || cardSection == "onTimeEntertainmentSummary" ||
            cardSection == "lateEntertainmentSummary") {
            if (accordionSectionData && accordionSectionData.length > 0) {
                if (navigatedFrom == "query" && (!paymentId && !invoiceId)) {
                    accordionSectionData[accordionNo].totalQueriedCost = "£" + (previousProviderAmount + (accordionCost ? accordionCost : 0));
                    accordionSectionData[accordionNo].totalNonQueriedCost = "£" + (accordionTotalNonQueriedCost - previousProviderAmount);

                    if (accordionSectionData[accordionNo].postApprovalEntertainmentCards && accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems && (cardSection == "lateEntertainmentSummary" || cardSection == "onTimeEntertainmentSummary")) {
                        accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems.totalQueriedCost = ("£" +
                            (parseFloat((accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems.totalQueriedHours
                            = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                    }
                    else if (accordionSectionData[accordionNo].postApprovalOtherCards && accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems && (cardSection == "lateEntertainmentSummary" || cardSection == "onTimeEntertainmentSummary")) {
                        accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems.totalQueriedCost = ("£" +
                            (parseFloat((accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems.totalQueriedHours
                            = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                    }
                    else if (accordionSectionData[accordionNo].postApprovalCleaningCards && accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems && (cardSection == "lateAdditionalSummary" || cardSection == "onTimeAdditionalSummary")) {
                        accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedCost = ("£" +
                            (parseFloat((accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedHours
                            = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                    }
                    else if (accordionSectionData[accordionNo].postApprovalSecurityCards && accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems && (cardSection == "adjustmentSummary")) {
                        accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost = ("£" +
                            (parseFloat((accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours
                            = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalSecurityCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                    }
                    else if (accordionSectionData[accordionNo].postApprovalCleaningCards && accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems && (cardSection == "adjustmentSummary")) {
                        accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedCost = ("£" +
                            (parseFloat((accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedHours
                            = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalCleaningCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                    }
                    else if (accordionSectionData[accordionNo].postApprovalEntertainmentCards && accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems && (cardSection == "adjustmentSummary")) {
                        accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems.totalQueriedCost = ("£" +
                            (parseFloat((accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems.totalQueriedHours
                            = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalEntertainmentCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                    }
                    else if (accordionSectionData[accordionNo].postApprovalOtherCards && accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems && (cardSection == "adjustmentSummary")) {
                        accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems.totalQueriedCost = ("£" +
                            (parseFloat((accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems.totalQueriedCost).replace("£", ""))) + previousProviderAmount);
                        accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems.totalQueriedHours
                            = this.convertMinstoHoursMins(this.convertHourMinuteToMinute(accordionSectionData[accordionNo].postApprovalOtherCards.disputedItems.totalQueriedHours) + convertedPreviousBillableHours);
                    }
                }
            }
        }
        this.setState({
            postApprovalData: accordionSectionData
        });
    }

    setCost = (currentProviderAmount: number, previousProviderAmount: number,
        currentBillableHours: string, previousBillableHours: string,
        cardSection: string, accordionNo: number, totalQueriedCost: string,
        totalNonQueriedCost: string, totalQueriedMinutes: string, totalNonQueriedMinutes: string,
        _billableItemId: string, serviceTypeId: string, summaryBreakDownKey: number,
        navigatedFrom: string,
        paymentId: string,
        invoiceId: string, billableQuantity: string) => {
        if (navigatedFrom == "calculation") {

            this.setChangedPostApprovalData(cardSection, previousProviderAmount, currentProviderAmount,
                currentBillableHours, previousBillableHours,
                accordionNo, totalNonQueriedCost, serviceTypeId,
                summaryBreakDownKey, totalNonQueriedMinutes, navigatedFrom, "", "", "", "", billableQuantity);

        }
        else if (navigatedFrom == "query") {

            this.setChangedPostApprovalData(cardSection, previousProviderAmount, currentProviderAmount,
                currentBillableHours, previousBillableHours,
                accordionNo, totalQueriedCost, serviceTypeId,
                summaryBreakDownKey, totalQueriedMinutes, navigatedFrom, totalNonQueriedCost,
                totalNonQueriedMinutes, paymentId, invoiceId, billableQuantity);

        }

    }

    renderSecurityAccordionSectionData(
        doorSupervisionCssClass: any,
        securitySectionDataObj: postApprovalData,
        count: number,
        totalNonQueriedCost: string,
        totalNonQueriedMinutes: string,
        totalQueriedCost: string,
        totalQueriedMinutes: string
    ) {
        var postApprovalSecurityCards: postApprovalData["postApprovalSecurityCards"];
        var nominatedOnTimePersonelSummaryBreakDown: any[] = [];
        var nominatedOnTimePersonnelSummary: postApprovalData["postApprovalSecurityCards"]["onTimePersonnelSummary"];
        var nominatedLatePersonnelSummary: postApprovalData["postApprovalSecurityCards"]["latePersonnelSummary"];
        var nominatedOnTimeEquipmentSummary: postApprovalData["postApprovalSecurityCards"]["onTimeEquipmentSummary"];
        var nominatedLateEquipmentSummary: postApprovalData["postApprovalSecurityCards"]["lateEquipmentSummary"];
        var nominatedAdjustmentSummary: postApprovalData["postApprovalSecurityCards"]["adjustmentSummary"];
        var nominatedDisputedItems: postApprovalData["postApprovalSecurityCards"]["disputedItems"];
        postApprovalSecurityCards = securitySectionDataObj.postApprovalSecurityCards;
        var idOfAccordionSection: string = `approvals-security-section${count}`;

        nominatedOnTimePersonnelSummary = postApprovalSecurityCards.onTimePersonnelSummary;
        nominatedLatePersonnelSummary = postApprovalSecurityCards.latePersonnelSummary;
        nominatedOnTimeEquipmentSummary = postApprovalSecurityCards.onTimeEquipmentSummary;
        nominatedLateEquipmentSummary = postApprovalSecurityCards.lateEquipmentSummary;
        nominatedAdjustmentSummary = postApprovalSecurityCards.adjustmentSummary;
        nominatedDisputedItems = postApprovalSecurityCards.disputedItems;
        nominatedOnTimePersonelSummaryBreakDown = nominatedOnTimePersonnelSummary.summaryBreakdown;
        var disableApproveAllBtn: boolean = false;

        var nominatedOnTimePersonnelSummaryShiftList: any[] = [];
        var nominatedLatePersonelSummaryShiftList: any[] = [];
        var nominatedOnTimeEquipmentSummaryShiftList: any[] = [];
        var nominatedLateEquipmentSummaryShiftList: any[] = [];
        var nominatedAdjustmentSummaryShiftList: any[] = [];
        var nominatedDisputedItemsShiftList: any[] = [];

        nominatedOnTimePersonelSummaryBreakDown && nominatedOnTimePersonelSummaryBreakDown.length > 0 &&
            nominatedOnTimePersonelSummaryBreakDown.map((item: any) => {
                item.shiftList.length > 0 &&
                    item.shiftList.map((billableItem: any) => {
                        nominatedOnTimePersonnelSummaryShiftList.push(
                            this.utils.convertBillableItemResponeToFormData(billableItem)
                        );
                    });
            });

        nominatedLatePersonnelSummary && nominatedLatePersonnelSummary.shiftDetails && nominatedLatePersonnelSummary.shiftDetails.length > 0 &&
            nominatedLatePersonnelSummary.shiftDetails.map((item: any) => {
                nominatedLatePersonelSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });


        nominatedOnTimeEquipmentSummary && nominatedOnTimeEquipmentSummary.shiftDetails && nominatedOnTimeEquipmentSummary.shiftDetails.length > 0 &&
            nominatedOnTimeEquipmentSummary.shiftDetails.map((item: any) => {
                nominatedOnTimeEquipmentSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });

        nominatedLateEquipmentSummary && nominatedLateEquipmentSummary.shiftDetails && nominatedLateEquipmentSummary.shiftDetails.length > 0 &&
            nominatedLateEquipmentSummary.shiftDetails.map((item: any) => {
                nominatedLateEquipmentSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });


        nominatedAdjustmentSummary && nominatedAdjustmentSummary.adjustmentShiftList && nominatedAdjustmentSummary.adjustmentShiftList.length > 0 &&
            nominatedAdjustmentSummary.adjustmentShiftList.map((item: any) => {
                nominatedAdjustmentSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });



        nominatedDisputedItems && nominatedDisputedItems.adjustmentShiftList && nominatedDisputedItems.adjustmentShiftList.length > 0 &&
            nominatedDisputedItems.adjustmentShiftList.map((item: any) => {
                nominatedDisputedItemsShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        var approvalbillableItem: BillableItemSubmitRequest[] = [];
        nominatedOnTimePersonnelSummaryShiftList && nominatedOnTimePersonnelSummaryShiftList.length > 0 &&
            nominatedOnTimePersonnelSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });

        nominatedLatePersonelSummaryShiftList && nominatedLatePersonelSummaryShiftList.length > 0 &&
            nominatedLatePersonelSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });


        nominatedOnTimeEquipmentSummaryShiftList && nominatedOnTimeEquipmentSummaryShiftList.length > 0 &&
            nominatedOnTimeEquipmentSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });


        nominatedLateEquipmentSummaryShiftList && nominatedLateEquipmentSummaryShiftList.length > 0 &&
            nominatedLateEquipmentSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });

        nominatedAdjustmentSummaryShiftList && nominatedAdjustmentSummaryShiftList.length > 0 &&
            nominatedAdjustmentSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });
        nominatedDisputedItemsShiftList && nominatedDisputedItemsShiftList.length > 0 &&
            nominatedDisputedItemsShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });

        if (approvalbillableItem.length == 0) {
            disableApproveAllBtn = true;
        }

        var titleOfNominatedProvider: string = "";
        var headerOfSections: string = "";
        if (nominatedOnTimePersonnelSummaryShiftList.length > 0 ||
            nominatedLatePersonelSummaryShiftList.length > 0 ||
            nominatedOnTimeEquipmentSummaryShiftList.length > 0 ||
            nominatedLateEquipmentSummaryShiftList.length > 0 ||
            nominatedAdjustmentSummaryShiftList.length > 0 ||
            nominatedDisputedItemsShiftList.length > 0) {
            titleOfNominatedProvider = postApprovalSecurityCards.providerName;
            if (titleOfNominatedProvider) {
                headerOfSections = titleOfNominatedProvider + " " + "|";
            }
        }
        return (
            <div
                id={idOfAccordionSection}
                className={doorSupervisionCssClass.sectionClass}
                aria-labelledby="approvalsHeadingSecurity"
                data-parent="#approvalsAccordion"
            >
                <br />
                <div className="col-md-5 col-xl-3 grid-info__button-container" style={{ width: "310px" }}>
                    <button
                        className="btn--link btn btn-primary btn--large btn--with-icon"
                        onClick={() => this.handleApproveAll(approvalbillableItem)}
                        disabled={disableApproveAllBtn}
                    >
                        Approve All
                    </button>
                </div>
                <br />


                <div className="mt-2">
                    <div className="mt-2">
                        <h4 className="ml-2 font-weight-bold">
                            {titleOfNominatedProvider ? titleOfNominatedProvider + ":" : ""}
                        </h4>
                    </div>
                    <br />
                    {nominatedOnTimePersonelSummaryBreakDown && nominatedOnTimePersonelSummaryBreakDown.length > 0 &&
                        nominatedOnTimePersonelSummaryBreakDown.map((item: any, summaryBreakDownKey: number) => {
                            return (
                                <>
                                    <h4 className="ml-2">
                                        <span>{headerOfSections} Shifts | {item.date} ({item.shiftCount}) | {item.totalNonQueriedHours} | {this.sharedUtils.thousandsSeparator(item.totalNonQueriedCost)}</span>
                                        <span>{" "}</span>
                                        {item.totalQueriedCost && item.totalQueriedHours ?
                                            <span className="text-white-50">(Items Disputed: {item.totalQueriedHours} | {this.sharedUtils.thousandsSeparator(item.totalQueriedCost)} )</span> : null}
                                    </h4>
                                    <div className="row mt-2">
                                        {item.shiftList.length > 0 &&
                                            item.shiftList.map((cardItem: BillableItemResponse, key: number) => {
                                                return (
                                                    <div
                                                        className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                        key={cardItem ? cardItem.billableItemId : 0}
                                                    >
                                                        <OutstandingApprovalCard
                                                            isApproveAll={this.state.isApproveAll}
                                                            outstandingCardData={cardItem}
                                                            configuarationValueIndex={
                                                                this.state.configuarationValueIndex
                                                            }
                                                            configurationValueArray={this.state.configurationValueArray}
                                                            renderCards={this.filterCardList}
                                                            totalNumber={item.shiftList.length}
                                                            serialNumber={key + 1}
                                                            setCost={this.setCost}
                                                            cardSection="onTimePersonnelSummary"
                                                            accordionNo={count}
                                                            totalNonQueriedCost={totalNonQueriedCost}
                                                            totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                            totalQueriedCost={totalQueriedCost}
                                                            totalQueriedMinutes={totalQueriedMinutes}
                                                            summaryBreakDownKey={summaryBreakDownKey + 1}
                                                        />
                                                    </div>
                                                );
                                            })}{" "}
                                    </div>
                                </>
                            );
                        })}

                    {nominatedOnTimeEquipmentSummary && nominatedOnTimeEquipmentSummary.shiftDetails && nominatedOnTimeEquipmentSummary.shiftDetails.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections} Equipment({nominatedOnTimeEquipmentSummary.shiftCount}) | {nominatedOnTimeEquipmentSummary.totalNonQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedOnTimeEquipmentSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedOnTimeEquipmentSummary.totalQueriedCost && nominatedOnTimeEquipmentSummary.totalQueriedHours ?
                                    <span className="text-white-50">(Items Disputed: {nominatedOnTimeEquipmentSummary.totalQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedOnTimeEquipmentSummary.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedOnTimeEquipmentSummary.shiftDetails.length > 0 &&
                                    nominatedOnTimeEquipmentSummary.shiftDetails.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <EquipmentApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedOnTimeEquipmentSummary.shiftDetails.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    cardSection="onTimeEquipmentSummary"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                    setCost={this.setCost}
                                                    summaryBreakDownKey={0}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                    {nominatedLatePersonnelSummary && nominatedLatePersonnelSummary.shiftDetails && nominatedLatePersonnelSummary.shiftDetails.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections} Late Shifts ({nominatedLatePersonnelSummary.shiftCount}) | {nominatedLatePersonnelSummary.totalNonQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedLatePersonnelSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedLatePersonnelSummary.totalQueriedCost && nominatedLatePersonnelSummary.totalQueriedHours ?
                                    <span className="text-white-50">(Items Disputed:  {nominatedLatePersonnelSummary.totalQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedLatePersonnelSummary.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedLatePersonnelSummary.shiftDetails.length > 0 &&
                                    nominatedLatePersonnelSummary.shiftDetails.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <OutstandingApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedLatePersonnelSummary.shiftDetails.length}
                                                    serialNumber={key + 1}
                                                    setCost={this.setCost}
                                                    cardSection="latePersonnelSummary"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                    summaryBreakDownKey={0}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}

                    {nominatedLateEquipmentSummary && nominatedLateEquipmentSummary.shiftDetails && nominatedLateEquipmentSummary.shiftDetails.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections}  Late Equipment ({nominatedLateEquipmentSummary.shiftCount}) | {nominatedLateEquipmentSummary.totalNonQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedLateEquipmentSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedLateEquipmentSummary.totalQueriedCost && nominatedLateEquipmentSummary.totalQueriedHours ?
                                    <span className="text-white-50">(Items Disputed: {nominatedLateEquipmentSummary.totalQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedLateEquipmentSummary.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedLateEquipmentSummary.shiftDetails.length > 0 &&
                                    nominatedLateEquipmentSummary.shiftDetails.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <EquipmentApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedLateEquipmentSummary.shiftDetails.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    cardSection="lateEquipmentSummary"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                    setCost={this.setCost}
                                                    summaryBreakDownKey={0}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}

                    {nominatedAdjustmentSummary && nominatedAdjustmentSummary.adjustmentShiftList && nominatedAdjustmentSummary.adjustmentShiftList.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections}  Adjustments ({nominatedAdjustmentSummary.shiftCount}) | {this.sharedUtils.thousandsSeparator(nominatedAdjustmentSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedAdjustmentSummary.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed: {this.sharedUtils.thousandsSeparator(nominatedAdjustmentSummary.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedAdjustmentSummary.adjustmentShiftList.length > 0 &&
                                    nominatedAdjustmentSummary.adjustmentShiftList.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <FinancialAdjustmentCard
                                                    type="security"
                                                    outstandingCardData={cardItem}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedAdjustmentSummary.adjustmentShiftList.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    cardSection="financialAdjustment"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                    setCost={this.setCost}

                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                    {nominatedDisputedItems && nominatedDisputedItems.adjustmentShiftList && nominatedDisputedItems.adjustmentShiftList.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections}  Items Disputed ({nominatedDisputedItems.shiftCount}) | {nominatedDisputedItems.totalNonQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedDisputedItems.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedDisputedItems.totalQueriedCost && nominatedDisputedItems.totalQueriedHours ?
                                    <span className="text-white-50">(Items Disputed:  {nominatedDisputedItems.totalQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedDisputedItems.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedDisputedItems.adjustmentShiftList.length > 0 &&
                                    nominatedDisputedItems.adjustmentShiftList.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <OutstandingApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedDisputedItems.adjustmentShiftList.length}
                                                    serialNumber={key + 1}
                                                    setCost={this.setCost}
                                                    cardSection="disputedItems"
                                                    accordionNo={count}
                                                    summaryBreakDownKey={0}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    }

    renderCleaningAccordionSectionData(cleaningCssClass: any, cleaningSectionDataObj: postApprovalData, count: number,
        totalNonQueriedCost: string,
        totalNonQueriedMinutes: string,
        totalQueriedCost: string,
        totalQueriedMinutes: string) {
        var postApprovalCleaningCards: postApprovalData["postApprovalCleaningCards"];
        postApprovalCleaningCards = cleaningSectionDataObj.postApprovalCleaningCards;
        var nominatedOnTimeWeeklyCleaningSummary: postApprovalData["postApprovalCleaningCards"]["onTimeWeeklyCleaningSummary"];
        var nominatedLateWeeklyCleaningSummary: postApprovalData["postApprovalCleaningCards"]["lateWeeklyCleaningSummary"];
        var nominatedOnTimeAdditionalSummary: postApprovalData["postApprovalCleaningCards"]["onTimeAdditionalSummary"];
        var nominatedLateAdditionalSummary: postApprovalData["postApprovalCleaningCards"]["lateAdditionalSummary"];
        var nominatedAdjustmentSummary: postApprovalData["postApprovalCleaningCards"]["adjustmentSummary"];
        var nominatedDisputedItems: postApprovalData["postApprovalCleaningCards"]["disputedItems"];
        nominatedOnTimeWeeklyCleaningSummary = postApprovalCleaningCards.onTimeWeeklyCleaningSummary;
        nominatedLateWeeklyCleaningSummary = postApprovalCleaningCards.lateWeeklyCleaningSummary;
        nominatedOnTimeAdditionalSummary = postApprovalCleaningCards.onTimeAdditionalSummary;
        nominatedLateAdditionalSummary = postApprovalCleaningCards.lateAdditionalSummary;
        nominatedAdjustmentSummary = postApprovalCleaningCards.adjustmentSummary;
        nominatedDisputedItems = postApprovalCleaningCards.disputedItems;
        var disableApproveAllBtn: boolean = false;
        var nominatedOnTimeWeeklyCleaningShiftList: any[] = [];
        var nominatedLateWeeklyCleaningShiftList: any[] = [];
        var nominatedOnTimeAdditionalSummaryShiftList: any[] = [];
        var nominatedLateAdditionalSummaryShiftList: any[] = [];
        var nominatedAdjustmentSummaryShiftList: any[] = [];
        var nominatedDisputedItemsShiftList: any[] = [];

        var idOfAccordionSection: string = `approvals-cleaning-section${count}`;
        var approvalbillableItem: BillableItemSubmitRequest[] = [];
        nominatedOnTimeWeeklyCleaningSummary && nominatedOnTimeWeeklyCleaningSummary.shiftDetails && nominatedOnTimeWeeklyCleaningSummary.shiftDetails.length > 0 &&
            nominatedOnTimeWeeklyCleaningSummary.shiftDetails.map((item: any) => {
                nominatedOnTimeWeeklyCleaningShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        nominatedLateWeeklyCleaningSummary && nominatedLateWeeklyCleaningSummary.shiftDetails && nominatedLateWeeklyCleaningSummary.shiftDetails.length > 0 &&
            nominatedLateWeeklyCleaningSummary.shiftDetails.map((item: any) => {
                nominatedLateWeeklyCleaningShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        nominatedOnTimeAdditionalSummary && nominatedOnTimeAdditionalSummary.shiftDetails && nominatedOnTimeAdditionalSummary.shiftDetails.length > 0 &&
            nominatedOnTimeAdditionalSummary.shiftDetails.map((item: any) => {
                nominatedOnTimeAdditionalSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        nominatedLateAdditionalSummary && nominatedLateAdditionalSummary.shiftDetails && nominatedLateAdditionalSummary.shiftDetails.length > 0 &&
            nominatedLateAdditionalSummary.shiftDetails.map((item: any) => {
                nominatedLateAdditionalSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        nominatedAdjustmentSummary && nominatedAdjustmentSummary.adjustmentShiftList && nominatedAdjustmentSummary.adjustmentShiftList.length > 0 &&
            nominatedAdjustmentSummary.adjustmentShiftList.map((item: any) => {
                nominatedAdjustmentSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        nominatedDisputedItems && nominatedDisputedItems.adjustmentShiftList && nominatedDisputedItems.adjustmentShiftList.length > 0 &&
            nominatedDisputedItems.adjustmentShiftList.map((item: any) => {
                nominatedDisputedItemsShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        nominatedOnTimeWeeklyCleaningShiftList && nominatedOnTimeWeeklyCleaningShiftList.length > 0 &&
            nominatedOnTimeWeeklyCleaningShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });



        nominatedLateWeeklyCleaningShiftList && nominatedLateWeeklyCleaningShiftList.length > 0 &&
            nominatedLateWeeklyCleaningShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });


        nominatedOnTimeAdditionalSummaryShiftList && nominatedOnTimeAdditionalSummaryShiftList.length > 0 &&
            nominatedOnTimeAdditionalSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });
        nominatedDisputedItemsShiftList && nominatedDisputedItemsShiftList.length > 0 &&
            nominatedDisputedItemsShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });


        nominatedLateAdditionalSummaryShiftList && nominatedLateAdditionalSummaryShiftList.length > 0 &&
            nominatedLateAdditionalSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });


        nominatedAdjustmentSummaryShiftList && nominatedAdjustmentSummaryShiftList.length > 0 &&
            nominatedAdjustmentSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });

        if (approvalbillableItem.length == 0) {
            disableApproveAllBtn = true;
        }
        var titleNominatedProvider: string = "";
        var headerOfSections: string = "";
        if (nominatedOnTimeWeeklyCleaningShiftList.length > 0 ||
            nominatedLateWeeklyCleaningShiftList.length > 0 ||
            nominatedOnTimeAdditionalSummaryShiftList.length > 0 ||
            nominatedLateAdditionalSummaryShiftList.length > 0 ||
            nominatedAdjustmentSummaryShiftList.length > 0) {
            titleNominatedProvider = postApprovalCleaningCards.providerName;
            if (titleNominatedProvider) {
                headerOfSections = titleNominatedProvider + " " + "|";
            }
        }



        return (
            <div
                id={idOfAccordionSection}
                className={cleaningCssClass.sectionClass}
                aria-labelledby="approvalsHeadingCleaning"
                data-parent="#approvalsAccordion"
            >
                <br />
                <div className="col-md-5 col-xl-3 grid-info__button-container" style={{ width: "310px" }}>
                    <button
                        className="btn--link btn btn-primary btn--large btn--with-icon"
                        onClick={() => this.handleApproveAll(approvalbillableItem)}
                        disabled={disableApproveAllBtn || this.state.isApproveAll}
                    >
                        Approve All
                    </button>
                </div>
                <br />



                <div className="mt-2">
                    <div className="mt-2">
                        <h4 className="ml-2 font-weight-bold">
                            {titleNominatedProvider ? titleNominatedProvider + ":" : ""}
                        </h4>
                    </div>
                    <br />
                    {nominatedOnTimeWeeklyCleaningSummary && nominatedOnTimeWeeklyCleaningSummary.shiftDetails && nominatedOnTimeWeeklyCleaningSummary.shiftDetails.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections} Contract Cleaning  ({nominatedOnTimeWeeklyCleaningSummary.shiftCount}) |{" "}
                                    {nominatedOnTimeWeeklyCleaningSummary.totalNonQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedOnTimeWeeklyCleaningSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedOnTimeWeeklyCleaningSummary.totalQueriedCost && nominatedOnTimeWeeklyCleaningSummary.totalQueriedHours ?
                                    <span className="text-white-50">(Items Disputed: {nominatedOnTimeWeeklyCleaningSummary.totalQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedOnTimeWeeklyCleaningSummary.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedOnTimeWeeklyCleaningSummary.shiftDetails.length > 0 &&
                                    nominatedOnTimeWeeklyCleaningSummary.shiftDetails.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <CleaningApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    cardUIType="weekly"
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedOnTimeWeeklyCleaningSummary.shiftDetails.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={this.setCost}
                                                    cardSection="onTimeWeeklyCleaningSummary"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}

                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                    {nominatedOnTimeAdditionalSummary && nominatedOnTimeAdditionalSummary.shiftDetails && nominatedOnTimeAdditionalSummary.shiftDetails.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections} Additional Cleaning ({nominatedOnTimeAdditionalSummary.shiftCount}) | {" "}
                                    {this.sharedUtils.thousandsSeparator(nominatedOnTimeAdditionalSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedOnTimeAdditionalSummary.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed: {this.sharedUtils.thousandsSeparator(nominatedOnTimeAdditionalSummary.totalQueriedCost)})</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedOnTimeAdditionalSummary.shiftDetails.length > 0 &&
                                    nominatedOnTimeAdditionalSummary.shiftDetails.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <CleaningApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    cardUIType="additional"
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedOnTimeAdditionalSummary.shiftDetails.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={this.setCost}
                                                    cardSection="onTimeAdditionalSummary"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                    {nominatedLateWeeklyCleaningSummary && nominatedLateWeeklyCleaningSummary.shiftDetails && nominatedLateWeeklyCleaningSummary.shiftDetails.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span> {headerOfSections} Late Contract Cleaning ({nominatedLateWeeklyCleaningSummary.shiftCount}) | {" "}
                                    {nominatedLateWeeklyCleaningSummary.totalNonQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedLateWeeklyCleaningSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedLateWeeklyCleaningSummary.totalQueriedCost && nominatedLateWeeklyCleaningSummary.totalQueriedHours ?
                                    <span className="text-white-50">(Items Disputed: {nominatedLateWeeklyCleaningSummary.totalQueriedHours} | {this.sharedUtils.thousandsSeparator(nominatedLateWeeklyCleaningSummary.totalQueriedCost)})</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedLateWeeklyCleaningSummary.shiftDetails.length > 0 &&
                                    nominatedLateWeeklyCleaningSummary.shiftDetails.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <CleaningApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    cardUIType="weekly"
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedLateWeeklyCleaningSummary.shiftDetails.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={this.setCost}
                                                    cardSection="lateWeeklyCleaningSummary"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}

                    {nominatedLateAdditionalSummary && nominatedLateAdditionalSummary.shiftDetails && nominatedLateAdditionalSummary.shiftDetails.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections} Late Additional Cleaning ({nominatedLateAdditionalSummary.shiftCount}) | {" "}
                                    {this.sharedUtils.thousandsSeparator(nominatedLateAdditionalSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedLateAdditionalSummary.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed: {this.sharedUtils.thousandsSeparator(nominatedLateAdditionalSummary.totalQueriedCost)})</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedLateAdditionalSummary.shiftDetails.length > 0 &&
                                    nominatedLateAdditionalSummary.shiftDetails.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <CleaningApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    cardUIType="additional"
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedLateAdditionalSummary.shiftDetails.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={this.setCost}
                                                    cardSection="lateAdditionalSummary"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}

                    {nominatedAdjustmentSummary && nominatedAdjustmentSummary.adjustmentShiftList && nominatedAdjustmentSummary.adjustmentShiftList.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections} Adjustments ({nominatedAdjustmentSummary.shiftCount}) | {" "}
                                    {this.sharedUtils.thousandsSeparator(nominatedAdjustmentSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedAdjustmentSummary.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed: {this.sharedUtils.thousandsSeparator(nominatedLateWeeklyCleaningSummary.totalQueriedCost)})</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedAdjustmentSummary.adjustmentShiftList.length > 0 &&
                                    nominatedAdjustmentSummary.adjustmentShiftList.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <FinancialAdjustmentCard
                                                    type="cleaner"
                                                    outstandingCardData={cardItem}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedAdjustmentSummary.adjustmentShiftList.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                    setCost={this.setCost}
                                                    cardSection="financialAdjustment"
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                    {nominatedDisputedItems && nominatedDisputedItems.adjustmentShiftList && nominatedDisputedItems.adjustmentShiftList.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>{headerOfSections} Items Disputed ({nominatedDisputedItems.shiftCount}) | {" "}
                                    {this.sharedUtils.thousandsSeparator(nominatedDisputedItems.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {nominatedDisputedItems.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed: {nominatedDisputedItems.totalQueriedHours} | {" "} {this.sharedUtils.thousandsSeparator(nominatedDisputedItems.totalQueriedCost)})</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {nominatedDisputedItems.adjustmentShiftList.length > 0 &&
                                    nominatedDisputedItems.adjustmentShiftList.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <CleaningApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    cardUIType="weekly"
                                                    renderCards={this.filterCardList}
                                                    totalNumber={nominatedDisputedItems.adjustmentShiftList.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={this.setCost}
                                                    cardSection="disputedItems"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}

                </div>
            </div>
        );
    }

    renderEntertainmentAccordionSectionData(
        entertainmentCssClass: any,
        entSectionDataObj: postApprovalData,
        count: number,
        totalNonQueriedCost: string,
        totalNonQueriedMinutes: string,
        totalQueriedCost: string,
        totalQueriedMinutes: string
    ) {
        var postApprovalEnteratinmentCards: postApprovalData["postApprovalEntertainmentCards"];
        var onTimeEntertainmentSummary: postApprovalData["postApprovalEntertainmentCards"]["onTimeEntertainmentSummary"];
        var lateEntertainmentSummary: postApprovalData["postApprovalEntertainmentCards"]["lateEntertainmentSummary"];
        var adjustmentSummary: postApprovalData["postApprovalEntertainmentCards"]["adjustmentSummary"];
        var disputedItems: postApprovalData["postApprovalEntertainmentCards"]["disputedItems"];
        var onTimeEntertainmentSummaryBreakDown: any[] = [];
        postApprovalEnteratinmentCards = entSectionDataObj.postApprovalEntertainmentCards;
        onTimeEntertainmentSummary = postApprovalEnteratinmentCards.onTimeEntertainmentSummary;
        lateEntertainmentSummary = postApprovalEnteratinmentCards.lateEntertainmentSummary;
        adjustmentSummary = postApprovalEnteratinmentCards.adjustmentSummary;
        disputedItems = postApprovalEnteratinmentCards.disputedItems;
        onTimeEntertainmentSummaryBreakDown = onTimeEntertainmentSummary.summaryBreakdown;
        var disableApproveAllBtn: boolean = false;
        var idOfAccordionSection: string = `approvals-entertainment-section${count}`;
        var onTimeEntertainmentSummaryShiftList: any[] = [];
        var lateEntertainmentSummaryShiftList: any[] = [];
        var adjustmentSummaryShiftList: any[] = [];
        var disputedItemsShiftsList: any[] = [];
        onTimeEntertainmentSummaryBreakDown && onTimeEntertainmentSummaryBreakDown.length > 0 &&
            onTimeEntertainmentSummaryBreakDown.map((item: any) => {
                item.shiftList.length > 0 &&
                    item.shiftList.map((billableItem: any) => {
                        onTimeEntertainmentSummaryShiftList.push(
                            this.utils.convertBillableItemResponeToFormData(billableItem)
                        );
                    });
            });
        lateEntertainmentSummary && lateEntertainmentSummary.shiftDetails && lateEntertainmentSummary.shiftDetails.length > 0 &&
            lateEntertainmentSummary.shiftDetails.map((item: any) => {
                lateEntertainmentSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        adjustmentSummary && adjustmentSummary.adjustmentShiftList && adjustmentSummary.adjustmentShiftList.length > 0 &&
            adjustmentSummary.adjustmentShiftList.map((item: any) => {
                adjustmentSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        disputedItems && disputedItems.adjustmentShiftList && disputedItems.adjustmentShiftList.length > 0 &&
            disputedItems.adjustmentShiftList.map((item: any) => {
                disputedItemsShiftsList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        var approvalbillableItem: BillableItemSubmitRequest[] = [];
        onTimeEntertainmentSummaryShiftList && onTimeEntertainmentSummaryShiftList.length > 0 &&
            onTimeEntertainmentSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });
        lateEntertainmentSummaryShiftList && lateEntertainmentSummaryShiftList.length > 0 &&
            lateEntertainmentSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });
        adjustmentSummaryShiftList && adjustmentSummaryShiftList.length > 0 &&
            adjustmentSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });
        disputedItemsShiftsList && disputedItemsShiftsList.length > 0 &&
            disputedItemsShiftsList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });

        if (approvalbillableItem.length == 0) {
            disableApproveAllBtn = true;
        }
        return (
            <div
                id={idOfAccordionSection}
                className={entertainmentCssClass.sectionClass}
                aria-labelledby="approvalsHeadingEntertainment"
                data-parent="#approvalsAccordion"
            >
                <br />
                <div className="col-md-5 col-xl-3 grid-info__button-container" style={{ width: "310px" }}>
                    <button
                        className="btn--link btn btn-primary btn--large btn--with-icon"
                        onClick={() => this.handleApproveAll(approvalbillableItem)}
                        disabled={disableApproveAllBtn || this.state.isApproveAll}
                    >
                        Approve All
                    </button>
                </div>
                <br />
                <div className="mt-2">
                    {onTimeEntertainmentSummaryBreakDown && onTimeEntertainmentSummaryBreakDown.length > 0 &&
                        onTimeEntertainmentSummaryBreakDown.map((item: any) => {
                            return (
                                <>
                                    <h4 className="ml-2">
                                        <span> {item.date} ({item.shiftCount}) | {this.sharedUtils.thousandsSeparator(item.totalNonQueriedCost)} </span>
                                        <span>{" "}</span>
                                        {item.totalQueriedCost ?
                                            <span className="text-white-50">(Items Disputed:  {this.sharedUtils.thousandsSeparator(item.totalQueriedCost)} )</span> : null}
                                    </h4>
                                    <div className="row mt-2">
                                        {item.shiftList.length > 0 &&
                                            item.shiftList.map((cardItem: BillableItemResponse, key: number) => {
                                                return (
                                                    <div
                                                        className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                        key={cardItem ? cardItem.billableItemId : 0}
                                                    >
                                                        <EntertainmentApprovalCard
                                                            isApproveAll={this.state.isApproveAll}
                                                            outstandingCardData={cardItem}
                                                            configuarationValueIndex={
                                                                this.state.configuarationValueIndex
                                                            }
                                                            renderCards={this.filterCardList}
                                                            totalNumber={item.shiftList.length}
                                                            serialNumber={key + 1}
                                                            configurationValueArray={this.state.configurationValueArray}
                                                            setCost={this.setCost}
                                                            cardSection="onTimeEntertainmentSummary"
                                                            accordionNo={count}
                                                            totalNonQueriedCost={totalNonQueriedCost}
                                                            totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                            totalQueriedCost={totalQueriedCost}
                                                            totalQueriedMinutes={totalQueriedMinutes}
                                                        />
                                                    </div>
                                                );
                                            })}{" "}
                                    </div>
                                </>
                            );
                        })}

                    {lateEntertainmentSummary && lateEntertainmentSummary.shiftDetails && lateEntertainmentSummary.shiftDetails.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>Late Entertainment({lateEntertainmentSummary.shiftCount}) |{" "}
                                    {this.sharedUtils.thousandsSeparator(lateEntertainmentSummary.totalNonQueriedCost)} </span>
                                <span>{" "}</span>
                                {lateEntertainmentSummary.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed:  {this.sharedUtils.thousandsSeparator(lateEntertainmentSummary.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {lateEntertainmentSummary.shiftDetails.length > 0 &&
                                    lateEntertainmentSummary.shiftDetails.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <EntertainmentApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={lateEntertainmentSummary.shiftDetails.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={this.setCost}
                                                    cardSection="lateEntertainmentSummary"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}

                    {adjustmentSummary && adjustmentSummary.adjustmentShiftList && adjustmentSummary.adjustmentShiftList.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>Adjustments ({adjustmentSummary.shiftCount})  | {" "}
                                    {this.sharedUtils.thousandsSeparator(adjustmentSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {adjustmentSummary.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed:  {this.sharedUtils.thousandsSeparator(adjustmentSummary.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {adjustmentSummary.adjustmentShiftList.length > 0 &&
                                    adjustmentSummary.adjustmentShiftList.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <FinancialAdjustmentCard
                                                    type="entertainer"
                                                    outstandingCardData={cardItem}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={adjustmentSummary.adjustmentShiftList.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                    setCost={this.setCost}
                                                    cardSection="financialAdjustment"
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                    {disputedItems && disputedItems.adjustmentShiftList && disputedItems.adjustmentShiftList.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>Items Disputed ({disputedItems.shiftCount})  | {" "}
                                    {this.sharedUtils.thousandsSeparator(disputedItems.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {disputedItems.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed:  {this.sharedUtils.thousandsSeparator(disputedItems.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {disputedItems.adjustmentShiftList.length > 0 &&
                                    disputedItems.adjustmentShiftList.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <EntertainmentApprovalCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={disputedItems.adjustmentShiftList.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={this.setCost}
                                                    cardSection="disputedItems"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    }

    renderOtherAccordionSectionData(
        otherCssClass: any,
        otherSectionDataObj: postApprovalData,
        count: number,
        totalNonQueriedCost: string,
        totalNonQueriedMinutes: string,
        totalQueriedCost: string,
        totalQueriedMinutes: string
    ) {
        var postApprovalEnteratinmentCards: postApprovalData["postApprovalOtherCards"];
        var lateEntertainmentSummary: postApprovalData["postApprovalOtherCards"]["lateEntertainmentSummary"];
        var adjustmentSummary: postApprovalData["postApprovalOtherCards"]["adjustmentSummary"];
        var disputedItems: postApprovalData["postApprovalOtherCards"]["disputedItems"];
        postApprovalEnteratinmentCards = otherSectionDataObj.postApprovalOtherCards;
        lateEntertainmentSummary = postApprovalEnteratinmentCards.lateEntertainmentSummary;
        adjustmentSummary = postApprovalEnteratinmentCards.adjustmentSummary;
        disputedItems = postApprovalEnteratinmentCards.disputedItems;
        var disableApproveAllBtn: boolean = false;
        var idOfAccordionSection: string = `approvals-other-section${count}`;
        var onTimeEntertainmentSummaryShiftList: any[] = [];
        var lateEntertainmentSummaryShiftList: any[] = [];
        var adjustmentSummaryShiftList: any[] = [];
        var disputedItemsShiftList: any[] = [];
        lateEntertainmentSummary && lateEntertainmentSummary.shiftDetails && lateEntertainmentSummary.shiftDetails.length > 0 &&
            lateEntertainmentSummary.shiftDetails.map((item: any) => {
                lateEntertainmentSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        adjustmentSummary && adjustmentSummary.adjustmentShiftList && adjustmentSummary.adjustmentShiftList.length > 0 &&
            adjustmentSummary.adjustmentShiftList.map((item: any) => {
                adjustmentSummaryShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        disputedItems && disputedItems.adjustmentShiftList && disputedItems.adjustmentShiftList.length > 0 &&
            disputedItems.adjustmentShiftList.map((item: any) => {
                disputedItemsShiftList.push(this.utils.convertBillableItemResponeToFormData(item));
            });
        var approvalbillableItem: BillableItemSubmitRequest[] = [];

        lateEntertainmentSummaryShiftList && lateEntertainmentSummaryShiftList.length > 0 &&
            lateEntertainmentSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                var payDate = this.sharedUtils.convertStringToDate(item.pay_Date);
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });
        adjustmentSummaryShiftList && adjustmentSummaryShiftList.length > 0 &&
            adjustmentSummaryShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });

        disputedItemsShiftList && disputedItemsShiftList.length > 0 &&
            disputedItemsShiftList.map((item: any) => {
                var isAmend: boolean = false;
                if ((item && item.itemTypeId == itemTypes.Original) || item.itemTypeId == "") {
                    if (item.equipmentBillableQuantity - item.equipmentScheduleQuantity != 0) {
                        isAmend = true;
                    }
                }
                if (
                    (!item.isAccrual && !item.invoiceId && !item.approval_Date) ||
                    (item.isAccrual && !item.approval_Date && !item.paymentId)
                ) {
                    approvalbillableItem.push(
                        this.utils.convertFormDataToBillableItemRequest(
                            item,
                            Status.Approved,
                            false,
                            null,
                            new Date(),
                            "",
                            "",
                            isAmend,
                            "",
                            "ApprovalCard"
                        )
                    );
                }
            });



        if (approvalbillableItem.length == 0) {
            disableApproveAllBtn = true;
        }
        return (
            <div
                id={idOfAccordionSection}
                className={otherCssClass.sectionClass}
                aria-labelledby="approvalsHeadingOther"
                data-parent="#approvalsAccordion"
            >
                <br />
                <div className="col-md-5 col-xl-3 grid-info__button-container" style={{ width: "310px" }}>
                    <button
                        className="btn--link btn btn-primary btn--large btn--with-icon"
                        onClick={() => this.handleApproveAll(approvalbillableItem)}
                        disabled={disableApproveAllBtn || this.state.isApproveAll}
                    >
                        Approve All
                    </button>
                </div>
                <br />
                <div className="mt-2">
                    {lateEntertainmentSummary && lateEntertainmentSummary.shiftDetails && lateEntertainmentSummary.shiftDetails.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>Work Performed ({lateEntertainmentSummary.shiftCount})|{" "}
                                    {this.sharedUtils.thousandsSeparator(lateEntertainmentSummary.totalNonQueriedCost)} </span>
                                <span>{" "}</span>
                                {lateEntertainmentSummary.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed:  {this.sharedUtils.thousandsSeparator(lateEntertainmentSummary.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {lateEntertainmentSummary.shiftDetails.length > 0 &&
                                    lateEntertainmentSummary.shiftDetails.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >

                                                <OtherServicesCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    cardUIType=""
                                                    renderCards={this.filterCardList}
                                                    totalNumber={lateEntertainmentSummary.shiftDetails.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={this.setCost}
                                                    cardSection="lateEntertainmentSummary"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}

                    {adjustmentSummary && adjustmentSummary.adjustmentShiftList && adjustmentSummary.adjustmentShiftList.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>Adjustments ({adjustmentSummary.shiftCount}) | {" "}
                                    {this.sharedUtils.thousandsSeparator(adjustmentSummary.totalNonQueriedCost)}</span>
                                <span>{" "}</span>
                                {adjustmentSummary.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed:  {this.sharedUtils.thousandsSeparator(adjustmentSummary.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {adjustmentSummary.adjustmentShiftList.length > 0 &&
                                    adjustmentSummary.adjustmentShiftList.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >
                                                <FinancialAdjustmentCard
                                                    type="otherService"
                                                    outstandingCardData={cardItem}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={adjustmentSummary.adjustmentShiftList.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                    setCost={this.setCost}
                                                    cardSection="financialAdjustment"
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                    {disputedItems && disputedItems.adjustmentShiftList && disputedItems.adjustmentShiftList.length > 0 ? (
                        <>
                            <h4 className="ml-2">
                                <span>Items Disputed({disputedItems.shiftCount})|{" "}
                                    {this.sharedUtils.thousandsSeparator(disputedItems.totalNonQueriedCost)} </span>
                                <span>{" "}</span>
                                {disputedItems.totalQueriedCost ?
                                    <span className="text-white-50">(Items Disputed:  {this.sharedUtils.thousandsSeparator(disputedItems.totalQueriedCost)} )</span> : null}
                            </h4>
                            <div className="row mt-2">
                                {disputedItems.adjustmentShiftList.length > 0 &&
                                    disputedItems.adjustmentShiftList.map((cardItem: any, key: number) => {
                                        return (
                                            <div
                                                className="col-12 col-xl-4 mb-4 mt-3 px-3"
                                                key={cardItem ? cardItem.billableItemId : 0}
                                            >

                                                <OtherServicesCard
                                                    isApproveAll={this.state.isApproveAll}
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    cardUIType=""
                                                    renderCards={this.filterCardList}
                                                    totalNumber={disputedItems.adjustmentShiftList.length}
                                                    serialNumber={key + 1}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={this.setCost}
                                                    cardSection="disputedItems"
                                                    accordionNo={count}
                                                    totalNonQueriedCost={totalNonQueriedCost}
                                                    totalNonQueriedMinutes={totalNonQueriedMinutes}
                                                    totalQueriedCost={totalQueriedCost}
                                                    totalQueriedMinutes={totalQueriedMinutes}
                                                />
                                            </div>
                                        );
                                    })}{" "}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    }

    renderSecurityCards(
        serviceType: string,
        dateFrom: string,
        dateTo: string,
        totalNonQueriedMinutes: string,
        totalNonQueriedCost: string,
        totalQueriedMinutes: string,
        totalQueriedCost: string,
        shiftCount: string,
        doorSupervisionCssClass: any,
        securitySectionDataObj: postApprovalData,
        count: number
    ) {
        var dataTarget: string = `#approvals-security-section${count}`;
        var ariaControls: string = `approvals-security-section${count}`;
        var summaryDetails: string = "";
        var queryDetails: string = "";
        if (serviceType && dateFrom && dateTo && totalNonQueriedCost && totalNonQueriedMinutes && shiftCount) {
            summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${dateFrom} - ${dateTo} | ${totalNonQueriedMinutes} | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
        }
        else if (serviceType && totalNonQueriedMinutes && totalNonQueriedCost && shiftCount) {
            summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${totalNonQueriedMinutes} | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
        }
        if (totalQueriedCost && totalQueriedMinutes) {
            queryDetails = `(Items Disputed: ${totalQueriedMinutes} | ${this.sharedUtils.thousandsSeparator(totalQueriedCost)})`;
        }
        return (
            <div className="card-accordion__section--security">
                <h4 id="approvalsHeadingSecurity">
                    <button
                        className={doorSupervisionCssClass.buttonClass}
                        type="button"
                        data-toggle="collapse"
                        data-target={dataTarget}
                        aria-expanded="false"
                        aria-controls={ariaControls}
                    >
                        <b>
                            <span>{summaryDetails}</span>
                            <span>{" "}</span>
                            <span className="text-white-50">{queryDetails}</span>
                        </b>

                        <span className="card-accordion__icon">
                            <FontAwesomeIcon icon={faChevronUp} />
                        </span>
                    </button>
                </h4>
                {this.renderSecurityAccordionSectionData(doorSupervisionCssClass, securitySectionDataObj, count, totalNonQueriedCost, totalNonQueriedMinutes, totalQueriedCost, totalQueriedMinutes)}
            </div>
        );
    }

    renderCleaningCards(
        serviceType: string,
        dateFrom: string,
        dateTo: string,
        totalNonQueriedMinutes: string,
        totalNonQueriedCost: string,
        totalQueriedMinutes: string,
        totalQueriedCost: string,
        shiftCount: string,
        cleaningCssClass: any,
        cleaningDataObj: postApprovalData,
        count: number
    ) {
        var dataTarget: string = `#approvals-cleaning-section${count}`;
        var ariaControls: string = `approvals-cleaning-section${count}`;
        var summaryDetails: string = "";
        var queryDetails: string = "";
        if (serviceType && dateFrom && dateTo && totalNonQueriedCost && totalNonQueriedMinutes && shiftCount) {
            summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${dateFrom} - ${dateTo} | ${totalNonQueriedMinutes} | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
        }
        else if (serviceType && totalNonQueriedMinutes && totalNonQueriedCost && shiftCount) {
            summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${totalNonQueriedMinutes} | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
        }
        if (totalQueriedCost && totalQueriedMinutes) {
            queryDetails = `(Items Disputed: ${totalQueriedMinutes} | ${this.sharedUtils.thousandsSeparator(totalQueriedCost)})`;
        }
        return (
            <div className="card-accordion__section--cleaning">
                <h4 id="approvalsHeadingCleaning">
                    <button
                        className={cleaningCssClass.buttonClass}
                        type="button"
                        data-toggle="collapse"
                        data-target={dataTarget}
                        aria-expanded="false"
                        aria-controls={ariaControls}
                    >
                        <b>  <span>{summaryDetails}</span>
                            <span>{" "}</span>
                            <span className="text-white-50">{queryDetails}</span>
                        </b>

                        <span className="card-accordion__icon">
                            <FontAwesomeIcon icon={faChevronUp} />
                        </span>
                    </button>
                </h4>
                {this.renderCleaningAccordionSectionData(cleaningCssClass, cleaningDataObj, count, totalNonQueriedCost, totalNonQueriedMinutes, totalQueriedCost, totalQueriedMinutes)}
            </div>
        );
    }

    renderEntertainmentCards(
        serviceType: string,
        dateFrom: string,
        dateTo: string,
        totalNonQueriedMinutes: string,
        totalNonQueriedCost: string,
        totalQueriedMinutes: string,
        totalQueriedCost: string,
        shiftCount: string,
        entertainmentCssClass: any,
        entertainmentSectionDataObj: postApprovalData,
        count: number
    ) {
        var dataTarget: string = `#approvals-entertainment-section${count}`;
        var ariaControls: string = `approvals-entertainment-section${count}`;
        var summaryDetails: string = "";
        var queryDetails: string = "";
        if (serviceType && dateFrom && dateTo && totalNonQueriedCost && totalNonQueriedMinutes && shiftCount) {
            if (serviceType != ServiceType.Security || serviceType != ServiceType.ContractCleaning) {
                summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${dateFrom} - ${dateTo} | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
            }
            else {
                summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${dateFrom} - ${dateTo} | ${totalNonQueriedMinutes} | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
            }
        }
        else if (serviceType && totalNonQueriedMinutes && totalNonQueriedCost && shiftCount) {
            if (serviceType != ServiceType.Security || serviceType != ServiceType.ContractCleaning) {
                summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
            }
            else {
                summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${totalNonQueriedMinutes} | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
            }

        }
        if (totalQueriedCost && totalQueriedMinutes) {
            if (serviceType != ServiceType.Security || serviceType != ServiceType.ContractCleaning) {
                queryDetails = `(Items Disputed: ${this.sharedUtils.thousandsSeparator(totalQueriedCost)})`;
            }
            else {
                queryDetails = `(Items Disputed: ${totalQueriedMinutes} | ${this.sharedUtils.thousandsSeparator(totalQueriedCost)})`;
            }
        }
        return (
            <div className="card-accordion__section--entertainment">
                <h4 id="approvalsHeadingEntertainment">
                    <button
                        className={entertainmentCssClass.buttonClass}
                        type="button"
                        data-toggle="collapse"
                        data-target={dataTarget}
                        aria-expanded="false"
                        aria-controls={ariaControls}
                    >
                        <b>  <span>{summaryDetails}</span>
                            <span>{" "}</span>
                            <span className="text-white-50">{queryDetails}</span></b>

                        <span className="card-accordion__icon">
                            <FontAwesomeIcon icon={faChevronUp} />
                        </span>
                    </button>
                </h4>
                {this.renderEntertainmentAccordionSectionData(
                    entertainmentCssClass,
                    entertainmentSectionDataObj,
                    count,
                    totalNonQueriedCost,
                    totalNonQueriedMinutes,
                    totalQueriedCost,
                    totalQueriedMinutes
                )}
            </div>
        );
    }

    renderotherServicesCards(
        serviceType: string,
        dateFrom: string,
        dateTo: string,
        totalNonQueriedMinutes: string,
        totalNonQueriedCost: string,
        totalQueriedMinutes: string,
        totalQueriedCost: string,
        shiftCount: string,
        otherCssClass: any,
        otherSectionDataObj: postApprovalData,
        count: number
    ) {
        var dataTarget: string = `#approvals-other-section${count}`;
        var ariaControls: string = `approvals-other-section${count}`;
        var summaryDetails: string = "";
        var queryDetails: string = "";
        if (serviceType && dateFrom && dateTo && totalNonQueriedCost && shiftCount) {
            summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${dateFrom} - ${dateTo} | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
        }
        else if (serviceType && totalNonQueriedCost && shiftCount) {
            summaryDetails = `${serviceType}${" "}(${shiftCount}) | ${this.sharedUtils.thousandsSeparator(totalNonQueriedCost)}`;
        }
        if (totalQueriedCost) {
            queryDetails = `(Items Disputed:  ${this.sharedUtils.thousandsSeparator(totalQueriedCost)})`;
        }
        return (
            <div className="card-accordion__section--other">
                <h4 id="approvalsHeadingOther">
                    <button
                        className={otherCssClass.buttonClass}
                        type="button"
                        data-toggle="collapse"
                        data-target={dataTarget}
                        aria-expanded="false"
                        aria-controls={ariaControls}
                    >
                        <b>   <span>{summaryDetails}</span>
                            <span>{" "}</span>
                            <span className="text-white-50">{queryDetails}</span></b>

                        <span className="card-accordion__icon">
                            <FontAwesomeIcon icon={faChevronUp} />
                        </span>
                    </button>
                </h4>
                {this.renderOtherAccordionSectionData(
                    otherCssClass,
                    otherSectionDataObj,
                    count,
                    totalNonQueriedCost,
                    totalNonQueriedMinutes,
                    totalQueriedCost,
                    totalQueriedMinutes
                )}
            </div>
        );
    }


    renderCardSections() {
        var doorSupervisionCssClass = this.cardListUtilities.getAccordionCssClass(ServiceNames.Security, 0, 0, 0, 0, 0);
        var contractCleaningCssClass = this.cardListUtilities.getAccordionCssClass(
            ServiceNames.ContractCleaing,
            0,
            0,
            0,
            0,
            0
        );
        var entertainmentCssClass = this.cardListUtilities.getAccordionCssClass(ServiceNames.Entertainment, 0, 0, 0, 0, 0);
        var otherServiceCssClass = this.cardListUtilities.getAccordionCssClass(ServiceNames.Other, 0, 0, 0, 0, 0)
        var accordionSectionData: postApprovalData[] = [];
        accordionSectionData = JSON.parse(JSON.stringify(this.state.postApprovalData));
        var securitySectionData =
            accordionSectionData.length > 0
                ? accordionSectionData.filter((x) => x.serviceId == parseInt(ServiceType.Security))
                : [];
        var cleaningSectionData =
            accordionSectionData.length > 0
                ? accordionSectionData.filter((x) => x.serviceId == parseInt(ServiceType.ContractCleaning))
                : [];

        var entertainmentSectionData =
            accordionSectionData.length > 0
                ? accordionSectionData.filter((x) => x.serviceId == parseInt(ServiceType.Entertainment))
                : [];

        var gardeningSectionData =
            accordionSectionData.length > 0
                ? accordionSectionData.filter((x) => x.serviceId == parseInt(ServiceType.Gardening))
                : [];

        var windowCleaningSectionData =
            accordionSectionData.length > 0
                ? accordionSectionData.filter((x) => x.serviceId == parseInt(ServiceType.WindowCleaning))
                : [];

        var miscSectionData =
            accordionSectionData.length > 0
                ? accordionSectionData.filter((x) => x.serviceId == parseInt(ServiceType.Miscelleanous))
                : [];

        var maintananceSectionData =
            accordionSectionData.length > 0
                ? accordionSectionData.filter((x) => x.serviceId == parseInt(ServiceType.Maintenance))
                : [];
        return (
            <div className="accordion" id="approvalsAccordion">
                {securitySectionData.length > 0 &&
                    securitySectionData.map((item: any, key: number) => {
                        return this.renderSecurityCards(
                            "Door Supervision",
                            item.dateFrom,
                            item.dateTo,
                            item.totalNonQueriedMinutes,
                            item.totalNonQueriedCost,
                            item.totalQueriedMinutes,
                            item.totalQueriedCost,
                            item.shiftCount,
                            doorSupervisionCssClass,
                            item,
                            key
                        );
                    })}
                {cleaningSectionData.length > 0 &&
                    cleaningSectionData.map((item: any, key: number) => {
                        return this.renderCleaningCards(
                            "Contract Cleaning",
                            item.dateFrom,
                            item.dateTo,
                            item.totalNonQueriedMinutes,
                            item.totalNonQueriedCost,
                            item.totalQueriedMinutes,
                            item.totalQueriedCost,
                            item.shiftCount,
                            contractCleaningCssClass,
                            item,
                            key
                        );
                    })}
                {entertainmentSectionData.length > 0 &&
                    entertainmentSectionData.map((item: any, key: number) => {
                        return this.renderEntertainmentCards(
                            "Entertainment",
                            item.dateFrom,
                            item.dateTo,
                            item.totalNonQueriedMinutes,
                            item.totalNonQueriedCost,
                            item.totalQueriedMinutes,
                            item.totalQueriedCost,
                            item.shiftCount,
                            entertainmentCssClass,
                            item,
                            key
                        );
                    })}

                {gardeningSectionData.length > 0 &&
                    gardeningSectionData.map((item: any, key: number) => {
                        return this.renderotherServicesCards(
                            "Gardening",
                            item.dateFrom,
                            item.dateTo,
                            item.totalNonQueriedMinutes,
                            item.totalNonQueriedCost,
                            item.totalQueriedMinutes,
                            item.totalQueriedCost,
                            item.shiftCount,
                            otherServiceCssClass,
                            item,
                            key
                        );
                    })}

                {windowCleaningSectionData.length > 0 &&
                    windowCleaningSectionData.map((item: any, key: number) => {
                        return this.renderotherServicesCards(
                            "Window Cleaning",
                            item.dateFrom,
                            item.dateTo,
                            item.totalNonQueriedMinutes,
                            item.totalNonQueriedCost,
                            item.totalQueriedMinutes,
                            item.totalQueriedCost,
                            item.shiftCount,
                            otherServiceCssClass,
                            item,
                            key
                        );
                    })}

                {maintananceSectionData.length > 0 &&
                    maintananceSectionData.map((item: any, key: number) => {
                        return this.renderotherServicesCards(
                            "Maintenance",
                            item.dateFrom,
                            item.dateTo,
                            item.totalNonQueriedMinutes,
                            item.totalNonQueriedCost,
                            item.totalQueriedMinutes,
                            item.totalQueriedCost,
                            item.shiftCount,
                            otherServiceCssClass,
                            item,
                            key
                        );
                    })}
                {miscSectionData.length > 0 &&
                    miscSectionData.map((item: any, key: number) => {
                        return this.renderotherServicesCards(
                            "Miscellaneous",
                            item.dateFrom,
                            item.dateTo,
                            item.totalNonQueriedMinutes,
                            item.totalNonQueriedCost,
                            item.totalQueriedMinutes,
                            item.totalQueriedCost,
                            item.shiftCount,
                            otherServiceCssClass,
                            item,
                            key
                        );
                    })}

            </div>
        );
    }

    renderNoDataMessageSection() {
        return (
            <div className="accent-container text-center py-5">
                {this.state.noDataMessage}
            </div>
        )
    }

    render() {
        return this.state.showProgressIndicator ? (
            <div className="row col-lg-12">
                <span className="col-lg-12 text-center">
                    <LoadIndicator
                        id="simple-grid-indicator"
                        height={100}
                        width={100}
                        visible={this.state.showProgressIndicator}
                    />
                </span>
            </div>
        ) : this.state.noDataMessage ?
            <>{this.renderNoDataMessageSection()}</>
            : (
                <>
                    {this.renderCardSections()}
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                </>
            );
    }
}

export default PostApprovalCardList;
