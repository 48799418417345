import { faHome, faClock, faCheckCircle, faCoins, faCog } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ServiceType } from "../../common/ServiceUtilities";
import { closeDrawer, viewSimpleShiftsForService } from "./NavigationUtils";

export const renderArtistMainNavigationItems = (): ReactNode => {
    return (
        <ul className="nav-drawer__quick-links navbar-nav">
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link className="nav-link" onClick={closeDrawer} to="/">
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faHome} />
                    </span>
                    Home <span className="sr-only">(current)</span>
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link className="nav-link" onClick={closeDrawer} to="/myGigList">
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faClock} />
                    </span>
                    My Gigs
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link
                    className="nav-link"
                    onClick={closeDrawer}
                    //map PBI 4312 component path here
                    to="/gigCardList"
                >
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    Gig List
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link
                    className="nav-link"
                    onClick={() => viewSimpleShiftsForService(ServiceType.Entertainment)}
                    //map PBI 4321 component path here
                    to={{
                        pathname: "/ProviderPayments",
                        state: {
                            burgerMenuName: "ArtistPayment",
                        },
                    }}
                >
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCoins} />
                    </span>
                    Payments
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link
                    className="nav-link"
                    onClick={closeDrawer}
                    //map PBI 4349 component path here
                    to={{
                        pathname: "/artistProfile",
                        state: {
                            isFromBurgerMenu: true,
                        },
                    }}
                >
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faCog} />
                    </span>
                    Profile
                </Link>
            </li>
        </ul>
    );
}
