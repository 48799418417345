import React, { MouseEvent, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

interface ChildFilterItemProps {
    item: any;
    selectFilterType: (listItem: any, selection: boolean,isEnter:boolean) => void;
    parentNodeselected: boolean;
    selected: boolean;
}

interface ChildFilterItemState {
    selected: boolean;
    listItem: any;
}

class ChildFilterItem extends React.Component<ChildFilterItemProps> {
    state: ChildFilterItemState;
    constructor(props: ChildFilterItemProps) {
        super(props);
        this.state = {
            selected: this.props.selected,
            listItem: this.props.item,
        };
        this.toggleSelected = this.toggleSelected.bind(this);
    }

    toggleSelected(e:any) {
        let iskeyDown = e.type && e.type === 'keydown' ? true:false;
        let isEnter = this.checkIsEnter(e);
        if(iskeyDown && !isEnter){
            return;
        }
        this.setState(
            (prevState: ChildFilterItemState) => ({
                // selected: !prevState.selected,
                // selected: !isEnter? !prevState.selected : prevState.selected,
                selected: isEnter? prevState.selected : !prevState.selected,
            }),
            () => {
                this.props.selectFilterType(this.state.listItem, this.state.selected,isEnter)
            }
        );
        // TODO allow parents to select children, may need to expose this function
    }

    checkIsEnter = (e: any) => {
        let isEnter = false;
        if (e.type && e.type === 'keydown') {
            e.preventDefault();
            if (e.key && e.key === 'Enter')
                isEnter = true;
        }
        return isEnter;
    }

    componentDidUpdate = (prevprops: ChildFilterItemProps, prevState: ChildFilterItemState) => {
        if (
            this.props.parentNodeselected != prevprops.parentNodeselected ||
            this.props.selected != prevprops.selected
        ) {
            if ((prevState.selected || !prevState.selected) && this.props.parentNodeselected) {
                this.setState({
                    selected: this.props.selected,
                });
            }
        }
    };

    render() {
        return (
            <button type='button'
                className={`filter-item ${this.state.selected ? "filter-item--selected" : ""}`}
                onClick={this.toggleSelected}
                onKeyDown={this.toggleSelected}
            >
                <span className="filter-item__icon">
                    <FontAwesomeIcon icon={faCheck} />
                </span>
                {this.state.listItem.value}
            </button>
        );
    }
}

export default ChildFilterItem;
