import React from "react";
import GigRequestPage from "./GigRequestPage";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";

interface GigRequestPageContainerProps extends RouteProps, withValidUserRoleProps {
    getBuild: (flag: boolean) => void; 
}

const GigRequestPageContainer = (props: GigRequestPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    const getBuild = (flag: boolean) => {
        props.getBuild(flag);
    };
    
    const searchparam = props.location ? new URLSearchParams(props.location.search.toLowerCase()) : null;
    let incidentId: any;
    if (props.location && props.location.search) {
        incidentId = searchparam ? searchparam.get("filterby") : "";
    }
    return <GigRequestPage title={getTitle()} category={getType()} getBuild={getBuild} incidentId={incidentId}/>;
};

export default GigRequestPageContainer;
