import GlobalService from "../../services/GlobalService";
import sharedUtils from "../grid/sharedUtilities";

export const closeDrawer = () => {
    let drawer = document.getElementById("navbarNavDrawer");
    if (drawer) {
        drawer.classList.remove("nav-drawer--open");
        drawer.setAttribute("aria-expanded", "false");
    }
};

export const viewGigReq = (from: string) => {
    GlobalService.removegigFilterValues();
    GlobalService.setGigComingFrom(from);
    closeDrawer();
}

export const viewShiftImport = (from: string) => {
    GlobalService.setGigComingFrom(from);
    closeDrawer();
}

//Helper function to navigate to the artist grid page by storing the appropriate service type id's into session storage.
export const viewArtistGrid = (from: string) => {
    GlobalService.setArtistGridComingFrom(from);
    closeDrawer();
};

export const viewQueryReq = () => {
    GlobalService.removequeryFilterValues();
    closeDrawer();
}

//Helper function to navigate to the Simple shifts page by storing the appropriate service type id's into session storage.
export const viewSimpleShiftsForService = (serviceTypeId: string) => {
    sessionStorage.setItem("serviceTypeFilter", serviceTypeId);
    closeDrawer();
};

//Helper function that will set the service type in the Manager global filter service for the cost report Grid.
export const viewCostReportForService = (serviceTypeId: string) => {
    sessionStorage.setItem("serviceTypeFilter", serviceTypeId);
    closeDrawer();
};

//Helper function that will set the service type in the Manager global filter service for the cost report Grid.
export const viewPaymentRequestForService = (serviceTypeId: string) => {
    sessionStorage.setItem("serviceTypeFilter", serviceTypeId);
    closeDrawer();
};

export const viewPaymentReqForServiceType = (serviceTypeId: string , paymentTypeId : string) => {
    sessionStorage.setItem("serviceTypeFilter", serviceTypeId);
    sessionStorage.setItem("paymentTypeFilter", paymentTypeId);
    closeDrawer();
}

export const onClickOfManageViewShiftsForAll = () => {
    closeDrawer();
};

export const setDateForCurrentWeek = (isFromDate: boolean): string => {
    let utils = new sharedUtils();
    if (isFromDate) {
        return utils.calculateWeeklyDates(true);
    } else {
        return utils.calculateWeeklyDates(false);
    }
};