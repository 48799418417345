import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, { Pager, Paging, FilterRow, Column, SearchPanel, Scrolling,StateStoring } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import gridUtils, { currencyTypeToSymbolMatrix, currencyTypes } from "../grid/GridUtilities";
import BillingCyclesGridService, { BillingCyclesGridServiceRowItem } from "../../services/BillingCyclesGridService";
import ViewActionCell from "./ViewActionCellComponent";
import EditActionCell from "./EditActionCellComponent";
import ClientGrossActionCell from "./ClientGrossActionCellComponent";
import UserService, { RoleGroupNames } from "../../services/UserService";

//props
interface BillingCycleGridProps {
    refreshSignal: boolean;
}

// State
interface BillingCycleGridState {
    gridDataSource: BillingCyclesGridServiceRowItem[];
    showProgressIndicator: boolean;
}

// Component - displays the simple Grid for all users
//Grid_Utilities component can be reused here to format the date and Decimal fields.
class BillingCycleGrid extends React.Component<BillingCycleGridProps> {
    //Initialize the component's state.
    state: BillingCycleGridState;
    //Initialize the service to fetch the data.
    Service: BillingCyclesGridService;
    //Reuse the Grid utils component to format the cells.
    gridUtils: gridUtils;

    constructor(props: BillingCycleGridProps) {
        super(props);
        // Initialize state and services/utils
        this.Service = new BillingCyclesGridService();
        this.gridUtils = new gridUtils();
        this.state = {
            gridDataSource: [],
            showProgressIndicator: false,
        };
        // Functions
        this.updateGridDataSource = this.updateGridDataSource.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    //TODO: Call the function "updateGridDataSource" to make an API call and populate the Grid.
    componentDidMount() {
        this.updateGridDataSource();
    }
    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: BillingCycleGridProps) {
        if (this.props.refreshSignal != prevProps.refreshSignal) {
            // refresh the grid
            this.updateGridDataSource();
        }
    }

    updateGridDataSource() {
        this.setState({
            showProgressIndicator: true,
        });
        this.Service.getBillingCycleGridRows().then(this.handleSuccess).catch(this.handleError);
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            gridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    }

    handleError(error: AxiosResponse<any>) {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "Get Billing Cycle Grid rows service failed with response: " + JSON.stringify(error);

        if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }
    onRefresh = (isRefresh: boolean) => {
        if (isRefresh) {
            //setTimeout( () => {
            this.updateGridDataSource();
            //}, 15000);
        }
    }

    ClientGrossActionCellRender = (cellInfo: any) => {
        return(
            <>
                <ClientGrossActionCell data={cellInfo} onRefresh={this.onRefresh}/>
            </>        
        );
    }

    render() {
        var isSeniorManager = UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        var isEventUK =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        columnMinWidth={50}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                             <StateStoring
                                enabled={true}
                                type="localStorage" storageKey="billing_cycles_grid"
                                savingTimeout={0}
                            />
                        {isEventUK ? <Column caption="VIEW" cellComponent={ViewActionCell} /> : null}
                        {isSeniorManager ? <Column caption="EDIT" cellComponent={EditActionCell} /> : null}
                        <Column
                            dataField="id"
                            caption="ID"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.id);
                            }}
                        />
                        <Column dataField="status" caption="STATUS" />
                        <Column dataField="client" caption="CLIENT" />
                        <Column dataField="period" caption="PERIOD" />
                        <Column
                            dataField="dateFrom"
                            caption="FROM"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateFrom);
                            }}
                        />
                        <Column
                            dataField="dateTo"
                            caption="TO"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                            }}
                        />
                        <Column dataField="internalReference" caption="OUR REF" />
                        <Column dataField="invoiceReference" caption="CLIENT REF" />
                        <Column
                            dataField="invoiceDate"
                            caption="INVOICE DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.invoiceDate);
                            }}
                        />
                        <Column
                            dataField="subTotal"
                            caption="CLIENT NET"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.subTotal,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.subTotal);
                            }}
                        />
                        <Column
                            dataField="tax"
                            caption="VAT"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.tax,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.tax);
                            }}
                        />
                        <Column dataField="total" caption="CLIENT GROSS" 
                        //cellComponent={ClientGrossActionCell} 
                        cellRender={this.ClientGrossActionCellRender}
                        />

                        <Column
                            dataField="invoiceSentDate"
                            caption="INVOICE SENT DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.invoiceSentDate);
                            }}
                        />
                        <Column
                            dataField="invoiceDueDate"
                            caption="INVOICE DUE DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.invoiceDueDate);
                            }}
                        />
                        <Column
                            dataField="invoicePaidDate"
                            caption="INVOICE PAID DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.invoicePaidDate);
                            }}
                        />
                        <Column
                            dataField="invoiceAmount"
                            caption="INVOICE PAID AMOUNT"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.invoiceAmount,
                                    currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                );
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.invoiceAmount);
                            }}
                        />
                        <Column dataField="description" caption="COMMENTS" />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default BillingCycleGrid;
