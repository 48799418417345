import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { findAnArtistCycleStatus } from "../../services/FindAnArtistService";
import GlobalService from "../../services/GlobalService";

//#region props
interface FindAnArtistStatusFlowProps extends WithTranslation {
    statusId: string;
    displayLink: string;
}
//#endregion

//#region State
interface FindAnArtistStatusFlowState {
    statusId: string;
    pendingStateCssClass: string;
    publishedStateCssClass: string;
    optionsSentStateCssClass: string;
    artistConfirmationStateCssClass: string;
    venueConfirmationCssClass: string;
    bookedStateCssClass: string;
    cancelledStateCssClass: string;
    artistSelectedCssClass: string;
    artistOfferedCssClass: string;
}
//#endregion

// Component
class FindAnArtistStatusFlow extends React.Component<FindAnArtistStatusFlowProps> {
    state: FindAnArtistStatusFlowState;
    constructor(props: FindAnArtistStatusFlowProps) {
        super(props);

        this.state = {
            statusId: this.props.statusId,
            pendingStateCssClass: "",
            publishedStateCssClass: "",
            optionsSentStateCssClass: "",
            artistConfirmationStateCssClass: "",
            venueConfirmationCssClass: "",
            bookedStateCssClass: "",
            cancelledStateCssClass: "",
            artistSelectedCssClass: "",
            artistOfferedCssClass: "",
        };
    }

    updateCssClasses = () => {
        let gigType = GlobalService.getGigComingFrom();
        const standardItemCssClass = gigType !== "Entertainment" ? "status-flow__cleaning-progress-bar__item" : "status-flow__progress-bar__item";
        const highlightItemCssClass = gigType !== "Entertainment" ? "status-flow__cleaning-progress-bar__item--highlighted" : "status-flow__progress-bar__item--highlighted";
        const statusId = this.state.statusId;

        this.setState({
            pendingStateCssClass:
                statusId == findAnArtistCycleStatus.Pending ||
                    statusId == findAnArtistCycleStatus.Published ||
                    statusId == findAnArtistCycleStatus.ArtistSelected || 
                    statusId == findAnArtistCycleStatus.Offered || 
                    statusId == findAnArtistCycleStatus.OptionsSent ||
                    statusId == findAnArtistCycleStatus.ArtistConfirmation ||
                    statusId == findAnArtistCycleStatus.VenueConfirmation ||
                    statusId == findAnArtistCycleStatus.Booked
                    ? highlightItemCssClass
                    : standardItemCssClass,
            publishedStateCssClass:
                statusId == findAnArtistCycleStatus.Published ||
                    statusId == findAnArtistCycleStatus.OptionsSent ||
                    statusId == findAnArtistCycleStatus.ArtistSelected || 
                    statusId == findAnArtistCycleStatus.Offered || 
                    statusId == findAnArtistCycleStatus.ArtistConfirmation ||
                    statusId == findAnArtistCycleStatus.VenueConfirmation ||
                    statusId == findAnArtistCycleStatus.Booked
                    ? highlightItemCssClass
                    : standardItemCssClass,
            optionsSentStateCssClass:
                statusId == findAnArtistCycleStatus.OptionsSent ||
                    statusId == findAnArtistCycleStatus.ArtistConfirmation ||
                    statusId == findAnArtistCycleStatus.ArtistSelected || 
                    statusId == findAnArtistCycleStatus.Offered || 
                    statusId == findAnArtistCycleStatus.VenueConfirmation ||
                    statusId == findAnArtistCycleStatus.Booked
                    ? highlightItemCssClass
                    : standardItemCssClass,
            artistSelectedCssClass:
                statusId == findAnArtistCycleStatus.ArtistSelected || 
                    statusId == findAnArtistCycleStatus.Offered ||
                    statusId == findAnArtistCycleStatus.ArtistConfirmation ||
                    statusId == findAnArtistCycleStatus.VenueConfirmation ||
                    statusId == findAnArtistCycleStatus.Booked
                    ? highlightItemCssClass
                    : standardItemCssClass,
            artistOfferedCssClass:
                statusId == findAnArtistCycleStatus.Offered ||
                    statusId == findAnArtistCycleStatus.ArtistConfirmation ||
                    statusId == findAnArtistCycleStatus.VenueConfirmation ||
                    statusId == findAnArtistCycleStatus.Booked
                    ? highlightItemCssClass
                    : standardItemCssClass,
            artistConfirmationStateCssClass:
                statusId == findAnArtistCycleStatus.ArtistConfirmation ||
                    statusId == findAnArtistCycleStatus.VenueConfirmation ||
                    statusId == findAnArtistCycleStatus.Booked
                    ? highlightItemCssClass
                    : standardItemCssClass,
            venueConfirmationCssClass:
                statusId == findAnArtistCycleStatus.VenueConfirmation ||
                    statusId == findAnArtistCycleStatus.Booked
                    ? highlightItemCssClass
                    : standardItemCssClass,
            bookedStateCssClass:
                statusId == findAnArtistCycleStatus.Booked
                    ? highlightItemCssClass
                    : standardItemCssClass,
            cancelledStateCssClass:
                statusId == findAnArtistCycleStatus.Cancelled
                    ? highlightItemCssClass
                    : standardItemCssClass
        });
    };

    componentDidUpdate(prevProps: FindAnArtistStatusFlowProps) {
        if (prevProps.statusId != this.props.statusId) {
            this.setState(
                {
                    statusId: this.props.statusId,
                },
                this.updateCssClasses
            );
        }
    }

    componentDidMount() {
        this.updateCssClasses();
    }

    renderStatusFlowExceptCancel = () => {
        let gigType = GlobalService.getGigComingFrom();
        if (this.state.statusId != findAnArtistCycleStatus.Cancelled) {
            return (
                <>
                    { gigType !== "Entertainment" ?
                        <ul className="status-flow__cleaning-progress-bar">
                            <li className={this.state.pendingStateCssClass}>
                                <label>Pending</label>
                            </li><li className={this.state.optionsSentStateCssClass}>
                                <label>Options Sent</label>
                            </li><li className={this.state.venueConfirmationCssClass}>
                                <label>Venue Confirmation</label>
                            </li><li className={this.state.bookedStateCssClass}>
                                <label>Booked</label>
                            </li>
                        </ul>
                        : <ul className="status-flow__progress-bar">
                            <li className={this.state.pendingStateCssClass}>
                                <label>Pending</label>
                            </li><li className={this.state.publishedStateCssClass}>
                                <label>Published</label>
                            </li><li className={this.state.optionsSentStateCssClass}>
                                <label>Options Sent</label>
                            </li><li className={this.state.artistSelectedCssClass}>
                                <label>Shortlisted</label>
                            </li><li className={this.state.artistOfferedCssClass}>
                                <label>Offered</label>
                            </li><li className={this.state.artistConfirmationStateCssClass}>
                                <label>Artist Confirmation</label>
                            </li><li className={this.state.venueConfirmationCssClass}>
                                <label>Venue Confirmation</label>
                            </li><li className={this.state.bookedStateCssClass}>
                                <label>Booked</label>
                            </li>
                         </ul>
                    }
                    <img src={this.props.displayLink ? this.props.displayLink : "/assets/images/event-UK-image.jpeg"} height={135} width={140}>
                    </img>
                </>
            )
        }
    }

    renderCancelledStatusFlow = () => {
        if (this.state.statusId == findAnArtistCycleStatus.Cancelled) {
            return (
                <>
                    <ul className="status-flow__progress-bar">
                        <li className={this.state.cancelledStateCssClass}>
                            <label>Cancelled</label>
                        </li>
                    </ul>
                    <img src={this.props.displayLink ? this.props.displayLink : "/assets/images/event-UK-image.jpeg"} height={135} width={140}>
                    </img>
                </>
            )
        }
    }

    //#region render Component
    render() {
        return (
            <section className="status-flow">
                {this.renderStatusFlowExceptCancel()}
                {this.renderCancelledStatusFlow()}
            </section>
        );
    }
}

//#endregion

export default withTranslation()(FindAnArtistStatusFlow);
