import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import StarRating from "../components/FindAnArtist/starRating";
import FavouriteButton from "../components/FindAnArtist/favouriteButton";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import EntertainmentCard from "../components/Cards/EntertainmentCard";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import Carousel, { Modal, ModalGateway } from "react-images";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface ArtistProfileDesignComponentProps extends RouteComponentProps {
    title: string;
    category: string;
}

interface ArtistProfileDesignState {
    currentImage: {};
    selectedIndex: number;
    lightboxIsOpen: boolean;
}

class ArtistProfileDesign extends Component<ArtistProfileDesignComponentProps> {
    state: ArtistProfileDesignState;

    constructor(props: Readonly<ArtistProfileDesignComponentProps>) {
        super(props);
        this.state = {
            currentImage: {},
            selectedIndex: 0,
            lightboxIsOpen: false,
        };

        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
    }

    openLightbox(selectedIndex?: number) {
        this.setState((state) => ({
            lightboxIsOpen: !this.state.lightboxIsOpen,
            selectedIndex,
        }));
    }

    closeLightbox() {
        this.setState((state) => ({
            lightboxIsOpen: false,
        }));
    }

    componentDidMount() {}

    selectFavorite = () => {};

    render() {
        // Placeholder data, please replace
        const artistName = "Deadmau5";
        const artistEmail = "reallylongemailaddresss@email.com";
        const artistPhone = "0123456789";
        const playlistURL = "https://www.youtube.com/embed/videoseries?list=PLF1E9D263B9DDFEB7";

        const cards = [1, 2, 3];

        //Image Gallery
        const images = [
            { index: 0, source: "https://picsum.photos/seed/1/1600/900" },
            { index: 1, source: "https://picsum.photos/seed/2/1600/900" },
            { index: 2, source: "https://picsum.photos/seed/3/1600/900" },
            { index: 3, source: "https://picsum.photos/seed/4/1600/900" },
            { index: 4, source: "https://picsum.photos/seed/6/1600/900" },
            { index: 5, source: "https://picsum.photos/seed/7/1600/900" },
            { index: 6, source: "https://picsum.photos/seed/8/1600/900" },
            { index: 7, source: "https://picsum.photos/seed/9/1600/900" },
        ];

        // These URLs are used as background images
        const imgUrl1 = "https://picsum.photos/seed/1/250/400";
        const imgUrl2 = "https://picsum.photos/seed/2/600/300";
        const imgUrl3 = "https://picsum.photos/seed/3/300/300";

        const { selectedIndex, lightboxIsOpen } = this.state;

        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    <div className="container">
                        <h3 className="find-an-artist-header__title">DJ, Club</h3>
                        <div className="text-right">
                            <button className="btn btn-primary">Add to Request</button>
                        </div>
                    </div>
                    <div className="multi-image-banner mt-6">
                        {/* Empty divs are bad practice but this is a fix for IE to make use of background images */}
                        {/* Inline styles are normally not ok but to support IE, this is the best way */}
                        <div className="multi-image-banner__container">
                            <div
                                className="multi-image-banner__image"
                                style={{
                                    backgroundImage: `url("${imgUrl1}")`,
                                }}
                            >
                                <button className="btn btn-primary multi-image-banner__image-upload-button">
                                    <FontAwesomeIcon icon={faCamera} />
                                    <span className="sr-only">Upload first banner image</span>
                                </button>
                            </div>
                            <div
                                className="multi-image-banner__image"
                                style={{
                                    backgroundImage: `url("${imgUrl2}")`,
                                }}
                            ></div>
                            <div
                                className="multi-image-banner__image"
                                style={{
                                    backgroundImage: `url("${imgUrl3}")`,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className="container">
                        <section className="artist-profile__bio-section">
                            <div className="row">
                                <div className="col-12 col-lg-3">
                                    <div className="artist-profile__primary-image__container">
                                        <div className="artist-profile__primary-image">
                                            <img
                                                className=""
                                                src="https://picsum.photos/id/1005/300/300"
                                                alt={`Profile photo for ${artistName}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-9">
                                    <div className="artist-profile__details">
                                        <div className="artist-profile__header">
                                            <div>
                                                <h4 className="artist-profile__title">Deadmau5</h4>
                                                <FavouriteButton
                                                    favourited={true}
                                                    selectFavorite={this.selectFavorite}
                                                />
                                            </div>
                                            <StarRating score={4.3} distance="" />
                                        </div>
                                        <div>Club DJ</div>
                                        <div className="artist-profile__location">Greater London</div>
                                        <div className="artist-profile__social-links">
                                            <a href="#" className="icon-btn">
                                                <FontAwesomeIcon icon={faFacebook} />
                                                <span className="sr-only">Visit Facebook page for {artistName}</span>
                                            </a>
                                            <a href="#" className="icon-btn">
                                                <FontAwesomeIcon icon={faInstagram} />
                                                <span className="sr-only">Visit Instagram page for {artistName}</span>
                                            </a>
                                            <a href="#" className="icon-btn">
                                                <FontAwesomeIcon icon={faTwitter} />
                                                <span className="sr-only">Visit Twitter page for {artistName}</span>
                                            </a>
                                        </div>
                                        <a className="artist-profile__website" href="#">
                                            www.deadmau5.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-lg-5">
                                <div className="col-12 col-lg-3 mt-3 mt-lg-0">
                                    <h5 className="artist-profile__contact-heading">Contact</h5>
                                    <div className="artist-profile__contact-detail">
                                        <FontAwesomeIcon icon={faUser} />
                                        <span>ReallyReallyLongContact Name so it wraps</span>
                                    </div>
                                    <div className="artist-profile__contact-detail">
                                        <FontAwesomeIcon icon={faPhone} />
                                        <a href={`tel:${artistPhone}`}>{artistPhone}</a>
                                    </div>
                                    <div className="artist-profile__contact-detail">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <a href={`mailto:${artistEmail}`}>{artistEmail}</a>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-9 mt-3 mt-lg-0">
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam quia facere
                                    recusandae, officia fugiat aspernatur, optio sunt officiis voluptates repellat
                                    numquam sapiente voluptatem reiciendis labore vero quos inventore eum maxime
                                    consectetur similique. Consequatur, fuga quaerat exercitationem autem cupiditate
                                    excepturi similique soluta ad aperiam qui pariatur ullam aliquid ipsam. Officia,
                                    quas autem ipsum officiis necessitatibus tempore fugiat error deleniti, laudantium
                                    atque unde consectetur repellat repudiandae quia iusto eveniet ab ea quam aperiam
                                    ut, odit dolore! Est recusandae quos, fuga quam ex hic officiis minima, similique
                                    consequatur repellat maxime. Aliquam neque ex doloribus ea, esse nesciunt sunt, nam
                                    vero animi voluptate accusamus.
                                </div>
                            </div>
                        </section>
                        <section>
                            <h5 className="artist-profile__section-header">Latest Videos</h5>
                            <iframe
                                className="artist-profile__latest-videos"
                                src={playlistURL}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </section>
                        <section className="mt-5">
                            <h5 className="artist-profile__section-header">Image Gallery</h5>
                            <div className="row">
                                {images.map((value) => {
                                    return (
                                        <div className="col-12 col-md-6 col-lg-3 mb-5">
                                            <img
                                                className="artist-profile__gallery-image"
                                                src={value.source}
                                                onClick={() => this.openLightbox(value.index)}
                                            />
                                        </div>
                                    );
                                })}
                                <ModalGateway>
                                    {lightboxIsOpen ? (
                                        <Modal onClose={this.closeLightbox}>
                                            <Carousel views={images} currentIndex={selectedIndex} />
                                        </Modal>
                                    ) : null}
                                </ModalGateway>
                            </div>
                        </section>
                        {/* hidding recent gig list for now */}
                        <section className="mt-5 d-none">
                            <h5 className="artist-profile__section-header">Recent Gigs</h5>
                            <div className="row">
                                {cards.map((value) => {
                                    return (
                                        <div key={value} className="col col-12 col-md-6 col-lg-4 mb-3">
                                            <EntertainmentCard
                                                type="entertainer"
                                                typeTitle="Entertainment"
                                                enumeration={true}
                                                cardIndex={value}
                                                cardsTotal={cards.length}
                                                cardType="upcoming"
                                            >
                                                stuff
                                            </EntertainmentCard>
                                        </div>
                                    );
                                })}
                            </div>
                        </section>
                        <section className="artist-profile__edit-section d-none">
                            Edit Section, add forms here <br />
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe debitis beatae quisquam!
                            Odio veniam atque qui eveniet, reiciendis ducimus corrupti obcaecati facilis animi unde
                            consequuntur porro error ea. Dolor inventore saepe non voluptatem repellat expedita
                            consectetur, adipisci ullam numquam facere facilis itaque et debitis, at quibusdam quidem
                            dignissimos, optio sunt ipsum sint dolorem? Eveniet officia, soluta rem pariatur repellendus
                            ipsum odit consequuntur ab, impedit quod exercitationem labore veritatis dolorum
                            necessitatibus delectus ducimus perspiciatis, saepe vel sapiente magnam quia provident?
                            Repellendus est assumenda consectetur voluptates nihil eum vitae, libero et impedit
                            molestias corporis. Quia fugiat provident commodi. Libero dolor, illum, maxime laboriosam
                            exercitationem harum expedita tempore quam necessitatibus neque nemo nam quod repudiandae
                            est beatae veritatis qui totam minima voluptas alias fugit ea doloribus! Temporibus possimus
                            porro esse eum dolorem distinctio, optio sapiente repellendus non vel. Temporibus atque sunt
                            corporis ullam aliquam. Consectetur dolor error in ratione ex magnam voluptates at,
                            excepturi perferendis non cupiditate, voluptatum aspernatur sequi animi tempora?
                            Consequatur, voluptatem eaque repudiandae excepturi magni iste. Temporibus officia pariatur
                            quibusdam molestias ullam et quas odit magni voluptatem, dolorem facilis cupiditate earum
                            rerum saepe praesentium. Eum nisi dolorem ipsum amet quibusdam eaque assumenda ad,
                            repudiandae, eligendi possimus rerum optio ea quas?
                        </section>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ArtistProfileDesign);
