import React, { MouseEvent } from "react";
import { LoadPanel } from "devextreme-react";
import { AxiosResponse } from "axios";
import GigHighLevelUtilities from "./GigHighLevelUtilities";
import { Link } from "react-router-dom";
import CardWrapper from "../Cards/CardWrapper";
import sharedUtils from "../grid/sharedUtilities";
import GigHighLevelService, { RequestType, GigMessageType, ShowInterestType } from "../../services/GigHighLevelService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/pro-regular-svg-icons";

interface HighLevelGigDetailedCardProps {
    location: any;
    history: any;
}
interface HighLevelGigDetailedCardState {
    editViewDataItem: RequestType;
    buttonText: string;
    buttonClass: string;
    interestedStatus: boolean;
    errorMessage: string;
    loadPanelVisible: boolean;
}
export default class HighLevelGigDetailedCard extends React.Component<HighLevelGigDetailedCardProps> {
    state: HighLevelGigDetailedCardState;
    gigHighLevelService: GigHighLevelService;
    utils: GigHighLevelUtilities;
    sharedUtils: sharedUtils;
    //service that would post the data back to the server.
    constructor(props: HighLevelGigDetailedCardProps) {
        super(props);
        //Initialize services
        this.gigHighLevelService = new GigHighLevelService();
        this.utils = new GigHighLevelUtilities();
        this.sharedUtils = new sharedUtils(); //props.location.state && this.props.location.state.id ? this.props.location.state.id.toString() : "");
        this.state = {
            editViewDataItem: this.utils.initializeGigDetailItem(),
            buttonText:
                this.props.location.state && this.props.location.state.interestText == "Expressed Interest"
                    ? "Interested"
                    : "I am interested",
            buttonClass:
                this.props.location.state && this.props.location.state.interestText == "Expressed Interest"
                    ? "btn btn_interested gig-detail-layout__button btn--large mt-4"
                    : "btn btn-primary gig-detail-layout__button btn--large btn_text_color mt-4",
            interestedStatus:
                this.props.location.state && this.props.location.state.interestText == "Expressed Interest"
                    ? true
                    : false,
            errorMessage: "",
            loadPanelVisible: false,
        };
    }

    componentDidMount() {
        this.getCardData();
    }

    //A function that would make the API call and populate the Object which would be displayed on the cards post destructuring.
    getCardData = () => {
        this.showLoadpanel();
        if (this.props.location.state && this.props.location.state.id) {
            this.gigHighLevelService
                .getHighLevelCardData(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
        }
    };

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        this.hideLoadpanel();
        this.setState({
            editViewDataItem: response.data.data,
        });
    };

    handleStandardError = (error: any) => {
        this.hideLoadpanel();
    };

    onClose = () => {
        this.props.history.push({
            pathname: "/gigCardList",
        });
    };

    onSubmitStatus = () => {
        if (!this.state.interestedStatus) {
            this.setState({
                loadPanelVisible: true,
            });
            if (this.props.location.state && this.props.location.state.id) {
                this.gigHighLevelService
                    .saveShowInterestStatus(this.props.location.state.id)
                    .then(this.handleSubmissionSuccess)
                    .catch(this.handleFailure);
            }
        }
    };

    handleFailure = () => {
        this.hideLoadpanel();
    };

    handleSubmissionSuccess = () => {
        this.hideLoadpanel();
        this.setState({
            interestedStatus: true,
            buttonText: "Interested",
            buttonClass: "btn btn_interested gig-detail-layout__button btn--large mt-4",
        });
    };

    hideLoadpanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    showLoadpanel = () => {
        this.setState({
            loadPanelVisible: true,
        });
    };

    isBlank = (filterId: string) => {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    };

    fetchRegMessage = (): string => {
        var regText = "";
        var regMsgObj: GigMessageType | null | undefined =
            this.state.editViewDataItem &&
            this.state.editViewDataItem.messages &&
            this.state.editViewDataItem.messages.length > 0
                ? this.state.editViewDataItem.messages.find(
                      (item: GigMessageType) => item.keyName === "GigRegistrationMessage"
                  )
                : null;
        regText =
            regMsgObj && regMsgObj != undefined
                ? regMsgObj.keyValue
                : "If you want to be considered for this gig, please register your interest below...";
        return regText;
    };

    fetchThankMessage = (): string => {
        var thankText = "";
        var thankMsgObj: GigMessageType | null | undefined =
            this.state.editViewDataItem &&
            this.state.editViewDataItem.messages &&
            this.state.editViewDataItem.messages.length > 0
                ? this.state.editViewDataItem.messages.find(
                      (item: GigMessageType) => item.keyName === "GigThankYouMessage"
                  )
                : null;
        thankText =
            thankMsgObj && thankMsgObj != undefined
                ? thankMsgObj.keyValue
                : "Thank you for showing interest in this gig." +
                  "If the venue chooses an alternative artist this will be shown in your giglist for up to 7 days.";
        return thankText;
    };

    fetchImpMessage = (): string => {
        var impText = "";
        var impMsgObj: GigMessageType | null | undefined =
            this.state.editViewDataItem &&
            this.state.editViewDataItem.messages &&
            this.state.editViewDataItem.messages.length > 0
                ? this.state.editViewDataItem.messages.find(
                      (item: GigMessageType) => item.keyName === "GigImportantMessage"
                  )
                : null;
        impText =
            impMsgObj && impMsgObj != undefined
                ? impMsgObj.keyValue
                : "Please maintain your profile, keeping it up-to-date and professional to have an impact on our Artist Showcase." +
                  "Venue managers use the Showcase to select who they want!";
        return impText;
    };

    //A helper function that will reset the Status on the Button to the normal state.
    resetInterest = () => {
        var { editViewDataItem } = this.state;
        var { location } = this.props;
        var businessEntityId: string =
            location && location.state && location.state.businessEntityId ? location.state.businessEntityId : "";
        var requestIdList: ShowInterestType[] =
            editViewDataItem && editViewDataItem.showInterest && editViewDataItem.showInterest.length > 0
                ? editViewDataItem.showInterest.filter((item: ShowInterestType) => {
                      return item.providerBusinessEntityId == businessEntityId;
                  })
                : [];
        var requestId: string = requestIdList[0] ? requestIdList[0].requestId : "";
        this.setState({
            loadPanelVisible: true,
        });
        this.gigHighLevelService
            .undoInterested(requestId)
            .then(() =>
                this.setState({
                    loadPanelVisible: false,
                    interestedStatus: false,
                    buttonText: "I am interested",
                    buttonClass: "btn btn-primary gig-detail-layout__button btn--large btn_text_color mt-4",
                })
            )
            .catch(this.handleFailure);
    };

    render() {
        var { interestedStatus } = this.state;
        var delimeter: string = ", ";
        var dash: string = " - ";
        var serialNumber = this.props.location.state ? this.props.location.state.serialNo : 0;
        var totalNumber = this.props.location.state ? this.props.location.state.totalNo : 0;
        var service =
            this.state.editViewDataItem &&
            this.state.editViewDataItem.serviceType &&
            this.state.editViewDataItem.serviceSubType
                ? `${this.state.editViewDataItem.serviceType} ${dash} ${this.state.editViewDataItem.serviceSubType}`
                : "";
        var addInfo =
            this.state.editViewDataItem.additionalInformation && this.state.editViewDataItem.entertainmentDescription
                ? this.state.editViewDataItem.entertainmentDescription +
                  delimeter +
                  this.state.editViewDataItem.additionalInformation
                : this.state.editViewDataItem.entertainmentDescription &&
                  !this.state.editViewDataItem.additionalInformation
                ? this.state.editViewDataItem.entertainmentDescription
                : this.state.editViewDataItem.additionalInformation &&
                  !this.state.editViewDataItem.entertainmentDescription
                ? this.state.editViewDataItem.additionalInformation
                : null;

        var gigRegMsgText = this.fetchRegMessage();

        var gigThankMsgText = this.fetchThankMessage();
        var gigImpMsgText = this.fetchImpMessage();

        // var genre = this.state.editViewDataItem.genre ? (this.state.editViewDataItem.genre + { delimeter }) : "";
        var venueCountry =
            this.state.editViewDataItem && this.state.editViewDataItem.country
                ? this.state.editViewDataItem.country
                : "";
        var city =
            this.state.editViewDataItem && this.state.editViewDataItem.townCity
                ? this.state.editViewDataItem.townCity
                : "";
        var dateFrom =
            this.state.editViewDataItem && this.state.editViewDataItem.dateFrom
                ? this.state.editViewDataItem.dateFrom
                : "";
        var time =
            this.state.editViewDataItem &&
            this.state.editViewDataItem.startTime &&
            this.state.editViewDataItem.finishTime
                ? `${this.state.editViewDataItem.startTime}${dash}${this.state.editViewDataItem.finishTime}`
                : this.state.editViewDataItem
                ? this.state.editViewDataItem.startTime
                : null;
        var typeLookUp =
            this.state.editViewDataItem && this.state.editViewDataItem.typeLookUp
                ? this.state.editViewDataItem.typeLookUp
                : null;
        var payAmount =
            this.state.editViewDataItem && this.state.editViewDataItem.budget
                ? `${this.sharedUtils.thousandsSeparator(this.state.editViewDataItem.budget)}`
                : "";
        return (
            <CardWrapper
                type="entertainer"
                typeTitle={service}
                enumeration={true}
                cardIndex={serialNumber}
                cardsTotal={totalNumber}
                fullHeight={false}
            >
                <div className="text-center">
                    <div className="euk-card__content-section">
                        {this.state.errorMessage != "" ? (
                            <span className="text-danger font-weight-bold">{this.state.errorMessage}</span>
                        ) : null}
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <div className="euk-card__title">
                            {city}
                            {delimeter}
                            {venueCountry}
                        </div>
                    </div>

                    <div className="euk-card__content-section--highlight">
                        <div className="entertainer-card-layout__date-info">
                            <time className="font-weight-bold" dateTime="">
                                {dateFrom}
                            </time>
                            <time>{time}</time>
                        </div>
                        <div className="entertainer-card-layout__payment-info">
                            <div>
                                <span>{typeLookUp}</span>
                            </div>

                            <span>{payAmount}</span>
                        </div>
                        <hr className="euk-card__seperator" />
                        <h5 className="gig-detail-layout__info-heading">Gig Information:</h5>
                        <span className="font-weight-bold">{addInfo}</span>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    </div>
                    <div className="euk-card__content-section--alt mt-5">
                        <div
                            className="mt-2 gig-detail-layout__small-text text-center"
                            hidden={this.state.interestedStatus}
                        >
                            <span>{gigRegMsgText}</span>
                        </div>
                        <div
                            className="mt-2 gig-detail-layout__small-text text-center mt-3"
                            hidden={!this.state.interestedStatus}
                        >
                            <span>{gigThankMsgText}</span>
                        </div>
                        <div
                            className="mt-2 gig-detail-layout__small-text text-center mt-3"
                            hidden={!this.state.interestedStatus}
                        >
                            <span>{gigImpMsgText}</span>
                        </div>
                        <div className="row col-12 mx-0">
                            <div className="col col-5">
                                <button
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onClose();
                                    }}
                                    className="btn btn--ghost gig-detail-layout__button btn--large mt-4"
                                >
                                    <span className="gig-button-text">Close</span>
                                </button>
                            </div>
                            <div className="col col-7">
                                <button
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onSubmitStatus();
                                    }}
                                    className={this.state.buttonClass}
                                >
                                    {interestedStatus && (
                                        <a className="icon-btn" href="#">
                                            <span className="mx-2">
                                                <FontAwesomeIcon icon={faUndo} onClick={this.resetInterest} />
                                            </span>
                                        </a>
                                    )}
                                    <span className="text-white">{this.state.buttonText}</span>
                                </button>
                            </div>
                        </div>
                        <div className="mt-4">
                            <span>See all &nbsp;</span>
                            <Link title="Terms & Conditions" to="#">
                                <span className="btn-link--inline">Terms & Conditions</span>
                            </Link>
                        </div>
                    </div>
                
                </div>
            </CardWrapper>
        );
    }
}
