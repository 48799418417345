import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import { SelectBox, LoadPanel, TextBox, NumberBox } from "devextreme-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import VenueHierarchyFormUtil, { AddEditVenueHierarchyFormData } from "../VenueHierarchy/VenueHierarchyFormUtil";
import VenueHierarchyService, {
    ContactByClientTypeItem,
    VenueByClientTypeItem,
} from "../../services/VenueHierarachyService";
import sharedUtils from "../grid/sharedUtilities";
import { LookUpOnValueChangedEvent, NumberBoxOnValueChangedEvent, SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";

type NullableDate = Date | null | undefined;
//#region props
interface VenueHierarchyFormProps extends WithTranslation {
    location: any;
    history: any;
    onApplySuccess: (applySignal: string) => void;
    rowId: string;
    clientId: string;
    isEditForm: any;
    handleFormOpenClose: (applySignal: string, id: string) => void;
}

// State
interface VenueHierarchyFormState {
    //TODO : Define the form state here.
    venueLookUp: LookupTypeItem[];
    contactLookUp: LookupTypeItem[];
    venueByClientList: VenueByClientTypeItem[];
    contactByClientList: ContactByClientTypeItem[];
    editvenueFormData: AddEditVenueHierarchyFormData;
    loadPanelVisible: boolean;
    errorMessage: [];
}

class VenueHierarchyForm extends React.Component<VenueHierarchyFormProps> {
    lookupService: LookupService;
    state: VenueHierarchyFormState;
    utils: VenueHierarchyFormUtil;
    sharedUtils: sharedUtils;
    venueHierarchyService: VenueHierarchyService;
    constructor(props: VenueHierarchyFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.utils = new VenueHierarchyFormUtil(props);
        this.sharedUtils = new sharedUtils();
        this.venueHierarchyService = new VenueHierarchyService();

        var convertedEditvenueData = this.utils.initializeVenueFormItem(props && props.rowId ? props.rowId : "");
        this.state = {
            venueLookUp: [],
            contactLookUp: [],
            venueByClientList: [],
            contactByClientList: [],
            editvenueFormData: convertedEditvenueData,
            loadPanelVisible: false,
            errorMessage: [],
        };
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        if (this.props.clientId) {
            this.dropDownDataSource();
        }
        if (this.props.rowId != "") {
            this.getEditViewItemDataSource();
        }
    }

    dropDownDataSource = () => {
        this.venueHierarchyService
            .fetchVenueByClient(this.props.clientId)
            .then(this.handleVenueSuccess)
            .catch(this.handleLookUpError);
        if (this.props && this.props.rowId) {
            this.venueHierarchyService
                .fetchContactByClientAll(this.props.clientId)
                .then(this.handleContactSuccess)
                .catch(this.handleLookUpError);
        }
        else {
            this.venueHierarchyService
                .fetchContactByClient(this.props.clientId)
                .then(this.handleContactSuccess)
                .catch(this.handleLookUpError);
        }
    };
    handleVenueSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                venueByClientList: response.data.data,
            },
            () => {
                this.populateVenueDropDownSource();
            }
        );
    };
    handleContactSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                contactByClientList: response.data.data,
            },
            () => {
                this.populateContactDropDownSource();
            }
        );
    };
    populateVenueDropDownSource = () => {
        var venueDropDown = this.lookUpCreation(this.state.venueByClientList, true);
        this.setState({
            venueLookUp: venueDropDown,
        });
    };
    populateContactDropDownSource = () => {
        var contactDropDown = this.lookUpCreation(this.state.contactByClientList, false);
        this.setState({
            contactLookUp: contactDropDown,
        });
    };
    lookUpCreation = (response: any, isVenue: boolean): LookupTypeItem[] => {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: isVenue ? this.sharedUtils.convertNumberToString(item.id) : item.contactId,
                value: isVenue ? item.venueName : item.contactName,
                parentMappingId: "0",
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };

    getEditViewItemDataSource = () => {
        if (this.props.rowId) {
            this.venueHierarchyService
                .getVenueHierarchyDataItem(this.props.rowId)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleError);
        }
    };

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        var viewDataSource = this.utils.convertServerResponseToFormData(response.data.data);
        this.setState({
            editvenueFormData: viewDataSource,
        });
    };

    handleLookUpError = (err: any) => { };

    handleChangeVenue = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState(
                {
                    editvenueFormData: {
                        ...this.state.editvenueFormData,
                        venueId: dxValueChange.value,
                    },
                },
                () => {
                    this.mapHouseId();
                }
            );
        }
    };

    mapHouseId = () => {
        var resultArray: VenueByClientTypeItem[] = [];
        resultArray =
            this.state.venueByClientList && this.state.venueByClientList.length > 0
                ? this.state.venueByClientList.filter((item: VenueByClientTypeItem) => {
                    return item.id == this.state.editvenueFormData.venueId;
                })
                : [];
        var houseNumber =
            resultArray && resultArray.length > 0 && resultArray[0].houseNumber ? resultArray[0].houseNumber : "";
        this.setState({
            editvenueFormData: {
                ...this.state.editvenueFormData,
                houseId: houseNumber,
            },
        });
    };
    handleChangeContact = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState(
                {
                    editvenueFormData: {
                        ...this.state.editvenueFormData,
                        contactId: dxValueChange.value,
                    },
                },
                () => {
                    this.mapUserRole();
                }
            );
        }
    };
    mapUserRole = () => {
        var resultArray: ContactByClientTypeItem[] = [];
        resultArray =
            this.state.contactByClientList && this.state.contactByClientList.length > 0
                ? this.state.contactByClientList.filter((item: ContactByClientTypeItem) => {
                    return item.contactId == this.state.editvenueFormData.contactId;
                })
                : [];
        var user = resultArray && resultArray.length > 0 && resultArray[0].userName ? resultArray[0].userName : "";
        var userId = resultArray && resultArray.length > 0 && resultArray[0].userId ? resultArray[0].userId : "";
        var role = resultArray && resultArray.length > 0 && resultArray[0].roleName ? resultArray[0].roleName : "";
        var roleId = resultArray && resultArray.length > 0 && resultArray[0].roleId ? resultArray[0].roleId : "";
        this.setState({
            editvenueFormData: {
                ...this.state.editvenueFormData,
                userName: user,
                userId: userId,
                rollId: roleId,
                rollName: role,
            },
        });
    };
    handleChangeManager = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editvenueFormData: {
                    ...this.state.editvenueFormData,
                    managerId: dxValueChange.value,
                },
            });
        }
    };

    handleChangeLevel = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editvenueFormData: {
                    ...this.state.editvenueFormData,
                    level: dxValueChange.value,
                },
            });
        }
    }
    onCancelClick = () => {
        // this.props.onApplySuccess("CANCEL");
        this.props.handleFormOpenClose("CLOSE", "");
    };
    onApplyClick = () => {
        this.setState({
            loadPanelVisible: true,
        });
        var venueSubmitRequest = this.utils.convertFormDataToRequest(this.state.editvenueFormData, this.props.clientId);

        this.venueHierarchyService
            .saveVenueHierarchyDataItem(venueSubmitRequest)
            .then(this.handleSuccessDataSubmission)
            .catch(this.handleError);
    };

    handleSuccessDataSubmission = () => {
        this.setState({
            loadPanelVisible: false,
        });

        if (this.props && this.props.rowId) {
            this.props.onApplySuccess("EDIT");
        } else if (this.state.editvenueFormData.id == "0") {
            this.props.onApplySuccess("ADD");
        }
    };

    handleError = (err: any) => {
        this.setState({
            loadPanelVisible: false,
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null, // For capturing response at the time of exception and showing error message
        });
    };

    filterDataSource = (data: LookupTypeItem[], filterId: string): LookupTypeItem[] => {
        return !this.isBlank(filterId)
            ? data.filter((item: LookupTypeItem) => {
                return item.id === filterId;
            })
            : data;
    };

    onKeyDown = (e: any) => {
        if (e.event.key === '.') e.event.preventDefault();
      }

    formattedValue = (val: any) => {
        let levelVal: string = "";
        if (val.length <= 2) {
            levelVal = val;
        }
        return levelVal;
    }

    isBlank = (filterId: string) => {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    };
    //#region render Component
    render() {
        return (
            <form data-testid="addEditShift-form" className="edit-form my-5 form_border">
                <div className="container mb-6 edit-form__column">
                    <div className="row pt-3">
                        <h2 className="billingForm-heading">Venue Hierarchy Form</h2>
                    </div>
                    <br></br>
                    {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
                        <div className="row justify-content-left pt-3">
                            <span className="text-danger font-weight-bold text-left">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        </div>
                    ) : null}
                    <div className="row" hidden={!this.props.isEditForm}>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">ID</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.id} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Last Updated On</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.lastUpdatedOn} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Last Updated By</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.lastUpdateBy} readOnly={true} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Venue</div>
                        <div className="mt-3 col-10 col-lg-3 text-left">
                            <LookupTypeListDynamic
                                lookupTypeIndex={LookupTypeIndexes.venueType}
                                onValueChanged={this.handleChangeVenue}
                                isRoot={false}
                                parentMappingId={this.props.clientId}
                                value={this.state.editvenueFormData.venueId}
                                displayExpression={"value"}
                                recordType={this.props && this.props.rowId ? "all" : "active"}
                            />
                        </div>

                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Contact</div>
                        <div className="mt-3 col-10 col-lg-3 text-left">
                            <LookupTypeListDynamic
                                lookupTypeIndex={LookupTypeIndexes.contactType}
                                onValueChanged={this.handleChangeContact}
                                isRoot={false}
                                parentMappingId={this.props.clientId}
                                value={this.state.editvenueFormData.contactId}
                                displayExpression={"value"}
                                recordType={this.props && this.props.rowId ? "all" : "active"}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Manager</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <SelectBox
                                dataSource={this.state.contactLookUp}
                                displayExpr={"value"}
                                valueExpr={"id"}
                                onValueChanged={this.handleChangeManager}
                                value={this.state.editvenueFormData.managerId}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Venue Id</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.venueId} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Contact Id</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.contactId} readOnly={true} />
                        </div>

                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Manager Id</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.managerId} readOnly={true} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">House Id</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.houseId} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">User Id</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.userId} readOnly={true} />
                        </div>

                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">User</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.userName} readOnly={true} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Role Id</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.rollId} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Role</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editvenueFormData.rollName} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Level</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                min={0}
                                max={10}
                                onValueChanged={this.handleChangeLevel}
                                valueChangeEvent="input"
                                value={this.state.editvenueFormData.level}
                                onKeyDown={this.onKeyDown}
                            />
                        </div>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <br></br>
                        <br></br>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-2 mb-3"></div>
                        <div className="col-12 col-lg-2 mb-3"></div>
                        <div className="col-12 col-lg-2 mb-3"></div>
                        {/* <div className='col-12 col-lg-2 mb-3'>
                        <Link to={{
                            pathname: "/matrixView",
                            state: {
                                isCancel: true
                            }
                        }}
                            className="btn btn--ghost btn--large">
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                            {this.props.t("addEditShiftRow.financeSection.cancelButton.text")}
                        </Link>
                    </div> */}
                        <div className="col-12 col-lg-3 mb-3">
                            <Link
                                to={{ pathname: "" }}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onCancelClick();
                                }}
                                className="btn btn--ghost btn--large"
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                                {this.props.t("addEditShiftRow.financeSection.cancelButton.text")}
                            </Link>
                        </div>
                        <div className="col-12 col-lg-3 mb-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="button"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onApplyClick();
                                }}
                            >
                                <span className="btn__icon"></span>
                                {this.props.t("addEditShiftRow.financeSection.applyButton.text")}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default withTranslation()(VenueHierarchyForm);
