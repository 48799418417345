import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import ScheduleService from "../../services/ScheduleService";
import { AxiosResponse } from "axios";
import sharedUtils from "../grid/sharedUtilities";
import UserService, { RoleGroupNames } from "../../services/UserService";

interface ProviderLookUpComponentProps extends WithTranslation {
    setParentFilterValuesCallback: (providerId: string, isProviderFound: boolean) => void;
    refreshSignal: boolean;
}

interface ProviderLookUpComponentState {
    providerId: string;
    isLoad: boolean;
    isShowDropDown: boolean;
}
class ProviderLookUpComponent extends React.Component<ProviderLookUpComponentProps> {
    state: ProviderLookUpComponentState;
    utils: sharedUtils;
    service: ScheduleService;
    lookUp: lookupService;
    constructor(props: ProviderLookUpComponentProps) {
        super(props);
        this.utils = new sharedUtils();
        this.service = new ScheduleService();
        this.lookUp = new lookupService();
        this.state = {
            providerId: "",
            isLoad: true,
            isShowDropDown: false,
        };
    }

    componentDidMount() {
        this.retrieveLookup();
    }

    componentDidUpdate(prevProps: ProviderLookUpComponentProps) {
        if (this.props.refreshSignal != prevProps.refreshSignal) {
            this.retrieveLookup();
        }
    }

    retrieveLookup = () => {
        this.lookUp
            .getLookupByLookupTypeIndex(LookupTypeIndexes.providerType)
            .then(this.handleProviderLookUpSuccess)
            .catch(this.handleStandardError);
    };

    handleProviderLookUpSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            var providers: any[] = response.data.data;
            this.setState(
                {
                    providerId: providers[0].id,
                    isLoad: false,
                },
                () => {
                    this.props.setParentFilterValuesCallback(providers[0].id, true);
                }
            );
            this.dropDownToShow(response.data.data);
        }
    };

    handleStandardError = (response: AxiosResponse<any>) => {
        this.setState({
            isLoad: false,
        });
    };

    dropDownToShow = (providerDataSource: LookupTypeItem[]) => {
        if (
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
        ) {
            this.setState({
                isShowDropDown: true,
            });
        } else if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            this.setState({
                isShowDropDown: false,
            });
        }
    };

    handleChangeProviderTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            providerId: dxValueChange.value,
        });
        this.props.setParentFilterValuesCallback(dxValueChange.value, true);
    };

    isBlank(param: string) {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    }

    render() {
        return (
            <div>
                <div>
                    <div className="card" hidden={!this.state.isShowDropDown}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="row">
                                        <label className="col-12 col-xl-2 mb-0 pt-1">Provider</label>
                                        <div className="col-12 col-xl-10">
                                            <DropDownFromLookupTable
                                                lookupTypeIndex={LookupTypeIndexes.providerType}
                                                onValueChanged={this.handleChangeProviderTypeSelect}
                                                value={this.state.providerId}
                                            />
                                            <input
                                                data-testid={LookupTypeIndexes.providerType}
                                                type="hidden"
                                                name={LookupTypeIndexes.providerType}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ProviderLookUpComponent);
