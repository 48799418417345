import React from "react";

interface AllUserGridShiftProps {
    data: any;
}

interface AllUserGridShiftState {}

class AllUserGridShift extends React.Component<AllUserGridShiftProps> {
    render() {
        const { shift, shift_Color, shift_Font } = this.props.data.data;
        const shiftStatus = "Unscheduled Shift";
        return (
            <div className="status-message-color">
                {shift_Color == "Red" && shift_Font == "Bold" ? (
                    <ul className="unscheduled-shift list-style-type">
                        <li>{shiftStatus}</li>
                    </ul>
                ) : (
                    <ul className="list-style-type">
                        <li> {shift}</li>
                    </ul>
                )}
            </div>
        );
    }
}

export default AllUserGridShift;
