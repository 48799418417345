import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadPanel } from "devextreme-react";
import { faUserTag } from "@fortawesome/pro-light-svg-icons";
import { faUserTag as faSolidUserTag } from "@fortawesome/pro-solid-svg-icons";
import BaseActionCell, { BaseActionCellProps } from "../GridCells/BaseActionCell";
import FindAnArtistService from "../../services/FindAnArtistService";
import { AxiosResponse } from "axios";
import { confirm, alert } from "devextreme/ui/dialog";
import UserService, { RoleGroupNames } from "../../services/UserService";

interface TagCellProps {
    data: any;
}


class TagCell extends BaseActionCell {
    service: FindAnArtistService;
    constructor(props: BaseActionCellProps) {
        super(props);
        this.service = new FindAnArtistService();
    }

    componentDidMount(): void {
        let taggedToColumnIndex: number = 0;
        taggedToColumnIndex = this.props.data.component.getVisibleColumnIndex("taggedUser.name");
        this.setState({
            taggedColumnIndexVal: taggedToColumnIndex
        });
    }

    onClickTag = () => {
        if (this.state.gridTaggedStatus) {
            if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
                this.onConfirm(false);
            }
        }
        else {
            //call the API for posting the Billable Item list to the server.
            this.onConfirm(true);
        }


    }

    onConfirm = (taggedStatus: boolean) => {
        this.showLoadPanel();
        let currentPath = window.location.pathname;
        let requestId: string;
        let serviceId: string;
        let serviceSubtypeId: string;
        let clientBusinessEntityId: string;
        if(this.props.data.data?.case_ID){
            requestId = this.props.data.data.case_ID;
        } 
        else {
            requestId = this.props.data.data.id;
        }
        if(this.props.data.data?.serviceTypeLookUp){
            serviceId = this.props.data.data.serviceTypeLookUp.id.toString();
        }
        else if(this.props.data.data?.serviceType) {
            serviceId = this.props.data.data.serviceType.id.toString();
        }
        else{
            serviceId = "";
        }
        if(this.props.data.data?.serviceSubTypeLookUp){
            serviceSubtypeId = this.props.data.data.serviceSubTypeLookUp.id.toString();
        }
        else {
            if(currentPath == "/QueryManageForm"){
                serviceSubtypeId = "";
            }
            else{
                serviceSubtypeId = this.props.data.data?.serviceSubType && this.props.data.data?.serviceSubType !== null ? this.props.data.data.serviceSubType.id.toString() : "";
            }
        }
        if(this.props.data.data?.clientBusinessEntity){
            clientBusinessEntityId = this.props.data.data.clientBusinessEntity.id.toString();
        }
        else {
            clientBusinessEntityId = "";
        }
        let submitRequest = this.findAnArtistUtils.mapTaggedRequest(requestId, taggedStatus, this.props.data.data.featured, this.props.data.data.reoccurrence, serviceId, serviceSubtypeId, clientBusinessEntityId);
        let tagUntagRequest = this.findAnArtistUtils.mapTagUntagRequest(requestId, taggedStatus);
        if(currentPath == "/gigRequest"){
            this.service.updateArtistRequest(this.props.data.data.id, submitRequest)
                .then(this.handleTaggedToSuccess)
                .catch((error) => {
                    let respMessage: string = "Tagging failed with response: " + JSON.stringify(error);
                    this.hideLoadPanel();
                    alert("Sorry!  Tagging failed", "Error!");
                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                });
        }
        else if(currentPath == "/paymentRequests"){
            this.service.updatePaymentTagUntagRequest(this.props.data.data.id, tagUntagRequest)
                .then(this.handleTaggedToSuccess)
                .catch((error) => {
                    let respMessage: string = "Tagging failed with response: " + JSON.stringify(error);
                    this.hideLoadPanel();
                    alert("Sorry!  Tagging failed", "Error!");
                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                });
        }
        else{
            this.service.updateQueryTagUntagRequest(requestId, tagUntagRequest)
                .then(this.handleTaggedToSuccess)
                .catch((error) => {
                    let respMessage: string = "Tagging failed with response: " + JSON.stringify(error);
                    this.hideLoadPanel();
                    alert("Sorry!  Tagging failed", "Error!");
                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                });
        }
    }

    handleTaggedToSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            gridTaggedStatus: response.data.data.isTagged
        });
        this.props.data.component.cellValue(
            this.props.data.rowIndex,
            this.state.taggedColumnIndexVal,
            response.data.data.taggedTo
        );
    };


    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    message="Please wait..."
                    visible={this.state.loadPanelVisible}
                />
                <button className={"btn icon-btn"} onClick={this.onClickTag}
                >
                    {this.state.gridTaggedStatus ?
                        <FontAwesomeIcon icon={faSolidUserTag} /> :
                        <FontAwesomeIcon icon={faUserTag} />}
                </button>
            </div>
        );
    }
}

export default TagCell;
