import { AdditionalCleaningRequest } from "../../services/AdditionalCleaningFormService";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;

export interface CleaningFormData {
    venueId: string;
    workDescription: string;
    workDate: NullableDate;
    agreedCost: number;
    authorisedBy: string;
}

export default class AdditionalCleaningFormUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializeCleaningItems = this.initializeCleaningItems.bind(this);
        this.convertFormDataToCleaningRequest = this.convertFormDataToCleaningRequest.bind(this);
    }

    initializeCleaningItems(idFromGrid: string): CleaningFormData {
        var formData: CleaningFormData = {
            venueId: "",
            workDescription: "",
            workDate: undefined,
            agreedCost: 0,
            authorisedBy: "",
        };

        return formData;
    }

    // Convert the form's data into a proper billable item submit request
    convertFormDataToCleaningRequest(formData: CleaningFormData): AdditionalCleaningRequest {
        var submitRequest: AdditionalCleaningRequest = {
            venueId: formData.venueId,
            workDescription: formData.workDescription,
            workDate: this.utils.convertDateToString(formData.workDate),
            agreedCost: this.utils.convertNumberToString(formData.agreedCost),
            authorisedBy: formData.authorisedBy,
        };
        return submitRequest;
    }
}
