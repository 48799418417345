import React from "react";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";
import { TypeOfFileUploaded } from "../../services/BillableItemHeaderService";
import UploadSchedulePage from "../page/UploadSchedulePage";
import { uploadedFileType } from "../../services/FileService";

interface FileUploadPageContainerProps extends RouteProps, withValidUserRoleProps {}

const FileUploadPageContainer = (props: FileUploadPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return (
        <UploadSchedulePage
            title={getTitle()}
            category={getType()}
            landingPage={TypeOfFileUploaded.SIA}
            typeId={uploadedFileType.SIA}
            landingPageTab = ""
        />
    );
};

export default withValidUserRole(FileUploadPageContainer);
