import React, { MouseEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { checkinCardData, successMessageResponse } from "../../services/CheckInCardService";
import checkInCardService from "../../services/CheckInCardService";
import { AxiosResponse } from "axios";
import sharedUtils from "../grid/sharedUtilities";
import CardWrapper3Part from "../Cards/CardWrapper3Part";
import CardHeaderSection from "../Cards/CardSections/CardHeaderSection";
import { LoadPanel } from "devextreme-react";

// all of this info will be needed to populate a card
// not sure if status (late/on time) will come from db or be dervied here
interface CheckinProps {
    cardUIType: string;
    checkinCard: checkinCardData;
    totalNumber: number;
    serialNumber: number;
}

interface CheckinCardState {
    currentCardState: {
        billableitemid: string;
        name: string;
        idvalue: string;
        checkInTime: string;
        checkOutTime: string;
    };
    isCheckIn: boolean;
    isCheckOut: boolean;
    checkInStatus: string;
    checkOutStatus: string;
    scheduledStartTime: string;
    scheduledFinishTime: string;
    errorMessage: [];
    IsShowSuccess: boolean;
    closeButton: boolean;
    successMessage: successMessageResponse[];
    statusMessageClass: string;
    showLoadIndicator: boolean;
}

class CheckinCard extends React.Component<CheckinProps> {
    state: CheckinCardState;
    sharedUtils: sharedUtils;
    //service that would post the data back to the server.
    cardService: checkInCardService;
    constructor(props: CheckinProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.onCheckInClick = this.onCheckInClick.bind(this);
        this.onCheckOutClick = this.onCheckOutClick.bind(this);
        this.checkInTimeCompare = this.checkInTimeCompare.bind(this);
        this.checkOutTimeCompare = this.checkOutTimeCompare.bind(this);
        // this.handleChangeLicence = this.handleChangeLicence.bind(this);

        //Initialize the service.
        this.cardService = new checkInCardService();

        this.state = {
            currentCardState: {
                billableitemid: this.props.checkinCard.billableItemId,
                name: this.props.checkinCard.name,
                idvalue: this.props.checkinCard.idValue,
                checkInTime: this.props.checkinCard.checkInTime,
                checkOutTime: this.props.checkinCard.checkOutTime,
            },
            isCheckIn: false,
            isCheckOut: false,
            checkInStatus: "",
            checkOutStatus: "OFF",
            scheduledStartTime: this.props.checkinCard.scheduledStartTime,
            scheduledFinishTime: this.props.checkinCard.scheduledFinishTime,
            errorMessage: [],
            successMessage: [],
            IsShowSuccess: false,
            closeButton: false,
            statusMessageClass: "",
            showLoadIndicator: false,
        };
    }
    componentDidMount() {
        this.convertTimeToDate(this.props.checkinCard.checkInTime, "checkin");
        this.convertTimeToDate(this.props.checkinCard.checkOutTime, "checkout");
    }
    convertTimeToDate = (Time: string, check: string) => {
        if (Time != "00:00") {
            let today = new Date();
            let time = Time.split(":");
            let date = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                parseInt(time[0]),
                parseInt(time[1]),
                0,
                0
            );

            if (check == "checkin") {
                this.checkInTimeCompare(date, this.state.scheduledStartTime);
            } else if (check == "checkout") {
                this.checkOutTimeCompare(date, this.state.scheduledFinishTime);
            }
        }
    };
    checkInTimeCompare = (today: Date, Time: string) => {
        let scheduledStartTime = Time.split(":");

        if (parseInt(scheduledStartTime[0]) < today.getHours()) {
            this.setState({
                checkInStatus: "late",
            });
        } else if (parseInt(scheduledStartTime[0]) == today.getHours()) {
            if (parseInt(scheduledStartTime[1]) < today.getMinutes()) {
                this.setState({
                    checkInStatus: "late",
                });
            }
        }
    };

    checkOutTimeCompare = (today: Date, Time: string) => {
        let scheduledStartTime = Time.split(":");
        let date = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            parseInt(scheduledStartTime[0]),
            parseInt(scheduledStartTime[1]),
            0,
            0
        );
        if (parseInt(scheduledStartTime[0]) <= 9) {
            date.setDate(date.getDate() + 1);
        }
        if (date > today) {
            this.setState({
                checkOutStatus: "early",
            });
        }
    };

    onCheckInClick(event: MouseEvent) {
        let today = new Date();
        let mins = today.getMinutes().toString();
        if (today.getMinutes() < 10) {
            mins = "0" + today.getMinutes();
        }
        this.setState(
            {
                isCheckIn: true,
                currentCardState: {
                    ...this.state.currentCardState,
                    checkInTime: today.getHours() + ":" + mins, // 04/06/2021 17:27
                },
                showLoadIndicator: true,
            },
            () => this.handleSubmit("CheckIn")
        );
        this.checkInTimeCompare(today, this.state.scheduledStartTime);
    }

    onCheckOutClick(event: MouseEvent) {
        let today = new Date();
        let mins = today.getMinutes().toString();
        if (today.getMinutes() < 10) {
            mins = "0" + today.getMinutes();
        }
        this.setState(
            {
                isCheckOut: true,
                currentCardState: {
                    ...this.state.currentCardState,
                    checkOutTime: today.getHours() + ":" + mins,
                },
                showLoadIndicator: true,
            },
            () => this.handleSubmit("CheckOut")
        );
        this.checkOutTimeCompare(today, this.state.scheduledFinishTime);
    }

    checkInRefresh = () => {
        var { checkOutTime } = this.state.currentCardState;
        var card = {
            billableitemid: this.state.currentCardState.billableitemid,
            name: this.state.currentCardState.name,
            idvalue: this.state.currentCardState.idvalue,
            checkInTime: "",
            checkOutTime: checkOutTime == "00:00" ? "" : checkOutTime ? this.sharedUtils.convertDateToString(new Date()) + " " + checkOutTime : "",
            IsRefresh: "y",
        };
        this.setState({
            currentCardState: {
                ...this.state.currentCardState,
                checkInTime: "",
            },
            isCheckIn: false,
        });
        this.cardService.submitShiftData(card).then(this.handleRefreshSuccess).catch(this.handleRefreshFailure);
    };

    handleRefreshFailure = (error: any) => {
        var respMessage: string = "Submit Shift Data service failed with response: " + JSON.stringify(error);

        if (!this.cardService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    checkOutRefresh = () => {
        var { checkInTime } = this.state.currentCardState;
        var card = {
            billableitemid: this.state.currentCardState.billableitemid,
            name: this.state.currentCardState.name,
            idvalue: this.state.currentCardState.idvalue,
            checkInTime: checkInTime == "00:00" ? "" : checkInTime ? this.sharedUtils.convertDateToString(new Date()) + " " + checkInTime : "",
            checkOutTime: "",
            IsRefresh: "y",
        };
        this.setState({
            currentCardState: {
                ...this.state.currentCardState,
                checkOutTime: "",
            },
            isCheckOut: false,
        });
        this.cardService.submitShiftData(card).then(this.handleRefreshSuccess).catch(this.handleRefreshFailure);
    };

    // Function to post data back to the server.
    handleSubmit = (operation: string) => {
        var card = {
            billableitemid: this.state.currentCardState.billableitemid,
            name: this.state.currentCardState.name,
            idvalue: this.state.currentCardState.idvalue,
            checkInTime:   this.sharedUtils.convertDateToString(new Date()) + " " + this.state.currentCardState.checkInTime,
            checkOutTime:  this.sharedUtils.convertDateToString(new Date()) + " " + this.state.currentCardState.checkOutTime,
        };
        //
        if (operation.toLowerCase() == "checkin") {
            card.checkOutTime = "";
        } else if (operation.toLowerCase() == "checkout") {
            card.checkInTime = "";
        }

        this.cardService
            .submitShiftData(card)
            .then(this.handleSubmitSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    IsShowSuccess: false,
                });
            });
    };

    showMessageColor = () => {
        if (this.state.successMessage[0].messageColor.toUpperCase() == "BLUE") {
            this.setState({ statusMessageClass: "text-info font-weight-bold" });
        } else if (this.state.successMessage[0].messageColor.toUpperCase() == "RED") {
            this.setState({
                statusMessageClass: "text-danger font-weight-bold",
            });
        } else {
            this.setState({
                statusMessageClass: "text-warning font-weight-bold",
            });
        }
    };

    handleRefreshSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            IsShowSuccess: true,
            errorMessage: null,
            successMessage: null,
        });
    };

    handleSubmitSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            successMessage: response.data !== null ? JSON.parse(JSON.stringify(response.data.data)) : null,
            IsShowSuccess: true,
            closeButton: true,
            showLoadIndicator: false,
        });
        this.showMessageColor();
    };

    public static defaultProps: Partial<CheckinProps> = {
        checkinCard: undefined,
    };

    render() {
        var { serviceTypeLookUpName, jobRole } = this.props.checkinCard;
        var finalLicenseNumber = this.sharedUtils.licenseNumberFormat(this.props.checkinCard.idValue);
        return (
            <CardWrapper3Part
                type="security"
                typeTitle={
                    this.props.checkinCard
                        ? `${serviceTypeLookUpName ? serviceTypeLookUpName : ""} - ${jobRole ? jobRole : ""}`
                        : ""
                }
                enumeration={true}
                cardIndex={this.props.serialNumber}
                cardsTotal={this.props.totalNumber}
                header={
                    <CardHeaderSection
                        venue={this.props.checkinCard.name}
                        date={this.props.checkinCard.dateFrom}
                        message={this.props.checkinCard.venueName}
                        licenseNumber={finalLicenseNumber}
                        adjustment={true}
                        provider = {""}
                        serviceSubTypeId = {""}
                        client = {""}
                    />
                }
                body={
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.showLoadIndicator} />
                        <div className="row">
                            <div className="col-6">
                                <div className="time-indicator time-indicator--checkin">
                                    {this.props.checkinCard.scheduledStartTime
                                        ? this.props.checkinCard.scheduledStartTime
                                        : "00.00"}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="time-indicator time-indicator--checkout">
                                    {this.props.checkinCard.scheduledFinishTime
                                        ? this.props.checkinCard.scheduledFinishTime
                                        : "00.00"}
                                </div>
                            </div>
                        </div>
                        <div className="checkin-card__CTAs row mt-3">
                            <div className="col-6">
                                {(this.props.checkinCard.checkInTime != "00:00" &&
                                    this.state.currentCardState.checkInTime != "") ||
                                this.state.isCheckIn == true ? (
                                    this.state.checkInStatus != "late" ? (
                                        <div className="time-indicator time-indicator--checkin time-indicator--checked-in">
                                            <span className="time-indicator__icon">
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </span>
                                            {this.state.currentCardState.checkInTime}
                                            <span className="time-indicator__undo">
                                                <FontAwesomeIcon icon={faUndo} onClick={this.checkInRefresh} />
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="time-indicator time-indicator--checkin time-indicator--alert">
                                            <span className="time-indicator__icon">
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </span>
                                            {this.state.currentCardState.checkInTime}
                                            <span className="time-indicator__undo">
                                                <FontAwesomeIcon icon={faUndo} onClick={this.checkInRefresh} />
                                            </span>
                                        </div>
                                    )
                                ) : (
                                    <button
                                        hidden={this.props.cardUIType != "manual" ? true : false}
                                        className="btn btn--small btn-primary"
                                        onClick={this.onCheckInClick}
                                    >
                                        Check In
                                    </button>
                                )}
                            </div>
                            <div className="col-6">
                                {(this.props.checkinCard.checkOutTime != "00:00" &&
                                    this.state.currentCardState.checkOutTime != "") ||
                                this.state.isCheckOut == true ? (
                                    this.state.checkOutStatus != "early" ? (
                                        <div className="time-indicator time-indicator--checkout time-indicator--checked-in">
                                            <span className="time-indicator__icon">
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </span>
                                            {this.state.currentCardState.checkOutTime}
                                            <span className="time-indicator__undo">
                                                <FontAwesomeIcon icon={faUndo} onClick={this.checkOutRefresh} />
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="time-indicator time-indicator--checkout time-indicator--alert">
                                            <span className="time-indicator__icon">
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </span>
                                            {this.state.currentCardState.checkOutTime}
                                            <span className="time-indicator__undo">
                                                <FontAwesomeIcon icon={faUndo} onClick={this.checkOutRefresh} />
                                            </span>
                                        </div>
                                    )
                                ) : (
                                    <button
                                        hidden={this.props.cardUIType != "manual" ? true : false}
                                        className="btn btn--small btn-primary"
                                        onClick={this.onCheckOutClick}
                                    >
                                        Check Out
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                }
            />
        );
    }
}

export default CheckinCard;
