import { AxiosResponse } from "axios";
import { _get, _aiTraceAsError } from "./BaseApiService";

// Endpoints
const HEADER_URL = "header";

//Interface for the returned response.
export interface HeaderResponse {
    title: string;
    description: string;
    businessEntityId: string;
}

/// REST service for lookups.
class HeaderService {
    getMainHeaderByRole(): Promise<AxiosResponse<any>> {
        return _get(HEADER_URL);
    }

    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default HeaderService;
