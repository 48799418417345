import { AxiosResponse } from "axios";
import { RequestStatus, RequestTypeEnum, IndependentBookFormRequest } from "../../services/FindAnArtistService";
import { LookupTypeItem } from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;
export interface RequestFormData {
    id: string;
    startTime: NullableDate;
    entertainmentDescription: string;
    serviceSubTypeId: string;
    clientBusinessEntityId: string;
    venueId: string;
    venueTypeId:string;
    dateFrom: NullableDate;
    workerName: string;
    workerEmail: string;
    workerPhone: string;
    additionalInformation: string;
    budget: number;
}

export default class IndependentBookingUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
    }
    initializeRequestItem = (): RequestFormData => {
        var formData: RequestFormData = {
            id: "0",
            clientBusinessEntityId: "",
            venueId: "",
            venueTypeId:"",
            serviceSubTypeId: "",
            entertainmentDescription: "",
            dateFrom: undefined,
            workerName: "",
            workerEmail: "",
            workerPhone: "",
            startTime: this.utils.convertStringToTime("00:00"),
            additionalInformation: "",
            budget: 0,
        };
        return formData;
    };
    // Convert the form's data into a proper item submit request
    convertFormDataToNewRequest = (formData: RequestFormData): IndependentBookFormRequest => {
        var submitRequest: IndependentBookFormRequest = {
            id: formData.id,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            venueTypeId: formData.venueTypeId,
            entertainmentDescription: formData.entertainmentDescription,
            serviceSubTypeId: formData.serviceSubTypeId,
            dateFrom: this.utils.convertDateToString(formData.dateFrom),
            workerName: formData.workerName,
            workerPhone: formData.workerPhone,
            workerEmail: formData.workerEmail,
            startTime: this.utils.convertTimeToString(formData.startTime),
            budget: `${this.utils.convertNumberToString(formData.budget)}`,
            additionalInformation: formData.additionalInformation,
            requestStatusId: RequestStatus.Pending,
            requestTypeId: RequestTypeEnum.IndependentBooking,
        };
        return submitRequest;
    };
    lookUpCreation = (response: AxiosResponse<any>): LookupTypeItem[] => {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.data.data.map((item: LookupTypeItem, uniqueKey: number) => {
            itemLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };
    isBlank = (filterId: string) => {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    };
}
