import React from "react";
import DataGrid, { Pager, Paging, Export, FilterRow, Column, Scrolling, SearchPanel } from "devextreme-react/data-grid";
import { DataGridOnInitializedEvent } from "../../types/DevExtremeTypes";
import BillableItemHeaderService, { VenueHierarchyChildGridRowItem } from "../../services/BillableItemHeaderService";
import gridUtils from "../grid/GridUtilities";
import { AxiosResponse } from "axios";
import { LoadIndicator } from "devextreme-react";
import ErrorMessage from "../grid/ErrorMessage";
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";

// Props
//  - refreshSignal will indicate that a grid refresh is required.
//  - gridVisibilitySignal is going to signal when the grid will be shown.
interface RateMatrixUploaderFileContentsGridProps {
    isChildGridVisible: boolean;
    headerId: string;
}

// State
interface RateMatrixUploaderFileContentsGridState {
    rateMatrixChildGridDataSource: VenueHierarchyChildGridRowItem[];
    showProgressIndicator: boolean;
}

// Component - displays both the header and child grids
class RateMatrixUploaderFileContentsGrid extends React.Component<RateMatrixUploaderFileContentsGridProps> {
    state: RateMatrixUploaderFileContentsGridState;
    gridUtils: gridUtils;
    dataGridElement: any;
    billableItemUploadService: BillableItemHeaderService;

    constructor(props: RateMatrixUploaderFileContentsGridProps) {
        super(props);
        this.state = {
            rateMatrixChildGridDataSource: [],
            showProgressIndicator: false,
        };

        this.gridUtils = new gridUtils();
        this.billableItemUploadService = new BillableItemHeaderService();
        this.dataGridElement = undefined;
        this.handleFileUploadOnInitialize = this.handleFileUploadOnInitialize.bind(this);
        this.updateChildGridDataSource = this.updateChildGridDataSource.bind(this);
    }

    // Initialize state from server
    componentDidMount() {
        this.updateChildGridDataSource(this.props.headerId);
    }

    updateChildGridDataSource = (headerId: string) => {
        if (headerId) {
            this.billableItemUploadService
                .getChildGridDataForRateMatrixUpload(headerId)
                .then(this.handleFileUploadSuccess)
                .catch(this.handleFailure);
        }
    };

    handleFileUploadSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            rateMatrixChildGridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    };

    handleFailure = (error: any) => {
        var respMessage: string = "getChildGridDataForVenueHierarchy failed with response: " + JSON.stringify(error);

        if (!this.billableItemUploadService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    componentDidUpdate(prevProps: RateMatrixUploaderFileContentsGridProps) {
        if (this.props.headerId !== prevProps.headerId) {
            // refresh the grid
            this.updateChildGridDataSource(this.props.headerId);
            this.setState({
                showProgressIndicator: !this.state.showProgressIndicator,
            });
        }
    }

    // Set the file uploader component
    handleFileUploadOnInitialize(e: DataGridOnInitializedEvent) {
        this.dataGridElement = e.component;
    }
    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    render() {
        return (
            <div>
                <div className="childGrid mt-5">
                    <h4 className="font-weight-bold mb-3" hidden={!this.props.isChildGridVisible}>
                        View Selected File Contents
                    </h4>
                    {this.state.showProgressIndicator ? (
                        <div className="starter-template">
                            <LoadIndicator
                                id="simple-grid-indicator"
                                height={50}
                                width={50}
                                visible={this.state.showProgressIndicator}
                            />
                        </div>
                    ) : (
                        <DataGrid
                            dataSource={this.state.rateMatrixChildGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            onExporting={this.onExporting}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <Paging defaultPageSize={1} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[1, 5, 10, 20]} showInfo={true} />
                            <Column dataField="id" caption="ID" />
                            <Column dataField="fileUploadHeaderId" caption="Header ID" />
                            <Column dataField="providerBusinessEntityName" caption="Provider" />
                            <Column dataField="clientBusinessEntityName" caption="Client" />
                            <Column dataField="serviceType" caption="Service" />
                            <Column dataField="serviceSubType" caption="Service Sub Type" />
                            <Column dataField="type" caption="Type" />
                            <Column dataField="houseNumber" caption="House Number" />
                            <Column dataField="venueName" caption="Venue" />
                            <Column dataField="idType" caption="ID Type" />
                            <Column dataField="idNo" caption="ID NO." />
                            <Column dataField="name" caption="Name" />
                            <Column dataField="lowerLimit" caption="Lower Limit" />
                            <Column dataField="upperLimit" caption="Upper Limit" />
                            <Column dataField="comment" caption="Comment" />
                            <Column dataField="lastUpdatedOn" caption="Last Updated on" />
                            <Column dataField="lastUpdatedBy" caption="Last Updated By" />
                            <Column
                                dataField="errorMessage"
                                caption="Error Message"
                                cellComponent={ErrorMessage}
                            />
                            <FilterRow visible={true} applyFilter="auto" />
                            <Scrolling mode="standard" useNative={true} scrollByThumb={true} />
                        </DataGrid>
                    )}
                </div>
            </div>
        );
    }
}

export default RateMatrixUploaderFileContentsGrid;
