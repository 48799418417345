import React from "react";
import { AxiosResponse } from "axios";
import SupplierInformationPageGrid from "./SupplierInformationPageGrid";
import SupplierInformationService, {
    RateResponse,
    EnhancementResponse,
    ExceptionalRatesResponse,
} from "../../services/SupplierInformationService";
import SupplierInformationUtils, { SupplierInformationData } from "./SupplierInformationUtils";
import { LoadIndicator } from "devextreme-react";

interface SupplierInformationMainContentProps {
    clientId: string;
    venueId: string;
    navigationFromShift?: string;
    serviceTypeId?: string;
}
// State
interface SupplierInformationMainContentState {
    supplierInformationDataSource: SupplierInformationData;
    rateDataSource: RateResponse[];
    exceptionalRateGridDataSource: ExceptionalRatesResponse[];
    showLoadIndicator: boolean;
}

class SupplierInformationMainContent extends React.Component<SupplierInformationMainContentProps> {
    state: SupplierInformationMainContentState;
    service: SupplierInformationService;
    utils: SupplierInformationUtils;
    constructor(props: SupplierInformationMainContentProps) {
        super(props);
        this.service = new SupplierInformationService();
        this.utils = new SupplierInformationUtils();
        var convertedSupplierInformationData = this.utils.initializeSupplierInformationItem();
        this.state = {
            supplierInformationDataSource: convertedSupplierInformationData,
            rateDataSource: [],
            exceptionalRateGridDataSource: [],
            showLoadIndicator: false,
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        this.setState({
            showLoadIndicator: true,
        });
        if (this.props.navigationFromShift == "SimpleShiftGrid") {
            this.getSupplierInfoDataSource();
        }
    }

    getSupplierInfoDataSource() {
        if (this.props.clientId && this.props.venueId) {
            this.setState({
                showLoadIndicator: true,
            });
            this.service
                .getSupplierInformation(parseInt(this.props.clientId), parseInt(this.props.venueId))
                .then(this.handleSuccessSupplierInformationData);
        } else {
            this.setState({
                supplierInformationDataSource: null,
                rateDataSource: null,
                exceptionalRateGridDataSource: null,
            });
        }
    }

    componentDidUpdate(prevProps: SupplierInformationMainContentProps) {
        if (prevProps.clientId != this.props.clientId || prevProps.venueId != this.props.venueId) {
            this.getSupplierInfoDataSource();
        }
    }
    handleSuccessSupplierInformationData = (response: AxiosResponse<any>) => {
        this.setState(
            {
                supplierInformationDataSource: response.data.data,
                showLoadIndicator: false,
            },
            () =>
                this.dataPreparation(
                    this.state.supplierInformationDataSource.personnelRates,
                    this.state.supplierInformationDataSource.equipmentRates,
                    this.state.supplierInformationDataSource.exceptionalRates
                )
        );
    };

    dataPreparation = (
        personnelRates: RateResponse[],
        equipmentRates: RateResponse[],
        exceptionalRates: ExceptionalRatesResponse[]
    ) => {
        var enhancementArr: RateResponse[] = [];
        if (personnelRates && personnelRates.length > 0) {
            personnelRates.forEach((item: any, uniqueKey: number) => {
                enhancementArr.push(item);
            });
        }
        if (equipmentRates && equipmentRates.length > 0) {
            equipmentRates.forEach((item: any, uniqueKey: number) => {
                enhancementArr.push(item);
            });
        }
        this.setState({
            rateDataSource: enhancementArr,
        });

        if (exceptionalRates && exceptionalRates.length > 0) {
            exceptionalRates.forEach(() => {
                this.setState({
                    exceptionalRateGridDataSource: exceptionalRates,
                });
            });
        }
    };

    renderSupplierShortName() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.shortName
        ) {
            return (
                <>{this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.shortName}</>
            );
        }
    }

    renderOperationalContactName() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.name
        ) {
            return <>{this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.name}</>;
        }
    }

    renderOperationalContactMobileNumber() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.mobileTelephone
        ) {
            return (
                <a
                    href={`tel:${this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.mobileTelephone}`}
                >
                    {this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.mobileTelephone}
                </a>
            );
        }
    }

    renderOperationalContactEmailAddress() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.email
        ) {
            return (
                <a
                    href={`mailto:${this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.email}`}
                >
                    {this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.email}
                </a>
            );
        }
    }

    renderOpeningHours() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.openingHours
        ) {
            return (
                <>
                    {this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.openingHours}
                </>
            );
        }
    }

    renderOoOProtocol() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.oooProtocol
        ) {
            return (
                <>{this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.oooProtocol}</>
            );
        }
    }

    renderWebsiteAddress() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.website
        ) {
            return (
                <>
                    <a
                        href={`${this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.website}`}
                    >
                        {this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.website}
                    </a>
                </>
            );
        }
    }

    renderOfficePhone() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.officePhone
        ) {
            return (
                <a
                    href={`tel:${this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.officePhone}`}
                >
                    {this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.officePhone}
                </a>
            );
        }
    }

    renderEmailAddress() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity
        ) {
            return (
                <a
                    href={`mailto:${this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.email}`}
                >
                    {this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity.email}
                </a>
            );
        }
    }

    renderLicenceConditions() {
        if (this.state.supplierInformationDataSource && this.state.supplierInformationDataSource.venue.contact) {
            return this.state.supplierInformationDataSource.venue.dsLicenseCondition;
        }
    }

    renderUniformPolicy() {
        if (this.state.supplierInformationDataSource && this.state.supplierInformationDataSource.venue.contact) {
            return this.state.supplierInformationDataSource.venue.dsUniformPolicy;
        }
    }

    renderRates() {
        if (this.state.rateDataSource && this.state.rateDataSource.length > 0) {
            return this.state.rateDataSource.map((item: any) => {
                return (
                    <>
                        <strong key={item.typeLookValue}>{item.typeLookValue}: </strong>
                        {item.rateText}
                        <br />
                    </>
                );
            });
        }
    }

    renderEnhancements() {
        var enhancementData: EnhancementResponse;
        var enhancementDataArr: EnhancementResponse[] = [];
        var enhancementDataPart1: string = "";
        var enhancementDataPart2: string = "";
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.enhancements.length > 0
        ) {
            this.state.supplierInformationDataSource.enhancements.forEach((item: any, uniqueKey: number) => {
                var parts = item.enhancementText.split("x");
                enhancementDataPart1 = parts[0];
                enhancementDataPart2 = parts[1];
                enhancementData = {
                    date: item.date,
                    multiplier: item.multiplier,
                    enhancementTextPart1: enhancementDataPart1,
                    enhancementTextPart2: enhancementDataPart2,
                };
                enhancementDataArr.push(enhancementData);
            });
        }

        if (enhancementDataArr && enhancementDataArr.length > 0) {
            return enhancementDataArr.map((item: any) => {
                return (
                    <>
                        <strong key={item.date}>{item.enhancementTextPart1}: </strong>
                        {this.props.clientId && this.props.venueId ? "x " : ""} {item.enhancementTextPart2}
                        <br />
                    </>
                );
            });
        }
        return null;
    }

    renderUnderstandings() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity
        ) {
            return this.state.supplierInformationDataSource.venue.contact.securityProviderBusinessEntity
                .dsProviderInformation;
        }
    }

    render() {
        return this.state.showLoadIndicator && this.props.clientId && this.props.venueId ? (
            <div className="card">
                <div className="card-body">
                    <LoadIndicator
                        visible={this.state.showLoadIndicator}
                        id="button-indicator"
                        height={60}
                        width={60}
                        className="home-page__todayloader"
                    ></LoadIndicator>
                </div>
            </div>
        ) : (
            <>
                <div className="card">
                    <div className="card-body">
                        <>
                            <h3 className="page-title--medium">Approved Supplier</h3>
                            <strong>Supplier Name: </strong>
                            {this.renderSupplierShortName()}
                            <br />
                            <strong>Operational Contact: </strong>
                            {this.renderOperationalContactName()}
                            <br />
                            <strong>Operational Contact Number: </strong>
                            {this.renderOperationalContactMobileNumber()}
                            <br />
                            <strong>Operational Contact Email: </strong>
                            {this.renderOperationalContactEmailAddress()}
                            <hr className="horizontal_border_style"></hr>
                            <strong>Office Hours:</strong> {this.renderOpeningHours()}
                            <br />
                            <strong>Out of Hours Protocol:</strong> {this.renderOoOProtocol()}
                            <br />
                            <strong>Website Address:</strong> {this.renderWebsiteAddress()}
                            <br />
                            <strong>Office Phone Number:</strong> {this.renderOfficePhone()}
                            <br />
                            <strong>Office Email Address:</strong> {this.renderEmailAddress()}
                            </>
                            { this.props.serviceTypeId == "10" ?
                            ( 
                            <>
                            <hr className="horizontal_border_style"></hr>
                            <h3 className="page-title--medium">Licence Conditions (Specific to Door Supervision)</h3>
                            {this.renderLicenceConditions()}
                            <hr className="horizontal_border_style"></hr>
                            <h3 className="page-title--medium">Venue Uniform Policy</h3>
                            {this.renderUniformPolicy()}
                            <hr className="horizontal_border_style"></hr>
                            <h3 className="page-title--medium">Approved Rating</h3>
                            <div className="row">
                                <div className="col-12 col-md-4 vertical-line">
                                    <h6>
                                        <b>Rates</b>
                                    </h6>
                                    {this.renderRates()}
                                </div>
                                <div className="col-12 col-md-4 vertical-line">
                                    <h6>
                                        <b>Enhancements</b>
                                    </h6>
                                    {this.renderEnhancements()}
                                </div>
                                <div className="col-12 col-md-4">
                                    <h6>
                                        <b>Understandings</b>
                                    </h6>
                                    {this.renderUnderstandings()}
                                </div>
                            </div>
                            <hr className="horizontal_border_style"></hr>
                            <h3 className="page-title--medium">Exceptional Rating (if applicable)</h3>
                            <SupplierInformationPageGrid
                                exceptionalRateGridDataSource={this.state.exceptionalRateGridDataSource}
                            ></SupplierInformationPageGrid>
                        
                        </>
                        ) : (null)
    }
                    </div>
                </div>
            </>
        );
    }
}

export default SupplierInformationMainContent;
