import React from "react";

interface StatusColourProps {
    data: any;
}

interface StatusColourState {
    billableItemUploadId: number;
    billableItemUploadStatus: string;
}

class StatusColour extends React.Component<StatusColourProps> {
    //state object to manage the look of the Individual buttons and badges displayed in each row.
    state: StatusColourState;

    constructor(props: StatusColourProps) {
        super(props);
        this.state = {
            billableItemUploadId: 0,
            billableItemUploadStatus: "",
        };
    }

    componentDidMount() {
        this.setState({
            billableItemUploadId: this.props.data.data.billableItemUploadId,
            billableItemUploadStatus: this.props.data.data.fileContentUploadStatus
                ? this.props.data.data.fileContentUploadStatus
                : this.props.data.data.billableItemUploadStatus,
        });
    }

    render() {
        return (
            <>
                {this.state.billableItemUploadStatus}
            </>
        );
    }
}

export default StatusColour;
