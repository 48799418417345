import { AxiosResponse } from "axios";
import { NullableDate } from "../components/Outstanding-Actions/OutstandingActionsUtilities";
import { _get, _post, _postBatchRequest, _aiTraceAsError, _put,_setURL } from "./BaseApiService";
import { ExcludedEntityList } from "./FindAnArtistService";

// Endpoints
const PAYMENT_CYCLE_API = "PaymentCycle";
const NEW_PAYMENT_CYCLE_API = "PaymentCycle/V2";
const PAYMENT_CYCLE_EXTRACT = "PaymentCycle/PaymentCycleExtract"
const PAYMENT_API = "Payment";
const BACS = "bacs";
const GENERATE_PAYMENTS_API = "RefreshPayment";
const PAYMENT_REQUEST_REMOTE_OPERATIONS = "PaymentRequest";
const PAYMENT_REQUEST_DUPLICATE_CHECK_OPERATIONS = "PaymentRequest/SimilarPaymentRequest";
const RECENT_PROVIDER_WORKER = "BillableItem/RecentProviderWorker"
const CANCEL_PAYMENT_API = "Payment/CancelPayment"
const LOOKUP_API = "lookup";

export const paymentGridTypes = {
    providerPayment: "providerPayment",
    artistPayment: "artistPayment",
};

export interface paymentsServices {
    paymentCycleId: string;
    serviceTypeLookupId: string;
    value: string;
}

export interface PaymentGridRowItem {
    paymentId: string;
    paymentCycleId: string;
    entityId: string;
    entity: string;
    contactId: string;
    contact: string;
    reference: string;
    subAmount: string;
    tax: string;
    amount: string;
    bankAccountId: string;
    sortCode: string;
    accountNumber: string;
    statusId: string;
    status: string;
    action: string;
    payDate: string;
    lastPayment: string;
    varience: string;
    rag: string;
    advisedDate: string;
    settleDate: string;
    remittanceDate: string;
    bounceDate: string;
    currency: string;
    validationErrors: string;
}

// Grid row
export interface PaymentCyclesGridServiceRowItem {
    id: string;
    payDate: string;
    payCycleReference: string;
    subTotal: string;
    tax: string;
    total: string;
    status: string;
    paySentDate: string;
}

//Single Grid row type of the Payment cycles Grid.
export interface PaymentCycleGridRowItem {
    paymentCycleId: string;
    billableItemId: string;
    statusId: string;
    status: string;
    payDate: string;
    paySentDate: string;
    description: string;
    subTotal: string;
    tax: string;
    total: string;
    actionType: string;
    validationErrors: string;
}

export interface PaymentCycleItem {
    paymentCycleId: string;
    statusId: string;
    payDate: string;
    paySentDate: string;
    description: string;
    subTotal: string;
    tax: string;
    total: string;
    actionType: string;
}

//Single Grid row type of the Payment BACS Grid.
export interface PaymentBACSGridRowItem {
    name: string;
    sortCode: string;
    accountNumber: string;
    reference: string;
    amount: string;
    paymentCode: string;
}

// Interfaces
export interface PaymentFormSubmitRequest {
    paymentCycleId: string;
    statusId: string;
    status: string;
    payDate: string;
    paySentDate: string;
    paidDate: string;
    description: string;
    subTotal: string;
    tax: string;
    total: string;
    actionType: string;
    excludedPaymentIds: string;
    bouncedPaymentIds: string;
    serviceMappingList: paymentsServices[]
    internalReference: string;
    paymentCycleTypeId: string;
    paymentCycleType: string;
    paymentCycleExcludedEntities: ExcludedEntityList[];
}

export const paymentStatus = {
    Draft: "0",
    Pending: "1",
    ReadyForPayment: "10",
    PaymentPackRequested: "35",
    PaymentNetted: "20",
    PaymentChecked: "30",
    PaymentPackReady: "40",
    PaymentPackFailed: "41",
    PaymentPaid: "50",
    PaymentNotificationRequested: "51",
    PaymentNotification: "52",
    PaymentEmailNotificationRequested:"53",
    PaymentEmailNotification:"54",
    PaymentAdviseRequested: "55",
    PaymentAdvised: "60",
    PaymentSettled: "70",
    PaymentRemittanceRequested: "75",
    PaymentRemitted: "80",
};

export const paymentCycleStatus = {
    Pending: "Pending",
    ReadyForPayment: "Ready For Payment",
    PaymentPackRequested: "Payment Pack Requested",
    PaymentNetted: "Payment Netted",
    PaymentChecked: "Payment Checked",
    PaymentPackReady: "Payment Pack Ready",
    PaymentPackFailed: "Payment Pack Failed",
    PaymentPaid: "Payment Paid",
    PaymentNotificationRequested: "Payment Notification Requested",
    PaymentNotification: "Payment Notification",
    PaymentEmailNotificationRequested:"Payment Email Notification Requested",
    PaymentEmailNotification:"Payment Email Notification",
    PaymentAdviseRequested: "Payment Advise Requested",
    PaymentAdvised: "Payment Advised",
    PaymentSettled: "Payment Settled",
    PaymentRemittanceRequested: "Payment Remittance Requested",
    PaymentRemitted: "Payment Remitted",
};

export const paymentRequestStatus = {
    Draft: "10",
    Pending: "20",
    NotMatched: "30",
    Matched: "40",
    Cancelled: "99",
    Queried: "50",
    AddJobDraft: "61",
    AddJobPending: "62",
    AddJobCompleted: "63",
    AddJobRejected: "64",
    AddJobQueried: "69",
    AddJobCancelled: "79"

}

export const paymentTypeIndex = {
    selfbillinvoice: "selfbillinvoice",
    remittenceadvice: "RemittenceAdvice",
    emailselfbill: "EmailSelfBill",
    paymentnotification: "PaymentNotification",
    paymentEmailNotification: "PaymentEmailNotification",
    RegenerateSelfbillinvoice:'RegenerateSelfbillinvoice'
};

export interface PaymentFormResponse {
    paymentCycleId: string;
    statusId: string;
    status: string;
    payDate: string;
    paySentDate: string;
    paidDate: string;
    description: string;
    subTotal: string;
    tax: string;
    total: string;
    actionType: string;
    excludedPaymentIds: string;
    bouncedPaymentIds: string;
    serviceMappingList: paymentsServices[]
    internalReference: string;
    paymentCycleTypeId: string;
    paymentCycleType: string;
    paymentCycleExcludedEntities: ExcludedEntityList[];
}

export interface logicAppPostRequest {
    PaymentCycleId: string;
}

export interface AddEditPaymentFormResponse {
    id: string;
    businessEntityId: string;
    businessEntity: string;
    contactId: string;
    contact: string;
    bankAccountId: string;
    bankAccountNumber: string;
    sortCode: string;
    amount: string;
    reference: string;
    payDate: string;
    settleDate: string;
    remittanceDate: string;
    bounceDate: string;
    paymentStatusId: string;
    paymentStatus: string;
    paymentCycleId: string;
    comments: string;
    advisedDate: string;
    subAmount: string;
    tax: string;
    validationErrors: string;
    immediatePayment: string;
    immediatePaymentId: string;
    orignatingBillableItemId: string;
}

export interface AddEditPaymentSubmitRequest {
    id: string;
    businessEntityId: string;
    businessEntity: string;
    contactId: string;
    contact: string;
    bankAccountId: string;
    bankAccountNumber: string;
    sortCode: string;
    amount: string;
    reference: string;
    payDate: string;
    settleDate: string;
    remittanceDate: string;
    bounceDate: string;
    paymentStatusId: string;
    paymentStatus: string;
    paymentCycleId: string;
    comments: string;
    advisedDate: string;
    subAmount: string;
    tax: string;
    immediatePayment: string;
    immediatePaymentId: string;
    orignatingBillableItemId: string;
}
interface VirtualScrollingResponse {
    totalCount: number,
    data: any
}

export interface AddEditPaymentReqSubmitRequest {
    id: string,
    providerBusinessEntityId: string,
    clientBusinessEntityId: string,
    serviceSubTypeId: string,
    startTime: string,
    finishTime: string,
    venueId: string,
    serviceTypeId: string,
    dateFrom: string,
    dateTo: string,
    description: string,
    providerPayAmountLocalExtrasDescription: string,
    providerPayAmountLocal: string | undefined,
    quantityOrHours: string | undefined,
    rate: string | undefined,
    providerFasterPayFee: string | undefined,
    providerFasterPayOriginalAmount: string | undefined,
    internalComments: string,
    comments: string,
    requestDate: string | NullableDate,
    requestDate_Web: string,
    fasterPayment: boolean;
    providerPayAmountLocalExtras: string | undefined,
    paymentRequestTypeID: string,                  // check post is giving us 200 ok or not with these three ( null/ empty checking)
    paymentRequestStatusID: string,
    typeLookUpId: string,
    otherDescription: string,
    client: string,
    houseNumber: string,
    venueDetails: string,
    subType:string
}

export interface PaymentRequestMatchedPopUpResponse {
    id: string,
    providerBusinessEntity: {
        id: number,
        shortName: string,
    },
    clientBusinessEntity: {
        id: number,
        shortName: string,
    },
    venue: {
        id: number,
        houseNumber: string,
        venueName: string,
    },
    providerPayAmountLocal: string,
    providerFasterPayFee: string,
    fasterPayment: boolean
    providerFasterPayOriginalAmount: string,
    dateFrom: string,
    dateTo: string,
    description: string,
    providerPayAmountLocalExtrasDescription: string,
    serviceType: {
        id: number,
        value: string,
    },
    requestDate: string,
    paymentRequestStatusLookUp: {
        id: string,
        value: string
    },
    serviceSubType: {
        id: number,
        value: string
    },
    typeLookUp: {
        id: number,
        value: string
    },
    startTime: string,
    finishTime: string,
    providerPayAmountLocalExtras: string,
    paymentRequestType: { id: string, value: string },
    quantityOrHours: string,
    rate: string,
    otherDescription: string,

}

export interface AddEditPaymentReqGetResponse {
    id: string,
    providerBusinessEntityId: string,
    clientBusinessEntityId: string,
    venueId: string,
    serviceTypeId: string,
    dateFrom: string,
    dateTo: string,
    description: string,
    providerPayAmountLocalExtrasDescription: string,
    providerPayAmountLocal: string,
    quantityOrHours: string,
    rate: string,
    providerFasterPayFee: string,
    providerFasterPayOriginalAmount: string,
    internalComments: string,
    comments: string,
    requestDate: string,
    requestDate_Web: string,
    requestApprover:string,
    status: string,
    ValidationErrors: [],
    serviceSubTypeId: string,
    startTime: string,
    finishTime: string,
    fasterPayment: boolean,
    providerPayAmountLocalExtras: string,
    paymentRequestTypeID: string,
    paymentRequestStatusID: string,
    typeLookUpId: string,
    otherDescription: string,
    client: string,
    houseNumber: string,
    venueDetails: string,
    subType:string,
    venue: {
        id: string,
        houseNumber: string,
        venueName: string,
        address:string,
        city:string,
        county:string,
        postCode:string
    }
}

// Calculation response
export interface CalculationResponse {
    validationErrors: [];
    paymentRequestId: string;
    clientId: string;
    providerId: string;
    providerPayAmountLocal: string;
    providerPayAmountLocalExtras?: string;
    providerPayAmountLocalWOExtras?: string;
    providerFasterPayFee?: string;
    providerFasterPayOriginalAmount?: string;
    providerPayAmountLocalExtrasDescription: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    fasterPayment: boolean;
    multiplier: string;
}


/**
 * Service for all payment related API calls
 */
class PaymentService {

    //fetch Status look up 

    getPaymentReqStatusDataSource(type: string, filterBy: string): Promise<AxiosResponse<any>> {
        return _get(`${LOOKUP_API}?type=${type}&filterBy=${filterBy}`)
    }

    //post PaymentRequestData 

    postPaymentRequestFormData(data: Object): Promise<AxiosResponse<any>> {
        return _post(PAYMENT_REQUEST_REMOTE_OPERATIONS, data);
    }

    //get PaymentRequest duplicate Data 

    postPaymentRequestValidateData(data: Object): Promise<AxiosResponse<any>> {
        return _post(PAYMENT_REQUEST_DUPLICATE_CHECK_OPERATIONS, data);
    }

    //GET a single row of the Payment cycle Grid.
    getPaymentRequestFormData(id: string): Promise<AxiosResponse<any>> {
        return _get(`${PAYMENT_REQUEST_REMOTE_OPERATIONS}/${id}`);
    }

    //GET recent provider worker
    getRecentProviderWorker(venueId: string, serviceTypeId: string, date: string, providerId: string): Promise<AxiosResponse<any>> {
        return _get(RECENT_PROVIDER_WORKER + `?venueId=${venueId}&servicetypeId=${serviceTypeId}&date=${date}&providerId=${providerId}`);
    }

    // RemoteOperations Service 
    getPaymentGridRowsWithinRemoteOperations(params: string): Promise<AxiosResponse<VirtualScrollingResponse>> {
        if (params) {
            return _get(PAYMENT_REQUEST_REMOTE_OPERATIONS + params);
        } else {
            return new Promise(function (resolve: any) {
                resolve({
                    data: [],
                    totalCount: 0
                });
            });
        }
    }

    //GET all the rows of the Payment cycles Grid.
    getPaymentCycleGridRows(): Promise<AxiosResponse<any>> {
        return _get(PAYMENT_CYCLE_API);
    }

    //GET a single row of the Payment cycle Grid.
    getPaymentCycleById(id: string): Promise<AxiosResponse<any>> {
        return _get(`${PAYMENT_CYCLE_API}/${id}`);
    }

    //GET the BACS Grid Rows.
    getBACSGridRows(id: number): Promise<AxiosResponse<any>> {
        return _get(`${PAYMENT_CYCLE_API}/${id}/${BACS}`);
    }

    getPaymentGridRowItems(id: string): Promise<AxiosResponse<any>> {
        return _get(`${PAYMENT_CYCLE_API}/${id}/${PAYMENT_API}`);
    }

    // Retrieve all rows for the grid based on Id of row clicked on parent grid
    getPaymentCycleViewData(id: number) {
        return _get(`${PAYMENT_CYCLE_API}/${id}`);
    }

    //post matched pop up data

    postMatchPopUpData(id: number, isMatch: boolean, paymentRequestTypeId?: string): Promise<AxiosResponse<any>> {
        if (isMatch) {
            return _put(`${PAYMENT_REQUEST_REMOTE_OPERATIONS}?id=${id}&isMatch=${isMatch}&paymentRequestTypeId=${paymentRequestTypeId}`)
        }
        else {
            return _put(`${PAYMENT_REQUEST_REMOTE_OPERATIONS}?id=${id}&isMatch=${isMatch}`)
        }
    }

    postPaymentCycleData(data: Object): Promise<AxiosResponse<any>> {
        return _post(PAYMENT_CYCLE_API, data);
    }

    generatePayments(id: string): Promise<AxiosResponse<any>> {
        return _post(`${PAYMENT_CYCLE_API}/${id}/${GENERATE_PAYMENTS_API}`);
    }

    generatePaymentsForProvider(
        providerId?: string,
        serviceTypeId?: string,
        startDate?: string,
        endDate?: string,
        status?: [],
        isFasterPayments?: boolean,
        navigateFrom?: string,        
    ): Promise<AxiosResponse<any>> {
        let url = "";          
        status?.forEach((x, i) => {
            url += `&status=${x}`
        }); 
        if(navigateFrom == "PaymentRequestFailed"){
            return _get(
                `${PAYMENT_API}?providerId=${providerId}&serviceTypeId=${serviceTypeId}&dateRangeFrom=${startDate}&dateRangeTo=${endDate}&navigateFrom=dashboard` + url
                // `${PAYMENT_API}?providerId=${providerId}&serviceTypeId=${serviceTypeId}&dateRangeFrom=${startDate}&dateRangeTo=${endDate}&originatingBillableItemId=null` + url
            );
        } else if (navigateFrom == "PaymentWithFasterPayments") {            
            return _get(
                `${PAYMENT_API}?serviceTypeId=${serviceTypeId}&dateRangeFrom=${startDate}&dateRangeTo=${endDate}&showFasterPayment=${isFasterPayments}` + url
            );
        } else if (providerId && serviceTypeId && startDate && endDate && status && status?.length > 0) {            
            return _get(
                `${PAYMENT_API}?providerId=${providerId}&serviceTypeId=${serviceTypeId}&dateRangeFrom=${startDate}&dateRangeTo=${endDate}` + url
            );
        } else if (providerId && serviceTypeId && startDate && endDate) {
            return _get(
                `${PAYMENT_API}?providerId=${providerId}&serviceTypeId=${serviceTypeId}&dateRangeFrom=${startDate}&dateRangeTo=${endDate}`
            );
        } else if (providerId && serviceTypeId && startDate && status && status?.length > 0) {
            return _get(
                `${PAYMENT_API}?providerId=${providerId}&serviceTypeId=${serviceTypeId}&dateRangeFrom=${startDate}` + url
            );
        } else if (providerId && serviceTypeId && startDate) {
            return _get(
                `${PAYMENT_API}?providerId=${providerId}&serviceTypeId=${serviceTypeId}&dateRangeFrom=${startDate}`
            );
        } else if (serviceTypeId && startDate && status && status?.length > 0) {
                return _get(
                    `${PAYMENT_API}?&serviceTypeId=${serviceTypeId}&dateRangeFrom=${startDate}` + url
                );
        } else if (providerId && serviceTypeId && status && status?.length > 0) {
            return _get(`${PAYMENT_API}?providerId=${providerId}&serviceTypeId=${serviceTypeId}` + url);
        } else if (status && status?.length > 0 && serviceTypeId) {
            return _get(`${PAYMENT_API}?serviceTypeId=${serviceTypeId}`  + url);
        } else if (providerId && serviceTypeId) {
            return _get(`${PAYMENT_API}?providerId=${providerId}&serviceTypeId=${serviceTypeId}`);
        } else if (serviceTypeId && startDate && endDate) {
            return _get(
                `${PAYMENT_API}?serviceTypeId=${serviceTypeId}&dateRangeFrom=${startDate}&dateRangeTo=${endDate}`
            );
        } else if (isFasterPayments == true && serviceTypeId)  {
            return _get(`${PAYMENT_API}?serviceTypeId=${serviceTypeId}&showFasterPayment=${isFasterPayments}`);
        } else if (serviceTypeId) {
            return _get(`${PAYMENT_API}?serviceTypeId=${serviceTypeId}`);        
        } else {
            return _get(`${PAYMENT_API}`);
        }
    }

    // Populate the Payment form with the returned data.
    getPaymentEditViewFormData(id: number): Promise<AxiosResponse<any>> {
        return _get(`${PAYMENT_API}/${id}`);
    }

    // Payment Form update request
    uploadEditAddData(submitFomrData: object): Promise<AxiosResponse<any>> {
        return _post(PAYMENT_API, submitFomrData);
    }

    //Payment V2 service calls 
    generatePaymentsV2(id: string): Promise<AxiosResponse<any>> {
        return _post(`${NEW_PAYMENT_CYCLE_API}/${id}/${GENERATE_PAYMENTS_API}`);
    }
    postPaymentCycleDataV2(data: Object): Promise<AxiosResponse<any>> {
        return _post(NEW_PAYMENT_CYCLE_API, data);
    }
    lockPaymentsV2(data: object): Promise<AxiosResponse<any>> {
        return _post(NEW_PAYMENT_CYCLE_API, data);
    }

    getPaymentCycleByIdV2(id: string): Promise<AxiosResponse<any>> {
        return _get(`${NEW_PAYMENT_CYCLE_API}/${id}`);
    }
    getPaymentCycleExtractDocument(paymenCycleId:any):Promise<AxiosResponse<any>> {
        //return _get(`${PAYMENT_CYCLE_EXTRACT}/${paymenCycleId}`);
        return _setURL(`${PAYMENT_CYCLE_EXTRACT}/${paymenCycleId}`);
    }
    // cancel/orphan payment
    cancelPaymentById(id: string): Promise<AxiosResponse<any>> {
        return _put(`${CANCEL_PAYMENT_API}?id=${id}`)
    }

    //Triggers off the Logic app Endpoint.
    triggerLogicAppForPayPackRequested(formData: object): Promise<AxiosResponse<any>> {
        return _postBatchRequest(paymentTypeIndex.selfbillinvoice, formData);
    }

    triggerLogicAppForRemittanceRequest(formData: object): Promise<AxiosResponse<any>> {
        return _postBatchRequest(paymentTypeIndex.remittenceadvice, formData);
    }

    triggerLogicAppForPaymentAdviceRequest(formData: object): Promise<AxiosResponse<any>> {
        return _postBatchRequest(paymentTypeIndex.emailselfbill, formData);
    }
    triggerLogicAppForPaymentNotificationRequest(formData: object): Promise<AxiosResponse<any>> {
        return _postBatchRequest(paymentTypeIndex.paymentnotification, formData);
    }

    triggerLogicAppForPaymentEmailNotificationRequest(formData: object): Promise<AxiosResponse<any>> {
        return _postBatchRequest(paymentTypeIndex.paymentEmailNotification, formData);
    }
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default PaymentService;
