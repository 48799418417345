import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TextBox, TextArea, RadioGroup, SelectBox, CheckBox } from "devextreme-react";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface ArtistSignupDesignComponentProps extends RouteComponentProps {}

class ArtistSignupDesign extends Component<ArtistSignupDesignComponentProps> {
    componentDidMount() {}

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar artist-signup__page-content">
                    <div className="artist-signup__hero-header">
                        <div className="artist-signup__hero1">
                            <h2 className="artist-signup__title">Welcome to Event UK</h2>
                            <div className="artist-signup__hero-content">
                                <div>
                                    Helping pubs, bars & nightclubs source entertainment across the UK since 1998.
                                </div>
                                <div className="artist-signup__hero-emphasis">It pays to use us</div>
                            </div>
                        </div>
                        <div className="artist-signup__hero2">
                            <div className="artist-signup__hero-content">
                                <div>We manage venue bookings and reliable artist payments 365 days a year.</div>
                                <div className="artist-signup__hero-emphasis">
                                    Join our list of artists and be seen by venues near you
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <form className="full-width-form my-5">
                                    <section>
                                        <h3 className="artist-signup__section-heading">Start application</h3>
                                        <label>
                                            Artist's Name
                                            <TextBox placeholder="Name you perform or operate under" />
                                        </label>
                                        <label>
                                            Description of act or service
                                            <TextArea placeholder="e.g. music style, bands covered" height={120} />
                                        </label>
                                        <label>
                                            Do you already have work that requires payment from Event UK?
                                            <RadioGroup items={["Yes", "No"]} layout="horizontal" />
                                        </label>
                                        <label>
                                            <span className="sr-only">
                                                Please provide details on the work that requires payment from Event UK
                                            </span>
                                            <TextArea placeholder="Please provide details" height={120} />
                                        </label>
                                    </section>
                                    <section className="artist-signup__divider-section">
                                        <h3 className="artist-signup__section-heading">Start application</h3>
                                        <label>
                                            Title
                                            <SelectBox items={["Mr", "Mrs", "Ms", "Miss", "Dr"]} />
                                        </label>
                                        <label>
                                            First name
                                            <TextBox />
                                        </label>
                                        <label>
                                            Last name
                                            <TextBox />
                                        </label>
                                        <label>
                                            Mobile
                                            <TextBox />
                                        </label>
                                        <label>
                                            Landline
                                            <TextBox />
                                        </label>
                                        <label>
                                            Email
                                            <TextBox />
                                        </label>
                                    </section>
                                    <section className="artist-signup__divider-section">
                                        <h3 className="artist-signup__section-heading">Your professional portfolio</h3>
                                        <div>
                                            Please share relevant professional content here to aid your application
                                        </div>
                                        <label>
                                            Profile photo
                                            {/* replace this with upload button, make sure it has an "input" element somewhere */}
                                            <div className="mt-3">
                                                <button className="btn btn-primary full-width-form__button">
                                                    Upload
                                                </button>
                                            </div>
                                        </label>
                                        <label>
                                            Youtube link
                                            <TextBox />
                                        </label>
                                        <label>
                                            Website link
                                            <TextBox />
                                        </label>
                                        <label>
                                            Professional social media
                                            <TextBox placeholder="e.g. Facebook, Twitter" />
                                        </label>
                                        <label>
                                            <div className="font-weight-bold">Professional bio</div>
                                            Your artist bio will be a key part of your profile
                                            <TextArea
                                                placeholder="e.g. past experience, music genres, skills and services"
                                                height={120}
                                            />
                                        </label>
                                        <label>
                                            <div className="artist-signup__TsAndCs">Terms & Conditions</div>
                                            <div className="square-dx-checkbox">
                                                <CheckBox text="I / We confirm you have read and accepted Event UK’s terms and conditions" />
                                            </div>
                                        </label>
                                        <div>Accepted on 13/01/2020 at 13:49</div>
                                    </section>
                                    <div className="row mt-5">
                                        <div className="col-12 col-lg-4">
                                            <button className="btn btn-primary btn--large mt-3">Submit</button>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <button className="btn btn--ghost btn--ghost--teal btn--large mt-3">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ArtistSignupDesign);
