import React from "react";
import gridUtils from "../GridUtilities";
import { SubServiceType } from "../../../common/ServiceUtilities";

export interface CheckInOutCellProps {
    data: any;
}

export interface CheckInOutCellState {
    CheckInOutValue: string[];
}

class CheckInOutCell extends React.Component<CheckInOutCellProps> {
    state: CheckInOutCellState;
    gridUtils: gridUtils;

    constructor(props: CheckInOutCellProps) {
        super(props);

        this.gridUtils = new gridUtils();
        this.state = {
            CheckInOutValue: this.gridUtils.convertStringToStringArray(
                this.props.data.data.check_In_Out
                    ? this.props.data.data.check_In_Out
                    : this.props.data.data.checkInCheckOut
            ),
        };
    }

    render() {
        let checkInClass = "";
        let checkOutClass = "";
        const {
            checkin_Color,
            checkin_Font,
            checkOut_Color,
            checkOut_Font,
            checkInColor,
            checkOutColor,
            checkInFont,
            checkOutFont,
            checkInCheckOut,
            subTypeId,
        } = this.props.data.data;

        if ((checkin_Color || checkInColor) == "Red" && (checkin_Font || checkInFont) == "Bold") {
            checkInClass = "check-wrong mr-1";
        } else if ((checkin_Color || checkInColor) == "Green" && (checkin_Font || checkInFont) == "Bold") {
            checkInClass = "check-perfect mr-1";
        } else if ((checkin_Color || checkInColor) == "Red" && (!checkin_Font || !checkInFont)) {
            checkInClass = "error-status mr-1";
        } else if ((checkin_Color || checkInColor) == "Green" && (!checkin_Font || !checkInFont)) {
            checkInClass = "correct-status mr-1";
        } else {
            checkInClass = "normal-status mr-1";
        }

        if ((checkOut_Color || checkOutColor) == "Red" && (checkOut_Font || checkOutFont) == "Bold") {
            checkOutClass = "check-wrong";
        } else if ((checkOut_Color || checkOutColor) == "Green" && (checkOut_Font || checkOutFont) == "Bold") {
            checkOutClass = "check-perfect";
        } else if ((checkOut_Color || checkOutColor) == "Red" && (!checkOut_Font || !checkOutFont)) {
            checkOutClass = "error-status";
        } else if ((checkOut_Color || checkOutColor) == "Green" && (!checkOut_Font || !checkOutFont)) {
            checkOutClass = "correct-status";
        } else {
            checkOutClass = "normal-status";
        }

        return (
            <div className="container-fluid">
                {!checkInCheckOut && subTypeId == SubServiceType.DoorSupervisionEquipment ? (
                    <></>
                ) : (
                    <div className="row">
                        <span className={checkInClass}> {`${this.state.CheckInOutValue[0]}`} </span>
                        <span className={checkOutClass}> {`${" - "}${this.state.CheckInOutValue[1]}`}</span>
                    </div>
                )}
            </div>
        );
    }
}

export default CheckInOutCell;
