import { RateResponse, PrimarySecurityContact } from "../../services/SupplierInformationService";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;
export interface SupplierInformationData {
    venue: {
        dsLicenseCondition: string;
        dsUniformPolicy: string;
        houseNumber: string;
        venueName: string;
        address: string;
        city: string;
        county: string;
        postCode: string;
        phone: string;
        email: string;
        website: string;
        businessEntity: {
            shortName: string;
        }
        generalManager: {
            id: number;
            verified: string;
            contactTypeLookUpId: string;
            contactTypeOther: string;
            name: string;
            jobTitle: string;
            email: string;
            businessTelphone: string;
            mobileTelephone: string;
            otherTelephone: string;
            addressStreet1: string;
            addressStreet2: string;
            addressStreet3: string;
            city: string;
            state: string;
            country: string;
            postCode: string;
            dynamicsContactID: string;
            preferredContact: boolean;
            emailNotification: boolean;
            emailRemittance: boolean;
            emailInvoice: boolean;
            emailSelfInvoice: boolean;
            phone: boolean;
            text: boolean;
            fax: boolean;
            mail: boolean;
            gdprOptout: boolean;
            dateOfBirth: string;
            utr: string;
            ni: string;
            active: string;
            title: string;
            firstName: string;
            lastName: string;
            primaryCleaningContactId: string;
            primarySecurityContactId: string;
            cleaningProviderBusinessEntityID: string;
            securityProviderBusinessEntityID: string;
            addressLongitude: string;
            addressLatitude: string;
            lastUpdatedBy: number;
            lastUpdatedOn: string;
            contactTypeLookUp: string;
            billableItem: [];
            payment: [];
            contractCleaningBusinessEntity: string;
            securityProviderBusinessEntity: string;
            primarySecurityContact: string;
            primaryCleaningContact: string;
            user: string;
        };
        contact: {
            securityProviderBusinessEntity: {
                shortName: string;
                officePhone: string;
                oooProtocol: string;
                website: string;
                email: string;
                openingHours: string;
                dsProviderInformation: string;
            };
            primarySecurityContact: PrimarySecurityContact;
            primaryCleaningContact: {
                name: string;
                mobileTelephone: string;
            };
        };
    };
    personnelRates: RateResponse[];
    equipmentRates: RateResponse[];
    exceptionalRates: [
        {
            typeLookValue: string;
            name: string;
            idValue: string;
            rate_Higher: number;
            rateText: string;
        }
    ];
    enhancements: [
        {
            date: string;
            multiplier: string;
            enhancementText: string;
        }
    ];
}

export default class SupplierInformationUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
    }
    initializeSupplierInformationItem = (): SupplierInformationData => {
        var formData: SupplierInformationData = {
            venue: {
                dsLicenseCondition: "",
                dsUniformPolicy: "",
                houseNumber: "",
                venueName: "",
                address: "",
                city: "",
                county: "",
                postCode: "",
                phone: "",
                email: "",
                website: "",
                businessEntity: {
                    shortName: ""
                },
                generalManager: {
                    id: 0,
                    verified: "",
                    contactTypeLookUpId: "",
                    contactTypeOther: "",
                    name: "",
                    jobTitle: "",
                    email: "",
                    businessTelphone: "",
                    mobileTelephone: "",
                    otherTelephone: "",
                    addressStreet1: "",
                    addressStreet2: "",
                    addressStreet3: "",
                    city: "",
                    state: "",
                    country: "",
                    postCode: "",
                    dynamicsContactID: "",
                    preferredContact: false,
                    emailNotification: false,
                    emailRemittance: false,
                    emailInvoice: false,
                    emailSelfInvoice: false,
                    phone: false,
                    text: false,
                    fax: false,
                    mail: false,
                    gdprOptout: false,
                    dateOfBirth: "",
                    utr: "",
                    ni: "",
                    active: "",
                    title: "",
                    firstName: "",
                    lastName: "",
                    primaryCleaningContactId: "",
                    primarySecurityContactId: "",
                    cleaningProviderBusinessEntityID: "",
                    securityProviderBusinessEntityID: "",
                    addressLongitude: "",
                    addressLatitude: "",
                    lastUpdatedBy: 0,
                    lastUpdatedOn: "",
                    contactTypeLookUp: "",
                    billableItem: [],
                    payment: [],
                    contractCleaningBusinessEntity: "",
                    securityProviderBusinessEntity: "",
                    primarySecurityContact: "",
                    primaryCleaningContact: "",
                    user: "",
                },
                contact: {
                    securityProviderBusinessEntity: {
                        shortName: "",
                        officePhone: "",
                        oooProtocol: "",
                        website: "",
                        email: "",
                        openingHours: "",
                        dsProviderInformation: "",
                    },
                    primarySecurityContact: {
                        id: 0,
                        verified: "",
                        contactTypeLookUpId: "",
                        contactTypeOther: "",
                        name: "",
                        jobTitle: "",
                        email: "",
                        businessTelphone: "",
                        mobileTelephone: "",
                        otherTelephone: "",
                        addressStreet1: "",
                        addressStreet2: "",
                        addressStreet3: "",
                        city: "",
                        state: "",
                        country: "",
                        postCode: "",
                        dynamicsContactID: "",
                        preferredContact: false,
                        emailNotification: false,
                        emailRemittance: false,
                        emailInvoice: false,
                        emailSelfInvoice: false,
                        phone: false,
                        text: false,
                        fax: false,
                        mail: false,
                        gdprOptout: false,
                        dateOfBirth: "",
                        utr: "",
                        ni: "",
                        active: "",
                        title: "",
                        firstName: "",
                        lastName: "",
                        primaryCleaningContactId: "",
                        primarySecurityContactId: "",
                        cleaningProviderBusinessEntityID: "",
                        securityProviderBusinessEntityID: "",
                        addressLongitude: "",
                        addressLatitude: "",
                        lastUpdatedBy: 0,
                        lastUpdatedOn: "",
                        contactTypeLookUp: "",
                        billableItem: [],
                        payment: [],
                        contractCleaningBusinessEntity: "",
                        securityProviderBusinessEntity: "",
                        primarySecurityContact: "",
                        primaryCleaningContact: "",
                        user: "",
                    },
                    primaryCleaningContact: {
                        name: "",
                        mobileTelephone: "",
                    },
                },
            },
            personnelRates: [],
            equipmentRates: [],
            exceptionalRates: [
                {
                    typeLookValue: "",
                    name: "",
                    idValue: "",
                    rate_Higher: 0,
                    rateText: "",
                },
            ],
            enhancements: [
                {
                    date: "",
                    multiplier: "",
                    enhancementText: "",
                },
            ],
        };
        return formData;
    };
}
