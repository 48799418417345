import { ServiceFeeFormResponse, ServiceFeeFormRequest } from "../../services/ServiceFeesService";
import lookupService, {LookupTypeItem } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import React from "react";
import sharedUtils from "../grid/sharedUtilities";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";

type NullableBool = boolean | null | undefined;
export interface ServiceFeeFormUtilProps {}
export interface AddEditServiceFeeFormData {
    id: string;
    clientBusinessEntityId: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    serviceFeeCalculationLookUpId: string;
    multiplier: number;
    fasterPayMultiplier: number;
    approvalVisibilityOffset: number;
    approvalVisibilityOffsetBatch: number;
    approvalVisibilityOffsetImport: number;
    twoTierRequestApprovalMode :string;
    twoTierRequestApprovalAmount:number;
    twoTierBillableApprovalMode:string;
    twoTierBillableApprovalAmount:number;
    paymentOffset: number;
    makeOfferLowerAdjustment: number;
    makeOfferUpperAdjustment: number;
    marketPlaceReduction:number;
    marketPlaceReduction01:number;
    marketPlaceReduction02: number;
    marketPlaceReduction03: number;
    marketPlaceReduction04: number;
    marketPlaceReduction05: number;
    marketPlaceReduction06: number;
    marketPlaceReduction07: number;
    marketPlaceReduction08: number;
    marketPlaceReduction09: number;
    marketPlaceReduction10: number;
    marketPlaceReduction11: number;
    marketPlaceReduction12: number;
    onlyBillApprovedItems:boolean;
    approvalVisibilityDate: NullableDate;
    refund: boolean;
    fasterPayAvailable: boolean;
    visibilityDateOverride: boolean;
    earlyPay: boolean;
    minGrossProfitValue: number;
    maxGrossProfitValue: number;
    isActive: boolean;
    isAccruals: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    providerAcceptances: boolean;
    budgetTypeId: string;
    budgetType: string;
}

export interface LookUpDetailsInformation {
    showProgressIndicator: boolean;
}
export default class ServiceFeeFormUtil extends React.Component<ServiceFeeFormUtilProps> {
    utils: sharedUtils;
    dropdownService: lookupService;
    state: LookUpDetailsInformation;
    constructor(props: ServiceFeeFormUtilProps) {
        super(props);
        this.utils = new sharedUtils();
        this.dropdownService = new lookupService();
        this.state = {
            showProgressIndicator: false,
        };
    }

    initializeServiceFeeFormItem = (): AddEditServiceFeeFormData => {
        var formData: AddEditServiceFeeFormData = {
            id: "0",
            clientBusinessEntityId: "",
            serviceTypeId: "",
            serviceSubTypeId: "",
            serviceFeeCalculationLookUpId: "",
            isActive: true,
            isAccruals: false,
            refund: true,
            fasterPayAvailable: false,
            visibilityDateOverride: false,
            earlyPay: false,
            multiplier: 0,
            fasterPayMultiplier: 0,
            approvalVisibilityOffset: 0,
            approvalVisibilityOffsetBatch: 0,
            approvalVisibilityOffsetImport: 0,
            twoTierRequestApprovalMode:"",
            twoTierRequestApprovalAmount:0,
            twoTierBillableApprovalMode:"",
            twoTierBillableApprovalAmount:0,
            paymentOffset: 0,
            makeOfferLowerAdjustment: 0,
            makeOfferUpperAdjustment: 0,
            marketPlaceReduction:0,
            marketPlaceReduction01: 0,
            marketPlaceReduction02: 0,
            marketPlaceReduction03: 0,
            marketPlaceReduction04: 0,
            marketPlaceReduction05: 0,
            marketPlaceReduction06: 0,
            marketPlaceReduction07: 0,
            marketPlaceReduction08: 0,
            marketPlaceReduction09: 0,
            marketPlaceReduction10: 0,
            marketPlaceReduction11: 0,
            marketPlaceReduction12: 0,
            onlyBillApprovedItems:false,
            approvalVisibilityDate: undefined,
            minGrossProfitValue: 0,
            maxGrossProfitValue: 999999,
            lastUpdatedOn: "",
            lastUpdatedBy: "",
            providerAcceptances: false,
            budgetTypeId: "",
            budgetType: ""
        };

        return formData;
    };

    // Convert the server response into the component's form state
    convertContractItemResponeToFormData = (serverResponse: ServiceFeeFormResponse): AddEditServiceFeeFormData => {
        var formData: AddEditServiceFeeFormData = {
            id: serverResponse.id,
            clientBusinessEntityId: serverResponse.clientBusinessEntityId,
            serviceTypeId: serverResponse.serviceTypeId,
            serviceSubTypeId: serverResponse.serviceSubTypeId,
            serviceFeeCalculationLookUpId: serverResponse.serviceFeeCalculationLookUpId,
            isActive: serverResponse.isActive,
            isAccruals: serverResponse.isAccruals,
            refund: serverResponse.refund,            
            fasterPayAvailable: serverResponse.fasterPayAvailable,
            visibilityDateOverride: serverResponse.visibilityDateOverride,
            earlyPay: serverResponse.earlyPay,
            multiplier: this.utils.convertStringToFloat(serverResponse.multiplier),
            fasterPayMultiplier: this.utils.convertStringToFloat(serverResponse.fasterPayMultiplier),
            approvalVisibilityOffset: this.utils.convertStringToInt(serverResponse.approvalVisibilityOffset),
            approvalVisibilityOffsetBatch: this.utils.convertStringToInt(serverResponse.approvalVisibilityOffsetBatch),
            approvalVisibilityOffsetImport: this.utils.convertStringToInt(serverResponse.approvalVisibilityOffsetImport),
            twoTierRequestApprovalMode:serverResponse.twoTierRequestApprovalMode,
            twoTierRequestApprovalAmount:this.utils.convertStringToInt(serverResponse.twoTierRequestApprovalAmount),
            twoTierBillableApprovalMode:serverResponse.twoTierBillableApprovalMode,
            twoTierBillableApprovalAmount:this.utils.convertStringToInt(serverResponse.twoTierBillableApprovalAmount),
            paymentOffset: this.utils.convertStringToInt(serverResponse.paymentOffset),
            makeOfferLowerAdjustment: this.utils.convertStringToFloat(serverResponse.makeOfferLowerAdjustment),
            makeOfferUpperAdjustment: this.utils.convertStringToFloat(serverResponse.makeOfferUpperAdjustment),
            marketPlaceReduction:this.utils.convertStringToFloat(serverResponse.marketPlaceReduction),
            marketPlaceReduction01: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction01),
            marketPlaceReduction02:this.utils.convertStringToFloat(serverResponse.marketPlaceReduction02),
            marketPlaceReduction03: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction03),
            marketPlaceReduction04: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction04),
            marketPlaceReduction05: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction05),
            marketPlaceReduction06: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction06),
            marketPlaceReduction07: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction07),
            marketPlaceReduction08: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction08),
            marketPlaceReduction09: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction09),
            marketPlaceReduction10: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction10),
            marketPlaceReduction11: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction11),
            marketPlaceReduction12: this.utils.convertStringToFloat(serverResponse.marketPlaceReduction12),
            onlyBillApprovedItems:serverResponse.onlyBillApprovedItems,
            approvalVisibilityDate: this.utils.convertStringToDate(serverResponse.approvalVisibilityDate),
            minGrossProfitValue: this.utils.convertStringToFloat(serverResponse.minGrossProfitValue),
            maxGrossProfitValue: this.utils.convertStringToFloat(serverResponse.maxGrossProfitValue),
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            lastUpdatedBy: serverResponse.lastUpdatedBy,
            providerAcceptances: serverResponse.providerAcceptances,
            budgetTypeId: serverResponse.budgetTypeId,
            budgetType: serverResponse.budgetType
        };

        return formData;
    };

    // Convert the form's data into a proper item submit request
    convertFormDataToContractItemRequest = (formData: AddEditServiceFeeFormData): ServiceFeeFormRequest => {
        var submitRequest: ServiceFeeFormRequest = {
            id: formData.id,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            serviceTypeId: formData.serviceTypeId,
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceFeeCalculationLookUpId: formData.serviceFeeCalculationLookUpId,
            isActive: formData.isActive,
            isAccruals: formData.isAccruals,
            refund: formData.refund,
            fasterPayAvailable: formData.fasterPayAvailable,
            visibilityDateOverride: formData.visibilityDateOverride,
            earlyPay: formData.earlyPay,
            multiplier: this.utils.convertNumberToString(formData.multiplier),
            fasterPayMultiplier: this.utils.convertNumberToString(formData.fasterPayMultiplier),
            approvalVisibilityOffset: this.utils.convertNumberToString(formData.approvalVisibilityOffset),
            approvalVisibilityOffsetBatch: this.utils.convertNumberToString(formData.approvalVisibilityOffsetBatch),
            approvalVisibilityOffsetImport: this.utils.convertNumberToString(formData.approvalVisibilityOffsetImport),
            twoTierRequestApprovalMode:formData.twoTierRequestApprovalMode,
            twoTierRequestApprovalAmount:this.utils.convertNumberToString(formData.twoTierRequestApprovalAmount),
            twoTierBillableApprovalMode:formData.twoTierBillableApprovalMode,
            twoTierBillableApprovalAmount:this.utils.convertNumberToString(formData.twoTierBillableApprovalAmount),
            paymentOffset: this.utils.convertNumberToString(formData.paymentOffset),
            marketPlaceReduction:this.utils.convertNumberToString(formData.marketPlaceReduction),
            marketPlaceReduction01:this.utils.convertNumberToString(formData.marketPlaceReduction01),
            marketPlaceReduction02:this.utils.convertNumberToString(formData.marketPlaceReduction02),
            marketPlaceReduction03:this.utils.convertNumberToString(formData.marketPlaceReduction03),
            marketPlaceReduction04:this.utils.convertNumberToString(formData.marketPlaceReduction04),
            marketPlaceReduction05:this.utils.convertNumberToString(formData.marketPlaceReduction05),
            marketPlaceReduction06:this.utils.convertNumberToString(formData.marketPlaceReduction06),
            marketPlaceReduction07:this.utils.convertNumberToString(formData.marketPlaceReduction07),
            marketPlaceReduction08:this.utils.convertNumberToString(formData.marketPlaceReduction08),
            marketPlaceReduction09:this.utils.convertNumberToString(formData.marketPlaceReduction09),
            marketPlaceReduction10:this.utils.convertNumberToString(formData.marketPlaceReduction10),
            marketPlaceReduction11:this.utils.convertNumberToString(formData.marketPlaceReduction11),
            marketPlaceReduction12:this.utils.convertNumberToString(formData.marketPlaceReduction12),
            onlyBillApprovedItems:formData.onlyBillApprovedItems,
            makeOfferLowerAdjustment: this.utils.convertNumberToString(formData.makeOfferLowerAdjustment),
            makeOfferUpperAdjustment: this.utils.convertNumberToString(formData.makeOfferUpperAdjustment),
            approvalVisibilityDate: this.utils.convertDateToString(formData.approvalVisibilityDate),
            minGrossProfitValue: this.utils.convertNumberToString(
                formData.minGrossProfitValue == 0 ? 0 : formData.minGrossProfitValue
            ),
            maxGrossProfitValue: this.utils.convertNumberToString(formData.maxGrossProfitValue),
            providerAcceptances: formData.providerAcceptances,
            budgetTypeId: formData.budgetTypeId,
            budgetType: formData.budgetType
        };
        return submitRequest;
    };

    lookUpCreation = (response: AxiosResponse<any>): LookupTypeItem[] => {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };
}
