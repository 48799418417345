import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { TextArea, LoadPanel, SelectBox, NumberBox } from "devextreme-react";
import CardWrapper from "../components/Cards/CardWrapper";
import CheckinCardAlt from "../components/Cards/CheckinCardAlt";
import CardWrapper3Part from "../components/Cards/CardWrapper3Part";
import CardCommentsSection from "../components/Cards/CardSections/CardCommentsSection";
import CardHeaderSection from "../components/Cards/CardSections/CardHeaderSection";
import ExampleCard from "../components/Cards/ExampleCard";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import ExampleLargeLightCard from "../components/Cards/ExampleLargeLightCard";
import ExampleLargeLightCardWithHeader from "../components/Cards/ExampleLargeLightCardWithHeader";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";

interface DesignComponentsComponentProps extends RouteComponentProps {}

class DesignComponents extends Component<DesignComponentsComponentProps> {
    handleChangeComments = () => {};
    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <section className="page-content--with-sidebar-hidden-mobile" role="main">
                    <h2>Cards</h2>
                    <h3>Security</h3>
                    <h3>Cleaning</h3>
                    <div className="row mt-3">
                        <div className="col-12 col-lg-4 mt-3">
                            <div className={"checkin-card checkin-card--cleaner checkin-card--manual"}>
                                <div className="checkin-card__category">
                                    Contract Cleaning
                                    <span>- Weekly Cleaning</span>
                                    <span className="float-right">1-100</span>
                                </div>
                                <div className="checkin-card__content">
                                    <span className="text-danger font-weight-bold">error message</span>
                                    <div className="row">
                                        <div className="col">
                                            <h4>Venue Name</h4> <p>WD3 5BL</p>
                                        </div>
                                        <span className="text-warning font-weight-bold float-right">Adjustment</span>
                                    </div>
                                    <div className="mt-3 checkin-card__licence-no font-weight-bold">
                                        Thu 6 August 2020 to Wed 12 August 2020
                                    </div>

                                    <div className="row my-3">
                                        <div className="col-12 text-left">
                                            <span className="font-weight-bold">
                                                "These are your contracted cleaning hours for the above period"
                                            </span>
                                        </div>
                                    </div>

                                    <div className="card_border cleaning-card__weekly">
                                        <div className="row mt-2 font-weight-bold">
                                            Please define the number of hours worked
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-3">
                                                <NumberBox
                                                    format="#"
                                                    valueChangeEvent="keyup"
                                                    placeholder="0"
                                                    value={40}
                                                />
                                            </div>
                                            <span className="font-weight-bold">Hours</span>
                                            <div className="col-4">
                                                <SelectBox
                                                    displayExpr="value"
                                                    valueExpr="id"
                                                    value={"this.state.minutes"}
                                                />
                                            </div>
                                            <span className="font-weight-bold">Minutes</span>
                                        </div>
                                        <div className="row my-2">
                                            {
                                                <div className="col-1 text-center mr-2">
                                                    <p>{"item.day"}</p>
                                                    <p>{"item.count"}</p>
                                                </div>
                                            }
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-9 text-right font-weight-bold">
                                                "Total Hours" : "Previously Billed Hours"
                                            </div>
                                            <div className="col text-left font-weight-bold">
                                                {"equipmentOriginalQuantity"}
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-9 text-right text-warning font-weight-bold">
                                                Hours Adjustment
                                            </div>
                                            <div className="col-2 text-center text-warning font-weight-bold">
                                                'equipmentTotalBillableQuantity : hoursApproved'
                                            </div>
                                        </div>
                                        <div className="horizontal_dotted_line" />
                                        <div className="row mt-2">
                                            <div className="col-9 text-right font-weight-bold">
                                                "Total Hours" : "Total Hours Adjustment" : "Total hours to approve"
                                            </div>
                                            <div className="col-2 text-center font-weight-bold">
                                                {"equipmentBillableQuantity or equipmentTotalBillableQuantity"}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card_border cleaning-card__additional">
                                        <div className="row my-3">
                                            <div className="col-12 text-left">
                                                {"otherDescription"}
                                                <span className="float-right font-weight-bold mr-2">
                                                    {"displayRate[0]"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">Authorised by</div>
                                            <div className="col-9 font-weight-bold text-center">
                                                {"additionalCleaningText"}
                                            </div>
                                        </div>
                                    </div>

                                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={false} />

                                    <div className="row my-3">
                                        <div className="col-12 text-center">Comments</div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <TextArea height={80} />
                                        </div>
                                        <div className="col-12 text-center mt-3">cardText</div>
                                    </div>

                                    <div className="row my-3">
                                        <div className="col-6">
                                            <button className="btn btn--large btn-primary" type="button">
                                                <span className=".btn__icon">
                                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                                </span>
                                                <span className="ml-2">Raise Query</span>
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn--large btn-primary" type="button">
                                                CTA
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 className="mt-5">New Card Design</h3>
                        <h4>Light mode</h4>
                        <div className="row mt-2">
                            <div className="col-4">
                                <CardWrapper type="cleaner" typeTitle="cleaner" enumeration={false} fullHeight={true}>
                                    <div className="euk-card__content-section">lots of info</div>
                                </CardWrapper>
                            </div>
                            <div className="col-4">
                                <CardWrapper
                                    enumeration={false}
                                    type="entertainer"
                                    typeTitle="entertainment"
                                    fullHeight={true}
                                >
                                    <div className="euk-card__content-section">
                                        <div className="euk-card__title">The Red Lion, London</div>
                                        <div>1 Horsham Road, London ER4 2UA 0208 273 2947</div>
                                    </div>
                                    <div className="euk-card__content-section--alt">lots of info</div>
                                    <div className="euk-card__content-section">lots of info</div>
                                    <div className="euk-card__content-section--highlight">lots of info</div>
                                </CardWrapper>
                            </div>
                            <div className="col-4">
                                <CheckinCardAlt
                                    type="security"
                                    typeTitle="security - door managemeent"
                                    enumeration={false}
                                />
                            </div>
                        </div>
                        <div className="dark-mode mt-4 py-4">
                            <h4>Dark mode</h4>
                            <div className="row mt-2">
                                <div className="col-4">
                                    <CardWrapper
                                        type="cleaner"
                                        typeTitle="cleaner"
                                        enumeration={true}
                                        cardsTotal={3}
                                        cardIndex={1}
                                        fullHeight={true}
                                    >
                                        <div className="euk-card__content-section">lots of info</div>
                                    </CardWrapper>
                                </div>
                                <div className="col-4">
                                    <CardWrapper
                                        type="entertainer"
                                        typeTitle="entertainment"
                                        enumeration={true}
                                        cardsTotal={3}
                                        cardIndex={2}
                                        fullHeight={true}
                                    >
                                        <div className="euk-card__content-section">
                                            <div className="euk-card__title">The Red Lion, London</div>
                                            <div>1 Horsham Road, London ER4 2UA 0208 273 2947</div>
                                        </div>
                                        <div className="euk-card__content-section--alt">lots of info</div>
                                        <div className="euk-card__content-section">lots of info</div>
                                        <div className="euk-card__content-section--highlight">lots of info</div>
                                    </CardWrapper>
                                </div>
                                <div className="col-4">
                                    <CheckinCardAlt
                                        type="security"
                                        typeTitle="security - door managemeent"
                                        enumeration={true}
                                        cardIndex={3}
                                        cardsTotal={3}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <CardWrapper3Part
                                        type="entertainer"
                                        typeTitle="Entertainment"
                                        enumeration={false}
                                        header={
                                            <CardHeaderSection
                                                venue="The Red Lion London"
                                                postcode="N1 1EV​"
                                                date="Mon 17 Aug 2020 to Sun 23 Aug 2020​"
                                                message="This is the security equipment supplied to the above venue in the above period.​"
                                                adjustment={false}
                                                provider = {""}
                                                serviceSubTypeId = {""}
                                                client = {""}
                                            />
                                        }
                                        // body={<CardConfirmHoursSection />}
                                        footer={
                                            <CardCommentsSection
                                                message="Please approve or raise a query"
                                                comments=""
                                                handleChangeComments={this.handleChangeComments}
                                                buttonText=""
                                                queryButtonText=""
                                                onSubmitQuery={() => {}}
                                                handleSubmit={() => {}}
                                                handleSave={() => {}}
                                                disableApproveButton={false}
                                                disableQueryButton={false}
                                                disableSaveButton={false}
                                            />
                                        }
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <ExampleCard />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 py-4">
                            <h4>Large Light Card Without Header</h4>
                            <ExampleLargeLightCard />
                            <h4 className="mt-5">Large Light Card With Header</h4>
                            <ExampleLargeLightCardWithHeader />
                        </div>
                    </div>
                    <hr />
                    <h2>Accordion</h2>
                    <h3>Light</h3>
                    <div className="accordion" id="queriesAccordion">
                        <div className="card-accordion__section--security">
                            <h4 id="queriesHeadingSecurity">
                                <button
                                    className="btn collapsed card-accordion__header--security"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#queries-security-section"
                                    aria-expanded="false"
                                    aria-controls="queries-security-section"
                                >
                                    Door Supervision (3)
                                    <span className="card-accordion__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </h4>

                            <div
                                id="queries-security-section"
                                className="collapse card-accordion__section-content--security"
                                aria-labelledby="queriesHeadingSecurity"
                                data-parent="#queriesAccordion"
                            >
                                <div className="mb-3">
                                    <ExampleLargeLightCardWithHeader />
                                </div>
                                <div className="mb-3">
                                    <ExampleLargeLightCardWithHeader />
                                </div>
                                <div className="mb-3">
                                    <ExampleLargeLightCardWithHeader />
                                </div>
                            </div>
                        </div>
                        <div className="card-accordion__section--cleaning">
                            <h4 id="queriesHeadingCleaning">
                                <button
                                    className="btn collapsed card-accordion__header--cleaning"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#queries-cleaning-section"
                                    aria-expanded="false"
                                    aria-controls="queries-cleaning-sectiong"
                                >
                                    Cleaning (4)
                                    <span className="card-accordion__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </h4>

                            <div
                                id="queries-cleaning-section"
                                className="collapse card-accordion__section-content--cleaning"
                                aria-labelledby="queriesHeadingCleaning"
                                data-parent="#queriesAccordion"
                            >
                                <div className="mb-3">
                                    <ExampleLargeLightCardWithHeader />
                                </div>
                                <div className="mb-3">
                                    <ExampleLargeLightCardWithHeader />
                                </div>
                                <div className="mb-3">
                                    <ExampleLargeLightCardWithHeader />
                                </div>
                            </div>
                        </div>
                        <div className="card-accordion__section--entertainment">
                            <h4 id="queriesHeadingEntertainment">
                                <button
                                    className="btn collapsed card-accordion__header--entertainment"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#queries-entertainment-section"
                                    aria-expanded="false"
                                    aria-controls="queries-entertainment-section"
                                >
                                    Entertainment (4)
                                    <span className="card-accordion__icon">
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    </span>
                                </button>
                            </h4>

                            <div
                                id="queries-entertainment-section"
                                className="collapse card-accordion__section-content--entertainment"
                                aria-labelledby="queriesHeadingEntertainment"
                                data-parent="#queriesAccordion"
                            >
                                <div className="mb-3">
                                    <ExampleLargeLightCardWithHeader />
                                </div>
                                <div className="mb-3">
                                    <ExampleLargeLightCardWithHeader />
                                </div>
                                <div className="mb-3">
                                    <ExampleLargeLightCardWithHeader />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h3>Dark</h3>
                    <div className="dark-mode">
                        <div className="accordion" id="approvalsAccordion">
                            <div className="card-accordion__section--security">
                                <h4 id="approvalsHeadingSecurity">
                                    <button
                                        className="btn collapsed card-accordion__header--security"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#approvals-security-section"
                                        aria-expanded="false"
                                        aria-controls="approvals-security-section"
                                    >
                                        Door Supervision (3)
                                        <span className="card-accordion__icon">
                                            <FontAwesomeIcon icon={faChevronUp} />
                                        </span>
                                    </button>
                                </h4>

                                <div
                                    id="approvals-security-section"
                                    className="collapse card-accordion__section-content--security"
                                    aria-labelledby="approvalsHeadingSecurity"
                                    data-parent="#approvalsAccordion"
                                >
                                    <div className="row mt-2">
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4 col-xs-12 mb-3">
                                            <CardWrapper
                                                enumeration={false}
                                                type="entertainer"
                                                typeTitle="entertainment"
                                                fullHeight={true}
                                            >
                                                <div className="euk-card__content-section">lots of info</div>
                                            </CardWrapper>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4 col-xs-12 mb-3">
                                            <CardWrapper
                                                enumeration={false}
                                                type="entertainer"
                                                typeTitle="entertainment"
                                                fullHeight={true}
                                            >
                                                <div className="euk-card__content-section">lots of info</div>
                                            </CardWrapper>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4 col-xs-12 mb-3">
                                            <CardWrapper
                                                enumeration={false}
                                                type="entertainer"
                                                typeTitle="entertainment"
                                                fullHeight={true}
                                            >
                                                <div className="euk-card__content-section">lots of info</div>
                                            </CardWrapper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-accordion__section--cleaning">
                                <h4 id="approvalsHeadingCleaning">
                                    <button
                                        className="btn collapsed card-accordion__header--cleaning"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#approvals-cleaning-section"
                                        aria-expanded="false"
                                        aria-controls="approvals-cleaning-sectiong"
                                    >
                                        Cleaning (4)
                                        <span className="card-accordion__icon">
                                            <FontAwesomeIcon icon={faChevronUp} />
                                        </span>
                                    </button>
                                </h4>

                                <div
                                    id="approvals-cleaning-section"
                                    className="collapse card-accordion__section-content--cleaning"
                                    aria-labelledby="approvalsHeadingCleaning"
                                    data-parent="#approvalsAccordion"
                                >
                                    <div className="row mt-2">
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4 col-xs-12 mb-3">
                                            <CardWrapper
                                                enumeration={false}
                                                type="entertainer"
                                                typeTitle="entertainment"
                                                fullHeight={true}
                                            >
                                                <div className="euk-card__content-section">lots of info</div>
                                            </CardWrapper>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4 col-xs-12 mb-3">
                                            <CardWrapper
                                                enumeration={false}
                                                type="entertainer"
                                                typeTitle="entertainment"
                                                fullHeight={true}
                                            >
                                                <div className="euk-card__content-section">lots of info</div>
                                            </CardWrapper>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4 col-xs-12 mb-3">
                                            <CardWrapper
                                                enumeration={false}
                                                type="entertainer"
                                                typeTitle="entertainment"
                                                fullHeight={true}
                                            >
                                                <div className="euk-card__content-section">lots of info</div>
                                            </CardWrapper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-accordion__section--entertainment">
                                <h4 id="approvalsHeadingEntertainment">
                                    <button
                                        className="btn collapsed card-accordion__header--entertainment"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#approvals-entertainment-section"
                                        aria-expanded="false"
                                        aria-controls="approvals-entertainment-section"
                                    >
                                        Entertainment (4)
                                        <span className="card-accordion__icon">
                                            <FontAwesomeIcon icon={faChevronUp} />
                                        </span>
                                    </button>
                                </h4>

                                <div
                                    id="approvals-entertainment-section"
                                    className="collapse card-accordion__section-content--entertainment"
                                    aria-labelledby="approvalsHeadingEntertainment"
                                    data-parent="#approvalsAccordion"
                                >
                                    <div className="row mt-2">
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4 col-xs-12 mb-3">
                                            <CardWrapper
                                                enumeration={false}
                                                type="entertainer"
                                                typeTitle="entertainment"
                                                fullHeight={true}
                                            >
                                                <div className="euk-card__content-section">lots of info</div>
                                            </CardWrapper>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4 col-xs-12 mb-3">
                                            <CardWrapper
                                                enumeration={false}
                                                type="entertainer"
                                                typeTitle="entertainment"
                                                fullHeight={true}
                                            >
                                                <div className="euk-card__content-section">lots of info</div>
                                            </CardWrapper>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4 col-xs-12 mb-3">
                                            <CardWrapper
                                                enumeration={false}
                                                type="entertainer"
                                                typeTitle="entertainment"
                                                fullHeight={true}
                                            >
                                                <div className="euk-card__content-section">lots of info</div>
                                            </CardWrapper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(DesignComponents);
