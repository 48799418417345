import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import StaticContentService from "../../services/StaticContentService";
import { AxiosResponse } from "axios";
import { LoadIndicator } from "devextreme-react";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import DatePanel from "../side-bar/DatePanel";
import BulletinPanel from "../BulletinPanel/BulletinPanel";

interface FooterFileComponentProps extends RouteComponentProps {
    FileName: string;
}

// State
interface FooterFileComponentState {
    content: string;
    showLoadIndicator: boolean;
}

class FooterFile extends Component<FooterFileComponentProps> {
    state: FooterFileComponentState;
    staticContentService: StaticContentService;

    constructor(props: FooterFileComponentProps) {
        super(props);
        this.staticContentService = new StaticContentService();
        this.state = {
            content: "",
            showLoadIndicator: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({
            showLoadIndicator: true,
        });
        this.staticContentService
            .get(this.props.FileName)
            .then(this.handleSuccess)
            .catch((err) => {
                var respMessage: string = "get content failed with response: " + JSON.stringify(err);

                if (!this.staticContentService.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }

                this.handleError();
            });
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            content: response.data,
            showLoadIndicator: false,
        });
    };

    handleError = () => {
        this.setState({
            content: "There was a problem loading the content. Please contact Event UK if you need more assistance.",
            showLoadIndicator: false,
        });
    };

    render() {
        return (
            <>
                <LightModePageTemplate>
                    <SideBarTemplate isFixed={true}>
                        <DatePanel />
                        <BulletinPanel />
                    </SideBarTemplate>
                    <div className="page-content--with-sidebar-hidden-mobile">
                        <section className="col">
                            <LoadIndicator
                                height={60}
                                width={60}
                                visible={this.state.showLoadIndicator}
                                style={{
                                    position: "absolute",
                                    left: "50%",
                                    top: "20px",
                                }}
                            />
                            <article className="article" dangerouslySetInnerHTML={{ __html: this.state.content }} />
                        </section>
                    </div>
                </LightModePageTemplate>
            </>
        );
    }
}

export default withRouter(FooterFile);
