import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import OutstandingApprovalsCardList from "./OutstandingApprovalsCardList";
import DarkModePageTemplate from "../page/Templates/DarkModePageTemplate";
import UserService, { RoleGroupNames } from "../../services/UserService";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import DatePanel from "../side-bar/DatePanel";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import VenueFilterPanel from "../Query/VenueFilterPanel";

interface OutstandingApprovalsPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
}

interface OutstandingApprovalsPageComponentState {
    refreshCount: boolean;
    cardCount: number;
    clientId: string;
    venueId: string;
    status: string;
    serviceTypeId: string;
}

class OutstandingApprovalsPage extends Component<OutstandingApprovalsPageComponentProps> {
    state: OutstandingApprovalsPageComponentState;
    constructor(props: OutstandingApprovalsPageComponentProps) {
        super(props);
        this.state = {
            refreshCount: false,
            cardCount: 0,
            clientId: "",
            venueId: "",
            status: "",
            serviceTypeId: this.props.location.state && this.props.location.state.serviceTypeId ? this.props.location.state.serviceTypeId : "",
        };
    }

    updateSidePanel = (count: number) => {
        this.setState((prevState: OutstandingApprovalsPageComponentState) => ({
            refreshCount: !prevState.refreshCount,
            cardCount: prevState.cardCount - 1,
        }));
    };

    //A parent callback function that will update the card count on the page header.
    setCardCount = (cardCount: number) => {
        this.setState({
            cardCount: cardCount,
        });
    };

    handleApplyButtonClick = (clientId: string, venueId: string) => {
        this.setState({
            clientId: clientId ? clientId : "",
            venueId: venueId ? venueId : "",
            status: this.props.location.state && this.props.location.state.status ? this.props.location.state.status : "",
            serviceTypeId: this.props.location.state && this.props.location.state.serviceTypeId ? this.props.location.state.serviceTypeId : "",
        });
    };

    renderOutStandingApprovalCardList = () => {
        return (
            <OutstandingApprovalsCardList
                parentCallback={this.updateSidePanel}
                updateCardCount={this.setCardCount}
                status={this.state.status}
                serviceTypeId={this.state.serviceTypeId}
                clientId={this.state.clientId}
                venueId={this.state.venueId}
            />
        );
    };

    render() {
        var { cardCount } = this.state;
        var outstandingCardsTotal: string = cardCount > -1 ? `(${cardCount})` : "";
        var header = "";
        if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            header = `Approvals ${outstandingCardsTotal}`;
        } else if (
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
        ) {
            if (this.props.location.state.status == "Approvals") {
                header = `Approvals ${outstandingCardsTotal}`;
            } else if (this.props.location.state.status == "Acceptances") {
                header = `Client Amendments ${outstandingCardsTotal}`;
            }
        } else if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
            header = `Client Amendments ${outstandingCardsTotal}`;
        }
        return (
            <DarkModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DatePanel />
                    <VenueFilterPanel
                        displayText={true}
                        onApplyButtonClick={this.handleApplyButtonClick}
                        serviceTypeId={this.props.location.state ? this.props.location.state.serviceTypeId : ""}
                        status={this.props.location.state ? this.props.location.state.status : ""}
                    />
                    <BulletinPanel />
                </SideBarTemplate>
                <div id="todaysView" className="page-content--with-sidebar-hidden-mobile">
                    <section className="container--no-padding">
                        <header>
                            <h2 className="page-title__lightgrey pb-2">{header}</h2>
                        </header>
                        {this.renderOutStandingApprovalCardList()}
                    </section>
                </div>
            </DarkModePageTemplate>
        );
    }
}

export default withRouter(OutstandingApprovalsPage);
