import { faClock, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { closeDrawer } from "./NavigationUtils";

export const renderDoorSupervisorMainNavigationItems = (): ReactNode => {
    return (
        <ul className="nav-drawer__quick-links navbar-nav">
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link
                    className="nav-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/todaysViewCheckinOut",
                        state: {
                            id: "",
                        },
                    }}
                >
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faClock} />
                    </span>
                    Today's Check In & Out
                </Link>
            </li>
            <li className="nav-item btn btn--ghost btn--ghost--light btn--large">
                <Link
                    className="nav-link"
                    onClick={closeDrawer}
                    to={{
                        pathname: "/viewQuery",
                        state: {
                            id: "",
                        },
                    }}
                >
                    <span className="btn__icon">
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                    Queries
                </Link>
            </li>
        </ul>
    );
}