import React from "react";
import { Link } from "react-router-dom";
import OutstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import CardWrapper from "../Cards/CardWrapper";
import { RequestType, ShowInterestType } from "../../services/GigHighLevelService";
import sharedUtils from "../grid/sharedUtilities";
import { HeaderResponse } from "../../services/HeaderService";

// all of this info will be needed to populate a card
// not sure if status (late/on time) will come from db or be dervied here
interface GigHighLevelCardProps {
    gigCardItem?: RequestType;
    totalNumber: number;
    serialNumber: number;
    location: any;
    history: any;
    headerResponse: HeaderResponse;
}

interface GigHighLevelCardState {}

class GigHighLevelCard extends React.Component<GigHighLevelCardProps> {
    state: GigHighLevelCardState;
    //service that would post the data back to the server.
    sharedUtils: sharedUtils;
    utils: OutstandingActionsUtilities;
    constructor(props: GigHighLevelCardProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.utils = new OutstandingActionsUtilities();
        this.state = {};
    }

    setInterestText = (gigData: RequestType | undefined): string => {
        var { headerResponse } = this.props;
        var text = "";
        var requestStatus = gigData ? gigData.requestStatusValue : null;
        var showInterestStatusList: ShowInterestType[] =
            gigData && gigData.showInterest && gigData.showInterest.length > 0
                ? gigData.showInterest.filter((item: ShowInterestType) => {
                      return item.providerBusinessEntityId == headerResponse.businessEntityId;
                  })
                : [];
        var showInterestStatus: string =
            showInterestStatusList && showInterestStatusList.length > 0
                ? showInterestStatusList[0].showInterestStatus
                : "";
        var pastDate = new Date(new Date().toDateString());
        pastDate.setDate(pastDate.getDate() - 8);
        var currentDate = new Date(new Date().toDateString());
        var dateFrominDateFormat = gigData && gigData.dateFrom ? new Date(gigData.dateFrom) : null;
        dateFrominDateFormat && dateFrominDateFormat.setDate(dateFrominDateFormat.getDate());
        var lastUpdinDateFormat =
            gigData && gigData.lastUpdatedOn
                ? new Date(this.utils.convertddmmyyyyStringToCustomizedFormat(gigData.lastUpdatedOn))
                : null;
        lastUpdinDateFormat && lastUpdinDateFormat.setDate(lastUpdinDateFormat.getDate());
        if (
            requestStatus &&
            (requestStatus.toUpperCase() == "BOOKED" || requestStatus.toUpperCase() == "CANCELLED") &&
            lastUpdinDateFormat &&
            lastUpdinDateFormat >= pastDate
        ) {
            text = "No Longer Available";
        } else if (
            requestStatus &&
            requestStatus.toUpperCase() == "PUBLISHED" &&
            dateFrominDateFormat &&
            dateFrominDateFormat >= currentDate &&
            showInterestStatus &&
            showInterestStatus.toUpperCase() == "APPLIED"
        ) {
            text = "Expressed Interest";
        } else if (
            requestStatus &&
            requestStatus.toUpperCase() == "PUBLISHED" &&
            dateFrominDateFormat &&
            dateFrominDateFormat >= currentDate
        ) {
            text = "";
        }
        return text;
    };

    render() {
        var { headerResponse } = this.props;
        var businessEntityId: string = headerResponse ? headerResponse.businessEntityId : "";
        var delimeter: string = " , ";
        var dash: string = " - ";
        var gigData = this.props.gigCardItem;
        var serviceName =
            gigData && gigData.serviceType && gigData.serviceSubType
                ? `${gigData.serviceType} ${delimeter} ${gigData.serviceSubType}`
                : "";
        var venueCountry = gigData && gigData.country ? gigData.country : "";
        var city = gigData && gigData.townCity ? gigData.townCity : "";
        var dateFrom = gigData && gigData.dateFrom ? gigData.dateFrom : "";
        var time =
            gigData && gigData.startTime && gigData.finishTime
                ? `${gigData.startTime}${dash}${gigData.finishTime}`
                : gigData
                ? gigData.startTime
                : null;
        var serviceSubType = gigData && gigData.typeLookUp ? gigData.typeLookUp : null;
        var payAmount = gigData && gigData.budget ? `${gigData.budget}` : "";
        var gigItemId = gigData && gigData.id ? gigData.id : "";
        var interestText = this.setInterestText(gigData);
        var hideViewDetailLink = interestText == "No Longer Available" ? true : false;
        var classForText = interestText && interestText == "Expressed Interest" ? "text-success" : "";
        return (
            <CardWrapper
                type="entertainer"
                typeTitle={serviceName}
                enumeration={true}
                cardIndex={this.props.serialNumber}
                cardsTotal={this.props.totalNumber}
                fullHeight={true}
            >
                <div className="euk-card__content-section">
                    <div className="entertainer-card-layout">
                        <div className="euk-card__title">
                            {city}
                            {delimeter}
                            {venueCountry}
                        </div>
                        <div className="entertainer-card-layout__date-info">
                            <time dateTime="">{dateFrom}</time>
                            <time>{time}</time>
                        </div>
                        <div className="entertainer-card-layout__payment-info">
                            <div>{serviceSubType}</div>
                            <span>{this.sharedUtils.thousandsSeparator(payAmount)}</span>
                        </div>
                        <div className="entertainer-card-layout__query-info" style={{ textAlign: "center" }}>
                            <span className={classForText}>{interestText}</span>
                        </div>

                        <div className="entertainer-card-layout__view-link" hidden={hideViewDetailLink}>
                            <Link
                                to={{
                                    pathname: "/highLevelDetailGig",
                                    state: {
                                        id: gigItemId,
                                        serialNo: this.props.serialNumber,
                                        totalNo: this.props.totalNumber,
                                        interestText: interestText,
                                        businessEntityId: businessEntityId,
                                    },
                                }}
                                className="btn btn-link btn-link--inline"
                            >
                                View Detail
                            </Link>
                        </div>
                    </div>
                </div>
            </CardWrapper>
        );
    }
}

export default GigHighLevelCard;
