import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import EntertainmentCompletionCard from "../../components/EntertainmentLanding/EntertainmentCompletionCard";
import DarkModePageTemplate from "./Templates/DarkModePageTemplate";

interface EntertainmentCompletionCardPageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

class EntertainmentCompletionCardPage extends Component<EntertainmentCompletionCardPageProps> {
    render() {
        let header = "Your Completed Gig";
        let dateString = new Date().toString();
        dateString = new Date(dateString).toUTCString();
        dateString = dateString.split(" ").slice(0, 4).join(" ");
        return (
            <DarkModePageTemplate>
                <div className="page-content">
                    <section className="container accent-container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-6">
                                <h3 className="text-center">
                                    <span>{header}</span>
                                    <span className="font-weight-bold d-block">{dateString}</span>
                                </h3>
                                <h4 className="mt-6"></h4>
                                {/* TODO why is the above empty? */}
                                <EntertainmentCompletionCard
                                    history={this.props.history}
                                    location={this.props.location}
                                ></EntertainmentCompletionCard>
                            </div>
                        </div>
                    </section>
                </div>
            </DarkModePageTemplate>
        );
    }
}

export default withRouter(EntertainmentCompletionCardPage);
