import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, SelectBox, DateBox, CheckBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import sharedUtils,{radioOptions} from "../grid/sharedUtilities";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import { SelectBoxOnValueChangedEvent, DateBoxOnValueChangedEvent, CheckBoxOnValueChanged } from "../../types/DevExtremeTypes";
import CostReportService from "../../services/CostReportService";
import CostReportUtils from "../CostReports/CostReportUtils";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { PeriodMatrixTableLookup } from "../Billing/BillingFormUtils";
import DayMonthWeekPanel from "../../DayMonthWeekPanel/DayMonthWeekPanel";
import SimpleShiftGlobalFilterService from "../../services/SimpleShiftGlobalFilterService";
import { ServiceType } from "../../common/ServiceUtilities";
import queryUtils from "../Query/QueryUtils";
import moment from "moment";

interface SimpleShiftsPanelProps extends WithTranslation {
    location: any;
    onApplyButtonClick: (
        venueId: string,
        startDateId: string,
        endDateId: string,
        clientId: string,
        serviceTypeId: string,
        onUpdate: boolean,
        paymentTypeId: string,
        isShowUnbilledItems: boolean,
        isUnPaidItems: boolean,
        isUnconfirmedItems: boolean,
        isUnapprovedItems :boolean
    ) => void;
    serviceTypeId: string;
    clientId: string;
    venueId: string;
    startDate: string;
    endDate: string;
    pointOfNavigation?: string;
    billableItemId: string;
    paymentTypeId?: string;
}

interface SimpleShiftsPanelState {
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    startDate: string;
    endDate: string;
    singleVenue: boolean;
    serviceTypeloaded: false;
    serviceTypeDataSource: LookupTypeItem[];
    minDateValue: NullableDate;
    maxDateValue: NullableDate;
    dateList: PeriodMatrixTableLookup[];
    onUpdate: boolean;
    isVenueManager: boolean;
    isProvider: boolean;
    isEventUk: boolean;
    venueLoaded: boolean;
    venueListByClient: any[];
    entireVenueList: any[];
    paymentTypeDataSource: LookupTypeItem[];
    paymentTypeId: string;
    isUnbilledItems: boolean;
    isUnPaidItems: boolean;
    isUnconfirmedItems: boolean;
    isUnapprovedItems:boolean;
}

class SimpleShiftsPanel extends Component<SimpleShiftsPanelProps> {
    lookupService: LookupService;
    state: SimpleShiftsPanelState;
    sharedUtils: sharedUtils;
    Service: CostReportService;
    utils: CostReportUtils;
    queryUtils: queryUtils;
    constructor(props: SimpleShiftsPanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.sharedUtils = new sharedUtils();
        this.utils = new CostReportUtils();
        this.queryUtils = new queryUtils();
        this.state = {
            clientId: "",
            venueId: "",
            serviceTypeId: this.props.serviceTypeId,
            startDate: "",
            endDate: "",
            singleVenue: UserService.isUserInGroup(RoleGroupNames.VenueManager) ? true : false,
            serviceTypeloaded: false,
            serviceTypeDataSource: [],
            minDateValue: null,
            maxDateValue: null,
            dateList: [],
            onUpdate: false,
            isVenueManager: UserService.isUserInGroup(RoleGroupNames.VenueManager) ? true : false,
            isProvider: UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ? true : false,
            isEventUk:
                UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                    UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
                    ? true
                    : false,
            venueLoaded: false,
            venueListByClient: [],
            entireVenueList: [],
            paymentTypeDataSource: [],
            paymentTypeId: "",
            isUnbilledItems: false,
            isUnPaidItems: false,
            isUnconfirmedItems: false,
            isUnapprovedItems:false
        };
    }

    //Retrieve the lookup values inside the mount
    //If a venue manager logs in Call the client and venue dropdowns and all the required API calls.
    //Else bypaas all the unnecessary API calls and Populate the Start and End dates.
    componentDidMount() {
        const { isVenueManager } = this.state;
        if (isVenueManager) {
            this.retrieveLookupValues(LookupTypeIndexes.clientType);
        } else {

            this.retrievePeriodMatrixVaues();


        }
        this.retrieveLookupValues(LookupTypeIndexes.serviceType);
        this.retrieveLookupValues(LookupTypeIndexes.paymentrequesttype);
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleLookupSuccessForClient)
                    .catch((error) => { });
                break;
            case LookupTypeIndexes.serviceType:
                var listOfServiceTypes: LookupTypeItem[] = UserService.operatingServiceList();
                if (listOfServiceTypes && listOfServiceTypes.length > 0) {
                    var serviceTypeDataSource: LookupTypeItem[] = [];
                    if (window.location.pathname.includes("paymentRequests")) {
                        serviceTypeDataSource = listOfServiceTypes.filter((item: any) => {
                            return (item.id != ServiceType.Security)
                        });
                    }
                    else {
                        serviceTypeDataSource = listOfServiceTypes;
                    }
                    this.setState({
                        serviceTypeloaded: true,
                        serviceTypeDataSource: serviceTypeDataSource,
                    });

                }
                break;
            case LookupTypeIndexes.providerType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.periodMatrix)
                    .then(this.handleProviderTypeLookupSuccess)
                    .catch((error) => { });
                break;
            case LookupTypeIndexes.paymentrequesttype:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.paymentrequesttype)
                    .then(this.handlePaymentReqTypeLookupSuccess)
                    .catch((error) => { });
                break;
        }
    };

    handlePaymentReqTypeLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            if (response.data.data.length == 0) {
                this.onApply();
            } else {
                this.setState(
                    {
                        paymentTypeDataSource: this.queryUtils.lookUpincludeAll(response.data.data)
                    }
                );
            }
        }
    };

    handleLookupSuccessForClient = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            if (response.data.data.length == 0) {
                this.onApply();
            } else {
                this.setState(
                    {
                        clientId: this.props.clientId ? this.props.clientId : response.data.data[0].id,
                    },
                    () => this.retrieveLookupValuesForVenue()
                );
            }
        }
    };

    retrieveLookupValuesForVenue = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLookupSuccess)
            .catch((error) => { });
    };

    //Set the Date from and Date to based on the Period Matrix for the Venue Managers.
    handleProviderTypeLookupSuccess = (response: AxiosResponse<any>) => {
        var startDate: string = "";
        var endDate: string = "";
        var minDateValue: NullableDate = null;
        var maxDateValue: NullableDate = null;
        var dateListPeriodMatrix: PeriodMatrixTableLookup[] = [];
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            dateListPeriodMatrix = response.data.data;
            var periodLookupList: PeriodMatrixTableLookup[] = this.utils.buildPeriodListBasedOnClient(
                this.state.clientId,
                dateListPeriodMatrix
            );
            var dateArray: string[] = this.utils.setDateRangeForVenueManagers(periodLookupList);
            if (!this.props.billableItemId) {
                minDateValue = this.props.startDate
                    ? this.sharedUtils.convertStringToDate(this.props.startDate)
                    : this.sharedUtils.convertStringToDate(dateArray[0]);
                maxDateValue = this.props.endDate
                    ? this.sharedUtils.convertStringToDate(this.props.endDate)
                    : this.sharedUtils.convertStringToDate(dateArray[1]);
            }
            var startDate: string = this.sharedUtils.convertDateToString(minDateValue);
            var endDate: string = this.sharedUtils.convertDateToString(maxDateValue);
        }
        this.setState(
            {
                dateList: dateListPeriodMatrix,
                minDateValue: minDateValue,
                maxDateValue: maxDateValue,
                startDate: startDate,
                endDate: endDate,
            },
            () =>
                this.props.onApplyButtonClick(
                    this.props.venueId ? this.props.venueId : this.state.venueId,
                    this.props.billableItemId ? "" : this.state.startDate,
                    this.props.billableItemId ? "" : this.state.endDate,
                    this.props.clientId ? this.props.clientId : this.state.clientId,
                    this.state.serviceTypeId,
                    false,
                    "",
                    false,
                    false,
                    false,
                    false
                )
        );
    };

    handleVenueLookupSuccess = (response: AxiosResponse<any>) => {
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            !this.isBlank(this.state.clientId)
        ) {
            var venues: any[] = response.data.data;
            var venueByClient: any[] = this.sharedUtils.extractVenueListPerClient(venues, this.state.clientId);
            var singleVenue: boolean = response.data.data.length == 1 ? true : false;
            this.setState(
                {
                    singleVenue: singleVenue,
                    venueId: this.props.venueId ? this.props.venueId : venueByClient[0].id,
                    venueLoaded: true,
                    venueListByClient: venueByClient,
                    entireVenueList: venues,
                },
                () => this.retrievePeriodMatrixVaues()
            );
        }
    };

    //A helper function that would either call the period matrix endpoint in case of Venue Managers to set the Start date and date date or set it by default.
    retrievePeriodMatrixVaues = () => {
        var minValue: NullableDate = null;
        var maxValue: NullableDate = null;
        var minDateValue: string = "";
        var maxDateValue: string = "";
        let filterValues: any;
        let paymentTypeId: string = "";
        if (this.props.location && this.props.location.state && this.props.location.state.navigateFrom != "PaymentRequestPending" && this.props.location.state.navigateFrom != "PaymentRequestQueried" && 
            this.props.location.state.navigateFrom != "AddJobPending" && this.props.location.state.navigateFrom != "AddJobQueried") {
            filterValues = SimpleShiftGlobalFilterService.getFilterValues();
            //paymentTypeId = SimpleShiftGlobalFilterService.getPaymentTypeVal();
            paymentTypeId = this.props.paymentTypeId ? this.props.paymentTypeId : SimpleShiftGlobalFilterService.getPaymentTypeVal();
        }
        let dateFrom: string = "";
        let dateTo: string = "";
        if (filterValues) {
            dateFrom = this.sharedUtils.convertDateToString(filterValues.dateFrom);
            dateTo = this.sharedUtils.convertDateToString(filterValues.dateTo);
        }
        if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            this.retrieveLookupValues(LookupTypeIndexes.providerType);
        } else {
            //original
            // if ((this.props.pointOfNavigation == "burgerMenu") || (this.props.pointOfNavigation?.toLocaleLowerCase() != "burgermenupayments" && (dateFrom || dateTo)) || this.props.billableItemId) {
                if ((this.props.pointOfNavigation == "burgerMenu") || this.props.billableItemId) {
                minValue = this.props.startDate
                    ? this.sharedUtils.convertStringToDate(this.props.startDate)
                    : this.sharedUtils.fetchMinValueOfCurrentWeek();
                maxValue = this.props.endDate
                    ? this.sharedUtils.convertStringToDate(this.props.endDate)
                    : this.sharedUtils.fetchMaxValueOfCurrentWeek();
            }
            
            //original
            if ((this.props.pointOfNavigation == "burgerMenu") || this.props.pointOfNavigation?.toLocaleLowerCase() != "burgermenupayments" && (dateFrom || dateTo)) {
                minDateValue = this.sharedUtils.convertDateToString(minValue);
                maxDateValue = this.sharedUtils.convertDateToString(maxValue);
            }
            if (this.props.pointOfNavigation?.toLocaleLowerCase() == "burgermenupayments") {
                minValue = this.sharedUtils.convertStringToDate(this.props.startDate);
                //maxValue = this.sharedUtils.convertStringToDate(this.props.endDate) || null;
                SimpleShiftGlobalFilterService.setDateToValue(null);
                minDateValue = this.sharedUtils.convertDateToString(minValue);
                maxDateValue = this.sharedUtils.convertDateToString(maxValue);
            }
            // if (this.props.pointOfNavigation?.toLocaleLowerCase() == "burgermenupayments") {
            //     minDateValue = this.sharedUtils.convertDateToString(minValue);
            //     maxDateValue = this.sharedUtils.convertDateToString(maxValue);
            // }
            let isUnbilled=false,isUnpaid=false,isUnconfirmed=false,isUnapproved = false;
            if(this.props.pointOfNavigation == "popUp"){
                let filters = SimpleShiftGlobalFilterService.getFilterValues(); 
                if(filters){
                    switch(filters.selectedRadio){
                        case radioOptions.UNBILLED:
                            isUnbilled=true;
                        break;
                        case radioOptions.UNPAID:
                            isUnpaid =true;
                        break;
                        case radioOptions.UNCONFIRMED:
                            isUnconfirmed = true;
                        break;
                        case radioOptions.UNAPPROVED:
                            isUnapproved = true;
                        break;
                        case 'default':
                        break;
                    }
                }
            }

            this.setState(
                {
                    paymentTypeId: paymentTypeId,
                    minDateValue:
                        this.props.pointOfNavigation == "popUp" && dateFrom
                            ? this.sharedUtils.convertStringToDate(dateFrom)
                            : minValue,
                    maxDateValue:
                        this.props.pointOfNavigation == "popUp" && dateTo
                            ? this.sharedUtils.convertStringToDate(dateTo)
                            : maxValue,
                    startDate: this.props.pointOfNavigation == "popUp" && dateFrom ? dateFrom : minDateValue,
                    endDate: this.props.pointOfNavigation == "popUp" && dateTo ? dateTo : maxDateValue,
                    isUnbilledItems:isUnbilled,
                    isUnPaidItems: isUnpaid,
                   isUnconfirmedItems: isUnconfirmed,
                   isUnapprovedItems:isUnapproved
                },
                () =>
                    this.props.onApplyButtonClick(
                        //On an initial load of the screen send ClientId and venueId as blank to the page.
                        this.props.venueId ? this.props.venueId : "",
                        this.props.billableItemId ? "" : this.state.startDate,
                        this.props.billableItemId ? "" : this.state.endDate,
                        this.props.clientId ? this.props.clientId : "",
                        this.state.serviceTypeId,
                        false,
                        this.state.paymentTypeId,
                        isUnbilled,
                        isUnpaid,
                        isUnconfirmed,
                        isUnapproved
                        // false,
                        // false,
                        // false,
                        // false
                    )
            );
        }
    };

    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var listOfVenues: any[] = this.state.entireVenueList;
        var venueDataSource: any[] = this.sharedUtils.extractVenueListPerClient(listOfVenues, dxValueChange.value);
        this.setState({
            venueId: "",
            clientId: dxValueChange.value,
            venueListByClient: venueDataSource,
        });
    };

    handleChangeUnbilledItems = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isUnbilledItems: dxValueChange.value,
        });
        if (dxValueChange.value) {
            this.setState({
                startDate: "",
                endDate: new Date(),
                minDateValue: undefined,
                maxDateValue: new Date(),
                isUnPaidItems: false,
                isUnconfirmedItems: false,
                isUnapprovedItems:false
            });
        }
        // if(dxValueChange.value == false && this.state.isUnPaidItems == false && this.state.isUnconfirmedItems == false && this.state.isUnapprovedItems == false){
        //     let minValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        //     let maxValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        //     this.setState({
        //         minDateValue: minValue,
        //         maxDateValue: maxValue,
        //     });
        // }
        if (dxValueChange && dxValueChange.element) {
            dxValueChange.element.onkeydown = (event: any) => {
                if (event.key && event.key === 'Enter') {
                    this.onUpdateApplyState();
                }
            }
        }
    }

    handleChangeUnPaidItems = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isUnPaidItems: dxValueChange.value,
        });
        if (dxValueChange.value) {
            this.setState({
                startDate: "",
                endDate: new Date(),
                minDateValue: undefined,
                maxDateValue: new Date(),
                isUnbilledItems: false,
                isUnconfirmedItems: false,
                isUnapprovedItems:false
            });
        }
        // if(dxValueChange.value == false && this.state.isUnbilledItems == false && this.state.isUnconfirmedItems == false && this.state.isUnapprovedItems == false){
        //     let minValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        //     let maxValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        //     this.setState({
        //         minDateValue: minValue,
        //         maxDateValue: maxValue,
        //     });
        // }
        if (dxValueChange && dxValueChange.element) {
            dxValueChange.element.onkeydown = (event: any) => {
                if (event.key && event.key === 'Enter') {
                    this.onUpdateApplyState();
                }
            }
        }
    }

    handleChangeUnconfirmedItems = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isUnconfirmedItems: dxValueChange.value,
        });
        if (dxValueChange.value) {
            this.setState({
                startDate: "",
                endDate: new Date(),
                minDateValue: undefined,
                maxDateValue: new Date(),
                isUnbilledItems: false,
                isUnPaidItems: false,
                isUnapprovedItems:false
            });
        }
        // if(dxValueChange.value == false && this.state.isUnbilledItems == false && this.state.isUnPaidItems == false && this.state.isUnapprovedItems == false){
        //     let minValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        //     let maxValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        //     this.setState({
        //         minDateValue: minValue,
        //         maxDateValue: maxValue,
        //     });
        // }
        if (dxValueChange && dxValueChange.element) {
            dxValueChange.element.onkeydown = (event: any) => {
                if (event.key && event.key === 'Enter') {
                    this.onUpdateApplyState();
                }
            }
        }
    }
    
    handleChangeUnapprovedItems = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            isUnapprovedItems: dxValueChange.value,
        });
        if (dxValueChange.value) {
            this.setState({
                startDate: "",
                endDate: new Date(),
                minDateValue: undefined,
                maxDateValue: new Date(),
                isUnbilledItems: false,
                isUnPaidItems: false,
                isUnconfirmedItems: false,
            });
        }
        // if(dxValueChange.value == false && this.state.isUnbilledItems == false && this.state.isUnPaidItems == false && this.state.isUnconfirmedItems == false){
        //     let minValue = this.sharedUtils.fetchMinValueOfCurrentWeek();
        //     let maxValue = this.sharedUtils.fetchMaxValueOfCurrentWeek();
        //     this.setState({
        //         minDateValue: minValue,
        //         maxDateValue: maxValue,
        //     });
        // }
        if (dxValueChange && dxValueChange.element) {
            dxValueChange.element.onkeydown = (event: any) => {
                if (event.key && event.key === 'Enter') {
                    this.onUpdateApplyState();
                }
            }
        }
    }

    handleChangeVenueTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            venueId: dxValueChange.value,
        });
    };

    handleChangePaymentTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        sessionStorage.setItem("paymentTypeFilter", dxValueChange.value);
        this.setState({
            paymentTypeId: dxValueChange.value
        });
    };

    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        sessionStorage.setItem("serviceTypeFilter", dxValueChange.value);
        this.setState({
            serviceTypeId: dxValueChange.value,

        });
    };

    differenceBetweenTwoDates = () => {
        // To calculate the time difference of two dates
        if (this.state.minDateValue && this.state.maxDateValue) {
            let Difference_In_Time = this.state.maxDateValue.getTime() - this.state.minDateValue.getTime();
            // To calculate the no. of days between two dates
            let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            console.log("difference in days is" + Difference_In_Days);
        }
    }

    handleChangeStartDateSelect = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.value) {
            this.setState({
                minDateValue: dxValueChange.value,
                startDate: this.sharedUtils.convertDateToString(dxValueChange.value),
                isUnbilledItems: false,
                isUnPaidItems: false,
                isUnconfirmedItems: false,
                isUnapprovedItems:false
            });
            SimpleShiftGlobalFilterService.setDateFromValue(dxValueChange.value);
        }
    };

    handleChangeEndDateSelect = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.value) {
            this.setState({
                maxDateValue: dxValueChange.value,
                endDate: this.sharedUtils.convertDateToString(dxValueChange.value),
            });
            SimpleShiftGlobalFilterService.setDateToValue(dxValueChange.value);
        }
    };

    componentDidUpdate = (prevprops: SimpleShiftsPanelProps, prevState: SimpleShiftsPanelState) => {
        if ((this.props.serviceTypeId != prevprops.serviceTypeId &&
            this.props.serviceTypeId != prevState.serviceTypeId) || 
            (this.props.paymentTypeId != prevprops.paymentTypeId &&
                this.props.paymentTypeId != prevState.paymentTypeId)
        ) {
            this.resetDateRange(this.state.clientId, this.props.serviceTypeId,this.props.paymentTypeId);
        }
    };


    // componentDidUpdate = (prevprops: SimpleShiftsPanelProps, prevState: SimpleShiftsPanelState) => {
    //     if (
    //         (this.props.serviceTypeId != prevprops.serviceTypeId && this.props.serviceTypeId != prevState.serviceTypeId) || 
    //         (this.props.paymentTypeId != prevprops.paymentTypeId && this.props.paymentTypeId != prevState.paymentTypeId)
    //     ) {
    //         this.resetDateRange(this.state.clientId, this.props.serviceTypeId,this.props.paymentTypeId);
    //     }
    // };

    calculateDateBefore6Weeks(){
        let currDate = new Date();
        let newStartDate = moment(currDate).subtract(6,'weeks');
        let date = newStartDate.format("DD/MM/YYYY");
        return date;
       }

    //A helper function that will reset the date list based on the client dropdown selection.
    resetDateRange = (clientId: string, serviceTypeId?: string,paymentTypeId?:string) => {
        if (!this.props.billableItemId) {
            if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
                var filteredLookupList: PeriodMatrixTableLookup[] = this.utils.buildPeriodListBasedOnClient(
                    clientId,
                    this.state.dateList
                );
                var dateArray: string[] = this.utils.setDateRangeForVenueManagers(filteredLookupList);
                var minDateValue: NullableDate = this.sharedUtils.convertStringToDate(dateArray[0]);
                var maxDateValue: NullableDate = this.sharedUtils.convertStringToDate(dateArray[1]);
                var startDate: string = this.sharedUtils.convertDateToString(minDateValue);
                var endDate: string = this.sharedUtils.convertDateToString(maxDateValue);
                this.setState(
                    {
                        serviceTypeId: serviceTypeId ? serviceTypeId : this.state.serviceTypeId,
                        paymentTypeId: paymentTypeId ? paymentTypeId: this.state.paymentTypeId,
                        minDateValue: minDateValue,
                        maxDateValue: maxDateValue,
                        startDate: startDate,
                        endDate: endDate,
                    },
                    () => this.onApply()
                );
            } else {
                var minValue: NullableDate = this.sharedUtils.fetchMinValueOfCurrentWeek();
                var maxValue: NullableDate = this.sharedUtils.fetchMaxValueOfCurrentWeek();
                var minDateRange: string = this.sharedUtils.convertDateToString(minValue);
                var maxDateRange: string = this.sharedUtils.convertDateToString(maxValue);

                if (this.props.pointOfNavigation?.toLocaleLowerCase() == "burgermenupayments") {
                    minValue = this.props.startDate ? this.sharedUtils.convertStringToDate(this.props.startDate):this.sharedUtils.convertStringToDate(this.calculateDateBefore6Weeks());
                    //minValue = this.sharedUtils.convertStringToDate(this.calculateDateBefore6Weeks());
                    //maxValue = null;
                    maxValue = this.props.endDate ? this.sharedUtils.convertStringToDate(this.props.endDate) : null ;
                    SimpleShiftGlobalFilterService.setDateToValue(maxValue);
                
                    minDateRange = this.sharedUtils.convertDateToString(minValue);
                    maxDateRange = this.sharedUtils.convertDateToString(maxValue);
                }
                this.setState(
                    {
                        serviceTypeId: serviceTypeId ? serviceTypeId : this.state.serviceTypeId,
                        paymentTypeId: paymentTypeId ? paymentTypeId : this.state.paymentTypeId,
                        minDateValue: minValue,
                        maxDateValue: maxValue,
                        startDate: minDateRange,
                        endDate: maxDateRange,
                    },
                    () => this.onApply()
                );
            }
        }
    };

    setSelectedRadioOptionGlobally = () => {
        let selectedRadio = '';
        if (this.state.isUnbilledItems) {
            selectedRadio = radioOptions.UNBILLED;
        } else if (this.state.isUnPaidItems) {
            selectedRadio = radioOptions.UNPAID;
        } else if (this.state.isUnconfirmedItems) {
            selectedRadio = radioOptions.UNCONFIRMED;
        } else if (this.state.isUnapprovedItems) {
            selectedRadio = radioOptions.UNAPPROVED;
        }
        SimpleShiftGlobalFilterService.setSelectedRadio(selectedRadio);
    }

    //A helper function that would toggle the state of onUpdate to send the signal to the page to close the sidebar.
    onUpdateApplyState = () => {
        this.setState(
            (prevState: SimpleShiftsPanelState) => ({
                onUpdate: !prevState.onUpdate,
            }),
            () => {
            this.setSelectedRadioOptionGlobally();
            this.onApply();
            }
        );
    };
    onApplySubmit =(e:any)=>{
        e.preventDefault();
        this.onUpdateApplyState();
    }

    //Fire the parent component's callback on an Apply click.
    onApply = () => {
        if (this.props?.location?.state) {
            this.props.location.state = {};
        }
        this.props.onApplyButtonClick(
            this.state.venueId,
            this.state.startDate,
            this.state.endDate,
            this.state.clientId,
            this.state.serviceTypeId,
            this.state.onUpdate,
            this.state.paymentTypeId,
            this.state.isUnbilledItems,
            this.state.isUnPaidItems,
            this.state.isUnconfirmedItems,
            this.state.isUnapprovedItems
        );
        SimpleShiftGlobalFilterService.setPaymentTypeVal(this.state.paymentTypeId);
        SimpleShiftGlobalFilterService.setDateFromValue(this.sharedUtils.convertStringToDate(this.state.startDate));
        SimpleShiftGlobalFilterService.setDateToValue(this.sharedUtils.convertStringToDate(this.state.endDate));
        this.differenceBetweenTwoDates();
    };

    ondateTodateFromSelection = (dateFromValue: string, dateToValue: string) => {
        this.setState({
            minDateValue: this.sharedUtils.convertStringToDate(dateFromValue),
            maxDateValue: this.sharedUtils.convertStringToDate(dateToValue),
            startDate: dateFromValue,
            endDate: dateToValue,
            isUnbilledItems: false,
            isUnPaidItems: false,
            isUnconfirmedItems: false,
            isUnapprovedItems:false
        });
        this.onUpdateApplyState();
        SimpleShiftGlobalFilterService.setDateFromValue(this.sharedUtils.convertStringToDate(dateFromValue));
        SimpleShiftGlobalFilterService.setDateToValue(this.sharedUtils.convertStringToDate(dateToValue));
    };

    render() {
        const { isVenueManager, isEventUk, isProvider, venueLoaded, venueListByClient } = this.state;
        var selectboxHiddenRole: boolean = isProvider || isEventUk;
        var isSingleClientandVenue: boolean = this.state.singleVenue;
        let isHiddenTypeLookup: boolean = true;
        let isHiddenCheckBox: boolean = true;
        let paymentTypeId: string = "";
        if (this.state.paymentTypeId) {
            paymentTypeId = this.state.paymentTypeId;
        }
        else if (this.props.paymentTypeId) {
            paymentTypeId = this.props.paymentTypeId;
        }
        if (window.location.href.includes("paymentRequests")) {
            isHiddenTypeLookup = false;
        }
        if (window.location.href.includes("simpleShifts")) {
            isHiddenCheckBox = false;
        }
        return (
            <div>
                <DayMonthWeekPanel
                    ondateTodateFromSelection={this.ondateTodateFromSelection}
                    dateToValue={this.state.maxDateValue ? this.state.maxDateValue : null}
                    dateFromValue={this.state.minDateValue ? this.state.minDateValue : null}
                    serviceTypeId={this.props.serviceTypeId}
                    periodLookUpItems={this.state.dateList}
                    clientId={this.state.clientId}
                    pointOfNavigation={this.props.pointOfNavigation}
                    billableItemId={this.props.billableItemId}
                ></DayMonthWeekPanel>
                <form onSubmit={this.onApplySubmit}>
                <div className="row mt-4">
                    <div className="col-10">
                        <h4 className="sidebar__heading font-weight-bold">Filter shifts</h4>
                    </div>
                </div>

                {isSingleClientandVenue || selectboxHiddenRole ? (
                    <></>
                ) : (
                    <>
                        {" "}
                        <div className="row mt-3">
                            <div className="col sidebar__heading">Client</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                {venueLoaded ? (
                                    <>
                                        {" "}
                                        <DropDownFromLookupTable
                                            lookupTypeIndex={LookupTypeIndexes.clientType}
                                            onValueChanged={this.handleChangeClientTypeSelect}
                                            value={this.state.clientId}
                                        />
                                        <input
                                            data-testid={LookupTypeIndexes.clientType}
                                            type="hidden"
                                            name={LookupTypeIndexes.clientType}
                                        ></input>{" "}
                                    </>
                                ) : (
                                    <span> Loading... </span>
                                )}{" "}
                            </div>
                        </div>
                    </>
                )}

                {isSingleClientandVenue || selectboxHiddenRole ? (
                    <></>
                ) : (
                    <>
                        {" "}
                        <div className="row mt-3">
                            <div className="col sidebar__heading">Venue</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                {venueLoaded ? (
                                    <SelectBox
                                        dataSource={venueListByClient}
                                        displayExpr={"value"}
                                        valueExpr={"id"}
                                        value={this.state.venueId}
                                        onValueChanged={this.handleChangeVenueTypeSelect}
                                    />
                                ) : (
                                    <span> Loading... </span>
                                )}{" "}
                            </div>
                        </div>
                    </>
                )}

                {(isVenueManager || isEventUk) && (
                    <>
                        {" "}
                        <div className="row mt-3">
                            <div className="col sidebar__heading">Service</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <>
                                    {this.state.serviceTypeloaded ? (
                                        <SelectBox
                                            dataSource={this.state.serviceTypeDataSource}
                                            displayExpr={"value"}
                                            valueExpr={"id"}
                                            value={this.state.serviceTypeId}
                                            onValueChanged={this.handleChangeServiceTypeSelect}
                                        />
                                    ) : (
                                        <span> Loading... </span>
                                    )}{" "}
                                </>
                            </div>
                        </div>{" "}
                    </>
                )}

                <div className="row mt-3">
                    <div className="col sidebar__heading">Date from</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeStartDateSelect}
                            value={this.state.minDateValue ? this.state.minDateValue : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                            onEnterKey={this.onUpdateApplyState}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Date to</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeEndDateSelect}
                            value={this.state.maxDateValue ? this.state.maxDateValue : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                            onEnterKey={this.onUpdateApplyState}
                        />
                    </div>
                </div>
                
                <div className="row mt-3" hidden={isHiddenCheckBox}>
                    <div className="col">
                        <CheckBox
                            value={this.state.isUnbilledItems}
                            onValueChanged={this.handleChangeUnbilledItems}
                        />
                        &nbsp; &nbsp;
                        Only Show Unbilled Items
                    </div>
                </div>
                <div className="row mt-3" hidden={isHiddenCheckBox}>
                    <div className="col">
                        <CheckBox
                            value={this.state.isUnPaidItems}
                            onValueChanged={this.handleChangeUnPaidItems}
                        />
                        &nbsp; &nbsp;
                        Only Show UnPaid Items
                    </div>
                </div>
                <div className="row mt-3" hidden={isHiddenCheckBox}>
                    <div className="col">
                        <CheckBox
                            value={this.state.isUnconfirmedItems}
                            onValueChanged={this.handleChangeUnconfirmedItems}
                        />
                        &nbsp; &nbsp;
                        Only Show Unconfirmed Items
                    </div>
                </div>
                <div className="row mt-3" hidden={isHiddenCheckBox}>
                    <div className="col">
                        <CheckBox
                            value={this.state.isUnapprovedItems}
                            onValueChanged={this.handleChangeUnapprovedItems}
                        />
                        &nbsp; &nbsp;
                        Only Show Unapproved Items
                    </div>
                </div>
                
                <div className="row mt-3" hidden={isHiddenTypeLookup}>
                    <div className="col sidebar__heading">Type</div>
                </div>
                <div className="row mt-2" hidden={isHiddenTypeLookup}>
                    <div className="col">
                        <SelectBox
                            dataSource={this.state.paymentTypeDataSource}
                            displayExpr={"value"}
                            valueExpr={"id"}
                            value={paymentTypeId}
                            onValueChanged={this.handleChangePaymentTypeSelect}
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        {/* <Button
                            className="btn btn-primary btn--large"
                            disabled={false}
                            onClick={this.onUpdateApplyState}
                        >
                            Apply
                        </Button> */}
                        <button type='submit'
                            className="btn btn-primary btn--large"
                            disabled={false}>
                            Apply
                        </button>
                    </div>
                </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(SimpleShiftsPanel);
