import { LookupTypeItem } from "../../services/LookupService";
import {
    ArtistProfileResponse,
    ContactInformation,
    BankInformation,
    LinkInformation,
    NewFormRequest,
    RequestStatus,
    RequestTypeEnum,
    ShowInterestType,
    PhotoTypes,
    RequestStatusTypes,
    ShowInterestStatusIdTypes,
    ShowInterestStatusTypes,
    EquipmentTypeList,
    MusicList,
    GenreList,
    ArtistConfirmRequest,
    VenueConfirmRequest,
    ReConfirmRequest,
    TaggedRequest,
    OptionsSentRequest,
    ShowInterestOptionsSentRequest,
    AppliedArtistObj,
    DjTypeList,
    WeekList,
    securityShiftDetails
} from "../../services/FindAnArtistService";
import { AxiosResponse } from "axios";
import sharedUtils from "../grid/sharedUtilities";
import { updateRequest } from "../../services/PendingRequestService";
import { serviceTypes } from "../../services/FileService";
import GridUtils from "../grid/GridUtilities";
import { ServiceType } from "../../common/ServiceUtilities";
import GlobalService from "../../services/GlobalService";

type NullableDate = Date | null | undefined;
type NullableNumber = number | null | undefined;



export interface RequestFormData {
    id: string;
    startTime?: NullableDate;
    finishTime?: NullableDate;
    serviceSubTypeId: string;
    serviceTypeId: string;
    venueTypeId?: string;
    clientBusinessEntityId: string;
    venueId: string;
    dateFrom: NullableDate;
    dateTo?: NullableDate;
    equipmentRequired?: string;
    entertainmentDescription: string;
    equipmentType?: EquipmentTypeList[];
    musicTypeList?: MusicList[];
    genreList?: GenreList[];
    performanceInformationSetsNumber?: number;
    performanceInformationSetsDuration?: number;
    additionalInformation: string;
    budget?: number;
    maxBudget?: number;
    marketPlaceBudget?:number;
    isRounded?: boolean;
    internalComments?: string;
    requestStatusId?: string;
    requeststatusValue?: string;
    lastUpdatedOn: string;
    typeLookUpId?: string;
    featured?: string;
    reoccurrence: string;
    reoccurrenceFrequency: string;
    publishedDate?: string;
    imageLinkId?: string;
    displayLink?: string;
    otherDescription?: string;
    djStyleList?: DjTypeList[];
    reoccurrenceEndDateKnown? : string,
    reoccurrenceEndDate? : NullableDate,
    dayOfWeekList?: WeekList[],
    daysOfWeekList?: WeekList[],
    hours?: number,
    minutes?: string,
    rate?: number,
    providerBusinessEntityId?: string;
    approvalDate?: string;
    invoiceId?: string;
    paymentId?: string;
    billableItemId?: string;
    shiftDetails?: securityShiftDetails[];
    requestShifts?: any[];
    providerPayAmountLocal?: number;
    grossProfitAmountLocal?: number;
    clientBillableAmountLocal?: number;
    requestDate: string;
    dateCreated:string;
    requestApprover: string;
    requestApproverPhoneNumber: string;
    twoTierApprovalDate:NullableDate;
    twoTierApprovedBy:string;
}

export interface LastUpdatedInformation {
    lastUpdatedDate: string;
    lastUpdatedTime: string;
}
export interface StatusDetail {
    businessEntityId: string;
    showIntereststatus: string;
    showInterestPreviousStatus: string;
}
// this is created for venue choice and businessentityid on card
export interface VenueChoiceDetail {
    businessEntityId: string;
    selectedOrderNumber: number;
}
//The object that would be constructed and sent over to the parent for filtering.
export interface filterObject {
    serviceSubtype: string;
    types: string[];
    count: number;
}

export const typeData: filterObject[] = [
    {
        serviceSubtype: "DJ",
        types: [],
        count: 0,
    },
    {
        serviceSubtype: "Live Music",
        types: [],
        count: 0,
    },
    {
        serviceSubtype: "Karaoke",
        types: [],
        count: 0,
    },
    {
        serviceSubtype: "Pub Quiz",
        types: [],
        count: 0,
    },
];

export interface subTypeCount {
    subType: string;
    count: number;
}

export const types = ["DJ", "Live Music", "Karaoke", "Pub Quiz"];

export default class ArtistUtilities {
    utils: sharedUtils;
    gridUtils: GridUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.gridUtils = new GridUtils();
        this.initializeArtistData = this.initializeArtistData.bind(this);
        this.initializeContactInformation = this.initializeContactInformation.bind(this);
        this.initializeBankInformation = this.initializeBankInformation.bind(this);
        this.convertStringToFloat = this.convertStringToFloat.bind(this);
    }

    convertStringToFloat(value: string) {
        return value ? parseFloat(value) : 0.0;
    }

    initializeCancellationData = (): updateRequest => {
        var formData: updateRequest = {
            requestStatusValue: "",
            cancellationreasonId: "",
            rePublishGigRequest: null
        }
        return formData;
    }

    initializeArtistConfirmData = (): ArtistConfirmRequest => {
        let formData: ArtistConfirmRequest = {
            id: "",
            isArtistConfirmed: false,
            artistConfirmDateTime: "",
            internalComments: "",
            isFromGrid: false,
            featured: false,
            reoccurrence: false
        }
        return formData;
    }

    initializeVenueConfirmData = (): VenueConfirmRequest => {
        let formData: VenueConfirmRequest = {
            id: "",
            isVenueConfirmed: false,
            venueConfirmDateTime: "",
            internalComments: "",
            isFromGrid: false,
            featured: false,
            reoccurrence: false
        }
        return formData;
    }

    initializeReConfirmData = (): ReConfirmRequest => {
        let formData: ReConfirmRequest = {
            id: "",
            isReConfirmed: false,
            reConfirmDateTime: "",
            internalComments: "",
            isFromGrid: false,
            featured: false,
            reoccurrence: false
        }
        return formData;
    }

    initializeTaggedData = (): TaggedRequest => {
        let formData: TaggedRequest = {
            id: "",
            IsTagged: false,
            isFromGrid: false,
            featured: false,
            reoccurrence: false
        }
        return formData;
    }

    initializeContactInformation(): ContactInformation {
        var contactData: ContactInformation = {
            name: "",
            mobileTelephone: "",
            businessTelephone: "",
            otherTelephone: "",
            email: "",
            addressStreet1: "",
            addressStreet2: "",
            city: "",
            state: "",
            postCode: "",
            emailNotification: "",
            dateOfBirth: "",
            utr: "",
            ni: "",
        };
        return contactData;
    }

    initializeSubTypeCount = (): subTypeCount => {
        var subTypeObject: subTypeCount = {
            subType: "",
            count: 0,
        };
        return subTypeObject;
    };

    initializeFilterObject = (): filterObject => {
        var filterCriteria: filterObject = {
            serviceSubtype: "",
            types: [],
            count: 0,
        };
        return filterCriteria;
    };

    initializeBankInformation(): BankInformation {
        var bankData: BankInformation = {
            id: "",
            businessEntityID: "",
            contactID: "",
            bankName: "",
            branchName: "",
            accountNumber: "",
            sortCode: "",
            buildingSociety: "",
            nameOnAccount: "",
            active: "",
            serviceTypeLookUpId: "",
            serviceSubTypeLookUpId: "",
        };
        return bankData;
    }

    initializeArtistData(): ArtistProfileResponse {
        var formData: ArtistProfileResponse = {
            businessEntityID: "",
            businessEntityTypeLookUpId: "",
            businessEntityType: "",
            legalName: "",
            tradingName: "",
            shortName: "",
            businessEntityLegalStatusLookUpId: "",
            businessEntityLegalStatus: "",
            companyNumber: "",
            tax_Applicable: "",
            provider_Payment_Offset_Days: "",
            taxRegistrationNumber: "",
            addressStreet1: "",
            addressStreet2: "",
            addressStreet3: "",
            city: "",
            state: "",
            country: "",
            postCode: "",
            professionalName: "",
            score: "",
            bio: "",
            shortDescription: "",
            coverageMiles: "",
            termsAgreed: "",
            venueFavourite: "",
            lastPlayedVenue: "",
            contactInformation: this.initializeContactInformation(),
            bankInformation: this.initializeBankInformation(),
            linkInformation: [],
            typeList: [],
            showInterest: [],
            artistApart: "",
            makeOfferAmount: "",
            comments: "",
            feedbackTypeId: "",
            feedbackScore: "",
            feedbackComments: "",
            trustedUser: false,
        };

        return formData;
    }

    //Helper function that takes in an array of Link Information and returns the primary picture.
    returnPrimaryPhoto = (linkInformation: LinkInformation[]): string => {
        return this.returnLink(linkInformation, PhotoTypes.Primary);
    };

    //Helper function that takes in an array of Link Information and returns the primary picture.
    returnLink = (linkInformation: LinkInformation[], linkTypeName: string): string => {
        var displayLink: string = "";
        for (var item = 0; item < linkInformation.length; item++) {
            if (linkInformation[item].linkTypeId == PhotoTypes.Primary) {
                displayLink = linkInformation[item].displayLink ? linkInformation[item].displayLink : "";
                break;
            }
        }
        return displayLink;
    };

    //Helper function that takes in the list of all the cards and returns the one added to request.
    filterViewAllIntoRequests = (
        artistCards: ArtistProfileResponse[],
        requestedCardsList: string[]
    ): ArtistProfileResponse[] => {
        var resultArray: ArtistProfileResponse[] = [];
        resultArray =
            requestedCardsList && requestedCardsList.length > 0
                ? artistCards.filter((item: ArtistProfileResponse) => {
                    return requestedCardsList.indexOf(item.businessEntityID) !== -1;
                })
                : [];
        return resultArray;
    };

    sortCardsBasedOnStarRating = (filteredCards: ArtistProfileResponse[]): ArtistProfileResponse[] => {
        var sortedArray: ArtistProfileResponse[] = [];
        sortedArray = [...filteredCards].sort((a, b) => {
            return -(this.convertStringToFloat(a.score) - this.convertStringToFloat(b.score));
        });
        return sortedArray;
    };

    initializeRequestItem = (obj: any): RequestFormData => {
        var formData: RequestFormData = {
            id: obj && obj.id ? obj.id : "0",
            startTime:
                obj && obj.startTime
                    ? this.utils.convertDateTimeStringToTime(obj.startTime)
                    : this.utils.convertStringToTime(""),
            finishTime:
                obj && obj.finishTime
                    ? this.utils.convertDateTimeStringToTime(obj.finishTime)
                    : this.utils.convertStringToTime(""),
            serviceSubTypeId: obj && obj.serviceSubTypeId ? obj.serviceSubTypeId : "",
            serviceTypeId: obj && obj.serviceTypeId ? obj.serviceTypeId : "",
            clientBusinessEntityId: obj && obj.clientBusinessEntityId ? obj.clientBusinessEntityId : "",
            venueId: obj && obj.venueId ? obj.venueId : "",
            venueTypeId: obj && obj.venueTypeId ? obj.venueTypeId : "",
            //dateFrom: obj && obj.dateFrom ? this.utils.convertStringToDate(obj.dateFrom) : undefined,
            dateFrom: obj && obj.dateFrom ? (!this.utils.isDefaultDate(obj.dateFrom) ? this.utils.convertStringToDate(obj.dateFrom) : undefined) : undefined,
            equipmentRequired: obj && obj.equipmentRequired ? obj.equipmentRequired : "",
            performanceInformationSetsNumber:
                obj && obj.performanceInformationSetsNumber ? obj.performanceInformationSetsNumber : 0,
            performanceInformationSetsDuration:
                obj && obj.performanceInformationSetsDuration ? obj.performanceInformationSetsDuration : 0,
            additionalInformation: obj && obj.additionalInformation ? obj.additionalInformation : "",
            entertainmentDescription: obj && obj.entertainmentDescription ? obj.entertainmentDescription : "",
            budget: obj && obj.budget ? obj.budget : 0,
            maxBudget: obj && obj.maxBudget ? obj.maxBudget : 0,
            marketPlaceBudget:obj && obj.marketPlaceBudget ? obj.marketPlaceBudget : 0,
            providerPayAmountLocal: obj && obj.providerPayAmountLocal ? obj.providerPayAmountLocal : 0,
            grossProfitAmountLocal: obj && obj.grossProfitAmountLocal ? obj.grossProfitAmountLocal : 0,
            clientBillableAmountLocal: obj && obj.clientBillableAmountLocal ? obj.clientBillableAmountLocal : 0, 
            isRounded: obj && obj.isRounded ? obj.isRounded : false,
            internalComments: obj && obj.internalComments ? obj.internalComments : "",
            lastUpdatedOn: obj && obj.lastUpdatedOn ? obj.lastUpdatedOn : "",
            typeLookUpId: obj && obj.typeLookUpId ? obj.typeLookUpId : "",
            requeststatusValue: obj && obj.requeststatusValue ? obj.requeststatusValue : "",
            requestStatusId: obj && obj.requestStatusId ? obj.requestStatusId : "",
            featured: obj && obj.featured ? obj.featured : "",
            reoccurrence: obj && obj.reoccurrence ? obj.reoccurrence : "",
            reoccurrenceFrequency: obj && obj.reoccurrenceFrequency ? obj.reoccurrenceFrequency : "",
            equipmentType: obj && obj.equipmentType ? obj.equipmentType : [],
            musicTypeList: obj && obj.musicTypeList ? obj.musicTypeList : [],
            genreList: obj && obj.genreList ? obj.genreList : [],
            djStyleList: obj && obj.djStyleList ? obj.djStyleList : [],
            publishedDate: obj && obj.publishedDate ? obj.publishedDate : "",
            imageLinkId: obj && obj.imageLinkId ? obj.imageLinkId : "",
            displayLink: obj && obj.displayLink ? obj.displayLink : "",
            otherDescription: obj && obj.otherDescription ? obj.otherDescription : "",
            reoccurrenceEndDateKnown : obj && obj.reoccurrenceEndDateKnown ? obj.reoccurrenceEndDateKnown : "",
            reoccurrenceEndDate : obj && obj.reoccurrenceEndDate ? obj.reoccurrenceEndDate : "",
            approvalDate:  obj && obj.approvalDate ? obj.approvalDate : "",
            invoiceId: obj && obj.invoiceId ? obj.invoiceId : "",
            paymentId: obj && obj.paymentId ? obj.paymentId : "",
            billableItemId: obj && obj.billableItemId ? obj.billableItemId : "",
            providerBusinessEntityId: obj && obj.providerBusinessEntityId ? obj.providerBusinessEntityId :"",
            requestDate: obj && obj.requestDate ? obj.requestDate :"",
            dateCreated : obj && obj.dateCreated ? obj.dateCreated :'',
            requestApprover: obj && obj.requestApprover ? obj.requestApprover :"",
            requestApproverPhoneNumber: obj && obj.requestApproverPhoneNumber ? obj.requestApproverPhoneNumber :"",
            twoTierApprovalDate:obj && obj.twoTierApprovalDate ? obj.twoTierApprovalDate : '',
            twoTierApprovedBy:obj && obj.twoTierApprovedBy ? obj && obj.twoTierApprovedBy : ''
        };
        return formData;
    };

    initializeCleaningRequestItem = (obj: any): RequestFormData => {
        let gigType = GlobalService.getGigComingFrom();
        let serviceTypeVal = "";
        if(gigType == "ContractCleaning"){
            serviceTypeVal = ServiceType.ContractCleaning;
        }
        else if(gigType == "Gardening"){
            serviceTypeVal = ServiceType.Gardening;
        }
        else if(gigType == "WindowCleaning"){
            serviceTypeVal = ServiceType.WindowCleaning;
        }
        else if(gigType == "Maintenance"){
            serviceTypeVal = ServiceType.Maintenance;
        }
        else if(gigType == "Miscelleanous"){
            serviceTypeVal = ServiceType.Miscelleanous;
        }
        var formData: RequestFormData = {
            id: obj && obj.id ? obj.id : "0",
            serviceSubTypeId: obj && obj.serviceSubTypeId ? obj.serviceSubTypeId : "",
            serviceTypeId: serviceTypeVal,
            clientBusinessEntityId: obj && obj.clientBusinessEntityId ? obj.clientBusinessEntityId : "",
            displayLink: obj && obj.displayLink ? obj.displayLink : "",
            typeLookUpId: obj && obj.typeLookUpId ? obj.typeLookUpId : "",
            venueId: obj && obj.venueId ? obj.venueId : "",
            dateFrom: obj && obj.dateFrom ? (!this.utils.isDefaultDate(obj.dateFrom) ? this.utils.convertStringToDate(obj.dateFrom) : undefined) : undefined,
            additionalInformation: obj && obj.additionalInformation ? obj.additionalInformation : "",
            entertainmentDescription: obj && obj.entertainmentDescription ? obj.entertainmentDescription : "",
            budget: obj && obj.budget ? obj.budget : 0,
            maxBudget: obj && obj.maxBudget ? obj.maxBudget : 0,
            marketPlaceBudget:obj && obj.marketPlaceBudget ? obj.marketPlaceBudget : 0,
            internalComments: obj && obj.internalComments ? obj.internalComments : "",
            lastUpdatedOn: obj && obj.lastUpdatedOn ? obj.lastUpdatedOn : "",
            requeststatusValue: obj && obj.requeststatusValue ? obj.requeststatusValue : "",
            requestStatusId: obj && obj.requestStatusId ? obj.requestStatusId : "",
            reoccurrence: obj && obj.reoccurrence ? obj.reoccurrence : "",
            reoccurrenceFrequency: obj && obj.reoccurrenceFrequency ? obj.reoccurrenceFrequency : "",
            otherDescription: obj && obj.otherDescription ? obj.otherDescription : "",
            dayOfWeekList:  obj && obj.dayOfWeekList ? obj.dayOfWeekList : [],
            hours: obj && obj.hours ? obj.hours : 0,
            minutes: obj && obj.minutes ? obj.minutes : "",
            rate: obj && obj.rate ? obj.rate : 0,
            providerBusinessEntityId: obj && obj.providerBusinessEntityId ? obj.providerBusinessEntityId :"",
            requestDate: obj && obj.requestDate ? obj.requestDate :"",
            dateCreated :obj && obj.dateCreated ? obj.dateCreated :"",
            requestApprover: obj && obj.requestApprover ? obj.requestApprover :"",
            requestApproverPhoneNumber: obj && obj.requestApproverPhoneNumber ? obj.requestApproverPhoneNumber :"",
            twoTierApprovalDate:obj && obj.twoTierApprovalDate ? obj.twoTierApprovalDate : '',
            twoTierApprovedBy:obj && obj.twoTierApprovedBy ? obj && obj.twoTierApprovedBy : ''
        };
        return formData;
    };

    // inititalize security request
    initializeSecurityRequestItem = (obj: any): RequestFormData => {
        var formData: RequestFormData = {
            id: obj && obj.id ? obj.id : "0",
            serviceSubTypeId: obj && obj.serviceSubTypeId ? obj.serviceSubTypeId : "",
            serviceTypeId: ServiceType.Security,
            typeLookUpId: obj && obj.typeLookUpId ? obj.typeLookUpId : "",
            clientBusinessEntityId: obj && obj.clientBusinessEntityId ? obj.clientBusinessEntityId : "",
            venueId: obj && obj.venueId ? obj.venueId : "",
            //dateFrom: obj && obj.dateFrom ? this.utils.convertStringToDate(obj.dateFrom) : undefined,
            //dateTo: obj && obj.dateTo ? this.utils.convertStringToDate(obj.dateTo) : undefined,
            dateFrom: obj && obj.dateFrom ? (!this.utils.isDefaultDate(obj.dateFrom) ? this.utils.convertStringToDate(obj.dateFrom) : undefined) : undefined,
            dateTo: obj && obj.dateTo ? (!this.utils.isDefaultDate(obj.dateTo) ? this.utils.convertStringToDate(obj.dateTo) : undefined) : undefined,
            additionalInformation: obj && obj.additionalInformation ? obj.additionalInformation : "",
            entertainmentDescription: obj && obj.entertainmentDescription ? obj.entertainmentDescription : "",
            internalComments: obj && obj.internalComments ? obj.internalComments : "",
            lastUpdatedOn: obj && obj.lastUpdatedOn ? obj.lastUpdatedOn : "",
            requeststatusValue: obj && obj.requeststatusValue ? obj.requeststatusValue : "",
            requestStatusId: obj && obj.requestStatusId ? obj.requestStatusId : "",
            reoccurrence: obj && obj.reoccurrence ? obj.reoccurrence : "No",
            reoccurrenceFrequency: obj && obj.reoccurrenceFrequency ? obj.reoccurrenceFrequency : "",
            reoccurrenceEndDateKnown : obj && obj.reoccurrenceEndDateKnown ? obj.reoccurrenceEndDateKnown : "",
            reoccurrenceEndDate : obj && obj.reoccurrenceEndDate ? obj.reoccurrenceEndDate : "",
            otherDescription: obj && obj.otherDescription ? obj.otherDescription : "",
            providerBusinessEntityId: obj && obj.providerBusinessEntityId ? obj.providerBusinessEntityId :"",
            shiftDetails: obj && obj.shiftDetails ? obj.shiftDetails : [],
            requestShifts: obj && obj.requestShifts ? obj.requestShifts : null,
            requestDate: obj && obj.requestDate ? obj.requestDate :"",
            dateCreated:obj && obj.dateCreated ? obj.dateCreated :"",
            requestApprover: obj && obj.requestApprover ? obj.requestApprover :"",
            requestApproverPhoneNumber: obj && obj.requestApproverPhoneNumber ? obj.requestApproverPhoneNumber :"",
            twoTierApprovalDate:obj && obj.twoTierApprovalDate ? obj.twoTierApprovalDate : '',
            twoTierApprovedBy:obj && obj.twoTierApprovedBy ? obj && obj.twoTierApprovedBy : ''
            
        };
        return formData;
    };

    // Convert the form's data into a proper item submit request
    convertFormDataToNewRequest = (
        formData: RequestFormData,
        showInterestData: string[],
        eventUKFlag: boolean,
        statusId: string,
        dateFromArr: string[]
    ): NewFormRequest => {
        let serviceTypeVal: string = "";
        if (formData.serviceTypeId) {
            serviceTypeVal = formData.serviceTypeId;
        }
        else {
            serviceTypeVal = serviceTypes.Entertainment;
        }
        var submitRequest: NewFormRequest = {
            id: formData.id,
            startTime: this.utils.convertTimeToString(formData.startTime),
            finishTime: this.utils.convertTimeToString(formData.finishTime),
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceTypeId: serviceTypeVal,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            venueTypeId: formData.venueTypeId,
            equipmentRequired: formData.equipmentRequired,
            equipmentType: formData.equipmentType,
            musicTypeList: formData.musicTypeList,
            genreList: formData.genreList,
            djStyleList: formData.djStyleList,
            performanceInformationSetsNumber: formData && formData.performanceInformationSetsNumber ? this.utils.convertNumberToString(
                formData.performanceInformationSetsNumber
            ) : "",
            performanceInformationSetsDuration: formData && formData.performanceInformationSetsDuration ? `${this.utils.convertNumberToString(
                formData.performanceInformationSetsDuration
            )}` : "",
            budget: formData && formData.budget ? `${this.utils.convertNumberToString(formData.budget)}` : "",
            maxBudget: formData && formData.maxBudget ? `${this.utils.convertNumberToString(formData.maxBudget)}` : "",
            marketPlaceBudget:formData && formData.marketPlaceBudget ? `${this.utils.convertNumberToString(formData.marketPlaceBudget)}` : "",
            isRounded: formData.isRounded,
            additionalInformation: formData.additionalInformation,
            requestStatusId: statusId,
            requestTypeId: RequestTypeEnum.FindanArtist,
            showInterest: this.mapShowInterest(showInterestData, eventUKFlag),
            typeLookUpId: formData.typeLookUpId,
            internalComments: formData.internalComments,
            featured: formData && formData.featured ? this.utils.convertSpecificStringValToBoolean(formData.featured) : false,
            reoccurrence: this.utils.convertSpecificStringValToBoolean(formData.reoccurrence),
            reoccurrenceFrequency: formData.reoccurrenceFrequency,
            dateFromList: dateFromArr,
            entertainmentDescription: formData.entertainmentDescription,
            imageLinkId: formData.imageLinkId,
            otherDescription: formData.otherDescription,
            reoccurrenceEndDateKnown : formData && formData.reoccurrenceEndDateKnown ? this.utils.convertSpecificStringValToBoolean(formData.reoccurrenceEndDateKnown) : false,
            reoccurrenceEndDate : this.utils.convertDateToString(formData.reoccurrenceEndDate),
            approvalDate: formData && formData.approvalDate ? formData.approvalDate : "",
            invoiceId: formData && formData.invoiceId ? formData.invoiceId : "",
            paymentId: formData && formData.paymentId ? formData.paymentId : "",
            billableItemId: formData && formData.billableItemId ? formData.billableItemId : "",
            providerBusinessEntityId: formData && formData.providerBusinessEntityId ? formData.providerBusinessEntityId : "",
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate)
        };
        return submitRequest;
    };
    //Convert the form's data into a proper item submit request for cleaning
    convertFormDataToNewRequestCleaning = (
        formData: RequestFormData,
        statusId: string,
        dateFromArr: string[]
    ): NewFormRequest => {
        let serviceTypeVal: string = "";
        if (formData.serviceTypeId) {
            serviceTypeVal = formData.serviceTypeId;
        }
        else {
            let gigType = GlobalService.getGigComingFrom();
            if(gigType == "ContractCleaning"){
                serviceTypeVal = ServiceType.ContractCleaning;
            }
            else if(gigType == "Gardening"){
                serviceTypeVal = ServiceType.Gardening;
            }
            else if(gigType == "WindowCleaning"){
                serviceTypeVal = ServiceType.WindowCleaning;
            }
            else if(gigType == "Maintenance"){
                serviceTypeVal = ServiceType.Maintenance;
            }
            else if(gigType == "Miscelleanous"){
                serviceTypeVal = ServiceType.Miscelleanous;
            }
        }
        var submitRequest: NewFormRequest = {
            id: formData.id,
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceTypeId: serviceTypeVal,
            typeLookUpId: formData.typeLookUpId,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            budget: formData && formData.budget ? `${this.utils.convertNumberToString(formData.budget)}` : "",
            maxBudget: formData && formData.maxBudget ? `${this.utils.convertNumberToString(formData.maxBudget)}` : "",
            marketPlaceBudget: formData && formData.marketPlaceBudget ? `${this.utils.convertNumberToString(formData.marketPlaceBudget)}` : "",
            additionalInformation: formData.additionalInformation,
            requestStatusId: statusId ? statusId : formData.requestStatusId,
            requestTypeId: RequestTypeEnum.FindanArtist,
            internalComments: formData.internalComments,
            reoccurrence: this.utils.convertSpecificStringValToBoolean(formData.reoccurrence),
            reoccurrenceFrequency: formData.reoccurrenceFrequency,
            dateFromList: dateFromArr,
            entertainmentDescription: formData.entertainmentDescription,
            otherDescription: formData.otherDescription,
            dayOfWeekList:  formData.dayOfWeekList,
            hours: formData.hours?.toString(),
            minutes: formData.minutes,
            rate: formData.rate?.toString(),
            providerBusinessEntityId: formData && formData.providerBusinessEntityId ? formData.providerBusinessEntityId : "",
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate)
        };
        return submitRequest;
    };
    //Convert the form's data into a proper item submit request for security
    convertFormDataToNewRequestSecurity = (
        formData: RequestFormData,
        statusId: string,
        dateFromArr: string[]
    ): NewFormRequest => {
        let serviceTypeVal: string = "";
        if (formData.serviceTypeId) {
            serviceTypeVal = formData.serviceTypeId;
        }
        else {
            serviceTypeVal = ServiceType.Security;
        }            
        var submitRequest: NewFormRequest = {
            id: formData.id,
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceTypeId: serviceTypeVal,
            typeLookUpId: formData.typeLookUpId,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            additionalInformation: formData.additionalInformation,
            requestStatusId: statusId ? statusId : formData.requestStatusId,
            requestTypeId: RequestTypeEnum.FindanArtist,
            internalComments: formData.internalComments,
            reoccurrence: this.utils.convertSpecificStringValToBoolean(formData.reoccurrence),
            reoccurrenceFrequency: formData.reoccurrenceFrequency,            
            reoccurrenceEndDateKnown : formData && formData.reoccurrenceEndDateKnown ? this.utils.convertSpecificStringValToBoolean(formData.reoccurrenceEndDateKnown) : false,
            reoccurrenceEndDate : this.utils.convertDateToString(formData.reoccurrenceEndDate),
            dateFromList: dateFromArr,
            dateTo : this.utils.convertDateToString(formData.dateTo),
            entertainmentDescription: formData.entertainmentDescription,
            otherDescription: formData.otherDescription,
            shiftDetails:  formData.shiftDetails,
            requestShifts:  formData.requestShifts,
            providerBusinessEntityId: formData && formData.providerBusinessEntityId ? formData.providerBusinessEntityId : "",
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate)
        };
        return submitRequest;
    };
    // Convert the form's data into a proper billable item submit request
    mapPublishRequest = (typeId: string): NewFormRequest => {
        var submitRequest: NewFormRequest = {
            requestStatusValue: RequestStatusTypes.Published,
            typeLookUpId: typeId
        };
        return submitRequest;
    };

    //convertFormData to proper request 
    mapArtistRequest = (id: string, artistConfirmationStatus: boolean, date: NullableDate, internalComments: string, featured: boolean, reoccurrence: boolean, serviceTypeId: string, serviceSubTypeId: string, clientBusinessEntityId: string, requestStatusId: string, previousRequestStatusId: string): ArtistConfirmRequest => {
        let submitRequest: ArtistConfirmRequest = {
            id: id,
            isArtistConfirmed: artistConfirmationStatus,
            artistConfirmDateTime: this.utils.convertDateToString(date),
            internalComments: internalComments,
            isFromGrid: true,
            featured: featured,
            reoccurrence: reoccurrence,
            serviceTypeId: serviceTypeId,
            serviceSubTypeId: serviceSubTypeId,
            clientBusinessEntityId: clientBusinessEntityId,
            requestStatusId: requestStatusId,
            previousRequestStatusId: previousRequestStatusId,
        };
        return submitRequest;
    }

    //convertFormData to proper request 
    mapReConfirmRequest = (id: string, reConfirmationStatus: boolean, date: NullableDate, internalComments: string, featured: boolean, reoccurrence: boolean, serviceTypeId: string, clientBusinessEntityId: string): ReConfirmRequest => {
        let submitRequest: ReConfirmRequest = {
            id: id,
            isReConfirmed: reConfirmationStatus,
            reConfirmDateTime: this.utils.convertDateToString(date),
            internalComments: internalComments,
            isFromGrid: true,
            featured: featured,
            reoccurrence: reoccurrence,
            serviceTypeId: serviceTypeId,
            clientBusinessEntityId: clientBusinessEntityId,
        };
        return submitRequest;
    }

    //convertFormData to proper request 
    mapVenueConfirmRequest = (id: string, venueConfirmationStatus: boolean, date: NullableDate, internalComments: string, featured: boolean, reoccurrence: boolean, serviceTypeId: string, serviceSubTypeId: string, clientBusinessEntityId: string, requestStatusId: string, previousRequestStatusId: string): VenueConfirmRequest => {
        let submitRequest: VenueConfirmRequest = {
            id: id,
            isVenueConfirmed: venueConfirmationStatus,
            venueConfirmDateTime: this.utils.convertDateToString(date),
            internalComments: internalComments,
            isFromGrid: true,
            featured: featured,
            reoccurrence: reoccurrence,
            serviceTypeId: serviceTypeId,
            serviceSubTypeId: serviceSubTypeId,
            clientBusinessEntityId: clientBusinessEntityId,
            requestStatusId: requestStatusId,
            previousRequestStatusId: previousRequestStatusId,
        };
        return submitRequest;
    }

    //convertFormData to proper request 
    mapTaggedRequest = (id: string, taggedStatus: boolean, featured: boolean, reoccurrence: boolean, serviceTypeId: string, serviceSubTypeId: string, clientBusinessEntityId: string): TaggedRequest => {
        let submitRequest: TaggedRequest = {
            id: id,
            IsTagged: taggedStatus,
            isFromGrid: true,
            featured: featured,
            reoccurrence: reoccurrence,
            serviceTypeId: serviceTypeId,
            serviceSubTypeId: serviceSubTypeId,
            clientBusinessEntityId: clientBusinessEntityId,
        };
        return submitRequest;
    }

    mapTagUntagRequest = (id: string, taggedStatus: boolean): TaggedRequest => {
        let tagUntagRequest: TaggedRequest = {
            id: id,
            IsTagged: taggedStatus
        };
        return tagUntagRequest;
    }

    mapShowInterest = (showInterestData: string[], eventUKFlag: boolean): ShowInterestType[] => {
        var showIntItem: ShowInterestType;
        var showInterestArray: ShowInterestType[];
        showInterestArray = [];
        if (showInterestData && showInterestData.length > 0) {
            for (var item = 0; item < showInterestData.length; item++) {
                showIntItem = {
                    providerBusinessEntityId: showInterestData[item],
                    showInterestStatusId: eventUKFlag
                        ? ShowInterestStatusIdTypes.Suggested
                        : ShowInterestStatusIdTypes.Requested,
                    showInterestStatus: eventUKFlag
                        ? ShowInterestStatusTypes.Suggested
                        : ShowInterestStatusTypes.Requested,
                    makeOfferAmount: "",
                    comments: "",
                    selectedOrderNumber: "",
                };
                showInterestArray.push(showIntItem);
            }
        }
        return showInterestArray;
    };

    //convertFormData to proper request for optionsSentRequest
    mapOptionsSentRequest = (id: string, optionSentStatus: boolean, date: NullableDate, internalComments: string, featured: boolean, reoccurrence: boolean, serviceTypeId: string, serviceSubTypeId:string, clientBusinessEntityId: string, requestStatusId: string, previousRequestStatusId: string): OptionsSentRequest => {
        let submitRequest: OptionsSentRequest = {
            id: id,
            isOptionsSent: optionSentStatus,
            optionsSentDateTime: this.utils.convertDateToString(date),
            internalComments: internalComments,
            isFromGrid: true,
            featured: featured,
            reoccurrence: reoccurrence,
            serviceTypeId: serviceTypeId,
            serviceSubTypeId: serviceSubTypeId,
            clientBusinessEntityId: clientBusinessEntityId,
            requestStatusId: requestStatusId,
            previousRequestStatusId: previousRequestStatusId
        };
        return submitRequest;
    }

    //convertFormData to proper request for optionsSentRequest
    mapShowInterestOptionsSentRequest = (requestId: string, optionSentStatus: boolean, date: NullableDate, internalComments: string, showInterestArr: AppliedArtistObj[], serviceTypeId: string, serviceSubTypeId: string, clientBusinessEntityId:string, requestStatusId: string, previousRequestStatusId:string): ShowInterestOptionsSentRequest => {
        let submitRequest: ShowInterestOptionsSentRequest = {
            id: requestId,
            isOptionsSent: optionSentStatus,
            optionsSentDateTime: this.utils.convertDateToString(date),
            internalComments: internalComments,
            isFromGrid: true,
            showInterest:showInterestArr,
            serviceTypeId: serviceTypeId,
            serviceSubTypeId: serviceSubTypeId,
            clientBusinessEntityId: clientBusinessEntityId,
            requestStatusId: requestStatusId,
            previousRequestStatusId: previousRequestStatusId
        };
        return submitRequest;
    }


    mapLastUpdatedInfo = (lastUpdatedOn: string): LastUpdatedInformation => {
        var lastUpdatedInfo: LastUpdatedInformation;
        var parts = !this.isBlank(lastUpdatedOn) ? lastUpdatedOn.split(" ") : null;
        lastUpdatedInfo = {
            lastUpdatedDate: parts && parts.length > 0 ? this.utils.convertstringToddmmmyyyyFormat(parts[0]) : "",
            lastUpdatedTime: parts && parts.length > 0 ? this.utils.convertstringTohhmmFormat(parts[1]) : "",
        };
        return lastUpdatedInfo;
    };
    // Convert the Request data to form data
    convertServerResponseToFormData = (serverResponse: any): RequestFormData => {
        let publisedDateVal: string = "";
        if (serverResponse.publishedDate) {
            publisedDateVal = this.utils.formattedDateValue(this.gridUtils.convertUTCStringToDate(serverResponse.publishedDate));
        }
        var formData: RequestFormData = {
            id: serverResponse.id,
            startTime: this.utils.convertStringToTime(serverResponse.startTime),
            finishTime: this.utils.convertStringToTime(serverResponse.finishTime),
            serviceSubTypeId: serverResponse.serviceSubTypeId,
            serviceTypeId: serverResponse.serviceTypeId,
            clientBusinessEntityId: serverResponse.clientBusinessEntityId,
            venueId: serverResponse.venueId,
            venueTypeId: serverResponse.venueTypeId,
            //dateFrom: this.utils.convertStringToDate(serverResponse.dateFrom),
            //dateTo: this.utils.convertStringToDate(serverResponse.dateTo),
            dateFrom: serverResponse && serverResponse.dateFrom ? (!this.utils.isDefaultDate(serverResponse.dateFrom) ? this.utils.convertStringToDate(serverResponse.dateFrom) : undefined) : undefined,
            dateTo: serverResponse && serverResponse.dateTo ? (!this.utils.isDefaultDate(serverResponse.dateTo) ? this.utils.convertStringToDate(serverResponse.dateTo) : undefined) : undefined,
            equipmentRequired: serverResponse.equipmentRequired,
            performanceInformationSetsNumber: this.utils.convertStringToInt(
                serverResponse.performanceInformationSetsNumber
            ),
            performanceInformationSetsDuration: this.utils.convertStringToInt(
                serverResponse.performanceInformationSetsDuration
            ),
            budget: this.utils.convertStringToFloat(serverResponse.budget),
            maxBudget: this.utils.convertStringToFloat(serverResponse.maxBudget),
            marketPlaceBudget: this.utils.convertStringToFloat(serverResponse.marketPlaceBudget),
            isRounded: serverResponse.isRounded,
            additionalInformation: serverResponse.additionalInformation,
            entertainmentDescription: serverResponse.entertainmentDescription,
            internalComments: serverResponse.internalComments,
            requestStatusId: serverResponse.requestStatusId,
            requeststatusValue: serverResponse.requestStatusValue,
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            typeLookUpId: serverResponse.typeLookUpId,
            featured: this.utils.convertBooleanToString(serverResponse.featured),
            reoccurrence: this.utils.convertBooleanToString(serverResponse.reoccurrence),
            reoccurrenceFrequency: serverResponse.reoccurrenceFrequency,
            equipmentType: serverResponse.equipmentType,
            musicTypeList: serverResponse.musicTypeList,
            djStyleList: serverResponse.djStyleList,
            genreList: serverResponse.genreList,
            publishedDate: publisedDateVal,
            displayLink: serverResponse.displayLink,
            imageLinkId: serverResponse.imageLinkId,
            otherDescription: serverResponse.otherDescription,
            reoccurrenceEndDateKnown : this.utils.convertBooleanToSpecificVal(serverResponse.reoccurrenceEndDateKnown),
            reoccurrenceEndDate : this.utils.convertStringToDate(serverResponse.reoccurrenceEndDate),
            daysOfWeekList:  serverResponse.daysOfWeekList,
            hours: this.utils.convertStringToInt(serverResponse.hours),
            minutes: serverResponse.minutes,
            rate: this.utils.convertStringToInt(serverResponse.rate),
            providerBusinessEntityId: serverResponse.providerBusinessEntityId,
            approvalDate: serverResponse.approvalDate,
            invoiceId: serverResponse.invoiceId,
            paymentId: serverResponse.paymentId,
            billableItemId: serverResponse.billableItemId,
            shiftDetails: serverResponse.shiftDetails,
            requestShifts: serverResponse.requestShifts,
            requestDate: serverResponse.requestDate,
            dateCreated:serverResponse.dateCreated,
            requestApprover: serverResponse.requestApprover,
            requestApproverPhoneNumber: serverResponse.requestApproverPhoneNumber,
            twoTierApprovalDate:this.utils.convertStringToDate(serverResponse.twoTierApprovalDate),
            twoTierApprovedBy : serverResponse.twoTierApprovedBy
        };
        return formData;
    };

    lookUpCreation = (response: AxiosResponse<any>): LookupTypeItem[] => {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.data.data.map((item: LookupTypeItem, uniqueKey: number) => {
            itemLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };

    isBlank = (filterId: string | null | undefined) => {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    };

    // Convert the form's data into a proper billable item submit request
    createUpdateRequest = (formData: RequestFormData, showInterestData: ShowInterestType[], statusId: string): NewFormRequest => {
        var submitRequest: NewFormRequest = {
            id: formData.id,
            startTime: this.utils.convertTimeToString(formData.startTime),
            finishTime: this.utils.convertTimeToString(formData.finishTime),
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceTypeId: formData.serviceTypeId,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            venueTypeId: formData.venueTypeId,
            dateFrom: this.utils.convertDateToString(formData.dateFrom),
            equipmentRequired: formData.equipmentRequired,
            performanceInformationSetsNumber: formData && formData.performanceInformationSetsNumber ? this.utils.convertNumberToString(
                formData.performanceInformationSetsNumber
            ) : "",
            performanceInformationSetsDuration: formData && formData.performanceInformationSetsDuration ? `${this.utils.convertNumberToString(
                formData.performanceInformationSetsDuration
            )}` : "",
            budget: formData && formData.budget ? `${this.utils.convertNumberToString(formData.budget)}` : "",
            maxBudget: formData && formData.maxBudget ? `${this.utils.convertNumberToString(formData.maxBudget)}` : "",
            marketPlaceBudget: formData && formData.marketPlaceBudget ? `${this.utils.convertNumberToString(formData.marketPlaceBudget)}` : "",
            isRounded: formData.isRounded,
            additionalInformation: formData.additionalInformation,
            entertainmentDescription: formData.entertainmentDescription,
            internalComments: formData.internalComments,
            //requestStatusId: RequestStatusTypes.Pending,
            requestTypeId: RequestTypeEnum.FindanArtist,
            typeLookUpId: formData.typeLookUpId,
            showInterest: showInterestData,
            requestStatusId: statusId ? statusId : formData.requestStatusId,
            featured: formData && formData.featured ? this.utils.convertSpecificStringValToBoolean(formData.featured) : false,
            reoccurrence: this.utils.convertSpecificStringValToBoolean(formData.reoccurrence),
            reoccurrenceFrequency: formData.reoccurrenceFrequency,
            equipmentType: formData.equipmentType,
            musicTypeList: formData.musicTypeList,
            genreList: formData.genreList,
            djStyleList: formData.djStyleList,
            imageLinkId: formData.imageLinkId,
            otherDescription: formData.otherDescription,
            reoccurrenceEndDateKnown : formData && formData.reoccurrenceEndDateKnown ? this.utils.convertSpecificStringValToBoolean(formData.reoccurrenceEndDateKnown) : false,
            reoccurrenceEndDate : this.utils.convertDateToString(formData.reoccurrenceEndDate),            
            approvalDate: formData && formData.approvalDate ? formData.approvalDate : "",
            invoiceId: formData && formData.invoiceId ? formData.invoiceId : "",
            paymentId: formData && formData.paymentId ? formData.paymentId : "",
            billableItemId: formData && formData.billableItemId ? formData.billableItemId : "",
            providerBusinessEntityId: formData && formData.providerBusinessEntityId && formData.providerBusinessEntityId !== "0" ? formData.providerBusinessEntityId : "",
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate)

        };
        return submitRequest;
    };

    // Convert the form's data into a proper billable item submit request cleaning
    createUpdateRequestCleaning = (formData: RequestFormData, statusId: string): NewFormRequest => {
        var submitRequest: NewFormRequest = {
            id: formData.id,
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceTypeId: formData.serviceTypeId,
            typeLookUpId: formData.typeLookUpId,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            dateFrom: this.utils.convertDateToString(formData.dateFrom),
            budget: formData && formData.budget ? `${this.utils.convertNumberToString(formData.budget)}` : "",
            maxBudget: formData && formData.maxBudget ? `${this.utils.convertNumberToString(formData.maxBudget)}` : "",
            marketPlaceBudget: formData && formData.marketPlaceBudget ? `${this.utils.convertNumberToString(formData.marketPlaceBudget)}` : "",
            additionalInformation: formData.additionalInformation,
            entertainmentDescription: formData.entertainmentDescription,
            internalComments: formData.internalComments,
            //requestStatusId: RequestStatusTypes.Pending,
            requestTypeId: RequestTypeEnum.FindanArtist,
            requestStatusId: statusId ? statusId : formData.requestStatusId,
            reoccurrence: this.utils.convertSpecificStringValToBoolean(formData.reoccurrence),
            reoccurrenceFrequency: formData.reoccurrenceFrequency,
            otherDescription: formData.otherDescription,
            dayOfWeekList:  formData.dayOfWeekList,
            hours: formData.hours?.toString(),
            minutes: formData.minutes,
            rate: formData.rate?.toString(),
            providerBusinessEntityId: formData && formData.providerBusinessEntityId && formData.providerBusinessEntityId !== "0" ? formData.providerBusinessEntityId : "",
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate)
        };
        return submitRequest;
    };

    // Convert the form's data into a proper billable item submit request security
    createUpdateRequestSecurity = (formData: RequestFormData, statusId: string ): NewFormRequest => {  
        var submitRequest: NewFormRequest = {
            id: formData.id,
            serviceSubTypeId: "",
            serviceTypeId: formData.serviceTypeId,
            typeLookUpId: formData.typeLookUpId,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            additionalInformation: formData.additionalInformation,
            requestStatusId: statusId ? statusId : formData.requestStatusId,
            requestTypeId: RequestTypeEnum.FindanArtist,
            internalComments: formData.internalComments,
            reoccurrence: this.utils.convertSpecificStringValToBoolean(formData.reoccurrence),
            reoccurrenceFrequency: formData.reoccurrenceFrequency,
            reoccurrenceEndDateKnown : formData && formData.reoccurrenceEndDateKnown ? this.utils.convertSpecificStringValToBoolean(formData.reoccurrenceEndDateKnown) : false,
            reoccurrenceEndDate : this.utils.convertDateToString(formData.reoccurrenceEndDate),
            dateFrom: this.utils.convertDateToString(formData.dateFrom),
            dateTo : this.utils.convertDateToString(formData.dateTo),
            entertainmentDescription: formData.entertainmentDescription,
            otherDescription: formData.otherDescription,
            shiftDetails:  formData.shiftDetails,
            requestShifts:  formData.requestShifts,
            providerBusinessEntityId: formData && formData.providerBusinessEntityId && formData.providerBusinessEntityId !== "0" ? formData.providerBusinessEntityId : "",
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate)
        };
        return submitRequest;
    };

    createBookRequest = (formData: RequestFormData, showInterestData: ShowInterestType[]): NewFormRequest => {
        let providerBusinessEntityId: string = "";
        showInterestData && showInterestData.length > 0
            ? showInterestData.map((item: ShowInterestType) => {
                if(item.showInterestStatusId == ShowInterestStatusIdTypes.Booked){
                    providerBusinessEntityId = item.providerBusinessEntityId ? item.providerBusinessEntityId : "";
                }
        })
        : null;
        var submitRequest: NewFormRequest = {
            id: formData.id,
            startTime: this.utils.convertTimeToString(formData.startTime),
            finishTime: this.utils.convertTimeToString(formData.finishTime),
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceTypeId: formData.serviceTypeId,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            venueTypeId: formData.venueTypeId,
            dateFrom: this.utils.convertDateToString(formData.dateFrom),
            equipmentRequired: formData.equipmentRequired,
            performanceInformationSetsNumber: formData && formData.performanceInformationSetsNumber ? this.utils.convertNumberToString(
                formData.performanceInformationSetsNumber
            ) : "",
            performanceInformationSetsDuration: formData && formData.performanceInformationSetsDuration ? `${this.utils.convertNumberToString(
                formData.performanceInformationSetsDuration
            )}` : "",
            budget: formData && formData.budget ? `${this.utils.convertNumberToString(formData.budget)}` : "",
            maxBudget: formData && formData.maxBudget ? `${this.utils.convertNumberToString(formData.maxBudget)}` : "",
            marketPlaceBudget:formData && formData.marketPlaceBudget ? `${this.utils.convertNumberToString(formData.marketPlaceBudget)}`:'',
            providerPayAmountLocal: formData && formData.providerPayAmountLocal ? `${this.utils.convertNumberToString(formData.providerPayAmountLocal)}` : "",
            grossProfitAmountLocal: formData && formData.grossProfitAmountLocal ? `${this.utils.convertNumberToString(formData.grossProfitAmountLocal)}` : "",
            clientBillableAmountLocal: formData && formData.clientBillableAmountLocal ? `${this.utils.convertNumberToString(formData.clientBillableAmountLocal)}` : "",
            isRounded: formData.isRounded,
            additionalInformation: formData.additionalInformation,
            entertainmentDescription: formData.entertainmentDescription,
            internalComments: formData.internalComments,
            requestStatusId: RequestStatus.Booked,
            requestStatusValue: RequestStatusTypes.Booked,
            requestTypeId: RequestTypeEnum.FindanArtist,
            typeLookUpId: formData.typeLookUpId,
            showInterest: showInterestData,
            featured: formData && formData.featured ? this.utils.convertSpecificStringValToBoolean(formData.featured) : false,
            reoccurrence: this.utils.convertSpecificStringValToBoolean(formData.reoccurrence),
            reoccurrenceFrequency: formData.reoccurrenceFrequency,
            equipmentType: formData.equipmentType,
            musicTypeList: formData.musicTypeList,
            genreList: formData.genreList,
            djStyleList: formData.djStyleList,
            imageLinkId: formData.imageLinkId,
            otherDescription: formData.otherDescription,
            reoccurrenceEndDateKnown : formData && formData.reoccurrenceEndDateKnown ? this.utils.convertSpecificStringValToBoolean(formData.reoccurrenceEndDateKnown) : false,
            reoccurrenceEndDate : this.utils.convertDateToString(formData.reoccurrenceEndDate),            
            approvalDate: formData && formData.approvalDate ? formData.approvalDate : "",
            invoiceId: formData && formData.invoiceId ? formData.invoiceId : "",
            paymentId: formData && formData.paymentId ? formData.paymentId : "",
            billableItemId: formData && formData.billableItemId ? formData.billableItemId : "",
            providerBusinessEntityId: providerBusinessEntityId,
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate)
        };
        return submitRequest;
    };

    //Convert the form's data into a proper item book request for cleaning
    createBookRequestCleaning = (formData: RequestFormData): NewFormRequest => {
        var submitRequest: NewFormRequest = {
            id: formData.id,
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceTypeId: formData.serviceTypeId,
            typeLookUpId: formData.typeLookUpId,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            dateFrom: this.utils.convertDateToString(formData.dateFrom),
            budget: formData && formData.budget ? `${this.utils.convertNumberToString(formData.budget)}` : "",
            maxBudget: formData && formData.maxBudget ? `${this.utils.convertNumberToString(formData.maxBudget)}` : "",
            marketPlaceBudget: formData && formData.marketPlaceBudget ? `${this.utils.convertNumberToString(formData.marketPlaceBudget)}` : "",
            additionalInformation: formData.additionalInformation,
            entertainmentDescription: formData.entertainmentDescription,
            internalComments: formData.internalComments,
            requestStatusId: RequestStatus.Booked,
            requestStatusValue: RequestStatusTypes.Booked,
            requestTypeId: RequestTypeEnum.FindanArtist,
            reoccurrence: this.utils.convertSpecificStringValToBoolean(formData.reoccurrence),
            reoccurrenceFrequency: formData.reoccurrenceFrequency,
            otherDescription: formData.otherDescription,
            dayOfWeekList:  formData.dayOfWeekList,
            hours: formData.hours?.toString(),
            minutes: formData.minutes,
            rate: formData.rate?.toString(),
            providerBusinessEntityId: formData && formData.providerBusinessEntityId && formData.providerBusinessEntityId !== "0" ? formData.providerBusinessEntityId : "",
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate)
        };
        return submitRequest;
    };

    //Convert the form's data into a proper item book request for security
    createBookRequestSecurity = (formData: RequestFormData): NewFormRequest => { 
        var submitRequest: NewFormRequest = {
            id: formData.id,
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceTypeId: formData.serviceTypeId,
            typeLookUpId: formData.typeLookUpId,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            additionalInformation: formData.additionalInformation,
            requestStatusId: RequestStatus.Booked,
            requestTypeId: RequestTypeEnum.FindanArtist,
            internalComments: formData.internalComments,
            reoccurrence: this.utils.convertSpecificStringValToBoolean(formData.reoccurrence),
            reoccurrenceFrequency: formData.reoccurrenceFrequency,
            reoccurrenceEndDateKnown : formData && formData.reoccurrenceEndDateKnown ? this.utils.convertSpecificStringValToBoolean(formData.reoccurrenceEndDateKnown) : false,
            reoccurrenceEndDate : this.utils.convertDateToString(formData.reoccurrenceEndDate), 
            dateFrom: this.utils.convertDateToString(formData.dateFrom),
            dateTo : this.utils.convertDateToString(formData.dateTo),
            entertainmentDescription: formData.entertainmentDescription,
            otherDescription: formData.otherDescription,
            shiftDetails:  formData.shiftDetails,
            requestShifts:  formData.requestShifts,
            providerBusinessEntityId: formData && formData.providerBusinessEntityId && formData.providerBusinessEntityId !== "0" ? formData.providerBusinessEntityId : "",
            twoTierApprovalDate:this.utils.convertDateToString(formData.twoTierApprovalDate)
        };
        return submitRequest;
    };

    buildIdList = (listItem: any): string[] => {
        var List = listItem.typeOptions;
        var IdList: string[] = [];
        for (var item = 0; item < List.length; item++) {
            IdList.push(List[item].id);
        }
        return IdList;
    };

    buildTypeList = (listItem: any): string[] => {
        var List = listItem.typeOptions;
        var typeList: string[] = [];
        for (var item = 0; item < List.length; item++) {
            typeList.push(List[item].value);
        }
        return typeList;
    };

    //Function to extract the types out of a typeItem.
    extractTypes = (item: any): filterObject => {
        var typeList: string[] = [];
        var subType: filterObject = this.initializeFilterObject();
        var List = item.typeOptions;
        if (List.length > 1) {
            //Do not extract the typeList for Karaoke and Pub quiz.
            for (var element = 0; element < List.length; element++) {
                typeList.push(List[element].id);
            }
        } else {
            typeList = [];
        }
      
            subType.serviceSubtype = item.id;
            subType.types = typeList;
        return subType;
    };

    updateTypeListArray = (typeListObjectArray: filterObject[], typeListObject: filterObject): filterObject[] => {
        //Insert the updated node in the array of Objects.
        if (typeListObject.serviceSubtype) {
            for (var item = 0; item < typeListObjectArray.length; item++) {
                if (
                    typeListObjectArray[item].serviceSubtype
                        .trim()
                        .toLowerCase()
                        .localeCompare(typeListObject.serviceSubtype.trim().toLowerCase()) == 0
                ) {
                    typeListObjectArray[item] = typeListObject;
                }
            }
        }
        return typeListObjectArray;
    };

    // //The Main helper function that will filter the Entire card List based on the Types selected in the Sidebar.
    filterArtistCardsBasedOnType = (
        listOfArtistCards: ArtistProfileResponse[],
        ListOfTypes: filterObject[]
    ): ArtistProfileResponse[] => {
        var typesList: filterObject[] = ListOfTypes;
        var typeListForEachCard: string[] = [];
        var filteredCards: ArtistProfileResponse[] = [];
        for (var artistItem = 0; artistItem < listOfArtistCards.length; artistItem++) {
            var countOfTypes: number = 0;
            typeListForEachCard = [];
            if (listOfArtistCards[artistItem].typeList.length > 0) {
                for (var typeItem = 0; typeItem < listOfArtistCards[artistItem].typeList.length; typeItem++) {
                    typeListForEachCard.push(listOfArtistCards[artistItem].typeList[typeItem].value);
                }
            }
            if (typeListForEachCard && typeListForEachCard.length > 0) {
                for (var item = 0; item < typesList.length; item++) {
                    if (typesList[item].types.length > 0) {
                        for (var type = 0; type < typesList[item].types.length; type++) {
                            if (typeListForEachCard.indexOf(typesList[item].types[type]) > -1) {
                                countOfTypes += 1;
                                if (countOfTypes == 1) {
                                    filteredCards.push(listOfArtistCards[artistItem]);
                                }
                            }
                        }
                    }
                }
            }
        }
        return filteredCards;
    };

    //Function that would return the modified types list and return the count of each subType in the filtered card array.
    countCardsBasedOnType = (
        listOfArtistCards: ArtistProfileResponse[],
        ListOfTypes: filterObject[]
    ): subTypeCount[] => {
        var typeCountList: subTypeCount;
        var count: number = 0;
        var requestId: string[] = [];
        var entityId: string = "";
        var typeCountListArray: subTypeCount[] = [];
        var typeListForEachCard: string[] = [];
        for (var item = 0; item < ListOfTypes.length; item++) {
            typeCountList = this.initializeSubTypeCount();
            requestId = [];
            var subType: string = ListOfTypes[item].serviceSubtype;
            if (ListOfTypes[item].types.length > 0) {
                for (var individualType = 0; individualType < ListOfTypes[item].types.length; individualType++) {
                    for (var artistItem = 0; artistItem < listOfArtistCards.length; artistItem++) {
                        entityId = listOfArtistCards[artistItem].businessEntityID;
                        typeListForEachCard = [];
                        if (listOfArtistCards[artistItem].typeList.length > 0) {
                            for (
                                var typeItem = 0;
                                typeItem < listOfArtistCards[artistItem].typeList.length;
                                typeItem++
                            ) {
                                typeListForEachCard.push(listOfArtistCards[artistItem].typeList[typeItem].value);
                            }
                        }
                        if (typeListForEachCard.indexOf(ListOfTypes[item].types[individualType]) > -1) {
                            if (requestId.indexOf(entityId) > -1) {
                                count = typeCountList.count;
                            } else {
                                requestId.push(entityId);
                                count = typeCountList.count + 1;
                            }
                            typeCountList.subType = subType;
                            typeCountList.count = count;
                        }
                    }
                }
            }
            typeCountListArray.push(typeCountList);
        }
        return typeCountListArray;
    };
    // A helper function that checks whether the there are any types currently checked in the sidebar.
    //When you check something and no search matches, you might not know whether the type was ever clicked or not.
    countOfTypes = (newListOfTypes: filterObject[]): number => {
        var count: number = 0;
        for (var item = 0; item < newListOfTypes.length; item++) {
            for (var singleType = 0; singleType < newListOfTypes[item].types.length; singleType++) {
                count += newListOfTypes[item].types.length;
            }
        }
        return count;
    };

    displaySubTypeCount = (typesList: subTypeCount[]): string => {
        var List: subTypeCount[] = typesList;
        var separator: string = " ";
        var subTypes: string = "";
        for (var item = 0; item < List.length; item++) {
            if (List[item].count > 0) {
                subTypes = subTypes + `${List[item].subType}${separator}(${List[item].count})` + " ";
            }
        }
        return subTypes;
    };

    //A helper function that would extract the postcode from the selected venue in the Geography panel.
    extractPostCode = (venueList: any[], venueId: string): string => {
        var venuePostCode: string = "";
        for (var venue of venueList) {
            if (venue.id == venueId) {
                venuePostCode = venue.postCode;
            }
        }
        return venuePostCode;
    };
}
