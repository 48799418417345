import React from "react";
import { Redirect } from "react-router-dom";
import UserService from "../../services/UserService";

export interface withValidUserRoleProps {
    authorizedUserRoles: string[];
}
/**
 * HoC that will verify a user's role is in the list of authorized user roles and redirect the user back to the home page if their role is not found in the list.
 *
 * @param WrappedComponent {React.Component} - the wrapped component of the HoC
 * @param props {withValidUserRoleProps} - the properties required by the HoC
 */
const withValidUserRole = (WrappedComponent: React.ComponentType<withValidUserRoleProps>) => (
    props: withValidUserRoleProps
) => {
    return (
        <>
            {UserService.isUserInAnyGroup(props.authorizedUserRoles) ? (
                <WrappedComponent {...props} />
            ) : (
                <Redirect to={"/"} />
            )}
        </>
    );
};

export default withValidUserRole;
