import React from "react";
import Webcam from "react-webcam";
import { Popup } from "devextreme-react/popup";
import { alert } from "devextreme/ui/dialog";

/**
 * Converts a string of base64 image data into a blob
 *
 * @param b64Data {string}
 * @param contentType {string}
 */
const b64toBlob = (b64Data: string, contentType: string) => {
    const b64DataWithPrefixRemoved = b64Data ? b64Data.replace("data:image/jpeg;base64,", "") : "";
    const byteCharacters = atob(b64DataWithPrefixRemoved);
    const byteArrays = [];
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

/**
 * Defaults
 */
const defaultWebcamSettings = {
    width: "100%",
    height: "63%", // credit card ratio
    audio: false,
};

const videoConstraints = {
    width: { min: 480 },
    height: { min: 320 },
    aspectRatio: 1.6,
    facingMode: "environment",
};

/**
 * Props
 */
interface PhotoCaptureProps {
    imageCapture: (imageLink: any) => void;
    cameraEnabled:(isCameraEnabled: any) => void;
}
interface PhotoCaptureState {
    imageLink: string;
    imageLinkURL: string;
    cameraContraints: MediaTrackConstraints;
}
/**
 * Component
 *
 * This component allows the user to take a photo with their device's built in webcam.
 */
class PhotoCapture extends React.Component<PhotoCaptureProps> {
    state: PhotoCaptureState;
    webcamRef: React.RefObject<Webcam>;
    constructor(props: PhotoCaptureProps) {
        super(props);
        this.state = {
            imageLink: "",
            imageLinkURL: "",
            cameraContraints: videoConstraints,
        };
        this.webcamRef = React.createRef();
        this.capturePhoto = this.capturePhoto.bind(this);
        this.detectFacingMode = this.detectFacingMode.bind(this);
    }

    detectFacingMode(videoConstraintParameters: MediaTrackConstraints) {
        return navigator.mediaDevices.getUserMedia({
            video: videoConstraintParameters,
            audio: false,
        });
    }

    //Once the Re-take option is
    reInitiatePhotoCapture = () => {
        this.setState({
            imageLink: "",
            imageLinkURL: "",
        });
    };

    /**
     * Signals the webcam to snap a photo
     */
    capturePhoto(): void {
        window.URL.revokeObjectURL(this.state.imageLinkURL);

        if (this.webcamRef && this.webcamRef.current) {
            const imgSource = this.webcamRef.current.getScreenshot() || "";
            if (imgSource.length > 0) {
                const imgAsBlob = b64toBlob(imgSource, "image/jpeg");
                const imgSourceURL = window.URL.createObjectURL(imgAsBlob);
                this.setState(
                    {
                        imageLink: imgSource,
                        imageLinkURL: imgSourceURL,
                    },
                    this.setImageSource
                );
            } else {
                alert("In order to capture a photo, please ensure you have allowed this browser to access your camera. You may need to reload this page in your browser once you have enabled permissions.", "Camera access denied");

                this.props.cameraEnabled(false);
            }
        }
    }

    setImageSource = () => {
        this.props.imageCapture(this.state.imageLink);
    };

    /**
     * An alternate solution - show the image taken in a poup to 'approve'
     * This is not needed with the current solution of just relacing the webcam with the image taken.
     */
    onPopupOkClickEvent = () => {
        this.setImageSource();
        this.setState({ imageLink: "" });
    };

    /**
     * An alternate solution - show the image taken in a poup to 'approve'
     * This is not needed with the current solution of just relacing the webcam with the image taken.
     */
    onPopupCancelClickEvent = () => {
        this.setState({ imageLink: "" });
    };

    render() {
        return (
            <div>
                {this.state.imageLink.length == 0 ? (
                    <>
                        <Webcam
                            audio={defaultWebcamSettings.audio}
                            height={defaultWebcamSettings.height}
                            width={defaultWebcamSettings.width}
                            screenshotFormat="image/jpeg"
                            videoConstraints={this.state.cameraContraints}
                            ref={this.webcamRef as React.RefObject<Webcam> & string}
                            className="photo-capture__container"
                        />
                        <button
                            className="btn btn-primary mt-2"
                            onClick={this.capturePhoto}
                        >
                            Capture SIA Licence
                        </button>
                    </>
                ) : (
                    <>
                        <img
                            alt="Crop"
                            style={{ height: "100%", width: "100%" }}
                            src={this.state.imageLinkURL}
                            className="photo-capture__container"
                        />
                        <div className="mt-2">
                            <span>If the licence is not visible, please re-take, otherwise submit.</span>
                            <button className="btn btn--ghost btn--large mt-2" onClick={this.reInitiatePhotoCapture}>
                                Re-take?
                            </button>
                        </div>
                    </>
                )}

                <Popup
                    visible={false}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    fullScreen={false}
                    title="Review Captured Photo"
                >
                    <img alt="Crop" style={{ maxWidth: "100%" }} src={this.state.imageLinkURL} />
                    <button type="button" onClick={this.onPopupOkClickEvent}>
                        Use Image
                    </button>
                    <button type="button" onClick={this.onPopupCancelClickEvent}>
                        Cancel
                    </button>
                </Popup>
            </div>
        );
    }
}

export default PhotoCapture;
