import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DateBox, SelectBox, TextBox } from "devextreme-react";
import LightModePageTemplate from "../components/page/Templates/LightModeTemplate";
import SideBarTemplate from "../components/side-bar/SideBarTemplate";
import DesignSystemNavigation from "./DesignSystemNavigation";
import LookupTypeListDynamic from "../components/select/LookupTypeListDynamic";
import DropDownFromLookupTableDynamic from "../components/select/SelectBoxFromLookupTableDynamic";
import {
    SelectBoxOnValueChangedEvent,
} from "../types/DevExtremeTypes";
import { LookupTypeIndexes } from "../services/LookupService";
import DataSource from "devextreme/data/data_source";

interface ShiftWorkerLookupDemoProps extends WithTranslation {
    
}

interface ShiftWorkerLookupDemoState {
    editBoxValue: any
}

const workers = [
    { 'Name': '', 'ID': '' },
    { 'Name': 'Sangeeta', 'ID': '0000000000000000' },
    { 'Name': 'Aditya', 'ID': '1000000000000000' },
    { 'Name': 'Steve O', 'ID': '2000000000000000' },
    { 'Name': 'Steve S', 'ID': '3000000000000000' },
    { 'Name': 'Mana', 'ID': '4000000000000000' },
    { 'Name': 'Abhilasha', 'ID': '5000000000000000' },
    { 'Name': 'Monalisa', 'ID': '6000000000000000' }
  ];

const workersDataSource = new DataSource({
    store: {
      data: workers,
      type: 'array',
      key: 'ID'
    }
  });

class ShiftWorkerLookupDemo extends Component<ShiftWorkerLookupDemoProps> {
    state: ShiftWorkerLookupDemoState;

    constructor(props: ShiftWorkerLookupDemoProps) {
        super(props);
        this.state = {
            editBoxValue: workers[0]
        }
    }
    
    editBoxValueChanged = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if(dxValueChange.component) {
            this.setState({ editBoxValue: dxValueChange.component.option('selectedItem') });
        }
    };
    
    render() {
        let { editBoxValue } = this.state;        
        return (
            <LightModePageTemplate>
                <SideBarTemplate>
                    <DesignSystemNavigation />
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    <div className={"checkin-card checkin-card--security mt-3"}>
                        <div className="checkin-card__category">
                            Security - Door Supervision Personnel
                            <span className="ml-md-2 float-md-right d-block d-md-inline">
                                today's date
                            </span>
                        </div>
                        <div className="checkin-card__content">
                            <div className="checkin-card__name-field">
                                <div className="row">
                                    <div className="col-12">Choose recent worker from the list</div>
                                </div>
                                <div className="row align-items-center mt-3">
                                    <div className="col-12 col-md-2 nameText">Recent worker list</div>
                                    <div className="col-md-10">
                                        <SelectBox 
                                            dataSource={workersDataSource}
                                            displayExpr="Name"
                                            valueExpr="ID"
                                            acceptCustomValue={true}
                                            defaultValue={workers[0].ID}
                                            onValueChanged={this.editBoxValueChanged} 
                                            searchEnabled={true}
                                            searchMode="contains"
                                            searchExpr="Name"
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className="checkin-card__name-field mt-3">
                                <div className="row">
                                    <div className="col-12">Or enter full name and licence below as appearing on SIA Badge</div>
                                </div>
                                <div className="row align-items-center mt-3">
                                    <div className="col-12 col-md-2 nameText">Full Name</div>
                                    <div className="col-md-10">
                                        <TextBox
                                            placeholder="Full name as appearing on SIA Badge"
                                            value={editBoxValue.Name}
                                            onValueChanged={() => {}}
                                        ></TextBox>
                                    </div>
                                </div>
                            </div>
                            <div className="checkin-card__licence-field mt-3">
                                <div className="row align-items-center">
                                    <div className="col-md-2 nameText">Licence No</div>
                                    <div className="col-md-10">
                                        <TextBox
                                            mask="0000  -  0000  -  0000  -  0000"
                                            value={editBoxValue.ID}
                                            placeholder="Please Enter Licence No"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 align-items-center">
                                <div className="col-md-2">Role</div>
                                <div className="col-md-10">
                                    <DropDownFromLookupTableDynamic
                                        lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                        isRoot={false}
                                        parentMappingId={"11"}
                                        value={"role"}
                                        onValueChanged={() => {}}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 align-items-center">
                                <div className="col-md-2 nameText">Venue</div>
                                <div className="col-md-10">
                                        <LookupTypeListDynamic
                                            lookupTypeIndex={LookupTypeIndexes.venueType}
                                            isRoot={true}
                                            parentMappingId=""
                                            value={"venue"}
                                            onValueChanged={() => {}}
                                            displayExpression={"value"}
                                        />
                                </div>
                            </div>
                            <div className="row mt-3 align-items-center">
                                <div className="col-md-2 nameText">Shift Start</div>
                                <div className="col-md-4">
                                    <DateBox
                                        type="time"
                                        interval={15}
                                        placeholder="Select time"
                                        useMaskBehavior={true}
                                        displayFormat={"HH:mm"}
                                        onValueChanged={() => {}}
                                        pickerType={"list"}
                                    />
                                </div>
                                <div className="col-md-2 nameText">Shift End</div>
                                <div className="col-md-4">
                                    <DateBox
                                        type="time"
                                        interval={15}
                                        placeholder="Select time"
                                        useMaskBehavior={true}
                                        displayFormat={"HH:mm"}
                                        onValueChanged={() => {}}
                                        pickerType={"list"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withTranslation()(ShiftWorkerLookupDemo);