import React from "react";
import { AxiosResponse } from "axios";
import { LoadIndicator } from "devextreme-react";
import AddEditPopUpService from "../../services/AddEditPopUpService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import "../../../assets/images/no-image.png";

interface SIALicenceDetailsProps {
    billableItemId: string;
}
// State
interface SIALicenceDetailsState {
    showLoadIndicator: boolean;
    name: string;
    identificationNumber: string;
    venue: string;
    role: string;
    shift: string;
    imageLink: string;
    showImageLoader: boolean;
}

class SIALicenceDetails extends React.Component<SIALicenceDetailsProps> {
    state: SIALicenceDetailsState;
    service: AddEditPopUpService;
    utils: addEditPopupUtils;
    constructor(props: SIALicenceDetailsProps) {
        super(props);
        this.service = new AddEditPopUpService();
        this.utils = new addEditPopupUtils();
        this.state = {
            showLoadIndicator: false,
            name: "",
            identificationNumber: "",
            venue: "",
            role: "",
            shift: "",
            imageLink: "",
            showImageLoader: true,
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        this.setState({
            showLoadIndicator: true,
        });
    }

    componentDidUpdate(prevProps: SIALicenceDetailsProps) {
        if (this.props.billableItemId != prevProps.billableItemId) {
            if (this.props.billableItemId) {
                this.setState({
                    showLoadIndicator: true,
                    showImageLoader: true
                });
                this.service
                    .getEditViewData(parseInt(this.props.billableItemId))
                    .then(this.handleSuccessViewDataSource)
                    .catch(this.handleStandardError);
            }
        }
    }

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        if (response.data.data) {
            this.setState({
                name: response.data.data.name,
                identificationNumber: response.data.data.identification_Number,
                venue: response.data.data.venue,
                role: response.data.data.role,
                shift: response.data.data.shift,
                imageLink: response.data.data.imageLink,
            });
        }
        this.setState({
            showLoadIndicator: false,
        });
    };

    handleStandardError = (error: any) => {
        this.setState({
            showLoadIndicator: false,
        });
        var respMessage: string = "Add/Edit shift form load failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    renderLicenceNo() {
        return <>{this.state.identificationNumber}</>;
    }

    renderRole() {
        return <>{this.state.role}</>;
    }
    renderVenue() {
        return <>{this.state.venue}</>;
    }

    renderShift() {
        return <>{this.state.shift}</>;
    }

    onImageLoad = () => {
        this.setState({
            showImageLoader: false,
        });
    };

    onImageLoadFail = () => {
        this.setState({
            showImageLoader: false,
            imageLink: "/assets/images/no-image.png"
        });

    }

    render() {
        return this.state.showLoadIndicator ? (
            <div className="card">
                <div className="card-body">
                    <LoadIndicator
                        visible={this.state.showLoadIndicator}
                        id="button-indicator"
                        height={60}
                        width={60}
                        className="home-page__todayloader"
                    ></LoadIndicator>
                </div>
            </div>
        ) : (
            <>
                <div className="card">
                    <div className="card-body">
                        <>
                            <h3 className="page-title--medium">{this.state.name}</h3>
                            <strong>Licence No: </strong>
                            {this.renderLicenceNo()}
                            <br />
                            <strong>Role: </strong>
                            {this.renderRole()}
                            <br />
                            <strong>Venue: </strong>
                            {this.renderVenue()}
                            <br />
                            <strong>Shift: </strong>
                            {this.renderShift()}
                            <hr className="horizontal_border_style"></hr>

                            <strong className="page-title--medium">SIA Licence </strong>
                            <img
                                className="d-block mt-2 mx-auto photo-capture__container"
                                src={this.state.imageLink}
                                width="100%"
                                onLoad={this.onImageLoad}
                                onError={this.onImageLoadFail}
                            />
                            <LoadIndicator
                                visible={this.state.showImageLoader}
                                id="button-indicator"
                                height={60}
                                width={60}
                                className="home-page__todayloader"
                            ></LoadIndicator>
                        </>
                    </div>
                </div>
            </>
        );
    }
}

export default SIALicenceDetails;
