import React from "react";
import { Link } from "react-router-dom";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { faStamp } from "@fortawesome/pro-regular-svg-icons";
import { itemTypes } from "./AddEditPopUpUtilities";
import { faAdjust } from "@fortawesome/pro-regular-svg-icons";
import SimpleGridService from "../../services/SimpleGridService";
import { confirm } from "devextreme/ui/dialog";
import { LoadIndicator } from "devextreme-react";

interface AllUserGridStatusProps {
    data: any;
}

interface AllUserGridStatusState {
    Status: string;
    StatusColor: string;
    StatusBackGroundColor: string;
    billableItemId: number;
    isEditLinkDisabled: boolean;
    isDeleteLinkDisabled: boolean;
    createAdjustment: boolean;
    isCheckInOutLinkDisabled: boolean;
    isFinancialAdjustment: boolean;
    isEnableAdjustmentButton: boolean;
    loadIndicatorVisible: boolean;
    serviceValue: string;
}

class AllUserGridStatus extends React.Component<AllUserGridStatusProps> {
    state: AllUserGridStatusState;
    Service: SimpleGridService;
    constructor(props: AllUserGridStatusProps) {
        super(props);
        this.setLoadIndicatorVisibility = this.setLoadIndicatorVisibility.bind(this);
        this.Service = new SimpleGridService();
        this.state = {
            loadIndicatorVisible: false,
            Status: this.props.data.data.status,
            StatusColor: this.props.data.data.status_Color,
            StatusBackGroundColor: this.props.data.data.status_BackGroundColor,
            billableItemId: this.props.data.data.billableItemId,
            isEditLinkDisabled: false,
            isDeleteLinkDisabled: false,
            createAdjustment: false,
            isCheckInOutLinkDisabled: false,
            isFinancialAdjustment: false,
            isEnableAdjustmentButton: false,
            serviceValue: this.props.data.data.service,
        };
    }

    //This is needed here to check for some conditions when the component mounts.
    componentDidMount() {
        this.hideEditButton();
        this.hideCheckInOutIcon();
        this.getItemType();
        this.hideAdjustButton();
    }

    setLoadIndicatorVisibility(isVisible: boolean): void {
        this.setState({
            loadIndicatorVisible: isVisible,
        });
    }

    hideAdjustButton = () => {
        let childRecordId = this.props.data.data.childRecordId;
        let childRecordItemType: string;
        childRecordItemType = this.props.data.data.childRecordItemType;
        let acceptDate = this.props.data.data.accept_Date;
        let approvalDate = this.props.data.data.approval_Date;
        let payDate = this.props.data.data.pay_Date;
        let billDate = this.props.data.data.bill_Date;
        if (
            (!childRecordId || (childRecordId && !childRecordItemType.toLowerCase().includes("adjustment"))) &&
            (acceptDate || approvalDate || payDate || billDate) &&
            !this.state.serviceValue.includes("Additional Cleaning")
        ) {
            this.setState({
                isEnableAdjustmentButton: true,
            });
        }
    };
    hideCheckInOutIcon = () => {
        let dateFlag = this.props.data.data.tenseFlag;
        if (
            (UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
                UserService.isUserInGroup(RoleGroupNames.VenueManager) ||
                UserService.isUserInGroup(RoleGroupNames.HeadDoorSupervisor)) &&
            dateFlag.toUpperCase() == "CURRENT"
        ) {
            this.setState({ isCheckInOutLinkDisabled: false });
        } else {
            this.setState({ isCheckInOutLinkDisabled: true });
        }
    };

    //Function that would check in for the user role and the "Tense" flag to figure out whether to hide/show respective Icons.
    hideEditButton = () => {
        let dateFlag = this.props.data.data.tenseFlag;
        if (
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
        ) {
            this.setState({ isEditLinkDisabled: false });
        } else if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) && dateFlag.toUpperCase() === "FUTURE") {
            this.setState({ isEditLinkDisabled: false });
        } else if (
            (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ||
                UserService.isUserInGroup(RoleGroupNames.VenueManager)) &&
            dateFlag.toUpperCase() != "FUTURE" &&
            dateFlag.toUpperCase() != "CURRENT"
        ) {
            this.setState({
                isEditLinkDisabled: false,
                createAdjustment: true,
            });
        } else {
            this.setState({ isEditLinkDisabled: true });
        }

        this.hideDeleteButton();
    };

    //Enable/disable delete buttons based on the User roles.
    hideDeleteButton = () => {
        let dateFlag = this.props.data.data.tenseFlag;
        let acceptDate = this.props.data.data.accept_Date;
        let approvalDate = this.props.data.data.approval_Date;
        let payDate = this.props.data.data.pay_Date;
        let billDate = this.props.data.data.bill_Date;
        if (
            (UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
                (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) && dateFlag.toUpperCase() == "FUTURE")) &&
            !acceptDate &&
            !payDate &&
            !approvalDate &&
            !billDate
        ) {
            this.setState({ isDeleteLinkDisabled: false });
        } else {
            this.setState({ isDeleteLinkDisabled: true });
        }
    };

    getItemType = () => {
        let itemTypeId = this.props.data.data.itemTypeId;
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            if (
                itemTypeId == itemTypes.ClientCredit ||
                itemTypeId == itemTypes.ClientDebit ||
                itemTypeId == itemTypes.ProviderCredit ||
                itemTypeId == itemTypes.ProviderDebit ||
                itemTypeId == itemTypes.Receivable ||
                itemTypeId == itemTypes.Payable
            ) {
                this.setState({
                    isFinancialAdjustment: true,
                });
            }
        }
    };

    handleDeleteOnClick = () => {
        confirm("<div class='text-center'>Delete this row?</div>", "Delete").then((hasConfirmed) => {
            if (hasConfirmed) {
                this.setLoadIndicatorVisibility(true);
                //call the API for posting all the Delete Billable Id's and handle the response
                this.Service.submitDeleteShift(this.state.billableItemId.toString())
                    .then(this.handleSuccessDelete)
                    .catch(this.handleDeleteFailure);
            }
        });
    };
    handleDeleteFailure = (error: any) => {
        var respMessage: string = "submitDeleteShift service failed with response: " + JSON.stringify(error);

        if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    handleSuccessDelete = () => {
        this.props.data.component.deleteRow(this.props.data.rowIndex);
        this.props.data.component.repaint();
    };

    render() {
        let statusBackGroundColour = "";
        const id = this.state.billableItemId;
        let tenseIdentifier = this.props.data.data.tenseFlag;
        const adjustment = this.state.createAdjustment;
        if (this.state.StatusBackGroundColor != null && tenseIdentifier.toUpperCase() == "CURRENT") {
            if (this.state.StatusBackGroundColor.toUpperCase() == "BLUE") {
                statusBackGroundColour = "bg-info";
            } else if (this.state.StatusBackGroundColor.toUpperCase() == "GREEN") {
                statusBackGroundColour = "bg-success";
            } else if (this.state.StatusBackGroundColor.toUpperCase() == "RED") {
                statusBackGroundColour = "bg-danger";
            } else if (
                this.state.StatusBackGroundColor.toUpperCase() == "AMBER" &&
                this.state.Status.toUpperCase() == "PENDING"
            ) {
                statusBackGroundColour = "bg-warning";
            }
        }

        let statusColour = "";
        if (this.state.StatusColor != null) {
            if (this.state.StatusColor.toUpperCase() == "BLUE") {
                statusColour = "bg-info";
            } else if (this.state.StatusColor.toUpperCase() == "GREEN") {
                statusColour = "bg-success";
            } else if (this.state.StatusColor.toUpperCase() == "RED") {
                statusColour = "bg-danger";
            }
        }

        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <div
                            className={
                                statusBackGroundColour != ""
                                    ? `mr-2 status__circle ${statusBackGroundColour}`
                                    : `mr-2 status__circle ${statusColour}`
                            }
                        ></div>
                        <span className="status-message-color">{this.state.Status}</span>
                        <LoadIndicator
                            className="ml-3"
                            id="button-indicator"
                            height={20}
                            width={20}
                            visible={this.state.loadIndicatorVisible}
                        />
                    </div>
                    <div className="d-flex">
                        <Link
                            title="Edit"
                            className={(this.state.isDeleteLinkDisabled ? "disabled " : "") + "icon-btn"}
                            onClick={this.handleDeleteOnClick}
                            to="#"
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Link>
                        <Link
                            title="Edit"
                            className={(this.state.isEditLinkDisabled ? "disabled " : "") + "icon-btn"}
                            to={
                                this.state.isEditLinkDisabled
                                    ? "#"
                                    : this.state.isFinancialAdjustment
                                    ? {
                                          pathname: "/FinanceAdjustmentPopUp",
                                          state: {
                                              id: this.state.billableItemId.toString(),
                                              adjustment: this.state.createAdjustment,
                                          },
                                      }
                                    : {
                                          pathname: "/AddEditPopUp",
                                          state: {
                                              id: this.state.billableItemId.toString(),
                                              adjustment: this.state.createAdjustment,
                                              isEdit: true,
                                          },
                                      }
                            }
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </Link>
                        <Link
                            className={(this.state.isCheckInOutLinkDisabled ? "disabled " : "") + "icon-btn"}
                            to={
                                this.state.isCheckInOutLinkDisabled
                                    ? "#"
                                    : {
                                          pathname: "/todaysViewCheckinOut",
                                          state: {
                                              id: this.state.billableItemId.toString(),
                                          },
                                      }
                            }
                        >
                            <FontAwesomeIcon icon={faStamp} />
                        </Link>

                        <Link
                            className={(this.state.isEnableAdjustmentButton == false ? "disabled " : "") + "icon-btn"}
                            to={{
                                pathname: "/AddEditPopUp",
                                state: {
                                    id: this.state.billableItemId.toString(),
                                    isAdjust: true,
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faAdjust} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default AllUserGridStatus;
