import React, { Component, MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import sharedUtils from "../grid/sharedUtilities";
import { AddEditFormData } from "../grid/AddEditPopUpUtilities";
import MatchedPopUpPaymentRequest from "./MatchedPopUpPaymentRequest";
import PaymentRequestUtils, { PaymentRequestMatchedPopUpFormData } from "./PaymentRequestUtils";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import MatchedPopUpGrid from "./MatchedPopUpGrid";
import MatchedPopUpShiftDetails from "./MatchedPopUpShiftDetails";
import CollapsibleSection from "./CollapsibleSection";
import { LoadIndicator, LoadPanel, Popup, ScrollView } from "devextreme-react";
import { PaymentTypes } from "../../services/FindAnArtistService";
import GlobalService from "../../services/GlobalService";
import AddEditPopUpService, { BillableItemSubmitRequest } from "../../services/AddEditPopUpService";
import PaymentService from "../../services/PaymentService";
import SimpleGridService from "../../services/SimpleGridService";
import notify from "devextreme/ui/notify";
import { AxiosResponse } from "axios";

interface MatchedPopUpPageProps extends RouteComponentProps, WithTranslation {
    title: string;
    category: string;
    location: any;
    payRequestId: string;
}

interface MatchedPopUpPageState {
    editViewDataItem: PaymentRequestMatchedPopUpFormData;
    billableDataItem: AddEditFormData;
    loadPanelVisible: boolean;
    buttonState: boolean;
    count: number;
    removeButtonState: boolean;
    isUnMatchSuccessful: boolean;
    dataSource: [];
    pageloadPanelVisible: boolean;
    isEdit: boolean;
    isShowPopUp: boolean;
    isClickedConfirm: boolean;
    isClickedCancel: boolean;
    isShowToast: boolean;
    billableItemId: string;
}

class MatchedPopUpPage extends Component<MatchedPopUpPageProps> {
    state: MatchedPopUpPageState;
    utils: PaymentRequestUtils;
    sharedUtils: sharedUtils;
    billableItemUtils: addEditPopupUtils;
    editViewService: AddEditPopUpService;
    paymentService: PaymentService;
    Service: SimpleGridService;
    constructor(props: MatchedPopUpPageProps) {
        super(props);
        this.utils = new PaymentRequestUtils();
        this.sharedUtils = new sharedUtils();
        this.billableItemUtils = new addEditPopupUtils();
        this.Service = new SimpleGridService();
        this.paymentService = new PaymentService();
        this.editViewService = new AddEditPopUpService();
        var convertedEditViewDataItem = this.utils.initializePaymentRequestMatchedPopUpItems();
        var convertedBillableViewItem = this.billableItemUtils.initializeBillableItem("");
        // Initialize button states to false as a user selection of a grid row is required
        this.state = {
            editViewDataItem: convertedEditViewDataItem,
            billableDataItem: convertedBillableViewItem,
            loadPanelVisible: false,
            buttonState: false,
            count: 0,
            removeButtonState: false,
            isUnMatchSuccessful: false,
            dataSource: [],
            pageloadPanelVisible: true,
            isEdit: false,
            isShowPopUp: false,
            isShowToast: false,
            billableItemId: "",
            isClickedCancel: false,
            isClickedConfirm: false
        };
    }

    setPayReqItemObj = (dataItem: PaymentRequestMatchedPopUpFormData) => {
        this.setState({
            editViewDataItem: dataItem
        });
    }

    componentDidMount() {
        setTimeout(this.hidepageLoadIndicator, 500);
        let isApply = GlobalService.getApplyState();
        let isClose = GlobalService.getCloseState();
        let billableItemId = GlobalService.getBillableItemId();
        let newShift = GlobalService.getNewShift();
        if (isClose && newShift) {
            notify({ message: "Newly Added Shift has NOT been added", width: 400, shading: true }, 'error', 600);
        }
        this.setState({
            isShowPopUp: isApply,
            billableItemId: billableItemId
        });
        GlobalService.removeApplyState();
        GlobalService.removeCloseState();
        GlobalService.removeBillableItemId();
        GlobalService.removeNewShift();
    }

    hidepageLoadIndicator = () => {
        this.setState({
            pageloadPanelVisible: false
        });
    }

    captureEditButtonState = (isEditClicked: boolean) => {
        this.setState({
            isEdit: isEditClicked
        });
    }


    setBillableItemObj = (billableItemObj: AddEditFormData) => {
        this.setState({
            billableDataItem: billableItemObj
        });
    }

    venueNameExtraction = (venue: string) => {
        var billableItemVenue: string = "";
        var parts = venue.split("-");
        billableItemVenue = parts[1];
        return billableItemVenue;
    }


    setPaymentGridRecordDataSource = (count: number) => {
        this.setState({
            count: count
        });
    }
    setMatchButtonState = (buttonState: boolean, loadPanelVisible: boolean) => {
        this.setState({
            loadPanelVisible: loadPanelVisible,
            buttonState: buttonState
        })
    }

    setRemoveButtonState = (buttonState: boolean) => {
        this.setState({
            removeButtonState: buttonState
        })
    }

    setUnMatchSuccessful = (isUnmatched: boolean) => {
        this.setState({
            isUnMatchSuccessful: isUnmatched
        });
    }
    onSaveClose = () => {
        this.setState({
            isShowPopUp: false
        });
    }

    onConfirm = () => {
        this.setState({
            isClickedConfirm: true
        });
        // first fetch the record based on billableItemId
        this.editViewService
            .getEditViewData(parseInt(this.state.billableItemId))
            .then(this.handleSuccessViewDataSource)
            .catch(this.handleStandardError);
    }

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        // Convert to proper data types
        let viewDataSource = this.billableItemUtils.convertBillableItemResponeToFormData(response.data.data, false);
        this.setState({
            billableDataItem: viewDataSource
        });
        let paymentRequestDate: string = "";
        if (this.state.editViewDataItem && this.state.editViewDataItem.requestDate) {
            paymentRequestDate = this.state.editViewDataItem.requestDate;
        }
        let billableItemSubmitRequest = this.billableItemUtils.convertFormDataToBillableItemRequestMatchUnmatch(
            this.state.billableDataItem,
            "",
            "",
            false,
            "",
            "",
            "",
            undefined,
            undefined,
            true,
            "paymentMatch",
            false,
            this.state.editViewDataItem.id,
            paymentRequestDate,
            this.state.editViewDataItem.providerBusinessEntity && this.state.editViewDataItem.providerBusinessEntity.id
        );
        let billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
        billableItemSubmitRequestArr.push(billableItemSubmitRequest);
        //passing object data to the server
        this.editViewService.uploadEditAddDataV3(billableItemSubmitRequestArr)
            .then(() => this.handleFormDataSubmissionSuccess("match"))
            .catch((err) => {
                this.setState({
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                    IsShowSuccess: false,
                });
                this.hideLoadpanel();
            })
            .finally(() => {
                this.hideLoadpanel();
            });

    }

    hideLoadpanel = () => {
        this.setState({
            loadPanelVisible: false
        });
    }

    handleFormDataSubmissionSuccess = (navigatedFrom: string) => {
        if (navigatedFrom == "match") {
            this.paymentService.postMatchPopUpData(parseInt(this.state.editViewDataItem.id), true, this.state.editViewDataItem.paymentRequestType.id)
                .then(this.handleMatchPopUpDataSubmissionSuccess).catch(this.handleError)
        }
    };

    handleMatchPopUpDataSubmissionSuccess = () => {
        this.hideLoadpanel();
        notify({ message: "Payment Request Match Successful", width: 400, shading: true }, 'success', 600);
        this.renderRedirect();
    }

    renderRedirect = () => {
        this.props.history.push({
            pathname: "/paymentRequests",
            state: {
                pointOfNavigation: "popUp"
            },
        });
    };

    handleStandardError = (error: any) => {
        let respMessage: string = "Billable Item get service failed with response: " + JSON.stringify(error);

        if (!this.editViewService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }

    handleError = (error: any) => {
        let respMessage: string = "Payment Matching service failed with response: " + JSON.stringify(error);

        if (!this.paymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }

    onCancel = () => {
        //call the API for posting all the Delete Billable Id's and handle the response
        this.Service.submitDeleteShift(this.state.billableItemId)
            .then(this.handleSuccessDelete)
            .catch(this.handleDeleteFailure);
    }

    handleSuccessDelete = () => {
        this.setState({
            isShowPopUp: false
        });
        notify({ message: "Newly Added Shift has been removed", width: 400, shading: true }, 'error', 600);
        this.setState((prevState: MatchedPopUpPageState) => ({
            isClickedCancel: !prevState.isClickedCancel
        }));
    }

    handleDeleteFailure = (error: any) => {
        let respMessage: string = "submitDeleteShift service failed with response: " + JSON.stringify(error);

        if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }
    render() {
        let isApply = GlobalService.getApplyState();
        let heading: string = "";
        if (this.state.editViewDataItem && this.state.editViewDataItem.paymentRequestType && this.state.editViewDataItem.paymentRequestType.id == PaymentTypes.AddJob) {
            heading = "Artist Job Notification - Matching";
        }
        else {
            heading = "Payment Request - Matching";
        }
        return (
            <div className="page-content">
                <div className="row col-lg-12">
                    <span className="col-lg-12 text-center">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.pageloadPanelVisible}
                        />
                    </span>
                </div>
                <div className="col-12" hidden={this.state.pageloadPanelVisible}>
                    <h3 className="card-form__page-heading">{heading}</h3>
                    <MatchedPopUpPaymentRequest location={this.props.location} history={this.props.history} setPayReqItemObj={this.setPayReqItemObj} payReqId={this.props.payRequestId} captureEditButtonState={this.captureEditButtonState} />
                    <CollapsibleSection title="" serviceTypeId={this.state.editViewDataItem.serviceType.id} dateFrom={this.sharedUtils.convertDateToString(this.state.editViewDataItem.dateFrom)}
                        venueId={this.state.editViewDataItem.venue.id} providerId={this.state.editViewDataItem.providerBusinessEntity.id} requestId={this.state.editViewDataItem.id} paymentRequestType={this.state.editViewDataItem.paymentRequestType.id} location={this.props.location} history={this.props.history} disable={this.state.isEdit}>
                    </CollapsibleSection>
                    <MatchedPopUpGrid editViewDataItem={this.state.editViewDataItem} setBillableItemObj={this.setBillableItemObj} setMatchButtonState={this.setMatchButtonState} setRemoveButtonState={this.setRemoveButtonState} isUnMatched={this.state.isUnMatchSuccessful}  disable={this.state.isEdit} isClickedCancel={this.state.isClickedCancel} />
                    <MatchedPopUpShiftDetails
                        location={this.props.location}
                        history={this.props.history}
                        billableDataItem={this.state.billableDataItem}
                        loadPanelVisible={this.state.loadPanelVisible}
                        buttonState={this.state.buttonState}
                        removeButtonState={this.state.removeButtonState}
                        setUnMatchSuccessful={this.setUnMatchSuccessful}
                        paymentReqId={this.props.payRequestId}
                        paymentDataItem={this.state.editViewDataItem}
                        disable={this.state.isEdit}
                    >
                    </MatchedPopUpShiftDetails>
                    <div className="starter-template">
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.isClickedConfirm} />
                    </div>
                </div>
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.isShowPopUp}
                    onHiding={this.onSaveClose}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Add Shift & Match Payment Request?"
                    maxWidth="600px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div className="pending-request-panel__raise-query">
                            <div className="pending-request-panel__query-box">
                                <span className="text-center"><h6>Please confirm to proceed with add shift and matching?</h6></span>
                                <div className="row mt-3">
                                    <div className={"col-12 col-lg-6"} >
                                        <button className="btn btn-primary btn--large"
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.onConfirm();
                                            }}
                                        >
                                            Confirm?
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <button className="btn btn--ghost btn--ghost--teal btn--large"
                                            onClick={this.onCancel}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </div>
        );
    }
}

export default withRouter(withTranslation()(MatchedPopUpPage));
