import React, { Component } from "react";
import CommonUIUtils from "../../../common/CommonUIUtils";

interface DarkModePageTemplateProps {
    children: React.ReactNode;
}

class DarkModePageTemplate extends Component<DarkModePageTemplateProps> {
    commonUIUtils: CommonUIUtils;

    constructor(props: DarkModePageTemplateProps) {
        super(props);
        this.commonUIUtils = new CommonUIUtils();
    }

    componentDidMount() {
        this.commonUIUtils.setDarkMode(true);
    }

    render() {
        return <>{this.props.children}</>;
    }
}

export default DarkModePageTemplate;
