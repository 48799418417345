export enum ServiceNames {
    Security = "Security",
    ContractCleaing = "Cleaning",
    Entertainment = "Entertainment",
    General = "General",
    Other = "Other"
}

export const ServiceType = {
    EmptyService: "",
    Security: "10",
    ContractCleaning: "20",
    Entertainment: "30",
    Miscelleanous: "80",
    Gardening: "40",
    WindowCleaning: "50",
    Maintenance: "60"
};

export const SubServiceType = {
    DoorSupervisionPersonnel: "11",
    DoorSupervisionEquipment: "12",
    WeeklyCleaning: "21",
    AdditionalCleaning: "22",
    AdditionalGardening:'106',
    AdditionalWindowCleaning:'108',
    AdditionalMaintenance_Misce:'82',
    OtherMaintenance_Misce:'83',
    
};

export function GetServiceName(serviceType: string) {
    switch (serviceType) {
        case ServiceType.Security: {
            return "Door Supervision";
        }
        case ServiceType.ContractCleaning: {
            return "Contract Cleaning";
        }
        case ServiceType.Entertainment: {
            return "Entertainment";
        }
        case ServiceType.Miscelleanous: {
            return "Miscellaneous";
        }
        default: {
            return "";
        }
    }
}
