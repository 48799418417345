import { TextBox, TextArea, LoadPanel } from "devextreme-react";
import React, { MouseEvent } from "react";
import { alert } from "devextreme/ui/dialog";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import AddEditPopUpService, { RaiseQueryFormData } from "../../services/AddEditPopUpService";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";

interface GeneralQueryCardProps {}

interface GeneralQueryCardState {
    loadPanelVisible: boolean;
    validationMessage: string;
    queryItem: RaiseQueryFormData;
    isNumberValid: boolean;
}

class GeneralQueryCard extends React.Component<GeneralQueryCardProps> {
    utils: addEditPopupUtils;
    sharedUtils: sharedUtils;
    state: GeneralQueryCardState;
    queryFormService: AddEditPopUpService;

    constructor(props: GeneralQueryCardProps) {
        super(props);
        this.queryFormService = new AddEditPopUpService();
        this.utils = new addEditPopupUtils();
        this.sharedUtils = new sharedUtils();

        var convertedQueryFormData = this.utils.initializeQueryFormData("");
        this.state = {
            loadPanelVisible: false,
            validationMessage: "",
            queryItem: convertedQueryFormData,
            isNumberValid: true,
        };
    }

    handleChangeName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                raisedByContactName: dxValueChange.value,
            },
        });
    };

    handleChangeRaisedByNumber = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                raisedByContactTelNumber: dxValueChange.value,
            },
        });
        if (dxValueChange.value) {
            var isNumberValid: boolean = this.sharedUtils.checkMobileNumber(dxValueChange.value);
            if (isNumberValid == true) {
                this.setState({
                    isNumberValid: true,
                });
            } else {
                this.setState({
                    isNumberValid: false,
                });
            }
        } else {
            this.setState({
                isNumberValid: true,
            });
        }
    };

    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
            },
        });
    };

    onSubmit = () => {
        let queryCommentsAdded: boolean = false;
        queryCommentsAdded = this.sharedUtils.validationCheckFunction(this.state.queryItem.queryDescription);
        var queryRaisedByAdded: boolean = this.sharedUtils.validationCheckFunction(
            this.state.queryItem.raisedByContactName
        );
        if (!queryCommentsAdded && !queryRaisedByAdded) {
            alert("Please enter the query description and name before pushing the submit button", "Raise Question");
        } else if (!queryCommentsAdded) {
            alert("Please enter the query description before pushing the submit button", "Raise Question");
        } else if (!queryRaisedByAdded) {
            alert("Please enter the name before pushing the submit button", "Raise Question");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            this.queryFormService
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    handleQuerySubmissionSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            queryItem: {
                //Clear the form data once the query is submitted.
                ...this.state.queryItem,
                queryDescription: "",
                raisedByContactTelNumber: "",
                raisedByContactName: "",
            },
        });
        alert(
            "Thank you - your question has been raised - a member of the Event UK Team will be in touch",
            "Question Raised"
        );
    };

    handleQuerySubmissionFailure = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "uploadQueryFormData srvice failed with response: " + JSON.stringify(error);

        if (!this.queryFormService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    render() {
        return (
            <>
                <h4>
                    <b>Have a Question?</b>
                </h4>
                <div className="row my-3">
                    <div className="col-6">
                        <TextBox
                            value={this.state.queryItem.raisedByContactName}
                            onValueChanged={this.handleChangeName}
                            placeholder="Full Name"
                        />
                    </div>
                    <div className="col-6">
                        <TextBox
                            value={this.state.queryItem.raisedByContactTelNumber}
                            onValueChanged={this.handleChangeRaisedByNumber}
                            placeholder="Contact Number"
                        />
                        <span className="text-danger">
                            {this.state.isNumberValid ? "" : "Please provide a valid contact number"}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-12">
                        <TextArea
                            value={this.state.queryItem.queryDescription}
                            height={240}
                            onValueChanged={this.handleChangeDescription}
                            placeholder="Please add your question here and we'll be in touch soon*"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12 col-lg-4 col-xl-2 mr-auto">
                        <button
                            className="btn btn-primary btn--large"
                            type="submit"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onSubmit();
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>

                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
            </>
        );
    }
}

export default GeneralQueryCard;
