import React, { MouseEvent, Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import FinanceAdjustmentForm from "../grid/FinanceAdjustmentPopUp";
import LightModePageTemplate from "./Templates/LightModeTemplate";

interface FinancialAdjustmentsPageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

class FinancialAdjustmentsPage extends Component<FinancialAdjustmentsPageProps> {
    constructor(props: FinancialAdjustmentsPageProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <LightModePageTemplate>
                 <section className="page-content">
                    <div className="row">
                        <div className="col-md">
                            <FinanceAdjustmentForm location={this.props.location} history={this.props.history} />
                        </div>
                        <div />
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(FinancialAdjustmentsPage);
