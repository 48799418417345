import React, { MouseEvent, Component } from "react";
import CardWrapper3Part from "./CardWrapper3Part";
import CardCommentsSection from "./CardSections/CardCommentsSection";
import CardHeaderSection from "./CardSections/CardHeaderSection";
import CardEquipmentSection from "./CardSections/CardEquipmentSection";

interface ExampleCardProps {}

class ExampleCard extends React.Component<ExampleCardProps> {
    constructor(props: ExampleCardProps) {
        super(props);
    }

    handleChangeComments = () => {};

    render() {
        return (
            <CardWrapper3Part
                type="security"
                typeTitle="Security - Door supervision Equipment"
                enumeration={false}
                header={
                    <CardHeaderSection
                        venue="The Red Lion London"
                        postcode="N1 1EV​"
                        date="Mon 17 Aug 2020 to Sun 23 Aug 2020​"
                        message="This is the security equipment supplied to the above venue in the above period.​"
                        adjustment={true}
                        provider = {""}
                        serviceSubTypeId = {""}
                        client ={""}
                    />
                }
                // body={<CardEquipmentSection />}
                footer={
                    <CardCommentsSection
                        message="Please approve or raise a query"
                        comments=""
                        handleChangeComments={this.handleChangeComments}
                        buttonText=""
                        queryButtonText=""
                        onSubmitQuery={() => {}}
                        handleSubmit={() => {}}
                        handleSave = {() => {}}
                        disableApproveButton={false}
                        disableQueryButton={false}
                        disableSaveButton={false}
                    />
                }
            />
        );
    }
}
export default ExampleCard;
