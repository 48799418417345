import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, SelectBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import sharedUtils from "../grid/sharedUtilities";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import { LookUpOnValueChangedEvent, SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import CostReportService, { costReportDates } from "../../services/CostReportService";
import CostReportUtils, { periodLookupItem } from "./CostReportUtils";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
interface CostReportPanelProps extends WithTranslation {
    onApplyButtonClick: (
        venueId: string,
        startDateId: string,
        endDateId: string,
        clientId: string,
        serviceTypeId: string,
        startDateDisplayPeriod: string,
        startDatePeriodNumber: string,
        startDatePeriodWeek: string,
        endDateDisplayPeriod: string,
        endDatePeriodNumber: string,
        endDatePeriodWeek: string,
        dateFromDayRange: string,
        dateToDayRange: string,
        onUpdate: boolean
    ) => void;
    serviceTypeId: string;
    clientId: string;
    venueId: string;
    startDateId: string;
    endDateId: string;
}

interface CostReportPanelState {
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    dateListDataSource: periodLookupItem[];
    startDateId: string;
    startDateDisplayPeriod: string;
    startDatePeriodNumber: string;
    startDatePeriodWeek: string;
    endDateDisplayPeriod: string;
    endDatePeriodNumber: string;
    endDatePeriodWeek: string;
    endDateId: string;
    singleVenue: boolean;
    dateList: costReportDates[];
    serviceTypeloaded: false;
    serviceTypeDataSource: LookupTypeItem[];
    venueListByClient: any[];
    entireVenueList: any[];
    venueDataLoaded: boolean;
    dateFromDayRange: string;
    dateToDayRange: string;
}

class CostReportPanel extends Component<CostReportPanelProps> {
    lookupService: LookupService;
    state: CostReportPanelState;
    sharedUtils: sharedUtils;
    Service: CostReportService;
    utils: CostReportUtils;
    constructor(props: CostReportPanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.sharedUtils = new sharedUtils();
        this.utils = new CostReportUtils();
        this.state = {
            clientId: this.props.clientId ? this.props.clientId : "",
            venueId: this.props.venueId ? this.props.venueId : "",
            serviceTypeId: this.props.serviceTypeId,
            dateListDataSource: [],
            startDateId: this.props.startDateId ? this.props.startDateId : "",
            startDateDisplayPeriod: "",
            startDatePeriodNumber: "",
            startDatePeriodWeek: "",
            endDateId: this.props.endDateId ? this.props.endDateId : "",
            endDateDisplayPeriod: "",
            endDatePeriodNumber: "",
            endDatePeriodWeek: "",
            singleVenue: UserService.isUserInGroup(RoleGroupNames.VenueManager) ? true : false,
            dateList: [],
            serviceTypeloaded: false,
            serviceTypeDataSource: [],
            venueListByClient: [],
            entireVenueList: [],
            venueDataLoaded: false,
            dateFromDayRange: "",
            dateToDayRange: "",
        };
    }

    //Retrieve the lookup values inside the mount
    componentDidMount() {
        var { clientId, venueId } = this.props;
        if (clientId && venueId) {
            this.fetchDateList();
            this.retrieveLookupValues(LookupTypeIndexes.venueType);
        } else {
            this.retrieveLookupValues(LookupTypeIndexes.clientType);
        }
        this.retrieveLookupValues(LookupTypeIndexes.serviceType);
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleClientLookupSuccess)
                    .catch((error) => {});
                break;
            case LookupTypeIndexes.serviceType:
                var listOfServiceTypes: LookupTypeItem[] = UserService.operatingServiceList();
                if (listOfServiceTypes && listOfServiceTypes.length > 0) {
                    var serviceTypeDataSource: LookupTypeItem[] = listOfServiceTypes;
                    this.setState({
                        serviceTypeloaded: true,
                        serviceTypeDataSource: serviceTypeDataSource,
                    });
                }
                break;
            case LookupTypeIndexes.venueType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                    .then(this.handleVenueDataRetrievalSuccess)
                    .catch((error) =>
                        this.setState({
                            venueDataLoaded: true,
                            venueListByClient: [],
                        })
                    );
                break;
        }
    };

    //This function would be called only wheh a props is passed down from the landing page resulting in calling the venue endpoint.
    handleVenueDataRetrievalSuccess = (response: AxiosResponse<any>) => {
        if (response.data && response.data.data) {
            var venueData: any[] = this.sharedUtils.extractVenueListPerClient(response.data.data, this.state.clientId);
            this.setState({
                venueDataLoaded: true,
                venueListByClient: venueData,
            });
        }
    };

    handleClientLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            if (response.data.data.length == 0) {
                this.onApplyButtonClicked();
            } else {
                this.setState(
                    {
                        clientId: response.data.data[0].id,
                    },
                    () => this.retrieveVenueLookupValues()
                );
            }
        }
    };

    retrieveVenueLookupValues = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLookupSuccess)
            .catch((error) => {});
    };

    handleVenueLookupSuccess = (response: AxiosResponse<any>) => {
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            !this.isBlank(this.state.clientId)
        ) {
            var venues: any[] = response.data.data;
            var clientMappedVenue: any[] = this.sharedUtils.extractVenueListPerClient(venues, this.state.clientId);
            var singleVenue: boolean = response.data.data.length == 1 ? true : false;
            this.setState(
                {
                    venueDataLoaded: true,
                    singleVenue: singleVenue,
                    venueId: clientMappedVenue[0].id,
                    venueListByClient: clientMappedVenue,
                    entireVenueList: venues,
                },
                () => this.retrieveDateList()
            );
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        var dateListDataSource: periodLookupItem[] = this.utils.filterDatesBasedOnClient(
            response.data.data,
            this.state.clientId
        );
        this.setState(
            {
                dateList: response.data.data,
                dateListDataSource: dateListDataSource,
                startDateId: dateListDataSource.length > 0 ? dateListDataSource[0].id : "",
                startDateDisplayPeriod: dateListDataSource.length > 0 ? dateListDataSource[0].value : "",
                startDatePeriodNumber: dateListDataSource.length > 0 ? dateListDataSource[0].periodNumber : "",
                startDatePeriodWeek: dateListDataSource.length > 0 ? dateListDataSource[0].periodWeek : "",
                endDateId: dateListDataSource.length > 0 ? dateListDataSource[0].id : "",
                endDateDisplayPeriod: dateListDataSource.length > 0 ? dateListDataSource[0].value : "",
                endDatePeriodNumber: dateListDataSource.length > 0 ? dateListDataSource[0].periodNumber : "",
                endDatePeriodWeek: dateListDataSource.length > 0 ? dateListDataSource[0].periodWeek : "",
                dateFromDayRange: dateListDataSource.length > 0 ? dateListDataSource[0].dayRange : "",
                dateToDayRange: dateListDataSource.length > 0 ? dateListDataSource[0].dayRange : "",
            },
            () =>
                this.props.onApplyButtonClick(
                    this.state.venueId,
                    this.state.startDateId,
                    this.state.endDateId,
                    this.state.clientId,
                    this.state.serviceTypeId,
                    this.state.startDateDisplayPeriod,
                    this.state.startDatePeriodNumber,
                    this.state.startDatePeriodWeek,
                    this.state.endDateDisplayPeriod,
                    this.state.endDatePeriodNumber,
                    this.state.endDatePeriodWeek,
                    this.state.dateFromDayRange,
                    this.state.dateToDayRange,
                    false
                )
        );
    };

    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };

    //Retrieve the DateList from the server.
    retrieveDateList = () => {
        this.Service.getPeriodWeekLookup()
            .then(this.handleSuccess)
            .catch((error) => {});
    };

    //Retrieve Date List when props are supplied.
    fetchDateList = () => {
        this.Service.getPeriodWeekLookup()
            .then(this.handleDateListRetrievalSuccess)
            .catch((error) => {});
    };

    handleDateListRetrievalSuccess = (response: AxiosResponse<any>) => {
        var dateListDataSource: periodLookupItem[] = this.utils.filterDatesBasedOnClient(
            response.data.data,
            this.state.clientId
        );
        var startDateIndex: number = this.utils.findIndexOfDateId(dateListDataSource, this.state.startDateId);
        var endDateIndex: number = this.utils.findIndexOfDateId(dateListDataSource, this.state.endDateId);
        this.setState(
            {
                dateList: response.data.data,
                dateListDataSource: dateListDataSource,
                startDateId: dateListDataSource.length > 0 ? dateListDataSource[startDateIndex].id : "",
                startDateDisplayPeriod: dateListDataSource.length > 0 ? dateListDataSource[startDateIndex].value : "",
                startDatePeriodNumber:
                    dateListDataSource.length > 0 ? dateListDataSource[startDateIndex].periodNumber : "",
                startDatePeriodWeek: dateListDataSource.length > 0 ? dateListDataSource[startDateIndex].periodWeek : "",
                endDateId: dateListDataSource.length > 0 ? dateListDataSource[endDateIndex].id : "",
                endDateDisplayPeriod: dateListDataSource.length > 0 ? dateListDataSource[endDateIndex].value : "",
                endDatePeriodNumber: dateListDataSource.length > 0 ? dateListDataSource[endDateIndex].periodNumber : "",
                endDatePeriodWeek: dateListDataSource.length > 0 ? dateListDataSource[endDateIndex].periodWeek : "",
                dateFromDayRange: dateListDataSource.length > 0 ? dateListDataSource[startDateIndex].dayRange : "",
                dateToDayRange: dateListDataSource.length > 0 ? dateListDataSource[endDateIndex].dayRange : "",
            },
            () =>
                this.props.onApplyButtonClick(
                    this.state.venueId,
                    this.state.startDateId,
                    this.state.endDateId,
                    this.state.clientId,
                    this.state.serviceTypeId,
                    this.state.startDateDisplayPeriod,
                    this.state.startDatePeriodNumber,
                    this.state.startDatePeriodWeek,
                    this.state.endDateDisplayPeriod,
                    this.state.endDatePeriodNumber,
                    this.state.endDatePeriodWeek,
                    this.state.dateFromDayRange,
                    this.state.dateToDayRange,
                    false
                )
        );
    };

    //A helper function that will reset the date list based on the client dropdown selection.
    resetDateList = (clientId: string, serviceTypeId?: string) => {
        var dateListDataSource: periodLookupItem[] = this.utils.filterDatesBasedOnClient(this.state.dateList, clientId);
        this.setState(
            {
                serviceTypeId: serviceTypeId ? serviceTypeId : this.state.serviceTypeId,
                dateListDataSource: dateListDataSource,
                startDateId: dateListDataSource.length > 0 ? dateListDataSource[0].id : "",
                startDateDisplayPeriod: dateListDataSource.length > 0 ? dateListDataSource[0].value : "",
                startDatePeriodNumber: dateListDataSource.length > 0 ? dateListDataSource[0].periodNumber : "",
                startDatePeriodWeek: dateListDataSource.length > 0 ? dateListDataSource[0].periodWeek : "",
                endDateId: dateListDataSource.length > 0 ? dateListDataSource[0].id : "",
                endDateDisplayPeriod: dateListDataSource.length > 0 ? dateListDataSource[0].value : "",
                endDatePeriodNumber: dateListDataSource.length > 0 ? dateListDataSource[0].periodNumber : "",
                endDatePeriodWeek: dateListDataSource.length > 0 ? dateListDataSource[0].periodWeek : "",
                dateFromDayRange: dateListDataSource.length > 0 ? dateListDataSource[0].dayRange : "",
                dateToDayRange: dateListDataSource.length > 0 ? dateListDataSource[0].dayRange : "",
            },
            () => this.onApplyButtonClicked()
        );
    };

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var venueList: any[] = this.state.entireVenueList;
        var venueDataByClientId: any[] = this.sharedUtils.extractVenueListPerClient(venueList, dxValueChange.value);
        this.setState({
            clientId: dxValueChange.value,
            venueListByClient: venueDataByClientId,
            venueId: "",
        }); //When a client dropdown is changed, initiate the API call, and default the VenueID to "", because the State is still holding the previously selected VenueId.
        this.resetDateList(dxValueChange.value);
    };

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            venueId: dxValueChange.value,
        });
    };

    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        sessionStorage.setItem("serviceTypeFilter", dxValueChange.value);
        this.setState({
            serviceTypeId: dxValueChange.value,
        });
    };

    handleChangeStartDateSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        
        var periodObject: periodLookupItem[] = this.utils.returnPeriodTextBasedOnId(
            this.state.dateListDataSource,
            dxValueChange.value
        );
        
        this.setState({
            startDateId: dxValueChange.value,
            startDateDisplayPeriod: periodObject[0] ? periodObject[0].value : "",
            startDatePeriodNumber:periodObject[0] ? periodObject[0].periodNumber : "",
            startDatePeriodWeek:periodObject[0] ? periodObject[0].periodWeek :"",
            dateFromDayRange:periodObject[0] ? periodObject[0].dayRange:"",
        });
    
    };

    handleChangeEndDateSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var periodObject: periodLookupItem[] = this.utils.returnPeriodTextBasedOnId(
            this.state.dateListDataSource,
            dxValueChange.value
        );
        this.setState({
            endDateId: dxValueChange.value,
            endDateDisplayPeriod:periodObject[0] ? periodObject[0].value:"",
            endDatePeriodNumber:periodObject[0] ? periodObject[0].periodNumber:"",
            endDatePeriodWeek: periodObject[0] ?periodObject[0].periodWeek: "",
            dateToDayRange:periodObject[0] ? periodObject[0].dayRange:"",
        });
    };

    componentDidUpdate = (prevprops: CostReportPanelProps, prevState: CostReportPanelState) => {
        if (
            this.props.serviceTypeId != prevprops.serviceTypeId &&
            this.props.serviceTypeId != prevState.serviceTypeId
        ) {
            this.resetDateList(this.state.clientId, this.props.serviceTypeId);
        }
    };

    //Fire the parent component's callback on an Apply click.
    onApplyButtonClicked = () => {
        var venueId : string;
        if(this.state.venueId == "0") {
           venueId = "";
        }
        else {
            venueId = this.state.venueId;
        }
        this.props.onApplyButtonClick(
            venueId,
            this.state.startDateId,
            this.state.endDateId,
            this.state.clientId,
            this.state.serviceTypeId,
            this.state.startDateDisplayPeriod,
            this.state.startDatePeriodNumber,
            this.state.startDatePeriodWeek,
            this.state.endDateDisplayPeriod,
            this.state.endDatePeriodNumber,
            this.state.endDatePeriodWeek,
            this.state.dateFromDayRange,
            this.state.dateToDayRange,
            true
        );
    };

    render() {
        var isSingleClientandVenue: boolean = this.state.singleVenue;
        return (
            <div>
                <div className="row mt-4">
                    <div className="col-10">
                        <h4 className="sidebar__heading font-weight-bold">Filter spend</h4>
                    </div>
                </div>

                {isSingleClientandVenue ? (
                    <></>
                ) : (
                    <>
                        {" "}
                        <div className="row mt-3">
                            <div className="col sidebar__heading">Client</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                    onValueChanged={this.handleChangeClientTypeSelect}
                                    value={this.state.clientId}
                                />
                                <input
                                    data-testid={LookupTypeIndexes.clientType}
                                    type="hidden"
                                    name={LookupTypeIndexes.clientType}
                                ></input>
                            </div>
                        </div>
                    </>
                )}

                {isSingleClientandVenue ? (
                    <></>
                ) : (
                    <>
                        {" "}
                        <div className="row mt-3">
                            <div className="col sidebar__heading">Venue</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                {this.state.venueDataLoaded ? (
                                    <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.venueType}
                                        onValueChanged={this.handleChangeVenueTypeSelect}
                                        isRoot={false}
                                        parentMappingId={this.state.clientId}
                                        value={this.state.venueId}
                                        displayExpression={"value"}
                                        itemAll= {true}
                                    />
                                ) : (
                                    <span> Loading... </span>
                                )}{" "}
                            </div>
                        </div>
                    </>
                )}

                <div className="row mt-3">
                    <div className="col sidebar__heading">Service</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <>
                            {this.state.serviceTypeloaded ? (
                                <SelectBox
                                    dataSource={this.state.serviceTypeDataSource}
                                    displayExpr={"value"}
                                    valueExpr={"id"}
                                    value={this.state.serviceTypeId}
                                    onValueChanged={this.handleChangeServiceTypeSelect}
                                />
                            ) : (
                                <span> Loading... </span>
                            )}{" "}
                        </>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Date From</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <SelectBox
                            dataSource={this.state.dateListDataSource}
                            displayExpr="value"
                            valueExpr="id"
                            onValueChanged={this.handleChangeStartDateSelect}
                            value={this.state.startDateId}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Date To</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <SelectBox
                            dataSource={this.state.dateListDataSource}
                            displayExpr="value"
                            valueExpr="id"
                            onValueChanged={this.handleChangeEndDateSelect}
                            value={this.state.endDateId}
                        />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        <Button
                            className="btn btn-primary btn--large"
                            disabled={false}
                            onClick={this.onApplyButtonClicked}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CostReportPanel);
