import React from "react";
import MatchedPopUpPage from "./MatchedPopUpPage";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";

interface MatchedPopUpPageContainerProps extends RouteProps, withValidUserRoleProps {}

const MatchedPopUpPageContainer = (props: MatchedPopUpPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };
    let filterById: string = "";
    if (props.location && props.location.search) {
        let searchparam = new URLSearchParams(props.location.search.toLowerCase());
        let filterByIdFromURL = searchparam.get("filterby");
        filterById = filterByIdFromURL ? filterByIdFromURL : "";
    }

    return <MatchedPopUpPage title={getTitle()} category={getType()} payRequestId={filterById} />;
};

export default withValidUserRole(MatchedPopUpPageContainer);
