import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BulletinPanelService, { bulletinCategoryValue } from "../../services/BulletinPanelService";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import {
    faAd,
    faBalanceScaleRight,
    faCoins,
    faHandsHelping,
    faLightbulbExclamation,
    faRadio,
    faTasks,
    faUsers,
    faVolumeUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadIndicator, Popup, ScrollView } from "devextreme-react";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { IbulletinGridRowItem } from "../../services/MatrixService";

interface BulletinPanelPageComponentProps extends RouteComponentProps {}

interface BulletinPanelState {
    bulletinContent: React.ReactElement;
    showLoadIndicator: boolean;
    displayBulletinPopUp: boolean;
    bulletinResponse: IbulletinGridRowItem[];
    popupContent: string;
    popupTitle: string;
}

class BulletinPanel extends Component<BulletinPanelPageComponentProps> {
    //Initialize the State
    state: BulletinPanelState;
    service: BulletinPanelService;
    sharedUtils: sharedUtils;
    constructor(props: BulletinPanelPageComponentProps) {
        super(props);
        this.service = new BulletinPanelService();
        this.sharedUtils = new sharedUtils();
        this.state = {
            bulletinContent: <></>,
            showLoadIndicator: false,
            displayBulletinPopUp: false,
            bulletinResponse: [],
            popupContent: "",
            popupTitle: "",
        };
    }

    componentDidMount() {
        this.bulletinPanelData();
    }

    bulletinPanelData = () => {
        this.setState({
            showLoadIndicator: true,
        });
        var todayDate = new Date();
        var todayDateStringFormat = this.sharedUtils.convertDateToString(todayDate);
        this.service
            .getBulletinPanelData(todayDateStringFormat)
            .then(this.handleSuccess)
            .catch(this.handleStandardError);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            bulletinResponse: response.data.data,
            bulletinContent: this.buildBulletinContent(response.data.data),
            showLoadIndicator: false,
        });
    };

    handleStandardError = (error: any) => {
        this.setState({
            showLoadIndicator: false,
            bulletinResponse: [],
        });
        // TODO: use appInsights logging here
        var respMessage: string = "Bulletin Panel response failed" + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    displayBulletinPopUp = (description: string, title: string) => {
        this.setState({
            displayBulletinPopUp: true,
            popupContent: description,
            popupTitle: title,
        });
    };

    onHidingBulletinPopUp = () => {
        this.setState({ displayBulletinPopUp: false });
    };

    buildBulletinContent = (response: IbulletinGridRowItem[]) => {
        let content = response.map((item: IbulletinGridRowItem, uniqueKey: number) => {
            return (
                <li key={uniqueKey} className="d-flex sidebar__dividerbottom px-3">
                    <div className="col-1 bulletin-box__links-icon">
                        {item.bulletinCategoryValue == bulletinCategoryValue.news ? (
                            <FontAwesomeIcon icon={faRadio} />
                        ) : item.bulletinCategoryValue == bulletinCategoryValue.attention ? (
                            <FontAwesomeIcon icon={faLightbulbExclamation} />
                        ) : item.bulletinCategoryValue == bulletinCategoryValue.action ? (
                            <FontAwesomeIcon icon={faTasks} />
                        ) : item.bulletinCategoryValue == bulletinCategoryValue.legal ? (
                            <FontAwesomeIcon icon={faBalanceScaleRight} />
                        ) : item.bulletinCategoryValue == bulletinCategoryValue.advert ? (
                            <FontAwesomeIcon icon={faAd} />
                        ) : item.bulletinCategoryValue == bulletinCategoryValue.gigs ? (
                            <FontAwesomeIcon icon={faVolumeUp} />
                        ) : item.bulletinCategoryValue == bulletinCategoryValue.quote ? (
                            <FontAwesomeIcon icon={faCoins} />
                        ) : item.bulletinCategoryValue == bulletinCategoryValue.request ? (
                            <FontAwesomeIcon icon={faHandsHelping} />
                        ) : item.bulletinCategoryValue == bulletinCategoryValue.social ? (
                            <FontAwesomeIcon icon={faUsers} />
                        ) : null}
                    </div>
                    {item.bulletinPriorityValue == "High" ? (
                        <>
                            <div className="col-10">
                                <span>{item.bulletinTitle ? item.bulletinTitle : ""}</span>{" "}
                                {item.isPopUpRequired && (
                                    <span>
                                        <a
                                            href="#"
                                            onClick={(event: any) => {
                                                event.preventDefault();
                                                this.displayBulletinPopUp(item.bulletinDescription, item.bulletinTitle);
                                            }}
                                        >
                                            Read more
                                        </a>
                                    </span>
                                )}
                            </div>
                            <div className="col-1 p-0">
                                <span className="home-page__legend--bulletin"></span>
                            </div>
                        </>
                    ) : (
                        <div className="col-11">
                            <span>{item.bulletinTitle ? item.bulletinTitle : ""}</span>{" "}
                            {item.isPopUpRequired && (
                                <span>
                                    <a
                                        href="#"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            this.displayBulletinPopUp(item.bulletinDescription, item.bulletinTitle);
                                        }}
                                    >
                                        Read more
                                    </a>
                                </span>
                            )}
                        </div>
                    )}
                </li>
            );
        });

        return content;
    };

    render() {
        return (
            <>
                <LightModePageTemplate>
                    {this.state.bulletinContent ? (
                        <div className="mt-3 pt-3 sidebar__divider">
                            {/* Accordion for narrow view*/}
                            <div className="d-block d-lg-none bulletin-box accordion" id="bulletinAccordion">
                                <h4 id="bulletinHeading" className="bulletin-box__header">
                                    <button
                                        className="btn collapsed"
                                        data-toggle="collapse"
                                        data-target="#bulletinAccordionSection"
                                        aria-expanded="false"
                                        aria-controls="bulletinAccordionSection"
                                    >
                                        BULLETIN
                                        <span className="bulletin-box__icon">
                                            <FontAwesomeIcon icon={faChevronUp} />
                                        </span>
                                    </button>
                                </h4>

                                <ul
                                    id="bulletinAccordionSection"
                                    className="collapse bulletin-box__links"
                                    aria-labelledby="bulletinHeading"
                                    data-parent="#bulletinAccordion"
                                >
                                    <LoadIndicator
                                        id="simple-grid-indicator"
                                        height={60}
                                        width={60}
                                        visible={this.state.showLoadIndicator}
                                    />
                                    {this.state.bulletinContent}
                                </ul>
                            </div>
                            {/* Standard bulletin list for wider view*/}
                            <h4 className="d-none d-lg-block bulletin-box__header">BULLETIN</h4>
                            <ul className="d-none d-lg-block bulletin-box__links">
                                <LoadIndicator height={60} width={60} visible={this.state.showLoadIndicator} />
                                {this.state.bulletinContent}
                            </ul>
                        </div>
                    ) : (
                        ""
                    )}
                </LightModePageTemplate>
                <Popup
                    visible={this.state.displayBulletinPopUp}
                    onHiding={this.onHidingBulletinPopUp}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showCloseButton={true}
                    width={'auto'}
                    height={'auto'}
                    title={this.state.popupTitle}
                >
                    <ScrollView>
                        <p>{this.state.popupContent ? this.state.popupContent : ""}</p>
                    </ScrollView>
                </Popup>
            </>
        );
    }
}

export default withRouter(BulletinPanel);
