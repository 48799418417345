import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    DateBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import sharedUtils from "../grid/sharedUtilities";
import { DateBox, LoadPanel, SelectBox, TextArea, TextBox } from "devextreme-react";
import QueryService, { queryTypes } from "../../services/QueryService";
import queryUtils, { QueryFormObject } from "../Query/QueryUtils";
import SimpleShiftsByService from "../SimpleShiftsByService/SimpleShiftsByService";
import QueryGrid from "./QueryGrid";

interface QueryManageFormProps extends WithTranslation {
    location: any;
    history: any;
    queryFormId?: string;
}
// State
interface QueryManageFormState {
    queryFormData: QueryFormObject;
    activityLookUp: LookupTypeItem[],
    queryTypeLookUp: LookupTypeItem[],
    errorMessage: [],
    loadPanelVisible: boolean;
    isView: boolean;
    isHideClientVenue: boolean;
    isHideRaisedByContactNo: boolean;
    isHideTimeField: boolean;
    isHideAsterikMark: boolean;
    showShiftsGrid: boolean;
}

class QueryManageForm extends React.Component<QueryManageFormProps> {
    queryService: QueryService;
    queryUtils: queryUtils;
    sharedUtils: sharedUtils;
    state: QueryManageFormState;
    lookupService: LookupService;
    decisionTypesDataSource: LookupTypeItem[];
    constructor(props: QueryManageFormProps) {
        super(props);
        this.queryService = new QueryService();
        this.queryUtils = new queryUtils();
        this.sharedUtils = new sharedUtils();
        this.lookupService = new LookupService();
        this.decisionTypesDataSource = this.lookupService.getDecisionTypes();
        //#region Set default values to the state object
        // Initialize state
        var convertedQueryViewDataItem = this.queryUtils.initializeQueryItem(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        this.state = {
            queryFormData: convertedQueryViewDataItem,
            activityLookUp: [],
            queryTypeLookUp: [],
            errorMessage: [],
            loadPanelVisible: false,
            isView: this.props.location?.state?.isView,
            isHideClientVenue: false,
            isHideRaisedByContactNo: true,
            isHideTimeField: true,
            isHideAsterikMark: false,
            showShiftsGrid:false,
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        this.lookupService
            .getLookupByLookupTypeIndexAll(LookupTypeIndexes.activity)
            .then(this.handleActivityLookupSuccess)
            .catch(this.handleStandardError);

        this.lookupService
            .getLookupByLookupTypeIndexAll(LookupTypeIndexes.querytype)
            .then(this.handleQueryTypeLookupSuccess)
            .catch(this.handleStandardError);
    }

    handleQueryTypeLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data.data) {
            var typeForQuery = response.data.data.filter((item: any) => {
                return item.value.includes("General Query");
            });
            if (this.props.location.state && this.props.location.state.id) {
                this.setState({
                    queryTypeLookUp: response.data.data
                });
            }
            else {
                this.setState({
                    queryTypeLookUp: typeForQuery
                });
            }
        }
        this.editViewDataSource();
    }


    handleActivityLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data.data) {
            this.setState({
                activityLookUp: response.data.data
            });
        }
    }

    editViewDataSource = () => {
        if (this.props.location.state && this.props.location.state.id) {
            if(this.props.queryFormId && this.props.queryFormId !== ""){
                this.queryService.getQueryCardItem(this.props.queryFormId)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError)
            }
            else{
                this.queryService.getQueryCardItem(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError)
            }            
        }
        else {
            this.hideLoadpanel();
        }
    }

    // Manage successful data source response
    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        var viewDataSource = this.queryUtils.convertQueryResponeToFormData(response.data.data);
        this.showOrHideColumn(viewDataSource.queryTypeLookupID);
        this.setState({
            queryFormData: viewDataSource,
            isHideTimeField: false
        }, () => { this.hideLoadpanel() });
    }

    handleStandardError = (error: any) => {
        this.setState({
            errorMessage:
                error.response && error.response.data && error.response.data.error
                    ? JSON.parse(JSON.stringify(error.response.data.error))
                    : null
        }, () => { this.hideLoadpanel() });
        var respMessage: string = "query form load failed with response: " + JSON.stringify(error);
        if (!this.queryService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    handleChangeQueryTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    queryTypeLookupID: dxValueChange.value,
                },
            }, () => { this.showOrHideColumn(this.state.queryFormData.queryTypeLookupID) });

        }
    }

    showOrHideColumn = (value: string) => {
        if (value == queryTypes.ArtistProfileUpdate || value == queryTypes.ArtistSignUp) {
            this.setState({
                isHideClientVenue: true
            });
        }
        else {
            this.setState({
                isHideClientVenue: false
            });
        }
        if (value == queryTypes.ArtistProfileUpdate || value == queryTypes.ArtistSignUp || value == queryTypes.GeneralQuery) {
            this.setState({
                isHideRaisedByContactNo: false
            });
        }
        else {
            this.setState({
                isHideRaisedByContactNo: true
            });
        }
        if (value == queryTypes.BillableItem) {
            this.setState({
                isHideAsterikMark: true
            });
        }
    }

    handleChangeCompanyTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    queryClientBusinessEntityId: dxValueChange.value,
                },
            });
        }
    }

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    venueId: dxValueChange.value,
                },
            });
        }
    }

    handleChangeProviderTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    providerBusinessEntityId: dxValueChange.value,
                },
            });
        }
    };

    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    serviceTypeId: dxValueChange.value,
                },
            });
        }
    }

    handleChangeTitle = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    queryTitle: dxValueChange.value,
                },
            });
        }
    }
    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    queryDescription: dxValueChange.value,
                },
            });
        }
    }

    handleChangeDateCreated = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    queryDateCreated: dxValueChange.value,
                },
            });
        }
    }

    handleChangeQueryRaisedTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    queryRaisedTime: dxValueChange.value,
                },
            });
        }
    }

    handleChangeContactName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    raisedByContactName: dxValueChange.value,
                },
            });
        }
    }
    handleChangeContactTelNumber = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    raisedByContactTelNumber: dxValueChange.value,
                },
            });
        }
    }

    handleChangeResolutionReason = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    resolutionReason: dxValueChange.value,
                },
            });
        }
    }

    handleChangeInternalComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    internal_Comment: dxValueChange.value,
                },
            });
        }
    }

    handleChangeActivityComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    activity_Comments: dxValueChange.value,
                },
            });
        }
    }

    handleChangeActivityTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    activity_Type: dxValueChange.value,
                },
            });
        }
    }

    handleChangeExternally = (dxValueChange: SelectBoxOnValueChangedEvent) => {        
        if (dxValueChange.event !== undefined) {
            this.setState({
                queryFormData: {
                    ...this.state.queryFormData,
                    activity_external: dxValueChange.value,
                },
            });
        }
    }

    handleFormDataSubmissionSuccess = () => {
        this.hideLoadpanel();
        this.renderRedirect();
    };


    hideLoadpanel = () => {
        this.setState({ loadPanelVisible: false });
    };


    renderRedirect = () => {
        this.navigationCommonMethod();
    };

    navigationCommonMethod = () => {
        if (this.props.location && this.props.location.state && this.props.location.state.pointOfNavigation == "signupProfileGrid") {
            this.props.history.push({
                pathname: "/signUpProfileUpdate",
                state: {
                    navigateFrom: ""
                },
            });
        }
        else {
            this.props.history.goBack();
        }
    }

    onCancel = () => {
        this.navigationCommonMethod();
    }

    onSubmitOrResolve = (navigation: string) => {
        this.setState({
            loadPanelVisible: true
        });
        var queryItemSubmitRequest = this.queryUtils.convertFormDataToQueryRequest(this.state.queryFormData, navigation);
        this.queryService.postQueryRequestFormData(queryItemSubmitRequest)
            .then(this.handleFormDataSubmissionSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null
                });
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    }

    showGrid = (isData:boolean) => {
        if(isData == true) this.setState({showShiftsGrid:true,})
    }

    setCalculations = () => {};

    activityRaisedBy = (item: any) => {
        let raisedBy = '';
        if (item.activity_RaisedBy && item.activity_RaisedBy.toLowerCase() !== 'company') {
            raisedBy = `(${item.activity_RaisedBy})`;
        }
        return raisedBy;
    }

    render() {
        var screenName: string = "";
        var dateUpdated: string = "";
        if (this.props.location.state && this.props.location.state.id) {
            screenName = "Edit Query";
        }
        else if (this.props.location.state && this.props.location.state.isView) {
            screenName = "View Query";
        }
        else {
            screenName = "Add Query";
        }
        let finalFormattedDate: string = "";
        let hideClientVenueAsterik :boolean = false;
        if(this.state.queryFormData?.queryRaisedBy?.toLowerCase() =='provider'){
            hideClientVenueAsterik = true;
        }
        if (this.state.queryFormData.lastUpdated_DateTime) {
            let queriedDateTime = this.state.queryFormData.lastUpdated_DateTime;
            let queriedDateTimeLocal = new Date(queriedDateTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(queriedDateTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(queriedDateTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        if (this.state.queryFormData.user && this.state.queryFormData.user.length > 0 && this.state.queryFormData.user[0].name && this.state.queryFormData.lastUpdated_DateTime) {

            dateUpdated = finalFormattedDate + " " + "(" + this.state.queryFormData.user[0].name + ")";
        }
        else if (this.state.queryFormData.lastUpdated_DateTime) {
            dateUpdated = finalFormattedDate;
        }
        else if (this.state.queryFormData.user && this.state.queryFormData.user.length > 0) {
            dateUpdated = this.state.queryFormData.user[0].name;
        }
        return (
            <LightModePageTemplate>
                <div className="page-content--with-sidebar">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <section>
                                        <h5>
                                            <b>{screenName}</b>
                                        </h5>
                                        {this.props.location.state && this.props.location.state.id ?
                                            <>
                                                <div className="card-form__field card-form__field--narrow query-status-text">
                                                    {this.state.queryFormData.queryStatus}
                                                </div>
                                                <div className="card-form__field card-form__field--narrow">
                                                    Date Updated: {dateUpdated ? dateUpdated : ""}
                                                </div>
                                                <div className="card-form__field card-form__field--narrow">
                                                    ID: {this.state.queryFormData.id}
                                                </div>
                                            </> : <></>}
                                        <hr></hr>                                        
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12">Type<sup className="card-form__mandatory-symbol">*</sup>
                                                <SelectBox
                                                    dataSource={this.state.queryTypeLookUp}
                                                    displayExpr="value"
                                                    valueExpr="id"
                                                    onValueChanged={this.handleChangeQueryTypeSelect}
                                                    value={this.state.queryFormData.queryTypeLookupID}
                                                    disabled={this.state.isView}
                                                />
                                            </div>
                                        </div>
                                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12" hidden={this.state.isHideClientVenue}>Client
                                            {/* <sup className="card-form__mandatory-symbol" hidden={this.state.isHideAsterikMark ? this.state.isHideAsterikMark : this.state.isHideClientVenue}>*</sup> */}
                                            <sup className="card-form__mandatory-symbol" hidden={hideClientVenueAsterik}>*</sup>
                                                <DropDownFromLookupTable
                                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                                    onValueChanged={this.handleChangeCompanyTypeSelect}
                                                    value={this.state.queryFormData.queryClientBusinessEntityId}
                                                    disableSignal={this.state.isView}
                                                />
                                                <input
                                                    data-testid={LookupTypeIndexes.clientType}
                                                    type="hidden"
                                                    name={LookupTypeIndexes.clientType}
                                                    value={
                                                        this.state.queryFormData.queryClientBusinessEntityId
                                                            ? this.state.queryFormData.queryClientBusinessEntityId
                                                            : 0
                                                    }
                                                ></input>
                                            </div>
                                        </div>                                        
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12" hidden={this.state.isHideClientVenue}>Venue
                                            {/* <sup className="card-form__mandatory-symbol" hidden={this.state.isHideAsterikMark ? this.state.isHideAsterikMark : this.state.isHideClientVenue}>*</sup> */}
                                            <sup className="card-form__mandatory-symbol" hidden={hideClientVenueAsterik}>*</sup>
                                                <LookupTypeListDynamic
                                                    lookupTypeIndex={LookupTypeIndexes.venueType}
                                                    onValueChanged={this.handleChangeVenueTypeSelect}
                                                    isRoot={false}
                                                    parentMappingId={this.state.queryFormData.queryClientBusinessEntityId}
                                                    value={this.state.queryFormData.venueId}
                                                    displayExpression={"value"}
                                                    isDisabled={this.state.isView}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12">
                                                Provider
                                                <LookupTypeListDynamic
                                                    lookupTypeIndex={LookupTypeIndexes.providerType}
                                                    onValueChanged={this.handleChangeProviderTypeSelect}
                                                    isRoot={false}
                                                    parentMappingId={""}
                                                    value={this.state.queryFormData.providerBusinessEntityId}
                                                    displayExpression={"value"}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12">Query Title<sup className="card-form__mandatory-symbol">*</sup>
                                                <TextBox
                                                    height={30}
                                                    value={this.state.queryFormData.queryTitle}
                                                    onValueChanged={this.handleChangeTitle}
                                                    disabled={this.state.isView}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12">Description<sup className="card-form__mandatory-symbol">*</sup>

                                                <TextArea
                                                    height={100}
                                                    value={this.state.queryFormData.queryDescription}
                                                    onValueChanged={this.handleChangeDescription}
                                                    disabled={this.state.isView}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-3 col-sm-12">Date Raised<sup className="card-form__mandatory-symbol">*</sup>
                                                <DateBox
                                                    onValueChanged={this.handleChangeDateCreated}
                                                    value={

                                                        this.state.queryFormData.queryDateCreated
                                                            ? this.state.queryFormData.queryDateCreated
                                                            : undefined
                                                    }
                                                    useMaskBehavior={true}
                                                    displayFormat="dd/MM/yyyy"
                                                    disabled={this.state.isView}
                                                ></DateBox>
                                            </div>
                                            <div className="card-form__field card-form__field--narrow col-md-3 col-sm-12" hidden={this.state.isHideTimeField}><sup className="card-form__mandatory-symbol"></sup>
                                                <DateBox
                                                    type="time"
                                                    interval={15}
                                                    placeholder="00:00"
                                                    useMaskBehavior={true}
                                                    displayFormat={"HH:mm"}
                                                    onValueChanged={this.handleChangeQueryRaisedTime}
                                                    value={
                                                        this.state.queryFormData.queryRaisedTime
                                                            ? this.state.queryFormData.queryRaisedTime
                                                            : undefined
                                                    }
                                                    disabled={!this.state.isHideTimeField}
                                                />
                                            </div>
                                            <div className="card-form__field card-form__field--narrow col-md-3 col-sm-12 disabled-grey">Raised By
                                            <TextBox value={this.state.queryFormData?.queryRaisedBy} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12" hidden={this.state.isHideRaisedByContactNo}>Raised By Contact<sup className="card-form__mandatory-symbol" hidden={this.state.isHideRaisedByContactNo}>*</sup>
                                                <TextBox
                                                    value={this.state.queryFormData.raisedByContactName}
                                                    onValueChanged={this.handleChangeContactName}
                                                    disabled={this.state.isView}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12" hidden={this.state.isHideRaisedByContactNo}>Contact No
                                            {/* <sup className="card-form__mandatory-symbol" hidden={this.state.isHideRaisedByContactNo}>*</sup> */}
                                                <TextBox
                                                    value={this.state.queryFormData.raisedByContactTelNumber}
                                                    onValueChanged={this.handleChangeContactTelNumber}
                                                    disabled={this.state.isView}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12">Resolution Reason
                                                <TextArea
                                                    height={100}
                                                    value={this.state.queryFormData.resolutionReason}
                                                    onValueChanged={this.handleChangeResolutionReason}
                                                    disabled={this.state.isView}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-form__field card-form__field--narrow col-md-6 col-sm-12">
                                                Internal Comments
                                                <TextArea
                                                    height={100}
                                                    value={this.state.queryFormData.internal_Comment}
                                                    onValueChanged={this.handleChangeInternalComments}
                                                    disabled={this.state.isView}
                                                />
                                            </div>
                                        </div>
                                        { this.state.showShiftsGrid ? <h5><strong>Queried Item</strong></h5> : null }
                                        <SimpleShiftsByService
                                            clientId={""}
                                            venueId={""}
                                            serviceTypeId={""}
                                            startDate={""}
                                            endDate={""}
                                            setCalculations={this.setCalculations}
                                            showGrid={this.showGrid}
                                            navigatedFrom="queryform"
                                            queryId={this.state.queryFormData.billableItemId}
                                            getBuild={() => { return null }}
                                        />                                         
                                        {this.props.location.state && this.props.location.state.id ?
                                            <>
                                                <h5><strong>Other Open Queries for Same Contact / Linked Queries</strong></h5>
                                                <QueryGrid 
                                                    statusId=""
                                                    serviceTypeId=""
                                                    venueId=""
                                                    clientId=""
                                                    location=""
                                                    navigateFrom="queryform"
                                                    queryId={this.state.queryFormData.id}
                                                    contactId={this.state.queryFormData.contactId}
                                                />
                                                <hr></hr>
                                                <div className="row">
                                                    <div className="mt-3 col-7 col-lg-7">
                                                        <h6>Activity</h6>
                                                    </div>
                                                </div>
                                                <hr className="incidents-comments-below-line"></hr>
                                                {this.state.queryFormData.activity.length > 0
                                                    ? this.state.queryFormData.activity.map(
                                                        (item: any) => {
                                                            return (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-lg-7">{item.activity_Comments}</div>
                                                                        <div className="col-lg-5">
                                                                            <div className="row">
                                                                                <div className="col-lg-6 mt-3 mt-lg-0 text-lg-right configurationText_Color">
                                                                                    Activity Type:
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    {item.activity_Type}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-6 mt-3 mt-lg-0 text-lg-right configurationText_Color">
                                                                                    By:
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    {item.activity_LastUpdatedBy} {this.activityRaisedBy(item)}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-6 text-lg-right configurationText_Color">
                                                                                    Date Added:
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    {item.activity_LastUpdated}
                                                                                </div>
                                                                            </div>
                                                                            { item.activity_external == true ? 
                                                                                <div className="row">
                                                                                    <div className="col-lg-6 text-lg-right"></div>
                                                                                    <div className="col-lg-4 btn activity-external mt-3">
                                                                                        External
                                                                                    </div>
                                                                                </div> 
                                                                            : null } 
                                                                            { item.activity_external == false ? 
                                                                                <div className="row">
                                                                                    <div className="col-lg-6 text-lg-right"></div>
                                                                                    <div className="col-lg-4 btn btn--ghost btn--ghost--teal btn--large mt-3">
                                                                                        Internal
                                                                                    </div>
                                                                                </div>
                                                                            : null } 
                                                                        </div>
                                                                    </div>
                                                                    <hr className="incidents-comments-below-line"></hr>
                                                                </>
                                                            );
                                                        }
                                                    )
                                                    : null}

                                                <div className="row">
                                                    <div className="mt-3 col-12 col-xl-8">
                                                        <TextArea
                                                            onValueChanged={this.handleChangeActivityComments}
                                                            placeholder="Please add comment here and then select Submit.."
                                                            height={100}
                                                            disabled={this.state.isView}
                                                        ></TextArea>
                                                    </div>
                                                    <div className="mt-3 col-12 col-xl-4">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <h6>Activity Type:</h6>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <SelectBox
                                                                    dataSource={this.state.activityLookUp}
                                                                    displayExpr="value"
                                                                    valueExpr="id"
                                                                    onValueChanged={this.handleChangeActivityTypeSelect}
                                                                    value={this.state.queryFormData.activity_Type}
                                                                    disabled={this.state.isView}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-md-4">
                                                                <h6>Show Externally?</h6>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <SelectBox
                                                                    dataSource={this.decisionTypesDataSource}
                                                                    displayExpr="value"
                                                                    valueExpr="id"
                                                                    onValueChanged={this.handleChangeExternally}
                                                                    value={ this.state.queryFormData.activity_external }
                                                                    disabled={this.state.isView}
                                                                    className={this.state.queryFormData.activity_external == "1" ? "external-option" : "internal-option"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : <></>}
                                        <section>
                                            {this.state.errorMessage &&
                                                this.state.errorMessage.length > 0 ? (
                                                <div className="row justify-content-left pt-3">
                                                    <span className="unscheduled-shift">
                                                        <ul>
                                                            {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                                                return (
                                                                    <li key={uniqueKey}>
                                                                        {item.columnName} : {item.errorMessage}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </span>
                                                </div>
                                            ) : null}
                                        </section>
                                        <div className="row">
                                            <div className="col-12 col-xl-2 pl-0">
                                                <button
                                                    className="btn btn-primary btn--large mt-3"

                                                    type="submit"
                                                    onClick={(e: MouseEvent) => {
                                                        e.preventDefault();
                                                        this.onSubmitOrResolve("");
                                                    }}
                                                    disabled={this.state.isView}
                                                >
                                                    <span className="btn__icon">
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </span>
                                                    Apply
                                                </button>
                                            </div>
                                            <div className="col-12 col-xl-2 pl-0">
                                                <button
                                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                                    onClick={(e: MouseEvent) => {
                                                        e.preventDefault();
                                                        this.onSubmitOrResolve("resolved");
                                                    }}
                                                    disabled={this.state.isView || (this.props.location.state && !this.props.location.state.id)}
                                                >
                                                    Resolve
                                                </button>
                                            </div>
                                            <div className="col-12 col-xl-2 pl-0">
                                                <button
                                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                                    onClick={(e: MouseEvent) => {
                                                        e.preventDefault();
                                                        this.onCancel();
                                                    }}
                                                >
                                                    Close
                                                </button>
                                            </div>

                                        </div>
                                    </section>
                                    <br></br>
                                    <p className="incidents-footer-text">
                                        Please ensure that the information provided is as accurate as possible- no changes to text can
                                        be made once submitted,<br></br>
                                        however attachments and comments can still be added.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LightModePageTemplate>


        );
    }
}

export default withTranslation()(QueryManageForm);
