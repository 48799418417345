import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import ProviderLookUpComponent from "./ProviderLookUpComponent";
import ProviderProfileComponent from "./ProviderProfileComponent";

interface ProviderProfilePageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

interface ProviderProfilePageState {
    providerId: string;
    isProviderFound: boolean;
    refreshSignal: boolean;
}

class ProviderProfilePage extends Component<ProviderProfilePageProps> {
    //Initialize the component's state.
    state: ProviderProfilePageState;

    constructor(props: ProviderProfilePageProps) {
        super(props);
        this.state = {
            providerId: "",
            isProviderFound: false,
            refreshSignal: false,
        };
    }
    setProviderId = (providerIdValue: string, isproviderValue: boolean) => {
        this.setState({
            providerId: providerIdValue,
            isProviderFound: isproviderValue,
        });
    };
    componentDidUpdate(prevProps: ProviderProfilePageProps) {
        if (
            this.props.location.state &&
            this.props.location.state.serviceTypeId != prevProps.location.state.serviceTypeId
        ) {
            this.setState({
                refreshSignal: !this.state.refreshSignal,
            });
        }
    }

    render() {
        return (
            <LightModePageTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <h2 className="page-title__black">Profile</h2>
                    </header>
                    <div className="row">
                        <SideBarTemplate isFixed={true}>
                            <BulletinPanel />
                        </SideBarTemplate>
                        <div className="col-md">
                            <ProviderLookUpComponent
                                setParentFilterValuesCallback={this.setProviderId}
                                refreshSignal={this.state.refreshSignal}
                            />
                            {this.state.isProviderFound && this.state.providerId ? (
                                <ProviderProfileComponent
                                    providerId={this.state.providerId}
                                    refreshSignal={this.state.refreshSignal}
                                ></ProviderProfileComponent>
                            ) : null}
                        </div>
                        <div />
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ProviderProfilePage);
