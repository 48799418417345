import React from "react";
import { LoadPanel } from "devextreme-react";
import {
    TextBoxOnValueChangedEvent,
    DateBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
} from "../../types/DevExtremeTypes";
import { AxiosResponse } from "axios";
import UserService, { RoleGroupNames } from "../../services/UserService";
import outstandingActionsUtilities, {
    AddEditFormData,
    checkInStatus,
    CalculationRequest,
    itemTypes,
    feedbackRequestData,
    feedbackScoreType
} from "./OutstandingActionsUtilities";
import OutstandingApprovalService, {
    BillableItemResponse,
    Status,
    BillableItemSubmitRequest,
} from "../../services/OutstandingApprovalService";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";
import LookupService, { LookupTypeItem } from "../../services/LookupService";
import AddEditPopUpService, { CalculationResponse, RaiseQueryFormData } from "../../services/AddEditPopUpService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import sharedUtils from "../grid/sharedUtilities";
import CardWrapper3Part from "../Cards/CardWrapper3Part";
import CardHeaderSection from "../Cards/CardSections/CardHeaderSection";
import CardCommentsSection from "../Cards/CardSections/CardCommentsSection";
import CardPersonnelSection from "../Cards/CardSections/CardPersonnelSection";
import { WithTranslation, withTranslation } from "react-i18next";
import GlobalService from "../../services/GlobalService";
import billableItemUtils from "../Utility/billableItemUtils";

// all of this info will be needed to populate a card
// not sure if status (late/on time) will come from db or be dervied here
interface OutstandingApprovalCardV2Props extends WithTranslation {
    outstandingCardData: BillableItemResponse;
    renderCards: (billableItemId: string, serviceTypeId: string) => void; // A callback to the card List component once the Shift has been accepted or Approved.
    configuarationValueIndex: string[];
    totalNumber?: number;
    serialNumber?: number;
    configurationValueArray: any;
    isApproveAll?: boolean;
    setCost: (currentProviderAmount: number, previousProviderAmount: number,
        currentBillableHours: string, previousBillableHours: string,
        cardSectionVal: string, acoordionNo: number, totalQueriedCost: string,
        totalNonQueriedCost: string, totalQueriedMinutes: string, totalNonQueriedMinutes: string, billableItemId: string,
        serviceTypeId: string, summaryBreakDownKey: number, navigatedFrom: string, paymentId: string, invoiceId: string, billableQuantity: string) => void;
    cardSection?: string;
    accordionNo?: number;
    totalNonQueriedCost?: string;
    totalNonQueriedMinutes?: string;
    totalQueriedCost?: string;
    totalQueriedMinutes?: string;
    summaryBreakDownKey?: number;
}

interface OutstandingApprovalCardV2State {
    checkInStatus: string;
    checkOutStatus: string;
    editViewDataItem: BillableItemResponse;
    convertedEditViewDataItem: AddEditFormData;
    disableBilableStartFinishFields: boolean;
    cardTitle: string;
    checkBoxType: boolean;
    billableHoursAdjustment: string; //State variable to show the adjusted billable Hours on the Scheduled shift timings.
    billableHours: string;
    convertedBillableHours: ""; //State variable to keep a copy of the billable hours on load.
    convertedBillableHoursAdjustment: "";
    buttonText: string;
    queryButtonText: string;
    calculationErrorMessage: [];
    errorMessage: "";
    queryItem: RaiseQueryFormData;
    loadPanelVisible: boolean;
    checkBoxValue: boolean;
    isChangeStartFinish: boolean;
    isChangeAbsent: boolean;
    billableStartTimer: any;
    billableFinishTimer: any;
    disableApproveButton: boolean;
    disableQueryButton: boolean;
    disableSaveButton: boolean;
    calculationText: string;
    feedbackRequestItem: feedbackRequestData;
    toggleFeedbackBox: boolean;
    isThumbsUpClicked: boolean;
    isFeedbackEdited: boolean;
    isSuccssApproved?: boolean;
}

class OutstandingApprovalCard extends React.Component<OutstandingApprovalCardV2Props> {
    lookupService: LookupService;
    state: OutstandingApprovalCardV2State;
    outstandingApprovalService: OutstandingApprovalService;
    utils: outstandingActionsUtilities;
    billableItemUtils: billableItemUtils;
    addEditUtils: addEditPopupUtils;
    sharedUtils: sharedUtils;
    editViewService: AddEditPopUpService;

    //service that would post the data back to the server.
    constructor(props: OutstandingApprovalCardV2Props) {
        super(props);
        //Initialize services
        this.lookupService = new LookupService();
        this.outstandingApprovalService = new OutstandingApprovalService();
        this.utils = new outstandingActionsUtilities();
        this.addEditUtils = new addEditPopupUtils();
        this.sharedUtils = new sharedUtils();
        this.editViewService = new AddEditPopUpService();
        this.billableItemUtils = new billableItemUtils();
        //Initialize the service.
        this.outstandingApprovalService = new OutstandingApprovalService();
        // var convertedEditViewDataItem = this.utils.initializeBillableItem();
        var initializedBillableItem = this.utils.initializeBillableItem();
        var cardTitle = "Security :" + initializedBillableItem.role;
        var convertedQueryFormData = this.addEditUtils.initializeQueryFormData(
            this.props.outstandingCardData.billableItemId
                ? this.props.outstandingCardData.billableItemId.toString()
                : "",
            this.props.outstandingCardData.venueId,
            "",
            "Shift / Item Status Query",
            ""
        );
        var initializedfeedbackItem = this.utils.initializeFeedbackRequestItem(
            this.props.outstandingCardData.billableItemId
                ? this.props.outstandingCardData.billableItemId.toString()
                : "",
            this.props.outstandingCardData
        );

        this.state = {
            checkInStatus: "", //These will resolve to early or Late based on the color field received from the server.
            checkOutStatus: "",
            editViewDataItem: this.props.outstandingCardData,
            convertedEditViewDataItem: initializedBillableItem,
            feedbackRequestItem: initializedfeedbackItem,
            disableBilableStartFinishFields: false,
            cardTitle: cardTitle,
            billableHoursAdjustment: "",
            billableHours: "",
            convertedBillableHours: "",
            convertedBillableHoursAdjustment: "",
            checkBoxType: this.props.outstandingCardData.noCharge != "Yes" ? true : false,
            buttonText: "Approve",
            queryButtonText: "Raise Query",
            calculationErrorMessage: [],
            errorMessage: "",
            queryItem: convertedQueryFormData,
            loadPanelVisible: false,
            checkBoxValue:
                this.props.outstandingCardData.noCharge != "Yes"
                    ? this.props.outstandingCardData.absent != "Yes"
                        ? false
                        : true
                    : true,
            isChangeStartFinish: false,
            isChangeAbsent: false,
            billableStartTimer: null,
            billableFinishTimer: null,
            calculationText: "",
            disableApproveButton: this.props.isApproveAll ? this.props.isApproveAll : false,
            disableQueryButton: false,
            disableSaveButton: false,
            toggleFeedbackBox: false,
            isThumbsUpClicked: false,
            isFeedbackEdited: false,
            isSuccssApproved: false
        };
    }

    componentDidMount() {
        var convertedDataSource = this.utils.convertBillableItemResponeToFormData(this.state.editViewDataItem);
        var feedBackType: string = "";
        this.setState(
            {
                convertedEditViewDataItem: convertedDataSource,
            },
            () => this.adjustmentCalculations()
        );

        if (convertedDataSource.feedback) {
            if (convertedDataSource.feedback.feedbackScore == feedbackScoreType.thumbsUp) {
                feedBackType = "thumbsUp";
            }
            this.setState({
                //toggleFeedbackBox: true,
                //feedbackComments: convertedDataSource.feedback.feedbackComments,
                isThumbsUpClicked: feedBackType == "thumbsUp" ? true : false,
            })
        }

        this.disableRoleBasedFields(convertedDataSource.itemTypeId);
        this.setCheckInOutClass();
        this.enableDisableQueryApproveButton(convertedDataSource);
    }

    enableDisableQueryApproveButton = (billableItem: AddEditFormData) => {
        var isPayment: boolean = false;

        if (billableItem && billableItem.paymentId) {
            isPayment = true;
        }
        if (billableItem.billableStatusLookupId == Status.Queried) {
            this.setState({
                disableQueryButton: true,
                queryButtonText: "Queried",
                disableApproveButton: true,
                disableBilableStartFinishFields: true
            })
        }
        else if (billableItem.isAccrual && !billableItem.paymentId && !billableItem.approval_Date ||
            !billableItem.isAccrual && !billableItem.approval_Date && !billableItem.invoiceId) {
            this.setState({
                disableApproveButton: false
            });
        } else if (billableItem.approval_Date) {
            this.setState({
                disableApproveButton: true,
                disableBilableStartFinishFields: true,
                disableSaveButton: true,
                buttonText: "Approved"
            });
        } else {
            this.setState({
                disableApproveButton: true,
                disableBilableStartFinishFields: true
            });
        }

        if (isPayment) {
            this.setState({
                disableApproveButton: true,
                disableBilableStartFinishFields: true,
                disableSaveButton: true,
                buttonText: "Paid"
            })
        }
    }

    adjustmentCalculations = () => {
        const { itemTypeId } = this.state.convertedEditViewDataItem;
        var adjustedBillableHours: string = this.utils.convertBillableHoursToAjustmentType(
            this.state.convertedEditViewDataItem.billable_Hours_Units
        );
        if (adjustedBillableHours) {
            var billableHours: string = this.state.convertedEditViewDataItem.billable_Hours_Units.substr(
                0,
                this.state.convertedEditViewDataItem.billable_Hours_Units.indexOf("(")
            );
            this.setState({
                billableHoursAdjustment: adjustedBillableHours,
                billableHours: billableHours,
                convertedBillableHours: billableHours,
                convertedBillableHoursAdjustment: adjustedBillableHours,
            });
        } else {
            this.setState({
                billableHoursAdjustment: adjustedBillableHours,
                billableHours: this.state.convertedEditViewDataItem.billable_Hours_Units,
                convertedBillableHours: this.state.convertedEditViewDataItem.billable_Hours_Units,
                convertedBillableHoursAdjustment: adjustedBillableHours,
            });
        }

    };

    //Function that will set the check in status to be early or late.
    setCheckInOutClass = () => {
        const { checkin_Color, check_In, checkOut_Color, check_Out } = this.state.editViewDataItem;
        if (check_In != "00:00") {
            if (checkin_Color == checkInStatus.red) {
                this.setState({
                    checkInStatus: "Late",
                });
            } else {
                this.setState({
                    checkInStatus: "Early",
                });
            }
        } else {
            this.setState({
                checkInStatus: "Late",
            });
        }
        if (check_Out != "00:00") {
            if (checkOut_Color == checkInStatus.red) {
                this.setState({
                    checkOutStatus: "Early",
                });
            } else {
                this.setState({
                    checkOutStatus: "Late",
                });
            }
        } else {
            this.setState({
                checkOutStatus: "Late",
            });
        }
    };

    //Arrow functions doesn't need to be bind in the constructor, the context of "this" is set inside of them.
    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            convertedEditViewDataItem: {
                ...this.state.convertedEditViewDataItem,
                comments: dxValueChange.value,
            },
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
                serviceTypeId: this.state.editViewDataItem.serviceTypeId,
                clientId: this.state.editViewDataItem.clientId,
                venueId: this.state.editViewDataItem.venueId
            },
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));

    };

    handleSubmit = () => {
        var isBillableFieldsBlank: boolean = false;
        var isAmend: boolean = false;
        var { itemTypeId, scheduledMinutes, billable_Minutes, billableRate, scheduleRate, serviceSubTypeId } = this.state.convertedEditViewDataItem;
        var acceptanceDate = undefined;
        var approvalDate = undefined;
        var isAbsent: string = "";
        var isNoCharge: string = "";
        var statusId: string = "";

        this.setState({
            loadPanelVisible: true,
        });
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) || itemTypeId == "12" || itemTypeId == "14") {
            acceptanceDate = new Date();
            statusId = Status.Accepted;
        } else {
            approvalDate = new Date();
            statusId = Status.Approved;
        }

        if (this.state.checkBoxValue == true && this.state.checkBoxType == true) {
            isAbsent = "Yes";
        } else if (this.state.checkBoxValue == true && this.state.checkBoxType == false) {
            isNoCharge = "Yes";
        }

        var serviceFeeMatrixDerivation = this.billableItemUtils.serviceFeeMatrixDerivation(
            this.state.convertedEditViewDataItem.clientId,
            this.state.convertedEditViewDataItem.serviceTypeId,
            this.state.convertedEditViewDataItem.serviceSubTypeId,
        );
        isAmend = this.billableItemUtils.acceptanceRecordCreation(
            "past",
            itemTypeId,
            false,
            serviceSubTypeId,
            billable_Minutes,
            scheduledMinutes,
            billableRate,
            parseInt(scheduleRate),
            0,
            0,
            serviceFeeMatrixDerivation.valueOfProviderAcceptance
        );
        if (isAmend) {
            statusId = this.billableItemUtils.billableItemStatusSetBasedOnItemType(this.state.convertedEditViewDataItem.itemTypeId, serviceFeeMatrixDerivation.valueOfProviderAcceptance);
        }
        var billableItemSubmitRequest = this.utils.convertFormDataToBillableItemRequest(
            this.state.convertedEditViewDataItem,
            statusId,
            isBillableFieldsBlank,
            acceptanceDate,
            approvalDate,
            isAbsent,
            isNoCharge,
            isAmend,
            "",
            "ApprovalCard"
        );

        var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
        billableItemSubmitRequestArr.push(billableItemSubmitRequest);

        this.outstandingApprovalService
            .uploadEditAddData(billableItemSubmitRequestArr)
            .then(this.handleSubmitSuccess)
            .catch((err) => {
                this.setState({
                    calculationErrorMessage:
                        err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of approval.
                    loadPanelVisible: false,
                });
                var respMessage: string = "uploadEditAddData failed with response: " + JSON.stringify(err);

                if (!this.outstandingApprovalService.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    };

    handleSave = () => {
        var isAmend: boolean = false;
        var isAbsent: string = "";
        var isNoCharge: string = "";

        this.setState({
            loadPanelVisible: true,
        });

        if (this.state.checkBoxValue == true && this.state.checkBoxType == true) {
            isAbsent = "Yes";
        } else if (this.state.checkBoxValue == true && this.state.checkBoxType == false) {
            isNoCharge = "Yes";
        }

        var billableItemSubmitRequest = this.utils.convertFormDataToBillableItemRequest(
            this.state.convertedEditViewDataItem,
            "",
            false,
            undefined,
            undefined,
            isAbsent,
            isNoCharge,
            isAmend,
            "",
            "ApprovalCard"
        );

        var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
        billableItemSubmitRequestArr.push(billableItemSubmitRequest);

        this.outstandingApprovalService
            .uploadEditAddData(billableItemSubmitRequestArr)
            .then(this.handleSaveSuccess)
            .catch((err) => {
                this.setState({
                    calculationErrorMessage:
                        err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of approval.
                    loadPanelVisible: false,
                });
                var respMessage: string = "uploadEditAddData failed with response: " + JSON.stringify(err);

                if (!this.outstandingApprovalService.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    }

    handleSaveSuccess = () => {
        if (this.state.feedbackRequestItem.feedbackScore || this.state.feedbackRequestItem.feedbackComments || this.state.isFeedbackEdited) {
            this.outstandingApprovalService
                .submitFeedBack(this.state.feedbackRequestItem)
                .then(this.handleFeedbackSubmissionSuccess)
                .catch(this.handleFeedbackSubmissionError);

        }
        this.hideLoadpanel();
    };

    handleSubmitSuccess = () => {
        if (this.state.feedbackRequestItem.feedbackScore || this.state.feedbackRequestItem.feedbackComments || this.state.isFeedbackEdited) {
            this.outstandingApprovalService
                .submitFeedBack(this.state.feedbackRequestItem)
                .then(this.handleFeedbackSubmissionSuccess)
                .catch(this.handleFeedbackSubmissionError);

        }

        this.setState({
            disableApproveButton: true,
            isSuccssApproved: true,
            disableBilableStartFinishFields: true,
            disableSaveButton: true,
            buttonText: "Approved"
        });
        this.hideLoadpanel();
        this.props.renderCards(
            this.state.convertedEditViewDataItem.billableItemId,
            this.state.convertedEditViewDataItem.serviceTypeId
        );
    };

    handleFeedbackSubmissionSuccess = () => {
        this.hideLoadpanel();
    };

    handleFeedbackSubmissionError = () => {
        this.hideLoadpanel();
    };

    handleChangeBillableStart = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event != undefined) {
            this.setState({
                convertedEditViewDataItem: {
                    ...this.state.convertedEditViewDataItem,
                    billable_Start: dxValueChange.value,
                },
                isChangeStartFinish: true,
                disableApproveButton: true,
                calculationText: "Calculating...",
            }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
            this.state.billableStartTimer = setTimeout(this.financialCalculations, 300);
        }
    };

    handleChangeBillableFinish = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event != undefined) {
            this.setState({
                convertedEditViewDataItem: {
                    ...this.state.convertedEditViewDataItem,
                    billable_Finish: dxValueChange.value,
                },
                isChangeStartFinish: true,
                disableApproveButton: true,
                calculationText: "Calculating...",
            }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
            this.state.billableFinishTimer = setTimeout(this.financialCalculations, 300);
        }
    };

    checkBoxValueChanged = (dxValueChange: CheckBoxOnValueChanged) => {
        if (dxValueChange.event != undefined) {
            this.setState({
                checkBoxValue: dxValueChange.value,
                disableApproveButton: true,
                isChangeStartFinish: false,
            });
            this.resetBillableTimeCalculations(dxValueChange.value);
        }
    };

    resetBillableTimeCalculations = (checkBoxValue: boolean) => {
        if (checkBoxValue == true) {
            this.setState(
                {
                    convertedEditViewDataItem: {
                        ...this.state.convertedEditViewDataItem,
                        billable_Finish: this.state.convertedEditViewDataItem.billable_Start,
                    },
                    billableHours: "0 Hrs",
                    calculationText: "Calculating...",
                },
                () => this.financialCalculations()
            );
        } else {
            this.setState(
                {
                    convertedEditViewDataItem: {
                        ...this.state.convertedEditViewDataItem,
                        billable_Start: this.sharedUtils.convertStringToTime(
                            this.state.editViewDataItem.billable_Start
                        ),
                        billable_Finish: this.sharedUtils.convertStringToTime(
                            this.state.editViewDataItem.billable_Finish
                        ),
                    },
                    billableHours: this.state.convertedBillableHours,
                    billableHoursAdjustment: this.state.convertedBillableHoursAdjustment,
                },
                () => this.financialCalculations()
            );
        }
    };

    financialCalculations = () => {
        GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem);
        let calCulationObject: CalculationRequest = this.utils.constructCalculationObject(
            this.state.convertedEditViewDataItem
        );

        this.outstandingApprovalService
            .uploadFormData(calCulationObject)
            .then(this.handleCalculationSuccess)
            .catch((err) => {
                this.setState({
                    calculationErrorMessage:
                        err.response ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    IsShowSuccess: false,
                });
            })
            .finally(() => {
                // this.hideLoadIndicator();
            });
    };

    handleCalculationSuccess = (response: AxiosResponse<any>) => {
        var serverResponse: CalculationResponse = response.data.data;
        const { billableMinutes } = response.data.data;
        const { scheduledMinutes, originalBillableMinutes, itemTypeId } = this.state.convertedEditViewDataItem;
        var extraBillableMinutes: number = 0;
        if (itemTypeId == itemTypes.Original || itemTypeId == itemTypes.Acceptance || itemTypeId == "") {
            extraBillableMinutes = this.sharedUtils.convertStringToFloat(billableMinutes) - scheduledMinutes;
        } else {
            extraBillableMinutes = parseFloat(billableMinutes);
        }
        if (
            this.state.isChangeStartFinish == true &&
            this.state.convertedEditViewDataItem.itemTypeId == itemTypes.Original
        ) {
            var billableHoursField: number = this.sharedUtils.convertStringToFloat(billableMinutes);
            var billableTime: string = this.utils.convertBillableMinutesToHoursFormat(billableHoursField);
            this.setState({
                billableHours: billableTime != " " ? billableTime : "0 Hrs",
            });
        } else if (this.state.convertedEditViewDataItem.itemTypeId != itemTypes.Original) {
            if (this.state.isChangeStartFinish == true) {
                var originalBillableTimeDifference: number = 0;
                var billableTimeField: string = "";
                if (this.sharedUtils.convertStringToFloat(billableMinutes) < 0) {
                    originalBillableTimeDifference =
                        originalBillableMinutes - Math.abs(this.sharedUtils.convertStringToFloat(billableMinutes));
                    billableTimeField = this.utils.convertBillableMinutesToHoursFormat(originalBillableTimeDifference);
                } else {
                    originalBillableTimeDifference =
                        originalBillableMinutes + this.sharedUtils.convertStringToFloat(billableMinutes);
                    billableTimeField = this.utils.convertBillableMinutesToHoursFormat(originalBillableTimeDifference);
                }
                this.setState({
                    billableHours: billableTimeField != " " ? billableTimeField : "0 Hrs",
                });
            }
        }
        var cardStatePostCalculation: AddEditFormData = this.utils.alterStatePostCalculation(
            this.state.convertedEditViewDataItem,
            serverResponse,
            this.props.configuarationValueIndex
        );
        var previousProviderPayAmountVal = this.state.convertedEditViewDataItem.provider_Pay_Amount_Local_Ccy;
        var previousBillableHoursMin = this.state.convertedEditViewDataItem.billable_Minutes;
        this.setState({
            convertedEditViewDataItem: cardStatePostCalculation,
            billableHoursAdjustment: extraBillableMinutes
                ? `${extraBillableMinutes ? (extraBillableMinutes > 0 ? "+" : "-") : ""
                }${this.utils.convertBillableMinutesToHoursFormat(Math.abs(extraBillableMinutes)).trim()}`
                : "0 Hrs",
            disableApproveButton: this.state.buttonText == "Approved" ? true : false,
            calculationText: "",
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
        this.props.setCost(cardStatePostCalculation.provider_Pay_Amount_Local_Ccy, previousProviderPayAmountVal,
            this.state.billableHours, previousBillableHoursMin.toString(),
            this.props.cardSection ? this.props.cardSection : "", this.props.accordionNo ? this.props.accordionNo : 0,
            this.props.totalQueriedCost ? this.props.totalQueriedCost : "", this.props.totalNonQueriedCost ? this.props.totalNonQueriedCost : "", this.props.totalQueriedMinutes ? this.props.totalQueriedMinutes : "", this.props.totalNonQueriedMinutes ? this.props.totalNonQueriedMinutes : "",
            this.props.outstandingCardData.billableItemId, this.props.outstandingCardData.serviceTypeId, this.props.summaryBreakDownKey ? this.props.summaryBreakDownKey : 0,
            "calculation", this.state.editViewDataItem.paymentId, this.state.editViewDataItem.invoiceId, "");
    };

    disableRoleBasedFields = (itemTypeId: string) => {
        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) || itemTypeId == "12" || itemTypeId == "14") {
            this.setState({
                disableBilableStartFinishFields: true,
                buttonText: "Accept",
            });
        }
    };

    onSubmitQuery = () => {
        let querySubmissionError: boolean = true;
        querySubmissionError = this.sharedUtils.validationCheckFunction(this.state.convertedEditViewDataItem.comments);
        if (!querySubmissionError) {
            this.setState({
                errorMessage: this.props.t("approvalCard.weeklyCleaning.queryText.error"),
            });
        } else {
            this.setState({
                errorMessage: "",
                loadPanelVisible: true,
            });
            this.editViewService
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
        this.props.setCost(0, this.state.convertedEditViewDataItem.provider_Pay_Amount_Local_Ccy,
            this.state.billableHours, this.state.convertedEditViewDataItem.billable_Minutes.toString(),
            this.props.cardSection ? this.props.cardSection : "", this.props.accordionNo ? this.props.accordionNo : 0,
            this.props.totalQueriedCost ? this.props.totalQueriedCost : "", this.props.totalNonQueriedCost ? this.props.totalNonQueriedCost : "", this.props.totalQueriedMinutes ? this.props.totalQueriedMinutes : "", this.props.totalNonQueriedMinutes ? this.props.totalNonQueriedMinutes : "",
            this.props.outstandingCardData.billableItemId, this.props.outstandingCardData.serviceTypeId, this.props.summaryBreakDownKey ? this.props.summaryBreakDownKey : 0,
            "query", this.state.editViewDataItem.paymentId, this.state.editViewDataItem.invoiceId, "");
    };

    handleQuerySubmissionSuccess = () => {
        this.setState({
            queryButtonText: "Queried",
            disableQueryButton: true,
            disableApproveButton: true,
            disableBilableStartFinishFields: true

        });
        this.hideLoadpanel();
        this.props.renderCards(
            this.state.convertedEditViewDataItem.billableItemId,
            this.state.convertedEditViewDataItem.serviceTypeId
        );
    };

    handleQuerySubmissionFailure = (error: any) => {
        var respMessage: string =
            "uploadQueryFormData - Query submission failed with response: " + JSON.stringify(error);

        if (!this.editViewService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.hideLoadpanel();
    };

    hideLoadpanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleThumbsUpIconClick = (event: any) => {
        if ((this.state.feedbackRequestItem.feedbackScore && this.state.feedbackRequestItem.feedbackScore == feedbackScoreType.thumbsUp) ||
            (this.state.editViewDataItem.feedback && this.state.editViewDataItem.feedback.feedbackScore && this.state.editViewDataItem.feedback.feedbackScore == feedbackScoreType.thumbsUp)) {
            this.setState({
                feedbackRequestItem: {
                    ...this.state.feedbackRequestItem,
                    feedbackScore: "",
                    feedbackType: "DoorSupervisor"
                },
                isFeedbackEdited: true,
                isThumbsUpClicked: false,
            });
        }
        else {
            this.setState({
                feedbackRequestItem: {
                    ...this.state.feedbackRequestItem,
                    feedbackScore: feedbackScoreType.thumbsUp,
                    feedbackType: "DoorSupervisor"
                },
                isThumbsUpClicked: true,
            });

        }
        event.preventDefault();
    };

    renderCustomData = (data: LookupTypeItem) => {
        return <div className="bg-warning font-weight-bold"> {data.value} </div>;
    };

    clearErrorMessage = () => {
        this.setState({
            errorMessage: "",
        });
    };

    //Clear the Timer.
    componentWillUnmount() {
        clearTimeout(this.state.billableStartTimer);
        clearTimeout(this.state.billableFinishTimer);
    }

    render() {
        var shiftDifferenceClass: string = "";
        var headerTextAsPerRole: string = "";
        var bodyTextAsPerRole: string = "";
        var personnelHeaderText: string = "";
        var personnelCommentText: string = "";
        var personnelAdjustmentHeaderText: string = "";
        var personnelAdjustmentCommentText: string = "";
        var personnelAdjustmentFooterText: string = "";
        var personnelAcceptanceHeaderText: string = "";
        var personnelAcceptanceCommentText: string = "";
        if (this.props.configurationValueArray.length > 0) {
            personnelHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalSecurityPersonnel_Header;
            })[0].value;
            personnelCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalSecurityPersonnel_Comments;
            })[0].value;
            personnelAdjustmentHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalSecurityPersonnelAdjustment_Header;
            })[0].value;
            personnelAdjustmentCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalSecurityPersonnelAdjustment_Comments;
            })[0].value;
            personnelAcceptanceHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebAcceptanceSecurityPersonnelAdjustment_Header;
            })[0].value;
            personnelAcceptanceCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebAcceptanceSecurityPersonnelAdjustment_Comments;
            })[0].value;
        }
        var { billableHoursAdjustment, billableHours } = this.state;
        const {
            originalBillableStartTime,
            originalBillableFinishTime,
            shift_Start,
            shift_End,
            check_In,
            check_Out,
        } = this.state.editViewDataItem;
        const {
            scheduled_Hours_Units,
            billable_Start,
            billable_Finish,
            service,
            itemTypeId,
            identification_Number,
            venueName,
            provider,
            venuePostCode,
            name,
            billable_Minutes,
            scheduledMinutes,
            previousHoursRunningTotal,
            serviceSubTypeId,
            client
        } = this.state.convertedEditViewDataItem;
        const finalLicenseNumber = this.sharedUtils.licenseNumberFormat(identification_Number);
        const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
        const isEventUser =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        var shiftDifference: number = billable_Minutes - scheduledMinutes;
        var isAdjustmentRecord: boolean =
            itemTypeId == itemTypes.Adjustment || itemTypeId == itemTypes.AdjustmentClientDebit ? true : false;
        var isProviderAcceptance: boolean = itemTypeId == itemTypes.AdjustmentProviderDebit ? true : false;
        if (
            shiftDifference &&
            (itemTypeId == itemTypes.Original || itemTypeId == "" || itemTypeId == itemTypes.Acceptance)
        ) {
            if (shiftDifference > 0) {
                if (isVenueManager || isEventUser) {
                    shiftDifferenceClass = "text-warning";
                } else {
                    shiftDifferenceClass = "text-info";
                }
            } else {
                if (isVenueManager || isEventUser) {
                    shiftDifferenceClass = "text-info";
                } else {
                    shiftDifferenceClass = "text-warning";
                }
            }
        }
        if (isAdjustmentRecord) {
            if (billable_Minutes > 0) {
                shiftDifferenceClass = "text-warning";
            } else if (billableHoursAdjustment != "0 Hrs") {
                shiftDifferenceClass = "text-info";
            }
        }
        if (isProviderAcceptance) {
            if (billable_Minutes > 0) {
                shiftDifferenceClass = "text-warning";
            } else {
                shiftDifferenceClass = "text-info";
            }
        }
        var itemType: boolean =
            itemTypeId == itemTypes.Original || itemTypeId == "" || itemTypeId == itemTypes.Acceptance ? true : false;
        const date = this.utils.convertddmmyyyyStringToCustomizedFormat(this.state.editViewDataItem.date);
        var venue: string = `${venueName ? venueName : ""}${venueName ? (venuePostCode ? `, ${venuePostCode}` : "") : venuePostCode
            }`;

        if (isVenueManager) {
            if (itemType) {
                headerTextAsPerRole = personnelHeaderText;
                bodyTextAsPerRole = personnelCommentText;
            } else {
                headerTextAsPerRole = personnelAdjustmentHeaderText;
                bodyTextAsPerRole = personnelAdjustmentCommentText;
            }
        } else {
            headerTextAsPerRole = personnelAcceptanceHeaderText;
            bodyTextAsPerRole = personnelAcceptanceCommentText;
        }

        return (
            <>
                <CardWrapper3Part
                    type="security"
                    typeTitle={service}
                    enumeration={true}
                    cardIndex={this.props.serialNumber}
                    cardsTotal={this.props.totalNumber}
                    header={
                        <CardHeaderSection
                            date={date}
                            adjustment={itemType}
                            message={headerTextAsPerRole ? headerTextAsPerRole : ""}
                            errorMessage={this.state.errorMessage}
                            clearErrorMessage={this.clearErrorMessage}
                            calculationErrorMessage={this.state.calculationErrorMessage}
                            licenseNumber={finalLicenseNumber}
                            venue={venue}
                            title={name}
                            provider={provider}
                            serviceSubTypeId={serviceSubTypeId}
                            client={client}
                        />
                    }
                    body={
                        <CardPersonnelSection
                            scheduled_Hours_Units={scheduled_Hours_Units}
                            shift_Start={shift_Start}
                            shift_End={shift_End}
                            check_In={check_In}
                            check_Out={check_Out}
                            billable_Start={billable_Start}
                            billable_Finish={billable_Finish}
                            disableBilableStartFinishFields={this.state.disableBilableStartFinishFields}
                            checkInStatus={this.state.checkInStatus}
                            checkOutStatus={this.state.checkOutStatus}
                            billableHoursAdjustment={billableHoursAdjustment}
                            billableHours={billableHours}
                            originalBillableStartTime={originalBillableStartTime}
                            originalBillableFinishTime={originalBillableFinishTime}
                            checkBoxType={this.state.checkBoxType}
                            checkBoxValue={this.state.checkBoxValue}
                            handleChangeBillableStart={this.handleChangeBillableStart}
                            handleChangeBillableFinish={this.handleChangeBillableFinish}
                            checkBoxValueChanged={this.checkBoxValueChanged}
                            shiftCss={shiftDifferenceClass}
                            previousHoursRunningTotal={previousHoursRunningTotal}
                            itemTypeId={itemTypeId}
                            calculationText={this.state.calculationText}
                            handleThumbsUpIconClick={this.handleThumbsUpIconClick}
                            isThumbsUpClicked={this.state.isThumbsUpClicked}
                            providerPayAmountLocal={this.sharedUtils.thousandsSeparator(this.state.convertedEditViewDataItem.provider_Pay_Amount_Local_Ccy.toString())}
                        />
                    }
                    footer={
                        // ToDo: wire up comments section, currently it looks good, but doesnt work
                        <CardCommentsSection
                            message={bodyTextAsPerRole ? bodyTextAsPerRole : ""}
                            comments={this.state.convertedEditViewDataItem.comments}
                            handleChangeComments={this.handleChangeComments}
                            buttonText={this.state.buttonText}
                            queryButtonText={this.state.queryButtonText}
                            onSubmitQuery={this.onSubmitQuery}
                            handleSubmit={this.handleSubmit}
                            handleSave={this.handleSave}
                            disableApproveButton={this.state.disableApproveButton}
                            disableQueryButton={this.state.disableQueryButton}
                            disableSaveButton={this.state.disableSaveButton}
                        />
                    }
                />
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
            </>
        );
    }
}

export default withTranslation()(OutstandingApprovalCard);
