import React, { Component } from "react";
import CommonUIUtils from "../../../common/CommonUIUtils";

interface LightModePageTemplateProps {
    children: React.ReactNode;
}

class LightModePageTemplate extends Component<LightModePageTemplateProps> {
    commonUIUtils: CommonUIUtils;

    constructor(props: LightModePageTemplateProps) {
        super(props);
        this.commonUIUtils = new CommonUIUtils();
    }

    componentDidMount() {
        this.commonUIUtils.setDarkMode(false);
    }

    render() {
        return <>{this.props.children}</>;
    }
}

export default LightModePageTemplate;
