import { AxiosResponse } from "axios";
import { BillableItemResponse } from "./AddEditPopUpService";
import { _get, _post, _aiTraceAsError} from "./BaseApiService";
import { eventUkUserGridRowItem } from "./SimpleGridService";

const POST_APPROVAL_GET_API = "BillableItem/PostApprovalSummary";

export interface postApprovalData {
    shiftCount:number,
    serviceId:number,
    dateFrom:string,
    dateTo:string,
    periodId:string,
    totalNonQueriedCost : string,
    totalNonQueriedMinutes :string,
    isItemQueried : boolean,
    totalQueriedMinutes :string,
    totalQueriedCost :string,
    postApprovalSecurityCards: {
        providerName: string,
        onTimePersonnelSummary:{
              totalHours: number,
              totalShiftHours:string,
              totalCost:number,
              totalShiftCost:number,
              summaryBreakdown: [
                  {
                    date: string,
                    totalNonQueriedHours:string,
                    totalQueriedHours:string,
                    totalNonQueriedCost:string,
                    totalQueriedCost:string,
                    shiftCount:string,
                    shiftList : BillableItemResponse[]
                  }
              ]
        },
        latePersonnelSummary : {
            totalNonQueriedHours:string,
            totalQueriedHours:string,
            totalNonQueriedCost:string,
            totalQueriedCost:string,
            shiftCount: number,         
            shiftDetails : BillableItemResponse[]
        },
        onTimeEquipmentSummary : {
            totalNonQueriedHours:string,
            totalQueriedHours:string,
            totalNonQueriedCost:string,
            totalQueriedCost:string,
            shiftCount: number,         
            shiftDetails : BillableItemResponse[]
        },
        lateEquipmentSummary : {
            totalNonQueriedHours:string,
            totalQueriedHours:string,
            totalNonQueriedCost:string,
            totalQueriedCost:string,
            shiftCount: number,         
            shiftDetails : BillableItemResponse[]
        },
        adjustmentSummary : {
            totalNonQueriedHours:string,
            totalQueriedHours:string,
            totalNonQueriedCost:string,
            totalQueriedCost:string,
            shiftCount: number,
            adjustmentShiftList: BillableItemResponse[]
        },
        disputedItems : {
            totalNonQueriedHours : string,
            totalQueriedHours : string,
            totalNonQueriedCost: string,
            totalQueriedCost : string,
            shiftCount : number,
            adjustmentShiftList: BillableItemResponse[]
        }
    },
    postApprovalCleaningCards: {
        providerName: string,
        onTimeWeeklyCleaningSummary: {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            shiftCount : number,
            shiftDetails: BillableItemResponse[]
        },
        lateWeeklyCleaningSummary: {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            shiftCount : number,
            shiftDetails: BillableItemResponse[]
        },
        onTimeAdditionalSummary: {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            shiftCount : number,
            shiftDetails: BillableItemResponse[]
        },
        lateAdditionalSummary: {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            shiftCount : number,
            shiftDetails: BillableItemResponse[]
        },
        adjustmentSummary: {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            shiftCount : number,
            adjustmentShiftList: BillableItemResponse[]
        },
        disputedItems : {
            totalNonQueriedHours : string,
            totalQueriedHours : string,
            totalNonQueriedCost: string,
            totalQueriedCost : string,
            shiftCount : number,
            adjustmentShiftList: BillableItemResponse[]
        }
    },
    postApprovalEntertainmentCards: {
        onTimeEntertainmentSummary: {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            summaryBreakdown: [
                {
                  date: string,
                  totalQueriedCost:string,
                  totalNonQueriedCost:string,
                  totalQueriedHours:string,
                  totalNonQueriedHours:string,
                  shiftCount:string,
                  shiftList : eventUkUserGridRowItem[]
                }
            ]
        },
        lateEntertainmentSummary: {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            shiftCount:number,
            shiftDetails: BillableItemResponse[]
        },
        adjustmentSummary : {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            shiftCount: number,
            adjustmentShiftList:BillableItemResponse[]
        },
        disputedItems : {
            totalNonQueriedHours : string,
            totalQueriedHours : string,
            totalNonQueriedCost: string,
            totalQueriedCost : string,
            shiftCount : number,
            adjustmentShiftList: BillableItemResponse[]
        }
    },
    postApprovalOtherCards: {
        onTimeEntertainmentSummary: {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            summaryBreakdown: [
                {
                  date: string,
                  totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
                  shiftCount:string,
                  shiftList : eventUkUserGridRowItem[]
                }
            ]
        },
        lateEntertainmentSummary: {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            shiftCount:number,
            shiftDetails: BillableItemResponse[]
        },
        adjustmentSummary : {
            totalQueriedCost:string,
            totalNonQueriedCost:string,
            totalQueriedHours:string,
            totalNonQueriedHours:string,
            shiftCount: number,
            adjustmentShiftList:BillableItemResponse[]
        },
        disputedItems : {
            totalNonQueriedHours : string,
            totalQueriedHours : string,
            totalNonQueriedCost: string,
            totalQueriedCost : string,
            shiftCount : number,
            adjustmentShiftList: BillableItemResponse[]
        }
    }
}

class PostApprovalService {

    getPostApprovalData(clientId: string , venueId: string , serviceId: string, dateRangeFrom : string , dateRangeTo : string , statusId : string): Promise<AxiosResponse<any>> {
        return _get(POST_APPROVAL_GET_API 
            + "?clientId=" + clientId 
            + "&venueId=" + venueId 
            + "&serviceId=" + serviceId 
            + "&dateRangeFrom=" + dateRangeFrom 
            + "&dateRangeTo=" + dateRangeTo + "&filterBy=" +statusId);
    }
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}
export default PostApprovalService;
