import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { faPen as faPenSolid } from "@fortawesome/pro-solid-svg-icons";
import { LoadPanel } from "devextreme-react";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { Link } from "react-router-dom";
import BaseActionCell, { BaseActionCellProps } from "./BaseActionCell";
import { ServiceSubTypeId } from "../../services/ArtistService";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import { serviceTypes } from "../../services/FileService";
import GlobalService from "../../services/GlobalService";
import { GridTypes } from "../../services/SimpleGridService";

class ChangeShiftMatrixCell extends BaseActionCell {
    constructor(props: BaseActionCellProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.data && this.props.data.data) {
            var statusColumnIndex: number = 0;
            if (this.props.data.data.statusValue) {
                statusColumnIndex = this.props.data.component.getVisibleColumnIndex("statusValue");
            }
            else if (this.props.data.data.status) {
                statusColumnIndex = this.props.data.component.getVisibleColumnIndex("status");
            }
            else if (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value) {
                statusColumnIndex = this.props.data.component.getVisibleColumnIndex("billableStatusLookUp.value");
            }

            if (
                this.props.data &&
                (this.props.data.data.itemTypeId == itemTypes.ClientCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ClientDebit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.ProviderDebit ||
                    this.props.data.data.itemTypeId == itemTypes.Receivable ||
                    this.props.data.data.itemTypeId == itemTypes.Payable ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
                    this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit)
            ) {
                this.setState({
                    isFinancialAdjustmentView: true,
                });
            }
            if ((this.props.data && this.props.data.data && this.props.data.data.editAllowed && this.props.data.data.editAllowed.toLowerCase() == "false") ||
                (UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) &&
                    (this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
                        this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit))) {
                this.setState({
                    isEditIconDisable: true,
                });
            }
            if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
                if (
                    ((this.props.data.data.itemTypeId == itemTypes.Original ||
                        this.props.data.data.itemTypeId == itemTypes.AdjustmentClientDebit ||
                        this.props.data.data.itemTypeId == itemTypes.AdjustmentProviderDebit) &&
                        (this.props.data.data.approvalDate ||
                            this.props.data.data.acceptDate ||
                            this.props.data.data.invoiceId)) ||
                    (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Approved") ||
                    (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value == "Accepted") ||
                    this.props.data.data.status == "Approved" ||
                    this.props.data.data.status == "Accepted"
                ) {
                    if (!this.props.data.data.childRecordId && this.props.data.data.isAccrual && !this.props.data.data.paymentId) {
                        this.setState({
                            isAdjustmentChange: false,
                        });
                    }
                    else {
                        this.setState({
                            isAdjustmentChange: true,
                        });
                    }
                }
            }
            if (this.props.data.data.serviceSubTypeLookUpId == SubServiceType.AdditionalCleaning ||
                this.props.data.data.serviceTypeLookUpId == ServiceType.Gardening ||
                this.props.data.data.serviceTypeLookUpId == ServiceType.WindowCleaning ||
                this.props.data.data.serviceTypeLookUpId == ServiceType.Maintenance ||
                this.props.data.data.serviceTypeLookUpId == ServiceType.Miscelleanous) {
                this.setState({
                    isAdjustmentChange: false,
                });
            }

            this.setState({
                statusColumnIndexVal: statusColumnIndex,
            });
        }
    }

    render() {        
        var gridType = GlobalService.getGridType();
        var pointOfNav: string = "";
        if (gridType && gridType == GridTypes.paymentsRequest) {
            pointOfNav = "MatchedPopUp";
        }
        else if (gridType && (gridType == GridTypes.artistShiftMatrix || gridType == GridTypes.providerShiftMatrix)) {
            pointOfNav = "FindAnArtistLandingPage";
        }
        else if (window.location.href.includes("BillingPageV2")) {
            pointOfNav = "Billing";
        }
        else if (gridType && gridType == GridTypes.futureNegetiveShift) {
            pointOfNav = "PaymentsV2Page";
        }
        else {
            pointOfNav = "ManageShifts";
        }
        var isEventUser =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        let billableItemId: string = "";
        if (this.props.data.data && this.props.data.data.billableItemId) {
            billableItemId = this.props.data.data.billableItemId.toString();
        }

        else if (this.props.data.data && this.props.data.data.id) {
            billableItemId = this.props.data.data.id.toString();
        }
        let service: string = "";
        if (this.props.data.data.service) {
            service = this.props.data.data.service;
        }
        else if (this.props.data.data.serviceTypeLookUp && this.props.data.data.serviceTypeLookUp.value) {
            service = this.props.data.data.serviceTypeLookUp.value;
        }
        let statusVal: string = "";
        if (this.props.data.data.statusText) {
            statusVal = this.props.data.data.statusText;
        }
        else if (this.props.data.data.billableStatusLookUp && this.props.data.data.billableStatusLookUp.value) {
            statusVal = this.props.data.data.billableStatusLookUp.value;
        }
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        message="Please wait..."
                        visible={this.state.loadPanelVisible}
                    />
                    {isEventUser ? (
                        <div>
                            {this.state.isEditIconDisable ? (
                                <Link title="Edit" className={"disabled icon-btn"} to="#">
                                    <FontAwesomeIcon icon={faPenSolid} />
                                </Link>
                            ) : (
                                <Link
                                    title="Edit"
                                    className={"icon-btn"}
                                    to={
                                        this.state.isFinancialAdjustmentView
                                            ? {
                                                pathname: "/FinanceAdjustmentPopUp",
                                                state: {
                                                    id: billableItemId,
                                                    adjustment: false,
                                                    isEdit: true,
                                                    pointOfNavigation: pointOfNav,
                                                    filterStartDate: this.props.data.data.filterStartDate,
                                                    filterEndDate: this.props.data.data.filterEndDate,
                                                    service: service,
                                                },
                                            }
                                            : this.state.isAdjustmentChange ?
                                                {
                                                    pathname: "/AddEditFormV2",
                                                    state: {
                                                        id: billableItemId,
                                                        isAdjust: this.state.isAdjustmentChange,
                                                        isEdit: true,
                                                        pointOfNavigation: pointOfNav,
                                                        filterStartDate: this.props.data.data.filterStartDate,
                                                        filterEndDate: this.props.data.data.filterEndDate,
                                                        service: service,
                                                    }
                                                } : {
                                                    pathname: "/QuickAddShiftForm",
                                                    state: {
                                                        id: billableItemId,
                                                        pointOfNavigation: pointOfNav,
                                                        filterStartDate: this.props.data.data.filterStartDate,
                                                        filterEndDate: this.props.data.data.filterEndDate,
                                                        service: service,
                                                    }

                                                }
                                            
                                    }
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </Link>
                            )}
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

export default ChangeShiftMatrixCell;
