import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { FileUploader, Button, LoadIndicator, SelectBox, TagBox } from "devextreme-react";
import FileService, { uploadedFileType, serviceTypes } from "../../services/FileService";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import {
    ButtonOnClickEvent,
    SelectBoxOnValueChangedEvent,
    FileUploaderOnValueChangedEvent,
    FileUploadOnInitializedEvent,
    TagBoxOnValueChangeEvent,
} from "../../types/DevExtremeTypes";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import { TypeOfFileUploaded } from "../../services/BillableItemHeaderService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
/**
 * Props
 */
interface DevExFileUploaderProps extends WithTranslation {
    onFileUploaded: () => void;
    onDropdownSelection: (serviceId: string, serviceSubTypeId: string) => void;
    landingPage: string;
    typeId?: string;
    configtext?: string;
    onFileUploadBillableItemHeaderId: (billableItemHeaderId: string) => void;
    isSubmitClick?: boolean;
    shiftType?: string;
}

export interface LookUpTypeItemForBillableItemUploadOverride {
    itemTypeLookupId: number;
    value: string;
    parentMappingId: string;
}

/**
 * State
 */
interface DevExFileUploaderState {
    parentTypeId: string;
    serviceSubTypeId: string;
    fileFormatTypeId: number;
    serviceTypeDataSource: LookupTypeItem[];
    serviceSubTypeDataSource: LookupTypeItem[];
    fileType: LookupTypeItem[];
    loadIndicatorVisible: boolean;
    isEnableUpload: boolean;
    isEnableBrowse: boolean;
    selectedFiles: File[];
    serviceTypeloaded: boolean;
    serviceSubTypeloaded: boolean;
    billableitemuploadhdroverrideloaded: boolean;
    billableitemuploadhdroverrideLookup: LookUpTypeItemForBillableItemUploadOverride[];
    billableitemuploadhdroverrideList: [];
    isUploadSuccessful : boolean;
}

/**
 * Component
 *
 * This component renders a form that captures all the data necessary to upload a file to the server.
 */
class DevExFileUploader extends React.Component<DevExFileUploaderProps> {
    // The DevExtreme FileUploader component
    fileUploadElement: any;
    // Services that uploads multipart/form-data to server
    fileService: FileService;
    // State object to manage form-data
    state: DevExFileUploaderState;
    //Initialize Lookup service
    lookupService: lookupService;

    // Instatitate a FileUploader component
    constructor(props: DevExFileUploaderProps) {
        super(props);
        // Need to init the file uploader element (need to figure out proper type)
        this.fileUploadElement = undefined;

        // Bind functions
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChangeParentTypeSelect = this.handleChangeParentTypeSelect.bind(this);
        this.handleChangeServiceSubTypeSelect = this.handleChangeServiceSubTypeSelect.bind(this);
        this.setLoadIndicator = this.setLoadIndicator.bind(this);
        this.enableUploadButton = this.enableUploadButton.bind(this);
        this.enableBrowseButton = this.enableBrowseButton.bind(this);
        this.handleFileUploadFailure = this.handleFileUploadFailure.bind(this);
        this.handleFileUploadSuccess = this.handleFileUploadSuccess.bind(this);
        this.handleFileUploadOnInitialize = this.handleFileUploadOnInitialize.bind(this);

        // Initialize services
        this.fileService = new FileService();
        this.lookupService = new lookupService();

        // Set default values to the state object
        this.state = {
            parentTypeId:
                (this.props && this.props.landingPage == TypeOfFileUploaded.SIA) ||
                (this.props && this.props.landingPage == TypeOfFileUploaded.Shifts)
                    ? serviceTypes.security
                    : "",
            serviceSubTypeId: "",
            fileFormatTypeId: 10, // will always be 10 for MVP release
            serviceTypeDataSource: [],
            serviceSubTypeDataSource: [],
            fileType: [],
            loadIndicatorVisible: false,
            isEnableUpload: false,
            isEnableBrowse: false,
            selectedFiles: [],
            serviceTypeloaded: false,
            serviceSubTypeloaded: false,
            billableitemuploadhdroverrideloaded: false,
            billableitemuploadhdroverrideLookup: [],
            billableitemuploadhdroverrideList: [],
            isUploadSuccessful: false
        };
    }

    // Load all lookup data
    componentDidMount() {
        this.retrieveLookupValues(LookupTypeIndexes.serviceType);
        this.retrieveLookupValues(LookupTypeIndexes.fileType);
        this.retrieveLookupValues(LookupTypeIndexes.billableitemuploadhdroverride);
    }

    componentDidUpdate(prevProps: Readonly<DevExFileUploaderProps>): void {
        if (this.props.shiftType != prevProps.shiftType && this.state.serviceSubTypeDataSource.length > 0) {
            this.fileUploadElement.reset();
            this.setState({
                serviceSubTypeId: "",
                billableitemuploadhdroverrideList: [],
                serviceSubTypeloaded: true,
                isEnableBrowse: false,
                isEnableUpload: false,
                selectedFiles: [],
                serviceSubTypeDataSource:
                    this.props && this.props.landingPage == TypeOfFileUploaded.Shifts
                        ? this.props.shiftType == "Security" ?  this.state.fileType.filter((responseItem: LookupTypeItem) => {
                              return (
                                  responseItem.id == uploadedFileType.personnel ||
                                  responseItem.id == uploadedFileType.equipment 
                              );
                          }) 
                          : this.state.fileType.filter((responseItem: LookupTypeItem) => {
                            return (
                                responseItem.id == uploadedFileType.otherServiceUploader
                            );
                        }) 
                        : this.props && this.props.landingPage == TypeOfFileUploaded.Venue ? 
                            this.state.fileType.filter((responseItem: LookupTypeItem) => {
                                return (
                                    responseItem.id == "14" ||
                                    responseItem.id == "17"
                                );
                            })
                            : this.state.fileType.filter((responseItem: LookupTypeItem) => {
                              return responseItem.id == this.props.typeId;
                          }),
            })
        }
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.serviceType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
                    .then((response) =>
                        this.setState({
                            serviceTypeloaded: true,
                            serviceTypeDataSource: [response.data.data[0]],
                        })
                    )
                    .catch((error) =>
                        this.setState({
                            serviceTypeloaded: true,
                            serviceTypeDataSource: [],
                        })
                    );
                break;
            case LookupTypeIndexes.billableitemuploadhdroverride:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.billableitemuploadhdroverride)
                    .then(this.handleSuccessItemTypeLookUp)
                    .catch((error) =>
                        this.setState({
                            billableitemuploadhdroverrideloaded: true,
                            billableitemuploadhdroverrideList: [],
                        })
                    );
                break;
            case LookupTypeIndexes.fileType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.fileType)
                    .then((response) =>
                        this.setState({
                            serviceSubTypeloaded: true,
                            fileType: response.data.data,
                            serviceSubTypeDataSource:
                                this.props && this.props.landingPage == TypeOfFileUploaded.Shifts
                                    ? this.props.shiftType == "Security" ?  response.data.data.filter((responseItem: LookupTypeItem) => {
                                          return (
                                              responseItem.id == uploadedFileType.personnel ||
                                              responseItem.id == uploadedFileType.equipment 
                                          );
                                      }) 
                                      : response.data.data.filter((responseItem: LookupTypeItem) => {
                                        return (
                                            responseItem.id == uploadedFileType.otherServiceUploader
                                        );
                                    }) 
                                    : this.props && this.props.landingPage == TypeOfFileUploaded.Venue ? 
                                        response.data.data.filter((responseItem: LookupTypeItem) => {
                                            return (
                                                responseItem.id == "14" ||
                                                responseItem.id == "17"
                                            );
                                        })
                                        : response.data.data.filter((responseItem: LookupTypeItem) => {
                                          return responseItem.id == this.props.typeId;
                                      }),
                        })
                    )
                    .catch((error) =>
                        this.setState({
                            serviceSubTypeloaded: true,
                            serviceSubTypeDataSource: [],
                        })
                    );
                break;
        }
    };

    handleSuccessItemTypeLookUp = (response: AxiosResponse<any>) => {
        var itemTypeLUItem: LookUpTypeItemForBillableItemUploadOverride;
        var itemTypeLUItemArray: LookUpTypeItemForBillableItemUploadOverride[];
        itemTypeLUItemArray = [];
        response.data.data.map((item: any) => {
            itemTypeLUItem = {
                itemTypeLookupId: parseInt(item.id),
                value: item.value,
                parentMappingId: "0",
            };
            itemTypeLUItemArray.push(itemTypeLUItem);
        });
        this.setState({
            billableitemuploadhdroverrideLookup: itemTypeLUItemArray,
        });
    };

    /**
     * Handle onValueChanged event for serviceType SelectBox component
     *
     * @param dxValueChange {SelectBoxOnValueChangedEvent} - DevExtreme event
     * @returns {void}
     */
    handleChangeParentTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent): void {
        if (this.props && this.props.landingPage == TypeOfFileUploaded.SIA) {
            this.setState({
                serviceSubTypeId: uploadedFileType.SIA,
            });
        } else if (
            this.props &&
            (this.props.landingPage == TypeOfFileUploaded.Venue ||
                this.props.landingPage == TypeOfFileUploaded.BudgetUpload || this.props.landingPage == TypeOfFileUploaded.RateMatrixUpload || 
                this.props.landingPage == TypeOfFileUploaded.PeriodMatrixUpload)
        ) {
            this.setState({
                parentTypeId: dxValueChange.value,
                serviceSubTypeId: this.props && this.props.typeId,
            });
        }
        this.enableBrowseButton();
        if (this.state.parentTypeId && this.state.serviceSubTypeId) {
            this.props.onDropdownSelection(this.state.parentTypeId.toString(), this.state.serviceSubTypeId.toString());
        }
    }

    /**
     * Handle onValueChanged event for serviceSubType SelectBox component
     *
     * @param dxValueChange {SelectBoxOnValueChangedEvent} - DevExtreme event
     * @returns {void}
     */
    handleChangeServiceSubTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent): void {
        this.setState({
            serviceSubTypeId: dxValueChange.value,
        });
        this.enableBrowseButton();
    }

    handleChangeItemTypeSelect = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setState({
            billableitemuploadhdroverrideList: dxValue.value,
        });
    };

   

    /**
     * Set visibility of the progress bar on the upload button
     *
     * @param isVisible {boolean} - make visible
     * @returns {void}
     */
    setLoadIndicator(isVisible: boolean): void {
        this.setState({ loadIndicatorVisible: isVisible });
    }

    // Set the file uploader component
    handleFileUploadOnInitialize(e: FileUploadOnInitializedEvent): void {
        this.fileUploadElement = e.component;
    }

    /**
     * Callback for file upload failure
     *
     * @param response {AxiosResponse<any>} server response
     * @returns {void}
     */
    handleFileUploadFailure(error: any): void {
        this.setLoadIndicator(false);

        // Log to AppInsights as a TRACE
        var respMessage: string = "Upload failed with response: " + JSON.stringify(error);

        if (!this.fileService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }

    /**
     * Callback for file upload success
     *
     * @param response {AxioResponse<any>}
     * @returns {void}
     */
    handleFileUploadSuccess(response: AxiosResponse<any>): void {
        //handle success
        this.fileUploadElement.reset();
        this.setLoadIndicator(false);
        // Signal to the parent page that a file has been uploaded.
        this.props.onFileUploaded();
        this.props.onFileUploadBillableItemHeaderId(response.data.data);
        // Reset the file uploader array
        this.setState({
            isEnableUpload: false,
            isUploadSuccessful: true,
            selectedFiles: [],
        });
    }

    /**
     * Submit the form data to the server.
     *
     * @param e {ButtonOnClickEvent} - DevExtreme event
     * @returns {void}
     */
    onSubmit(e: ButtonOnClickEvent): void {
        this.setLoadIndicator(true);        
        var formData = new FormData();
        formData.append(LookupTypeIndexes.serviceType, this.state.parentTypeId.toString());
        formData.append(LookupTypeIndexes.fileType, this.state.serviceSubTypeId.toString());
        formData.append(LookupTypeIndexes.fileFormatType, this.state.fileFormatTypeId.toString());
        formData.append(LookupTypeIndexes.clientType, this.state.parentTypeId.toString());
        formData.append("files[]", this.state.selectedFiles[0], this.state.selectedFiles[0].name);
        if(this.state.serviceSubTypeId.toString() == "14"){
            if(this.state.billableitemuploadhdroverrideList && this.state.billableitemuploadhdroverrideList.length > 0 ) {
                formData.append("override", this.state.billableitemuploadhdroverrideList.toString());
            }
            else {
                formData.append("override", "0");
            }        
            
            this.fileService
                .uploadV1FormData(formData)
                .then(this.handleFileUploadSuccess)
                .catch(this.handleFileUploadFailure);
        }
        else if(this.state.serviceSubTypeId.toString() == "17"){
            if(this.state.billableitemuploadhdroverrideList && this.state.billableitemuploadhdroverrideList.length > 0 ) {
                formData.append("override", this.state.billableitemuploadhdroverrideList.toString());
            }
            else {
                formData.append("override", "0");
            }        
            
            this.fileService
                .uploadV2FormData(formData)
                .then(this.handleFileUploadSuccess)
                .catch(this.handleFileUploadFailure);
        }
        else {
            if(this.state.billableitemuploadhdroverrideList && this.state.billableitemuploadhdroverrideList.length > 0 ) {
                formData.append("override", this.state.billableitemuploadhdroverrideList.toString());
            }
            else {
                formData.append("override", "0");
            }        
            
            this.fileService
                .uploadFormData(formData)
                .then(this.handleFileUploadSuccess)
                .catch(this.handleFileUploadFailure);
        }        
    }

    /**
     * Enabling Browse button
     *
     * @returns {void}
     */
    enableBrowseButton(): void {
        if (this.state.parentTypeId != "" && this.state.serviceSubTypeId != "") {
            this.setState({
                isEnableBrowse: true,
            });
            this.props.onDropdownSelection(this.state.parentTypeId.toString(), this.state.serviceSubTypeId.toString());
        }
    }

    /**
     * Enabling upload button and capture the file(s) selected
     *
     * @param e {FileUploaderOnValueChangedEvent} - DevExtreme event
     * @returns {void}
     */
    enableUploadButton(e: FileUploaderOnValueChangedEvent): void {
        this.setState({
            isEnableUpload: true,
            selectedFiles: e.value,
        });
    }
    disableDownloadButton=()=>{
        let disable= true;
        if(this.state.serviceSubTypeId){
            disable = false;
        }
       return disable;
    }

    onDownloadClick = () => {
        let serviceSubTypeId = this.state.serviceSubTypeId;
        this.fileService
            .downloadTemplate(serviceSubTypeId)
            //.then(=>this.handleDownloadSuccess)
            .then(res => this.handleDownloadSuccess(res,serviceSubTypeId))
            .catch(this.handleDownloadError);
    };

    handleDownloadSuccess = (response: AxiosResponse<any>,serviceSubTypeId:any) => {
        let fileName = 'shift_import_template.csv';
        if(serviceSubTypeId){
            if(serviceSubTypeId == '11'){
                fileName = 'template_security_personnel.csv';
            } else if(serviceSubTypeId == '12'){
              fileName = 'template_security_equipment.csv'
            } else if(serviceSubTypeId == '18'){
                fileName = 'template_service_uploader.csv'
              }
        }
        //console.log(response.headers["content-disposition"],response.headers.get('content-disposition'));
        saveAs(response.data,fileName);
        //saveAs(new Blob([response.data], { type: "application/vnd.ms-excel" }), "ShiftImportTemplate");
        // var blob = new Blob([response.data], {
        //     type: "application/vnd.ms-excel;charset=charset=utf-8"
        //   });
        //   saveAs(blob,fileName);
    };

    handleDownloadError = (error: any) => {
         // Log to AppInsights as a TRACE
         var respMessage: string = "Download template failed with response: " + JSON.stringify(error);

         if (!this.fileService.traceAsErrorToAppInsights(respMessage)) {
             // AppInsights is not available
             console.error(respMessage);
         }
    };

    render() {
        var { landingPage } = this.props;
        var uploadBtnClass: string = "position-relative btn btn--small-inline";
        var browseBtnClass: string = "";
        if (this.state.isEnableUpload) {
            uploadBtnClass += " saveCycleButtonColor";
        } 
        if(this.state.isUploadSuccessful){
            browseBtnClass = "col-lg-6 file-upload-container__file-summary";
        }
        else if((this.state.isEnableBrowse && !this.state.isEnableUpload)) {
            browseBtnClass = "col-lg-6 file-upload-container__file-summary file-upload-container__file-summary--next-action";
        }
        else {
            browseBtnClass = "col-lg-6 file-upload-container__file-summary";
        }
        return (
            <form data-testid="file-upload-form">
                <div className="container file-upload-container">
                    {landingPage == TypeOfFileUploaded.Shifts
                        ? this.props.configtext
                        : this.props.t("shiftUpload.selectionInstructions.text")}
                    <div className="row mt-3">
                        {this.props.landingPage == TypeOfFileUploaded.Shifts ? (
                            <></>
                        ) : (
                            <>
                                {" "}
                                <div className="col-lg-1 font-weight-bold">
                                    {this.props.landingPage == TypeOfFileUploaded.SIA
                                        ? this.props.t("shiftUpload.serviceDropdown.label")
                                        : "Client"}
                                </div>
                                <div className="col-lg-4">
                                    {this.props.landingPage == TypeOfFileUploaded.SIA ? (
                                        <>
                                            {this.state.serviceTypeloaded ? (
                                                <SelectBox
                                                    dataSource={this.state.serviceTypeDataSource}
                                                    displayExpr={"value"}
                                                    valueExpr={"id"}
                                                    onValueChanged={this.handleChangeParentTypeSelect}
                                                    value={this.state.parentTypeId}
                                                />
                                            ) : (
                                                <span> Loading... </span>
                                            )}
                                            <input
                                                data-testid={LookupTypeIndexes.serviceType}
                                                type="hidden"
                                                name={LookupTypeIndexes.serviceType}
                                                value={this.state.parentTypeId}
                                            ></input>{" "}
                                        </>
                                    ) : (
                                        <>
                                            <DropDownFromLookupTable
                                                lookupTypeIndex={LookupTypeIndexes.clientType}
                                                onValueChanged={this.handleChangeParentTypeSelect}
                                                signal={false}
                                                value={this.state.parentTypeId}
                                            />                                            
                                            <input
                                                data-testid={LookupTypeIndexes.clientType}
                                                type="hidden"
                                                name={LookupTypeIndexes.clientType}
                                                value={this.state.parentTypeId}
                                            ></input>{" "}
                                        </>
                                    )}
                                </div>{" "}
                            </>
                        )}
                        <div className="col-lg-1 font-weight-bold">
                            {this.props.t("shiftUpload.subServiceDropdown.label")}
                        </div>
                        <div className="col-lg-3">
                            <>
                                {this.state.serviceSubTypeloaded ? (
                                    <SelectBox
                                        dataSource={this.state.serviceSubTypeDataSource}
                                        displayExpr={"value"}
                                        valueExpr={"id"}
                                        onValueChanged={this.handleChangeServiceSubTypeSelect}
                                        value={this.state.serviceSubTypeId}
                                    />
                                ) : (
                                    <span> Loading... </span>
                                )}{" "}
                            </>
                            <input
                                data-testid={LookupTypeIndexes.fileType}
                                type="hidden"
                                name={LookupTypeIndexes.fileType}
                                value={this.state.serviceSubTypeId}
                            ></input>
                        </div>
                        {landingPage == TypeOfFileUploaded.Shifts &&
                        (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
                            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)) ? (
                            <>
                                <div className="col-lg-2 font-weight-bold">
                                    Rules Override?
                                </div>
                                <div className="col-lg-3">
                                    <>

                                    <TagBox
                                        value={this.state.billableitemuploadhdroverrideList}
                                        dataSource={this.state.billableitemuploadhdroverrideLookup}
                                        width={"100%"}
                                        valueExpr="itemTypeLookupId"
                                        displayExpr="value"
                                        showSelectionControls={true}
                                        onValueChanged={this.handleChangeItemTypeSelect}
                                        // placeholder={artistTypeText}
                                    ></TagBox>
                                        {/* <SelectBox
                                            dataSource={this.state.billableitemuploadhdroverrideDataSource}
                                            displayExpr={"value"}
                                            valueExpr={"id"}
                                            onValueChanged={this.handleChangeServiceSubTypeSelect}
                                            value={this.state.billableitemuploadhdroverrideId}
                                        /> */}
                                    </>
                                    <input
                                        data-testid={LookupTypeIndexes.fileType}
                                        type="hidden"
                                        name={LookupTypeIndexes.fileType}
                                        value={this.state.serviceSubTypeId}
                                    ></input>
                                </div>
                            </>
                        ) : null}
                        {landingPage == TypeOfFileUploaded.Shifts && <div className="col">
                            <Link
                                onClick={(e) => {
                                    this.onDownloadClick();
                                }}
                                className={`approval-query-column ${this.disableDownloadButton()?'disabled':'' }`}
                                to="#">
                                Download Template
                            </Link>
                        </div>}
                    </div>
                    <div className="dx-fileuploader-container my-3">
                        <div className="row">
                            <div className={browseBtnClass}>
                                <FileUploader
                                    selectButtonText={this.props.t("shiftUpload.fileUploader.browseButton.text")}
                                    labelText="or drop files here"
                                    uploadMode="useForm"
                                    onInitialized={this.handleFileUploadOnInitialize}
                                    onUploadAborted={(e: any) => {
                                        this.setLoadIndicator(false);
                                    }}
                                    onUploadError={(e: any) => {
                                        this.setLoadIndicator(false);
                                    }}
                                    readyToUploadMessage={this.props.t("shiftUpload.fileUploader.uploadMessage.text")}
                                    onValueChanged={this.enableUploadButton}
                                    disabled={!this.state.isEnableBrowse}
                                />
                            </div>
                            <div className="col-lg-2 file-upload-container__upload-button">
                                <div className="dx-fileuploader-input-wrapper dx-upload-button-override">
                                    <Button
                                        className={uploadBtnClass}
                                        onClick={this.onSubmit}
                                        disabled={!this.state.isEnableUpload}
                                    >
                                        <LoadIndicator
                                            id="button-indicator"
                                            className="load-indicator--centered"
                                            height={20}
                                            width={20}
                                            visible={this.state.loadIndicatorVisible}
                                        />
                                        <span className="dx-button-text">
                                            {this.props.t("shiftUpload.fileUploadButton.text")}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

// First export is for unit testing only as HoC for the default cannot be rendered in a testing framework
export { DevExFileUploader };
export default withTranslation()(DevExFileUploader);
