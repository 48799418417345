import React, { MouseEvent, Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import PaymentCycleForm from "../Payments/PaymentCycleForm";
import LightModePageTemplate from "./Templates/LightModeTemplate";

interface PaymentCycleFormPageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

interface PaymentCycleFormPageState {}

class PaymentCycleFormPage extends Component<PaymentCycleFormPageProps> {
    //Initialize the component's state.
    state: PaymentCycleFormPageState;

    constructor(props: PaymentCycleFormPageProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <LightModePageTemplate>
                <section className="container page-content">
                    <header className="grid-info mb-3">{/* TODO why is this empty? */}</header>
                    <div className="row">
                        <div className="col-md">
                            <PaymentCycleForm location={this.props.location} history={this.props.history} />
                        </div>
                        <div />
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(PaymentCycleFormPage);
