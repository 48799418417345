import React from "react";

interface MaskedStatusForProviderAcceptanceProps {
    data: any;
}

interface MaskedStatusForProviderAcceptanceState {
    providerAcceptanceStatus: string;
}

class MaskedStatusForProviderAcceptance extends React.Component<MaskedStatusForProviderAcceptanceProps> {
    state: MaskedStatusForProviderAcceptanceState;
    constructor(props: MaskedStatusForProviderAcceptanceProps) {
        super(props);
        this.state = {
            providerAcceptanceStatus: this.props.data.data.providerAcceptances ? "Yes" : "No",
        };
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <span className="status-message-color">{this.state.providerAcceptanceStatus}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default MaskedStatusForProviderAcceptance;
