import React, { MouseEvent } from "react";
import { TextArea, LoadPanel } from "devextreme-react";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { AxiosResponse } from "axios";
import outstandingActionsUtilities, {
    ContactType,
    upcomingGigDetailedData,
} from "../Outstanding-Actions/OutstandingActionsUtilities";
import OutstandingApprovalService from "../../services/OutstandingApprovalService";
import LookupService from "../../services/LookupService";
import AddEditPopUpService, { RaiseQueryFormData } from "../../services/AddEditPopUpService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import gridUtils from "../grid/GridUtilities";
import ArtistService, { ServiceSubTypeId } from "../../services/ArtistService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/pro-regular-svg-icons";
import CardWrapper from "../Cards/CardWrapper";
import sharedUtils from "../grid/sharedUtilities";

interface GigDetailedCardProps {
    location: any;
    history: any;
}
interface GigDetailedCardState {
    editViewDataItem: upcomingGigDetailedData;
    //convertedEditViewDataItem: AddEditFormData,
    errorMessage: string;
    successMessage: string;
    queryItem: RaiseQueryFormData;
    loadPanelVisible: boolean;
}
export class GigDetailedCard extends React.Component<GigDetailedCardProps> {
    state: GigDetailedCardState;
    lookupService: LookupService;
    outstandingApprovalService: OutstandingApprovalService;
    utils: outstandingActionsUtilities;
    addEditUtils: addEditPopupUtils;
    artistService: ArtistService;
    editViewService: AddEditPopUpService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    //service that would post the data back to the server.
    constructor(props: GigDetailedCardProps) {
        super(props);
        //Initialize services
        this.lookupService = new LookupService();
        this.outstandingApprovalService = new OutstandingApprovalService();
        this.utils = new outstandingActionsUtilities();
        this.addEditUtils = new addEditPopupUtils();
        this.sharedUtils = new sharedUtils();
        this.editViewService = new AddEditPopUpService();
        this.gridUtils = new gridUtils();
        //Initialize the service.
        this.artistService = new ArtistService();
        // var initializedBillableItem = this.utils.initializeBillableItem();
        var convertedQueryFormData = this.addEditUtils.initializeQueryFormData(
            this.props.location.state && this.props.location.state.id ? this.props.location.state.id.toString() : ""
        );
        this.state = {
            editViewDataItem: this.utils.initializeGigDetailedItem(this.props.location.state.id),
            //convertedEditViewDataItem: initializedBillableItem,
            errorMessage: "",
            successMessage: "",
            queryItem: convertedQueryFormData,
            loadPanelVisible: false,
        };
    }

    componentDidMount() {
        this.getCardData();
    }

    //A function that would make the API call and populate the Object which would be displayed on the cards post destructuring.
    getCardData = () => {
        this.setState({
            loadPanelVisible: true,
        });
        var id: string = this.props.location.state.id;
        var bilid: number = this.sharedUtils.convertStringToInt(id);
        if (this.props.location.state && this.props.location.state.id) {
            this.artistService
                .getEditViewData(bilid)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
        }
    };

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
        this.setState({
            editViewDataItem: response.data.data,
        });
    };

    handleStandardError = (error: any) => {
        var respMessage: string = "Upcoming detailed Gig Response failed with response: " + JSON.stringify(error);
        if (!this.editViewService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    //Arrow functions doesn't need to be bind in the constructor, the context of "this" is set inside of them.
    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                comments: dxValueChange.value,
            },
        });
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
            },
        });
    };

    handleSubmitSuccess = () => {
        this.hideLoadpanel();
    };

    validationCheckFunction = () => {
        let errorMessage: string = "";
        let commentsField: string = this.state.editViewDataItem.comments;
        if (this.sharedUtils.isBlank(commentsField)) {
            errorMessage = "Please enter the comments.";
        }

        return errorMessage;
    };

    onSubmitQuery = () => {
        let querySubmissionError: string = "";
        querySubmissionError = this.validationCheckFunction();
        if (querySubmissionError.length > 0) {
            this.setState({
                errorMessage: querySubmissionError,
            });
            // this.setState({
            //     successMessage: ""
            // });
        } else {
            this.setState({
                errorMessage: "",
                loadPanelVisible: true,
            });
            this.editViewService
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    handleQuerySubmissionSuccess = () => {
        this.hideLoadpanel();
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                comments: "",
            },
            // successMessage: "Query raised successfully. Please go back to the main page."
        });
        this.props.history.push({
            pathname: "/",
        });
    };

    handleQuerySubmissionFailure = (error: any) => {
        this.hideLoadpanel();
        var respMessage: string =
            "uploadQueryFormData - Query submission failed with response: " + JSON.stringify(error);

        if (!this.editViewService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    hideLoadpanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    isPerfInfoRequired = (data: any): boolean => {
        var showPerInfo = false;
        if (
            data.serviceSubTypeId == ServiceSubTypeId.ChildrensEntertainer ||
            data.serviceSubTypeId == ServiceSubTypeId.LiveMusic
        ) {
            showPerInfo = true;
        }
        return showPerInfo;
    };
    onClose = () => {
        this.props.history.push({
            pathname: "/",
        });
    };
    render() {
        var delimeter: string = ",";
        var { contacts, reoccurance } = this.state.editViewDataItem;
        var contactPhone: string = contacts ? (contacts.mobileTelephone ? `(${contacts.mobileTelephone})` : "") : "";
        var residencyField: string =
            reoccurance && reoccurance.toLowerCase().localeCompare("no") == 0 ? "" : `(${"Residency"})`;
        var serialNumber = this.props.location.state ? this.props.location.state.serialNo : 0;
        var totalNumber = this.props.location.state ? this.props.location.state.totalNo : 0;
        var addInfo = this.state.editViewDataItem.equipmentRequired
            ? this.state.editViewDataItem.additionalInformation +
              delimeter +
              " " +
              this.state.editViewDataItem.equipmentRequired
            : this.state.editViewDataItem.additionalInformation;
        var text =
            "For any non-urgent queries which can be followed up by the Event UK Team during normal hours, please raise your query here...";

        var service = this.state.editViewDataItem.service ? this.state.editViewDataItem.service.split("-")[0] : "";
        var serviceSubType = this.state.editViewDataItem.service
            ? this.state.editViewDataItem.service.split("-")[1]
            : "";
        var genre = this.state.editViewDataItem.genre ? this.state.editViewDataItem.genre + { delimeter } : "";
        var subTypeGenre = `${serviceSubType}${genre}`;

        var performanceInformation =
            this.state.editViewDataItem &&
            !this.sharedUtils.isBlank(this.state.editViewDataItem.performanceInformationSetsNumber) &&
            !this.sharedUtils.isBlank(this.state.editViewDataItem.performanceInformationSetsDuration)
                ? `${this.state.editViewDataItem.performanceInformationSetsNumber} X ${this.state.editViewDataItem.performanceInformationSetsDuration}`
                : null;

        var performanceInfo =
            !this.sharedUtils.isBlank(performanceInformation) && this.isPerfInfoRequired(this.state.editViewDataItem)
                ? `${performanceInformation} minutes sets`
                : null;

        return (
            <CardWrapper
                type="entertainer"
                typeTitle={service}
                enumeration={true}
                cardIndex={serialNumber}
                cardsTotal={totalNumber}
                fullHeight={false}
            >
                <div className="euk-card__content-section text-center">
                    {this.state.errorMessage != "" ? (
                        <span className="text-danger font-weight-bold">{this.state.errorMessage}</span>
                    ) : null}
                    {/* {this.state.successMessage != "" ? <span className="text-success font-weight-bold">{this.state.successMessage}</span> : null} */}
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />

                    <h4 className="euk-card__title">{this.state.editViewDataItem.venueName}</h4>
                    <div>
                        {this.state.editViewDataItem.address}
                        {delimeter} {this.state.editViewDataItem.venueTownCity}
                        {delimeter} {this.state.editViewDataItem.venuePostCode}
                    </div>
                    <span>{this.state.editViewDataItem.venue_Phone}</span>
                    <div>
                        <>
                            {`General Manager: ${contacts ? (contacts.name ? contacts.name : "") : ""} ${
                                contacts ? (contacts.mobileTelephone ? contactPhone : "") : ""
                            }`}
                        </>
                    </div>
                </div>
                <div className="euk-card__content-section--highlight text-center">
                    <div>{this.state.editViewDataItem.entertainmentDateInfo}</div>
                    <div className="gig-detail-layout__further-information">{performanceInfo}</div>
                    <div>{subTypeGenre}</div>
                    <div className="gig-detail-layout__further-information">{residencyField}</div>
                    <div className="font-weight-bold">{this.sharedUtils.thousandsSeparator(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy)}</div>
                    <hr className="euk-card__seperator" />
                    <div className="font-weight-bold">Important Information:</div>
                    <div>{addInfo}</div>
                </div>
                <div className="euk-card__content-section text-center">
                    <div className="font-weight-bold">
                        Running late or need anything urgent on the day? Please phone the venue directly.
                    </div>
                    <Link title="Terms & Conditions" to="#">
                        <span className="btn-link--inline">Terms & Conditions</span>
                    </Link>

                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                </div>
                <div className="euk-card__content-section--alt text-center">
                    <h5 className="gig-detail-layout__query-heading">Raise non-urgent query</h5>
                    <TextArea
                        height={100}
                        value={this.state.editViewDataItem.comments}
                        onValueChanged={this.handleChangeComments}
                        placeholder={text}
                    />
                    <div className="mt-2 gig-detail-layout__small-text">
                        Running late or for anything urgent on the day? Please phone venue directly.
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <button
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onSubmitQuery();
                                }}
                                className="btn btn--ghost btn--large gig-detail-layout__button mt-3 float-left"
                            >
                                Raise Query
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onClose();
                                }}
                                className="btn btn-primary btn--large gig-detail-layout__button mt-3"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </CardWrapper>
        );
    }
}
