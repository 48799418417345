import { AxiosResponse } from "axios";
import { _get, _aiTraceAsError, _post, _put } from "./BaseApiService";

const QUERY_CARDS_LIST_API = "Query";
const COMPANY_DETAIL_API = "Dashboard/company";
const QUERY_REQUEST_REMOTE_OPERATIONS = "Query/RemoteGetQueries";
const QUERY_EDIT_RESPONSE = "Query/AddEditQuery";
const RESOLVE_QUERY_API = "query/ResolveQuery";
const LINKED_QUERY_API = "Query/RemoteGetLinkedQueries";
const SAVE_LINKED_QUERY_API = "Query/LinkQueries";
export interface cardSections {
    security: queryCard[];
    cleaning: queryCard[];
    entertainment: queryCard[];
    general: queryCard[];
}

interface VirtualScrollingResponse {
    totalCount: number,
    data: any
}


export const queryCardUIType = {
    Open: "Open",
    Resolved: "Resolved",
};

export interface ResolveSubmitRequest {
    id: string,
    resolutionreason: string
}

export interface QueryFormSubmitRequest {
    id: string,
    queryStatusId: string,
    queryStatus: string,
    queryTitle: string,
    queryDescription: string,
    resolutionReason: string,
    billableItemId: string,
    dateCreated: string,
    venueId: string,
    providerBusinessEntityId: string,
    venue: string,
    venueName: string,
    comments: string,
    lastUpdate: string,
    serviceTypeId: string,
    serviceTypeValue: string,
    serviceSubTypeId: string,
    queryTypeLookupID: string,
    queryTypeLookupValue: string,
    lastUpdatedBy: string,
    businessEntityId: string,
    businessEntityTypeText: string,
    raisedByContactName: string,
    raisedByContactTelNumber: string,
    typeLookupId: string,
    typeLookupValue: string,
    queryTypeText: string,
    queryTypeEnum: number,
    activity_Comments: string,
    activity_Type: string,
    activity_LastUpdatedBy: string,
    activity_LastUpdated: string,
    activity_external: boolean,
    internal_Comment: string,
    clientId: string,
    contactId: string,
    queryClientBusinessEntityId: string,
    queryClientBusinessEntityValue: string,
    queryServiceTypeId: string,
    queryServiceTypeValue: string,
    queryRaisedTime: string,
    queryDateCreated: string,
    linkedId: string
}

export interface QueryFormResponse {
    id: string,
    queryStatusId: string,
    queryStatus: string,
    queryTitle: string,
    queryDescription: string,
    resolutionReason: string,
    billableItemId: string,
    dateCreated: string,
    venueId: string,
    providerBusinessEntityId: string,
    venue: string,
    venueName: string,
    comments: string,
    lastUpdate: string,
    serviceTypeId: string,
    serviceTypeValue: string,
    serviceSubTypeId: string,
    queryTypeLookupID: string,
    queryTypeLookupValue: string,
    lastUpdatedBy: string,
    businessEntityId: string,
    businessEntityTypeText: string,
    raisedByContactName: string,
    raisedByContactTelNumber: string,
    queryRaisedBy:string,
    typeLookupId: string,
    typeLookupValue: string,
    queryTypeText: string,
    queryTypeEnum: number,
    internal_Comment: string,
    activity: [{
        id: string,
        query_Id: string,
        activity_Comments: string,
        activity_Type: string,
        activity_LastUpdatedBy: string,
        activity_LastUpdated: string,
        activity_external: boolean,
    }],
    validationErrors: [],
    user: [{
        id: string,
        name: string
    }],
    lastUpdated_DateTime: string,
    clientId: string,
    contactId: string,
    queryClientBusinessEntityId: string,
    queryClientBusinessEntityValue: string,
    queryServiceTypeId: string,
    queryServiceTypeValue: string,
    queryRaisedTime: string,
    queryDateCreated: string,
    linkedId: string
}

export interface queryCard {
    id: string;
    queryTitle: string;
    queryDescription: string;
    contactId: string;
    contactName: string;
    contactJobTitle: string;
    companyId: string;
    company: string;
    d365LinkID: string;
    resolutionReason: string;
    billableItemId: string;
    queryStatusId: string;
    queryStatus: string;
    dateCreated: string;
    clientId: string;
    client: string;
    providerId: string;
    provider: string;
    venueId: string;
    providerBusinessEntityId: string;
    venue: string;
    venueName: string;
    city: string;
    postCode: string;
    houseNumber: string;
    comments: string;
    lastUpdate: string;
    assignedTo: string;
    serviceTypeId: string;
    serviceTypeValue: string;
    serviceSubTypeId: string;
    serviceSubTypeValue: string;
    entertainmentDateInfo: string;
    requestDateFrom: string;
    requestId: string;
    linkedId: string;
    queryTypeLookupID: string;
    queryTypeLookupValue: string;
    lastUpdatedBy: string;
    businessEntityId: string;
    businessEntityTypeText: string;
    priority: string;
    raisedByContactName: string;
    raisedByContactTelNumber: string;
    name: string;
    dateFrom: string;
    dateTo: string;
    licenseNo: string;
    typeLookupId: string;
    typeLookupValue: string;
    rate: string;
    scheduledStartTime: string;
    scheduledFinishTime: string;
    billableStartTime: string;
    billableFinishTime: string;
    check_In_Out: string;
    providerPayAmountLocal: string;
    clientBillableAmountLocal: string;
    billed: string;
    quantity: string;
    otherDescription: string;
    requestApprover: string;
    checkin_Color: string;
    checkOut_Color: string;
    checkin_Font: string;
    checkOut_Font: string;
    queryDateCreated: string;
}

export interface linkData {
    id: string;
    businessEntityId: string;
    linkTypeId: string;
    linkTypeName: string;
    linkTypeOther: string;
    link: string;
    displayLink: string;
    active: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
}

export interface teamData {
    id: string;
    profileName: string;
    profileOrder: string;
    profileRole: string;
    link: string;
    displayLink: string;
}

export interface companyInformationData {
    shortName: string;
    openingHours: string;
    webSite: string;
    officePhone: string;
    email: string;
    addressStreet1: string;
    addressStreet2: string;
    addressStreet3: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
    linkInformation: linkData[] | [];
}

export const socialMediaType = {
    Facebook: "12",
    Twitter: "15",
    YouTube: "10",
    Instagram: "13",
};

export const queryTypes = {
    GeneralQuery: "10",
    BillableItem: "11",
    BookingRequest: "12",
    ArtistSignUp: "13",
    ArtistProfileUpdate: "14",
    DoorSupervisionIncident: "15",
    CostReport: "16",
    AddJob: "19",
    PaymentRequest: "18"
}

export const queryStatusType = {
    Pending : "10",
    Assigned: "20",
    Resolved: "30",
    Closed: "40"
}

class QueryService {

    // RemoteOperations Service 
    getQueryGridRowsWithinRemoteOperations(params: string): Promise<AxiosResponse<VirtualScrollingResponse>> {
        return _get(QUERY_REQUEST_REMOTE_OPERATIONS + params);
    }
    //Constructs a dynamic URL by adding query params on the fly.
    getQueryCardList(
        clientId: string,
        venueId: string,
        status: string,
        serviceTypeId?: string
    ): Promise<AxiosResponse<any>> {
        var queryParam = [clientId, venueId, status];
        var queryParamKeys = ["clientId", "venueId", "status"];
        var query = "?";
        if (clientId || venueId || status) {
            for (var queryItem = 0; queryItem < queryParam.length; queryItem++) {
                if (queryParam[queryItem]) {
                    query += queryParamKeys[queryItem] + "=" + queryParam[queryItem] + "&";
                } else {
                    query += "";
                }
            }
            if (query && query.length > 0) {
                var queryString: string = query.charAt(query.length - 1);
                if (queryString == "&") {
                    query = query.substring(0, query.length - 1);
                }
            }
        } else {
            query = "";
        }
        if (serviceTypeId) {
            return _get(`${QUERY_CARDS_LIST_API}${query}&servicetypeid=${serviceTypeId}`);
        } else {
            return _get(`${QUERY_CARDS_LIST_API}${query}`);
        }
    }

    getQueryCardItem(Id: string): Promise<AxiosResponse<any>> {
        return _get(`${QUERY_CARDS_LIST_API}/${Id}`);
    }

    //Helper function to fetch the data for the Help scree.
    getHelpScreenData(): Promise<AxiosResponse<any>> {
        return _get(`${COMPANY_DETAIL_API}`);
    }

    postQueryRequestFormData(data: Object): Promise<AxiosResponse<any>> {
        return _post(QUERY_EDIT_RESPONSE, data);
    }

    postResolveQueryRequestFormData(data: Object): Promise<AxiosResponse<any>> {
        return _post(RESOLVE_QUERY_API, data);
    }

    linkedQueryData(linkedParams:string): Promise<AxiosResponse<any>> {
        return _get(LINKED_QUERY_API + linkedParams);
    }

    savelinkedQuery(contactId:string, data:object): Promise<AxiosResponse<any>> {
        return _put(`${SAVE_LINKED_QUERY_API}?id=${contactId}`, data);
    }

    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default QueryService;
