import React from "react";

interface MaskedPopupStatusProps {
    data: any;
}

interface MaskedPopupStatusState {
    popupStatus: string;
}

class MaskedPopupStatus extends React.Component<MaskedPopupStatusProps> {
    state: MaskedPopupStatusState;
    constructor(props: MaskedPopupStatusProps) {
        super(props);
        this.state = {
            popupStatus: this.props.data.data.isPopUpRequired ? "Yes" : "No",
        };
    }

    render() {
        var { popupStatus } = this.state;
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <span className="status-message-color">{popupStatus}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default MaskedPopupStatus;
