import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { alert } from "devextreme/ui/dialog";
import { LoadPanel, TextArea } from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { AxiosResponse } from "axios";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { Link } from "react-router-dom";
import CostReportUtils from "../CostReports/CostReportUtils";
import { RaiseQueryFormData } from "../../services/AddEditPopUpService";
import addEditPopupUtils from "./AddEditPopUpUtilities";
import sharedUtils from "./sharedUtilities";
import CostReportService from "../../services/CostReportService";
import { eventUkUserGridRowItem } from "../../services/SimpleGridService";

export interface CardQueryComponentProps {
    data: eventUkUserGridRowItem;

}
export interface CardQueryComponentState {
    // pendingIconVisible: boolean,
    // loadPanelVisible: boolean,
    // iconDisabled: boolean;
    // futureShift: boolean;
    // noIconToDisplay: boolean;
    // disableQueryIcon: boolean;
    // isBilledItem: boolean;
    status: string;
    queryId: number;
    queryItem: RaiseQueryFormData;
    disableQueryIcon: boolean;
    loadPanelVisible: boolean;
    onQueryRaising: boolean;
}

class CardQueryComponent extends React.Component<CardQueryComponentProps> {
    costReportUtils: CostReportUtils;
    state: CardQueryComponentState;
    utils: addEditPopupUtils;
    sharedUtils: sharedUtils;
    queryService: CostReportService;
    constructor(props: CardQueryComponentProps) {
        super(props);
        this.utils = new addEditPopupUtils();
        this.costReportUtils = new CostReportUtils();
        this.sharedUtils = new sharedUtils();
        this.queryService = new CostReportService();
        var convertedQueryFormData = this.utils.initializeQueryFormData(
            props.data && props.data.billableItemId ? props.data.billableItemId.toString() : "",
            "",
            "",
            "Shift / Item Status Query"
        );
        this.state = {
              status : this.props.data.statusValue,
              queryId: 0,
              queryItem: convertedQueryFormData,
              disableQueryIcon: false,
              loadPanelVisible: false,
              onQueryRaising: false
        }
        
        
    }

    componentDidMount() {
        var disableQueryIcon: boolean = false;
        var queryId: number = 0;
        if (this.props.data && this.props.data.queries && this.props.data.queries.length > 0) {
            disableQueryIcon = this.costReportUtils.disableRaiseQueryIcon(this.props.data.queries);
            queryId = parseInt(this.props.data.queries[this.props.data.queries.length - 1].case_ID);
        }
        else if(this.state.status == "Queried"){
            disableQueryIcon = true;
            queryId = this.state.queryId;
        }
        this.setState({
            disableQueryIcon: disableQueryIcon,
            queryId: queryId,
        });

        if (
            this.props.data &&
            (this.props.data.itemTypeId == itemTypes.ClientCredit ||
                this.props.data.itemTypeId == itemTypes.ClientDebit ||
                this.props.data.itemTypeId == itemTypes.ProviderCredit ||
                this.props.data.itemTypeId == itemTypes.ProviderDebit ||
                this.props.data.itemTypeId == itemTypes.Receivable ||
                this.props.data.itemTypeId == itemTypes.Payable)
        ) {
            this.setState({
                isFinancialAdjustmentView: true,
            });
        }
        if (this.props.data && this.props.data.editAllowed.toLowerCase() == "false") {
            this.setState({
                isEditIconDisable: true,
            });
        }
        if (
            ((this.props.data.itemTypeId == itemTypes.Original ||
                this.props.data.itemTypeId == itemTypes.AdjustmentClientDebit ||
                this.props.data.itemTypeId == itemTypes.AdjustmentProviderDebit) &&
                ((this.props.data.approvalDate ||
                    this.props.data.acceptDate ||
                    this.props.data.billDate) ||
                    (this.props.data.approval_Date
                        || this.props.data.accept_Date
                        || this.props.data.bill_Date))) ||
            this.props.data.statusValue == "Approved" || this.props.data.statusValue == "Approved" ||
            this.props.data.statusValue == "Accepted" || this.props.data.statusValue == "Accepted"
        ) {
            this.setState({
                isAdjustmentChange: true,
            });
        }
    }

    handleChangeQueryComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
                clientId: this.props.data.clientId,
                venueId: this.props.data.venueId,
                serviceTypeId: this.props.data.serviceTypeId
            },
        });
    };

    handleQueryOnClick = () => {
        this.setState({
            onQueryRaising: true,
        });
    };

    onQuerySubmission = () => {
        let queryDescriptionAdded: boolean = false;
        queryDescriptionAdded = this.sharedUtils.validationCheckFunction(this.state.queryItem.queryDescription);
        if (!queryDescriptionAdded) {
            alert("Please enter the query before pushing the submit button", "Raise Query");
        } else {
            this.setState({
                loadPanelVisible: true,
            });
            this.queryService
                .uploadQueryData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    };

    handleQuerySubmissionSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            onQueryRaising: false,
            disableQueryIcon: true,
            queryId: parseInt(response.data)
        });
        alert("The Query has been submitted, an Event Uk staff will be in touch soon. Thanks!", "Query Submitted");
        // this.props.data.component.cellValue(
        //     this.props.data.rowIndex,
        //     this.state.statusColumnIndexVal,
        //     "Queried"
        // );
    };

    handleQuerySubmissionFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    //Helper function to close the Query popup.
    closeQueryBox = () => {
        this.setState({
            onQueryRaising: false,
        });
    };
    render() {
        var { disableQueryIcon } = this.state;
        return (
            <>
                
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        message="Please wait..."
                        visible={this.state.loadPanelVisible}
                    />

                  
                        {" "}
                        {disableQueryIcon ? (
                            <Link
                                className="btn icon-btn-card queried-column"
                                to={{
                                    pathname: "/viewQuery",
                                    state: {
                                        queryId:
                                            this.state.queryId
                                    },
                                }}
                            >
                                 <span className="icon-btn-card__text">Queried</span>
                            </Link>
                        ) : (
                            <button className={"btn icon-btn-card"} onClick={this.handleQueryOnClick}>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                                <span className="icon-btn-card__text">Query</span>
                            </button>
                        )}
                 
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.onQueryRaising}
                    onHiding={this.closeQueryBox}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title="Raise Query"
                    maxWidth="500px"
                >
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="pending-request-panel__raise-query">
                        <div className="pending-request-panel__query-box">
                            <TextArea
                                height="100%"
                                placeholder="Please enter your query here and select Submit Query button - a member of the Event UK staff will be in touch"
                                onValueChanged={this.handleChangeQueryComments}
                            />

                            <button className="btn btn-primary btn--full-width" onClick={this.onQuerySubmission}>
                                Submit Query
                            </button>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }
}

export default CardQueryComponent;
