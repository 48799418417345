import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import lookupService, { LookupTypeItem } from "../../services/LookupService";
import { SelectBoxOnValueChangedEvent, DateBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import ScheduleService, { SchedulerItem } from "../../services/ScheduleService";
import { AxiosResponse } from "axios";
import BillingFormUtils from "../Billing/BillingFormUtils";
import sharedUtils from "../grid/sharedUtilities";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { Link } from "react-router-dom";
import { DashboardResponseCount } from "../../services/DashBoardService";
import { RequestStatus } from "../../services/FindAnArtistService";
import { LoadIndicator } from "devextreme-react";

interface BookingsOverviewProps extends WithTranslation {
    dashBoardCount: DashboardResponseCount;
    isDashBoardDataLoaded: boolean;
    serviceTypeId: string;
}
interface BookingsOverviewState {
    clientId: string;
    venueId: string;
    minDateValue: string;
    maxDateValue: string;
    schedulerData: SchedulerItem[];
    currentDate: Date;
    isLoad: boolean;
    periodMatrixData: any;
    period: string;
    week: string;
    dateFrom: Date;
    dateTo: Date;
    isShowDropDown: boolean;
}
class BookingsOverview extends React.Component<BookingsOverviewProps> {
    state: BookingsOverviewState;
    utils: sharedUtils;
    billingUtils: BillingFormUtils;
    service: ScheduleService;
    lookUp: lookupService;
    constructor(props: BookingsOverviewProps) {
        super(props);
        this.utils = new sharedUtils();
        this.service = new ScheduleService();
        this.lookUp = new lookupService();
        this.billingUtils = new BillingFormUtils();
        this.state = {
            clientId: "",
            venueId: "",
            minDateValue: "",
            maxDateValue: "",
            schedulerData: [],
            currentDate: new Date(),
            isLoad: true,
            periodMatrixData: {},
            period: "",
            week: "",
            dateFrom: new Date(),
            dateTo: new Date(),
            isShowDropDown: false,
        };
    }

    handleStandardError = () => {
        this.setState({
            isLoad: false,
        });
    };


    dropDownToShow = (venueDataSource: LookupTypeItem[]) => {
        if (
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)
        ) {
            this.setState({
                isShowDropDown: true,
            });
        } else if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            if (venueDataSource.length > 1) {
                this.setState({
                    isShowDropDown: true,
                });
            }
        }
    };
    handleSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data) {
            let data: SchedulerItem[] = response.data.data;
            let convertedDataPoints: SchedulerItem[] = [];
            // Build the data points
            data.forEach((item) => {
                convertedDataPoints.push({
                    count: item.count,
                    serviceType: item.serviceType,
                    serviceSubType: item.serviceSubType,
                    shiftStart: this.billingUtils.parseTimestamp(Date.parse(item.shiftStart.toString())),
                    shiftEnd: this.billingUtils.parseTimestamp(Date.parse(item.shiftEnd.toString())),
                    clientId: this.state.clientId,
                    venueId: this.state.venueId,
                });
            });
            // Save data points to state
            this.setState({
                schedulerData: convertedDataPoints,
                isLoad: false,
            });
        } else {
            this.setState({
                schedulerData: [],
                isLoad: false,
            });
        }
    };

    handleSuccesPeriodMatrixLookUp = (response: AxiosResponse<any>) => {
        if (response != null && response.data != null && response.data.data && response.data.data.length > 0) {
            this.setState({
                periodMatrixData: response.data.data ? response.data.data : [],
            });
            if (!this.isBlank(this.state.clientId)) {
                let currentPeriodData = this.billingUtils.getTodaysPeriodWeekAndDatesByClient(
                    this.state.clientId,
                    this.state.periodMatrixData,
                    this.state.currentDate
                );
                if (
                    currentPeriodData &&
                    currentPeriodData.dateFrom != undefined &&
                    currentPeriodData.dateTo != undefined
                ) {
                    this.setState({
                        period: currentPeriodData.period,
                        week: currentPeriodData.week,
                        dateFrom: currentPeriodData.dateFrom,
                        dateTo: currentPeriodData.dateTo,
                    });
                }
            }
        }
        this.setState({
            isLoad: false,
        });
    };





    handleFailure = (error: any) => {
        let respMessage: string = "getScheduleData failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            schedulerData: "",
            isLoad: false,
        });
    };
    handleChangePeriod = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        let firstWeekValue = "1";
        this.setState({
            period: dxValueChange.value,
            week: firstWeekValue,
            dateFrom: this.billingUtils.getDateFromPeriodMatrixTable(
                true,
                this.state.periodMatrixData,
                this.state.clientId,
                dxValueChange.value,
                firstWeekValue
            ),
            dateTo: this.billingUtils.getDateFromPeriodMatrixTable(
                false,
                this.state.periodMatrixData,
                this.state.clientId,
                dxValueChange.value,
                firstWeekValue
            ),
        });
    };

    handleChangeWeek = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            week: dxValueChange.value,
            dateFrom: this.billingUtils.getDateFromPeriodMatrixTable(
                true,
                this.state.periodMatrixData,
                this.state.clientId,
                this.state.period,
                dxValueChange.value
            ),
            dateTo: this.billingUtils.getDateFromPeriodMatrixTable(
                false,
                this.state.periodMatrixData,
                this.state.clientId,
                this.state.period,
                dxValueChange.value
            ),
        });
    };

    handleStartDateChange = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            dateFrom: dxValueChange.value,
        });
        this.handleChangeCalFromSelect(this.state.dateFrom);
    };
    handleChangeCalFromSelect = (date: Date) => {
        let currentPeriodData = this.billingUtils.getTodaysPeriodWeekAndDatesByClient(
            this.state.clientId,
            this.state.periodMatrixData,
            date
        );
        this.setState({
            period: currentPeriodData.period,
            week: currentPeriodData.week,
            dateFrom: currentPeriodData.dateFrom,
            dateTo: currentPeriodData.dateTo,
        });
    };
    handleFinishDateChange = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            dateTo: dxValueChange.value,
        });
    };
    isBlank(param: string) {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    }
    onAppointmentFormOpening(data: any) {
        let form = data.form;
        form.option("items", [{}]);
        let shiftStart = data.appointmentData.shiftStart;
        let shiftEnd = data.appointmentData.shiftEnd;

        let formattedStartTime =
            [shiftStart.getMonth() + 1, shiftStart.getDate(), shiftStart.getFullYear()].join("-") +
            " " +
            [shiftStart.getHours(), shiftStart.getMinutes()].join(":");
        let formattedEndTime =
            [shiftEnd.getMonth() + 1, shiftEnd.getDate(), shiftEnd.getFullYear()].join("-") +
            " " +
            [shiftEnd.getHours(), shiftEnd.getMinutes()].join(":");

        window.location.href =
            "/manageviewshift/?clientId=" +
            data.appointmentData.clientId +
            "&venueId=" +
            data.appointmentData.venueId +
            "&shiftStart=" +
            formattedStartTime +
            "&shiftEnd=" +
            formattedEndTime +
            "&isSchedulePage=" +
            true;
    }



    render() {

        const bookingsOverviewEntData = [
            {
                key: 1,
                pathname: "/gigRequest",
                state: { statusToShow: RequestStatus.Pending, navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestPending,
                text: "New Requests",
                style: "new-requests"
            },
            {
                key: 2,
                pathname: "/gigRequest",
                state: { statusToShow: RequestStatus.Published, navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestPublished,
                text: "Advertised",
                style: "advertised"
            },
            {
                key: 3,
                pathname: "/gigRequest",
                state: { statusToShow: RequestStatus.OptionsSent, navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestOptionsSent,
                text: "Options Sent",
                style: "optionSent"
            },
            {
                key: 4,
                pathname: "/gigRequest",
                state: { statusToShow: RequestStatus.Shortlisted, navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestShortlisted,
                text: "Shortlisted",
                style: "shortlisted"
            },
            {
                key: 5,
                pathname: "/gigRequest",
                state: { statusToShow: RequestStatus.Offered, navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestOffered,
                text: "Offered",
                style: "offered"
            },
            {
                key: 6,
                pathname: "/gigRequest",
                state: { statusToShow: "Confirm", navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestConfirming,
                text: "Confirming",
                style: "confirming"
            },
            {
                key: 7,
                pathname: "/gigRequest",
                state: { statusToShow: "reConfirm", navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestReConfirm,
                text: "To Re-Confirm",
                style: "re-confirm"
            }
        ];
        const bookingsOverviewOtherData = [
            {
                key: 1,
                pathname: "/gigRequest",
                state: { statusToShow: RequestStatus.Pending, navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestPending,
                text: "New Requests",
                style: "new-requests"
            },
            {
                key: 2,
                pathname: "/gigRequest",
                state: { statusToShow: RequestStatus.OptionsSent, navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestOptionsSent,
                text: "Options Sent",
                style: "optionSent"
            },
            {
                key: 3,
                pathname: "/gigRequest",
                state: { statusToShow: "Confirm", navFrom: "bookingsOverview", serviceType: this.props.serviceTypeId },
                number: this.props.dashBoardCount.gigRequestConfirming,
                text: "Confirming",
                style: "confirming"
            },
        ];
        return (
            <>
                {this.props.isDashBoardDataLoaded ? (
                    <div className="home-page-card__bookings-overview-container">
                        { this.props.serviceTypeId == "30" ?
                            bookingsOverviewEntData.map(item =>
                                <Link key={item.key}
                                    to={{
                                        pathname: item.pathname,
                                        state: item.state,
                                    }}
                                    className={`home-page-card__bookings-overview-item home-page-card__bookings-overview-item--${item.style}`}
                                >
                                    <span className="home-page-card__bookings-overview-item__stat">{item.number}</span>
                                    <span className="home-page-card__bookings-overview-item__stat-title">{item.text}</span>
                                </Link>
                            ) : 
                            bookingsOverviewOtherData.map(item =>
                                <Link key={item.key}
                                    to={{
                                        pathname: item.pathname,
                                        state: item.state,
                                    }}
                                    className={`home-page-card__bookings-overview-item home-page-card__bookings-overview-item--${item.style}`}
                                >
                                    <span className="home-page-card__bookings-overview-item__stat">{item.number}</span>
                                    <span className="home-page-card__bookings-overview-item__stat-title">{item.text}</span>
                                </Link>
                            )
                        }
                    </div>
                ) : (
                    <LoadIndicator
                        id="button-indicator"
                        height={60}
                        width={60}
                        className="home-page__todayloader"
                        visible={true}
                    />
                )}
            </>
        );
    }
}

export default withTranslation()(BookingsOverview);
