import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DevExFileUploader from "../file/DevExFileUploader";
import UploadFileListGrid from "../grid/UploadFileListGrid";
import { Button } from "devextreme-react";
import BillableItemHeaderService, {
    TypeOfFileUploaded,
    fileListGridType,
} from "../../services/BillableItemHeaderService";

// Props
interface OtherFileUploaderComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    landingPage: string;
    typeId?: string; // TypeId props specifies which value the type dropdown should hold.
    landingPageTab: string;
}

// State - refreshSignal used to determine when a grid refresh is required
//State property - gridVisibilitySignal used to determine when to display the Upload file list grid.
interface OtherFileUploaderComponentState {
    refreshSignal: boolean;
    gridVisibilitySignal: boolean;
    hideHeader: boolean;
    serviceType: string;
    subServiceType: string;
    landingPage: string;
    selectedPendingButton: boolean;
    selectedSubmittedButton: boolean;
    parentGridType: string;
}

// Component - displays the file upload component and the two grids that show file data
class OtherFileUploaderComponent extends Component<OtherFileUploaderComponentProps> {
    state: OtherFileUploaderComponentState;
    service: BillableItemHeaderService;
    constructor(props: OtherFileUploaderComponentProps) {
        super(props);
        this.service = new BillableItemHeaderService();
        this.onFileUploaded = this.onFileUploaded.bind(this);
        this.onDropdownSelection = this.onDropdownSelection.bind(this);
        this.state = {
            refreshSignal: false,
            gridVisibilitySignal: false,
            hideHeader: true,
            serviceType: "",
            subServiceType: "",
            landingPage: "",
            selectedPendingButton: true,
            selectedSubmittedButton: false,
            parentGridType: fileListGridType.Pending,
        };
    }

    // DevExFileUploader child component will signal via this callback when a file has been uploaded
    onFileUploaded() {
        // This refresh signal will toggle between true and false to indicate a refresh is required.
        this.setState({
            refreshSignal: !this.state.refreshSignal,
            parentGridType: fileListGridType.Pending,
            selectedPendingButton: true,
            selectedSubmittedButton: false,
        });
    }
    onRefresh = () => {
        this.setState({
            refreshSignal: !this.state.refreshSignal,
        });
    };

    //The gridVisibilitySignal will be set to true when the values in the dropdowns are populated.
    onDropdownSelection(serviceId: string, serviceSubTypeId: string) {
        if (serviceId && serviceSubTypeId) {
            this.setState({
                gridVisibilitySignal: true,
                hideHeader: false,
                serviceType: serviceId,
                subServiceType: serviceSubTypeId,
            });
        }
    }

    onFileUploadBillableItemHeaderId = () => {
        return "";
    };

    //A helper function that would toggle the state of the button to refresh the contents of the Parent Grid.
    toggleButtonStates = (toggleButtonState: boolean) => {
        this.setState({
            selectedPendingButton: toggleButtonState,
            selectedSubmittedButton: !toggleButtonState,
            parentGridType: toggleButtonState ? fileListGridType.Pending : fileListGridType.Submitted,
        });
    };

    setFilter = () => {};
    onShowStatusOfFile = () => {
        return "";
    };

    render() {
        var { landingPage } = this.props;
        var pageHeader: string =
            landingPage == TypeOfFileUploaded.Shifts
                ? "Door Supervision - Shift Import"
                : "Please upload new files here";
        return (
            <>
                <div
                    className={`page-content${
                        landingPage == TypeOfFileUploaded.Shifts ? "--with-sidebar" : ""
                    } container--small-margins`}
                >
                    <h3 className="sr-only">Upload Shifts</h3>

                    <div className="row">
                        <div className="col-md">
                            <h4 className="font-weight-bold">{pageHeader}</h4>
                            <DevExFileUploader
                                onFileUploaded={this.onFileUploaded}
                                onDropdownSelection={this.onDropdownSelection}
                                landingPage={landingPage}
                                typeId={this.props.typeId}
                                onFileUploadBillableItemHeaderId={this.onFileUploadBillableItemHeaderId}
                            />
                        </div>
                    </div>
                    <Button 
                    className="btn btn-primary view-queries-header__refresh-button ml-2" 
                    onClick={this.onRefresh}
                    >
                        <span className="dx-button-text">Refresh</span>
                    </Button>
                    <h5 className="font-weight-bold pt-2" hidden={this.state.hideHeader}>
                        Previous Files
                    </h5>
                    <div>
                        {landingPage == TypeOfFileUploaded.Shifts &&
                        this.state.serviceType &&
                        this.state.subServiceType ? (
                            <div className="view-queries-header__controls">
                                <div className="view-queries-header__view-buttons">
                                    <button
                                        className={`btn
                                        view-queries-header__view-button ${
                                            this.state.selectedPendingButton
                                                ? "view-queries-header__view-button--selected"
                                                : ""
                                        }`}
                                        onClick={() => this.toggleButtonStates(true)}
                                    >
                                        Pending
                                    </button>
                                    <button
                                        className={`btn
                                        view-queries-header__view-button ${
                                            this.state.selectedSubmittedButton
                                                ? "view-queries-header__view-button--selected"
                                                : ""
                                        }`}
                                        onClick={() => this.toggleButtonStates(false)}
                                    >
                                        Submitted
                                    </button>
                                </div>
                                <Button
                                    className="btn btn-primary view-queries-header__refresh-button"
                                    onClick={this.onFileUploaded}
                                >
                                    <span className="dx-button-text">Refresh</span>
                                </Button>
                            </div>
                        ) : (
                            <></>
                        )}
                        {this.state.serviceType && this.state.subServiceType ? (
                            <UploadFileListGrid
                                refreshSignal={this.state.refreshSignal}
                                gridVisibilitySignal={this.state.gridVisibilitySignal}
                                service={this.state.serviceType}
                                subService={this.state.subServiceType}
                                landingPage={this.props.landingPage}
                                gridType={this.state.parentGridType}
                                onShowStatusOfFile={this.onShowStatusOfFile}
                                pointOfNavigation = "Other File Submission"
                            />
                        ) : null}
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(OtherFileUploaderComponent);
