import { ResidencyFormResponse, ResidencyFormRequest } from "../../services/EntertainmentResidencyService";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import { AxiosResponse } from "axios";
import React from "react";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;
export interface ResidencyUtilProps {}
export interface AddEditResidencyData {
    ResidenciesId: string;
    ClientId: string;
    ServiceTypeId: string;
    ServiceSubTypeId: string;
    ProviderId: string;
    VenueId: string;
    IsActive: string;
    Rate: number | undefined;
    DateFrom: NullableDate;
    DateTo: NullableDate;
    DayId: string;
    StartTime: NullableDate;
    EndTime: NullableDate;
    FrequencyId: string;
    OverrideFee: number | undefined;
    clientNet: number | undefined;
    typeLookUpId: string;
    feeOverrideFlag: string;
    LastUpdatedOn: string;
    LastUpdatedBy: string;
    internalComments: string;
}

export interface LookUpDetailsInformation {
    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    venueLookUp: LookupTypeItem[];
    providerLookUp: LookupTypeItem[];
    showProgressIndicator: boolean;
}
export default class ResidencyFormUtil extends React.Component<ResidencyUtilProps> {
    utils: sharedUtils;
    dropdownService: lookupService;
    state: LookUpDetailsInformation;
    constructor(props: ResidencyUtilProps) {
        super(props);
        this.utils = new sharedUtils();
        this.dropdownService = new lookupService();
        this.state = {
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            venueLookUp: [],
            providerLookUp: [],
            showProgressIndicator: false,
        };
        this.initializeResidencyFormItem = this.initializeResidencyFormItem.bind(this);
        this.convertResidencyItemResponeToFormData = this.convertResidencyItemResponeToFormData.bind(this);
    }

    initializeResidencyFormItem(idFromGrid: string): AddEditResidencyData {
        var formData: AddEditResidencyData = {
            ResidenciesId: "0",
            ClientId: "",
            ServiceTypeId: "30",
            ServiceSubTypeId: "",
            ProviderId: "",
            VenueId: "",
            IsActive: "True",
            Rate: undefined,
            OverrideFee: undefined,
            clientNet: undefined,
            DateFrom: undefined,
            DateTo: undefined,
            DayId: "",
            StartTime: null,
            EndTime: null,
            FrequencyId: "11",
            typeLookUpId: "",
            feeOverrideFlag: "False",
            LastUpdatedOn: "",
            LastUpdatedBy: "",
            internalComments: "",
        };

        return formData;
    }

    // Convert the server response into the component's form state
    convertResidencyItemResponeToFormData(serverResponse: ResidencyFormResponse): AddEditResidencyData {
        var formData: AddEditResidencyData = {
            ResidenciesId: serverResponse.residenciesId,
            ClientId: serverResponse.clientId,
            ServiceTypeId: serverResponse.serviceTypeId,
            ServiceSubTypeId: serverResponse.serviceSubTypeId,
            ProviderId: serverResponse.providerId,
            VenueId: serverResponse.venueId,
            IsActive: serverResponse.isActive,
            Rate: this.utils.convertStringToFloat(serverResponse.rate),
            OverrideFee: this.utils.convertStringToFloat(serverResponse.feeOverride),
            clientNet: this.utils.convertStringToFloat(serverResponse.clientNet),
            DateFrom: this.utils.convertStringToDate(serverResponse.dateFrom),
            DateTo: this.utils.convertStringToDate(serverResponse.dateTo),
            DayId: serverResponse.dayId,
            StartTime: this.utils.convertStringToTime(serverResponse.startTime),
            EndTime: this.utils.convertStringToTime(serverResponse.endTime),
            FrequencyId: serverResponse.frequencyId,
            typeLookUpId: serverResponse.typeLookUpId,
            feeOverrideFlag: serverResponse.feeOverrideFlag,
            LastUpdatedOn: serverResponse.lastUpdatedon,
            LastUpdatedBy: serverResponse.lastUpdatedBy,
            internalComments: serverResponse.internalComments,
        };
        return formData;
    }

    // Convert the form's data into a proper item submit request
    convertFormDataToResidencyItemRequest(formData: AddEditResidencyData): ResidencyFormRequest {
        var submitRequest: ResidencyFormRequest = {
            residenciesId: formData.ResidenciesId,
            clientId: formData.ClientId,
            isActive: formData.IsActive,
            rate: formData.Rate ? this.utils.convertNumberToString(formData.Rate) : "",
            feeOverride: formData.OverrideFee ? this.utils.convertNumberToString(formData.OverrideFee) : "",
            clientNet: formData.clientNet ? this.utils.convertNumberToString(formData.clientNet) : "",
            dateFrom: this.utils.convertDateToString(formData.DateFrom),
            dateTo: this.utils.convertDateToString(formData.DateTo),
            dayId: formData.DayId,
            startTime: this.utils.convertTimeToString(formData.StartTime),
            endTime: this.utils.convertTimeToString(formData.EndTime),
            frequencyId: formData.FrequencyId,
            feeOverrideFlag: formData.feeOverrideFlag,
            typeLookUpId: formData.typeLookUpId,
            internalComments: formData.internalComments,
            serviceTypeId: formData.ServiceTypeId,
            serviceSubTypeId: formData.ServiceSubTypeId,
            providerId: formData.ProviderId,
            venueId: formData.VenueId,
        };
        return submitRequest;
    }
}
