import React, { MouseEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

interface BillableItemCellComponentProps {
    data: any;
    location: any;
    history: any;
}

interface BillableItemCellComponentState {
    billableItemId: string;
    isVisibleManageShiftPopup: boolean;
}

class BillableItemCell extends React.Component<BillableItemCellComponentProps> {
    state: BillableItemCellComponentState;

    constructor(props: BillableItemCellComponentProps) {
        super(props);
        this.state = {
            billableItemId: this.props.data.data.originatingBillableItemId,
            isVisibleManageShiftPopup: false,
        };
    }

    componentDidMount() {}

    closeManageShiftPopup = () => {
        this.setState({
            isVisibleManageShiftPopup: false
        });
    }

    hidePopup = (isPopupVisible: boolean) => {
        this.setState({
            isVisibleManageShiftPopup: isPopupVisible
        });
    } 
    
    saveBillableItemId = () => {
        localStorage.setItem("BillableIdFromPayment", this.state.billableItemId);
    }

    render() {
        let disablerelatedItemIcon: boolean = this.state.billableItemId !== "" ? false : true;        
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    { !disablerelatedItemIcon ? 
                        <Link
                            title="Edit"
                            className={(false ? "disabled " : "") + "icon-btn"}
                            onClick={this.saveBillableItemId}
                            to={{
                                pathname: "/AddEditFormV2",
                                state: {
                                    id: this.state.billableItemId,
                                },
                            }}
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faFileAlt} />
                        </Link> : 
                        <button
                            className={"disabled icon-btn without-border-button"}
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                            }}
                            disabled={disablerelatedItemIcon}
                        >
                            <FontAwesomeIcon icon={faFileAlt} />
                        </button>
                    }
                </div>
            </div>
        );
    }
}

export default BillableItemCell;
