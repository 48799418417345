import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateBox, LoadPanel, Popup, TextArea } from "devextreme-react";
import BaseActionCell, { BaseActionCellProps } from "../GridCells/BaseActionCell";
import { faHandshake } from "@fortawesome/pro-light-svg-icons";
import { faHandshake as faSolidHandShake } from "@fortawesome/pro-solid-svg-icons";
import FindAnArtistService from "../../services/FindAnArtistService";
import { DateBoxOnValueChangedEvent, TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { AxiosResponse } from "axios";
import notify from "devextreme/ui/notify";



class GigRequestReConfirmedCell extends BaseActionCell {
    service: FindAnArtistService;
    constructor(props: BaseActionCellProps) {
        super(props);
        this.service = new FindAnArtistService();

    }
    closeReConfirmBox = () => {
        this.setState({
            onReConfirm: false,
        });
    };

    onClickHandShakeBtn = () => {
        this.setState({
            onReConfirm: true,
            errorMessage: []
        });
    };

    removeReConfirmClick = () => {
        this.showLoadPanel();
        let submitRequest = this.findAnArtistUtils.mapReConfirmRequest(this.props.data.data.id, false, null, this.state.internalComments, this.props.data.data.featured, this.props.data.data.reoccurrence, this.props.data.data.serviceTypeLookUp.id.toString(), this.props.data.data.clientBusinessEntity.id.toString());
        this.service.updateArtistRequest(this.props.data.data.id, submitRequest)
            .then(this.handlePostReConfirmSuccess)
            .catch((error) => {
                let respMessage: string = "Re Confirmed failed with response: " + JSON.stringify(error);
                this.hideLoadPanel();
                notify({ message: "Sorry! The Re Confirmation failed", width: 300, shading: true }, 'error', 600);
                if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                    console.error(respMessage);
                }
            });
    }
    handlePostReConfirmSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            gridReConfirmDate: response.data.data.reConfirmDateTime,
            gridReConfirmStatus: response.data.data.isReConfirmed
        });
        this.closeReConfirmBox();
        notify({ message: "Thank you", width: 300, shading: true }, 'success', 600);
    };


    handleReConfirmOnClick = () => {
        this.showLoadPanel();
        let submitRequest = this.findAnArtistUtils.mapReConfirmRequest(this.props.data.data.id, true, this.state.reConfirmationDate, this.state.internalComments, this.props.data.data.featured, this.props.data.data.reoccurrence, this.props.data.data.serviceTypeLookUp.id.toString(), this.props.data.data.clientBusinessEntity.id.toString());
        this.service.updateArtistRequest(this.props.data.data.id, submitRequest)
            .then(this.handlePostReConfirmSuccess)
            .catch((error) => {
                if (error.response && error.response.data.error) {
                    this.setState({
                        errorMessage: JSON.parse(JSON.stringify(error.response.data.error))
                    });
                }
                else {
                    let respMessage: string = "Re Confirmed failed with response: " + JSON.stringify(error);
                    notify({ message: "Sorry! Re Confirmation failed", width: 300, shading: true }, 'error', 600);
                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                }
                this.hideLoadPanel();
            });
    }

    handleChangeInternalComment = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            internalComments: dxValueChange.value
        });
    };

    handleChangeReConfirmDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState(
            {
                reConfirmationDate: dxValueChange.value
            }
        );
    };

    render() {
        return (
            <>
                <>
                    <div className="d-flex justify-content-between align-items-center">
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            message="Please wait..."
                            visible={this.state.loadPanelVisible}
                        />
                        <button className={"btn icon-btn"} onClick={this.onClickHandShakeBtn}>
                            {this.state.gridReConfirmStatus ?
                                <FontAwesomeIcon icon={faSolidHandShake} /> : <FontAwesomeIcon icon={faHandshake} />}
                        </button>
                        {this.state.gridReConfirmDate}
                    </div>
                    <Popup
                        wrapperAttr={{
                            class: "pending-request-panel__raise-query-pop-up",
                        }}
                        visible={this.state.onReConfirm}
                        onHiding={this.closeReConfirmBox}
                        dragEnabled={false}
                        hideOnOutsideClick={false}
                        showTitle={true}
                        showCloseButton={true}
                        title="Please Confirm date and update comment (as reqd.)"
                        maxWidth="600px"
                        height="50%"
                    >
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <div className="pending-request-panel__raise-query">
                            <div className="pending-request-panel__query-box">
                                {this.state.errorMessage && this.state.errorMessage.length > 0 && this.state.errorMessage != null ? (
                                    <span className="unscheduled-shift">
                                        <ul>
                                            {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                                return (
                                                    <li key={uniqueKey}>
                                                        {item.columnName}: {item.errorMessage}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </span>
                                ) : null}
                                <div className="card-form__subheading ">Internal Comments
                                    <sup className="card-form__mandatory-symbol">*</sup></div>
                                <TextArea
                                    onValueChanged={this.handleChangeInternalComment}
                                    value={this.state.internalComments}
                                    height="50%"
                                />
                                <div className="card-form__subheading mt-4">
                                    Date Confirmed?
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <DateBox
                                        onValueChanged={this.handleChangeReConfirmDate}
                                        value={
                                            this.state.reConfirmationDate
                                                ? this.state.reConfirmationDate
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                    ></DateBox></div>
                                <div>
                                    <div className="row">
                                        <div className={this.state.gridReConfirmStatus ? "col-12 col-lg-6" : "col-12 col-lg-612"} >
                                            <button className="btn btn-primary btn--large" onClick={this.handleReConfirmOnClick}>
                                                Re-Confirm
                                            </button>
                                        </div>
                                        <div className="col-12 col-lg-6" hidden={!this.state.gridReConfirmStatus}>
                                            <button className="btn btn--ghost btn--ghost--teal btn--large" onClick={this.removeReConfirmClick}>
                                                Remove Confirm
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Popup>
                </>
            </>
        );
    }
}
export default GigRequestReConfirmedCell;