import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import BaseActionCell, { BaseActionCellProps } from "../GridCells/BaseActionCell";
import { Link } from "react-router-dom";

class MatchIdActionCell extends BaseActionCell {
    constructor(props: BaseActionCellProps) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <div className="d-flex align-items-center">
                    <div className="mr-2">
                    </div>
                    { this.props.data.data.paymentRequestStatusLookUp.id !== 10 && this.props.data.data.paymentRequestStatusLookUp.id !== 61 ?
                        <div className="btn icon-btn">
                            <Link
                                to={
                                    {
                                        pathname: "/MatchedPopUpPage",
                                        state: {
                                            id: this.props.data.data.id.toString(),
                                        },
                                    }
                                }
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </Link>
                        </div> :
                        <span className="disabled-icon"><FontAwesomeIcon icon={faSearch}/></span>
                    }
                </div>
            </>
        );
    }
}

export default MatchIdActionCell;
