import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { Status } from "../../services/AddEditPopUpService";
import { LoadPanel } from "devextreme-react";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { Link } from "react-router-dom";
import GlobalService from "../../services/GlobalService";
import { eventUkUserGridRowItem, GridTypes } from "../../services/SimpleGridService";
import CostReportUtils from "../CostReports/CostReportUtils";

export interface CardViewComponentProps {
    data: eventUkUserGridRowItem;

}

export interface CardViewComponentState {
     pendingIconVisible: boolean;
     loadPanelVisible: boolean;
     isFinancialAdjustmentView: boolean;
    // loadPanelVisible: boolean,
    // iconDisabled: boolean;
    // futureShift: boolean;
    // noIconToDisplay: boolean;
    // disableQueryIcon: boolean;
    // isBilledItem: boolean;
}

class CardViewComponent extends React.Component<CardViewComponentProps> {
    state: CardViewComponentState;
    costReportUtils: CostReportUtils;
    constructor(props: CardViewComponentProps) {
        super(props);
        this.costReportUtils = new CostReportUtils();
        this.state = {
            pendingIconVisible : false,
            loadPanelVisible : false,
            isFinancialAdjustmentView: false
        }
    }

    componentDidMount() {
        var disableQueryIcon: boolean = false;
        var noIcon: boolean = false;
        const isEventUk =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        const isProvider = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler);
        const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
        var pendingIconVisible = this.state.pendingIconVisible;
        var futureShift: boolean = false;
        var tenseIdentifier: string = this.props.data.tenseFlag;
        var statusId: string = this.props.data.statusId;
        var approvalDate: string = this.props.data.approvalDate;
        var acceptanceDate: string = this.props.data.acceptDate;
        var itemTypeId: string =
            this.props && this.props.data && this.props.data ? this.props.data.itemTypeId : "";
        var iconDisabled: boolean = false;
        var isBilledItem: boolean = false;
        if (tenseIdentifier.trim().toLowerCase() == "future") {
            iconDisabled = true;
            futureShift = true;
        } else {
            if (
                (tenseIdentifier.trim().toLowerCase() == "past" &&
                    isEventUk &&
                    statusId &&
                    statusId == Status.Pending &&
                    this.props.data.statusValue == "Pending") ||
                (isEventUk &&
                    statusId &&
                    statusId == Status.PendingAcceptance &&
                    this.props.data.statusValue == "Pending Acceptance")
            ) {
                pendingIconVisible = true;
            } else if (
                (statusId && statusId == Status.Approved) ||
                (statusId && statusId == Status.Accepted) ||
                this.props.data.statusValue == "Approved" ||
                this.props.data.statusValue == "Accepted" ||
                approvalDate ||
                acceptanceDate
            ) {
                iconDisabled = true;
                pendingIconVisible = false;
            } else if (this.props.data.invoiceId || this.props.data.paymentId) {
                iconDisabled = true;
                pendingIconVisible = false;
                isBilledItem = true;
            } else if (
                (isVenueManager && itemTypeId && itemTypeId == itemTypes.AdjustmentProviderDebit) ||
                (isProvider &&
                    itemTypeId &&
                    itemTypeId == itemTypes.AdjustmentClientDebit &&
                    tenseIdentifier.trim().toLowerCase() == "past")
            ) {
                iconDisabled = true;
                pendingIconVisible = true;
            } else {
                noIcon = true;
            }
        }
        if (this.props.data && this.props.data.queries && this.props.data.queries.length > 0) {
            disableQueryIcon = this.costReportUtils.disableRaiseQueryIcon(this.props.data.queries);
        }
        this.setState({
            disableQueryIcon: disableQueryIcon,
            futureShift: futureShift,
            pendingIconVisible: pendingIconVisible,
            iconDisabled: iconDisabled,
            noIconToDisplay: noIcon,
            isBilledItem: isBilledItem,
        });

        if (
            this.props.data &&
            (this.props.data.itemTypeId == itemTypes.ClientCredit ||
                this.props.data.itemTypeId == itemTypes.ClientDebit ||
                this.props.data.itemTypeId == itemTypes.ProviderCredit ||
                this.props.data.itemTypeId == itemTypes.ProviderDebit ||
                this.props.data.itemTypeId == itemTypes.Receivable ||
                this.props.data.itemTypeId == itemTypes.Payable ||
                this.props.data.itemTypeId == itemTypes.AdjustmentClientDebitToProviderCredit ||
                this.props.data.itemTypeId == itemTypes.AdjustmentProviderDebitToClientCredit)
        ) {
            this.setState({
                isFinancialAdjustmentView: true,
            });
        }
        if (this.props.data && this.props.data.editAllowed.toLowerCase() == "false") {
            this.setState({
                isEditIconDisable: true,
            });
        }
        if (
            ((this.props.data.itemTypeId == itemTypes.Original ||
                this.props.data.itemTypeId == itemTypes.AdjustmentClientDebit ||
                this.props.data.itemTypeId == itemTypes.AdjustmentProviderDebit) &&
                (this.props.data.approvalDate ||
                    this.props.data.acceptDate ||
                    this.props.data.billDate)) ||
            this.props.data.statusValue == "Approved" ||
            this.props.data.statusValue == "Accepted"
        ) {
            this.setState({
                isAdjustmentChange: true,
            });
        }
    }

    render() {
        var isEventUser =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
            var gridType = GlobalService.getGridType();
            var pointOfNav : string = "";
            if(gridType && gridType == GridTypes.paymentsRequest){
                pointOfNav = "MatchedPopUp";
            }
            else if(gridType && gridType == GridTypes.SimpleGrid) {
                pointOfNav ="SimpleShifts";
            }
            else {
                pointOfNav ="ManageShifts";
            }
        
        return (
            <>
                
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        message="Please wait..."
                        visible={this.state.loadPanelVisible}
                    />
                    {isEventUser ? (
                       
                            <Link
                                title="View"
                                className={"btn icon-btn-card"}
                                to={
                                    this.state.isFinancialAdjustmentView
                                        ? {
                                              pathname: "/FinanceAdjustmentPopUp",
                                              state: {
                                                  id: this.props.data && this.props.data.billableItemId ? this.props.data.billableItemId.toString() : "",
                                                  adjustment: false,
                                                  isReadOnly: true,
                                              },
                                          }
                                        : {
                                              pathname: "/AddEditFormV2",
                                              state: {
                                                  id: this.props.data && this.props.data.billableItemId ? this.props.data.billableItemId.toString() : "",
                                                  pointOfNavigation : pointOfNav,
                                                  adjustment: false,
                                                  isReadOnly: true,
                                              },
                                          }
                                }
                            >
                                <FontAwesomeIcon icon={faEye} />
                                <span className="icon-btn-card__text">View/Edit </span>
                            </Link>
                       
                    ) : null}
               
            </>
        );
    }
}

export default CardViewComponent;
