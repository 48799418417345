import React from "react";
import PaymentCycleManagePage from "./PaymentCycleManagePage";
import { RouteProps } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";

interface PaymentCycleManagePageContainerProps extends RouteProps, withValidUserRoleProps {}

const PaymentCycleManagePageContainer = (props: PaymentCycleManagePageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return <PaymentCycleManagePage title={getTitle()} category={getType()} />;
};

export default withValidUserRole(PaymentCycleManagePageContainer);
