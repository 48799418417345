import React from "react";
import { AxiosResponse } from "axios";
import { DataGrid, LoadIndicator, LoadPanel } from "devextreme-react";
import { Column, FilterRow, Lookup, Pager, Paging, SearchPanel, Sorting } from "devextreme-react/data-grid";
import MatrixService, { UserViewGridRowItem } from "../../services/MatrixService";
import periodMatrixFormUtil from "../Matrix/PeriodMatrixFormUtil";
import lookupService, { LookupTypeIndexes } from "../../services/LookupService";
import CustomStore from 'devextreme/data/custom_store';

interface UserViewProps {}
export interface LookupTypeItemGrid {
    id: number;
    value: string;
    parentMappingId: string;
}
export interface userViewState {
    userViewGridDataSource: UserViewGridRowItem[];
    showProgressIndicator: boolean;
    loadPanelVisible: boolean;
    errorMessage: any[];
    userContactTypeLookUp: LookupTypeItemGrid[];
    userCompanyLookUp: LookupTypeItemGrid[];
    userRoleLookUp: LookupTypeItemGrid[];
    activeLookUp: any[];
}
class UserView extends React.Component<UserViewProps> {
    userviewService: MatrixService;
    state: userViewState;
    utils: periodMatrixFormUtil;
    dropdownService: lookupService;
    constructor(props: UserViewProps) {
        super(props);
        this.userviewService = new MatrixService();
        this.utils = new periodMatrixFormUtil();
        this.dropdownService = new lookupService();

        this.state = {
            userViewGridDataSource: [],
            userContactTypeLookUp: [],
            userCompanyLookUp: [],
            userRoleLookUp: [],
            activeLookUp: [
                { id: "true", value: "true" },
                { id: "false", value: "false" },
            ],
            showProgressIndicator: false,
            loadPanelVisible: false,
            errorMessage: [],
        };
    }
    componentDidMount() {
        this.dropDownDataSource();
        this.userViewDataSource();
    }

    userViewDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.userviewService.getUserViewDataItem().then(this.handleSuccess).catch(this.handleError);
    };
    dropDownDataSource = () => {
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.userCompanyType)
            .then(this.handlecompanyLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.userContactType)
            .then(this.handlecontactTypeLUSuccess)
            .catch(this.handleError);
        this.dropdownService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.userRoleType)
            .then(this.handleRoleLUSuccess)
            .catch(this.handleError);
        
    };
    handlecontactTypeLUSuccess = (response: AxiosResponse<any>) => {
        var contactTypeDropDown = this.lookUpCreation(response);
        this.setState({
            userContactTypeLookUp: contactTypeDropDown,
        });
    };
    handleRoleLUSuccess = (response: AxiosResponse<any>) => {
        var roleDropDown = this.lookUpCreation(response);
        this.setState({
            userRoleLookUp: roleDropDown,
        });
    };
    handlecompanyLUSuccess = (response: AxiosResponse<any>) => {
        var companyDropDown = this.lookUpCreation(response);
        this.setState({
            userCompanyLookUp: companyDropDown,
        });
    };

    lookUpCreation = (response: AxiosResponse<any>): LookupTypeItemGrid[] => {
        var itemLUItem: LookupTypeItemGrid;
        var itemLUItemArray: LookupTypeItemGrid[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: parseInt(item.id),
                value: item.value,
                parentMappingId: item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    };
    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            userViewGridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    };
    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "user view service failed with response: " + JSON.stringify(error);

        if (!this.userviewService.traceAsErrorToAppInsights(respMessage)) {
            console.error(respMessage);
        }
    };
    renderErrorMessage = (errorMessage: any[]) => {
        let errorData: React.ReactNode = <></>;
        if (errorMessage) {
            errorData = (
                <span className="unscheduled-shift">
                    <ul>
                        {errorMessage.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    {item.columnName}: {item.errorMessage}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            );
        }
        return errorData;
    };
    linkCellDisplayContent = (cellInfo: any) => {
        return <div>{cellInfo.data.link ? `${cellInfo.data.link.substring(0, 50)}...` : ""}</div>;
    };

    // getlookupDataSourceConfig1 = () => {
    //     let obj = {
    //         store: {
    //             type: 'array',
    //             data: this.state.userCompanyLookUp
    //         },
    //         pageSize: 10,
    //         paginate: true
    //     }
    //     return obj;
    // }

    getlookupDataSourceConfig = () =>{
        let config = {
            store: new CustomStore({
                //key: "id",
                loadMode: "raw",
                load: () => {
                    return this.state.userCompanyLookUp;
                }
            }),
            // sort: "name",
            pageSize: 10,
            paginate: true
        };
        return config;
    }

    render() {
        var { errorMessage, loadPanelVisible } = this.state;
        return (
            <div>
                {this.renderErrorMessage(errorMessage)}
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={loadPanelVisible} />
                        <DataGrid
                            dataSource={this.state.userViewGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Sorting mode="single" />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <Column dataField="id" allowSorting={true} caption="ID" allowEditing={false} cssClass='grid-column-padding'/>
                            <Column dataField="companyId" allowSorting={true} caption="COMPANY">
                                <Lookup 
                                //dataSource={this.state.userCompanyLookUp} 
                                dataSource={this.getlookupDataSourceConfig()}
                                displayExpr="value" 
                                valueExpr="id" />
                            </Column>
                            <Column dataField="name" allowSorting={true} caption="NAME"></Column>
                            <Column dataField="email" allowSorting={true} caption="EMAIL"></Column>
                            <Column dataField="roleId" allowSorting={true} caption="ROLE">
                                <Lookup dataSource={this.state.userRoleLookUp} displayExpr="value" valueExpr="id" />
                            </Column>
                            <Column dataField="isActive" allowSorting={true} caption="ACTIVE">
                                <Lookup dataSource={this.state.activeLookUp} displayExpr="value" valueExpr="id" />
                            </Column>
                            <Column dataField="contactTypeId" allowSorting={true} caption="CONTACT TYPE">
                                <Lookup
                                    dataSource={this.state.userContactTypeLookUp}
                                    displayExpr="value"
                                    valueExpr="id"
                                />
                            </Column>
                            <Column
                                dataField="link"
                                allowSorting={true}
                                caption="LINK"
                                cellRender={this.linkCellDisplayContent}
                            ></Column>
                            <Column dataField="lastUpdated" allowSorting={true} caption="LAST UPDATED"></Column>
                            <FilterRow visible={true} applyFilter="auto" />
                        </DataGrid>
                    </>
                )}
            </div>
        );
    }
}
export default UserView;
