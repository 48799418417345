import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DatePanel from "../side-bar/DatePanel";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import BillingFormUtils, { BillingFormData, BillingStatus } from "../Billing/BillingFormUtils";
import BillingPrepareComponent from "./BillingPrepareComponent";
import LockShiftComponent from "./LockShiftComponent";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import { AxiosResponse } from "axios";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import { billingCycleStatus } from "../../services/BillingService";
import sharedUtils from "../grid/sharedUtilities";
import ReportsGridComponent from "./ReportsGridComponent";

interface BillingPagePreviewProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
}

export interface LookUpTypeItemForServiceType {
    serviceTypeLookupId: number;
    value: string;
    parentMappingId: string;
}

interface BillingPagePreviewState {
    billingStatus: BillingStatus;
    invoiceId: number;
    calculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    billingData: BillingFormData;
    billingPreviewData: any;
    isShowIncludedShift: boolean;
    previewGrid: boolean;
    status: BillingStatus;
    statusId: string;
    invoiceSentDate: NullableDate;
    invoicePaidDate: NullableDate;
    invoiceAmount: number;
    vatConfig: string;
    isPreviewClicked: boolean;
    isError: boolean;
    error: [];
}

class BillingPagePreview extends Component<BillingPagePreviewProps> {
    //Initialize the component's state.
    state: BillingPagePreviewState;
    utils: BillingFormUtils;
    lookupService: lookupService;
    accrualTypesDataSource: LookupTypeItem[];
    groupAccrualTypesDataSource: LookupTypeItem[];
    sharedUtils: sharedUtils;
    constructor(props: BillingPagePreviewProps) {
        super(props);
        this.utils = new BillingFormUtils();
        this.sharedUtils = new sharedUtils();
        this.lookupService = new lookupService();
        var convertedEditBillingData = this.utils.initializeBillingCycleItems("");
        this.accrualTypesDataSource = this.lookupService.getAccrualTypes();
        this.groupAccrualTypesDataSource = this.lookupService.getGroupAccrualTypes();
        this.state = {
            billingStatus: BillingStatus.Draft,
            invoiceId: 0,
            calculationsObject: {
                subTotal: "",
                total: "",
                tax: "",
            },
            billingData: convertedEditBillingData,
            billingPreviewData: {},
            isShowIncludedShift: false,
            previewGrid: false,
            status: BillingStatus.BillDefault,
            statusId: billingCycleStatus.Draft,
            invoiceSentDate: undefined,
            invoicePaidDate: undefined,
            invoiceAmount: 0,
            vatConfig: "",
            isPreviewClicked: false,
            isError: false,
            error: [],
        };
    }

    componentDidMount() {
        this.vatConfig();
    }

    onReceiveData = (
        invoiceId: string,
        formData: BillingFormData,
        status: BillingStatus,
        isRestartDisable: boolean,
        isShowIncludedShift: boolean,
        isSuccessRestart: boolean,
        statusId?: string,
        previewGrid?: boolean,
        isPreviewClicked?: boolean,
    ) => {
        let serviceItemArray: any[];
        serviceItemArray = [];
        formData.invoiceServiceMappingList.map((item: any, uniqueKey: number) => {
            serviceItemArray.push(item.serviceTypeLookupId);
        });
        this.setState((prevState: BillingPagePreviewState) => ({
            invoiceId: invoiceId,
            billingData: formData,
            isShowIncludedShift: isShowIncludedShift,
            previewGrid: previewGrid,
            billingStatus: status,
            statusId: statusId ? statusId : formData.statusId,
            isPreviewClicked: isPreviewClicked,
            billingPreviewData: {
                clientId: formData.clientId,
                serviceTypeIds: serviceItemArray,
                dateRangeFrom: this.sharedUtils.convertDateToString(formData.dateRangeFrom),
                dateRangeTo: this.sharedUtils.convertDateToString(formData.dateRangeTo),
                includeAccruals: formData.includeAccruals.toString() == "Include" || formData.includeAccruals == true ? true : false,
                groupAccruals: formData.groupAccruals.toString() == "Group" || formData.groupAccruals == true ? true : false,
            }
        }));
    };

    // A function which is invoked when the "Save" button is clicked on the Summary panel.
    onSaveBillingData = (invoiceId: string, formData: BillingFormData) => {
        this.setState({
            invoiceId: invoiceId,
            billingData: formData,
            statusId: formData.statusId,
        });
    };

    setCalculationsCallback = (calculationsObject: any) => {
        if (calculationsObject.subTotal) {
            let vatConfig = parseFloat(this.state.vatConfig);
            let tax = parseFloat((calculationsObject.subTotal * vatConfig).toFixed(2));
            let clientGross = calculationsObject.subTotal + tax;
            // let tax = calculationsObject.tax;
            // let clientGross = calculationsObject.total;
            this.setState({
                calculationsObject: {
                    ...this.state.calculationsObject,
                    total: clientGross,
                    subTotal: calculationsObject.subTotal,
                    tax: tax,
                },
            });
        }
    };

    //A helper function that will sync the date
    syncDateFields = (date: NullableDate, type: string) => {
        if (type.toLowerCase() == "sent") {
            this.setState({
                invoiceSentDate: date,
                billingData: {
                    ...this.state.billingData,
                    invoiceSentDate: date,
                },
            });
        } else {
            this.setState({
                invoicePaidDate: date,
            });
        }
    };

    syncAmountFields = (data: number) => {
        this.setState({
            invoiceAmount: data ? data : 0,
        });
    };

    vatConfig = () => {
        this.lookupService
            .getVATValue(LookupTypeIndexes.configuration, "VatPercentage")
            .then(this.handleSuccessVATLookUp)
            .catch(this.handleError);
    };

    handleSuccessVATLookUp = (response: AxiosResponse<any>) => {
        this.setState({
            vatConfig: response.data.data[0].value,
        });
    };

    handleError = (error: any) => {
        var respMessage: string = "Get Configuration Text service failed with response: " + JSON.stringify(error);

        if (!this.lookupService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    previewUpdate = (flag1: boolean, flag2: boolean, error: []) => {
        this.setState({
            isPreviewClicked:flag1, 
            isError:flag2,
            error: error
        });
    }

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>
                <section className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <h2>
                            <b>Billing - Preview Invoice</b>
                        </h2>
                    </header>
                    <BillingPrepareComponent
                        isClickedLockedShift={false}
                        location={this.props.location}
                        onReceiveData={this.onReceiveData}
                        saveBillingData={this.onSaveBillingData}
                        calculationsObject={this.state.calculationsObject}
                        isClickedRestart={false}
                        status={this.state.status}
                        statusId={this.state.statusId}
                        syncDateFields={this.syncDateFields}
                        syncAmountFields={this.syncAmountFields}
                        invoiceSentDate={this.state.invoiceSentDate}
                        invoicePaidDate={this.state.invoicePaidDate}
                        invoiceAmount={this.state.invoiceAmount}
                        refreshPage={false}
                        vatConfig={this.state.vatConfig}
                        pageType="BillingPreview"
                        previousPage={this.props.history}
                        isError={this.state.isError}
                        error={this.state.error}

                    />
                    {this.state.isShowIncludedShift && this.state.previewGrid ? (
                        <LockShiftComponent
                            viewDisable={false}
                            invoiceId={this.state.invoiceId}
                            billingData={this.state.billingPreviewData}
                            setCalculationsCallback={this.setCalculationsCallback}
                            onClickLockedShift={()=>{}}
                            isPrepareClicked={false}
                            isSuccessRestart={false}
                            statusId={this.state.statusId}
                            gridType="BillingPreview"
                            isPreviewClicked={this.state.isPreviewClicked}
                            previewUpdate={this.previewUpdate}
                        />
                    ) : (
                        <></>
                    )}
                    {this.state.isShowIncludedShift && this.state.previewGrid ? (                        
                        <ReportsGridComponent
                            viewDisable={false}
                            invoiceId={this.state.invoiceId}
                            previewGrid={this.state.previewGrid}
                            billingData={this.state.billingPreviewData}
                            onShiftsChecked={() => { return null }}
                            setTaxValues={this.setCalculationsCallback}
                            statusId={this.state.statusId}
                            calculationsObject={this.state.calculationsObject}
                            isPreviewClicked={this.state.isPreviewClicked}
                        />
                    ) : (
                        <></>
                    )}
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(BillingPagePreview);
