import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";

interface BulletinActionCellComponentProps {
    data: any;
}

class BulletinActionCellComponent extends React.Component<BulletinActionCellComponentProps> {
    constructor(props: BulletinActionCellComponentProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <Link
                            title="Edit"
                            className="icon-btn"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    id: this.props.data.data.id.toString(),
                                    isEdit: true,
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default BulletinActionCellComponent;
