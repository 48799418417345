import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, DateBox } from "devextreme-react";
import LookupService from "../../services/LookupService";
import sharedUtils from "../grid/sharedUtilities";
import { DateBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import CostReportService from "../../services/CostReportService";
import CostReportUtils from "../CostReports/CostReportUtils";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";

interface IncidentsListDatePanelProps extends WithTranslation {
    onApplyButtonClick: (startDate: string, endDate: string) => void;
}

interface IncidentsListDatePanelState {
    startDate: string;
    endDate: string;
    minDateValue: NullableDate;
    maxDateValue: NullableDate;
}

class IncidentsListDatePanel extends Component<IncidentsListDatePanelProps> {
    lookupService: LookupService;
    state: IncidentsListDatePanelState;
    sharedUtils: sharedUtils;
    Service: CostReportService;
    utils: CostReportUtils;
    constructor(props: IncidentsListDatePanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.sharedUtils = new sharedUtils();
        this.utils = new CostReportUtils();
        this.state = {
            startDate: "",
            endDate: "",
            minDateValue: null,
            maxDateValue: null,
        };
    }

    handleChangeStartDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            minDateValue: dxValueChange.value,
            startDate: this.sharedUtils.convertDateToString(dxValueChange.value),
        });
    };

    handleChangeEndDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            maxDateValue: dxValueChange.value,
            endDate: this.sharedUtils.convertDateToString(dxValueChange.value),
        });
    };

    //Fire the parent component's callback on an Apply click.
    onApplyClick = () => {
        this.props.onApplyButtonClick(this.state.startDate, this.state.endDate);
    };

    render() {
        return (
            <div>
                <div className="row mt-4">
                    <div className="col-10">
                        <h4 className="sidebar__heading font-weight-bold">Filter</h4>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col sidebar__heading">Start date</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeStartDate}
                            value={this.state.minDateValue ? this.state.minDateValue : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">End date</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <DateBox
                            onValueChanged={this.handleChangeEndDate}
                            value={this.state.maxDateValue ? this.state.maxDateValue : undefined}
                            useMaskBehavior={true}
                            displayFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        <Button className="btn btn-primary btn--large" disabled={false} onClick={this.onApplyClick}>
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(IncidentsListDatePanel);
