import React, { MouseEvent, Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import UserService, { RoleGroupNames } from "../../services/UserService";
import PaymentCycleGrid from "../Payments/PaymentCyclesGrid";
import LightModePageTemplate from "./Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import DatePanel from "../side-bar/DatePanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";

interface PaymentCycleManagePageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
}

interface PaymentCycleManagePageState {
    gridRefreshSignal: boolean;
}

class PaymentCycleManagePage extends Component<PaymentCycleManagePageComponentProps> {
    //Initialize the component's state.
    state: PaymentCycleManagePageState;

    constructor(props: PaymentCycleManagePageComponentProps) {
        super(props);

        this.state = {
            gridRefreshSignal: false,
        };
    }
    //function that would indicate that a Grid refresh is required on click of the button.
    signalGridRefresh = () => {
        this.setState({ gridRefreshSignal: !this.state.gridRefreshSignal });
    };

    //on click of add Payment cycle v2

    onclickAddPaymentCycleV2 = () => {
        sessionStorage.removeItem("paymentCycleId");
    };

    render() {
        let isaddButtonDisabled: boolean = true;
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            isaddButtonDisabled = false;
        }
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>

                <section className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <div>
                            <h2 className="page-title__black-table">Payments</h2>
                        </div>
                        <div className="grid-info__button-container text-center text-lg-left">
                            <Link
                                className={
                                    (isaddButtonDisabled ? "disabled " : "") +
                                    "btn btn-primary btn--large btn--with-icon col-12 col-sm-6 col-xl-4 mb-3"
                                }
                                onClick={this.onclickAddPaymentCycleV2}
                                to={{
                                    pathname: "/PaymentsV2Page",
                                    state: {
                                        id: 0,
                                    },
                                }}
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                                Add Payment Cycle
                            </Link>

                            <span>{"     "}</span>
                            <button
                                className={(true ? "" : "disabled") + "btn btn--ghost btn--ghost--teal btn--large btn--with-icon col-12 col-sm-6 col-xl-2 mb-3"}
                                onClick={this.signalGridRefresh}
                            >
                                Refresh
                            </button>
                        </div>
                        <h3 className="font-weight-bold ml-3">Previous Payment Cycles</h3>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            <PaymentCycleGrid
                                location={this.props.location}
                                history={this.props.history}
                                refreshSignal={this.state.gridRefreshSignal}
                            />
                        </div>
                        <div />
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(PaymentCycleManagePage);
