import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CheckinCardList from "../check-in/CheckinCardList";
import { WithTranslation, withTranslation } from "react-i18next";
import DarkModePageTemplate from "./Templates/DarkModePageTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import DatePanel from "../side-bar/DatePanel";
import AddShiftsSection from "../check-in/AddShiftsSection";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import VenueFilterPanel from "../Query/VenueFilterPanel";
interface TodaysViewCheckInOutPageComponentProps extends RouteComponentProps, WithTranslation {
    title: string;
    category: string;
    location: any;
}

interface TodaysViewCheckInOutPageComponentState {
    cardsLoaded: boolean;
    clientId: string;
    venueId: string;
}

class TodaysViewCheckInOutPage extends Component<TodaysViewCheckInOutPageComponentProps> {
    //Initialize the state.
    state: TodaysViewCheckInOutPageComponentState;
    constructor(props: TodaysViewCheckInOutPageComponentProps) {
        super(props);
        this.state = {
            cardsLoaded: false,
            clientId: "",
            venueId: "",
        };
    }

    //A helper function that would set the state of the parent page component if the cards are not present in the deck.
    setParentCardCount = (cardCountFlag: number) => {
        if (cardCountFlag && cardCountFlag > 0) {
            this.setState({
                cardsLoaded: true,
            });
        } else {
            this.setState({
                cardsLoaded: false,
            });
        }
    };

    handleApplyButtonClick = (clientId: string, venueId: string) => {
        this.setState({
            clientId: clientId ? clientId : "",
            venueId: venueId ? venueId : "",
        });
    };

    render() {
        let id = this.props.location.state ? (this.props.location.state.id ? this.props.location.state.id : "") : "";
        var header: string = "Door Supervision - Today's Check In & Out";
        return (
            <DarkModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <DatePanel />
                    <VenueFilterPanel onApplyButtonClick={this.handleApplyButtonClick} displayText={true} />
                    <BulletinPanel />
                </SideBarTemplate>
                <div id="todaysView" className="page-content--with-sidebar-hidden-mobile">
                    <section className="container--no-padding" role="main">
                        <header>
                            <h2 className="page-title__lightgrey pb-2">{header}</h2>
                        </header>
                        <div className="accent-container">
                            <AddShiftsSection header={true} />
                            {id ? (
                                <CheckinCardList
                                    billableId={id}
                                    setParentCardCount={this.setParentCardCount}
                                    clientId={this.state.clientId}
                                    venueId={this.state.venueId}
                                />
                            ) : (
                                <CheckinCardList
                                    setParentCardCount={this.setParentCardCount}
                                    clientId={this.state.clientId}
                                    venueId={this.state.venueId}
                                />
                            )}
                            {this.state.cardsLoaded ? <AddShiftsSection header={false} /> : <></>}
                        </div>
                    </section>
                </div>
            </DarkModePageTemplate>
        );
    }
}

export default withRouter(withTranslation()(TodaysViewCheckInOutPage));
