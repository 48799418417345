import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import SubmitGridRowComponent from "./SubmitGridRowComponent";
import BillableItemHeaderService, {
    BillableItemHeaderStatuses,
    fileTypeId,
} from "../../services/BillableItemHeaderService";
import { LoadIndicator } from "devextreme-react";
import { confirm, alert } from "devextreme/ui/dialog";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import gridUtils from "../grid/GridUtilities";
import PendingRemovalComponent from "./PendingRemovalComponent";
import UserService, { RoleGroupNames } from "../../services/UserService";

// The refresh rate of the LogicApp responsible for processing uploaded files in Azure Blob Storage
//    LogicAppRefreshRate = 10000 (10 seconds)
//    The grid is going to show a load indicator for the approx time it takes the LogicApp to update, then stop indicating to the user to click the refresh button
const LoadIndicatorTimeFrame = 10000;

interface UploadScheduleGridRowProps extends WithTranslation {
    data: any;
}

interface UploadScheduleGridRowState {
    loadIndicatorVisible: boolean;
    disableStatusButton: boolean;
    disableRemoveButton: boolean;
    rowData: {
        id: string;
        fileFormatTypeLookUpId: string;
        fileFormatText: string;
        fileTypeText: string;
        fileName: string;
        serviceTypeId: string;
        serviceType: string;
        serviceSubTypeId: string;
        serviceSubType: string;
        status: string;
        errorMessage: string;
        lastUpdatedOn: string;
        lastUpdatedBy: string;
        fileTypeId: string;
        submittedBillableItemIds: [];
    };
}

class UploadScheduleGridRow extends React.Component<UploadScheduleGridRowProps> {
    //state object to manage the look of the Individual buttons and badges displayed in each row.
    state: UploadScheduleGridRowState;
    Service: BillableItemHeaderService;
    loadIndicatorTimer: any;
    gridUtils: gridUtils;

    constructor(props: UploadScheduleGridRowProps) {
        super(props);

        // Bind functions
        this.onFileSubmit = this.onFileSubmit.bind(this);
        this.onFileReUpload = this.onFileReUpload.bind(this);
        this.onSubmitConfirmation = this.onSubmitConfirmation.bind(this);
        this.setLoadIndicatorVisibility = this.setLoadIndicatorVisibility.bind(this);
        this.setStatusButtonDisabledState = this.setStatusButtonDisabledState.bind(this);
        this.onRemoveShifts = this.onRemoveShifts.bind(this);
        this.onRemoveConfirmation = this.onRemoveConfirmation.bind(this);
        // Init services, variables
        this.Service = new BillableItemHeaderService();
        this.loadIndicatorTimer = () => false;
        this.gridUtils = new gridUtils();

        // Set initial state
        this.state = {
            loadIndicatorVisible: false,
            disableStatusButton: this.gridUtils.getStatusButtonDisabledState(this.props.data.data.status),
            disableRemoveButton:
                this.props.data.data.submittedBillableItemIds &&
                this.props.data.data.submittedBillableItemIds.length > 0
                    ? false
                    : true,
            rowData: {
                id: "",
                fileFormatTypeLookUpId: "",
                fileFormatText: "",
                fileTypeText: "",
                fileName: "",
                serviceTypeId: "",
                serviceType: "",
                serviceSubTypeId: "",
                serviceSubType: "",
                status: "",
                errorMessage: "",
                lastUpdatedOn: "",
                lastUpdatedBy: "",
                fileTypeId: "",
                submittedBillableItemIds: [],
            },
        };
    }

    /**
     * Updates the disabled status of the Submit button
     *
     * @param isDisabled {boolean}
     * @returns {void}
     */
    setStatusButtonDisabledState(isDisabled: boolean): void {
        this.setState({
            disableStatusButton: isDisabled,
        });
    }

    /**
     * Display the load indicator.
     *
     * @param isVisible {boolean}
     * @returns {void}
     */
    setLoadIndicatorVisibility(isVisible: boolean): void {
        this.setState({
            loadIndicatorVisible: isVisible,
        });
    }

    /**
     * Set initial view states
     *
     * @returns {void}
     */
    componentDidMount(): void {
        var startLoadIndicator = false;

        if (
            this.Service.isStatusEqualTo(this.props.data.data.status, BillableItemHeaderStatuses.Pending) ||
            this.Service.isStatusEqualTo(this.props.data.data.status, BillableItemHeaderStatuses.InProgress) ||
            this.Service.isStatusEqualTo(this.props.data.data.status, BillableItemHeaderStatuses.SubmissionInProgress)
        ) {
            startLoadIndicator = true;
            this.loadIndicatorTimer = setTimeout(() => this.setLoadIndicatorVisibility(false), LoadIndicatorTimeFrame);
            // If Pending, need to update status text to In Progress
            if (this.Service.isStatusEqualTo(this.props.data.data.status, BillableItemHeaderStatuses.Pending)) {
                this.props.data.data.status = BillableItemHeaderStatuses.InProgress;
            }
        }

        this.setState({
            rowData: this.props.data.data,
            loadIndicatorVisible: startLoadIndicator,
        });
    }

    /**
     * Component cleanup
     *
     * @returns {void}
     */
    componentWillUnmount(): void {
        clearTimeout(this.loadIndicatorTimer);
    }

    /**
     * Callback function used by child component to submit file
     *
     * @returns {void}
     */
    onFileSubmit(event: any): void {
        confirm(this.props.t("shiftUpload.uploadScheduleGridRow.submitConfirmation.text"), "Submit").then(
            (hasConfirmed) => {
                if (hasConfirmed === true) {
                    this.onSubmitConfirmation();
                }
            }
        );
        event.event.stopPropagation();
    }

    /**
     * Callback function used by child component to remove shifts
     *
     * @returns {void}
     */
    onRemoveShifts(event: any): void {
        confirm("Remove all shifts?", "Submit").then((hasConfirmed) => {
            if (hasConfirmed === true) {
                this.onRemoveConfirmation();
            }
        });
        event.event.stopPropagation();
    }

    onRemoveConfirmation(): void {
        this.Service.removeRowData(this.state.rowData.submittedBillableItemIds)
            .then((response) => {
                alert("Post Removal Complete (all shifts deleted successfully)", "Success");
            })
            .catch((error) => {
                alert("Post Removal Failure (error experienced)", "Failed");
                var respMessage: string = "Remove RowData failed with response: " + JSON.stringify(error);

                if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
            });
    }

    /**
     * Callback used to re-upload file
     *
     * @returns {void}
     */
    onFileReUpload(): void {}

    /**
     * Post submit confirmation helper function
     * NOTE: No component state changes/access can be done in here as the component as unmounted by now
     *
     * @returns {void}
     */
    onSubmitConfirmation(): void {
        // Update the status if necessary and start the load indicator - this updates the grid's row data so when the component re-mounts, it will display the correct status
        if (
            this.Service.isStatusEqualTo(this.state.rowData.status, BillableItemHeaderStatuses.ReadyForSubmission) ||
            this.Service.isStatusEqualTo(
                this.state.rowData.status,
                BillableItemHeaderStatuses.ReadyForSubmissionWarnings
            )
        ) {
            this.props.data.data.status = BillableItemHeaderStatuses.SubmissionInProgress;
            this.props.data.component.repaint();
        }

        // Submit the row
        if (
            this.state.rowData.fileTypeId == fileTypeId.equipment ||
            this.state.rowData.fileTypeId == fileTypeId.personnel || 
            this.state.rowData.fileTypeId == fileTypeId.ServiceUploader
        ) {
            this.Service.submitRowData(this.state.rowData.id)
                .then((response) => {})
                .catch((error) => {
                    var respMessage: string = "submitRowData failed with response: " + JSON.stringify(error);

                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        } else if (this.state.rowData.fileTypeId == fileTypeId.SIA) {
            this.Service.submitSIARowData(this.state.rowData.id)
                .then((response) => {})
                .catch((error) => {
                    var respMessage: string = "submitSIARowData failed with response: " + JSON.stringify(error);

                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        } 
        else if (this.state.rowData.fileTypeId == fileTypeId.Venue || this.state.rowData.fileTypeId == fileTypeId.VenueV2) {
            this.Service.submitVenueUploaderRowData(this.state.rowData.id)
                .then((response) => {})
                .catch((error) => {
                    var respMessage: string =
                        "submitVenueUploaderRowData failed with response: " + JSON.stringify(error);

                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        } else if (this.state.rowData.fileTypeId == fileTypeId.RateMatrix) {
            this.Service.submitRateMatrixUploaderRowData(this.state.rowData.id)
                .then((response) => {})
                .catch((error) => {
                    var respMessage: string =
                        "submitRateMatrixUploaderRowData failed with response: " + JSON.stringify(error);

                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        } else if (this.state.rowData.fileTypeId == fileTypeId.PeriodMatrix) {
            this.Service.submitPeriodMatrixUploaderRowData(this.state.rowData.id)
                .then((response) => {})
                .catch((error) => {
                    var respMessage: string =
                        "submitPeriodMatrixUploaderRowData failed with response: " + JSON.stringify(error);

                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                }); 
        } else {
            this.Service.submitBudgetUploaderRowData(this.state.rowData.id)
                .then((response) => {})
                .catch((error) => {
                    var respMessage: string =
                        "submitBudgetUploaderRowData failed with response: " + JSON.stringify(error);

                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        }
    }

    handleDeleteOnClick = () => {
        confirm("<div class='text-center'>Delete this row?</div>", "Delete").then((hasConfirmed) => {
            if (hasConfirmed) {
                //call the API for posting all the Delete Billable Id and handle the response
                this.onDeleteConfirmation();
            }
        });
    };

    onDeleteConfirmation = (): void => {
        if (this.state.rowData.serviceSubTypeId) {
            this.Service.deleteFileUploadRowItem(this.state.rowData.id.toString())
                .then(this.handleSuccessDelete)
                .catch((error) => {
                    var respMessage: string = "deleteFileUploadRowItem failed with response: " + JSON.stringify(error);

                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        } else {
            this.Service.deleteSIAUploadRowItem(this.state.rowData.id.toString())
                .then(this.handleSuccessDelete)
                .catch((error) => {
                    var respMessage: string = "deleteSIAUploadRowItem failed with response: " + JSON.stringify(error);

                    if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        }
    };

    disableDeleteIcon = (): boolean => {
        return (
            this.Service.isStatusEqualTo(this.props.data.data.status, BillableItemHeaderStatuses.Submitted) ||
            this.Service.isStatusEqualTo(this.props.data.data.status, BillableItemHeaderStatuses.InProgress) ||
            this.Service.isStatusEqualTo(this.props.data.data.status, BillableItemHeaderStatuses.SubmissionInProgress)
        );
    };

    handleSuccessDelete = () => {
        this.props.data.component.deleteRow(this.props.data.rowIndex);
        this.props.data.component.repaint();
    };

    render() {
        let colourStyle = "";
        if (this.Service.isStatusEqualTo(this.state.rowData.status, BillableItemHeaderStatuses.Failed)) {
            colourStyle = "bg-danger";
        } else if (this.Service.isStatusEqualTo(this.state.rowData.status, BillableItemHeaderStatuses.NotAccepted)) {
            colourStyle = "bg-danger";
        } else if (
            this.Service.isStatusEqualTo(
                this.state.rowData.status,
                BillableItemHeaderStatuses.ReadyForSubmissionWarnings
            )
        ) {
            colourStyle = "bg-warning";
        } else if (
            this.Service.isStatusEqualTo(this.state.rowData.status, BillableItemHeaderStatuses.ReadyForSubmission)
        ) {
            colourStyle = "bg-success";
        } else if (this.Service.isStatusEqualTo(this.state.rowData.status, BillableItemHeaderStatuses.Submitted)) {
            colourStyle = "bg-info";
        } else {
            colourStyle = "bg-mid"; // pending or in progress
        }

        return (
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <span className={`status__circle ${colourStyle}`}></span>

                    <div className="ml-3 d-inline-block">{this.state.rowData.status}</div>
                    <LoadIndicator
                        className="ml-3"
                        id="button-indicator"
                        height={20}
                        width={20}
                        visible={this.state.loadIndicatorVisible}
                    />
                </div>

                <div className="ml-3">
                    {this.Service.isStatusEqualTo(this.state.rowData.status, BillableItemHeaderStatuses.Failed) ? (
                        <Link
                            title="Edit"
                            className={(BillableItemHeaderStatuses.Failed ? "" : "disabled ") + "icon-btn"}
                            onClick={this.handleDeleteOnClick}
                            to="#"
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Link>
                    ) : (
                        <>
                            {(UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) &&
                            this.state.rowData.status != BillableItemHeaderStatuses.NotAccepted  && window.location.pathname.includes("uploadSchedule")  ? (
                                <PendingRemovalComponent
                                    buttonText="Remove Pendings"
                                    onRemovalClick={this.onRemoveShifts}
                                    currentRowState={this.state.rowData}
                                    isDisabled={this.state.disableRemoveButton}
                                ></PendingRemovalComponent>
                            ) : null}
                            <SubmitGridRowComponent
                                buttonText={this.props.t("shiftUpload.submitGridRowComponent.submitButton.text")}
                                onSubmitClick={this.onFileSubmit}
                                currentRowState={this.state.rowData}
                                isDisabled={this.state.disableStatusButton}
                            />

                            <Link
                                title="Edit"
                                className={(this.disableDeleteIcon() ? "disabled " : "") + "icon-btn"}
                                onClick={this.handleDeleteOnClick}
                                to="#"
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Link>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation()(UploadScheduleGridRow);
