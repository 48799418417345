import { AxiosResponse } from "axios";
import { _get, _delete, _setURL, _post, _aiTraceAsError } from "./BaseApiService";

const SERVICE_FEE_API = "ServiceFeeMatrix";
const SERVICE_FEE_FILTER_API = "ServiceFeeMatrix/filter";

type NullableBool = boolean | null | undefined;
export interface ServiceFeeGridRowItem {
    id: string;
    clientBusinessEntityId: string;
    clientBusinessEntity: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    serviceFeeCalculationLookUpId: string;
    serviceFeeCalculation: string;
    multiplier: string;
    fasterPayMultiplier: string;
    approvalVisibilityOffset: string;
    approvalVisibilityOffsetBatch: string;
    approvalVisibilityOffsetImport: string;
    paymentOffset: string;
    makeOfferLowerAdjustment: string;
    makeOfferUpperAdjustment: string;
    approvalVisibilityDate: string;
    refund: boolean;    
    fasterPayAvailable: boolean;
    visibilityDateOverride: boolean;
    earlyPay: boolean;
    minGrossProfitValue: string;
    maxGrossProfitValue: string;
    isActive: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    marketPlaceReduction:string;
}

export interface ServiceFeeFormResponse {
    id: string;
    clientBusinessEntityId: string;
    clientBusinessEntity: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    serviceFeeCalculationLookUpId: string;
    serviceFeeCalculation: string;
    multiplier: string;
    fasterPayMultiplier: string;
    approvalVisibilityOffset: string;
    approvalVisibilityOffsetBatch: string;
    approvalVisibilityOffsetImport: string;
    twoTierRequestApprovalMode:string;
    twoTierRequestApprovalAmount:string;
    twoTierBillableApprovalMode:string;
    twoTierBillableApprovalAmount:string;
    paymentOffset: string;
    makeOfferLowerAdjustment: string;
    makeOfferUpperAdjustment: string;
    marketPlaceReduction:string;
    marketPlaceReduction01:string;
    marketPlaceReduction02: string;
    marketPlaceReduction03: string;
    marketPlaceReduction04: string;
    marketPlaceReduction05: string;
    marketPlaceReduction06: string;
    marketPlaceReduction07: string;
    marketPlaceReduction08: string;
    marketPlaceReduction09: string;
    marketPlaceReduction10: string;
    marketPlaceReduction11: string;
    marketPlaceReduction12: string;
    onlyBillApprovedItems:boolean;
    approvalVisibilityDate: string;
    refund: boolean;    
    fasterPayAvailable: boolean;
    visibilityDateOverride: boolean;
    earlyPay: boolean;
    minGrossProfitValue: string;
    maxGrossProfitValue: string;
    isActive: boolean;
    isAccruals: boolean;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    providerAcceptances: boolean;
    budgetTypeId: string;
    budgetType: string;
}

export interface ServiceFeeFormRequest {
    id: string;
    clientBusinessEntityId: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    serviceFeeCalculationLookUpId: string;
    multiplier: string;
    fasterPayMultiplier: string;
    approvalVisibilityOffset: string;
    approvalVisibilityOffsetBatch: string;
    approvalVisibilityOffsetImport: string;
    twoTierRequestApprovalMode :string;
    twoTierRequestApprovalAmount:string;
    twoTierBillableApprovalMode:string;
    twoTierBillableApprovalAmount:string;
    paymentOffset: string;
    marketPlaceReduction:string;
    marketPlaceReduction01:string;
    marketPlaceReduction02: string;
    marketPlaceReduction03: string;
    marketPlaceReduction04: string;
    marketPlaceReduction05: string;
    marketPlaceReduction06: string;
    marketPlaceReduction07: string;
    marketPlaceReduction08: string;
    marketPlaceReduction09: string;
    marketPlaceReduction10: string;
    marketPlaceReduction11: string;
    marketPlaceReduction12: string;
    onlyBillApprovedItems:boolean;
    makeOfferLowerAdjustment: string;
    makeOfferUpperAdjustment: string;
    approvalVisibilityDate: string;
    refund: boolean;    
    fasterPayAvailable: boolean;
    visibilityDateOverride: boolean;
    earlyPay: boolean;
    minGrossProfitValue: string;
    maxGrossProfitValue: string;
    isActive: boolean;
    isAccruals: boolean;
    providerAcceptances: boolean;
    budgetTypeId: string;
    budgetType: string;
}
export const feeService = {
    ServiceFeeMatrix: "ServiceFeeMatrix",
};
class ServiceFeeService {
    //#region Contract Cleaning Service Call
    getServiceFeeMatrixData(): Promise<AxiosResponse<any>> {
        return _get(SERVICE_FEE_API);
    }
    getServiceFeeMatrixDataItem(id: any): Promise<AxiosResponse<any>> {
        return _get(`${SERVICE_FEE_API}/${id}`);
    }
    saveServiceFeeMatrixDataItem(serviceFeeMatrixObject: object) {
        return _post(SERVICE_FEE_API, serviceFeeMatrixObject);
    }
    getServiceFeeMatrixDataItemFilter(clientId: string, serviceTypeId:string, serviceSubTypeId:string): Promise<AxiosResponse<any>> {
        return _get(`${SERVICE_FEE_FILTER_API}?clientId=${clientId}&serviceTypeId=${serviceTypeId}&serviceSubTypeId=${serviceSubTypeId}`);
    }
    //#endregion
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}
export default ServiceFeeService;
