import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, SelectBox } from "devextreme-react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import UserService from "../../services/UserService";
import sharedUtils from "../grid/sharedUtilities";
import CostReportService from "../../services/CostReportService";
import CostReportUtils from "../CostReports/CostReportUtils";
import queryUtils from "../Query/QueryUtils";
import { SelectBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import GlobalService from "../../services/GlobalService";
import { ClickEvent } from "devextreme/ui/button";
interface QueryGridSidePanelProps extends WithTranslation {
    onApplyButtonClick: (
        clientId: string,
        venueId: string,
        serviceId: string,
        statusId: string,
        raisedById:string,
        isApplyClicked:boolean
    ) => void;
    serviceTypeId: string;
    clientId: string;
    venueId: string;
}

interface PostApprovalPanelState {
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    serviceTypeloaded: false;
    serviceTypeDataSource: LookupTypeItem[];
    venueListByClient: any[];
    entireVenueList: any[];
    venueDataLoaded: boolean;
    disableApplyButton: boolean;
    statusTypeList: any[];
    statusId: string;
    raisedByList: any[];
    raisedById:string;
    clientDataSource: LookupTypeItem[];
}

class QueryGridSidePanel extends Component<QueryGridSidePanelProps> {
    lookupService: LookupService;
    state: PostApprovalPanelState;
    sharedUtils: sharedUtils;
    Service: CostReportService;
    utils: CostReportUtils;
    queryUtils: queryUtils;
    constructor(props: QueryGridSidePanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.sharedUtils = new sharedUtils();
        this.utils = new CostReportUtils();
        this.queryUtils = new queryUtils();
        this.state = {
            clientId: this.props.clientId,
            venueId: this.props.venueId,
            serviceTypeId: this.props.serviceTypeId,
            serviceTypeloaded: false,
            serviceTypeDataSource: [],
            venueListByClient: [],
            entireVenueList: [],
            venueDataLoaded: false,
            disableApplyButton: false,
            statusTypeList: [],
            statusId: "",
            raisedByList:[],
            raisedById:'',
            clientDataSource: [],
        };
    }

    //Retrieve the lookup values inside the mount
    componentDidMount() {
        var { clientId, venueId } = this.props;
        if (clientId && venueId) {
            this.retrieveLookupValues(LookupTypeIndexes.venueType);
        } else {
            this.retrieveLookupValues(LookupTypeIndexes.clientType);
        }
        this.retrieveLookupValues(LookupTypeIndexes.serviceType);
        this.retrieveLookupValues(LookupTypeIndexes.query);
        this.setRaisedBySource();
    }

    setRaisedBySource = () => {
        let raisedByList = this.queryUtils.lookUpRaisedBy();
        this.setState({
            raisedByList: raisedByList,
            raisedById: raisedByList[0].id,
        });
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleClientLookupSuccess)
                    .catch((error) => { });
                break;
            case LookupTypeIndexes.serviceType:
                var listOfServiceTypes: LookupTypeItem[] = UserService.operatingServiceList();
                if (listOfServiceTypes && listOfServiceTypes.length > 0) {
                    var serviceTypeDataSource: LookupTypeItem[] = this.queryUtils.lookUpincludeAll(listOfServiceTypes);
                    this.setState({
                        serviceTypeloaded: true,
                        serviceTypeId: serviceTypeDataSource[0].id,
                        serviceTypeDataSource: serviceTypeDataSource,
                    });
                }
                break;
            case LookupTypeIndexes.venueType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                    .then(this.handleVenueDataRetrievalSuccess)
                    .catch((error) =>
                        this.setState({
                            venueDataLoaded: true,
                            venueListByClient: [],
                        })
                    );
                break;
            case LookupTypeIndexes.query:
                var statusType = this.lookupService
                    .getLookupByLookupTypeIndexAll(LookupTypeIndexes.query)
                    .then(this.handleRequestLookupSuccess)
                    .catch((error) => {

                    })

                break;
        }
    };

    handleRequestLookupSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            statusTypeList: response.data.data,
        }, () => { this.setIntialValueForStatus(this.state.statusTypeList) });
    }

    setIntialValueForStatus = (statusObject: LookupTypeItem[]) => {
        var queryFilterValues = GlobalService.getQueryFilterValues();
        if (queryFilterValues.statusId || queryFilterValues.clientId || queryFilterValues.venueId || queryFilterValues.serviceId || queryFilterValues.raisedById) {
            this.setState({
                clientId: queryFilterValues.clientId,
                venueId: queryFilterValues.venueId,
                serviceTypeId: queryFilterValues.serviceId,
                statusId: queryFilterValues.statusId,
                raisedById:queryFilterValues.raisedById
            }, () => { this.onApplyButtonClicked() });
        }
        else if (statusObject.length > 0) {
            this.setState({
                statusId: statusObject[0].id
            }, () => { this.onApplyButtonClicked() });
        }
    }

    //This function would be called only wheh a props is passed down from the landing page resulting in calling the venue endpoint.
    handleVenueDataRetrievalSuccess = (response: AxiosResponse<any>) => {
        if (response.data && response.data.data) {
            var venueData: any[] = this.sharedUtils.extractVenueListPerClient(response.data.data, this.state.clientId);
            this.setState({
                venueDataLoaded: true,
                venueListByClient: venueData,
            });
        }
    };

    handleClientLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data) {
            if (response.data.data.length == 0) {
                this.onApplyButtonClicked();
            } else {
                this.setState(
                    {
                        clientDataSource:
                            response.data && response.data.data
                                ? this.queryUtils.lookUpincludeAll(response.data.data)
                                : [],
                    },
                    () => this.retrieveVenueLookupValues()
                );
            }
        }
    };

    retrieveVenueLookupValues = () => {
        this.setState({
            clientId: this.state.clientDataSource[0].id
        });
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
            .then(this.handleVenueLookupSuccess)
            .catch((error) => { });
    };

    handleVenueLookupSuccess = (response: AxiosResponse<any>) => {
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            !this.isBlank(this.state.clientId)
        ) {
            var venues: any[] = response.data.data;
            var clientMappedVenue: any[] = this.queryUtils.extractVenueListBasedOnClient(venues, this.state.clientId);
            this.setState(
                {
                    venueDataLoaded: true,
                    venueId: clientMappedVenue[0].id,
                    venueListByClient: clientMappedVenue,
                    entireVenueList: venues,
                }
            );
        }
    };


    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };


    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var venueList: any[] = this.state.entireVenueList;
        var venueDataByClientId: any[] = this.queryUtils.extractVenueListBasedOnClient(venueList, dxValueChange.value);
        this.setState({
            clientId: dxValueChange.value,
            venueListByClient: venueDataByClientId,
            venueId:'0'
        }); //When a client dropdown is changed, initiate the API call, and default the VenueID to "", because the State is still holding the previously selected VenueId.
    };

    handleChangeVenueTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            venueId: dxValueChange.value,
        });
    };

    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        sessionStorage.setItem("serviceTypeFilter", dxValueChange.value);
        this.setState({
            serviceTypeId: dxValueChange.value,
        });
    };


    handleChangeStatusTypeChange = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            statusId: dxValueChange.value,
        });
    };

    handleChangeRaisedBy = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            raisedById: dxValueChange.value
        });
    };


    componentDidUpdate = (prevprops: QueryGridSidePanelProps, prevState: PostApprovalPanelState) => {
    };

    //Fire the parent component's callback on an Apply click.
    onApplyButtonClicked = (isApplyClicked:boolean = false) => {
        var venueId: string;
        var clientId: string;
        var serviceTypeId: string;
        let raisedById:string;
        if (this.state.venueId == "0") {
            venueId = "";
        } else {
            venueId = this.state.venueId;
        }
        if (this.state.clientId == "0") {
            clientId = "";
        }
        else {
            clientId = this.state.clientId;
        }
        if (this.state.serviceTypeId == "0") {
            serviceTypeId = "";
        }
        else {
            serviceTypeId = this.state.serviceTypeId;
        }
        if (this.state.raisedById == "0") {
            raisedById = "";
        }
        else {
            raisedById = this.state.raisedById;
        }
        this.props.onApplyButtonClick(clientId, venueId, serviceTypeId, this.state.statusId,raisedById,isApplyClicked);
        GlobalService.setQueryFilterValues(this.state.clientId, this.state.venueId, this.state.serviceTypeId,this.state.statusId,this.state.raisedById);
    };
    ondateTodateFromSelection = () => {

    }
    render() {
        return (
            <div>
                <div className="row mt-4">
                    <div className="col-10">
                        <h4 className="sidebar__heading font-weight-bold">Filter</h4>
                    </div>
                </div>

                <>
                    {" "}
                    <div className="row mt-3">
                        <div className="col sidebar__heading">Client</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <SelectBox
                                dataSource={this.state.clientDataSource}
                                displayExpr={"value"}
                                valueExpr={"id"}
                                value={this.state.clientId}
                                onValueChanged={this.handleChangeClientTypeSelect}
                            />
                        </div>
                    </div>
                </>



                <>
                    {" "}
                    <div className="row mt-3">
                        <div className="col sidebar__heading">Venue</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <SelectBox
                                dataSource={this.state.venueListByClient}
                                displayExpr={"value"}
                                valueExpr={"id"}
                                value={this.state.venueId}
                                onValueChanged={this.handleChangeVenueTypeSelect}
                            />
                            {/* <LookupTypeListDynamic
                                lookupTypeIndex={LookupTypeIndexes.venueType}
                                onValueChanged={this.handleChangeVenueTypeSelect}
                                isRoot={false}
                                parentMappingId={this.state.clientId}
                                value={this.state.venueId}
                                displayExpression={"value"}
                                itemAll={true}
                            /> */}
                        </div>
                    </div>
                </>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Service</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <>
                            {this.state.serviceTypeloaded ? (
                                <SelectBox
                                    dataSource={this.state.serviceTypeDataSource}
                                    displayExpr={"value"}
                                    valueExpr={"id"}
                                    value={this.state.serviceTypeId}
                                    onValueChanged={this.handleChangeServiceTypeSelect}
                                />
                            ) : (
                                <span> Loading... </span>
                            )}{" "}
                        </>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Status</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <SelectBox
                            dataSource={this.state.statusTypeList}
                            displayExpr="value"
                            valueExpr="id"
                            onValueChanged={this.handleChangeStatusTypeChange}
                            value={this.state.statusId}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Raised By</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <SelectBox
                            dataSource={this.state.raisedByList}
                            displayExpr="value"
                            valueExpr="id"
                            onValueChanged={this.handleChangeRaisedBy}
                            value={this.state.raisedById}
                        />
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col-6 mx-auto">
                        <Button
                            className="btn btn-primary btn--large"
                            disabled={this.state.disableApplyButton}
                            //onClick={this.onApplyButtonClicked}
                            onClick={(e: ClickEvent) => {
                                //e.preventDefault();
                                this.onApplyButtonClicked(true)
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(QueryGridSidePanel);
