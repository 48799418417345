export const ConfigurableTextIndexes = {
    WebApprovalCleaningVariable_Header: "WebApprovalCleaningVariable_Header",
    WebApprovalCleaningVariable_Comment: "WebApprovalCleaningVariable_Comment",
    WebApprovalCleaningVariable_Footer: "WebApprovalCleaningVariable_Footer",
    WebApprovalCleaningWeeklyFixed_Header: "WebApprovalCleaningWeeklyFixed_Header",
    WebApprovalCleaningWeeklyFixed_Comment: "WebApprovalCleaningWeeklyFixed_Comment",
    WebApprovalCleaningWeeklyFixed_Footer: "WebApprovalCleaningWeeklyFixed_Footer",
    WebApprovalCleaningWeeklyVariableAdjustment_Header: "WebApprovalCleaningWeeklyVariableAdjustment_Header",
    WebApprovalCleaningWeeklyVariableAdjustment_Comment: "WebApprovalCleaningWeeklyVariableAdjustment_Comment",
    WebApprovalCleaningWeeklyVariableAdjustment_Footer: "WebApprovalCleaningWeeklyVariableAdjustment_Footer",
    WebApprovalCleaningWeeklyFIxedAdjustment_Header: "WebApprovalCleaningWeeklyFIxedAdjustment_Header",
    WebApprovalCleaningWeeklyFIxedAdjustment_Comment: "WebApprovalCleaningWeeklyFIxedAdjustment_Comment",
    WebApprovalCleaningWeeklyFIxedAdjustment_Footer: "WebApprovalCleaningWeeklyFIxedAdjustment_Footer",
    WebApprovalCleaningAdditional_Header: "WebApprovalCleaningAdditional_Header",
    WebApprovalCleaningAdditional_Comment: "WebApprovalCleaningAdditional_Comment",
    WebApprovalCleaningAdditional_Footer: "WebApprovalCleaningAdditional_Footer",
    WebApprovalEntertainment_Header: "WebApprovalEntertainment_Header",
    WebApprovalEntertainment_Comment: "WebApprovalEntertainment_Comment",
    WebApprovalEntertainment_Footer: "WebApprovalEntertainment_Footer",
    WebApprovalSecurityEquipment_Header: "WebApprovalSecurityEquipment_Header",
    WebApprovalSecurityEquipment_Comments: "WebApprovalSecurityEquipment_Comments",
    WebApprovalSecurityEquipment_Footer: "WebApprovalSecurityEquipment_Footer",
    WebAcceptanceCleaningWeeklyVariable_Header: "WebAcceptanceCleaningWeeklyVariable_Header",
    WebAcceptanceCleaningWeeklyVariable_Comment: "WebAcceptanceCleaningWeeklyVariable_Comment",
    WebAcceptanceCleaningWeeklyVariable_Footer: "WebAcceptanceCleaningWeeklyVariable_Footer",
    WebAcceptanceCleaningFixedAdjustment_Header: "WebAcceptanceCleaningFixedAdjustment_Header",
    WebAcceptanceCleaningFixedAdjustment_Comments: "WebAcceptanceCleaningFixedAdjustment_Comments",
    WebAcceptanceCleaningFixedAdjustment_Footer: "WebAcceptanceCleaningFixedAdjustment_Footer",
    WebAcceptanceCleaningVariableAdjustment_Header: "WebAcceptanceCleaningVariableAdjustment_Header",
    WebAcceptanceCleaningVariableAdjustment_Comments: "WebAcceptanceCleaningVariableAdjustment_Comments",
    WebAcceptanceCleaningVariableAdjustment_Footer: "WebAcceptanceCleaningVariableAdjustment_Footer",
    WebAcceptanceSecurityEquipmentAdjustment_Header: "WebAcceptanceSecurityEquipmentAdjustment_Header",
    WebAcceptanceSecurityEquipmentAdjustment_Comments: "WebAcceptanceSecurityEquipmentAdjustment_Comments",
    WebAcceptanceSecurityEquipmentAdjustment_Footer: "WebAcceptanceSecurityEquipmentAdjustment_Footer",
    WebApprovalSecurityPersonnel_Header: "WebApprovalSecurityPersonnel_Header",
    WebApprovalSecurityPersonnel_Comments: "WebApprovalSecurityPersonnel_Comments",
    WebApprovalSecurityPersonnel_Footer: "WebApprovalSecurityPersonnel_Footer",
    WebApprovalSecurityPersonnelAdjustment_Header: "WebApprovalSecurityPersonnelAdjustment_Header",
    WebApprovalSecurityPersonnelAdjustment_Comments: "WebApprovalSecurityPersonnelAdjustment_Comments",
    WebApprovalSecurityPersonnelAdjustment_Footer: "WebApprovalSecurityPersonnelAdjustment_Footer",
    WebApprovalEntertainment_Recommend: "WebApprovalEntertainment_Recommend",
    WebApprovalCleaningVariable_Confirm: "WebApprovalCleaningVariable_Confirm",
    WebAcceptanceSecurityPersonnelAdjustment_Header: "WebAcceptanceSecurityPersonnelAdjustment_Header",
    WebAcceptanceSecurityPersonnelAdjustment_Comments: "WebAcceptanceSecurityPersonnelAdjustment_Comments",
    WebAcceptanceSecurityPersonnelAdjustment_Footer: "WebAcceptanceSecurityPersonnelAdjustment_Footer",
    WebApprovalSecurityEquipmentAdjustment_Header: "WebApprovalSecurityEquipmentAdjustment_Header",
    WebApprovalSecurityEquipmentAdjustment_Comments: "WebApprovalSecurityEquipmentAdjustment_Comments",
    WebApprovalSecurityEquipmentAdjustment_Footer: "WebApprovalSecurityEquipmentAdjustment_Footer",
    Artisteditprofile_ArtistName: "Artisteditprofile_ArtistName",
    Artisteditprofile_ArtistType: "Artisteditprofile_ArtistType",
    Artisteditprofile_Facebook: "Artisteditprofile_Facebook",
    Artisteditprofile_Linkedin: "Artisteditprofile_Linkedin",
    Artisteditprofile_Twitter: "Artisteditprofile_Twitter",
    Artisteditprofile_Website: "Artisteditprofile_Website",
    Artisteditprofile_Biocomments: "Artisteditprofile_Biocomments",
    Artisteditprofile_Youtube: "Artisteditprofile_Youtube",
    Artisteditprofile_Instagram: "Artisteditprofile_Instagram",
    Artisteditprofile_Name: "Artisteditprofile_Name",
    Artisteditprofile_Mobile: "Artisteditprofile_Mobile",
    Artisteditprofile_Landline: "Artisteditprofile_Landline",
    Artisteditprofile_Email: "Artisteditprofile_Email",
    Artisteditprofile_AddressLine1: "Artisteditprofile_AddressLine1",
    Artisteditprofile_AddressLine2: "Artisteditprofile_AddressLine2",
    Artisteditprofile_Town_City: "Artisteditprofile_Town_City",
    Artisteditprofile_County: "Artisteditprofile_County",
    Artisteditprofile_Postcode: "Artisteditprofile_Postcode",
    Artisteditprofile_DateofBirth: "Artisteditprofile_DateofBirth",
    Artisteditprofile_UniqueTaxReference: "Artisteditprofile_UniqueTaxReference",
    Artisteditprofile_NationalInsurance: "Artisteditprofile_NationalInsurance",
    Artisteditprofile_PhotoID: "Artisteditprofile_PhotoID",
    Artisteditprofile_BusinessType: "Artisteditprofile_BusinessType",
    Artisteditprofile_BusinessName: "Artisteditprofile_BusinessName",
    Artisteditprofile_TradingNameFieldName: "Artisteditprofile_TradingNameFieldName",
    Artisteditprofile_TradingName: "Artisteditprofile_TradingName",
    Artisteditprofile_VATQuestionFieldName: "Artisteditprofile_VATQuestionFieldName",
    Artisteditprofile_VATNumber: "Artisteditprofile_VATNumber",
    Artisteditprofile_CompanyNumber: "Artisteditprofile_CompanyNumber",
    Artisteditprofile_RegisteredAddressLine1: "Artisteditprofile_RegisteredAddressLine1",
    Artisteditprofile_RegisteredAddressLine2: "Artisteditprofile_RegisteredAddressLine2",
    Artisteditprofile_RegisteredAddressLine3: "Artisteditprofile_RegisteredAddressLine3",
    Artisteditprofile_RegisteredAddressTown_City: "Artisteditprofile_RegisteredAddressTown_City",
    Artisteditprofile_RegisteredAddressCounty: "Artisteditprofile_RegisteredAddressCounty",
    Artisteditprofile_RegisteredAddressPostcode: "Artisteditprofile_RegisteredAddressPostcode",
    Artisteditprofile_Travel: "Artisteditprofile_Travel",
    Artisteditprofile_NameofBank: "Artisteditprofile_NameofBank",
    Artisteditprofile_BranchName: "Artisteditprofile_BranchName",
    Artisteditprofile_SortCode: "Artisteditprofile_SortCode",
    Artisteditprofile_AccountNumber: "Artisteditprofile_AccountNumber",
    Artisteditprofile_NameonAccount: "Artisteditprofile_NameonAccount",
    Artisteditprofile_Reference: "Artisteditprofile_Reference",
    Artisteditprofile_TermsConditions: "Artisteditprofile_TermsConditions",
    CookiePageIntro1: "CookiePageIntro1",
    CookiePageIntro2: "CookiePageIntro2",
    CookiePageAnalytics: "CookiePageAnalytics",
    CookiePageCommsMarket: "CookiePageCommsMarket",
    CookiePageSettings: "CookiePageSettings",
    Web_ShiftImport_Intro: "Web_ShiftImport_Intro",
    Web_ShiftImport_Step1_Intro: "Web_ShiftImport_Step1_Intro",
    Web_ShiftImport_Step2_Intro: "Web_ShiftImport_Step2_Intro",
    Web_ShiftImport_Step3_Intro_Ready: "Web_ShiftImport_Step3_Intro_Ready",
    Web_ShiftImport_Step3_Intro_Failed: "Web_ShiftImport_Step3_Intro_Failed",
    Findanartist_defaultradius: "Findanartist_defaultradius",
    Web_AddIncident_Title: "Web_AddIncident_Title",
    Web_AddIncident_Date: "Web_AddIncident_Date",
    Web_AddIncident_ReportedBy: "Web_AddIncident_ReportedBy",
    Web_AddIncident_ReportedRolePosition: "Web_AddIncident_ReportedRolePosition",
    Web_AddIncident_Description: "Web_AddIncident_Description",
    Web_AddIncident_InjuriesDescription: "Web_AddIncident_InjuriesDescription",
    Web_AddIncident_Time: "Web_AddIncident_Time",
    Web_LandingPage_Ents_Popup_OOH: "Web_LandingPage_Ents_Popup_OOH",
    Web_CheckInOutSummary_Intro1: "Web_CheckInOutSummary_Intro1",
    Web_CheckInOutSummary_Intro2: "Web_CheckInOutSummary_Intro2",
    CookiePopUpHeader: "CookiePopUpHeader",
    CookiePopUpIntro: "CookiePopUpIntro",
    VatPercentage: "VatPercentage"

}