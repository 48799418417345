import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons";

interface ViewActionCellProps {
    data: any;
}

interface ViewActionCellState {
    invoiceId: number;
    viewDisable: boolean;
}

class ViewActionCell extends React.Component<ViewActionCellProps> {
    state: ViewActionCellState;

    constructor(props: ViewActionCellProps) {
        super(props);
        this.state = {
            invoiceId: this.props.data.data.id,
            viewDisable: true,
        };
    }

    componentDidMount() {}

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    <Link
                        title="View"
                        className={(false ? "disabled " : "") + "icon-btn"}
                        to={{
                            pathname: "/BillingPageV2",
                            state: {
                                id: this.state.invoiceId.toString(),
                                viewDisable: this.state.viewDisable,
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </Link>
                </div>
            </div>
        );
    }
}

export default ViewActionCell;
