import { NumberBox, SelectBox } from "devextreme-react";
import React from "react";
import { LookupTypeItem } from "../../../services/LookupService";
import { NumberBoxOnValueChangedEvent, SelectBoxOnValueChangedEvent } from "../../../types/DevExtremeTypes";

interface CardConfirmHoursSectionProps {
    equipmentOriginalQuantity?: number;
    equipmentTotalBillableQuantity?: string;
    equipmentBillableQuantity?: number;
    hoursApproved?: string;
    itemType: boolean;
    confirmText?: string;
    isProvider: boolean;
    hours: number;
    minutes: string;
    lookup: LookupTypeItem[];
    handleChangeMinutes: (dxValueChange: SelectBoxOnValueChangedEvent) => void;
    handleChangeHours: (dxValueChange: NumberBoxOnValueChangedEvent) => void;
}

class CardConfirmHoursSection extends React.Component<CardConfirmHoursSectionProps> {
    constructor(props: CardConfirmHoursSectionProps) {
        super(props);
    }

    render() {
        var { isProvider, hours, minutes, lookup, confirmText, itemType } = this.props;
        return itemType ? (
            <>
                <div className="text-center font-weight-bold">{confirmText ? confirmText : ""}</div>
                <div className="row mt-3">
                    <div className="col-6">
                        <label className="euk-card__time-input">
                            <NumberBox
                                disabled={isProvider ? true : false}
                                format="#"
                                onValueChanged={this.props.handleChangeHours}
                                valueChangeEvent="keyup"
                                placeholder="0"
                                value={hours}
                            />
                            <span>Hours</span>
                        </label>
                    </div>
                    <div className="col-6">
                        <label className="euk-card__time-input">
                            <SelectBox
                                disabled={isProvider ? true : false}
                                dataSource={lookup}
                                displayExpr="value"
                                valueExpr="id"
                                onValueChanged={this.props.handleChangeMinutes}
                                value={minutes}
                            />
                            <span>Mins</span>
                        </label>
                    </div>
                </div>
            </>
        ) : (
            <div className="px-3 euk-card__calculation-box--right-aligned">
                <div className="row mb-2 font-weight-bold">
                    <div className="col-9 text-right">{isProvider ? "Total Hours" : "Previously Billed Hours"}</div>
                    <div className="col-3 euk-card__calculation-box__value">
                        {this.props.equipmentOriginalQuantity ? this.props.equipmentOriginalQuantity : ""}
                    </div>
                </div>

                <div className="row mb-2 euk-card__adjustment-text">
                    <div className="col-9 text-right">Hours Adjustment</div>
                    <div className="col-3 euk-card__calculation-box__value">
                        {this.props.equipmentTotalBillableQuantity ? this.props.equipmentTotalBillableQuantity : ""}
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-9 text-right">
                        {isProvider
                            ? itemType
                                ? "Total Hours:"
                                : "Total Hours Adjustment:"
                            : "Total hours to approve:"}
                    </div>
                    <div className="col-3 euk-card__calculation-box__value">
                        {this.props.equipmentTotalBillableQuantity ? this.props.equipmentTotalBillableQuantity : ""}
                    </div>
                </div>
            </div>
        );
    }
}
export default CardConfirmHoursSection;
