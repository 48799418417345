import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { TextArea, LoadIndicator } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import EntertainmentCompletionCardService from "../../services/EntertainmentCompletionCardService";
import EntertainmentCompletionCardUtils, { EntertainmentCardFormData } from "./EntertainmentCompletionCardUtils";
import outstandingActionsUtilities from "../../components/Outstanding-Actions/OutstandingActionsUtilities";
import { TextBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { AxiosResponse } from "axios";
import { LoadPanel } from "devextreme-react";
import CardWrapper3Part from "../Cards/CardWrapper3Part";
import { RaiseQueryFormData } from "../../services/AddEditPopUpService";
import sharedUtils from "../grid/sharedUtilities";

interface EntertainmentCompletionCardProps {
    history: any;
    location: any;
}
interface EntertainmentCompletionCardState {
    viewDataItem: EntertainmentCardFormData;
    queryItem: RaiseQueryFormData;
    isClickedOnConfirm: boolean;
    loadPanelVisible: boolean;
    isThumbsUpClicked: boolean;
    isThumbsDownClicked: boolean;
    mandatoryMessage: string;
    isFeedBackOnlyView: boolean;
    loadIndicatorVisible: boolean;
    isFeedBackSubmissionSuccessful: boolean;
    isRaiseQuerySuccess: boolean;
    isDisableSubmitButton: boolean;
}

class EntertainmentCompletionCard extends React.Component<EntertainmentCompletionCardProps> {
    utils: EntertainmentCompletionCardUtils;
    outstandingActionsUtility: outstandingActionsUtilities;
    service: EntertainmentCompletionCardService;
    sharedUtils: sharedUtils;
    state: EntertainmentCompletionCardState;
    constructor(props: EntertainmentCompletionCardProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.utils = new EntertainmentCompletionCardUtils();
        this.outstandingActionsUtility = new outstandingActionsUtilities();
        this.service = new EntertainmentCompletionCardService();
        var convertedViewDataItem = this.utils.initializeEntertainmentCardItem(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        //var convertedViewDataItem = this.utils.initializeEntertainmentCardItem("2");
        var convertedQueryFormData = this.utils.initializeQueryFormData(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        // var convertedQueryFormData = this.utils.initializeQueryFormData("2");
        this.state = {
            isClickedOnConfirm: false,
            viewDataItem: convertedViewDataItem,
            queryItem: convertedQueryFormData,
            loadPanelVisible: false,
            isThumbsUpClicked: false,
            isThumbsDownClicked: false,
            mandatoryMessage: "",
            isFeedBackOnlyView: this.props.location.state.FeedBack ? this.props.location.state.FeedBack : false,
            loadIndicatorVisible: false,
            isFeedBackSubmissionSuccessful: false,
            isRaiseQuerySuccess: false,
            isDisableSubmitButton: false,
        };
        this.onClickConfirm = this.onClickConfirm.bind(this);
        this.handleConfirmSuccess = this.handleConfirmSuccess.bind(this);
        this.viewDataSource = this.viewDataSource.bind(this);
        this.handleSuccessViewDataSource = this.handleSuccessViewDataSource.bind(this);
        this.OnSubmit = this.OnSubmit.bind(this);
        this.handleChangeQueries = this.handleChangeQueries.bind(this);
        this.handleChangeFeedBackComments = this.handleChangeFeedBackComments.bind(this);
        this.hideLoadpanel = this.hideLoadpanel.bind(this);
        this.handleStandardError = this.handleStandardError.bind(this);
        this.errorMessageDisplay = this.errorMessageDisplay.bind(this);
        this.handleFeedbackSubmissionSuccess = this.handleFeedbackSubmissionSuccess.bind(this);
        this.errorMessageDisplayForQuery = this.errorMessageDisplayForQuery.bind(this);
        this.OnSubmitQuery = this.OnSubmitQuery.bind(this);
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        this.viewDataSource();
    }

    viewDataSource() {
        // Retrieve form data from server if an ID was provided
        this.service
            .getCompletionCardData(this.props.location.state.id)
            .then(this.handleSuccessViewDataSource)
            .catch(this.handleStandardError);
    }
    handleSuccessViewDataSource(response: AxiosResponse<any>) {
        // Convert to proper data types
        var viewDataSource = this.utils.convertEntertainmentCardResponeToFormData(response.data.data);
        this.setState({
            viewDataItem: viewDataSource,
        });
    }
    handleStandardError(error: any) {
        var respMessage: string =
            "Entertainment completion card service failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }
    onClickConfirm() {
        this.setState({
            loadIndicatorVisible: true,
        });
        this.service
            .submitCompletionOfGigs(parseInt(this.state.viewDataItem.billableItemId))
            .then(this.handleConfirmSuccess)
            .catch(this.handleStandardError);
    }

    handleConfirmSuccess() {
        this.setState({
            isClickedOnConfirm: true,
            loadIndicatorVisible: false,
        });
    }
    errorMessageDisplayForQuery() {
        let errorMessage: string = "";
        if (!this.state.queryItem.queryDescription || !this.state.queryItem.queryDescription.trim()) {
            errorMessage = "Please Provide Query Comments.";
        }
        return errorMessage;
    }
    errorMessageDisplay() {
        let errorMessage: string = "";
        if (this.state.isThumbsDownClicked) {
            if (!this.state.viewDataItem.feedbackComments) {
                errorMessage = "Please Provide Feedback Comments.";
            } else {
                errorMessage = "";
            }
        }
        if (
            !this.state.isThumbsDownClicked &&
            !this.state.isThumbsUpClicked &&
            !this.state.queryItem.queryDescription &&
            !this.state.viewDataItem.feedbackComments
        ) {
            errorMessage = "Please provide feedback details or let us know of any issues.";
        }
        return errorMessage;
    }
    OnSubmitQuery() {
        var submissionError = this.errorMessageDisplayForQuery();
        this.setState({
            loadPanelVisible: true,
        });
        if (!submissionError) {
            this.service
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        } else {
            this.setState({
                mandatoryMessage: submissionError,
                loadPanelVisible: false,
            });
            return;
        }
    }
    OnSubmit() {
        var ErrorForBlank = this.errorMessageDisplay();
        if (ErrorForBlank) {
            this.setState({
                mandatoryMessage: ErrorForBlank,
                loadPanelVisible: false,
            });
            return;
        }
        if (this.state.queryItem.queryDescription && this.state.queryItem.queryDescription.trim()) {           
            this.setState({
                loadPanelVisible: true,
            });
            this.service
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }

        if (this.state.isThumbsDownClicked || this.state.isThumbsUpClicked || this.state.viewDataItem.feedbackComments) {
            var submissionError = this.errorMessageDisplay();
            this.setState({
                loadPanelVisible: true,
            });
            if (!submissionError) {
                var submitFeedbackRequest = this.utils.convertFormDataToBillableItemRequest(
                    this.state.viewDataItem,
                    this.state.viewDataItem.providerId,
                    "Artist"
                );
                this.service
                    .submitFeedBack(submitFeedbackRequest)
                    .then(this.handleFeedbackSubmissionSuccess)
                    .then(this.handleStandardError);
            } else if (submissionError) {
                this.setState({
                    mandatoryMessage: submissionError,
                    loadPanelVisible: false,
                });
                return;
            }
        }
    }
    handleFeedbackSubmissionSuccess() {
        this.hideLoadpanel();
        this.setState({
            isFeedBackSubmissionSuccessful: true,
            isDisableSubmitButton: true,
        });
        this.navigateBack();
    }
    hideLoadpanel() {
        this.setState({ loadPanelVisible: false });
    }
    handleQuerySubmissionSuccess = () => {
        this.hideLoadpanel();
        this.setState({
            isRaiseQuerySuccess: true,
            isDisableSubmitButton: true,
        });
        this.navigateBack();
    };

    navigateBack = () => {
        this.props.history.push({
            pathname: "/",
        });
    };

    handleQuerySubmissionFailure = (error: any) => {
        var respMessage: string =
            "uploadQueryFormData - Query submission failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    handleChangeFeedBackComments(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            viewDataItem: {
                ...this.state.viewDataItem,
                feedbackComments: dxValueChange.value,
                mandatoryMessage: "",
            },
        });
    }
    handleChangeQueries(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
                queryTypeLookupID: "11"
            },
        });
    }
    //Function that would toggle the state of the TextBox
    handleThumbsUpIconClick = () => {
        this.setState({
            viewDataItem: {
                ...this.state.viewDataItem,
                feedbackScore: 5,
            },
            isThumbsUpClicked: true,
            isThumbsDownClicked: false,
        });
    };

    handleThumbsDownIconClick = () => {
        this.setState({
            viewDataItem: {
                ...this.state.viewDataItem,
                feedbackScore: 1,
            },
            isThumbsUpClicked: false,
            isThumbsDownClicked: true,
        });
    };
    render() {
        var subService = "";
        var dateFrom = "";
        var titleText =
            this.state.isClickedOnConfirm || this.state.isFeedBackOnlyView
                ? "Entertainment - Confirmed"
                : "Entertainment - Awaiting Artist Confirmation";

        if (this.state.viewDataItem.service) {
            var fullServiceString = this.state.viewDataItem.service.split("-");
            subService = fullServiceString[1];
        }
        if (this.state.viewDataItem.date) {
            dateFrom = this.outstandingActionsUtility.convertddmmyyyyStringToCustomizedFormat(
                this.state.viewDataItem.date.toString()
            );
        }
        return (
            // for now this will only ever be security
            <form>
                <div className="mb-5">
                    <CardWrapper3Part
                        type="entertainer"
                        typeTitle={titleText}
                        enumeration={false}
                        header={
                            <>
                                {this.state.mandatoryMessage && this.state.isFeedBackSubmissionSuccessful ? (
                                    <div className="text-success">{this.state.mandatoryMessage}</div>
                                ) : this.state.mandatoryMessage && this.state.isRaiseQuerySuccess ? (
                                    <div className="text-success">{this.state.mandatoryMessage}</div>
                                ) : this.state.mandatoryMessage &&
                                  !this.state.isFeedBackSubmissionSuccessful &&
                                  !this.state.isRaiseQuerySuccess ? (
                                    <div className="unscheduled-shift">{this.state.mandatoryMessage}</div>
                                ) : null}
                                <div className="text-center">
                                    <header>
                                        <div className="euk-card__title">{this.state.viewDataItem.venueName}</div>
                                        <div className="">
                                            {this.state.viewDataItem.address} {this.state.viewDataItem.venueTownCity}{" "}
                                            {this.state.viewDataItem.venuePostCode}
                                        </div>
                                        <div className="">{this.state.viewDataItem.venue_Phone}</div>
                                    </header>
                                </div>
                            </>
                        }
                        body={
                            <div className="text-center">
                                <div>{dateFrom}</div>
                                <div>
                                    {this.state.viewDataItem.shift_Start}{" "}
                                    {this.state.viewDataItem.shift_End ? "-" + this.state.viewDataItem.shift_End : null}
                                </div>
                                <div>{this.state.viewDataItem.performanceInformation}</div>
                                <div>
                                    {subService} {this.state.viewDataItem.genre}
                                </div>
                                <div>{this.state.viewDataItem.reoccurance == "Yes" ? "Residency" : null}</div>
                                <div>
                                    {this.state.viewDataItem.status}:
                                    {this.sharedUtils.thousandsSeparator(
                                        this.state.viewDataItem.provider_Pay_Amount_Local_Ccy.toString()
                                    )}
                                </div>
                            </div>
                        }
                        footer={
                            <div className="text-center">
                                <LoadPanel
                                    //shading = {false}
                                    //shadingColor="rgba(0,0,0,0.2)"
                                    visible={this.state.loadPanelVisible}
                                />
                                {this.state.isClickedOnConfirm || this.state.isFeedBackOnlyView ? null : (
                                    <div>
                                        <div className="mb-3">
                                            You must confirm that the gig was performed for your payment to proceed to
                                            venue verification:
                                        </div>
                                        <div className="mb-3">
                                            <LoadIndicator visible={this.state.loadIndicatorVisible}></LoadIndicator>
                                            <button
                                                className="btn btn-primary"
                                                // hidden={this.state.closeButton}
                                                disabled={false}
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.onClickConfirm();
                                                }}
                                            >
                                                Confirm Gig Performed
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {this.state.isClickedOnConfirm || this.state.isFeedBackOnlyView ? (
                                    <>
                                        <div className="text-center mb-3">
                                            <div className="text-success mb-3">
                                                <FontAwesomeIcon
                                                    className="fa_check_circle_style"
                                                    icon={faCheckCircle}
                                                />
                                                <div className="text-uppercase font-weight-bold">Gig performed</div>
                                                <div>Thanks for confirming!</div>
                                            </div>
                                            Would you recommend this venue to another artist?
                                            <Link
                                                className={`faThumbsUp-btn ${
                                                    this.state.isThumbsUpClicked ? "text-success" : ""
                                                }`}
                                                onClick={this.handleThumbsUpIconClick}
                                                to="#"
                                            >
                                                <FontAwesomeIcon icon={faThumbsUp} />
                                            </Link>
                                            <Link
                                                className={`faThumbsDown-btn ${
                                                    this.state.isThumbsDownClicked ? "text-danger" : ""
                                                }`}
                                                onClick={this.handleThumbsDownIconClick}
                                                to="#"
                                            >
                                                <FontAwesomeIcon icon={faThumbsDown} />
                                            </Link>
                                        </div>
                                        <div className="mb-3">
                                            Please leave a feedback for Event UK
                                            <TextArea
                                                height={80}
                                                placeholder="Please write here..."
                                                onValueChanged={this.handleChangeFeedBackComments}
                                            ></TextArea>
                                        </div>
                                    </>
                                ) : null}
                                <div className="mb-3">
                                    Were there any issues?
                                    <TextArea
                                        height={80}
                                        placeholder="Please write here..."
                                        onValueChanged={this.handleChangeQueries}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <button
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.navigateBack();
                                            }}
                                            className="btn btn--ghost gig-detail-layout__button float-left btn--large"
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        {this.state.isFeedBackOnlyView || this.state.isClickedOnConfirm ? (
                                            <button
                                                className="btn btn-primary btn--large"
                                                // hidden={this.state.closeButton}
                                                disabled={this.state.isDisableSubmitButton}
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.OnSubmit();
                                                }}
                                            >
                                                Submit Feedback
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn--ghost btn--large"
                                                // hidden={this.state.closeButton}
                                                disabled={this.state.isDisableSubmitButton}
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.OnSubmitQuery();
                                                }}
                                            >
                                                Raise Query
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
            </form>
        );
    }
}

export default EntertainmentCompletionCard;
