import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GigDetailedCard } from "../EntertainmentLanding/GigDetailedCard";
import DarkModePageTemplate from "./Templates/DarkModePageTemplate";

interface DetailedGigPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    history: any;
}

class DetailedGigPage extends Component<DetailedGigPageComponentProps> {
    render() {
        // let id = this.props.location.state ? this.props.location.state.id : "";
        let header = "Your Upcoming Gig";

        return (
            <DarkModePageTemplate>
                <div className="page-content">
                    <section className="container accent-container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-5 mb-3">
                                <h3 className="text-left font-weight-bold mt-4">
                                    <span>{header}</span>
                                </h3>
                                <GigDetailedCard location={this.props.location} history={this.props.history} />
                            </div>
                        </div>
                    </section>
                </div>
            </DarkModePageTemplate>
        );
    }
}

export default withRouter(DetailedGigPage);
