import React from "react";
import { LoadIndicator } from "devextreme-react";
import { WithTranslation } from "react-i18next";

interface MaskedStatusForOverrideProps extends WithTranslation {
    data: any;
}

interface MaskedStatusForOverrideState {
    overrideStatus: string;
}

class MaskedStatusForOverride extends React.Component<MaskedStatusForOverrideProps> {
    state: MaskedStatusForOverrideState;
    constructor(props: MaskedStatusForOverrideProps) {
        super(props);
        this.state = {
            overrideStatus: this.props.data.data.visibilityDateOverride ? "Yes" : "No",
        };
    }

    //This is needed here to check for some conditions when the component mounts.
    componentDidMount() {}
    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2 d-flex">
                        <span className="status-message-color">{this.state.overrideStatus}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default MaskedStatusForOverride;
