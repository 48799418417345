import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, TextBox, Slider } from "devextreme-react";
import { AxiosResponse } from "axios";
import LookupService, { LookupTypeIndexes } from "../../services/LookupService";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import {
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
    SliderOnChangedEvent,
} from "../../types/DevExtremeTypes";
import CostReportService from "../../services/CostReportService";
import ArtistUtilities from "../FindAnArtist/FindAnArtistUtils";
import GlobalService from "../../services/GlobalService";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";

interface GeographyPanelProps extends WithTranslation {
    setPostCodeDistance: (postCode: string, distance: string) => void;
    location: any;
    gridClient: string;
    gridVenue: string;
}

interface GeographyPanelState {
    clientId: string;
    venueId: string;
    postCode: string;
    radius: number;
    venueList: any[];
}

class GeographyPanel extends Component<GeographyPanelProps> {
    lookupService: LookupService;
    state: GeographyPanelState;
    Utils: ArtistUtilities;
    Service: CostReportService;
    // utils: CostReportUtils;
    constructor(props: GeographyPanelProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.Utils = new ArtistUtilities();
        // this.utils = new CostReportUtils();
        this.state = {
            clientId: "",
            venueId: "",
            postCode: "",
            radius: 0,
            venueList: [],
        };
    }

    //Retrieve the lookup values inside the mount
    componentDidMount() {
        this.retrieveLookupValues(LookupTypeIndexes.clientType);
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.clientType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.clientType)
                    .then(this.handleClientLookupSuccess)
                    .catch((error) => { });
                break;
            case LookupTypeIndexes.venueType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.venueType)
                    .then(this.handleVenueLookupSuccess)
                    .catch((error) => { });
                break;
        }

    };

    handleClientLookupSuccess = (response: AxiosResponse<any>) => {
        if (response && response.data && response.data.data.length > 0) {
            this.setState(
                {
                    clientId: "",
                },
                () => this.retrieveLookupValues(LookupTypeIndexes.venueType)
            );
        } else {
            this.props.setPostCodeDistance("null", "");
        }
    };

    handleVenueLookupSuccess = (response: AxiosResponse<any>) => {
        var radiusValue = "";
        var configurationValues = GlobalService.configurationValue();
        if (configurationValues.length > 0) {
            radiusValue = configurationValues.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Findanartist_defaultradius;
            })[0].value;
        }
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0)
        // &&
        // !this.isBlank(this.state.clientId)
        {
            var venuesList: any[] = response.data.data;
            var venues = venuesList.filter((item) => {
                return item.parentMappingId == this.state.clientId;
            });
            if(this.props.gridClient !== "" && this.props.gridVenue !== "" && this.props.location?.state?.navigation == "gigRequestEdit"){
                this.setState({
                    venueList: venuesList,
                    venueId: this.props.gridVenue,
                    clientId: this.props.gridClient,
                    postCode: "",
                    radius: parseInt(radiusValue),
                });
            }
            else{
                this.setState({
                    venueList: venuesList,
                    venueId: "",
                    postCode: "",
                    radius: parseInt(radiusValue),
                });
            }

            this.props.setPostCodeDistance(venues[0].postCode, radiusValue);

        }
    };

    isBlank = (param: string) => {
        return !param || /^\s*$/.test(param) || 0 === param.length;
    };

    handleChangeClient = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            clientId: dxValueChange.value,
            venueId: "",
            postCode: "",
        }); //When a client dropdown is changed, initiate the API call, and default the VenueID to "", because the State is still holding the previously selected VenueId.
    };

    handleChangeVenue = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        var postCodeValue: string = this.Utils.extractPostCode(this.state.venueList, dxValueChange.value);
        this.setState({
            venueId: dxValueChange.value,
            postCode: postCodeValue,
        });
    };

    handleChangePostCode = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            postCode: dxValueChange.value,
        });
    };

    handleChangeRadius = (dxValueChange: SliderOnChangedEvent) => {
        this.setState({
            radius: dxValueChange.value,
        });
        if (dxValueChange && dxValueChange.element) {
            dxValueChange.element.onkeydown = (event: any) => {
                if (event.key && event.key === 'Enter') {
                    this.onSearchButtonClicked();
                }
            }
        } 
    };
    onSearchSubmit = (e:any)=>{
        e.preventDefault();
        this.onSearchButtonClicked();
    }

    onSearchButtonClicked = () => {
        this.props.setPostCodeDistance(this.state.postCode, this.state.radius.toString());
    };

    render() {
        return (
            <div>
                <form onSubmit={this.onSearchSubmit}>
                <>
                    {" "}
                    <div className="row mt-3">
                        <div className="col sidebar__heading">Client</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <DropDownFromLookupTable
                                lookupTypeIndex={LookupTypeIndexes.clientType}
                                onValueChanged={this.handleChangeClient}
                                value={this.state.clientId}
                            />
                            <input
                                data-testid={LookupTypeIndexes.clientType}
                                type="hidden"
                                name={LookupTypeIndexes.clientType}
                            ></input>
                        </div>
                    </div>
                </>

                <>
                    {" "}
                    <div className="row mt-3">
                        <div className="col sidebar__heading">Venue</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <DropDownFromLookupTableDynamic
                                lookupTypeIndex={LookupTypeIndexes.venueType}
                                onValueChanged={this.handleChangeVenue}
                                isRoot={false}
                                parentMappingId={this.state.clientId}
                                value={this.state.venueId}
                            />
                        </div>
                    </div>
                </>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Postcode</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <TextBox
                            width={"100%"}
                            readOnly={false}
                            value={this.state.postCode}
                            onValueChanged={this.handleChangePostCode}
                        />
                    </div>
                    <div className="col">
                        {/* <Button
                            className="btn btn-primary btn--large"
                            disabled={false}
                            onClick={this.onSearchButtonClicked}
                        >
                            Search
                        </Button> */}
                        <button type='submit'
                            className="btn btn-primary btn--large"
                            disabled={false}>
                            Search
                        </button>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col sidebar__heading">Radius</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <Slider
                            min={0}
                            max={100}
                            value={this.state.radius}
                            step={5}
                            onValueChanged={this.handleChangeRadius}
                            tooltip={{
                                enabled: true,
                                showMode: "always",
                                position: "bottom",
                                format: () => {
                                    return `${this.state.radius} Miles`;
                                },
                            }}
                        />
                    </div>
                </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(GeographyPanel);
