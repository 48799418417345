import React from "react";
import { LoadPanel } from "devextreme-react";
import { TextBoxOnValueChangedEvent, SelectBoxOnValueChangedEvent, NumberBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { AxiosResponse } from "axios";
import UserService, { RoleGroupNames } from "../../services/UserService";
import outstandingActionsUtilities, {
    AddEditFormData,
    CalculationRequest,
    itemTypes,
    minuteLookup,
    contractHourLookup,
} from "./OutstandingActionsUtilities";
import OutstandingApprovalService, {
    BillableItemResponse,
    Status,
    BillableItemSubmitRequest
} from "../../services/OutstandingApprovalService";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";
import LookupService, { LookupTypeItem } from "../../services/LookupService";
import AddEditPopUpService, { CalculationResponse, RaiseQueryFormData } from "../../services/AddEditPopUpService";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import gridUtils from "../grid/GridUtilities";
import { ApprovalCardTypes } from "../../services/FileService";
import sharedUtils from "../grid/sharedUtilities";
import CardWrapper3Part from "../Cards/CardWrapper3Part";
import CardHeaderSection from "../Cards/CardSections/CardHeaderSection";
import CardCommentsSection from "../Cards/CardSections/CardCommentsSection";
import CardCleaningSection from "../Cards/CardSections/CardCleaningSection";
import CardAdditionalCleaningSection from "../Cards/CardSections/CardAdditionalCleaningSection";
import CardConfirmHoursSection from "../Cards/CardSections/CardConfirmHoursSection";
import { withTranslation, WithTranslation } from "react-i18next";
import GlobalService from "../../services/GlobalService";

// all of this info will be needed to populate a card
// not sure if status (late/on time) will come from db or be dervied here
interface CleaningApprovalCardProps extends WithTranslation {
    outstandingCardData: BillableItemResponse;
    renderCards: (billableItemId: string, serviceTypeId: string) => void; // A callback to the card List component once the Shift has been accepted or Approved.
    configuarationValueIndex: string[];
    cardUIType: string; //The Prop specifies which type of card i.e Weekly/Additional cleaning view to be rendered on the screen.
    totalNumber: number;
    serialNumber: number;
    configurationValueArray: any;
    isApproveAll?: boolean;
    setCost: (currentProviderAmount: number, previousProviderAmount: number,
        currentBillableHours: string, previousBillableHours: string,
        cardSectionVal: string, acoordionNo: number, totalQueriedCost: string,
        totalNonQueriedCost: string, totalQueriedMinutes: string, totalNonQueriedMinutes: string, billableItemId: string,
        serviceTypeId: string, summaryBreakDownKey: number, navigatedFrom: string,
        paymentId: string, invoiceId: string, billableQuantity: string) => void;
    cardSection?: string;
    accordionNo?: number;
    totalNonQueriedCost?: string;
    totalNonQueriedMinutes?: string;
    totalQueriedCost?: string;
    totalQueriedMinutes?: string;
}

interface CleaningApprovalCardState {
    editViewDataItem: BillableItemResponse;
    convertedEditViewDataItem: AddEditFormData;
    disableBilableStartFinishFields: boolean;
    cardText: string;
    buttonText: string;
    queryButtonText: string;
    errorMessage: string;
    queryItem: RaiseQueryFormData;
    loadPanelVisible: boolean;
    equipmentText: string;
    hours: number;
    minutes: string;
    isProvider: boolean;
    calculationErrorMessage: [];
    disableApproveButton: boolean;
    disableQueryButton: boolean;
    disableSaveButton: boolean;
    isSuccssApproved?: boolean;
}

class CleaningApprovalCard extends React.Component<CleaningApprovalCardProps> {
    lookupService: LookupService;
    state: CleaningApprovalCardState;
    outstandingApprovalService: OutstandingApprovalService;
    utils: outstandingActionsUtilities;
    addEditUtils: addEditPopupUtils;
    sharedUtils: sharedUtils;
    editViewService: AddEditPopUpService;
    gridUtils: gridUtils;
    //service that would post the data back to the server.
    constructor(props: CleaningApprovalCardProps) {
        super(props);
        //Initialize services
        this.lookupService = new LookupService();
        this.outstandingApprovalService = new OutstandingApprovalService();
        this.utils = new outstandingActionsUtilities();
        this.addEditUtils = new addEditPopupUtils();
        this.sharedUtils = new sharedUtils();
        this.editViewService = new AddEditPopUpService();
        this.gridUtils = new gridUtils();
        //Initialize the service.
        this.outstandingApprovalService = new OutstandingApprovalService();
        // var convertedEditViewDataItem = this.utils.initializeBillableItem();
        var initializedBillableItem = this.utils.initializeBillableItem();
        var convertedQueryFormData = this.addEditUtils.initializeQueryFormData(
            this.props.outstandingCardData.billableItemId
                ? this.props.outstandingCardData.billableItemId.toString()
                : "",
            this.props.outstandingCardData.venueId,
            "",
            "Shift / Item Status Query",
            ""
        );
        this.state = {
            editViewDataItem: this.props.outstandingCardData,
            convertedEditViewDataItem: initializedBillableItem,
            disableBilableStartFinishFields: false,
            cardText: "",
            buttonText: "Approve",
            queryButtonText: "Raise Query",
            errorMessage: "",
            queryItem: convertedQueryFormData,
            loadPanelVisible: false,
            equipmentText: "Cleaning works carried out",
            hours: 0,
            minutes: "00",
            isProvider: false,
            calculationErrorMessage: [],
            disableApproveButton: this.props.isApproveAll ? this.props.isApproveAll : false,
            disableQueryButton: false,
            disableSaveButton: false,
            isSuccssApproved: false
        };
    }

    componentDidMount() {
        var convertedDataSource = this.utils.convertBillableItemResponeToFormData(this.state.editViewDataItem);
        var quantitySubFields: string[] = this.utils.splitQuantityIntoHoursMinutesFormat(
            this.state.editViewDataItem.equipmentQuantity
        );
        this.setState(
            {
                convertedEditViewDataItem: convertedDataSource,
                hours: this.sharedUtils.convertStringToInt(quantitySubFields[0]),
                minutes: quantitySubFields[1],
            },
            () => this.disableRoleBasedFields(convertedDataSource.itemTypeId)
        );

        this.enableDisableQueryApproveButton(convertedDataSource);
    }


    enableDisableQueryApproveButton = (billableItem: AddEditFormData) => {
        var isPayment: boolean = false;
        if (billableItem && billableItem.paymentId) {
            isPayment = true;
        }
        if (billableItem.billableStatusLookupId == Status.Queried) {
            this.setState({
                disableQueryButton: true,
                queryButtonText: "Queried",
                disableApproveButton: true
            })
        }
        else if (billableItem.isAccrual && !billableItem.paymentId && !billableItem.approval_Date ||
            !billableItem.isAccrual && !billableItem.approval_Date && !billableItem.invoiceId) {
            this.setState({
                disableApproveButton: false
            });
        } else if (billableItem.approval_Date) {
            this.setState({
                disableApproveButton: true,
                disableSaveButton: true,
                buttonText: "Approved"
            });
        } else {
            this.setState({
                disableApproveButton: true
            });
        }
        if (isPayment) {
            this.setState({
                disableApproveButton: true,
                disableSaveButton: true,
                buttonText: "Paid"
            })
        }
    }


    handleChangeHours = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            hours: dxValueChange.value,
        });
        this.reCalculateQuantity();
    };

    handleChangeMinutes = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            minutes: dxValueChange.value,
        });
        this.reCalculateQuantity();
    };

    //Helper function to recalulate the Quantity when either of the minute or Hour field varies.
    reCalculateQuantity = () => {
        var delimeter = ".";
        var { hours, minutes } = this.state; //Destructure the state to pull out the contents.
        var convertedHours: string = this.sharedUtils.convertNumberToString(hours);
        var quantity: string = `${convertedHours}${delimeter}${minutes}`;
        this.setState({
            convertedEditViewDataItem: {
                ...this.state.convertedEditViewDataItem,
                equipmentQuantity: this.sharedUtils.convertStringToFloat(quantity),
                equipmentBillableQuantity: this.sharedUtils.convertStringToFloat(quantity),
            },
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
        this.financialCalculations();
    };

    financialCalculations = () => {
        let calCulationObject: CalculationRequest = this.utils.constructCalculationObject(
            this.state.convertedEditViewDataItem
        );

        this.outstandingApprovalService
            .uploadFormData(calCulationObject)
            .then(this.handleCalculationSuccess)
            .catch((err) => {
                this.setState({
                    calculationErrorMessage:
                        err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    IsShowSuccess: false,
                });
            })
            .finally(() => {
                // this.hideLoadIndicator();
            });
    };

    handleCalculationSuccess = (response: AxiosResponse<any>) => {
        var serverResponse: CalculationResponse = response.data.data;
        var cardStatePostCalculation: AddEditFormData = this.utils.alterStatePostCalculation(
            this.state.convertedEditViewDataItem,
            serverResponse,
            this.props.configuarationValueIndex
        );
        var previousProviderPayAmountVal = this.state.convertedEditViewDataItem.provider_Pay_Amount_Local_Ccy;
        this.setState({
            convertedEditViewDataItem: cardStatePostCalculation,
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
        this.props.setCost(cardStatePostCalculation.provider_Pay_Amount_Local_Ccy, previousProviderPayAmountVal, "", "",
            this.props.cardSection ? this.props.cardSection : "", this.props.accordionNo ? this.props.accordionNo : 0,
            this.props.totalQueriedCost ? this.props.totalQueriedCost : "", this.props.totalNonQueriedCost ? this.props.totalNonQueriedCost : "", this.props.totalQueriedMinutes ? this.props.totalQueriedMinutes : "", this.props.totalNonQueriedMinutes ? this.props.totalNonQueriedMinutes : "",
            this.props.outstandingCardData.billableItemId, this.props.outstandingCardData.serviceTypeId, 0, "calculation", this.state.editViewDataItem.paymentId, this.state.editViewDataItem.invoiceId, this.state.editViewDataItem.equipmentBillableQuantity);
    };

    //Arrow functions doesn't need to be bind in the constructor, the context of "this" is set inside of them.
    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            convertedEditViewDataItem: {
                ...this.state.convertedEditViewDataItem,
                comments: dxValueChange.value,
            },
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
        this.setState({
            queryItem: {
                ...this.state.queryItem,
                queryDescription: dxValueChange.value,
                serviceTypeId: this.state.editViewDataItem.serviceTypeId,
                clientId: this.state.editViewDataItem.clientId,
                venueId: this.state.editViewDataItem.venueId
            },
        }, () => GlobalService.setEditedApprovalItems(this.state.convertedEditViewDataItem));
    };

    handleSubmit = () => {
        var isAmend: boolean = false;
        var isAbsent: string = "";
        var isNoCharge: string = "";
        var { itemTypeId, equipmentBillableQuantity, equipmentScheduleQuantity } = this.state.convertedEditViewDataItem;
        var isBillableFieldsBlank: boolean = true;
        var acceptanceDate = undefined;
        var approvalDate = undefined;
        var statusId: string = "";

        this.setState({
            loadPanelVisible: true,
        });

        if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) || itemTypeId == "12" || itemTypeId == "14") {
            acceptanceDate = new Date();
            statusId = Status.Accepted;
        } else {
            approvalDate = new Date();
            statusId = Status.Approved;
        }

        if (itemTypeId == itemTypes.Original || itemTypeId == "") {
            if (equipmentBillableQuantity - equipmentScheduleQuantity != 0) {
                isAmend = true;
            }
        }

        var billableItemSubmitRequest = this.utils.convertFormDataToBillableItemRequest(
            this.state.convertedEditViewDataItem,
            statusId,
            isBillableFieldsBlank,
            acceptanceDate,
            approvalDate,
            isAbsent,
            isNoCharge,
            isAmend,
            "",
            "ApprovalCard"
        );

        var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
        billableItemSubmitRequestArr.push(billableItemSubmitRequest);

        this.outstandingApprovalService
            .uploadEditAddData(billableItemSubmitRequestArr)
            .then(this.handleSubmitSuccess)
            .catch((err) => {
                this.setState({
                    calculationErrorMessage:
                        err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of approval.
                    loadPanelVisible: false,
                });
                var respMessage: string = "uploadEditAddData failed with response: " + JSON.stringify(err);

                if (!this.outstandingApprovalService.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    };

    handleSave = () => {
        var isAmend: boolean = false;
        var isAbsent: string = "";
        var isNoCharge: string = "";
        this.setState({
            loadPanelVisible: true,
        });

        var billableItemSubmitRequest = this.utils.convertFormDataToBillableItemRequest(
            this.state.convertedEditViewDataItem,
            "",
            false,
            undefined,
            undefined,
            isAbsent,
            isNoCharge,
            isAmend,
            "",
            "ApprovalCard"
        );

        var billableItemSubmitRequestArr: BillableItemSubmitRequest[] = [];
        billableItemSubmitRequestArr.push(billableItemSubmitRequest);

        this.outstandingApprovalService
            .uploadEditAddData(billableItemSubmitRequestArr)
            .then(this.handleSaveSuccess)
            .catch((err) => {
                this.setState({
                    calculationErrorMessage:
                        err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of approval.
                    loadPanelVisible: false,
                });
                var respMessage: string = "uploadEditAddData failed with response: " + JSON.stringify(err);

                if (!this.outstandingApprovalService.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    }

    handleSaveSuccess = () => {
        this.hideLoadpanel();
    };

    handleSubmitSuccess = () => {
        this.hideLoadpanel();
        this.setState({
            disableApproveButton: true,
            disableSaveButton: true,
            isSuccssApproved: true,
            buttonText: "Approved"
        });
        this.props.renderCards(
            this.state.convertedEditViewDataItem.billableItemId,
            this.state.convertedEditViewDataItem.serviceTypeId
        );
    };

    disableRoleBasedFields = (itemTypeId: string) => {
        var cardText: string = "";
        if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            if (this.state.convertedEditViewDataItem.serviceSubTypeId == ApprovalCardTypes.AdditionalCleaning) {
                cardText = "Please approve or raise a query";
            } else if (
                this.state.convertedEditViewDataItem.serviceSubTypeId == ApprovalCardTypes.WeeklyCleaning &&
                this.state.convertedEditViewDataItem.contractHours &&
                this.state.convertedEditViewDataItem.contractHours.variableHours == "true"
            ) {
                cardText = "Please approve or raise a query if the hours have changed from the contracted hours";
            } else {
                cardText = "Please approve or raise a query if there are any changes to your contracted cleaning hours";
            }
            this.setState({
                cardText: cardText,
            });
        } else if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) || itemTypeId == "12" || itemTypeId == "14") {
            if (this.state.convertedEditViewDataItem.itemTypeId != itemTypes.Original) {
                cardText = "Please accept the above adjustments or raise a query";
            } else {
                cardText = "Please accept the above changes or raise a query if required";
            }
            this.setState({
                cardText: cardText,
                buttonText: "Accept",
                equipmentText: "Equipment supplied to",
                isProvider: true,
            });
        }
    };

    onSubmitQuery = () => {
        let querySubmissionError: boolean = true;
        querySubmissionError = this.sharedUtils.validationCheckFunction(this.state.convertedEditViewDataItem.comments);
        if (!querySubmissionError) {
            this.setState({
                errorMessage: this.props.t("approvalCard.weeklyCleaning.queryText.error"),
            });
        } else {
            this.setState({
                errorMessage: "",
                loadPanelVisible: true,
            });
            this.editViewService
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
        var previousProviderPayAmountVal = this.state.convertedEditViewDataItem.provider_Pay_Amount_Local_Ccy;
        this.props.setCost(0, previousProviderPayAmountVal, "", "",
            this.props.cardSection ? this.props.cardSection : "", this.props.accordionNo ? this.props.accordionNo : 0,
            this.props.totalQueriedCost ? this.props.totalQueriedCost : "", this.props.totalNonQueriedCost ? this.props.totalNonQueriedCost : "", this.props.totalQueriedMinutes ? this.props.totalQueriedMinutes : "", this.props.totalNonQueriedMinutes ? this.props.totalNonQueriedMinutes : "",
            this.props.outstandingCardData.billableItemId, this.props.outstandingCardData.serviceTypeId, 0, "query", this.state.editViewDataItem.paymentId, this.state.editViewDataItem.invoiceId, this.state.editViewDataItem.equipmentBillableQuantity);
    };

    handleQuerySubmissionSuccess = () => {
        this.setState({
            queryButtonText: "Queried",
            disableQueryButton: true,
            disableApproveButton: true
        });
        this.hideLoadpanel();
        this.props.renderCards(
            this.state.convertedEditViewDataItem.billableItemId,
            this.state.convertedEditViewDataItem.serviceTypeId
        );
    };

    handleQuerySubmissionFailure = (error: any) => {
        var respMessage: string =
            "uploadQueryFormData - query submission failed with response: " + JSON.stringify(error);

        if (!this.editViewService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.hideLoadpanel();
    };

    hideLoadpanel = () => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    renderCustomData = (data: LookupTypeItem) => {
        return <div className="bg-warning font-weight-bold"> {data.value} </div>;
    };

    clearErrorMessage = () => {
        this.setState({
            errorMessage: "",
        });
    };

    render() {
        var headerTextAsPerRole: string = "";
        var bodyTextAsPerRole: string = "";
        var cleaningHeaderText: string = "";
        var cleaningCommentText: string = "";
        var cleaningFooterText: string = "";
        var cleaningAdjustmentHeaderText: string = "";
        var cleaningAdjustmentCommentText: string = "";
        var cleaningAcceptanceAdjustmentHeaderText: string = "";
        var cleaningAcceptanceAdjustmentCommentText: string = "";
        var additionalCleaningHeaderText: string = "";
        var additionalCleaningCommentText: string = "";
        var variableCleaningHeaderText: string = "";
        var variableCleaningCommentText: string = "";
        var variableCleaningAcceptanceHeaderText: string = "";
        var variableCleaningAcceptanceCommentText: string = "";
        var variableCleaningConfirmText: string = "";
        var variableAdjustmentCleaningHeaderText: string = "";
        var variableAdjustmentCleaningCommentText: string = "";
        var variableAcceptanceAdjustmentCleaningHeaderText: string = "";
        var variableAcceptanceAdjustmentCleaningCommentText: string = "";

        if (this.props.configurationValueArray.length > 0) {
            additionalCleaningHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningAdditional_Header;
            })[0].value;
            additionalCleaningCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningAdditional_Comment;
            })[0].value;
            variableCleaningHeaderText = this.props.configurationValueArray.filter((item: any) => {
               return item.id == ConfigurableTextIndexes.WebApprovalCleaningVariable_Header;
            })[0].value;
            variableCleaningCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningVariable_Comment;
            })[0].value;
            variableCleaningConfirmText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningVariable_Confirm;
            })[0].value;
            variableAdjustmentCleaningHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningWeeklyVariableAdjustment_Header;
            })[0].value;
            variableAdjustmentCleaningCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningWeeklyVariableAdjustment_Comment;
            })[0].value;
            cleaningHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningWeeklyFixed_Header;
            })[0].value;
            cleaningCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningWeeklyFixed_Comment;
            })[0].value;
            cleaningAdjustmentHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningWeeklyFIxedAdjustment_Header;
            })[0].value;
            cleaningAdjustmentCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebApprovalCleaningWeeklyFIxedAdjustment_Comment;
            })[0].value;
            cleaningAcceptanceAdjustmentHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebAcceptanceCleaningFixedAdjustment_Header;
            })[0].value;
            cleaningAcceptanceAdjustmentCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebAcceptanceCleaningFixedAdjustment_Comments;
            })[0].value;
            variableAcceptanceAdjustmentCleaningHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebAcceptanceCleaningVariableAdjustment_Header;
            })[0].value;
            variableAcceptanceAdjustmentCleaningCommentText = this.props.configurationValueArray.filter((item: any) => {
               return item.id == ConfigurableTextIndexes.WebAcceptanceCleaningVariableAdjustment_Comments;
            })[0].value;
            variableCleaningAcceptanceHeaderText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebAcceptanceCleaningWeeklyVariable_Header;
            })[0].value;
            variableCleaningAcceptanceCommentText = this.props.configurationValueArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.WebAcceptanceCleaningWeeklyVariable_Comment;
            })[0].value;
        }

        var exceededSign: string = "+";
        const {
            equipmentOriginalQuantity,
            serviceSubTypeId,
            venuePostCode,
            venueName,
            provider,
            itemTypeId,
            service,
            equipmentBillableQuantity,
            otherDescription,
            requestApprover,
            requestDate,
            contractHours,
            client,
        } = this.state.convertedEditViewDataItem; //Destructuring the state variables.
        const startDate = this.utils.convertddmmyyyyStringToCustomizedFormat(this.state.editViewDataItem.date).trim();
        const endDate = this.utils.convertddmmyyyyStringToCustomizedFormat(this.state.editViewDataItem.dateTo).trim();
        var dateRangeField: string =
            startDate.localeCompare(endDate) == 0 ? `${startDate}` : `${startDate} to ${endDate}`;
        const additionalCleaningText: string = `${requestApprover ? requestApprover : ""} on ${requestDate ? requestDate : ""
            }`;
        const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
        const isEventUser = UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) || UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        var itemType: boolean =
            itemTypeId == itemTypes.Original || itemTypeId == "" || itemTypeId == itemTypes.Acceptance ? true : false;
        var weeklyHours: contractHourLookup[] = this.utils.populateContractTable(contractHours);
        var equipmentTotalBillableQuantity: string =
            equipmentBillableQuantity > 0
                ? `${exceededSign}${this.sharedUtils.convertNumberToString(equipmentBillableQuantity)}`
                : this.sharedUtils.convertNumberToString(equipmentBillableQuantity);
        var hoursAdjustment: string =
            contractHours && contractHours.variableHours == "false" && itemType == true
                ? this.sharedUtils.convertNumberToString(
                    equipmentBillableQuantity - this.sharedUtils.convertStringToFloat(contractHours.totalHours)
                )
                : "0";
        var hoursAdjustmentWithSign =
            hoursAdjustment && this.sharedUtils.convertStringToFloat(hoursAdjustment) > 0 ? "+" : "";
        var hoursApproved = `${hoursAdjustmentWithSign}${hoursAdjustment}`;
        var venue: string = "";
        if (venueName && venuePostCode) {
            venue = venueName + "," + venuePostCode;
        }
        else if (venueName) {
            venue = venueName;
        }
        else if (venuePostCode) {
            venue = venuePostCode;
        }

        if (this.props.cardUIType == "weekly") {
            if (contractHours && contractHours.variableHours.toLowerCase() == "true") {
                if (isVenueManager) {
                    if (itemType) {
                        headerTextAsPerRole = variableCleaningHeaderText;
                        bodyTextAsPerRole = variableCleaningCommentText;
                    } else {
                        headerTextAsPerRole = variableAdjustmentCleaningHeaderText;
                        bodyTextAsPerRole = variableAdjustmentCleaningCommentText;
                    }
                } else {
                    if (itemType) {
                        headerTextAsPerRole = variableCleaningAcceptanceHeaderText;
                        bodyTextAsPerRole = variableCleaningAcceptanceCommentText;
                    } else {
                        headerTextAsPerRole = variableAcceptanceAdjustmentCleaningHeaderText;
                        bodyTextAsPerRole = variableAcceptanceAdjustmentCleaningCommentText;
                    }
                }
            } else {
                if (isVenueManager) {
                    if (itemType) {
                        headerTextAsPerRole = cleaningHeaderText;
                        bodyTextAsPerRole = cleaningCommentText;
                    } else {
                        headerTextAsPerRole = cleaningAdjustmentHeaderText;
                        bodyTextAsPerRole = cleaningAdjustmentCommentText;
                    }
                } else {
                    if (itemType) {
                        headerTextAsPerRole = cleaningAcceptanceAdjustmentHeaderText;
                        bodyTextAsPerRole = cleaningAcceptanceAdjustmentCommentText;
                    }
                }
            }
        } else {
            if (isVenueManager) {
                headerTextAsPerRole = additionalCleaningHeaderText;
                bodyTextAsPerRole = additionalCleaningCommentText;
            }
        }

        return (
            <>
                <CardWrapper3Part
                    type="cleaner"
                    typeTitle={service}
                    enumeration={true}
                    cardIndex={this.props.serialNumber}
                    cardsTotal={this.props.totalNumber}
                    header={
                        <CardHeaderSection
                            venue={venue}
                            title={isVenueManager || isEventUser ? provider : client}
                            postcode={this.state.isProvider ? venuePostCode : ""}
                            date={serviceSubTypeId == ApprovalCardTypes.AdditionalCleaning ? startDate : dateRangeField}
                            message={headerTextAsPerRole ? headerTextAsPerRole : ""}
                            adjustment={itemType}
                            errorMessage={this.state.errorMessage}
                            clearErrorMessage={this.clearErrorMessage}
                            calculationErrorMessage={this.state.calculationErrorMessage}
                            provider={provider}
                            serviceSubTypeId={serviceSubTypeId}
                            client={client}
                        />
                    }
                    body={
                        this.props.cardUIType == "weekly" ? (
                            contractHours && contractHours.variableHours.toLowerCase() == "true" ? (
                                <CardConfirmHoursSection
                                    confirmText={variableCleaningConfirmText ? variableCleaningConfirmText : ""}
                                    isProvider={this.state.isProvider}
                                    hours={this.state.hours}
                                    minutes={this.state.minutes}
                                    lookup={minuteLookup}
                                    handleChangeHours={this.handleChangeHours}
                                    handleChangeMinutes={this.handleChangeMinutes}
                                    itemType={itemType}
                                    equipmentOriginalQuantity={equipmentOriginalQuantity}
                                    equipmentTotalBillableQuantity={equipmentTotalBillableQuantity}
                                    equipmentBillableQuantity={equipmentBillableQuantity}
                                    hoursApproved={hoursApproved}
                                />
                            ) : (
                                <CardCleaningSection
                                    itemType={itemType}
                                    contractHours={contractHours}
                                    weeklyHours={weeklyHours}
                                    isProvider={this.state.isProvider}
                                    equipmentOriginalQuantity={equipmentOriginalQuantity}
                                    equipmentTotalBillableQuantity={equipmentTotalBillableQuantity}
                                    equipmentBillableQuantity={equipmentBillableQuantity}
                                    hoursApproved={hoursApproved}
                                    providerPayAmount={this.sharedUtils.thousandsSeparator(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy)}
                                />
                            )
                        ) : (
                            <CardAdditionalCleaningSection
                                otherDescription={otherDescription ? otherDescription : ""}
                                displayRate={this.sharedUtils.thousandsSeparator(
                                    this.state.editViewDataItem.rate_Decimal
                                )}
                                additionalCleaningText={additionalCleaningText}
                            />
                        )
                    }
                    footer={
                        // ToDo: wire up comments section, currently it looks good, but doesnt work
                        <CardCommentsSection
                            message={bodyTextAsPerRole}
                            comments={this.state.convertedEditViewDataItem.comments}
                            handleChangeComments={this.handleChangeComments}
                            buttonText={this.state.buttonText}
                            queryButtonText={this.state.queryButtonText}
                            onSubmitQuery={this.onSubmitQuery}
                            handleSubmit={this.handleSubmit}
                            handleSave={this.handleSave}
                            disableApproveButton={this.state.disableApproveButton}
                            disableQueryButton={this.state.disableQueryButton}
                            disableSaveButton={this.state.disableSaveButton}
                        />
                    }
                />
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
            </>
        );
    }
}

export default withTranslation()(CleaningApprovalCard);
