import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Texts,
    SearchPanel,
    Export,
} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import FAQActionCellComponent from "./FAQActionCellComponent";
import MatrixGridService, { IFAQGridRowItem } from "../../services/MatrixService";
import gridUtils from "../grid/GridUtilities";
import MaskedPopupStatus from "../BulletinPanel/BulletinAuthoring/MaskedPopupStatus";
import MaskedStatusForActive from "../ServiceFeeMatrix/MaskedStatusForActive";
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
//props
interface FAQGridProps {
    refreshSignal: boolean;
}

// State
interface FAQGridState {
    faQDataSource: IFAQGridRowItem[];
    showProgressIndicator: boolean;
}

// Component - displays the Rate Matrix Grid
class FAQGrid extends React.Component<FAQGridProps> {
    //Initialize the component's state.
    state: FAQGridState;
    faQService: MatrixGridService;
    gridUtils: gridUtils;
    constructor(props: FAQGridProps) {
        super(props);
        this.faQService = new MatrixGridService();
        this.gridUtils = new gridUtils();
        // Initialize state and services/utils
        this.state = {
            faQDataSource: [],
            showProgressIndicator: false,
        };
    }

    componentDidMount() {
        this.faQDataSource();
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate = (prevProps: FAQGridProps) => {
        if (this.props.refreshSignal !== prevProps.refreshSignal) {
            // refresh the grid
            this.faQDataSource();
        }
    }

    faQDataSource = () => {
        this.setState({
            showProgressIndicator: true,
        });
        this.faQService.getFAQDataItem().then(this.handleSuccess).catch(this.handleError);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            faQDataSource: response.data.data,
            showProgressIndicator: false,
        });
    }

    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "response failed with response: " + JSON.stringify(error);

        if (!this.faQService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }
    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    render() {
        var { faQDataSource } = this.state;
        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={faQDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        columnResizingMode={"widget"}
                        allowColumnResizing={true}
                        onExporting={this.onExporting}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Sorting mode="single" />
                        <Export enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <Column dataField="id"  dataType = "number" allowSorting={true} caption="ID" />
                        <Column dataField="appName" allowSorting={true} caption="App" />
                        <Column dataField="categoryLookUpValue" allowSorting={true} caption="CATEGORY" />
                        <Column dataField="categoryOrder"  dataType = "number" allowSorting={true} caption="CATEGORY ORDER" />
                        <Column dataField="questionOrder"  dataType = "number" allowSorting={true} caption="QUESTION ORDER" />
                        <Column dataField="name" allowSorting={true} caption="NAME" />
                        <Column dataField="question" allowSorting={true} caption="QUESTION" />
                        <Column dataField="answer" allowSorting={true} caption="ANSWER" />
                        <Column
                            dataField="isPopUpRequired"
                            allowSorting={true}
                            caption="POP UP"
                            cellComponent={MaskedPopupStatus}
                        ></Column>
                        <Column
                            dataField="faqDate"
                            allowSorting={true}
                            caption="DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.faqDate);
                            }}
                        />
                        <Column
                            dataField="validTo"
                            allowSorting={true}
                            caption="VALID TO"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.validTo);
                            }}
                        />
                        <Column
                            dataField="validFrom"
                            allowSorting={true}
                            caption="VALID FROM"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.validFrom);
                            }}
                        />
                        <Column
                            dataField="isActive"
                            allowSorting={true}
                            caption="ACTIVE"
                            cellComponent={MaskedStatusForActive}
                        ></Column>
                        <Column
                            dataField="lastUpdatedOn"
                            allowSorting={true}
                            caption="LAST UPDATED"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                            }}
                        />
                        <Column
                            dataField="lastUpdatedBy"
                            allowSorting={true}
                            caption="LAST UPDATED BY"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedBy);
                            }}
                        />
                        <Column cellComponent={FAQActionCellComponent} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
            </div>
        );
    }
}

export default FAQGrid;
