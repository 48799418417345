import React from "react";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Editing,
    Texts,
    Selection,
    SearchPanel,
} from "devextreme-react/data-grid";
import UploadScheduleGridRow from "./UploadScheduleGridRow";
import {
    DataGridOnContentReadyEvent,
    DataGridOnInitializedEvent,
    DataGridOnSelectionChanged,
    onExportingEvent
} from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import BillableItemHeaderService, {
    BillableItemHeaderServiceRowItem,
    BillableItemHeaderStatuses,
    TypeOfFileUploaded,
    UploadFileListGridSections,
} from "../../services/BillableItemHeaderService";
import gridUtils from "./GridUtilities";
import { uploadedFileType } from "../../services/FileService";
import UploadedFileContentsGrid from "./UploadedFileContentsGrid";
import SIAUploadedFileContentsGrid from "./SIAUploadedFileContentsGrid";
import VenueHierarchyUploadFileContentsGrid from "../Venue_Hierarchy/VenueHierarchyUploadFileContentsGrid";
import { LoadIndicator } from "devextreme-react";
import BudgetUploadFileContentsGrid from "../BudgetUpload/BudgetUploadFileContentsGrid";
import RateMatrixUploaderFileContentsGrid from "../Rate_Matrix_Uploader/RateMatrixUploaderFileContentsGrid";
import { saveAs } from "file-saver";

// Props
//  - refreshSignal will indicate that a grid refresh is required.
//  - gridVisibilitySignal is going to signal when the grid will be shown.
interface UploadFileListGridProps {
    refreshSignal: boolean;
    gridVisibilitySignal: boolean;
    service: string;
    subService: string;
    landingPage?: string;
    gridType: string;
    billableItemHeaderId?: string;
    onShowStatusOfFile: (statusOfFile: BillableItemHeaderServiceRowItem[]) => void;
    isSubmitClick?: boolean;
    pointOfNavigation: string;
}

// State
interface UploadFileListGridState {
    headerId: string;
    IsShowChildGrid: boolean;
    loadSIAChildGrid: boolean;
    loadVenueHierarchyChildGrid: boolean;
    IsShowColumn: string;
    ChildGridDataSource: [];
    refreshSignal: boolean;
    headerGridDataSource: BillableItemHeaderServiceRowItem[];
    showProgressIndicator: boolean;
    loadBudgetUploadChildGrid: boolean;
    loadPeriodMatrixUploadChildGrid: boolean;
    loadRateMatrixChildGrid: boolean;
    fileListGridDataSource: UploadFileListGridSections;
    shiftImportGridDataSource: BillableItemHeaderServiceRowItem[];
    selectedRowStatus: string;
    selectedRowKeys: [];
    status: string;
}

// Component - displays both the header and child grids
class UploadFileListGrid extends React.Component<UploadFileListGridProps> {
    state: UploadFileListGridState;
    gridUtils: gridUtils;
    response: any;
    ChildGridDataSource: any;
    dataGridElement: any;
    billableItemUploadService: BillableItemHeaderService;

    constructor(props: UploadFileListGridProps) {
        super(props);
        this.gridUtils = new gridUtils();
        this.state = {
            headerId: "",
            IsShowChildGrid: false,
            loadSIAChildGrid: false,
            loadVenueHierarchyChildGrid: false,
            IsShowColumn: "",
            ChildGridDataSource: [],
            refreshSignal: this.props.refreshSignal,
            headerGridDataSource: [],
            showProgressIndicator: false,
            loadBudgetUploadChildGrid: false,
            loadPeriodMatrixUploadChildGrid: false,
            loadRateMatrixChildGrid: false,
            fileListGridDataSource: this.gridUtils.initializeUploadFileListGridSections(),
            shiftImportGridDataSource: [],
            selectedRowStatus: "",
            selectedRowKeys: [],
            status: "",
        };

        this.billableItemUploadService = new BillableItemHeaderService();
        this.dataGridElement = undefined;
        this.updateHeaderGridDataSource = this.updateHeaderGridDataSource.bind(this);
        this.handleChangeOnClick = this.handleChangeOnClick.bind(this);
        this.handleFileUploadOnInitialize = this.handleFileUploadOnInitialize.bind(this);
    }

    // Initialize state from server
    componentDidMount() {
        this.updateHeaderGridDataSource();
    }

    // Update state from server
    componentDidUpdate(prevProps: UploadFileListGridProps) {
        if (
            this.props.refreshSignal !== prevProps.refreshSignal ||
            this.props.service !== prevProps.service ||
            this.props.subService !== prevProps.subService
        ) {
            // refresh the grid
            this.updateHeaderGridDataSource();
        }
    }

    // Refresh the grid's data source
    updateHeaderGridDataSource() {
        this.setState({
            showProgressIndicator: true,
            IsShowChildGrid: false,
            status: "",
        });
        const { subService } = this.props;
        if (subService == uploadedFileType.SIA) {
            this.billableItemUploadService
                .getSIAWatchListHeaderRows(this.props.service, this.props.subService)
                .then((response) =>
                    this.setState({
                        headerGridDataSource: response.data.data,
                        showProgressIndicator: false,
                    })
                )
                .catch((error) => {
                    // Log to AppInsights as a TRACE
                    var respMessage: string = `File upload grid data load failed with response: ${JSON.stringify(
                        error
                    )}`;
                    this.setState({
                        headerGridDataSource: [],
                        showProgressIndicator: false,
                    });
                    if (!this.billableItemUploadService.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        } else if (
            subService == uploadedFileType.VenueUpload ||
            subService == uploadedFileType.BudgetUpload || 
            subService == uploadedFileType.PeriodMatrixUpload ||
            subService == uploadedFileType.VenueUploadV2 ||
            subService == uploadedFileType.RateMatrixUpload
        ) {
            this.billableItemUploadService
                .getHeaderRows(this.props.service, this.props.subService)
                .then((response) =>
                    this.setState({
                        headerGridDataSource: response.data.data,
                        showProgressIndicator: false,
                    })
                )
                .catch((error) => {
                    // Log to AppInsights as a TRACE
                    var respMessage: string = `File upload grid data load failed with response: ${JSON.stringify(
                        error
                    )}`;
                    this.setState({
                        headerGridDataSource: [],
                        showProgressIndicator: false,
                    });
                    if (!this.billableItemUploadService.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
        } else {
            if(this.props.subService == "18"){
                this.billableItemUploadService
                .getBillableItemHeaderRowsServiceUploader(this.props.subService)
                .then((response) => {
                    var gridData: UploadFileListGridSections = this.gridUtils.filterShiftImportGrid(
                        response.data.data,
                        this.props.billableItemHeaderId ? this.props.billableItemHeaderId : ""
                    );
                    this.setState({
                        shiftImportGridDataSource: this.props.billableItemHeaderId
                            ? gridData.newUploaded
                            : gridData.all,
                        showProgressIndicator: false,
                    });
                    this.props.onShowStatusOfFile(gridData.newUploaded);
                })
                .catch((error) => {
                    // Log to AppInsights as a TRACE
                    var respMessage: string = `File upload grid data load failed with response: ${JSON.stringify(
                        error
                    )}`;
                    this.setState({
                        shiftImportGridDataSource: [],
                        showProgressIndicator: false,
                        fileListGridDataSource: this.gridUtils.initializeUploadFileListGridSections(),
                    });
                    if (!this.billableItemUploadService.traceAsErrorToAppInsights(respMessage)) {
                        // AppInsights is not available
                        console.error(respMessage);
                    }
                });
            }
            else {
                this.billableItemUploadService
                    .getBillableItemHeaderRows(this.props.service, this.props.subService)
                    .then((response) => {
                        var gridData: UploadFileListGridSections = this.gridUtils.filterShiftImportGrid(
                            response.data.data,
                            this.props.billableItemHeaderId ? this.props.billableItemHeaderId : ""
                        );
                        this.setState({
                            shiftImportGridDataSource: this.props.billableItemHeaderId
                                ? gridData.newUploaded
                                : gridData.all,
                            showProgressIndicator: false,
                        });
                        this.props.onShowStatusOfFile(gridData.newUploaded);
                    })
                    .catch((error) => {
                        // Log to AppInsights as a TRACE
                        var respMessage: string = `File upload grid data load failed with response: ${JSON.stringify(
                            error
                        )}`;
                        this.setState({
                            shiftImportGridDataSource: [],
                            showProgressIndicator: false,
                            fileListGridDataSource: this.gridUtils.initializeUploadFileListGridSections(),
                        });
                        if (!this.billableItemUploadService.traceAsErrorToAppInsights(respMessage)) {
                            // AppInsights is not available
                            console.error(respMessage);
                        }
                    });
            }
        }
    }

    // Set the file uploader component
    handleFileUploadOnInitialize(e: DataGridOnInitializedEvent) {
        this.dataGridElement = e.component;
    }

    // Calling API on row click to fetch data depending on headerId
    handleChangeOnClick(event: DataGridOnSelectionChanged) {
        if (event.selectedRowsData) {
            this.showChildGrid(event.selectedRowsData[0].fileTypeId, event.selectedRowsData[0].Status);
            // Prevent two events being fired: button click from the status row and row click
            if (event) {
                this.setState({
                    headerId: event.selectedRowsData[0].id,
                });
            }
        }
    }

    showChildGrid = (fileTypeId: string, status: string, billableheaderId?: string) => {
        if (fileTypeId == uploadedFileType.equipment) {
            this.setState({
                IsShowColumn: uploadedFileType.equipment,
                loadVenueHierarchyChildGrid: false,
            });
        } else if (fileTypeId == uploadedFileType.personnel) {
            this.setState({
                IsShowColumn: uploadedFileType.personnel,
                loadVenueHierarchyChildGrid: false,
            });
        } else if (fileTypeId == uploadedFileType.otherServiceUploader) {
            this.setState({
                IsShowColumn: uploadedFileType.otherServiceUploader,
                loadVenueHierarchyChildGrid: false,
            });
        } else if (fileTypeId == uploadedFileType.VenueUpload || fileTypeId == uploadedFileType.VenueUploadV2) {
            this.setState({
                loadVenueHierarchyChildGrid: true,
            });
        } else if (fileTypeId == uploadedFileType.BudgetUpload) {
            this.setState({
                loadBudgetUploadChildGrid: true,
            });
        } else if (fileTypeId == uploadedFileType.PeriodMatrixUpload) {
            this.setState({
                loadPeriodMatrixUploadChildGrid: true,
            });
        } else if (fileTypeId == uploadedFileType.RateMatrixUpload) {
            this.setState({
                loadRateMatrixChildGrid: true,
            });
        } else {
            this.setState({
                loadSIAChildGrid: true,
            });
        }

        if (
            this.billableItemUploadService.isStatusEqualTo(status, BillableItemHeaderStatuses.InProgress) ||
            this.billableItemUploadService.isStatusEqualTo(status, BillableItemHeaderStatuses.Pending) ||
            this.billableItemUploadService.isStatusEqualTo(status, BillableItemHeaderStatuses.Failed)
        ) {
            this.setState({
                IsShowChildGrid: false,
                headerId: "",
            });
        } else {
            this.setState({
                IsShowChildGrid: true,
                selectedRowStatus: status,
            });
        }
        if (billableheaderId) {
            this.setState({
                headerId: billableheaderId,
            });
        }
    };

    selectFirstRow = (event: DataGridOnContentReadyEvent) => {
        const rowKey = event.component.getKeyByRowIndex(0);
        this.setState(
            {
                status: rowKey.status,
            },
            () => {
                this.isShowChildGridForNewComponent();
            }
        );
    };

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    isShowChildGridForNewComponent = () => {
        if (
            this.billableItemUploadService.isStatusEqualTo(
                this.state.status,
                BillableItemHeaderStatuses.ReadyForSubmission
            ) ||
            this.billableItemUploadService.isStatusEqualTo(
                this.state.status,
                BillableItemHeaderStatuses.ReadyForSubmissionWarnings
            ) ||
            this.billableItemUploadService.isStatusEqualTo(this.state.status, BillableItemHeaderStatuses.NotAccepted)
        ) {
            this.showChildGrid(this.props.subService, this.state.status, this.props.billableItemHeaderId);
        }
    };

    render() {
        var { landingPage } = this.props;
        //Determine the appropriate React.Node here.
        var childGrid: React.ReactNode = <></>;
        if (this.state.IsShowChildGrid) {
            if (this.state.loadSIAChildGrid) {
                childGrid = (
                    <SIAUploadedFileContentsGrid
                        isChildGridVisible={this.state.IsShowChildGrid}
                        IsShowColumn={this.state.IsShowColumn}
                        headerId={this.state.headerId}
                    />
                );
            } else if (this.state.loadVenueHierarchyChildGrid) {
                childGrid = (
                    <VenueHierarchyUploadFileContentsGrid
                        isChildGridVisible={this.state.IsShowChildGrid}
                        headerId={this.state.headerId}
                    />
                );
            } else if (this.state.loadBudgetUploadChildGrid) {
                childGrid = (
                    <BudgetUploadFileContentsGrid
                        isChildGridVisible={this.state.IsShowChildGrid}
                        headerId={this.state.headerId}
                        gridType={uploadedFileType.BudgetUpload}
                    />
                );
            } else if (this.state.loadPeriodMatrixUploadChildGrid) {
                childGrid = (
                    <BudgetUploadFileContentsGrid
                        isChildGridVisible={this.state.IsShowChildGrid}
                        headerId={this.state.headerId}
                        gridType={uploadedFileType.PeriodMatrixUpload}
                    />
                );
            } else if (this.state.loadRateMatrixChildGrid) {
                childGrid = (
                    <RateMatrixUploaderFileContentsGrid
                        isChildGridVisible={this.state.IsShowChildGrid}
                        headerId={this.state.headerId}
                    />
                );
            } else {
                childGrid = (
                    <UploadedFileContentsGrid
                        IsShowColumn={this.state.IsShowColumn}
                        isChildGridVisible={this.state.IsShowChildGrid}
                        headerId={this.state.headerId}
                        pointOfNavigation={this.props.pointOfNavigation}
                        clientId={""}
                        providerId={""}
                        typeId={""}
                        dateFrom={""}
                        dateTo={""}
                        isAcknowledged={false}
                        refreshSignal={false}
                        hideTab={false}
                    />
                );
            }
        }
        return (
            <div>
                <div className="mb-5 mt-3">
                    {this.props.gridVisibilitySignal ? (
                        this.state.showProgressIndicator ? (
                            <div className="starter-template">
                                <LoadIndicator
                                    id="simple-grid-indicator"
                                    height={100}
                                    width={100}
                                    visible={this.state.showProgressIndicator}
                                />
                            </div>
                        ) : (
                            <DataGrid
                                dataSource={
                                    landingPage == TypeOfFileUploaded.Shifts
                                        ? this.state.shiftImportGridDataSource
                                        : this.state.headerGridDataSource
                                }
                                showBorders={false}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                columnAutoWidth={true}
                                onSelectionChanged={this.handleChangeOnClick}
                                columnResizingMode={"widget"}
                                allowColumnResizing={true}
                                className="view-queries-grid"
                                onContentReady={
                                    !this.state.status && this.props.pointOfNavigation == "New File Submission"
                                        ? this.selectFirstRow
                                        : undefined
                                }
                                onExporting={this.onExporting}
                            >
                                <SearchPanel visible={true} placeholder={"Search"} />
                                <Selection mode="single" />
                                <Editing>
                                    <Texts confirmDeleteMessage=""></Texts>
                                </Editing>
                                <Export enabled={true} allowExportSelectedData={true} />
                                <Paging defaultPageSize={5} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                <Column dataField="fileFormatText" caption="Format Type" />
                                {this.props.landingPage &&
                                    (this.props.landingPage == TypeOfFileUploaded.Venue ||
                                        this.props.landingPage == TypeOfFileUploaded.BudgetUpload || 
                                        this.props.landingPage == TypeOfFileUploaded.PeriodMatrixUpload ||
                                        this.props.landingPage == TypeOfFileUploaded.RateMatrixUpload) ? (
                                    <Column
                                        dataField="fileName"
                                        calculateDisplayValue={(rowData: any) => {
                                            return this.gridUtils.trimFilename(rowData.fileName);
                                        }}
                                        caption="File Name"
                                    />
                                ) : (
                                    <Column dataField="fileName" caption="File Name" />
                                )}
                                <Column dataField="fileTypeText" caption="File Type" />
                                <Column
                                    dataField="lastUpdatedOn"
                                    caption="Date Time"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertUTCStringToDate(rowData.lastUpdatedOn);
                                    }}
                                    calculateDisplayValue={(rowData: any) => {
                                        return this.gridUtils.convertUTCStringToDate(rowData.lastUpdatedOn);
                                    }}
                                    format="dd/MM/yyyy HH:mm"
                                />
                                {this.props.billableItemHeaderId ? (
                                    <Column dataField="status" caption="Status" />
                                ) : (
                                    <Column
                                        dataField="status"
                                        allowSorting={false}
                                        cellComponent={UploadScheduleGridRow}
                                    />
                                )}
                                {landingPage == TypeOfFileUploaded.Shifts ? (
                                    <Column dataField="errorMessage" caption="File Comment" />
                                ) : (
                                    <Column dataField="errorMessage" caption="Error Message" />
                                )}

                                <FilterRow visible={true} applyFilter="auto" />
                            </DataGrid>
                        )
                    ) : null}
                </div>

                {childGrid}
            </div>
        );
    }
}

export default UploadFileListGrid;
