import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { WithTranslation } from "react-i18next";
import ContractCleaningService from "../../services/ContractCleaningService";
import { confirm } from "devextreme/ui/dialog";
import MatrixGridService, { PeriodMatrixGridRowItem } from "../../services/MatrixService";

interface ContractActionCellComponentProps extends WithTranslation {
    data: any;
}

interface ContractActionCellComponentState {}

class ContractActionCellComponent extends React.Component<ContractActionCellComponentProps> {
    state: ContractActionCellComponentState;
    Service: ContractCleaningService;
    manageService: MatrixGridService;
    constructor(props: ContractActionCellComponentProps) {
        super(props);
        this.setLoadIndicatorVisibility = this.setLoadIndicatorVisibility.bind(this);
        this.Service = new ContractCleaningService();
        this.manageService = new MatrixGridService();
        this.state = {};
        this.handleDeleteOnClick = this.handleDeleteOnClick.bind(this);
        this.handleSuccessDelete = this.handleSuccessDelete.bind(this);
    }

    setLoadIndicatorVisibility(isVisible: boolean): void {
        this.setState({
            loadIndicatorVisible: isVisible,
        });
    }

    handleDeleteOnClick = () => {
        confirm("<div class='text-center'>Delete this row?</div>", "Delete").then((hasConfirmed) => {
            if (hasConfirmed) {
                this.setLoadIndicatorVisibility(true);
                //call the API for posting Delete Period Id's and handle the response
                this.manageService
                    .deleteRateMatrixDataItem(this.props.data.data.rateMatrixId)
                    .then(this.handleSuccessDelete)
                    .catch(this.handleFailureDelete);
            }
        });
    };
    handleFailureDelete = (error: any) => {
        var respMessage: string = "Delete rate matrix data item service failed with response: " + JSON.stringify(error);

        if (!this.manageService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    handleSuccessDelete = () => {
        this.props.data.component.deleteRow(this.props.data.rowIndex);
        this.props.data.component.repaint();
    };

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <Link
                            title="Edit"
                            className="icon-btn"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    id: this.props.data.data.contractCleaningId,
                                    isEdit: true,
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </Link>
                        {/* <Link
              title="Delete"
              className= "icon-btn"
              onClick={this.handleDeleteOnClick}
              to= {{
                pathname: "/matrixView",
                state: {
                  isDelete : true
                }
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Link> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default ContractActionCellComponent;
