import React from "react";
import { LoadPanel } from "devextreme-react";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import PaymentService, { paymentStatus, paymentCycleStatus, logicAppPostRequest } from "../../services/PaymentService";
import paymentFormUtils, { PaymentFormData, ActionType } from "../Payments/PaymentFormUtils";

interface PaymentRemittanceSectionProps {
    viewDisabled: boolean;
    paymentCycleId: number;
    paymentData: PaymentFormData;
    statusId: string;
    invoiceSentDate: NullableDate;
    calculationsObject: any;
    onPaymentProduced: (status: string, statusId: string, isRestartDisable: boolean) => void;
}

interface PaymentRemittanceSectionState {
    disableRemittanceButton: boolean;
    loadPanelVisible: boolean;
    errorMessage: [];
    statusIdOnClick: string;
}

class PaymentRemittanceSection extends React.Component<PaymentRemittanceSectionProps> {
    state: PaymentRemittanceSectionState;
    PaymentService: PaymentService;
    paymentFormUtils: paymentFormUtils;
    sharedUtils: sharedUtils;

    constructor(props: PaymentRemittanceSectionProps) {
        super(props);
        this.PaymentService = new PaymentService();
        this.paymentFormUtils = new paymentFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            disableRemittanceButton: false,
            loadPanelVisible: false,
            errorMessage: [],
            statusIdOnClick: "",
        };
    }

    //Function which is invoked when the "Send Remittances" button is clicked.
    onPaymentRemittance = (statusId: string, status: string) => {
        let data: PaymentFormData = this.paymentFormUtils.paymentDataInitializePaymentItems(
            this.props,
            statusId,
            status
        );
        this.setState({
            gridData: data,
            loadPanelVisible: true,
            statusIdOnClick: statusId,
        });
        this.onSubmit(data);
    };

    onSubmit = (paymentData: PaymentFormData) => {
        var paymentCycleSubmitRequest = this.paymentFormUtils.convertFormDataToPaymentCycleRequest(
            paymentData,
            ActionType.Save,
            "",
            ""
        );
        this.PaymentService.postPaymentCycleDataV2(paymentCycleSubmitRequest)
            .then(this.handleSuccess)
            .catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        const { paymentCycleId } = this.props;
        const { statusIdOnClick } = this.state;
        this.setState(
            (prevState: PaymentRemittanceSectionState) => ({
                loadPanelVisible: false,
                disableRemittanceButton: true,
                errorMessage: [],
            }),
            () => this.props.onPaymentProduced(paymentStatus.PaymentRemittanceRequested, statusIdOnClick, true)
        );
        let postObject: logicAppPostRequest = {
            PaymentCycleId: paymentCycleId ? paymentCycleId.toString() : "",
        };
        this.PaymentService.triggerLogicAppForRemittanceRequest(postObject);
    };

    handleFailure = (error: any) => {
        var respMessage: string = "postPaymentCycleInvoice failed with response: " + JSON.stringify(error);

        if (!this.PaymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            errorMessage: error.response !== null ? JSON.parse(JSON.stringify(error.response.data.error)) : null,
            loadPanelVisible: false,
            disableRemittanceButton: false,
        });
    };

    render() {
        var remittanceButtonDisabled: boolean = true;
        var remittanceButtonCssClass: string = "btn disabledCycleButtonColor btn--large";
        var { disableRemittanceButton } = this.state;
        var { statusId } = this.props;
        var buttonText: string = "Send Remittances";
        var disablePaymentRemittanceButton: boolean = statusId != paymentStatus.PaymentSettled ? false : true;
        if (statusId && statusId == paymentStatus.PaymentRemittanceRequested) {
            buttonText = "Sending Remittances";
        } else if (statusId && statusId == paymentStatus.PaymentRemitted) {
            buttonText = "Remittances Sent";
        }
        if (disablePaymentRemittanceButton && disableRemittanceButton == false) {
            remittanceButtonCssClass =
                "btn " + (this.props.viewDisabled ? "disabled" : "save") + "CycleButtonColor btn--large";
            remittanceButtonDisabled = false;
        }
        return (
            <div className="card my-3">
                <div className="card-body">
                    <h4>Payment Remittance</h4>
                    <>
                        {this.state.errorMessage ? (
                            <span className="unscheduled-shift">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        ) : (
                            <></>
                        )}
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <div className="row mt-3">
                            <div className="col-12 col-lg-3 mr-auto">
                                <button
                                    className={remittanceButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onPaymentRemittance(
                                            paymentStatus.PaymentRemittanceRequested,
                                            paymentCycleStatus.PaymentRemittanceRequested
                                        );
                                    }}
                                    disabled={this.props.viewDisabled ? true : remittanceButtonDisabled}
                                >
                                    {buttonText}
                                </button>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        );
    }
}
export default PaymentRemittanceSection;
