import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CustomStore from "devextreme/data/custom_store";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import SimpleGridService, { GridTypes, ShiftMatrixRemoteGridRowItem } from "../../services/SimpleGridService";
import ShiftMatrixGridContents from "../../components/ShiftMatrix/ShiftMatrixGridContents";
import GlobalService from "../../services/GlobalService";
import { LoadPanel } from "devextreme-react";

interface LockShiftMatrixGridProps extends WithTranslation {
    dateRangeFrom: string,
    dateRangeTo: string,
    filters : any[],
    isShowUnbilledItems?: boolean;
    isUnPaidItems?: boolean;
    isUnconfirmedItems?: boolean;
    isUnapprovedItems?:boolean;
    isAddShiftClicked:boolean;
    getSelectedRows:(selectedRows:any)=>void;
    // findMatchesClicked:boolean,
    // reloadShiftGrid:boolean,
    // payDatesGridDataSource :any[],
    // updateFoundRecords: (records:any[],paySource:any[]) => void;
}

interface LockShiftMatrixGridState {
    dataSource: [],
    // modifiedDataSource: ShiftMatrixRemoteGridRowItem[],
    loadIndicatorForGrid: boolean,
}

class LockShiftMatrixGrid extends Component<LockShiftMatrixGridProps> {
    state: LockShiftMatrixGridState;
    service: SimpleGridService;
    constructor(props: LockShiftMatrixGridProps) {
        super(props);
        this.state = {
            dataSource: [],
            // modifiedDataSource: [],
            loadIndicatorForGrid: false
        }
        this.service = new SimpleGridService();
    }


    // isPayDateEqual = (recordPaydate: string, shiftPayDate: string) => {
    //     let isEqual = false;
    //     if (new Date(recordPaydate).getTime() === new Date(shiftPayDate).getTime()) {
    //         isEqual = true;
    //     }
    //     return isEqual;
    // }

    getResults = (loadOptions: any) => {
        this.setState({
            loadIndicatorForGrid: true
        })
        
        let params = buildRequestParameters(loadOptions,'filter',this.props.filters);
        //params += `&skip=0&take=${this.props.defaultPageSize}`
        if (params) {
            params = params + `&`;
        } else {
            params = params + `?`;
        }
        if (!this.props.isShowUnbilledItems && !this.props.isUnPaidItems && !this.props.isUnconfirmedItems && !this.props.isUnapprovedItems) {
            params = params + `dateRangeFrom=${this.props.dateRangeFrom}&dateRangeTo=${this.props.dateRangeTo}`;
        }
        else {
            if (this.props.isShowUnbilledItems) {
                params = params + `showUnbilledItems=${this.props.isShowUnbilledItems}`;
            } else if (this.props.isUnPaidItems) {
                params = params + `showUnpaidItems=${this.props.isUnPaidItems}`;
            } else if (this.props.isUnconfirmedItems) {
                params = params + `showUnconfirmedItems=${this.props.isUnconfirmedItems}`;
            } else if (this.props.isUnapprovedItems) {
                params = params + `showUnapprovedItems=${this.props.isUnapprovedItems}`;
            }
        }
        // if (params) {
        //     if(!this.props.isShowUnbilledItems){
        //         params = params + `&dateRangeFrom=${this.props.dateRangeFrom}&dateRangeTo=${this.props.dateRangeTo}`;
        //     }
        //     else {
        //         params = params + `&showUnbilledItems=${this.props.isShowUnbilledItems}`;
        //     }
        // }
        
        return this.service.getShiftMatrixGridRowsWithinRemoteOperations(params)
            .then(this.handleSuccessLoadDataSource)
            .catch(() => { 
                this.setState({
                loadIndicatorForGrid: false
            });
            throw 'Data Loading Error'; });
    }

    handleSuccessLoadDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });

            return {
                data: response.data.billableItemRemoteGrid ?response.data.billableItemRemoteGrid:[],
                totalCount: response.data.totalCount
            };
    }

    componentDidMount() {
        this.loadDataSource();
    }

    componentDidUpdate(prevProps: LockShiftMatrixGridProps,prevState: LockShiftMatrixGridState) {
        if (this.props.dateRangeFrom != prevProps.dateRangeFrom || 
            this.props.dateRangeTo != prevProps.dateRangeTo || 
            this.props.isShowUnbilledItems != prevProps.isShowUnbilledItems ||
            this.props.isUnPaidItems != prevProps.isUnPaidItems || 
            this.props.isUnconfirmedItems != prevProps.isUnconfirmedItems || 
            this.props.isUnapprovedItems != prevProps.isUnapprovedItems ||
            (this.props.isAddShiftClicked !== prevProps.isAddShiftClicked && this.props.isAddShiftClicked == true)
            ) 
            {
            this.loadDataSource();
        }

    }

    loadDataSource = () => {
        let dataSource = new CustomStore({
            key: "id",
            load: this.getResults
        });
        GlobalService.setGridType(GridTypes.ShiftMatrixGrid);
        this.setState({
            dataSource: dataSource
        });
        
    }

    render() {
        return (
            <div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadIndicatorForGrid} />
                    <div className="row">
                        <div className="col-md">
                            <ShiftMatrixGridContents dataSource={this.state.dataSource} 
                            navigateFrom={GridTypes.addShiftBillingGrid} 
                            getSelectedRows ={this.props.getSelectedRows}
                            isAddShiftClicked = {this.props.isAddShiftClicked}
                            //defaultPageSize = {this.props.defaultPageSize}
                            />
                        </div>
                    </div>

            </div>
        );
    }
}

export default withTranslation()(LockShiftMatrixGrid);