import React, { MouseEvent, Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import UserService, { RoleGroupNames } from "../../services/UserService";
import BillingCycleGrid from "../Billing/BillingCyclesGrid";
import LightModePageTemplate from "./Templates/LightModeTemplate";
import DatePanel from "../side-bar/DatePanel";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";

interface BillingCycleManagePageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
}

interface BillingCycleManagePageState {
    gridRefreshSignal: boolean;
}

class BillingCycleManagePage extends Component<BillingCycleManagePageComponentProps> {
    //Initialize the component's state.
    state: BillingCycleManagePageState;

    constructor(props: BillingCycleManagePageComponentProps) {
        super(props);

        this.state = {
            gridRefreshSignal: false,
        };
    }
    //function that would indicate that a Grid refresh is required on click of the button.
    signalGridRefresh = () => {
        this.setState({ gridRefreshSignal: !this.state.gridRefreshSignal });
    };

    //on click of add billing cycle v2

    onclickAddBillingCycleV2 = () => {
        sessionStorage.removeItem("invoiceId");
    };

    render() {
        let isaddButtonDisabled: boolean = true;
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            isaddButtonDisabled = false;
        }
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>
                <section className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <div>
                            <h2 className="page-title__black-table">Billing</h2>
                        </div>

                        <div className="grid-info__button-container text-center text-lg-left">
                            
                                <Link
                                    className={(isaddButtonDisabled ? "disabled " : "") + "btn btn-primary btn--large btn--with-icon col-12 col-sm-6 col-xl-3 mb-3"}
                                    onClick={this.onclickAddBillingCycleV2}
                                    to={{
                                        pathname: "/BillingPageV2",
                                        state: {
                                            id: 0,
                                        },
                                    }}
                                >
                                    <span className="btn__icon">
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </span>
                                    Add New Invoice
                                </Link>
                                <span>{"     "}</span>
                                <button
                                    className={(true ? "" : "disabled") + "btn btn--ghost btn--ghost--teal btn--large btn--with-icon col-12 col-sm-6 col-xl-2 mb-3"}
                                    onClick={this.signalGridRefresh}
                                >
                                    Refresh
                                </button>
                                <span>{"     "}</span>
                                <Link
                                    className={(isaddButtonDisabled ? "disabled " : "") + "btn btn--ghost btn--ghost--teal btn--large btn--with-icon col-12 col-sm-6 col-xl-3 mb-3"}
                                    onClick={this.onclickAddBillingCycleV2}
                                    to={{
                                        pathname: "/BillingPagePreview",
                                        state: {
                                            id: 0,
                                        },
                                    }}
                                >
                                    Preview Invoice
                                </Link>
                        </div>
                        <h3 className="font-weight-bold ml-3">Previous Billing Cycles</h3>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            <BillingCycleGrid refreshSignal={this.state.gridRefreshSignal} />
                        </div>
                        <div />
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(BillingCycleManagePage);
