import React from "react";

interface AllUserGridBillableHrsProps {
    data: any;
}

interface AllUserGridBillableHrsState {
    billableHoursUnits: string;
}

class AllUserGridBillableHrs extends React.Component<AllUserGridBillableHrsProps> {
    state: AllUserGridBillableHrsState;

    constructor(props: AllUserGridBillableHrsProps) {
        super(props);
        this.state = {
            billableHoursUnits: this.props.data.data.billable_Hours_Units,
        };
    }

    render() {
        var billableExtraHrsStr = "";
        var billableHrsStr = "";
        var billableUnitClrClassValue : string = "errorstatus";
        if (this.state.billableHoursUnits && this.state.billableHoursUnits.includes("(")) {
            billableExtraHrsStr = this.state.billableHoursUnits.substring(
                this.state.billableHoursUnits.lastIndexOf("(") + 1,
                this.state.billableHoursUnits.lastIndexOf(")")
            );

            billableHrsStr = this.state.billableHoursUnits.substr(0, this.state.billableHoursUnits.indexOf("("));
            if(billableHrsStr && billableHrsStr.trim() == "0") {
                billableUnitClrClassValue = "" ; 
            }
        }
        return (
            <div className="status-message-color">
                {billableExtraHrsStr == "" ? (
                    <ul className="list-style-type">
                        <li> {this.state.billableHoursUnits} </li>
                    </ul>
                ) : (
                    <ul className="list-style-type">
                        {/* <li>{billableHrsStr}</li>
                        <li className={billableUnitClrClassValue}> ({billableExtraHrsStr})</li> */}
                        <li>{billableHrsStr}<span className={billableUnitClrClassValue}> ({billableExtraHrsStr})</span></li>
                    </ul>
                )}
            </div>
        );
    }
}

export default AllUserGridBillableHrs;
