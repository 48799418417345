import React from "react";
import { RouteProps } from "react-router-dom";
import { withValidUserRoleProps } from "../Auth/ProtectedPage";
import PaymentRequestPage from "./PaymentRequestPage";

interface PaymentRequestPageContainerProps extends RouteProps, withValidUserRoleProps {
    getBuild: (flag: boolean) => void; 
}

const SimpleShiftsPageContainer = (props: PaymentRequestPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    const getBuild = (flag: boolean) => {
        props.getBuild(flag);
    };

    return <PaymentRequestPage title={getTitle()} category={getType()} getBuild={getBuild}/>;
};

export default SimpleShiftsPageContainer;
