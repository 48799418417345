import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    DateBox,
    TextBox,
    NumberBox,
    TextArea,
    ValidationSummary,
    LoadPanel
} from "devextreme-react";
import {
    DateBoxOnValueChangedEvent,
    NumberBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import UserService, { RoleGroupNames } from "../../services/UserService";
import LookupService, { LookupTypeIndexes, dropdownFilteredValue } from "../../services/LookupService";
import { AxiosResponse, AxiosError } from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import paymentFormUtils, { AddEditPaymentFormData } from "./PaymentFormUtils";
import DropDownFromLookupTableStatic from "../select/SelectBoxFromLookupTableStatic";
import PaymentService from "../../services/PaymentService";

interface AddEditPaymentFormProps extends WithTranslation {
    location: any;
    history: any;
    id: any;
}

interface AddEditPaymentFormState {
    headerId: string;
    editPaymentFormData: AddEditPaymentFormData;
    IsShowSuccess: boolean;
    IsShowError: boolean;
    errorMessage: [];
    loadPanelVisible: boolean;
    accountNumberDataSource: any[];
}
//#endregion

// Component
class AddEditPaymentForm extends React.Component<AddEditPaymentFormProps> {
    lookupService: LookupService;
    state: AddEditPaymentFormState;
    editPaymentFormService: PaymentService;
    utils: paymentFormUtils;
    userHasAccess: boolean;

    constructor(props: AddEditPaymentFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.editPaymentFormService = new PaymentService();
        this.utils = new paymentFormUtils();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        // Initialize state
        var convertedAddEditPaymentData = this.utils.initializeAddEditPaymentItems(
            props.location.state && props.id ? props.id.toString() : ""
        );
        this.state = {
            headerId: props.location.state && props.location.state.id ? props.location.state.id.toString() : "",
            editPaymentFormData: convertedAddEditPaymentData,
            IsShowSuccess: false,
            IsShowError: false,
            errorMessage: [],
            loadPanelVisible: false,
            accountNumberDataSource: []
        };

        this.handleChangeStatusTypeSelect = this.handleChangeStatusTypeSelect.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleStandardError = this.handleStandardError.bind(this);
        this.handleSetStateError = this.handleSetStateError.bind(this);
        //NOTE ------ No need to bind the "onValueChanged functions, because arrow functions are already used."
    }

    componentDidMount() {
        this.getEditViewDataSource();        
    }

    getEditViewDataSource() {
        if (this.state.headerId != "" && this.userHasAccess) {
            this.editPaymentFormService
                .getPaymentEditViewFormData(parseInt(this.state.headerId))
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleStandardError);
        }
    }

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        // Convert to proper data types
        var viewDataSource = this.utils.convertPaymentResponseToFormData(response.data.data);
        this.setState({
            editPaymentFormData: viewDataSource,
        },
            () => {
                this.lookupService
                .getLookupByEntityId(this.state.editPaymentFormData.businessEntityId)
                .then(this.handleSuccessAccountTypeLookUp)
                .catch(this.handleError)
            }
        );
    };

    handleSuccessAccountTypeLookUp = (response: AxiosResponse<any>) => {
        this.setState({
            accountNumberDataSource: response.data.data,
        });
    };

    handleError = (err: any) => {
        this.setState({
            errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
            showError: true,
            loadPanelVisible: false,
        });
    };

    handleChangeStatusTypeSelect(dxValueChange: SelectBoxOnValueChangedEvent) {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                paymentStatusId: dxValueChange.value,
            },
        });
    }

    handleChangeReference = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                reference: dxValueChange.value,
            },
        });
    };

    handleChangeEntityTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                businessEntityId: dxValueChange.value,
                bankAccountId: "",
            },
        });
    };

    handleChangeContactTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                contactId: dxValueChange.value,
                bankAccountId: "",
            },
        });
    };

    handleChangeSortCodeTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                bankAccountId: dxValueChange.value,
            },
        });
    };

    handleChangeAccountTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {        
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                bankAccountId: dxValueChange.value,
            },
        });
    };

    handleChangeRemittanceDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                remittanceDate: dxValueChange.value,
            },
        });
    };

    handleChangeAdvisedDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                advisedDate: dxValueChange.value,
            },
        });
    };

    handleChangePayDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                payDate: dxValueChange.value,
            },
        });
    };

    handleChangeSettleDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                settleDate: dxValueChange.value,
            },
        });
    };

    handleChangeBounceDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                bounceDate: dxValueChange.value,
            },
        });
    };

    handleChangeSubAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                subAmount: dxValueChange.value,
            },
        });
    };

    handleChangeTaxAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                tax: dxValueChange.value,
            },
        });
    };

    handleChangeAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                amount: dxValueChange.value,
            },
        });
    };

    handleChangeDescription(dxValueChange: TextBoxOnValueChangedEvent) {
        this.setState({
            editPaymentFormData: {
                ...this.state.editPaymentFormData,
                comments: dxValueChange.value,
            },
        });
    }

    renderRedirect = () => {
        this.props.history.goBack(); // Return back to the page on a form submission success.
    };

    hideRestartIndicator = () => {
        this.setState({
            restartIndicator: false,
        });
    };

    failureMessageVisible = () => {
        this.setState({
            IsShowError: true,
        });
    };

    makeOrphanRecord = () => {
        this.setState({
            loadPanelVisible: true,
        });

        this.editPaymentFormService
            .cancelPaymentById(this.props.location.state.id)
            .then(this.handleFormSubmissionSuccess)
            .catch(this.handleSetStateError)
            .finally(() => {
                this.hideLoadpanel();
        });  
    };

    onSubmit = () => {
        if (this.userHasAccess) {
            this.setState({
                loadPanelVisible: true,
            });
            // to set account number and sortcode on the basis of id
            const index = this.state.accountNumberDataSource?.length > 0 ? this.state.accountNumberDataSource.findIndex(item => item.id == this.state.editPaymentFormData.bankAccountId) : -1;
            let bankAccountNumber = index >= 0 ? this.state.accountNumberDataSource[index].accountNumber : "";
            let sortCode = index >= 0 ? this.state.accountNumberDataSource[index].sortCode : "";
            this.setState({
                editPaymentFormData: {
                    ...this.state.editPaymentFormData,
                    bankAccountNumber: bankAccountNumber,
                    sortCode: sortCode
                },
            },
            () => {
                var paymentFormSubmitRequest = this.utils.convertFormDataToPaymentRequest(this.state.editPaymentFormData);
                //passing object data to the server

                this.editPaymentFormService
                    .uploadEditAddData(paymentFormSubmitRequest)
                    .then(this.handleFormSubmissionSuccess)
                    .catch(this.handleSetStateError)
                    .finally(() => {
                        this.hideLoadpanel();
                    });
            })            
        }
    };

    handleFormSubmissionSuccess = (response: AxiosResponse<any>) => {
        this.hideLoadpanel();
        this.setState({
            IsShowError: false,
        });
        this.renderRedirect();
    };

    hideLoadpanel() {
        this.setState({ loadPanelVisible: false });
    }

    handleStandardError(error: AxiosError<any>) {
        // Redirect back to home page on a 401 unauth error
        if (error.response != null && error.response.status === 401) {
            this.props.history.push({
                pathname: "/",
            });
        } else {
            // TODO: use appInsights logging here
            var respMessage: string = "getPaymentEditViewFormData failed with response: " + JSON.stringify(error);

            if (!this.editPaymentFormService.traceAsErrorToAppInsights(respMessage)) {
                // AppInsights is not available
                console.error(respMessage);
            }
        }
    }

    handleSetStateError(error: AxiosError<any>) {
        // Redirect back to home page on a 401 unauth error
        if (error.response != null && error.response.status === 401) {
            this.props.history.push({
                pathname: "/",
            });
        } else {
            this.setState({
                errorMessage:
                    error.response && error.response.data && error.response.data.error
                        ? JSON.parse(JSON.stringify(error.response.data.error))
                        : null,
                IsShowSuccess: false,
            });
            this.failureMessageVisible();
            this.hideLoadpanel();
        }
    }

    onClose = () => {
        this.props.history.goBack();
    }

    //#region render Component
    render() {
        let disableForm = true;
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            disableForm = false;
        }
        let disabledCancelPayment = true;
        if(((this.props.location.state.comesFrom == "ManageShift" && this.props.location.state.cycleId == "") 
            || this.state.editPaymentFormData.paymentCycleId == "" || this.state.editPaymentFormData.paymentCycleId == null) 
            && this.state.editPaymentFormData.paymentStatusId !== "70"){
            disabledCancelPayment = false;
        }
        return (
            <form data-testid="addEditPayment-form" className="edit-form my-5">
                <div className="container mb-6 edit-form__column">
                    <div className="row">
                        <h2 className="billingForm-heading">
                            <u>Payment - Edit/View</u>
                        </h2>
                    </div>
                    <div>
                        {this.state.IsShowError && this.state.errorMessage != null ? (
                            <span className="unscheduled-shift">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        ) : null}
                    </div>
                    <div>
                        <ValidationSummary className="billingForm-heading"></ValidationSummary>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="mt-2 col-2 col-lg-1 font-weight-bold font_size">Business Entity</div>
                        <div className="mt-2 col-10 col-lg-3">
                            <DropDownFromLookupTableDynamic
                                lookupTypeIndex={LookupTypeIndexes.providerType}
                                isRoot={true}
                                parentMappingId={"0"}
                                isDisabled={disableForm}
                                onValueChanged={this.handleChangeEntityTypeSelect}
                                value={this.state.editPaymentFormData.businessEntityId}
                            />
                            <input
                                data-testid={LookupTypeIndexes.providerType}
                                type="hidden"
                                name={LookupTypeIndexes.providerType}
                                value={
                                    this.state.editPaymentFormData.businessEntityId
                                        ? this.state.editPaymentFormData.businessEntityId
                                        : ""
                                }
                            ></input>
                        </div>
                        <div className="mt-2 col-2 col-lg-1 font-weight-bold font_size">Contact</div>
                        <div className="mt-2 col-10 col-lg-3">
                            <DropDownFromLookupTableDynamic
                                lookupTypeIndex={LookupTypeIndexes.contactType}
                                isRoot={false}
                                parentMappingId={this.state.editPaymentFormData.businessEntityId}
                                isDisabled={disableForm}
                                onValueChanged={this.handleChangeContactTypeSelect}
                                value={this.state.editPaymentFormData.contactId}
                            />
                            <input
                                data-testid={LookupTypeIndexes.contactType}
                                type="hidden"
                                name={LookupTypeIndexes.contactType}
                                value={
                                    this.state.editPaymentFormData.contactId
                                        ? this.state.editPaymentFormData.contactId
                                        : ""
                                }
                            ></input>
                        </div>
                        <div className="mt-2 col-2 col-lg-1 font-weight-bold font_size">Sort Code</div>
                        <div className="mt-2 col-10 col-lg-3">
                            <DropDownFromLookupTableStatic
                                entityID={this.state.editPaymentFormData.businessEntityId}
                                isRoot={true}
                                parentMappingId={
                                    this.state.editPaymentFormData.contactId
                                        ? this.state.editPaymentFormData.contactId
                                        : "0"
                                }
                                isDisabled={disableForm}
                                onValueChanged={this.handleChangeSortCodeTypeSelect}
                                value={this.state.editPaymentFormData.bankAccountId}
                                displayDataset={dropdownFilteredValue.sortCode}
                                valueDataset="id"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Account Number</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DropDownFromLookupTableStatic
                                entityID={this.state.editPaymentFormData.businessEntityId}
                                isRoot={true}
                                parentMappingId={
                                    this.state.editPaymentFormData.contactId
                                        ? this.state.editPaymentFormData.contactId
                                        : "0"
                                }
                                isDisabled={disableForm}
                                onValueChanged={this.handleChangeAccountTypeSelect}
                                value={this.state.editPaymentFormData.bankAccountId}
                                displayDataset={dropdownFilteredValue.accountNumber}
                                valueDataset="id"
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Pay Date</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangePayDate}
                                disabled={disableForm}
                                value={
                                    this.state.editPaymentFormData.payDate
                                        ? this.state.editPaymentFormData.payDate
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>

                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Status</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DropDownFromLookupTableDynamic
                                lookupTypeIndex={LookupTypeIndexes.paymentStatusType}
                                isRoot={true}
                                parentMappingId={"0"}
                                isDisabled={disableForm}
                                onValueChanged={this.handleChangeStatusTypeSelect}
                                value={this.state.editPaymentFormData.paymentStatusId}
                            />
                            <input
                                data-testid={LookupTypeIndexes.paymentStatusType}
                                type="hidden"
                                name={LookupTypeIndexes.paymentStatusType}
                                value={
                                    this.state.editPaymentFormData.paymentStatusId
                                        ? this.state.editPaymentFormData.paymentStatusId
                                        : ""
                                }
                            ></input>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Sub Amount</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                disabled={disableForm}
                                onValueChanged={this.handleChangeSubAmount}
                                value={this.state.editPaymentFormData.subAmount}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Tax</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                disabled={disableForm}
                                onValueChanged={this.handleChangeTaxAmount}
                                value={this.state.editPaymentFormData.tax}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Amount</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                disabled={disableForm}
                                onValueChanged={this.handleChangeAmount}
                                value={this.state.editPaymentFormData.amount}
                            />
                        </div>
                    </div>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="row mt-3">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Advised Date</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeAdvisedDate}
                                disabled={disableForm}
                                value={
                                    this.state.editPaymentFormData.advisedDate
                                        ? this.state.editPaymentFormData.advisedDate
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Settle Date</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeSettleDate}
                                disabled={disableForm}
                                value={
                                    this.state.editPaymentFormData.settleDate
                                        ? this.state.editPaymentFormData.settleDate
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Remittance Date</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeRemittanceDate}
                                disabled={disableForm}
                                value={
                                    this.state.editPaymentFormData.remittanceDate
                                        ? this.state.editPaymentFormData.remittanceDate
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Reference</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox
                                disabled={disableForm}
                                onValueChanged={this.handleChangeReference}
                                value={this.state.editPaymentFormData.reference}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Payment Cycle #</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editPaymentFormData.paymentCycleId} readOnly={true}></TextBox>
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Bounce Date</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeBounceDate}
                                disabled={disableForm}
                                value={
                                    this.state.editPaymentFormData.bounceDate
                                        ? this.state.editPaymentFormData.bounceDate
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Faster Pay?</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox 
                                readOnly={true} 
                                value={this.state.editPaymentFormData.immediatePayment == "True" ? "Yes" : "No"}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Faster Pay Id</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editPaymentFormData.immediatePaymentId} readOnly={true}></TextBox>
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Old Billable Id</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.editPaymentFormData.orignatingBillableItemId} readOnly={true}></TextBox>
                        </div>
                    </div>
                    <div className="row"></div>
                    <div className="row mt-3">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Comments</div>
                        <div className="mt-3 col-10 col-lg-11">
                            <TextArea
                                height={90}
                                onValueChanged={this.handleChangeDescription}
                                value={this.state.editPaymentFormData.comments}
                                disabled={disableForm}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="ml-auto mt-3 col-10 col-lg-2">

                        <button
                                className="btn btn-primary btn--large"
                                disabled={disableForm}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onClose();
                                }}
                            >
                                <span className="btn__icon">
                                <FontAwesomeIcon icon={faTimes} />
                                </span>
                                Close
                            </button>
                            {/* <Link
                                className="btn btn-primary btn--large"
                                to={{
                                    pathname: "managePaymentCycleItem",
                                    state: {
                                        id: this.state.editPaymentFormData.paymentCycleId,
                                    },
                                }}
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faTimes} />

                                
                                Close
                            </Link> */}
                        </div>
                        <div className="mt-3 col-10 col-lg-3">
                            <Link
                                className="btn btn-primary btn--large"
                                to={{
                                    pathname: "/documents",
                                    state: {
                                        paymentId: this.state.editPaymentFormData.id,
                                    },
                                }}
                            >
                                View Documents
                            </Link>
                        </div>
                        <div className="mr-auto mt-3 col-10 col-lg-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="submit"
                                disabled={disableForm}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onSubmit();
                                }}
                            >
                                <span className="btn__icon" />
                                Apply
                            </button>
                        </div>
                        <div className="mr-auto mt-3 col-10 col-lg-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="submit"
                                disabled={disableForm || disabledCancelPayment}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.makeOrphanRecord();
                                }}
                            >
                                <span className="btn__icon" />
                                Cancel Payment
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default withTranslation()(AddEditPaymentForm);
