import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import BaseActionCell, { BaseActionCellProps } from "../GridCells/BaseActionCell";

class PayRequestEditActionCell extends BaseActionCell {
    constructor(props: BaseActionCellProps) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <Link title="Edit"
                        className={"icon-btn"}
                        to={
                            {
                                      pathname: "/PaymentRequestForm",
                                      state: {
                                          id: this.props.data.data.id.toString(),
                                      },
                                  }
                        }
                        >
                        <FontAwesomeIcon icon={faPen} /></Link>
                </div>
            </>
        );
    }
}

export default PayRequestEditActionCell;
