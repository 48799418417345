import { AxiosResponse } from "axios";
import { _get, _aiTraceAsError } from "./BaseApiService";

const RECONCILIATION_TRANSACTION_GRID_API = "Reconciliation/Invoice/";

export interface TransactionGridRowItem {
    client: string;
    provider: string;
    venue: string;
    house: string;
    service: string;
    serviceSubType: string;
    type: string;
    dateFrom: string;
    dateTo: string;
    rate: string;
    expectedRateLower: string;
    expectedRateHigher: string;
    rateDiff: string;
    rateDiffColor: string;
    minutes: string;
    quantity: string;
    pay: string;
    fee: string;
    bill: string;
    expectedFee: string;
    feeDiff: string;
    feeDiffColor: string;
    currecy: string;
}

export interface WeekOnWeekGridRowItem {
    invoiceId: string;
    client: string;
    venue: string;
    house: string;
    service: string;
    serviceId: string;
    serviceSubType: string;
    serviceSubTypeid: string;
    period: string;
    week: string;
    pay_Sum: string;
    previousPay_Sum: string;
    varience: string;
    rag: string;
    currency: string;
    validationErrors: string;
}

class ReconciliationGridService {
    getTransactionGridRows(id: string): Promise<AxiosResponse<any>> {
        return _get(RECONCILIATION_TRANSACTION_GRID_API + id + "/transaction");
    }

    getWeekOnWeekGridRows(id: string): Promise<AxiosResponse<any>> {
        return _get(RECONCILIATION_TRANSACTION_GRID_API + id + "/WeekonWeek");
    }
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default ReconciliationGridService;
