import React, { MouseEvent, Component } from "react";
import CardWrapper from "./CardWrapper";

interface CheckinCardAltProps {
    type: string;
    typeTitle: string;
    enumeration: boolean;
    cardIndex?: number;
    cardsTotal?: number;
}

class CheckinCardAlt extends React.Component<CheckinCardAltProps> {
    constructor(props: CheckinCardAltProps) {
        super(props);
    }

    render() {
        return (
            <CardWrapper enumeration={false} type="security" typeTitle="security - Door supervision" fullHeight={true}>
                <div className="euk-card__content-section">
                    <div className="checkin-layout">
                        <div className="euk-card__title">Richard Fitzgerald</div>
                        <div className="checkin-layout__licence-no">0000-0000-0000-0000</div>
                        <div className="checkin-layout__time-indicators row">
                            <div className="col-6">
                                <div className="time-indicator time-indicator--checkin">00:00</div>
                            </div>
                            <div className="col-6">
                                <div className="time-indicator time-indicator--checkout">00:00</div>
                            </div>
                        </div>
                        <div className="checkin-layout__controls row">
                            <div className="col-6">
                                <button className="btn btn--large btn-primary">Check In</button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn--large btn-primary">Check Out</button>
                            </div>
                        </div>
                    </div>
                </div>
            </CardWrapper>
        );
    }
}
export default CheckinCardAlt;
