import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { DateBox, LoadPanel } from "devextreme-react";
import billingFormUtils, { BillingFormData, BillingStatus, documentObject } from "../Billing/BillingFormUtils";
import BillingService, { billingCycleStatus, billingStatus, logicAppPostRequest } from "../../services/BillingService";
import sharedUtils from "../grid/sharedUtilities";
import { BillingCyclesGridServiceInvoiceItem } from "../../services/BillingCyclesGridService";
import { AxiosResponse } from "axios";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import { DateBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { NullableDate } from "../grid/AddEditPopUpUtilities";

interface DocumentsSectionProps {
    invoiceId: number;
    billingData: BillingFormData;
    onBillProduced: (status: BillingStatus, statusId: string) => void;
    statusId: string;
    invoiceSentDate: NullableDate;
    syncDateFields: (date: NullableDate, type: string) => void;
    calculationsObject: any;
    viewDisable: boolean;
}

interface DocumentsSectionState {
    loadPanelVisible: boolean;
    disableProduceButton: boolean;
    disableSentButton: boolean;
    statusIdOnClick: string;
    downloadLink: string;
    downloadLinkCss: string;
    billingFormData: BillingFormData;
    errorMessage: [];
}

class DocumentsSection extends React.Component<DocumentsSectionProps> {
    state: DocumentsSectionState;
    BillingService: BillingService;
    billingFormUtils: billingFormUtils;
    sharedUtils: sharedUtils;
    documentService: ManageDocumentsGridService;

    constructor(props: DocumentsSectionProps) {
        super(props);
        this.BillingService = new BillingService();
        this.billingFormUtils = new billingFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            loadPanelVisible: false,
            disableProduceButton: this.props.viewDisable ? true : false,
            disableSentButton: this.props.viewDisable ? true : false,
            statusIdOnClick: "",
            downloadLink: "",
            downloadLinkCss: "icon-btn",
            billingFormData: this.props.billingData,
            errorMessage: [],
        };
        this.documentService = new ManageDocumentsGridService();
    }

    //Series of helper functions that would trigger the download of Invoice documents.
    onDownloadClick = (id: string, link: string) => {
        this.setState({
            loadPanelVisible: true,
            downloadLink: link,
        });
        this.documentService.getDocumentById(id).then(this.handleDownloadSuccess).catch(this.handleDownloadError);
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        var link = this.state.downloadLink.split("/");
        var filename = link[link.length - 1];
        var FileSaver = require("file-saver");
        FileSaver.saveAs(response.data, filename);
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleDownloadError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "getDocumentById failed with response: " + JSON.stringify(error);

        if (!this.documentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    //Trigger the onChange function for "Invoice Sent Date" field which is to be synchronised with the form.
    handleChangeSentDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            billingFormData: {
                ...this.state.billingFormData,
                invoiceSentDate: dxValueChange.value,
            },
        });
        this.props.syncDateFields(dxValueChange.value, "Sent");
    };

    //Function which is invoked when the "Produce" as well as "Sent" button is clicked.
    onBillProduced = (statusId: string, status: string) => {
        let data: BillingCyclesGridServiceInvoiceItem = this.billingFormUtils.initializeBillingCyclesGridServiceInvoiceItem();
        data.invoiceId = this.props.invoiceId ? this.props.invoiceId.toString() : "";
        data.clientId = this.props.billingData.clientId;
        data.client = this.props.billingData.client;
        data.statusId = statusId;
        data.status = status;
        data.periodMatrixId = this.props.billingData.periodMatrixId;
        data.period = this.props.billingData.period;
        data.week = this.props.billingData.week;
        data.dateRangeFrom = this.props.billingData.dateRangeFrom
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeFrom)
            : "";
        data.dateRangeTo = this.props.billingData.dateRangeTo
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeTo)
            : "";
        data.tax =
            this.props.calculationsObject && this.props.calculationsObject.tax
                ? this.props.calculationsObject.tax.toString()
                : "";
        data.subTotal =
            this.props.calculationsObject && this.props.calculationsObject.subTotal
                ? this.props.calculationsObject.subTotal.toString()
                : "";
        data.total =
            this.props.calculationsObject && this.props.calculationsObject.total
                ? this.props.calculationsObject.total.toString()
                : "";
        data.invoiceDate = this.props.billingData.invoiceDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDate)
            : "";
        data.invoiceReference = this.props.billingData.invoiceReference
            ? this.props.billingData.invoiceReference.toString()
            : "";
        data.internalReference = this.props.billingData.internalReference
            ? this.props.billingData.internalReference.toString()
            : "";
        data.invoiceSentDate = this.state.billingFormData.invoiceSentDate
            ? this.sharedUtils.convertDateToString(this.state.billingFormData.invoiceSentDate)
            : "";
        data.invoiceDueDate = this.props.billingData.invoiceDueDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDueDate)
            : "";

        data.invoicePaidDate = this.props.billingData.invoicePaidDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoicePaidDate)
            : "";
        data.invoiceAmount = this.props.billingData.invoiceAmount
            ? this.props.billingData.invoiceAmount.toString()
            : "";
        data.description = this.props.billingData.description ? this.props.billingData.description.toString() : "";
        data.actionType = "Save";
        data.invoiceServiceMappingList = this.props.billingData.invoiceServiceMappingList;

        this.setState({
            gridData: data,
            loadPanelVisible: true,
            statusIdOnClick: statusId,
        });
        this.onSubmit(data);
    };

    onSubmit = (billingRowData: BillingCyclesGridServiceInvoiceItem) => {
        this.BillingService.billingDataService(billingRowData).then(this.handleSuccess).catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        const { invoiceId } = this.props;
        const { statusIdOnClick } = this.state;

        this.setState(
            (prevState: DocumentsSectionState) => ({
                loadPanelVisible: false,
                disableProduceButton: true,
                disableSentButton: statusIdOnClick == billingCycleStatus.BillPackSent ? true : false,
                downloadLinkCss: "icon-btn",
                errorMessage: [],
            }),
            () =>
                this.props.onBillProduced(
                    statusIdOnClick == billingCycleStatus.BillPackRequested
                        ? BillingStatus.BillPackRequested
                        : BillingStatus.BillPackSent,
                    statusIdOnClick
                )
        );
        if (statusIdOnClick == billingCycleStatus.BillPackRequested) {
            let postObject: logicAppPostRequest = {
                InvoiceId: invoiceId ? invoiceId.toString() : "",
            };
            this.BillingService.triggerLogicAppEndpoint(postObject);
        }
    };

    handleFailure = (error: any) => {
        var { statusIdOnClick } = this.state;
        var respMessage: string = "postBillingCycleInvoice failed with response: " + JSON.stringify(error);

        if (!this.BillingService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            errorMessage: error.response !== null ? JSON.parse(JSON.stringify(error.response.data.error)) : null,
            loadPanelVisible: false,
            disableProduceButton: false,
            downloadLinkCss: statusIdOnClick == billingCycleStatus.BillPackRequested ? "disabled icon-btn" : "icon-btn",
        });
    };

    onRetry = () => {
        this.setState({loadPanelVisible: true});
        const { invoiceId } = this.props;
        let postObject: logicAppPostRequest = {
            InvoiceId: invoiceId ? invoiceId.toString() : "",
        };
        this.BillingService.triggerLogicAppEndpoint(postObject).then((response) => {
            if(response.status) {
                this.setState({loadPanelVisible: false});
            }            
        });
    }

    //Inside the update function, when the Sent date is changed in the form, it would reflect here and vice versa.
    componentDidUpdate = (prevProps: DocumentsSectionProps) => {
        if (this.props.invoiceSentDate != prevProps.invoiceSentDate) {
            this.setState({
                billingFormData: {
                    ...this.state.billingFormData,
                    invoiceSentDate: this.props.invoiceSentDate,
                },
            });
        }
    };

    render() {
        var documentsList: documentObject[] = this.billingFormUtils.extractDocumentLink(this.props.billingData);
        var produceButtonText: string = "PRODUCE";
        var { statusId } = this.props;
        var { disableProduceButton, disableSentButton } = this.state;
        var iconClass: string =
            statusId &&
            statusId != billingCycleStatus.Pending &&
            statusId != billingCycleStatus.ReadyForBilling &&
            statusId != billingCycleStatus.BillChecked &&
            statusId != billingCycleStatus.BillPackRequested
                ? "icon-btn"
                : "disabled icon-btn";
        let retryButtonDisabled: boolean = true;
        let retryButtonCssClass: string = "btn disabledCycleButtonColor btn--large";
        if (statusId && statusId == billingCycleStatus.BillPackRequested) {
            produceButtonText = "Documents Requested";
        } else if (
            statusId &&
            (statusId == billingCycleStatus.BillPackReady ||
                statusId == billingCycleStatus.BillPackSent ||
                statusId == billingCycleStatus.BillPaid ||
                statusId == billingCycleStatus.BillQueried ||
                statusId == billingCycleStatus.BillNotPaid)
        ) {
            produceButtonText = "Documents Ready";
        }
        var produceButtonCssClass: string =
            disableProduceButton ||
            (statusId != billingCycleStatus.Pending &&
                statusId != billingCycleStatus.ReadyForBilling &&
                statusId != billingCycleStatus.BillChecked)
                ? "btn disabledCycleButtonColor btn--large"
                : "btn saveCycleButtonColor btn--large";
        var disableBillProduceButton: boolean =
            disableProduceButton ||
            (statusId != billingCycleStatus.Pending &&
                statusId != billingCycleStatus.ReadyForBilling &&
                statusId != billingCycleStatus.BillChecked)
                ? true
                : false;
        var disableBillSentButton: boolean = statusId != billingCycleStatus.BillPackReady ? false : true;
        var sentButtonCssClass: string = "btn disabledCycleButtonColor btn--large";        
        var sentButtonDisabled: boolean = true;        
        if (disableBillSentButton && disableSentButton == false) {
            sentButtonDisabled = false;
            sentButtonCssClass = "btn saveCycleButtonColor btn--large";
        }
        if (statusId && (statusId == billingCycleStatus.BillPackRequested || statusId == billingCycleStatus.BillChecked)) {
            retryButtonDisabled = false;
            retryButtonCssClass = "btn retryCycleButtonColor btn--large";
        }
        return (
            <div className="card my-3">
                <div className="card-body">
                    <h4>
                        <b>Documents</b>
                    </h4>
                    <>
                        <div className="row mt-3 mb-5">
                            <div className="col-4">
                                <b>Client Invoice</b>
                                <span className={iconClass}>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        onClick={() => this.onDownloadClick(documentsList[0].id, documentsList[0].link)}
                                    />
                                </span>
                            </div>
                            <div className="col-4">
                                <b>Client Breakdown</b>
                                <span className={iconClass}>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        onClick={() => this.onDownloadClick(documentsList[1].id, documentsList[1].link)}
                                    />
                                </span>
                            </div>
                            <div className="col-4">
                                <b>Finance Report</b>
                                <span className={iconClass}>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        onClick={() => this.onDownloadClick(documentsList[2].id, documentsList[2].link)}
                                    />
                                </span>
                            </div>
                        </div>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        {this.state.errorMessage ? (
                            <span className="unscheduled-shift">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className="row align-items-center">
                            <div className="col-2">
                                <button
                                    className={produceButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onBillProduced(
                                            billingCycleStatus.BillPackRequested,
                                            billingStatus.BillPackRequested
                                        );
                                    }}
                                    disabled={disableBillProduceButton}
                                >
                                    {produceButtonText}
                                </button>
                            </div>
                            <div className="col-2">
                                <button
                                    className={retryButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onRetry();
                                    }}
                                    disabled={retryButtonDisabled}
                                >
                                    RETRY
                                </button>
                            </div>
                            <div className="col-2">
                                <button
                                    className={sentButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onBillProduced(
                                            billingCycleStatus.BillPackSent,
                                            billingStatus.BillPackSent
                                        );
                                    }}
                                    disabled={sentButtonDisabled}
                                >
                                    SENT
                                </button>
                            </div>
                            <div className="col-2 text-right">Sent Date</div>
                            <div className="col-4">
                                <DateBox
                                    displayFormat="dd/MM/yyyy"
                                    onValueChanged={this.handleChangeSentDate}
                                    value={
                                        this.state.billingFormData.invoiceSentDate
                                            ? this.state.billingFormData.invoiceSentDate
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    disabled={this.props.viewDisable ? true : false}
                                />
                            </div>
                        </div>
                    </>
                </div>
            </div>
        );
    }
}
export default DocumentsSection;
