import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import { SelectBox, LoadPanel, TextBox, CheckBox, NumberBox, Popup, ScrollView } from "devextreme-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import ContractFormUtil, { AddEditContractFormData } from "../ContractCleaning/ContractFormUtil";
import ContractCleaningService, { ContractCleaningGridRowItem } from "../../services/ContractCleaningService";
import BillingFormUtils from "../Billing/BillingFormUtils";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import {
    SelectBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
    NumberBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import DataGrid, {  Pager, Paging, FilterRow, Column, Sorting, SearchPanel} from "devextreme-react/data-grid";
import gridUtils, { currencyTypeToSymbolMatrix, currencyTypes } from "../grid/GridUtilities";
import SimpleShiftsByService from "../SimpleShiftsByService/SimpleShiftsByService";
import { ServiceType } from "../../common/ServiceUtilities";

type NullableDate = Date | null | undefined;
//#region props
interface ContractFormProps extends WithTranslation {
    location: any;
    history: any;
    onApplySuccess: (applySignal: string) => void;
    isEditForm: any;
    filterText: any;
}

// State
interface ContractFormState {
    //TODO : Define the form state here.

    clientLookUp: LookupTypeItem[];
    serviceLookUp: LookupTypeItem[];
    subServiceLookUp: LookupTypeItem[];
    venueLookUp: LookupTypeItem[];
    providerLookUp: LookupTypeItem[];
    frequencyLookUp: LookupTypeItem[];
    editPeriodFormData: AddEditContractFormData;
    //PeriodMatrix: any;
    loadPanelVisible: boolean;
    disableDayTextbox: boolean;
    filterId: string;
    errorMessage: [];
    showError: boolean;
    isShiftGridVisible: boolean;
    serviceId: string;
    contractCleaningId: string;
    venueContractFormData: ContractCleaningGridRowItem[];
    isVenueGridVisible: boolean;
    showDuplicateItem: boolean;
    //weekLookUp: LookupTypeItem[];
}

class ContractForm extends React.Component<ContractFormProps> {
    lookupService: LookupService;
    state: ContractFormState;
    utils: ContractFormUtil;
    cleaningService: ContractCleaningService;
    periodWeekUtil: BillingFormUtils;
    gridUtils: gridUtils;
    constructor(props: ContractFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.utils = new ContractFormUtil(props);
        this.cleaningService = new ContractCleaningService();
        this.periodWeekUtil = new BillingFormUtils();
        this.gridUtils = new gridUtils();
        var convertedEditPeriodData = this.utils.initializePeriodFormItem(
            props.location.state && props.location.state.contractCleaningId
                ? props.location.state.contractCleaningId
                : ""
        );
        this.state = {
            clientLookUp: [],
            serviceLookUp: [],
            subServiceLookUp: [],
            venueLookUp: [],
            providerLookUp: [],
            frequencyLookUp: [],
            editPeriodFormData: convertedEditPeriodData,

            loadPanelVisible: false,
            disableDayTextbox: false,
            filterId: "20",
            errorMessage: [],
            showError: false,
            isShiftGridVisible: false,
            serviceId: "",
            contractCleaningId: "",
            venueContractFormData: [],
            isVenueGridVisible: false,
            showDuplicateItem: false,
        };
        this.lookUpCreation = this.lookUpCreation.bind(this);
        this.handleServiceLUSuccess = this.handleServiceLUSuccess.bind(this);
        this.handleSubServiceLUSuccess = this.handleSubServiceLUSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.getEditViewItemDataSource = this.getEditViewItemDataSource.bind(this);
        this.handleSuccessViewDataSource = this.handleSuccessViewDataSource.bind(this);
        this.onApplyClick = this.onApplyClick.bind(this);
        this.onViewContractsClick = this.onViewContractsClick.bind(this);
        this.onViewShiftClick = this.onViewShiftClick.bind(this);
        this.handleSuccessVenueData = this.handleSuccessVenueData.bind(this);
        this.hideInfoVenueGrid = this.hideInfoVenueGrid.bind(this);
        this.handleSuccessDataSubmission = this.handleSuccessDataSubmission.bind(this);
        this.handleSuccessDataValidate = this.handleSuccessDataValidate.bind(this);
    }

    //#region 'componentDidMount and Service Call"
    componentDidMount() {
        this.dropDownDataSource();
        this.dropDownSubDataSource();
        this.getEditViewItemDataSource();
    }

    dropDownDataSource() {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
            .then(this.handleServiceLUSuccess)
            .catch(this.handleError);
    }

    dropDownSubDataSource() {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.subServiceType)
            .then(this.handleSubServiceLUSuccess)
            .catch(this.handleError);
        
    }

    lookUpCreation(response: AxiosResponse<any>): LookupTypeItem[] {
        var itemLUItem: LookupTypeItem;
        var itemLUItemArray: LookupTypeItem[];
        itemLUItemArray = [];
        response.data.data.map((item: any, uniqueKey: number) => {
            itemLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: item.parentMappingId == null ? "0" : item.parentMappingId,
            };
            itemLUItemArray.push(itemLUItem);
        });
        return itemLUItemArray;
    }

    handleServiceLUSuccess(response: AxiosResponse<any>) {
        var serviceDropDown = this.lookUpCreation(response);
        let serviceTypeDataSource = serviceDropDown.filter((item: any) => {
            return (item.id != ServiceType.Entertainment && item.id != ServiceType.Security)
        });
        this.setState({
            serviceLookUp: serviceTypeDataSource,
        });
    }

    handleSubServiceLUSuccess(response: AxiosResponse<any>) {
        var serviceDropDown = this.lookUpCreation(response);
        this.setState({
            subServiceLookUp: serviceDropDown,
        });
    }

    getEditViewItemDataSource() {
        var id = this.props.location.state;
        if (this.props.location.state && this.props.location.state.id) {
            this.cleaningService
                .getContractCleaningDataItem(this.props.location.state.id)
                .then(this.handleSuccessViewDataSource)
                .catch(this.handleError);
        }
    }

    handleSuccessViewDataSource(response: AxiosResponse<any>) {
        var viewDataSource = this.utils.convertContractItemResponeToFormData(response.data.data);
        this.setState({
            editPeriodFormData: viewDataSource,
        });
    }

    handleError(err: any) {
        this.setState({
            errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
            showError: true,
            loadPanelVisible: false,
        });
    }

    handleChangeClient = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                clientId: dxValueChange.value,
            },
        });
    };

    handleChangeVenue = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                venuId: dxValueChange.value,
            },
        });
    };

    handleChangeServiceType = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                serviceSubTypeId: dxValueChange.value,
                typeLookUpId: "",
            },
        });
    };

    handleChangeTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {            
            this.setState({
                editPeriodFormData: {
                    ...this.state.editPeriodFormData,
                    typeLookUpId: dxValueChange.value,
                },
            });
        }
    };

    handleChangeService = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if(this.props.filterText == "ContractCleaning"){
            this.setState({
                editPeriodFormData: {
                    ...this.state.editPeriodFormData,
                    serviceTypeId: "20",
                    typeLookUpId: "",
                },
            });
        }
        else{
            this.setState({
                editPeriodFormData: {
                    ...this.state.editPeriodFormData,
                    serviceTypeId: dxValueChange.value,
                    typeLookUpId: "",
                },
            });
        }        
    };

    handleChangeProvider = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                providerId: dxValueChange.value,
            },
        });
    };

    handleChangeActive = (dxValueChange: CheckBoxOnValueChanged) => {
        var activeFlag = dxValueChange.value;
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                isActive: activeFlag ? "True" : "False",
            },
        });
    };

    handleChangeVariableHours = (dxValueChange: CheckBoxOnValueChanged) => {
        var flag = dxValueChange.value;
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                variableHours: flag ? "True" : "False",
            },
        });
        if (flag) {
            this.setState({
                editPeriodFormData: {
                    ...this.state.editPeriodFormData,
                    monday: 0,
                    tuesday: 0,
                    wednesday: 0,
                    thursday: 0,
                    friday: 0,
                    saturday: 0,
                    sunday: 0,
                    total: 0,
                },
            });
        }
    };

    handleChangeMon = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState((prevState: ContractFormState) => ({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                monday: dxValueChange.value,
                total: prevState.editPeriodFormData.total + dxValueChange.value,
            },
        }));
    };

    handleChangeTue = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState((prevState: ContractFormState) => ({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                tuesday: dxValueChange.value,
                total: prevState.editPeriodFormData.total + dxValueChange.value,
            },
        }));
    };

    handleChangeWed = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState((prevState: ContractFormState) => ({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                wednesday: dxValueChange.value,
                total: prevState.editPeriodFormData.total + dxValueChange.value,
            },
        }));
    };

    handleChangeThu = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState((prevState: ContractFormState) => ({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                thursday: dxValueChange.value,
                total: prevState.editPeriodFormData.total + dxValueChange.value,
            },
        }));
    };

    handleChangeFri = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState((prevState: ContractFormState) => ({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                friday: dxValueChange.value,
                total: prevState.editPeriodFormData.total + dxValueChange.value,
            },
        }));
    };

    handleChangeSat = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState((prevState: ContractFormState) => ({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                saturday: dxValueChange.value,
                total: prevState.editPeriodFormData.total + dxValueChange.value,
            },
        }));
    };

    handleChangeSun = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState((prevState: ContractFormState) => ({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                sunday: dxValueChange.value,
                total: prevState.editPeriodFormData.total + dxValueChange.value,
            },
        }));
    };

    handleChangeRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                rate: dxValueChange.value,
            },
        });
    };

    handleChangeRepeatingRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                rate: dxValueChange.value,
            },
        });
        this.calculateRepeatingServiceTotal(this.state.editPeriodFormData.total, dxValueChange.value);
    };

    handleChangeTotal = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event != undefined) {
            this.setState({
                editPeriodFormData: {
                    ...this.state.editPeriodFormData,
                    total: dxValueChange.value,
                    monday: 0.0,
                    tuesday: 0.0,
                    wednesday: 0.0,
                    thursday: 0.0,
                    friday: 0.0,
                    saturday: 0.0,
                    sunday: 0.0,
                },
            });
        }
    };

    handleChangeFrequency = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                frequencyId: dxValueChange.value,
            },
        });
    };

    handleChangeFee = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                feeOverride: dxValueChange.value,
            },
        });
    };

    handleChangeOverrideFlag = (dxValueChange: CheckBoxOnValueChanged) => {
        var overRideFlag = dxValueChange.value;
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                feeOverrideFlag: overRideFlag ? "True" : "False",
            },
        });
    };

    handleChangeQuantity = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editPeriodFormData: {
                    ...this.state.editPeriodFormData,
                    total: dxValueChange.value,
                },
            });
        }
        this.calculateRepeatingServiceTotal(dxValueChange.value, this.state.editPeriodFormData.rate);
    };

    handleChangeOtherDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                editPeriodFormData: {
                    ...this.state.editPeriodFormData,
                    otherDescription: dxValueChange.value,
                },
            });
        }
    };

    calculateRepeatingServiceTotal = (quantity: any, rate: any) => {
        let amount = rate * quantity;
        this.setState({
            editPeriodFormData: {
                ...this.state.editPeriodFormData,
                providerPayAmountLocal: amount,
            },
        });
    };

    onApplyClick() {
        this.setState({
            loadPanelVisible: true,
        });
        let periodSubmitRequest = this.utils.convertFormDataToContractItemRequest(this.state.editPeriodFormData);

        this.cleaningService
            .validateContractDataItem(periodSubmitRequest)
            .then(this.handleSuccessDataValidate)
            .catch(this.handleError);        
    }

    handleSuccessDataValidate(response: AxiosResponse<any>) {
        this.setState({
            loadPanelVisible: false,
        });

        let serverResponse: any = response.data.data; 
        if(serverResponse.length > 0){
            this.setState({
                showDuplicateItem: true,
            });  
        }
        else {
            this.setState({
                loadPanelVisible: true,
            });
            let periodSubmitRequest = this.utils.convertFormDataToContractItemRequest(this.state.editPeriodFormData);
            this.cleaningService
                .saveContractCleaningDataItem(periodSubmitRequest)
                .then(this.handleSuccessDataSubmission)
                .catch(this.handleError);      
        }
    }

    handleSuccessDataSubmission() {
        this.setState({
            loadPanelVisible: false,
        });

        if (this.props.location.state && this.props.location.state.id) {
            this.props.onApplySuccess("EDIT");
        } else if (this.state.editPeriodFormData.contractCleaningId == "0") {
            this.props.onApplySuccess("ADD");
        }
    }
    removeDataSource = (data: LookupTypeItem[], filterId: string): LookupTypeItem[] => {
        return !this.isBlank(filterId)
            ? data.filter((item: LookupTypeItem) => {
                  return item.id !== filterId;
              })
            : data;
    };
    filterDataSource = (data: LookupTypeItem[], filterId: string): LookupTypeItem[] => {
        return !this.isBlank(filterId)
            ? data.filter((item: LookupTypeItem) => {
                  return item.id === filterId;
              })
            : data;
    };
    filterSubServiceDataSource = (filterId: string): LookupTypeItem[] => {
        return !this.isBlank(filterId)
            ? this.state.subServiceLookUp.filter((item: LookupTypeItem) => {
                  return item.parentMappingId === filterId;
              })
            : this.state.subServiceLookUp;
    };
    isBlank(filterId: string) {
        return !filterId || /^\s*$/.test(filterId) || 0 === filterId.length;
    }
    onViewShiftClick() {
        this.setState({
            isShiftGridVisible: true,
            contractCleaningId: this.state.editPeriodFormData.contractCleaningId,
            serviceId: this.state.editPeriodFormData.serviceTypeId,
        });
    }
    hideInfoVenueGrid() {
        this.setState({
            isVenueGridVisible: false,
            isShiftGridVisible: false,
            showDuplicateItem: false
        });
    }
    onViewContractsClick() {
        this.setState({
            loadPanelVisible: true,
        });
        let contractVenueId = this.state.editPeriodFormData.venuId;
        this.cleaningService.getContractsDataItemByVenue(contractVenueId).then(this.handleSuccessVenueData).catch(this.handleError);
    }
    handleSuccessVenueData(response: AxiosResponse<any>) {
        this.setState({
            venueContractFormData: response.data.data,
            loadPanelVisible: false,
            isVenueGridVisible: true
        });
    }
    onProceed() {
        this.setState({
            loadPanelVisible: true,
        });
        let periodSubmitRequest = this.utils.convertFormDataToContractItemRequest(this.state.editPeriodFormData);
        this.cleaningService
            .saveContractCleaningDataItem(periodSubmitRequest)
            .then(this.handleSuccessDataSubmission)
            .catch(this.handleError);  
    };
    onContentReadyNumberBox = (e: any) => {
        this.onClickElementSelection(e);
    }
    onClickElementSelection = (e: any) => {
        e.element.addEventListener('click', function () {
            let inputElement = e.element.querySelector(".dx-texteditor-input");
            inputElement.selectionStart = 0;
            inputElement.selectionEnd = inputElement.value.length;
        });
    }

    setCalculations = () => {};

    // form for CongtractCleaning
    renderContractCleaningPart = () => {        
        return (
            <>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Client</div>
                    <div className="mt-3 col-10 col-lg-3">
                        <DropDownFromLookupTable
                            lookupTypeIndex={LookupTypeIndexes.clientType}
                            onValueChanged={this.handleChangeClient}
                            value={this.state.editPeriodFormData.clientId}
                            recordType={
                                this.props.location.state && this.props.location.state.id ? "all" : "active"
                            }
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Venue Name</div>
                    <div className="mt-3 col-10 col-lg-5 text-left">
                        <LookupTypeListDynamic
                            lookupTypeIndex={LookupTypeIndexes.venueType}
                            onValueChanged={this.handleChangeVenue}
                            isRoot={false}
                            parentMappingId={this.state.editPeriodFormData.clientId}
                            value={this.state.editPeriodFormData.venuId}
                            displayExpression={"value"}
                            recordType={
                                this.props.location.state && this.props.location.state.id ? "all" : "active"
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Service</div>
                    <div className="mt-3 col-10 col-lg-3">
                        <SelectBox
                            dataSource={this.filterDataSource(this.state.serviceLookUp, this.state.filterId)}
                            displayExpr={"value"}
                            valueExpr={"id"}
                            onValueChanged={this.handleChangeService}
                            value={this.state.editPeriodFormData.serviceTypeId = "20"}
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Sub Type</div>
                    <div className="mt-3 col-10 col-lg-5">
                        <SelectBox
                            dataSource={this.filterDataSource(this.state.subServiceLookUp, "21")}
                            displayExpr={"value"}
                            valueExpr={"id"}
                            onValueChanged={this.handleChangeServiceType}
                            value={this.state.editPeriodFormData.serviceSubTypeId}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">
                        {this.props.t("addEditShiftRow.summarySection.providerDropdown.label")}
                    </div>
                    <div className="mt-3 col-10 col-lg-3 text-left">
                        <LookupTypeListDynamic
                            lookupTypeIndex={LookupTypeIndexes.providerType}
                            onValueChanged={this.handleChangeProvider}
                            isRoot={false}
                            parentMappingId={""}
                            value={this.state.editPeriodFormData.providerId}
                            displayExpression={"value"}
                            recordType={
                                this.props.location.state && this.props.location.state.id ? "all" : "active"
                            }
                        />                        
                    </div>

                    <div className="mt-3 col-2 col-lg-2">
                        <CheckBox
                            value={this.state.editPeriodFormData.isActive != "False" ? true : false}
                            onValueChanged={this.handleChangeActive}
                            width={130}
                            text="Is Active?"
                        />
                    </div>
                    <div className="mt-3 col-3 col-lg-2">
                        <CheckBox
                            value={this.state.editPeriodFormData.variableHours != "False" ? true : false}
                            onValueChanged={this.handleChangeVariableHours}
                            width={150}
                            text="Variable Hours?"
                        />
                    </div>
                    {/* <div className='mt-3 col-2 col-lg-1 font-weight-bold font_size'>
                        
                    </div> */}
                    <div className="mt-3 col-3 col-lg-2">
                        <CheckBox
                            value={this.state.editPeriodFormData.feeOverrideFlag != "False" ? true : false}
                            onValueChanged={this.handleChangeOverrideFlag}
                            width={130}
                            text="Override Fee?"
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Override Fee</div>
                    <div className="mt-3 col-10 col-lg-1">
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeFee}
                            valueChangeEvent="keyup"
                            value={this.state.editPeriodFormData.feeOverride}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Mon</div>
                    <div className="mt-3 col-10 col-lg-1">
                        <NumberBox
                            format={{ type: "fixedPoint", precision: 2 }}
                            value={this.state.editPeriodFormData.monday}
                            disabled={this.state.editPeriodFormData.variableHours != "False" ? true : false}
                            onValueChanged={this.handleChangeMon}
                            valueChangeEvent="keyup"
                        />
                    </div>

                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Tue</div>
                    <div className="mt-3 col-10 col-lg-1">
                        <NumberBox
                            format={{ type: "fixedPoint", precision: 2 }}
                            value={this.state.editPeriodFormData.tuesday}
                            disabled={this.state.editPeriodFormData.variableHours != "False" ? true : false}
                            onValueChanged={this.handleChangeTue}
                            valueChangeEvent="keyup"
                        />
                    </div>

                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Wed</div>
                    <div className="mt-3 col-10 col-lg-1">
                        <NumberBox
                            format={{ type: "fixedPoint", precision: 2 }}
                            value={this.state.editPeriodFormData.wednesday}
                            disabled={this.state.editPeriodFormData.variableHours != "False" ? true : false}
                            onValueChanged={this.handleChangeWed}
                            valueChangeEvent="keyup"
                        />
                    </div>

                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Thu</div>
                    <div className="mt-3 col-10 col-lg-1">
                        <NumberBox
                            format={{ type: "fixedPoint", precision: 2 }}
                            value={this.state.editPeriodFormData.thursday}
                            disabled={this.state.editPeriodFormData.variableHours != "False" ? true : false}
                            onValueChanged={this.handleChangeThu}
                            valueChangeEvent="keyup"
                        />
                    </div>

                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Fri</div>
                    <div className="mt-3 col-10 col-lg-1">
                        <NumberBox
                            format={{ type: "fixedPoint", precision: 2 }}
                            value={this.state.editPeriodFormData.friday}
                            disabled={this.state.editPeriodFormData.variableHours != "False" ? true : false}
                            onValueChanged={this.handleChangeFri}
                            valueChangeEvent="keyup"
                        />
                    </div>

                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Sat</div>
                    <div className="mt-3 col-10 col-lg-1">
                        <NumberBox
                            format={{ type: "fixedPoint", precision: 2 }}
                            value={this.state.editPeriodFormData.saturday}
                            disabled={this.state.editPeriodFormData.variableHours != "False" ? true : false}
                            onValueChanged={this.handleChangeSat}
                            valueChangeEvent="keyup"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Sun</div>
                    <div className="mt-3 col-10 col-lg-1">
                        <NumberBox
                            format={{ type: "fixedPoint", precision: 2 }}
                            disabled={this.state.editPeriodFormData.variableHours != "False" ? true : false}
                            onValueChanged={this.handleChangeSun}
                            valueChangeEvent="keyup"
                            value={this.state.editPeriodFormData.sunday}
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Total</div>
                    <div className="mt-3 col-10 col-lg-1">
                        <NumberBox
                            format={{ type: "fixedPoint", precision: 2 }}
                            disabled={this.state.editPeriodFormData.variableHours != "False" ? true : false}
                            onValueChanged={this.handleChangeTotal}
                            value={this.state.editPeriodFormData.total}
                            valueChangeEvent="keyup"
                        />
                    </div>

                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Rate</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeRate}
                            valueChangeEvent="keyup"
                            value={this.state.editPeriodFormData.rate}
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Frequency</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <DropDownFromLookupTableDynamic
                            lookupTypeIndex={LookupTypeIndexes.frequencyType}
                            isRoot={true}
                            parentMappingId={"0"}
                            onValueChanged={this.handleChangeFrequency}
                            value={this.state.editPeriodFormData.frequencyId}
                        />
                    </div>
                </div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                <br></br>
                <br></br>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                        Last Updated On
                    </div>
                    <div className="mt-3 col-10 col-lg-2" hidden={!this.props.isEditForm}>
                        <TextBox value={this.state.editPeriodFormData.lastUpdatedOn} readOnly={true} />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                        Last Updated By
                    </div>
                    <div className="mt-3 col-10 col-lg-3" hidden={!this.props.isEditForm}>
                        <TextBox value={this.state.editPeriodFormData.lastUpdatedBy} readOnly={true} />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size"></div>
                    <div
                        className="mt-3 col-2 col-lg-7 font-weight-bold font_size"
                        hidden={this.props.isEditForm}
                    ></div>
                </div>
                <div className="row pt-5">
                    <div className="col-12 col-lg-2 mt-3">
                        <Link
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    isCancel: true,
                                },
                            }}
                            className="btn btn--ghost btn--large"
                        >
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                            {this.props.t("addEditShiftRow.financeSection.cancelButton.text")}
                        </Link>
                    </div>
                    <div className="col-12 col-lg-2 mt-3">
                        <button
                            className="btn btn-primary btn--large"
                            type="button"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onApplyClick();
                            }}
                        >
                            <span className="btn__icon"></span>
                            {this.props.t("addEditShiftRow.financeSection.applyButton.text")}
                        </button>
                    </div>
                    <div className="col-12 col-lg-2 mt-3">
                        <button
                            className="btn btn-primary btn--large"
                            type="button"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onViewShiftClick();
                            }}
                        >
                            <span className="btn__icon"></span>
                            View Shifts
                        </button>
                    </div>
                    <div className="col-12 col-lg-3 mt-3">
                        <button
                            className="btn btn-primary btn--large"
                            type="button"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onViewContractsClick();
                            }}
                        >
                            <span className="btn__icon"></span>
                            View Venue Contracts
                        </button>
                    </div>
                </div>
            </>
        )        
    }

    // form for Repeating Service
    renderRepeatingServicePart = () => {   
        return (
            <>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Client</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <DropDownFromLookupTable
                            lookupTypeIndex={LookupTypeIndexes.clientType}
                            onValueChanged={this.handleChangeClient}
                            value={this.state.editPeriodFormData.clientId}
                            recordType={
                                this.props.location.state && this.props.location.state.id ? "all" : "active"
                            }
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Venue Name</div>
                    <div className="mt-3 col-10 col-lg-8 text-left">
                        <LookupTypeListDynamic
                            lookupTypeIndex={LookupTypeIndexes.venueType}
                            onValueChanged={this.handleChangeVenue}
                            isRoot={false}
                            parentMappingId={this.state.editPeriodFormData.clientId}
                            value={this.state.editPeriodFormData.venuId}
                            displayExpression={"value"}
                            recordType={
                                this.props.location.state && this.props.location.state.id ? "all" : "active"
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">
                        {this.props.t("addEditShiftRow.summarySection.providerDropdown.label")}
                    </div>
                    <div className="mt-3 col-10 col-lg-2 text-left">
                        <LookupTypeListDynamic
                            lookupTypeIndex={LookupTypeIndexes.providerType}
                            onValueChanged={this.handleChangeProvider}
                            isRoot={false}
                            parentMappingId={""}
                            value={this.state.editPeriodFormData.providerId}
                            displayExpression={"value"}
                            recordType={
                                this.props.location.state && this.props.location.state.id ? "all" : "active"
                            }
                        />                       
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Frequency</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <DropDownFromLookupTableDynamic
                            lookupTypeIndex={LookupTypeIndexes.frequencyType}
                            isRoot={true}
                            parentMappingId={"0"}
                            onValueChanged={this.handleChangeFrequency}
                            value={this.state.editPeriodFormData.frequencyId}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Service</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <SelectBox
                            dataSource={this.state.serviceLookUp}
                            displayExpr={"value"}
                            valueExpr={"id"}
                            onValueChanged={this.handleChangeService}
                            value={this.state.editPeriodFormData.serviceTypeId}
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Sub Type</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <SelectBox
                            dataSource={this.state.editPeriodFormData.serviceTypeId == "20" ? this.filterDataSource(this.state.subServiceLookUp, "22")
                                : this.filterSubServiceDataSource(this.state.editPeriodFormData.serviceTypeId)}
                            displayExpr={"value"}
                            valueExpr={"id"}
                            onValueChanged={this.handleChangeServiceType}
                            value={this.state.editPeriodFormData.serviceSubTypeId}
                            disabled={this.state.editPeriodFormData.serviceTypeId == ""}
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Type</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <DropDownFromLookupTableDynamic
                            lookupTypeIndex={LookupTypeIndexes.offeringsType}
                            isRoot={true}
                            parentMappingId={this.state.editPeriodFormData.serviceSubTypeId}
                            onValueChanged={this.handleChangeTypeSelect}
                            value={this.state.editPeriodFormData.typeLookUpId}                            
                            isDisabled={this.state.editPeriodFormData.serviceSubTypeId == ""}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Quantity</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <NumberBox
                            onValueChanged={this.handleChangeQuantity}
                            valueChangeEvent="keyup"
                            value={this.state.editPeriodFormData.total}
                            onContentReady={this.onContentReadyNumberBox}
                            step={0}
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Rate</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeRepeatingRate}
                            valueChangeEvent="keyup"
                            value={this.state.editPeriodFormData.rate}
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Total</div>
                    <div className="mt-3 col-10 col-lg-2">
                        <NumberBox
                            format={{ type: "fixedPoint", precision: 2 }}
                            disabled={true}
                            onValueChanged={this.handleChangeTotal}
                            value={this.state.editPeriodFormData.providerPayAmountLocal}
                            valueChangeEvent="keyup"
                        />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Override Fee</div>
                        <div className="mt-3 col-3 col-lg-1 pl-0">
                            <CheckBox
                                value={this.state.editPeriodFormData.feeOverrideFlag != "False" ? true : false}
                                onValueChanged={this.handleChangeOverrideFlag}
                                width={150}
                                text="Override Fee?"
                            />
                        </div>
                        <div className="mt-3 col-10 col-lg-1">
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                onValueChanged={this.handleChangeFee}
                                valueChangeEvent="keyup"
                                value={this.state.editPeriodFormData.feeOverride}
                            />
                        </div>
                </div>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Description</div>
                    <div className="mt-3 col-10 col-lg-11">
                        <TextBox
                            onValueChanged={this.handleChangeOtherDescription}
                            value={this.state.editPeriodFormData.otherDescription}
                        ></TextBox>
                    </div>
                </div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                <br></br>
                <br></br>
                <div className="row">
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                        Last Updated On
                    </div>
                    <div className="mt-3 col-10 col-lg-2" hidden={!this.props.isEditForm}>
                        <TextBox value={this.state.editPeriodFormData.lastUpdatedOn} readOnly={true} />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>
                        Last Updated By
                    </div>
                    <div className="mt-3 col-10 col-lg-3" hidden={!this.props.isEditForm}>
                        <TextBox value={this.state.editPeriodFormData.lastUpdatedBy} readOnly={true} />
                    </div>
                    <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size"></div>
                    <div
                        className="mt-3 col-2 col-lg-7 font-weight-bold font_size"
                        hidden={this.props.isEditForm}
                    ></div>
                </div>
                <div className="row pt-5">
                    <div className="col-12 col-lg-2 mt-3">
                        <Link
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    isCancel: true,
                                },
                            }}
                            className="btn btn--ghost btn--large"
                        >
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                            {this.props.t("addEditShiftRow.financeSection.cancelButton.text")}
                        </Link>
                    </div>
                    <div className="col-12 col-lg-2 mt-3">
                        <button
                            className="btn btn-primary btn--large"
                            type="button"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onApplyClick();
                            }}
                        >
                            <span className="btn__icon"></span>
                            {this.props.t("addEditShiftRow.financeSection.applyButton.text")}
                        </button>
                    </div>
                    <div className="col-12 col-lg-2 mt-3">
                        <button
                            className="btn btn-primary btn--large"
                            type="button"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onViewShiftClick();
                            }}
                        >
                            <span className="btn__icon"></span>
                            View Shifts
                        </button>
                    </div>
                    <div className="col-12 col-lg-3 mt-3">
                        <button
                            className="btn btn-primary btn--large"
                            type="button"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.onViewContractsClick();
                            }}
                        >
                            <span className="btn__icon"></span>
                            View Venue Contracts
                        </button>
                    </div>
                </div>
            </>
        )        
    }
    //#region render Component
    render() {
        return (
            <>
                <form data-testid="addEditShift-form" className="edit-form my-5 form_border">
                    <div className="container mb-6 edit-form__column">
                        <div className="row pt-3">
                            { this.props.filterText == "ContractCleaning" ? <h2 className="billingForm-heading">Contract Cleaning Form</h2>
                                : <h2 className="billingForm-heading">Repeating Service Form</h2>
                            }
                        </div>
                        <br></br>
                        <div className="row justify-content-left pt-3" hidden={!this.state.showError}>
                            <span className="text-danger font-weight-bold text-left">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size" hidden={!this.props.isEditForm}>ID</div>
                            <div className="mt-3 col-10 col-lg-2" hidden={!this.props.isEditForm}>
                                <TextBox value={this.state.editPeriodFormData.contractCleaningId} readOnly={true} />
                            </div>
                            <div className= {`mt-3 col-2 ${!this.props.isEditForm ? "col-lg-2 add-active" : "col-lg-1"}`} hidden={this.props.filterText == "ContractCleaning"}>
                                <CheckBox
                                    value={this.state.editPeriodFormData.isActive != "False" ? true : false}
                                    onValueChanged={this.handleChangeActive}
                                    width={130}
                                    text="Is Active?"
                                />
                            </div>
                        </div>
                        { this.props.filterText == "ContractCleaning" ? this.renderContractCleaningPart()
                            : this.renderRepeatingServicePart()
                        }
                    </div>
                </form>
                <Popup
                    visible={this.state.isShiftGridVisible}
                    onHiding={this.hideInfoVenueGrid}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Shifts belonging to same Contract"
                    width={"90%"}
                    height={600}
                >
                    <ScrollView width="100%" height="100%">
                        <SimpleShiftsByService
                            clientId=""
                            venueId=""
                            serviceTypeId={this.state.serviceId}
                            startDate=""
                            endDate=""
                            setCalculations={this.setCalculations}
                            navigatedFrom="contractCleaning"
                            residencyId={this.state.contractCleaningId}
                            getBuild={() => { return null }}
                        ></SimpleShiftsByService>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.isVenueGridVisible}
                    onHiding={this.hideInfoVenueGrid}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Contract belonging to same Venue"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <DataGrid
                                dataSource={this.state.venueContractFormData}
                                showBorders={false}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                columnAutoWidth={true}
                                allowColumnReordering={true}
                                selection={{ mode: "single" }}
                                allowColumnResizing={true}
                                columnResizingMode="widget"
                            >
                                <SearchPanel visible={true} placeholder={"Search"} />
                                <Sorting mode="single" />
                                <Paging defaultPageSize={5} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                <Column dataField="contractCleaningId" caption="ID"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertStringToInt(rowData.contractCleaningId);
                                    }}>                                        
                                </Column>
                                <Column dataField="clientName" allowSorting={true} caption="CLIENT"></Column>
                                <Column dataField="serviceTypeName" allowSorting={true} caption="SERVICE"></Column>
                                <Column dataField="serviceSubTypeName" allowSorting={true} caption="SERVICE TYPE"></Column>
                                <Column dataField="providerName" allowSorting={true} caption="PROVIDER"></Column>
                                <Column dataField="venuName" allowSorting={true} caption="VENUE NAME"></Column>
                                <Column
                                    dataField="monday"
                                    allowSorting={true}
                                    caption="MON"
                                    format={{ type: "float", precision: 2 }}
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.monday);
                                    }}
                                ></Column>
                                <Column
                                    dataField="tuesday"
                                    allowSorting={true}
                                    caption="TUE"
                                    format={{ type: "float", precision: 2 }}
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.tuesday);
                                    }}
                                ></Column>
                                <Column
                                    dataField="wednesday"
                                    allowSorting={true}
                                    caption="WED"
                                    format={{ type: "float", precision: 2 }}
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.wednesday);
                                    }}
                                ></Column>
                                <Column
                                    dataField="thursday"
                                    allowSorting={true}
                                    caption="THU"
                                    format={{ type: "float", precision: 2 }}
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.thursday);
                                    }}
                                ></Column>
                                <Column
                                    dataField="friday"
                                    allowSorting={true}
                                    caption="FRI"
                                    format={{ type: "float", precision: 2 }}
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.friday);
                                    }}
                                ></Column>
                                <Column
                                    dataField="saturday"
                                    allowSorting={true}
                                    caption="SAT"
                                    format={{ type: "float", precision: 2 }}
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.saturday);
                                    }}
                                ></Column>
                                <Column
                                    dataField="sunday"
                                    allowSorting={true}
                                    caption="SUN"
                                    format={{ type: "float", precision: 2 }}
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.sunday);
                                    }}
                                ></Column>

                                <Column
                                    dataField="total"
                                    allowSorting={true}
                                    caption="TOTAL"
                                    format={{ type: "float", precision: 2 }}
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.total);
                                    }}
                                ></Column>
                                <Column
                                    dataField="rate"
                                    allowSorting={true}
                                    caption="RATE"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToFloat(rowData.rate);
                                    }}
                                    calculateDisplayValue={(rowData: any) => {
                                        return this.gridUtils.convertDecimalAsStringToCurrency(
                                            rowData.rate,
                                            currencyTypeToSymbolMatrix[currencyTypes.GBP]
                                        );
                                    }}
                                ></Column>
                                <Column dataField="frequencyName" allowSorting={true} caption="FREQUENCY"></Column>
                                <Column
                                    dataField="lastUpdatedOn"
                                    allowSorting={true}
                                    caption="LAST UPDATED"
                                    calculateSortValue={(rowData: any) => {
                                        return this.gridUtils.convertddmmyyyyStringToDate(rowData.lastUpdatedOn);
                                    }}
                                ></Column>
                                <Column dataField="lastUpdatedBy" allowSorting={true} caption="LAST UPDATED BY"></Column>

                                <FilterRow visible={true} applyFilter="auto" />
                            </DataGrid>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.showDuplicateItem}
                    onHiding={this.hideInfoVenueGrid}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Possible Duplicate Contract"
                    width={"30%"}
                    height={"auto"}
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <p className="text-center">Please confirm to proceed with adding this contract as another one exists for the same venue</p>
                            <div className="row px-3">
                                <div className="col-12 col-xl-6 pl-0">
                                    <button
                                        className="btn btn-primary btn--large mt-3"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onProceed();
                                        }}
                                    >
                                        CONFIRM?
                                    </button>
                                </div>
                                <div className="col-12 col-xl-6 pl-0">
                                    <button
                                        className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.hideInfoVenueGrid();
                                        }}
                                    >
                                        CANCEL
                                    </button>
                                </div>
                            </div> 
                        </div>
                    </ScrollView>
                </Popup>
            </>
        );
    }
}

export default withTranslation()(ContractForm);
