import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { WithTranslation } from "react-i18next";
import { confirm } from "devextreme/ui/dialog";

interface ResidencyCellComponentProps extends WithTranslation {
    data: any;
}

interface ResidencyCellComponentState {}

class ResidencyCellComponent extends React.Component<ResidencyCellComponentProps> {
    state: ResidencyCellComponentState;
    constructor(props: ResidencyCellComponentProps) {
        super(props);
        this.setLoadIndicatorVisibility = this.setLoadIndicatorVisibility.bind(this);
        this.state = {};
    }

    //This is needed here to check for some conditions when the component mounts.
    componentDidMount() {}

    setLoadIndicatorVisibility(isVisible: boolean): void {
        this.setState({
            loadIndicatorVisible: isVisible,
        });
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <Link
                            title="Edit"
                            className="icon-btn"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    id: this.props.data.data.residenciesId,
                                    isEdit: true,
                                    gridName: "Residency",
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResidencyCellComponent;
