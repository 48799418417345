import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { ConfigurableTextType } from "../../services/OutstandingApprovalService";
import LookupService, { LookupTypeIndexes } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";

interface AddShiftsSectionProps extends WithTranslation {
    header: boolean;
}

interface AddShiftsSectionState {
    configurationTextArray: ConfigurableTextType[];
}

class AddShiftsSection extends Component<AddShiftsSectionProps> {
    state: AddShiftsSectionState;
    //Initialize the service
    lookupService: LookupService;

    constructor(props: AddShiftsSectionProps) {
        super(props);
        this.lookupService = new LookupService();
        this.state = {
            configurationTextArray: [],
        };
    }

    //On an initial page load this will get the configurable text from the API call.
    componentDidMount() {
        this.getConfigurationLookup();
    }

    getConfigurationLookup = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.configuration)
            .then(this.handleConfigurationLookupSuccess)
            .catch(this.handleFailure);
    };

    handleConfigurationLookupSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            configurationTextArray: response.data.data,
        });
    };

    handleFailure = (response: AxiosResponse<any>) => {
        this.setState({
            configurationTextArray: [],
        });
    };

    renderDate = () => {
        let dateString = new Date();
        let longMonth = dateString.toLocaleString("en-GB", { month: "long" });
        return dateString.getDate() + " " + longMonth + " " + dateString.getFullYear();
    };

    renderDay = () => {
        let dateString = new Date();
        return dateString.toLocaleString("en-GB", { weekday: "long" });
    };

    render() {
        var { header } = this.props;
        var addShiftsHeaderTitle: string = "";
        var addShiftsFooterTitle: string = "";
        var { configurationTextArray } = this.state;
        if (configurationTextArray && configurationTextArray.length > 0) {
            addShiftsHeaderTitle = configurationTextArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_CheckInOutSummary_Intro1;
            })[0].value;
            addShiftsFooterTitle = configurationTextArray.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Web_CheckInOutSummary_Intro2;
            })[0].value;

        }
        return (
            <header>
                <div className="col-md-12 col-xl-12 text-center text-lg-left mt-3 mt-md-0">
                    <span>{header ? addShiftsHeaderTitle : addShiftsFooterTitle} </span>
                </div>
                <div className="row py-3 px-3 justify-content-center justify-content-lg-start">
                    <div className="col-md-5 col-xl-3 grid-info__button-container">
                        <Link
                            className="btn--link btn btn-primary btn--large btn--with-icon"
                            to={{
                                pathname: "/checkinOut",
                                state: {
                                    id: 0,
                                },
                            }}
                        >
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </span>
                            Add Shift
                        </Link>
                    </div>
                </div>
            </header>
        );
    }
}

export default withTranslation()(AddShiftsSection);
