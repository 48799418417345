import React from "react";
import { AxiosResponse } from "axios";
import SupplierInformationService, {
    RateResponse,
    ExceptionalRatesResponse,
} from "../../services/SupplierInformationService";
import SupplierInformationUtils, { SupplierInformationData } from "../SupplierInformation/SupplierInformationUtils";
import { LoadIndicator } from "devextreme-react";
import ApprovedRatingComponent from "./ApprovedRatingComponent";
import { ServiceType} from "../../common/ServiceUtilities";

interface ClientInformationMainContentProps {
    clientId: string;
    venueId: string;
    isShowByVenue: boolean;
    serviceTypeId: string;
}
// State
interface ClientInformationMainContentState {
    supplierInformationDataSource: SupplierInformationData;
    rateDataSource: RateResponse[];
    exceptionalRateGridDataSource: ExceptionalRatesResponse[];
    showLoadIndicator: boolean;
}

class ClientInformationMainContent extends React.Component<ClientInformationMainContentProps> {
    state: ClientInformationMainContentState;
    service: SupplierInformationService;
    utils: SupplierInformationUtils;
    constructor(props: ClientInformationMainContentProps) {
        super(props);
        this.service = new SupplierInformationService();
        this.utils = new SupplierInformationUtils();
        var convertedSupplierInformationData = this.utils.initializeSupplierInformationItem();
        this.state = {
            supplierInformationDataSource: convertedSupplierInformationData,
            rateDataSource: [],
            exceptionalRateGridDataSource: [],
            showLoadIndicator: false,
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        this.setState({
            showLoadIndicator: true,
        });
        if (this.props.isShowByVenue) {
            this.getSupplierInfoDataSource();
        }
    }

    getSupplierInfoDataSource() {
        if (this.props.clientId && this.props.venueId) {
            this.setState({
                showLoadIndicator: true,
            });
            this.service
                .getSupplierInformation(parseInt(this.props.clientId), parseInt(this.props.venueId))
                .then(this.handleSuccessSupplierInformationData);
        } else {
            this.setState({
                supplierInformationDataSource: null,
                rateDataSource: null,
                exceptionalRateGridDataSource: null,
            });
        }
    }

    componentDidUpdate(prevProps: ClientInformationMainContentProps) {
        if (prevProps.clientId != this.props.clientId || prevProps.venueId != this.props.venueId) {
            this.getSupplierInfoDataSource();
        }
    }
    handleSuccessSupplierInformationData = (response: AxiosResponse<any>) => {
        this.setState(
            {
                supplierInformationDataSource: response.data.data,
                showLoadIndicator: false,
            },
            () =>
                this.dataPreparation(
                    this.state.supplierInformationDataSource.personnelRates,
                    this.state.supplierInformationDataSource.equipmentRates,
                    this.state.supplierInformationDataSource.exceptionalRates
                )
        );
    };

    dataPreparation = (
        personnelRates: RateResponse[],
        equipmentRates: RateResponse[],
        exceptionalRates: ExceptionalRatesResponse[]
    ) => {
        var enhancementArr: RateResponse[] = [];
        if (personnelRates && personnelRates.length > 0) {
            personnelRates.forEach((item: any, uniqueKey: number) => {
                enhancementArr.push(item);
            });
        }
        if (equipmentRates && equipmentRates.length > 0) {
            equipmentRates.forEach((item: any, uniqueKey: number) => {
                enhancementArr.push(item);
            });
        }
        this.setState({
            rateDataSource: enhancementArr,
        });

        if (exceptionalRates && exceptionalRates.length > 0) {
            exceptionalRates.forEach((item: any, uniqueKey: number) => {
                this.setState({
                    exceptionalRateGridDataSource: exceptionalRates,
                });
            });
        }
    };

    renderSupplierShortName() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.venueName
        ) {
            return this.state.supplierInformationDataSource.venue.venueName;
        }
    }

    renderSupplierHouseNumber() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.houseNumber
        ) {
            return `(` + this.state.supplierInformationDataSource.venue.houseNumber + `)`;
        }
    }

    renderClientShortName() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.businessEntity
        ) {
            return this.state.supplierInformationDataSource.venue.businessEntity.shortName;
        }
    }

    renderVenueAddress() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.address
        ) {
            return this.state.supplierInformationDataSource.venue.address;
        }
    }

    renderVenueCity() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.city
        ) {
            return `, ` + this.state.supplierInformationDataSource.venue.city;
        }
    }

    renderVenueCounty() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.county
        ) {
            return `, ` + this.state.supplierInformationDataSource.venue.county;
        }
    }

    renderVenuePostcode() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.postCode
        ) {
            return `, ` + this.state.supplierInformationDataSource.venue.postCode;
        }
    }

    renderVenueManager() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.generalManager &&
            this.state.supplierInformationDataSource.venue.generalManager.name
        ) {
            return this.state.supplierInformationDataSource.venue.generalManager.name;
        }
    }

    renderWebsiteAddress() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.website
        ) {
            return (
                <>
                    <a href={`${this.state.supplierInformationDataSource.venue.website}`}>
                        {this.state.supplierInformationDataSource.venue.website}
                    </a>
                </>
            );
        }
    }

    renderEmailAddress() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.email
        ) {
            return (
                <>
                    <a href={`mailto:${this.state.supplierInformationDataSource.venue.email}`}>
                        {this.state.supplierInformationDataSource.venue.email}
                    </a>
                </>
            );
        }
    }

    renderOfficePhone() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.phone
        ) {
            return (
                <>
                    <a href={`tel:${this.state.supplierInformationDataSource.venue.phone}`}>
                        {this.state.supplierInformationDataSource.venue.phone}
                    </a>
                </>
            );
        }
    }

    renderUniformPolicy() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.dsUniformPolicy
        ) {
            return this.state.supplierInformationDataSource.venue.dsUniformPolicy;
        }
    }
    renderLicenseCondition() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.dsLicenseCondition
        ) {
            return this.state.supplierInformationDataSource.venue.dsLicenseCondition;
        }
    }

    renderPrimarySecurityContactName() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.name
        ) {
            return (
                <>
                    <strong>Operational Contact: </strong>
                    {this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.name}
                </>
            );
        }
    }

    renderPrimarySecurityContactNumber() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.mobileTelephone
        ) {
            return (
                <>
                    <br />
                    <strong>Operational Contact Number: </strong>
                    <a
                        href={`tel:${this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.mobileTelephone}`}
                    >
                        {this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.mobileTelephone}
                    </a>
                </>
            );
        }
    }

    renderPrimarySecurityContactEmail() {
        if (
            this.state.supplierInformationDataSource &&
            this.state.supplierInformationDataSource.venue &&
            this.state.supplierInformationDataSource.venue.contact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact &&
            this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.email
        ) {
            return (
                <>
                    <br />
                    <strong>Operational Contact Email: </strong>
                    <a
                        href={`mailto:${this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.email}`}
                    >
                        {this.state.supplierInformationDataSource.venue.contact.primarySecurityContact.email}
                    </a>
                </>
            );
        }
    }

    render() {
        return this.state.showLoadIndicator && this.props.clientId && this.props.venueId ? (
            <div className="card">
                <div className="card-body">
                    <LoadIndicator
                        visible={this.state.showLoadIndicator}
                        id="button-indicator"
                        height={60}
                        width={60}
                        className="home-page__todayloader"
                    ></LoadIndicator>
                </div>
            </div>
        ) : (
            <>
                <div className="card">
                    <div className="card-body">
                        <>
                            <h3 className="page-title--medium">Client</h3>
                            <strong>Venue:</strong> {this.renderSupplierShortName()} {this.renderSupplierHouseNumber()}
                            <br />
                            <strong>Client:</strong> {this.renderClientShortName()}
                            <br />
                            <strong>Address:</strong> {this.renderVenueAddress()} {this.renderVenueCity()}
                            {this.renderVenueCounty()} {this.renderVenuePostcode()}
                            <br />
                            <strong>Venue Manager:</strong> {this.renderVenueManager()}
                            <br />
                            <strong>Website Address:</strong> {this.renderWebsiteAddress()}
                            <br />
                            <strong>Venue Phone Number:</strong> {this.renderOfficePhone()}
                            <br />
                            <strong>Venue Email Address:</strong> {this.renderEmailAddress()}
                             {this.props.serviceTypeId == ServiceType.Security ? <>
                                <hr className="horizontal_border_style"></hr>
                                <h3 className="page-title--medium">Door Supervision Operational Contact</h3>
                                {this.renderPrimarySecurityContactName()}
                                {this.renderPrimarySecurityContactNumber()}
                                {this.renderPrimarySecurityContactEmail()}
                                <hr className="horizontal_border_style"></hr>
                                <h3 className="page-title--medium">Licence Conditions (Specific to Door Supervision)</h3>
                                {this.renderLicenseCondition()}
                                <hr className="horizontal_border_style"></hr>
                                <h3 className="page-title--medium">Venue Uniform Policy</h3>
                                {this.renderUniformPolicy()}
                                <ApprovedRatingComponent
                                    clientId=""
                                    venueId=""
                                    dataSource={this.state.supplierInformationDataSource}
                                ></ApprovedRatingComponent> </> : ""}
                        </>
                    </div>
                </div>
            </>
        );
    }
}

export default ClientInformationMainContent;
