import React from "react";
import { RouteProps } from "react-router-dom";
import { withValidUserRoleProps } from "../Auth/ProtectedPage";
import ArtistGridPage from "./ArtistGridPage";

interface ArtistGridPageContainerProps extends RouteProps, withValidUserRoleProps {}

const ArtistGridPageContainer = (props: ArtistGridPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    return <ArtistGridPage title={getTitle()} category={getType()} />;
};

export default ArtistGridPageContainer;
