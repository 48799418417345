import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import UserService, { RoleGroupNames } from "../../services/UserService";
import ManageDocumentsGrid from "../Payments/Documents/ManageDocumentsGrid";
import { Button } from "devextreme-react";
import LightModePageTemplate from "./Templates/LightModeTemplate";

interface ManageDocumentsPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
}

interface ManageDocumentsPageComponentState {
    gridRefreshSignal: boolean;
}

class ManageDocumentsPage extends Component<ManageDocumentsPageComponentProps> {
    //Initialize the component's state.
    state: ManageDocumentsPageComponentState;

    constructor(props: ManageDocumentsPageComponentProps) {
        super(props);

        this.state = {
            gridRefreshSignal: false,
        };
    }
    //function that would indicate that a Grid refresh is required on click of the button.
    signalGridRefresh = () => {
        this.setState({ gridRefreshSignal: !this.state.gridRefreshSignal });
    };

    onCloseClick = () => {
        this.props.history.goBack();
    };

    render() {
        let isaddButtonDisabled: boolean = true;
        if (UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)) {
            //Enable or disable some UI components here based on RBAC.
        }
        return (
            <LightModePageTemplate>
                <section className="container page-content">
                    <header className="grid-info mb-3">
                        <h3 className="font-weight-bold ml-3">Documents</h3>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            <ManageDocumentsGrid
                                refreshSignal={this.state.gridRefreshSignal}
                                invoiceId={this.props.location.state.invoiceId}
                                paymentId={this.props.location.state.paymentId}
                            />
                        </div>
                        <div />
                    </div>
                    <div className="col-12 col-lg-2 ml-auto mt-3">
                        <Button
                            className={(false ? "disabled " : "") + " btn btn-primary btn--large"}
                            onClick={this.onCloseClick}
                        >
                            Close
                        </Button>
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(ManageDocumentsPage);
