import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import { QuickBillableItemSubmitRequest, QuickBillableItemResponse } from "../../services/AddEditPopUpService";
import { serviceTypes } from "../../services/FileService";
import sharedUtils from "../grid/sharedUtilities";
import moment from 'moment';

type NullableDate = Date | null | undefined;

export interface QuickShiftFormData {
    serviceTypeId: string;
    serviceSubTypeId: string;
    clientId: string;
    providerId: string;
    venueId: string;
    description: string;
    otherDescription: string;
    date: NullableDate;
    dateTo: NullableDate;
    provider_Pay_Amount_Local_Ccy: number;
    providerPayAmountLocalWOExtras:  number | undefined;
    comments: string;
    internalComments: string;
    equipmentQuantity: number;
    shift_Start: NullableDate,
    shift_End: NullableDate,
    name: string,
    identification_Number: string,
    typeId: string,
    additionalInformation: string,
    navigateFrom: string,
    validationError: [],
    billableItemId: string,
    equipmentScheduleQuantity: number,
    billableStatusLookupId: string,
    itemTypeId: string,
    recalculate: boolean,
    billable_Start: NullableDate,
    billable_Finish: NullableDate,
    fee_Percentage_Local_Ccy: string,
    scheduleRate: number,
    parentRecordId: string;
    cover: string;
    billable_Minutes: string;
    originalBillableMinutes: string;
    originalRate: string;
    billableRate: string;
    provider_Tax_Applicable: string,
    client_Tax_Applicable: string,
    fee_Tax_Applicable: string,
    pay_Date: string,
    bill_Date: string,
    provider_Pay_Tax_Local_Ccy: number,
    provider_Pay_Total_Local_Ccy: number,
    provider_Pay_Original_Amount_Local_Ccy: string,
    fee_Amount_Local_Ccy: number,
    fee_Tax_Local_Ccy: number | undefined,
    fee_Total_Local_Ccy: number | undefined,
    client_Billable_Amount_Local_Ccy: number,
    client_Billable_Tax_Local_Ccy: number | undefined,
    client_Billable_Total_Local_Ccy: number | undefined,
    client_Billable_Original_Amount_Local_Ccy: string,
    accept_Date: string,
    approval_Date: string,
    non_Standard: string,
    absent: string,
    unscheduled: string,
    originalBillableStartTime: string,
    originalBillableFinishTime: string,
    identificationTypeId: string,
    equipmentOriginalQuantity: string,
    equipmentBillableQuantity: string,
    isAmend: string,
    noCharge: string,
    scheduledMinutes: string,
    requestApprover: string,
    requestDate: NullableDate,
    performanceInformation: string,
    reoccurance: string,
    genre: string,
    equipmentRequired: string,
    performanceInformationSetsNumber: string,
    performanceInformationSetsDuration: string,
    providerNetOverride: string,
    multiplierOverride: string,
    feeAmountOverride: string,
    shiftCompleteAcknowledgment: boolean,
    shiftCompleteAcknowledgmentDateTime: string,
    shiftCompleteAcknowledgmentTime: NullableDate,
    isAccrual: boolean,
    providerFasterPayFee: number;
    providerPayAmountLocal: number | undefined;
    fasterPayment: boolean;
    fasterPaymentWithdrawn: boolean;
    accrual: {
        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null,
    hardApproval: string,
    holdPayment: string,
    lastUpdatedOn: string,
    lastUpdatedOnTZ:string,
    lastUpdatedBy:string,
    dateCreated:string,
    dateCreated_UI:string,
    dateCreatedTime:string,
    createdBy:string,
    paymentId: string,
    originator: {
        id: number,
        value: string
    } | null,
    rate_Decimal:number,
    updateOrCorrection: string,
    invoiceId: string,
}

//Product Backlog Item 16295: Quick Add Shift - Pop-Up - UI and Testing

export default class QuickAddShiftUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializeQuickShiftItems = this.initializeQuickShiftItems.bind(this);
        this.convertQuickShiftResponeToFormData = this.convertQuickShiftResponeToFormData.bind(this);
        this.convertFormDataToQuickShiftRequest = this.convertFormDataToQuickShiftRequest.bind(this);
    }

    initializeQuickShiftItems(idFromGrid: string): QuickShiftFormData {
        let formData: QuickShiftFormData = {
            serviceSubTypeId: "",
            serviceTypeId: "",
            clientId: "",
            venueId: "",
            providerId: "",
            description: "",
            otherDescription: "",
            date: undefined,
            dateTo: undefined,
            provider_Pay_Amount_Local_Ccy: NaN,
            providerPayAmountLocalWOExtras: undefined,
            comments: "",
            internalComments: "",
            equipmentQuantity: 0,
            providerFasterPayFee: 0,
            shift_Start: undefined,
            shift_End: undefined,
            name: "",
            identification_Number: "",
            providerPayAmountLocal: undefined,
            typeId: "",
            additionalInformation: "",
            navigateFrom: "",
            validationError: [],
            billableItemId: "",
            equipmentScheduleQuantity: 0,
            billableStatusLookupId: "",
            itemTypeId: "",
            recalculate: true,
            billable_Start: undefined,
            billable_Finish: undefined,
            fee_Percentage_Local_Ccy: "",
            scheduleRate: 0,
            parentRecordId: "",
            cover: "",
            billable_Minutes: "",
            originalBillableMinutes: "",
            originalRate: "",
            billableRate: "",
            provider_Tax_Applicable: "",
            client_Tax_Applicable: "",
            fee_Tax_Applicable: "",
            pay_Date: "",
            bill_Date: "",
            provider_Pay_Tax_Local_Ccy: NaN,
            provider_Pay_Total_Local_Ccy: NaN,
            provider_Pay_Original_Amount_Local_Ccy: "",
            fee_Amount_Local_Ccy: 0,
            fee_Tax_Local_Ccy: undefined,
            fee_Total_Local_Ccy: undefined,
            client_Billable_Amount_Local_Ccy: 0,
            client_Billable_Tax_Local_Ccy: undefined,
            client_Billable_Total_Local_Ccy: undefined,
            client_Billable_Original_Amount_Local_Ccy: "",
            accept_Date: "",
            approval_Date: "",
            non_Standard: "",
            absent: "",
            unscheduled: "",
            originalBillableStartTime: "",
            originalBillableFinishTime: "",
            identificationTypeId: "",
            equipmentOriginalQuantity: "",
            equipmentBillableQuantity: "",
            isAmend: "",
            noCharge: "",
            scheduledMinutes: "",
            requestApprover: "",
            requestDate: undefined,
            performanceInformation: "",
            reoccurance: "",
            genre: "",
            equipmentRequired: "",
            performanceInformationSetsNumber: "",
            performanceInformationSetsDuration: "",
            providerNetOverride: "",
            multiplierOverride: "",
            feeAmountOverride: "",
            shiftCompleteAcknowledgment: false,
            shiftCompleteAcknowledgmentDateTime: "",
            shiftCompleteAcknowledgmentTime: undefined,
            isAccrual: false,
            accrual: {
                id: 0,
                accrualTypeId: 0,
                accrualStatusId: 0,
                accrualBillableItemId: 0,
                accrualInvoiceId: 0,
                providerPayAmountAccrual: 0,
                providerPayAmountTaxAccrual: 0,
                providerPayAmountTotalAccrual: 0,
                grossProfitAmountAccrual: 0,
                grossProfitAmountTaxAccrual: 0,
                grossProfitAmountTotalAccrual: 0,
                clientBillableAmountAccrual: 0,
                clientBillableAmountTaxAccrual: 0,
                clientBillableAmountTotalAccrual: 0,
                lastUpdatedOn: "",
                lastUpdatedBy: 0
            },
            hardApproval: "",
            fasterPayment: false,
            fasterPaymentWithdrawn: false,
            holdPayment: "",
            lastUpdatedOn: "",
            lastUpdatedOnTZ:'',
            lastUpdatedBy:'',
            dateCreated:'',
            dateCreated_UI:'',
            dateCreatedTime:'',
            createdBy:'',
            paymentId: "",
            originator: {
                id: 0,
                value: ""
            },
            rate_Decimal: 0,
            updateOrCorrection: "false",
            invoiceId: "",
        };

        return formData;
    }

    // Convert the form's data into a proper billable item submit request
    convertFormDataToQuickShiftRequest(formData: QuickShiftFormData, recalculate: boolean): QuickBillableItemSubmitRequest {
        let feeTaxApplicable : string = "";
        let clientTaxApplicable : string = "";
        let providerTaxApplicable : string = "";
        let equipmentScheduledQuantity: string = "";
        let equipmentQuantity: string = "";
        // if (formData.serviceSubTypeId == SubServiceType.AdditionalCleaning || formData.serviceTypeId == serviceTypes.Entertainment || formData.serviceTypeId == ServiceType.WindowCleaning || formData.serviceTypeId == ServiceType.Gardening || formData.serviceTypeId == ServiceType.Maintenance || formData.serviceTypeId == ServiceType.Miscelleanous) {
        if (formData.serviceTypeId == serviceTypes.Entertainment) {    
            equipmentScheduledQuantity = "1";
            equipmentQuantity = "1";
        }
        else {
            if(formData.equipmentScheduleQuantity == 0 || formData.equipmentScheduleQuantity == null || formData.equipmentScheduleQuantity == undefined){
                equipmentScheduledQuantity = "1";
            }
            if(formData.equipmentQuantity == 0 || formData.equipmentQuantity == null || formData.equipmentQuantity == undefined){
                equipmentQuantity = "1";
            }
            else{
                equipmentScheduledQuantity = this.utils.convertNumberToString(formData.equipmentScheduleQuantity);
                equipmentQuantity = this.utils.convertNumberToString(formData.equipmentQuantity);
            }
        }
        if(formData.fee_Tax_Applicable && (formData.fee_Tax_Applicable == "Yes" || formData.fee_Tax_Applicable == "True")){
            feeTaxApplicable = "True";
        }
        else {
            feeTaxApplicable = "False";
        }
        if(formData.client_Tax_Applicable && (formData.client_Tax_Applicable == "Yes" || formData.client_Tax_Applicable == "True")){
            clientTaxApplicable = "True";
        }
        else {
            clientTaxApplicable = "False";
        }
        if(formData.provider_Tax_Applicable && (formData.provider_Tax_Applicable == "Yes" || formData.provider_Tax_Applicable == "True")){
            providerTaxApplicable = "True";
        }
        else {
            providerTaxApplicable = "False";
        }
        let submitRequest: QuickBillableItemSubmitRequest = {
            serviceSubTypeId: formData.serviceSubTypeId,
            serviceTypeId: formData.serviceTypeId,
            clientId: formData.clientId,
            venueId: formData.venueId,
            providerId: formData.providerId,
            description: formData.description,
            otherDescription: formData.otherDescription,
            date: this.utils.convertDateToString(formData.date),
            dateTo: this.utils.convertDateToString(formData.dateTo),
            provider_Pay_Amount_Local_Ccy: formData.provider_Pay_Amount_Local_Ccy ? `${this.utils.convertNumberToString(formData.provider_Pay_Amount_Local_Ccy)}` : "",
            providerPayAmountLocalWOExtras: formData.providerPayAmountLocalWOExtras ? `${this.utils.convertNumberToString(formData.providerPayAmountLocalWOExtras)}` : "",
            comments: formData.comments,
            internalComments: formData.internalComments,
            equipmentQuantity: equipmentQuantity,
            rate: this.utils.convertNumberToString(formData.rate_Decimal),
            providerPayAmountLocal: formData.providerPayAmountLocal ? this.utils.convertNumberToString(formData.providerPayAmountLocal) : "",
            shift_Start: formData.updateOrCorrection == "false" && formData.serviceTypeId !=="30" ? this.utils.convertTimeToString(formData.billable_Start) : this.utils.convertTimeToString(formData.shift_Start),
            shift_End: formData.updateOrCorrection == "false"  && formData.serviceTypeId !=="30" ? this.utils.convertTimeToString(formData.billable_Finish) : this.utils.convertTimeToString(formData.shift_End),
            fasterPayment: formData.fasterPayment,
            fasterPaymentWithdrawn: formData.fasterPaymentWithdrawn,
            name: formData.name,
            identification_Number: formData.identification_Number,
            typeId: formData.typeId,
            additionalInformation: formData.additionalInformation,
            navigateFrom: "webApp",
            validationError: [],
            billableItemId: formData.billableItemId,
            equipmentScheduleQuantity: formData.updateOrCorrection == "false" ? equipmentQuantity : equipmentScheduledQuantity,
            billableStatusLookupId: formData.billableStatusLookupId, // fix
            itemTypeId: formData.itemTypeId,
            recalculate: recalculate,
            billable_Start: this.utils.convertTimeToString(formData.billable_Start),
            billable_Finish: this.utils.convertTimeToString(formData.billable_Finish),
            fee_Percentage_Local_Ccy: formData.fee_Percentage_Local_Ccy.toString(),
            scheduleRate: formData.updateOrCorrection == "false" ? this.utils.convertNumberToString(formData.rate_Decimal) : this.utils.convertNumberToString(formData.scheduleRate),
            parentRecordId: formData.parentRecordId,
            cover: formData.cover,
            billable_Minutes: "",
            originalBillableMinutes: formData.originalBillableMinutes,
            originalRate: formData.originalRate,
            billableRate: this.utils.convertNumberToString(formData.rate_Decimal),
            provider_Tax_Applicable: providerTaxApplicable,
            client_Tax_Applicable: clientTaxApplicable,
            fee_Tax_Applicable: feeTaxApplicable,
            pay_Date: formData.pay_Date,
            bill_Date: formData.bill_Date,
            provider_Pay_Tax_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Tax_Local_Ccy),
            provider_Pay_Total_Local_Ccy: this.utils.convertNumberToString(formData.provider_Pay_Total_Local_Ccy),
            provider_Pay_Original_Amount_Local_Ccy: formData.provider_Pay_Original_Amount_Local_Ccy,
            fee_Amount_Local_Ccy: formData.fee_Amount_Local_Ccy ? `${this.utils.convertNumberToString(formData.fee_Amount_Local_Ccy)}` : "",
            fee_Tax_Local_Ccy: formData.fee_Tax_Local_Ccy ? `${this.utils.convertNumberToString(formData.fee_Tax_Local_Ccy)}` : "",
            fee_Total_Local_Ccy: formData.fee_Total_Local_Ccy ? `${this.utils.convertNumberToString(formData.fee_Total_Local_Ccy)}` : "",
            client_Billable_Amount_Local_Ccy: formData.client_Billable_Amount_Local_Ccy ? `${this.utils.convertNumberToString(formData.client_Billable_Amount_Local_Ccy)}` : "",
            client_Billable_Tax_Local_Ccy: formData.client_Billable_Tax_Local_Ccy ? `${this.utils.convertNumberToString(formData.client_Billable_Tax_Local_Ccy)}` : "",
            client_Billable_Total_Local_Ccy: formData.client_Billable_Total_Local_Ccy ? `${this.utils.convertNumberToString(formData.client_Billable_Total_Local_Ccy)}` : "",
            client_Billable_Original_Amount_Local_Ccy: formData.client_Billable_Original_Amount_Local_Ccy,
            accept_Date: formData.accept_Date,
            approval_Date: formData.approval_Date,
            non_Standard: formData.non_Standard,
            absent: formData.absent,
            unscheduled: formData.unscheduled,
            originalBillableStartTime: formData.originalBillableStartTime,
            originalBillableFinishTime: formData.originalBillableFinishTime,
            identificationTypeId: formData.identificationTypeId,
            equipmentOriginalQuantity: formData.equipmentOriginalQuantity,
            equipmentBillableQuantity: equipmentQuantity,
            isAmend: formData.isAmend,
            noCharge: formData.noCharge,
            scheduledMinutes: formData.scheduledMinutes,
            requestApprover: formData.requestApprover,
            requestDate: this.utils.convertDateToString(formData.requestDate),
            performanceInformation: formData.performanceInformation,
            reoccurance: formData.reoccurance,
            genre: formData.genre,
            equipmentRequired: formData.equipmentRequired,
            performanceInformationSetsNumber: formData.performanceInformationSetsNumber,
            performanceInformationSetsDuration: formData.performanceInformationSetsDuration,
            providerNetOverride: formData.providerNetOverride,
            multiplierOverride: formData.multiplierOverride,
            feeAmountOverride: formData.feeAmountOverride,
            shiftCompleteAcknowledgment: formData.shiftCompleteAcknowledgment,
            shiftCompleteAcknowledgmentDateTime: formData.shiftCompleteAcknowledgmentDateTime,
            shiftCompleteAcknowledgmentTime: formData.shiftCompleteAcknowledgment == false ? "" : this.utils.convertTimeToString(formData.shiftCompleteAcknowledgmentTime),
            isAccrual: formData.isAccrual.toString() == "true" ? true : false,
            accrual: ((formData.accrual && formData.billableItemId !== "0" && (formData.isAccrual.toString() == "Yes" || formData.isAccrual))) ? formData.accrual : null,
            hardApproval: formData.hardApproval,
            holdPayment: formData.holdPayment,
            lastUpdatedOn: formData.lastUpdatedOn,
            paymentId: formData.paymentId,
            originator: formData.originator ? {
                id: formData.originator.id,
                value: formData.originator.value
            } : null,
            updateOrCorrection: formData.updateOrCorrection == "true" ? true : false,
            invoiceId: formData.invoiceId,
            dateCreated:formData.billableItemId ? formData.dateCreated_UI : '',
            dateCreatedTime:formData.billableItemId ?formData.dateCreatedTime : ''
        };
        return submitRequest;
    }

    // Convert the server response into the component's form state
    convertQuickShiftResponeToFormData(serverResponse: QuickBillableItemResponse): QuickShiftFormData {
        let formData: QuickShiftFormData = {
            serviceSubTypeId: serverResponse.serviceSubTypeId,
            serviceTypeId: serverResponse.serviceTypeId,
            clientId: serverResponse.clientId,
            venueId: serverResponse.venueId,
            providerId: serverResponse.providerId,
            description: serverResponse.description ? serverResponse.description : "",
            otherDescription: serverResponse.otherDescription,
            date: this.utils.convertStringToDate(serverResponse.date),
            dateTo: this.utils.convertStringToDate(serverResponse.dateTo),
            provider_Pay_Amount_Local_Ccy: this.utils.convertStringToFloat(serverResponse.provider_Pay_Amount_Local_Ccy),
            providerPayAmountLocalWOExtras: serverResponse.providerPayAmountLocalWOExtras,
            comments: serverResponse.comments,
            internalComments: serverResponse.internalComments,
            equipmentQuantity: this.utils.convertStringToFloat(serverResponse.equipmentQuantity),
            //rate: this.utils.convertStringToFloat(serverResponse.rate),
            rate_Decimal:this.utils.convertStringToFloat(serverResponse.rate_Decimal),
            providerFasterPayFee: this.utils.convertStringToFloat(serverResponse.providerFasterPayFee),
            providerPayAmountLocal: serverResponse.providerPayAmountLocal,
            shift_Start: this.utils.convertStringToTime(serverResponse.shift_Start),
            shift_End: this.utils.convertStringToTime(serverResponse.shift_End),
            name: serverResponse.name,
            identification_Number: serverResponse.identification_Number,
            typeId: serverResponse.typeId,
            additionalInformation: serverResponse.additionalInformation,
            navigateFrom: serverResponse.navigateFrom,
            validationError: serverResponse.validationError,
            billableItemId: serverResponse.billableItemId,
            equipmentScheduleQuantity: this.utils.convertStringToFloat(serverResponse.equipmentScheduleQuantity),
            billableStatusLookupId: serverResponse.billableStatusLookupId,
            itemTypeId: serverResponse.itemTypeId,
            recalculate: true,
            fasterPayment: serverResponse.fasterPayment,
            fasterPaymentWithdrawn: serverResponse.fasterPaymentWithdrawn,
            billable_Start: this.utils.convertStringToTime(serverResponse.billable_Start),
            billable_Finish: this.utils.convertStringToTime(serverResponse.billable_Finish),
            fee_Percentage_Local_Ccy: serverResponse.fee_Percentage_Local_Ccy,
            scheduleRate: this.utils.convertStringToFloat(serverResponse.scheduleRate),
            parentRecordId: serverResponse.parentRecordId,
            cover: serverResponse.cover,
            billable_Minutes: serverResponse.billable_Minutes,
            originalBillableMinutes: serverResponse.originalBillableMinutes,
            originalRate: serverResponse.originalRate,
            billableRate: serverResponse.billableRate,
            provider_Tax_Applicable: serverResponse.provider_Tax_Applicable,
            client_Tax_Applicable: serverResponse.client_Tax_Applicable,
            fee_Tax_Applicable: serverResponse.fee_Tax_Applicable,
            pay_Date: serverResponse.pay_Date,
            bill_Date: serverResponse.bill_Date,
            provider_Pay_Tax_Local_Ccy: this.utils.convertStringToFloat(serverResponse.provider_Pay_Tax_Local_Ccy),
            provider_Pay_Total_Local_Ccy: this.utils.convertStringToFloat(serverResponse.provider_Pay_Total_Local_Ccy),
            provider_Pay_Original_Amount_Local_Ccy: serverResponse.provider_Pay_Original_Amount_Local_Ccy,
            fee_Amount_Local_Ccy: this.utils.convertStringToFloat(serverResponse.fee_Amount_Local_Ccy),
            fee_Tax_Local_Ccy: serverResponse.fee_Tax_Local_Ccy,
            fee_Total_Local_Ccy: serverResponse.fee_Total_Local_Ccy,
            client_Billable_Amount_Local_Ccy: this.utils.convertStringToFloat(serverResponse.client_Billable_Amount_Local_Ccy),
            client_Billable_Tax_Local_Ccy: serverResponse.client_Billable_Tax_Local_Ccy,
            client_Billable_Total_Local_Ccy: serverResponse.client_Billable_Total_Local_Ccy,
            client_Billable_Original_Amount_Local_Ccy: serverResponse.client_Billable_Original_Amount_Local_Ccy,
            accept_Date: serverResponse.accept_Date,
            approval_Date: serverResponse.approval_Date,
            non_Standard: serverResponse.non_Standard,
            absent: serverResponse.absent,
            unscheduled: serverResponse.unscheduled,
            originalBillableStartTime: serverResponse.originalBillableStartTime,
            originalBillableFinishTime: serverResponse.originalBillableFinishTime,
            identificationTypeId: serverResponse.identificationTypeId,
            equipmentOriginalQuantity: serverResponse.equipmentOriginalQuantity,
            equipmentBillableQuantity: serverResponse.equipmentBillableQuantity,
            isAmend: serverResponse.isAmend,
            noCharge: serverResponse.noCharge,
            scheduledMinutes: serverResponse.scheduledMinutes,
            requestApprover: serverResponse.requestApprover,
            requestDate: this.utils.convertStringToDate(serverResponse.requestDate),
            performanceInformation: serverResponse.performanceInformation,
            reoccurance: serverResponse.reoccurance,
            genre: serverResponse.genre,
            equipmentRequired: serverResponse.equipmentRequired,
            performanceInformationSetsNumber: serverResponse.performanceInformationSetsNumber,
            performanceInformationSetsDuration: serverResponse.performanceInformationSetsDuration,
            providerNetOverride: serverResponse.providerNetOverride,
            multiplierOverride: serverResponse.multiplierOverride,
            feeAmountOverride: serverResponse.feeAmountOverride,
            shiftCompleteAcknowledgment: serverResponse.shiftCompleteAcknowledgment,
            shiftCompleteAcknowledgmentDateTime: serverResponse.shiftCompleteAcknowledgmentDateTime,
            shiftCompleteAcknowledgmentTime: this.utils.convertStringToTime(serverResponse.shiftCompleteAcknowledgmentTime),
            isAccrual: serverResponse.isAccrual,
            accrual: serverResponse.accrual,
            hardApproval: serverResponse.hardApproval,
            holdPayment: serverResponse.holdPayment,
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            lastUpdatedOnTZ:serverResponse.lastUpdatedOnTZ,
            lastUpdatedBy:serverResponse.lastUpdatedBy,
            dateCreated:serverResponse.dateCreated,
            //dateCreated_UI:serverResponse.dateCreated,
            dateCreated_UI:serverResponse.dateCreated ? moment(serverResponse.dateCreated).format("DD/MM/YYYY") : '',
            dateCreatedTime:serverResponse.dateCreated ? moment(serverResponse.dateCreated).format("HH:MM") : '',
            createdBy:serverResponse.createdBy,
            paymentId: serverResponse.paymentId,
            originator: serverResponse.originator ? {
                id: serverResponse.originator.id,
                value: serverResponse.originator.value
            } : null,
            updateOrCorrection: serverResponse.updateOrCorrection ? "true" : "false",
            invoiceId: serverResponse.invoiceId,
        };

        return formData;
    }
}
