import React, { MouseEvent, Component } from "react";
import LargeLightCardWrapper from "./LargeLightCardWrapper";

interface ExampleLargeLightCardWithHeaderProps {}

class ExampleLargeLightCardWithHeader extends React.Component<ExampleLargeLightCardWithHeaderProps> {
    constructor(props: ExampleLargeLightCardWithHeaderProps) {
        super(props);
    }

    handleChangeComments = () => {};

    render() {
        return (
            <LargeLightCardWrapper
                hasHeader={true}
                type="security"
                typeTitle="Security - Door supervision Equipment"
                enumeration={false}
                leftSection={
                    <>
                        <div className="row">
                            <div className="col-12 col-xl-4">
                                <span className="large-light-card-layout__title">Popworld, Watling St</span>
                                Stonegate 156986
                            </div>
                            <div className="col-12 col-xl-8 large-light-card-layout__status">
                                <div className="divider-narrow--top pt-4 pt-xl-0">
                                    <span className="large-light-card-layout__title">Missing Keys - Resolved</span>
                                    <span className="large-light-card-layout__emph">Raised by:</span> Chris Smith
                                    (Spectrum Cleaning Ltd) - Fri 25 Sep 2020 12:08
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="pt-4 text-center text-xl-left">
                                    <div className="divider--top divider--bottom py-4 mb-4">
                                        We terminated our cleaning contract but they still have the keys
                                    </div>
                                    <div className="row mb-xl-3">
                                        <label className="col-4 col-xl-3 large-light-card-layout__label">
                                            Resolution
                                        </label>
                                        <div className="col-8 text-left">
                                            Spectrum contacted and have agreed to return the keys on Friday 9th October
                                            at 11am. Venue made aware and will have someone in the house to collect.
                                        </div>
                                    </div>
                                    <div className="row mb-xl-3">
                                        <label className="col-4 col-xl-3 large-light-card-layout__label">
                                            Assigned to
                                        </label>
                                        <div className="col-8 col-xl-4 text-left text-truncate" title="Amy">
                                            Amy
                                        </div>
                                        <label className="col-4 col-xl-2 large-light-card-layout__label">
                                            Last updated
                                        </label>
                                        <div
                                            className="col-8 col-xl-3 text-left text-truncate"
                                            title=" Mon 05 Oct 2020 11:51am"
                                        >
                                            Fri 25 Sep 2020 12:08
                                        </div>
                                    </div>
                                    {/* EVENT UK ONLY START */}
                                    <div className="row mb-xl-3">
                                        <label className="col-4 col-xl-3 large-light-card-layout__label">
                                            Link to Dynamics
                                        </label>
                                        <div
                                            className="col-8 col-xl-4 text-left text-truncate"
                                            title="www.dynamics.com/w345454325"
                                        >
                                            www.dynamics.com/w345454325
                                        </div>
                                        <label className="col-4 col-xl-2 large-light-card-layout__label">
                                            Billable ID
                                        </label>
                                        <div className="col-8 col-xl-3 text-left text-truncate" title="34534534">
                                            34534534
                                        </div>
                                    </div>
                                    <div className="row mb-xl-3">
                                        <label className="col-4 col-xl-3 large-light-card-layout__label">
                                            Internal comments
                                        </label>
                                        <div className="col-8 text-left">
                                            Joan raised query at venue - agreed to return keys
                                        </div>
                                    </div>
                                    {/* EVENT UK ONLY END */}
                                </div>
                            </div>
                        </div>
                    </>
                }
                rightSection={
                    <>
                        <div className="large-light-card-layout__controls">
                            <a className="btn btn-primary btn--full-width" href="/findAnArtistLandingPage">
                                View Related Item
                            </a>
                        </div>
                    </>
                }
            />
        );
    }
}
export default ExampleLargeLightCardWithHeader;
