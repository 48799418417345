import React, { useState } from "react";
import { RouteProps, withRouter } from "react-router-dom";
import withValidUserRole, { withValidUserRoleProps } from "../Auth/ProtectedPage";
import PendingRequestCardListPage from "../FindAnArtist/PendingBookingRequests/PendingRequestCardListPage";

interface PendingRequestPageContainerProps extends RouteProps, withValidUserRoleProps {}

const PendingRequestPageContainer = (props: PendingRequestPageContainerProps) => {
    const getTitle = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "Templates" : `${path.charAt(1).toUpperCase()}${path.slice(2)}`;
    };

    const getType = () => {
        const path = props.location ? props.location.pathname : "";
        return path === "/" ? "templates" : getTitle().toLowerCase();
    };

    const getSearchParam = () => {
        let queryRequestId: string = "";
        if (props.location && props.location.search) {
            let searchparam = new URLSearchParams(props.location.search.toLowerCase());
            let filterByIdFromURL = searchparam.get("filterby");
            queryRequestId = filterByIdFromURL ? filterByIdFromURL : "";
        }
        return queryRequestId;
    };

    return <PendingRequestCardListPage title={getTitle()} category={getType()} queryRequestId={getSearchParam()} />;
};

export default withValidUserRole(PendingRequestPageContainer);
