import React, { Component, Suspense } from "react";
import "./i18n";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HeaderContainer from "./components/header/HeaderContainer";
import PageContainer from "./components/page/PageContainer";
import UploadSchedulePageContainer from "./components/page/UploadSchedulePageContainer";
import ManageViewShiftPageContainer from "./components/page/ManageViewShiftPageContainer";
import DevelopmentSupportPageContainer from "./components/page/DevelopmentSupportPage";
import TodaysViewCheckInOutPageContainer from "./components/page/TodaysViewCheckInOutPageContainer";
import CheckInOutPageContainer from "./components/page/CheckInOutPageContainer";
import TelemetryProvider from "./components/AppInsights/TelemetryProvider";
import UserService, { errorMessages, RoleGroupNames } from "./services/UserService";
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "../assets/scss/main.scss";
import BillingCycleManagePageContainer from "./components/page/BillingCycleManagePageContainer";
import BillingReconciliationPageContainer from "./components/page/BillingReconciliationPageContainer";
import PaymentCycleManagePageContainer from "./components/page/PaymentCycleManagePageContainer";
import PaymentCycleFormPageContainer from "./components/page/PaymentCycleFormPageContainer";
import PaymentCycleItemManagePageContainer from "./components/page/PaymentCycleItemManagePageContainer";
import AddEditPaymentFormPageContainer from "./components/page/AddEditFormPageContainer";
import PaymentBACSPageContainer from "./components/page/PaymentBACSPageContainer";
import ManageDocumentsPageContainer from "./components/page/ManageDocumentsPageContainer";
import OutstandingApprovalsPageContainer from "./components/Outstanding-Actions/OutstandingApprovalsPageContainer";
import GeneralQueryPageContainer from "./components/General-Query/GeneralQueryPageContainer";
import QueryPageContainer from "./components/Query/QueryPageContainer";
import MatrixPageContainer from "./components/page/MatrixPageContainer";
import AdditionalPageContainer from "./components/page/AdditionalPageContainer";
import DesignComponents from "./design-system/DesignComponents";
import EntertainerLandingPageDesign from "./design-system/EntertainerLandingPageDesign";
import DetailedGigPageContainer from "./components/page/DetailedGigPageContainer";
import EntertainmentCompletionCardPageContainer from "./components/page/EntertainmentCompletionCardPageContainer";
import GigListPageContainer from "./components/page/GigListPageContainer";
import HighLevelGigCardListPageContainer from "./components/page/HighLevelGigCardListPageContainer";
import ArtistPaymentsPageContainer from "./components/Payments/EntertainmentPayments/ArtistPaymentsPageContainer";
import FindAnArtistLandingPageContainer from "./components/page/FindAnArtistLandingPageContainer";
import HighLevelDetailedGigPageContainer from "./components/page/HighLevelDetailedGigPageContainer";
import ArtistProfileDesign from "./design-system/ArtistProfileDesign";
import ArtistSignUpDesign from "./design-system/ArtistSignUpDesign";
import ArtistProfilePageContainer from "./components/Artist-Profile/ArtistProfilePageContainer";
import FileUploadPageContainer from "./components/SIA_License/FileUploadPageContainer";
import IndependentBookingPageContainer from "./components/IndependentBooking/IndependentBookingPageContainer";
import PendingRequestPageContainer from "./components/page/PendingRequestPageContainer";
import RaiseIncidentPageContainer from "./components/RaiseIncident/RaiseIncidentPageContainer";
import LandingPageDesign from "./design-system/LandingPageDesign";
import FinanceAdjustmentPageContainer from "./components/page/FinancialAdjustmentsPageContainer";
import CostReportPageContainer from "./components/CostReports/CostReportPageContainer";
import SimpleShiftsPageContainer from "./components/SimpleShiftsByService/SimpleShiftsPageContainer";
import FixedSidePanelDesign from "./design-system/FixedSidePanelDesign";
import CollapsibleSidePanelDesign from "./design-system/CollapsibleSidePanelDesign";
import FixedOnDesktopSidePanelDesign from "./design-system/FixedOnDesktopSidePanelDesign";
import FullBleedSidePanelDesign from "./design-system/FullBleedSidePanelDesign";
import LicenseChecksPageContainer from "./components/LicenseChecks/LicenseChecksPageContainer";
import IncidentsListPageContainer from "./components/IncidentsList/IncidentsListPageContainer";
import SupplierInformationPageContainer from "./components/SupplierInformation/SupplierInformationPageContainer";
import FormDesign from "./design-system/FormDesign";
import Terms from "./components/page/FooterFiles/Terms";
import Privacy from "./components/page/FooterFiles/Privacy";
import AcceptableUse from "./components/page/FooterFiles/AcceptableUse";
import FAQs from "./components/page/FooterFiles/FAQs";
import Footer from "./components/footer/Footer";
import ProviderPaymentsPageContainer from "./components/Payments/ProviderPayment/ProviderPaymentsPageContainer";
import CookiePolicyPage from "./components/page/FooterFiles/CookiePolicyPage";
import ClientInformationPageContainer from "./components/ClientInformation/ClientInformationPageContainer";
import ProviderProfilePageContainer from "./components/ProviderProfile/ProviderProfilePageContainer";
import BillingStatusFlowDesign from "./design-system/BillingStatusFlowDesign";
import BillingPageV2Container from "./components/BillingV2/BillingPageV2Container";
import BillingPagePreview from "./components/BillingV2/BillingPagePreview";
import PaymentStatusFlowDesign from "./design-system/PaymentStatusFlowDesign";
import VersionPage from "./components/page/VersionPage";
import ButtonsDesign from "./design-system/ButtonsDesign";
import DesignSystemLandingPage from "./design-system/DesignSystemLandingPage";
import PaymentsV2PageContainer from "./components/PaymentsV2/PaymentsV2PageContainer";
import ManageShiftsDesign from "./design-system/ManageShiftsDesign";
import ManageShiftsDesignAsPopUp from "./design-system/ManageShiftsDesignAsPopUp";
import AddEditFormV2 from "./components/grid/AddEditFormV2";
import VenueLookUpDesign from "./design-system/VenueLookUpDesign";
import VirtualScrollingDemo from "./design-system/VirtualScrollingDemo";
import ShiftWorkerLookupDemo from "./design-system/ShiftWorkerLookupDemo";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./components/Auth/AuthConfig";
import { msalInstance } from "./services/AuthService";
import GlobalService from "./services/GlobalService";
import AuthErrorMessage from "./components/Auth/AuthErrorMessage";
import { LoadIndicator } from "devextreme-react";
import PostApprovalPageContainer from "./components/PostApproval/PostApprovalPageContainer";
import PaymentRequestPageContainer from "./components/PaymentRequests/PaymentRequestPageContainer";
import PaymentRequestForm from "./components/PaymentRequests/PaymentRequestForm";
import GigRequestPageContainer from "./components/GigRequest/GigRequestPageContainer";
import QueryGridPageContainer from "./components/QueryGrid/QueryGridPageContainer";
import QueryManageForm from "./components/QueryGrid/QueryManageForm";
import SignUpProfileUpdatePageContainer from "./components/SignUpProfileUpdate/SignUpProfileUpdatePageContainer";
import MatchedPopUpCustomizedGrid from "./design-system/MatchedPopUpCustomizedGrid";
import MatchedPopUpPageContainer from "./components/PaymentRequests/MatchedPopUpPageContainer";
import ShiftMatrixGrid from "./components/ShiftMatrix/ShiftMatrixGrid";
import ShiftMatrixUnbilledGrid from "./components/ShiftMatrix/ShiftMatrixUnbilledGrid";
import FutureNegativeShiftGrid from "./components/ShiftMatrix/FutureNegativeShiftGrid";
import InvoiceByIdGrid from "./components/ShiftMatrix/InvoiceByIdGrid";
import QuickAddShiftForm from "./components/QuickAddShift/QuickAddShiftForm";
import ArtistGridPageContainer from "./components/FindAnArtist/ArtistGridPageContainer";
import InvalidUserPage from "./components/page/InvalidUserPage";
//import CacheBuster from '../src/CacheBuster';
const aiInstrumentationKey =
    process && process.env && process.env.APP_INSIGHTS_INSTRUMENTATION_KEY
        ? process.env.APP_INSIGHTS_INSTRUMENTATION_KEY
        : "";


interface ProtectedPageState {
    hasRoles: boolean;
    hasValidUser: boolean;
    hasError: boolean;
    errorMessages: errorMessages[];
    isNewBuild: boolean;
}

interface ProtectedPageProps {
}

function App() {
    return (
        <MsalProvider instance={msalInstance}>
            <ProtectedPages />
        </MsalProvider>
    );

    // return (
    //     <CacheBuster>
    //         {(loading: boolean, isLatestVersion: boolean, refreshCacheAndReload: () => void) => {
    //             if (loading) return null;
    //             if (!loading && !isLatestVersion) {
    //                 refreshCacheAndReload();
    //             }
    //             return (
    //                 <MsalProvider instance={msalInstance}>
    //                     <ProtectedPages />
    //                 </MsalProvider>
    //             );

    //         }
    //         }
    //     </CacheBuster>
    // );
      }

class ProtectedPages extends Component {
    state: ProtectedPageState;
    constructor(props: ProtectedPageProps) {
        super(props);
        this.state = {
            hasRoles: false,
            hasValidUser: true,
            hasError: false,
            errorMessages: [],
            isNewBuild: false,
        }
    }

    async componentDidMount() {
        let forbiddenError = localStorage.getItem('ForbiddenError');
        if(forbiddenError == "true"){
            this.setState({
                hasValidUser: false,
                hasError: false,
                hasRoles: false,
            });
            localStorage.setItem("ForbiddenError", "");
        }
        else{
            let errorResponse = await UserService.setUserRoles(); 
            if (errorResponse) {
                this.setState({
                    hasError: true,
                    errorMessages: errorResponse
                });
            }
            else {   
                if (UserService.userHasARole()) {
                    GlobalService.setServiceFeeMatrixDetails();
                    GlobalService.setConfigurationDetails();
                    this.setState({
                        hasRoles: true,
                        hasError: false
                    }); 
                }                     
                if(UserService.userHasNotKey()){
                    this.setState({
                        hasValidUser: false,
                        hasError: false,
                        hasRoles: false,
                    });
                }     
            }
        }
    }

    getBuild = (flag: boolean) => {
        this.setState({
            isNewBuild: flag
        });
    }

    render() {
        return (
            <TelemetryProvider instrumentationKey={aiInstrumentationKey} after={() => { }}>
                <Router>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={loginRequest}
                    >
                        <div className="main-wrapper">
                            <main role="main">
                                <Suspense fallback={null}>
                                    {this.state.hasRoles ? <HeaderContainer getBuild={this.getBuild} isNewBuild={this.state.isNewBuild}/> : <></>}
                                    <Switch>
                                        {this.state.hasError ?
                                            <AuthErrorMessage messages={this.state.errorMessages} />
                                            : !this.state.hasValidUser ? 
                                                <Route exact path="/" 
                                                    render={(routeProps) => (
                                                        <InvalidUserPage
                                                            {...routeProps}
                                                        />
                                                    )}
                                                /> 
                                            : this.state.hasRoles ?
                                                <>
                                                    <Route exact path="/" 
                                                        render={(routeProps) => (
                                                            <PageContainer
                                                                {...routeProps}
                                                                getBuild={this.getBuild}
                                                            />
                                                        )}
                                                    />
                                                    <Route exact path="/budgetView" component={PageContainer} />
                                                    <Route path="/manageviewshift" component={ManageViewShiftPageContainer} />
                                                    <Route path="/developmentSupport" component={DevelopmentSupportPageContainer} />
                                                    <Route path="/AddEditFormV2" component={AddEditFormV2} />
                                                    <Route path="/PaymentRequestForm" component={PaymentRequestForm} />
                                                    <Route path="/ShiftMatrixGrid" component={ShiftMatrixGrid} />
                                                    <Route path="/ShiftMatrixUnbilledGrid" component={ShiftMatrixUnbilledGrid} />
                                                    <Route path="/FutureNegativeShiftGrid" component={FutureNegativeShiftGrid}/>
                                                    <Route path ="/InvoiceByIdGrid" component={InvoiceByIdGrid}/>
                                                    <Route path="/QuickAddShiftForm" component={QuickAddShiftForm}/>
                                                    <Route path="/InvalidUserPage" component={InvalidUserPage}/>

                                                    <Route path="/QueryManageForm" component={QueryManageForm} />
                                                    <Route path="/BillingPagePreview" component={BillingPagePreview} />
                                                    
                                                    {/* Protected Pages */}
                                                    <Route
                                                        path="/FinanceAdjustmentPopUp"
                                                        render={(routeProps) => (
                                                            <FinanceAdjustmentPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route path="/MatchedPopUpPage" render={(routeProps) => (
                                                        <MatchedPopUpPageContainer
                                                            {...routeProps}
                                                            authorizedUserRoles={[
                                                                RoleGroupNames.EventUKSeniorManager,
                                                                RoleGroupNames.EventUKRelationshipManager,
                                                            ]}
                                                        />
                                                    )} />
                                                    <Route
                                                        path="/CostReport"
                                                        render={(routeProps) => (
                                                            <CostReportPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/DoorSupervision/Incidents"
                                                        render={(routeProps) => (
                                                            <IncidentsListPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/simpleShifts"
                                                        render={(routeProps) => (
                                                            <SimpleShiftsPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                                getBuild={this.getBuild}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/pendingRequest"
                                                        render={(routeProps) => (
                                                            <PendingRequestPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/SIALicenseUpload"
                                                        render={(routeProps) => (
                                                            <FileUploadPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/checkinOut"
                                                        render={(routeProps) => (
                                                            <CheckInOutPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderSiteManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.HeadDoorSupervisor,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/AddEditPaymentForm"
                                                        render={(routeProps) => (
                                                            <AddEditPaymentFormPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/documents"
                                                        render={(routeProps) => (
                                                            <ManageDocumentsPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/outstandingActions"
                                                        render={(routeProps) => (
                                                            <OutstandingApprovalsPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderSiteManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                    RoleGroupNames.Artist,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/postApprovalActions"
                                                        render={(routeProps) => (
                                                            <PostApprovalPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderSiteManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                    RoleGroupNames.Artist,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/raiseQuery"
                                                        render={(routeProps) => (
                                                            <GeneralQueryPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderSiteManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.HeadDoorSupervisor,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                    RoleGroupNames.Artist,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/upcomingGig"
                                                        render={(routeProps) => (
                                                            <DetailedGigPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.Artist,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/EntertainmentCompletionCard"
                                                        render={(routeProps) => (
                                                            <EntertainmentCompletionCardPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.Artist,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/myGigList"
                                                        render={(routeProps) => (
                                                            <GigListPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.Artist,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/gigRequest"
                                                        render={(routeProps) => (
                                                            <GigRequestPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                    RoleGroupNames.VenueManager
                                                                ]}
                                                                getBuild={this.getBuild}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/artistGrid"
                                                        render={(routeProps) => (
                                                            <ArtistGridPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                    RoleGroupNames.VenueManager
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/gigCardList"
                                                        render={(routeProps) => (
                                                            <HighLevelGigCardListPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.Artist,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/ArtistPayments"
                                                        render={(routeProps) => (
                                                            <ArtistPaymentsPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.Artist,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/ProviderPayments"
                                                        render={(routeProps) => (
                                                            <ProviderPaymentsPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/highLevelDetailGig"
                                                        render={(routeProps) => (
                                                            <HighLevelDetailedGigPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.Artist,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/artistProfile"
                                                        render={(routeProps) => (
                                                            <ArtistProfilePageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.Artist,
                                                                    RoleGroupNames.VenueManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/providerProfile"
                                                        render={(routeProps) => (
                                                            <ProviderProfilePageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/manageBillingCycle"
                                                        render={(routeProps) => (
                                                            <BillingCycleManagePageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/BillingReconciliationPage"
                                                        render={(routeProps) => (
                                                            <BillingReconciliationPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/BillingPageV2"
                                                        render={(routeProps) => (
                                                            <BillingPageV2Container
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/managePaymentCycle"
                                                        render={(routeProps) => (
                                                            <PaymentCycleManagePageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/PaymentCycleForm"
                                                        render={(routeProps) => (
                                                            <PaymentCycleFormPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/managePaymentCycleItem"
                                                        render={(routeProps) => (
                                                            <PaymentCycleItemManagePageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/PaymentsV2Page"
                                                        render={(routeProps) => (
                                                            <PaymentsV2PageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/paymentCycleBacs"
                                                        render={(routeProps) => (
                                                            <PaymentBACSPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/matrixView"
                                                        render={(routeProps) => (
                                                            <MatrixPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/todaysViewCheckinOut"
                                                        render={(routeProps) => (
                                                            <TodaysViewCheckInOutPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.HeadDoorSupervisor,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/viewQuery"
                                                        render={(routeProps) => (
                                                            <QueryPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.HeadDoorSupervisor,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/queryGrid"
                                                        render={(routeProps) => (
                                                            <QueryGridPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                    RoleGroupNames.VenueManager
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/signUpProfileUpdate"
                                                        render={(routeProps) => (
                                                            <SignUpProfileUpdatePageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/additionalCleaning"
                                                        render={(routeProps) => (
                                                            <AdditionalPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/paymentRequests"
                                                        render={(routeProps) => (
                                                            <PaymentRequestPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                                getBuild={this.getBuild}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/uploadSchedule"
                                                        render={(routeProps) => (
                                                            <UploadSchedulePageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/findAnArtistLandingPage"
                                                        render={(routeProps) => (
                                                            <FindAnArtistLandingPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/independentBookingPage"
                                                        render={(routeProps) => (
                                                            <IndependentBookingPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/incident"
                                                        render={(routeProps) => (
                                                            <RaiseIncidentPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/LicenseChecksPage"
                                                        render={(routeProps) => (
                                                            <LicenseChecksPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/SupplierInformationPage"
                                                        render={(routeProps) => (
                                                            <SupplierInformationPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.VenueManager,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/ClientInformationPage"
                                                        render={(routeProps) => (
                                                            <ClientInformationPageContainer
                                                                {...routeProps}
                                                                authorizedUserRoles={[
                                                                    RoleGroupNames.EventUKSeniorManager,
                                                                    RoleGroupNames.EventUKRelationshipManager,
                                                                    RoleGroupNames.ProviderScheduler,
                                                                ]}
                                                            />
                                                        )}
                                                    />
                                                    <FooterPages />
                                                    <DesignSystemPages />
                                                </>
                                                : <>
                                                    <LoadIndicator
                                                        height={60}
                                                        width={60}
                                                        className="load-indicator--centered "
                                                    />
                                                </>}
                                    </Switch>
                                </Suspense>
                            </main>
                            {this.state.hasRoles ? <Footer /> : <></>}
                        </div>
                    </MsalAuthenticationTemplate>
                </Router>
            </TelemetryProvider>
        );
    }
}

function FooterPages() {
    return (
        <>
            {/* Footer Pages -- Need not be protected. */}
            <Route path="/cookiePolicy" component={CookiePolicyPage} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/acceptableUse" component={AcceptableUse} />
            <Route path="/faqs" component={FAQs} />
            <Route path="/version" component={VersionPage} />
        </>
    )
}

function DesignSystemPages() {
    return (
        <>
            <Route exact path="/design-system/" component={DesignSystemLandingPage} />
            <Route path="/design-system/components" component={DesignComponents} />
            <Route path="/design-system/buttons" component={ButtonsDesign} />
            <Route path="/design-system/landingPage" component={LandingPageDesign} />
            <Route path="/design-system/artistProfile" component={ArtistProfileDesign} />
            <Route path="/design-system/artistSignup" component={ArtistSignUpDesign} />
            <Route
                path="/design-system/entertainerLandingPage"
                component={EntertainerLandingPageDesign}
            />
            <Route
                path="/design-system/fixedSidePanelDesign"
                component={FixedSidePanelDesign}
            />
            <Route
                path="/design-system/collapsibleSidePanelDesign"
                component={CollapsibleSidePanelDesign}
            />
            <Route
                path="/design-system/fixedOnDesktopSidePanelDesign"
                component={FixedOnDesktopSidePanelDesign}
            />
            <Route
                path="/design-system/fullBleedSidePanelDesign"
                component={FullBleedSidePanelDesign}
            />
            <Route path="/design-system/formDesign" component={FormDesign} />
            <Route
                path="/design-system/BillingStatusFlow"
                component={BillingStatusFlowDesign}
            />
            <Route
                path="/design-system/PaymentStatusFlow"
                component={PaymentStatusFlowDesign}
            />
            <Route
                path="/design-system/ManageShiftsDesign"
                component={ManageShiftsDesign}
            />
            <Route
                path="/design-system/ManageShiftsDesignAsPopUp"
                component={ManageShiftsDesignAsPopUp}
            />
            <Route path="/design-system/VenueLookUpDesign" component={VenueLookUpDesign} />
            <Route
                path="/design-system/VirtualScrollingDemo"
                component={VirtualScrollingDemo}
            />
            <Route
                path="/design-system/ShiftWorkerLookupDemo"
                component={ShiftWorkerLookupDemo}
            />
            <Route
                path="/design-system/MatchedPopUpCustomizedGrid"
                component={MatchedPopUpCustomizedGrid}
            />
        </>
    );
}

export default App;
