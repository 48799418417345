import React from "react";
import { Popup } from "devextreme-react";
import UserService, { RoleGroupNames } from "../../../services/UserService";
import { ServiceSubTypeId } from "../../../services/ArtistService";
import { SubServiceType } from "../../../common/ServiceUtilities";

interface CardHeaderSectionProps {
    title?: string;
    venue: string;
    postcode?: string;
    date: string;
    message: string;
    adjustment?: boolean;
    errorMessage?: string;
    clearErrorMessage?: any; //to allow the closing of the error popup
    calculationErrorMessage?: string[]; //Only for Security personnel cards.
    licenseNumber?: string; // for security personnel cards
    provider: string;
    serviceSubTypeId: string;
    client : string;
}

class CardHeaderSection extends React.Component<CardHeaderSectionProps> {
    constructor(props: CardHeaderSectionProps) {
        super(props);
    }

    render() {
        var valueToShow : string = "";
        var relationShipMng = UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        var seniorManager = UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        var venueMng = UserService.isUserInGroup(RoleGroupNames.VenueManager);
        var provider = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler);
        if((relationShipMng || seniorManager || venueMng) && this.props.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel){
            valueToShow = this.props.provider;
        }
        else if((provider) && this.props.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel){
            valueToShow = this.props.client;
        }
        return (
            <>
                <div className="text-center">
                    {this.props.calculationErrorMessage ? (
                        <span className="text-danger font-weight-bold">
                            <ul>
                                {this.props.calculationErrorMessage.map((item: any, uniqueKey: number) => {
                                    return (
                                        <li key={uniqueKey}>
                                            {item.columnName}: {item.errorMessage}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    ) : null}
                    <header>
                        {this.props.adjustment ? null : <div className="euk-card__adjustment-label">Adjustment</div>}
                        <div className="euk-card__title">{this.props.title ? this.props.title : ""}</div>
                        {this.props.licenseNumber ? <div className="mt-3">{this.props.licenseNumber}</div> : null}
                        <div className="euk-card__header-venue">{valueToShow ? valueToShow : ""}</div>
                        <div className="euk-card__header-venue">{this.props.venue ? this.props.venue : ""}</div>
                        <div className="euk-card__header-date">{this.props.date}</div>
                        <div className="euk-card__message">{this.props.message ? this.props.message : ""}</div>
                    </header>
                </div>
                <Popup
                    width="550px"
                    height="auto"
                    visible={this.props.errorMessage !== undefined && this.props.errorMessage !== ""}
                    onHiding={this.props.clearErrorMessage}
                    title="Error"
                >
                    <span className="my-2 d-block text-center">{this.props.errorMessage}</span>
                </Popup>
            </>
        );
    }
}
export default CardHeaderSection;
