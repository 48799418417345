import React, { Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import EventUkUserGrid from "../grid/EventUkUserGrid";
import { AxiosResponse, AxiosError } from "axios";
import { LoadPanel } from "devextreme-react";
import PaymentCycleItemGrid from "../Payments/PaymentCycleItemGrid";
import { GridTypes, PeriodGridTypeParams } from "../../services/SimpleGridService";
import PaymentService, { PaymentCycleGridRowItem, paymentStatus } from "../../services/PaymentService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import LightModePageTemplate from "./Templates/LightModeTemplate";

interface PaymentCycleItemManagePageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
}

interface PaymentCycleItemManagePageState {
    gridRefreshSignal: boolean;
    Load: boolean;
    gridData: [];
    loadPanelVisible: boolean;
    calculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    saveClick: boolean;
    paymentsRefresh: boolean;
    paymentCycleRefresh: boolean;
    paymentStatusId: string;
}

class PaymentCycleItemManagePage extends Component<PaymentCycleItemManagePageComponentProps> {
    //Initialize the component's state.
    state: PaymentCycleItemManagePageState;
    service: PaymentService;
    periodGridTypeParams: PeriodGridTypeParams;
    userHasAccess: boolean;
    constructor(props: PaymentCycleItemManagePageComponentProps) {
        super(props);
        // PeriodGridTypeParams is not used in the context of payments
        this.periodGridTypeParams = {
            serviceTypeId: "",
            periodWeek: "",
            periodNumber: "",
        };
        this.service = new PaymentService();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        this.state = {
            gridRefreshSignal: false,
            Load: false,
            gridData: [],
            loadPanelVisible: false,
            calculationsObject: {
                subTotal: "0.00",
                total: "0.00",
                tax: "0.00",
            },
            saveClick: false,
            paymentsRefresh: false,
            paymentCycleRefresh: false,
            paymentStatusId: "",
        };
    }

    //function that would indicate that a Grid refresh is required on click of the button.
    signalGridRefresh = () => {
        this.setState({ gridRefreshSignal: !this.state.gridRefreshSignal });
    };

    PaymentCalculations = (calculations: any) => {
        if (calculations.total || calculations.subTotal || calculations.tax) {
            this.setState({
                calculationsObject: {
                    ...this.state.calculationsObject,
                    total: calculations.total,
                    subTotal: calculations.subTotal,
                    tax: calculations.tax,
                },
            });
        }
        this.setState({
            Load: true,
        });
    };

    onSaveClick = () => {
        this.setState({
            saveClick: !this.state.saveClick,
        });
    };

    setCalculations = () => {};

    setBillableItemList = () => {};

    setButtonStates(isButtonStateChangedSignal: boolean) {}
    setIndicatorStates = () => {};
    setLoadIndicator(isVisible: boolean): void {
        this.setState({ loadPanelVisible: isVisible });
    }

    updatePayments = () => {
        this.setLoadIndicator(true);
        if (this.userHasAccess) {
            this.service
                .generatePayments(this.props.location.state.id)
                .then(this.handlePostSuccess)
                .catch(this.handlePostFailure);
        }
    };

    handlePostSuccess = (response: AxiosResponse<any>) => {
        this.setLoadIndicator(false);
        this.setState({
            paymentsRefresh: !this.state.paymentsRefresh,
            paymentCycleRefresh: !this.state.paymentCycleRefresh,
            gridRefreshSignal: !this.state.gridRefreshSignal,
        });
    };

    handlePostFailure = (error: AxiosError<any>) => {
        // Redirect back to home page on a 401 unauth error
        if (error.response != null && error.response.status === 401) {
            this.props.history.push({
                pathname: "/",
            });
        } else {
            this.setLoadIndicator(false);
        }
    };

    setGridData(updatedGridData: PaymentCycleGridRowItem) {
        this.setState({
            gridData: [updatedGridData],
        });
    }
    setSummary = (SummaryObject: any) => {};

    disableUpdatePaymentsButton = (statusID: string) => {
        //Callback that would be triggered once the Payment cycle status is set to some specific values.
        this.setState({
            paymentStatusId: statusID,
        });
    };

    render() {
        var notificationMessage: string =
            "Please Note: The user will not be able to save the cycle if the Payment cycle status is Payment Advise Requested/Payment Pack Requested/Payment Remittance Requested";
        const { paymentStatusId } = this.state;

        const disablePaymentButton =
            paymentStatusId == paymentStatus.Pending ||
            paymentStatusId == paymentStatus.ReadyForPayment ||
            paymentStatusId == paymentStatus.PaymentNetted
                ? false
                : true;
        const disableSaveCycleButton =
            paymentStatusId == paymentStatus.PaymentAdviseRequested ||
            paymentStatusId == paymentStatus.PaymentPackRequested ||
            paymentStatusId == paymentStatus.PaymentRemittanceRequested
                ? true
                : false;
        var notificationText: string =
            paymentStatusId == paymentStatus.PaymentAdviseRequested ||
            paymentStatusId == paymentStatus.PaymentPackRequested ||
            paymentStatusId == paymentStatus.PaymentRemittanceRequested
                ? notificationMessage
                : "";
        var saveCycleText: string;
        var saveCycleColor: string;
        var updatePaymentsColor: string;
        if(paymentStatusId == paymentStatus.Pending || paymentStatusId == paymentStatus.ReadyForPayment
            || paymentStatusId == paymentStatus.PaymentNetted){
                saveCycleText = "LOCK PAYMENTS";
                saveCycleColor = "btn saveCycleButtonColor btn--large";
                updatePaymentsColor = "btn saveCycleButtonColor btn--large";
            }
        else if(paymentStatusId == paymentStatus.PaymentPaid || paymentStatusId == paymentStatus.PaymentSettled){
                saveCycleText = "SAVE STEP";
                saveCycleColor = "btn saveCycleButtonColor btn--large";
                updatePaymentsColor = "btn btn-primary btn--large";
            }
        else{
            saveCycleText = "SAVE CYCLE";
            saveCycleColor = "btn btn-primary btn--large"
            updatePaymentsColor = "btn btn-primary btn--large";
        }
        return (
            <LightModePageTemplate>
                {notificationText ? (
                    <span className="text-danger font-weight-bold ml-3">{notificationText}</span>
                ) : null}
                <section className="container page-content">
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div>
                        {this.state.Load == true ? (
                            <PaymentCycleItemGrid
                                location={this.props.location}
                                history={this.props.history}
                                id={this.props.location.state.id}
                                calculationsObject={this.state.calculationsObject}
                                saveClick={this.state.saveClick}
                                refreshSignal={this.state.paymentCycleRefresh}
                                onSaveSuccess={this.signalGridRefresh}
                                disableUpdateButtonCallback={this.disableUpdatePaymentsButton}
                            />
                        ) : null}
                    </div>
                    <header className="grid-info mt-3 mb-2">
                    {!!this.state.paymentStatusId && (
                        <div className="grid-info__row mt-1 mb-1">
                            <div className="grid-info__button-container mr-auto">
                                <button
                                    className= {updatePaymentsColor}
                                    onClick={this.updatePayments}
                                    disabled={disablePaymentButton}
                                >
                                    Update Payments
                                </button>
                            </div>
                            <div className="col-12 col-lg-2 mb-3">
                                <Link
                                    className={"btn btn-primary btn--large"}
                                    to={{
                                        pathname: "/PaymentCycleForm",
                                        state: {
                                            id: this.props.location.state.id,
                                        },
                                    }}
                                >
                                    Manage Cycle
                                </Link>
                            </div>
                            <div className="col-12 col-lg-2 mb-3">
                                <button
                                    className={(true ? "" : "disabled") + saveCycleColor}
                                    onClick={this.onSaveClick}
                                    disabled={disableSaveCycleButton}
                                >
                                    {saveCycleText}
                                </button>
                            </div>
                            <div className="col-12 col-lg-2 mb-3">
                                <Link
                                    className={(false ? "disabled " : "") + " btn btn-primary btn--large"}
                                    to="/managePaymentCycle"
                                >
                                    Close
                                </Link>
                            </div>
                        </div>
                    )}
                        <h3 className="font-weight-bold ml-3">Generated Payments</h3>
                        <div className="row">
                            <div className="col-md">
                            </div>
                        </div>
                        <h3 className="font-weight-bold ml-3 mt-3">Individual Payment Included Items...</h3>
                    </header>
                    <div className="row">
                        <div className="col-md">
                            {/* <BillingCycleGrid refreshSignal = {this.state.gridRefreshSignal}/> */}
                            <EventUkUserGrid
                                setSummary={this.setSummary}
                                setBillableItemObj={()=>{}}
                                setCalculations={this.setCalculations}
                                setParentButtonStatesSignal={this.setButtonStates}
                                refreshSignal={this.state.gridRefreshSignal}
                                gridType={GridTypes.payments}
                                id={this.props.location.state.id}
                                setBillableItemList={this.setBillableItemList}
                                filterText={""}
                                billableItemId={""}
                                clientId={""}
                                venueId={""}
                                shiftStart={""}
                                shiftEnd={""}
                                isSchedulePage={false}
                                periodGridTypeParams={this.periodGridTypeParams}
                                setIndicatorStates={this.setIndicatorStates}
                                dateFrom=""
                                dateTo=""
                                setMatchButtonState={()=>{}}
                                setRemoveButtonState={()=>{}}
                                setBillableItemCount={()=>{return null}}
                            />
                        </div>
                    </div>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(PaymentCycleItemManagePage);
