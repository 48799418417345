
import { _aiTraceAsError, _get } from "./BaseApiService";

const BULLETIN_PANEL_API = "Bulletin";
const staticText = "priority:asc,date:desc"

export interface bulletinPanelData {
    id: string,
    bulletinCategoryId: string,
    bulletinCategoryValue: string,
    bulletinPriorityId: string,
    bulletinPriorityValue: string,
    bulletinTitle: string,
    bulletinDescription: string,
    bulletinDate: string,
    validFrom: string,
    validTo: string,
    isActive: boolean,
    lastUpdatedOn: string,
    lastUpdatedBy: string,
    validationErrors: string
}

export const bulletinCategoryValue = {
    news  : "News",
    attention : "Attention",
    action : "Action",
    legal : "Legal",
    advert : "Advert",
    gigs : "Gigs",
    quote : "Quote",
    request : "Request",
    social : "Social"
}

/// REST service for the bulletin resource.
class BulletinPanelService {
    getBulletinPanelData(todaysDate: string) {
        return _get(`${BULLETIN_PANEL_API}?date=${todaysDate}&sort=${staticText}`);
    }
    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default BulletinPanelService;
