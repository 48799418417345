import React from "react";
import DataGrid, { Pager, Paging, Export, FilterRow, Column, Scrolling, SearchPanel } from "devextreme-react/data-grid";
import { DataGridOnInitializedEvent } from "../../types/DevExtremeTypes";
import BillableItemHeaderService, { VenueHierarchyChildGridRowItem } from "../../services/BillableItemHeaderService";
import gridUtils from "../grid/GridUtilities";
import { AxiosResponse } from "axios";
import StatusColour from "../grid/StatusColour";
import ErrorMessage from "../grid/ErrorMessage";
import { onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";

// Props
//  - refreshSignal will indicate that a grid refresh is required.
//  - gridVisibilitySignal is going to signal when the grid will be shown.
interface VenueHierarchyUploadFileContentsGridProps {
    isChildGridVisible: boolean;
    headerId: string;
}

// State
interface VenueHierarchyUploadFileContentsGridState {
    venueHierarchyChildGridDataSource: VenueHierarchyChildGridRowItem[];
}

// Component - displays both the header and child grids
class VenueHierarchyUploadFileContentsGrid extends React.Component<VenueHierarchyUploadFileContentsGridProps> {
    state: VenueHierarchyUploadFileContentsGridState;
    gridUtils: gridUtils;
    dataGridElement: any;
    billableItemUploadService: BillableItemHeaderService;

    constructor(props: VenueHierarchyUploadFileContentsGridProps) {
        super(props);
        this.state = {
            venueHierarchyChildGridDataSource: [],
        };

        this.gridUtils = new gridUtils();
        this.billableItemUploadService = new BillableItemHeaderService();
        this.dataGridElement = undefined;
        this.handleFileUploadOnInitialize = this.handleFileUploadOnInitialize.bind(this);
        this.updateChildGridDataSource = this.updateChildGridDataSource.bind(this);
    }

    // Initialize state from server
    componentDidMount() {
        this.updateChildGridDataSource(this.props.headerId);
    }

    updateChildGridDataSource = (headerId: string) => {
        if (headerId) {
            this.billableItemUploadService
                .getChildGridDataForVenueHierarchy(headerId)
                .then(this.handleFileUploadSuccess)
                .catch(this.handleFailure);
        }
    };

    handleFileUploadSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            venueHierarchyChildGridDataSource: response.data.data,
        });
    };

    handleFailure = (error: any) => {
        var respMessage: string = "getChildGridDataForVenueHierarchy failed with response: " + JSON.stringify(error);

        if (!this.billableItemUploadService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    componentDidUpdate(prevProps: VenueHierarchyUploadFileContentsGridProps) {
        if (this.props.headerId !== prevProps.headerId) {
            // refresh the grid
            this.updateChildGridDataSource(this.props.headerId);
        }
    }

    // Set the file uploader component
    handleFileUploadOnInitialize(e: DataGridOnInitializedEvent) {
        this.dataGridElement = e.component;
    }
    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }

    render() {
        return (
            <div>
                <div className="childGrid mt-5">
                    <h4 className="font-weight-bold mb-3" hidden={!this.props.isChildGridVisible}>
                        View Selected File Contents
                    </h4>
                    {
                        <DataGrid
                            dataSource={this.state.venueHierarchyChildGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            onExporting={this.onExporting}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <Paging defaultPageSize={1} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[1, 5, 10, 20]} showInfo={true} />
                            <Column dataField="id" caption="ID" />
                            <Column dataField="fileUploadHeaderId" caption="Header ID" />
                            <Column dataField="clientBusinessEntityName" caption="Client" />
                            <Column dataField="venueName" caption="Venue" />
                            <Column dataField="contactName" caption="Contact" />
                            <Column dataField="userName" caption="User" />
                            <Column dataField="roleName" caption="Role" />
                            <Column dataField="managerContactName" caption="Manager" />
                            <Column dataField="fileContentUploadStatus" cellComponent={StatusColour} />
                            <Column dataField="errorMessage" caption="Error Message" cellComponent={ErrorMessage} />
                            <Column dataField="lastUpdatedBy" caption="Last Updated By" />
                            <Column dataField="lastUpdatedOn" caption="Last Updated on" />
                            <FilterRow visible={true} applyFilter="auto" />
                            <Scrolling mode="standard" useNative={true} scrollByThumb={true} />
                        </DataGrid>
                    }
                </div>
            </div>
        );
    }
}

export default VenueHierarchyUploadFileContentsGrid;
