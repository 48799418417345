import React from "react";
import { AxiosResponse } from "axios";
import OutstandingApprovalService, {
    BillableItemResponse,
    OutstandingCardSections,
    ConfigurableTextType,
} from "../../services/OutstandingApprovalService";
import { ApprovalCardTypes, serviceTypes } from "../../services/FileService";
import LookupService, { LookupTypeIndexes } from "../../services/LookupService";
import outstandingActionsUtilities from "./OutstandingActionsUtilities";
import { LoadIndicator } from "devextreme-react";
import sharedUtils, { LazyLoadConfig } from "../grid/sharedUtilities";
import OutstandingApprovalCard from "./OutstandingApprovalCard";
import EquipmentApprovalCard from "./EquipmentApprovalCard";
import CleaningApprovalCard from "./CleaningApprovalCard";
import EntertainmentApprovalCard from "./EntertainmentApprovalCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import LazyLoad from "react-lazyload";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { ServiceNames, ServiceType } from "../../common/ServiceUtilities";
import CardListUtilities from "../../common/CardListUtilities";
import { Link } from "react-router-dom";

interface OutstandingApprovalsCardListProps {
    status: string;
    serviceTypeId: string;
    parentCallback: (count: number) => void;
    updateCardCount: (cardCount: number) => void;
    clientId: string;
    venueId: string;
}

interface OutstandingApprovalsCardListState {
    cards: BillableItemResponse[];
    configuarationValueIndex: string[];
    showProgressIndicator: boolean;
    configurationValueArray: ConfigurableTextType[];
    count: number;
    outstandingCardSections: OutstandingCardSections;
    clientId: string;
    venueId: string;
    status: string;
}

class OutstandingApprovalsCardList extends React.Component<OutstandingApprovalsCardListProps> {
    lookupService: LookupService;
    state: OutstandingApprovalsCardListState;
    cardService: OutstandingApprovalService;
    utils: outstandingActionsUtilities;
    sharedUtils: sharedUtils;
    cardListUtilities: CardListUtilities;

    constructor(props: OutstandingApprovalsCardListProps) {
        super(props);
        //Initializing the service.
        this.lookupService = new LookupService();
        this.cardService = new OutstandingApprovalService();
        this.utils = new outstandingActionsUtilities();
        this.sharedUtils = new sharedUtils();
        this.cardListUtilities = new CardListUtilities();

        var accordionSectionCardData: OutstandingCardSections = this.utils.initializeOutstandingCardSections();
        this.state = {
            cards: [],
            configuarationValueIndex: [],
            showProgressIndicator: false,
            configurationValueArray: [],
            count: 0,
            outstandingCardSections: accordionSectionCardData,
            clientId: this.props.clientId,
            venueId: this.props.venueId,
            status: this.props.status,
        };
    }

    componentDidMount() {
        this.setState({
            showProgressIndicator: true,
        });
        this.getConfigurationLookup();
    }

    getConfigurationLookup = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.configuration)
            .then(this.handleConfigurationLookupSuccess)
            .catch(this.handleFailure);
    };

    handleConfigurationLookupSuccess = (response: AxiosResponse<any>) => {
        var configData = this.sharedUtils.getConfigurationDataFromLookupResponse(response.data.data);
        this.setState({
            configuarationValue: response.data.data,
            configurationIdIndex: configData.id,
            configuarationValueIndex: configData.value,
        });
        this.setState({
            configurationValueArray: response.data.data,
        });
    };

    // Arrow functions bind the context of 'this' inside the function itself, so no need to bind inside of the constructor.
    // To fetch in the card details from the server.
    fetchCardDetails = () => {
        var { clientId, venueId } = this.props;
        this.props.updateCardCount(-1); //Sending -1 here, since when there are no outstanding approvals the length would be 0.
        this.setState({
            showProgressIndicator: true,
        });
        this.cardService
            .getEditViewData(this.props.status, this.props.serviceTypeId, clientId, venueId)
            .then(this.handleSuccess)
            .catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        var accordionSectionData: OutstandingCardSections = this.utils.groupOutstandingCardListByService(
            response.data.data.eventUkUserGridLookUp ? response.data.data.eventUkUserGridLookUp : []
        );
        this.setState({
            showProgressIndicator: false,
            cards: response.data.data.eventUkUserGridLookUp,
            count: response.data.data.length,
            outstandingCardSections: accordionSectionData,
        });
        this.props.updateCardCount(response.data.data.eventUkUserGridLookUp.length);
    };

    handleFailure = () => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    //need to tweak some logic here as because now cards are separated into accordions.
    //So, we need to paas the serviceTypeId into this function as well.
    //It will facilitate to remove that specific card from the deck.
    filterCardList = (billableId: string, serviceTypeId: string) => {
        var index: number = 0;
        var indexLazyLoaded: number = 0;
        var listOfCards: OutstandingCardSections = this.utils.initializeOutstandingCardSections();
        listOfCards = this.state.outstandingCardSections;
        if (serviceTypeId == ServiceType.Security) {
            index =
                listOfCards && listOfCards.security
                    ? listOfCards.security.findIndex((item: BillableItemResponse) => {
                          return item.billableItemId == billableId;
                      })
                    : -1;
            indexLazyLoaded =
                listOfCards && listOfCards.securityLazyLoaded
                    ? listOfCards.securityLazyLoaded.findIndex((item: BillableItemResponse) => {
                          return item.billableItemId == billableId;
                      })
                    : -1;
            if (index > -1) {
                listOfCards.security.splice(index, 1);
            } else if (indexLazyLoaded > -1) {
                listOfCards.securityLazyLoaded.splice(indexLazyLoaded, 1);
            }
        } else if (serviceTypeId == ServiceType.ContractCleaning) {
            index =
                listOfCards && listOfCards.cleaning
                    ? listOfCards.cleaning.findIndex((item: BillableItemResponse) => {
                          return item.billableItemId == billableId;
                      })
                    : -1;
            indexLazyLoaded =
                listOfCards && listOfCards.cleaningLazyLoaded
                    ? listOfCards.cleaningLazyLoaded.findIndex((item: BillableItemResponse) => {
                          return item.billableItemId == billableId;
                      })
                    : -1;
            if (index > -1) {
                listOfCards.cleaning.splice(index, 1);
            } else if (indexLazyLoaded > -1) {
                listOfCards.cleaningLazyLoaded.splice(indexLazyLoaded, 1);
            }
        } else {
            index =
                listOfCards && listOfCards.entertainment
                    ? listOfCards.entertainment.findIndex((item: BillableItemResponse) => {
                          return item.billableItemId == billableId;
                      })
                    : -1;
            indexLazyLoaded =
                listOfCards && listOfCards.entertainmentLazyLoaded
                    ? listOfCards.entertainmentLazyLoaded.findIndex((item: BillableItemResponse) => {
                          return item.billableItemId == billableId;
                      })
                    : -1;
            if (index > -1) {
                listOfCards.entertainment.splice(index, 1);
            } else if (indexLazyLoaded > -1) {
                listOfCards.entertainmentLazyLoaded.splice(indexLazyLoaded, 1);
            }
        }
        this.setState((prevState: OutstandingApprovalsCardListState) => ({
            outstandingCardSections: listOfCards,
            count:
                listOfCards.security.length +
                listOfCards.cleaning.length +
                listOfCards.entertainment.length +
                listOfCards.securityLazyLoaded.length +
                listOfCards.entertainmentLazyLoaded.length +
                listOfCards.cleaningLazyLoaded.length,
        }));
    };

    //When the count of the card changes update the sidepanel.
    componentDidUpdate(prevProps: OutstandingApprovalsCardListProps, prevState: OutstandingApprovalsCardListState) {
        if (this.state.count != prevState.count) {
            this.props.parentCallback(this.state.count);
        }
        if (this.props.serviceTypeId != prevProps.serviceTypeId || this.props.status != prevProps.status) {
            this.fetchCardDetails();
        } else if (this.props.clientId != prevProps.clientId || this.props.venueId != prevProps.venueId) {
            this.setState(
                {
                    clientId: this.props.clientId,
                    venueId: this.props.venueId,
                },
                () => this.fetchCardDetails()
            );
        }
    }

    renderSecurityCards(
        securityCardLength: number,
        doorSupervisionCssClass: any,
        outstandingCardSections: any,
        securityCardSerialNo: any
    ) {
        if (securityCardLength >= 0 ) {
            return (
                <div className="card-accordion__section--security">
                    <h4 id="approvalsHeadingSecurity">
                        <button
                            className={doorSupervisionCssClass.buttonClass}
                            type="button"
                            data-toggle="collapse"
                            data-target="#approvals-security-section"
                            aria-expanded="false"
                            aria-controls="approvals-security-section"
                        >
                            <b>
                                {securityCardLength > 0
                                    ? `Door Supervision (${securityCardLength})`
                                    : `Door Supervision`}
                            </b>
                            <span className="card-accordion__icon">
                                <FontAwesomeIcon icon={faChevronUp} />
                            </span>
                        </button>
                    </h4>
                    <div
                        id="approvals-security-section"
                        className={doorSupervisionCssClass.sectionClass}
                        aria-labelledby="approvalsHeadingSecurity"
                        data-parent="#approvalsAccordion"
                    >
                        <br />
                        <div className="col-md-5 col-xl-3 grid-info__button-container" style={{ width: "310px" }}>
                            <Link
                                className="btn--link btn btn-primary btn--large btn--with-icon"
                                to={{
                                    pathname: "/checkinOut",
                                    state: {
                                        id: 0,
                                        approvals: true,
                                        serviceTypeId: this.props.serviceTypeId
                                    },
                                }}
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                                ADD SHIFT APPROVAL
                            </Link>
                        </div>

                        {securityCardLength > 0 ? (
                            <div className="row mt-2">
                                {outstandingCardSections.securityLazyLoaded.map((cardItem: BillableItemResponse) => {
                                    return (
                                        <div className="col-12 col-xl-4 mb-4 mt-3 px-3" key={cardItem.billableItemId}>
                                            {cardItem.serviceSubTypeId == ApprovalCardTypes.personnel ? (
                                                <OutstandingApprovalCard
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={securityCardLength}
                                                    serialNumber={securityCardSerialNo++}
                                                    setCost = {()=> {}}
                                                />
                                            ) : (
                                                <EquipmentApprovalCard
                                                    outstandingCardData={cardItem}
                                                    renderCards={this.filterCardList}
                                                    totalNumber={securityCardLength}
                                                    serialNumber={securityCardSerialNo++}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={()=>{}}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                                {outstandingCardSections.security.map((cardItem: BillableItemResponse) => {
                                    return (
                                        <div className="col-12 col-xl-4 mb-4 mt-3 px-3" key={cardItem.billableItemId}>
                                            <LazyLoad
                                                height={LazyLoadConfig.height}
                                                offset={LazyLoadConfig.offset}
                                                placeholder={
                                                    <div className="euk-card__placeholder">
                                                        <LoadIndicator />
                                                    </div>
                                                }
                                            >
                                                {cardItem.serviceSubTypeId == ApprovalCardTypes.personnel ? (
                                                    <OutstandingApprovalCard
                                                        outstandingCardData={cardItem}
                                                        configuarationValueIndex={this.state.configuarationValueIndex}
                                                        configurationValueArray={this.state.configurationValueArray}
                                                        renderCards={this.filterCardList}
                                                        totalNumber={securityCardLength}
                                                        serialNumber={securityCardSerialNo++}
                                                        setCost = {()=> {}}
                                                    />
                                                ) : (
                                                    <EquipmentApprovalCard
                                                        outstandingCardData={cardItem}
                                                        renderCards={this.filterCardList}
                                                        totalNumber={securityCardLength}
                                                        serialNumber={securityCardSerialNo++}
                                                        configurationValueArray={this.state.configurationValueArray}
                                                        setCost={()=>{}}
                                                    />
                                                )}
                                            </LazyLoad>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <p className="text-center">No outstanding approvals</p>
                        )}
                    </div>
                </div>
            );
        }
    }

    renderCleaningCards(
        cleaningCardLength: number,
        contractCleaningCssClass: any,
        outstandingCardSections: any,
        cleaningCardSerialNo: any
    ) {
        if (cleaningCardLength > 0) {
            return (
                <div className="card-accordion__section--cleaning">
                    <h4 id="approvalsHeadingCleaning">
                        <button
                            className={contractCleaningCssClass.buttonClass}
                            type="button"
                            data-toggle="collapse"
                            data-target="#approvals-cleaning-section"
                            aria-expanded="false"
                            aria-controls="approvals-cleaning-sectiong"
                        >
                            <b>{`Contract Cleaning (${cleaningCardLength})`}</b>
                            <span className="card-accordion__icon">
                                <FontAwesomeIcon icon={faChevronUp} />
                            </span>
                        </button>
                    </h4>

                    <div
                        id="approvals-cleaning-section"
                        className={contractCleaningCssClass.sectionClass}
                        aria-labelledby="approvalsHeadingCleaning"
                        data-parent="#approvalsAccordion"
                    >
                        <div className="row mt-2">
                            {outstandingCardSections.cleaningLazyLoaded.map((cardItem: BillableItemResponse) => {
                                return (
                                    <div className="col-12 col-xl-4 mb-4 mt-3 px-3" key={cardItem.billableItemId}>
                                        {cardItem.serviceSubTypeId == ApprovalCardTypes.WeeklyCleaning ? (
                                            <CleaningApprovalCard
                                                outstandingCardData={cardItem}
                                                configuarationValueIndex={this.state.configuarationValueIndex}
                                                cardUIType="weekly"
                                                renderCards={this.filterCardList}
                                                totalNumber={cleaningCardLength}
                                                serialNumber={cleaningCardSerialNo++}
                                                configurationValueArray={this.state.configurationValueArray}
                                                setCost={()=>{}}
                                            />
                                        ) : (
                                            <CleaningApprovalCard
                                                outstandingCardData={cardItem}
                                                configuarationValueIndex={this.state.configuarationValueIndex}
                                                cardUIType="additional"
                                                renderCards={this.filterCardList}
                                                totalNumber={cleaningCardLength}
                                                serialNumber={cleaningCardSerialNo++}
                                                configurationValueArray={this.state.configurationValueArray}
                                                setCost={()=>{}}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                            {outstandingCardSections.cleaning.map((cardItem: BillableItemResponse) => {
                                return (
                                    <div className="col-12 col-xl-4 mb-4 mt-3 px-3" key={cardItem.billableItemId}>
                                        <LazyLoad height={LazyLoadConfig.height} offset={LazyLoadConfig.offset}>
                                            {cardItem.serviceSubTypeId == ApprovalCardTypes.WeeklyCleaning ? (
                                                <CleaningApprovalCard
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    cardUIType="weekly"
                                                    renderCards={this.filterCardList}
                                                    totalNumber={cleaningCardLength}
                                                    serialNumber={cleaningCardSerialNo++}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={()=>{}}
                                                />
                                            ) : (
                                                <CleaningApprovalCard
                                                    outstandingCardData={cardItem}
                                                    configuarationValueIndex={this.state.configuarationValueIndex}
                                                    cardUIType="additional"
                                                    renderCards={this.filterCardList}
                                                    totalNumber={cleaningCardLength}
                                                    serialNumber={cleaningCardSerialNo++}
                                                    configurationValueArray={this.state.configurationValueArray}
                                                    setCost={()=>{}}
                                                />
                                            )}
                                        </LazyLoad>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        }
    }
    renderEntertainmentCards(
        entertainmentCardLength: number,
        entertainmentCssClass: any,
        outstandingCardSections: any,
        entertainmentCardSerialNo: any
    ) {
        if (entertainmentCardLength > 0) {
            return (
                <div className="card-accordion__section--entertainment">
                    <h4 id="approvalsHeadingEntertainment">
                        <button
                            className={entertainmentCssClass.buttonClass}
                            type="button"
                            data-toggle="collapse"
                            data-target="#approvals-entertainment-section"
                            aria-expanded="false"
                            aria-controls="approvals-entertainment-section"
                        >
                            <b>{`Entertainment (${entertainmentCardLength})`}</b>
                            <span className="card-accordion__icon">
                                <FontAwesomeIcon icon={faChevronUp} />
                            </span>
                        </button>
                    </h4>

                    <div
                        id="approvals-entertainment-section"
                        className={entertainmentCssClass.sectionClass}
                        aria-labelledby="approvalsHeadingEntertainment"
                        data-parent="#approvalsAccordion"
                    >
                        <div className="row mt-2">
                            {outstandingCardSections.entertainmentLazyLoaded.map((cardItem: BillableItemResponse) => {
                                return (
                                    <div className="col-12 col-xl-4 mb-4 mt-3 px-3" key={cardItem.billableItemId}>
                                        <EntertainmentApprovalCard
                                            outstandingCardData={cardItem}
                                            configuarationValueIndex={this.state.configuarationValueIndex}
                                            renderCards={this.filterCardList}
                                            totalNumber={entertainmentCardLength}
                                            serialNumber={entertainmentCardSerialNo++}
                                            configurationValueArray={this.state.configurationValueArray}
                                            setCost={()=>{}}
                                        />
                                    </div>
                                );
                            })}
                            {outstandingCardSections.entertainment.map((cardItem: BillableItemResponse) => {
                                return (
                                    <div className="col-12 col-xl-4 mb-4 mt-3 px-3" key={cardItem.billableItemId}>
                                        <LazyLoad height={LazyLoadConfig.height} offset={LazyLoadConfig.offset}>
                                            <EntertainmentApprovalCard
                                                outstandingCardData={cardItem}
                                                configuarationValueIndex={this.state.configuarationValueIndex}
                                                renderCards={this.filterCardList}
                                                totalNumber={entertainmentCardLength}
                                                serialNumber={entertainmentCardSerialNo++}
                                                configurationValueArray={this.state.configurationValueArray}
                                                setCost={()=>{}}
                                            />
                                        </LazyLoad>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderCardSections() {
        var isVenueManager: boolean = UserService.isUserInGroup(RoleGroupNames.VenueManager) ? true : false;
        var securityCardSerialNo: number = 1;
        var cleaningCardSerialNo: number = 1;
        var entertainmentCardSerialNo: number = 1;
        const { outstandingCardSections } = this.state;
        var securityCardLength: number =
            outstandingCardSections && (outstandingCardSections.security || outstandingCardSections.securityLazyLoaded)
                ? outstandingCardSections.security.length + outstandingCardSections.securityLazyLoaded.length
                : 0;
        var cleaningCardLength: number =
            outstandingCardSections && (outstandingCardSections.cleaning || outstandingCardSections.cleaningLazyLoaded)
                ? outstandingCardSections.cleaning.length + outstandingCardSections.cleaningLazyLoaded.length
                : 0;
        var entertainmentCardLength: number =
            outstandingCardSections &&
            (outstandingCardSections.entertainment || outstandingCardSections.entertainmentLazyLoaded)
                ? outstandingCardSections.entertainment.length + outstandingCardSections.entertainmentLazyLoaded.length
                : 0;

        var doorSupervisionCssClass = this.cardListUtilities.getAccordionCssClass(
            ServiceNames.Security,
            securityCardLength,
            cleaningCardLength,
            entertainmentCardLength,
            0,
            0
        );
        var contractCleaningCssClass = this.cardListUtilities.getAccordionCssClass(
            ServiceNames.ContractCleaing,
            securityCardLength,
            cleaningCardLength,
            entertainmentCardLength,
            0,
            0
        );
        var entertainmentCssClass = this.cardListUtilities.getAccordionCssClass(
            ServiceNames.Entertainment,
            securityCardLength,
            cleaningCardLength,
            entertainmentCardLength,
            0,
            0
        );

        if (
            outstandingCardSections &&
            (securityCardLength >= 0 || cleaningCardLength > 0 || entertainmentCardLength > 0)
        ) {
            return (
                <div className="accordion" id="approvalsAccordion">
                    {this.props.serviceTypeId == ServiceType.Security || this.props.serviceTypeId == ServiceType.EmptyService? this.renderSecurityCards(
                        securityCardLength,
                        doorSupervisionCssClass,
                        outstandingCardSections,
                        securityCardSerialNo
                    ) : null}
                    {this.props.serviceTypeId == ServiceType.ContractCleaning || this.props.serviceTypeId == ServiceType.EmptyService? this.renderCleaningCards(
                        cleaningCardLength,
                        contractCleaningCssClass,
                        outstandingCardSections,
                        cleaningCardSerialNo
                    ) : null}
                    {this.props.serviceTypeId == ServiceType.Entertainment || this.props.serviceTypeId == ServiceType.EmptyService? this.renderEntertainmentCards(
                        entertainmentCardLength,
                        entertainmentCssClass,
                        outstandingCardSections,
                        entertainmentCardSerialNo
                    ) : null}
                </div>
            );
        } else {
            return (
                <div className="accent-container text-center py-5">
                    {isVenueManager || this.props.status.toLowerCase() == "approvals"
                        ? "No outstanding approvals"
                        : "No outstanding acceptances"}
                </div>
            );
        }
    }

    render() {
        return this.state.showProgressIndicator ? (
            <div className="row col-lg-12">
                <span className="col-lg-12 text-center">
                    <LoadIndicator
                        id="simple-grid-indicator"
                        height={100}
                        width={100}
                        visible={this.state.showProgressIndicator}
                    />
                </span>
            </div>
        ) : (
            this.renderCardSections()
        );
    }
}

export default OutstandingApprovalsCardList;
