import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import QueryCardList from "../Query/QueryCardList";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import { queryCardUIType } from "../../services/QueryService";
import VenueFilterPanel from "./VenueFilterPanel";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import UserService, { RoleGroupNames } from "../../services/UserService";

interface QueryPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    queryId: string;
}

interface QueryPageComponentState {
    clientId: string;
    venueId: string;
    selectedAllButton: boolean;
    selectedResolvedButton: boolean;
    queryCardType: string;
    queryCardCount: number;
    queryCardsLoaded: boolean;
}

class QueryPage extends Component<QueryPageComponentProps> {
    //Initialize the State
    state: QueryPageComponentState;

    constructor(props: QueryPageComponentProps) {
        super(props);

        this.state = {
            clientId: "",
            venueId: "",
            selectedAllButton: true,
            selectedResolvedButton: false,
            queryCardType: queryCardUIType.Open,
            queryCardCount: 0,
            queryCardsLoaded: false,
        };
    }

    //A callback function that would set the value of client and venue dropdowns in the parent Query page.
    setIdOnParent = (clientId: string, venueId: string) => {
        var isVenueManager: boolean = UserService.isUserInGroup(RoleGroupNames.VenueManager) ? true : false;
        this.setState({
            clientId: clientId ? clientId : isVenueManager ? "0" : "",
            venueId: venueId ? venueId : isVenueManager ? "0" : "",
        });
    };

    //A helper function that would set the total query card count on the parent page.
    setCardCount = (count: number, cardsLoaded: boolean) => {
        this.setState({
            queryCardCount: count,
            queryCardsLoaded: cardsLoaded,
        });
    };

    //Heper function that would toggle the state of the Open/Resolved buttons.
    //The functions receives a parameter which specifies whether the "View Open" button is selected or not.
    toggleButtonStates = (selectedOpenButton: boolean) => {
        this.setState({
            cardUIType: "",
            selectedAllButton: selectedOpenButton,
            selectedResolvedButton: !selectedOpenButton,
            queryCardType: selectedOpenButton ? queryCardUIType.Open : queryCardUIType.Resolved,
        });
    };

    renderViewButtons = () => {
        if(UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager || RoleGroupNames.EventUKRelationshipManager)) {
            return (
                <div className="view-queries-header__controls">
                    <div className="view-queries-header__view-buttons">
                        <button
                            className={`btn
                            view-queries-header__view-button ${
                                this.state.selectedAllButton
                                    ? "view-queries-header__view-button--selected"
                                    : ""
                            }`}
                            onClick={() => this.toggleButtonStates(true)}
                        >
                            View Open
                        </button>
                        <button
                            className={`btn
                            view-queries-header__view-button ${
                                this.state.selectedResolvedButton
                                    ? "view-queries-header__view-button--selected"
                                    : ""
                            }`}
                            onClick={() => this.toggleButtonStates(false)}
                        >
                            View Resolved
                        </button>
                    </div>
                </div>
            )
        }
    }

    render() {
        var { selectedAllButton, clientId, venueId, queryCardCount, queryCardsLoaded } = this.state;
        var queryCardsTotal: string = queryCardsLoaded ? `(${queryCardCount})` : "";
        var header: string = `${"Open Queries"} ${queryCardsTotal}`;
        if (!selectedAllButton) {
            header = `${"Resolved Queries"} ${queryCardsTotal}`;
        }
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <VenueFilterPanel onApplyButtonClick={this.setIdOnParent} />
                    <BulletinPanel />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <section className="col">
                        <div className="view-queries-header">
                            <div>
                                <h2 className="page-title__black">{header}</h2>
                            </div>
                            {this.renderViewButtons()}
                        </div>
                        <div className="py-3">
                            <QueryCardList
                                filterId={this.props.queryId}
                                queryCardType={this.state.queryCardType}
                                clientId={clientId ? clientId : ""}
                                venueId={venueId ? venueId : ""}
                                serviceTypeId={this.props.location.state ? this.props.location.state.serviceTypeId : ""}
                                setQueryCardCount={this.setCardCount}
                                location = {this.props.location}
                            />
                        </div>
                    </section>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(QueryPage);
